import { QueryConfig } from "react-query";
import { listInternalAccounts } from "../../../services/api/banking/internalAccounts";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";

export const QUERY_INTERNAL_ACCOUNTS = "QUERY_INTERNAL_ACCOUNTS";

export const useQueryInternalAccounts = (
  type?: "Clearing" | "TaxWithholding" | "Banking" | "InternationalClearing",
  config?: QueryConfig<
    Awaited<ReturnType<typeof listInternalAccounts>>,
    WSServiceError
  >
) =>
  useWSQuery(QUERY_INTERNAL_ACCOUNTS, () => listInternalAccounts(type), config);
