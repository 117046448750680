import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useWSModal } from "@wingspanhq/fe-component-library/dist/lib/components/common/useWSModal/useWSModal.hook";
import { hi } from "date-fns/locale";

type WSModal = ReturnType<typeof useWSModal>;

export const useOpenModalBySearchParam = <M extends WSModal = WSModal>(
  param: string,
  modal: M,
  modalProps?: Parameters<M["open"]>
) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const isModalOpen = !!params.get(param);

  useEffect(() => {
    if (isModalOpen) {
      modal.open(modalProps).then(result => {
        history.replace(history.location.pathname);
      });
    }
  }, [isModalOpen, history]);

  return {};
};
