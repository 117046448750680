import {
  WSButton,
  WSCentered,
  WSContainer,
  WSElement,
  WSLayout,
  WSMessageBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  TinType,
  VerificationStatus
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { TAX_DOCUMENTS_PATH } from "../..";
import { WSQueries } from "../../../../query/WSQuery";
import { useUserId } from "../../../../query/hooks/helpers";
import { usePaymentsStatusQuery } from "../../../../query/payments/queries";
import {
  useActivities,
  useMemberProfile,
  useUserProfile
} from "../../../../query/users/queries";
import { AddressText } from "../../../../shared/components/AddressText/AddressText";
import { LogoApp } from "../../../../shared/components/LogoApp";
import { selectorTaxClassificationLabel } from "../../../../shared/selectors/selectorTaxClassificationLabel";
import { openIntercom } from "../../../../shared/utils/intercom";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { WS_LINKS } from "../../../../types/wsLinks";
import { selectorMemberTaxFormName } from "../../../1099NECFiling/selectors/selectorMemberTaxFormName";
import { Field } from "../../components/Field";

export const RouteTaxPreferences: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const queryPaymentsSettings = usePaymentsStatusQuery();
  const queryActivity = useActivities(userId);

  return (
    <WSLayout
      onBack={() => {
        history.push(TAX_DOCUMENTS_PATH);
      }}
      line
      headerCenter={<LogoApp />}
    >
      <WSContainer verticalPadding>
        <WSCentered span={{ s: "10", m: "8", l: "6" }}>
          <WSQueries
            queries={{
              queryUser,
              queryMember,
              queryPaymentsSettings,
              queryActivity
            }}
          >
            {({
              queryUserData: user,
              queryMemberData: member,
              queryPaymentsSettingsData: paymentsSettings,
              queryActivityData: activity
            }) => {
              const isIndividual =
                member.profile.formW9Options?.tinPreference ===
                TinType.Individual;
              const isBusiness =
                member.profile.formW9Options?.tinPreference ===
                TinType.Business;
              const status = isIndividual
                ? member.profile.tinVerification?.ssn?.status
                : isBusiness
                ? member.profile.tinVerification?.ein?.status
                : undefined;
              return (
                <>
                  <WSText.Heading4 mb="2XL">Tax preferences</WSText.Heading4>

                  <WSPanel mb="2XL">
                    <Field label="Name" mb="2XL">
                      <WSText color="gray500">
                        {selectorMemberTaxFormName(member)}
                      </WSText>
                    </Field>
                    <Field label="Email" mb="2XL">
                      <WSText color="gray500">{member.user.email}</WSText>
                    </Field>
                    <Field label="Federal Tax Classification" mb="2XL">
                      <WSText color="gray500">
                        {selectorTaxClassificationLabel(
                          member.profile.company?.structure
                        )}
                      </WSText>
                    </Field>

                    <Field label="Taxpayer Identification Number" mb="2XL">
                      <WSElement>
                        <WSText.ParagraphSm mb="M" color="gray500">
                          {isBusiness
                            ? "Employer Identification Number (EIN)"
                            : isIndividual
                            ? "Social Security Number (SSN)"
                            : "-"}
                        </WSText.ParagraphSm>

                        {status === VerificationStatus.Verified ? (
                          <WSMessageBox.Success icon="check" noBorder>
                            <WSText weight="medium" color="green500">
                              IRS Verified
                            </WSText>
                          </WSMessageBox.Success>
                        ) : status === VerificationStatus.Failed ? (
                          <WSMessageBox.Error icon="alert-circle" noBorder>
                            <WSText weight="medium" mb="XS" color="red500">
                              Verification Failed
                            </WSText>
                            <WSText.ParagraphSm color="gray400" mb="XS">
                              Your TIN could not be verified with the IRS.
                            </WSText.ParagraphSm>
                          </WSMessageBox.Error>
                        ) : (
                          <WSMessageBox.Warning icon="alarm" noBorder>
                            <WSText weight="medium" color="amber400">
                              Pending Verification
                            </WSText>
                            <WSText.ParagraphSm color="gray400" mb="XS">
                              we're still waiting to hear back from the IRS. You
                              may receive an email from us requesting more
                              information
                            </WSText.ParagraphSm>
                          </WSMessageBox.Warning>
                        )}
                      </WSElement>
                    </Field>

                    <Field label="Address" mb="2XL">
                      <WSText.ParagraphSm color="gray400" mb="M" as="pre">
                        <AddressText {...member.profile?.address} />
                      </WSText.ParagraphSm>
                      {activity.events.electronic1099Consent ? null : (
                        <WSMessageBox.Info icon="envelope">
                          Your 1099 will be delivered to the address listed
                          above. You will also receive an electronic copy.
                        </WSMessageBox.Info>
                      )}
                    </Field>

                    <Field label="Delivery preferences">
                      <WSText color="gray500">
                        {activity.events.electronic1099Consent
                          ? "Electronic delivery"
                          : "Mail"}
                      </WSText>
                    </Field>
                  </WSPanel>

                  <WSButton.Secondary
                    mb="2XL"
                    fullWidth
                    onClick={() => {
                      history.push(TAX_DOCUMENTS_PATH);
                    }}
                  >
                    Back to tax documents
                  </WSButton.Secondary>

                  <WSText>
                    Questions? View our{" "}
                    <WSButton.Link
                      onClick={() => {
                        openInNewTab(WS_LINKS.faq);
                      }}
                    >
                      1099 FAQs
                    </WSButton.Link>
                  </WSText>
                  <WSText>
                    Need help?{" "}
                    <WSButton.Link onClick={openIntercom}>
                      Chat with a live representative
                    </WSButton.Link>
                  </WSText>
                </>
              );
            }}
          </WSQueries>
        </WSCentered>
      </WSContainer>
    </WSLayout>
  );
};
