import * as Yup from "yup";
import { signatureValidationSchema } from "../../modules/Signature/createRequirementDefinition/signatureValidationSchema";
import { sharedFileValidationSchema } from "../../modules/SharedFile/createRequirementDefinition/sharedFileValidationSchema";
import { backgroundChecksValidationSchema } from "../../modules/BackgroundChecks/createRequirementDefinition/backgroundChecksValidationSchema";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";

const baseValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  type: Yup.string()
    .oneOf([
      RequirementType.Signature,
      RequirementType.SharedFile,
      RequirementType.BackgroundCheck
    ])
    .required("Type is required"),
  instructions: Yup.mixed().when("type", {
    is(type: RequirementType) {
      return [
        RequirementType.BackgroundCheck,
        RequirementType.SharedFile
      ].includes(type);
    },
    then: Yup.string(),
    otherwise: Yup.mixed().notRequired()
  }),
  description: Yup.string()
});

export const validationSchema = baseValidationSchema
  .concat(signatureValidationSchema)
  .concat(sharedFileValidationSchema)
  .concat(backgroundChecksValidationSchema);
