import cn from "classnames";
import * as React from "react";
import { useMemo } from "react";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSGrid, WSGridBreakpoint, WSGridItemProps } from "./WSGrid.component";
import styles from "./WSGrid.module.scss";
import { getResponsiveClassNames } from "./WSGrid.utils";

export type WSCenteredSpan = "2" | "4" | "6" | "8" | "10" | "12";

export interface WSCenteredProps extends WSElementProps {
  span?: WSCenteredSpan | { [key in WSGridBreakpoint]?: WSCenteredSpan };
  itemProps?: WSGridItemProps;
}

export const WSCentered: React.FC<WSCenteredProps> = ({
  span,
  itemProps,
  children,
  ...otherProps
}) => {
  const spanClassNames = useMemo(
    () => getResponsiveClassNames(span, "span-centered"),
    [span]
  );

  const classNames = cn(styles.item, ...spanClassNames, itemProps?.className);

  return (
    <WSGrid {...otherProps}>
      <WSElement {...itemProps} className={classNames}>
        {children}
      </WSElement>
    </WSGrid>
  );
};
