import {
  WSPill,
  WSButton,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  EmailVerificationStatus,
  PhoneVerificationStatus
} from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUserProfile } from "../../../../query/users/queries";
import { formatPhone } from "../../../../shared/utils/formatPhone";
import {
  useModalFirstNameEdit,
  useModalLastNameEdit,
  useModalPreferredNameEdit,
  useModalUserPasswordEdit
} from "./ModalEditSetting";
import { Setting } from "./Setting";

export const Account: React.FC<RouteComponentProps> = () => {
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const history = useHistory();
  const passwordModal = useModalUserPasswordEdit();
  const preferredNameModal = useModalPreferredNameEdit();
  const legalFirstNameModal = useModalFirstNameEdit();
  const legalLastNameModal = useModalLastNameEdit();

  return (
    <>
      <WSText.Heading5 mb="2XL">Account Settings</WSText.Heading5>

      <Setting
        label="Email address"
        onEdit={() => {
          history.push({
            pathname: "/member/verify/email",
            search: "edit=1",
            state: {
              redirectPath: "/payment/account"
            }
          });
        }}
      >
        <WSFlexBox.CenterY>
          <WSText>{queryUser.data?.email}</WSText>
          {queryUser.data?.emailVerificationState?.status ===
          EmailVerificationStatus.Verified ? (
            <WSPill ml="XS" text="Verified" theme="success" />
          ) : (
            <WSButton.Link
              ml="XS"
              onClick={() =>
                history.push({
                  pathname: "/member/verify/email",
                  state: {
                    email: queryUser.data?.email,
                    redirectPath: "/payment/account"
                  }
                })
              }
            >
              Verify
            </WSButton.Link>
          )}
        </WSFlexBox.CenterY>
      </Setting>

      <Setting
        label="Phone number"
        onEdit={() => {
          history.push({
            pathname: "/member/verify/phone-number",
            search: "edit=1",
            state: {
              redirectPath: "/payment/account"
            }
          });
        }}
      >
        {queryUser.data?.phone.number ? (
          <WSFlexBox.CenterY>
            <WSText>
              {formatPhone(queryUser.data?.phone.number.slice(-10))}
            </WSText>
            {queryUser.data?.phone.status ===
            PhoneVerificationStatus.Verified ? (
              <WSPill ml="XS" text="Verified" theme="success" />
            ) : (
              <WSButton.Link
                ml="XS"
                onClick={() =>
                  history.push({
                    pathname: "/member/verify/phone-number",
                    state: {
                      phoneNumber: queryUser.data?.phone.number,
                      redirectPath: "/payment/account"
                    }
                  })
                }
              >
                Verify
              </WSButton.Link>
            )}
          </WSFlexBox.CenterY>
        ) : (
          <WSText color="gray500">Phone number is not added yet</WSText>
        )}
      </Setting>

      <Setting
        label="Password"
        onEdit={() => {
          passwordModal.open();
        }}
      >
        <WSText>*********</WSText>
      </Setting>

      <Setting
        label="Legal first name"
        description="Enter your first name exactly as it is recorded with government tax authorities."
        onEdit={() => {
          legalFirstNameModal.open({
            value: queryUser.data?.profile.firstName
          });
        }}
      >
        {queryUser.data?.profile.firstName ? (
          <WSText>{queryUser.data?.profile.firstName}</WSText>
        ) : (
          <WSText color="gray500">First name is not added yet</WSText>
        )}
      </Setting>

      <Setting
        label="Legal last name"
        description="Enter your last name exactly as it is recorded with government tax authorities."
        onEdit={() => {
          legalLastNameModal.open({
            value: queryUser.data?.profile.lastName
          });
        }}
      >
        {queryUser.data?.profile.lastName ? (
          <WSText>{queryUser.data?.profile.lastName}</WSText>
        ) : (
          <WSText color="gray500">Last name is not added yet</WSText>
        )}
      </Setting>

      <Setting
        label="Preferred name"
        description="A public-facing name that will appear to clients and contractors."
        onEdit={() => {
          preferredNameModal.open({
            value: queryUser.data?.profile.preferredName
          });
        }}
      >
        {queryUser.data?.profile.preferredName ? (
          <WSText>{queryUser.data?.profile.preferredName}</WSText>
        ) : (
          <WSText color="gray500">Preferred name is not added yet</WSText>
        )}
      </Setting>
    </>
  );
};
