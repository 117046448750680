import { ICardResponse } from "../../../../services/api/banking/cards/types";
import { WSList, WSPanel, WSText } from "@wingspanhq/fe-component-library";
import { FieldView } from "../../../../shared/components/FieldView/FieldView";
import React from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import { useMemberProfile } from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { AddressText } from "../../../../shared/components/AddressText/AddressText";

export const CardBillingInfo: React.FC<{ card: ICardResponse }> = ({
  card
}) => {
  const userId = useUserId();
  const memberProfileQuery = useMemberProfile(userId);

  return (
    <WSPanel>
      <WSQueries queries={{ memberProfileQuery }}>
        {({ memberProfileQueryData }) => {
          const names = wsName({
            member: memberProfileQueryData,
            user: memberProfileQueryData.user
          });

          return (
            <WSList gap="L">
              <FieldView label="Name on card" value={names.fullName} />
              <FieldView
                label="Billing address"
                value={
                  <WSText.ParagraphSm as="pre">
                    <AddressText
                      placeholder="-"
                      {...memberProfileQueryData.profile.address}
                    />
                  </WSText.ParagraphSm>
                }
              />
            </WSList>
          );
        }}
      </WSQueries>
    </WSPanel>
  );
};
