import {
  WSCheckboxToggle,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSInputDateOld,
  WSInputNumberOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useFormContext } from "react-hook-form";
import { Hidden } from "../Hidden/Hidden";
import styles from "./index.module.scss";
import { useInvoicesFormContext } from "./InvoicesForm";

export type AdvancedSectionValues = {
  advanced: {
    sendEmails: boolean;
    sendReminders: boolean;
    creditFeeHandling: FormDataCreditFeeHandling;
    date: Date;
  };
};

export type FormDataCreditFeeHandling = {
  clientPaysProcessingFees: boolean;
  clientProcessingFeePercentage?: number;
};

type Props = {} & WSElementProps;

export const AdvancedSection: React.FC<Props> = ({ ...elementProps }) => {
  const { setValue } = useFormContext();
  const { invoicingConfigForPayee } = useInvoicesFormContext();

  return (
    <WSElement {...elementProps}>
      <WSText.Heading5 mb="M">Advanced</WSText.Heading5>

      <Hidden name="advanced">
        <WSFormOld.Field
          name="advanced.sendEmails"
          component={WSCheckboxToggle}
          componentProps={{
            label: "Send invoice to client",
            description:
              "When this invoice is sent, client is emailed invoice payment link and PDF",
            onChange: value => {
              if (!value) {
                setValue("advanced.sendReminders", false);
              }
            }
          }}
          mb="XL"
        />

        {invoicingConfigForPayee?.allowPaymentReminders?.enabled ? (
          <WSFormOld.Value name="advanced.sendEmails">
            {sendEmails => (
              <WSElement
                style={{
                  opacity: sendEmails ? 1 : 0.5,
                  pointerEvents: sendEmails ? undefined : "none"
                }}
              >
                <WSFormOld.Field
                  name="advanced.sendReminders"
                  component={WSCheckboxToggle}
                  componentProps={{
                    label: "Remind client to pay invoice",
                    description:
                      "Send reminder emails when invoice is almost due and overdue"
                  }}
                  mb="XL"
                />
              </WSElement>
            )}
          </WSFormOld.Value>
        ) : null}

        {invoicingConfigForPayee?.allowProcessingFeePercentage?.enabled ? (
          <WSFlexBox alignItems="center" mb="XL">
            <WSFormOld.Field
              name="advanced.creditFeeHandling.clientPaysProcessingFees"
              component={WSCheckboxToggle}
              componentProps={{
                label: "Processing Fee Percentage"
              }}
            />
            <WSFormOld.Value name="advanced.creditFeeHandling.clientPaysProcessingFees">
              {clientPaysProcessingFees => (
                <WSFormOld.Field
                  ml="M"
                  hidden={!clientPaysProcessingFees}
                  name="advanced.creditFeeHandling.clientProcessingFeePercentage"
                  component={WSInputNumberOld}
                  componentProps={{
                    minFractionDigits: 1,
                    min: 0.01,
                    max: 10,
                    suffix: "%"
                  }}
                  defaultValue={2.9}
                  className={styles.clientProcessingFeePercentage}
                />
              )}
            </WSFormOld.Value>

            <WSText.ParagraphSm color="gray400" mt="S">
              Wingspan charges 2.9% for card payments, you can pass on all,
              part, or more this fee to the client.
            </WSText.ParagraphSm>
          </WSFlexBox>
        ) : null}

        <WSElement mb="3XL">
          <WSGrid>
            <WSGrid.Item span={{ m: "7" }}>
              <WSText.Heading5 mb="XS">Date</WSText.Heading5>
              <WSFormOld.Field
                name="advanced.date"
                component={WSInputDateOld}
              />
            </WSGrid.Item>
          </WSGrid>
        </WSElement>
      </Hidden>
    </WSElement>
  );
};
