import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { DeductionsTab } from "./DuductionsTab";
import { DeductionStatus } from "@wingspanhq/payments/dist/interfaces";

export const DeductionsCompleted: React.FC<RouteComponentProps<{
  collaboratorId: string;
}>> = props => {
  const { collaboratorId } = props.match.params;
  const history = useHistory();

  return (
    <DeductionsTab
      collaboratorId={collaboratorId}
      status={[DeductionStatus.Complete]}
      onRowClick={data => {
        history.push(
          `/member/invoices/contacts/collaborators/${collaboratorId}/deductions/completed/${data.deductionId}`
        );
      }}
    />
  );
};
