import { WSTableColumn } from "@wingspanhq/fe-component-library";
import { ReportsColumn } from "../types";
import { formatTableValue } from "./formatTableValue";

export const selectorTableColumns = <T extends any | undefined>(
  columns: ReportsColumn<T>[]
) => {
  return columns.map(
    (column, index, all) =>
      ({
        config: {
          gridTemplateSizeMax: "1fr",
          header: { text: column?.label || "-" }
        },
        renderFunction: rowData =>
          formatTableValue(column?.getTableCell(rowData.data))
      } as WSTableColumn<T>)
  );
};
