import { useHistory } from "react-router-dom";
import { FlowSetup } from "../../../../shared/components/FlowSetup";
import { RouteSubmit } from "./routes/RouteSubmit";
import { RouteEditPayerInformation } from "./routes/RouteEditPayerInformation";
import { RouteSubmitting } from "./routes/RouteSubmitting";
import { RouteNewSubmitting } from "./routes/RouteNewSubmitting";
import { RouteSubmittingError } from "./routes/RouteSubmittingError";
import { RouteSubmittingSuccess } from "./routes/RouteSubmittingSuccess";
import { RouteEditStateFiling } from "./routes/RouteEditStateFiling";

export const FINISH_FILING_FLOW_PATH = "/member/1099-filing/dashboard/all";
export const SubmitFilingFlow: React.FC = () => {
  const history = useHistory();

  const basePath = "/member/1099-filing/filing";
  const onBack = () => {
    history.push(FINISH_FILING_FLOW_PATH);
  };

  return (
    <FlowSetup
      basePath={basePath}
      routes={[
        {
          path: "/submit",
          component: RouteSubmit,
          onBack,
          onNext: () => {
            history.push(basePath + `/new-submitting`);
          }
        },
        {
          path: "/edit-payer-information",
          component: RouteEditPayerInformation,
          onBack: () => {
            history.push(basePath + "/submit");
          },
          onNext: context => {
            history.push(basePath + "/submit");
          }
        },
        {
          path: "/edit-state-filing",
          component: RouteEditStateFiling,
          onBack: () => {
            history.push(basePath + "/submit");
          },
          onNext: context => {
            history.push(basePath + "/submit");
          }
        },
        {
          path: "/new-submitting",
          component: RouteNewSubmitting,
          onBack,
          onNext: props => {
            history.push(basePath + `/submitting/${props?.bulkBatchId}`);
          }
        },
        {
          path: "/submitting/:bulkBatchId",
          component: RouteSubmitting,
          exact: true,
          onBack,
          onNext: context => {
            if (context?.isSuccess) {
              history.push(
                basePath + `/submitting-success/${context?.bulkBatchId}`
              );
            } else {
              history.push(
                basePath + `/submitting-error/${context?.bulkBatchId}`
              );
            }
          }
        },
        {
          path: "/submitting-error/:bulkBatchId",
          component: RouteSubmittingError,
          exact: true,
          onBack,
          onNext: context => {
            history.push(basePath + `/new-submitting`);
          }
        },
        {
          path: "/submitting-success/:bulkBatchId",
          component: RouteSubmittingSuccess,
          exact: true,
          onBack,
          onNext: () => {
            onBack();
          }
        }
      ]}
    />
  );
};
