import cn from "classnames";
import React, { useMemo } from "react";
import { WSFieldStatus } from "../../types/forms";
import {
  detachLayoutProps,
  WSElement,
  WSElementColorsType,
  WSElementProps
} from "../WSElement/WSElement.component";
import {
  WSTooltipIcon,
  WSTooltipIconProps
} from "../WSTooltipIcon/WSTooltipIcon";
import { WSButton, WSButtonProps } from "../core/WSButton/WSButton.component";
import { WSIcon, WSIconName } from "../core/WSIcon/WSIcon.component";
import { WSLoader } from "../core/WSLoader/WSLoader.component";
import { WSText } from "../core/WSText/WSText.component";
import { WSFlexBox } from "../layout";
import styles from "./WSField.module.scss";

export interface InputProps<Value> {
  name: string;
  value: Value;
  onChange: (value: Value, event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  status?: WSFieldStatus;
}

const messageStatusColorMap: Record<WSFieldStatus, WSElementColorsType> = {
  success: "green400",
  error: "red400",
  warning: "amber400",
  loading: "blue400"
};

const messageStatusIconMap: Record<WSFieldStatus, WSIconName> = {
  success: "check-circle-fill",
  error: "alert-circle-fill",
  warning: "alert-fill",
  loading: "loader"
};

export interface WSFieldProps
  extends Omit<
    WSElementProps,
    "name" | "value" | "onChange" | "onBlur" | "onFocus"
  > {
  label?: string;
  required?: boolean;
  action?: {
    onClick: WSButtonProps<"Link">["onClick"];
    loading?: WSButtonProps<"Link">["loading"];
    text: string;
  };
  helperText?: string;
  tooltip?: WSTooltipIconProps["tooltip"];
  stacked?: boolean;
  status?: WSFieldStatus;
  message?: string;
}

export const WSField: React.FC<WSFieldProps> = ({
  label,
  required,
  helperText,
  action,
  tooltip,
  stacked,
  status,
  message,
  children,
  className,
  hidden,
  ...elementProps
}) => {
  const isTopVisible = !!label || !!helperText || !!action;

  const statusMessage = useMemo(() => {
    if (message) return message;

    if (status === "loading") return "Loading...";

    return undefined;
  }, [status, message]);

  return (
    <WSElement
      className={cn(styles.field, className, hidden && styles.hidden)}
      as={action ? "div" : "label"}
      {...elementProps}
    >
      {isTopVisible && (
        <WSElement className={cn(styles.top, stacked && styles.stacked)} mb="S">
          <WSElement>
            {label && (
              <WSElement className={styles.label}>
                <WSText.ParagraphSm color="gray500">{label}</WSText.ParagraphSm>

                {required && (
                  <WSText.ParagraphSm color="gray500" ml="XS">
                    *
                  </WSText.ParagraphSm>
                )}

                {tooltip && <WSTooltipIcon tooltip={tooltip} ml="S" />}
              </WSElement>
            )}

            {helperText && (
              <WSText.ParagraphXs color="gray400">
                {helperText}
              </WSText.ParagraphXs>
            )}
          </WSElement>

          {action && (
            <WSButton.Link
              type="button"
              className={styles.action}
              size="M"
              onClick={action.onClick}
              loading={action.loading}
            >
              {action.text}
            </WSButton.Link>
          )}
        </WSElement>
      )}

      {children}

      {status && (
        <WSFlexBox mt="S" wrap="nowrap" alignItems="center">
          {status === "loading" ? (
            <WSLoader.Spinner size="XS" mr="XS" />
          ) : (
            <WSIcon
              mr="XS"
              name={messageStatusIconMap[status]}
              color={messageStatusColorMap[status]}
            />
          )}
          <WSText.ParagraphXs color={messageStatusColorMap[status]}>
            {statusMessage}
          </WSText.ParagraphXs>
        </WSFlexBox>
      )}
    </WSElement>
  );
};

export const detachFieldProps = <
  T extends { [k: string]: any } = { [k: string]: any }
>({
  label,
  required,
  helperText,
  action,
  tooltip,
  stacked,
  status,
  message,
  children,
  hidden,
  ...elementProps
}: T) => {
  const { layoutProps, systemProps, ...otherProps } = detachLayoutProps(
    elementProps
  );

  return {
    ...otherProps,
    fieldProps: {
      label,
      required,
      helperText,
      action,
      tooltip,
      stacked,
      status,
      message,
      children,
      hidden,
      ...layoutProps
    }
  };
};
