import React from "react";
import { getCountryName } from "../../../query/users/selectors";

export type AddressTextProps = {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  placeholder?: string;
  separator?: string;
};

export const AddressText: React.FC<AddressTextProps> = ({
  addressLine1,
  addressLine2,
  city,
  state,
  postalCode,
  country,
  placeholder = "",
  separator = "\n"
}) => {
  const stateAddress = [city ? `${city},` : null, state, postalCode]
    .filter(Boolean)
    .join(" ");

  const countryName =
    country && getCountryName(country) ? getCountryName(country) : null;

  const result = [addressLine1, addressLine2, stateAddress, countryName]
    .filter(Boolean)
    .join(separator);

  return <>{result.trim() ? result : placeholder}</>;
};
