import get from "lodash/get";
import React, { useContext, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  detachLayoutProps,
  WSElement,
  WSElementProps
} from "../../WSElement/WSElement.component";
import { WSFormFieldContext } from "./WSFormFieldContext";

type RenderInput = React.JSXElementConstructor<{
  onChange: (...event: any[]) => void;
  onBlur: (...event: any[]) => void;
  value: any;
  name: string;
  error: any;
}>;

export interface WSFormFieldInputProps extends WSElementProps {
  className?: string;
  render: RenderInput;
  defaultValue?: any;
}

export const WSFormInput: React.FC<WSFormFieldInputProps> = ({
  render: Component,
  className,
  defaultValue,
  ...otherProps
}) => {
  const name = useContext(WSFormFieldContext);
  const { control, errors } = useFormContext();
  const { layoutProps } = detachLayoutProps(otherProps);

  const error = get(errors, name)?.message;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Input = useMemo(() => Component, []);

  return (
    <WSElement
      as="div"
      data-error={error}
      className={className}
      {...layoutProps}
    >
      <Controller
        name={name}
        control={control}
        render={({ ref, ...props }) => (
          <Input
            error={error}
            {...props}
            onChange={(event) => {
              if (!!event?.originalEvent && event?.value !== undefined) {
                props.onChange(event.value);
              } else if (event?.target) {
                props.onChange(event.target.rawValue || event.target.value);
              } else if (event?.date) {
                props.onChange(event.date);
              } else {
                props.onChange(event);
              }
            }}
          />
        )}
        defaultValue={defaultValue}
      />
    </WSElement>
  );
};

WSFormInput.displayName = "WSFormInput";
