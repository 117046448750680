import { useWSModal, WSLoader } from "@wingspanhq/fe-component-library";
import {
  EmailVerificationStatus,
  PhoneVerificationStatus
} from "@wingspanhq/users/dist/lib/interfaces";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../../../../query/hooks/helpers";
import {
  useMemberProfile,
  useUserProfile
} from "../../../../../query/users/queries";
import { DataView } from "../../../../Onboarding/components/DataView";
import { FormAddPassword } from "../components/FormAddPassword";
import { FormChangePassword } from "../components/FormChangePassword";

type Props = {};

export const RouteAccount: React.FC<Props> = () => {
  const history = useHistory();
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);

  const modalChangePassword = useWSModal(FormChangePassword, {
    title: "Change password",
    size: "S"
  });
  const modalAddPassword = useWSModal(FormAddPassword, {
    title: "Add password",
    size: "S"
  });

  if (queryUser.isLoading || queryMember.isLoading) {
    return <WSLoader.Spinner />;
  }

  return (
    <DataView
      title="Account information"
      data={[
        {
          label: "Sign-in email",
          value: queryUser.data?.email,
          action: {
            label: "Edit",
            onClick: () => {
              history.push("/member/verify/email?edit=1");
            }
          },
          pill:
            queryUser.data?.emailVerificationState?.status ===
            EmailVerificationStatus.Verified
              ? {
                  theme: "success",
                  icon: true,
                  text: "Verified"
                }
              : undefined
        },
        {
          label: "Phone number",
          value: queryUser.data?.phone.number,
          action: {
            label: "Edit",
            onClick: () => {
              history.push("/member/verify/phone-number?edit=1");
            }
          },
          pill:
            queryUser.data?.phone?.status === PhoneVerificationStatus.Verified
              ? {
                  theme: "success",
                  icon: true,
                  text: "Verified"
                }
              : undefined
        },
        {
          label: "Password",
          value: queryUser.data?.isPasswordSet
            ? "********"
            : "Password is not added yet",
          action: queryUser.data?.isPasswordSet
            ? {
                label: "Edit",
                onClick: () => {
                  modalChangePassword.open();
                }
              }
            : {
                label: "Add",
                onClick: () => {
                  modalAddPassword.open();
                }
              }
        }
      ]}
    />
  );
};
