import { WSGrid, WSPage } from "@wingspanhq/fe-component-library";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Tabs } from "../../components/Tabs";
import { InvoicingConfig } from "../../Invoices/screens/payables/InvoicingConfig";
import { PayrollSettings } from "../../Invoices/screens/payables/PayrollSettings";

export const BillingSettings: React.FC = () => {
  return (
    <WSPage title="Payable & Payroll Settings">
      <WSGrid>
        <WSGrid.Item span={{ m: "10" }}>
          <Tabs
            tabs={[
              {
                path: "/member/settings/billing/payables",
                label: "Payables settings"
              },
              {
                path: "/member/settings/billing/payroll",
                label: "Payroll settings"
              }
            ]}
          />
          <Switch>
            <Route
              path="/member/settings/billing/payroll"
              component={PayrollSettings}
            />
            <Route
              path="/member/settings/billing/payables/edit"
              render={props => <InvoicingConfig {...props} isEditing={true} />}
            />
            <Route
              path="/member/settings/billing/payables"
              component={InvoicingConfig}
              exact
            />
            <Redirect from="*" to="/member/settings/billing/payables" />
          </Switch>
        </WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
