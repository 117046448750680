import {
  useIsMobile,
  WSActions,
  WSActionsProps,
  WSBreadcrumbs,
  WSBreadcrumbsItem,
  WSElement,
  WSText
} from "@wingspanhq/fe-component-library";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { classNames } from "primereact/utils";

import styles from "./PageHeader.module.scss";
import { KEYBOARD_SHORTCUT_SEARCH } from "../../../constants/keyboardShortcuts";
import { safePageKeyboardEventHandling } from "../../../utils/keyboard";

export interface IPageHeaderProps extends WSActionsProps {
  title: string;
  breadcrumbs?: WSBreadcrumbsItem[];
  rootPath: string;
  showSearch?: boolean;
}

export const PageHeader: React.FC<IPageHeaderProps> = ({
  title,
  breadcrumbs = [],
  buttons = [],
  showSearch,
  rootPath,
  menuItems = [],
  ...elementProps
}) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const handleSearch = useCallback(() => {
    history.push(rootPath + "/search");
  }, [history, rootPath]);

  const handleKeyUp = useCallback(
    safePageKeyboardEventHandling((event: KeyboardEvent) => {
      if (showSearch && event.key === KEYBOARD_SHORTCUT_SEARCH) {
        handleSearch();
      }
    }),
    [handleSearch, showSearch]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp]);

  let buttonsWithSearch = buttons;
  if (showSearch) {
    buttonsWithSearch = [
      {
        icon: "search",
        label: "Search",
        kind: "Tertiary",
        onClick: handleSearch
      },
      ...buttons
    ];
  }

  return (
    <WSElement
      mb="2XL"
      {...elementProps}
      className={classNames(
        styles.pageToolbar,
        breadcrumbs?.length > 0 && styles.withBreadcrumb
      )}
    >
      <WSElement>
        {breadcrumbs.length > 0 ? (
          <WSBreadcrumbs items={breadcrumbs} />
        ) : (
          title && (
            <WSText kind={isMobile ? "Heading4" : "Heading2"}>{title}</WSText>
          )
        )}
      </WSElement>

      <WSActions
        buttons={buttonsWithSearch}
        menuItems={menuItems}
        alignment={{ xs: "fill", s: "right" }}
        className={styles.actions}
      />
    </WSElement>
  );
};
