import { IS_PRODUCTION_ENV } from "../../shared/constants/environment";

export const VG_ROOT_PARENT_IDS = [
  "9z1k_S5qH55WA2S92R50fk",
  "iXpMhkrPHwHPPCRPDdsMd0",
  "oryj_XBwI7Pim7hzdLMkLF",
  "wMtKRr4cF2LIludIlpSW00"
]; // prod, staging(WS), staging(VG), integration

export const VG_USERS_PARENT_IDS = [
  "VwKkkz2RFOayP0kwCstHa0",
  "TRvO57kpF69gP51tjgNqbV",
  "7OK6oq_QHA9c4eM1lakhG0",
  "IZRxOr_mGeDprq3Pfe7cYV"
]; // prod, staging(WS), staging(VG), integration

export const VG_CLIENT_PARENT_IDS = [
  "04wbVMS9H35R8jY4K3RRMk",
  "sUEkkvtUIq5N1UdGV7Duv0",
  "PmWLaBvfIzD1ubkh1byRVk",
  "I99RwST8HfL1YepP5BZN0F"
]; // prod, staging(WS), staging(VG), integration

export const VG_CONTRACTOR_PARENT_IDS = [
  "9cAM3f4zGfX6T4k7DZMXIF",
  "EAh2Y4l1Hx5cFDmFnzO8DF",
  "JCI0PBJ7GH1wjDnkhu5HEF",
  "J2lSPxOYFwy76jd2z0lg7V"
]; // prod, staging(WS), staging(VG), integration

export const VG_ORG_IDS = [
  ...VG_ROOT_PARENT_IDS,
  ...VG_USERS_PARENT_IDS,
  ...VG_CLIENT_PARENT_IDS,
  ...VG_CONTRACTOR_PARENT_IDS
];
