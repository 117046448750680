import {
  useModalContext,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces/account";
import React from "react";
import { useDeleteAccount } from "../../../query/users/mutations";

type Props = {
  accounts: Array<IAccount>;
  onSubmit(accounts: IAccount[]): void;
};

export const REMOVE_ACCOUNT_CONFIRMATION_MODAL =
  "REMOVE_ACCOUNT_CONFIRMATION_MODAL";

export const ConfirmRemoving: React.FC<Props> = ({ accounts, onSubmit }) => {
  const [deleteAccount, deleteAccountMeta] = useDeleteAccount();
  const { closeModal } = useModalContext();

  return (
    <WSModal
      size="S"
      name={REMOVE_ACCOUNT_CONFIRMATION_MODAL}
      title="Remove account?"
    >
      <WSText mb="XL">
        Remove this account? All data will be removed from Wingspan. This action
        cannot be undone.
      </WSText>

      <WSButtons format="modal">
        <WSButton
          destructive
          loading={deleteAccountMeta.isLoading}
          onClick={async () => {
            await Promise.all(
              accounts.map(account =>
                deleteAccount({ accountId: account.accountId })
              )
            );
            onSubmit(accounts);
            closeModal(REMOVE_ACCOUNT_CONFIRMATION_MODAL);
          }}
        >
          Remove
        </WSButton>
        <WSButton.Secondary
          onClick={() => {
            closeModal(REMOVE_ACCOUNT_CONFIRMATION_MODAL);
          }}
        >
          Cancel
        </WSButton.Secondary>
      </WSButtons>
    </WSModal>
  );
};
