import { ILocationResponse } from "@wingspanhq/users";
import { QueryConfig } from "react-query";
import { usersService } from "../services/users";
import { WSServiceError } from "../utils/serviceHelper";
import { useWSQuery } from "./helpers";

export const QUERY_USER_LOCATION = "QUERY_USER_LOCATION";

export const useUserLocation = (
  queryConfig?: QueryConfig<ILocationResponse, WSServiceError>
) =>
  useWSQuery(
    QUERY_USER_LOCATION,
    async () => await usersService.user.location.get(),
    {
      ...queryConfig
    }
  );
