import { useWSModal } from "@wingspanhq/fe-component-library";
import React, { useEffect } from "react";
import { wsEvents } from "../../utils/WSEvents";
import { ModalOtpVerification } from "./ModalOtpVerification";

export const OPT_VERIFICATION_MODAL = "OPT_VERIFICATION_MODAL";

export const OptVerification: React.FC = () => {
  const modal = useWSModal(ModalOtpVerification, {
    title: "Additional verification required",
    size: "S",
    onClose: () => {
      wsEvents.dispatch(wsEvents.list.OTP_FAIL);
    }
  });

  useEffect(() => {
    const listener = () => {
      modal.open({
        onSuccess: () => {
          wsEvents.dispatch(wsEvents.list.OTP_COMPLETE);
        }
      });
    };

    wsEvents.addListener(wsEvents.list.OTP_REQUEST, listener);

    return () => {
      wsEvents.removeListener(wsEvents.list.OTP_REQUEST, listener);
    };
  }, [modal]);

  return null;
};
