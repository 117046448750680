import React from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import { FormW9Concern } from "../../../../shared/components/FormW9Concern";
import { LayoutPaymentsSetup } from "../../../../shared/components/LayoutPaymentsSetup";
import { useMutationUpdateCurrentStep } from "../../mutations/useMutationUpdateCurrentStep";
import { RouteComponentProps } from "../../types";

export const RouteConsent: React.FC<RouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const userId = useUserId();
  const [updateCurrentStep] = useMutationUpdateCurrentStep();

  return (
    <LayoutPaymentsSetup title="Certify your W-9 is accurate" onBack={onBack}>
      <FormW9Concern
        onSuccess={() => {
          updateCurrentStep({ newStep: 4 });
          onNext();
        }}
      />
    </LayoutPaymentsSetup>
  );
};
