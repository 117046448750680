import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSInputDateOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useFormContext } from "react-hook-form";
import { RadioGroupWithCustomInputs } from "../../../components/RadioGroupWithCustomInputs/RadioGroupWithCustomInputs";
import { useInvoicesFormContext } from "./InvoicesForm";
import { dueInDaysValueMap } from "../../screens/payables/InvoicingConfig";

export type DueSectionValues = {
  due: "on-receipt" | "7-days" | "30-days" | "custom";
  customDueDate: Date;
};

type Props = {} & WSElementProps;

export const DueSection: React.FC<Props> = ({ ...elementProps }) => {
  const { isDraft, invoicingConfigForPayee } = useInvoicesFormContext();
  const { setValue } = useFormContext();
  return (
    <WSElement {...elementProps}>
      <WSText.Heading5 mb="M">Due</WSText.Heading5>
      {invoicingConfigForPayee?.dueInDays?.enabled ? (
        <>
          <WSText.ParagraphSm color="gray400" inline>
            Due date (set by payer):{" "}
          </WSText.ParagraphSm>
          <WSText.ParagraphSm inline data-testid="dueInDays">
            {dueInDaysValueMap[invoicingConfigForPayee?.dueInDays?.value]}
          </WSText.ParagraphSm>
        </>
      ) : (
        <>
          <RadioGroupWithCustomInputs
            style={{ display: isDraft ? "block" : "none" }}
            name="due"
            options={[
              {
                value: "on-receipt",
                label: "On receipt"
              },
              {
                value: "7-days",
                label: "7 days after receipt"
              },
              {
                value: "30-days",
                label: "30 days after receipt"
              },
              {
                value: "custom",
                label: (
                  <WSFlexBox.CenterY wrap="nowrap">
                    <WSText.ParagraphSm as="pre" inline mr="M">
                      On
                    </WSText.ParagraphSm>
                    <WSFormOld.Field
                      name="customDueDate"
                      component={WSInputDateOld}
                      componentProps={{
                        onFocus: () => {
                          setValue("due", "custom");
                        }
                      }}
                    />
                  </WSFlexBox.CenterY>
                )
              }
            ]}
          />

          {!isDraft && (
            <WSGrid gutter="M">
              <WSGrid.Item span={{ s: "4" }}>
                <WSFormOld.Field
                  label="Due date"
                  name="customDueDate"
                  component={WSInputDateOld}
                />
              </WSGrid.Item>
            </WSGrid>
          )}
        </>
      )}
    </WSElement>
  );
};
