import {
  WSButton,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSMarginProps,
  WSText,
  toWSMoneyString,
  useWSModal
} from "@wingspanhq/fe-component-library";
import { useFieldArray, useFormContext } from "react-hook-form";
import { usePayeeQuery } from "../../../query/payee/queries/usePayeeQuery";
import { ModalInvoiceCollaboratorForm } from "./ModalInvoiceCollaboratorForm";

export type InvoiceCollaboratorValues = {
  payee: {
    payeeId: string;
    payerPayeeEngagementId: string;
  } | null;
  description: string;
  amount: number | null;
  remove: string;
};

export type InvoiceCollaboratorsSectionValues = {
  invoiceCollaborators: InvoiceCollaboratorValues[];
};

type Props = WSMarginProps;

export const InvoiceCollaboratorsSection: React.FC<Props> = props => {
  const { register, control } = useFormContext();
  const {
    fields: invoiceCollaborators,
    append,
    remove,
    insert
  } = useFieldArray({
    control,
    name: "invoiceCollaborators"
  });

  const modalForm = useWSModal(ModalInvoiceCollaboratorForm, { size: "S" });

  return (
    <WSElement {...props}>
      <WSText.Heading5 mb="XS">Contractors</WSText.Heading5>
      <WSText color="gray600" mb="XL">
        Automatically route payments to subcontractors when invoice is paid.
      </WSText>

      <WSElement mt="XL" mb="XS">
        <WSGrid gutter="M">
          <WSGrid.Item span={{ xs: "6", s: "7" }}>
            <WSText.ParagraphSm color="gray500">Description</WSText.ParagraphSm>
          </WSGrid.Item>
          <WSGrid.Item span={{ xs: "6", s: "5" }}>
            <WSText.ParagraphSm color="gray500">Amount</WSText.ParagraphSm>
          </WSGrid.Item>
        </WSGrid>
      </WSElement>
      <WSDivider mb="M" />

      {invoiceCollaborators.map((invoiceCollaborator, index) => (
        <WSElement
          my="M"
          key={invoiceCollaborator.id}
          hidden={invoiceCollaborator.remove}
        >
          <input
            type="hidden"
            name={`invoiceCollaborators[${index}].payee.payeeId`}
            ref={register()}
            defaultValue={invoiceCollaborator.payee?.payeeId || ""}
          />
          <input
            type="hidden"
            name={`invoiceCollaborators[${index}].payee.payerPayeeEngagementId`}
            ref={register()}
            defaultValue={
              invoiceCollaborator.payee?.payerPayeeEngagementId || ""
            }
          />
          <input
            type="hidden"
            name={`invoiceCollaborators[${index}].description`}
            ref={register()}
            defaultValue={invoiceCollaborator.description || ""}
          />
          <input
            type="hidden"
            name={`invoiceCollaborators[${index}].amount`}
            ref={register({ valueAsNumber: true })}
            defaultValue={invoiceCollaborator.amount || null}
          />
          <input
            type="hidden"
            name={`invoiceCollaborators[${index}].remove`}
            ref={register()}
            defaultValue={invoiceCollaborator.remove || ""}
          />

          <WSGrid gutter="M">
            <WSGrid.Item span={{ xs: "6", s: "7" }}>
              {invoiceCollaborator.payee?.payeeId && (
                <PayeePreview payeeId={invoiceCollaborator.payee.payeeId} />
              )}
              <WSText.ParagraphSm color="gray600">
                {invoiceCollaborator?.description}
              </WSText.ParagraphSm>
            </WSGrid.Item>
            <WSGrid.Item span={{ xs: "4", s: "4" }}>
              <WSFlexBox.CenterY wrap="nowrap" style={{ height: "100%" }}>
                <WSText>{toWSMoneyString(invoiceCollaborator?.amount)}</WSText>
              </WSFlexBox.CenterY>
            </WSGrid.Item>
            <WSGrid.Item span={{ xs: "2", s: "1" }}>
              <WSFlexBox.CenterY wrap="nowrap" style={{ height: "100%" }}>
                <WSButton.Link
                  size="M"
                  type="button"
                  onClick={() => {
                    modalForm.open({
                      defaultValues: invoiceCollaborator as any,
                      onSubmit: values => {
                        remove(index);
                        insert(index, values);
                      },
                      onDelete: () => {
                        remove(index);
                        insert(index, {
                          remove: "1"
                        });
                      }
                    });
                  }}
                  name={`editCollaborator${index}`}
                >
                  Edit
                </WSButton.Link>
              </WSFlexBox.CenterY>
            </WSGrid.Item>
          </WSGrid>
        </WSElement>
      ))}

      <WSButton.Link
        mb="S"
        size="M"
        type="button"
        icon="plus-circle"
        onClick={() => {
          modalForm.open({
            onSubmit: values => {
              append(values);
            }
          });
        }}
      >
        Add contractor
      </WSButton.Link>

      <WSDivider mb="M" />
    </WSElement>
  );
};

type PayeePreviewProps = {
  payeeId: string;
};

const PayeePreview = ({ payeeId }: PayeePreviewProps) => {
  const queryPayee = usePayeeQuery(payeeId);

  if (!queryPayee.data || !queryPayee.data.user) {
    return null;
  }

  return <WSText>{queryPayee.data.user.email}</WSText>;
};
