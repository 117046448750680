import {
  WSButton,
  WSFlexBox,
  WSInfiniteScroll,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSText,
  toWSDateString
} from "@wingspanhq/fe-component-library";

import { IBulkPayableBatch } from "@wingspanhq/payments/dist/interfaces";
import { WSQueries } from "../../../../query/WSQuery";
import { useBulkPayablesBatchesQuery } from "../../query/bulkPayable/queries";

import format from "date-fns/format";
import { Route, useHistory } from "react-router-dom";
import { BulkBatchFilename } from "../../components/BulkBatchFilename";
import { BulkBatchImportStatus } from "../../components/BulkBatchImportStatus";
import { BulkBatchImportTotal } from "../../components/BulkBatchImportTotal";
import { BulkResource } from "../../types";
import { getBatchCreatorName } from "../../utils/getBatchCreator";
import { BulkImportPayableBatchDetails } from "../BulkImportPayableBatchDetails";

export function BulkImportPayables() {
  const history = useHistory();
  const qBulkPayableBatches = useBulkPayablesBatchesQuery();

  const doFirstImport = () => {
    history.push("/member/invoices/payables/bulk-upload/info");
  };

  const columns: Array<WSTableColumn<IBulkPayableBatch>> = [
    {
      config: {
        gridTemplateSizeMax: "0.4fr",
        justify: "start",
        header: { text: "Created" }
      },
      renderFunction: row => (
        <WSTableCell
          text={toWSDateString(row.data.createdAt, "monthDayYear")}
          secondaryText={format(row.data.createdAt, "hh:mm aaa")}
        />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.5fr",
        justify: "start",
        header: { text: "Imported by" }
      },
      renderFunction: row => (
        <WSTableCell text={getBatchCreatorName(row.data)} />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.8fr",
        justify: "start",
        header: { text: "Name" }
      },
      renderFunction: row => (
        <BulkBatchFilename
          batchId={row.data.bulkPayableBatchId}
          bulkResource={BulkResource.Payable}
        />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.4fr",
        justify: "start",
        header: { text: "Import total" }
      },
      renderFunction: row => (
        <BulkBatchImportTotal batchId={row.data.bulkPayableBatchId} />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.4fr",
        justify: "start",
        header: { text: "Import status" }
      },
      renderFunction: row => (
        <BulkBatchImportStatus
          batchId={row.data.bulkPayableBatchId}
          bulkResource={BulkResource.Payable}
        />
      )
    }
  ];

  return (
    <>
      <Route
        path="/member/imports/payables/:bulkBatchId"
        component={BulkImportPayableBatchDetails}
        exact
      />
      <WSQueries queries={{ qBulkPayableBatches }}>
        {({ qBulkPayableBatches: { data: bulkPayableBatches } }) => {
          const tableData = bulkPayableBatches.map(payableBatch => {
            return {
              data: payableBatch,
              id: payableBatch.bulkPayableBatchId
            };
          });

          return (
            <WSInfiniteScroll
              onLoad={() => {
                qBulkPayableBatches.fetchMore();
              }}
              loadMore={bulkPayableBatches.length > 0}
              endOfList={!qBulkPayableBatches.canFetchMore}
              loading={!!qBulkPayableBatches.isFetchingMore}
            >
              <WSTable<IBulkPayableBatch>
                mt="2XL"
                bottomMessage={
                  qBulkPayableBatches.isFetched && tableData.length === 0 ? (
                    <WSFlexBox.Center direction="column">
                      <WSText mt="2XL">No imports found. </WSText>
                      <WSButton.Link onClick={doFirstImport}>
                        Import payables & deductions
                      </WSButton.Link>
                    </WSFlexBox.Center>
                  ) : null
                }
                tableData={tableData}
                columns={columns}
                onRowClick={rowItem => {
                  history.push(`/member/imports/payables/${rowItem.id}`);
                }}
              />
            </WSInfiniteScroll>
          );
        }}
      </WSQueries>
    </>
  );
}
