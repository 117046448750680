import { servicePayments } from "../servicePayments";
import { IEngagementResponse } from "@wingspanhq/payments/dist/interfaces";

export const deleteRequirementFromEngagement = async (
  engagementId: string,
  requirementDefinitionId: string
): Promise<IEngagementResponse> => {
  const response = await servicePayments.delete(
    `/engagement/${engagementId}/requirement/${requirementDefinitionId}`
  );
  return response.data;
};
