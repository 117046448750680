import {
  WSButton,
  WSAvatar,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { TinType } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { SetupParams } from ".";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import { formatEIN } from "../../../../shared/utils/formatEIN";
import { EditLayout } from "../../components/EditLayout";
import { selectorPayerTaxFormDefaultTinType } from "../../selectors/selectorPayerTaxFormDefaultTinType";

export const RouteTinVerified: React.FC<SetupRouteComponentProps<
  SetupParams
>> = ({ onNext, onBack, params }) => {
  return (
    <EditLayout title="Add recipient" onBack={onBack}>
      <WSFlexBox.CenterX mb="M">
        <WSAvatar.Icon icon="check" color="white" colorBackground="green500" />
      </WSFlexBox.CenterX>

      <WSText.Heading5 align="center" mb="2XL">
        Recipient taxpayer identification number has been verified
      </WSText.Heading5>

      <WSText color="gray500" mb="M">
        The IRS has confirmed the TIN{" "}
        {params?.taxForm
          ? `ending in ${
              selectorPayerTaxFormDefaultTinType(params.taxForm) ===
              TinType.Business
                ? `${
                    params.taxForm.data.w9Info?.ein
                      ? formatEIN(params.taxForm.data.w9Info.ein)
                      : params.taxForm.data.w9Info?.einLastFour
                      ? `••• ${params.taxForm.data.w9Info.einLastFour}`
                      : ""
                  } (Employer Identification Number)`
                : selectorPayerTaxFormDefaultTinType(params.taxForm) ===
                  TinType.Individual
                ? `••• ${params.taxForm.data.w9Info?.ssnLastFour} (Social Security Number)`
                : "-"
            }`
          : ""}
      </WSText>
      <WSText color="gray500">
        You’ll be directed to the next step shortly. If it takes too long, click
        continue below.
      </WSText>

      <WSButton mt="XL" onClick={onNext} fullWidth>
        Continue
      </WSButton>
    </EditLayout>
  );
};
