import { WSSelectOption } from "@wingspanhq/fe-component-library";
import { IIntegrationVendor } from "@wingspanhq/integrations/dist/lib/interfaces/vendor";
import { getIntegrationUserSelectLabel } from "../utils/getIntegrationUserSelectLabel";
import { IIntegrationCustomer } from "@wingspanhq/integrations/dist/lib/interfaces/customer";
import { getSortedAndGroupedQuickbooksUserList } from "../utils/getSortedAndGroupedQuickbooksUserList";

export const selectQuickbooksUserOptions = (
  user?: (IIntegrationVendor | IIntegrationCustomer)[]
) => {
  const groupedUsers = getSortedAndGroupedQuickbooksUserList(user || []);
  const quickbooksUserOptions: WSSelectOption[] = groupedUsers.map(user => {
    return {
      label: getIntegrationUserSelectLabel(user),
      value:
        (user as IIntegrationVendor).vendorId ||
        (user as IIntegrationCustomer).customerId,
      searchText: [
        user.primaryEmailAddress,
        user.displayName,
        user.companyName,
        user.familyName,
        user.givenName
      ]
        .filter(Boolean)
        .join(" ")
    };
  });

  return quickbooksUserOptions;
};
