import { QueryConfig } from "react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { getCards } from "../../../services/api/banking/cards";
import { ICardResponse } from "../../../services/api/banking/cards/types";

export const QUERY_CARDS = "QUERY_CARDS";

export const useCardsQuery = (
  config?: QueryConfig<ICardResponse[], WSServiceError>
) => {
  return useWSQuery<ICardResponse[], WSServiceError>(QUERY_CARDS, getCards, {
    retry: false,
    ...config
  });
};
