import React from "react";
import { Route, Switch } from "react-router-dom";
import { KnowledgeCenterBootcampItem } from "./screens/KnowledgeCenterBootcampItem";
import {
  bootcampItems,
  KnowledgeCenterDashboard
} from "./screens/KnowledgeCenterDashboard";

export const KnowledgeCenterRoot = () => {
  return (
    <>
      {/* Modals */}

      <Switch>
        {/* bootcamp items */}
        {bootcampItems.map(item => (
          <Route
            path={`/member/knowledge-center/${item.slug}`}
            exact
            render={() => <KnowledgeCenterBootcampItem item={item} />}
          />
        ))}

        <Route
          path="/member/knowledge-center"
          component={KnowledgeCenterDashboard}
        />
      </Switch>
    </>
  );
};
