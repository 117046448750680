import React, { PropsWithChildren, useMemo, useRef } from "react";
import { WSIcon, WSIconProps } from "../core/WSIcon/WSIcon.component";
import { WSElementLayoutProps } from "../WSElement/WSElement.component";
import { WSTooltip, WSTooltipProps } from "../WSTooltip/WSTooltip";

const DEFAULT_ICON_SIZE = "S";
const DEFAULT_ICON_COLOR = "gray500";
const DEFAULT_ICON_NAME = "info-circle-fill";

export type WSTooltipIconProps = {
  tooltip: string | Omit<PropsWithChildren<WSTooltipProps>, "target">;
} & WSElementLayoutProps & {
    name?: WSIconProps["name"];
    size?: WSIconProps["size"];
    block?: WSIconProps["block"];
  };

export const WSTooltipIcon: React.FC<WSTooltipIconProps> = ({
  tooltip,
  ...iconProps
}) => {
  const tooltipIconRef = useRef<HTMLSpanElement>(null);

  const tooltipProps: WSTooltipProps = useMemo(
    () => ({
      position: "right",
      target: tooltipIconRef,
      ...(typeof tooltip === "string" ? { children: tooltip } : tooltip)
    }),
    [tooltip]
  );

  return (
    <>
      <WSIcon
        size={DEFAULT_ICON_SIZE}
        color={DEFAULT_ICON_COLOR}
        name={DEFAULT_ICON_NAME}
        ref={tooltipIconRef}
        {...iconProps}
      />

      <WSTooltip {...tooltipProps} />
    </>
  );
};
