import {
  WSButton,
  WSButtonProps,
  WSButtons,
  WSCheckboxToggle,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSInputDateOld,
  WSInputNumberOld,
  WSMessageBox,
  WSModal,
  WSSelectOld,
  WSTable,
  WSTableCell,
  WSText,
  toWSDateString,
  useModalContext,
  useWSModal,
  useWSSnackbar
} from "@wingspanhq/fe-component-library";
import {
  FrequencyAndScheduleStatus,
  IPayrollSettings,
  PayrollWorkflowStrategy,
  ScheduleStatus
} from "@wingspanhq/payments/dist/interfaces";
import {
  IFrequencyUpdate,
  Intervals
} from "@wingspanhq/payments/dist/interfaces/invoice";
import React from "react";
import { queryCache } from "react-query";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { RadioGroupWithCustomInputs } from "../../../components/RadioGroupWithCustomInputs/RadioGroupWithCustomInputs";
import { WSQueries } from "../../../query/WSQuery";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { QUERY_PAYROLL_SETTINGS } from "../../../query/payments/keys";
import { useUpdatePayrollSettings } from "../../../query/payments/mutations";
import { usePayrollSettings } from "../../../query/payments/queries";
import { useClientQuery } from "../../../query/users/queries";
import { paymentsService } from "../../../services/payments";
import { EmptyState } from "../../../shared/components/EmptyState";
import { selectorIsDefaultPaymentMethodSet } from "../../../shared/selectors/selectorIsDefaultPaymentMethodSet";
import { WeekDay } from "../../../shared/types/time";
import {
  dayInIntervalToWeekDayMapping,
  weekDayToDayInIntervalMapping
} from "../../../shared/utils/time";
import { getChangedData } from "../../../utils/getChangedData";
import { getExactCutOffDate } from "../../utils/payables";

enum Options {
  Monthly = "Monthly",
  SemiMonthly = "SemiMonthly",
  Everyday = "Everyday",
  Week = "Week"
}

const EDIT_SCHEDULE_DATE_MODAL = "EDIT_SCHEDULE_DATE_MODAL";
const APPROVE_CHANGES_MODAL = "APPROVE_CHANGES_MODAL";
const PAY_DATE_INFO_MODAL = "PAY_DATE_INFO_MODAL";
const PAY_APPROVED_INVOICES_ONLY_WHEN_DUE_MODAL =
  "PAY_APPROVED_INVOICES_ONLY_WHEN_DUE_MODAL";

const PayDateInfoModal = () => {
  return (
    <WSModal name={PAY_DATE_INFO_MODAL} size="M">
      <WSText.ParagraphSm weight="medium" mb="XS">
        Payroll Schedule
      </WSText.ParagraphSm>
      <WSText mb="M">
        Set your payroll schedule by defining the day(s) of the month when your
        contractors will be paid (Expected pay date). By default, payroll will
        process two business days before Expected pay date on the Processing
        date.
      </WSText>
      <WSText>
        • Monthly: The payment(s) will be received on the last day of each
        month.
      </WSText>
      <WSText>
        • Semi-Monthly: The payment(s) will be received on the 15th and last day
        of each month.
      </WSText>
      <WSText>• Everyday: The payment(s) will be received daily.</WSText>
      <WSText>
        • Weekly: You decide which day of the week, and the weekly frequency.
      </WSText>
      <WSText.ParagraphSm weight="medium" mt="XL" mb="XS">
        Weekend and Holidays
      </WSText.ParagraphSm>
      <WSText mb="XL">
        If Processing date falls on a weekend or a holiday, it will run on the
        preceding business day. For example if it falls on Saturday, the payment
        will run on Friday.
      </WSText>
      <WSText mb="XL">
        If the Expected pay date falls on a weekend or a holiday, it will be
        deposited the next business day. For example, if the 15th is a Sunday,
        the Expected pay date will be the 16th.
      </WSText>
      <WSText>
        On US bank holidays, the payroll schedule may be updated to accommodate.
      </WSText>
    </WSModal>
  );
};

const PayApprovedInvoicesOnlyWhenDueModal = () => {
  return (
    <WSModal name={PAY_APPROVED_INVOICES_ONLY_WHEN_DUE_MODAL} size="M">
      <WSText.ParagraphSm weight="medium" mt="XL" mb="XS">
        Only pay approved payables when due
      </WSText.ParagraphSm>
      <WSText mb="XL">
        If this checkbox is selected, approved payables will only be paid at the
        last possible payroll such that the payment arrives before the Due Date.
      </WSText>
      <WSText mb="XL">
        If this checkbox is not selected, all approved payables will be paid out
        at the next payroll run.
      </WSText>
      <WSText mb="XL">
        If you would like to change when a payable is paid, you can override the
        Due Date and set Pay Date by editing the payable.
      </WSText>
      <WSText>
        If you would like to change the number of days considered in payroll
        before an payable is due, contact your account manager.
      </WSText>
    </WSModal>
  );
};

export const ModalDisablePayroll: React.FC<{
  onCancel?: () => void;
  onClose: () => void;
}> = ({ onClose, onCancel }) => {
  const userId = useUserId();

  const { openSnackbar } = useWSSnackbar();

  const [disablePayroll, disablePayrollMeta] = useWSMutation(
    async () => {
      return await paymentsService.payrollSettings.update(userId, {
        runsPayroll: false
      });
    },
    {
      onSuccess: payrollSettings => {
        onClose();
        openSnackbar({
          type: "success",
          message: "Payroll disabled"
        });

        queryCache.setQueryData(QUERY_PAYROLL_SETTINGS, payrollSettings);
      },
      onError: () => {
        onClose();
        openSnackbar({
          type: "warning",
          message: "Something went wrong"
        });
      }
    }
  );

  return (
    <>
      <WSMessageBox.Warning
        mb="M"
        title="Disable payroll and remove upcoming payroll runs"
        icon="alert-circle"
      >
        If you proceed with disabling payroll now, all upcoming payroll runs
        will be removed. You can always re-enable it and set up your payroll
        runs from scratch.
      </WSMessageBox.Warning>

      <WSButtons forceFullWidth>
        <WSButton
          destructive
          onClick={disablePayroll}
          loading={disablePayrollMeta.isLoading}
        >
          Yes, disable payroll
        </WSButton>
        <WSButton.Tertiary
          onClick={() => {
            onClose();
            onCancel?.();
          }}
        >
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </>
  );
};

export const PayrollSettings: React.FC = () => {
  const userId = useUserId();
  const history = useHistory();
  const qPayrollSettings = usePayrollSettings(userId, { retry: false });
  const [
    updatePayrollSettings,
    updatePayrollSettingsMeta
  ] = useUpdatePayrollSettings(userId);
  const [
    updatePayrollSettingsInModal,
    updatePayrollSettingsInModalMeta
  ] = useUpdatePayrollSettings(userId);
  const { openModal, closeModal } = useModalContext();
  const clientQuery = useClientQuery(userId);

  const { openSnackbar } = useWSSnackbar();
  const modalDisablePayroll = useWSModal(ModalDisablePayroll, {
    title: "Are you sure?",
    size: "S"
  });

  const [enablePayroll, enablePayrollMeta] = useWSMutation(
    async () => {
      return await paymentsService.payrollSettings.update(userId, {
        runsPayroll: true
      });
    },
    {
      onSuccess: payrollSettings => {
        openSnackbar({
          type: "success",
          message: "Payroll enabled"
        });

        queryCache.setQueryData(QUERY_PAYROLL_SETTINGS, payrollSettings);
      },
      onError: () => {
        openSnackbar({
          type: "warning",
          message: "Something went wrong"
        });
      }
    }
  );

  return (
    <WSQueries
      queries={{ qPayrollSettings, clientQuery }}
      renderErrors={() => (
        <WSText color="gray500">
          No payment settings found. Please set up{" "}
          <WSButton.Link onClick={() => history.push("/member/invoices")}>
            Payments
          </WSButton.Link>{" "}
        </WSText>
      )}
    >
      {({
        qPayrollSettings: { data: payrollSettings },
        clientQuery: { data: client }
      }) => {
        let interval: Options;
        let every = 1;
        let dayInInterval = 1;

        if (payrollSettings.frequency?.daily) {
          interval = Options.Everyday;
        } else if (payrollSettings.frequency?.twicePerMonth) {
          interval = Options.SemiMonthly;
        } else if (
          payrollSettings.frequency?.interval === Intervals.Month &&
          payrollSettings.frequency?.every === 1 &&
          payrollSettings.frequency?.dayInInterval === 31
        ) {
          interval = Options.Monthly;
        } else {
          interval = Options.Week;
          every = payrollSettings.frequency?.every || 1;
          dayInInterval = payrollSettings.frequency?.dayInInterval || 1;
        }

        if (!payrollSettings.runsPayroll) {
          return (
            <WSFlexBox
              direction="column"
              alignItems="center"
              alignContent="center"
            >
              <EmptyState
                mb="M"
                image="NoResults"
                title="No upcoming payroll runs"
                description="Enable payroll to set up payroll runs"
              />

              <WSButton
                onClick={enablePayroll}
                loading={enablePayrollMeta.isLoading}
              >
                Enable payroll
              </WSButton>
            </WSFlexBox>
          );
        }

        return (
          <>
            <PayDateInfoModal />
            <PayApprovedInvoicesOnlyWhenDueModal />
            <WSFormOld
              mt="M"
              onSubmit={data => {
                openModal(APPROVE_CHANGES_MODAL, {
                  onSubmit: () => {
                    let every = data.every;
                    let dayInInterval = data.dayInInterval;
                    let interval = data.interval;
                    let startDate = new Date();
                    let frequency: IFrequencyUpdate;

                    if (interval === Options.Everyday) {
                      frequency = {
                        daily: true,
                        startDate
                      };
                    } else if (interval === Options.SemiMonthly) {
                      frequency = {
                        twicePerMonth: true,
                        startDate
                      };
                    } else if (interval === Options.Monthly) {
                      frequency = {
                        every: 1,
                        dayInInterval: 31,
                        interval: Intervals.Month,
                        startDate
                      };
                    } else {
                      frequency = {
                        every,
                        dayInInterval:
                          weekDayToDayInIntervalMapping[dayInInterval],
                        interval: Intervals.Week,
                        startDate
                      };
                    }

                    updatePayrollSettings(
                      getChangedData(payrollSettings, {
                        frequency,
                        status: FrequencyAndScheduleStatus.Active,
                        scheduleDates: null,
                        issue1099s: data.issue1099s,
                        workflow: data.enableMultiStageApproval
                          ? PayrollWorkflowStrategy.DualStage
                          : PayrollWorkflowStrategy.SingleStage,
                        enableProcessDaysBeforeDue:
                          data.enableProcessDaysBeforeDue,
                        processDaysBeforeDue: data.enableProcessDaysBeforeDue
                          ? data.processDaysBeforeDue
                          : undefined
                      })
                    );
                  }
                });
              }}
              defaultValues={{
                interval,
                every,
                dayInInterval: dayInIntervalToWeekDayMapping[dayInInterval],
                start: payrollSettings.frequency?.startDate || new Date(),
                issue1099s: payrollSettings.issue1099s,
                enableMultiStageApproval:
                  payrollSettings?.workflow ===
                  PayrollWorkflowStrategy.DualStage
                    ? true
                    : false,
                enableProcessDaysBeforeDue:
                  payrollSettings.enableProcessDaysBeforeDue,
                processDaysBeforeDue: payrollSettings.processDaysBeforeDue || 0
              }}
              validationSchema={Yup.object().shape({
                interval: Yup.string()
                  .oneOf(Object.values(Options))
                  .required(),
                every: Yup.number().when("interval", {
                  is: Options.Week,
                  then: Yup.number().required("Required")
                }),
                dayInInterval: Yup.string()
                  .oneOf(Object.values(WeekDay))
                  .when("interval", {
                    is: Options.Week,
                    then: Yup.string()
                      .oneOf(Object.values(WeekDay))
                      .required("Required")
                  })
              })}
            >
              {({ setValue }) => {
                return (
                  <>
                    <WSModal
                      name={APPROVE_CHANGES_MODAL}
                      size="S"
                      title="Confirm saving"
                    >
                      {({ onSubmit }) => (
                        <>
                          <WSText mb="XL">
                            All future payrolls will be rescheduled
                          </WSText>
                          <WSButtons format="modal">
                            <WSButton
                              type="button"
                              onClick={() => {
                                closeModal(APPROVE_CHANGES_MODAL);
                                onSubmit();
                              }}
                            >
                              Confirm
                            </WSButton>
                            <WSButton.Secondary
                              type="button"
                              onClick={() => {
                                closeModal(APPROVE_CHANGES_MODAL);
                              }}
                            >
                              Cancel
                            </WSButton.Secondary>
                          </WSButtons>
                        </>
                      )}
                    </WSModal>

                    {!selectorIsDefaultPaymentMethodSet(
                      client,
                      payrollSettings
                    ) ? (
                      <WSMessageBox.Warning
                        mb="XL"
                        onClick={() => {
                          history.push("/member/settings/payment-methods");
                        }}
                      >
                        Please{" "}
                        <WSButton.Link>
                          add a default payment method
                        </WSButton.Link>{" "}
                        to enable payroll.
                      </WSMessageBox.Warning>
                    ) : null}

                    <WSFlexBox.CenterY>
                      <WSText.ParagraphSm weight="medium">
                        Expected pay date
                      </WSText.ParagraphSm>
                      <WSIcon
                        ml="XS"
                        size="XS"
                        name="info-circle"
                        onClick={() => openModal(PAY_DATE_INFO_MODAL)}
                      />
                    </WSFlexBox.CenterY>
                    <RadioGroupWithCustomInputs
                      mb="XL"
                      label="How often should your contractors receive payments?"
                      name="interval"
                      options={[
                        {
                          value: Options.Monthly,
                          label: "Monthly"
                        },
                        {
                          value: Options.SemiMonthly,
                          label: "Semi-monthly"
                        },
                        {
                          value: Options.Everyday,
                          label: "Everyday"
                        },
                        {
                          value: Options.Week,
                          label: (
                            <WSFlexBox wrap="nowrap" style={{ maxHeight: 44 }}>
                              <WSText.ParagraphSm as="pre" inline mr="M" mt="M">
                                Every
                              </WSText.ParagraphSm>
                              <WSFormOld.Field
                                name="every"
                                component={WSInputNumberOld}
                                style={{ width: 50 }}
                                componentProps={{
                                  onFocus: () => {
                                    setValue("interval", Options.Week);
                                  }
                                }}
                              />
                              <WSText.ParagraphSm as="pre" inline mx="M" mt="M">
                                week(s) on
                              </WSText.ParagraphSm>
                              <WSFormOld.Field
                                name="dayInInterval"
                                component={WSSelectOld}
                                componentProps={{
                                  options: Object.keys(WeekDay).map(key => ({
                                    value: key,
                                    label: key
                                  })),
                                  onFocus: () => {
                                    setValue("interval", Options.Week);
                                  }
                                }}
                              />
                            </WSFlexBox>
                          )
                        }
                      ]}
                    />

                    <WSElement mb="2XL">
                      <WSFormOld.Field
                        mb="XL"
                        name="enableProcessDaysBeforeDue"
                        component={WSCheckboxToggle}
                        componentProps={{
                          label: (
                            <WSFlexBox.CenterY>
                              <WSText.ParagraphSm>
                                Pay approved payables only when due
                              </WSText.ParagraphSm>
                              <WSIcon
                                ml="XS"
                                size="XS"
                                name="info-circle"
                                onClick={() =>
                                  openModal(
                                    PAY_APPROVED_INVOICES_ONLY_WHEN_DUE_MODAL
                                  )
                                }
                              />
                            </WSFlexBox.CenterY>
                          )
                        }}
                      />

                      {payrollSettings?.processDaysBeforeDue &&
                      payrollSettings.processDaysBeforeDue > 0 ? (
                        <WSFormOld.Value name="enableProcessDaysBeforeDue">
                          {enableProcessDaysBeforeDue => (
                            <WSElement
                              mb="XL"
                              hidden={!enableProcessDaysBeforeDue}
                              style={{ width: 150 }}
                            >
                              <WSFormOld.Field
                                name="processDaysBeforeDue"
                                component={WSInputNumberOld}
                                label="Number of days to pay before due"
                              />
                            </WSElement>
                          )}
                        </WSFormOld.Value>
                      ) : null}
                    </WSElement>

                    <WSFormOld.Field
                      mb="XL"
                      name="enableMultiStageApproval"
                      component={WSCheckboxToggle}
                      componentProps={{
                        label: "Enable multi-stage approval for payables"
                      }}
                    />

                    <WSButtons>
                      <WSButton.Primary
                        loading={updatePayrollSettingsMeta.isLoading}
                      >
                        Save
                      </WSButton.Primary>
                      <WSButton.Secondary
                        destructive
                        type="button"
                        onClick={() => {
                          modalDisablePayroll.open();
                        }}
                      >
                        Disable payroll
                      </WSButton.Secondary>
                    </WSButtons>
                  </>
                );
              }}
            </WSFormOld>

            <WSModal name={EDIT_SCHEDULE_DATE_MODAL} size="XS">
              {({ index, checkDate }) => (
                <WSFormOld
                  defaultValues={{ checkDate }}
                  onSubmit={({ checkDate }) => {
                    updatePayrollSettingsInModal(
                      getChangedData(payrollSettings, {
                        scheduleDates: (
                          payrollSettings.scheduleDates || []
                        ).map((_, itemIndex) =>
                          itemIndex === index
                            ? {
                                date: checkDate,
                                status: ScheduleStatus.Modified
                              }
                            : {}
                        )
                      }),
                      {
                        onSuccess: () => {
                          closeModal(EDIT_SCHEDULE_DATE_MODAL);
                        }
                      }
                    );
                  }}
                >
                  <WSFormOld.Field
                    mb="XL"
                    label="Expected pay date"
                    name="checkDate"
                    component={WSInputDateOld}
                  />
                  <WSButton
                    loading={updatePayrollSettingsInModalMeta.isLoading}
                  >
                    Update
                  </WSButton>
                </WSFormOld>
              )}
            </WSModal>
            <WSElement mt="2XL" style={{ maxWidth: 400 }}>
              <WSText.Heading5 mb="XL">Upcoming payroll runs</WSText.Heading5>

              <WSTable
                tableData={(payrollSettings.scheduleDates || [])
                  .map((scheduleDate, index) => ({
                    id: String(index),
                    data: scheduleDate
                  }))
                  .filter(tableDataItem =>
                    tableDataItem.data?.cutOffDate
                      ? getExactCutOffDate(tableDataItem.data.cutOffDate) >
                        new Date()
                      : false
                  )}
                columns={[
                  {
                    config: {
                      header: { text: "Processing date" }
                    },
                    renderFunction: ({ data }) => (
                      <WSTableCell
                        text={toWSDateString(data.cutOffDate, "monthDayYear")}
                      />
                    )
                  },
                  {
                    config: {
                      header: { text: "Expected pay date" }
                    },
                    renderFunction: ({ data }) => (
                      <WSTableCell
                        text={toWSDateString(data.date, "monthDayYear")}
                      />
                    )
                  },
                  {
                    config: {
                      justify: "end"
                    },
                    renderFunction: ({ id, data }) =>
                      data.status === ScheduleStatus.Skipped ? (
                        <RenewButton
                          index={Number(id)}
                          payrollSettings={payrollSettings}
                        />
                      ) : (
                        <WSFlexBox direction="row">
                          <WSButton.Link
                            mr="M"
                            onClick={() => {
                              openModal(EDIT_SCHEDULE_DATE_MODAL, {
                                index: Number(id),
                                checkDate: data.date
                              });
                            }}
                          >
                            Edit
                          </WSButton.Link>
                          <SkipButton
                            index={Number(id)}
                            payrollSettings={payrollSettings}
                          />
                        </WSFlexBox>
                      )
                  }
                ]}
              />
            </WSElement>
          </>
        );
      }}
    </WSQueries>
  );
};

const SkipButton: React.FC<{
  index: number;
  payrollSettings: IPayrollSettings;
} & WSButtonProps<"Link">> = ({ index, payrollSettings, ...buttonProps }) => {
  const userId = useUserId();
  const [
    updatePayrollSettings,
    updatePayrollSettingsMeta
  ] = useUpdatePayrollSettings(userId);

  return (
    <WSButton.Link
      loading={updatePayrollSettingsMeta.isLoading}
      onClick={() => {
        updatePayrollSettings({
          scheduleDates: (
            payrollSettings.scheduleDates || []
          ).map((_, itemIndex) =>
            itemIndex === index ? { status: ScheduleStatus.Skipped } : {}
          )
        });
      }}
      {...buttonProps}
    >
      Skip
    </WSButton.Link>
  );
};

const RenewButton: React.FC<{
  index: number;
  payrollSettings: IPayrollSettings;
} & WSButtonProps<"Link">> = ({ index, payrollSettings, ...buttonProps }) => {
  const userId = useUserId();
  const [
    updatePayrollSettings,
    updatePayrollSettingsMeta
  ] = useUpdatePayrollSettings(userId);

  return (
    <WSButton.Link
      loading={updatePayrollSettingsMeta.isLoading}
      onClick={() => {
        updatePayrollSettings({
          scheduleDates: (
            payrollSettings.scheduleDates || []
          ).map((_, itemIndex) =>
            itemIndex === index ? { status: ScheduleStatus.Modified } : {}
          )
        });
      }}
      {...buttonProps}
    >
      Renew
    </WSButton.Link>
  );
};
