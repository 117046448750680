import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse,
  ITaxFormCorrectionResponse,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
import { FormRequestCorrectionData } from "../../../../TaxDocuments/routes/RequestCorrectionFlow/types";
import {
  getPayeeFormW9Address,
  getPayeeTINorName
} from "../../../../TaxDocuments/routes/RequestCorrectionFlow/getDefaultValuesForCorrectionsForm";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../../TaxDocuments/constants/corrections";

export function transformCorrectionRequestToFormData(
  corrections: string[],
  taxForm: IPayeeTaxFormResponse | IPayerTaxFormResponse,
  correction?: ITaxFormCorrectionResponse
): Partial<FormRequestCorrectionData> {
  const address = getPayeeFormW9Address(taxForm);
  const addressFormData = {
    addressLine1:
      correction?.data?.w9Info?.addressLine1 ?? address.addressLine1,
    addressLine2:
      correction?.data?.w9Info?.addressLine2 ?? address.addressLine2,
    city: correction?.data?.w9Info?.city ?? address.city,
    state: correction?.data?.w9Info?.state ?? address.state,
    zipCode: correction?.data?.w9Info?.postalCode ?? address.zipCode,
    country: correction?.data?.w9Info?.country ?? address.country
  };

  const tinData = getPayeeTINorName(taxForm);
  return {
    ...(corrections.includes(CORRECTION_AMOUNT)
      ? {
          necTotalAmount:
            correction?.data?.totalAmount ?? taxForm?.data?.totalAmount ?? 0,
          amountCorrectionHelpText: correction?.payerOwnedData?.comment ?? ""
        }
      : {}),

    ...(corrections.includes(CORRECTION_TIN_OR_NAME)
      ? {
          identificationNumberType:
            correction?.data?.w9Info?.ein ||
            correction?.data?.w9Info?.einLastFour ||
            tinData?.ein ||
            tinData?.einLastFour
              ? TinType.Business
              : TinType.Individual,
          ssn: "",
          ein:
            correction?.data?.w9Info?.ein ??
            correction?.data?.w9Info?.einLastFour ??
            tinData?.ein,
          firstName: correction?.data?.w9Info?.firstName ?? tinData?.firstName,
          lastName: correction?.data?.w9Info?.lastName ?? tinData?.lastName,
          businessName:
            correction?.data?.w9Info?.legalBusinessName ?? tinData.businessName,
          disregardedEntityName:
            correction?.data?.w9Info?.disregardedEntityName ??
            tinData.disregardedEntityName,
          taxClassification:
            correction?.data?.w9Info?.companyStructure ??
            tinData.taxClassification
        }
      : {}),

    ...(corrections.includes(CORRECTION_ADDRESS)
      ? { formW9Address: addressFormData }
      : {})
  };
}
