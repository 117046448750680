import { cardsService } from "./cardsService";
import { IActivateCardRequest, ICardResponse } from "./types";

export const activateCard = async (
  cardId: string,
  request: IActivateCardRequest
): Promise<ICardResponse> => {
  const { data } = await cardsService.post(`/${cardId}/activate`, request);

  return data;
};
