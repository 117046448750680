import { InvoiceStatus } from "@wingspanhq/payments/dist/interfaces";
import { IInvoiceRow } from "../../service";

export const selectorIsInvoiceDeposited = (invoice: IInvoiceRow) => {
  if (invoice.oldStatus === InvoiceStatus.Paid) {
    if (invoice.amountDetails) {
      return !!invoice.events.depositedAt || !!invoice.events.instantPayoutAt;
    }

    return true;
  }

  return false;
};
