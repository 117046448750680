import { IActivity } from "@wingspanhq/users/dist/lib/interfaces";
import { WSStore } from "../../store";
import { GrowthSourceNames } from "../../utils/growthAttribution";

export const selectorPartnerId = (store: WSStore, activity?: IActivity) => {
  if (
    store.growthAttributionDetails?.growthSource === GrowthSourceNames.Partner
  ) {
    return store.growthAttributionDetails?.growthName;
  }

  if (activity?.context?.growthSource === GrowthSourceNames.Partner) {
    return activity.context?.growthSourceName;
  }
};
