import {
  WSControl,
  WSElement,
  WSForm,
  WSInfoBox,
  WSList,
  WSMarginProps,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";

export interface FormCanadaTaxPayerCertificationConsentData {
  canadaTaxInfoConsent: boolean;
  shareCanadaTaxInfo: boolean;
}

interface FormCanadaTaxPayerCertificationConsentProps extends WSMarginProps {
  payerNames: string[];
}

export const getValidationSchemaFormCanadaTaxPayerCertificationConsent = (
  payerNames: string[]
) => {
  return Yup.object().shape({
    canadaTaxInfoConsent: Yup.boolean().oneOf(
      [true],
      "You must certify the information is accurate"
    ),
    shareCanadaTaxInfo:
      payerNames.length > 0
        ? Yup.boolean().oneOf(
            [true],
            "You must authorize Wingspan to share the tax information with your payer(s)"
          )
        : Yup.boolean()
  });
};

export const FormCanadaTaxPayerCertificationConsent: React.FC<FormCanadaTaxPayerCertificationConsentProps> = ({
  payerNames
}) => {
  return (
    <WSPanel>
      <WSList gap="2XL">
        <WSElement>
          <WSText.Paragraph weight="medium" color="gray600" mb="M">
            Certify your taxpayer information is accurate
          </WSText.Paragraph>
          <WSText.ParagraphSm weight="book" color="gray600">
            By using Wingspan’s services you agree that the information provided
            is accurate and that you certify the provided information as
            follows:
          </WSText.ParagraphSm>
        </WSElement>
        <WSInfoBox my="2XL">
          <p>By submitting this form, I certify that:</p>
          <ol>
            <li>
              The information provided on this form is true, correct, and
              complete to the best of my knowledge and belief;
            </li>
            <li>
              The number provided on this form is my correct Canadian Social
              Insurance Number (SIN) as an individual, or Canadian Business
              Number (BN) as a business entity;
            </li>
            <li>I am a Canadian resident for tax purposes;</li>

            <li>
              I am the beneficial owner (or am authorized to sign for the
              beneficial owner) of all the income to which this form relates;
            </li>
            <li>
              The income to which this form relates is business or professional
              income earned as an independent contractor, not as an employee;
            </li>
            <li>
              I understand that this information may be shared with the Canada
              Revenue Agency (CRA) and/or the Internal Revenue Service (IRS) for
              tax reporting and verification purposes;
            </li>

            <li>
              I agree to notify the payer immediately of any changes that would
              render the information on this form incorrect;
            </li>
            <li>
              I understand that providing false or misleading information may
              result in penalties under Canadian and/or U.S. tax laws;
            </li>
            <li>
              I confirm that I have the authority to sign this form either as
              the individual contractor or on behalf of the business entity.
            </li>
          </ol>
        </WSInfoBox>

        <WSList gap="M">
          <WSForm.Field
            name="canadaTaxInfoConsent"
            component={WSControl}
            componentProps={{
              type: "checkbox",
              label:
                "By providing the information on this form, I hereby certify, to the best of my knowledge, that the information provided is complete and correct.",
              size: "S"
            }}
          />

          {payerNames.length > 0 && (
            // TODO: implement edit payers list
            <WSForm.Field
              name="shareCanadaTaxInfo"
              component={WSControl}
              componentProps={{
                type: "checkbox",
                label: `I authorize Wingspan to share the tax information I've provided with my payer(s) on the Wingspan platform (${payerNames.join(
                  ", "
                )}). I understand this information will be used for tax reporting purposes in accordance with applicable laws and regulations.`,
                size: "S"
              }}
            />
          )}
        </WSList>
      </WSList>
    </WSPanel>
  );
};
