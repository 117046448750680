import { useUserProfileData } from "./utils/useUserProfileData";
import { isUserIdsIncluded } from "./utils/isUserIdsIncluded";
import { HIDE_PAYABLES_AND_PAYROLL_NAV_IDS } from "./helpers";

export const useShowPayeableAndPayrollSettingsNav = () => {
  const userData = useUserProfileData();

  const showPayeableAndPayrollSettingsNav = !isUserIdsIncluded(
    userData,
    HIDE_PAYABLES_AND_PAYROLL_NAV_IDS
  );

  return showPayeableAndPayrollSettingsNav;
};
