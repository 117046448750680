import { Industry } from "../types";

export const insdustryLabelMap: Record<Industry, string> = {
  AgricultureForestryFishingOrHunting:
    "Agriculture, Forestry, Fishing, Hunting",
  ArtsEntertainmentAndRecreation: "Arts, Entertainment, Recreation",
  BusinessSupportOrBuildingServices: "Business Support, Building Services",
  Cannabis: "Cannabis",
  Construction: "Construction",
  DirectMarketingOrTelemarketing: "Direct Marketing, Telemarketing",
  EducationalServices: "Educational Services",
  FinancialServices: "Financial Services",
  GamingOrGambling: "Gaming, Gambling",
  HealthCareAndSocialAssistance: "Health Care, Social Assistance",
  HospitalityAccommodationOrFoodServices:
    "Hospitality, Accommodation, FoodServices",
  LegalAccountingConsultingOrComputerProgramming:
    "Legal, Accounting, Computer Programming, Other Consulting",
  Manufacturing: "Manufacturing",
  Mining: "Mining",
  Nutraceuticals: "Nutraceuticals",
  PersonalCareServices: "Personal Care Services",
  PublicAdministration: "Public Administration",
  RealEstate: "Real Estate",
  ReligiousCivicAndSocialOrganizations:
    "Religious, Civic, Social Organizations",
  RepairAndMaintenance: "Repair, Maintenance",
  RetailTrade: "Retail",
  TechnologyMediaOrTelecom: "Technology, Media, Telecom",
  TransportationOrWarehousing: "Transportation, Warehousing",
  Utilities: "Utilities",
  WholesaleTrade: "Wholesale Trade",
  MatureEntertainmentDatingOrEscortServices: "Adult Entertainment, Dating"
};

export const DEFAULT_PAYER_INDUSTRY: Industry =
  "LegalAccountingConsultingOrComputerProgramming";

export const industrySelectOptions = Object.entries(
  insdustryLabelMap
).map(([value, label]) => ({ value, label }));
