import {
  WSButton,
  WSButtons,
  WSDivider,
  WSElement,
  WSFormOld,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory, useRouteMatch } from "react-router-dom";
import React from "react";

import { SetupStartNewCorrectionFlowProps } from ".";
import { usePayerTaxForm } from "../../../../../query/taxForm/queries/usePayerTaxForm";
import { useUserId } from "../../../../../query/hooks/helpers";
import { EditLayout } from "../../../components/EditLayout";
import { PreventLeave } from "../../../../../components/PreventLeave/PreventLeave";
import { WSQueries } from "../../../../../query/WSQuery";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";
import { AmountCorrectionForm } from "../../../../TaxDocuments/components/AmountCorrectionForm";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../../TaxDocuments/constants/corrections";
import { TINOrNameCorrectionForm } from "../../../../TaxDocuments/components/TINOrNameCorrectionForm";
import { AddressCorrectionForm } from "../../../../TaxDocuments/components/AddressCorrectionForm";
import { CURRENT_YEAR } from "../../../constants/currentYear";
import { useCancelCorrectionModal } from "../../../../TaxDocuments/components/CorrectionFormActions";
import {
  getDefaultValuesForCorrectionsForm,
  getPayeeFormW9Address,
  getPayeeTINorName
} from "../../../../TaxDocuments/routes/RequestCorrectionFlow/getDefaultValuesForCorrectionsForm";
import { getCorrectionType } from "../../../../TaxDocuments/utils/getCorrectionType";
import { TaxFormViewer } from "../../../../TaxDocuments/types";
import { getFormValidationSchema } from "../../../../TaxDocuments/routes/RequestCorrectionFlow/getFormValidationSchema";
import uniq from "lodash/uniq";
import { TinType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { usePayerForceSubmitTaxFormWarningModal } from "../../../components/modals/usePayerForceSubmitTaxFormWarningModal";
import { usePayerVoidOriginalTaxFormWarningModal } from "../../../components/modals/usePayerVoidOriginalTaxFormWarningModal";

export const RouteAddCorrectionValues: React.FC<SetupStartNewCorrectionFlowProps> = ({
  onNext
}) => {
  const history = useHistory<{
    isEdit: boolean;
    corrections: string[];
    correctionsFormData: any;
  }>();
  const match = useRouteMatch<{ taxFormId: string }>();
  const { taxFormId } = match.params;
  const queryTaxForm = usePayerTaxForm(taxFormId);

  const isEdit = history.location.state?.isEdit;
  const corrections = uniq(history.location.state?.corrections ?? []);
  const correctionsFormData = history.location.state?.correctionsFormData;

  const userId = useUserId();
  const cancelCorrectionModal = useCancelCorrectionModal();
  const payerVoidOriginalTaxFormWarningModal = usePayerVoidOriginalTaxFormWarningModal();
  const payerForceSubmitTaxFormWarningModal = usePayerForceSubmitTaxFormWarningModal();

  const onCancel = () => {
    history.push(`/member/1099-filing/details/${taxFormId}`);
  };

  const confirmCancel = () => {
    cancelCorrectionModal.open({
      onCancel
    });
  };

  const onFormSubmit = (data: any, options = { isForce: 0 }) => {
    onNext({
      corrections,
      correctionsFormData: data,
      isForce: options.isForce
    });
  };
  return (
    <EditLayout title="Start new correction">
      <WSQueries queries={{ queryTaxForm }}>
        {({ queryTaxFormData: taxForm }) => {
          const recipientName = selectorTaxFormRecipientName(taxForm);
          const originalTinData = getPayeeTINorName(taxForm);
          const correctionTypeToFormComponentMap: Record<
            string,
            JSX.Element
          > = {
            [CORRECTION_AMOUNT]: (
              <AmountCorrectionForm
                taxForm={taxForm}
                taxFormViewer={TaxFormViewer.Payer}
                amount={taxForm.data?.totalAmount || 0}
                recipientName={recipientName}
              />
            ),
            [CORRECTION_TIN_OR_NAME]: (
              <TINOrNameCorrectionForm
                taxForm={taxForm}
                taxFormViewer={TaxFormViewer.Payer}
                originalTinData={originalTinData}
                identificationNumberType={
                  originalTinData?.ein ? TinType.Business : TinType.Individual
                }
                showRequestedValue={false}
              />
            ),
            [CORRECTION_ADDRESS]: (
              <AddressCorrectionForm
                taxForm={taxForm}
                taxFormViewer={TaxFormViewer.Payer}
                originalAddress={getPayeeFormW9Address(taxForm)}
              />
            )
          };
          return (
            <>
              <WSText.Heading2 mb="L">
                {recipientName} {CURRENT_YEAR} Form 1099-NEC
              </WSText.Heading2>
              <WSFormOld
                defaultValues={
                  isEdit
                    ? correctionsFormData
                    : getDefaultValuesForCorrectionsForm(corrections, taxForm)
                }
                validationSchema={getFormValidationSchema(
                  corrections,
                  !!false,
                  TaxFormViewer.Payer
                )}
              >
                {corrections.map((correctionType, index) => {
                  const FormComponent =
                    correctionTypeToFormComponentMap[correctionType];
                  const isLast = index === corrections.length - 1;
                  return (
                    <WSElement mb="L" key={correctionType}>
                      {FormComponent}
                      {isLast ? null : <WSDivider my="XL" />}
                    </WSElement>
                  );
                })}
                <WSFormOld.Values
                  names={[
                    "necTotalAmount",
                    "amountCorrectionHelpText",
                    "identificationNumberType",
                    "ssn",
                    "ein",
                    "firstName",
                    "lastName",
                    "businessName",
                    "disregardedEntityName",
                    "taxClassification",
                    "reasonForChange",
                    "otherReason",
                    "formW9Address"
                  ]}
                >
                  {formData => {
                    const {
                      corrections,
                      isVoidType,
                      isCompanyStructureChangeOnly
                    } = getCorrectionType(taxForm, formData);

                    return (
                      <>
                        <PreventLeave isEnabled={corrections.length > 0} />
                        <WSButtons forceFullWidth>
                          {isVoidType ? (
                            <WSButton.Primary
                              type="button"
                              onClick={() =>
                                payerVoidOriginalTaxFormWarningModal.open({
                                  onContinue: () =>
                                    onFormSubmit(formData, { isForce: 0 })
                                })
                              }
                            >
                              Continue: void original filing
                            </WSButton.Primary>
                          ) : null}

                          {isVoidType && !isCompanyStructureChangeOnly ? (
                            <WSButton.Secondary
                              type="button"
                              onClick={() =>
                                payerForceSubmitTaxFormWarningModal.open({
                                  onContinue: () =>
                                    onFormSubmit(formData, { isForce: 1 })
                                })
                              }
                            >
                              Continue: submit as a correction anyway
                            </WSButton.Secondary>
                          ) : null}

                          {!isVoidType && (
                            <WSButton.Primary
                              type="button"
                              onClick={() => onFormSubmit(formData)}
                              disabled={!corrections.length}
                            >
                              Continue
                            </WSButton.Primary>
                          )}

                          <WSButton.Tertiary
                            type="button"
                            onClick={confirmCancel}
                          >
                            Cancel
                          </WSButton.Tertiary>
                        </WSButtons>
                      </>
                    );
                  }}
                </WSFormOld.Values>
              </WSFormOld>
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
