import { useMemo } from "react";
import { WSFilter, WSFilterValues, WSFiltersProps } from "./types";

function getFilters<R>(
  filters: R | ((values: WSFilterValues) => R),
  values: WSFilterValues
): NonNullable<R> {
  return (
    (typeof filters === "function" ? (filters as Function)(values) : filters) ||
    []
  );
}

function getButtonText(
  button:
    | WSFiltersProps["primaryFiltersButton"]
    | WSFiltersProps["secondaryFiltersButton"],
  values: WSFilterValues,
  filters: WSFilter[],
  defaultText: string
): string {
  return (
    (typeof button === "function"
      ? (button as Function)(values, filters)
      : button) || defaultText
  );
}

export const useInternalState = ({
  _primaryFilters,
  _secondaryFilters,
  _quickFilters,
  primaryFiltersButton,
  secondaryFiltersButton,
  values
}: {
  _primaryFilters: WSFiltersProps["primaryFilters"];
  _secondaryFilters: WSFiltersProps["secondaryFilters"];
  _quickFilters: WSFiltersProps["quickFilters"];
  primaryFiltersButton: WSFiltersProps["primaryFiltersButton"];
  secondaryFiltersButton: WSFiltersProps["secondaryFiltersButton"];
  values: WSFiltersProps["values"];
}) => {
  const primaryFilters = useMemo(() => getFilters(_primaryFilters, values), [
    _primaryFilters,
    values
  ]);

  const secondaryFilters = useMemo(
    () => getFilters(_secondaryFilters, values),
    [_secondaryFilters, values]
  );

  const quickFilters = useMemo(() => getFilters(_quickFilters, values), [
    _quickFilters,
    values
  ]);

  const mergedFilters = useMemo(() => {
    return [...primaryFilters, ...secondaryFilters];
  }, [primaryFilters, secondaryFilters]);

  const primaryButton = useMemo(
    () =>
      getButtonText(primaryFiltersButton, values, primaryFilters, "Filters"),
    [primaryFiltersButton, values, primaryFilters]
  );

  const secondaryButton = useMemo(
    () =>
      getButtonText(secondaryFiltersButton, values, secondaryFilters, "Select"),
    [secondaryFiltersButton, values, secondaryFilters]
  );

  return {
    primaryFilters,
    secondaryFilters,
    quickFilters,
    primaryButton,
    secondaryButton,
    mergedFilters
  } as const;
};
