import React, { ReactElement } from "react";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSContainer } from "../WSContainer/WSContainer.component";
import { WSFlexBox } from "../WSFlexBox/WSFlexBox.component";
import {
  WSButton,
  WSButtonProps
} from "../../core/WSButton/WSButton.component";
import { WSText } from "../../core/WSText/WSText.component";
import { useIsMobile, WSScreen } from "../WSScreen/WSScreen.component";
import { WSIcon, WSIconProps } from "../../core/WSIcon/WSIcon.component";
import styles from "./WSPage.module.scss";
import classNames from "classnames";
import { WSPill, WSPillProps } from "../../common/WSPill/WSPill.component";

export interface WSPageButtonProps {
  label: string;
  name?: string;
  disabled?: boolean;
  blocked?: boolean;
  loading?: boolean;
  link?: string;
  linkTarget?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
export interface WSPageBreadcrumbProps {
  label: string;
  icon?: WSIconProps["name"];
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export interface WSPageProps {
  title?: React.ReactNode;
  breadcrumb?: WSPageBreadcrumbProps;
  secondaryButton?: WSPageButtonProps;
  secondaryButtonComponent?: React.FC<WSElementProps & { fullWidth?: boolean }>;
  primaryButton?: WSPageButtonProps;
  primaryButtonComponent?: React.FC<WSElementProps & { fullWidth?: boolean }>;
  headerContent?: ReactElement;
  sideContent?: React.ReactNode;
  pills?: WSPillProps[];
}

const SecondaryButton = (
  button: WSPageButtonProps & { isMobile: boolean; type?: string }
) => (
  <WSButton.Secondary
    mr={button.isMobile ? "NONE" : "M"}
    mb={button.isMobile ? "XL" : "NONE"}
    disabled={button.disabled}
    fullWidth={button.isMobile}
    loading={button.loading}
    onClick={button.onClick}
    type={button.type}
  >
    {button.label}
  </WSButton.Secondary>
);

const PrimaryButton = (
  button: WSPageButtonProps & { isMobile: boolean; type?: string }
) => (
  <WSButton.Primary
    mb={button.isMobile ? "M" : "NONE"}
    disabled={button.disabled}
    fullWidth={button.isMobile}
    loading={button.loading}
    onClick={button.onClick}
    type={button.type}
  >
    {button.label}
  </WSButton.Primary>
);

export const WSPage: React.FC<WSPageProps> = ({
  children,
  title,
  breadcrumb,
  secondaryButton,
  secondaryButtonComponent: CustomSeoncdaryButton,
  primaryButton,
  primaryButtonComponent: CustomPrimaryButton,
  headerContent,
  pills,
  sideContent
}) => {
  const isMobile = useIsMobile();

  const SecondaryButtonElement = () => (
    <>
      {secondaryButton && secondaryButton.link ? (
        <SecondaryButton
          onClick={() => {
            window.open(secondaryButton.link, secondaryButton.linkTarget);
          }}
          isMobile={isMobile}
          type="button"
          {...secondaryButton}
        />
      ) : (
        secondaryButton && (
          <SecondaryButton isMobile={isMobile} {...secondaryButton} />
        )
      )}
    </>
  );

  const PrimaryButtonElement = () => (
    <>
      {primaryButton && primaryButton.link ? (
        <PrimaryButton
          onClick={() => {
            window.open(primaryButton.link, primaryButton.linkTarget);
          }}
          isMobile={isMobile}
          type="button"
          {...primaryButton}
        />
      ) : (
        primaryButton && (
          <PrimaryButton isMobile={isMobile} {...primaryButton} />
        )
      )}
    </>
  );

  const hasBadges = pills && pills.length > 0;

  const hasButtons =
    secondaryButton ||
    primaryButton ||
    CustomPrimaryButton ||
    CustomSeoncdaryButton;

  return (
    <WSElement
      className={
        isMobile
          ? undefined
          : classNames(styles.pageContainer, {
              [styles.breadCrumb]: breadcrumb
            })
      }
      mt={isMobile ? "XL" : undefined}
    >
      <WSContainer>
        <WSScreen.Mobile>
          {breadcrumb ? (
            <WSFlexBox.CenterY onClick={breadcrumb.onClick} mb="XS">
              {breadcrumb.icon ? (
                <WSIcon block name={breadcrumb.icon} color="gray500" mr="M" />
              ) : null}
              <WSText color="gray500">{breadcrumb.label}</WSText>
            </WSFlexBox.CenterY>
          ) : null}
          {(title || headerContent || sideContent) && (
            <WSFlexBox.CenterY mb="M" justify="space-between" wrap="nowrap">
              <WSFlexBox.CenterY wrap="nowrap">
                {title && <WSText.Heading1>{title}</WSText.Heading1>}
                {hasBadges &&
                  pills &&
                  pills.map((badge, index) => (
                    <WSPill
                      key={`${badge.text} ${index}`}
                      {...badge}
                      ml={title ? "M" : "NONE"}
                    />
                  ))}
                {headerContent && (
                  <WSElement ml={title || hasBadges ? "M" : "NONE"}>
                    {headerContent}
                  </WSElement>
                )}
              </WSFlexBox.CenterY>
              {sideContent}
            </WSFlexBox.CenterY>
          )}
          {CustomSeoncdaryButton ? (
            <CustomSeoncdaryButton
              mr={isMobile ? "NONE" : "M"}
              mb={isMobile ? "M" : "NONE"}
              fullWidth={isMobile}
            />
          ) : (
            secondaryButton && <SecondaryButtonElement />
          )}
          {CustomPrimaryButton ? (
            <CustomPrimaryButton
              mb={isMobile ? "M" : "NONE"}
              fullWidth={isMobile}
            />
          ) : (
            primaryButton && <PrimaryButtonElement />
          )}
        </WSScreen.Mobile>
        <WSScreen.TabletAndDesktop>
          {breadcrumb ? (
            <WSFlexBox.CenterY onClick={breadcrumb.onClick}>
              {breadcrumb.icon ? (
                <WSIcon block name={breadcrumb.icon} color="gray500" mr="M" />
              ) : null}
              <WSText color="gray500">{breadcrumb.label}</WSText>
            </WSFlexBox.CenterY>
          ) : null}
          {(title || hasButtons || headerContent || sideContent) && (
            <WSFlexBox.CenterY mb="XL" justify="space-between" wrap="nowrap">
              <WSFlexBox.CenterY wrap="nowrap">
                {title && <WSText.Heading1>{title}</WSText.Heading1>}
                {hasBadges && pills && (
                  <WSFlexBox.CenterY wrap="nowrap">
                    {pills.map((pill, index) => (
                      <WSPill
                        key={`${pill.text} ${index}`}
                        {...pill}
                        ml={(index === 0 && title) || index > 0 ? "M" : "NONE"}
                      />
                    ))}
                  </WSFlexBox.CenterY>
                )}
                {headerContent && (
                  <WSElement ml={title || hasBadges ? "M" : "NONE"}>
                    {headerContent}
                  </WSElement>
                )}
              </WSFlexBox.CenterY>
              {sideContent}
              {hasButtons && (
                <WSFlexBox.CenterY
                  ml={title || hasBadges || headerContent ? "M" : "NONE"}
                  wrap="nowrap"
                  alignItems="stretch"
                >
                  {CustomSeoncdaryButton ? (
                    <CustomSeoncdaryButton
                      mr={isMobile ? "NONE" : "M"}
                      mb={isMobile ? "M" : "NONE"}
                      fullWidth={isMobile}
                    />
                  ) : (
                    <SecondaryButtonElement />
                  )}
                  {CustomPrimaryButton ? (
                    <CustomPrimaryButton
                      mb={isMobile ? "M" : "NONE"}
                      fullWidth={isMobile}
                    />
                  ) : (
                    <PrimaryButtonElement />
                  )}
                </WSFlexBox.CenterY>
              )}
            </WSFlexBox.CenterY>
          )}
        </WSScreen.TabletAndDesktop>

        {children}
      </WSContainer>
    </WSElement>
  );
};
