import {
  WSElement,
  WSElementProps,
  WSPanel,
  WSSectionToolbar
} from "@wingspanhq/fe-component-library";
import { IntegrationResourceType } from "./types";
import {
  IIntegrationAccountResponse,
  IIntegrationCustomer,
  IIntegrationItemResponse,
  IIntegrationVendor
} from "@wingspanhq/integrations/dist/lib/interfaces";
import { InfoItem } from "../ClientInfoPanel/InfoItem";
import { QuickbooksHistory } from "../../../../Invoices/components/QuickbooksHistory/QuickbooksHistory";
import { getIntegrationUserSelectLabel } from "../../../Integrations/utils/getIntegrationUserSelectLabel";
import { useEditPayeeIntegrationModal } from "./EditPayeeIntegrationModal";
import { useEditPayerIntegrationModal } from "./EditPayerIntegrationModal";

export interface IntegrationsSectionProps extends WSElementProps {
  resourceType: IntegrationResourceType;
  isInactive: boolean;
  payerIntegration?: {
    payerId: string;
    engagementId?: string;
    customer?: IIntegrationCustomer;
    item?: IIntegrationItemResponse;
    defaultItem?: IIntegrationItemResponse;
  };
  payeeIntegration?: {
    payeeId: string;
    engagementId?: string;
    vendor?: IIntegrationVendor;
    expenseAccount?: IIntegrationAccountResponse;
    defaultAccount?: IIntegrationAccountResponse;
  };
}

export const IntegrationsSection: React.FC<IntegrationsSectionProps> = ({
  resourceType,
  isInactive,
  payerIntegration,
  payeeIntegration,
  ...elementProps
}) => {
  const updatePayeeModal = useEditPayeeIntegrationModal();
  const updatePayerModal = useEditPayerIntegrationModal();

  const onEdit = () => {
    if (resourceType === IntegrationResourceType.Payer) {
      updatePayerModal.open({
        payerId: payerIntegration?.payerId!,
        engagementId: payerIntegration?.engagementId,
        itemId: payerIntegration?.item?.itemId!,
        customerId: payerIntegration?.customer?.customerId!
      });
    } else {
      updatePayeeModal.open({
        payeeId: payeeIntegration?.payeeId!,
        engagementId: payeeIntegration?.engagementId,
        vendorId: payeeIntegration?.vendor?.vendorId,
        expenseAccountId: payeeIntegration?.expenseAccount?.accountId
      });
    }
  };

  return (
    <WSElement {...elementProps}>
      <WSSectionToolbar
        title="Integration mappings"
        button={{
          label: "Edit",
          onClick: onEdit,
          disabled: isInactive
        }}
      />

      <WSPanel>
        <InfoItem
          mb="XL"
          label={
            resourceType === IntegrationResourceType.Payer
              ? "QBO Customer"
              : "QBO Vendor"
          }
          value={
            resourceType === IntegrationResourceType.Payer
              ? payerIntegration?.customer
                ? getIntegrationUserSelectLabel(
                    payerIntegration?.customer as IIntegrationCustomer
                  )
                : "Not mapped"
              : payeeIntegration?.vendor
              ? getIntegrationUserSelectLabel(
                  payeeIntegration?.vendor as IIntegrationVendor
                )
              : "Not mapped"
          }
        />

        <InfoItem
          label={
            resourceType === IntegrationResourceType.Payer
              ? "Default QBO Item"
              : "Default QBO Expense Account"
          }
          value={
            resourceType === IntegrationResourceType.Payer
              ? payerIntegration?.item
                ? payerIntegration?.item.fullyQualifiedName
                : `Not mapped, use default "${payerIntegration?.defaultItem?.fullyQualifiedName}"`
              : payeeIntegration?.expenseAccount
              ? payeeIntegration?.expenseAccount?.fullyQualifiedName
              : `Not mapped, use default "${payeeIntegration?.defaultAccount?.fullyQualifiedName}"`
          }
        />
      </WSPanel>

      <QuickbooksHistory
        Wrapper={WSPanel}
        noBorder
        entityId={
          (resourceType === IntegrationResourceType.Payer
            ? payerIntegration?.payerId
            : payeeIntegration?.payeeId) as string
        }
      />
    </WSElement>
  );
};
