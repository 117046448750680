import React from "react";
import {
  useWSModal,
  WSActions,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";

export const NotYourEmailPayer: React.FC<{
  email: string;
  isSignIn?: boolean;
  onClose(isNotMe: boolean): void;
}> = ({ onClose, email, isSignIn }) => {
  const history = useHistory();

  return (
    <>
      <WSText.ParagraphSm mb="L" weight="book">
        By updating your email now, your new account will not be associated with
        this company. You should do this only if you do not recognize the email
        address shown on this page. If you would like to update your email
        address, you’ll have the opportunity to do this later.
      </WSText.ParagraphSm>

      <WSActions
        orientation="vertical"
        buttons={[
          {
            label: "Use a different email",
            onClick: () => {
              onClose(true);
              history.push("/member/sign-up");
            },
            kind: "Secondary"
          },
          {
            label: `Sign ${isSignIn ? "in" : "up"} with: ${email}`,
            onClick: async () => {
              onClose(false);
            }
          }
        ]}
      />
    </>
  );
};

export const useNotYourEmailPayer = () =>
  useWSModal(NotYourEmailPayer, {
    title: "Not you?",
    size: "S"
  });
