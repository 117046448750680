import React, { useMemo } from "react";
import { toCamelCase } from "../../../../utils";
import {
  detachLayoutProps,
  WSElement,
  WSElementProps
} from "../../WSElement/WSElement.component";
import { WSCheckbox } from "./WSCheckbox.component";

import styles from "./WSCheckbox.module.scss";

export interface CheckboxOption {
  value: any;
  label: React.ReactNode;
  disabled?: boolean;
}

export interface WSCheckboxGroupProps extends WSElementProps {
  name: string;
  value: any;
  options: CheckboxOption[];
}

export const WSCheckboxGroup: React.FC<WSCheckboxGroupProps> = (props) => {
  const {
    options,
    value: selectedValues = [],
    name,
    layoutProps,
    systemProps,
    onChange,
    ...other
  } = detachLayoutProps(props);

  const mapSelecting = useMemo(
    () => (checkedOption: string[] | string) => {
      // NOTE: handle a special case "All" option with the value "ALL"
      const newValues = selectedValues?.includes(checkedOption)
        ? selectedValues?.filter((value: any) => value !== checkedOption)
        : checkedOption === "ALL"
        ? ["ALL"]
        : [...(selectedValues ?? []), checkedOption].filter(
            (value: any) => value !== "ALL"
          );

      return newValues;
    },
    [selectedValues]
  );

  return (
    <WSElement
      className={styles.checkboxGroup}
      tabIndex={0}
      data-testid={toCamelCase(name, "field")}
      {...layoutProps}
    >
      {options.map((option) => (
        <WSCheckbox
          {...other}
          onChange={() => onChange?.(mapSelecting(option.value))}
          key={`WSCheckboxGroup-${option.value}`}
          checked={selectedValues.includes(option.value)}
          value={option.value}
          name={name}
          label={option.label}
          disabled={!!option.disabled}
        />
      ))}
    </WSElement>
  );
};
