import React from "react";
import { useBrowserPageTitle } from "../../shared/hooks/useBrowserPageTitle";
import { Redirect, Route, Switch } from "react-router-dom";
import { RouteCreateRequirement } from "./routes/RouteCreateRequirement";
import { RouteRequirements } from "./routes/RouteRequirements";
import { RouteRequirementDetails } from "./routes/RouteRequirementDetails";

export const ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH =
  "/member/settings/engagement-requirements";

export const EngagementRequirementsSettingsIndex: React.FC = () => {
  useBrowserPageTitle("Engagement Requirements Settings");

  return (
    <Switch>
      <Route
        path={`${ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH}/create`}
        component={RouteCreateRequirement}
        exact
      />

      <Route
        path={`${ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH}/:requirementId`}
        component={RouteRequirementDetails}
      />

      <Route
        path={ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH}
        component={RouteRequirements}
      />

      <Redirect from="*" to={ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH} />
    </Switch>
  );
};
