import {
  WSElement,
  WSGrid,
  WSIcon,
  WSPanel,
  WSText,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import { useMemo } from "react";
import { useBankingBalance } from "../../../../query/bookkeeping/queries";
import { useWithholdingBalance } from "../../../../query/taxes/queries";
import { TransferBalance } from "../../types";
import { TransferSchema } from "./TransferSchema";
import styles from "./index.module.scss";

type Props = {
  balance: TransferBalance;
  onDeposit: () => void;
  onWithdrawal: () => void;
};

export const FormType: React.FC<Props> = ({
  balance,
  onDeposit,
  onWithdrawal
}) => {
  const balanceName = useMemo(
    () => (balance === "wallet" ? "Wingspan Wallet" : "Tax Withholdings"),
    [balance]
  );
  const queryBankingBalanceBalance = useBankingBalance();
  const queryWithholdingBalance = useWithholdingBalance();

  const bankingBalance = queryBankingBalanceBalance.data
    ? queryBankingBalanceBalance.data.balance
    : undefined;
  const taxWithholdingsBalance = queryWithholdingBalance.data
    ? queryWithholdingBalance.data.balance
    : undefined;

  const balanceTotal =
    balance === "wallet" ? bankingBalance : taxWithholdingsBalance;

  const isDepositDisabled =
    balanceTotal !== undefined ? balanceTotal <= 0 : false;

  return (
    <>
      <WSText.Heading4 align="center" mb="XS">
        Transfer balance
      </WSText.Heading4>
      <WSText color="gray500" align="center" mb="2XL">
        Where would you like to send your funds?
      </WSText>
      <WSGrid gutter="M">
        <WSGrid.Item span={{ s: "6" }}>
          <WSPanel
            onClick={isDepositDisabled ? undefined : onWithdrawal}
            className={classNames({ [styles.disabled]: isDepositDisabled })}
          >
            <WSText.Heading5 mb="M">Transfer to bank</WSText.Heading5>
            <WSText color="gray500">
              Send funds from your {balanceName} to your personal bank account.
              <br />
              (1 business day)
            </WSText>

            <TransferSchema type="withdrawal" mt="XL" />
          </WSPanel>

          {isDepositDisabled && (
            <WSElement mt="XL" className={styles.info}>
              <WSIcon block name="info-circle" color="gray500" mr="XL" />
              <WSText color="gray500">
                There is no balance ({toWSMoneyString(balanceTotal)}) to
                transfer from your {balanceName}
              </WSText>
            </WSElement>
          )}
        </WSGrid.Item>
        <WSGrid.Item span={{ s: "6" }}>
          <WSPanel onClick={onDeposit}>
            <WSText.Heading5 mb="M">Transfer to {balanceName}</WSText.Heading5>
            <WSText color="gray500">
              Send funds from your bank account to your {balanceName}.<br />
              (3-5 business days)
            </WSText>

            <TransferSchema type="deposit" mt="XL" />
          </WSPanel>
        </WSGrid.Item>
      </WSGrid>
    </>
  );
};
