import {
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSText,
  useModalContext
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WS_LINKS } from "../../../types/wsLinks";
import { openInNewTab } from "../../../utils/openInNewTab";
import { MODAL_INFO, ModalInfo } from "./ModalInfo";

type Props = WSElementProps;

export const Banner: React.FC<Props> = ({ ...elementProps }) => {
  const { openModal } = useModalContext();

  return (
    <WSFlexBox.CenterY wrap="nowrap" {...elementProps}>
      <WSText
        onClick={() => {
          openInNewTab(WS_LINKS.security);
        }}
      >
        Wingspan is PCI Certified Secure.
      </WSText>
      <WSIcon
        block
        ml="XS"
        name="info-circle"
        color="gray500"
        onClick={() => openModal(MODAL_INFO)}
      />
      <ModalInfo />
    </WSFlexBox.CenterY>
  );
};
