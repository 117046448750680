import {
  toWSDateString,
  WSButton,
  WSMessageBox
} from "@wingspanhq/fe-component-library";
import {
  ClientWorkFlowStatus,
  ICollaboratorSchema,
  InvoiceStatus,
  IPayableSchema
} from "@wingspanhq/payments/dist/interfaces";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCollaboratorsQuery } from "../../../query/payments/queries";
import { paymentsService } from "../../../services/payments";

interface ExistingPaybleBannerProps {
  collaboratorEmail: string;
}
export const ExistingPayableBanner: React.FC<ExistingPaybleBannerProps> = ({
  collaboratorEmail
}) => {
  const [existingPayable, setExistingPayable] = useState<IPayableSchema | null>(
    null
  );
  const [
    collaboratorForExistingPayable,
    setCollaboratorForExistingPayable
  ] = useState<ICollaboratorSchema | null>(null);

  const collaboratorsQuery = useCollaboratorsQuery();
  const history = useHistory();

  useEffect(() => {
    const getExistingPayablesForCollaboratorEmail = async () => {
      const collaborators = collaboratorsQuery.data || [];
      const matchingCollaborator = collaborators.find(
        memberClient => memberClient.member.user.email === collaboratorEmail
      );
      if (matchingCollaborator) {
        let existingPayables: IPayableSchema[] = [];
        try {
          existingPayables = (
            await paymentsService.payable.list({
              filter: {
                memberClientId: {
                  in: [matchingCollaborator.collaboratorId]
                },
                status: {
                  in: [
                    InvoiceStatus.Draft,
                    InvoiceStatus.Open,
                    InvoiceStatus.Pending
                  ]
                },
                "client.workflowStatus": {
                  "!=": ClientWorkFlowStatus.Declined
                }
              }
            })
          ).data;

          if (existingPayables.length > 0) {
            // TODO(Anesh): implement picking strategy if there are more than 1 payable
            setExistingPayable(existingPayables[0]);
            const collaborator = await paymentsService.collaborator.get(
              existingPayables[0].collaboratorId
            );
            setCollaboratorForExistingPayable(collaborator);
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        setCollaboratorForExistingPayable(null);
        setExistingPayable(null);
      }
    };
    getExistingPayablesForCollaboratorEmail();
  }, [collaboratorEmail]);

  const getCollaboratorDisplayName = (collaborator: ICollaboratorSchema) => {
    if (
      collaborator.member.user.profile?.firstName &&
      collaborator.member.user.profile.lastName
    ) {
      return `${collaborator.member.user.profile?.firstName} ${collaborator.member.user.profile?.lastName}`;
    } else if (collaborator.member.profile?.company?.name) {
      return collaborator.member.profile?.company?.name;
    }
    return collaborator.member.user.email;
  };

  const payableStatusToSentenceMap = {
    [InvoiceStatus.Cancelled]: "A cancelled",
    [InvoiceStatus.Draft]: "A draft",
    [InvoiceStatus.Open]: "An open",
    [InvoiceStatus.Overdue]: "An overdue",
    [InvoiceStatus.Paid]: "A paid",
    [InvoiceStatus.PaymentInTransit]: "A payment in transit",
    [InvoiceStatus.Pending]: "A pending"
  };

  return existingPayable && collaboratorForExistingPayable ? (
    <WSMessageBox kind="Warning" mb="XL">
      {payableStatusToSentenceMap[existingPayable.status]} payable exists to{" "}
      {getCollaboratorDisplayName(collaboratorForExistingPayable)} due{" "}
      {toWSDateString(existingPayable.dueDate, "monthDayYear")}.
      <WSButton.Link
        ml="XS"
        onClick={() =>
          history.push(
            `/member/invoices/payables/${existingPayable.payableId}/edit`
          )
        }
      >
        Edit payable
      </WSButton.Link>
    </WSMessageBox>
  ) : null;
};
