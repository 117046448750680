import {
  useModalContext,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import { IAccount } from "@wingspanhq/users";
import React from "react";
import { useDeleteAccount } from "../../../query/users/mutations";
import { BankAccountPreview } from "../BankAccountPreview/BankAccountPreview";

export const REMOVE_ACCOUNT_MODAL_KEY = "removeAccount";

export const RemoveAccountModal: React.FC = () => {
  const [deleteAccount, deleteAccountMeta] = useDeleteAccount();
  const { closeModal } = useModalContext();

  return (
    <WSModal
      name={REMOVE_ACCOUNT_MODAL_KEY}
      size="S"
      title="Remove bank account"
    >
      {(account: IAccount) => (
        <>
          <WSText mb="XL">
            Are you sure you want to remove this bank account?
          </WSText>
          <BankAccountPreview
            mb="2XL"
            institutionId={account.institutionId}
            name={account.name}
          />

          <WSButtons format="modal">
            <WSButton
              destructive
              onClick={() => {
                deleteAccount(
                  { accountId: account.accountId },
                  {
                    onSuccess: () => {
                      closeModal(REMOVE_ACCOUNT_MODAL_KEY);
                    }
                  }
                );
              }}
              loading={deleteAccountMeta.isLoading}
            >
              Remove
            </WSButton>
            <WSButton.Tertiary
              onClick={() => {
                closeModal(REMOVE_ACCOUNT_MODAL_KEY);
              }}
            >
              Cancel
            </WSButton.Tertiary>
          </WSButtons>
        </>
      )}
    </WSModal>
  );
};
