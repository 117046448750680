import React from "react";
import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import styles from "./ProcessCardPlaceholder.module.scss";

export const ProcessCardPlaceholder: React.FC<WSElementProps & {
  title: string;
  cardType: "virtual" | "physical";
  label?: string;
  onAction?(): void;
}> = ({ onAction, cardType, label, title, ...props }) => (
  <WSElement
    className={classNames(
      styles.cardPlaceholder,
      cardType === "virtual" ? styles.virtualCard : styles.physicalCard
    )}
    {...props}
  >
    <WSFlexBox.Center className={styles.overlay} direction="column" gap="M">
      <WSText.Paragraph weight="bold" color="white">
        {title}
      </WSText.Paragraph>
      {label ? (
        onAction ? (
          <WSButton.Secondary
            className={styles.button}
            size="M"
            onClick={onAction}
            textClassName={styles.buttonText}
          >
            {label}
          </WSButton.Secondary>
        ) : (
          <WSText.ParagraphXs weight="medium" color="gray200">
            {label}
          </WSText.ParagraphXs>
        )
      ) : null}
    </WSFlexBox.Center>
  </WSElement>
);
