import {
  WSControl,
  WSElement,
  WSForm,
  WSInfoBox,
  WSList,
  WSMarginProps,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";

export interface FormLocalTaxInformationConsentData {
  localTaxInfoConsent: boolean;
  shareLocalTaxInfo: boolean;
}

interface FormLocalTaxInformationConsentProps extends WSMarginProps {
  payerNames: string[];
}

export const getValidationSchemaFormLocalTaxPayerCertificationConsent = (
  payerNames: string[]
) => {
  return Yup.object().shape({
    localTaxInfoConsent: Yup.bool()
      .required()
      .oneOf([true], "You must certify the information is accurate"),
    shareLocalTaxInfo:
      payerNames.length > 0
        ? Yup.bool()
            .required()
            .oneOf(
              [true],
              "You must authorize Wingspan to share the tax information with your payer(s)"
            )
        : Yup.boolean()
  });
};

export const FormLocalTaxInformationConsent: React.FC<FormLocalTaxInformationConsentProps> = ({
  payerNames
}) => {
  return (
    <WSPanel>
      <WSList gap="2XL">
        <WSElement>
          <WSText.Paragraph weight="medium" color="gray600" mb="M">
            Certify your taxpayer information is accurate
          </WSText.Paragraph>
          <WSText.ParagraphSm weight="book" color="gray600">
            By using Wingspan’s services you agree that the information provided
            is accurate and that you certify the provided information as
            follows:
          </WSText.ParagraphSm>
        </WSElement>

        <WSInfoBox>
          <p>
            By submitting this form, you certify, under penalties of perjury,
            that:
          </p>
          <ol>
            <li>
              The information provided on this form is true, correct, and
              complete to the best of your knowledge and belief;
            </li>
            <li>
              You are the individual that is the beneficial owner, or you are
              authorized to sign for the entity that is the beneficial owner, of
              all the income to which this form relates;
            </li>
            <li>
              The beneficial owner is not a U.S. person (including U.S.
              citizens, U.S. resident aliens, or U.S. entities);
            </li>
            <li>
              The country of residence indicated on this form is the beneficial
              owner's true country of tax residence;
            </li>
            <li>
              The income to which this form relates is:
              <ul style={{ listStyleType: "lower-alpha" }}>
                <li>
                  not effectively connected with the conduct of a trade or
                  business in the United States,
                </li>
                <li>
                  effectively connected but not subject to tax under an
                  applicable income tax treaty, or
                </li>
                <li>
                  the partner's share of a partnership's effectively connected
                  income;
                </li>
              </ul>
            </li>

            <li>
              You agree to submit a new form within 30 days if any certification
              made on this form becomes incorrect.
            </li>
          </ol>
          <p>
            You authorize this form to be provided to any withholding agent that
            has control, receipt, or custody of the income of which the entity
            or individual named on this form is the beneficial owner or any
            withholding agent that can disburse or make payments of the income.
          </p>
        </WSInfoBox>

        <WSList gap="M">
          <WSForm.Field
            name="localTaxInfoConsent"
            component={WSControl}
            componentProps={{
              type: "checkbox",
              label:
                "By providing the information on this form, I hereby certify, to the best of my knowledge, that the information provided is complete and correct.",
              size: "S"
            }}
          />

          {payerNames.length > 0 && (
            // TODO: implement edit payers list
            <WSForm.Field
              name="shareLocalTaxInfo"
              component={WSControl}
              componentProps={{
                type: "checkbox",
                label: `I authorize Wingspan to share the tax information I've provided with my payer(s) on the Wingspan platform (${payerNames.join(
                  ", "
                )}). I understand this information will be used for tax reporting purposes in accordance with applicable laws and regulations.`,
                size: "S"
              }}
            />
          )}
        </WSList>
      </WSList>
    </WSPanel>
  );
};
