import React from "react";
import { FormPersonalInfo } from "../../../../shared/components/FormPersonalInfo";
import { LayoutPaymentsSetup } from "../../../../shared/components/LayoutPaymentsSetup";
import { useMutationUpdateCurrentStep } from "../../mutations/useMutationUpdateCurrentStep";
import { RouteComponentProps } from "../../types";

export const RoutePersonal: React.FC<RouteComponentProps> = ({
  onBack,
  onNext,
  params
}) => {
  const [updateCurrentStep] = useMutationUpdateCurrentStep();

  return (
    <LayoutPaymentsSetup onBack={onBack} title="Verify personal details">
      <FormPersonalInfo
        skipTaxVerification={params?.skipTaxVerification}
        onUpdateBusinessInfo={onBack}
        onSuccess={() => {
          updateCurrentStep({ newStep: params?.skipTaxVerification ? 3 : 5 });
          onNext();
        }}
      />
    </LayoutPaymentsSetup>
  );
};
