import React, { useEffect, useState } from "react";
import {
  WSButton,
  WSButtonProps
} from "../../core/WSButton/WSButton.component";
import { useIsDesktop } from "../../layout";
import { shareText, copyText } from "../WSCopyText/WSCopyText.component";

export type WSCopyButtonProps = Omit<WSButtonProps, "badgeNumber"> & {
  data: string;
  copyLabel: string;
  shareLabel: string;
  shareTitle: string;
  isURL: boolean;
  onCopy?: () => void;
  onShare?: () => void;
  onSuccess?: () => void;
};

export const WSCopyButton: React.FC<WSCopyButtonProps> = ({
  data,
  copyLabel,
  shareLabel,
  shareTitle = "Share",
  onCopy,
  onShare,
  children,
  onSuccess,
  isURL,
  ...other
}) => {
  const [isDone, setIsDone] = useState(false);
  const isDesktop = useIsDesktop();
  const isSharing = !isDesktop && !!window.navigator.share;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isDone) {
      const timer = setTimeout(() => {
        setIsDone(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isDone]);

  const onClick = async () => {
    if (isSharing) {
      await shareText(isURL, data, shareTitle);
      setIsDone(true);
      onShare?.();
    } else {
      await copyText(data);
      setIsDone(true);
      onCopy?.();
    }
    onSuccess?.();
  };

  return (
    <WSButton
      fullWidth
      onClick={onClick}
      disabled={isDone}
      icon={isDone ? "check" : "copy-fill"}
      {...other}
    >
      {isSharing
        ? isDone
          ? "Shared"
          : shareLabel
        : isDone
        ? "Copied"
        : copyLabel}
    </WSButton>
  );
};
