import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useUserId } from "../query/hooks/helpers";
import { useUserProfile } from "../query/users/queries";
import { WSQueries } from "../query/WSQuery";
import { OrganizationAccounts } from "./screens/OrganizationAccounts";
import { UserAccountType } from "@wingspanhq/users/dist/lib/interfaces/newUser";

export const OrganizationIndex: React.FC = () => {
  const userId = useUserId();
  const qUserProfile = useUserProfile(userId);

  return (
    <WSQueries queries={{ qUserProfile }}>
      {({ qUserProfile }) => {
        const isEnterpriseUser =
          qUserProfile.data.settings?.userAccountType ===
          UserAccountType.enterprise;

        return isEnterpriseUser ? (
          <Switch>
            <Route
              path="/member/organization/accounts"
              exact
              component={OrganizationAccounts}
            />

            <Redirect from="*" to="/member/organization/accounts" />
          </Switch>
        ) : (
          <Redirect from="*" to="/member" />
        );
      }}
    </WSQueries>
  );
};
