import { CreateRequirementDefinitionSharedFileMimeType } from "../types";

const IMAGE_MIME_TYPES = ["image/jpeg", "image/png"];
const DOCUMENT_MIME_TYPES = ["application/pdf"];
const OTHER_MIME_TYPES = [
  "text/csv",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
];

const ALL_MIME_TYPES = [
  ...IMAGE_MIME_TYPES,
  ...DOCUMENT_MIME_TYPES,
  ...OTHER_MIME_TYPES
];

export const MIME_TYPES_VALUES: Record<
  CreateRequirementDefinitionSharedFileMimeType,
  string[]
> = {
  [CreateRequirementDefinitionSharedFileMimeType.All]: ALL_MIME_TYPES,
  [CreateRequirementDefinitionSharedFileMimeType.Image]: IMAGE_MIME_TYPES,
  [CreateRequirementDefinitionSharedFileMimeType.Document]: DOCUMENT_MIME_TYPES,
  [CreateRequirementDefinitionSharedFileMimeType.Other]: OTHER_MIME_TYPES
};

export const MIME_TYPES_LABELS: Record<
  CreateRequirementDefinitionSharedFileMimeType,
  string
> = {
  [CreateRequirementDefinitionSharedFileMimeType.All]: "All types",
  [CreateRequirementDefinitionSharedFileMimeType.Image]: "Image (jpeg, png)",
  [CreateRequirementDefinitionSharedFileMimeType.Document]: "Document (pdf)",
  [CreateRequirementDefinitionSharedFileMimeType.Other]:
    "Other (docx, xlsx, csv)"
};

export const mimeTypesToLabel = (selectedMimeTypes: string[]) => {
  const selectedTypes = Object.entries(MIME_TYPES_VALUES).reduce<
    CreateRequirementDefinitionSharedFileMimeType[]
  >((acc, [key, value]) => {
    if (
      value.every(type => selectedMimeTypes.includes(type)) &&
      value.length === selectedMimeTypes.length &&
      value.length
    ) {
      return [...acc, key as CreateRequirementDefinitionSharedFileMimeType];
    }
    return acc;
  }, []);

  return selectedTypes
    .map(
      type =>
        MIME_TYPES_LABELS[type as CreateRequirementDefinitionSharedFileMimeType]
    )
    .filter(Boolean)
    .join(", ");
};
