import {
  IPayerTaxFormResponse,
  ITaxFormCorrectionResponse
} from "@wingspanhq/payments/dist/interfaces";
import { IAddress } from "@wingspanhq/users";

export function selectorTaxFormCorrectionFormW9Address(
  taxForm: IPayerTaxFormResponse
) {
  const correctionRequest = taxForm?.pendingCorrection;
  return {
    addressLine1: correctionRequest?.data?.w9Info?.addressLine1 ?? "",
    addressLine2: correctionRequest?.data?.w9Info?.addressLine2 ?? "",
    city: correctionRequest?.data?.w9Info?.city ?? "",
    state: correctionRequest?.data?.w9Info?.state ?? "",
    postalCode: correctionRequest?.data?.w9Info?.postalCode ?? "",
    country: correctionRequest?.data?.w9Info?.country ?? ""
  };
}
