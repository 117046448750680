import cn from "classnames";
import {
  InputNumber,
  InputNumberChangeEvent,
  InputNumberProps
} from "primereact/inputnumber";
import React, { useEffect, useRef } from "react";
import { toCamelCase } from "../../../../utils";
import {
  WSElement,
  WSElementLayoutProps,
  detachLayoutProps
} from "../../WSElement/WSElement.component";
import styles from "./WSAmountInput.module.scss";

export interface WSAmountInputProps
  extends Omit<InputNumberProps, "pt" | "prefix" | "size">,
    WSElementLayoutProps {
  error?: boolean;
  prefix?: string;
}

export const WSAmountInput = React.forwardRef<InputNumber, WSAmountInputProps>(
  (props, ref) => {
    const placeFillerRef = useRef<HTMLDivElement>(null);

    const {
      error,
      className,
      layoutProps,
      prefix = "$",
      systemProps,
      onChange,
      placeholder = "0.00",
      ...inputProps
    } = detachLayoutProps(props);
    const containerClassNames = cn(styles.container, className, {
      [styles.error]: error
    });

    useEffect(() => {
      if (placeFillerRef.current) {
        placeFillerRef.current.innerText = inputProps.value
          ? String(inputProps.value)
          : placeholder;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event: InputNumberChangeEvent) => {
      onChange?.(event);
      if (placeFillerRef.current) {
        placeFillerRef.current.innerText =
          (event.originalEvent.target as HTMLInputElement).value || placeholder;
      }
    };

    return (
      <WSElement
        {...systemProps}
        className={containerClassNames}
        {...layoutProps}
      >
        <div className={styles.inner}>
          <div className={styles.prefix}>{prefix}</div>
          <div className={styles.inputWrapper}>
            <InputNumber
              ref={ref}
              className={styles.input}
              pt={{ input: { root: { className: styles.rawInput } } }}
              data-testid={
                inputProps.name
                  ? toCamelCase(inputProps.name, "field")
                  : undefined
              }
              placeholder={placeholder}
              onChange={handleChange}
              minFractionDigits={2}
              maxFractionDigits={2}
              {...inputProps}
            />

            <div ref={placeFillerRef} className={styles.placeFiller} />
          </div>
        </div>
      </WSElement>
    );
  }
);
