import {
  WSContainer,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import cn from "classnames";
import React from "react";
import logo from "../../../assets/images/ws-logo.svg";
import styles from "./Header.module.scss";

export interface LayoutProps {
  onBack?: () => void;
  hideBorder?: boolean;
  partnerLogo?: string;
}

export const Header: React.FC<LayoutProps> = ({
  onBack,
  partnerLogo,
  hideBorder
}) => (
  <WSFlexBox.Center
    className={cn(styles.header, { [styles.hideBorder]: hideBorder })}
    py="XL"
  >
    <WSContainer>
      {onBack && (
        <WSIcon
          block
          name="arrow-left"
          onClick={onBack}
          className={styles.backIcon}
        />
      )}

      {partnerLogo ? (
        <WSElement className={styles.partnerHeader}>
          <a href="https://wingspan.app" className={styles.leftLogo}>
            <img className={styles.logo} src={logo} alt="Wingspan Logo" />
          </a>
          <WSIcon block name="exit" mx="XL" size="XS" />
          <WSElement className={styles.rightLogo} data-testid="partnerLogo">
            <img
              className={styles.rightImage}
              src={partnerLogo}
              alt="Partner Logo"
            />
          </WSElement>
        </WSElement>
      ) : (
        <a href="https://wingspan.app" className={styles.logoLink}>
          <img className={styles.logo} src={logo} alt="Wingspan Logo" />
        </a>
      )}
    </WSContainer>
  </WSFlexBox.Center>
);
