import { IMemberOccupation } from "@wingspanhq/users";
import React from "react";
import { BusinessAddressForm } from "../SettingsUpdateForms/BusinessAddressForm";
import { BusinessNameForm } from "../SettingsUpdateForms/BusinessNameForm";
import { BusinessTypeForm } from "../SettingsUpdateForms/BusinessTypeForm";
import { DefaultMemberProfileBusinessUrlsForm } from "../SettingsUpdateForms/DefaultMemberProfileBusinessUrlsForm";
import { DefaultUserProfileSettingForm } from "../SettingsUpdateForms/DefaultUserProfileSettingForm";
import { MemberOccupationsForm } from "../SettingsUpdateForms/MemberOccupationsForm";
import { RequestDOBChangeForm } from "../SettingsUpdateForms/RequestDOBChangeForm";
import { TaxExpensesEstimateForYearForm } from "../SettingsUpdateForms/TaxExpensesEstimateForYearForm";
import { TaxFilingStateForm } from "../SettingsUpdateForms/TaxFilingStateForm";
import { TaxFilingStatusForm } from "../SettingsUpdateForms/TaxFilingStatusForm";
import { TaxIncomeEstimateForYearForm } from "../SettingsUpdateForms/TaxIncomeEstimateForYearForm";
import { TaxNumberOfDependentsForm } from "../SettingsUpdateForms/TaxNumberOfDependentsForm";
import { UpdateEINForm } from "../SettingsUpdateForms/UpdateEINForm";
import { UpdateEmailForm } from "../SettingsUpdateForms/UpdateEmailForm";
import { UpdateForeignTaxIdForm } from "../SettingsUpdateForms/UpdateForeignTaxIdForm";
import { UpdateGenderForm } from "../SettingsUpdateForms/UpdateGenderForm";
import { UpdatePasswordForm } from "../SettingsUpdateForms/UpdatePasswordForm";
import { UpdatePostalCodeForm } from "../SettingsUpdateForms/UpdatePostalCodeForm";
import { UpdateSSNForm } from "../SettingsUpdateForms/UpdateSSNForm";
import { LegalBusinessNameForm } from "../SettingsUpdateForms/LegalBusinessNameForm";
import { StateOfIncorporationForm } from "../SettingsUpdateForms/StateOfIncorporationForm";
import { PersonalAddressForm } from "../SettingsUpdateForms/PersonalAddressForm";
import { LegalFirstNameForm } from "../SettingsUpdateForms/LegalFirstNameForm";
import { LegalLastNameForm } from "../SettingsUpdateForms/LegalLastNameForm";
import { YearOfIncorporationForm } from "../SettingsUpdateForms/YearOfIncorporationForm";

export interface EditableSettingValueProps {
  label?: string;
  value: any;
  field: string;
  onSubmit: (data: any) => void;
  onCancel?: () => void;
}

export const EditableSettingValue: React.FC<EditableSettingValueProps> = ({
  field,
  value,
  ...formProps
}) => {
  switch (field) {
    case "password":
      return <UpdatePasswordForm {...formProps} />;
    case "email":
      return (
        <UpdateEmailForm
          defaultValues={{ currentEmail: value }}
          {...formProps}
        />
      );
    case "preferredName":
      return (
        <DefaultUserProfileSettingForm
          field={field}
          defaultValues={{ [field]: value }}
          {...formProps}
        />
      );
    case "firstName":
      return (
        <LegalFirstNameForm
          defaultValues={{ firstName: value }}
          {...formProps}
        />
      );
    case "lastName":
      return (
        <LegalLastNameForm defaultValues={{ lastName: value }} {...formProps} />
      );
    case "dob":
      return (
        <RequestDOBChangeForm defaultValues={{ dob: value }} {...formProps} />
      );
    case "gender":
      return (
        <UpdateGenderForm defaultValues={{ [field]: value }} {...formProps} />
      );
    case "postalCode":
      return (
        <UpdatePostalCodeForm
          defaultValues={{ [field]: value }}
          {...formProps}
        />
      );
    case "ssn":
      return (
        <UpdateSSNForm defaultValues={{ [field]: value }} {...formProps} />
      );
    case "taxId":
      return (
        <UpdateEINForm defaultValues={{ [field]: value }} {...formProps} />
      );
    case "legalBusinessName":
      return (
        <LegalBusinessNameForm defaultValues={{ name: value }} {...formProps} />
      );
    case "stateOfIncorporation":
      return (
        <StateOfIncorporationForm
          defaultValues={{ stateOfIncorporation: value }}
          {...formProps}
        />
      );
    case "yearOfIncorporation":
      return (
        <YearOfIncorporationForm
          defaultValues={{ yearOfIncorporation: value }}
          {...formProps}
        />
      );
    case "name":
      return (
        <BusinessNameForm defaultValues={{ name: value }} {...formProps} />
      );
    case "businessType":
      return (
        <BusinessTypeForm defaultValues={{ structure: value }} {...formProps} />
      );
    case "businessAddress":
      return (
        <BusinessAddressForm
          field="address"
          defaultValues={value}
          {...formProps}
        />
      );
    case "personalAddress":
      return (
        <PersonalAddressForm
          field="address"
          defaultValues={value}
          {...formProps}
        />
      );

    case "occupations":
      let customOccupation = "";
      const occupations = value.map((occupation: IMemberOccupation) => {
        if (occupation.category === "Other") {
          customOccupation = occupation.customCategory || "";
        }
        return occupation.category;
      });
      return (
        <MemberOccupationsForm
          defaultValues={{
            occupations,
            customOccupation
          }}
          {...formProps}
        />
      );
    case "businessWebsite":
      return (
        <DefaultMemberProfileBusinessUrlsForm
          field="websiteUrl"
          defaultValues={{ websiteUrl: value }}
          {...formProps}
        />
      );
    case "businessLinkedInURL":
      return (
        <DefaultMemberProfileBusinessUrlsForm
          field="linkedInUrl"
          defaultValues={{ linkedInUrl: value }}
          {...formProps}
        />
      );
    case "businessTwitterURL":
      return (
        <DefaultMemberProfileBusinessUrlsForm
          field="twitterUrl"
          defaultValues={{ twitterUrl: value }}
          {...formProps}
        />
      );
    case "businessInstagramURL":
      return (
        <DefaultMemberProfileBusinessUrlsForm
          field="instagramUrl"
          defaultValues={{ instagramUrl: value }}
          {...formProps}
        />
      );
    case "businessOtherURL":
      return (
        <DefaultMemberProfileBusinessUrlsForm
          field="otherUrl"
          defaultValues={{ otherUrl: value }}
          {...formProps}
        />
      );
    case "taxFilingStatus":
      return (
        <TaxFilingStatusForm
          field="filingCode"
          defaultValues={{ filingCode: value }}
          {...formProps}
        />
      );
    case "noOfDependents":
      return (
        <TaxNumberOfDependentsForm
          field="numExemptions"
          defaultValues={{ numExemptions: value }}
          {...formProps}
        />
      );
    case "taxFilingState":
      return (
        <TaxFilingStateForm
          field="stateOfResidence"
          defaultValues={{ stateOfResidence: value }}
          {...formProps}
        />
      );
    case "incomeEstimateForYear":
      return (
        <TaxIncomeEstimateForYearForm
          field="incomeEstimateForYear"
          defaultValues={{ incomeEstimateForYear: value }}
          {...formProps}
        />
      );
    case "expensesEstimateForYear":
      return (
        <TaxExpensesEstimateForYearForm
          field="expensesEstimateForYear"
          defaultValues={{ expensesEstimateForYear: value }}
          {...formProps}
        />
      );
    case "foreignTaxId":
      return (
        <UpdateForeignTaxIdForm
          defaultValues={{ [field]: value }}
          {...formProps}
        />
      );
    default:
      return (
        <DefaultUserProfileSettingForm
          field={field}
          defaultValues={{ [field]: value }}
          {...formProps}
        />
      );
  }
};
