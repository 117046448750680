import { IPayerEngagementResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { servicePayments } from "../servicePayments";

export const getPayerEngagement = async (
  payerId: string,
  engagementId: string
): Promise<IPayerEngagementResponse> => {
  const { data } = await servicePayments.get(
    `/payer/${payerId}/engagement/${engagementId}`
  );
  return data;
};
