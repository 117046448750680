import React, { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import * as Yup from "yup";

import {
  WSButton,
  WSFlexBox,
  WSFormOld,
  WSInputNumberOld,
  WSModal,
  WSText,
  WSTextArea
} from "@wingspanhq/fe-component-library";
import {
  Form1099DisputeStatus,
  Form1099Status,
  IMemberClient
} from "@wingspanhq/payments/dist/interfaces";

import { getClientCompanyName, getClientName } from "../../../../utils/client";
import { getNonEmployeeCompensation } from "../utils/getTotalsItems";
import { useHistory } from "react-router-dom";
import CancelActionFullWidthButtons from "../../../../components/CancelActionFullWidthButtons";
import LabelValueList, {
  ListValueType,
  RowData
} from "../../../../components/LabelValueList";
import { SidePanelSection } from "../../../../components/SidePanel";
import useSnackbar from "../../../../hooks/useSnackbar";
import { useUpdateMemberClient } from "../../../../query/payments/mutations";
import { getCurrentYearForm1099Balance } from "../../../../query/payments/selectors";
import getCurrentFilingYear from "../../../../utils/getCurrentFilingYear";

import { selectorIsForm1099BalanceSubmitted } from "../../../../shared/selectors/selectorIsForm1099BalanceSubmitted";

interface FormValues {
  comment: string;
  amount: number;
}

const FormInner: React.FC<{
  memberClient: IMemberClient;
  loading: boolean;
  onCancel: () => void;
}> = ({ memberClient, loading, onCancel }) => {
  const form1099Balance = getCurrentYearForm1099Balance(memberClient);
  const nonEmployeeComp = form1099Balance
    ? getNonEmployeeCompensation(form1099Balance)
    : 0;

  const disputableDetails: RowData[] = [
    {
      id: "nonEmployeeCompensationTotal",
      label: "Non-Employee Compensation Total",
      value: nonEmployeeComp,
      type: ListValueType.MoneyStrike
    }
  ];
  const {
    formState: { isDirty: isFormStateDirty }
  } = useFormContext();

  return (
    <>
      <WSFormOld.Field name="comment" label="Comments" component={WSTextArea} />
      <LabelValueList items={disputableDetails} />
      <WSFormOld.Field
        mt="XL"
        name="amount"
        component={WSInputNumberOld}
        componentProps={{
          mode: "currency",
          currency: "USD"
        }}
        label="Suggested Non-Employee Compensation total"
      />
      <WSFlexBox justify="space-between" mt={"M"}>
        <WSButton.Tertiary
          disabled={loading}
          type="button"
          style={{ flexGrow: 1 }}
          mr="XS"
          onClick={onCancel}
        >
          Cancel
        </WSButton.Tertiary>
        <WSButton.Primary
          loading={loading}
          disabled={!isFormStateDirty || loading}
          style={{ flexGrow: 1 }}
          ml="XS"
        >
          Save
        </WSButton.Primary>
      </WSFlexBox>
    </>
  );
};

const Dispute: React.FC<{
  memberClient: IMemberClient;
}> = ({ memberClient }) => {
  const history = useHistory();
  const snackbar = useSnackbar();
  const [correction, setCorrection] = useState<FormValues | null>(null);

  const { memberClientId } = memberClient;
  const currentYear = getCurrentFilingYear();
  const form1099Balance = getCurrentYearForm1099Balance(memberClient);
  const isFormNotSubmitted = !selectorIsForm1099BalanceSubmitted(
    form1099Balance
  );
  const nonEmployeeComp = form1099Balance
    ? getNonEmployeeCompensation(form1099Balance)
    : 0;
  const clientName = getClientName(memberClient);
  const companyName =
    getClientCompanyName(memberClient as IMemberClient) || clientName;

  const [updateMemberClient, updateMemberClientMeta] = useUpdateMemberClient(
    memberClientId
  );

  const onFormSubmit = useCallback(
    (formValues: FormValues) => setCorrection(formValues),
    []
  );

  const handleCorrectionSubmit = useCallback(() => {
    if (!correction) return;

    const { comment, amount } = correction;
    const updatePayload = {
      form1099Balances: {
        [currentYear]: {
          status: Form1099Status.NeedsActionDispute,
          events: isFormNotSubmitted
            ? { suggestionMadeAt: new Date() }
            : { postFilingSuggestionMadeAt: new Date() },
          dispute: {
            comment,
            amount,
            status: Form1099DisputeStatus.Open
          }
        }
      }
    };

    updateMemberClient(updatePayload, {
      onSuccess: () => {
        snackbar.success("Suggestion submitted");
        history.goBack();
      },
      onError: err => {
        console.log(err);
        history.goBack();
      }
    });
  }, [
    correction,
    currentYear,
    history,
    isFormNotSubmitted,
    snackbar,
    updateMemberClient
  ]);

  return (
    <>
      {correction && (
        <WSModal
          allowClickOutsideToClose
          onClose={() => setCorrection(null)}
          size="S"
        >
          <WSText.Heading5 my="XL">
            Are you ready to submit your suggestion?
          </WSText.Heading5>
          <WSText mb="M">
            Your suggestion will be sent to {companyName} so they can make a
            correction. If you notice errors later, please contact us at
            support@wingspan.app.
          </WSText>
          <CancelActionFullWidthButtons
            cancelText="No"
            onCancel={() => setCorrection(null)}
            actionText="Yes"
            onAction={handleCorrectionSubmit}
          />
        </WSModal>
      )}

      <SidePanelSection title={"What should the total be?"} first>
        <WSText mt="XL" color="gray500">
          {companyName} will receive an email notifying them of your suggested
          {isFormNotSubmitted ? " change" : " correction"}. Please provide
          specific details that will help {companyName}, like incorrect invoice
          numbers, amounts or anything else that will help them correct your tax
          form.
        </WSText>
        <WSFormOld<FormValues>
          mt="XL"
          defaultValues={{
            comment: "",
            amount: nonEmployeeComp
          }}
          validationSchema={Yup.object().shape({
            comment: Yup.string().required("A comment is required"),
            amount: Yup.number().notOneOf(
              [nonEmployeeComp],
              "The suggested amount must be different from the current value"
            )
          })}
          onSubmit={onFormSubmit}
        >
          <FormInner
            loading={updateMemberClientMeta.isLoading}
            memberClient={memberClient}
            onCancel={history.goBack}
          />
        </WSFormOld>
      </SidePanelSection>
    </>
  );
};

export default Dispute;
