import { WSSidebar } from "@wingspanhq/fe-component-library";
import React from "react";
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";

import { getParentPath } from "../../../../utils/goToParentRoute";
import { usePayoutQuery } from "../../../../query/payouts/queries/usePayoutQuery";
import { WSQueries } from "../../../../query/WSQuery";
import { IPayoutResponse } from "../../../../services/api/payments/payouts/types";
import { PayoutAccountDetailsItem } from "./PayoutAccountDetailsItem";

export const PayoutAccountDetails: React.FC<RouteComponentProps<{
  customFieldId: string;
}>> = () => {
  const history = useHistory();
  const location = useLocation<{ backPath: string }>();
  const backPath = location.state?.backPath || getParentPath(history);
  const match = useRouteMatch<{ payoutId: string }>();

  const queryPayout = usePayoutQuery(match.params.payoutId);

  const onBack = () => {
    if (backPath) {
      history.push({
        pathname: backPath,
        search: location.search,
        state: {
          ...(location.state ?? {})
        }
      });
    }
  };

  return (
    <WSSidebar.Container onClose={onBack}>
      <WSQueries queries={{ queryPayout }}>
        {({ queryPayoutData }) => (
          <WSSidebar.Layout header={"Payout accounts details"}>
            <Inner data={queryPayoutData} onBack={onBack} />
          </WSSidebar.Layout>
        )}
      </WSQueries>
    </WSSidebar.Container>
  );
};

export const Inner: React.FC<{
  data: IPayoutResponse;
  onBack: () => void;
}> = ({ data }) => {
  return (
    <>
      {(data.externalFundsTransfers || []).map(payoutAccountItem => (
        <PayoutAccountDetailsItem
          payoutAccountItem={payoutAccountItem}
          payout={data}
        />
      ))}
      {data.bankingFundsTransfer && (
        <PayoutAccountDetailsItem
          payoutAccountItem={data?.bankingFundsTransfer}
          payout={data}
          isWallet={true}
        />
      )}
    </>
  );
};
