import {
  WSButton,
  WSButtons,
  WSElement,
  WSMessageBox,
  useWSModal
} from "@wingspanhq/fe-component-library";
import {
  TaxFormStatus,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces";

const ModalRecipientCorrectionExists: React.FC<{
  taxForm: IPayerTaxFormResponse;
  onClose(): void;
  onReviewCorrectionRequest(): void;
}> = ({ taxForm, onClose, onReviewCorrectionRequest }) => {
  return (
    <WSElement>
      <WSMessageBox.Warning
        noBorder
        icon="alert"
        title="Correction requested by recipient"
        mb="M"
      >
        The recipient has requested corrections to their Form 1099-NEC. You must
        first resolve the request before starting a new correction.
      </WSMessageBox.Warning>

      <WSButtons forceFullWidth>
        <WSButton.Primary onClick={onReviewCorrectionRequest}>
          {taxForm.status === TaxFormStatus.AcceptedByIrs
            ? "Review correction request"
            : "View correction request"}
        </WSButton.Primary>
        <WSButton.Secondary onClick={onClose}>Back</WSButton.Secondary>
      </WSButtons>
    </WSElement>
  );
};

export const useRecipientCorrectionExistsModal = () =>
  useWSModal(ModalRecipientCorrectionExists, {
    title: "Important, please read:",
    size: "S"
  });
