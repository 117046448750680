import { formatNumber } from "accounting";
import camelCase from "lodash/camelCase";

export const toCamelCase = (array: string[]) => {
  return camelCase(array.join(" "));
};

export const censorWord = (
  word: string,
  options?: {
    hideFirst?: boolean;
    hideLast?: boolean;
  }
) => {
  if (word.length === 0) {
    return "";
  }

  if (word.length === 1) {
    return "*";
  }

  if (word.length === 2) {
    return word[0] + "*";
  }

  const firstChar = options?.hideFirst ? "*" : word[0];
  const middle = "*".repeat(word.length - 2);
  const lastChar = options?.hideLast ? "*" : word.slice(-1);

  return firstChar + middle + lastChar;
};

export const censorEmail = (email: string) => {
  if (!email.includes("@")) {
    return "";
  }

  const emailParts = email.split("@");

  return (
    censorWord(emailParts[0]) +
    "@" +
    censorWord(emailParts[1], { hideFirst: emailParts[1].length <= 3 })
  );
};

export const censorPhone = (phone: string) => {
  return (
    phone.slice(0, 4) +
    "*".repeat(phone.length - 4) +
    phone.slice(phone.length - 4)
  );
};

interface PluralizeOptions {
  one: string;
  other: string;
}

interface PluralizeConfig {
  withCount?: boolean;
}

export const pluralize = (
  count: number,
  options: PluralizeOptions,
  config: PluralizeConfig = { withCount: true }
) => {
  const formattedCount = formatNumber(count);
  if (count === 1) {
    return config?.withCount
      ? `${formattedCount} ${options["one"]}`
      : options["one"];
  }
  return config?.withCount
    ? `${formattedCount} ${options["other"]}`
    : options["other"];
};

export const humanJoin = (array: string[]) => {
  if (array.length === 0) {
    return "";
  } else if (array.length === 1) {
    return array[0];
  } else if (array.length === 2) {
    return array.join(" and ");
  } else {
  }
  return `${array.slice(0, -2).join(", ")}, ${array.slice(-2).join(" and ")}`;
};
