import {
  useModalContext,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import { IApiSessionResponse } from "@wingspanhq/users";
import React from "react";
import { useDeleteApiSession } from "../../query/users/mutations";

export interface DeleteApiTokenModalProps {
  session: IApiSessionResponse;
}

export const DELETE_API_TOKEN_MODAL = "DELETE_API_TOKEN_MODAL";

export const DeleteApiTokenModal: React.FC = () => {
  const { closeModal } = useModalContext();
  const [deleteApiSession, deleteApiSessionMeta] = useDeleteApiSession();
  return (
    <WSModal
      name={DELETE_API_TOKEN_MODAL}
      size="S"
      title="Are you sure you want to delete?"
    >
      {(props: DeleteApiTokenModalProps) => (
        <>
          <WSText mb="M">Once confirmed, this action can't be undone.</WSText>

          <WSButtons format="modal">
            <WSButton
              destructive
              name="deleteApiToken"
              loading={deleteApiSessionMeta.isLoading}
              onClick={async () => {
                await deleteApiSession(props.session.sessionId);
                closeModal(DELETE_API_TOKEN_MODAL);
              }}
            >
              Delete
            </WSButton>
            <WSButton.Tertiary
              onClick={() => closeModal(DELETE_API_TOKEN_MODAL)}
            >
              Cancel
            </WSButton.Tertiary>
          </WSButtons>
        </>
      )}
    </WSModal>
  );
};
