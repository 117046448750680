import { useHistory } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { safePageKeyboardEventHandling } from "./keyboard";

export const useRedirectToByKeyPress = (key: string, redirectRo: string) => {
  const history = useHistory();

  const handleKeyUp = useCallback(
    safePageKeyboardEventHandling((event: KeyboardEvent) => {
      if (event.key === key) {
        history.push(redirectRo);
      }
    }),
    [key, redirectRo, history]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp]);
};
