import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import {
  IActivity,
  IActivityUpdateRequest,
  IClientCreateRequest,
  IClientUpdateRequest,
  ICompletePhoneVerificationRequest,
  IMember,
  IMemberUpdateRequest,
  IUserUpdateRequest
} from "@wingspanhq/users/dist/lib/index";
import {
  IAccountUpdateRequest,
  IApiSessionCreateRequest,
  IApiSessionResponse,
  IApiSessionUpdateRequest,
  IAuthorizationCreateRequest,
  IBeginEmailVerificationRequest,
  IBeginPhoneVerificationRequest,
  ICompleteEmailVerificationRequest,
  IMemberCreateRequest,
  INewUser,
  IOtpSessionCreateRequest,
  ISession,
  IUserCreateRequest
} from "@wingspanhq/users/dist/lib/interfaces";
import { IActivityEvents } from "@wingspanhq/users/dist/lib/interfaces/activity";
import { IPasswordResetRequest } from "@wingspanhq/users/dist/lib/interfaces/api/password";
import { useState } from "react";
import { QueryKey, ReactQueryMutationsConfig, queryCache } from "react-query";
import { useHistory } from "react-router-dom";
import { useCaptcha } from "../../components/Captcha";
import {
  NotificationStatus,
  addNotification
} from "../../components/Notification/Notification";
import {
  ISessionCreateRequest,
  ISignInWithGoogleRequest,
  usersService
} from "../../services/users";
import { Await } from "../../utils";
import { track } from "../../utils/analytics";
import { WSServiceError } from "../../utils/serviceHelper";
import {
  QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_EXPENSE,
  QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_INCOME,
  QUERY_BOOKKEEPING_PROFIT_AND_LOSS,
  QUERY_BOOKKEEPING_TRANSACTIONS
} from "../bookkeeping/keys";
import { useWSMutation } from "../helpers";
import { useUserId } from "../hooks/helpers";
import { QUERY_USERS_SESSION } from "../session";
import {
  QUERY_API_SESSION_LIST,
  QUERY_AUTHORIZATIONS,
  QUERY_AUTHORIZATIONS_INFINITE,
  QUERY_USERS_ACCOUNTS,
  QUERY_USERS_ACTIVITIES,
  QUERY_USERS_CLIENT,
  QUERY_USERS_MEMBER_PROFILE,
  QUERY_USERS_USER_PROFILE
} from "./keys";

export const useUpdateActivity = (
  userId: string,
  config?: ReactQueryMutationsConfig<IActivity, WSServiceError>
) =>
  useWSMutation(
    async (request: IActivityUpdateRequest) => {
      const activity = await usersService.activity.update(userId, request);

      queryCache.setQueryData(QUERY_USERS_ACTIVITIES, activity);

      return activity;
    },
    {
      dependencies: [QUERY_USERS_ACTIVITIES],
      ...config
    }
  );

export const useUpdateActivityById = (
  config?: ReactQueryMutationsConfig<IActivity, WSServiceError>
) =>
  useWSMutation(
    (request: IActivityUpdateRequest & { userId: string }) => {
      const { userId, ...props } = request;

      return usersService.activity.update(userId, props);
    },
    {
      onSuccess: async () => {
        await queryCache.refetchQueries(QUERY_USERS_ACTIVITIES);
      },
      ...config
    }
  );

export const useUpdateTaxesSetupActivity = (
  config?: ReactQueryMutationsConfig<IActivity, WSServiceError>
) =>
  useWSMutation(
    (userId: string) =>
      usersService.activity.update(userId, {
        flows: {
          taxWithholdingsSetup: {
            version: 1,
            currentStep: 1,
            totalSteps: 1,
            complete: true
          }
        }
      }),
    {
      onSuccess: async () => {
        await queryCache.refetchQueries(QUERY_USERS_ACTIVITIES);
      },
      ...config
    }
  );

export const useUpdatePayLinkSetupActivity = (
  config?: ReactQueryMutationsConfig<IActivity, WSServiceError>
) =>
  useWSMutation(
    (userId: string) =>
      usersService.activity.update(userId, {
        flows: {
          personalPayLinkSetup: {
            version: 1,
            currentStep: 1,
            totalSteps: 1,
            complete: true
          }
        }
      }),
    {
      onSuccess: async () => {
        await queryCache.refetchQueries(QUERY_USERS_ACTIVITIES);
      },
      ...config
    }
  );

export const useUpdateBenefitsSetupActivity = (
  config?: ReactQueryMutationsConfig<IActivity, WSServiceError>
) =>
  useWSMutation(
    (userId: string) =>
      usersService.activity.update(userId, {
        flows: {
          benefitsSetup: {
            version: 1,
            currentStep: 1,
            totalSteps: 1,
            complete: true
          }
        }
      }),
    {
      onSuccess: async () => {
        await queryCache.refetchQueries(QUERY_USERS_ACTIVITIES);
      },
      ...config
    }
  );

export const useUpdateActivityCatchUpEvents = (
  config?: ReactQueryMutationsConfig<IActivity, WSServiceError>
) =>
  useWSMutation(
    ({
      userId,
      events
    }: {
      userId: string;
      events: Partial<IActivityEvents>;
    }) =>
      usersService.activity.update(userId, {
        events
      } as any),
    {
      dependencies: [QUERY_USERS_ACTIVITIES],
      ...config
    }
  );

export const useUpdateUserProfile = (
  config?: ReactQueryMutationsConfig<INewUser, WSServiceError> & {
    dependencies?: QueryKey[];
  }
) => {
  const userId = useUserId();

  return useWSMutation(
    async (request: IUserUpdateRequest) =>
      usersService.user.update(userId, request),
    {
      dependencies: [QUERY_USERS_USER_PROFILE],
      ...config
    }
  );
};

type CreateSessionRequest = {
  remember?: boolean;
} & ISessionCreateRequest;

export const useCreateSession = (
  config?: ReactQueryMutationsConfig<
    ISession,
    WSServiceError,
    CreateSessionRequest
  >
) => {
  const callWithCaptcha = useCaptcha("createSession");

  return useWSMutation<ISession, WSServiceError, CreateSessionRequest>(
    ({ remember, ...request } = {}) =>
      callWithCaptcha((captchaToken, captchaVersion, forceCaptcha) =>
        usersService.session.create(
          { captchaToken, captchaVersion, forceCaptcha, ...request },
          { remember }
        )
      ),
    {
      dependencies: [QUERY_USERS_SESSION],
      ...config
    }
  );
};

export const useIdentifyAuthentication = (
  config?: ReactQueryMutationsConfig<
    ReturnType<typeof usersService.authentication.identify>,
    WSServiceError,
    { email: string }
  >
) => {
  const callWithCaptcha = useCaptcha("identifyAuth");

  return useWSMutation<
    ReturnType<typeof usersService.authentication.identify>,
    WSServiceError,
    { email: string }
  >(payload =>
    callWithCaptcha((captchaToken, captchaVersion, forceCaptcha) =>
      usersService.authentication.identify({
        captchaToken,
        captchaVersion,
        ...payload
      })
    )
  );
};

export const useSingleSignIn = (
  config?: ReactQueryMutationsConfig<
    Await<ReturnType<typeof usersService.authentication.singleSignIn>>,
    WSServiceError,
    { code: string }
  >
) => {
  return useWSMutation<
    Await<ReturnType<typeof usersService.authentication.singleSignIn>>,
    WSServiceError,
    { code: string }
  >(payload => usersService.authentication.singleSignIn(payload), {
    dependencies: [QUERY_USERS_SESSION],
    ...config
  });
};

// API tokens
export const useCreateApiSession = () =>
  useWSMutation<IApiSessionResponse, WSServiceError, IApiSessionCreateRequest>(
    (request: IApiSessionCreateRequest) =>
      usersService.session.apiToken.create(request),
    {
      dependencies: [QUERY_API_SESSION_LIST]
    }
  );

export const useUpdateApiSession = () =>
  useWSMutation<
    IApiSessionResponse,
    WSServiceError,
    IApiSessionUpdateRequest & { id: string }
  >(
    ({ id, ...request }: IApiSessionUpdateRequest & { id: string }) =>
      usersService.session.apiToken.update(id, request),
    {
      dependencies: [QUERY_API_SESSION_LIST]
    }
  );

export const useDeleteApiSession = () =>
  useWSMutation((id: string) => usersService.session.apiToken.delete(id), {
    dependencies: [QUERY_API_SESSION_LIST]
  });

// 2FA

// to send otp to mobile OR magic link to email
export const useBeginOTPSession = () =>
  useWSMutation<
    any, // empty response
    WSServiceError
  >(
    (request: IOtpSessionCreateRequest) =>
      usersService.session.beginOtpSession(request),
    {
      dependencies: [QUERY_USERS_USER_PROFILE]
    }
  );

export const useCreateOtpSession = () =>
  useWSMutation<ISession, WSServiceError, ICompletePhoneVerificationRequest>(
    request => usersService.session.createOtpSession(request),
    {
      dependencies: [QUERY_USERS_SESSION]
    }
  );

export const useCreateSessionWithGoogleAuthToken = () =>
  useWSMutation(
    (request: ISignInWithGoogleRequest) =>
      usersService.session.socialAuth.google(request),
    {
      dependencies: [QUERY_USERS_SESSION]
    }
  );

export const useRequestUser = () => {
  const callWithCaptcha = useCaptcha("requestUser");

  return useWSMutation(
    (request: ISessionCreateRequest) =>
      callWithCaptcha((captchaToken, captchaVersion, forceCaptcha) =>
        usersService.session.requestUser({
          captchaToken,
          captchaVersion,
          forceCaptcha,
          ...request
        })
      ),
    {
      dependencies: [QUERY_USERS_SESSION]
    }
  );
};

export const useRequestPrincipalUser = () => {
  const callWithCaptcha = useCaptcha("requestPrincipalUser");

  return useWSMutation<ISession, WSServiceError, ISessionCreateRequest>(
    request =>
      callWithCaptcha((captchaToken, captchaVersion, forceCaptcha) =>
        usersService.session.requestPrincipalUser({
          captchaToken,
          captchaVersion,
          forceCaptcha,
          ...request
        })
      ),
    {
      dependencies: [QUERY_USERS_SESSION]
    }
  );
};

export const useUpdateMemberProfile = (
  config?: ReactQueryMutationsConfig<IMember, WSServiceError>
) =>
  useWSMutation(
    async (request: IMemberUpdateRequest) =>
      usersService.member.update(request.memberId, request),
    {
      dependencies: [QUERY_USERS_MEMBER_PROFILE],
      ...config
    }
  );

export const useDeleteAccount = () =>
  useWSMutation(
    async (props: { accountId: string; deleteTransactions?: boolean }) =>
      usersService.account.delete(props.accountId, props.deleteTransactions),
    {
      dependencies: [
        QUERY_BOOKKEEPING_TRANSACTIONS,
        QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_INCOME,
        QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_EXPENSE,
        QUERY_BOOKKEEPING_PROFIT_AND_LOSS,
        QUERY_USERS_ACCOUNTS
      ]
    }
  );

export const useUpdateAccounts = () => {
  const [updateAccount] = useUpdateAccount();
  const [isLoading, setIsLoading] = useState(false);

  return {
    updateAccounts: async (
      accounts: Array<IAccountUpdateRequest & { accountId: string }>
    ) => {
      setIsLoading(true);
      await Promise.all(accounts.map(params => updateAccount(params)));
      await queryCache.refetchQueries(QUERY_USERS_ACCOUNTS);
      setIsLoading(false);
    },
    updateAccountsLoading: isLoading
  };
};

export const useUpdateAccount = () =>
  useWSMutation(
    async (props: IAccountUpdateRequest & { accountId: string }) => {
      const { accountId, ...data } = props;

      return await usersService.account.update(accountId, data);
    },
    {
      dependencies: [
        QUERY_BOOKKEEPING_TRANSACTIONS,
        QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_INCOME,
        QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_EXPENSE,
        QUERY_BOOKKEEPING_PROFIT_AND_LOSS,
        QUERY_USERS_ACCOUNTS
      ]
    }
  );

export const useCreateAccount = () =>
  useWSMutation(usersService.account.create, {
    dependencies: [
      QUERY_BOOKKEEPING_TRANSACTIONS,
      QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_INCOME,
      QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_EXPENSE,
      QUERY_BOOKKEEPING_PROFIT_AND_LOSS,
      QUERY_USERS_ACCOUNTS
    ]
  });

export const useCreateUser = () => {
  const callWithCaptcha = useCaptcha("createUser");
  return useWSMutation(
    (data: IUserCreateRequest & { inviteCode?: string; context?: string }) => {
      const isPublicSignUp = data.context === "PublicSignUp";
      return callWithCaptcha((captchaToken, captchaVersion, forceCaptcha) =>
        usersService.user.create(
          {
            captchaToken,
            captchaVersion,
            forceCaptcha,
            ...data
          },
          isPublicSignUp
        )
      );
    },
    {
      dependencies: [QUERY_USERS_USER_PROFILE]
    }
  );
};

export const useCreateClient = (userId: string) =>
  useWSMutation(
    (data: IClientCreateRequest) => usersService.client.create(userId, data),
    {
      dependencies: [QUERY_USERS_CLIENT]
    }
  );

export const useCreateClientWithoutUserId = () =>
  useWSMutation(
    (params: { userId: string; data: IClientCreateRequest }) =>
      usersService.client.create(params.userId, params.data),
    {
      dependencies: [QUERY_USERS_CLIENT]
    }
  );

export const useUpdateClient = (userId: string) =>
  useWSMutation(
    (data: IClientUpdateRequest) => usersService.client.update(userId, data),
    {
      dependencies: [QUERY_USERS_CLIENT]
    }
  );

export const useRequestPasswordReset = (redirectTo: string) => {
  const history = useHistory();
  const callWithCaptcha = useCaptcha("resetPassword");
  const snackbar = useWSSnackbar();

  return useWSMutation(
    (request: IPasswordResetRequest) =>
      callWithCaptcha((captchaToken, captchaVersion, forceCaptcha) =>
        usersService.user.passwordReset.create({
          captchaToken,
          captchaVersion,
          forceCaptcha,
          ...request
        } as any)
      ),

    {
      onSuccess: () => {
        history.replace(redirectTo);
        snackbar.openSnackbar({
          type: "success",
          message: "If you have an account we’ll email you a reset link"
        });
      },
      onSettled: () => {
        track("Request reset password");
      }
    }
  );
};

export const useSetPassword = () =>
  useWSMutation(
    (request: any) => usersService.user.update(request.userId, request),
    {
      onSuccess: () => {
        addNotification({
          text:
            "Your password has been successfully updated. Please, sign in to continue.",
          status: NotificationStatus.success,
          timeout: 4000
        });
      }
    }
  );

export const useSignInWithGoogle = () =>
  useWSMutation(
    async (
      request: ISignInWithGoogleRequest & {
        inviteCode?: string;
        context?: string;
      }
    ) => {
      const isPublicSignUp = request.context === "PublicSignUp";
      const session = await usersService.socialAuth.signInWithGoogle(
        request,
        isPublicSignUp
      );
      track("Log In with Google");

      return session;
    },
    {
      throwOnError: false,
      dependencies: [QUERY_USERS_SESSION]
    }
  );

export const useCreateMember = (userId: string) =>
  useWSMutation(
    (request: IMemberCreateRequest) =>
      usersService.member.create(userId, request),
    {
      dependencies: [QUERY_USERS_MEMBER_PROFILE]
    }
  );

export const useCreateAuthorization = () =>
  useWSMutation(
    (request: IAuthorizationCreateRequest) =>
      usersService.authorization.create(request),
    {
      dependencies: [QUERY_AUTHORIZATIONS, QUERY_AUTHORIZATIONS_INFINITE]
    }
  );

export const useDeleteAuthorization = () =>
  useWSMutation((id: string) => usersService.authorization.delete(id), {
    dependencies: [QUERY_AUTHORIZATIONS, QUERY_AUTHORIZATIONS_INFINITE]
  });

export const useBeginPhoneVerification = (memberId: string) =>
  useWSMutation(
    (request: IBeginPhoneVerificationRequest) =>
      usersService.user.phoneVerification.begin(memberId, request),
    {
      dependencies: [QUERY_USERS_USER_PROFILE]
    }
  );

export const useCompletePhoneVerification = (memberId: string) =>
  useWSMutation(
    (request: ICompletePhoneVerificationRequest) =>
      usersService.user.phoneVerification.complete(memberId, request),
    {
      dependencies: [QUERY_USERS_USER_PROFILE]
    }
  );

export const useBeginEmailVerification = (memberId: string) =>
  useWSMutation(
    (request: IBeginEmailVerificationRequest) =>
      usersService.user.emailVerification.begin(memberId, request),
    {
      dependencies: [QUERY_USERS_USER_PROFILE]
    }
  );

export const useBeginExistingEmailVerification = (memberId: string) =>
  useWSMutation(
    () => usersService.user.emailVerification.existingEmail(memberId),
    {
      dependencies: [QUERY_USERS_USER_PROFILE]
    }
  );

export const useCompleteEmailVerification = (memberId: string) =>
  useWSMutation(
    (request: ICompleteEmailVerificationRequest) =>
      usersService.user.emailVerification.complete(memberId, request),
    {
      dependencies: [QUERY_USERS_USER_PROFILE]
    }
  );

export const useGetGuestAccountNumbers = () =>
  useWSMutation((request: { accountId: string; publicToken: string }) =>
    usersService.guest.accountNumbers(request)
  );

export const useRequestOrganizationUser = () => {
  return useWSMutation(
    (userId: string) => usersService.organization.users.session(userId),
    {
      dependencies: [QUERY_USERS_SESSION]
    }
  );
};
