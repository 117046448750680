const USDFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
});
const moneyFormatter: Intl.NumberFormat = USDFormat;

export const WSMoneyFormats = ["default", "bookkeeping", "taxes"] as const;
export type WSMoneyFormatsType = typeof WSMoneyFormats[number];

export type WSMoneyObject = {
  toString: () => string;
  formattedValue: string;
  value: number;
};

export const toWSMoney = (
  value: any,
  type: WSMoneyFormatsType = "default"
): WSMoneyObject => {
  try {
    value = Number(value.toString());

    if (Number.isNaN(value)) {
      throw new Error("Formatter toWSMoney handled invalid value");
    }
  } catch {
    value = 0.0;
  }

  let formattedValue = moneyFormatter.format(value);
  if (value > 0 && type !== "default") {
    formattedValue = `+${formattedValue}`;
  }

  if (formattedValue === "-$0.00") {
    formattedValue = "$0.00";
  }

  return {
    toString: () => formattedValue,
    formattedValue,
    value
  };
};

export const toWSMoneyString = (
  value: any,
  type: WSMoneyFormatsType = "default"
): string => toWSMoney(value, type).formattedValue;
