import { WSList } from "@wingspanhq/fe-component-library";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../../../query/hooks/useFeatureFlags";
import { useQueryCustomerEntity } from "../../../../../query/onboarding/queries/useQueryCustomerEntity";
import {
  useMemberProfile,
  useUserProfile
} from "../../../../../query/users/queries";
import { ReviewAccountType } from "../../../../Onboarding/components/ReviewAccountType";
import { ReviewPerson } from "../../../../Onboarding/components/ReviewPerson";
import { InfoBoxChangeRequest } from "../components/InfoBoxChangeRequest";

type Props = {};

export const RoutePersonalInformation: React.FC<Props> = () => {
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const queryMonitoredEntity = useQueryCustomerEntity();
  const queryFeatureFlags = useFeatureFlags();

  return (
    <WSList gap="2XL">
      {queryFeatureFlags.data?.monitoredEntityChange && (
        <InfoBoxChangeRequest />
      )}

      <ReviewAccountType
        monitoredEntity={queryMonitoredEntity.data}
        member={queryMember.data}
      />

      <ReviewPerson
        title="Personal information"
        type="Individual"
        user={queryUser.data}
        member={queryMember.data}
      />
    </WSList>
  );
};
