import {
  toCamelCase,
  WSButton,
  WSElement
} from "@wingspanhq/fe-component-library";
import cn from "classnames";
import React, { useState } from "react";
import styles from "./Hidden.module.scss";

type Props = {
  defaultVisible?: boolean;
  showLabel?: string;
  hideLabel?: string;
  name?: string;
};

export const Hidden: React.FC<Props> = ({
  defaultVisible,
  showLabel,
  hideLabel,
  children,
  name
}) => {
  const [visible, setVisible] = useState(defaultVisible || false);

  return (
    <>
      <WSElement className={cn({ [styles.hidden]: !visible })}>
        {children}
      </WSElement>
      <WSButton.Link
        onClick={() => {
          setVisible(!visible);
        }}
        type="button"
        name={name ? toCamelCase(name, visible ? "hide" : "show") : undefined}
      >
        {visible ? hideLabel || "Hide" : showLabel || "Show"}
      </WSButton.Link>
    </>
  );
};
