import { IWebflowEnterprise } from "@wingspanhq/cms/dist/lib/interfaces";
import { IActivity } from "@wingspanhq/users/dist/lib/interfaces";
import {
  WHITE_LABEL_ADP,
  WHITE_LABEL_UNDERWRITER,
  WHITE_LABEL_VELOCITY_GLOBAL
} from "../../constants/whiteLabel";

export const selectorIsWhilteLabeled = (
  activity?: IActivity,
  enterprise?: IWebflowEnterprise
) =>
  activity?.context?.growthSourceName === WHITE_LABEL_UNDERWRITER ||
  activity?.context?.growthSourceName === WHITE_LABEL_ADP ||
  activity?.context?.growthSourceName === WHITE_LABEL_VELOCITY_GLOBAL ||
  (enterprise?.logo?.src && enterprise?.isWhiteLabelLogo);
