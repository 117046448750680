import {
  ICustomField,
  WSDataTypes
} from "@wingspanhq/payments/dist/interfaces";
import * as Yup from "yup";

export function getYupValidationSchema(formFields: ICustomField[]) {
  const validationSchema: any = {};
  formFields.forEach(field => {
    let validationRule: any = Yup.string();
    switch (field.type) {
      case WSDataTypes.String:
        if (field.required) {
          validationRule = validationRule.required(`${field.name} is required`);
        }
        break;
      case WSDataTypes.Boolean:
        validationRule = Yup.boolean();
        if (field.required) {
          validationRule = validationRule.required(`${field.name} is required`);
        }
        break;
      default:
        if (field.required) {
          validationRule = validationRule.required(`${field.name} is required`);
        }
    }
    validationSchema[field.key] = validationRule;
  });
  return validationSchema;
}

export function getDefaultValuesForAdditionalData(
  formFields: ICustomField[],
  labels?: { [key: string]: string | null | boolean }
) {
  const defaultValues: any = {};
  formFields.forEach(field => {
    switch (field.type) {
      case WSDataTypes.String:
        defaultValues[field.key] = labels?.[field.key] || "";
        break;
      case WSDataTypes.Boolean:
        defaultValues[field.key] = labels?.[field.key]
          ? labels[field.key] === "true"
          : false;
        break;
    }
  });
  return defaultValues;
}

export function prepareCollaboratorLabelsRequest(
  formFields: ICustomField[],
  values: { [key: string]: any }
) {
  const labels: any = {};
  formFields.forEach(field => {
    switch (field.type) {
      case WSDataTypes.String:
        labels[field.key] = values[field.key] ? String(values[field.key]) : "";
        break;

      case WSDataTypes.Boolean:
        labels[field.key] = values[field.key] === true ? "true" : "false";
        break;
    }
  });
  return labels;
}

function booleanFieldToFilterRequest(filterValue: string[]) {
  if (filterValue.length === 1) {
    if (filterValue[0] === "true") {
      return "true";
    } else {
      return {
        "!=": "true"
      };
    }
  }

  return;
}

function stringFieldToFilterRequest(filterValue: string) {
  return {
    contains: filterValue
  };
}

export function customFieldToFiltersRequest(
  customField: ICustomField,
  filterValue?: string | string[]
) {
  if (!filterValue) {
    return;
  }

  switch (customField.type) {
    case WSDataTypes.Boolean:
      if (Array.isArray(filterValue)) {
        return booleanFieldToFilterRequest(filterValue);
      } else {
        console.error("Boolean field should be an array");
        return;
      }
    default:
      if (typeof filterValue === "string") {
        return stringFieldToFilterRequest(filterValue);
      } else {
        console.error("String field should be a string");
      }
  }
}
