import { IAdditionalData } from "@wingspanhq/payments/dist/interfaces";
import { customFieldsService } from "../../../services/customFields";
import { useWSMutation } from "../../helpers";
import { QUERY_CUSTOM_FIELDS_ALL } from "../keys";

export const useCreateCustomField = () =>
  useWSMutation(
    (payload: IAdditionalData) =>
      customFieldsService.customField.create(payload),
    {
      dependencies: [QUERY_CUSTOM_FIELDS_ALL]
    }
  );
