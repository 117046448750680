import { WSFlexBoxProps } from "@wingspanhq/fe-component-library";
import React, { useEffect, useState } from "react";
import { filesService } from "../../services/files";

export type PrivateURLStatus = "loading" | "success" | "fail";

export type PrivateURLProps = WSFlexBoxProps & {
  fileId?: string;
  children(status: PrivateURLStatus, url: string, type: string): any;
};

export const PrivateURL: React.FC<PrivateURLProps> = ({ fileId, children }) => {
  const [state, setState] = useState<{
    url: string;
    type: string;
    status: PrivateURLStatus;
  }>({ url: "", type: "", status: "loading" });

  useEffect(() => {
    if (fileId) {
      (async () => {
        try {
          setState({ url: "", type: "", status: "loading" });
          const file = await filesService.member.private.get(fileId);
          const data = await filesService.member.private.download(fileId);

          const arrayBufferView = new Uint8Array(data);

          const url = window.URL.createObjectURL(
            new Blob([arrayBufferView], {
              type: file.mimetype
            })
          );

          setState({
            url,
            type: file.mimetype || "",
            status: "success"
          });
        } catch {
          setState({ url: "", type: "", status: "fail" });
        }
      })();
    } else {
      setState({ url: "", type: "", status: "loading" });
    }

    return () => {
      state.url && window.URL.revokeObjectURL(state.url);
    };
  }, [fileId]);

  return children(state.status, state.url, state.type);
};
