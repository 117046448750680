import { useUserProfileData } from "./utils/useUserProfileData";
import { isUserIdsIncluded } from "./utils/isUserIdsIncluded";
import { TEAMS_MANAGED_BY_ORGANIZATION } from "./helpers";

export const useTeamsManagedByOrganization = () => {
  const userData = useUserProfileData();
  const isTeamsManagedByOrganization = isUserIdsIncluded(
    userData,
    TEAMS_MANAGED_BY_ORGANIZATION
  );

  return isTeamsManagedByOrganization;
};
