import { queryCache, QueryConfig } from "react-query";
import {
  concurrentActions,
  WSServiceError
} from "../../../utils/serviceHelper";
import { useWSQuery } from "../../../query/helpers";
import { paymentsService } from "../../../services/payments";
import { IInvoice } from "@wingspanhq/payments/dist/interfaces";
import { QUERY_INVOICE } from "../../../query/payments/keys";

export const QUERY_PAYOUT_INVOICES_DETAILS_BY_IDS =
  "QUERY_PAYOUT_INVOICES_DETAILS_BY_IDS";

export const usePayoutInvoicesDetails = (
  invoicesIds: string[],
  config?: QueryConfig<IInvoice[], WSServiceError>
) =>
  useWSQuery<IInvoice[], WSServiceError>(
    [QUERY_PAYOUT_INVOICES_DETAILS_BY_IDS, invoicesIds],
    async () => {
      const actions = invoicesIds.map(invoiceId => async () => {
        const cachedInvoiceDetail = queryCache.getQueryData<IInvoice>([
          QUERY_INVOICE,
          invoiceId
        ]);

        if (cachedInvoiceDetail) {
          return cachedInvoiceDetail;
        }

        const invoiceDetails = await paymentsService.invoice.get(invoiceId);

        queryCache.setQueryData([QUERY_INVOICE, invoiceId], invoiceDetails);

        return invoiceDetails;
      });

      const allInvoicesDetails = await concurrentActions(actions);

      return allInvoicesDetails;
    },
    config
  );
