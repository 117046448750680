import React from "react";
import { useHistory } from "react-router-dom";
import { WSButton, WSButtonProps } from "@wingspanhq/fe-component-library";

interface ButtonLinkProps extends WSButtonProps<"Link"> {
  url: string;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({ url, children, ...props }) => {
  const history = useHistory();

  return (
    <WSButton.Link onClick={() => history.push(url)} {...props}>
      {children}
    </WSButton.Link>
  );
};

export default ButtonLink;
