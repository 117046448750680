import React, { useContext } from "react";

import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSIcon } from "../WSIcon/WSIcon.component";
import { useFormContext } from "react-hook-form";
import { WSFormFieldContext } from "./WSFormFieldContext";
import { WSText } from "../WSText/WSText.component";
import styles from "./WSFormError.module.scss";
import get from "lodash/get";

interface WSFormFieldErrorProps extends WSElementProps {
  name?: string;
}

export const WSFormError: React.FC<WSFormFieldErrorProps> = ({ name }) => {
  const nameFromContext = useContext(WSFormFieldContext);
  const { errors } = useFormContext();

  const errorName = name || nameFromContext;

  if (!get(errors, errorName)?.message) {
    return null;
  }

  return (
    <WSElement className={styles.error}>
      <WSIcon
        block
        name="alert-circle"
        size="XS"
        className={styles.errorIcon}
      />
      <WSText.ParagraphSm className={styles.errorText}>
        {get(errors, errorName).message}
      </WSText.ParagraphSm>
    </WSElement>
  );
};
