import { updateAcknowledgement } from "../../../services/api/onboarding/acknowledgements";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation } from "../../helpers";
import { QUERY_ACKNOWLEDGEMENT } from "../queries/useQueryAcknowledgement";

export const useMutationUpdateAcknowledgement = () =>
  useWSMutation<
    Awaited<ReturnType<typeof updateAcknowledgement>>,
    WSServiceError,
    {
      acknowledgementName: Parameters<typeof updateAcknowledgement>[0];
      requestData: Parameters<typeof updateAcknowledgement>[1];
    }
  >(
    ({ acknowledgementName, requestData }) =>
      updateAcknowledgement(acknowledgementName, requestData),
    {
      dependencies: [QUERY_ACKNOWLEDGEMENT]
    }
  );
