import { ITransactionGroup } from "@wingspanhq/bookkeeping/dist/lib/interfaces/insights";
import {
  toWSMoney,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import { IAccount } from "@wingspanhq/users";
import classNames from "classnames";
import camelCase from "lodash/camelCase";
import startCase from "lodash/startCase";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";
import React, { useEffect, useState } from "react";
import { useUpdateTransaction } from "../../../query/bookkeeping/mutations";
import styles from "./CatchUpTransactionForm.module.scss";
import { WSCategories } from "../../components/WSCategories/WSCategories";
import {
  TransactionType,
  WSCategory
} from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";
import { useHistory, useLocation } from "react-router-dom";

type Props = {
  transactionGroups: ITransactionGroup[];
  onChange(data: ITransactionGroup[]): void;
  accounts: IAccount[];
  showCategory?: boolean;
  type: TransactionType;
};
export const CatchUpTransactionForm: React.FC<Props> = ({
  transactionGroups = [],
  onChange,
  accounts,
  showCategory,
  type
}) => {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState<{ [key: string]: boolean }>(
    transactionGroups.length === 1 ? { [transactionGroups[0].group]: true } : {}
  );

  useEffect(() => {
    setOpen(
      transactionGroups.length === 1
        ? { [transactionGroups[0].group]: true }
        : {}
    );
  }, [transactionGroups[0]?.group]);

  const [updateTransaction] = useUpdateTransaction();

  return (
    <div className={styles.wrapper}>
      {transactionGroups.map(transactionGroup => {
        const isChecked = transactionGroup.transactions.every(t => t.business);
        let name = transactionGroup.group;
        const account = accounts.find(
          a => a.accountId === transactionGroup.group
        ) as IAccount;

        if (account) {
          name = `${account.institution} ${startCase(
            camelCase(account.subType)
          )} |  • • • • ${account.mask || ""}`;
        }

        const category = showCategory
          ? transactionGroup.transactions[0]?.wsCategory
          : false;

        const subcategory = showCategory
          ? transactionGroup.transactions[0]?.labels?.subcategory
          : false;

        return (
          <WSElement className={styles.group} key={transactionGroup.group}>
            <WSElement className={styles.groupHeader}>
              <WSElement
                p="M"
                onClick={() => {
                  onChange(
                    transactionGroups.map(group => {
                      if (group.group === transactionGroup.group) {
                        return {
                          ...group,
                          transactions: group.transactions.map(t => ({
                            ...t,
                            business: !isChecked
                          }))
                        };
                      } else {
                        return group;
                      }
                    })
                  );
                }}
              >
                <WSElement
                  className={classNames(styles.checkbox, {
                    [styles.checked]: isChecked
                  })}
                  data-testid={`catch-up-transaction-group-${name}`}
                />
              </WSElement>
              <div
                className={styles.groupHeaderBody}
                onClick={() =>
                  setOpen(prev => ({
                    ...prev,
                    [transactionGroup.group]: !prev[transactionGroup.group]
                  }))
                }
              >
                <div>
                  <h4 className={styles.groupTitle}>{name}</h4>
                  <span className={styles.description}>
                    {transactionGroup.transactions.length} transactions |{" "}
                    {toWSMoney(
                      transactionGroup.transactions.reduce<number>((acc, t) => {
                        return (
                          acc +
                          (type === TransactionType.Income
                            ? Math.abs(t.amount)
                            : t.amount)
                        );
                      }, 0)
                    ).toString()}
                  </span>
                </div>
                {category ? (
                  <WSCategories
                    header={name}
                    data={{
                      type: transactionGroup.transactions[0].type,
                      wsCategory: transactionGroup.transactions[0]
                        .wsCategory as WSCategory,
                      subcategory: transactionGroup.transactions[0].labels
                        ?.subcategory as string,
                      isPositive: transactionGroup.transactions[0].amount > 0,
                      business: transactionGroup.transactions[0].business
                    }}
                    renderTrigger={({ isLoading, open }) => {
                      if (isLoading) {
                        return (
                          <WSFlexBox.CenterY
                            mr="M"
                            px="M"
                            py="XS"
                            wrap="nowrap"
                            className={styles.category}
                            shimmer
                          >
                            <WSText.ParagraphSm
                              className={styles.categoryText}
                              color="gray500"
                            >
                              Category
                            </WSText.ParagraphSm>
                            <WSIcon
                              block
                              name="exit"
                              size="XS"
                              ml="M"
                              color="gray500"
                            />
                          </WSFlexBox.CenterY>
                        );
                      }

                      return (
                        <WSFlexBox.CenterY
                          mr="M"
                          px="M"
                          py="XS"
                          wrap="nowrap"
                          className={styles.category}
                          onClick={event => {
                            event.stopPropagation();
                            open();
                          }}
                        >
                          <WSText.ParagraphSm
                            className={styles.categoryText}
                            color="gray500"
                          >
                            {category}
                            {subcategory ? (
                              <>
                                <br />
                                {subcategory}
                              </>
                            ) : null}
                          </WSText.ParagraphSm>
                          <WSIcon
                            block
                            name="exit"
                            size="XS"
                            ml="M"
                            color="gray500"
                          />
                        </WSFlexBox.CenterY>
                      );
                    }}
                    onUpdate={async formData => {
                      const newTransactionGroup = {
                        ...transactionGroup,
                        transactions: transactionGroup.transactions.map(t => {
                          const amount =
                            Math.abs(t.amount || 0) *
                            (formData.isPositive ? 1 : -1);

                          return {
                            ...t,
                            wsCategory: formData.wsCategory as WSCategory,
                            type: (formData.type as any) as TransactionType,
                            amount,
                            businessAmount: amount,
                            business: formData.business,
                            labels: {
                              ...(formData.subcategory
                                ? { subcategory: formData.subcategory }
                                : null)
                            }
                          };
                        })
                      };

                      if (newTransactionGroup.transactions.length) {
                        let i = 0;
                        while (newTransactionGroup.transactions[i]) {
                          const transaction =
                            newTransactionGroup.transactions[i];
                          ++i;
                          await updateTransaction({
                            transactionId: transaction.transactionId,
                            wsCategory: transaction.wsCategory,
                            amount: transaction.amount,
                            labels: transaction.labels,
                            type: transaction.type,
                            businessAmount: transaction.businessAmount,
                            business: transaction.business
                          });
                        }

                        onChange(
                          transactionGroups.map(g => {
                            if (g.group === newTransactionGroup.group) {
                              return {
                                ...g,
                                transactions: [
                                  ...newTransactionGroup.transactions
                                ]
                              };
                            } else {
                              return g;
                            }
                          })
                        );
                      }
                    }}
                  />
                ) : null}
              </div>
              <div
                className={styles.action}
                onClick={() =>
                  setOpen(prev => ({
                    ...prev,
                    [transactionGroup.group]: !prev[transactionGroup.group]
                  }))
                }
              >
                <WSIcon
                  block
                  name={
                    open[transactionGroup.group] ? "chevron-up" : "chevron-down"
                  }
                  size="XS"
                  className={styles.actionIcon}
                  style={{
                    opacity: transactionGroup.transactions.length === 1 ? 0 : 1
                  }}
                />
              </div>
            </WSElement>
            {open[transactionGroup.group] &&
            transactionGroup.transactions.length !== 1 ? (
              <div className={styles.transactions}>
                {transactionGroup.transactions.map(transaction => {
                  return (
                    <WSFlexBox.CenterY
                      key={transaction.transactionId}
                      alignItems="stretch"
                      data-testid={`catch-up-transaction-${transaction.transactionId}`}
                    >
                      <WSElement
                        p="M"
                        onClick={() => {
                          onChange(
                            transactionGroups.map(group => {
                              return {
                                ...group,
                                transactions: group.transactions.map(t => {
                                  if (
                                    t.transactionId ===
                                    transaction.transactionId
                                  ) {
                                    return {
                                      ...t,
                                      business: !t.business
                                    };
                                  } else {
                                    return t;
                                  }
                                })
                              };
                            })
                          );
                        }}
                      >
                        <WSElement
                          className={classNames(styles.checkbox, {
                            [styles.checked]: transaction.business
                          })}
                        />
                      </WSElement>
                      <WSFlexBox.CenterY
                        justify="space-between"
                        className={styles.transactionBody}
                        onClick={() => {
                          history.push(
                            `${location.pathname}/${transaction.transactionId}`
                          );
                        }}
                      >
                        <WSElement>
                          <WSText
                            weight="medium"
                            className={styles.transactionTitle}
                          >
                            {moment(transaction.date).format("MM/DD/YYYY")}
                          </WSText>
                          <span className={styles.description}>
                            {transaction.labels.overrideName ||
                              transaction.merchantName ||
                              transaction.name ||
                              "Unknown"}
                          </span>
                        </WSElement>
                        <WSText formatMoney>
                          {type === TransactionType.Income
                            ? Math.abs(transaction.amount)
                            : transaction.amount}
                        </WSText>
                      </WSFlexBox.CenterY>
                    </WSFlexBox.CenterY>
                  );
                })}
              </div>
            ) : null}
          </WSElement>
        );
      })}
    </div>
  );
};
