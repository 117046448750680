import { initSheetJS } from "./initSheetJS";

export const downloadXLSX = async (
  data: Array<Array<any>> | Array<object>,
  filename: string,
  sheetName?: string
) => {
  const XLSX = await initSheetJS();

  const wb = XLSX.utils.book_new();
  let ws;
  if (Array.isArray(data[0])) {
    ws = XLSX.utils.aoa_to_sheet(data);
  } else {
    ws = XLSX.utils.json_to_sheet(data);
  }

  XLSX.utils.book_append_sheet(wb, ws, sheetName || "Sheet");
  XLSX.writeFile(wb, `${filename}.xlsx`);
};
