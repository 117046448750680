import cn from "classnames";
import React from "react";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSText } from "../../core/WSText/WSText.component";
import { WSGrid } from "../WSGrid/WSGrid.component";
import { WSScreen } from "../WSScreen/WSScreen.component";
import styles from "./WSSectionContainer.module.scss";

export const WSSectionContainerKinds = ["Default"] as const;
type WSSectionContainerKindsType = typeof WSSectionContainerKinds[number];

export interface WSSectionContainerSection {
  title: string;
  content: React.ReactNode;
}

export interface WSSectionContainerProps extends WSElementProps {
  kind?: WSSectionContainerKindsType;
  rootTitle?: string;
  sections: WSSectionContainerSection[];
}

export const WSSectionContainer: React.FC<WSSectionContainerProps> & {} = ({
  kind = "Default",
  rootTitle = "All",
  sections,
  className,
  children,
  ...componentProps
}) => {
  const classNames = cn(className, styles.base, styles[kind]);
  return (
    <WSElement as="div" className={classNames} {...componentProps}>
      <WSScreen.Mobile>
        <WSText>{rootTitle}</WSText>
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        <WSGrid>
          <WSGrid.Item span={{ m: "6" }}>
            <WSText>{rootTitle}</WSText>
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "6" }}>
            <WSText>{rootTitle}</WSText>
          </WSGrid.Item>
        </WSGrid>
      </WSScreen.TabletAndDesktop>
    </WSElement>
  );
};
