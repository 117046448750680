import { Route, Switch } from "react-router-dom";
import { RouteCustomFields } from "../modules/CustomFields/routes/RouteCustomFields";

export const Data = () => {
  return (
    <Switch>
      <Route path="/member/data/custom-fields" component={RouteCustomFields} />
    </Switch>
  );
};
