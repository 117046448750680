import {
  WSContainer,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSIcon,
  WSLayout,
  WSNavHistoryList,
  WSText
} from "@wingspanhq/fe-component-library";
import { WSNavHistoryListItem } from "@wingspanhq/fe-component-library/dist/lib/components/common/WSNavHistoryList";
import React, { ReactNode } from "react";
import { useBrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import { LogoApp } from "../../../../shared/components/LogoApp";
import styles from "./Main1099Layout.module.scss";

interface NEC1099FilingContainerProps {
  sections?: WSNavHistoryListItem[];
  activeSection?: WSNavHistoryListItem["key"];
  title: string;
  children: ReactNode;
  onSaveAndExit?: () => void;
  headerRight?: ReactNode;
  rightPanel?: ReactNode;
}

export const Main1099LayoutModule: React.FC<NEC1099FilingContainerProps> = ({
  title,
  children,
  onSaveAndExit,
  headerRight,
  sections,
  rightPanel,
  activeSection
}) => {
  useBrowserPageTitle(title);

  return (
    <WSLayout
      headerRight={headerRight}
      headerLeft={
        onSaveAndExit ? (
          <WSFlexBox.CenterY onClick={onSaveAndExit}>
            <WSIcon
              block
              mr="XS"
              size="XS"
              name="chevron-left"
              color="gray600"
            />
            <WSText.ParagraphSm color="gray500">Back</WSText.ParagraphSm>
          </WSFlexBox.CenterY>
        ) : (
          undefined
        )
      }
      headerCenter={<LogoApp />}
      title={title}
      line
    >
      <WSContainer verticalPadding>
        <WSGrid>
          <WSGrid.Item span={{ m: rightPanel ? "12" : "3", l: "3" }}>
            {sections && activeSection !== undefined ? (
              <WSElement className={styles.sideNav}>
                <WSNavHistoryList items={sections} active={activeSection} />
              </WSElement>
            ) : null}
          </WSGrid.Item>
          <WSGrid.Item span={{ m: rightPanel ? "12" : "6", l: "6" }}>
            {children}
          </WSGrid.Item>
          <WSGrid.Item span={{ m: rightPanel ? "12" : "3", l: "3" }}>
            {rightPanel}
          </WSGrid.Item>
        </WSGrid>
      </WSContainer>

      <WSElement pb="3XL" pt="XL" />
    </WSLayout>
  );
};
