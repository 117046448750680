export const getSsnInputProps = (options?: {
  showPlaceholder?: boolean;
  isValueProvided?: boolean;
}) => ({
  placeholder: options?.showPlaceholder
    ? options?.isValueProvided
      ? "•••••••••"
      : "Social Security Number"
    : undefined,
  mask: "999999999",
  autoComplete: "off",
  fsExclude: true
});
