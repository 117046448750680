import { IInvoicingConfig } from "@wingspanhq/payments/dist/interfaces";
import { servicePayments } from "../servicePayments";

export const getInvoicingConfigForPayee = async (
  payerId: string
): Promise<IInvoicingConfig> => {
  const { data } = await servicePayments.get(
    `/payer/${payerId}/invoicing-config`
  );
  return data;
};
