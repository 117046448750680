import { queryCache, QueryConfig } from "react-query";
import {
  concurrentActions,
  WSServiceError
} from "../../../utils/serviceHelper";
import { useWSQuery } from "../../../query/helpers";
import { ISignedDocumentResponse } from "@wingspanhq/signed-documents/dist/lib/interfaces";
import { DataSourceType } from "@wingspanhq/payments/dist/interfaces/requirement";
import { QUERY_SIGNED_DOCUMENT } from "../../../query/signedDocuments/keys";
import { getSignedDocument } from "../../../services/signedDocuments";
import { IRequirementResponse } from "@wingspanhq/payments/dist/interfaces/api/requirement";
import { IRequirementDefinitionResponse } from "@wingspanhq/payments/dist/interfaces";
import { QUERY_REQUIREMENT_DEFINITION } from "../../../query/requirementDefinitions/keys";
import { getRequirement } from "../../../services/requirementDefinitions/getRequirement";

export const QUERY_ALL_REQUIREMENT_DEFINITIONS_WITH_SIGNED_DOCUMENTS_ITEMS =
  "QUERY_ALL_REQUIREMENT_DEFINITIONS_WITH_SIGNED_DOCUMENTS_ITEMS";

export type IRequirementWithDocument = IRequirementDefinitionResponse & {
  document: ISignedDocumentResponse;
};

export const useQueryAllRequirementDefinitionsWithSignedDocumentsItems = (
  requirements: IRequirementResponse[],
  payeeId: string,
  config?: QueryConfig<IRequirementWithDocument[], WSServiceError>
) => {
  return useWSQuery<IRequirementWithDocument[], WSServiceError>(
    [
      QUERY_ALL_REQUIREMENT_DEFINITIONS_WITH_SIGNED_DOCUMENTS_ITEMS,
      payeeId,
      requirements.map(requirement => requirement.requirementId).join(",")
    ],
    async () => {
      const fetchRequirementsDocumentsActions = requirements.map(
        (requirement, i) => async () => {
          if (
            requirement.dataSourceType === DataSourceType.SignedDocument &&
            requirement.dataSourceId
          ) {
            const documentInCache = queryCache.getQueryData<
              ISignedDocumentResponse
            >([QUERY_SIGNED_DOCUMENT, requirement.dataSourceId]);

            if (documentInCache) {
              return documentInCache;
            } else {
              try {
                const document = await getSignedDocument(
                  requirement.dataSourceId
                );
                queryCache.setQueryData(
                  [QUERY_SIGNED_DOCUMENT, requirement.dataSourceId],
                  document
                );

                return document;
              } catch (e) {
                console.error(e);
              }
            }
          }
        }
      );

      const documentsLists = await concurrentActions<
        ISignedDocumentResponse | undefined
      >(fetchRequirementsDocumentsActions, {
        concurrentLimit: 5
      });

      const fetchRequirementsDefinitionsActions = requirements.map(
        (requirement, i) => async () => {
          if (requirement.requirementDefinitionId) {
            const requirementDefinitionInCache = queryCache.getQueryData<
              IRequirementDefinitionResponse
            >([
              QUERY_REQUIREMENT_DEFINITION,
              requirement.requirementDefinitionId
            ]);

            if (requirementDefinitionInCache) {
              return requirementDefinitionInCache;
            } else {
              try {
                const requirementDefinition = await getRequirement(
                  requirement.requirementDefinitionId
                );
                queryCache.setQueryData(
                  [
                    QUERY_REQUIREMENT_DEFINITION,
                    requirement.requirementDefinitionId
                  ],
                  requirementDefinition
                );

                return requirementDefinition;
              } catch (e) {
                console.error(e);
              }
            }
          }
        }
      );

      const requirementList = await concurrentActions<
        IRequirementDefinitionResponse | undefined
      >(fetchRequirementsDefinitionsActions, {
        concurrentLimit: 5
      });

      const result = requirementList
        .map((requirement, index) => {
          return {
            ...requirement,
            document: documentsLists.find(
              document =>
                document?.signedDocumentId === requirements[index].dataSourceId
            )
          };
        })
        .filter(requirement => {
          const items = requirement.document?.responseItems?.filter(
            item => item.userId === payeeId
          );

          return items?.length && requirement.requirementDefinitionId;
        }) as IRequirementWithDocument[];

      return result;
    },
    {
      ...config
    }
  );
};
