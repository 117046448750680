import classNames from "classnames";
import React from "react";
import { WSElement } from "../WSElement/WSElement.component";
import { WSAvatar, WSPill } from "../common";
import { WSButton } from "../core/WSButton/WSButton.component";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import { WSText } from "../core/WSText/WSText.component";
import styles from "./WSSelect.module.scss";
import { WSSelectOption } from "./types";

type Props = { onClick?: () => void; isActive?: boolean } & WSSelectOption;

export const Option: React.FC<Props> = ({
  onClick,
  label,
  isActive,
  icon,
  avatar,
  labels,
  pill,
  action
}) => {
  return (
    <WSElement
      onClick={onClick}
      className={classNames(styles.option, isActive && styles.active)}
    >
      <WSElement className={styles.optionContent}>
        {icon ? (
          <WSIcon name={icon} size="S" color="gray600" />
        ) : avatar ? (
          <WSAvatar
            color="white"
            colorBackground="gray500"
            size="S"
            {...avatar}
          />
        ) : null}

        <WSElement className={styles.optionLabel}>
          <WSText.ParagraphSm color="gray600">{label}</WSText.ParagraphSm>
          {labels && (
            <WSText.ParagraphXs color="gray400">
              {labels?.join(" • ")}
            </WSText.ParagraphXs>
          )}
        </WSElement>

        {pill ? (
          <WSPill {...pill} />
        ) : action ? (
          <WSButton.Link onClick={action.onClick} size="S" type="button">
            {action.text}
          </WSButton.Link>
        ) : null}
      </WSElement>

      {isActive && <WSIcon name="check" size="S" color="blue400" />}
    </WSElement>
  );
};
