import {
  useModalContext,
  WSButton,
  WSButtons,
  WSCheckboxGroup,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSModal,
  WSPage,
  WSScreen,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import {
  AuthorizedAction,
  IUserCreateRequest,
  SubscriptionPackage,
  UserStatus
} from "@wingspanhq/users/dist/lib/interfaces";
import cn from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { BrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import { useCreateSubscriptionGrant } from "../../../../query/subscriptions/mutations";
import {
  useCreateAuthorization,
  useCreateUser
} from "../../../../query/users/mutations";
import { usersService } from "../../../../services/users";
import { goToParentRoute } from "../../../../utils/goToParentRoute";
import { WSServiceError } from "../../../../utils/serviceHelper";
import {
  validatorFirstName,
  validatorLastName
} from "../../../../utils/validators";
import {
  SubscriptionGrantOptionsModal,
  SUBSCRIPTION_GRANT_OPTIONS_MODAL
} from "../../components/SubscriptionGrantOptionsModal/SubscriptionGrantOptionsModal";
import { scopeGroups } from "../../../../shared/utils/teamUtils";
import styles from "./AddTeamMember.module.scss";
import { validatorEmail } from "../../../../shared/validators/validatorEmail";
import {
  SUBSCRIPTION_SELECT_OPTIONS,
  SubscriptionGrantOption
} from "../../constants/subscriptionOptions";

interface TwoLineLabelProps {
  title: string;
  scopes: string;
}

export const TwoLineLabel: React.FC<TwoLineLabelProps> = ({
  title,
  scopes
}) => {
  return (
    <WSFlexBox direction="column">
      <WSText singleLine>{title}</WSText>
      <WSText.ParagraphSm singleLine color="gray500">
        {scopes}
      </WSText.ParagraphSm>
    </WSFlexBox>
  );
};

interface AddTeamMemberProps {}

export const AddTeamMember: React.FC<AddTeamMemberProps> = () => {
  const [formError, setFormError] = React.useState("");
  const history = useHistory<{ permission: keyof typeof scopeGroups }>();

  const permissions =
    history.location.state?.permission &&
    scopeGroups[history.location.state?.permission]
      ? [history.location.state?.permission]
      : [];

  const [selectedSubscription, setSelectedSubscription] = React.useState(
    SUBSCRIPTION_SELECT_OPTIONS[0]
  );
  const { openModal } = useModalContext();
  const [
    createAuthorization,
    createAuthorizationMeta
  ] = useCreateAuthorization();
  const [createUser, createUserMeta] = useCreateUser();
  const [createSubscriptionGrant] = useCreateSubscriptionGrant();
  const [isModalOpened, setIsModalOpened] = React.useState(false);

  const onFormSubmit = async (data: any) => {
    createAuthorizationMeta.reset();
    createUserMeta.reset();
    const polishedData = {
      ...data,
      email: data.email?.trim(),
      firstName: data.firstName?.trim(),
      lastName: data.lastName?.trim()
    };
    if (
      selectedSubscription.value.package === SubscriptionPackage.None &&
      polishedData.permissions.length === 0
    ) {
      setFormError(
        "At least one of permissions or membership must be selected."
      );
      return;
    }
    const request: any = {
      allowedAction: AuthorizedAction.Write
    };
    if (polishedData.email) {
      try {
        const userByEmailRes = await usersService.user.email.get(
          polishedData.email
        );
        request.requestingUserId = userByEmailRes.userId;
      } catch (err) {
        if ((err as WSServiceError).response?.status === 404) {
          const payload: IUserCreateRequest = {
            email: polishedData.email,
            status: UserStatus.Pending,
            notificationSettings: {
              reviewNotifications: true,
              newsletters: true
            }
          };
          if (polishedData.firstName && polishedData.lastName) {
            payload.profile = {
              firstName: polishedData.firstName,
              lastName: polishedData.lastName
            };
          }
          const newUser = await createUser(payload, { throwOnError: true });
          request.requestingUserId = newUser?.userId as string;
        }
      }
    }
    if (request.requestingUserId) {
      await Promise.all(
        polishedData.permissions.map(async (scopeGroupId: string) => {
          const payload = {
            ...request,
            allowedScopeGroupId: scopeGroupId
          };
          await createAuthorization(payload);
        })
      );
    }

    /**
     * We don't grant subscription if None is selected. `None` option can be
     * used to delete the given subscription-grant.
     */
    const { value: subscriptionPkgProps } = selectedSubscription;
    if (subscriptionPkgProps.package !== SubscriptionPackage.None) {
      await createSubscriptionGrant({
        granteeId: request.requestingUserId,
        package: subscriptionPkgProps.package,
        packageTier: subscriptionPkgProps.packageTier
      });
    }
    goToParentRoute(history);
  };
  const addTeamMemberForm = (
    <WSFormOld
      onSubmit={onFormSubmit}
      defaultValues={{
        email: "",
        firstName: "",
        lastName: "",
        permissions,
        subscription: ""
      }}
      validationSchema={Yup.object().shape({
        email: validatorEmail.required("Email is required"),
        firstName: validatorFirstName,
        lastName: validatorLastName
      })}
    >
      <WSFormOld.Field
        mb="2XL"
        name="firstName"
        label="First name"
        component={WSTextInput}
      />

      <WSFormOld.Field
        mb="2XL"
        name="lastName"
        label="Last name"
        component={WSTextInput}
      />

      <WSFormOld.Field
        mb="2XL"
        name="email"
        label="Email"
        component={WSTextInput}
        componentProps={{
          type: "email"
        }}
      />

      <WSFormOld.Field name="permissions" mb="2XL">
        <WSText.Heading5 mb="M">Permissions</WSText.Heading5>
        <WSFormOld.Input
          render={props => (
            <>
              <WSCheckboxGroup
                {...props}
                options={Object.values(scopeGroups).map(
                  ({ title, scopes, scopeGroupId }) => ({
                    label: <TwoLineLabel title={title} scopes={scopes} />,
                    value: scopeGroupId
                  })
                )}
              />
              <WSFormOld.Error />
            </>
          )}
        />
      </WSFormOld.Field>

      <WSFlexBox direction="column" mb="XL">
        <WSText.Heading5 mb="M">Membership</WSText.Heading5>
        <WSFlexBox.CenterY
          className={styles.membership}
          p="S"
          onClick={() => {
            setIsModalOpened(true);
            openModal(SUBSCRIPTION_GRANT_OPTIONS_MODAL, {
              selectedSubscription,
              onChange: (sg: SubscriptionGrantOption) => {
                setFormError("");
                setSelectedSubscription(sg);
              },
              onCloseModal: () => {
                setIsModalOpened(false);
              },
              action: "Add"
            });
          }}
          justify="space-between"
          wrap="nowrap"
          data-testid="subscriptionGrantOptionsModal"
        >
          <WSFlexBox.CenterY
            direction="column"
            alignItems="flex-start"
            wrap="wrap"
          >
            <WSText mb="XS">{selectedSubscription.title}</WSText>
            <WSText.ParagraphSm>
              {selectedSubscription.description}
            </WSText.ParagraphSm>
          </WSFlexBox.CenterY>
          <WSIcon block name="caret-down" color="gray500" size="M" />
        </WSFlexBox.CenterY>
      </WSFlexBox>

      {formError && (
        <WSFlexBox mb="XL" wrap="nowrap">
          <WSIcon
            block
            name="alert-circle"
            size="XS"
            mr="XS"
            color="garnet"
            mt="XS"
          />
          <WSFlexBox wrap="wrap">
            <WSText.ParagraphSm weight="medium" color="gray700">
              {formError}
            </WSText.ParagraphSm>
          </WSFlexBox>
        </WSFlexBox>
      )}
      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <WSButtons format="modal">
            <WSButton.Primary
              className={styles.saveBtn}
              type="submit"
              loading={isSubmitting}
              name="save"
            >
              Save
            </WSButton.Primary>
            <WSButton.Tertiary
              onClick={() => goToParentRoute(history)}
              name="cancel"
            >
              Cancel
            </WSButton.Tertiary>
          </WSButtons>
        )}
      </WSFormOld.Context>
      {createUserMeta.isError && (
        <WSText.ParagraphSm my="M" color="garnet">
          {createUserMeta.error?.response?.data["error"] ??
            "Please enter valid data"}
        </WSText.ParagraphSm>
      )}
      {createAuthorizationMeta.isError && (
        <WSText.ParagraphSm my="M" color="garnet">
          {createAuthorizationMeta.error?.response?.data["error"]}
        </WSText.ParagraphSm>
      )}
    </WSFormOld>
  );
  return (
    <>
      <BrowserPageTitle title="Add Team member" />
      <SubscriptionGrantOptionsModal
        onClose={() => {
          setIsModalOpened(false);
        }}
      />
      <WSScreen.MobileAndTablet>
        <WSModal
          fullScreen
          fullScreenOnMobile
          size="XS"
          onClose={() => goToParentRoute(history)}
          className={cn({
            [styles.hideParentModal]: isModalOpened
          })}
        >
          <WSPage
            breadcrumb={{
              label: "Team members",
              icon: "arrow-left",
              onClick: () => goToParentRoute(history)
            }}
            title="Add team member"
          >
            {addTeamMemberForm}
          </WSPage>
        </WSModal>
      </WSScreen.MobileAndTablet>
      <WSScreen.Desktop>
        <WSModal
          size="XS"
          onClose={() => goToParentRoute(history)}
          className={cn({
            [styles.hideParentModal]: isModalOpened
          })}
          title="Add team member"
        >
          {addTeamMemberForm}
        </WSModal>
      </WSScreen.Desktop>
    </>
  );
};
