import {
  ICheckbookCard,
  PayoutDestinationType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { MutationConfig, queryCache } from "react-query";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import {
  QUERY_PAYOUT_SETTINGS,
  QUERY_PAYOUT_SETTINGS_DEBIT_CARDS
} from "../../../query/payments/keys";
import { paymentsService } from "../../../services/payments";
import { WSServiceError } from "../../../utils/serviceHelper";
import { FormData } from "./types";

export const useMutationSubmit = (
  params?: { setInstantPayoutPreference?: boolean },
  config?: MutationConfig<ICheckbookCard>
) => {
  const userId = useUserId();

  return useWSMutation<ICheckbookCard, WSServiceError, FormData>(
    async data => {
      if (
        !data.country ||
        !data.address.state ||
        !data.card.expMM ||
        !data.card.expYYYY
      ) {
        throw new Error("Invalid request");
      }

      const debitCard = await paymentsService.payoutSettings.debitCard.create(
        userId,
        {
          name: data.card.name,
          cardNumber: data.card.cardNumber,
          expMM: data.card.expMM,
          expYYYY: data.card.expYYYY,
          cvv: data.card.cvv,
          address: {
            country: data.country,
            addressLine1: data.address.addressLine1,
            addressLine2: data.address.addressLine2,
            city: data.address.city,
            state: data.address.state,
            postalCode: data.address.postalCode
          }
        }
      );

      if (params?.setInstantPayoutPreference) {
        const payoutSettings = await paymentsService.payoutSettings.update(
          userId,
          {
            payoutPreferences: PayoutPreferences.Instant,
            instant: {
              destinationId: debitCard.cardId,
              destinationType: PayoutDestinationType.Card
            }
          }
        );

        queryCache.setQueryData(QUERY_PAYOUT_SETTINGS, payoutSettings);
      }

      return debitCard;
    },
    {
      dependencies: [QUERY_PAYOUT_SETTINGS, QUERY_PAYOUT_SETTINGS_DEBIT_CARDS],
      ...config
    }
  );
};
