import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  WSButton,
  WSContainer,
  WSFlexBox,
  WSCentered,
  WSPanel,
  WSRadioInputGroup,
  WSText
} from "@wingspanhq/fe-component-library";
import { BulkPayableStatus } from "@wingspanhq/payments/dist/interfaces";
import { useBrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { WSQueries } from "../../../../query/WSQuery";
import { useUpdateBulkPayableBatch } from "../../../../modules/BulkImporter/query/bulkPayable/mutations";
import { useLocalStorage } from "../../../../shared/hooks/useLocalStorage";
import { useUserId } from "../../../../query/hooks/helpers";

export interface BulkUploadPayablesStatusProps {}

export const BulkUploadPayablesStatus: React.FC<BulkUploadPayablesStatusProps> = () => {
  useBrowserPageTitle("Wingspan - Bulk import payables");
  const userId = useUserId();
  const [status, setStatus] = useLocalStorage(
    `${userId}:bulk-payable-status`,
    BulkPayableStatus.Draft
  );
  const history = useHistory();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const bulkBatchId = match.params.bulkBatchId;

  const featureFlagsQuery = useFeatureFlags();

  const [
    updateBulkPayableBatch,
    updateBulkPayableBatchMeta
  ] = useUpdateBulkPayableBatch();

  const gotoBulkBatchDetails = async () => {
    await updateBulkPayableBatch(
      {
        id: bulkBatchId,
        data: {
          labels: {
            payableStatus: status
          }
        }
      },
      {
        onSuccess: () => {
          history.push(`/member/imports/payables/${bulkBatchId}`);
        }
      }
    );
  };

  return (
    <ProjectOnboardingLayout progress={(100 / 3) * 2}>
      <WSContainer verticalPadding>
        <WSCentered span={{ s: "6", m: "8" }}>
          <WSText.Heading4 mb="M">
            Import payables with a spreadsheet
          </WSText.Heading4>
          <WSPanel>
            <WSQueries queries={{ featureFlagsQuery }}>
              {({ featureFlagsQuery: { data: featureFlags } }) => {
                return (
                  <>
                    <WSText weight="medium" mb="XL">
                      Select status for imported payables
                    </WSText>
                    <WSRadioInputGroup
                      mb="XL"
                      name="status"
                      value={status}
                      onChange={(event: any) => {
                        setStatus(event.target?.value);
                      }}
                      options={[
                        {
                          value: BulkPayableStatus.Draft,
                          label: (
                            <WSFlexBox direction="column">
                              <WSText weight="medium" mb="XS">
                                {BulkPayableStatus.Draft}
                              </WSText>
                              <WSText.ParagraphSm>
                                Payable will be hidden to contractors until it
                                is opened
                              </WSText.ParagraphSm>
                            </WSFlexBox>
                          )
                        },
                        {
                          value: BulkPayableStatus.Open,
                          label: (
                            <WSFlexBox direction="column">
                              <WSText weight="medium" mb="XS">
                                {BulkPayableStatus.Open}
                              </WSText>
                              <WSText.ParagraphSm>
                                Contractor can see payable, but it will not be
                                paid until it is approved
                              </WSText.ParagraphSm>
                            </WSFlexBox>
                          )
                        },
                        {
                          value: BulkPayableStatus.Approved,
                          label: (
                            <WSFlexBox direction="column">
                              <WSText weight="medium" mb="XS">
                                {BulkPayableStatus.Approved}
                              </WSText>
                              <WSText.ParagraphSm>
                                Contractor will automatically be paid on the
                                next payroll date
                              </WSText.ParagraphSm>
                            </WSFlexBox>
                          )
                        },
                        ...(featureFlags.nec1099Filing
                          ? [
                              {
                                value: BulkPayableStatus.Paid,
                                label: (
                                  <WSFlexBox direction="column">
                                    <WSText weight="medium" mb="XS">
                                      {BulkPayableStatus.Paid} (Historical
                                      import)
                                    </WSText>
                                    <WSText.ParagraphSm>
                                      Payables will be visible to contractors
                                      and marked as paid off of Wingspan
                                    </WSText.ParagraphSm>
                                  </WSFlexBox>
                                )
                              }
                            ]
                          : [])
                      ]}
                    />
                    <WSButton
                      name="importPayablesAndDeductions"
                      icon="upload"
                      fullWidth
                      loading={updateBulkPayableBatchMeta.isLoading}
                      onClick={gotoBulkBatchDetails}
                    >
                      Import payables & deductions
                    </WSButton>
                  </>
                );
              }}
            </WSQueries>
          </WSPanel>
        </WSCentered>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
