import cn from "classnames";
import React from "react";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSText } from "../../core/WSText/WSText.component";
import { WSChip, WSChipProps } from "../WSChip/WSChip.component";
import styles from "./WSQuickFilters.module.scss";

export interface WSQuickFiltersProps
  extends Omit<WSElementProps, "color" | "colorBackground"> {
  chips: WSChipProps[];
}

export const WSQuickFilters: React.FC<WSQuickFiltersProps> = ({
  className,
  chips,
  ...elementProps
}) => {
  const classNames = cn(styles.container, className);

  return (
    <WSElement className={classNames} {...elementProps}>
      <WSText.ParagraphXs color="gray500">Quick filters</WSText.ParagraphXs>

      {chips.map((chip, index) => (
        <WSChip key={getChipKey(chip, index)} {...chip} />
      ))}
    </WSElement>
  );
};

const getChipKey = (chip: WSChipProps, index: number) =>
  [index, chip.leftText, chip.pillText, chip.count, chip.rightText]
    .filter((s) => s !== undefined)
    .join("_");
