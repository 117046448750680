import {
  IBulkBatchCreate,
  IBulkCollaboratorBatch,
  IBulkBatchUpdate,
  IBulkCollaboratorItemCreate,
  IBulkCollaboratorItem,
  IBulkCollaboratorItemUpdate,
  BulkStatus
} from "@wingspanhq/payments/dist/interfaces";
import { WSService } from "../../../utils/WSService";
import { ListRequestQuery } from "../../../utils/serviceHelper";
import {
  BulkBatchItemsFilter,
  BulkBatchItemsListResponse,
  BulkBatchesFilters,
  ICollaboratorBatchImportSummary
} from "./types";

const service = new WSService("/payments");

// bulk collaborator upload

const createBulkCollaboratorBatch = async <
  IBatchCreate = IBulkBatchCreate,
  IBatchResponse = IBulkCollaboratorBatch
>(
  data: IBatchCreate
): Promise<IBatchResponse> => {
  const response = await service.post(`/bulk/collaborator/batch`, data);
  return response.data;
};

const updateBulkCollaboratorBatch = async <
  IBatchUpdate = IBulkBatchUpdate,
  IBatchResponse = IBulkCollaboratorBatch
>(
  id: string,
  data: IBatchUpdate
): Promise<IBatchResponse> => {
  const response = await service.patch(`/bulk/collaborator/batch/${id}`, data);
  return response.data;
};

const getBulkCollaboratorBatch = async <
  IBatchResponse = IBulkCollaboratorBatch
>(
  id: string
): Promise<IBatchResponse> => {
  const response = await service.get(`/bulk/collaborator/batch/${id}`);
  return response.data;
};

const deleteBulkCollaboratorBatch = async <
  IBatchResponse = IBulkCollaboratorBatch
>(
  id: string
): Promise<IBatchResponse> => {
  const response = await service.delete(`/bulk/collaborator/batch/${id}`);
  return response.data;
};

const getBulkCollaboratorBatches = async <
  IBatchResponse = IBulkCollaboratorBatch
>(
  query?: ListRequestQuery<
    BulkBatchesFilters,
    {
      createdAt?: "asc" | "desc";
    }
  >
): Promise<IBatchResponse[]> => {
  const response = await service.get(`/bulk/collaborator/batch`, {
    params: query
  });
  return response.data;
};

const getBulkCollaboratorBatchImportSummary = async (
  batchId: string,
  query?: ListRequestQuery<
    BulkBatchItemsFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >
): Promise<ICollaboratorBatchImportSummary> => {
  const { headers } = await service.get(
    `/bulk/collaborator/batch/${batchId}/item`,
    {
      params: { ...query, page: { size: 1 } }
    }
  );
  return {
    collaboratorsCount: Number(headers["x-ws-list-size"]),
    listSize: Number(headers["x-ws-list-size"])
  };
};

const createBulkCollaboratorBatchItem = async <
  IBatchItemCreate = IBulkCollaboratorItemCreate,
  IBatchItemResponse = IBulkCollaboratorItem
>(
  bulkCollaboratorBatchId: string,
  data: IBatchItemCreate
): Promise<IBatchItemResponse> => {
  const response = await service.post(
    `/bulk/collaborator/batch/${bulkCollaboratorBatchId}/item`,
    data
  );
  return response.data;
};

const updateBulkCollaboratorBatchItem = async <
  IBatchItemUpdate = IBulkCollaboratorItemUpdate,
  IBatchItemResponse = IBulkCollaboratorItem
>(
  bulkCollaboratorBatchId: string,
  bulkCollaboratorItemId: string,
  data: IBatchItemUpdate
): Promise<IBatchItemResponse> => {
  const response = await service.patch(
    `/bulk/collaborator/batch/${bulkCollaboratorBatchId}/item/${bulkCollaboratorItemId}`,
    data
  );
  return response.data;
};

const getBulkCollaboratorBatchItems = async <
  IBatchItemResponse = IBulkCollaboratorItem
>(
  batchId: string,
  query?: ListRequestQuery<
    BulkBatchItemsFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >
): Promise<IBatchItemResponse[]> => {
  const { data } = await service.get(
    `/bulk/collaborator/batch/${batchId}/item`,
    {
      params: query
    }
  );
  return data;
};

const getBulkCollaboratorBatchItem = async <
  IBatchItemResponse = IBulkCollaboratorItem
>(
  bulkCollaboratorBatchId: string,
  bulkCollaboratorItemId: string
): Promise<IBatchItemResponse> => {
  const response = await service.get(
    `/bulk/collaborator/batch/${bulkCollaboratorBatchId}/item/${bulkCollaboratorItemId}`
  );
  return response.data;
};

const getBulkCollaboratorBatchItemsListSize = async (
  batchId: string,
  query?: ListRequestQuery<
    BulkBatchItemsFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >
): Promise<number> => {
  const { headers } = await service.get(
    `/bulk/collaborator/batch/${batchId}/item`,
    {
      params: { ...query, page: { size: 1 } }
    }
  );
  return Number(headers["x-ws-list-size"]);
};

export const bulkCollaboratorService = {
  batch: {
    create: createBulkCollaboratorBatch,
    get: getBulkCollaboratorBatch,
    list: getBulkCollaboratorBatches,
    delete: deleteBulkCollaboratorBatch,
    update: updateBulkCollaboratorBatch,
    summary: getBulkCollaboratorBatchImportSummary
  },
  batchItem: {
    create: createBulkCollaboratorBatchItem,
    get: getBulkCollaboratorBatchItem,
    list: getBulkCollaboratorBatchItems,
    listSize: getBulkCollaboratorBatchItemsListSize,
    update: updateBulkCollaboratorBatchItem
  }
};
