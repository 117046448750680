import { WSButton, WSGrid, WSText } from "@wingspanhq/fe-component-library";
import { formatMoney } from "accounting";
import React from "react";
import { useFormContext } from "react-hook-form";

type Props = {
  index: number;
  onEdit: () => void;
};

export const LineItemMobilePreview: React.FC<Props> = ({ index, onEdit }) => {
  const { watch } = useFormContext();
  const description = watch(`lineItems[${index}].description`);
  const totalCost = watch(`lineItems[${index}].totalCost`);

  return (
    <WSGrid>
      <WSGrid.Item span={{ xs: "6" }}>
        <WSText.ParagraphSm>{description}</WSText.ParagraphSm>
      </WSGrid.Item>
      <WSGrid.Item span={{ xs: "4" }}>
        <WSText.ParagraphSm>{formatMoney(totalCost)}</WSText.ParagraphSm>
      </WSGrid.Item>
      <WSGrid.Item span={{ xs: "2" }}>
        <WSButton.Link
          type="button"
          onClick={onEdit}
          name={`lineItem${index}Edit`}
        >
          Edit
        </WSButton.Link>
      </WSGrid.Item>
    </WSGrid>
  );
};
