import {
  WSButton,
  WSFlexBox,
  WSPanel,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IMemberClientRequirementResponse,
  RequirementStatus
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useSignDocument } from "../../../../query/files/mutations";
import { useUserId } from "../../../../query/hooks/helpers";
import styles from "./index.module.scss";

export const Requirement: React.FC<{
  requirement: IMemberClientRequirementResponse;
}> = ({ requirement }) => {
  const userId = useUserId();
  const [sign, signMeta] = useSignDocument();
  const isSigned =
    requirement.document?.memberId === userId
      ? !!requirement.document?.events?.memberSignedAt
      : !!requirement.document?.events?.clientSignedAt;

  return requirement.status === RequirementStatus.Complete || isSigned ? (
    <WSPanel mb="XL">
      <WSFlexBox.CenterY justify="space-between" wrap="nowrap">
        <WSText weight="medium" mr="XL" color="gray400">
          {requirement.document?.title}
        </WSText>
        <WSPill icon="check" text="Complete" />
      </WSFlexBox.CenterY>
    </WSPanel>
  ) : (
    <WSPanel mb="XL" key={requirement.eligibilityRequirementId}>
      <WSFlexBox.CenterY
        justify="space-between"
        wrap="nowrap"
        onClick={() => {
          sign({ documentId: requirement.documentId });
        }}
      >
        <WSText weight="medium" mr="XL">
          {requirement.document?.title}
        </WSText>
        <WSButton.Link
          textClassName={styles.reviewAndSignButtonText}
          loading={signMeta.isLoading}
        >
          Review & Sign
        </WSButton.Link>
      </WSFlexBox.CenterY>
    </WSPanel>
  );
};
