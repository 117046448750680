import {
  toWSDateString,
  useIsMobile,
  WSElement,
  WSElementProps,
  WSTable,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WSQueries } from "../../../../query/WSQuery";
import { useBankStatementsList } from "../../../../query/payments/queries";
import { DownloadBankStatementButton } from "./DownloadBankStatementButton";

type Props = WSElementProps;

export const BankStatements: React.FC<Props> = ({ ...elementProps }) => {
  const queryBankStatements = useBankStatementsList();
  const isMobile = useIsMobile();

  return (
    <WSElement {...elementProps}>
      <WSQueries queries={{ queryBankStatements }}>
        {({ queryBankStatements: { data: bankStatements } }) => {
          return (
            <WSTable
              mt={isMobile ? "L" : "2XL"}
              tableData={bankStatements.map(bankStatement => {
                return {
                  data: bankStatement,
                  id: bankStatement.id
                };
              })}
              columns={[
                {
                  config: {
                    header: {
                      text: "Account Id"
                    },
                    gridTemplateSizeMax: "0.25fr"
                  },
                  renderFunction: row => (
                    <WSTableCell text={row.data.accountId} />
                  )
                },
                {
                  config: {
                    header: {
                      text: "Name"
                    },
                    gridTemplateSizeMax: "0.75fr"
                  },
                  renderFunction: row => <WSTableCell text={row.data.name} />
                },
                {
                  config: {
                    header: {
                      text: "Date"
                    },
                    justify: "end"
                  },
                  renderFunction: row => (
                    <WSTableCell
                      text={toWSDateString(row.data.date, "monthDayYear")}
                    />
                  )
                },
                {
                  config: {
                    justify: "end"
                  },
                  renderFunction: row => (
                    <DownloadBankStatementButton data={row.data} />
                  )
                }
              ]}
              bottomMessage={
                bankStatements.length === 0
                  ? "You have no bank statements."
                  : undefined
              }
            />
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
