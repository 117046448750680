import React from "react";
import { useFormContext } from "react-hook-form";

export const WSFormContextView: React.FC<{}> = () => {
  const context = useFormContext();
  if (process.env.NODE_ENV !== "production") {
    return <pre>{JSON.stringify(context, null, 2)}</pre>;
  }
  return null;
};
