import { useHistory } from "react-router-dom";
import { PATH_ONBOARDING_VERIFICAIONS_BANKING } from "..";
import { OnboardingFlow } from "../components/OnboardingFlow";
import { OnboardingModule } from "../types";

const modules: OnboardingModule[] = [
  {
    type: "create_account",
    options: {
      accountTypes: ["Individual", "Business"],
      verificationLevel: "Banking"
    },
    title: "Banking verification"
  }
];

export const RouteBankingVerification: React.FC = () => {
  const history = useHistory();

  return (
    <OnboardingFlow
      basePath={PATH_ONBOARDING_VERIFICAIONS_BANKING}
      modules={modules}
      onSuccess={() => {
        history.push("/member");
      }}
    />
  );
};
