import React from "react";
import {
  useWSModal,
  WSButton,
  WSButtons,
  WSElement,
  WSText
} from "@wingspanhq/fe-component-library";

const ModalCancelEdit: React.FC<{
  onClose(result: boolean): void;
}> = ({ onClose }) => {
  return (
    <WSElement mt="XS">
      <WSText color="gray600">
        None of the information will be saved if you choose to cancel.
      </WSText>

      <WSButtons mt="2XL" forceFullWidth>
        <WSButton
          destructive
          onClick={() => {
            onClose(true);
          }}
        >
          Yes, cancel
        </WSButton>
        <WSButton.Secondary onClick={() => onClose(false)}>
          No, continue
        </WSButton.Secondary>
      </WSButtons>
    </WSElement>
  );
};

export const useModalCancelEdit = () =>
  useWSModal(ModalCancelEdit, {
    title: "Are you sure you want to cancel?",
    size: "S"
  });
