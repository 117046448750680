import { WSButton } from "@wingspanhq/fe-component-library";
import React from "react";
import { useModalFormW9Certify } from "../../ModalW9Certify/useModalFormW9Certify";
import { NotificationTemplate } from "../index";

export const W9Certification: NotificationTemplate<never> = {
  renderTitle: props => "W-9 certification required",
  renderBody: props => {
    return "You have not yet certified your W-9 information. Please certify your W-9 information is accurate in order to get paid.";
  },
  Footer: relatedData => {
    const consentModal = useModalFormW9Certify();

    return (
      <WSButton.Link
        size="S"
        rightIcon="chevron-right"
        onClick={() => {
          consentModal.open({});
        }}
      >
        Certify W-9 information
      </WSButton.Link>
    );
  }
};
