import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSGrid,
  WSInfiniteScroll,
  WSLoader,
  WSMessageBox,
  WSText,
  toWSDateString,
  toWSMoneyString,
  useIsMobile,
  useWSSnackbar
} from "@wingspanhq/fe-component-library";
import { FundingSourceType } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { Route, RouteComponentProps, useHistory } from "react-router-dom";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { WSQueries } from "../../../query/WSQuery";
import { useBankingAccount } from "../../../query/bookkeeping/queries";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useCollaboratorsQuery,
  usePayrollSettings,
  useQueryPayrollImmediatePayables
} from "../../../query/payments/queries";
import { useAccounts, useClientQuery } from "../../../query/users/queries";
import bookkeepingService from "../../../services/bookkeeping";
import { paymentsService } from "../../../services/payments";
import { usersService } from "../../../services/users";
import { LayoutSetup } from "../../../shared/components/LayoutSetup";
import { selectorFundingSource } from "../../../shared/selectors/selectorFundingSource";
import { selectorFundingSourceName } from "../../../shared/selectors/selectorFundingSourceName";
import { selectorIsDefaultPaymentMethodSet } from "../../../shared/selectors/selectorIsDefaultPaymentMethodSet";
import { Summary } from "../../components/Summary/Summary";
import { TablePayables } from "../../components/TablePayables";
import { CompletedDeductionDetails } from "../deductions/CompletedDuductionDetails";
import { ScheduledDeductionDetails } from "../deductions/ScheduledDuductionDetails";
import { PayableDetails } from "./PayableDetails";

const FundingSource: React.FC<WSElementProps> = ({ ...elementProps }) => {
  const userId = useUserId();
  const queryClient = useClientQuery(userId);
  const queryPayrollSettings = usePayrollSettings(userId);
  const queryAccounts = useAccounts();
  const queryInternalAccount = useBankingAccount();
  const history = useHistory();

  if (!queryClient.data || !queryPayrollSettings.data || !queryAccounts.data) {
    return (
      <WSElement {...elementProps}>
        <WSLoader.Spinner />
      </WSElement>
    );
  }

  const fundingSourceName = selectorFundingSourceName(
    queryClient.data,
    queryPayrollSettings.data,
    queryAccounts.data,
    queryInternalAccount.data
  );

  if (
    queryClient.isLoading ||
    queryPayrollSettings.isLoading ||
    queryAccounts.isLoading ||
    queryInternalAccount.isLoading
  )
    return null;

  if (fundingSourceName) {
    return (
      <Summary
        label={
          <WSButton.Link
            onClick={() => {
              history.push("/member/settings/payment-methods");
            }}
            rightIcon="arrow-right"
            size="S"
          >
            Payment method
          </WSButton.Link>
        }
        value={fundingSourceName}
      />
    );
  }

  return (
    <Summary
      label={
        <WSButton.Link
          onClick={() => {
            history.push("/member/settings/payment-methods");
          }}
          rightIcon="arrow-right"
          size="S"
        >
          Payment method
        </WSButton.Link>
      }
      value="–"
    />
  );
};

export const OffCyclePayroll: React.FC<RouteComponentProps> = ({ match }) => {
  const history = useHistory();
  const userId = useUserId();
  const queryPayrollImmediatePayables = useQueryPayrollImmediatePayables({
    refetchOnMount: true
  });
  const queryCollaborators = useCollaboratorsQuery();
  const queryClient = useClientQuery(userId);
  const queryPayrollSettings = usePayrollSettings(userId);
  const { openSnackbar } = useWSSnackbar();

  const [runOffCyclePayroll, runOffCyclePayrollMeta] = useWSMutation(
    async () => {
      const payrollSettings = await paymentsService.payrollSettings.get(userId);
      const client = await usersService.client.get(userId);

      const fundingSource = selectorFundingSource(payrollSettings, client);

      if (!fundingSource) {
        throw new Error("No funding source set");
      }

      if (fundingSource.type === FundingSourceType.InternalAccount) {
        const balance = await bookkeepingService.getBankingBalance();
        if (!balance.balance) {
          throw new Error("Insufficient funds in internal account");
        }
      }

      try {
        await paymentsService.payApproved();
      } catch (error) {
        console.error("Off cycle payroll request failed", error);
      }
    },
    {
      onSuccess: () => {
        history.push("/member/invoices/payables");

        openSnackbar({ message: "Off-cycle payroll initiated" });
      },
      onError: error => {
        openSnackbar({ message: error.message, type: "error" });
      }
    }
  );
  const isMobile = useIsMobile();

  return (
    <LayoutSetup
      wide
      onBack={() => {
        history.push("/member/invoices/payables");
      }}
    >
      <WSText.Heading4>Run off-cycle payroll</WSText.Heading4>
      <WSText mb="2XL">
        Invoices eligible for payroll must be approved, have a status of
        Pending, Open, or Overdue, and an amount of $0 or more. Invoices will be
        paid on the last possible payroll to ensure payment reaches the
        contractor by the due date.
      </WSText>

      <WSQueries
        queries={{
          queryPayrollImmediatePayables,
          queryCollaborators,
          queryClient,
          queryPayrollSettings
        }}
      >
        {({
          queryPayrollImmediatePayables: {
            data: {
              data: payables,
              summary: { totalValue, listSize }
            }
          },
          queryCollaborators: { data: collaborators },
          queryClient: { data: client },
          queryPayrollSettings: { data: payrollSettings }
        }) => (
          <>
            <WSElement mb="2XL">
              <WSGrid>
                <WSGrid.Item span={{ xs: "6", m: "3" }}>
                  <Summary
                    label="Total 1099s"
                    value={toWSMoneyString(totalValue)}
                  />
                </WSGrid.Item>

                <WSGrid.Item span={{ xs: "6", m: "2" }}>
                  <Summary label="Total amount" value={listSize} />
                </WSGrid.Item>

                <WSGrid.Item span={{ xs: "6", m: "4" }}>
                  <FundingSource />
                </WSGrid.Item>

                {payables[0]?.labels && (
                  <WSGrid.Item span={{ xs: "6", m: "3" }}>
                    <Summary
                      label="Expected deposit date"
                      value={toWSDateString(
                        payables[0].labels?.settlementDate,
                        "monthDate"
                      )}
                    />
                  </WSGrid.Item>
                )}
              </WSGrid>
            </WSElement>

            {!selectorIsDefaultPaymentMethodSet(client, payrollSettings) ? (
              <WSMessageBox.Error
                mb="XL"
                onClick={() => {
                  history.push("/member/settings/payment-methods");
                }}
              >
                Please{" "}
                <WSButton.Link>add a default payment method</WSButton.Link> to
                run payroll.
              </WSMessageBox.Error>
            ) : null}

            <WSElement mb="2XL">
              <WSGrid>
                <WSGrid.Item span={{ s: "6", m: "8" }}>
                  <WSText.Heading5>Included payables</WSText.Heading5>
                  <WSText>
                    To update the payables included in this payroll run, edit
                    the payable approval or pay date.
                  </WSText>
                </WSGrid.Item>

                <WSGrid.Item span={{ s: "6", m: "4" }}>
                  <WSFlexBox.CenterY justify="flex-end">
                    <WSButton.Primary
                      fullWidth={isMobile}
                      disabled={
                        !selectorIsDefaultPaymentMethodSet(
                          client,
                          payrollSettings
                        )
                      }
                      onClick={() => {
                        runOffCyclePayroll();
                      }}
                      loading={runOffCyclePayrollMeta.isLoading}
                    >
                      Run payroll
                    </WSButton.Primary>
                  </WSFlexBox.CenterY>
                </WSGrid.Item>
              </WSGrid>
            </WSElement>

            <WSErrorMessage
              contextKey="OffCyclePayroll"
              error={runOffCyclePayrollMeta.error}
              mb="2XL"
            />

            <Route
              path={`${match.path}/:payableId/deduction/scheduled/:deductionId`}
              component={ScheduledDeductionDetails}
            />
            <Route
              path={`${match.path}/:payableId/deduction/completed/:deductionId`}
              component={CompletedDeductionDetails}
            />
            <Route
              path={`${match.path}/:payableId`}
              component={PayableDetails}
              exact
            />

            <WSInfiniteScroll
              onLoad={() => {
                queryPayrollImmediatePayables.fetchMore();
              }}
              loadMore={payables.length > 0}
              endOfList={!queryPayrollImmediatePayables.canFetchMore}
              loading={!!queryPayrollImmediatePayables.isFetchingMore}
            >
              <TablePayables
                payables={payables}
                collaborators={collaborators}
                payrollSettings={payrollSettings}
                showHeaders
                showInvoiceNumber
                showDateRequested
                showDueDate
                onRowClick={({
                  data: {
                    payable: { payableId }
                  }
                }) => {
                  history.push(`${match.path}/${payableId}`);
                }}
              />
              {payables.length === 0 && (
                <WSFlexBox justify="center" mt="XL">
                  <WSText color="gray600">
                    No payables available for off-cycle payroll
                  </WSText>
                </WSFlexBox>
              )}
            </WSInfiniteScroll>
          </>
        )}
      </WSQueries>
    </LayoutSetup>
  );
};
