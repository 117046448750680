import {
  WSButton,
  WSButtonProps,
  WSElementColorsType,
  WSFlexBox,
  WSIcon,
  WSIconName,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IInvoice,
  IInvoiceTemplate,
  IMemberClient
} from "@wingspanhq/payments/dist/interfaces";
import React, { ReactNode } from "react";
import {
  useGetInvoiceActions,
  useGetInvoiceTemplateActions
} from "../InvoiceActions/InvoiceActions";
import {
  InvoiceStatusTimeline,
  InvoiceTimelineStatus
} from "../InvoiceStatusTimeline/InvoiceStatusTimeline";
import styles from "./InvoiceDetailsHeader.module.scss";
import { Actions } from "../../../components/Actions";

type Props = {
  title: string;
  description: ReactNode;
  status?: keyof typeof InvoiceTimelineStatus;
  buttonProps?: WSButtonProps;
  icon?: WSIconName;
  iconColor?: WSElementColorsType;
  memberClient: IMemberClient;
  invoice?: IInvoice;
  invoiceTemplate?: IInvoiceTemplate;
};

export const InvoiceDetailsHeader: React.FC<Props> = ({
  title,
  description,
  status,
  buttonProps,
  icon,
  iconColor,
  memberClient,
  invoice,
  invoiceTemplate
}) => {
  const getInvoiceActions = useGetInvoiceActions();
  const getInvoiceTemplateActions = useGetInvoiceTemplateActions();

  return (
    <>
      <WSFlexBox.CenterY mb="XS">
        {icon && <WSIcon block name={icon} color={iconColor} mr="M" />}
        <WSText.Heading5>{title}</WSText.Heading5>
      </WSFlexBox.CenterY>
      <WSText color="gray500" mb="XL">
        {description}
      </WSText>
      <InvoiceStatusTimeline
        currentStatus={status}
        statuses={[
          InvoiceTimelineStatus.Sent,
          InvoiceTimelineStatus.Viewed,
          InvoiceTimelineStatus.Paid,
          InvoiceTimelineStatus.Deposited
        ]}
        mb="XL"
      />

      {buttonProps && (
        <WSFlexBox wrap="nowrap" alignItems="center">
          <WSButton
            fullWidth
            mr="M"
            className={styles.mainButton}
            {...buttonProps}
          />
          {invoice && (
            <Actions name="actions" items={getInvoiceActions(invoice)} />
          )}
          {invoiceTemplate && (
            <Actions
              name="actions"
              items={getInvoiceTemplateActions(invoiceTemplate)}
            />
          )}
        </WSFlexBox>
      )}
    </>
  );
};
