import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import { ICheckbookCard } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { BankCardLogo } from "../../../../shared/components/BankCardLogo";
import { selectorDebitCardBrand } from "../../../../shared/selectors/selectorDebitCardBrand";
import { selectorDebitCardName } from "../../../../shared/selectors/selectorDebitCardName";

type Props = { debitCard: ICheckbookCard } & WSElementProps;

export const DebitCard: React.FC<Props> = ({ debitCard, ...elementProps }) => {
  const title = selectorDebitCardBrand(debitCard);
  const description = selectorDebitCardName(debitCard);

  return (
    <WSFlexBox wrap="nowrap" alignItems="center" {...elementProps}>
      <BankCardLogo.Circle brand={debitCard.brand} type="debit" mr="M" />
      <WSScreen.Mobile>
        <WSElement>
          <WSText weight="medium" mr="M">
            {title}
          </WSText>
          <WSText.ParagraphSm color="gray500">{description}</WSText.ParagraphSm>
        </WSElement>
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        <WSText weight="medium" mr="M">
          {title}
        </WSText>
        <WSText color="gray500">{description}</WSText>
      </WSScreen.TabletAndDesktop>
    </WSFlexBox>
  );
};
