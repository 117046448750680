export const cleanCSVData = (line: Object, all: Array<Object>) => {
  // Any columns where all of the column values are undefined should be removed.
  return Object.keys(line).reduce((acc, key) => {
    if (all.every(l => (l as any)[key] === undefined)) {
      return acc;
    }

    const value = (line as any)[key];

    return {
      ...acc,
      // stringify Date to more compact style
      [key]: value instanceof Date ? value.toISOString().split("T")[0] : value
    };
  }, {});
};
