import { WSPage } from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { INVOICING_PATH } from "..";
import { useUrlQueryFilters } from "../../../utils/router";
import { PaginatedTable } from "../components/PaginatedTable";
import { SearchPanel } from "../components/SearchPanel";
import { Toolbar } from "../components/Toolbar";
import { useDownloadCsv } from "../hooks/useDownloadCsv";
import { useQueryInvoicesRows } from "../queries/useQueryInvoicesRows";
import { useQueryInvoicesRowsSummary } from "../queries/useQueryInvoicesRowsSummary";
import { InvoiceRowsQueryFilter } from "../service";

export const RouteSearch: React.FC = () => {
  const history = useHistory();

  const { filters, setFilters } = useUrlQueryFilters<{
    search: string;
  }>({
    search: ""
  });

  const requestFilters: InvoiceRowsQueryFilter = useMemo(
    () => ({
      searchString: filters.search || undefined
    }),
    [filters.search]
  );

  const queryRows = useQueryInvoicesRows({
    filter: requestFilters
  });
  const querySummary = useQueryInvoicesRowsSummary({
    filter: requestFilters
  });

  const { downloadCsv, isLoading } = useDownloadCsv();

  return (
    <WSPage>
      <SearchPanel
        mb="L"
        context="invoicing"
        onBack={() => {
          history.push(INVOICING_PATH);
        }}
        value={filters.search || ""}
        onSearch={newValue => {
          setFilters({
            ...filters,
            search: newValue
          });
        }}
        onClear={() => {
          setFilters({
            search: ""
          });
        }}
        placeholder="Search invoices"
      />

      {!!filters.search && (
        <>
          <Toolbar
            count={querySummary.data?.listSize}
            totalAmount={querySummary.data?.totalValue}
            mb="L"
          />

          <PaginatedTable
            onDownload={
              queryRows.data && queryRows.data.length > 0
                ? () => {
                    downloadCsv({
                      filter: requestFilters
                    });
                  }
                : undefined
            }
            query={queryRows}
          />
        </>
      )}
    </WSPage>
  );
};
