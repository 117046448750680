import {
  WSButton,
  WSButtons,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSMessageBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WSQueries } from "../../../../../query/WSQuery";
import { useUserId } from "../../../../../query/hooks/helpers";
import {
  useActivities,
  useMemberProfile
} from "../../../../../query/users/queries";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { LayoutW9 } from "../../../components/LayoutW9";
import { useHistory } from "react-router-dom";
import {
  TinType,
  VerificationStatus
} from "@wingspanhq/payments/dist/interfaces";
import { useTaxFormSubmitW9 } from "../../../../../query/taxForm/mutations/useTaxFormSubmitW9";
import { selectorTaxClassificationLabel } from "../../../../../shared/selectors/selectorTaxClassificationLabel";
import { selectorMemberTaxFormName } from "../../../selectors/selectorMemberTaxFormName";
import { openInNewTab } from "../../../../../shared/utils/openInNewTab";
import { AddressText } from "../../../../../shared/components/AddressText/AddressText";
import { formatEIN } from "../../../../../shared/utils/formatEIN";
import { Field } from "../../../components/Field/Field";

export const RouteConfirmPreferences: React.FC<SetupRouteComponentProps> = ({
  onNext
}) => {
  const history = useHistory();
  const userId = useUserId();

  const queryMember = useMemberProfile(userId);
  const queryActivity = useActivities(userId);

  const [submit, submitMeta] = useTaxFormSubmitW9({
    onSuccess: onNext
  });

  const onSubmit = async () => {
    await submit();
  };

  return (
    <LayoutW9
      title="Confirm tax preferences"
      description="Please review your preferences listed below before confirming."
    >
      <WSQueries queries={{ queryMember, queryActivity }}>
        {({ queryMemberData, queryActivityData }) => {
          const isIndividual =
            queryMemberData.profile.formW9Options?.tinPreference ===
            TinType.Individual;
          const isBusiness =
            queryMemberData.profile.formW9Options?.tinPreference ===
            TinType.Business;
          const status = isIndividual
            ? queryMemberData.profile.tinVerification?.ssn?.status
            : isBusiness
            ? queryMemberData.profile.tinVerification?.ein?.status
            : undefined;

          return (
            <>
              <WSPanel mb="2XL" pb="NONE">
                <Field
                  label="Name"
                  link="/member/1099-filing/tax-information/edit-taxpayer-information"
                  value={selectorMemberTaxFormName(queryMemberData)}
                />
                <Field label="Email" value={queryMemberData.user.email} />
                <Field
                  label="Federal Tax Classification"
                  link="/member/1099-filing/tax-information/edit-taxpayer-information"
                  value={selectorTaxClassificationLabel(
                    queryMemberData.profile.company?.structure
                  )}
                />

                <Field
                  label="Taxpayer Identification Number"
                  link="/member/1099-filing/tax-information/edit-taxpayer-information"
                  value={
                    <WSElement>
                      <WSText.ParagraphSm mb="M" color="gray500">
                        {isBusiness
                          ? `${
                              queryMemberData.profile.company?.taxId
                                ? formatEIN(
                                    queryMemberData.profile.company?.taxId
                                  )
                                : ""
                            } (Employer Identification Number)`
                          : "Social Security Number"}
                      </WSText.ParagraphSm>

                      {status === VerificationStatus.Verified ? (
                        <WSMessageBox.Success icon="check" noBorder>
                          <WSText weight="medium" color="green500">
                            IRS Verified
                          </WSText>
                        </WSMessageBox.Success>
                      ) : status === VerificationStatus.Failed ? (
                        <WSMessageBox.Error icon="alert-circle" noBorder>
                          <WSText weight="medium" mb="XS" color="red500">
                            Verification Failed
                          </WSText>
                          <WSText.ParagraphSm color="gray400" mb="XS">
                            Your TIN could not be verified with the IRS. Please
                            update your TIN below to restart the verification
                            process.
                          </WSText.ParagraphSm>

                          <WSButton.Link
                            rightIcon="arrow-right"
                            onClick={() =>
                              history.push(
                                "/member/1099-filing/tax-information/edit-taxpayer-information"
                              )
                            }
                          >
                            Update TIN
                          </WSButton.Link>
                        </WSMessageBox.Error>
                      ) : (
                        <WSMessageBox.Warning icon="alarm" noBorder>
                          <WSText weight="medium" color="amber400">
                            Pending Verification
                          </WSText>
                          <WSText.ParagraphSm color="gray400" mb="XS">
                            we're still waiting to hear back from the IRS. You
                            may receive an email from us requesting more
                            information
                          </WSText.ParagraphSm>
                        </WSMessageBox.Warning>
                      )}
                    </WSElement>
                  }
                />

                <Field
                  label="Address"
                  link="/member/1099-filing/tax-information/edit-taxpayer-information"
                  value={
                    <WSElement>
                      <WSText.ParagraphSm color="gray400" mb="M" as="pre">
                        <AddressText {...queryMemberData.profile?.address} />
                      </WSText.ParagraphSm>
                      {queryActivityData.events.electronic1099Consent ? null : (
                        <WSMessageBox.Info icon="envelope">
                          Your 1099 will be delivered to the address listed
                          above
                        </WSMessageBox.Info>
                      )}
                    </WSElement>
                  }
                />

                <Field
                  label="Delivery preferences"
                  value={
                    queryActivityData.events.electronic1099Consent
                      ? "Electronic delivery"
                      : "Mail"
                  }
                  link="/member/1099-filing/tax-information/edit-paperless-delivery"
                />
              </WSPanel>

              <WSText.ParagraphSm mb="XL" color="gray400">
                After you have reviewed your tax preferences, please confirm
                below. Once confirmed, the preferences cannot be edited for the
                current filing season. If eligible, you will receive your 1099
                by February 1st, 2024.
              </WSText.ParagraphSm>

              <WSButtons forceFullWidth mb="XL">
                <WSButton onAsyncClick={onSubmit}>
                  Confirm tax preferences
                </WSButton>
              </WSButtons>

              <WSText.ParagraphSm color="gray400" mb="XS">
                Questions?{" "}
                <a
                  href="https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
                  target="_blank"
                  rel="noreferrer"
                >
                  View our 1099 FAQs
                </a>
              </WSText.ParagraphSm>
              <WSText.ParagraphSm color="gray400" mb="M">
                Need help?{" "}
                <a
                  onClick={() =>
                    openInNewTab("https://www.wingspan.app/support")
                  }
                >
                  Chat with a live representative
                </a>
              </WSText.ParagraphSm>
            </>
          );
        }}
      </WSQueries>
    </LayoutW9>
  );
};
