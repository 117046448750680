import { useConfirmCreateRequirementModal } from "../../../components/CreateRequirementDefinitionForm/useConfirmCreateRequirementModal";
import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { useUserId } from "../../../../../query/hooks/helpers";
import { CreateRequirementDefinitionFormValues } from "../../../types";
import {
  DefinitionDataSourceType,
  IRequirementDefinitionResponse,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import {
  CreateRequirementDefinitionSharedFileMimeType,
  CreateRequirementDefinitionSharedFilePartialForm
} from "../types";
import { useCreateSharedFileSettings } from "../../../../../query/sharedFile/mutations/useCreateSharedFileSettings";
import { MIME_TYPES_VALUES } from "./utils";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";

type Params = {
  engagementId?: string;
  onSuccess: (requirement: IRequirementDefinitionResponse) => void;
};

export const useCreateSharedFileRequirementDefinition = ({
  engagementId,
  onSuccess
}: Params) => {
  const { openSnackbar } = useWSSnackbar();
  const userId = useUserId();

  const [
    createSharedFileSettings,
    createSharedFileSettingsMeta
  ] = useCreateSharedFileSettings();

  const confirmCreateRequirementModal = useConfirmCreateRequirementModal();

  const onSubmit = async (
    formValues: CreateRequirementDefinitionFormValues &
      CreateRequirementDefinitionSharedFilePartialForm
  ) => {
    const mimeTypes = uniq(
      flatten(
        Object.entries(formValues.mimeTypes)
          .filter(([_, value]) => value)
          .map(
            ([key, value]) =>
              MIME_TYPES_VALUES[
                key as CreateRequirementDefinitionSharedFileMimeType
              ]
          )
      )
    );

    const sharedFileSettings = await createSharedFileSettings(
      {
        title: formValues.name,
        mimeTypes: mimeTypes,
        instructions: formValues.instructions
      },
      {
        throwOnError: true
      }
    );

    if (sharedFileSettings) {
      // open confirmation modal to create requirement
      await confirmCreateRequirementModal.open({
        engagementId,
        dataSourceType: DefinitionDataSourceType.SharedFileRequestSettings,
        dataSourceId:
          // TODO: BE returned sharedFileRequestSettings instead of sharedFileRequestSettingsId
          (sharedFileSettings as any).id ||
          sharedFileSettings.sharedFileRequestSettingsId,
        name: formValues.name,
        description: formValues.description,
        requirementType: RequirementType.SharedFile,
        onSuccess(requirement) {
          onSuccess?.(requirement);
        }
      });
    }
  };

  return {
    onSubmit,
    errors: createSharedFileSettingsMeta.error,
    isLoading: createSharedFileSettingsMeta.isLoading
  };
};
