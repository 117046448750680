import cn from "classnames";
import React from "react";
import styles from "./ErrorMessage.module.scss";

export interface ErrorMessageProps {
  className?: string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  className,
  children
}) => <p className={cn(styles.base, className)}>{children}</p>;
