import { servicePayments } from "../servicePayments";
import {
  IPayeeEmailChangeRequest,
  IPayeeEmailChangeResponse
} from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEmailChange";
import { PayeeEmailUpdateRequest } from "./getPayeeEmailUpdate";

export const updatePayeeEmailUpdate = async ({
  payeeId,
  targetEmail
}: PayeeEmailUpdateRequest): Promise<IPayeeEmailChangeResponse> => {
  const request: IPayeeEmailChangeRequest = {
    targetEmail
  };

  const { data } = await servicePayments.post(
    `/payee/${payeeId}/email-update`,
    request
  );

  return data;
};
