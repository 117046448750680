import {
  ISessionResponse,
  SessionType
} from "@wingspanhq/users/dist/lib/interfaces";

import { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useSetWSStore, useWSStore } from "../../../../store";
import { RouteSignUpIn1099 } from "./RouteSignUpIn1099";

export const save1099DataInStorage = (
  search: string,
  setStore: ReturnType<typeof useSetWSStore>
) => {
  const params = new URLSearchParams(search);

  if (params.get("companyName") && params.get("inviteeEmail")) {
    const companyName = params.get("companyName")!;
    const inviteeEmail = params.get("inviteeEmail")!;
    const status = params.get("status");

    const signUp1099: any = {
      companyName,
      inviteeEmail,
      status:
        typeof status === "string" && status.trim().toLowerCase() === "active"
          ? "active"
          : "pending"
    };
    setStore({
      is1099SignUp: true,
      signUp1099
    });
    window.localStorage.setItem("signUp1099", JSON.stringify(signUp1099));
  }
};

export const RouteAuth1099: React.FC<{ session?: ISessionResponse }> = ({
  session
}) => {
  const location = useLocation();
  const store = useWSStore();
  const setStore = useSetWSStore();

  const search = location.search;
  const growthSource = store.growthAttributionDetails?.growthSource;

  useEffect(() => {
    save1099DataInStorage(search, setStore);
  }, [search, setStore]);

  if (session?.sessionType === SessionType.user) {
    return (
      <Redirect to="/member/1099-filing/tax-information/verify-identity" />
    );
  } else {
    return (
      <Switch>
        <Route path="/member/1099" component={RouteSignUpIn1099} />
        <Redirect
          to={{
            pathname: `/member/1099`,
            search: location.search,
            state: {
              growthSource
            }
          }}
        />
      </Switch>
    );
  }
};
