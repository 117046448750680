import React, { useMemo } from "react";
import { WSElement } from "../WSElement/WSElement.component";
import { OptionItem } from "./OptionItem";
import {
  FilterChangeHandler,
  WSFilterCheckbox,
  WSFilterMultiValue
} from "./types";
import { toggleValues } from "./utils/toggleValues";

type Props = {
  value?: WSFilterMultiValue;
  onChange: FilterChangeHandler;
} & Omit<WSFilterCheckbox, "type">;

export const FilterFieldCheckbox: React.FC<Props> = ({
  name,
  options,
  value: uncastedValue,
  onChange
}) => {
  const value = useMemo(() => uncastedValue || [], [uncastedValue]);

  return (
    <>
      {options.map((option) => {
        const subOptions = option.options;
        const isParent = !!subOptions?.length;
        const parentTrue = !!option.options?.every((option) =>
          value.includes(option.name)
        );
        const parentIndeterminate =
          !parentTrue &&
          option.options?.some((option) => value.includes(option.name));

        const optionValue = parentIndeterminate
          ? "indeterminate"
          : isParent
          ? parentTrue
          : value.includes(option.name);

        return (
          <WSElement key={option.name}>
            <OptionItem
              type="checkbox"
              value={optionValue}
              onChange={() => {
                if (isParent) {
                  const subOptionValues = subOptions
                    .filter((o) => !o.disabled)
                    .map((o) => o.name);

                  onChange(name, toggleValues(value, ...subOptionValues));
                  return;
                } else {
                  onChange(name, toggleValues(value, option.name));
                }
              }}
              {...option}
            />
            <WSElement ml="S">
              {option.options?.map((subOption) => (
                <OptionItem
                  key={subOption.name}
                  type="checkbox"
                  value={value.includes(subOption.name)}
                  onChange={() => {
                    onChange(name, toggleValues(value, subOption.name));
                  }}
                  {...subOption}
                />
              ))}
            </WSElement>
          </WSElement>
        );
      })}
    </>
  );
};
