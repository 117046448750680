import {
  WSElement,
  WSElementProps,
  WSIcon,
  WSLoader,
  toWSDateString,
  useWSSnackbar
} from "@wingspanhq/fe-component-library";
import { IBankStatement } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUserProfile } from "../../../../query/users/queries";
import { paymentsService } from "../../../../services/payments";
import { downloadFileFromBlob } from "../../../../utils/files";

type Props = { data: IBankStatement } & WSElementProps;

export const DownloadBankStatementButton: React.FC<Props> = ({
  data,
  ...elementProps
}) => {
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const { openSnackbar } = useWSSnackbar();

  const [download, downloadMeta] = useWSMutation(
    async () => {
      const pdfBlob = await paymentsService.banking.statement.download(data.id);

      const fileName = `${queryUser.data?.email}-bank-satement-${toWSDateString(
        data.date,
        "mobileInput"
      )}.pdf`;

      downloadFileFromBlob(pdfBlob, fileName);

      openSnackbar({
        message: `Downloaded ${data.name}`
      });
    },
    {
      onError: () => {
        openSnackbar({
          duration: 5000,
          type: "warning",
          message: `Something went wrong`
        });
      }
    }
  );

  return (
    <WSElement {...elementProps}>
      {downloadMeta.isLoading ? (
        <WSLoader.Spinner size="S" />
      ) : (
        <WSIcon block name="download" onClick={download} color="gray500" />
      )}
    </WSElement>
  );
};
