import {
  WSButton,
  WSButtons,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";

type Props = {
  onClose: () => void;
  onContinue: () => void;
  onBack?: () => void;
};

export const ModalCreateAccountWarning: React.FC<Props> = ({
  onContinue,
  onBack,
  onClose
}) => (
  <>
    <WSText.Heading3 mb="S">Create a new Wingspan account</WSText.Heading3>
    <WSText mb="S">
      Create a new account if you are signing up for the first time to pay for
      your first invoice.
    </WSText>

    <WSMessageBox.Info mb="XL">
      <WSText color="gray500" mb="M">
        If your organization already has an account, creating a new account will
        result in issues or delays with payments moving forward.
      </WSText>
      <WSText color="gray500">
        You should only continue if you don’t recognize the email address on the
        previous sign-in page. If you would like to update your email address,
        you’ll have the opportunity to do this later. To sign-in with an
        existing account, click “Back.”
      </WSText>
    </WSMessageBox.Info>

    <WSButtons forceFullWidth>
      <WSButton.Secondary
        type="button"
        onClick={() => {
          onContinue();
          onClose();
        }}
      >
        Continue with create account
      </WSButton.Secondary>
      <WSButton.Tertiary
        type="button"
        onClick={() => {
          onBack?.();
          onClose();
        }}
      >
        Back
      </WSButton.Tertiary>
    </WSButtons>
  </>
);
