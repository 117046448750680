import {
  FundingSourceType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { Currency } from "@wingspanhq/users/dist/lib/interfaces";
import { QUERY_INTERNAL_ACCOUNT } from "../../../../query/banking/queries/useQueryInternalAccount";
import { QUERY_INTERNAL_ACCOUNTS } from "../../../../query/banking/queries/useQueryInternalAccounts";
import { useWSMutation, WSMutationConfig } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  QUERY_PAYOUT_SETTINGS,
  QUERY_PAYROLL_SETTINGS
} from "../../../../query/payments/keys";
import { createInternalAccount } from "../../../../services/api/banking/internalAccounts";
import {
  components as bankingComponents,
  operations
} from "../../../../services/api/banking/types";
import { components as onboardingComponents } from "../../../../services/api/onboarding/types";
import { paymentsService } from "../../../../services/payments";
import { updateAcknowledgements } from "../../../../shared/utils/updateAcknowledgements";
import { WSServiceError } from "../../../../utils/serviceHelper";

export const useMutationSubmit = (
  type: bankingComponents["schemas"]["InternalAccountCreate"]["type"],
  currency: bankingComponents["schemas"]["Currency"],
  acknowledgements: onboardingComponents["schemas"]["AcknowledgementName"][],
  options?: {
    setFundingSource?: boolean;
    setStandardPayoutDestination?: boolean;
  },
  config?: WSMutationConfig<
    operations["createInternalAccount"]["responses"]["200"]["content"]["application/json"],
    WSServiceError,
    unknown
  >
) => {
  const userId = useUserId();

  return useWSMutation(
    async () => {
      if (
        !Object.values(Currency)
          .map(String)
          .includes(currency)
      ) {
        throw new Error("Currency is not supported");
      }

      // 1. Update acknowledgements
      await updateAcknowledgements(acknowledgements);

      // 2. Create internal account
      const internalAccount = await createInternalAccount({
        type,
        currency
      });

      // 3. Set usage of the internal account
      // 3.1 Payroll funding method
      if (options?.setFundingSource) {
        await paymentsService.payrollSettings.update(userId, {
          fundingSource: {
            fundingSourceCurrency:
              currency === "CAD" ? Currency.CAD : Currency.USD,
            fundingSourceId: internalAccount.internalAccountId,
            fundingSourceType: FundingSourceType.InternalAccount
          }
        });
      }

      // 3.2 Payout method
      if (options?.setStandardPayoutDestination) {
        await paymentsService.payoutSettings.update(userId, {
          payoutPreferences: PayoutPreferences.Standard,
          standard: null
        });
      }

      return internalAccount;
    },
    {
      dependencies: [
        QUERY_PAYROLL_SETTINGS,
        QUERY_PAYOUT_SETTINGS,
        QUERY_INTERNAL_ACCOUNT,
        QUERY_INTERNAL_ACCOUNTS
      ],
      ...config
    }
  );
};
