import {
  WSButton,
  WSEmptyState,
  WSFlexBox,
  WSGrid,
  WSPage,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useCustomizationQuery } from "../../query/customization/queries/useCustomizationQuery";
import { useHistory } from "react-router-dom";

import styles from "./EmailSettings.module.scss";
import { WSQueries } from "../../query/WSQuery";

export const EmailSettings: React.FC = () => {
  const history = useHistory();
  const customizationQuery = useCustomizationQuery();

  return (
    <WSPage title="Email settings">
      <WSGrid>
        <WSGrid.Item span={{ m: "12" }}>
          <WSText.Paragraph weight="medium" color="gray700" mb="S">
            Custom email snippets
          </WSText.Paragraph>
          <WSText.ParagraphSm weight="book" color="gray500" mb="XL">
            Set up custom email snippets for your invite emails to contractors
          </WSText.ParagraphSm>

          <WSQueries queries={{ customizationQuery }}>
            {({ customizationQueryData: customization }) => {
              return customization ? (
                <WSPanel px="M" py="S">
                  <WSFlexBox.CenterY
                    justify="space-between"
                    wrap="nowrap"
                    onClick={() => {
                      history.push(
                        "/member/settings/email-settings/edit-snippet"
                      );
                    }}
                  >
                    <WSText.ParagraphSm
                      weight="book"
                      color="gray600"
                      className={styles.snippetName}
                    >
                      Contractor invite
                    </WSText.ParagraphSm>
                    <WSButton.Link
                      rightIcon="chevron-right"
                      onClick={() => {
                        history.push(
                          "/member/settings/email-settings/edit-snippet"
                        );
                      }}
                    >
                      Edit
                    </WSButton.Link>
                  </WSFlexBox.CenterY>
                </WSPanel>
              ) : (
                <WSEmptyState
                  hasBorder
                  fullWidth
                  bgColor="gray"
                  type="tasks"
                  title="No snippet yet"
                  description="Add a custom email snippet for your invite emails to contractors"
                  buttons={[
                    {
                      label: "Add snippet",
                      onClick: () => {
                        history.push(
                          "/member/settings/email-settings/add-snippet"
                        );
                      }
                    }
                  ]}
                />
              );
            }}
          </WSQueries>
        </WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
