import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { NEC1099FilingDashboard } from "./routes/Dashboard/RouteDashboard";
import { RouteEditCalculationSettings } from "./routes/Dashboard/RouteEditCalculationSettings";
import { RouteEditImportRecipients } from "./routes/Dashboard/RouteEditImportRecipients";
import { RouteEditPayerInformation } from "./routes/Dashboard/RouteEditPayerInformation";
import { RouteEditStateFiling } from "./routes/Dashboard/RouteEditStateFiling";
import { RouteTaxFormDetails } from "./routes/Dashboard/RouteTaxFormDetails";
import { RouteTaxFormEditRecipient } from "./routes/Dashboard/RouteTaxFormEditRecipient";
import { RouteInitEditImportRecipients } from "./routes/ImportRecipientsFlow/RouteInitEditImportRecipients";
import { RouteNewRecipientFlow } from "./routes/NewRecipientFlow";
import { PayerSetupFlow } from "./routes/PayerSetupFlow";
import { RoutePreview } from "./routes/PayerSetupFlow/routes/RoutePreview";
import { RouteInit } from "./routes/RouteInit";
import { W9Flow } from "./routes/W9Flow";
import { RouteVerifyYourIdentity } from "./routes/W9Flow/routes/RouteVerifyYourIdentity";
import { RouteVerifyYourIdentityLocked } from "./routes/W9Flow/routes/RouteVerifyYourIdentityLocked";
import { RouteInitImportPayments } from "./routes/ImportPaymentsFlow/RouteInitImportPayments";
import { RouteBatchUploadPayments } from "./routes/Dashboard/RouteBatchUploadPayments";
import { RouteInitGenerate1099NECAmounts } from "./routes/Generate1099NECAmounts/RouteInitGenerate1099NECAmounts";
import { RouteGenerate1099NECAmounts } from "./routes/Generate1099NECAmounts";
import { RouteGenerate1099NECAmountsProgress } from "./routes/Generate1099NECAmounts/RouteGenerate1099NECAmountsProgress";
import { SubmitFilingFlow } from "./routes/SubmitFilingFlow";
import { RouteStartNewCorrection } from "./routes/Dashboard/RouteTaxFormDetails/RouteStartNewCorrection";
import { RouteViewCorrection } from "./routes/Dashboard/RouteViewCorrection";
import { RouteReviewCorrectionRequest } from "./routes/Dashboard/RouteSubmitCorrection/RouteReviewCorrectionRequest";
import { RouteSubmitCorrection } from "./routes/Dashboard/RouteSubmitCorrection/RouteSubmitCorrection";
import { RouteEditCorrectionRequest } from "./routes/Dashboard/RouteSubmitCorrection/RouteEditCorrectionRequest";
import { RouteSubmitCorrectionSuccess } from "./routes/Dashboard/StartNewCorrectionFlow/RouteSubmitCorrectionSuccess";

export const NEC1099Filing = () => {
  const history = useHistory();
  return (
    <Switch>
      <Route
        path="/member/1099-filing/tax-information/verify-identity"
        component={RouteVerifyYourIdentity}
        exact
      />
      <Route
        path="/member/1099-filing/tax-information/:taxFormId/locked"
        component={RouteVerifyYourIdentityLocked}
      />

      <Route
        path="/member/1099-filing/tax-information"
        render={() => (
          <W9Flow
            basePath="/member/1099-filing/tax-information"
            onBack={() => {
              history.push("/member/1099-filing/tax-information");
            }}
            onFinish={() => {
              history.push(`/member/dashboard`);
            }}
          />
        )}
      />

      <Route
        path="/member/1099-filing/set-up"
        render={() => (
          <PayerSetupFlow
            basePath="/member/1099-filing/set-up"
            onBack={() => {
              history.push("/member/1099-filing/preview");
            }}
            onFinish={() => {
              history.push(`/member/1099-filing/dashboard/all`);
            }}
          />
        )}
      />

      <Route
        path="/member/1099-filing/update-payer-information"
        component={RouteEditPayerInformation}
      />

      <Route
        path="/member/1099-filing/update-state-filing-settings"
        component={RouteEditStateFiling}
      />

      <Route
        path="/member/1099-filing/batch-add-recipients"
        exact
        component={RouteInitEditImportRecipients}
      />

      <Route
        path="/member/1099-filing/batch-add-recipients"
        component={RouteEditImportRecipients}
      />

      <Route
        path="/member/1099-filing/add-recipient"
        component={RouteNewRecipientFlow}
      />

      <Route
        path="/member/1099-filing/update-calculation-settings"
        component={RouteEditCalculationSettings}
      />

      <Route path="/member/1099-filing/preview" component={RoutePreview} />

      <Route path="/member/1099-filing/filing" component={SubmitFilingFlow} />

      <Route
        path="/member/1099-filing/batch-upload-payments"
        exact
        component={RouteInitImportPayments}
      />
      <Route
        path="/member/1099-filing/batch-upload-payments"
        component={RouteBatchUploadPayments}
      />

      <Route
        path="/member/1099-filing/generate-1099-nec-amounts"
        exact
        component={RouteInitGenerate1099NECAmounts}
      />
      <Route
        path="/member/1099-filing/generate-1099-nec-amounts/:bulkBatchId/progress"
        component={RouteGenerate1099NECAmountsProgress}
      />
      <Route
        path="/member/1099-filing/generate-1099-nec-amounts/:bulkBatchId"
        component={RouteGenerate1099NECAmounts}
      />

      <Route
        path={
          "/member/1099-filing/dashboard/:path(all|needs-action|ready|submitted|excluded)"
        }
        component={NEC1099FilingDashboard}
      />

      <Route
        path="/member/1099-filing/details/:taxFormId/view-correction"
        component={RouteViewCorrection}
      />

      <Route
        path="/member/1099-filing/details/:taxFormId/review-correction-request"
        component={RouteReviewCorrectionRequest}
      />

      <Route
        path="/member/1099-filing/details/:taxFormId/submit-correction/edit"
        component={RouteEditCorrectionRequest}
      />

      <Route
        path="/member/1099-filing/details/:taxFormId/submit-correction/success"
        component={RouteSubmitCorrectionSuccess}
      />

      <Route
        path="/member/1099-filing/details/:taxFormId/submit-correction"
        component={RouteSubmitCorrection}
      />

      <Route
        path="/member/1099-filing/details/:taxFormId/start-correction"
        component={RouteStartNewCorrection}
      />
      <Route
        path="/member/1099-filing/details/:taxFormId/edit"
        component={RouteTaxFormEditRecipient}
      />

      <Route
        path="/member/1099-filing/details/:taxFormId"
        component={RouteTaxFormDetails}
      />

      <Redirect
        from="/member/1099-filing/dashboard"
        to="/member/1099-filing/dashboard/all"
        exact
      />

      <Route path="/member/1099-filing" component={RouteInit} />
    </Switch>
  );
};
