import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import * as React from "react";

import styles from "./ViewPDF.module.scss";

export const ViewPDF: React.FC<{
  isSmall: boolean;
  url: string;
  height: number | string;
  text: string;
  onClick?(): void;
}> = ({ isSmall, url, height, text, onClick }) => {
  return (
    <WSElement className={styles.receiptPDF} style={{ height }}>
      <object
        data={`${url}#navpanes=0${isSmall ? "&toolbar=0&scrollbar=0" : ""}`}
        type="application/pdf"
        width="100%"
        height={height}
      >
        <WSPanel onClick={onClick}>
          <WSFlexBox.CenterY justify="space-between">
            <WSFlexBox.CenterY>
              <WSIcon
                block
                name={onClick ? "download" : "file"}
                mr="M"
                color="blue500"
              />
              <WSButton.Link>{text}</WSButton.Link>
            </WSFlexBox.CenterY>
            <WSText color="gray400">PDF</WSText>
          </WSFlexBox.CenterY>
        </WSPanel>
      </object>
      {isSmall ? <WSElement className={styles.receiptPDFBlocker} /> : null}
    </WSElement>
  );
};
