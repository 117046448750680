import { QueryConfig } from "react-query";
import {
  getPayeeRowsSummary,
  IPayeeSearchRowsSummary,
  PayeeRowsQuery
} from "../../../../services/search";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { useWSQuery } from "../../../helpers";

export const QUERY_PAYEE_ROWS_SUMMARY = "QUERY_PAYEE_ROWS_SUMMARY";

export const usePayeeRowsSummaryQuery = (
  params?: PayeeRowsQuery,
  config?: QueryConfig<IPayeeSearchRowsSummary, WSServiceError>
) => {
  return useWSQuery<IPayeeSearchRowsSummary, WSServiceError>(
    [QUERY_PAYEE_ROWS_SUMMARY, params],
    () =>
      getPayeeRowsSummary({
        ...params,
        page: { size: 1, number: 1 }
      }),
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};
