import { WSModal, useModalContext } from "@wingspanhq/fe-component-library";
import React from "react";
import { Auth, AuthProps } from "../Auth";

export const CLIENT_SIGN_IN_MODAL_KEY = "clientSignInModal";

export const ClientSignInModal: React.FC = () => {
  const { closeModal } = useModalContext();
  return (
    <WSModal name={CLIENT_SIGN_IN_MODAL_KEY} size="S" p="M">
      {(props?: AuthProps) => (
        <Auth
          {...props}
          onSuccess={() => {
            props?.onSuccess?.();
            closeModal(CLIENT_SIGN_IN_MODAL_KEY);
          }}
        />
      )}
    </WSModal>
  );
};
