import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import {
  ICardResponse,
  ISetPinRequest
} from "../../../services/api/banking/cards/types";
import { QUERY_CARD } from "../queries/useCardQuery";
import { QUERY_CARDS } from "../queries/useCardsQuery";
import { updatePIN } from "../../../services/api/banking/cards/updatePIN";

export const useSetPINCard = () =>
  useWSMutation<
    ICardResponse,
    WSServiceError,
    {
      cardId: string;
    } & ISetPinRequest
  >(
    ({ cardId, ...request }) => {
      return updatePIN(cardId, request);
    },
    {
      dependencies(response, { cardId }) {
        return [[QUERY_CARD, cardId], QUERY_CARDS];
      }
    }
  );
