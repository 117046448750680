import { IInvoice } from "@wingspanhq/payments/dist/interfaces";
import { IInvoiceRow } from "../../modules/Invoicing/service";

export const calculateAmountAvailableToRefund = (
  invoice: IInvoice | IInvoiceRow
) => {
  if (!invoice.amountDetails?.clientPaid) {
    return 0;
  }

  const clientPaid = -invoice.amountDetails?.clientPaid;

  const refundedAmount = (invoice.refundDestinations || []).reduce(
    (acc, value) => (acc += value.amount),
    0
  );

  return Number((clientPaid - refundedAmount).toFixed(2));
};
