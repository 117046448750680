import { servicePayments } from "../servicePayments";

import {
  IBeneficiaryVerificationRequest,
  IBeneficiaryVerificationResponse
} from "@wingspanhq/payments/dist/interfaces";

export const checkAccountBeneficiaryVerification = async (
  request: IBeneficiaryVerificationRequest
): Promise<IBeneficiaryVerificationResponse> => {
  const { data } = await servicePayments.post(
    "/verification/beneficiary",
    request
  );

  return data;
};
