import {
  ITaxFormResponse,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces";

export const selectorTaxFormIsPDFDownloadAvailableForPayer = (
  taxForm: ITaxFormResponse
): boolean => {
  return (
    [
      TaxFormStatus.SubmittedToIrs,
      TaxFormStatus.AcceptedByIrs,
      TaxFormStatus.RejectedByIrs
    ].includes(taxForm.status) && !!taxForm.currentSubmissionId
  );
};
