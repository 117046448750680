import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSLoader,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WSQueries } from "../../../query/WSQuery";
import { useAccount } from "../../../query/users/queries";
import { selectorAccountFullName } from "../../selectors/selectorAccountFullName";
import { IconInstitution } from "../IconInstitution";

type Props = { accountId: string; skipError?: boolean } & WSElementProps;

export const BankAccount: React.FC<Props> = ({
  accountId,
  skipError,
  ...elementProps
}) => {
  const accountQuery = useAccount(accountId);

  return (
    <WSElement {...elementProps}>
      <WSQueries
        queries={{ accountQuery }}
        renderLoader={() => (
          <WSElement {...elementProps}>
            <WSLoader.Spinner size="S" />
          </WSElement>
        )}
        renderErrors={skipError ? () => null : undefined}
      >
        {({ accountQuery: { data: account } }) => (
          <WSFlexBox.CenterY wrap="nowrap" justify="space-between">
            <WSFlexBox.CenterY wrap="nowrap" gap="M">
              <IconInstitution institutionId={account.institutionId} />
              <WSText kind="ParagraphSm">
                {selectorAccountFullName(account)}
              </WSText>
            </WSFlexBox.CenterY>
          </WSFlexBox.CenterY>
        )}
      </WSQueries>
    </WSElement>
  );
};
