import {
  useIsMobile,
  WSControl,
  WSElement,
  WSFlexBox,
  WSForm,
  WSInfoBox,
  WSInputDate,
  WSInputMask,
  WSInputNumber,
  WSInputText,
  WSList,
  WSSelect
} from "@wingspanhq/fe-component-library";
import get from "lodash/get";
import { useFormContext } from "react-hook-form";
import { COUNTRY_OPTIONS } from "../../../../constants/user";
import { getInternationalTaxIdInfo } from "../../../../shared/utils/internationalTaxId";
import { getTaxIdLabel, getTaxIdMask } from "../../utils/taxId";
import { FormDataAddress, FormPartialAddress } from "../FormPartialAddress";
import {
  FormDataPhoneNumber,
  FormPartialPhoneNumber
} from "../FormPartialPhoneNumber";
import { useMemo } from "react";

export type FormPartialPersonType =
  | "Individual"
  | "Representative"
  | "BeneficialOwner";

export type FormPartialDataPerson = {
  firstName: string;
  middleName: string;
  lastName: string;
  jobTitle: string;
  ownershipStake: number | null;
  representativeConfirmation: boolean;
  birthday: Date | null;
  email: string;
  phone: FormDataPhoneNumber;
  country: string;
  taxId: string;
  address: FormDataAddress;
};

type Props = {
  type: FormPartialPersonType;
  name: string;
  showOwnershipStake?: boolean;
  requireTaxId?: boolean;
  singleOwnershipStake?: boolean;
  mismatch?: boolean;
  country: string;
};

export const FormPartialPerson: React.FC<Props> = ({
  type,
  name,
  showOwnershipStake,
  singleOwnershipStake,
  requireTaxId,
  mismatch,
  country
}) => {
  const { trigger, errors } = useFormContext();
  const isMobile = useIsMobile();

  const ownershipStakeError = get(errors, name + ".ownershipStake");

  const internationalTaxIdInfo = useMemo(
    () => getInternationalTaxIdInfo(country, "Individual"),
    [country]
  );

  return (
    <WSElement>
      <WSList gap="2XL">
        <WSInfoBox>
          Spell {type === "BeneficialOwner" ? "the person’s" : "your"} first and
          last name exactly as shown on{" "}
          {type === "BeneficialOwner" ? "their" : "your"} government-issued ID.
        </WSInfoBox>

        <WSFlexBox
          direction={isMobile ? "column" : "row"}
          wrap="nowrap"
          gap="L"
        >
          <WSForm.Field
            name={name + ".firstName"}
            label="Legal first name"
            component={WSInputText}
            componentProps={{
              required: true,
              placeholder: "Enter first name",
              fsExclude: true,
              autoComplete: "given-name",
              status: mismatch ? "warning" : undefined,
              message: mismatch ? "Mismatched info" : undefined
            }}
          />

          <WSForm.Field
            name={name + ".middleName"}
            label="Middle name"
            component={WSInputText}
            componentProps={{
              placeholder: "Enter middle name",
              fsExclude: true,
              autoComplete: "additional-name"
            }}
          />

          <WSForm.Field
            name={name + ".lastName"}
            label="Legal last name"
            component={WSInputText}
            componentProps={{
              required: true,
              placeholder: "Enter last name",
              fsExclude: true,
              autoComplete: "family-name",
              status: mismatch ? "warning" : undefined,
              message: mismatch ? "Mismatched info" : undefined
            }}
          />
        </WSFlexBox>

        <WSForm.Field
          name={name + ".jobTitle"}
          label="Job title / occupation"
          component={WSInputText}
          componentProps={{
            required: true,
            placeholder: `Enter ${
              type === "BeneficialOwner" ? "the person’s" : "your"
            } job title / occupation`,
            helperText:
              "Enter the role or description that best describes your work"
          }}
        />

        {showOwnershipStake && (
          <>
            <WSForm.Field
              name={name + ".ownershipStake"}
              label="Ownership stake"
              onChange={() => {
                trigger(name + ".ownershipStake");
              }}
              component={WSInputNumber}
              componentProps={{
                required: true,
                iconRight: "percent",
                disabled: singleOwnershipStake,
                min: 0,
                max: 100,
                helperText: `Enter the approximate percentage (%) ownership stake ${
                  type === "BeneficialOwner" ? "this person has" : "you have"
                } in the business`,
                status: ownershipStakeError ? "error" : undefined,
                message: ownershipStakeError?.message
              }}
            />

            <WSForm.Value name={name + ".ownershipStake"}>
              {ownershipStake => {
                if (
                  type === "Representative" &&
                  ownershipStake !== null &&
                  ownershipStake < 25
                ) {
                  return (
                    <WSInfoBox title="Important:">
                      <p>
                        You need to be a beneficial owner (25% ownership or
                        more) or an authorized representative to verify your
                        business
                      </p>

                      <p>
                        If you are not a beneficial owner or authorized
                        representative of the business (such as a controller or
                        officer), we recommend having either a beneficial owner
                        or authorized representative of the business setup the
                        account instead. Otherwise, we cannot successfully
                        verify your business.
                      </p>

                      <WSForm.Field
                        name={name + ".representativeConfirmation"}
                        component={WSControl}
                        componentProps={{
                          type: "checkbox",
                          size: "S",
                          label:
                            "I confirm that I’m an authorized representative of the business with less than 25% ownership stake."
                        }}
                      />
                    </WSInfoBox>
                  );
                }

                return null;
              }}
            </WSForm.Value>
          </>
        )}

        <WSForm.Field
          name={name + ".birthday"}
          label="Birthday"
          component={WSInputDate}
          componentProps={{
            required: true,
            placeholder: "MM/DD/YYYY",
            fsExclude: true
          }}
        />

        {type === "BeneficialOwner" && (
          <>
            <WSForm.Field
              name={name + ".email"}
              label="Contact email"
              component={WSInputText}
              componentProps={{
                placeholder: "Enter contact email",
                required: true
              }}
            />
            <FormPartialPhoneNumber
              name={name + ".phone"}
              label="Contact phone number"
              required
            />
          </>
        )}

        <WSForm.Field
          label="Country of residence"
          name={name + ".country"}
          component={WSSelect}
          componentProps={{
            placeholder: "Country",
            internalSearch: true,
            options: COUNTRY_OPTIONS,
            required: true
          }}
        />

        {country === "US" || country === "CA" ? (
          <WSForm.Field
            key={country + "-tax-id"}
            name={name + ".taxId"}
            label={
              getTaxIdLabel("Individual", country) +
              (requireTaxId ? "" : " (optional)")
            }
            component={WSInputMask}
            componentProps={{
              required: requireTaxId,
              placeholder: getTaxIdMask("Individual", country),
              mask: getTaxIdMask("Individual", country),
              unmask: true,
              fsExclude: true,
              status: mismatch ? "warning" : undefined,
              message: mismatch ? "Mismatched info" : undefined
            }}
          />
        ) : (
          <WSForm.Field
            key={country + "-tax-id"}
            name={name + ".taxId"}
            label={
              internationalTaxIdInfo.name + (requireTaxId ? "" : " (optional)")
            }
            component={WSInputText}
            componentProps={{
              required: requireTaxId,
              fsExclude: true,
              placeholder: internationalTaxIdInfo.placeholder,
              status: mismatch ? "warning" : undefined,
              message: mismatch ? "Mismatched info" : undefined
            }}
          />
        )}

        <WSForm.Value name={name + ".country"}>
          {country => (
            <FormPartialAddress
              country={country}
              name={name + ".address"}
              label="Personal address"
            />
          )}
        </WSForm.Value>
      </WSList>
    </WSElement>
  );
};
