import {
  TransactionType,
  WSCategory
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  ALL_SECTIONS,
  CategoryItemValue,
  CategorySections,
  INFLOW_SECTIONS,
  OUTFLOW_SECTIONS,
  PseudoCategory
} from "./categories";
import { WSCategoriesData } from "./utils";

export type CategoryState = {
  sections: CategorySections;
  selectedValue: CategoryItemValue;
};

export const mapDataToCategoryState: (
  isFiltering: boolean,
  data?: WSCategoriesData
) => CategoryState = (isFiltering, data) => {
  if (isFiltering) {
    return {
      sections: ALL_SECTIONS,
      selectedValue: {
        wsCategory: data?.wsCategory,
        subcategory: data?.subcategory
      }
    };
  }

  if (!data || !data.type) {
    return { sections: ALL_SECTIONS, selectedValue: {} };
  }

  if (!data.business) {
    return {
      sections: ALL_SECTIONS,
      selectedValue: {
        pseudoCategory: PseudoCategory.IGNORED,
        wsCategory: data.wsCategory,
        subcategory: data.subcategory,
        type: data.type
      }
    };
  }

  if (data.type === TransactionType.Income && data.isPositive) {
    return {
      sections: INFLOW_SECTIONS,
      selectedValue: {
        pseudoCategory: PseudoCategory.INCOME_REFUND,
        wsCategory: data.wsCategory,
        subcategory: data.subcategory,
        type: data.type
      }
    };
  }

  if (data.type === TransactionType.Expense && !data.isPositive) {
    return {
      sections: OUTFLOW_SECTIONS,
      selectedValue: {
        pseudoCategory: PseudoCategory.EXPENSE_REFUND,
        wsCategory: data.wsCategory,
        subcategory: data.subcategory,
        type: data.type
      }
    };
  }

  if (data.type === TransactionType.Transfer) {
    return {
      sections: ALL_SECTIONS,
      selectedValue: {
        wsCategory: WSCategory.Transfer,
        subcategory: data.subcategory,
        type: data.type
      }
    };
  }

  if (data.type === TransactionType.Expense) {
    return {
      sections: OUTFLOW_SECTIONS,
      selectedValue: {
        wsCategory: data.wsCategory,
        subcategory: data.subcategory,
        type: data.type
      }
    };
  }

  return {
    sections: INFLOW_SECTIONS,
    selectedValue: {
      wsCategory: data.wsCategory,
      subcategory: data.subcategory,
      type: data.type
    }
  };
};

export const mapValuesToData: (
  selectedValue: CategoryItemValue,
  data?: WSCategoriesData
) => WSCategoriesData = (selectedValue, data) => {
  const { subcategory } = selectedValue;
  let wsCategory = selectedValue.wsCategory ?? WSCategory.Other;
  let isPositive = data?.isPositive ?? true;
  let business = data?.business ?? true;
  let type: TransactionType =
    selectedValue.type ?? data?.type ?? TransactionType.Expense;

  if (
    selectedValue.type === TransactionType.Expense ||
    selectedValue.type === TransactionType.Income
  ) {
    business = true;
  }

  if (
    selectedValue.wsCategory !== WSCategory.Transfer &&
    selectedValue.wsCategory !== WSCategory.Income
  ) {
    isPositive = true;
  }

  if (selectedValue.wsCategory === WSCategory.Income) {
    isPositive = false;
  }

  if (selectedValue.pseudoCategory === PseudoCategory.INCOME_REFUND) {
    isPositive = true;
  }

  if (selectedValue.pseudoCategory === PseudoCategory.EXPENSE_REFUND) {
    isPositive = false;
  }

  if (selectedValue.pseudoCategory === PseudoCategory.EXPENSE) {
    isPositive = true;
  }

  if (selectedValue.wsCategory === WSCategory.Transfer) {
    business = false;
  }

  return {
    business,
    isPositive,
    type,
    wsCategory,
    subcategory
  };
};
