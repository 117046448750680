import { verificationService } from "../../../../../shared/services/verificaiton";
import { NotificationTemplate } from "../index";

export const TaxVerification: NotificationTemplate<never> = {
  renderTitle: props => props.subject,
  renderBody: props => props.message,
  buttonActionAsync: (_, history) => async () => {
    await verificationService.completeTaxVerification(history);
  },
  buttonText: props => props.actionTitle || "Complete profile"
};
