import { WSFormOld } from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import React from "react";
import * as Yup from "yup";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import {
  FormDataAddress,
  FormPartialAddress,
  formDataToRequestAddress
} from "../../../shared/components/FormPartialAddress";
import { validatorAddress } from "../../../utils/validators";
import { FormActions } from "./FormActions";
import styles from "./SettingsUpdateForms.module.scss";

export interface BusinessAddressFormValues {
  businessAddress: FormDataAddress;
  isHomeAddressSame: boolean;
  homeAddress: FormDataAddress;
}

interface BusinessAddressFormProps {
  field: string;
  onSubmit: (data: any) => void;
  defaultValues: BusinessAddressFormValues;
  onCancel?: (data: any) => void;
}

export const BusinessAddressForm: React.FC<BusinessAddressFormProps> = ({
  onSubmit,
  defaultValues = {},
  onCancel
}) => {
  console.log(defaultValues);
  const userId = useUserId();
  const [updateMemberProfile] = useUpdateMemberProfile();
  const onFormSubmit = async (formValues: BusinessAddressFormValues) => {
    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        address: formDataToRequestAddress(formValues.businessAddress),
        ...(defaultValues.isHomeAddressSame
          ? {
              homeAddress: formDataToRequestAddress(formValues.businessAddress)
            }
          : {})
      }
    };
    await updateMemberProfile(request, {
      onSuccess: () => {
        onSubmit(formValues);
      }
    });
  };

  return (
    <WSFormOld<BusinessAddressFormValues>
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        businessAddress: validatorAddress
      })}
    >
      <FormPartialAddress name="businessAddress" mb="XL" />

      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <FormActions onCancel={onCancel} loading={isSubmitting} />
        )}
      </WSFormOld.Context>
    </WSFormOld>
  );
};
