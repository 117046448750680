export const QUERY_FILES_MEMBER_PUBLIC_FILES =
  "QUERY_FILES_MEMBER_PUBLIC_FILES";
export const QUERY_FILE_MEMBER_PUBLIC_FILE = "QUERY_FILE_MEMBER_PUBLIC_FILE";
export const QUERY_FILE_MEMBER_PUBLIC_FILE_SUMMARY =
  "QUERY_FILE_MEMBER_PUBLIC_FILE_SUMMARY";
export const QUERY_FILES_MEMBER_PRIVATE_FILES =
  "QUERY_FILES_MEMBER_PRIVATE_FILES";

export const QUERY_TEMPLATE = "QUERY_TEMPLATE";
export const QUERY_TEMPLATES = "QUERY_TEMPLATES";
