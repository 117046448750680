import { getLast4 } from "./getLast4";

export const getInternalAccountLast4Digits = (
  number: number | string | undefined
): string => {
  if (!number) return "N/A";
  const numberStr = number.toString();
  const lastFourDigits = getLast4(numberStr);
  return `x${lastFourDigits}`;
};
