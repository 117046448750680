import {
  WSButton,
  WSCheckboxToggle,
  WSMarginProps
} from "@wingspanhq/fe-component-library";
import { IPaymentCard } from "@wingspanhq/payments/dist/interfaces";
import { useState } from "react";
import { Overlay } from "../../../components/Overlay";
import { usePaymentCardForm } from "../../../shared/hooks/usePaymentCardForm";
import { Card } from "../Card/Card";
import { PaymentCard } from "../PaymentCard";

type Props = {
  onSignIn: () => void;
  requireSignIn?: boolean;
  value: string;
  onChange: (newValue: string) => void;
  clientId: string;
} & WSMarginProps;

export const SelectPaymentCardGuest: React.FC<Props> = ({
  onSignIn,
  requireSignIn,
  value,
  onChange,
  clientId,
  ...elementProps
}) => {
  const [paymentCard, setPaymentCard] = useState<IPaymentCard | undefined>();

  const paymentCardForm = usePaymentCardForm({
    clientId,
    oneTime: true
  });

  const openPaymentCardForm = () => {
    paymentCardForm.open({
      onSuccess: paymentCard => {
        onChange(paymentCard.paymentCardId);
        setPaymentCard(paymentCard);
      }
    });
  };

  return (
    <Card
      title="Credit card information"
      action={
        paymentCard
          ? {
              kind: "Link",
              icon: "edit",
              children: "Edit",
              onClick: openPaymentCardForm
            }
          : undefined
      }
      {...elementProps}
    >
      {requireSignIn && <Overlay overParent onClick={onSignIn} />}

      {paymentCard ? (
        <PaymentCard mb="M" paymentCard={paymentCard} />
      ) : (
        <WSButton.Link mb="M" icon="plus-circle" onClick={openPaymentCardForm}>
          Enter credit card info
        </WSButton.Link>
      )}

      <WSCheckboxToggle
        label="Save for 1-click payment"
        name="savePaymentMethod"
        value={requireSignIn || false}
        onChange={onSignIn}
      />
    </Card>
  );
};
