import { useHistory } from "react-router-dom";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUpdateActivity } from "../../../../query/users/mutations";
import { OverlaySpinner } from "../../../../components/OverlaySpinner";
import {
  FlowSetup,
  SetupRouteComponentProps
} from "../../../../shared/components/FlowSetup";

import { RouteImportRecipients } from "./RouteImportRecipients";
import { RouteRecipientsImportPreview } from "./RouteRecipientsImportPreview";
import { RouteRecipientsImportProgress } from "./RouteRecipientsImportProgress";
import { RouteRecipientsImportErrors } from "./RouteRecipientsImportErrors";

import React, { ReactNode } from "react";
import { IBulkCollaboratorBatch } from "@wingspanhq/payments/dist/interfaces";

type ImportRecipientsFlowProps = {
  basePath: string;
  onBack: () => Promise<void> | void;
  onFinish: () => Promise<void> | void;
  Layout: React.FC<{
    title: string;
    children: ReactNode;
    onBack?: () => void;
  }>;
};

export type SetupImportRecipientsComponentProps = SetupRouteComponentProps<{
  Layout?: React.FC<{
    title: string;
    children: ReactNode;
    onBack?: () => void;
  }>;
}>;
export const ImportRecipientsFlow: React.FC<ImportRecipientsFlowProps & {
  parentPath?: string;
  isEdit?: boolean;
}> = ({ basePath, parentPath, onBack, isEdit, Layout }) => {
  const history = useHistory();
  const userId = useUserId();
  // const memberQuery = useMemberProfile(userId);
  // const { track } = useWSAnalytics();

  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);

  return (
    <>
      {updateActivityMeta.isLoading && <OverlaySpinner />}

      <FlowSetup
        basePath={basePath}
        routes={[
          {
            path: "/:bulkBatchId/preview",
            component: RouteRecipientsImportPreview,
            basePath,
            Layout,
            onBack: () => {
              // there is no back to import recipients page,
              // we will directly go to import recipients question page
              onBack();
            },
            onNext: context => {
              history.replace(`${basePath}/${context?.bulkBatchId}/upload`);
            }
          },
          {
            path: "/:bulkBatchId/upload",
            component: RouteRecipientsImportProgress,
            isEdit,
            Layout,
            onBack: () => {
              // there is no back to import recipients page,
              // we will directly go to import recipients question page
              onBack();
            },
            onNext: context => {
              const collaboratorBulkBatch = context?.collaboratorBulkBatch as IBulkCollaboratorBatch;
              // We are getting Batch status as Completed though some items are failed.
              // So checking for itemsFailed to navigate to errors page.
              if ((collaboratorBulkBatch?.statistics?.itemsFailed || 0) === 0) {
                if (isEdit) {
                  // After successful edit, navigating to dashboard.
                  history.replace("/member/1099-filing/dashboard/all");
                } else {
                  history.replace(`${parentPath}/calculation-settings`);
                }
              } else {
                history.replace(
                  `${basePath}/${collaboratorBulkBatch?.bulkCollaboratorBatchId}/errors`
                );
              }
            }
          },
          {
            path: "/:bulkBatchId/errors",
            component: RouteRecipientsImportErrors,
            isEdit,
            Layout,
            onBack: () => {
              // there is no back to import recipients page,
              // we will directly go to import recipients question page
              onBack();
            },
            onNext: context => {
              // upload failed items again
              if (context?.bulkBatchId) {
                history.push(`${basePath}/${context?.bulkBatchId}`);
              }
              if (context?.skipErrors) {
                history.replace(`${parentPath}/calculation-settings`);
              }
            }
          },
          {
            path: "/:bulkBatchId",
            component: RouteImportRecipients,
            onBack: () => {
              onBack();
            },
            onNext: context => {
              history.replace(`${basePath}/${context?.bulkBatchId}/preview`);
            }
          }
        ]}
      />
    </>
  );
};
