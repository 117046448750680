import React from "react";

import { WSIcon, WSIconProps } from "../../core/WSIcon/WSIcon.component";
import { WSText, WSTextProps } from "../../core/WSText/WSText.component";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { ComponentWithKinds } from "../../WSElement/WSElement.helpers";

import styles from "./WSIconList.component.module.scss";

export const WSIconListKinds = ["Checks"] as const;

type WSIconListKindsType = typeof WSIconListKinds[number];

export interface IconListItem {
  icon: WSIconProps;
  description: React.ReactNode;
}
export interface WSIconListProps extends WSElementProps {
  items: (string | IconListItem)[];
  iconProps?: WSIconProps;
  textProps?: WSTextProps;
}

export const WSIconList: ComponentWithKinds<
  WSIconListProps,
  WSIconListKindsType
> = ({ items, iconProps, textProps, ...otherProps }) => {
  const textPropsInternal = textProps || {
    kind: "Paragraph",
    color: "gray600"
  };
  const iconPropsInternal = iconProps || {
    name: "chevron-right",
    color: "gray600"
  };
  const itemsTemplate = items.map((item: string | IconListItem) =>
    typeof item === "string" ? (
      <WSElement mb="M">
        <WSText as="span" {...textPropsInternal}>
          <WSIcon className={styles.icon} {...iconPropsInternal} />
          {item}
        </WSText>
      </WSElement>
    ) : (
      <WSElement mb="M">
        <WSText as="span" {...textPropsInternal}>
          <WSIcon className={styles.icon} {...item.icon} />
          {item.description}
        </WSText>
      </WSElement>
    )
  );

  return <WSElement {...otherProps}>{itemsTemplate}</WSElement>;
};

WSIconList.Checks = (props) => (
  <WSIconList
    iconProps={{
      name: "check",
      color: "green100"
    }}
    textProps={{
      kind: "Paragraph",
      color: "gray600"
    }}
    {...props}
  />
);
