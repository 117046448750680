import {
  WSElement,
  WSFlexBox,
  WSPanel,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import { classNames } from "primereact/utils";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { OnboardingStep } from "../../types";
import styles from "./index.module.scss";
import { StepPoint } from "./StepPoint";

type NavigationProps = {
  navigation: OnboardingStep[];
};

export const Navigation: React.FC<NavigationProps> = ({ navigation }) => {
  const location = useLocation();
  const activeIndex = useMemo(
    () => navigation.findIndex(step => location.pathname.includes(step.slug)),
    [location.pathname, navigation]
  );

  return (
    <WSElement className={styles.navigation}>
      {navigation.map((step, index) => {
        const isActive = index === activeIndex;
        const isComplete = step.status === "Complete";
        const isPending = step.status === "Pending";
        const isLoading = step.status === "Loading";

        return (
          <WSPanel
            key={step.title}
            className={classNames(
              styles.navigationItem,
              isActive && styles.navigationItemActive
            )}
          >
            <WSFlexBox justify="space-between" wrap="nowrap">
              <WSText weight="medium" color={isActive ? "gray600" : "gray400"}>
                {step.title}
              </WSText>
              {isComplete && <WSPill icon theme="success" />}
              {isPending && <WSPill icon="time" theme="neutral" />}
              {isLoading && (
                <WSPill shimmer={true} icon="loader" theme="blue" />
              )}
            </WSFlexBox>

            {isActive && !isComplete && !isPending && step.steps && (
              <NavigationLevel2 isLoading={isLoading} navigation={step.steps} />
            )}
          </WSPanel>
        );
      })}
    </WSElement>
  );
};

interface NavigationChildProps extends NavigationProps {
  isLoading?: boolean;
}

const NavigationLevel2: React.FC<NavigationChildProps> = ({
  navigation,
  isLoading
}) => {
  const location = useLocation();
  const activeIndex = useMemo(
    () => navigation.findIndex(step => location.pathname.includes(step.slug)),
    [location.pathname, navigation]
  );

  return (
    <WSElement className={styles.navigationLevel2}>
      {navigation.map((step, index) => {
        const isActive = index === activeIndex;
        const isComplete = index < activeIndex;

        return (
          <WSElement
            className={classNames(
              styles.navigationItem,
              isActive && styles.navigaitonItemActive,
              isComplete && styles.navigationItemComplete
            )}
          >
            <StepPoint
              isLoading={isLoading}
              isActive={isActive}
              isComplete={isComplete}
            />

            <WSText.ParagraphXs
              weight="medium"
              color={isActive || isComplete ? "gray600" : "gray300"}
            >
              {step.title}
            </WSText.ParagraphXs>
          </WSElement>
        );
      })}
    </WSElement>
  );
};
