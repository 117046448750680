declare global {
  interface Window {
    XLSX: any;
  }
}

export const initSheetJS = () => {
  const SCRIPT_ID = "sheetjs";

  return new Promise<any>(resolve => {
    if (window.XLSX) {
      resolve(window.XLSX);
      return;
    }

    const _script = document.querySelector<HTMLScriptElement>(
      `script[data-script-id="${SCRIPT_ID}"]`
    );

    if (_script) {
      _script.onload = () => {
        resolve(window.XLSX);
      };
    } else {
      const script = document.createElement("script");
      script.setAttribute("data-script-id", SCRIPT_ID);
      script.src =
        "https://cdn.sheetjs.com/xlsx-0.19.3/package/dist/xlsx.full.min.js";
      document.head.appendChild(script);
      script.onload = () => {
        resolve(window.XLSX);
      };
    }
  });
};
