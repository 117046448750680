import {
  useIsS,
  useIsXS,
  WSElement,
  WSGrid,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import { useMemo } from "react";
import { OnboardingStep } from "../../types";
import styles from "./index.module.scss";
import { useBrowserPageTitle } from "../../../../shared/hooks/useBrowserPageTitle";
import { Stepper } from "./Stepper";
import { Navigation } from "./Navigation";
import { AppFooter } from "../../../../shared/components/AppFooter";

type Props = {
  title?: string;
  onClose?: () => void;
  navigation?: OnboardingStep[];
};

export const Layout: React.FC<Props> = ({
  title,
  onClose,
  navigation,
  children
}) => {
  useBrowserPageTitle(title);
  const isXS = useIsXS();
  const isS = useIsS();

  const hasNavigation = useMemo(() => navigation && navigation.length > 0, [
    navigation
  ]);

  return (
    <WSElement className={styles.layout}>
      <WSElement className={styles.header}>
        <WSText.ParagraphSm my="L" weight="medium">
          {title}
        </WSText.ParagraphSm>

        {onClose && (
          <WSIcon onClick={onClose} className={styles.closeIcon} name="exit" />
        )}
      </WSElement>

      <WSElement
        className={classNames(
          styles.content,
          hasNavigation && styles.twoColumn
        )}
      >
        {hasNavigation ? (
          <WSGrid>
            <WSGrid.Item span={{ m: "3" }}>
              {isXS || isS ? (
                <Stepper navigation={navigation || []} mb="L" />
              ) : (
                <Navigation navigation={navigation || []} />
              )}
            </WSGrid.Item>
            <WSGrid.Item span={{ m: "9" }}>{children}</WSGrid.Item>
          </WSGrid>
        ) : (
          children
        )}
      </WSElement>
      <AppFooter showLinks={false} showAdditionalInfo={false} />
    </WSElement>
  );
};
