import { WSButton } from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import { useBankingAccount } from "../../../../query/bookkeeping/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import { usePayoutSettings } from "../../../../query/payments/queries";
import { useAccounts, useMemberProfile } from "../../../../query/users/queries";
import { selectorIsDomesticMember } from "../../../../shared/selectors/selectorIsDomesticMember";
import { LayoutFullscreen } from "../../../../shared/components/LayoutFullscreen";
import { StandardMethods } from "../../components/StandardMethods";

export const RouteAddStandardPlaid: React.FC<RouteComponentProps> = () => {
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryBankingAccount = useBankingAccount();
  const queryAccounts = useAccounts();
  const history = useHistory();
  const queryMember = useMemberProfile(userId);

  return (
    <LayoutFullscreen
      headTitle="Add payout method"
      title="Set default payout method"
      description="Select an account you added through Plaid as your default payout method"
      onBack={() => {
        history.push("/member/settings/payment-methods");
      }}
    >
      <WSQueries
        queries={{
          queryPayoutSettings,
          queryAccounts
        }}
      >
        {({
          queryPayoutSettings: { data: payoutSettings },
          queryAccounts: { data: accounts }
        }) => (
          <StandardMethods
            showInternationalTitle={
              !(queryMember.data && !selectorIsDomesticMember(queryMember.data))
            }
            payoutSettings={payoutSettings}
            internalAccount={queryBankingAccount.data}
            accounts={accounts}
          />
        )}
      </WSQueries>

      <WSButton
        fullWidth
        onClick={() => {
          history.push("/member/settings/payment-methods");
        }}
        name="continue"
      >
        Set default payout method
      </WSButton>
    </LayoutFullscreen>
  );
};
