import { useHistory } from "react-router-dom";
import {
  WSContainer,
  WSGrid,
  WSLayout,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { FormCardAcknowledgements } from "../components/FormCardAcknowledgements";
import { useActivateVirtualCardModal } from "../modals/CreateVirtualCard/useActivateVirtualCardModal";
import { WalletCardType } from "../types";
import { useCreateVirtualCardModal } from "../modals/CreateVirtualCard/useCreateVirtualCardModal";
import { WALLET_ROOT_PATH } from "../index";
import { useCardCreatedModal } from "../modals/CreateVirtualCard/useCardCreatedModal";

export const RouteActivateVirtualCard = () => {
  const history = useHistory();
  const activateVirtualCardModal = useActivateVirtualCardModal();
  const createVirtualCardModal = useCreateVirtualCardModal();
  const cardCreatedModal = useCardCreatedModal();

  return (
    <WSLayout
      headerCenter={
        <WSText.ParagraphSm weight="medium">
          Activate virtual card
        </WSText.ParagraphSm>
      }
      smallHeader={true}
      onClose={() => {
        history.push("/member/wallet");
      }}
      line
    >
      <WSContainer verticalPadding>
        <WSGrid>
          <WSGrid.Item span={{ m: "2" }} />
          <WSGrid.Item span={{ m: "8" }}>
            <FormCardAcknowledgements
              cardType={WalletCardType.Virtual}
              onBack={() => {
                history.push(WALLET_ROOT_PATH);
              }}
              onContinue={async () => {
                await createVirtualCardModal.open({
                  onSuccess: card => {
                    activateVirtualCardModal.open({
                      cardId: card.cardId,
                      onSuccess: () => {
                        cardCreatedModal.open({
                          onSuccess: () => {
                            history.push(WALLET_ROOT_PATH);
                          }
                        });
                      }
                    });
                  }
                });
              }}
            />
          </WSGrid.Item>
        </WSGrid>
      </WSContainer>
    </WSLayout>
  );
};
