import classNames from "classnames";
import React from "react";
import { WSElement } from "../../WSElement/WSElement.component";
import { WSContainer } from "../WSContainer/WSContainer.component";
import { WSFlexBox } from "../WSFlexBox/WSFlexBox.component";
import styles from "./WSSetup.module.scss";
import { WingspanLogo } from "./Logo";
import { WSIcon } from "../../core/WSIcon/WSIcon.component";
import { WSText } from "../../core/WSText/WSText.component";

export interface WSSetupProps {
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  bodyLeft?: React.ReactNode;
  bodyRight?: React.ReactNode;
  headerCenter?: React.ReactNode;
  onBack?: () => void;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
}

export const WSSetup: React.FC<WSSetupProps> = ({
  children,
  headerCenter = <WingspanLogo />,
  headerLeft,
  headerRight,
  bodyLeft,
  bodyRight,
  onBack,
  className,
  headerClassName,
  contentClassName
}) => (
  <WSElement className={classNames(styles.wrapper, className)}>
    <WSElement className={classNames(styles.header, headerClassName)}>
      <WSContainer className={styles.headerContainer}>
        <WSFlexBox.CenterY wrap="nowrap" justify="space-between">
          <WSElement className={styles.headerLeft}>{headerLeft}</WSElement>
          <WSElement className={styles.headerCenter}>{headerCenter}</WSElement>
          <WSElement className={styles.headerRight}>{headerRight}</WSElement>
        </WSFlexBox.CenterY>
      </WSContainer>
    </WSElement>
    <WSContainer className={classNames(styles.content, contentClassName)}>
      <WSElement className={styles.bodyLeft}>
        {bodyLeft ||
          (onBack ? (
            <WSFlexBox.CenterY onClick={onBack}>
              <WSIcon
                block
                name="chevron-left"
                size="S"
                mr="M"
                color="gray600"
              />
              <WSText weight="medium" color="gray600">
                Back
              </WSText>
            </WSFlexBox.CenterY>
          ) : null)}
      </WSElement>
      <WSElement className={styles.bodyCenter}>{children}</WSElement>
      <WSElement mb="XL" className={styles.bodyRight}>
        {bodyRight}
      </WSElement>
    </WSContainer>
  </WSElement>
);
