import { QueryConfig } from "react-query";

import { useWSQuery } from "../../helpers";
import { QUERY_SHARED_FILE_SETTINGS } from "../keys";
import { getSharedFileSettings } from "../../../services/sharedFile/getSharedFileSettings";
import { ISharedFileRequestSettingsResponse } from "@wingspanhq/shared-files/dist/lib/interfaces";

export const useSharedFileSettingsQuery = (
  sharedFileSettingsId: string,
  queryConfig?: QueryConfig<ISharedFileRequestSettingsResponse, unknown>
) =>
  useWSQuery(
    [QUERY_SHARED_FILE_SETTINGS, sharedFileSettingsId],
    () => getSharedFileSettings(sharedFileSettingsId),
    {
      retry: false,
      refetchOnMount: false,
      ...queryConfig
    }
  );
