import {
  WSButton,
  WSCheckboxToggle,
  WSText
} from "@wingspanhq/fe-component-library";
import { AutoPayStrategy } from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { AutopaySetupParams } from ".";
import { useWSMutation } from "../../../query/helpers";
import { QUERY_COLLABORATORS } from "../../../query/payments/keys";
import { paymentsService } from "../../../services/payments";
import { SetupRouteComponentProps } from "../../../shared/components/FlowSetup";
import { useSetWSStore } from "../../../store";
import { SetupAutopayLayout } from "./SetupAutopayLayout";

export const SetupConsent: React.FC<SetupRouteComponentProps<
  AutopaySetupParams
>> = ({ onBack, onNext, params }) => {
  const [agreement, setAgreement] = useState(false);
  const setStore = useSetWSStore();

  const [submit, submitMeta] = useWSMutation(
    async () => {
      if (!params?.collaboratorId) return;
      await paymentsService.collaborator.update(params.collaboratorId, {
        clientData: {
          autoPayStrategy: AutoPayStrategy.All
        }
      });

      // clear setup autopay data;
      window.sessionStorage.removeItem("signUpAutopayDetails");
      setStore({ signUpAutopayDetails: undefined });
    },
    {
      onSuccess: onNext,
      dependencies: [
        QUERY_COLLABORATORS,
        [QUERY_COLLABORATORS, params?.collaboratorId]
      ]
    }
  );

  if (!params?.collaboratorId && !params?.payeeId) {
    return null;
  }

  return (
    <SetupAutopayLayout
      collaboratorId={params.collaboratorId}
      onBack={onBack}
      title={companyName => `Consent to paying ${companyName} automatically?`}
    >
      {({ companyName }) => (
        <>
          <WSText mb="XL">
            {`Easily automate your ${companyName} invoice payments with our Autopay feature.`}
          </WSText>
          <WSText>
            By enrolling, you authorize us to securely charge your payment
            method for each invoice, ensuring timely and hassle-free
            transactions. Review the terms, provide your consent, and let
            Autopay manage the rest.
          </WSText>

          <WSCheckboxToggle
            my="XL"
            name="agreenment"
            label={`I agree to enroll in Autopay for ${companyName}`}
            value={agreement}
            onChange={value => {
              setAgreement(value);
            }}
          />

          <WSButton
            disabled={!agreement}
            fullWidth
            onClick={submit}
            loading={submitMeta.isLoading}
          >
            Continue
          </WSButton>
        </>
      )}
    </SetupAutopayLayout>
  );
};
