import {
  WSButton,
  WSButtons,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSMessageBox,
  WSRadioInputGroup,
  WSText
} from "@wingspanhq/fe-component-library";
import { ICollaboratorSchema } from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useMissingInfoCollaboratorsQuery } from "../../../../../query/payments/queries";
import { useUpdateActivity } from "../../../../../query/users/mutations";
import { useActivities } from "../../../../../query/users/queries";
import { get1099SetupProgress } from "../../../../../query/users/selectors";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { track } from "../../../../../utils/analytics";
import { useCreateBulkCollaboratorBatch } from "../../../../BulkImporter/query/bulkCollaborator/mutations";
import { PayerSetupLayout } from "../../../components/PayerSetupLayout";
import styles from "./styles.module.scss";

export enum ImportCollaboratorsOptions {
  YES = "YES",
  NO = "NO"
}

export const RouteSetupImportRecipientsQuestion: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const userId = useUserId();
  const qActivity = useActivities(userId);
  const [importCollaborators, setImportCollaborators] = useState<
    ImportCollaboratorsOptions
  >();
  const missingInfoCollaboratorsQuery = useMissingInfoCollaboratorsQuery();

  const [
    createBulkCollaboratorBatch,
    createBulkCollaboratorBatchMeta
  ] = useCreateBulkCollaboratorBatch();
  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);

  const onFormSubmit = async (
    importCollaborators?: ImportCollaboratorsOptions,
    collaborators: ICollaboratorSchema[] = []
  ) => {
    let bulkBatchResponse;
    if (importCollaborators === ImportCollaboratorsOptions.NO) {
      const nec1099SetupProgress = get1099SetupProgress(qActivity.data);
      await updateActivity({
        flows: {
          nec1099Setup: {
            ...nec1099SetupProgress,
            currentStep: 3
          }
        }
      });
    } else {
      bulkBatchResponse = await createBulkCollaboratorBatch(
        {},
        {
          onSuccess: () => {
            track("1099 Filing Recipients Bulk Upload Started");
          }
        }
      );
    }
    onNext({
      shouldImportCollaborators:
        importCollaborators === ImportCollaboratorsOptions.YES,
      bulkBatchId: bulkBatchResponse?.bulkCollaboratorBatchId,
      doMissingInfoCollaboratorsExists: collaborators.length > 0
    });
  };

  return (
    <PayerSetupLayout title="Add new contractors">
      <WSText.Heading4 mb="XL">
        Would you like to file Form 1099-NEC’s for contractors paid outside of
        Wingspan in 2023?
      </WSText.Heading4>
      <WSText color="gray600" mb="XL">
        You will be able to import additional contractors who will be added as
        recipients in the next step and invited to Wingspan.
      </WSText>

      <WSMessageBox.Info size="Large">
        <WSText.ParagraphSm color="gray600">
          If you’ve been making payments outside of Wingspan or switched to
          Wingspan mid-year, this is your seamless pathway to manage 1099s for
          all your recipients.
          <WSElement as="ul">
            <WSElement as="li" ml="XL" mt="M">
              In order for Wingspan to prepare a Form 1099-NEC for a recipient,
              specific details are needed for IRS submission, including legal
              name and address, among others
            </WSElement>
            <WSElement as="li" ml="XL" mt="M">
              Once you add recipients to your company in Wingspan, they’ll
              receive an invitation to create an account
            </WSElement>
          </WSElement>
        </WSText.ParagraphSm>
      </WSMessageBox.Info>

      <WSFormOld
        onSubmit={() =>
          onFormSubmit(importCollaborators, missingInfoCollaboratorsQuery.data)
        }
      >
        <WSRadioInputGroup
          mb="3XL"
          name="hasOffPlatformPayments"
          onChange={(event: any) => {
            setImportCollaborators(event.target?.value);
          }}
          itemElementProps={{ mt: "S" }}
          options={[
            {
              value: ImportCollaboratorsOptions.YES,
              label: "Yes, import new recipients to Wingspan"
            },
            {
              value: ImportCollaboratorsOptions.NO,
              label: "No, everyone I paid is here already"
            }
          ]}
          optionHasBorder
          value={importCollaborators}
        />

        <WSButtons mt="3XL">
          <WSButton.Secondary className={styles.actionBtn} onClick={onBack}>
            Back
          </WSButton.Secondary>
          <WSFormOld.SubmitButton
            className={styles.actionBtn}
            data-testid="hasOffPlatformPayments"
            loading={
              createBulkCollaboratorBatchMeta.isLoading ||
              updateActivityMeta.isLoading
            }
            disabled={!importCollaborators}
          >
            Continue
          </WSFormOld.SubmitButton>
        </WSButtons>

        <WSFlexBox.Center my="XL">
          <WSButton.Link
            type="button"
            onClick={() => {
              onFormSubmit(ImportCollaboratorsOptions.NO, []);
            }}
          >
            Skip for now
          </WSButton.Link>
        </WSFlexBox.Center>
      </WSFormOld>
    </PayerSetupLayout>
  );
};
