import {
  WSButton,
  WSButtons,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSInputNumberOld,
  WSPanel,
  WSPill,
  WSText,
  useWSModal
} from "@wingspanhq/fe-component-library";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { usePayerTaxFormUpdate } from "../../../../../query/taxForm/mutations/useTaxFormUpdate";
import { selector1099NECTotal } from "../../../selectors/selector1099NECTotal";

const ModalAdjustAmount: React.FC<{
  taxForm: IPayerTaxFormResponse;
  onClose(result?: IPayerTaxFormResponse): void;
}> = ({ onClose, taxForm }) => {
  const [updateTaxForm, updateTaxFormMeta] = usePayerTaxFormUpdate();
  const defaultAmount = selector1099NECTotal(taxForm);
  const [amount, setAmount] = React.useState(defaultAmount);

  return (
    <WSElement mt="XS">
      <WSText color="gray600">
        The amount recorded in Wingspan will be adjusted
      </WSText>
      <WSPanel p="S" mt="M">
        <WSFlexBox.CenterY justify="space-between">
          <WSText.ParagraphSm color="gray500">
            1099-NEC Total
          </WSText.ParagraphSm>
          <WSPill text="Original" />
        </WSFlexBox.CenterY>
        <WSFlexBox.CenterY mt="XL">
          <WSText.Heading5 formatMoney>{defaultAmount}</WSText.Heading5>
          {taxForm.groupSize ? (
            <WSFlexBox.CenterY ml="M">
              <WSIcon block name="group" color="gray400" size="XS" />
              <WSText.ParagraphSm ml="XS" color="gray400">
                Grouped forms
              </WSText.ParagraphSm>
              {/*<WSIcon block name="info-circle" color="gray400" onClick={() => {}} />*/}
            </WSFlexBox.CenterY>
          ) : null}
        </WSFlexBox.CenterY>
      </WSPanel>

      <WSText weight="medium" mt="XL">
        What should the adjusted 1099-NEC total be?
      </WSText>

      <WSInputNumberOld
        mt="M"
        mode="currency"
        currency="USD"
        min={0}
        placeholder="$_.__"
        onChange={(e: any) => {
          setAmount(e?.value as number);
        }}
        value={amount}
      />

      <WSText color="gray600" mt="M">
        The recipient will see the net adjustment amount in addition to their on
        platform payments
      </WSText>

      <WSErrorMessage
        contextKey="NEC1099DetailsUpdate"
        my="XL"
        error={updateTaxFormMeta.error}
      />

      <WSButtons mt="2XL" forceFullWidth>
        <WSButton.Primary
          disabled={
            typeof amount !== "number" || amount === taxForm.data.totalAmount
          }
          onAsyncClick={async () => {
            const response = await updateTaxForm(
              {
                taxFormId: taxForm.taxFormId,
                requestData: {
                  data: {
                    totalAmount: amount
                  }
                }
              },
              {
                throwOnError: true
              }
            );

            onClose(response);
          }}
        >
          Confirm
        </WSButton.Primary>
        <WSButton.Secondary onClick={() => onClose()}>
          Cancel
        </WSButton.Secondary>
      </WSButtons>
    </WSElement>
  );
};

export const useModalAdjustAmount = () =>
  useWSModal(ModalAdjustAmount, {
    title: "Edit 1099-NEC amount",
    size: "S"
  });
