import {
  IPendingWithholding,
  PendingWithholdingStatus,
  WithholdingTransactionType
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { TaxQuarter } from "@wingspanhq/bookkeeping/dist/lib/interfaces/taxEstimate";

export const selectorIsSendToIRSDisabled = (
  pendingTransactions: IPendingWithholding[],
  year: number,
  quarter: TaxQuarter
) => {
  return pendingTransactions.some(transaction => {
    return (
      transaction.type === WithholdingTransactionType.TaxPayment &&
      transaction.year === year &&
      transaction.quarter === quarter &&
      transaction.status === PendingWithholdingStatus.Pending
    );
  });
};
