import { WSElement, WSElementProps } from "@wingspanhq/fe-component-library";
import React, { useEffect, useState } from "react";
import styles from "./ProgressBar.module.scss";

type Props = {
  onComplete?: () => void;
  ms: number;
  showCompleted?: boolean;
} & WSElementProps;

export const ProgressBar: React.FC<Props> = ({
  onComplete,
  ms,
  showCompleted,
  ...elementProps
}) => {
  const [progress, setProgress] = useState(showCompleted ? 100 : 0);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (!showCompleted) {
        onComplete?.();
      }
    }, ms);
    setProgress(100);

    return () => {
      clearTimeout(timer);
    };
  }, [ms, onComplete, showCompleted]);

  return (
    <WSElement className={styles.progressContainer} {...elementProps}>
      <WSElement
        className={styles.progress}
        style={{
          width: `${progress}%`,
          transition: showCompleted ? "none" : undefined,
          transitionDuration: `${ms}ms`
        }}
      />
    </WSElement>
  );
};
