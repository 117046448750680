import { INewUser } from "@wingspanhq/users/dist/lib/interfaces/newUser";

export const isUserIdsIncluded = (
  user: INewUser | undefined,
  targetUserIds: string[]
) => {
  const ownerIds = user?.userRoles?.ownerIds || [];

  return targetUserIds.some(targetId =>
    ownerIds.some(id => id?.includes(targetId))
  );
};
