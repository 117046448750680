import { queryCache, QueryConfig } from "react-query";
import {
  concurrentActions,
  WSServiceError
} from "../../../utils/serviceHelper";
import { useWSQuery } from "../../../query/helpers";
import {
  IPayerEngagementListResponse,
  IPayerEngagementResponse
} from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { getPayerEngagements } from "../../../services/payerEngagements";
import { QUERY_PAYER_ENGAGEMENTS_LIST } from "../../../query/payerEngagements/keys";
import { useQueryPayerRowsAll } from "../../../query/search/payer/queries/useQueryPayerRowsAll";
import { IPayerRow } from "../../../services/search";
import { ISignedDocumentResponse } from "@wingspanhq/signed-documents/dist/lib/interfaces";
import flatten from "lodash/flatten";
import { PayerPayeeEngagementStatus } from "@wingspanhq/payments/dist/interfaces/payerPayeeEngagement";
import { DataSourceType } from "@wingspanhq/payments/dist/interfaces/requirement";
import { QUERY_SIGNED_DOCUMENT } from "../../../query/signedDocuments/keys";
import { getSignedDocument } from "../../../services/signedDocuments";
import { IRequirementResponse } from "@wingspanhq/payments/dist/interfaces/api/requirement";
import { selectorIsEngagementsEnabled } from "../selectors/getActivePayersWithEngagement";

export const QUERY_ALL_ENGAGEMENTS_BY_PAYER_IDS =
  "QUERY_ALL_ENGAGEMENTS_BY_PAYER_IDS";

export type IRequirementExtended = IRequirementResponse & {
  document?: ISignedDocumentResponse;
};

export type IPayerEngagementExtended = Omit<
  IPayerEngagementResponse,
  "requirements"
> & {
  requirements: IRequirementExtended[];
};

export type AllEngagementsByPayerIds = Array<{
  engagements: IPayerEngagementExtended[];
  payer: IPayerRow;
}>;

const getPayerRowsWithRequirements = (payers?: IPayerRow[]) => {
  return (
    payers?.filter(payer => {
      const isEngagementsEnabled = selectorIsEngagementsEnabled(
        payer.engagements
      );

      return payer.engagements.some(engagement => {
        return isEngagementsEnabled
          ? engagement.requirements?.length
          : engagement.eligibilityRequirements?.length;
      });
    }) ?? []
  );
};

export const useQueryAllPayersWithEngagements = (
  config?: QueryConfig<AllEngagementsByPayerIds, WSServiceError>
) => {
  const queryPayerRowsAll = useQueryPayerRowsAll(
    {},
    {
      onSuccess() {
        if (query.isIdle) query.refetch();
      }
    }
  );
  const payersWithRequirements = getPayerRowsWithRequirements(
    queryPayerRowsAll.data
  );

  const payerIds = payersWithRequirements.map(payer => payer.payerId);

  const query = useWSQuery<AllEngagementsByPayerIds, WSServiceError>(
    [QUERY_ALL_ENGAGEMENTS_BY_PAYER_IDS, payerIds],
    async () => {
      if (payerIds.length === 0) {
        return [];
      }

      const fetchEngagementsListsActions = payerIds.map((payerId, i) => () =>
        getPayerEngagements(payerId, {
          page: { size: 100, number: 1 }
        })
      );

      const engagementsLists = await concurrentActions<
        IPayerEngagementListResponse
      >(fetchEngagementsListsActions, {
        concurrentLimit: 5
      });

      const allEngagements = flatten(engagementsLists);
      const activeRequirements = flatten(
        allEngagements.map(engagement => {
          const isRequirementsNotHere =
            engagement.status !== PayerPayeeEngagementStatus.Active ||
            !engagement.requirements?.length;

          return isRequirementsNotHere ? [] : engagement.requirements;
        })
      );

      const fetchRequirementsDocumentsActions = activeRequirements.map(
        (requirement, i) => async () => {
          if (
            requirement.dataSourceType === DataSourceType.SignedDocument &&
            requirement.dataSourceId
          ) {
            const documentInCache = queryCache.getQueryData<
              ISignedDocumentResponse
            >([QUERY_SIGNED_DOCUMENT, requirement.dataSourceId]);

            if (documentInCache) {
              return documentInCache;
            } else {
              try {
                const document = await getSignedDocument(
                  requirement.dataSourceId
                );
                queryCache.setQueryData(
                  [QUERY_SIGNED_DOCUMENT, requirement.dataSourceId],
                  document
                );

                return document;
              } catch (e) {
                console.error(e);
              }
            }
          }
        }
      );

      const documentsLists = await concurrentActions<
        ISignedDocumentResponse | undefined
      >(fetchRequirementsDocumentsActions, {
        concurrentLimit: 5
      });

      return engagementsLists.map((engagements, index) => {
        const payer = payersWithRequirements[index] as IPayerRow;

        queryCache.setQueryData(
          [QUERY_PAYER_ENGAGEMENTS_LIST, payer.payerId],
          engagements
        );

        return {
          payer,
          engagements: engagements.map(engagement => {
            return {
              ...engagement,
              requirements: (engagement.requirements || []).map(
                (requirement, i) => {
                  return {
                    ...requirement,
                    document: documentsLists.find(
                      document =>
                        document?.signedDocumentId === requirement.dataSourceId
                    )
                  };
                }
              )
            };
          })
        };
      });
    },
    {
      ...config,
      enabled: payersWithRequirements.length > 0
    }
  );

  return query;
};
