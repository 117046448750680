import {
  useIsMobile,
  WSActions,
  WSFlexBox,
  WSForm,
  WSFormProps,
  WSInputMask,
  WSInputText,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSSelect
} from "@wingspanhq/fe-component-library";
import merge from "lodash/merge";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { US_STATES_OPTIONS } from "../../../../constants/user";
import { WSFormErrorSummary } from "../../../../shared/components/WSFormErrorSummary";
import {
  SELECT_OPTIONS_CANADIAN_PROVINCES,
  SELECT_OPTIONS_YEAR
} from "../../../../shared/constants/user";
import { canadaQuebecEnterpriseNumberProps } from "../../utils/canadaQuebecEnterpriseNumber";
import { FormDataAddress, FormPartialAddress } from "../FormPartialAddress";
import {
  FormDataPhoneNumber,
  FormPartialPhoneNumber
} from "../FormPartialPhoneNumber";
import { useModalWhyWeCollectInfo } from "../ModalWhyWeCollectInfo";

export type FormData = {
  firstName: string;
  middleName: string;
  lastName: string;
  phone: FormDataPhoneNumber;
  address: FormDataAddress;
};

type Props = {
  defaultValues?: WSFormProps<FormData>["defaultValues"];
  onSubmit: WSFormProps<FormData>["onSubmit"];
  country: string;
  onBack?: () => void;
};

export const FormImmaterialChangePersonal: React.FC<Props> = ({
  defaultValues,
  country,
  onBack,
  onSubmit
}) => {
  const isMobile = useIsMobile();
  const modal = useModalWhyWeCollectInfo();

  return (
    <WSForm<FormData>
      defaultValues={merge({}, defaultValues)}
      onSubmit={onSubmit}
    >
      <WSList gap="2XL" mb="2XL">
        <WSPanel>
          <WSSectionToolbar title="Personal information" />

          <WSList gap="XL">
            <WSFlexBox
              direction={isMobile ? "column" : "row"}
              wrap="nowrap"
              gap="L"
            >
              <WSForm.Field
                name="firstName"
                label="Legal first name"
                component={WSInputText}
                componentProps={{
                  required: true,
                  placeholder: "Enter first name",
                  fsExclude: true,
                  autoComplete: "given-name"
                }}
              />

              <WSForm.Field
                name="middleName"
                label="Middle name"
                component={WSInputText}
                componentProps={{
                  placeholder: "Enter middle name",
                  fsExclude: true,
                  autoComplete: "additional-name"
                }}
              />

              <WSForm.Field
                name="lastName"
                label="Legal last name"
                component={WSInputText}
                componentProps={{
                  required: true,
                  placeholder: "Enter last name",
                  fsExclude: true,
                  autoComplete: "family-name"
                }}
              />
            </WSFlexBox>

            <FormPartialPhoneNumber
              name="phone"
              label="Phone number"
              required
            />
          </WSList>
        </WSPanel>

        <WSPanel>
          <WSList gap="XL">
            <FormPartialAddress
              country={country}
              name="address"
              label="Personal address"
            />
            {/* Enhanced handle of address changes */}
            <AddressWatcher />

            <WSForm.Values names={["address.state"]}>
              {values => {
                const state = values["address.state"];

                const isNeqVisible = country === "CA" && state === "QC";

                return (
                  <WSForm.Field
                    hidden={!isNeqVisible}
                    name="canadaQuebecEnterpriseNumber"
                    label="Québec Enterprise Number (NEQ)"
                    component={WSInputMask}
                    componentProps={{
                      ...canadaQuebecEnterpriseNumberProps,
                      required: true
                    }}
                  />
                );
              }}
            </WSForm.Values>

            {country === "US" ? (
              <WSForm.Field
                key="US-state-of-incorporation"
                name="stateOfIncorporation"
                label="State/Territory of incorporation"
                component={WSSelect}
                componentProps={{
                  required: true,
                  internalSearch: true,
                  options: US_STATES_OPTIONS
                }}
              />
            ) : country === "CA" ? (
              <WSForm.Field
                key="CA-state-of-incorporation"
                name="stateOfIncorporation"
                label="Province of incorporation"
                component={WSSelect}
                componentProps={{
                  required: true,
                  internalSearch: true,
                  options: SELECT_OPTIONS_CANADIAN_PROVINCES
                }}
              />
            ) : null}

            {country === "CA" ? (
              <WSForm.Field
                name="yearOfIncorporation"
                label="Year of incorporation"
                component={WSSelect}
                componentProps={{
                  required: true,
                  options: SELECT_OPTIONS_YEAR
                }}
              />
            ) : null}
          </WSList>
        </WSPanel>
        <WSFormErrorSummary />
        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Back",
              onClick: onBack,
              kind: "Secondary",
              type: "button",
              visible: !!onBack
            },
            {
              label: "Continue"
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};

const AddressWatcher = () => {
  const { watch, setValue } = useFormContext();

  const state = watch("address.state");
  const stateOfIncorporation = watch("stateOfIncorporation");
  const prevStateOfIncorporation = useRef<string | null>(null);

  useEffect(() => {
    if (state && !stateOfIncorporation && !prevStateOfIncorporation.current) {
      setValue("stateOfIncorporation", state);
    }

    prevStateOfIncorporation.current = state;
  }, [setValue, state, stateOfIncorporation]);

  return null;
};
