import {
  useIsMobile,
  WSDivider,
  WSFlexBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import cn from "classnames";
import {
  IBenefitCostExample,
  IBenefitLineItem
} from "../../screens/BenefitsV3";
import styles from "./BenefitDetailsV2.module.scss";

export interface BenefitCostExampleProps {
  costExample: IBenefitCostExample;
}

export const BenefitCostExample: React.FC<BenefitCostExampleProps> = ({
  costExample
}) => {
  const { title, lineItems, finalCost } = costExample;
  const isMobile = useIsMobile();
  return (
    <WSPanel mt="3XL">
      <WSFlexBox direction="column">
        <WSText.Heading5 mb="XL">{title}</WSText.Heading5>
        <WSFlexBox
          direction="column"
          className={styles.costExampleLineItems}
          alignItems={undefined}
        >
          {lineItems.map(({ description, amount }: IBenefitLineItem) => (
            <WSFlexBox justify="space-between" mb="M">
              {typeof description === "string" ? (
                <WSText
                  color="gray600"
                  className={cn({
                    [styles.lineItem]: isMobile
                  })}
                >
                  {description}
                </WSText>
              ) : null}
              <WSText
                ml="3XL"
                color="gray600"
                formatMoney
                className={cn({
                  [styles.lineItem]: isMobile
                })}
              >
                {amount}
              </WSText>
            </WSFlexBox>
          ))}
          <WSDivider mb="M" />
          <WSFlexBox justify="space-between" mb="M">
            {typeof finalCost.description === "string" ? (
              <WSText
                color="gray600"
                className={cn({
                  [styles.lineItem]: isMobile
                })}
              >
                {finalCost.description}
              </WSText>
            ) : (
              finalCost.description
            )}
            <WSText
              ml="3XL"
              color="gray700"
              formatMoney
              className={cn({
                [styles.lineItem]: isMobile
              })}
            >
              {finalCost.amount}
            </WSText>
          </WSFlexBox>
        </WSFlexBox>
      </WSFlexBox>
    </WSPanel>
  );
};
