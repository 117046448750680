import {
  toWSDateString,
  toWSMoney,
  WSEmptyState,
  WSFilterDateRangeOption,
  WSFilters,
  WSPage,
  WSPageToolbar,
  WSPageToolbarAction,
  WSSelect,
  WSTable,
  WSTableCell,
  WSTableColumn
} from "@wingspanhq/fe-component-library";
import { WSQueries } from "../../../../query/WSQuery";
import { RouteComponentProps } from "react-router-dom";
import { useUrlQueryFilters } from "../../../../utils/router";
import {
  mapAndRevertParamsSortToTable,
  mapAndRevertTableSortToParams
} from "../../../../utils/tableSortingHelpers";
import { sortData } from "../../utils/sortData";
import { MAIN_FILTER_OPTIONS } from "../../utils/constants";
import { AssociatedResourceType, PayoutPeriod } from "../../utils/types";
import { getPayoutStatusTableCellProps } from "../../utils/getPayoutsStatusPillProps";
import { BadgePayoutPreference } from "../../../PaymentMethods/components/BadgePayoutPreference";
import { useHistory } from "react-router-dom";
import { PAYOUTS_PATH } from "../..";
import { useWherePayoutModal } from "../../components/WherePayoutModal/useWherePayoutModal";
import { isWithinInterval } from "date-fns";
import { usePayoutsQuery } from "../../../../query/payouts/queries/usePayoutsQuery";
import {
  getPayoutTotalAmount,
  selectorIsPayoutItemVisible
} from "../../utils/getPayoutTotalAmount";
import {
  IPayoutResponse,
  IPayoutStatus,
  PayoutStatusEnum,
  PayoutTypeEnum,
  IPayoutType
} from "../../../../services/api/payments/payouts/types";

export type SortKey = "createdAt" | "currentPeriodBalance" | string;

type Filters = {
  payoutType?: IPayoutType[];
  status?: IPayoutStatus[];
  // dateRange?: WSFilterDateRangeOption | any;
};

type PayoutsFilters = {
  filters: Filters;
  sort: Record<SortKey, "asc" | "desc">;
};

const DEFAULT_FILTERS: PayoutsFilters = {
  filters: { payoutType: undefined },
  sort: { createdAt: "desc" }
};

export const RoutePayoutsDashboard: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const queryPayouts = usePayoutsQuery();

  const wherePayoutModal = useWherePayoutModal();

  const { filters, setFilters } = useUrlQueryFilters<PayoutsFilters>(
    DEFAULT_FILTERS
  );

  const onSetFilters = (filterValue: Filters) => {
    setFilters({
      ...filters,
      filters: filterValue
    });
  };

  const sortByAmount = () => {
    setFilters({
      ...filters,
      sort: {
        currentPeriodBalance: mapAndRevertTableSortToParams(
          filters.sort?.currentPeriodBalance
        )
      }
    });
  };
  const sortByDate = () => {
    setFilters({
      ...filters,
      sort: {
        createdAt: mapAndRevertTableSortToParams(filters.sort?.createdAt)
      }
    });
  };

  const filterByPayout = (payout: IPayoutResponse) => {
    const {
      payoutType,
      status
      //  dateRange
    } = filters.filters;

    if (Array.isArray(payoutType) && payoutType.length) {
      if (
        payout?.payoutType !== undefined &&
        !payoutType.includes(payout.payoutType)
      ) {
        return false;
      }
    }

    if (Array.isArray(status) && status.length) {
      if (payout?.status && !status.includes(payout?.status)) {
        return false;
      }
    }

    // if (payout?.createdAt && dateRange && dateRange.start && dateRange.end) {
    //   const payoutDate = payout.createdAt;

    //   const isInRange = isWithinInterval(new Date(payoutDate), {
    //     start: dateRange.start,
    //     end: dateRange.end
    //   });

    //   if (!isInRange) {
    //     return false;
    //   }
    // }

    return true;
  };

  const columns: Array<WSTableColumn<IPayoutResponse>> = [
    {
      config: {
        header: {
          text: "Initiated date"
        },
        onColumnSort: sortByDate,
        sortDirection: mapAndRevertParamsSortToTable(filters?.sort?.createdAt),
        gridTemplateSizeMax: "1fr"
      },
      renderFunction: ({ data }) => (
        <WSTableCell
          text={`${toWSDateString(data.createdAt, "monthDayYear")}`}
        />
      )
    },
    {
      config: {
        header: {
          text: "Description"
        },
        gridTemplateSizeMax: "1fr",
        hideOnScreens: ["XS"]
      },
      renderFunction: () => <WSTableCell text={"Wingspan Payout"} />
    },
    {
      config: {
        header: {
          text: "Type"
        },
        gridTemplateSizeMax: "1fr"
      },
      renderFunction: ({ data }) => {
        const payoutType = data.payoutType;
        if (payoutType) {
          const payoutPreference = PayoutTypeEnum[payoutType];
          return <BadgePayoutPreference payoutPreference={payoutPreference} />;
        }
      }
    },
    {
      config: {
        header: {
          text: "Invoices"
        },
        hideOnScreens: ["XS"],
        gridTemplateSizeMax: "1fr"
      },
      renderFunction: ({ data }) => {
        return (
          <WSTableCell
            text={String(
              data?.payoutItems?.filter(selectorIsPayoutItemVisible).length
            )}
          />
        );
      }
    },
    {
      config: {
        header: {
          text: "Amount"
        },
        onColumnSort: sortByAmount,
        sortDirection: mapAndRevertParamsSortToTable(
          filters?.sort?.currentPeriodBalance
        ),
        hideOnScreens: ["XS"],
        gridTemplateSizeMax: "1fr"
      },
      renderFunction: ({ data }) => {
        // const totalDeposit =
        //   (data?.currentPeriodBalance || 0) - (data?.futureDebits || 0);
        const payoutTotal = getPayoutTotalAmount(data);

        return (
          <WSTableCell
            text={toWSMoney(payoutTotal).formattedValue}
            secondaryText={data.currency}
          />
        );
      }
    },
    {
      config: {
        header: {
          text: "Status"
        },
        hideOnScreens: ["XS"],
        gridTemplateSizeMax: "1fr"
      },
      renderFunction: ({ data }) => (
        <WSTableCell {...getPayoutStatusTableCellProps(data)} />
      )
    }
  ];

  // const pageActions = useMemo(() => {
  //   const actions: WSPageToolbarAction[] = [
  //     {
  //       label: "Search",
  //       icon: "search",
  //       buttonKind: "Tertiary",
  //       onClick: () => {},
  //       hideButtonOn: false
  //     }
  //   ];

  //   return actions;
  // }, []);

  return (
    <WSPage>
      <WSPageToolbar
        title={"Payouts"}
        // actions={pageActions}
        // titleAfter={
        //   <WSSelect
        //     hideClearAction={true}
        //     mode="single"
        //     // placeholder="Year"
        //     value={PayoutPeriod.Year2024}
        //     onChange={period => {
        //       // if (period !== PayoutPeriod.Year2024) { //todo
        //       //   history.push(`/member/statements/${period}`);
        //       // }
        //     }}
        //     options={MAIN_FILTER_OPTIONS}
        //   />
        // }
      />
      <WSQueries
        queries={{
          queryPayouts
        }}
      >
        {({ queryPayoutsData }) => {
          const tableList = queryPayoutsData
            .filter(filterByPayout)
            .sort(sortData(filters.sort!))
            .map(payout => ({
              id: payout.payoutId,
              data: payout
            }));

          return (
            <>
              <WSFilters<Filters>
                values={filters.filters}
                onFilter={onSetFilters}
                info={{ count: tableList.length }}
                primaryFilters={[
                  {
                    name: "payoutType",
                    title: "Payout Type",
                    type: "checkbox",
                    options: [
                      {
                        label: PayoutTypeEnum.PayoutType_Instant,
                        name: "PayoutType_Instant"
                      },
                      {
                        label: PayoutTypeEnum.PayoutType_Standard,
                        name: "PayoutType_Standard"
                      }
                    ]
                  },
                  {
                    name: "status",
                    title: "Status",
                    type: "checkbox",
                    options: [
                      {
                        label: "Complete",
                        name: PayoutStatusEnum.Complete
                      },
                      {
                        label: "Created",
                        name: PayoutStatusEnum.Created
                      },
                      {
                        label: "Clearing",
                        name: PayoutStatusEnum.Clearing
                      },
                      {
                        label: "Failed",
                        name: PayoutStatusEnum.Failed
                      }
                    ]
                  }
                  // {
                  //   name: "dateRange",
                  //   nonExpandable: true,
                  //   type: "daterange",
                  //   options: [
                  //     "all",
                  //     "pastWeek",
                  //     "pastMonth",
                  //     "yearToDate",
                  //     "custom"
                  //   ]
                  // }
                ]}
              />
              {queryPayoutsData.length === 0 ? (
                <WSEmptyState
                  type="search"
                  title="No payouts available yet"
                  description="Payouts will appear here once available."
                />
              ) : tableList.length === 0 ? (
                <WSEmptyState
                  type="search"
                  title="No results"
                  description="Try adjusting your filters to find what you're looking for. Clear filters to see all items."
                  buttons={[
                    {
                      label: "Clear filters",
                      kind: "Secondary",
                      onClick: () => {
                        setFilters(DEFAULT_FILTERS);
                      }
                    }
                  ]}
                />
              ) : (
                <WSTable<IPayoutResponse>
                  mt="XL"
                  showHeader={true}
                  rowMenuActions={rowItem => [
                    {
                      label: "Where is my payout?",
                      onClick: async () => {
                        await wherePayoutModal.open();
                      }
                    }
                  ]}
                  columns={columns}
                  onRowClick={item => {
                    history.push(`${PAYOUTS_PATH}/${item.id}`, {
                      backPath: `${PAYOUTS_PATH}`
                    });
                  }}
                  tableData={tableList}
                />
              )}
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
