import { WSIcon } from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./Notification.module.scss";

export enum NotificationStatus {
  success = "success",
  error = "error",
  info = "info"
}

type Notification = {
  text: string | React.ReactNode;
  status?: NotificationStatus;
  key?: any;
  delay?: number;
  timeout?: number;
  onEnd?(): void;
};

const DELAY = 1;
const TIMEOUT = 3000;
const ANIMATION_TIMEOUT = 500;

const shared: {
  notifications: Notification[];
} = {
  notifications: []
};

let onUpdateNotifications: (notifications: Notification[]) => void =
  console.log;

export const addNotification = (notification: Notification) => {
  notification.key = notification.key
    ? notification.key
    : `${Date.now()}_${Math.random().toFixed(4)}`;
  notification.timeout = notification.timeout ? notification.timeout : TIMEOUT;
  notification.delay = notification.delay ? notification.delay : DELAY;
  notification.status = notification.status
    ? notification.status
    : NotificationStatus.success;

  window.setTimeout(
    (key: any) => {
      shared.notifications = shared.notifications.filter(n => {
        if (n.key === key) {
          if (typeof n.onEnd === "function") {
            window.setTimeout(n.onEnd, ANIMATION_TIMEOUT);
          }

          return false;
        } else {
          return true;
        }
      });
      onUpdateNotifications(shared.notifications);
    },
    notification.timeout,
    notification.key
  );

  shared.notifications = [notification, ...shared.notifications];
  setTimeout(() => {
    onUpdateNotifications(shared.notifications);
  }, notification.delay);
};

// @ts-ignore
window.addNotification = addNotification;

export const removeNotification = (key: string) => {
  shared.notifications = shared.notifications.filter(n => n.key !== key);
  onUpdateNotifications(shared.notifications);
};

export const Notifications: React.FC<{}> = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    onUpdateNotifications = setNotifications;

    return () => {
      onUpdateNotifications = console.log;
    };
  }, []);

  return (
    <TransitionGroup
      className={classNames(styles.notifications, {
        [styles.ignore]: notifications.length === 0
      })}
    >
      {notifications.map(({ text, status, key }, index) => (
        <CSSTransition
          key={key}
          timeout={ANIMATION_TIMEOUT}
          classNames={{
            appear: styles.appear,
            appearActive: styles.appearActive,
            appearDone: styles.appearDone,
            enter: styles.enter,
            enterActive: styles.enterActive,
            enterDone: styles.enterDone,
            exit: styles.exit,
            exitActive: styles.exitActive,
            exitDone: styles.exitDone
          }}
        >
          <div
            className={classNames(styles.notification, {
              [styles.success]: status === NotificationStatus.success,
              [styles.error]: status === NotificationStatus.error,
              [styles.info]: status === NotificationStatus.info
            })}
          >
            <WSIcon
              block
              className={styles.close}
              name="exit"
              onClick={() => {
                removeNotification(key);
              }}
            />
            {status === NotificationStatus.success && (
              <WSIcon block className={styles.statusIcon} name="check" />
            )}
            {status === NotificationStatus.error && (
              <WSIcon block className={styles.statusIcon} name="alert-circle" />
            )}
            <span className={styles.text}>{text}</span>
          </div>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};
