import React from "react";
import { WSPage } from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router";
import { OpenLineItemAgingReportDashboard } from "./OpenLineItemAgingReportDashboard";
import { WSQueries } from "../../../../query/WSQuery";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";

export const RouteOpenLineItemAgingReport: React.FC = () => {
  const history = useHistory();
  const queryCustomFieldsAll = useCustomFieldsAll({
    resourceType: [
      CustomFieldResourceType.LineItem,
      CustomFieldResourceType.Collaborator
    ]
  });

  return (
    <WSPage
      title="Open Line Item Aging"
      breadcrumb={{
        label: "Back",
        onClick() {
          history.push("/member/reports");
        },
        icon: "arrow-left"
      }}
    >
      <WSQueries
        queries={{
          customFields: queryCustomFieldsAll
        }}
        component={OpenLineItemAgingReportDashboard}
      />
    </WSPage>
  );
};
