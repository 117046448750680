import classNames from "classnames";
import * as React from "react";
import {
  WSElement,
  WSElementProps,
  WSElementSpacingsType
} from "../../WSElement/WSElement.component";
import { ComponentWithKinds } from "../../WSElement/WSElement.helpers";
import styles from "./WSFlexBox.module.scss";

export type WSFlexBoxAlignContent =
  | "stretch"
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-between"
  | "space-around";

export type WSFlexBoxAlignItems =
  | "flex-start"
  | "center"
  | "flex-end"
  | "stretch"
  | "baseline";

export type WSFlexBoxJustify =
  | "flex-start"
  | "center"
  | "flex-end"
  | "space-between"
  | "space-around"
  | "space-evenly";

export type WSFlexBoxDirection =
  | "row"
  | "row-reverse"
  | "column"
  | "column-reverse";

export type WSFlexBoxWrap = "nowrap" | "wrap" | "wrap-reverse";

export interface WSFlexBoxProps extends WSElementProps {
  alignContent?: WSFlexBoxAlignContent;
  alignItems?: WSFlexBoxAlignItems;
  justify?: WSFlexBoxJustify;
  direction?: WSFlexBoxDirection;
  wrap?: WSFlexBoxWrap;
  gap?: WSElementSpacingsType;
}

export const WSFlexBoxKinds = ["Center", "CenterY", "CenterX"] as const;
type WSFlexBoxKindsType = typeof WSFlexBoxKinds[number];

export const WSFlexBox: ComponentWithKinds<
  WSFlexBoxProps,
  WSFlexBoxKindsType
> = ({
  alignContent = "flex-start",
  alignItems = "flex-start",
  justify = "flex-start",
  direction = "row",
  wrap = "wrap",
  gap = "NONE",
  className,
  children,
  ...componentProps
}) => {
  const containerClasses = classNames(
    styles.container,
    styles[`alignContent-${alignContent}`],
    styles[`alignItems-${alignItems}`],
    styles[`justify-${justify}`],
    styles[`direction-${direction}`],
    styles[`wrap-${wrap}`],
    styles[`gap-${gap}`]
  );

  const classes = classNames(className, containerClasses);

  return (
    <WSElement className={classes} {...componentProps}>
      {children}
    </WSElement>
  );
};

WSFlexBox.Center = (props) => (
  <WSFlexBox
    alignItems="center"
    alignContent="center"
    justify="center"
    {...props}
  />
);

WSFlexBox.CenterX = (props) => <WSFlexBox justify="center" {...props} />;

WSFlexBox.CenterY = (props) => (
  <WSFlexBox alignItems="center" alignContent="center" {...props} />
);
