import {
  useModalContext,
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSRadioInputGroup,
  WSText
} from "@wingspanhq/fe-component-library";
import { IAccount, SessionType } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { PlaidReAuth } from "../../../components/PlaidReAuth/PlaidReAuth";
import { useSession } from "../../../query/session";
import { selectorAccountName } from "../../../shared/selectors/selectorAccountName";
import { BankAccountPreview } from "../BankAccountPreview/BankAccountPreview";
import {
  REMOVE_ACCOUNT_MODAL_KEY,
  RemoveAccountModal
} from "../RemoveAccountModal/RemoveAccountModal";

type Props = {
  value: string;
  accounts: IAccount[];
  onChange: (value: string) => void;
  allowRemove?: boolean;
} & Omit<WSElementProps, "onChange">;

export const BankAccountSelect: React.FC<Props> = ({
  accounts,
  value,
  onChange,
  allowRemove,
  ...elementProps
}) => {
  const { openModal } = useModalContext();
  const sessionQuery = useSession({ refetchOnWindowFocus: false });
  const isGuest = sessionQuery.data?.sessionType !== SessionType.user;

  return (
    <WSElement {...elementProps}>
      {allowRemove && <RemoveAccountModal />}
      <WSRadioInputGroup
        name="user-account"
        value={value}
        options={accounts.map(account => ({
          value: account.accountId,
          label: (
            <WSFlexBox
              wrap="nowrap"
              justify="space-between"
              alignItems="center"
            >
              <WSElement mr="XL">
                <BankAccountPreview
                  institutionId={account.institutionId}
                  name={selectorAccountName(account)}
                />
                {allowRemove && !isGuest && (
                  <PlaidReAuth accountId={account.accountId}>
                    {({ status }) =>
                      status ? (
                        <WSText.ParagraphXs mt="XS">
                          Do you want to remove this account?
                          <WSElement
                            as="span"
                            ml="XS"
                            onClick={event => {
                              event.stopPropagation();
                              openModal(REMOVE_ACCOUNT_MODAL_KEY, account);
                            }}
                            color="blue500"
                          >
                            Remove
                          </WSElement>
                        </WSText.ParagraphXs>
                      ) : null
                    }
                  </PlaidReAuth>
                )}
              </WSElement>

              {!isGuest && (
                <PlaidReAuth accountId={account.accountId}>
                  {({ onOpen, status }) =>
                    status ? (
                      <WSButton.Link
                        type="button"
                        onClick={() => {
                          onOpen();
                        }}
                      >
                        Reconnect
                      </WSButton.Link>
                    ) : allowRemove ? (
                      <WSButton.Link
                        type="button"
                        onClick={() => {
                          openModal(REMOVE_ACCOUNT_MODAL_KEY, account);
                        }}
                      >
                        Remove
                      </WSButton.Link>
                    ) : null
                  }
                </PlaidReAuth>
              )}
            </WSFlexBox>
          )
        }))}
        onChange={(event: any) => {
          onChange(event.target.value);
        }}
      />
    </WSElement>
  );
};
