import React from "react";

import { WSQueries } from "../../../../query/WSQuery";
import { WSButton, WSFlexBox, WSText } from "@wingspanhq/fe-component-library";
import { BulkBatchImportStatus } from "../../components/BulkBatchImportStatus";

import styles from "./styles.module.scss";
import { useFlatfile } from "../../../../hooks/useFlatfile";
import { PartialRejection } from "@flatfile/sdk";
import { pullSessionToken } from "../../../../services/sessionStorage";
import { IS_PRODUCTION_ENV } from "../../../../shared/constants/environment";
import {
  handleFlatfileCSVChunk,
  processFlatfileChunk
} from "../../utils/flatfileBulkPayable";
import {
  BulkPayableStatus,
  BulkStatus,
  CustomFieldResourceType,
  IBulkPayableBatch
} from "@wingspanhq/payments/dist/interfaces";
import { flatfileConfig, flatfileTheme } from "../../utils/flatfileThemeConfig";
import useSnackbar from "../../../../hooks/useSnackbar";
import { BulkResource } from "../../types";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { useFlatfileErrorModal } from "../../components/FlatfileError/useFlatfileErrorModal";
import { FlatfileError } from "@flatfile/sdk/dist/errors/FlatfileError";
import { useHistory } from "react-router-dom";
import { BulkImportError } from "../../components/BulkImportError";

interface BulkPayableOpenBatchHeaderProps {
  bulkBatchId: string;
  bulkResource: BulkResource;
}

export function BulkPayableOpenBatchHeader({
  bulkBatchId,
  bulkResource
}: BulkPayableOpenBatchHeaderProps) {
  const history = useHistory();
  const { warning } = useSnackbar();
  const {
    useBulkBatch,
    useBulkBatchSummary,
    useUpdateBulkBatch
  } = getBulkQueriesByResource(bulkResource);

  const qBulkBatch = useBulkBatch(bulkBatchId);
  const qBulkBatchItems = useBulkBatchSummary(bulkBatchId);
  const qFeatureFlags = useFeatureFlags();

  const qLineItemsCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.LineItem]
  });
  const [
    updateBulkPayableBatch,
    updateBulkPayableBatchMeta
  ] = useUpdateBulkBatch();

  const payableStatus = qBulkBatch.data?.labels
    .payableStatus as BulkPayableStatus;

  // Flatfile error handling
  const flatfileErrorModal = useFlatfileErrorModal();

  const onFlatfileError = (error: FlatfileError) => {
    flatfileErrorModal.open({
      error,

      batchId: bulkBatchId,
      bulkResource,
      onStartNewImport: bulkBatch => {
        const newBulkBatchId = (bulkBatch as IBulkPayableBatch)
          .bulkPayableBatchId;

        updateBulkPayableBatch(
          {
            id: newBulkBatchId,
            data: {
              labels: {
                payableStatus
              }
            }
          },
          {
            onSuccess: () => {
              history.push(`/member/imports/payables/${newBulkBatchId}`);
            }
          }
        );
      }
    });
  };

  // Flatfile initialization

  const EMBED_ID = process.env
    .REACT_APP_FLATFILE_PAYMENTS_PAYABLES_EMBED_ID as string;

  const flatfile = useFlatfile({
    embedId: EMBED_ID,
    onError: onFlatfileError
  });

  const lineItemsCustomFields = qLineItemsCustomFields.data || [];

  const importDataV3 = async () => {
    flatfile.requestDataFromUser({
      ...flatfileConfig,
      theme: {
        ...flatfileTheme
      },
      onInit: ({ session }) => {
        session.updateEnvironment({
          payableStatus,
          isProdEnv: IS_PRODUCTION_ENV,
          authToken: pullSessionToken(),
          bulkBatchId
        });

        session.on("close", () => {
          // Just refetch the batch items query to update the batch details view
          // so that user won't upload the spreadsheet again in the same batch.
          qBulkBatchItems.refetch();
        });
      },
      customFields: lineItemsCustomFields.map(({ key, name }) => {
        return {
          field: key,
          label: name,
          // FIX: boolean fields are not supported in Flatfile
          type: "string"
        };
      }),
      /**
       * Flatfile streams the uploaded spreadsheet data as chunks of size 1000 (default) to onData
       * callback function where we can process the spreadsheet data (like making an external API call)
       * and reject any spreadsheet row(s) if there are any failures during processing.
       */
      onData: async (chunk, next) => {
        const processChunkFn = qFeatureFlags.data?.improveBulkImportConcurrency
          ? processFlatfileChunk
          : handleFlatfileCSVChunk;
        const rejections = await processChunkFn(
          chunk,
          bulkBatchId,
          payableStatus,
          lineItemsCustomFields
        );
        next(new PartialRejection(rejections));
      },
      onComplete: async payload => {
        const csvRecords = await payload.data();
        const csvLineItems = csvRecords.rows
          .filter(row => row.valid)
          .map(row => row.data);

        // ------------- Flatfile's Duplicate Row issue Debug Statements ----------------------
        console.group("Bulk Import Payables");
        console.log("CSV Line items", csvLineItems);
        console.groupEnd();
        // ------------- Flatfile's Duplicate Row issue Debug Statements ----------------------

        if (csvLineItems.length === 0) {
          warning("Sorry, Looks like the uploaded spreadsheet is empty!");
          return;
        }
        await updateBulkPayableBatch({
          id: bulkBatchId,
          data: {
            labels: {
              flatfileBatchId: payload.batchId
            }
          }
        });
      }
    });
  };

  // status: Open & batch is empty

  const uploadSpreadsheet = () => {
    importDataV3();
  };

  // status: Open & batch has some batch items

  const startImport = async () => {
    await updateBulkPayableBatch({
      id: bulkBatchId,
      data: {
        status: BulkStatus.Pending
      }
    });
  };

  return (
    <WSQueries queries={{ qBulkBatch, qBulkBatchItems }}>
      {({ qBulkBatch: { data: bulkBatch } }) => {
        const isEmptyBatch =
          qBulkBatchItems.data && qBulkBatchItems.data.listSize === 0;
        const filename = getUploadedFilename(bulkBatch, BulkResource.Payable, {
          length: 35
        });
        return (
          <WSFlexBox direction="column">
            <WSFlexBox.CenterY
              className={styles.header}
              justify="space-between"
              mb="XL"
            >
              <WSText
                weight="medium"
                data-testid="bulkBatchFilename"
                shimmer={
                  qBulkBatchItems.isLoading ||
                  updateBulkPayableBatchMeta.isLoading
                }
                title={getUploadedFilename(bulkBatch, BulkResource.Payable)}
              >
                {filename ||
                  (isEmptyBatch
                    ? "File not uploaded"
                    : "Unnamed Payable Batch")}
              </WSText>
              <BulkBatchImportStatus
                batchId={bulkBatchId}
                bulkResource={bulkResource}
              />
            </WSFlexBox.CenterY>

            <WSText.ParagraphSm color="gray500" mb="XL">
              {isEmptyBatch
                ? "This import is empty. You can upload a spreadsheet of payables to continue, or delete the import."
                : "A spreadsheet is uploaded and ready to be imported as payables. Imported payables with the same contractor and due date as existing payables will be merged."}
            </WSText.ParagraphSm>

            {isEmptyBatch ? (
              <WSButton
                shimmer={qBulkBatchItems.isLoading}
                name="uploadSpreadsheet"
                icon="upload"
                fullWidth
                onClick={uploadSpreadsheet}
              >
                Upload spreadsheet
              </WSButton>
            ) : (
              <WSButton
                name="initImportPayablesAndDeductions"
                icon="details"
                fullWidth
                onClick={startImport}
                loading={updateBulkPayableBatchMeta.isLoading}
              >
                Import payables & deductions
              </WSButton>
            )}

            {isEmptyBatch ? null : (
              <BulkImportError
                mt="XL"
                bulkResource={bulkResource}
                batchId={bulkBatchId}
              />
            )}
          </WSFlexBox>
        );
      }}
    </WSQueries>
  );
}
