import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";

export const selectorPayeeTaxFormPayerName = (
  taxForm: IPayeeTaxFormResponse
): string => {
  if (taxForm.payer?.profile?.company?.legalBusinessName) {
    return taxForm.payer.profile.company.legalBusinessName;
  }

  if (
    taxForm.payer?.user.profile?.firstName &&
    taxForm.payer?.user.profile?.lastName
  ) {
    return `${taxForm.payer.user.profile.firstName} ${taxForm.payer.user.profile.lastName}`;
  }

  if (taxForm.payer?.user.email) {
    return taxForm.payer.user.email;
  }

  return "";
};
