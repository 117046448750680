import classNames from "classnames";
import React, { useMemo } from "react";
import { WSElement } from "../WSElement/WSElement.component";
import styles from "./WSCardHeader.module.scss";
import { WSCardHeaderLabel, WSCardHeaderLabelProps } from "./WSCardHeaderLabel";
import { WSCardHeaderValue, WSCardHeaderValueProps } from "./WSCardHeaderValue";

export type WSCardHeaderProps = {
  label?: string | WSCardHeaderLabelProps;
  value?: WSCardHeaderValueProps;
  className?: string;
};

export const WSCardHeader: React.FC<WSCardHeaderProps> = (props) => {
  const labelProps = useMemo(() => {
    if (typeof props.label === "string") {
      return {
        label: props.label
      };
    }
    return props.label;
  }, [props.label]);

  return (
    <WSElement className={classNames(styles.header, props.className)}>
      <WSCardHeaderLabel {...labelProps} />
      {props.value && <WSCardHeaderValue {...props.value} />}
    </WSElement>
  );
};
