import React from "react";
import { toCamelCase } from "../../../../utils";
import {
  detachLayoutProps,
  WSElement,
  WSElementProps
} from "../../WSElement/WSElement.component";
import { WSText } from "../WSText/WSText.component";
import styles from "./WSStepper.module.scss";

export interface WSStepperProps extends WSElementProps {
  value: number;
  name: string;
  prefix?: string;
  postfix?: string;
  min?: number;
  max?: number;
  onChange(v: any): void;
}

export const WSStepper: React.FC<WSStepperProps> = (props) => {
  const {
    className,
    value,
    name,
    onChange,
    layoutProps,
    systemProps,
    postfix,
    prefix,
    min = 0,
    max = +Infinity,
    ...otherProps
  } = detachLayoutProps(props);
  return (
    <WSElement className={styles.wrapper} {...layoutProps}>
      <WSElement
        className={styles.minus}
        data-testid={toCamelCase(name, "minus")}
        tabIndex={0}
        {...otherProps}
        onClick={() => {
          const newValue = value - 1;
          onChange?.(newValue < min ? min : newValue);
        }}
      />
      <WSText.Heading1 weight="book" className={styles.value}>
        {prefix}
        {value || min}
        {postfix}
      </WSText.Heading1>
      <WSElement
        className={styles.plus}
        data-testid={toCamelCase(name, "plus")}
        tabIndex={0}
        {...otherProps}
        onClick={() => {
          const newValue = value + 1;
          onChange?.(newValue > max ? max : newValue);
        }}
      />
    </WSElement>
  );
};
