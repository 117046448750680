import cn from "classnames";
import React from "react";
import {
  detachLayoutProps,
  WSElement,
  WSElementProps
} from "../../WSElement/WSElement.component";
import styles from "./WSSwitchInput.module.scss";

export interface WSSwitchInputProps extends WSElementProps {
  name: string;
  value: boolean;
}

export const WSSwitchInput = React.forwardRef<
  HTMLInputElement,
  WSSwitchInputProps
>((props, ref) => {
  const {
    className = "",
    layoutProps,
    systemProps,
    name,
    value,
    onChange,
    ...inputProps
  } = detachLayoutProps(props);

  return (
    <WSElement
      {...systemProps}
      className={cn(styles.switch, className, {
        [styles.onState]: value
      })}
      {...layoutProps}
      as="label"
    >
      <input
        ref={ref}
        type="checkbox"
        name={name}
        checked={value}
        onChange={() => {
          onChange?.(!value as any);
        }}
        className={styles.input}
        {...inputProps}
      />
      <i className={styles.switcher} />
    </WSElement>
  );
});
