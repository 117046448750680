import { WSText } from "@wingspanhq/fe-component-library";
import {
  CustomFieldResourceType,
  ICollaboratorSchema,
  ITaxFormResponse
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { SetupParams } from "..";
import { WSQueries } from "../../../../../query/WSQuery";
import { useCustomFieldsAll } from "../../../../../query/customFields/queries/useCustomFieldsAll";
import { useWSMutation } from "../../../../../query/helpers";
import { useUserId } from "../../../../../query/hooks/helpers";
import {
  QUERY_COLLABORATORS,
  QUERY_COLLABORATORS_V2
} from "../../../../../query/payments/keys";
import { useAllOrganizationUsers } from "../../../../../query/users/queries";
import { paymentsService } from "../../../../../services/payments";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { getRequestDataCustomFields } from "../../../../../shared/components/FormPartialCustomFields";
import { WSServiceError } from "../../../../../utils/serviceHelper";
import { EditLayout } from "../../../components/EditLayout";
import { Form, FormData } from "./Form";

type Props = SetupRouteComponentProps<SetupParams>;

export const RoutePersonalDetails: React.FC<Props> = ({ onBack, onNext }) => {
  const userId = useUserId();
  const queryOrganizations = useAllOrganizationUsers();
  const queryCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });

  const [submit, submitMeta] = useWSMutation<
    {
      collaborator: ICollaboratorSchema;
      taxForm: ITaxFormResponse;
    },
    WSServiceError,
    FormData
  >(
    async data => {
      const clientId = data.organization || userId;

      const collaborator = await paymentsService.collaborator.create(
        {
          clientId,
          memberEmail: data.email,
          memberName: `${data.firstName} ${data.lastName}`,
          labels: data.customFields
            ? getRequestDataCustomFields(data.customFields)
            : undefined,
          clientData: {
            externalId: data.externalId
          }
        },
        data.organization && userId !== data.organization
          ? data.organization
          : undefined
      );

      const taxForm = await paymentsService.taxForm.create({
        memberId: collaborator.memberId,
        clientId,
        data: {
          w9Info: {
            firstName: data.firstName,
            lastName: data.lastName,
            country: data.country
          }
        }
      });

      return {
        collaborator,
        taxForm
      };
    },
    {
      dependencies: [QUERY_COLLABORATORS, QUERY_COLLABORATORS_V2],
      onSuccess: onNext
    }
  );

  return (
    <EditLayout onBack={onBack} title="Add recipient">
      <WSText.Heading4>Personal details</WSText.Heading4>
      <WSText color="gray500" mt="M" mb="2XL">
        Please fill out the personal details of the new recipient you would like
        to add. Once invited, the organization and email address cannot be
        edited.
      </WSText>

      <WSQueries
        queries={{
          queryOrganizations,
          queryCustomFields
        }}
      >
        {({ queryCustomFieldsData, queryOrganizationsData }) => (
          <Form
            organizations={queryOrganizationsData}
            customFields={queryCustomFieldsData}
            onCancel={onBack}
            onSubmit={submit}
            isLoading={submitMeta.isLoading}
            error={submitMeta.error}
          />
        )}
      </WSQueries>
    </EditLayout>
  );
};
