import { useWSStore } from "../../store";
import { useCMSWebflowEnterprise } from "../cms/queries";
import { useUserId } from "../hooks/helpers";
import { useActivities } from "../users/queries";
import { getCurrentEntepriseId } from "./selectors";

export const useEnterpriseQuery = () => {
  const userId = useUserId();
  const activityQuery = useActivities(userId);
  const store = useWSStore();
  const enterpriseId = getCurrentEntepriseId(store, activityQuery.data);
  return useCMSWebflowEnterprise(enterpriseId || "", {
    enabled: !!enterpriseId
  });
};
