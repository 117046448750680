import cn from "classnames";
import * as React from "react";
import { useMemo } from "react";
import { WSElement } from "../../WSElement/WSElement.component";
import styles from "./WSGrid.module.scss";
import { WSGridComponent, WSGridItemProps } from "./WSGrid.types";
import { getResponsiveClassNames } from "./WSGrid.utils";

export { WSGridBreakpoint } from "./WSGrid.types";
export type {
  WSGridComponent,
  WSGridGutter,
  WSGridItemProps,
  WSGridItemSpan,
  WSGridProps
} from "./WSGrid.types";

export const WSGrid: WSGridComponent = ({
  children,
  gutter,
  className,
  ...otherProps
}) => {
  const gutterClassNames = useMemo(
    () => getResponsiveClassNames(gutter, "gutter"),
    [gutter]
  );

  const classNames = cn(styles.grid, ...gutterClassNames, className);

  return (
    <WSElement className={classNames} {...otherProps}>
      {children}
    </WSElement>
  );
};

export const WSGridItem: React.FC<WSGridItemProps> = ({
  span,
  className,
  ...otherProps
}) => {
  const spanClassNames = useMemo(() => getResponsiveClassNames(span, "span"), [
    span
  ]);

  const classNames = cn(styles.item, ...spanClassNames, className);

  return <WSElement className={classNames} {...otherProps} />;
};

WSGrid.Item = WSGridItem;
