import { QueryConfig } from "react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation } from "../../helpers";
import {
  getPayeeEmailUpdate,
  PayeeEmailUpdateRequest
} from "../../../services/payees/getPayeeEmailUpdate";
import { IPayeeEmailChangeResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEmailChange";

export const usePreviewPayeeEmailUpdate = (
  config?: QueryConfig<IPayeeEmailChangeResponse, WSServiceError>
) =>
  useWSMutation(
    (request: PayeeEmailUpdateRequest) => getPayeeEmailUpdate(request),
    {
      ...config
    }
  );
