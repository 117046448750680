import {
  useModalContext,
  WSButton,
  WSButtons,
  WSIcon,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

export const BOOKKEEPING_NO_ACCOUNTS_SELECTED =
  "BOOKKEEPING_NO_ACCOUNTS_SELECTED";

export const NoAccountsSelected: React.FC = () => {
  const { closeModal } = useModalContext();

  return (
    <WSModal name={BOOKKEEPING_NO_ACCOUNTS_SELECTED} size="S">
      {({ onContinue }) => (
        <>
          <WSText.Heading5 mb="M">
            <WSIcon name="alert-circle" size="M" mr="M" color="amber400" />
            No accounts selected
          </WSText.Heading5>
          <WSText mb="M">Continue without connecting an account?</WSText>
          <WSButtons mt="2XL" format="modal">
            <WSButton
              destructive
              onClick={() => {
                closeModal(BOOKKEEPING_NO_ACCOUNTS_SELECTED);
                onContinue();
              }}
            >
              Continue
            </WSButton>
            <WSButton.Tertiary
              onClick={() => closeModal(BOOKKEEPING_NO_ACCOUNTS_SELECTED)}
            >
              Cancel
            </WSButton.Tertiary>
          </WSButtons>
        </>
      )}
    </WSModal>
  );
};
