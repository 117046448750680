import {
  WSButton,
  WSFlexBox,
  WSGrid,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  SubscriptionPackage,
  SubscriptionPackageTier,
  SubscriptionTerm
} from "@wingspanhq/users/dist/lib/interfaces";
import { formatMoney } from "accounting";
import React from "react";
import { useHistory } from "react-router";
import { FREE_TRIAL_DAYS } from "../../Settings/components/MembershipPackages/subscriptionPackages";
import { hasSubscription } from "../../Settings/utils/subscriptionUtils";
import { WSQueries } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import {
  useSubscriptionPackages,
  useSubscriptionV3Query
} from "../../query/subscriptions/queries";
import styles from "./MembershipUpgrade.module.scss";

interface UpgradePackageData {
  title: string;
  description: string;
  valuePropositionList: string[];
}

type UpgradePackagesData = {
  [key in SubscriptionPackage]?: UpgradePackageData;
};

const data: UpgradePackagesData = {
  [SubscriptionPackage.Professional]: {
    title: "Upgrade your membership to Teams",
    description:
      "Manage, track, and automate subcontractor payments in Wingspan",
    valuePropositionList: [
      "No-fee invoicing",
      "Pay contractors",
      "Tax withholdings",
      "Manage multiple businesses",
      "Tax write-offs",
      "Invite teammates"
    ]
  },
  [SubscriptionPackage.Benefits]: {
    title: "Upgrade your membership to Benefits",
    description: "Get the benefits & perks you need",
    valuePropositionList: [
      "Medical support & savings",
      "Mind & body",
      "Financial, legal & tax support"
    ]
  }
};
export interface MembershipUpgradeProps {
  packageName?: keyof typeof SubscriptionPackage;
  onExplorePlans?: () => void;
}

export const MembershipUpgrade: React.FC<MembershipUpgradeProps> = ({
  packageName,
  onExplorePlans
}) => {
  const history = useHistory();
  const userId = useUserId();
  const qSubscription = useSubscriptionV3Query(userId);
  const qSubscriptionPackages = useSubscriptionPackages();
  const subscription = qSubscription.data;

  return (
    <WSQueries queries={{ qSubscriptionPackages }}>
      {({
        qSubscriptionPackages: { data: subscriptionPackagesWithAmount }
      }) => {
        packageName = packageName
          ? packageName
          : SubscriptionPackage.Professional;
        const { title, description, valuePropositionList } = data[
          packageName
        ] as UpgradePackageData;
        const updatedTitle =
          hasSubscription(subscription) &&
          packageName !== SubscriptionPackage.Professional
            ? "Upgrade your membership to Starter"
            : title;

        const amount =
          subscriptionPackagesWithAmount[packageName][
            SubscriptionPackageTier.Basic
          ][SubscriptionTerm.Yearly].amount / 12;

        return (
          <WSFlexBox direction="column" alignItems="stretch">
            <WSFlexBox justify="space-between" mb="XL">
              <WSFlexBox.Center className={styles.upgradeIconContainer}>
                <WSIcon block name="reward" color="red400" size="L" />
              </WSFlexBox.Center>
              <WSFlexBox direction="column">
                <WSText.Heading4>{FREE_TRIAL_DAYS} days free</WSText.Heading4>
                <WSText.Heading4>
                  then{" "}
                  {`${formatMoney(amount, {
                    symbol: "$",
                    precision: amount % 12 === 0 ? 0 : 2
                  })}/mo`}
                </WSText.Heading4>
                <WSButton.Link>billed annually</WSButton.Link>
              </WSFlexBox>
            </WSFlexBox>
            <WSText.Heading4 mb="M">{updatedTitle}</WSText.Heading4>
            <WSText mb="XL">{description}</WSText>
            <WSGrid>
              {valuePropositionList.map((vp: string) => (
                <WSGrid.Item mb="M" span={{ m: "6" }} key={vp}>
                  <WSIcon name="check" color="green500" mr="XS" />
                  <WSText.ParagraphSm inline>{vp}</WSText.ParagraphSm>
                </WSGrid.Item>
              ))}
            </WSGrid>
            <WSButton
              name="explorePlans"
              mt="2XL"
              fullWidth
              onClick={() => {
                history.push("/member/membership-plans");
                onExplorePlans?.();
              }}
            >
              Explore plans
            </WSButton>
          </WSFlexBox>
        );
      }}
    </WSQueries>
  );
};
