import * as Yup from "yup";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";

export const signatureValidationSchema = Yup.object().shape({
  requiredSignatures: Yup.mixed().when("type", {
    is: RequirementType.Signature,
    then: Yup.string()
      .oneOf(["collaborator", "mutual"])
      .required("Required"),
    otherwise: Yup.mixed().notRequired()
  }),
  eSignatureFile: Yup.mixed().when("type", {
    is: RequirementType.Signature,
    then: Yup.mixed()
      .required("Required")
      .test("File", "File type", value => {
        return value instanceof File;
      }),
    otherwise: Yup.mixed().notRequired()
  })
});
