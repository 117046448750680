import { WSAvatarProps } from "@wingspanhq/fe-component-library";
import plaidIcon from "../../assets/images/plaid-icon.png";

export const avatarBankAccount: WSAvatarProps = {
  format: "square",
  type: "icon",
  icon: "bank",
  color: "white",
  colorBackground: "gray500"
};

export const avatarWallet: WSAvatarProps = {
  format: "square",
  type: "icon",
  icon: "wallet",
  color: "white",
  colorBackground: "garnet"
};

export const avatarInstantPayout: WSAvatarProps = {
  format: "square",
  type: "icon",
  icon: "instant",
  color: "white",
  colorBackground: "black"
};

export const avatarPlaid: WSAvatarProps = {
  format: "square",
  type: "image",
  image: plaidIcon
};

export const avatarBankCard: WSAvatarProps = {
  format: "square",
  type: "icon",
  icon: "card",
  color: "white",
  colorBackground: "blue500"
};

export const avatarInternationalBankAccount: WSAvatarProps = {
  format: "square",
  type: "icon",
  icon: "globe",
  color: "white",
  colorBackground: "black"
};

export const avatarUSBankAccount: WSAvatarProps = {
  format: "square",
  type: "country",
  country: "UnitedStates"
};
