import {
  toWSMoneyString,
  WSActions,
  WSElement,
  WSSectionToolbar,
  WSTable,
  WSTableCell,
  WSTableItem
} from "@wingspanhq/fe-component-library";
import { IDeductionResponse } from "@wingspanhq/payments/dist/interfaces/api/deductions";
import {
  DeductionStatus,
  DeductionType
} from "@wingspanhq/payments/dist/interfaces/deductions";
import React from "react";
import { useCollaboratorDeductions } from "../../../query/payments/queries";
import { WSQueries } from "../../../query/WSQuery";

type Props = {
  clientId: string;
  memberId: string;
  onRowClick(d: IDeductionResponse): void;
  onShowAllClick(): void;
};

const DeductionTable: React.FC<{
  tableData: WSTableItem[];
  onRowClick(d: IDeductionResponse): void;
}> = ({ tableData, onRowClick }) => {
  const data = tableData.slice(0, 4);

  return (
    <WSTable<IDeductionResponse>
      tableData={data}
      onRowClick={item => onRowClick(item.data)}
      columns={[
        {
          config: {
            header: {
              text: "Deduction"
            },
            gridTemplateSizeMax: "1fr"
          },
          renderFunction: ({ data }) => {
            const deductedAmount = data.application
              .map(a => a.amountDeducted)
              .reduce((a, b) => a + b, 0);

            const remainingAmount = deductedAmount
              ? data.amount - deductedAmount
              : data.amount;

            return (
              <WSTableCell
                text={
                  data.status === DeductionStatus.PartiallyApplied
                    ? `${toWSMoneyString(remainingAmount)} ${
                        deductedAmount ? "remaining" : ""
                      }`
                    : toWSMoneyString(data.amount)
                }
                secondaryText={data.name}
              />
            );
          }
        },
        {
          config: {
            header: {
              text: "Type"
            }
          },
          renderFunction: ({ data }) => (
            <WSTableCell
              pill={{
                theme: "neutral",
                icon:
                  data.type === DeductionType.PostPayment ? "after" : "before",
                text:
                  data.type === DeductionType.PostPayment
                    ? "Post-payment"
                    : "Pre-payment"
              }}
            />
          )
        }
      ]}
    />
  );
};

export const DeductionsWidget: React.FC<Props> = props => {
  const qPendingDeductions = useCollaboratorDeductions({
    memberId: props.memberId,
    clientId: props.clientId,
    status: DeductionStatus.Pending
  });

  return (
    <WSQueries queries={{ qPendingDeductions }}>
      {({ qPendingDeductionsData }) => {
        const pendingData: WSTableItem[] = qPendingDeductionsData
          .map(deduction => {
            const { deductionId } = deduction;

            return {
              id: deductionId,
              data: deduction
            };
          })
          .slice(0, 10);

        if (!pendingData.length) {
          return null;
        }

        return (
          <WSElement>
            <WSSectionToolbar title="Scheduled deductions" mb="S" />
            <DeductionTable
              tableData={pendingData}
              onRowClick={props.onRowClick}
            />
            <WSActions
              alignment="left"
              size="M"
              mt="M"
              buttons={[
                {
                  label: "View all in deductions page",
                  kind: "Link",
                  onClick: () => {
                    props.onShowAllClick();
                  }
                }
              ]}
            />
          </WSElement>
        );
      }}
    </WSQueries>
  );
};
