import { CardBrand } from "@wingspanhq/payments/dist/interfaces";

export const creditCardBrandNameMap: { [key: string]: string } = {
  amex: "American Express",
  discover: "Discover",
  jcb: "JCB",
  mastercard: "MasterCard",
  unionPay: "UnionPay",
  visa: "Visa"
};

export const debitCardBrandNameMap: { [key: string]: string } = {
  [CardBrand.AmericanExpress]: "American Express",
  [CardBrand.Discover]: "Discover",
  [CardBrand.JCB]: "JCB",
  [CardBrand.Mastercard]: "Mastercard",
  [CardBrand.DinersClub]: "Diners Club",
  [CardBrand.Visa]: "Visa"
};
