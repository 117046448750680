import React from "react";
import { useQueryCustomerEntity } from "../../../query/onboarding/queries/useQueryCustomerEntity";
import { Form } from "./Form";
import { useMutationSubmit } from "./useMutationSubmit";
import { useMemberProfile } from "../../../query/users/queries";
import { useUserId } from "../../../query/hooks/helpers";

type Props = {
  onSuccess?: () => void;
  onBack?: () => void;
  withPanel?: boolean;
};

export const FormManuallyAddInternationalAccount: React.FC<Props> = ({
  onSuccess,
  onBack,
  withPanel
}) => {
  const userId = useUserId();
  const queryCustomerEntity = useQueryCustomerEntity();
  const queryMemberProfile = useMemberProfile(userId);
  const [submit, submitMeta] = useMutationSubmit({
    onSuccess
  });

  if (!queryCustomerEntity.isFetched || !queryMemberProfile.isFetched) {
    return null;
  }

  const country =
    queryCustomerEntity.data?.country ||
    queryMemberProfile.data?.profile.address?.country;

  return (
    <Form
      onSubmit={data => {
        submit({ data });
      }}
      error={submitMeta.error}
      isLoading={submitMeta.isLoading}
      defaultValues={{
        country: country
      }}
      onBack={onBack}
      withPanel={withPanel}
    />
  );
};
