import cn from "classnames";
import React from "react";
import { toCamelCase } from "../../../../utils";
import { WSFlexBox } from "../../layout/WSFlexBox/WSFlexBox.component";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSIcon } from "../WSIcon/WSIcon.component";
import { WSText } from "../WSText/WSText.component";
import styles from "./WSCheckboxToggle.module.scss";

export interface WSCheckboxToggleProps
  extends Omit<WSElementProps, "onChange"> {
  name: string;
  value: boolean;
  label: React.ReactNode;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  description?: string;
  onInfo?: () => void;
}

export const WSCheckboxToggle: React.FC<WSCheckboxToggleProps> = ({
  className,
  name,
  label,
  value,
  onChange,
  onClick,
  description,
  disabled,
  onInfo,
  ...elementProps
}) => (
  <WSElement
    className={cn(styles.base, className, {
      [styles.checked]: value,
      [styles.disabled]: disabled
    })}
    data-testid={toCamelCase(name, "field")}
    onClick={(event) => {
      if (disabled) {
        return;
      }
      onChange(!value);
      onClick?.(event);
    }}
    {...elementProps}
  >
    <WSElement as="i" className={styles.control} />
    <WSElement>
      <WSFlexBox.CenterY>
        <WSText.ParagraphSm className={styles.label} mr="M">
          {label}
        </WSText.ParagraphSm>
        {onInfo && (
          <WSIcon block name="info-circle" color="gray400" onClick={onInfo} />
        )}
      </WSFlexBox.CenterY>
      {description && (
        <WSText.ParagraphSm color="gray500" mt="XS">
          {description}
        </WSText.ParagraphSm>
      )}
    </WSElement>
  </WSElement>
);
