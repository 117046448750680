import { QueryConfig } from "react-query";
import { useWSQuery } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { IBackgroundCheckSettingsResponse } from "@wingspanhq/background-checks/dist/lib/interfaces";
import { getBackgroundChecksSettings } from "../../../services/backgroundChecks/getBackgroundChecksSettings";

export const QUERY_BACKGROUND_CHECKS_SETTINGS =
  "QUERY_BACKGROUND_CHECKS_SETTINGS";

export const useBackgroundChecksSettingsQuery = (
  settingsId: string,
  queryConfig?: QueryConfig<IBackgroundCheckSettingsResponse, WSServiceError>
) => {
  return useWSQuery(
    [QUERY_BACKGROUND_CHECKS_SETTINGS, settingsId],
    () => getBackgroundChecksSettings(settingsId),
    queryConfig
  );
};
