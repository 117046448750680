import {
  WSAvatar,
  WSButton,
  WSElement,
  WSElementColorsType,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSIconName,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import { PayoutDestinationType } from "@wingspanhq/payments/dist/interfaces";
import { MemberWithholdingStatus } from "@wingspanhq/users/dist/lib/interfaces";
import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import { useBankingAccount } from "../../../../query/bookkeeping/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import { usePayoutSettings } from "../../../../query/payments/queries";
import { useMemberProfile } from "../../../../query/users/queries";
import { debitCardLogoMap } from "../../../../shared/components/BankCardLogo/constants";
import { IS_PRODUCTION_ENV } from "../../../../shared/constants/environment";
import { selectorAccountName } from "../../../../shared/selectors/selectorAccountName";
import { selectorDebitCardName } from "../../../../shared/selectors/selectorDebitCardName";
import { selectorDefaultDestination } from "../../../../shared/selectors/selectorDefaultDestination";
import { selectorIsDomesticMember } from "../../../../shared/selectors/selectorIsDomesticMember";
import { getCountyFlagLink } from "../../../../shared/utils/countries";
import { ContainerAccount } from "../ContainerAccount";
import { ContainerDebitCard } from "../ContainerDebitCard";

const getInstitutionLogo = (institutionId: string) => {
  const bucketName = IS_PRODUCTION_ENV
    ? "wingspan-shared-content"
    : "wingspan-staging-shared-content";

  return `https://storage.cloud.google.com/${bucketName}/plaid/logos/${institutionId}.png`;
};

const getBankBrandLogo = (brand: string) => {
  return debitCardLogoMap[brand]?.src;
};

type Props = {
  onEditWallet?: () => void;
  onEditTaxWithholdings?: () => void;
} & WSElementProps;

export const PayoutSplits: React.FC<Props> = ({
  onEditWallet,
  onEditTaxWithholdings,
  ...elementProps
}) => {
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryBankingAccount = useBankingAccount();
  const queryMember = useMemberProfile(userId);
  const history = useHistory();

  return (
    <WSElement {...elementProps}>
      <WSText.Heading5 mb="XL">Payout splits</WSText.Heading5>
      <WSElement>
        <WSQueries
          queries={{
            queryPayoutSettings,
            queryMember
          }}
        >
          {({
            queryPayoutSettings: { data: payoutSettings },
            queryMember: { data: member }
          }) => {
            const destination = selectorDefaultDestination(payoutSettings);

            const walletItem = queryBankingAccount.data ? (
              <Item
                icon={{
                  color: "garnet",
                  secondaryColor: "white",
                  name: "wallet"
                }}
                name="Wingspan Wallet"
                onEdit={() => {
                  onEditWallet
                    ? onEditWallet()
                    : history.push("/member/wallet");
                }}
                value={`${destination ? 100 - destination.percentage : 100}%`}
              />
            ) : (
              <Item
                icon={{
                  color: "garnet",
                  secondaryColor: "white",
                  name: "wallet"
                }}
                disabled
                name="Wingspan Wallet"
                onAdd={() => {
                  history.push("/member/wallet");
                }}
              />
            );

            const payoutMethodItem =
              destination && destination.destinationId ? (
                destination.destinationType ===
                PayoutDestinationType.Account ? (
                  <ContainerAccount accountId={destination.destinationId}>
                    {account => (
                      <Item
                        icon={
                          account.institutionId
                            ? getInstitutionLogo(account.institutionId)
                            : account.country && account.country !== "US"
                            ? getCountyFlagLink(account.country.toLowerCase())
                            : {
                                name: "bank",
                                color: "gray50",
                                secondaryColor: "gray500"
                              }
                        }
                        name={selectorAccountName(account)}
                        value={`${destination.percentage}%`}
                      />
                    )}
                  </ContainerAccount>
                ) : destination.destinationType ===
                  PayoutDestinationType.Card ? (
                  <ContainerDebitCard cardId={destination.destinationId}>
                    {debitCard => (
                      <Item
                        icon={getBankBrandLogo(debitCard.brand)}
                        name={selectorDebitCardName(debitCard)}
                        value={`${destination.percentage}%`}
                      />
                    )}
                  </ContainerDebitCard>
                ) : null
              ) : queryBankingAccount.data ? (
                <Item disabled name="No split method" value="0%" />
              ) : (
                <Item
                  disabled
                  name="Add payout method"
                  onAdd={() => {
                    if (selectorIsDomesticMember(member)) {
                      history.push("/member/settings/payment-methods/add");
                    } else {
                      history.push(
                        "/member/settings/payment-methods/add-international"
                      );
                    }
                  }}
                />
              );

            const texWithholdingItem = queryBankingAccount.data ? (
              <Item
                icon={{
                  color: "garnet",
                  secondaryColor: "white",
                  name: "taxes"
                }}
                name="Tax Witholdings"
                onEdit={() => {
                  onEditTaxWithholdings
                    ? onEditTaxWithholdings()
                    : history.push("/member/taxes/dashboard");
                }}
                value={
                  member.profile.withholdings?.tax?.status ===
                  MemberWithholdingStatus.Active
                    ? "On"
                    : "Off"
                }
              />
            ) : (
              <Item
                icon={{
                  color: "garnet",
                  secondaryColor: "white",
                  name: "taxes"
                }}
                disabled
                name="Tax Witholdings"
                onAdd={() => {
                  history.push("/member/taxes/dashboard");
                }}
              />
            );

            const items: ReactNode[] = [];
            if (!destination && queryBankingAccount.data!) {
              items.push(walletItem);
              items.push(payoutMethodItem);
            } else {
              items.push(payoutMethodItem);
              items.push(walletItem);
            }
            items.push(texWithholdingItem);

            return items.map((item, index) => (
              <WSElement
                key={String(index)}
                mb={index === items.length - 1 ? undefined : "M"}
              >
                {item}
              </WSElement>
            ));
          }}
        </WSQueries>
      </WSElement>
    </WSElement>
  );
};

type ItemProps = {
  name: string;
  disabled?: boolean;
  pending?: boolean;
  value?: string;
  onAdd?: () => void;
  onEdit?: () => void;
  icon?:
    | {
        name: WSIconName;
        color: WSElementColorsType;
        secondaryColor: WSElementColorsType;
      }
    | string;
} & WSElementProps;

const Item: React.FC<ItemProps> = ({
  name,
  value,
  disabled,
  pending,
  onAdd,
  onEdit,
  icon,
  ...elementProps
}) => (
  <WSFlexBox
    {...elementProps}
    justify="space-between"
    wrap="nowrap"
    alignItems="center"
  >
    <WSFlexBox
      wrap="nowrap"
      alignItems="center"
      style={{ opacity: disabled ? 0.6 : undefined }}
    >
      {icon ? (
        typeof icon === "object" ? (
          <WSAvatar.Icon
            color={icon.secondaryColor}
            colorBackground={icon.color}
            icon={icon.name}
            mr="M"
            size="S"
          />
        ) : (
          <WSAvatar.Image image={icon} mr="M" size="S" />
        )
      ) : (
        <WSAvatar.Text text={name} color="gray200" mr="M" size="S" />
      )}
      <WSText color="gray600">{name}</WSText>
    </WSFlexBox>
    <WSFlexBox wrap="nowrap" alignItems="center">
      {onAdd && (
        <WSButton.Link size="M" icon="plus" onClick={onAdd}>
          Add
        </WSButton.Link>
      )}
      {onEdit && (
        <WSIcon block mr="M" name="edit" color="gray500" onClick={onEdit} />
      )}
      {value && <WSText.ParagraphSm>{value}</WSText.ParagraphSm>}
      {pending && <WSPill icon="time" text="Pending" />}
    </WSFlexBox>
  </WSFlexBox>
);
