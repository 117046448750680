import camelCase from "lodash/camelCase";

function titleCase(str: string): string {
  // Same script is being used in line items custom labels migration script
  // https://github.com/wingspanHQ/payments/blob/cdbb59198ab00263f6a1d9b11c86dea450665e2f/src/migrations/004-bulk-payable-line-items-labels-custom-fields.ts

  return (
    str
      .replace(/[-_]/g, " ")
      // insert a space between lower & upper: HttpRequest => Http Request
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      // space before last upper in a sequence followed by lower: XMLHttp => XML Http
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
      // add space between numbers and letters: Col1 => Col 1
      .replace(/([a-zA-Z])([0-9])/g, "$1 $2")
      .replace(/([0-9])([a-zA-Z])/g, "$1 $2")
      // uppercase the first character
      .replace(/^./, firstChar => firstChar.toUpperCase())
      // replace multiple whitespaces with one
      .replace(/\s+/g, " ")
      .trim()
  );
}

export function getFlatfileCustomFields(csvRow: any) {
  const FLATFILE_CUSTOM_FIELD_SUFFIX = "custom_";
  const customFields: { [key: string]: string | boolean | null } = {};
  for (const key in csvRow) {
    if (key.startsWith(FLATFILE_CUSTOM_FIELD_SUFFIX)) {
      const actualKeyFF = key.substring(key.indexOf("_") + 1);
      const actualKeyWS = titleCase(camelCase(actualKeyFF)) ?? key;
      customFields[actualKeyWS] = csvRow[key] || "";
    }
  }
  return customFields;
}
