import React, { useCallback } from "react";
import { WSControl, WSControlProps } from "../WSControl/WSControl";
import { WSElement } from "../WSElement/WSElement.component";
import { InputProps, WSField, WSFieldProps } from "../WSField/WSField";
import styles from "./WSControlGroup.module.scss";

type RadioGroupValue = string;
type CheckboxGroupValue = { [key: string]: boolean };

export type WSControlGroupOption = {
  value: string;
  label: WSControlProps["label"];
  helperText?: WSControlProps["helperText"];
  tooltip?: WSControlProps["tooltip"];
  description?: WSControlProps["description"];
  avatar?: WSControlProps["avatar"];
};

export interface WSControlGroupBaseProps
  extends Omit<InputProps<any>, "value" | "onChange">,
    WSFieldProps {
  options: WSControlGroupOption[];
  chip?: boolean;
}

export interface WSControlRadioGroupProps extends WSControlGroupBaseProps {
  type: "radio";
  value: RadioGroupValue;
  onChange: (
    value: RadioGroupValue,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export interface WSControlCheckboxGroupProps extends WSControlGroupBaseProps {
  type: "checkbox" | "switch";
  value: CheckboxGroupValue;
  onChange: (
    value: CheckboxGroupValue,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export type WSControlGroupProps =
  | WSControlRadioGroupProps
  | WSControlCheckboxGroupProps;

export const WSControlGroup = ({
  type,
  name,
  value,
  onChange,
  disabled,
  options,
  chip,
  ...fieldProps
}: WSControlGroupProps) => {
  const getControlChecked = useCallback(
    (optionValue?: string) => {
      return type === "radio"
        ? optionValue === value
        : value && optionValue
        ? !!value[optionValue]
        : false;
    },
    [type, value]
  );

  const getHandleControlChange = useCallback(
    (option: WSControlGroupOption) => (
      controlValue: boolean,
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (type === "radio") {
        onChange(option.value, event);
      } else {
        onChange(
          {
            ...value,
            [option.value]: controlValue
          },
          event
        );
      }
    },
    [onChange, type, value]
  );

  return (
    <WSField as="div" {...fieldProps}>
      <WSElement className={styles.controlGroup}>
        {options.map((option) => {
          const checked = getControlChecked(option.value);
          const inputName = type === "radio" ? name : option.value;
          const optionValue = option.value;

          return (
            <WSControl
              key={optionValue}
              name={inputName}
              type={type}
              value={checked}
              onChange={getHandleControlChange(option)}
              chip={chip}
              label={option.label}
              helperText={option.helperText}
              tooltip={option.tooltip}
              disabled={disabled}
              description={option.description}
              avatar={option.avatar}
            />
          );
        })}
      </WSElement>
    </WSField>
  );
};
