import {
  useWSModal,
  WSActions,
  WSDivider,
  WSForm,
  WSInfoBox,
  WSInputPassword,
  WSList
} from "@wingspanhq/fe-component-library";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import * as Yup from "yup";
import { useSetPINCard } from "../../../../query/cards/mutations/useSetPINCard";
import { encryptCardPin } from "../../utils/encryptCardPin";

export interface ChangePinProps {
  cardId: string;
  onSuccess: () => void;
  onClose: () => void;
}

const validationSchema = Yup.object().shape({
  newPin: Yup.string()
    .required("New PIN is required")
    .matches(/^\d{4}$/, "PIN must be 4 digits"),
  confirmPin: Yup.string()
    .required("Confirm PIN is required")
    .oneOf([Yup.ref("newPin")], "PINs must match")
});

const ChangePin: React.FC<ChangePinProps> = ({
  cardId,
  onClose,
  onSuccess
}) => {
  const [setPIN, setPINMeta] = useSetPINCard();

  return (
    <WSList gap="L">
      <WSInfoBox>
        Enter your current PIN and create a new 4-digit PIN.
      </WSInfoBox>
      <WSForm
        defaultValues={{
          newPin: "",
          confirmPin: ""
        }}
        validationSchema={validationSchema}
        onSubmit={async ({ confirmPin }) => {
          const encryptedPin = await encryptCardPin(confirmPin);

          await setPIN(
            {
              cardId,
              pin: encryptedPin
            },
            {
              onSuccess: () => {
                onSuccess();
                onClose();
              }
            }
          );
        }}
      >
        <WSList gap="L">
          <WSForm.Field
            name="newPin"
            label="New PIN"
            component={WSInputPassword}
            componentProps={{
              required: true,
              maxLength: 4
            }}
          />
          <WSForm.Field
            name="confirmPin"
            label="Re-enter PIN"
            component={WSInputPassword}
            componentProps={{
              required: true,
              maxLength: 4
            }}
          />
          <WSErrorMessage contextKey="Other" error={setPINMeta.error} />
          <WSDivider />
          <WSActions
            alignment="fill"
            buttons={[
              {
                label: "Continue",
                type: "submit",
                kind: "Primary"
              },
              {
                label: "Cancel",
                onClick: onClose,
                kind: "Secondary"
              }
            ]}
          />
        </WSList>
      </WSForm>
    </WSList>
  );
};

export function useChangePINModal() {
  return useWSModal(ChangePin, {
    title: "Change pin",
    size: "S"
  });
}
