import {
  WSFormOld,
  WSRadioInputGroup,
  WSText
} from "@wingspanhq/fe-component-library";
import { IUserUpdateRequest } from "@wingspanhq/users";
import React from "react";
import * as Yup from "yup";
import { useUpdateUserProfile } from "../../../query/users/mutations";
import { FormActions } from "./FormActions";
import styles from "./SettingsUpdateForms.module.scss";

interface UpdateGenderFormProps {
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (data: any) => void;
}

export const UpdateGenderForm: React.FC<UpdateGenderFormProps> = ({
  onSubmit,
  defaultValues,
  onCancel
}) => {
  const [updateUserProfile] = useUpdateUserProfile();
  const onFormSubmit = async (data: any) => {
    const request: IUserUpdateRequest = {
      profile: {
        gender: data.gender
      }
    };
    await updateUserProfile(request, {
      onSuccess: () => {
        onSubmit(data);
      }
    });
  };
  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        gender: Yup.string().required("Select gender")
      })}
    >
      <WSFormOld.Field name="gender">
        <WSFormOld.Input
          render={props => (
            <WSRadioInputGroup
              {...props}
              options={[
                {
                  value: "Male",
                  label: "Male"
                },
                {
                  value: "Female",
                  label: "Female"
                },
                {
                  value: "Other",
                  label: "Other"
                }
              ]}
            />
          )}
        />
      </WSFormOld.Field>
      <WSFormOld.Error />
      <WSText.ParagraphSm pt="XL">
        This information is required by some of our insurance partners.
      </WSText.ParagraphSm>
      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <FormActions onCancel={onCancel} loading={isSubmitting} />
        )}
      </WSFormOld.Context>
    </WSFormOld>
  );
};
