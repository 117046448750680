import * as Yup from "yup";

export const getValidationSchema = () =>
  Yup.object().shape({
    country: Yup.string()
      .nullable()
      .required("Required"),
    type: Yup.string()
      .nullable()
      .required("Required")
  });
