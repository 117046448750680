import {
  useModalContext,
  WSButton,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";

import { openIntercom } from "../../../shared/utils/intercom";

export const PAYMENTS_VERIFICATION_MODAL = "paymentsVerification";

export const PaymentsVerificationModal = () => {
  const { closeModal } = useModalContext();
  const history = useHistory();

  return (
    <WSModal
      name={PAYMENTS_VERIFICATION_MODAL}
      title="Digital payments verification in progress"
    >
      <WSText mb="XL">
        We've got everything we need, but it could take us up to 24 hours to
        finish the verification process. Any invoices you create and send will
        go out automatically as soon as your account is verified.{" "}
        <WSButton.Link onClick={openIntercom}>
          Let us know if you have any questions
        </WSButton.Link>
      </WSText>
      <WSButton
        onClick={() => {
          closeModal(PAYMENTS_VERIFICATION_MODAL);
          history.push("/member/invoices");
        }}
      >
        Proceed to payments
      </WSButton>
    </WSModal>
  );
};
