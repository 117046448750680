import { WSButton } from "@wingspanhq/fe-component-library";
import { ReactNode } from "react";
import {
  FlowSetup,
  SetupRouteComponentProps
} from "../../../../shared/components/FlowSetup";
import { RouteImportPaymentsPreview } from "./RouteImportPaymentsPreview";
import { useHistory } from "react-router-dom";
import { RouteImportPaymentsProgress } from "./RouteImportPaymentsProgress";
import { IBulkPayableBatch } from "@wingspanhq/payments/dist/interfaces";
import { RouteImportPayments } from "./RouteImportPayments";
import { RouteImportPaymentsErrors } from "./RouteImportPaymentsErrors";

type ImportPaymentsFlowProps = {
  basePath: string;
  onBack: () => Promise<void> | void;
  onFinish: () => Promise<void> | void;
  Layout: React.FC<{
    title: string;
    children: ReactNode;
    onBack?: () => void;
  }>;
};

export type SetupImportPaymentsComponentProps = SetupRouteComponentProps<{
  Layout?: React.FC<{
    title: string;
    children: ReactNode;
    onBack?: () => void;
  }>;
}>;

export const ImportPaymentsFlow: React.FC<ImportPaymentsFlowProps> = ({
  basePath,
  onBack,
  Layout
}) => {
  const history = useHistory();
  return (
    <FlowSetup
      basePath={basePath}
      routes={[
        {
          path: "/:bulkBatchId/preview",
          component: RouteImportPaymentsPreview,
          basePath,
          Layout,
          onBack: () => {
            onBack();
          },
          onNext: context => {
            history.replace(`${basePath}/${context?.bulkBatchId}/upload`);
          }
        },
        {
          path: "/:bulkBatchId/upload",
          component: RouteImportPaymentsProgress,
          Layout,
          onBack: () => {
            onBack();
          },
          onNext: context => {
            const payableBulkBatch = context?.payableBulkBatch as IBulkPayableBatch;
            // We are getting Batch status as Completed though some items are failed.
            // So checking for itemsFailed to navigate to errors page.
            if ((payableBulkBatch?.statistics?.itemsFailed || 0) === 0) {
              history.push({
                pathname: "/member/1099-filing/generate-1099-nec-amounts",
                state: {
                  canSkip: true
                }
              });
            } else {
              history.replace(
                `${basePath}/${payableBulkBatch?.bulkPayableBatchId}/errors`
              );
            }
          }
        },
        {
          path: "/:bulkBatchId/errors",
          component: RouteImportPaymentsErrors,
          Layout,
          onBack: () => {
            onBack();
          },
          onNext: context => {
            // upload failed items again
            if (context?.bulkBatchId) {
              history.push(`${basePath}/${context?.bulkBatchId}`);
            }
            if (context?.skipErrors) {
              history.replace("/member/1099-filing/dashboard/all");
            }
          }
        },
        {
          path: "/:bulkBatchId",
          component: RouteImportPayments,
          onBack: () => {
            onBack();
          },
          onNext: context => {
            history.replace(`${basePath}/${context?.bulkBatchId}/preview`);
          }
        }
      ]}
    />
  );
};
