import { IAdditionalData } from "@wingspanhq/payments/dist/interfaces";
import { IBulkPayableItem } from "@wingspanhq/payments/dist/interfaces/bulkPayable";
import { ILabelsDictionary } from "../../utils/getUploadedFilename";

export const prepareLineItemsFromBulkBatchItem = (
  batchItem: IBulkPayableItem,
  customFields: IAdditionalData[],
  options?: { includeErrors?: boolean }
) => {
  const customFieldsColumns = customFields.reduce(
    (acc: ILabelsDictionary, cf) => {
      acc[cf.key] = batchItem.labels[cf.key];
      return acc;
    },
    {}
  );
  return {
    email: batchItem.collaboratorEmail,
    collaboratorId: batchItem.collaboratorId,
    contractorId: batchItem.collaboratorExternalId,
    dueDate: batchItem.dueDate,
    invoiceNotes: batchItem.payableNotes,
    amount: batchItem.amount,
    lineItemTitle: batchItem.lineItemDescription,
    lineItemDescription: batchItem.lineItemDetail,
    reimbursable: batchItem.reimbursableExpense,
    ...customFieldsColumns,
    ...(options?.includeErrors
      ? { error: batchItem.metadata?.errorMessage }
      : {})
  };
};
