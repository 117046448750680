import { ISubscription } from "@wingspanhq/users";

export const getAccountIsPaused = (subscription?: ISubscription) => {
  if (subscription) {
    return subscription.gracePeriodEnd < subscription.currentPeriodEnd
      ? subscription.currentPeriodEnd < new Date()
      : subscription.gracePeriodEnd < new Date();
  } else {
    return false;
  }
};
