import { paymentsService } from "../../../../services/payments";
import { useWSMutation } from "../../../../query/helpers";
import { WSServiceError } from "../../../../utils/serviceHelper";
import {
  ITaxFormInviteRequest,
  ITaxFormInviteResponse
} from "@wingspanhq/payments/dist/interfaces";

export const useRequestLink = () =>
  useWSMutation<ITaxFormInviteResponse, WSServiceError, ITaxFormInviteRequest>(
    data => paymentsService.taxForm.requestLink(data)
  );
