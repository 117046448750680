// TODO: move to FECL

import { WSText, WSTextProps } from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";

type Props = WSTextProps;

export const TextBulleted: React.FC<Props> = ({ className, ...textProps }) => (
  <WSText
    as="ul"
    className={classNames(styles.bulletedText, className)}
    {...textProps}
  />
);
