import {
  useIsMobile,
  WSButton,
  WSContainer,
  WSDivider,
  WSElement,
  WSIcon,
  WSLogo,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { ReactNode } from "react";
import { useUserId } from "../../query/hooks/helpers";
import { useActivities } from "../../query/users/queries";
import { LogoApp } from "../../shared/components/LogoApp";
import { selectorIsWhilteLabeled } from "../../shared/components/LogoApp/selectorIsWhilteLabeled";
import styles from "./OnboardingLayout.module.scss";

type Props = {
  onBack?: () => void;
  bannerMessage?: ReactNode;
  partnerLogo?: string;
};

export const OnboardingLayout: React.FC<Props> = ({
  onBack,
  bannerMessage,
  partnerLogo,
  children
}) => {
  const isMobile = useIsMobile();
  const userId = useUserId();
  const activityQuery = useActivities(userId);
  return (
    <>
      <WSElement className={styles.header}>
        <WSContainer>
          <WSElement className={styles.headerInner}>
            <WSScreen.TabletAndDesktop>
              {onBack && (
                <WSIcon
                  block
                  name="arrow-left"
                  className={styles.backButton}
                  color="gray500"
                  onClick={onBack}
                />
              )}
            </WSScreen.TabletAndDesktop>

            {partnerLogo && !selectorIsWhilteLabeled(activityQuery.data) ? (
              <>
                <WSLogo size="S" />
                <WSIcon
                  block
                  name="exit"
                  mx={isMobile ? "M" : "XL"}
                  size="XS"
                  color="gray700"
                />
                <WSElement
                  className={styles.partnerLogo}
                  data-testid="partnerLogo"
                >
                  <img
                    className={styles.partnerLogoImage}
                    src={partnerLogo}
                    alt="Partner Logo"
                  />
                </WSElement>
              </>
            ) : (
              <LogoApp />
            )}
          </WSElement>
        </WSContainer>
      </WSElement>
      {bannerMessage ? (
        <WSElement className={styles.banner} data-testid="signUpBanner">
          <WSContainer>
            <WSElement className={styles.bannerInner}>
              <WSText>{bannerMessage}</WSText>
            </WSElement>
          </WSContainer>
        </WSElement>
      ) : (
        <WSDivider />
      )}
      <WSContainer>
        <WSElement py="2XL">
          <WSScreen.Mobile>
            {onBack && (
              <WSButton.Link
                icon="chevron-left"
                onClick={onBack}
                className={styles.backButton}
              >
                Back
              </WSButton.Link>
            )}
          </WSScreen.Mobile>
          <WSElement mt="2XL" className={styles.content}>
            {children}
          </WSElement>
        </WSElement>
      </WSContainer>
    </>
  );
};
