import {
  IWebflowEnterprise,
  IWebflowPartner
} from "@wingspanhq/cms/dist/lib/interfaces";

export const selectorPartnerLogoData = (
  customLogo?: string,
  enterprise?: IWebflowEnterprise,
  partner?: IWebflowPartner
) => {
  if (customLogo) {
    return {
      src: customLogo,
      alt: ""
    };
  } else if (enterprise) {
    return {
      src: enterprise.logo.src,
      alt: enterprise.name
    };
  } else if (partner && partner.logo) {
    return {
      src: partner.logo.src,
      alt: partner.name
    };
  }
};
