import { WSService } from "../utils/WSService";
import { IIntegrationAccountResponse } from "@wingspanhq/integrations/dist/lib/interfaces/account";
import {
  IIntegrationItemResponse,
  IIntegrationsQuicbooksSyncActivityAction,
  IIntegrationsQuickbooksSyncActivityGetResponse,
  IIntegrationsQuickbooksSyncActivityForceSyncResponse
} from "@wingspanhq/integrations/dist/lib/interfaces";
import { IIntegrationCustomer } from "@wingspanhq/integrations/dist/lib/interfaces/customer";
import { IIntegrationVendor } from "@wingspanhq/integrations/dist/lib/interfaces/vendor";
import {
  IIntegrationsQuickbooksCreateResponse,
  IIntegrationsQuickbooksResponse,
  IIntegrationsQuickbooksUpdateRequest,
  IIntegrationsQuickbooksUpdateResponse
} from "@wingspanhq/integrations/dist/lib/interfaces/quickbooks-api/integrationsQuickbooks";
import { ListRequestQuery } from "../utils/serviceHelper";

const service = new WSService("/integrations");

export const getIntegrationsQuickbooks = async () => {
  const response = await service.get("/quickbooks/service");
  return response.data as IIntegrationsQuickbooksResponse;
};

export type IntegrationsQuickbooksSyncActivitiesWithSumamry = {
  data: IIntegrationsQuickbooksSyncActivityGetResponse[];
  summary: {
    listSize: number;
    totalValue: number;
  };
};

export interface IntegrationsQuickbooksSyncActivitiesFilter {
  userId?: string;
  action?:
    | IIntegrationsQuicbooksSyncActivityAction
    | {
        "!="?: IIntegrationsQuicbooksSyncActivityAction;
        in?: IIntegrationsQuicbooksSyncActivityAction[];
      };
  createdAt?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
  entityId?: string;
  displayName?: {
    like?: string;
  };
  isError?: boolean;
}

export const getIntegrationsQuickbooksSyncActivities = async (
  query?: ListRequestQuery<
    IntegrationsQuickbooksSyncActivitiesFilter,
    {
      updatedAt: "asc" | "desc";
    }
  >
) => {
  const { headers, data } = await service.get(
    "/quickbooks/service/sync/activity",
    {
      params: query
    }
  );
  return {
    data,
    summary: {
      listSize: Number(headers["x-ws-list-size"]),
      totalValue: Number(headers["x-ws-custom-invoice-amount-total"])
    }
  } as IntegrationsQuickbooksSyncActivitiesWithSumamry;
};

export const doIntegrationsQuickbooksSyncActivityForceSync = async (
  id: string
) => {
  const response = await service.post(
    `/quickbooks/service/sync/activity/${id}/force-sync`,
    {}
  );
  return response.data as IIntegrationsQuickbooksSyncActivityForceSyncResponse;
};

export const updateIntegrationsQuickbooks = async (
  data: IIntegrationsQuickbooksUpdateRequest
) => {
  const response = await service.patch("/quickbooks/service", data);
  return response.data as IIntegrationsQuickbooksUpdateResponse;
};

export const createIntegrationsQuickbooks = async (redirectUrl: any) => {
  const response = await service.post("/quickbooks/service", redirectUrl);
  return response.data as IIntegrationsQuickbooksCreateResponse;
};

export const deleteIntegrationsQuickbooks = async () => {
  const response = await service.delete("/quickbooks/service");
  return response.data;
};

export const getIntegrationsQuickbooksAccountAssets = async (
  query?: ListRequestQuery
) => {
  const response = await service.get("/quickbooks/account/asset", {
    params: query
  });
  return response.data;
};

export const getIntegrationsQuickbooksAccountAsset = async (id: string) => {
  const response = await service.get(`/quickbooks/account/asset/${id}`);
  return response.data;
};

export const createIntegrationsQuickbooksAccountAssets = async () => {
  const response = await service.post(`/quickbooks/account/asset`, {});
  return response.data;
};

export const getIntegrationsQuickbooksAccountExpenses = async (
  query?: ListRequestQuery
) => {
  const response = await service.get("/quickbooks/account/expense", {
    params: query
  });
  return response.data as IIntegrationAccountResponse[];
};

export const getIntegrationsQuickbooksAccountExpense = async (id: string) => {
  const response = await service.get(`/quickbooks/account/expense/${id}`);
  return response.data;
};

export const createIntegrationsQuickbooksAccountExpenses = async () => {
  const response = await service.post(`/quickbooks/account/expense`, {});
  return response.data;
};

export const getIntegrationsQuickbooksAccountLiabilities = async (
  query?: ListRequestQuery
) => {
  const response = await service.get("/quickbooks/account/liability", {
    params: query
  });
  return response.data;
};

export const getIntegrationsQuickbooksAccountLiability = async (id: string) => {
  const response = await service.get(`/quickbooks/account/liability/${id}`);
  return response.data;
};

export const createIntegrationsQuickbooksAccountLiabilities = async () => {
  const response = await service.post(`/quickbooks/account/liability`, {});
  return response.data;
};

export const getIntegrationsQuickbooksAccountRevenues = async (
  query?: ListRequestQuery
) => {
  const response = await service.get("/quickbooks/account/revenue", {
    params: query
  });
  return response.data;
};

export const getIntegrationsQuickbooksAccountRevenue = async (id: string) => {
  const response = await service.get(`/quickbooks/account/revenue/${id}`);
  return response.data;
};

export const createIntegrationsQuickbooksAccountRevenues = async () => {
  const response = await service.post(`/quickbooks/account/revenue`, {});
  return response.data;
};

export const getIntegrationsQuickbooksCustomers = async (
  query: ListRequestQuery
) => {
  const response = await service.get("/quickbooks/customer", { params: query });
  return response.data as IIntegrationCustomer[];
};

export const getIntegrationsQuickbooksCustomer = async (id: string) => {
  const response = await service.get(`/quickbooks/customer/${id}`);
  return response.data;
};

export const createIntegrationsQuickbooksCustomers = async () => {
  const response = await service.post(`/quickbooks/customer`, {});
  return response.data;
};

export const getIntegrationsQuickbooksItems = async (
  query: ListRequestQuery
) => {
  const response = await service.get("/quickbooks/item", {
    params: query
  });
  return response.data as IIntegrationItemResponse[];
};

export const getIntegrationsQuickbooksItem = async (id: string) => {
  const response = await service.get(`/quickbooks/item/${id}`);
  return response.data;
};

export const createIntegrationsQuickbooksItems = async () => {
  const response = await service.post(`/quickbooks/item`, {});
  return response.data;
};

export const getIntegrationsQuickbooksVendors = async (
  query: ListRequestQuery
) => {
  const response = await service.get("/quickbooks/vendor", {
    params: query
  });
  return response.data as IIntegrationVendor[];
};

export const getIntegrationsQuickbooksVendor = async (id: string) => {
  const response = await service.get(`/quickbooks/vendor/${id}`);
  return response.data;
};

export const createIntegrationsQuickbooksVendors = async () => {
  const response = await service.post(`/quickbooks/vendor`, {});
  return response.data;
};
