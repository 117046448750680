import isEqual from "lodash/isEqual";
import { useDebounce } from "primereact/hooks";
import { InputText } from "primereact/inputtext";
import React, { useCallback, useMemo, useState } from "react";
import { WSElement } from "../WSElement/WSElement.component";
import { WSBadge, WSChip, WSSidebar } from "../common";
import { WSButton } from "../core/WSButton/WSButton.component";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import { WSText } from "../core/WSText/WSText.component";
import { WSFlexBox } from "../layout";
import { ActiveFilters, useActiveFilters } from "./ActiveFilters";
import { Filter } from "./Filter";
import styles from "./WSFilters.module.scss";
import {
  WSFilter,
  WSFilterOption,
  WSFilterValue,
  WSFilterValues,
  WSQuickFilterItem
} from "./types";

export type SidePanelProps = {
  searchable?: boolean;
  filters?: WSFilter[];
  quickFilters?: WSQuickFilterItem[];
  values: WSFilterValues;
  onFilter: (newValues: WSFilterValues) => void;
  onChange?: (newValues: WSFilterValues) => void;
  onClose: () => void;
};

export const Sidebar: React.FC<SidePanelProps> = (props, ref) => {
  const {
    filters = [],
    quickFilters = [],
    searchable,
    onClose,
    onFilter,
    onChange,
    values: initialValues
  } = props;
  const [values, setValues] = useState(initialValues);

  const handleFilterChange = useCallback(
    (name: string, newValue?: WSFilterValue) => {
      setValues((prev) => {
        const newValues = { ...prev, [name]: newValue };

        onChange?.(newValues);
        return newValues;
      });
    },
    [onChange]
  );

  const handleQuickFilterChange = useCallback(
    (newPreset: WSFilterValues) => {
      setValues(newPreset);
      onChange?.(newPreset);
    },
    [onChange]
  );

  const activeFilters = useActiveFilters(values, filters);

  const handleClear = () => {
    setValues({});
  };

  const [searchValue, debouncedSearchValue, setSearchValue] = useDebounce(
    "",
    400
  );
  const [expandedFilters, setExpandedFilters] = useState<
    Record<string, boolean>
  >(filters.reduce((acc, filter) => ({ ...acc, [filter.name]: true }), {}));

  const visibleFilters = useMemo(() => {
    const searchString = debouncedSearchValue.toLowerCase();

    if (!searchString) return filters;

    return filters
      .filter((filter) => filter.type === "checkbox" || filter.type === "radio")
      .map((filter) => ({
        ...filter,
        // Cast to any because we know that the filter is of type checkbox or radio
        options: (filter as any).options?.filter(
          (option: WSFilterOption) =>
            option.label.toLowerCase().includes(searchString) ||
            option.options?.filter((subOption) =>
              subOption.label.toLowerCase().includes(searchString)
            ).length
        )
      }))
      .filter((filter) =>
        debouncedSearchValue ? filter.options?.length : true
      );
  }, [filters, debouncedSearchValue]);

  const count = activeFilters.length;

  return (
    <WSSidebar
      visible
      header={
        <WSFlexBox.CenterY>
          {count ? (
            <WSBadge mr="S" theme="dark" size="M" number={count} />
          ) : null}
          <WSText.Paragraph weight="medium">Filters</WSText.Paragraph>
        </WSFlexBox.CenterY>
      }
      footer={
        <>
          <WSButton.Tertiary type="button" size="S" mr="M" onClick={onClose}>
            Cancel
          </WSButton.Tertiary>
          <WSButton.Primary
            size="S"
            type="button"
            onAsyncClick={async () => {
              await onFilter?.(values);
              onClose?.();
            }}
          >
            Show results
          </WSButton.Primary>
        </>
      }
      onClose={() => {
        onClose?.();
      }}
      contentElementProps={{ p: "NONE", colorBackground: "white" }}
    >
      <WSFlexBox direction="column" wrap="wrap" className={styles.sidebarBody}>
        {searchable ? (
          <WSFlexBox.CenterY className={styles.search}>
            <WSIcon name="search" size="M" color="gray400" mr="S" />
            <InputText
              unstyled
              className={styles.searchInput}
              placeholder="Search filters"
              value={searchValue}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
            />

            {searchValue && (
              <WSIcon
                name="exit"
                size="M"
                color="gray600"
                ml="S"
                onClick={() => {
                  setSearchValue("");
                }}
              />
            )}
          </WSFlexBox.CenterY>
        ) : null}
        <WSElement className={styles.sidebarFilters}>
          {searchable && searchValue && visibleFilters.length === 0 && (
            <WSText.ParagraphSm mt="L" color="gray500" align="center">
              No results for “{searchValue}”
            </WSText.ParagraphSm>
          )}
          {quickFilters && quickFilters.length > 0 ? (
            <WSElement className={styles.quickFilters}>
              {quickFilters.map((filter, index) => {
                return (
                  <WSChip
                    key={index}
                    className={styles.quickFilterItem}
                    icon={filter.icon}
                    theme={filter.theme}
                    pillText={filter.label}
                    rightText={filter.rightText as any}
                    rightTextProps={filter.rightTextProps}
                    active={isEqual(values, filter.preset)}
                    onClick={() => {
                      handleQuickFilterChange(filter.preset);
                    }}
                  />
                );
              })}
            </WSElement>
          ) : (
            visibleFilters.map((filter, index) => (
              <Filter
                key={filter.name + index}
                expanded={expandedFilters[filter.name]}
                onExpandedChange={() => {
                  setExpandedFilters((prev) => ({
                    ...prev,
                    [filter.name]: !prev[filter.name]
                  }));
                }}
                value={values[filter.name]}
                onChange={handleFilterChange}
                {...filter}
              />
            ))
          )}
        </WSElement>

        {activeFilters.length > 0 && (
          <WSElement className={styles.sidebarFooter}>
            <ActiveFilters
              activeFilters={activeFilters}
              values={values}
              onFilterChange={handleFilterChange}
            />

            <WSButton.Secondary
              mt="XL"
              type="button"
              size="S"
              fullWidth
              rightIcon="exit"
              onClick={handleClear}
            >
              Clear filters
            </WSButton.Secondary>
          </WSElement>
        )}
      </WSFlexBox>
    </WSSidebar>
  );
};
