import {
  EngagementType,
  IEngagement
} from "@wingspanhq/payments/dist/interfaces";
import { servicePayments } from "../servicePayments";
import { ListRequestQuery } from "../../utils/serviceHelper";

export type EngagementListQueryResponse = {
  data: IEngagement[];
  summary: {
    listSize: number;
  };
};

export interface EngagementFilter {
  type?:
    | EngagementType
    | {
        in?: EngagementType[];
        notIn?: EngagementType[];
      };
  isDefault?: boolean;
}

export interface EngagementApiFilter extends EngagementFilter {}

export const getEngagements = async (
  query?: ListRequestQuery<
    EngagementApiFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >
): Promise<EngagementListQueryResponse> => {
  const { data, headers } = await servicePayments.get(`/engagement`, {
    params: query
  });

  return { summary: { listSize: Number(headers["x-ws-list-size"]) }, data };
};
