import {
  useModalContext,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { track } from "../../../utils/analytics";
import { addNotification } from "../../../components/Notification/Notification";
import { useBookkeepingFiltersYear } from "../dashboard/BookkeepingIndex";

export const TAXES_MODAL_REQUEST_SCHEDULE_C_PDF =
  "TAXES_MODAL_REQUEST_SCHEDULE_C_PDF";

export const TaxesModalRequestScheduleCPDF: React.FC = () => {
  const { closeModal } = useModalContext();

  const currentYear = useBookkeepingFiltersYear();

  return (
    <WSModal name={TAXES_MODAL_REQUEST_SCHEDULE_C_PDF} size="S">
      <WSText.Heading5 mb="M">
        Generate {currentYear} Schedule C PDF?
      </WSText.Heading5>
      <WSText mb="XS">
        Requests will be processed within 24 hours and sent to your email.
      </WSText>
      <WSButtons mt="2XL" format="modal">
        <WSButton.Primary
          onClick={async () => {
            track("Schedule-C Requested", { year: currentYear });
            closeModal(TAXES_MODAL_REQUEST_SCHEDULE_C_PDF);
            addNotification({
              text: `${currentYear} Schedule-C PDF requested`
            });
          }}
        >
          Request PDF
        </WSButton.Primary>
        <WSButton.Tertiary
          onClick={() => {
            closeModal(TAXES_MODAL_REQUEST_SCHEDULE_C_PDF);
          }}
        >
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </WSModal>
  );
};
