import cn from "classnames";
import React from "react";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { ComponentWithKinds } from "../../WSElement/WSElement.helpers";
import { WSButton, WSButtonProps } from "../WSButton/WSButton.component";
import { WSIcon, WSIconName } from "../WSIcon/WSIcon.component";
import { WSPanel } from "../WSPanel/WSPanel.component";
import { WSText } from "../WSText/WSText.component";
import styles from "./WSMessageBox.module.scss";

export enum WSMessageBoxKindMap {
  Regular = "Regular",
  Warning = "Warning",
  Info = "Info",
  Error = "Error",
  Success = "Success"
}

export type WSMessageBoxKind = keyof typeof WSMessageBoxKindMap;

export enum WSMessageBoxSizeMap {
  Medium = "Medium",
  Large = "Large"
}
export type WSMessageBoxSize = keyof typeof WSMessageBoxSizeMap;

export type WSMessageBoxProps = WSElementProps & {
  kind?: WSMessageBoxKind;
  size?: WSMessageBoxSize;
  centered?: boolean;
  noBorder?: boolean;
  icon?: WSIconName;
  title?: string;
  button?: WSButtonProps;
  onClose?: () => void;
};

export const WSMessageBox: ComponentWithKinds<
  WSMessageBoxProps,
  WSMessageBoxKindMap
> = ({
  children,
  kind = "Regular",
  size = "Medium",
  className,
  centered,
  noBorder,
  icon,
  title,
  button,
  onClose,
  ...elementProps
}) => (
  <WSPanel
    className={cn(
      styles.base,
      styles[`kind-${kind}`],
      styles[`size-${size}`],
      { [styles.centered]: centered, [styles.noBorder]: noBorder },
      className
    )}
    {...elementProps}
  >
    {icon && <WSIcon block name={icon} mr="M" className={styles.icon} />}
    <WSElement>
      {title && (
        <WSText className={styles.title} mb={children ? "XS" : undefined}>
          {title}
        </WSText>
      )}
      {children && (
        <WSElement
          className={styles.text}
          color={title ? "gray500" : "gray600"}
        >
          {children}
        </WSElement>
      )}
      {button && <WSButton mt="M" {...button} />}
    </WSElement>
    {onClose && (
      <WSIcon
        block
        name="exit"
        onClick={onClose}
        color="gray500"
        className={styles.closeButton}
      />
    )}
  </WSPanel>
);

WSMessageBox.Regular = (props) => <WSMessageBox kind="Regular" {...props} />;
WSMessageBox.Warning = (props) => <WSMessageBox kind="Warning" {...props} />;
WSMessageBox.Error = (props) => <WSMessageBox kind="Error" {...props} />;
WSMessageBox.Success = (props) => <WSMessageBox kind="Success" {...props} />;
WSMessageBox.Info = (props) => <WSMessageBox kind="Info" {...props} />;
