import {
  WSElement,
  WSElementColorsType,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { ReactNode } from "react";
import styles from "./Summary.module.scss";

type Props = WSElementProps & {
  value: ReactNode;
  label: ReactNode;
  color?: WSElementColorsType;
};

export const Summary: React.FC<Props> = ({
  value,
  label,
  color = "gray200",
  ...elementProps
}) => (
  <WSElement {...elementProps} className={styles.summary} color={color}>
    <WSText.Heading5>{value}</WSText.Heading5>
    <WSText color="gray600">{label}</WSText>
  </WSElement>
);
