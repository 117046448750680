import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation, WSMutationConfig } from "../../helpers";
import {
  QUERY_PAYMENTS_TAXFORM_LIST_PAYEE,
  QUERY_PAYMENTS_TAXFORM_PAYEE
} from "../keys";
import { paymentsService } from "../../../services/payments";
import { ITinVerificationResponse } from "@wingspanhq/payments/dist/interfaces";
import { QUERY_USERS_MEMBER_PROFILE } from "../../users/keys";

export const useTaxFormVerifyTin = (
  config?: WSMutationConfig<ITinVerificationResponse, WSServiceError, never>
) =>
  useWSMutation(() => paymentsService.taxForm.verifyTin(), {
    dependencies: [
      QUERY_USERS_MEMBER_PROFILE,
      QUERY_PAYMENTS_TAXFORM_PAYEE,
      QUERY_PAYMENTS_TAXFORM_LIST_PAYEE
    ],
    ...config
  });
