import { IS_DEV_ENV } from "../constants/environment";

export const openCustomSupportPortal = ({
  message,
  customURL
}: {
  message?: string;
  customURL?: any;
}): boolean => {
  const customSupportURL =
    typeof customURL === "string"
      ? customURL
      : window.organizationAccountConfig?.support?.portal.generalUrl;

  if (customSupportURL) {
    const width = 400;
    const height = 600;

    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    const features = [
      `width=${width}`,
      `height=${height}`,
      `left=${left}`,
      `top=${top}`,
      "resizable=yes",
      "scrollbars=yes",
      "status=no",
      "location=no",
      "toolbar=no",
      "menubar=no"
    ].join(",");

    window.open(
      message ? `${customSupportURL}?message=` : customSupportURL,
      "targetWindow",
      features
    );

    console.info("Custom support portal is opened!");

    return true;
  }

  return false;
};

export const openIntercom = (customURL?: any) => {
  if (openCustomSupportPortal({ customURL })) return;

  if (window.Intercom) {
    window.document.documentElement.setAttribute("data-show-intercom", "true");
    window.Intercom("show");
    window.Intercom("onHide", () => {
      window.document.documentElement.removeAttribute("data-show-intercom");
    });
  } else if (IS_DEV_ENV) {
    console.log("Intercom is opened");
  } else {
    console.error("Intercom is not defined");
  }
};

export const openIntercomWithMessage = (
  message: string,
  customURL?: string
) => {
  if (openCustomSupportPortal({ customURL, message })) return;

  openIntercom();

  if (window.Intercom) {
    window.Intercom("showNewMessage", message);
  } else if (IS_DEV_ENV) {
    console.log("Intercom message sent:", message);
  }
};

export const openIntercomMessage = openIntercomWithMessage;
