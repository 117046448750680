import orderBy from "lodash/orderBy";
import {
  getAuthorizedAccountName,
  ITeamMemberRecord
} from "../../../../shared/utils/teamUtils";
import { getOrganizationScopesList } from "../../utils/getOrganizationScopesCell";
import { getPermissionScopesList } from "../../utils/getPermissionScopesCell";
import { DefaultTeamFilters } from "../../utils/teamFilters";
import { INewUser } from "@wingspanhq/users";


export const sortTableData = (
  sort: DefaultTeamFilters["sort"],
  tableData: {
    id: string;
    data: ITeamMemberRecord;
  }[],
  queryOrganizationUsersData: INewUser[]
) => {
  return sort?.name
    ? orderBy(
        tableData,
        [
          row =>
            getAuthorizedAccountName(
              row.data.teamMemberWSMember,
              row.data.teamMemberWSUser
            ).toLowerCase()
        ],
        [sort?.name]
      )
    : sort?.status
    ? orderBy(
        tableData,
        [row => row.data.teamMemberWSUser?.status || "w"],
        [sort?.status]
      )
    : sort?.organization
    ? orderBy(
        tableData,
        [
          row =>
            (row.data.authorization &&
              getOrganizationScopesList(
                row.data.authorization,
                queryOrganizationUsersData
              )) ||
            "w"
        ],
        [sort?.organization]
      )
    : sort?.permission
    ? orderBy(
        tableData,
        [
          row =>
            getPermissionScopesList(
              row.data.authorization?.allowedScopeGroupIds || []
            ).join(" ") || "w"
        ],
        [sort?.permission]
      )
    : tableData;
};