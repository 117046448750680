import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { useCallback, useState } from "react";
import { getAllEntries } from "../../../utils/serviceHelper";
import { downloadCSV } from "../../Reports/utils/sheetjs/downloadCSV";
import { InvoiceRowsQuery, getInvoiceRows } from "../service";
import { mapInvoiceToCsvTable } from "../utils/csv";

export const useDownloadCsv = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { openSnackbar } = useWSSnackbar();

  const downloadCsv = useCallback(
    async (query: InvoiceRowsQuery) => {
      try {
        setIsLoading(true);
        setError(null);

        const invoices = await getAllEntries(
          async (...args) => await getInvoiceRows(...args),
          query.filter,
          query.sort
        );

        const csvData = invoices.map(invoice => mapInvoiceToCsvTable(invoice));

        downloadCSV(csvData, "Invoices");

        openSnackbar({
          type: "success",
          message: "CSV downloaded"
        });
      } catch (error) {
        setError(error as any);

        openSnackbar({
          type: "warning",
          message: "Failed to download CSV"
        });
      } finally {
        setIsLoading(false);
      }
    },
    [openSnackbar]
  );

  return {
    downloadCsv,
    isLoading,
    error
  };
};
