import {
  WSAvatar,
  WSElement,
  WSElementColorsType,
  WSElementProps,
  WSFlexBox,
  WSIconName,
  WSPanel,
  WSText,
  toCamelCase
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { Tooltip } from "../../../../components/Tooltip";
import styles from "./index.module.scss";

type Props = {
  name: string;
  desctiption?: string;
  isSelected?: boolean;
  icon?:
    | {
        name: WSIconName;
        color: WSElementColorsType;
        secondaryColor: WSElementColorsType;
      }
    | string;
  kind?: "vertical" | "horisontal";
  tooltip?: ReactNode;
} & WSElementProps;

export const FlowOption: React.FC<Props> = ({
  name,
  desctiption,
  isSelected,
  icon,
  kind = "horisontal",
  tooltip,
  ...elementProps
}) => {
  return (
    <WSPanel
      {...elementProps}
      className={classNames(
        styles.panel,
        { [styles.active]: isSelected },
        styles[kind]
      )}
      data-testid={toCamelCase(name, "option")}
    >
      {kind === "horisontal" ? (
        <WSFlexBox wrap="nowrap" alignItems="center" justify="space-between">
          <WSFlexBox wrap="nowrap" alignItems="center">
            {icon ? (
              typeof icon === "object" ? (
                <WSAvatar.Icon
                  color={icon.secondaryColor}
                  colorBackground={icon.color}
                  icon={icon.name}
                  mr="M"
                />
              ) : (
                <WSAvatar.Image image={icon} mr="M" />
              )
            ) : null}
            <WSElement>
              <WSText.Heading5>{name}</WSText.Heading5>
              {desctiption && (
                <WSText mt="XS" color="gray500">
                  {desctiption}
                </WSText>
              )}
            </WSElement>
          </WSFlexBox>

          {tooltip && <Tooltip>{tooltip}</Tooltip>}
        </WSFlexBox>
      ) : (
        <>
          {icon ? (
            typeof icon === "object" ? (
              <WSAvatar.Icon
                color={icon.secondaryColor}
                colorBackground={icon.color}
                icon={icon.name}
                mb="M"
              />
            ) : (
              <WSAvatar.Image image={icon} mb="M" />
            )
          ) : null}
          <WSFlexBox wrap="nowrap" alignItems="center">
            <WSText.Heading5 mr={tooltip ? "M" : undefined}>
              {name}
            </WSText.Heading5>
            {tooltip && <Tooltip>{tooltip}</Tooltip>}
          </WSFlexBox>
          {desctiption && (
            <WSText mt="XS" color="gray500">
              {desctiption}
            </WSText>
          )}
        </>
      )}
    </WSPanel>
  );
};
