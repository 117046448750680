import {
  WSButton,
  WSGrid,
  WSMessageBox,
  WSPage,
  WSTabs,
  WSText,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import {
  TaxFormEventType,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces/taxForm";
import React from "react";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router-dom";
import { WSQueries } from "../../../../../query/WSQuery";
import { useFeatureFlags } from "../../../../../query/hooks/useFeatureFlags";
import { usePayerTaxForm } from "../../../../../query/taxForm/queries/usePayerTaxForm";
import { useOrganizationUsersListSize } from "../../../../../query/users/queries";
import { selectorMemberName } from "../../../../../shared/selectors/selectorMemberName";
import { useAuthorizedScopeGroups } from "../../../../../shared/utils/teamUtils";
import { getFillingStatusBadgeProps } from "../../../components/FillingStatusBadge";
import { selector1099NECTotal } from "../../../selectors/selector1099NECTotal";
import { selectorPayerTaxFormIsEventExist } from "../../../selectors/selectorPayerTaxFormIsEventExist";
import { selectorPayerTaxFormIsLatestAddressCorrection } from "../../../selectors/selectorPayerTaxFormIsLatestAddressCorrection";
import { selectorPayerTaxFormRecipientConfirmationStatusText } from "../../../selectors/selectorPayerTaxFormRecipientConfirmationStatusText";
import { selectorTaxFormIsEditable } from "../../../selectors/selectorTaxFormIsEditable";
import { Header } from "./Header";
import { HistorySection } from "./HistorySection";
import { useModalAdjustAmount } from "./ModalAdjustAmount";
import { useModalBeforeEdit } from "./ModalBeforeEdit";
import { useModalRevertingChanges } from "./ModalRevertingChanges";
import { useModalReviewChanges } from "./ModalReviewChanges";
import { useModalUpdateStatus } from "./ModalUpdateStatus";
import { Payables } from "./Payables";
import { RecipientDetails } from "./RecipientDetails";
import { StatusItem, Statuses } from "./Statuses";
import { EXCLUSION_REASONS } from "./exclusionReasons";

export const RouteTaxFormDetails: React.FC<RouteComponentProps<{
  taxFormId: string;
}>> = ({
  match: {
    params: { taxFormId }
  }
}) => {
  const queryPayerTaxForm = usePayerTaxForm(taxFormId);
  const queryFeatureFlags = useFeatureFlags();
  const queryOrganizationListSize = useOrganizationUsersListSize();
  const history = useHistory();
  const beforeEditModal = useModalBeforeEdit();
  const updateStatusModal = useModalUpdateStatus();
  const adjustAmountModal = useModalAdjustAmount();
  const reviewRedactedData = useModalReviewChanges();
  const revertChanges = useModalRevertingChanges();
  const { hasAdminScope, hasCollaboratorsScope } = useAuthorizedScopeGroups();

  return (
    <WSPage>
      <WSQueries
        queries={{
          queryPayerTaxForm,
          queryOrganizationListSize
        }}
      >
        {({
          queryPayerTaxFormData: taxForm,
          queryOrganizationListSizeData
        }) => {
          const isEditable = selectorTaxFormIsEditable(taxForm);
          const organizationName =
            queryOrganizationListSizeData && taxForm.payer
              ? selectorMemberName(taxForm.payer)
              : undefined;

          const statusItems: StatusItem[] = [
            {
              label: "1099-NEC total",
              onClick:
                taxForm.status !== TaxFormStatus.Pending && isEditable
                  ? async () => {
                      if (taxForm.recipientSyncEnabled) {
                        const response = await beforeEditModal.open();
                        if (!response) {
                          return;
                        }
                      }

                      const responseAfterUpdateAmount = await adjustAmountModal.open(
                        {
                          taxForm
                        }
                      );

                      if (
                        responseAfterUpdateAmount?.status !== taxForm.status &&
                        responseAfterUpdateAmount
                      ) {
                        await updateStatusModal.open({
                          taxForm: responseAfterUpdateAmount
                        });
                      }
                    }
                  : undefined,
              pill:
                selector1099NECTotal(taxForm) === undefined
                  ? {
                      icon: "time",
                      text: "Pending"
                    }
                  : {
                      icon:
                        taxForm.recipientSyncEnabled === false &&
                        taxForm.data.adjustments
                          ? "edit-box"
                          : "dollar-circle",
                      text: toWSMoneyString(selector1099NECTotal(taxForm))
                    }
            },
            {
              label: "Filing status",
              onClick:
                taxForm.status !== TaxFormStatus.Pending && isEditable
                  ? async () => {
                      if (taxForm.recipientSyncEnabled) {
                        const response = await beforeEditModal.open();
                        if (!response) {
                          return;
                        }
                      }

                      await updateStatusModal.open({
                        taxForm
                      });
                    }
                  : undefined,
              pill: getFillingStatusBadgeProps(taxForm.status)
            }
          ];
          // remove "Recipient confirmation" pill and "Sync" pill if not editable
          if (isEditable) {
            statusItems.push({
              label: "Recipient confirmation",
              pill: {
                icon: selectorPayerTaxFormIsEventExist(
                  taxForm,
                  TaxFormEventType.RecipientSharedW9Info
                )
                  ? "check-double"
                  : undefined,
                text: selectorPayerTaxFormRecipientConfirmationStatusText(
                  taxForm
                )
              }
            });
            statusItems.push({
              label: "Sync",
              pill: {
                icon: "instant-circle",
                text: taxForm.recipientSyncEnabled ? "Automatic" : "Disabled"
              }
            });
          }

          const openRevertModal = async () => {
            const startReverting = await reviewRedactedData.open({
              taxForm
            });

            if (startReverting) {
              await revertChanges.open({
                taxForm
              });
            }
          };

          return (
            <>
              <Header mb="2XL" pill={organizationName} taxForm={taxForm} />

              {queryFeatureFlags.data?.nec1099AddEditRecipient ? null : (
                <WSMessageBox.Info
                  mb="2XL"
                  noBorder
                  title="Editing 1099 drafts will be available soon"
                >
                  Starting Jan 8th, you'll be able to edit recipient profiles.
                  This feature is currently unavailable as we are confirming tax
                  information with recipients.
                </WSMessageBox.Info>
              )}

              <Statuses items={statusItems} mb="2XL" />

              {taxForm.status === TaxFormStatus.Excluded &&
              taxForm.exclusionReason ? (
                <WSMessageBox.Info mb="2XL" noBorder icon="alert-circle">
                  <WSText weight="medium" mb="XS">
                    {EXCLUSION_REASONS[taxForm.exclusionReason].title}
                  </WSText>
                  {EXCLUSION_REASONS[taxForm.exclusionReason].getMessage(
                    taxForm
                  )}
                </WSMessageBox.Info>
              ) : null}
              {taxForm.recipientSyncEnabled === false ? (
                <WSMessageBox.Info mb="2XL" noBorder icon="block">
                  <WSText weight="medium" mb="XS">
                    Automatic updates are disabled
                  </WSText>
                  Automatic updates are disabled for this 1099 because of manual
                  edits. You can always revert edits to recalculate status,
                  amount and recipient details.
                  {isEditable && (
                    <WSButton.Link
                      size="S"
                      mt="M"
                      onClick={openRevertModal}
                      rightIcon="chevron-right"
                    >
                      Revert
                    </WSButton.Link>
                  )}
                </WSMessageBox.Info>
              ) : null}

              {selectorPayerTaxFormIsLatestAddressCorrection(taxForm) ? (
                <WSMessageBox.Info mb="L" noBorder icon="info-circle">
                  <WSText.Heading4 mb="XS">
                    Information updated by recipient
                  </WSText.Heading4>
                  The recipient updated their address. Since their State did not
                  change, no correction needs to be filed with the IRS. If the
                  recipient contacts you to re-mail their tax forms, their
                  updated address will be used.
                </WSMessageBox.Info>
              ) : null}

              {!!taxForm.pendingCorrection ? (
                <WSMessageBox.Warning
                  mb="L"
                  noBorder
                  icon="alert-circle"
                  title="Correction requested by recipient"
                >
                  The recipient has requested corrections to their Form
                  1099-NEC. Review the request, verify the corrections, and
                  determine whether to accept the changes and submit to the IRS
                  or reject the changes.
                  <WSButton.Link
                    size="S"
                    mt="S"
                    onClick={() => {
                      if (taxForm.status === TaxFormStatus.AcceptedByIrs) {
                        history.push(
                          `/member/1099-filing/details/${taxFormId}/review-correction-request`
                        );
                      } else {
                        history.push(
                          `/member/1099-filing/details/${taxFormId}/view-correction`
                        );
                      }
                    }}
                    rightIcon="chevron-right"
                  >
                    Review correction request
                  </WSButton.Link>
                </WSMessageBox.Warning>
              ) : null}

              <WSGrid gutter="2XL">
                <WSGrid.Item span={{ s: "6" }}>
                  <WSTabs
                    mb="2XL"
                    tabs={[
                      {
                        label: "Summary",
                        render: () => (
                          <RecipientDetails
                            taxForm={taxForm}
                            onEdit={
                              queryFeatureFlags.data?.nec1099AddEditRecipient &&
                              (hasAdminScope || hasCollaboratorsScope) &&
                              isEditable
                                ? async () => {
                                    if (taxForm.recipientSyncEnabled) {
                                      const response = await beforeEditModal.open();
                                      if (!response) {
                                        return;
                                      }
                                    }
                                    history.push(
                                      `/member/1099-filing/details/${taxFormId}/edit`
                                    );
                                  }
                                : undefined
                            }
                            onRevert={openRevertModal}
                          />
                        )
                      },
                      ...(queryFeatureFlags.data?.nec1099PaymentsUpload &&
                      taxForm.status !== TaxFormStatus.Pending
                        ? [
                            {
                              label: "Payments",
                              render: () => <Payables taxForm={taxForm} />
                            }
                          ]
                        : [])
                    ]}
                  />
                </WSGrid.Item>

                <WSGrid.Item span={{ m: "6" }}>
                  <HistorySection taxForm={taxForm} />
                </WSGrid.Item>
              </WSGrid>
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
