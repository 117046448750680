import {
  useWSModal,
  WSButton,
  WSElement,
  WSFormOld,
  WSInputMaskOld,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { TinType } from "@wingspanhq/payments/dist/interfaces";
import {
  IPayeeTaxFormResponse,
  TaxFormViewer
} from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { TaxFormDocumentType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import React, { useState } from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useTaxFormDownloadDocument } from "../../../query/taxForm/mutations/useTaxFormDownloadDocument";
import { ErrorContextKey } from "../../../services/platform";
import { downloadFileFromBlob } from "../../../utils/files";
import { openInNewTab } from "../../../utils/openInNewTab";
import { selectorPayeeTaxFormPayeeDefaultTinType } from "../../1099NECFiling/selectors/selectorPayeeTaxFormPayeeDefaultTinType";

const DownloadTaxDocumentForm: React.FC<{
  taxForm: IPayeeTaxFormResponse;
  documentType?: TaxFormDocumentType;
  documentName: string;
  submissionId?: string;
  onClose(result: boolean): void;
}> = ({ onClose, taxForm, documentName, documentType, submissionId }) => {
  const tinType = selectorPayeeTaxFormPayeeDefaultTinType(taxForm);
  const isIndividual = tinType === TinType.Individual;
  const [downloadDocument, downloadDocumentMeta] = useTaxFormDownloadDocument();
  const typeAbbreviation = isIndividual ? "SSN" : "EIN";
  const [isTimedOut, setIsTimedOut] = useState(false);

  const doDownload = async (values: any) => {
    const downloadSubmissionId = submissionId ?? taxForm.currentSubmissionId;
    await downloadDocument(
      {
        taxFormId: taxForm.taxFormId,
        submissionId: downloadSubmissionId!,
        tinLast4: values.tin,
        viewer: TaxFormViewer.payee,
        documentType
      },
      {
        onSuccess(pdf) {
          downloadFileFromBlob(pdf, documentName + ".pdf");
          onClose?.(true);
        },
        async onError(err) {
          const data = (err.response?.data as any) as Blob;
          if (data) {
            const rawError = JSON.parse(await data.text());
            console.log(rawError);
          } else if (err.code === "ECONNABORTED") {
            setIsTimedOut(true);
          }
        }
      }
    );
  };

  const tryAgain = () => {
    setIsTimedOut(false);
  };

  return isTimedOut ? (
    <WSElement>
      <WSMessageBox.Warning mb="XL" icon="alarm" noBorder>
        <WSText weight="medium" mb="M" color="amber400">
          There was an issue downloading the tax form
        </WSText>
        <WSText.ParagraphSm color="gray400" mb="M">
          We ran into an issue while downloading your tax form. Please try again
          and if the issue continues, reach out to support and we'll get this
          fixed.
        </WSText.ParagraphSm>
      </WSMessageBox.Warning>
      <WSButton fullWidth onClick={tryAgain}>
        Try again
      </WSButton>
    </WSElement>
  ) : (
    <WSElement>
      <WSText color="gray600" mb="XL">
        For your security, verify the last four (4) digits of your tax
        identification number to download the document.
      </WSText>
      <WSFormOld
        defaultValues={{
          tin: ""
        }}
        validationSchema={Yup.object().shape({
          tin: Yup.string()
            .length(4, "Must be 4 digits")
            .required("Required")
        })}
        onSubmit={doDownload}
      >
        <WSText weight="medium" mb="M">
          What are the last 4 digits of your {typeAbbreviation}?
        </WSText>
        <WSFormOld.Field
          mb="XL"
          name="tin"
          component={WSInputMaskOld}
          componentProps={{
            placeholder: "____",
            mask: "****",
            unmask: true,
            fsExclude: true
          }}
        />
        <WSErrorMessage
          mb="XL"
          error={
            downloadDocumentMeta.error
              ? "There is a mismatch with our records. Please try again."
              : undefined
          }
          contextKey={ErrorContextKey.NEC1099TaxDocuments}
        />
        <WSFormOld.SubmitButton
          loading={downloadDocumentMeta.isLoading}
          mb="M"
          fullWidth
        >
          Continue
        </WSFormOld.SubmitButton>
        {downloadDocumentMeta.isLoading && (
          <WSText color="gray400">
            Downloading... this could take a minute
          </WSText>
        )}
        {downloadDocumentMeta.error && (
          <WSMessageBox.Warning icon="alarm" noBorder>
            <WSText weight="medium" mb="M" color="amber400">
              Mismatch on the last 4 digits of your {typeAbbreviation}
            </WSText>
            <WSText.ParagraphSm color="gray400" mb="XS">
              {`1. Ensure only the last four (4) digits of your ${typeAbbreviation} is entered`}
            </WSText.ParagraphSm>
            <WSText.ParagraphSm color="gray400" mb="M">
              2. Contact the payer to confirm the {typeAbbreviation} they have
              on file
            </WSText.ParagraphSm>
            <WSButton.Link
              rightIcon="chevron-right"
              onClick={() =>
                openInNewTab(
                  "https://help.wingspan.app/en/articles/8868896-downloading-the-1099-nec-tax-form"
                )
              }
            >
              Learn more
            </WSButton.Link>
          </WSMessageBox.Warning>
        )}
      </WSFormOld>
    </WSElement>
  );
};

export const useDownloadTaxDocument = () =>
  useWSModal(DownloadTaxDocumentForm, {
    title: "Download Form 1099-NEC",
    size: "S"
  });
