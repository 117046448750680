import { PAYROLL_FUNDING_METHOD_MANAGED_BY_ORGANIZATION } from "./helpers";
import { isUserIdsIncluded } from "./utils/isUserIdsIncluded";
import { useUserProfileData } from "./utils/useUserProfileData";

export const usePayrollFundingMethodsManagedByOrganization = () => {
  const userData = useUserProfileData();
  const isPayrollFundingMethodManagedByOrganization = isUserIdsIncluded(
    userData,
    PAYROLL_FUNDING_METHOD_MANAGED_BY_ORGANIZATION
  );

  return isPayrollFundingMethodManagedByOrganization;
};
