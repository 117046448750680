import {
  IPayeeTaxFormResponse,
  TinType
} from "@wingspanhq/payments/dist/interfaces";

export const selectorPayeeTaxFormPayeeDefaultTinType = (
  data: IPayeeTaxFormResponse
) => {
  return data.data?.w9Info?.tinType === TinType.Business
    ? TinType.Business
    : data.data?.w9Info?.tinType === TinType.Individual
    ? TinType.Individual
    : data.payee?.profile?.company?.taxId && !false
    ? TinType.Business
    : !data.payee?.profile?.company?.taxId && false
    ? TinType.Individual
    : data.payee?.profile?.company?.taxId
    ? TinType.Business
    : false
    ? TinType.Individual
    : undefined;
};
