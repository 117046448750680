import classNames from "classnames";
import React from "react";

import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";

import styles from "./index.module.scss";

export type WSNavHistoryListItem = {
  key: string | number;
  title: React.ReactNode;
};

export interface WSNavHistoryListProps extends WSElementProps {
  items: WSNavHistoryListItem[];
  active: WSNavHistoryListItem["key"];
}

export const WSNavHistoryList: React.FC<WSNavHistoryListProps> = ({
  items,
  active
}) => (
  <WSElement className={styles.list}>
    {items.map(({ key, title }) => (
      <WSElement
        key={key}
        className={classNames(styles.listItem, {
          [styles.active]: active === key
        })}
      >
        {title}
      </WSElement>
    ))}
  </WSElement>
);
