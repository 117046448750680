import {
  WSElementProps,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { VerificationStatus } from "@wingspanhq/payments/dist/interfaces";

type Props = {
  verificationStatus?: VerificationStatus;
} & WSElementProps;

export const BannerTinStatus: React.FC<Props> = ({
  verificationStatus,
  ...elementProps
}) =>
  verificationStatus === VerificationStatus.Verified ? (
    <WSMessageBox.Success icon="check" title="IRS Verified" {...elementProps} />
  ) : verificationStatus === VerificationStatus.Pending ? (
    <WSMessageBox.Warning
      icon="time"
      title="Pending Verification"
      {...elementProps}
    >
      <WSText.ParagraphSm color="gray500">
        We're still waiting to hear back from the IRS. You may receive an email
        from us requesting more information
      </WSText.ParagraphSm>
    </WSMessageBox.Warning>
  ) : verificationStatus === VerificationStatus.Failed ? (
    <WSMessageBox.Error title="Verification Failed" {...elementProps}>
      <WSText.ParagraphSm color="gray500">
        TIN could not be verified with the IRS.
      </WSText.ParagraphSm>
    </WSMessageBox.Error>
  ) : null;
