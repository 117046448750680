import {
  WSButtons,
  WSButton,
  WSText,
  useWSModal,
  WSElementProps,
  detachLayoutProps,
  WSFormOld
} from "@wingspanhq/fe-component-library";

export interface CorrectionFormActionsProps extends WSElementProps {
  onCancel: () => void;
}

export const CorrectionFormActions: React.FC<CorrectionFormActionsProps> = ({
  onCancel,
  ...other
}) => {
  const { layoutProps } = detachLayoutProps(other);
  const cancelCorrectionModal = useCancelCorrectionModal();
  const confirmCancel = () => {
    cancelCorrectionModal.open({
      onCancel
    });
  };
  return (
    <>
      <WSButtons forceFullWidth {...layoutProps}>
        <WSFormOld.SubmitButton>Continue</WSFormOld.SubmitButton>
        <WSButton.Tertiary type="button" onClick={confirmCancel}>
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </>
  );
};

interface CancelCorrectionModalProps {
  onClose(): void;
  onCancel: () => void;
}

const CancelCorrectionModal: React.FC<CancelCorrectionModalProps> = ({
  onCancel,
  onClose
}) => {
  const handleCancel = () => {
    onCancel();
  };
  return (
    <>
      <WSText.ParagraphSm mb="XL">
        None of the information will be saved if you choose to cancel.
      </WSText.ParagraphSm>
      <WSButtons forceFullWidth>
        <WSButton destructive onClick={handleCancel}>
          Yes, cancel
        </WSButton>
        <WSButton.Tertiary onClick={onClose}>No, continue</WSButton.Tertiary>
      </WSButtons>
    </>
  );
};

export function useCancelCorrectionModal() {
  return useWSModal(CancelCorrectionModal, {
    title: "Are you sure you want to cancel?",
    size: "S"
  });
}
