import { WSFlexBox, WSLoader, WSText } from "@wingspanhq/fe-component-library";
import queryString from "query-string";
import React, { useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { paymentsService } from "../services/payments";
import { usersService } from "../services/users";

type RouteProps = RouteComponentProps;

type Props = RouteProps;

export const CreateTestCollaborator: React.FC<Props> = () => {
  const history = useHistory();

  // Allowed params:
  // c - count of collaborators to create
  const queries = queryString.parse(window.location.search);
  const [done, setDone] = React.useState(false);

  const run = async () => {
    try {
      const session = await usersService.session.get();

      if (session.userId) {
        const clientSuffix = new Date().valueOf();
        const count = queries.c ? parseInt(queries.c as string) : 1;

        for (let i = 0; i < count; i++) {
          const randomChars = (Math.random() + 1).toString(36).substring(7);

          const collaborator = await paymentsService.collaborator.create({
            clientId: session.userId,
            memberCompany: `Test company ${randomChars}`,
            memberEmail: `test__fe_e2e__${i}_${randomChars}@wingspan.test`,
            memberName: `Test collaborator ${randomChars}`
          });
        }
        setDone(true);
      } else {
        history.push("/member/sign-in");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    run();
  }, []);

  return (
    <WSFlexBox.Center py="5XL">
      {done ? (
        <WSText data-testid="successResult">Done!</WSText>
      ) : (
        <WSLoader.Spinner />
      )}
    </WSFlexBox.Center>
  );
};

// paymentsService.payable.create
