import React from "react";
import { WSScreen, WSContainer } from "../../layout";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import styles from "./WSFullscreenLayout.module.scss";

export interface WSFullscreenLayoutProps extends Omit<WSElementProps, "title"> {
  left: React.ReactNode;
  right: React.ReactNode;
}

export const WSFullscreenLayout: React.FC<WSFullscreenLayoutProps> = ({
  right,
  left,
  children
}) => (
  <WSElement className={styles.container}>
    <WSContainer className={styles.header}>
      {left}
      {right}
    </WSContainer>
    <WSScreen.TabletAndDesktop>
      <WSElement className={styles.line} />
    </WSScreen.TabletAndDesktop>
    <WSContainer className={styles.body} verticalPadding>
      {children}
    </WSContainer>
  </WSElement>
);
