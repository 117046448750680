import { WSList, WSText } from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  useMemberProfile,
  useUserProfile
} from "../../../../query/users/queries";
import { verificationService } from "../../../../shared/services/verificaiton";
import { getProfileDataPerson } from "../../components/FormPartialPerson/getProfileDataPerson";
import { FormPersonalInformation } from "../../components/FormPersonalInformation";
import { useOnboardignModules, useOnboardingContext } from "../../Context";
import { useMissingFields } from "../../hooks/useMissingFields";
import { OnboardingModuleCreateAccount, OnboardingStep } from "../../types";
import { CreateAccountContext } from "./types";

export const PersonalInformation: React.FC<OnboardingStep> = ({
  onNext,
  onBack
}) => {
  const [context, setContext] = useOnboardingContext<CreateAccountContext>();

  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const profileData = useMemo(
    () => ({
      person: getProfileDataPerson(queryUser.data, queryMember.data)
    }),
    [queryMember.data, queryUser.data]
  );

  const modules = useOnboardignModules();
  const onboardingModule = modules.find(m => m.type === "create_account") as
    | OnboardingModuleCreateAccount
    | undefined;
  const verificationLevel =
    onboardingModule?.options?.verificationLevel || "Tax";

  const missingFields = useMissingFields(verificationLevel);
  const isMismatch = useMemo(
    () => verificationService.getIsIndividualMismatch(missingFields),
    [missingFields]
  );

  return (
    <WSList gap="2XL">
      <FormPersonalInformation
        defaultValues={context.personalInformation || profileData}
        onBack={onBack}
        onSubmit={data => {
          setContext(prev => ({ ...prev, personalInformation: data }));
          onNext?.();
        }}
        mismatch={isMismatch}
        country={context.accountType?.country || "US"}
      />

      <WSText.ParagraphXs color="gray400">
        The information on this page is required to setup your Wingspan account,
        if you exit or leave without completing this step, you will return to
        this page the next time you sign-in
      </WSText.ParagraphXs>
    </WSList>
  );
};
