import { IInvitationResponse } from "@wingspanhq/users";

import { serviceInvitations } from "./serviceInvitations";

export const getInvitation = async (token: string) => {
  const { data } = await serviceInvitations.get<IInvitationResponse>(
    `/${token}`
  );

  return data;
};
