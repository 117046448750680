import { ICheckbookCard } from "@wingspanhq/payments/dist/interfaces";
import { debitCardBrandNameMap } from "../constants/bankCard";

export const selectorDebitCardFullName = (debitCard?: ICheckbookCard) => {
  if (debitCard) {
    return `${debitCardBrandNameMap[debitCard.brand]} Debit Card (${
      debitCard.last4
    })`;
  }
  return "Debit Card";
};
