import {
  useWSModal,
  useWSSnackbar,
  WSActions,
  WSElement,
  WSFormOld,
  WSTextArea,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { useCreateCollaboratorsGroup } from "../../../query/payments/mutations";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useHistory } from "react-router-dom";
import { buildGroupDetailsPath } from "../utils";

type Props = {
  onClose: () => void;
};

const AddCollaboratorGroupModal: React.FC<Props> = ({ onClose }) => {
  const history = useHistory();
  const [createGroup, createGroupMeta] = useCreateCollaboratorsGroup();
  const { openSnackbar } = useWSSnackbar();

  return (
    <WSElement>
      <WSFormOld
        defaultValues={{
          name: "",
          description: ""
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Name is required"),
          description: Yup.string().required("Description is required")
        })}
        onSubmit={async formData => {
          await createGroup(
            {
              name: formData.name,
              description: formData.description
            },
            {
              onSuccess(group) {
                onClose();
                openSnackbar({
                  duration: 5000,
                  type: "success",
                  message: "Group successfully added"
                });
                history.push(buildGroupDetailsPath(group.collaboratorGroupId));
              }
            }
          );
        }}
      >
        <WSFormOld.Field
          mb="XL"
          label="Name"
          name="name"
          component={WSTextInput}
        />

        <WSFormOld.Field
          mb="XL"
          label="Description"
          name="description"
          component={WSTextArea}
        />

        <WSErrorMessage
          mt="XL"
          error={createGroupMeta.error}
          contextKey="CreateCollaboratorsGroup"
        />

        <WSActions
          mt="3XL"
          alignment="fill"
          buttons={[
            {
              label: "Add group",
              kind: "Primary",
              name: "submit",
              type: "submit",
              loading: createGroupMeta.isLoading
            },
            {
              label: "Cancel",
              kind: "Secondary",
              type: "button",
              onClick() {
                onClose();
              }
            }
          ]}
        />
      </WSFormOld>
    </WSElement>
  );
};

export const useAddCollaboratorGroupModal = () =>
  useWSModal(AddCollaboratorGroupModal, {
    title: "Add group",
    size: "S"
  });
