import { WSGrid } from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { PaymentMethodsList } from "../../../Settings/components/PaymentMethodsList/PaymentMethodsList";
import { PaymentHistory } from "../../components/PaymentHistory";

export const History: React.FC<RouteComponentProps> = () => {
  return (
    <WSGrid gutter={{ m: "3XL" }}>
      <WSGrid.Item span={{ m: "7" }}>
        <PaymentHistory />
      </WSGrid.Item>
      <WSGrid.Item span={{ m: "5" }}>
        <PaymentMethodsList />
      </WSGrid.Item>
    </WSGrid>
  );
};
