import { ICreditCard } from "@wingspanhq/users/dist/lib/interfaces";
import { creditCardBrandNameMap } from "../constants/bankCard";

export const selectorCreditCardName = (creditCard?: ICreditCard) => {
  const name =
    creditCardBrandNameMap[creditCard?.cardBrand || ""] || "Credit Card";

  if (!creditCard?.last4) {
    return name;
  }

  return `${name} (${creditCard.last4})`;
};
