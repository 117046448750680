import {
  toWSDateString,
  WSElementColorsType,
  WSPillProps
} from "@wingspanhq/fe-component-library";
import {
  ClientWorkFlowStatus,
  InvoiceStatus,
  IPayableSchema,
  IPayrollSettings
} from "@wingspanhq/payments/dist/interfaces";
import {
  getIsPayableDisputed,
  getIsPayableResubmitted
} from "../../../query/payments/selectors";
import { getNextPayrollDate } from "../../utils/payables";

type StatusProjection =
  | "Draft"
  | "ActionRequired"
  | "AwaitingContractor"
  | "Scheduled"
  | "Paid"
  | "Cancelled"
  | "Unknown";

const mapStatusProjection: Record<StatusProjection, WSPillProps> = {
  ActionRequired: {
    theme: "warning",
    icon: true,
    text: "Action required"
  },
  AwaitingContractor: {
    theme: "neutral",
    icon: "time",
    text: "Awaiting contractor"
  },
  Scheduled: {
    theme: "blue",
    icon: "calendar",
    text: "Scheduled"
  },
  Paid: {
    theme: "success",
    icon: true,
    text: "Paid"
  },
  Cancelled: {
    theme: "neutral",
    icon: "draft",
    text: "Cancelled"
  },
  Draft: {
    theme: "neutral",
    icon: "draft",
    text: "Draft"
  },
  Unknown: {
    theme: "neutral",
    icon: "draft",
    text: "Unknown"
  }
};

export const getStatusColumn = (
  payable: IPayableSchema,
  payrollSettings: IPayrollSettings
): {
  secondaryText?: string;
  secondaryTextColor?: WSElementColorsType;
  pill: WSPillProps;
} => {
  if (payable.status === InvoiceStatus.Draft) {
    return {
      pill: mapStatusProjection.Draft,
      secondaryText: `Created • ${toWSDateString(
        payable.createdAt,
        "monthDate"
      )}`
    };
  }

  if (payable.status === InvoiceStatus.Cancelled) {
    return {
      pill: mapStatusProjection.Cancelled
    };
  }

  if (payable.status === InvoiceStatus.Paid) {
    return {
      pill: mapStatusProjection.Paid,
      secondaryText: `Payout cleared • ${toWSDateString(
        payable.events.paidAt,
        "monthDate"
      )}`
    };
  }

  if (payable.status === InvoiceStatus.PaymentInTransit) {
    return {
      pill: mapStatusProjection.Paid,
      secondaryText: "Payment in route"
    };
  }

  if (getIsPayableDisputed(payable)) {
    return {
      pill: mapStatusProjection.ActionRequired,
      secondaryText: "Disputed by contractor"
    };
  }

  if (getIsPayableResubmitted(payable)) {
    return {
      pill: mapStatusProjection.ActionRequired,
      secondaryText: `Contractor resubmitted • ${toWSDateString(
        payable.events.memberResubmittedAt,
        "monthDate"
      )}`
    };
  }

  if (
    payable.status === InvoiceStatus.Open ||
    payable.status === InvoiceStatus.Overdue
  ) {
    if (
      payable.client.workflowStatus === ClientWorkFlowStatus.Approved ||
      payable.client.workflowStatus === ClientWorkFlowStatus.PaymentInitiated
    ) {
      return {
        pill: mapStatusProjection.Scheduled,
        secondaryText: `Estimated • ${toWSDateString(
          getNextPayrollDate(payrollSettings),
          "monthDate"
        )}`
      };
    }

    return {
      pill: mapStatusProjection.ActionRequired,
      secondaryText: "Approval by you",
      secondaryTextColor: "amber400"
    };
  }

  if (payable.status === InvoiceStatus.Pending) {
    if (
      payable.client.workflowStatus === ClientWorkFlowStatus.PaymentInitiated ||
      payable.client.workflowStatus === ClientWorkFlowStatus.Funded
    ) {
      return {
        pill: mapStatusProjection.Paid,
        secondaryText: "Funded for payment"
      };
    }

    if (payable.client.workflowStatus === ClientWorkFlowStatus.Approved) {
      return {
        pill: mapStatusProjection.Scheduled,
        secondaryText: `To be funded • ${toWSDateString(
          getNextPayrollDate(payrollSettings),
          "monthDate"
        )}`
      };
    }

    return {
      pill: mapStatusProjection.AwaitingContractor,
      secondaryText: "Eligibility pending"
    };
  }

  return {
    pill: mapStatusProjection.Unknown
  };
};
