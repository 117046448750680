import {
  IBulkBatchCreate,
  IBulkBatchUpdate,
  IBulkPayableItem
} from "@wingspanhq/payments/dist/interfaces";
import {
  IBulkCalculation1099ItemCreate,
  IBulkCalculation1099ItemUpdate
} from "@wingspanhq/payments/dist/interfaces/api/bulkCalculation1099";
import {
  IBulkCalculation1099Batch,
  IBulkCalculation1099Item
} from "@wingspanhq/payments/dist/interfaces/bulkCalculation1099";
import { WSService } from "../../../utils/WSService";
import {
  BulkBatchItemsFilter,
  BulkCalculate1099BatchesFilters,
  IBulkCalculate1099BatchSummary
} from "./types";
import { ListRequestQuery } from "../../../utils/serviceHelper";

const service = new WSService("/payments");

const createBulkCalculate1099Batch = async (
  data: IBulkBatchCreate
): Promise<IBulkCalculation1099Batch> => {
  const response = await service.post(`/bulk/calculation1099/batch`, data);
  return response.data;
};

const updateBulkCalculate1099Batch = async (
  id: string,
  data: IBulkBatchUpdate
): Promise<IBulkCalculation1099Batch> => {
  const response = await service.patch(
    `/bulk/calculation1099/batch/${id}`,
    data
  );
  return response.data;
};

const getBulkCalculate1099Batch = async (
  id: string
): Promise<IBulkCalculation1099Batch> => {
  const response = await service.get(`/bulk/calculation1099/batch/${id}`);
  return response.data;
};

const getBulkCalculate1099Batches = async <
  IBatchResponse = IBulkCalculation1099Batch
>(
  query?: ListRequestQuery<
    BulkCalculate1099BatchesFilters,
    {
      createdAt?: "asc" | "desc";
    }
  >
): Promise<IBatchResponse[]> => {
  const response = await service.get(`/bulk/calculation1099/batch`, {
    params: query
  });
  return response.data;
};

// bulk calc batch item
const createBulkCalculate1099BatchItem = async (
  bulkCalculation1099BatchId: string,
  data: IBulkCalculation1099ItemCreate
): Promise<IBulkCalculation1099Item> => {
  const response = await service.post(
    `/bulk/calculation1099/batch/${bulkCalculation1099BatchId}/item`,
    data
  );
  return response.data;
};

const updateBulkCalculate1099BatchItem = async (
  bulkCalculation1099BatchId: string,
  bulkCalculation1099BatchItemId: string,
  data: IBulkCalculation1099ItemUpdate
): Promise<IBulkPayableItem> => {
  const response = await service.patch(
    `/bulk/calculation1099/batch/${bulkCalculation1099BatchId}/item/${bulkCalculation1099BatchItemId}`,
    data
  );
  return response.data;
};

const getBulkCalculate1099BatchSummary = async (
  batchId: string,
  query?: ListRequestQuery<
    BulkBatchItemsFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >
): Promise<IBulkCalculate1099BatchSummary> => {
  const { headers, data } = await service.get(
    `/bulk/calculation1099/batch/${batchId}/item`,
    {
      params: { ...query, page: { size: 1 } }
    }
  );
  const list = data as IBulkCalculation1099Item[];
  const item = list?.[0];
  const metadata = item?.metadata;

  return {
    listSize: Number(headers["x-ws-list-size"]),
    totalTaxForms: metadata?.totalTaxForms || 0,
    processedTaxForms: metadata?.processedTaxForms || 0,
    failedTaxForms: metadata?.failedTaxForms || 0,
    status: item?.status
  };
};

const getBulkCalculate1099BatchItems = async (
  bulkCalculation1099BatchId: string
): Promise<IBulkPayableItem[]> => {
  const response = await service.get(
    `/bulk/calculation1099/batch/${bulkCalculation1099BatchId}/item`
  );
  return response.data;
};

const getBulkCalculate1099BatchItem = async (
  bulkCalculation1099BatchId: string,
  bulkCalculation1099BatchItemId: string
): Promise<IBulkPayableItem> => {
  const response = await service.get(
    `/bulk/calculation1099/batch/${bulkCalculation1099BatchId}/item/${bulkCalculation1099BatchItemId}`
  );
  return response.data;
};

export const bulkCalculation1099Service = {
  batch: {
    create: createBulkCalculate1099Batch,
    get: getBulkCalculate1099Batch,
    update: updateBulkCalculate1099Batch,
    summary: getBulkCalculate1099BatchSummary,
    list: getBulkCalculate1099Batches
  },
  batchItem: {
    create: createBulkCalculate1099BatchItem,
    get: getBulkCalculate1099BatchItem,
    list: getBulkCalculate1099BatchItems,
    update: updateBulkCalculate1099BatchItem
  }
};
