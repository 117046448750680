import {
  WSButton,
  WSElement,
  WSText,
  useModalContext
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { WSQueries } from "../../../query/WSQuery";
import { useUserId } from "../../../query/hooks/helpers";
import { useMemberProfile } from "../../../query/users/queries";
import { useLinkPayoutAccount } from "../../hooks/useLinkPayoutAccount";
import { useModalFormManuallyAddAccount } from "../../hooks/useModalFormManuallyAddAccount";
import { selectorMemberCountryName } from "../../selectors/selectorMemberCountryName";
import {
  MODAL_MANUALLY_ADD_INTERNATIONAL_ACCOUNT,
  ModalManuallyAddInternationalAccount
} from "../ModalManuallyAddInternationalAccount";

type Props = {
  onSuccess: () => void;
};

export const FormInternationalPayoutSettings: React.FC<Props> = ({
  onSuccess
}) => {
  const { openModal } = useModalContext();
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);
  const { open: openManuallAddAccountModal } = useModalFormManuallyAddAccount();
  const { linkPayoutAccount, createAccountMeta } = useLinkPayoutAccount({
    onSuccess
  });

  return (
    <WSElement>
      <WSText.Heading4 mb="XS">Direct deposit</WSText.Heading4>
      <WSText mb="XL">
        We saved the best for last: tell us where to deposit your funds.
      </WSText>

      <WSQueries queries={{ queryMember }}>
        {({ queryMember: { data: member } }) => (
          <>
            <WSText.Heading5 mb="XS">
              My bank account is in {selectorMemberCountryName(member)}
            </WSText.Heading5>

            <WSText mb="XL">
              Wingspan will convert all US dollar deposits into your local
              currency and deposit it to your bank account.
            </WSText>

            <WSButton
              mb="2XL"
              fullWidth
              name="enterLocalAccountInfo"
              onClick={() => {
                openModal(MODAL_MANUALLY_ADD_INTERNATIONAL_ACCOUNT, {
                  onSuccess
                });
              }}
            >
              Enter local account info
            </WSButton>

            <WSText.Heading5 mb="XS">
              I'm using a US based account
            </WSText.Heading5>

            <WSText mb="XL">
              Wingspan uses Plaid to verify your bank account.
            </WSText>
            <WSText mb="XL">
              Plaid partners with thousands of financial institutions and is
              trusted by companies like Venmo, Betterment, and Alto Pharmacy.
            </WSText>

            <WSErrorMessage
              mb="XL"
              contextKey="PaymentsSetup"
              error={createAccountMeta.error}
            />

            <WSButton.Secondary
              onClick={linkPayoutAccount}
              fullWidth
              mb="XL"
              name="connectPlaid"
            >
              Link account using Plaid
            </WSButton.Secondary>
            <WSButton.Link
              name="connectManually"
              fullWidth
              onClick={() => {
                openManuallAddAccountModal({
                  onSuccess,
                  setStandardPayoutDestination: true
                });
              }}
            >
              Manually enter account info
            </WSButton.Link>
          </>
        )}
      </WSQueries>

      <ModalManuallyAddInternationalAccount />
    </WSElement>
  );
};
