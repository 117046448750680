import React from "react";
import { WSControl, WSControlProps } from "../WSControl/WSControl";
import { WSElement, WSElementProps } from "../WSElement/WSElement.component";
import { WSText } from "../core/WSText/WSText.component";
import styles from "./WSFilters.module.scss";
import { WSFilterOption, WSFilterType } from "./types";

export const OptionItem: React.FC<
  Omit<WSElementProps, "onChange"> &
    WSFilterOption & {
      type: WSFilterType;
      onChange: (value: WSControlProps["value"]) => void;
      value: WSControlProps["value"];
    }
> = ({
  name,
  type,
  value,
  helperText,
  label,
  options,
  onChange,
  disabled,
  ...otherProps
}) => {
  let mainInput = null;

  switch (type) {
    case "radio":
    case "checkbox":
      mainInput = (
        <WSControl
          size="S"
          type={type === "checkbox" ? "multistep-checkbox" : type}
          name={name}
          label={label}
          value={value as any}
          onChange={onChange}
          disabled={disabled}
        />
      );
      break;
    default:
      return null;
  }

  return (
    <WSElement {...otherProps} className={styles.optionItem}>
      {mainInput}
      {helperText ? (
        <WSText.ParagraphXs
          className={styles[`optionHelpText-${type}`]}
          color={disabled ? "gray300" : "gray400"}
        >
          {helperText}
        </WSText.ParagraphXs>
      ) : null}
    </WSElement>
  );
};
