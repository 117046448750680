const primaryButton = {
  backgroundColor: "#057ccc",
  color: "#fff",
  border: "1px solid #057ccc",
  padding: "6px 24px",
  fontSize: "15px",
  fontWeight: "500",
  borderRadius: "2px",
  boxShadow: "none",
  ":hover": {
    backgroundColor: "#046db3",
    border: "1px solid #046db3"
  }
};

const secondaryButton = {
  backgroundColor: "transparent",
  color: "#057ccc",
  border: "1px solid #057ccc",
  padding: "6px 24px",
  fontSize: "15px",
  fontWeight: "500",
  borderRadius: "2px",
  boxShadow: "none",
  ":hover": {
    backgroundColor: "#057ccc",
    border: "1px solid #057ccc",
    color: "#fff"
  }
};

const tertiaryButton = {
  backgroundColor: "transparent",
  color: "#6b727f",
  border: "1px solid #6b727f",
  padding: "6px 24px",
  fontSize: "15px",
  fontWeight: "500",
  borderRadius: "2px",
  boxShadow: "none",
  ":hover": {
    backgroundColor: "transparent",
    border: "1px solid #4e535e",
    color: "#4e535e"
  }
};

export const flatfileTheme: any = {
  theme: {
    global: {
      fontFamily: "Centra No2",
      backgroundColor: "#ffffff",
      textColor: "#282d37",
      primaryTextColor: "#282d37",
      secondaryTextColor: "#4e535e",
      successColor: "#2f9b6b",
      warningColor: "#c70c00"
    },
    buttons: {
      primary: primaryButton,
      secondary: secondaryButton,
      tertiary: tertiaryButton,
      success: primaryButton,
      headerMatchYes: primaryButton,
      headerMatchNo: secondaryButton
    },
    dropzone: {
      content: {
        backgroundColor: "#f3f5f9"
      }
    },
    manualInput: {
      table: {
        th: {
          backgroundColor: "#f3f5f9",
          color: "#282d37",
          borderColor: "#e8eaef"
        },
        td: {
          color: "#282d37"
        }
      }
    }
  },
  integrations: {
    fonts: [
      {
        family: "Centra No2",
        style: "normal",
        weight: "normal",
        src: [
          {
            url: require("../assets/fonts/CentraNo2-Book.woff"),
            format: "woff"
          },
          {
            url: require("../assets/fonts/CentraNo2-Book.woff2"),
            format: "woff2"
          },
          {
            url: require("../assets/fonts/CentraNo2-Book.eot"),
            format: "eot"
          }
        ]
      },
      {
        family: "Centra No2",
        style: "normal",
        weight: 500,
        src: [
          {
            url: require("../assets/fonts/CentraNo2-Medium.woff"),
            format: "woff"
          },
          {
            url: require("../assets/fonts/CentraNo2-Medium.woff2"),
            format: "woff2"
          },
          {
            url: require("../assets/fonts/CentraNo2-Medium.eot"),
            format: "eot"
          }
        ]
      }
    ]
  }
};
