import { WSLayout } from "@wingspanhq/fe-component-library";
import React from "react";
import { LogoApp } from "../../shared/components/LogoApp";
import { useWSStore } from "../../store";

export const ProductActivationWrapper: React.FC = ({ children }) => {
  const store = useWSStore();

  return store.productActivationAfterOnboarding ? (
    <WSLayout headerCenter={<LogoApp />} line>
      {children}
    </WSLayout>
  ) : (
    <>{children}</>
  );
};
