import { InfiniteQueryConfig, QueryConfig } from "react-query";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSInfiniteQuery, useWSQuery } from "../../helpers";
import { QUERY_PAYMENTS_TAXFORM_LIST_PAYER } from "../keys";
import { paymentsService } from "../../../services/payments";

interface TaxFormsFilter {
  year: number;
  userId?: string;
  filter?: {
    recipientSyncEnabled?: boolean;
  };
}

export const usePayerTaxFormsListSize = (
  params: TaxFormsFilter,
  config?: QueryConfig<number, WSServiceError>
) => {
  const defaultParams = {
    page: {
      size: 1
    }
  };
  const queryParams = {
    ...defaultParams,
    ...params
  };
  const { year, userId, ...otherParams } = queryParams;
  return useWSQuery<number, WSServiceError>(
    [QUERY_PAYMENTS_TAXFORM_LIST_PAYER, [queryParams]],
    async () => {
      const { summary } = await paymentsService.taxForm.payerList(
        year,
        userId,
        {
          ...otherParams
        }
      );
      return summary.listSize;
    },
    config
  );
};

export const usePaginatedPayerTaxForms = (
  params: TaxFormsFilter,
  config?: InfiniteQueryConfig<IPayerTaxFormResponse[], WSServiceError>
) => {
  const { year, userId, ...filter } = params;

  return useWSInfiniteQuery<IPayerTaxFormResponse[], WSServiceError>(
    [QUERY_PAYMENTS_TAXFORM_LIST_PAYER, params],
    async (query, queryParams, pageNumber = 1) => {
      const { data } = await paymentsService.taxForm.payerList(year, userId, {
        filter,
        page: { size: 10, number: pageNumber }
      });

      return data;
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.length < 10) {
          return undefined;
        }

        return allPages.length + 1;
      },
      ...config
    }
  );
};
