import { CollaboratorPayablesSummaryDashboard } from "./routes/RouteCollaboratorPayablesReport/CollaboratorPayablesSummaryDashboard";

export type ReportsColumn<C extends any> = {
  label: string;
  value: string;
  preselected?: boolean;
  disabled?: boolean;
  group?: string;
  getTableCell(c: C): string | number | undefined;
  getReportCell?(c: C): string;
};

export enum ReportType {
  OpenLineItemAging = "Open Line Item Aging",
  OpenPayableAging = "Open Payable Aging",
  OpenReceivableAging = "Open Receivable Aging",
  PayrollLineItems = "Payroll Line Items",
  Collaborator = "Collaborator",
  CollaboratorPayablesSummary = "Collaborator Payables Summary"
}

export enum ReportFormat {
  CSV = "CSV",
  XLSX = "XLSX"
}
