import React from "react";
import {
  useWSModal,
  useWSSnackbar,
  WSActions,
  WSDropFileInput,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSInfoBox,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useSharedFileSettingsQuery } from "../../../../../query/sharedFile/query/useSharedFileSettingsQuery";
import { WSQueries } from "../../../../../query/WSQuery";
import { useUpdateSharedFile } from "../../../../../query/sharedFile/mutations/useUpdateSharedFile";
import { useMemberPrivateFileCreate } from "../../../../../query/files/mutations";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { mimeTypesToLabel } from "../createRequirementDefinition/utils";
import { getPayerEngagements } from "../../../../../services/payerEngagements";

type Props = {
  onSuccess: () => void;
  sharedFileRequestId: string;
  sharedFileRequestSettingsId: string;
  onClose: (result: boolean) => void;
  payerId: string | undefined;
  payeeId: string | undefined;
};

const UploadFileModal: React.FC<Props> = ({
  sharedFileRequestId,
  sharedFileRequestSettingsId,
  onSuccess,
  onClose,
  payerId,
  payeeId
}) => {
  const [file, setFile] = React.useState<File | null>(null);
  const { openSnackbar } = useWSSnackbar();

  const [uploadFile, uploadFileMeta] = useMemberPrivateFileCreate({
    hidden: true,
    viewerIds: [payeeId, payerId].filter(Boolean) as string[]
  });

  const querySharedFileSettings = useSharedFileSettingsQuery(
    sharedFileRequestSettingsId
  );

  const [updateSharedFile, updateSharedFileMeta] = useUpdateSharedFile();

  return (
    <WSQueries queries={{ querySharedFileSettings }}>
      {({ querySharedFileSettingsData }) => {
        const formatsList = mimeTypesToLabel(
          querySharedFileSettingsData?.mimeTypes || []
        );
        return (
          <WSList gap={undefined}>
            {querySharedFileSettingsData.instructions ? (
              <WSInfoBox>{querySharedFileSettingsData.instructions}</WSInfoBox>
            ) : null}

            {file ? (
              <WSPanel p="XL">
                <WSFlexBox.CenterY wrap="nowrap">
                  <WSText.Paragraph singleLine>{file.name}</WSText.Paragraph>
                  <WSFlexBox>
                    <WSIcon
                      block
                      color="gray500"
                      size="M"
                      mx="M"
                      name="exit"
                      onClick={() => {
                        setFile(null);
                      }}
                    />
                  </WSFlexBox>
                </WSFlexBox.CenterY>
              </WSPanel>
            ) : (
              <WSElement>
                <WSText.ParagraphSm color="gray500">
                  Only {formatsList} files are accepted.
                </WSText.ParagraphSm>
                <WSDropFileInput
                  name="dropFileInput"
                  buttonText="Browse file"
                  buttonProps={{
                    type: "button",
                    icon: "upload"
                  }}
                  maxSize={1000000 * 100}
                  accept={querySharedFileSettingsData.mimeTypes}
                  onDropAccepted={accepted => {
                    setFile(accepted[0]);
                  }}
                />
              </WSElement>
            )}

            <WSErrorMessage
              error={updateSharedFileMeta.error || uploadFileMeta.error}
              contextKey="Other"
            />

            <WSActions
              alignment="fill"
              orientation="horizontal"
              buttons={[
                {
                  kind: "Primary",
                  label: "Continue",
                  disabled: !file,
                  onAsyncClick: async () => {
                    const data = new FormData();
                    data.append("file", file!);

                    const uploadFileResponse = await uploadFile(data, {
                      onError: error => {
                        openSnackbar({
                          message: "Failed to upload file",
                          type: "error"
                        });
                      },
                      throwOnError: true
                    });

                    const fileId = uploadFileResponse?.[0].fileId;

                    if (fileId) {
                      const updatedSharedFile = await updateSharedFile(
                        {
                          sharedFileId: sharedFileRequestId,
                          fileId
                        },
                        {
                          onError: error => {
                            openSnackbar({
                              message: "Failed to update shared file",
                              type: "error"
                            });
                          },
                          throwOnError: true
                        }
                      );

                      if (updatedSharedFile) {
                        if (payerId) {
                          await getPayerEngagements(payerId);
                        }

                        openSnackbar({
                          message: "File uploaded successfully",
                          type: "success"
                        });

                        onSuccess();
                        onClose(true);

                        return;
                      }
                    }
                  }
                }
              ]}
            />
          </WSList>
        );
      }}
    </WSQueries>
  );
};

export function useUploadFileModal() {
  return useWSModal(UploadFileModal, {
    title: "File upload",
    size: "S"
  });
}
