import {
  WSButton,
  WSButtonProps,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import cn from "classnames";
import React from "react";
import styles from "./Card.module.scss";

type Props = { title?: string; action?: WSButtonProps } & WSElementProps;

export const Card: React.FC<Props> = ({
  children,
  title,
  action,
  className,
  ...elementProps
}) => (
  <WSElement className={cn(styles.container, className)} {...elementProps}>
    {(title || action) && (
      <WSFlexBox
        className={styles.header}
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
      >
        <WSText.Heading5>{title}</WSText.Heading5>
        {action && <WSButton {...action} />}
      </WSFlexBox>
    )}
    <WSElement className={styles.main}>{children}</WSElement>
  </WSElement>
);
