import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksAccountExpenses,
  useIntegrationsQuickbooksItems
} from "../../../../query/integrations/queries";
import { isQuickbooksServiceActive } from "../../../../query/integrations/selectors";
import { useMemberClientsQuery } from "../../../../query/payments/queries";
import { getMemberClient } from "../../../../query/payments/selectors";
import { useInvoicesFormContext } from "../InvoicesForm";

export const useQuickbooksProps = ({
  isIntegrationQuickbooksAccounts,
  isIntegrationQuickbooksItems
}: {
  isIntegrationQuickbooksAccounts?: boolean;
  isIntegrationQuickbooksItems?: boolean;
}) => {
  const { watch } = useFormContext();
  const { invoice } = useInvoicesFormContext();
  const memberClientId = watch("client.memberClientId");
  const memberClientsQuery = useMemberClientsQuery();
  const qFeatureFlags = useFeatureFlags();

  const qIntegrationsQuickbooks = useIntegrationsQuickbooks({
    enabled: isIntegrationQuickbooksAccounts || isIntegrationQuickbooksItems
  });

  const isQuickbooksActive =
    qFeatureFlags.data?.qboOnboarding &&
    qIntegrationsQuickbooks.data &&
    (qFeatureFlags.data?.forceShowQBO ||
      isQuickbooksServiceActive(qIntegrationsQuickbooks.data));

  const qIntegrationsQuickbooksItems = useIntegrationsQuickbooksItems({
    enabled: isQuickbooksActive && isIntegrationQuickbooksItems
  });

  const qIntegrationsQuickbooksAccountExpenses = useIntegrationsQuickbooksAccountExpenses(
    {
      enabled: isQuickbooksActive && isIntegrationQuickbooksAccounts
    }
  );

  return useMemo(() => {
    const memberClient =
      memberClientsQuery.data && memberClientId
        ? getMemberClient(memberClientsQuery.data, memberClientId)
        : undefined;

    const quickbooksOptions = isIntegrationQuickbooksItems
      ? qIntegrationsQuickbooksItems.data?.map(item => ({
          label: item.name,
          value: item.itemId
        }))
      : isIntegrationQuickbooksAccounts
      ? qIntegrationsQuickbooksAccountExpenses.data?.map(item => ({
          label: item.name,
          value: item.accountId
        }))
      : undefined;

    const isQuickbooksItem = isIntegrationQuickbooksItems && isQuickbooksActive;
    const isQuickbooksAccount =
      isIntegrationQuickbooksAccounts && isQuickbooksActive;
    const quickbooksDefaultItemId = isQuickbooksItem
      ? invoice?.integration?.quickbooks?.itemId ||
        memberClient?.integration?.quickbooks?.itemId ||
        qIntegrationsQuickbooks.data?.defaults?.itemId
      : undefined;
    const quickbooksDefaultAccountId = isQuickbooksAccount
      ? invoice?.integration?.quickbooks?.expenseAccountId ||
        memberClient?.integration?.quickbooks?.expenseAccountId ||
        qIntegrationsQuickbooks.data?.defaults?.expenseAccountId
      : undefined;

    return {
      isQuickbooksItem,
      isQuickbooksAccount,
      quickbooksOptions: quickbooksOptions || [],
      quickbooksDefaultItemId,
      quickbooksDefaultAccountId
    };
  }, [
    invoice?.integration?.quickbooks?.expenseAccountId,
    invoice?.integration?.quickbooks?.itemId,
    isIntegrationQuickbooksAccounts,
    isIntegrationQuickbooksItems,
    isQuickbooksActive,
    memberClientId,
    memberClientsQuery.data,
    qIntegrationsQuickbooks.data?.defaults?.expenseAccountId,
    qIntegrationsQuickbooks.data?.defaults?.itemId,
    qIntegrationsQuickbooksAccountExpenses.data,
    qIntegrationsQuickbooksItems.data
  ]);
};
