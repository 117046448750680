import {
  WSElement,
  WSElementProps,
  WSIcon,
  WSMenuItem,
  WSTable,
  WSTableCell,
  WSText,
  toWSDateString
} from "@wingspanhq/fe-component-library";
import { DocumentStatus } from "@wingspanhq/files/dist/lib/interfaces";
import { IMemberClientRequirementResponse } from "@wingspanhq/payments/dist/interfaces";
import {
  RequirementStatus,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import React from "react";
import { WSQueries } from "../../../query/WSQuery";
import {
  useDownloadMemberDocument,
  useSignDocument
} from "../../../query/files/mutations";
import { useMemberClientQuery } from "../../../query/payments/queries";

type Props = { memberClientId: string } & WSElementProps;

interface Requirement
  extends Omit<IMemberClientRequirementResponse, "requirementType"> {
  requirementType: "PayoutMethod" | "W9TaxForm" | RequirementType;
}

export const ClientEligibilityRequirements: React.FC<Props> = ({
  memberClientId,
  ...elementProps
}) => {
  const memberClientQuery = useMemberClientQuery(memberClientId);

  const [sign] = useSignDocument();
  const [downloadPdf] = useDownloadMemberDocument();

  return (
    <WSElement {...elementProps}>
      <WSQueries queries={{ memberClientQuery }}>
        {({ memberClientQuery: { data: memberClient } }) => {
          return (
            <>
              <WSText.Heading5 mb="XL">
                Eligibility requirements
              </WSText.Heading5>

              <WSTable
                tableData={(memberClient.eligibilityRequirements || []).map(
                  i => ({
                    id: i.eligibilityRequirementId,
                    data: i
                  })
                )}
                columns={[
                  {
                    config: {
                      gridTemplateSizeMax: "5fr"
                    },
                    renderFunction: ({ data }) => (
                      <WSTableCell
                        avatar={{
                          type: "icon",
                          icon: "paperclip",
                          colorBackground: "gray50",
                          color: "gray500"
                        }}
                        text={data.document?.title}
                        secondaryText={
                          data.status === RequirementStatus.Complete &&
                          data.document?.events?.clientSignedAt
                            ? "Completed " +
                              toWSDateString(
                                data.document.events.clientSignedAt,
                                "monthDayYear"
                              )
                            : data.status === RequirementStatus.Complete &&
                              data.document?.events?.memberSignedAt
                            ? "Completed " +
                              toWSDateString(
                                data.document.events.memberSignedAt,
                                "monthDayYear"
                              )
                            : data.status === RequirementStatus.Complete
                            ? "Completed " +
                              toWSDateString(new Date(), "monthDayYear")
                            : data.status === RequirementStatus.Pending &&
                              data.document?.events?.memberSignedAt
                            ? "Waiting for client's signature"
                            : "Incomplete"
                        }
                      />
                    )
                  },
                  {
                    config: {
                      gridTemplateSizeMax: "1fr",
                      justify: "end"
                    },
                    renderFunction: ({ data }) =>
                      data.status === RequirementStatus.Complete ? (
                        data.document?.status === DocumentStatus.Pending ? (
                          <WSIcon block name="alert-circle" color="amber400" />
                        ) : (
                          <WSIcon block name="check" color="green500" />
                        )
                      ) : (
                        <WSIcon block name="alarm" color="gray400" />
                      )
                  }
                ]}
                rowMenuActions={({ data }) => {
                  const actions: WSMenuItem[] = [];

                  if (data.document?.documentId) {
                    actions.push({
                      onClick: () => {
                        downloadPdf({
                          documentId: data.document?.documentId || ""
                        });
                      },
                      label: "Download PDF",
                      icon: "download"
                    });
                  }

                  if (
                    data.status !== RequirementStatus.Complete &&
                    !(
                      data.status === RequirementStatus.Pending &&
                      data.document?.events?.memberSignedAt
                    )
                  ) {
                    actions.push({
                      onClick: () => {
                        sign({
                          documentId: data.document?.documentId,
                          memberClientId: memberClient.memberClientId
                        });
                      },
                      label: "Sign",
                      icon: "file"
                    });
                  }

                  return actions;
                }}
                {...elementProps}
              />
            </>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
