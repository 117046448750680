import {
  WSActions,
  WSElement,
  WSForm,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSSelect
} from "@wingspanhq/fe-component-library";
import { IMember, INewUser } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { COUNTRY_OPTIONS } from "../../../constants/user";
import { FormPartialAddress } from "../../../modules/Onboarding/components/FormPartialAddress";
import { FormPartialBankCard } from "../FormPartialBankCard";
import { selectorDefaultValues } from "./selectorDefaultValues";
import { FormData } from "./types";
import { validationSchema } from "./validationSchema";

type Props = {
  user: INewUser;
  member: IMember;
  onSubmit: (data: FormData) => void;
  error?: any;
  isLoading?: boolean;
  onBack?: () => void;
  withPanel?: boolean;
};

export const Form: React.FC<Props> = ({
  user,
  member,
  onSubmit,
  error,
  isLoading,
  withPanel,
  onBack
}) => {
  const panelContent = (
    <WSList gap="2XL">
      <WSElement>
        <WSSectionToolbar title="Card details" />

        <FormPartialBankCard name="card" />
      </WSElement>

      <WSElement>
        <WSSectionToolbar title="Billing address" />

        <WSList gap="M">
          <WSForm.Field
            label="Country"
            name="country"
            component={WSSelect}
            componentProps={{
              internalSearch: true,
              options: COUNTRY_OPTIONS
            }}
          />
          <WSForm.Value name="country">
            {country => <FormPartialAddress name="address" country={country} />}
          </WSForm.Value>
        </WSList>
      </WSElement>

      <WSErrorMessage contextKey="BankCard" forceShowApiErrors error={error} />
    </WSList>
  );

  return (
    <WSForm<FormData>
      defaultValues={selectorDefaultValues(user, member)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <WSList gap="2XL">
        {withPanel ? <WSPanel>{panelContent}</WSPanel> : panelContent}

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Back",
              kind: "Secondary",
              onClick: onBack,
              visible: !!onBack,
              type: "button"
            },
            {
              label: "Continue",
              loading: isLoading
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};
