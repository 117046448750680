import { useHistory, useRouteMatch } from "react-router-dom";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { EditLayout } from "../../components/EditLayout";
import { WSQueries } from "../../../../query/WSQuery";
import {
  WSButton,
  WSFlexBox,
  WSIcon,
  WSProgressBar,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  useBulkCalculate1099Batch,
  useBulkCalculate1099BatchSummary
} from "../../../BulkImporter/query/bulkCalculation1099/queries";
import { CURRENT_YEAR } from "../../constants/currentYear";
import { formatNumber } from "accounting";
import React, { useEffect, useState } from "react";
import { queryCache } from "react-query";
import { QUERY_PAYMENTS_TAXFORM_LIST_PAYER } from "../../../../query/taxForm/keys";

const POLL_TIME_IN_MS = 5000;

export const RouteGenerate1099NECAmountsProgress: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const [isBatchProcessed, setIsBatchProcessed] = useState(false);
  const queryCalculate1099BulkBatch = useBulkCalculate1099Batch(bulkBatchId, {
    refetchInterval: POLL_TIME_IN_MS,
    enabled: !isBatchProcessed
  });

  const queryBulkCalculate1099Summary = useBulkCalculate1099BatchSummary(
    bulkBatchId,
    {},
    { refetchInterval: POLL_TIME_IN_MS, enabled: !isBatchProcessed }
  );

  const status = queryCalculate1099BulkBatch.data?.status;

  useEffect(() => {
    const isBatchUploadFinished =
      status && [BulkStatus.Complete, BulkStatus.Failed].includes(status);
    if (isBatchUploadFinished) {
      setIsBatchProcessed(true);
      // refetch tax forms to refresh amounts & statuses on 1099 dashboard
      queryCache.invalidateQueries([QUERY_PAYMENTS_TAXFORM_LIST_PAYER]);
    }
  }, [status]);

  const goto1099Dashboard = () => {
    history.push(`/member/1099-filing/dashboard/all`);
  };

  return (
    <EditLayout title="Generate amounts and statuses">
      <WSQueries
        queries={{
          queryCalculate1099BulkBatch,
          queryBulkCalculate1099Summary
        }}
      >
        {({
          queryCalculate1099BulkBatchData,
          queryBulkCalculate1099SummaryData
        }) => {
          const total = queryBulkCalculate1099SummaryData.totalTaxForms;
          const processed = queryBulkCalculate1099SummaryData.processedTaxForms;
          const importPercent = total ? (processed / total) * 100 : 0;
          const formattedTotalItems = formatNumber(total);

          const statusProgressMessageMap = {
            [BulkStatus.Open]: "Fetching all tax forms..",
            [BulkStatus.Pending]: "Preparing data..",
            [BulkStatus.Processing]: `Generating 1099 amounts & statuses for ${formattedTotalItems} tax forms`,
            [BulkStatus.Complete]: `Successfully generated amounts & statuses`,
            [BulkStatus.Failed]:
              "There was an error while generating some forms"
          };

          let progressText =
            statusProgressMessageMap[queryCalculate1099BulkBatchData.status];
          const isGenerationCompleted = [
            BulkStatus.Complete,
            BulkStatus.Failed
          ].includes(queryCalculate1099BulkBatchData.status);

          return (
            <>
              <WSText.Heading4 mb="XL">
                Generate 1099-NECs amounts and statuses
              </WSText.Heading4>

              <WSFlexBox.CenterY mb="2XL">
                {isGenerationCompleted ? (
                  <WSIcon block name="check" size="M" color="green400" />
                ) : (
                  <WSProgressBar
                    my="XL"
                    percent={importPercent}
                    text={progressText}
                    noAnimation={isBatchProcessed}
                  />
                )}
              </WSFlexBox.CenterY>
              {!isGenerationCompleted ? (
                <>
                  <WSText my="XL">
                    We are calculating 1099-NECs amounts and status for {total}{" "}
                    recipients for {CURRENT_YEAR}. This could take a minute.
                  </WSText>
                  <WSText mb="XL" color="gray400">
                    Do not close this window while calculation is in progress.
                  </WSText>
                </>
              ) : null}
              <WSButton
                onClick={goto1099Dashboard}
                fullWidth
                name="goto1099Dashboard"
                disabled={
                  ![BulkStatus.Complete, BulkStatus.Failed].includes(
                    queryCalculate1099BulkBatchData.status
                  )
                }
              >
                Go to dashboard
              </WSButton>
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
