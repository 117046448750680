import * as Yup from "yup";
import { validatorInternationalTaxId } from "../../../../shared/validators/internationalTaxIdValidator";
import { validatorCanadaQuebecEnterpriseNumber } from "../../../../shared/validators/validatorCanadaQuebecEnterpriseNumber";
import { validatorCompanyName } from "../../../../shared/validators/validatorCompanyName";
import { validatorStringTextNumbersSymbols } from "../../../../shared/validators/validatorStringTextNumbersSymbols";
import { validatorWebsite } from "../../../../shared/validators/validatorWebsite";
import { getValidationSchemaAddress } from "../FormPartialAddress";
import { validationSchemaPhoneNumber } from "../FormPartialPhoneNumber";

export const getValidationSchema = (country: string) =>
  Yup.object().shape({
    legalBusinessName: validatorCompanyName.required("Required"),
    dba: validatorStringTextNumbersSymbols,
    taxClassification:
      country === "US"
        ? Yup.string()
            .nullable()
            .required("Required")
        : Yup.string().nullable(),
    taxId: validatorInternationalTaxId("taxId", "Business", country).required(
      "Required"
    ),
    industry: Yup.string()
      .nullable()
      .required("Required"),
    email: Yup.string().when("usePersonalEmail", {
      is: false,
      then: Yup.string()
        .email("Invalid email")
        .required("Required")
    }),
    usePersonalEmail: Yup.boolean().nullable(),
    phone: validationSchemaPhoneNumber,
    website: validatorWebsite,
    noWebsite: Yup.boolean()
      .nullable()
      .when("website", {
        is: (website: string) => !website,
        then: Yup.boolean()
          .nullable()
          .isTrue(
            "Please check the box if your business does not have a website"
          )
      }),
    address: getValidationSchemaAddress(country),
    stateOfIncorporation:
      country === "US" || country === "CA"
        ? Yup.string()
            .nullable()
            .required("Required")
        : Yup.string().nullable(),
    yearOfIncorporation:
      country === "CA"
        ? Yup.string()
            .nullable()
            .required("Required")
        : Yup.string().nullable(),
    canadaQuebecEnterpriseNumber: Yup.string().when("address", {
      is: (address: any) =>
        address ? country === "CA" && address.state === "QC" : false,
      then: validatorCanadaQuebecEnterpriseNumber.required("Required"),
      otherwise: Yup.string().nullable()
    })
  });
