import {
  WSButton,
  WSButtons,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";

type Props = {
  email: string;
  onClose: () => void;
  onChange: () => void;
  isSignUp?: boolean;
};

export const ModalNotYou: React.FC<Props> = ({
  email,
  onChange,
  onClose,
  isSignUp
}) => (
  <>
    <WSText.Heading5 mb="M">Not you?</WSText.Heading5>
    <WSText mb="M">
      By using a different email now, your new account will not be associated
      with your organization.
    </WSText>

    <WSMessageBox.Info mb="M">
      <WSText color="gray500" mb="S">
        If your organization already has an account, creating a new account will
        result in issues or delays with payments moving forward.
      </WSText>
      <WSText color="gray500">
        You should only continue if you don’t recognize the email address on the
        previous sign-in page. If you would like to update your email address,
        you’ll have the opportunity to do this later. To sign-in with an
        existing account, click “Back.”
      </WSText>
    </WSMessageBox.Info>

    <WSButtons forceFullWidth>
      <WSButton
        type="button"
        onClick={() => {
          onClose();
        }}
      >
        Leave {email} to {isSignUp ? "sign up" : "sign in"}
      </WSButton>
      <WSButton.Secondary
        type="button"
        onClick={() => {
          onChange();
          onClose();
        }}
      >
        Use a different email
      </WSButton.Secondary>
    </WSButtons>
  </>
);
