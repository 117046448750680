import { WSElementProps } from "@wingspanhq/fe-component-library";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";

import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { BulkResource } from "../../types";

export interface BulkImportErrorProps extends WSElementProps {
  batchId: string;
  bulkResource: BulkResource;
}

export function BulkImportError({
  batchId,
  bulkResource,
  ...otherProps
}: BulkImportErrorProps) {
  const { useBulkBatch } = getBulkQueriesByResource(bulkResource);
  const qBulkBatch = useBulkBatch(batchId);

  const hasImportError = () => {
    const bulkBatch = qBulkBatch.data;
    if (!bulkBatch) {
      return false;
    }
    return (
      bulkBatch.labels?.[BATCH_LABELS_WS_KEYS.bulkImporterErrorCode] ||
      bulkBatch.labels?.[BATCH_LABELS_WS_KEYS.bulkImporterErrorDebug] ||
      bulkBatch.labels?.[BATCH_LABELS_WS_KEYS.bulkImporterErrorUserMessage]
    );
  };
  return hasImportError() ? (
    <WSErrorMessage
      {...otherProps}
      contextKey={ErrorContextKey.BulkBatchImportError}
      shimmer={qBulkBatch.isLoading}
      error={
        qBulkBatch.data?.labels?.[
          BATCH_LABELS_WS_KEYS.bulkImporterErrorUserMessage
        ]
      }
    />
  ) : null;
}
