import {
  useModalContext,
  WSButtons,
  WSElement,
  WSFormOld,
  WSModal,
  WSRadioInputGroup,
  WSStepper,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { QUERY_PAYOUT_SETTINGS } from "../../../query/payments/keys";
import { usePayoutSettings } from "../../../query/payments/queries";
import { WSQueries } from "../../../query/WSQuery";
import { paymentsService } from "../../../services/payments";
import styles from "./UpdateDepositRate.module.scss";

import { reversePercent } from "../utils";

export const UPDATE_DEPOSIT_RATE_MODAL = "UPDATE_DEPOSIT_RATE_MODAL";

export const UpdateDepositRateModal: React.FC = () => {
  const { closeModal } = useModalContext();
  const userId = useUserId();
  const qPayoutSettings = usePayoutSettings(userId);

  const [mutate] = useWSMutation<
    any,
    any,
    {
      values: {
        incomeType?: string;
        rate?: number;
      };
    }
  >(
    async ({ values }) => {
      let walletDistibution;
      if (values.incomeType === "ALL") {
        walletDistibution = 100;
      } else if (values.incomeType === "NO") {
        walletDistibution = 0;
      } else {
        walletDistibution = values.rate || 0;
      }

      await paymentsService.payoutSettings.updateWalletDistribution(
        userId,
        walletDistibution
      );
    },
    {
      dependencies: [QUERY_PAYOUT_SETTINGS],
      onSuccess: () => {
        closeModal(UPDATE_DEPOSIT_RATE_MODAL);
      }
    }
  );

  return (
    <WSModal
      name={UPDATE_DEPOSIT_RATE_MODAL}
      size="S"
      onClose={() => closeModal(UPDATE_DEPOSIT_RATE_MODAL)}
    >
      <WSQueries
        queries={{
          qPayoutSettings
        }}
      >
        {({ qPayoutSettings }) => {
          const depositRatePercentage = qPayoutSettings.data.payoutDestinations.find(
            p => Number.isInteger(p.percentage)
          )?.percentage;
          const normalPercent = reversePercent(depositRatePercentage);

          return (
            <WSFormOld
              onSubmit={async formData => {
                await mutate({ values: formData });
              }}
              defaultValues={{
                incomeType:
                  normalPercent === 100
                    ? "ALL"
                    : normalPercent === 0
                    ? "NO"
                    : "SOME",
                rate:
                  normalPercent === 100
                    ? 30
                    : normalPercent === 0
                    ? 30
                    : normalPercent
              }}
              validationSchema={Yup.object().shape({
                rate: Yup.number()
                  .min(0)
                  .max(100)
              })}
            >
              <WSText.Heading4>
                How much of your income would you like deposited into your
                Wingspan Wallet account?
              </WSText.Heading4>

              <WSFormOld.Field
                name="incomeType"
                mt="2XL"
                component={WSRadioInputGroup}
                componentProps={{
                  radioInputClassName: styles.option,
                  options: [
                    {
                      label: "All income",
                      value: "ALL"
                    },
                    {
                      label: "No income",
                      value: "NO"
                    },
                    {
                      label: "Some income",
                      value: "SOME"
                    }
                  ]
                }}
              />

              <WSFormOld.Value name="incomeType">
                {incomeType =>
                  incomeType === "SOME" ? (
                    <WSElement mx="3XL">
                      <WSText mt="XL" color="gray400" align="center">
                        Select income
                      </WSText>
                      <WSFormOld.Field
                        name="rate"
                        component={WSStepper}
                        componentProps={{ min: 0, max: 100, postfix: "%" }}
                      />
                    </WSElement>
                  ) : null
                }
              </WSFormOld.Value>

              <WSText color="gray500" mt="2XL" align="center">
                Don’t worry, you can always change this again later.
              </WSText>

              <WSButtons justify="center" mt="3XL">
                <WSFormOld.SubmitButton name="submitDepositRate" fullWidth>
                  Update deposit rate
                </WSFormOld.SubmitButton>
              </WSButtons>
            </WSFormOld>
          );
        }}
      </WSQueries>
    </WSModal>
  );
};
