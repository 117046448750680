import {
  SubscriptionPackage,
  SubscriptionPackageTier
} from "@wingspanhq/users/dist/lib/interfaces/subscription";

export const SUBSCRIPTION_SELECT_OPTIONS = [
  {
    title: "Free",
    description: "Invoicing, tax withholdings, bookkeeping and more.",
    value: {
      package: SubscriptionPackage.None,
      packageTier: SubscriptionPackageTier.Basic
    }
  },
  {
    title: "Premium Perks",
    description: "The benefits & perks they need",
    value: {
      package: SubscriptionPackage.Benefits,
      packageTier: SubscriptionPackageTier.Basic
    }
  },
  {
    title: "Teams",
    description: "Contractor mgmt, automated workflows",
    value: {
      package: SubscriptionPackage.Professional,
      packageTier: SubscriptionPackageTier.Basic
    }
  },
  {
    title: "Dedicated CPA",
    description: "Hand things over to us for white glove care",
    value: {
      package: SubscriptionPackage.Professional,
      packageTier: SubscriptionPackageTier.Premium
    }
  }
] as const;

export type SubscriptionGrantOptionTitle = typeof SUBSCRIPTION_SELECT_OPTIONS[number]["title"];
