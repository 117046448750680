import {
  detachLayoutProps,
  useIsMobile,
  useModalContext,
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSImage,
  WSPanel,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router";
import { useUserId } from "../../../query/hooks/helpers";
import { useActivities } from "../../../query/users/queries";
import { IBenefitCTAWidget } from "../../screens/BenefitsV3";
import { BenefitBlockStatus } from "../BenefitBlock/BenefitBlock.module";
import { BENEFIT_DETAILS_TRANSITION_MODAL } from "./BenefitDetailsTransitionModal";
import styles from "./BenefitDetailsV2.module.scss";

export interface BenefitDetailsWidgetProps extends WSElementProps {
  widgetData: IBenefitCTAWidget;
}

export const BenefitDetailsWidget: React.FC<BenefitDetailsWidgetProps> = ({
  widgetData,
  ...otherProps
}) => {
  const userId = useUserId();
  const qActivity = useActivities(userId);
  const {
    headerText,
    logo,
    usageText,
    videoScript,
    ctaBtnText,
    ctaBtnFooter,
    contactInfo,
    memberNumber,
    cost,
    status,
    isPremium
  } = widgetData;
  const { layoutProps } = detachLayoutProps(otherProps);
  const isMobile = useIsMobile();
  const { openModal } = useModalContext();
  const history = useHistory();

  const getCostInfo = () => {
    if (cost) {
      return cost;
    } else if (status === BenefitBlockStatus.INCLUDED) {
      return "Included";
    } else if (status === BenefitBlockStatus.DEFAULT) {
      return "No additional cost";
    } else if (status === BenefitBlockStatus.PREMIUM) {
      return `Included ${isPremium ? "" : "with premium"}`;
    }
    return null;
  };
  const costInfo = getCostInfo();
  let ctaProps = {
    onClick: () => history.push("/member/benefits/set-up/1"),
    btnText: "Activate Benefits" as React.ReactNode
  };

  if (qActivity.data?.flows.benefitsSetup?.complete) {
    ctaProps = {
      onClick: () =>
        openModal(BENEFIT_DETAILS_TRANSITION_MODAL, {
          gettingStarted: widgetData
        }),
      btnText: ctaBtnText
    };
  }

  const content = (
    <WSFlexBox
      direction="column"
      alignItems="stretch"
      p={isMobile ? "NONE" : "M"}
    >
      <WSImage
        className={styles.logo}
        src={logo as string}
        placeholder="Logo"
        mb="M"
      />
      {costInfo && (
        <WSText.ParagraphSm weight="medium" mb="M">
          Cost: {costInfo}
        </WSText.ParagraphSm>
      )}
      <WSText color="gray600" mb="M">
        {usageText}
        <WSText.ParagraphSm weight="medium" inline>
          {memberNumber ? memberNumber : "——"}
        </WSText.ParagraphSm>
      </WSText>
      <WSScreen.Mobile>
        {videoScript && (
          <WSElement mb="3XL" className={styles.videoContainer}>
            {videoScript}
          </WSElement>
        )}
      </WSScreen.Mobile>
      <WSButton fullWidth onClick={ctaProps.onClick} mb="XL">
        {ctaProps.btnText}
      </WSButton>
      {typeof ctaBtnFooter === "string" ? (
        <WSText color="gray600">{ctaBtnFooter}</WSText>
      ) : (
        ctaBtnFooter
      )}
      {contactInfo && (
        <WSFlexBox
          direction="column"
          alignItems="stretch"
          className={styles.contactInfo}
          mt="XL"
          p="S"
        >
          <WSText.ParagraphSm mb="XS" color="gray600">
            Call or Email
          </WSText.ParagraphSm>
          <a href={`tel:${contactInfo.phone}`}>
            <WSText.Heading4>{contactInfo.phone}</WSText.Heading4>
          </a>
          <a href={`mailto:${contactInfo.email}`}>
            <WSText color="blue400">{contactInfo.email}</WSText>
          </a>
        </WSFlexBox>
      )}
    </WSFlexBox>
  );
  return (
    <>
      <WSScreen.TabletAndDesktop>
        <WSPanel className={styles.ctaWidget} {...layoutProps} p="NONE">
          <WSElement className={styles.headerContainer} p="S">
            <WSText align="center" color="gray600">
              {headerText}
            </WSText>
          </WSElement>
          {content}
        </WSPanel>
      </WSScreen.TabletAndDesktop>
      <WSScreen.Mobile>
        <WSElement className={styles.ctaWidget} {...layoutProps}>
          {content}
        </WSElement>
      </WSScreen.Mobile>
    </>
  );
};
