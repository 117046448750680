import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation, WSMutationConfig } from "../../helpers";
import { IChannelNotificationResponse } from "@wingspanhq/notifications/dist/lib/interfaces/api/notifications";
import {
  QUERY_NOTIFICATIONS_NOTIFICATION,
  QUERY_NOTIFICATIONS_NOTIFICATIONS
} from "../keys";
import { patchDismissNotification } from "../../../services/notifications/patchDismissNotification";
import { queryCache } from "react-query";

export const useDismissNotification = (
  config?: WSMutationConfig<
    IChannelNotificationResponse,
    WSServiceError,
    string
  >
) =>
  useWSMutation((id: string) => patchDismissNotification(id), {
    onSuccess: (response, id) => {
      queryCache.setQueryData([QUERY_NOTIFICATIONS_NOTIFICATION, id], response);
      queryCache.setQueryData(QUERY_NOTIFICATIONS_NOTIFICATIONS, () => {
        return queryCache
          .getQueryData<IChannelNotificationResponse[]>(
            QUERY_NOTIFICATIONS_NOTIFICATIONS
          )
          ?.map((cachedData: IChannelNotificationResponse) => {
            if (cachedData.channelMessageId === id) {
              return response;
            }
            return cachedData;
          });
      });
    },
    ...config
  });
