import {
  ITransaction,
  TransactionType
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { ITransactionGroup } from "@wingspanhq/bookkeeping/dist/lib/interfaces/insights";
import {
  WSButton,
  WSButtons,
  WSLoader
} from "@wingspanhq/fe-component-library";
import { formatMoney } from "accounting";
import chunk from "lodash/chunk";
import flatten from "lodash/flatten";
import React, { useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { useUpdateTransaction } from "../../../query/bookkeeping/mutations";
import { useCatchUpTransactionsExpense } from "../../../query/bookkeeping/queries";
import { getAllTotalCatchUpExpenseTransactions } from "../../../query/bookkeeping/selectors";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateActivityCatchUpEvents } from "../../../query/users/mutations";
import { useAccounts, useActivities } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { track } from "../../../utils/analytics";
import {
  CURRENT_YEAR,
  getCatchUpPagesFromGroups,
  getCatchUpTransactionsFromGroups,
  totalTransactionValues,
  updateTransactions
} from "../../utils";
import { CatchUpLayout } from "./CatchUpLayout";
import { CatchUpTransactionForm } from "./CatchUpTransactionForm";
import styles from "./Index.module.scss";
import { CatchUpTransactionDetails } from "./CatchUpTransaction";
import { useOnQueriesFetched } from "../../../shared/hooks/useOnQueriesFetched";

export const CatchUpExpenseOnboarding: React.FC<{
  next: string;
  year?: number;
}> = ({ next, year }) => {
  const history = useHistory();
  const userId = useUserId();
  const [updateActivityCatchUpEvents] = useUpdateActivityCatchUpEvents();
  const [updateTransaction] = useUpdateTransaction();

  const memberId = userId;
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState<ITransactionGroup[][]>([]);
  const [oldTransactions, setOldTransactions] = useState<ITransaction[]>([]);
  const [loading, setLoading] = useState(false);

  const qAccounts = useAccounts();
  const qActivity = useActivities(memberId);
  const qCatchUpTransactionsExpense = useCatchUpTransactionsExpense(
    memberId,
    year
  );

  useOnQueriesFetched(() => {
    setPages(
      getCatchUpPagesFromGroups(
        qCatchUpTransactionsExpense.data?.merchantExpenses,
        qCatchUpTransactionsExpense.data?.accountExpenses
      )
    );

    setOldTransactions(
      getCatchUpTransactionsFromGroups(
        qCatchUpTransactionsExpense.data?.merchantExpenses,
        qCatchUpTransactionsExpense.data?.accountExpenses
      )
    );
  }, qCatchUpTransactionsExpense);

  const isPrevYear = year !== CURRENT_YEAR;

  return (
    <WSQueries
      queries={{
        activity: qActivity,
        accounts: qAccounts,
        catchUpExpenseTransactions: qCatchUpTransactionsExpense
      }}
      renderLoader={() => (
        <ProjectOnboardingLayout progress={0} noBack>
          <WSLoader.Spinner style={{ marginTop: 100 }} />
        </ProjectOnboardingLayout>
      )}
    >
      {({ accounts, catchUpExpenseTransactions, activity }) => {
        const totalAmount = totalTransactionValues(
          TransactionType.Expense,
          ...flatten(pages)
        );

        const groupsToReview = flatten(pages.slice(page + 1, Infinity)).length;
        const nextGroups = pages[page + 1]?.length;
        const countOfMerchantPages = chunk(
          catchUpExpenseTransactions.data.merchantExpenses,
          5
        ).length;

        const totalExpenseTransactions = getAllTotalCatchUpExpenseTransactions(
          catchUpExpenseTransactions.data
        );

        if (totalExpenseTransactions === 0) {
          return <Redirect to={next} />;
        }

        return (
          <CatchUpLayout
            progress={0}
            steps={[
              {
                title: "Add up income",
                name: "1",
                checked: true
              },
              {
                title: "Find business expenses",
                name: "2",
                current: true
              },
              {
                title: "You’re all caught up!",
                name: "3"
              }
            ]}
            onBack={
              page > 0
                ? () => {
                    setPage(page - 1);
                  }
                : undefined
            }
          >
            <Route
              path={
                isPrevYear
                  ? "/member/bookkeeping/setup/catch-up/expense/prev-year/:transactionId"
                  : "/member/bookkeeping/setup/catch-up/expense/:transactionId"
              }
              component={CatchUpTransactionDetails}
              exact
            />
            <BrowserPageTitle title="Select business income" />
            <h1 className={styles.title}>
              {year} Transaction Review: Find business expenses
            </h1>
            <p className={styles.subtitle}>
              {formatMoney(totalAmount)} in business expenses found
            </p>
            <h1 className={styles.title}>Select business expenses</h1>
            <CatchUpTransactionForm
              type={TransactionType.Expense}
              transactionGroups={pages[page]}
              onChange={transactionGroups => {
                setPages(prev =>
                  prev.map((t, i) => {
                    if (i === page) {
                      return transactionGroups;
                    } else {
                      return t;
                    }
                  })
                );
              }}
              accounts={accounts.data}
              showCategory={page < countOfMerchantPages}
            />

            <p className={styles.subtitle}>
              {groupsToReview
                ? `${groupsToReview} groups to review`
                : "All groups reviewed"}{" "}
            </p>

            <WSButtons mt="XL" mb="2XL">
              {nextGroups ? (
                <WSButton
                  loading={loading}
                  name="catchUpExpenseNext"
                  onClick={async () => {
                    setLoading(true);
                    await updateTransactions(
                      pages[page],
                      oldTransactions,
                      TransactionType.Expense,
                      updateTransaction
                    );

                    setLoading(false);

                    setPage(prev => prev + 1);
                  }}
                >
                  Continue to next {pages[page + 1]?.length}
                </WSButton>
              ) : (
                <WSButton
                  name="catchUpExpenseFinish"
                  onClick={async () => {
                    setLoading(true);
                    await updateTransactions(
                      pages[page],
                      oldTransactions,
                      TransactionType.Expense,
                      updateTransaction
                    );
                    await updateActivityCatchUpEvents({
                      userId,
                      events: {
                        expensesReviewedAt: new Date()
                      }
                    });
                    track("Catch Up Completed", {
                      type: "initial",
                      year
                    });
                    setLoading(false);

                    history.push(next);
                  }}
                  loading={loading}
                >
                  Finish expense review
                </WSButton>
              )}
              {pages.length > 3 && page >= 1 && page < countOfMerchantPages ? (
                <WSButton
                  onClick={async () => {
                    track("Catch Up Completed", {
                      type: "initial",
                      year
                    });
                    history.push(next);
                    setPage(countOfMerchantPages);
                  }}
                  data-testid="skipAccountView"
                >
                  Skip to account view
                </WSButton>
              ) : null}
            </WSButtons>
          </CatchUpLayout>
        );
      }}
    </WSQueries>
  );
};
