import {
  WSButton,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { IBankAccount } from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { InfoRow } from "../InfoRow/InfoRow";

type Props = {
  bankTransferInfo?: IBankAccount;
  internationalBankTransferInfo?: IBankAccount;
  invoiceNumber?: string;
} & WSElementProps;

export const ManualWireTransferDetails: React.FC<Props> = ({
  bankTransferInfo,
  internationalBankTransferInfo,
  invoiceNumber,
  ...elementProps
}) => {
  const [
    isInternationalBankDetailsVisible,
    setIsInternationalBankDetailsVisible
  ] = useState(false);

  const bankName = isInternationalBankDetailsVisible
    ? internationalBankTransferInfo?.bankName
    : bankTransferInfo?.bankName;

  const routingNumber = isInternationalBankDetailsVisible
    ? internationalBankTransferInfo?.routingNumber
    : bankTransferInfo?.routingNumber;

  const accountNumber = isInternationalBankDetailsVisible
    ? internationalBankTransferInfo?.accountNumber
    : bankTransferInfo?.accountNumber;

  const swiftCode = internationalBankTransferInfo?.swiftCode;

  return (
    <WSElement {...elementProps}>
      <WSText weight="medium" mb="XL">
        {isInternationalBankDetailsVisible
          ? "International bank wire details"
          : "US bank wire details"}
      </WSText>

      <InfoRow label="Bank name" value={bankName || "N/A"} longName />
      {routingNumber && (
        <InfoRow label="Routing" value={routingNumber} longName mt="M" />
      )}
      <InfoRow label="Account" value={accountNumber || "N/A"} longName mt="M" />
      {isInternationalBankDetailsVisible && (
        <InfoRow label="SWIFT" value={swiftCode || "N/A"} longName mt="M" />
      )}
      {invoiceNumber && (
        <InfoRow
          mt="M"
          label="Note/memo"
          value={`Invoice #${invoiceNumber}`}
          longName
        />
      )}
      {internationalBankTransferInfo && (
        <WSButton.Link
          mt="XL"
          onClick={() => {
            setIsInternationalBankDetailsVisible(value => !value);
          }}
        >
          {isInternationalBankDetailsVisible
            ? "View domestic wire details"
            : "View international wire details"}
        </WSButton.Link>
      )}
    </WSElement>
  );
};
