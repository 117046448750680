import flatten from "lodash/flatten";
import { InfiniteQueryConfig } from "react-query";

import {
  getPayerRows,
  IPayerRow,
  PayerRowsQuery
} from "../../../../services/search/payer";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { useWSInfiniteQuery } from "../../../helpers";

const PAGE_SIZE = 20;

export const QUERY_PAYER_ROWS = "QUERY_PAYER_ROWS";

export const useQueryPayerRows = (
  requestQuery?: PayerRowsQuery,
  config?: InfiniteQueryConfig<IPayerRow[], WSServiceError>
) => {
  const query = useWSInfiniteQuery<IPayerRow[], WSServiceError>(
    [QUERY_PAYER_ROWS, requestQuery],
    (_, __, pageNumber = 1) => {
      return getPayerRows({
        ...requestQuery,
        page: {
          size: PAGE_SIZE,
          number: pageNumber
        }
      });
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.length < PAGE_SIZE) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  return {
    ...query,
    data: query.data ? flatten(query.data) : undefined
  };
};
