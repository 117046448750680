import React from "react";
import { getClientNameFromContext, NotificationTemplate } from "../index";

export const BusinessInfo: NotificationTemplate<never> = {
  renderTitle: props => "Add business logo",
  renderBody: props => {
    return (
      <>
        Add a business logo for your company so that your contractors know who
        they are receiving payments from when they work for you!
      </>
    );
  },
  buttonAction: (props, history) => () => {
    return history.push("/member/settings/business-info#marketing");
  },
  buttonText: () => "Add your business logo"
};
