import React from "react";
import { WSButton, WSFlexBox, WSText } from "@wingspanhq/fe-component-library";

import LabelValueList, {
  ListValueType,
  LABEL_COLOR,
  ListElement,
  LIST_MT,
  RowData
} from "../../../../components/LabelValueList";
import ButtonLink from "../../../../components/ButtonLink";

interface TotalsWithSuggestedChangeProps {
  items?: RowData[] | null;
  newValue?: number;
  link?: string;
}

const TotalsWithSuggestedChange: React.FC<TotalsWithSuggestedChangeProps> = ({
  items,
  newValue,
  link
}) => {
  if (!items) return <WSText>'No NEC values found'</WSText>;

  const [compensationItem, ...otherItems] = items;

  return (
    <>
      <WSFlexBox justify="space-between" mt={LIST_MT}>
        <ListElement
          color={LABEL_COLOR}
          value="Non-employee compensation total"
        />
        <ListElement
          type={ListValueType.MoneyStrike}
          value={compensationItem.value || "-"}
        />
      </WSFlexBox>
      {link ? (
        <ButtonLink
          rightIcon="open-tab"
          url={link}
          fullWidth
          size="S"
          style={{ justifyContent: "space-between" }}
        >
          <WSFlexBox justify="space-between" style={{ flexGrow: 1 }}>
            <ListElement
              value="Suggested NEC total"
              style={{ color: "inherit " }}
            />
            <ListElement
              type={ListValueType.Money}
              value={newValue || "-"}
              style={{ color: "inherit " }}
            />
          </WSFlexBox>
        </ButtonLink>
      ) : (
        <WSFlexBox justify="space-between">
          <ListElement value="Suggested NEC total" />
          <ListElement type={ListValueType.Money} value={newValue || "-"} />
        </WSFlexBox>
      )}
      <LabelValueList items={otherItems} />
    </>
  );
};

export default TotalsWithSuggestedChange;
