import { IRequirement } from "@wingspanhq/payments/dist/interfaces";
import { ContextItem } from "../../../../types";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { IBackgroundCheckRequestResponse } from "@wingspanhq/background-checks/dist/lib/interfaces";

export const getBackgroundChecksRequestFromCache = (
  requirement: IRequirement,
  contextItem: ContextItem
): IBackgroundCheckRequestResponse | undefined => {
  return contextItem?.[RequirementType.BackgroundCheck]?.find(requestItem => {
    return requestItem.backgroundCheckRequestId === requirement.dataSourceId;
  });
};
