import * as Yup from "yup";
import { isURLValid } from "../utils/isURLValid";

export const validatorWebsite = Yup.string().test(
  "urlValidator",
  "Invalid URL. Please enter a valid URL (e.g., https://example.com or http://example.org)",
  value => {
    if (value === undefined || value === null || value === "") {
      return true;
    }

    return isURLValid(value);
  }
);
