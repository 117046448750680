import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { useBankingAccount } from "../../../../query/bookkeeping/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import { usePayoutSettings } from "../../../../query/payments/queries";
import { selectorDefaultDestination } from "../../../../shared/selectors/selectorDefaultDestination";
import styles from "./index.module.scss";

type Props = WSElementProps;

export type BannerProps = {
  type?: "info" | "warning" | "error";
  message?: ReactNode;
  description?: ReactNode;
  actionText?: ReactNode;
  onAction?: () => void;
};

export const NotificationBanner: React.FC<Props> = ({ ...elementProps }) => {
  const location = useLocation<{ notificationBanner?: BannerProps }>();
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryBankingAccount = useBankingAccount();

  if (
    queryBankingAccount.isLoading ||
    queryPayoutSettings.isLoading ||
    queryBankingAccount.isLoading
  )
    return null;

  let bannerProps: BannerProps | undefined = undefined;

  const hasDefaultDestination =
    queryPayoutSettings.data &&
    !!selectorDefaultDestination(queryPayoutSettings.data);

  const hasNoPayoutMethod = !hasDefaultDestination && !queryBankingAccount.data;

  if (location.state && location.state.notificationBanner) {
    bannerProps = location.state.notificationBanner;
  } else if (hasNoPayoutMethod) {
    bannerProps = {
      type: "error",
      message: "You cannot get paid until you add a payout method"
    };
  }

  if (!bannerProps) return null;

  return <Banner {...bannerProps} {...elementProps} />;
};

const Banner: React.FC<BannerProps & WSElementProps> = ({
  type = "info",
  message,
  description,
  actionText,
  onAction,
  ...elementProps
}) => (
  <WSPanel
    noBorder
    {...elementProps}
    onClick={onAction}
    className={classNames(styles.panel, styles[type], elementProps.className)}
  >
    <WSFlexBox wrap="nowrap" alignItems="center">
      <WSIcon
        block
        name="alert-circle"
        mr="M"
        size="M"
        className={styles.icon}
      />
      <WSElement>
        {message && (
          <WSText
            weight="medium"
            mb={description ? "XS" : undefined}
            className={styles.message}
          >
            {message}
          </WSText>
        )}
        {description && (
          <WSText className={styles.description}>{description}</WSText>
        )}

        {actionText && (
          <WSButton.Link mt="M" onClick={onAction}>
            {actionText}
          </WSButton.Link>
        )}
      </WSElement>
    </WSFlexBox>
  </WSPanel>
);
