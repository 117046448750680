import { WSGrid } from "@wingspanhq/fe-component-library";
import { AvailableBalance } from "../components/AvailableBalance";
import { Sidebar } from "../components/Sidebar";
import { BankCardsList } from "../components/BankCardsList/BankCardsList";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";

export const RouteDashboard: React.FC = () => {
  const featureFlags = useFeatureFlags();

  return (
    <WSGrid gutter={{ s: "3XL" }}>
      <WSGrid.Item span={{ m: "8" }}>
        <WSGrid mb="4XL">
          <WSGrid.Item span={{ m: "12", l: "12" }}>
            <AvailableBalance />
          </WSGrid.Item>
          {/*
           <WSGrid.Item span={{ m: "12", l: "6" }}>
            <CashbackBalance />
          </WSGrid.Item>
          */}
        </WSGrid>
        {featureFlags.data?.walletCardReissuing ? <BankCardsList /> : null}
      </WSGrid.Item>
      <WSGrid.Item span={{ m: "4" }}>
        <Sidebar />
      </WSGrid.Item>
    </WSGrid>
  );
};
