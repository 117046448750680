import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
import { selectorMemberName } from "../../../shared/selectors/selectorMemberName";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces";

export const selectorTaxFormRecipientName = (
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse
) => {
  let name = "";
  const { firstName, lastName, legalBusinessName, companyStructure, tinType } =
    taxForm.data?.w9Info || {};

  const isSinglePerson =
    companyStructure === CompanyStructure.LlcSingleMember ||
    companyStructure === CompanyStructure.SoleProprietorship;

  // Check if both a legal business name and individual names are provided for sole proprietorships and single-member LLCs
  if (isSinglePerson && legalBusinessName) {
    // If they are different, append the individual's name in parentheses for clear differentiation
    name = legalBusinessName;
    if (
      firstName &&
      lastName &&
      `${firstName} ${lastName}` !== legalBusinessName
    ) {
      name += ` (${firstName} ${lastName})`;
    }
  } else if (legalBusinessName && tinType === TinType.Business) {
    name = legalBusinessName;
  } else if (firstName && lastName && tinType === TinType.Individual) {
    name = `${firstName} ${lastName}`;
  } else if (legalBusinessName) {
    name = legalBusinessName;
  } else if (firstName && lastName) {
    name = `${firstName} ${lastName}`;
  } else if (taxForm.payee) {
    name = selectorMemberName(taxForm.payee);
  }

  return name;
};
