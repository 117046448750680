import { useHistory } from "react-router-dom";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import { EditLayout } from "../../components/EditLayout";
import { ImportPaymentsFlow } from "../ImportPaymentsFlow";

export const RouteBatchUploadPayments: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const history = useHistory();
  const goBack = () => {
    if (onBack) {
      onBack();
      return;
    }

    history.push("/member/1099-filing");
  };

  return (
    <ImportPaymentsFlow
      Layout={EditLayout}
      basePath="/member/1099-filing/batch-upload-payments"
      onBack={goBack}
      onFinish={onNext}
    />
  );
};
