import styles from "./WSGrid.module.scss";
import { WSGridBreakpoint } from "./WSGrid.types";

export const getResponsiveClassNames = (
  value: string | { [key in WSGridBreakpoint]?: string } | undefined,
  className: string
) => {
  if (!value) return [];

  const classNames = [];
  if (typeof value === "string") {
    classNames.push(styles[`${className}-${value}`]);
  } else {
    Object.entries(value).forEach(([breakpointName, value]) => {
      const breakpointSuffix =
        breakpointName === WSGridBreakpoint.XS
          ? ""
          : `-bp-${breakpointName.toUpperCase()}`;

      classNames.push(styles[`${className}-${value}${breakpointSuffix}`]);
    });
  }
  return classNames;
};
