import { WeekDay } from "../../../../shared/types/time";

export enum PayrollSettingsInterval {
  Monthly = "Monthly",
  SemiMonthly = "SemiMonthly",
  Everyday = "Everyday",
  Week = "Week"
}

export type FormData = {
  runsPayroll: boolean;
  interval: PayrollSettingsInterval;
  every: number;
  dayInInterval: WeekDay;
  start: Date;
  issue1099s: boolean;
  enableMultiStageApproval: boolean;
  enableProcessDaysBeforeDue: boolean;
  processDaysBeforeDue: number;
};
