import * as React from "react";
import {
  useIsMobile,
  WSFlexBox,
  WSIcon,
  WSIconName,
  WSText
} from "@wingspanhq/fe-component-library";
import styles from "./styles.module.scss";
import { Tooltip } from "../Tooltip";

interface NormalOptionProps {
  onClick: () => void;
  label: string;
  icon: WSIconName;
  isSideNavOpened?: boolean;
  name: string;
}

export const SideNavRootOption: React.FC<NormalOptionProps> = ({
  onClick,
  label,
  icon,
  isSideNavOpened,
  name
}) => {
  const isMobile = useIsMobile();
  const isFullSize = isSideNavOpened || isMobile;

  const content = (
    <WSFlexBox.CenterY
      px="XL"
      className={styles.defaultMainMenuOption}
      onClick={onClick}
      data-testid={name}
    >
      <WSIcon block name={icon} color="gray600" />
      {isFullSize ? (
        <WSText ml="XL" color="gray600" className={styles.label}>
          {label}
        </WSText>
      ) : null}
    </WSFlexBox.CenterY>
  );
  return isFullSize ? (
    content
  ) : (
    <Tooltip trigger={content} placement="right">
      <WSText color="white" className={styles.label}>
        {label}
      </WSText>
    </Tooltip>
  );
};
