export const downloadFile = (url: string, fileName?: string) => {
  const link = document.createElement("a");
  link.style.opacity = "0";
  link.download = fileName || "";
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFileFromBlob = (data: Blob, fileName: string) => {
  const tempLink = document.createElement("a");
  tempLink.href = window.URL.createObjectURL(data);
  tempLink.setAttribute("download", fileName);
  const tempElement = document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempElement);
};

export const downloadFileFromBuffer = (
  data: ArrayBuffer,
  fileName: string,
  mimetype?: string
) => {
  const blob = new Blob([data], { type: mimetype });
  downloadFileFromBlob(blob, fileName);
};
