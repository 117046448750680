import {
  useModalContext,
  WSButton,
  WSFormOld,
  WSModal,
  WSText,
  WSTextArea
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import * as Yup from "yup";
import { track } from "../../utils/analytics";

export const BENEFITS_REQUEST_BENEFIT_MODAL = "BENEFITS_REQUEST_BENEFIT_MODAL";

export const BenefitsRequestBenefit: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { closeModal } = useModalContext();

  return (
    <WSModal
      name={BENEFITS_REQUEST_BENEFIT_MODAL}
      size="S"
      title="Don’t see what you’re looking for?"
    >
      <WSText mt="M">
        Have other benefits you want assistance? Let us know and we’ll give you
        a hand.
      </WSText>
      <WSFormOld
        onSubmit={async formData => {
          track("Benefit Request", { message: formData.message });
          setLoading(true);
          await new Promise(r => setTimeout(r, 1000));
          setLoading(false);
          closeModal(BENEFITS_REQUEST_BENEFIT_MODAL);
        }}
        defaultValues={{
          message: ""
        }}
        validationSchema={Yup.object().shape({
          message: Yup.string().required("Description is required")
        })}
      >
        <WSFormOld.Field
          mt="M"
          name="message"
          component={WSTextArea}
          componentProps={{
            placeholder: "Write your benefit request"
          }}
        />
        <WSButton.Primary mt="XL" loading={loading} type="submit">
          Send Request
        </WSButton.Primary>
      </WSFormOld>
    </WSModal>
  );
};
