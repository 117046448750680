import { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { RoutePayoutsDashboard } from "./routes/RouteDashboard/RouteDashboard";
import { RoutePayoutDetails } from "./routes/RoutePayoutDetails/RoutePayoutDetails";

export const PAYOUTS_PATH = "/member/invoices/payouts";

export const PayoutsIndex: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Route exact path={`${PAYOUTS_PATH}`} component={RoutePayoutsDashboard} />
      <Route
        path={`${PAYOUTS_PATH}/:payoutId`}
        component={RoutePayoutDetails}
      />
    </Switch>
  );
};
