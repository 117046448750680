import {
  WSButton,
  WSCheckboxToggle,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { UserAccountType } from "@wingspanhq/users/dist/lib/interfaces/newUser";
import React, { useState } from "react";
import { useWSMutation } from "../../../../../query/helpers";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { TextBulleted } from "../../../../../shared/components/TextBulleted";
import { LayoutW9 } from "../../../components/LayoutW9";
import { useMemberClientsQuery } from "../../../../../query/payments/queries";
import { WSQueries } from "../../../../../query/WSQuery";
import { selectorEmployerName } from "../../../../../shared/selectors/selectorEmployerName";
import { WSServiceError } from "../../../../../utils/serviceHelper";
import { paymentsService } from "../../../../../services/payments";
import { MemberTaxDocumentSharePermission } from "@wingspanhq/payments/dist/interfaces";
import { usersService } from "../../../../../services/users";
import { QUERY_USERS_ACTIVITIES } from "../../../../../query/users/keys";
import { useUserId } from "../../../../../query/hooks/helpers";

export const RouteShareW9: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const userId = useUserId();
  const [consent, setConsent] = useState(false);

  const queryMemberClients = useMemberClientsQuery();

  const [submit, submitMeta] = useWSMutation<any, WSServiceError>(
    async () => {
      const enterpriseMemberClients = (queryMemberClients.data || []).filter(
        mc => mc.clientUserAccountType === UserAccountType.enterprise
      );

      await Promise.all(
        enterpriseMemberClients.map(memberClient =>
          paymentsService.memberClient.update(memberClient.memberClientId, {
            memberData: {
              shareTaxDocument: MemberTaxDocumentSharePermission.Allow
            }
          })
        )
      );

      await usersService.activity.update(userId, {
        events: {
          w9FormAcknowledged: new Date()
        }
      });

      onNext?.();
    },
    {
      dependencies: [QUERY_USERS_ACTIVITIES]
    }
  );

  return (
    <LayoutW9 noTitle onBack={onBack} title="Share your W-9 with payers">
      <WSQueries queries={{ queryMemberClients }}>
        {({ queryMemberClientsData }) => {
          const payersNames: string = queryMemberClientsData
            .map(selectorEmployerName)
            .join(", ");

          return (
            <>
              <WSText.Heading4 mb="XL">
                Share your W-9 with {payersNames}
              </WSText.Heading4>
              <WSText mt="XS" color="gray500">
                You have the option to share your certified W-9 with our list of
                affiliated payers. This streamlines the payment process and
                ensures compliance with tax requirements.
              </WSText>

              <WSMessageBox.Info my="XL">
                <WSText mb="M" color="gray500">
                  I hereby authorize, in accordance with Wingspan's Terms of
                  Service and Privacy Policy, the following:
                </WSText>
                <TextBulleted color="gray500">
                  <li>
                    The secure and electronic dissemination of my certified W-9
                    form to {payersNames}.
                  </li>
                  <li>
                    I acknowledge that sharing my W-9 is for the purpose of
                    facilitating transactions and ensuring compliance with
                    applicable tax laws.
                  </li>
                </TextBulleted>
              </WSMessageBox.Info>

              <WSCheckboxToggle
                mb="XL"
                name="consent"
                value={consent}
                onChange={setConsent}
                label="By checking this box, I authorize Wingspan to share my W-9 with all affiliated payers. I understand that my data will be handled securely and in accordance with Wingspan’s privacy policy."
              />

              <WSButton
                disabled={!consent}
                fullWidth
                onClick={submit}
                loading={submitMeta.isLoading}
              >
                Share W-9 information
              </WSButton>
            </>
          );
        }}
      </WSQueries>
    </LayoutW9>
  );
};
