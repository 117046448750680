import React from "react";

import {
  useIsMobile,
  WSElement,
  WSGrid,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { BootcampItem } from "./KnowledgeCenterDashboard";

import { useHistory } from "react-router-dom";

import styles from "./KnowledgeCenterBootcampItem.module.scss";

export const KnowledgeCenterBootcampItem: React.FC<{ item: BootcampItem }> = ({
  item
}) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const { title, videoSrc, pageContent } = item;

  return (
    <WSPage
      title={title}
      breadcrumb={{
        label: "KNOWLEDGE CENTER",
        icon: "arrow-left",
        onClick: () => {
          history.push("/member/knowledge-center");
        }
      }}
    >
      <BrowserPageTitle title={title} />
      <WSGrid>
        <WSGrid.Item span={{ s: "8" }}>
          <WSElement className={styles.container} data-testid="videoContent">
            <iframe
              title="Loom demo"
              src={videoSrc}
              frameBorder={0}
              allowFullScreen
              className={styles.video}
            ></iframe>
          </WSElement>
        </WSGrid.Item>
        <WSGrid.Item span={{ s: "4" }}>{pageContent}</WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
