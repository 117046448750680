import {
  useWSModal,
  WSActions,
  WSDivider,
  WSEmptyState,
  WSInfoBox,
  WSList
} from "@wingspanhq/fe-component-library";
import React from "react";
import { openInNewTab } from "../../../../../shared/utils/openInNewTab";
import { useBackgroundChecksRequestQuery } from "../../../../../query/backgroundChecks/queries/useBackgroundChecksRequestQuery";
import { WSQueries } from "../../../../../query/WSQuery";

interface BackgroundCheckFeatureUpgradeModalProps {
  onClose: () => void;
  backgroundCheckRequestId: string;
}

const BackgroundChecksStartModal: React.FC<BackgroundCheckFeatureUpgradeModalProps> = ({
  onClose,
  backgroundCheckRequestId
}) => {
  const backgroundChecksRequestQuery = useBackgroundChecksRequestQuery(
    backgroundCheckRequestId
  );

  return (
    <WSList gap="L">
      <WSEmptyState
        size="M"
        title="Your payer has requested a background check to be completed."
        type="documents"
        orientation="horizontal"
        description="Click continue to open the background check portal in a new window. When
        you’ve finished with your background check requirement, return to
        Wingspan to complete your onboarding. You can access the portal and
        check the status from your Wingspan homepage."
      />
      <WSQueries queries={{ backgroundChecksRequestQuery }}>
        {({ backgroundChecksRequestQueryData }) => {
          return (
            <>
              {backgroundChecksRequestQueryData.settings?.instructions ? (
                <WSInfoBox>
                  {backgroundChecksRequestQueryData.settings?.instructions}
                </WSInfoBox>
              ) : null}
              <WSDivider />
              <WSActions
                buttons={[
                  {
                    label: "Continue",
                    kind: "Primary",
                    name: "submit",
                    onClick: async () => {
                      openInNewTab(
                        backgroundChecksRequestQueryData.vendorMetadata
                          .applicationUrl!
                      );
                      onClose();
                    }
                  }
                ]}
                alignment="fill"
              />
            </>
          );
        }}
      </WSQueries>
    </WSList>
  );
};

export function useBackgroundChecksStartModal() {
  return useWSModal(BackgroundChecksStartModal, {
    title: "Background check requirement",
    size: "M"
  });
}
