import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  getCardNumberDisplayValue,
  getCardNumberRawValue
} from "../../../utils/formatters/cardNumber";
import { WSInputText, WSInputTextProps } from "../WSInputText/WSInputText";

const getInitialCardNumberInput = (rawValue: string) => {
  const displayValue = getCardNumberDisplayValue(rawValue);

  return {
    rawValue,
    displayValue,
    position: displayValue.length
  };
};

interface WSInputCardNumberProps extends WSInputTextProps {
  onChange: (
    value: string,
    sevent: React.ChangeEvent<HTMLInputElement>
  ) => void;
  value: string;
}

export const WSInputCardNumber: React.FC<WSInputCardNumberProps> = ({
  onChange,
  value,
  ...otherProps
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [cardNumberInput, setCardNumberInput] = useState(
    getInitialCardNumberInput(value)
  );

  useEffect(() => {
    inputRef.current?.setSelectionRange(
      cardNumberInput.position,
      cardNumberInput.position
    );
  }, [cardNumberInput]);

  const handleChange = useCallback(
    (newValue: string, event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target;
      const newRawValue = getCardNumberRawValue(newValue);

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const currentPosition = input.selectionStart!;
      const displayValue = getCardNumberDisplayValue(newRawValue);
      const newPosition =
        currentPosition < newValue.length
          ? currentPosition
          : displayValue.length;

      setCardNumberInput({
        rawValue: newRawValue,
        displayValue,
        position: newPosition
      });

      onChange(newRawValue, {
        ...event,
        target: {
          ...event.target,
          value: newRawValue
        }
      });
    },
    [onChange]
  );

  return (
    <WSInputText
      ref={inputRef}
      value={cardNumberInput.displayValue}
      onChange={handleChange}
      {...otherProps}
    />
  );
};
