import { IMemberClientForm1099Balances } from "@wingspanhq/payments/dist/interfaces";

export const selectorIsForm1099CorrectionPresent = (
  form1099Balance: IMemberClientForm1099Balances
) =>
  Boolean(
    form1099Balance.correction ||
      (form1099Balance.taxForm?.submissions &&
        form1099Balance.taxForm?.submissions?.length > 1)
  );
