import {
  WSButton,
  WSButtons,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { TextBulleted } from "../../../../../shared/components/TextBulleted";
import { LayoutW9 } from "../../../components/LayoutW9";
import { useUpdateActivity } from "../../../../../query/users/mutations";
import { useUserId } from "../../../../../query/hooks/helpers";
import { openInNewTab } from "../../../../../shared/utils/openInNewTab";

export const RoutePaperlessDelivery: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const userId = useUserId();
  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);

  const onSubmit = async (isElectronic: boolean) => {
    await updateActivity(
      {
        events: {
          electronic1099Consent: isElectronic
        }
      },
      { onSuccess: onNext }
    );
  };

  return (
    <LayoutW9
      onBack={onBack}
      title="Access your tax forms in Wingspan"
      description="Save time and paper by consenting to receive your form 1099 form electronically, By continuing with paperless delivery, you’re agreeing to the following terms;"
    >
      <WSMessageBox.Info mb="XL">
        <WSText mb="M" color="gray500">
          You are opting in to receive an electronic copy of your form 1099
          Information Return
        </WSText>
        <TextBulleted color="gray500">
          <li>
            You will receive an electronic Form 1099 from each business that has
            paid you via Wingspan, unless they opt out of using our Form 1099
            filing service
          </li>
          <li>
            This consent to receive an electronic Form 1099 will remain in place
            until you opt out
          </li>
          <li>
            Any electronic Forms 1099 made available to you will remain
            available for 2 years or until you request that your Wingspan
            account be closed and/or deleted
          </li>
        </TextBulleted>
      </WSMessageBox.Info>

      <WSText mb="XL">
        If you have any questions about accessing your forms directly or via
        portal mail,{" "}
        <WSButton.Link
          onClick={() => openInNewTab("https://www.wingspan.app/support")}
        >
          chat with a live representative
        </WSButton.Link>
      </WSText>

      <WSButtons forceFullWidth>
        <WSButton
          onClick={() => onSubmit(true)}
          loading={updateActivityMeta.isLoading}
        >
          Continue with paperless delivery
        </WSButton>
        <WSButton.Secondary
          onClick={() => onSubmit(false)}
          loading={updateActivityMeta.isLoading}
        >
          Mail me a copy
        </WSButton.Secondary>
      </WSButtons>
    </LayoutW9>
  );
};
