import {
  IEngagement,
  IEngagementCreateRequest
} from "@wingspanhq/payments/dist/interfaces";
import { servicePayments } from "../servicePayments";

export const createEngagement = async (
  request: IEngagementCreateRequest
): Promise<IEngagement> => {
  const response = await servicePayments.post(`/engagement`, request);
  return response.data;
};
