import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import React from "react";
import { useDownloadPdf } from "../../../Invoices/utils";
import { Tooltip } from "../../../components/Tooltip";
import { useClientInvoiceQuery } from "../../../query/payments/queries";
import styles from "./index.module.scss";

type Props = {
  invoiceId: string;
};

export const ButtonDownloadReceipt: React.FC<Props> = ({ invoiceId }) => {
  const clientInvoiceQuery = useClientInvoiceQuery(invoiceId, {
    refetchInterval: 5000
  });
  const downloadPdf = useDownloadPdf();

  if (!clientInvoiceQuery.data) return null;

  const clientInvoice = clientInvoiceQuery.data;

  const timePassedAfterPayment =
    new Date().getTime() -
    (
      clientInvoice.events.paidAt ||
      clientInvoice.events.paymentInTransitAt ||
      new Date()
    ).getTime();

  return clientInvoice.attachments?.receiptPdf ? (
    <WSButton.Secondary
      size="S"
      onClick={() => {
        if (clientInvoice.attachments?.receiptPdf) {
          downloadPdf(clientInvoice.attachments.receiptPdf);
        }
      }}
    >
      Download receipt
    </WSButton.Secondary>
  ) : (
    <Tooltip
      trigger={
        <WSButton.Secondary size="S" disabled>
          Download receipt
        </WSButton.Secondary>
      }
      toolTipClassName={styles.toolTipContent}
      arrowClassName={styles.arrow}
    >
      {timePassedAfterPayment > 120000 ? (
        <WSText.ParagraphSm>
          Please check your email for the receipt
        </WSText.ParagraphSm>
      ) : (
        <WSText.ParagraphSm>
          Generating receipt, will appear here soon
        </WSText.ParagraphSm>
      )}
    </Tooltip>
  );
};
