import { WSButton, WSLoader, WSText } from "@wingspanhq/fe-component-library";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useSingleSignIn } from "../../query/users/mutations";

export const AuthSSO: React.FC<RouteComponentProps> = ({
  history,
  location
}) => {
  const code = new URLSearchParams(location.search).get("code");
  const [sso, ssoMeta] = useSingleSignIn();

  useEffect(() => {
    if (code) {
      sso(
        { code },
        {
          onSuccess() {
            history.push("/member/dashboard");
          }
        }
      );
    }
  }, [code]);

  if (!code || ssoMeta.isError) {
    return (
      <WSText my="6XL" align="center">
        Something went wrong. Go to{" "}
        <WSButton.Link
          onClick={() => {
            history.push("/member/sign-in");
          }}
        >
          Sign In
        </WSButton.Link>
      </WSText>
    );
  }

  return <WSLoader.Spinner size="L" my="6XL" />;
};
