import { useEffect } from "react";
import { useUserId } from "../query/hooks/helpers";
import { useSubscriptionV3Query } from "../query/subscriptions/queries";
import {
  useClientQuery,
  useMemberProfile,
  useUserProfile
} from "../query/users/queries";
import { identifyWithCommonFields, page } from "./analytics";
import { IAddress } from "@wingspanhq/users/dist/lib/interfaces";

export const useIdentify = () => {
  const userId = useUserId();
  const userQuery = useUserProfile(userId, {
    retry: false
  });
  const memberQuery = useMemberProfile(userId, {
    retry: false
  });
  const clientQuery = useClientQuery(userId);
  const subscriptionQuery = useSubscriptionV3Query(userId);

  useEffect(() => {
    if (
      userQuery.isFetched &&
      memberQuery.isFetched &&
      clientQuery.isFetched &&
      subscriptionQuery.isFetched
    ) {
      if (!window.userEmail) {
        window.userEmail = userQuery.data?.email as string;
        window.intercomUserHash = userQuery.data?.intercomUserHash as string;
        page();
      }

      const ownerIds = userQuery.data?.userRoles?.ownerIds;
      const formattedOwnerIds =
        ownerIds && ownerIds.length > 0 ? ownerIds.join("-") : undefined;

      const getValidAddress = (address?: IAddress) => {
        if (
          address?.addressLine1 &&
          address?.city &&
          address?.state &&
          address?.postalCode &&
          address?.country
        ) {
          return {
            street: address.addressLine2
              ? `${address.addressLine1}, ${address.addressLine2}`
              : address.addressLine1,
            city: address.city,
            state: address.state,
            postalCode: address.postalCode,
            country: address.country
          };
        }
        return undefined;
      };

      const formattedAddress =
        getValidAddress(memberQuery.data?.profile?.homeAddress) ||
        getValidAddress(memberQuery.data?.profile?.address);

      const company = memberQuery.data?.profile?.company;
      const companyName =
        (company as any)?.doingBusinessAs ||
        company?.legalBusinessName ||
        company?.name;

      identifyWithCommonFields(userId, {
        email: userQuery.data?.email,
        intercomUserHash: userQuery.data?.intercomUserHash,
        ownerIds: formattedOwnerIds,
        address: formattedAddress,
        website: company?.website || company?.links?.websiteUrl,
        companyName: companyName,
        legalBusinessName: company?.legalBusinessName,
        phone: userQuery.data?.phone?.number
      });
    }
  }, [
    clientQuery.data?.clientId,
    clientQuery.isFetched,
    memberQuery.data?.memberId,
    memberQuery.data?.profile,
    memberQuery.isFetched,
    subscriptionQuery.data?.createdAt,
    subscriptionQuery.data?.trialEnd,
    subscriptionQuery.isFetched,
    userId,
    userQuery.data?.email,
    userQuery.data?.userRoles,
    userQuery.data?.intercomUserHash,
    userQuery.data?.profile,
    userQuery.isFetched,
    memberQuery.data?.profile?.homeAddress,
    memberQuery.data?.profile?.address,
    memberQuery.data?.profile?.company
  ]);
};
