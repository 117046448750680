import {
  WSGrid,
  WSMarginProps,
  WSPanel
} from "@wingspanhq/fe-component-library";
import { IPayerResponse } from "@wingspanhq/payments/dist/interfaces";
import { InfoItem, InfoItemProps } from "./InfoItem";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";

import { useMemo } from "react";

import styles from "./styles.module.scss";

export interface ClientInfoPanelProps extends WSMarginProps {
  client: IPayerResponse;
}

export const ClientInfoPanel: React.FC<ClientInfoPanelProps> = ({
  client,
  ...elementProps
}) => {
  const fields = useMemo(() => {
    const names = wsName({
      user: client.user!,
      member: client.member,
      payeeOwnedData: client.payeeOwnedData
    });

    const address = client.member?.profile?.address;
    const { payerName, payerCompanyName } = client.payeeOwnedData;

    const result: InfoItemProps[] = [
      {
        label: "DBA (doing business as)",
        value: names.companyName
      },
      {
        label: "Business name",
        value: names.legalBusinessName || payerCompanyName
      },
      {
        label: "Name",
        value: names.fullName || payerName
      },
      {
        label: "Email",
        value: names.email
      },
      {
        label: "Location",
        value: [address?.city, address?.country].filter(Boolean).join(", ")
      },
      {
        label: "External ID",
        value: names.externalId
      },
      {
        label: "Additional emails",
        value: client.payeeOwnedData.payerEmailCC?.join(", ")
      }
    ].filter(i => i.value);

    return result;
  }, [client]);

  return (
    <WSPanel className={styles.panel} {...elementProps}>
      <WSGrid gutter="2XL">
        {fields.map(({ label, value }) => (
          <WSGrid.Item key={label} span={{ xs: "12", s: "6", l: "6" }}>
            <InfoItem label={label} value={value} />
          </WSGrid.Item>
        ))}
      </WSGrid>
    </WSPanel>
  );
};
