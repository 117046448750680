import footprint, { FootprintComponentKind } from "@onefootprint/footprint-js";
import { queryCache } from "react-query";
import { useHistory } from "react-router-dom";
import {
  PATH_ONBOARDING_VERIFICAIONS_BANKING,
  PATH_ONBOARDING_VERIFICAIONS_TAX
} from "../../modules/Onboarding";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../../query/notifications/keys";
import { QUERY_VERIFICATIONS } from "../../query/onboarding/queries/useQueryVerifications";
import { getCustomerEntity } from "../../services/api/onboarding/customer";
import { getVerificationMissingData } from "../../services/api/onboarding/missingFields";
import { operations } from "../../services/api/onboarding/types";
import { footprintAppearanceConfig } from "../constants/footprint";

const tinIndividualMismatchFields = [
  "individualTaxId",
  "firstName",
  "lastName"
];
const tinBusinessMismatchFields = ["legalBusinessName", "businessTaxId"];

class VerificationService {
  openFootprintPlaybook(token: string, onComplete?: () => void) {
    const component = footprint.init({
      kind: FootprintComponentKind.Verify,
      variant: "modal",
      authToken: token,
      onComplete: () => {
        queryCache.invalidateQueries(QUERY_VERIFICATIONS);
        queryCache.invalidateQueries(QUERY_NOTIFICATIONS_NOTIFICATIONS);
        onComplete?.();
      },
      options: {
        showLogo: false
      },
      appearance: footprintAppearanceConfig
    });
    component.render();
  }

  async completeBankingVerification(history: ReturnType<typeof useHistory>) {
    try {
      await getCustomerEntity();
    } catch (e) {
      // No monitored entity? Go get one:
      history.push(PATH_ONBOARDING_VERIFICAIONS_BANKING);
      return;
    }

    const missingData = await getVerificationMissingData("Banking");

    if (missingData.requiredFields && missingData.requiredFields.length > 0) {
      history.push(PATH_ONBOARDING_VERIFICAIONS_BANKING);
      return;
    }

    if (missingData.completeOnboardingToken) {
      this.openFootprintPlaybook(missingData.completeOnboardingToken);
      return;
    }

    throw new Error("No completeOnboardingToken or requiredFields provided");
  }

  async completeTaxVerification(history: ReturnType<typeof useHistory>) {
    try {
      await getCustomerEntity();
    } catch (e) {
      // No monitored entity? Go get one:
      history.push(PATH_ONBOARDING_VERIFICAIONS_TAX);
      return;
    }

    const missingData = await getVerificationMissingData("Tax");

    if (missingData.requiredFields && missingData.requiredFields.length > 0) {
      history.push(PATH_ONBOARDING_VERIFICAIONS_TAX);
      return;
    }

    if (missingData.completeOnboardingToken) {
      this.openFootprintPlaybook(missingData.completeOnboardingToken);
      return;
    }

    throw new Error("No completeOnboardingToken or requiredFields provided");
  }

  getIsIndividualMismatch(missingFields: string[]) {
    return this.complareLists(missingFields, tinIndividualMismatchFields);
  }

  getIsBusinessMismatch(missingFields: string[]) {
    return this.complareLists(missingFields, tinBusinessMismatchFields);
  }

  getVerificationStatus(
    verificationLevel: operations["getVerificationMissingData"]["parameters"]["path"]["verificationLevel"],
    verifications: operations["getVerifications"]["responses"]["200"]["content"]["application/json"]
  ) {
    return (
      verifications[
        verificationLevel.toLowerCase() as keyof typeof verifications
      ] || "None"
    );
  }

  private complareLists(list1: string[], list2: string[]) {
    return (
      list1.length === list2.length &&
      list1.every(field => list2.includes(field))
    );
  }
}

export const verificationService = new VerificationService();
