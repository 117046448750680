import {
  IWebflowBenefit,
  IWebflowEnterprise,
  IWebflowPartner
} from "@wingspanhq/cms/dist/lib/interfaces";
import { QueryConfig } from "react-query";
import {
  getWebflowBenefit,
  getWebflowBenefits,
  getWebflowEnterprise,
  getWebflowEnterprises,
  getWebflowPartner,
  getWebflowPartners
} from "../../services/cms";
import { WSServiceError } from "../../utils/serviceHelper";
import { useWSQuery } from "../helpers";
import {
  QUERY_CMS_WEBFLOW_BENEFIT,
  QUERY_CMS_WEBFLOW_BENEFITS,
  QUERY_CMS_WEBFLOW_ENTERPRISE,
  QUERY_CMS_WEBFLOW_ENTERPRISES,
  QUERY_CMS_WEBFLOW_PARTNER,
  QUERY_CMS_WEBFLOW_PARTNERS
} from "./keys";

export const useCMSWebflowPartner = (
  key: string,
  queryConfig?: QueryConfig<IWebflowPartner, unknown>
) => {
  return useWSQuery(
    `${QUERY_CMS_WEBFLOW_PARTNER}_${key}`,
    async () => await getWebflowPartner(key),
    {
      retry: 0,
      ...queryConfig
    }
  );
};

export const useCMSWebflowPartners = (
  queryConfig?: QueryConfig<IWebflowPartner[], unknown>
) => {
  return useWSQuery(
    QUERY_CMS_WEBFLOW_PARTNERS,
    async () => await getWebflowPartners(),
    queryConfig
  );
};

export const useCMSWebflowBenefit = (
  key: string,
  queryConfig?: QueryConfig<IWebflowBenefit, unknown>
) => {
  return useWSQuery(
    `${QUERY_CMS_WEBFLOW_BENEFIT}_${key}`,
    async () => await getWebflowBenefit(key),
    {
      retry: 0,
      ...queryConfig
    }
  );
};

export const useCMSWebflowBenefits = (
  queryConfig?: QueryConfig<IWebflowBenefit[], unknown>
) => {
  return useWSQuery(
    QUERY_CMS_WEBFLOW_BENEFITS,
    async () => await getWebflowBenefits(),
    queryConfig
  );
};

export const useCMSWebflowEnterprise = (
  key: string,
  queryConfig?: QueryConfig<IWebflowEnterprise, WSServiceError>
) => {
  return useWSQuery(
    [QUERY_CMS_WEBFLOW_ENTERPRISE, key],
    async () => await getWebflowEnterprise(key),
    {
      retry: 0,
      ...queryConfig
    }
  );
};

export const useCMSWebflowEnterprises = (
  queryConfig?: QueryConfig<IWebflowEnterprise[], WSServiceError>
) => {
  return useWSQuery(
    QUERY_CMS_WEBFLOW_ENTERPRISES,
    async () => await getWebflowEnterprises(),
    queryConfig
  );
};
