import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { ACCOUNT_MANUAL_NAME } from "../constants/accounts";

export const selectorAccountName = (account: IAccount) => {
  const { subType, mask, name } = account;
  const type = subType
    ? subType
    : name === ACCOUNT_MANUAL_NAME
    ? "Manual Bank Account"
    : undefined;

  const last4 = mask
    ? mask
    : account.numbers?.account
    ? account.numbers.account.slice(-4)
    : undefined;

  if (!type) {
    return name;
  }

  if (!last4) {
    return type;
  }

  return `${type} (${last4})`;
};
