import {
  WSButton,
  WSButtons,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

export type PromptDismissProps = {
  onClose: (result: boolean) => void;
} & WSElementProps;

export const PromptDismiss: React.FC<PromptDismissProps> = ({
  onClose,
  ...elementProps
}) => (
  <WSElement {...elementProps}>
    <WSText mb="M" color="gray500">
      This can't be undone.
    </WSText>

    <WSButtons format="modal">
      <WSButton.Primary onClick={() => onClose(true)}>Dismiss</WSButton.Primary>
      <WSButton.Tertiary onClick={() => onClose(false)}>
        Go back
      </WSButton.Tertiary>
    </WSButtons>
  </WSElement>
);
