import {
  WSIconName,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

type Props = { items: string[]; icon: WSIconName } & WSElementProps;

export const List: React.FC<Props> = ({ items, icon, ...elementProps }) => (
  <WSElement {...elementProps}>
    {items.map((item, index) => (
      <WSFlexBox.CenterY
        key={`${index}_${item}`}
        mb={index + 1 === items.length ? undefined : "S"}
      >
        <WSIcon block name={icon} color="green500" mr="M" />
        <WSText>{item}</WSText>
      </WSFlexBox.CenterY>
    ))}
  </WSElement>
);
