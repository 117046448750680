import {
  useIsMobile,
  WSButton,
  WSAvatar,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSIconProps,
  WSImage,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { getPathForBenefit } from "../../utils";
import { useHistory } from "react-router-dom";
import { track } from "../../../utils/analytics";
import { addNotification } from "../../../components/Notification/Notification";

export enum BenefitBlockStatus {
  INCLUDED = "INCLUDED",
  DEFAULT = "DEFAULT",
  PREMIUM = "PREMIUM",
  COMING_SOON = "COMING_SOON"
}

export type BenefitBlockProps = {
  icon: WSIconProps["name"];
  customIcon?: string;
  cost?: string;
  status: BenefitBlockStatus;
  isPremium?: boolean;
  title: string;
  subtitle?: string | React.ReactNode;
  description: React.ReactNode;
  parentPath?: string;
  pathOverride?: string;
};

export const BenefitBlock: React.FC<BenefitBlockProps> = ({
  icon,
  title,
  customIcon,
  status,
  cost,
  description,
  parentPath,
  isPremium,
  pathOverride
}) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const path = getPathForBenefit(title, parentPath);

  const joinWaitlist = () => {
    track("Benefits Join waitlist", { title });
    addNotification({
      text: "You’re on the waitlist!"
    });
  };

  return (
    <WSPanel
      mt="L"
      p="L"
      data-testid={
        status === BenefitBlockStatus.COMING_SOON
          ? ""
          : `block-${pathOverride ? pathOverride : path}`
      }
      onClick={
        status === BenefitBlockStatus.COMING_SOON
          ? undefined
          : () =>
              history.push(pathOverride ? pathOverride : path, {
                comingFromBenefitsPage: true
              })
      }
    >
      {isMobile ? (
        <WSElement>
          <WSFlexBox.CenterY justify="space-between">
            {customIcon ? (
              <WSImage
                placeholder={
                  <WSAvatar.Icon
                    icon={icon}
                    size="L"
                    color="blue500"
                    colorBackground="blue50"
                  />
                }
                mr="XL"
                src={customIcon}
                width={48}
              />
            ) : (
              <WSAvatar.Icon
                icon={icon}
                size="L"
                colorBackground="blue50"
                color="blue500"
                mr="XL"
              />
            )}
            {status !== BenefitBlockStatus.COMING_SOON ? (
              <WSIcon block name="chevron-right" color="blue500" />
            ) : null}
          </WSFlexBox.CenterY>
          <WSText.Heading5 mt="XL">{title}</WSText.Heading5>
          <WSText.ParagraphSm mt="XS">{description}</WSText.ParagraphSm>
          {cost ? (
            <WSText.ParagraphSm mt="XL">Cost: {cost}</WSText.ParagraphSm>
          ) : status === BenefitBlockStatus.INCLUDED ? (
            <WSText.ParagraphSm mt="XL">Cost: Included</WSText.ParagraphSm>
          ) : status === BenefitBlockStatus.DEFAULT ? (
            <WSText.ParagraphSm mt="XL">
              Cost: No additional cost
            </WSText.ParagraphSm>
          ) : status === BenefitBlockStatus.PREMIUM ? (
            <WSText.ParagraphSm mt="XL">
              Cost: Included {isPremium ? "" : "with premium"}
            </WSText.ParagraphSm>
          ) : status === BenefitBlockStatus.COMING_SOON ? (
            <WSButton.Tertiary mt="XL" fullWidth onClick={joinWaitlist}>
              Join waitlist
            </WSButton.Tertiary>
          ) : null}
        </WSElement>
      ) : (
        <WSFlexBox.CenterY wrap="nowrap">
          <WSFlexBox.CenterY
            wrap="nowrap"
            style={{ flexBasis: "75%" }}
            pr="2XL"
          >
            {customIcon ? (
              <WSImage
                placeholder={
                  <WSAvatar.Icon
                    icon={icon}
                    size="L"
                    color="blue500"
                    colorBackground="blue50"
                  />
                }
                mr="XL"
                src={customIcon}
                width={48}
              />
            ) : (
              <WSAvatar.Icon
                icon={icon}
                size="L"
                color="blue500"
                colorBackground="blue50"
                mr="XL"
              />
            )}
            <WSElement>
              <WSText.Heading5>{title}</WSText.Heading5>
              <WSText.ParagraphSm mt="XS">{description}</WSText.ParagraphSm>
            </WSElement>
          </WSFlexBox.CenterY>

          <WSFlexBox.CenterY
            justify="space-between"
            wrap="nowrap"
            style={{ flexBasis: "25%" }}
          >
            {cost ? (
              <WSText.ParagraphSm>Cost: {cost}</WSText.ParagraphSm>
            ) : status === BenefitBlockStatus.INCLUDED ? (
              <WSText.ParagraphSm>
                Cost: {cost ? cost : "Included"}
              </WSText.ParagraphSm>
            ) : status === BenefitBlockStatus.DEFAULT ? (
              <WSText.ParagraphSm>Cost: No additional cost</WSText.ParagraphSm>
            ) : status === BenefitBlockStatus.PREMIUM ? (
              <WSText.ParagraphSm>
                Cost: Included {isPremium ? "" : "with premium"}
              </WSText.ParagraphSm>
            ) : status === BenefitBlockStatus.COMING_SOON ? (
              <WSButton.Tertiary fullWidth onClick={joinWaitlist}>
                Join waitlist
              </WSButton.Tertiary>
            ) : null}

            {status !== BenefitBlockStatus.COMING_SOON ? (
              <WSIcon block name="chevron-right" color="blue500" />
            ) : null}
          </WSFlexBox.CenterY>
        </WSFlexBox.CenterY>
      )}
    </WSPanel>
  );
};
