import { WSAvatarIconProps } from "@wingspanhq/fe-component-library";
import {
  ClientWorkFlowStatus,
  IInvoice,
  InvoiceStatus
} from "@wingspanhq/payments/dist/interfaces";
import { getIsInvoiceDisputed } from "../../../query/payments/selectors";

export const buildInvoiceIcon = (
  invoice: IInvoice
): Omit<WSAvatarIconProps, "type"> =>
  invoice.status === InvoiceStatus.Draft
    ? {
        icon: "edit",
        colorBackground: "gray50",
        color: "gray500"
      }
    : getIsInvoiceDisputed(invoice)
    ? {
        icon: "alert-circle",
        colorBackground: "amber50",
        color: "amber400"
      }
    : invoice.status === InvoiceStatus.Open
    ? {
        icon: "check-envelope",
        colorBackground: "blue50",
        color: "blue500",

        ...(invoice.client.workflowStatus === ClientWorkFlowStatus.Declined
          ? {
              badge: true,
              badgeProps: {
                theme: "red"
              }
            }
          : {})
      }
    : invoice.status === InvoiceStatus.Overdue
    ? {
        icon: "check-envelope",
        colorBackground: "blue50",
        color: "blue500",
        ...(invoice.client.workflowStatus === ClientWorkFlowStatus.Declined
          ? {
              badge: true,
              badgeProps: {
                theme: "amber"
              }
            }
          : {
              badge: true,
              badgeProps: {
                theme: "red"
              }
            })
      }
    : invoice.status === InvoiceStatus.PaymentInTransit
    ? {
        icon: "check",
        colorBackground: "gray100",
        color: "green500"
      }
    : invoice.status === InvoiceStatus.Paid
    ? invoice.events.depositedAt
      ? {
          icon: "check-double",
          colorBackground: "green50",
          color: "green500"
        }
      : invoice.events.instantPayoutAt
      ? {
          icon: "check-double",
          colorBackground: "green50",
          color: "green500"
        }
      : invoice.amountDetails
      ? {
          icon: "check",
          colorBackground: "gray100",
          color: "green500"
        }
      : {
          icon: "check-double",
          colorBackground: "green50",
          color: "green500"
        }
    : {
        icon: "edit",
        colorBackground: "gray50",
        color: "gray500"
      };
