import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useGoBackOrToParent } from "../../../../utils/goToParentRoute";
import {
  WSFlexBox,
  WSPanel,
  WSSidebar
} from "@wingspanhq/fe-component-library";
import { usePayeeEngagementQuery } from "../../../../query/payeeEngagements/queries/usePayeeEngagementQuery";
import { WSQueries } from "../../../../query/WSQuery";
import { FieldView } from "../../../../shared/components/FieldView/FieldView";
import { PayeeIntegrationWidget } from "../../../Payers/components/IntegrationsSection/PayeeIntegrationWidget";
import { usePayeeQuery } from "../../../../query/payee/queries/usePayeeQuery";
import styles from "./index.module.scss";
import { PayerPayeeEngagementStatus } from "@wingspanhq/payments/dist/interfaces/payerPayeeEngagement";
import { useCollaboratorGroupsQuery } from "../../../../query/payments/queries";
import { selectorPayeeEngagementNameTmp } from "../../selectors/selectorPayeeEngagementsTmp";
import { RequirementsTable } from "../../../Requirements/components/RequirementsTable";
import { RequirementContextType } from "../../../Requirements/types";
import { selectorRequirementsFromEngagement } from "../../../Requirements/selectors/selectorRequirementsFromEngagement";
import { selectorPayerPayeeEngagementName } from "../../selectors/selectorPayerPayeeEngagementName";
import { useUserId } from "../../../../query/hooks/helpers";

type EngagementDetailsSidebarProps = RouteComponentProps<{
  engagementId: string;
  payeeId: string;
}>;

export const EngagementDetailsSidebar: React.FC<EngagementDetailsSidebarProps> = ({
  match: {
    params: { engagementId, payeeId }
  },
  history
}) => {
  const userId = useUserId();
  const onClose = useGoBackOrToParent();
  const queryPayee = usePayeeQuery(payeeId);
  const queryEngagement = usePayeeEngagementQuery(payeeId, engagementId);
  const queryGroups = useCollaboratorGroupsQuery();
  const isEngagementActive =
    queryEngagement.data?.status !== PayerPayeeEngagementStatus.Inactive;

  return (
    <WSSidebar.Container onClose={onClose}>
      <WSQueries queries={{ queryEngagement, queryPayee, queryGroups }}>
        {({ queryEngagementData, queryPayeeData, queryGroupsData }) => {
          const requirements = selectorRequirementsFromEngagement(
            queryEngagementData
          );

          const engagementName = isEngagementActive
            ? selectorPayerPayeeEngagementName(queryEngagementData, true)
            : selectorPayeeEngagementNameTmp(
                queryEngagementData,
                queryGroupsData,
                true
              );

          const isInactive =
            queryEngagementData.status === PayerPayeeEngagementStatus.Inactive;

          const groupNames = queryGroupsData
            .filter(group =>
              queryEngagementData.collaboratorGroupIds?.includes(
                group.collaboratorGroupId
              )
            )
            .map(group => group.name);

          return (
            <WSSidebar.Layout header={engagementName}>
              <WSFlexBox
                className={styles.panels}
                direction="column"
                gap="XL"
                alignItems="stretch"
              >
                <WSPanel>
                  <WSFlexBox gap="XL">
                    <FieldView label="Engagement" value={engagementName} />
                    {/*
                   <FieldView
                      label="Type"
                      value={getEngagementLabelByType(
                        queryEngagementData.type,
                        {
                          descriptive: true
                        }
                      )}
                    />
                    */}
                  </WSFlexBox>
                  {groupNames.length ? (
                    <FieldView
                      mt="XL"
                      label={`Group${groupNames.length > 1 ? "s" : ""}`}
                      value={groupNames.join(", ")}
                    />
                  ) : null}
                  {/* <FieldView
                  mt="XL"
                  label="Description"
                  value={"TODO:description"}
                /> */}
                </WSPanel>
                <WSPanel p="NONE">
                  <RequirementsTable
                    payeeId={payeeId}
                    payerId={userId}
                    requirements={requirements}
                    contextType={RequirementContextType.Payee}
                    compact
                  />
                </WSPanel>

                {/*
                <WSPanel pt="S">
                  <CollaboratorsGroupsForm
                    isInactive={isInactive}
                    collaboratorId={queryEngagementData.payerPayeeEngagementId}
                    collaboratorGroupIds={
                      queryEngagementData.collaboratorGroupIds
                    }
                  />
                </WSPanel>
                */}

                <PayeeIntegrationWidget
                  isInactive={isInactive}
                  payeeId={queryPayeeData.payeeId}
                  engagementId={queryEngagementData.payerPayeeEngagementId}
                  vendorId={
                    queryEngagement.data?.payerOwnedData.integration?.quickbooks
                      ?.vendorId
                  }
                  expenseAccountId={
                    queryEngagement.data?.payerOwnedData.integration?.quickbooks
                      ?.expenseAccountId
                  }
                />
              </WSFlexBox>
            </WSSidebar.Layout>
          );
        }}
      </WSQueries>
    </WSSidebar.Container>
  );
};
