import { IInvoicingConfigUpdateRequest } from "@wingspanhq/payments/dist/interfaces/api/invoicingConfig";
import { useWSMutation } from "../../helpers";
import { updateInvoicingConfig } from "../../../services/invoicingConfig";
import { INVOICING_CONFIG_FOR_PAYER } from "../keys";

export const useUpdateInvoicingConfig = () =>
  useWSMutation(
    (payload: IInvoicingConfigUpdateRequest) => updateInvoicingConfig(payload),
    {
      dependencies: [INVOICING_CONFIG_FOR_PAYER]
    }
  );
