import { IChannelNotificationResponse } from "@wingspanhq/notifications/dist/lib/interfaces";
import { NotificationType } from "@wingspanhq/notifications/dist/lib/interfaces/notifications";

export const selectorCountOfVisibleNotifications = (
  responses: IChannelNotificationResponse[]
) => {
  return (
    responses.filter(response => {
      return (
        response.type === NotificationType.NonDismissable ||
        (response.type === NotificationType.Dismissable &&
          !response.events.viewedAt)
      );
    }).length || 0
  );
};
