import { IPaymentCard } from "@wingspanhq/payments/dist/interfaces";
import { paymentsService } from "../../../services/payments";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation } from "../../helpers";
import { QUERY_PAYMENT_CARDS } from "../keys";

export const useMutationDeletePaymentCard = () =>
  useWSMutation<IPaymentCard, WSServiceError, { paymentCardId: string }>(
    ({ paymentCardId }) => {
      return paymentsService.paymentCard.delete(paymentCardId);
    },
    { dependencies: [QUERY_PAYMENT_CARDS] }
  );
