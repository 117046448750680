import { WSButton } from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import { useUserId } from "../../../../query/hooks/helpers";
import { useQueryPaymentsCardsDebit } from "../../../../query/paymentCards/queries/useQueryPaymentsCardsDebit";
import {
  usePayoutSettings,
  usePayoutSettingsDebitCards
} from "../../../../query/payments/queries";
import { useAccounts } from "../../../../query/users/queries";
import { LayoutFullscreen } from "../../../../shared/components/LayoutFullscreen";
import { InstantMethods } from "../../components/InstantMethods";

export const RouteAddInstantPlaid: React.FC<RouteComponentProps> = () => {
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryPayoutSettingsDebitCards = usePayoutSettingsDebitCards(userId);
  const queryPaymentCards = useQueryPaymentsCardsDebit();
  const queryAccounts = useAccounts();
  const history = useHistory();

  return (
    <LayoutFullscreen
      headTitle="Add payout method"
      title="Set default payout method"
      description="Select an account you added through Plaid as your default payout method"
      onBack={() => {
        history.push("/member/settings/payment-methods");
      }}
    >
      <WSQueries
        queries={{
          queryPayoutSettings,
          queryAccounts,
          queryPayoutSettingsDebitCards,
          queryPaymentCards
        }}
      >
        {({
          queryPayoutSettings: { data: payoutSettings },
          queryAccounts: { data: accounts },
          queryPayoutSettingsDebitCards: { data: debitCards },
          queryPaymentCards: { data: paymentCards }
        }) => (
          <InstantMethods
            payoutSettings={payoutSettings}
            accounts={accounts}
            debitCards={debitCards}
            paymentCards={paymentCards}
          />
        )}
      </WSQueries>

      <WSButton
        fullWidth
        onClick={() => {
          history.push("/member/settings/payment-methods");
        }}
      >
        Set default payout method
      </WSButton>
    </LayoutFullscreen>
  );
};
