import { IAccount } from "@wingspanhq/users/dist/lib/interfaces/account";
import React, { useEffect, useState } from "react";
import groupBy from "lodash/groupBy";
import {
  WSCheckboxToggle,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSProgressBar,
  WSText
} from "@wingspanhq/fe-component-library";
import { InstitutionLogo } from "../../../components/InstitutionLogo/InstitutionLogo";
import { getAccountSubName } from "../../utils";

export type AccountsCheckListProps = Omit<WSElementProps, "onChange"> & {
  accounts: Array<IAccount>;
  onChange(accounts: Array<IAccount>): void;
  onToggle(account: IAccount): IAccount;
  getValue(account: IAccount): boolean;
  selectedMark?: React.ReactNode;
};

export const AccountsCheckList: React.FC<AccountsCheckListProps> = ({
  accounts,
  onChange,
  onToggle,
  getValue,
  selectedMark,
  ...props
}) => {
  const institutions = groupBy(accounts, "institutionId");

  return (
    <WSElement {...props}>
      {Object.entries(institutions)
        .sort(([idA, accountsA], [idB, accountsB]) => {
          if (!accountsA[0].institution || !accountsB[0].institution) {
            return 0;
          }

          return accountsA[0].institution > accountsB[0].institution ? 1 : -1;
        })
        .map(([institutionId, accounts]) => {
          const { institution } = accounts[0];

          return (
            <WSElement key={institutionId}>
              <WSFlexBox.CenterY p="S">
                <InstitutionLogo institutionId={institutionId} />
                <WSText.Heading4 ml="M">{institution}</WSText.Heading4>
              </WSFlexBox.CenterY>
              <WSDivider />
              <WSElement>
                {accounts
                  .sort((a, b) => {
                    const _a = getAccountSubName(a);
                    const _b = getAccountSubName(b);

                    if (_a > _b) {
                      return 1;
                    } else if (_a < _b) {
                      return -1;
                    } else {
                      return 0;
                    }
                  })
                  .map(account => {
                    const isEnabled = getValue(account);

                    return (
                      <WSElement key={account.accountId}>
                        <WSFlexBox.CenterY
                          py="M"
                          pl="M"
                          justify="space-between"
                        >
                          <WSCheckboxToggle
                            ml="XS"
                            name={account.accountId}
                            value={isEnabled}
                            label={getAccountSubName(account)}
                            onChange={() => {
                              onChange(
                                accounts.map(acc => {
                                  if (acc.accountId === account.accountId) {
                                    return onToggle(acc);
                                  }

                                  return { ...acc };
                                })
                              );
                            }}
                          />
                          {isEnabled && selectedMark ? selectedMark : null}
                        </WSFlexBox.CenterY>
                        <WSDivider />
                      </WSElement>
                    );
                  })}
              </WSElement>
            </WSElement>
          );
        })}
    </WSElement>
  );
};

export const AccountsCheckLoading: React.FC<{
  institutionId?: string;
  institutionName?: string;
}> = ({ ...props }) => {
  const [text, setText] = useState("Linking...");
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    let seconds = 0;
    const timer = window.setInterval(() => {
      ++seconds;

      if (seconds > 35) {
        setText("Taking a bit longer than expected, hang in there.");
        setPercent(75);
      } else if (seconds > 3) {
        setText("Downloading transactions...");
        setPercent(50);
      } else {
        setText("Linking...");
        setPercent(20);
      }
    }, 1000);

    return () => {
      window.clearInterval(timer);
    };
  }, []);

  return (
    <WSElement {...props}>
      <WSFlexBox.CenterY p="S">
        <InstitutionLogo institutionId={props.institutionId} />
        <WSText.Heading5 ml="M">{props.institutionName}</WSText.Heading5>
      </WSFlexBox.CenterY>
      <WSDivider />
      <WSProgressBar mt="2XL" percent={percent} text={text} />
    </WSElement>
  );
};
