import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RouteRequestLinkSuccess } from "./RouteRequestLinkSuccess";
import { RouteRequestLink } from "./RouteRequestLink";

export const REQUEST_INVITATION_USER_LINK_PATH = "/member/invitation/resend";

export const REQUEST_INVITATION_USER_LINK_SUCCESS_PATH =
  REQUEST_INVITATION_USER_LINK_PATH + "/:email";

export const RouteRequestLinkIndex: React.FC = ({}) => {
  return (
    <Switch>
      <Route
        path={REQUEST_INVITATION_USER_LINK_PATH}
        exact
        component={RouteRequestLink}
      />
      <Route
        path={REQUEST_INVITATION_USER_LINK_SUCCESS_PATH}
        exact
        component={RouteRequestLinkSuccess}
      />
      <Redirect from="*" to="/member/sign-in" />
    </Switch>
  );
};
