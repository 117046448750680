import {
  WSButton,
  WSButtons,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";

type Props = {
  onClose: () => void;
  onContinue: () => void;
  onBack?: () => void;
};

export const ModalGoogleAuthWarning: React.FC<Props> = ({
  onContinue,
  onBack,
  onClose
}) => (
  <>
    <WSText.Heading3 mb="S">Sign in with Google</WSText.Heading3>
    <WSText mb="S">
      Please ensure you are signing in with the correct Google email address
    </WSText>

    <WSMessageBox.Info mb="M">
      <WSText color="gray500" mb="M">
        You must sign-in with the email address associated with your Wingspan
        account (email the invite was sent to), otherwise the correct
        information may not exist. If your existing Wingspan account is not
        associated with a Google email, click “Back.”
      </WSText>
    </WSMessageBox.Info>

    <WSButtons forceFullWidth>
      <WSButton.Secondary
        type="button"
        onClick={() => {
          onContinue();
          onClose();
        }}
      >
        Sign in with Google
      </WSButton.Secondary>
      <WSButton.Tertiary
        type="button"
        onClick={() => {
          onBack?.();
          onClose();
        }}
      >
        Back
      </WSButton.Tertiary>
    </WSButtons>
  </>
);
