export const PAYERS_FEATURES = [
  {
    title: "Effortless Onboarding",
    description:
      "Seamlessly onboard contractors with a streamlined, self-service portal. Reduce compliance risks with automated W-9/W-8 collection and verification."
  },
  {
    title: "Automated Payments",
    description:
      "Simplify your accounts payable with automated invoicing and payment processing."
  },
  {
    title: "Comprehensive Tax Compliance",
    description:
      "Easily generate, file, and deliver 1099 forms, freeing your team from the burden of tax season."
  },
  {
    title: "Offer Competitive Benefits",
    description:
      "Attract and retain top talent by offering access to health, dental, vision, and life insurance."
  },
  {
    title: "Enhanced Security and Compliance",
    description:
      "Keep your data secure with industry-leading practices and certifications, including SOC 2 and PCI compliance."
  },
  {
    title: "Real-Time Insights and Reporting",
    description:
      "Generate detailed reports to gain insights into payments, compliance status, and contractor performance. Use customizable reporting tools to make informed decisions and optimize your operations."
  }
];

export const PAYEES_FEATURES = [
  {
    title: "Effortless Onboarding",
    description:
      "Get started quickly with a simple and intuitive onboarding process."
  },
  {
    title: "Access to Benefits",
    description:
      "Enjoy the perks of full-time employment with access to health, dental, vision, and life insurance."
  },
  {
    title: "Streamlined Payments",
    description:
      "Receive timely and accurate payments with multiple flexible options."
  },
  {
    title: "Dedicated Support",
    description:
      "Access a dedicated help desk for any questions or issues related to payments, taxes, or benefits."
  }
];
