import {
  useModalContext,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useDeleteTransaction } from "../../../query/bookkeeping/mutations";
import { useHistory } from "react-router-dom";

type DeleteTransactionProps = {
  transactionId: string;
};

export const BOOKKEEPING_DELETE_TRANSACTION = "BOOKKEEPING_DELETE_TRANSACTION";

export const DeleteTransaction: React.FC = () => {
  const { closeModal } = useModalContext();
  const history = useHistory();
  const [deleteTransaction, deleteTransactionMeta] = useDeleteTransaction();

  return (
    <WSModal
      name={BOOKKEEPING_DELETE_TRANSACTION}
      size="S"
      title="Are you sure you want to delete this transaction?"
    >
      {(props: DeleteTransactionProps) => (
        <>
          <WSText mb="M">Once confirmed, this action can’t be undone.</WSText>

          <WSButtons forceFullWidth format="modal">
            <WSButton
              destructive
              loading={deleteTransactionMeta.isLoading}
              onClick={async () => {
                await deleteTransaction(props.transactionId);
                history.push("/member/bookkeeping/transactions");
                closeModal(BOOKKEEPING_DELETE_TRANSACTION);
              }}
              name="confirmDeleteTransaction"
            >
              Delete
            </WSButton>
            <WSButton.Tertiary
              onClick={() => closeModal(BOOKKEEPING_DELETE_TRANSACTION)}
            >
              Cancel
            </WSButton.Tertiary>
          </WSButtons>
        </>
      )}
    </WSModal>
  );
};
