import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

enum Image {
  Complete = "Complete",
  NoResults = "NoResults"
}

type Props = {
  image?: keyof typeof Image;
  title: string;
  description: string;
} & WSElementProps;

export const EmptyState: React.FC<Props> = ({
  image = "Complete",
  title,
  description,
  ...elementProps
}) => {
  const imageClassName = classNames(
    styles.bodyImage,
    styles[`bodyImage${image}`]
  );

  return (
    <WSFlexBox.Center direction="column" {...elementProps}>
      <WSElement py="6XL" className={imageClassName} />
      <WSText.Heading5 mb="S">{title}</WSText.Heading5>
      <WSText.ParagraphSm color="gray400">{description}</WSText.ParagraphSm>
    </WSFlexBox.Center>
  );
};
