import { formatReportsDate } from "./formatReportsDate";

export const formatReportsValue = (value?: any) => {
  if (value instanceof Date) {
    return formatReportsDate(value);
  } else if (typeof value === "number") {
    return `${value}`;
  } else if (Array.isArray(value)) {
    return value.join(", ");
  } else if (value?.constructor === Object) {
    return JSON.stringify(value);
  } else if (typeof value === "string") {
    return value;
  } else if (typeof value === "boolean") {
    return value ? "yes" : "no";
  }

  return undefined;
};
