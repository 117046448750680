import {
  CategoryItem,
  CategoryItemValue,
  CategorySections,
  PseudoCategory
} from "./categories";
import {
  TransactionType,
  WSCategory
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { CATEGORY_ICONS } from "../../utils";

export type WSCategoriesData = {
  type: TransactionType;
  wsCategory: WSCategory;
  subcategory?: string;
  business: boolean;
  isPositive: boolean;
  isFiltering?: boolean;
};

export const isEqualValues = (a: CategoryItemValue, b?: CategoryItemValue) => {
  if (!Object.values(a).length && !Object.values(b || {}).length) {
    return false;
  }

  if ((a.subcategory || b?.subcategory) && a.subcategory !== b?.subcategory) {
    return false;
  }

  return (
    a.wsCategory === b?.wsCategory && a.pseudoCategory === b?.pseudoCategory
  );
};

export const getAllItems = (list: CategoryItem[]) => {
  const all: CategoryItem[] = [...list];

  const addChildren: (children: CategoryItem[]) => void = children => {
    // eslint-disable-next-line no-restricted-syntax
    for (const child of children) {
      if (child.children) {
        addChildren(child.children);
      }
      if (!all.includes(child)) {
        all.push(child);
      }
    }
  };

  addChildren(list);

  return all;
};

export const getAllItemsFromSections = (sections: CategorySections) => {
  const mainList = sections.reduce<CategoryItem[]>(
    (acc, c) => [...acc, ...c.categories],
    []
  );

  return getAllItems(mainList);
};

export const getItemByValue = (
  list: CategoryItem[],
  value?: CategoryItemValue
) => list.find(item => isEqualValues(item.value, value));

export const itemIsMarked = (item: CategoryItem, value?: CategoryItemValue) => {
  let result = false;

  if (
    item.value.subcategory
      ? isEqualValues(item.value, value)
      : (item.value.wsCategory || value?.wsCategory) &&
        item.value.wsCategory === value?.wsCategory &&
        item.value.pseudoCategory === value?.pseudoCategory
  ) {
    result = true;
  }

  if (item.children && !result) {
    result = getAllItems(item.children).some(
      i =>
        i.value.wsCategory === value?.wsCategory &&
        (value?.pseudoCategory || i.value.pseudoCategory
          ? i.value.pseudoCategory === value?.pseudoCategory
          : true)
    );
  }

  return result;
};

export const CATEGORY_ICON: { [key: string]: any } = {
  ...CATEGORY_ICONS,
  [PseudoCategory.EXPENSE]: "minus-circle",
  [PseudoCategory.EXPENSE_REFUND]: "plus-circle",
  [PseudoCategory.IGNORED]: "block",
  [PseudoCategory.INCOME_REFUND]: "minus-circle"
};

export const getCategoryIcon = (value: CategoryItemValue) => {
  const name = value.wsCategory || value.pseudoCategory || "info-circle";
  return CATEGORY_ICON[name] ? CATEGORY_ICON[name] : "info-circle";
};
