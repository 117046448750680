import { queryCache } from "react-query";
import { QUERY_BOOKKEEPING_BANKING_ACCOUNT } from "../../query/bookkeeping/keys";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { QUERY_TAXES_WITHHOLDING_BALANCE } from "../../query/taxes/keys";

export function useShouldPauseAccount(type: "wallet" | "taxes") {
  const queryFeatureFlags = useFeatureFlags();

  return (
    queryFeatureFlags.data?.accountPause &&
    (type === "wallet"
      ? !queryCache.getQueryData(QUERY_BOOKKEEPING_BANKING_ACCOUNT)
      : !queryCache.getQueryData(QUERY_TAXES_WITHHOLDING_BALANCE))
  );
}
