import React from "react";

import {
  WSAvatar,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSIconName,
  WSImage,
  WSPanel,
  WSText,
  isValidIconName,
  useIsMobile
} from "@wingspanhq/fe-component-library";

import { IWebflowBenefit } from "@wingspanhq/cms/dist/lib/interfaces/webflow/benefits";
import { ISubscription } from "@wingspanhq/users";
import { useHistory } from "react-router-dom";
import { getCostOfBenefitWithSubscription } from "../utils";

interface BenefitsCMSBlockProps {
  benefit: IWebflowBenefit;
  subscription?: ISubscription;
  parentPath: string;
}

export const BenefitsCMSBlock: React.FC<BenefitsCMSBlockProps> = ({
  benefit,
  subscription,
  parentPath
}) => {
  const isMobile = useIsMobile();
  const history = useHistory();

  const {
    atAGlance,
    logo,
    icon: iconString,
    helperCostDescription,
    name,
    slug
  } = benefit;

  const pagePath =
    history.location.pathname.substr(-1) === "/"
      ? `${history.location.pathname}${slug}`
      : `${history.location.pathname}/${slug}`;

  let icon: WSIconName = "heart";
  if (!logo?.src && iconString) {
    if (isValidIconName(iconString)) {
      icon = iconString;
    }
  }

  return (
    <WSPanel
      key={slug}
      data-testid={`block-${slug}`}
      onClick={() => history.push(pagePath, { comingFromBenefitsPage: true })}
      mt="L"
      p="L"
    >
      {isMobile ? (
        <WSElement>
          <WSFlexBox.CenterY justify="space-between">
            {logo?.src ? (
              <WSImage
                placeholder={
                  <WSAvatar.Icon
                    icon={icon}
                    size="L"
                    color="blue500"
                    colorBackground="blue50"
                  />
                }
                mr="XL"
                src={logo.src}
                width={48}
              />
            ) : (
              <WSAvatar.Icon
                icon={icon}
                size="L"
                color="blue500"
                colorBackground="blue50"
                mr="XL"
              />
            )}
          </WSFlexBox.CenterY>
          <WSText.Heading5 mt="XL">{name}</WSText.Heading5>
          <WSText.ParagraphSm mt="XS">{atAGlance}</WSText.ParagraphSm>
          <WSText.ParagraphSm mt="XL">
            Cost: {getCostOfBenefitWithSubscription(benefit, subscription)}
          </WSText.ParagraphSm>
        </WSElement>
      ) : (
        <WSFlexBox.CenterY wrap="nowrap">
          <WSFlexBox.CenterY
            wrap="nowrap"
            style={{ flexBasis: "75%" }}
            pr="2XL"
          >
            {logo ? (
              <WSImage
                placeholder={
                  <WSAvatar.Icon
                    icon={icon}
                    size="L"
                    color="blue500"
                    colorBackground="blue50"
                  />
                }
                mr="XL"
                src={logo.src}
                width={48}
              />
            ) : (
              <WSAvatar.Icon
                icon={icon}
                size="L"
                color="blue500"
                colorBackground="blue50"
                mr="XL"
              />
            )}
            <WSElement>
              <WSText.Heading5>{name}</WSText.Heading5>
              <WSText.ParagraphSm mt="XS">{atAGlance}</WSText.ParagraphSm>
            </WSElement>
          </WSFlexBox.CenterY>

          <WSFlexBox.CenterY
            justify="space-between"
            wrap="nowrap"
            style={{ flexBasis: "25%" }}
          >
            <WSText.ParagraphSm>
              Cost: {getCostOfBenefitWithSubscription(benefit, subscription)}
            </WSText.ParagraphSm>
            <WSIcon block name="chevron-right" color="blue500" />
          </WSFlexBox.CenterY>
        </WSFlexBox.CenterY>
      )}
    </WSPanel>
  );
};
