import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React, { ReactNode } from "react";
import styles from "./Timeline.module.scss";

type Props = {
  items: {
    date: Date;
    text: ReactNode;
    badges?: string[];
  }[];
} & WSElementProps;

export const Timeline: React.FC<Props> = ({ items, ...elementProps }) => (
  <WSElement {...elementProps}>
    {items.map((item, index) => (
      <WSElement
        key={String(item.date.getTime()) + String(index)}
        className={classNames(styles.item)}
        mb="XL"
      >
        <WSFlexBox.CenterY mb="XS">
          {(item.badges || []).filter(Boolean).map(badge => (
            <WSText.ParagraphSm
              key={badge}
              className={styles.badge}
              color="gray400"
              mr="M"
            >
              {badge}
            </WSText.ParagraphSm>
          ))}
          <WSText.ParagraphSm
            className={styles.badge}
            formatDate="monthDayYear"
            color="gray400"
          >
            {item.date}
          </WSText.ParagraphSm>
        </WSFlexBox.CenterY>

        <WSText color="gray600">{item.text}</WSText>
      </WSElement>
    ))}
  </WSElement>
);
