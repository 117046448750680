import {
  WSDivider,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { openInNewTab } from "../../../shared/utils/openInNewTab";

type Props = WSElementProps;

const link = (url: string) => () => {
  openInNewTab(url);
};
export const FAQ1099: React.FC<Props> = props => (
  <WSElement {...props}>
    <WSText weight="medium" mb="2XL">
      Frequently asked questions
    </WSText>

    <WSElement mb="2XL">
      <WSText.ParagraphSm weight="medium" color="gray400">
        About the form
      </WSText.ParagraphSm>
      <WSDivider my="M" />
      <WSText.ParagraphSm
        onClick={link(
          "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_962b6bc6c0"
        )}
      >
        What is the 1099-NEC Form?
      </WSText.ParagraphSm>
      <WSText.ParagraphSm
        onClick={link(
          "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_348c9b2970"
        )}
        mt="M"
      >
        When will I receive my 1099-NEC?
      </WSText.ParagraphSm>
      <WSText.ParagraphSm
        onClick={link(
          "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions?q=electron#h_0bc0865f71"
        )}
        mt="M"
      >
        Is it available electronically?
      </WSText.ParagraphSm>
    </WSElement>

    <WSElement mb="2XL">
      <WSText.ParagraphSm weight="medium" color="gray400">
        Payments
      </WSText.ParagraphSm>
      <WSDivider my="M" />
      <WSText.ParagraphSm
        onClick={link(
          "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_08831d4825"
        )}
      >
        What amounts are included?
      </WSText.ParagraphSm>
      <WSText.ParagraphSm
        onClick={link(
          "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_b93d20c425"
        )}
        mt="M"
      >
        Why is the amount different than I expected?
      </WSText.ParagraphSm>
    </WSElement>

    <WSElement mb="2XL">
      <WSText.ParagraphSm weight="medium" color="gray400">
        Corrections & amendments
      </WSText.ParagraphSm>
      <WSDivider my="M" />
      <WSText.ParagraphSm
        onClick={link(
          "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_748f87fef0"
        )}
      >
        What if there’s an error on the form?
      </WSText.ParagraphSm>
      <WSText.ParagraphSm
        onClick={link(
          "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
        )}
        mt="M"
      >
        Can I get a corrected 1099-NEC?
      </WSText.ParagraphSm>
      <WSText.ParagraphSm
        onClick={link(
          "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
        )}
        mt="M"
      >
        What if I never receive my 1099-NEC?
      </WSText.ParagraphSm>
    </WSElement>

    <WSElement>
      <WSText.ParagraphSm weight="medium" color="gray400">
        Misc
      </WSText.ParagraphSm>
      <WSDivider my="M" />
      <WSText.ParagraphSm
        onClick={link(
          "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
        )}
        mt="M"
      >
        What should I do if I stop contracting mid-year?
      </WSText.ParagraphSm>
      <WSText.ParagraphSm
        onClick={link(
          "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
        )}
        mt="M"
      >
        Is this form reported to the IRS?
      </WSText.ParagraphSm>
      <WSText.ParagraphSm
        onClick={link(
          "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
        )}
        mt="M"
      >
        What happens if I dispute the amount?
      </WSText.ParagraphSm>
    </WSElement>
  </WSElement>
);
