import React, { useEffect, useLayoutEffect } from "react";
import {
  useIsDesktop,
  WSElement,
  WSScreen
} from "@wingspanhq/fe-component-library";
import { SectionInfo } from "../SettingsSectionInfo/SectionInfo.component";
import kebabCase from "lodash/kebabCase";
import { useLocation } from "react-router-dom";
import { useScrollIntoViewByHash } from "../../../utils/useScrollIntoViewByHash";

export interface SettingsSectionProps {
  title: string;
  description?: string;
  render(): React.ReactNode;
}

export const SettingsSection: React.FC<SettingsSectionProps> = ({
  title,
  description,
  render
}) => {
  const id = kebabCase(title);
  const scrollableRef = useScrollIntoViewByHash(id);

  return (
    <WSElement mb="3XL" ref={scrollableRef}>
      <WSScreen.Desktop>
        <SectionInfo title={title} description={description} />
      </WSScreen.Desktop>
      {render()}
    </WSElement>
  );
};
