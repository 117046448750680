import React from "react";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";

import styles from "./WSTodoList.module.scss";

export interface WSTodoListProps extends WSElementProps {
  items: { mark: React.ReactNode; content: React.ReactNode }[];
}

export const WSTodoList: React.FC<WSTodoListProps> = ({ items, ...other }) => (
  <WSElement className={styles.container} {...other}>
    {items.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <WSElement key={index} className={styles.item}>
        <WSElement className={styles.mark}>{item.mark}</WSElement>
        <WSElement className={styles.content}>{item.content}</WSElement>
      </WSElement>
    ))}
  </WSElement>
);
