// hardcoded list of 1099 only payers

export const NEC_1099_ONLY_PAYERS = [
  "JpQzWw5hGTmXR32G6uc4uV",
  "aLqum_a4IYyLkZ9dhkoq5V",
  "g1JNn0hHI1q1Rs7_qu0A4V",
  "IcV99WkVHRiXw3DdSQx9jV",
  "iaNGQlGhHrm5cG27Yekoo0",
  "zuOQYpYXIIuQZ8BJnhe7Ok",
  "AFh2n0SsG0e1U08SCI4e9k",
  "vQbwQVyYGt59wuwj0ZG2GF",
  "NjMhxu0hFIHYGxaUetAuvk",
  "y_40u1bdGFPcpHzssPJyIV",
  "7FuKfXEaFWeqwmm_07Al7k",
  "D1gqW50bGOmGQ5PoF8FXNV",
  "69yCLsSPI4e0WCkEX4yyZF",
  "2RdZPRyhHau6WcPVRoXHjV",
  "3uF7Jgx7FdLXBJ8arwkcFk",
  "3tCMP1_TIAH_VtlABv6Kck",
  "QdFZBHfyFlmOK0fn_8Xfqk",
  "02dC6DIQH9asz1xWezor9V",
  "l5m4OQ0nG3e79aErHTgb20",
  "8pIEoVPgGXubH10Obuwvl0",
  "6yRhzGsVFbqyJkEEn5ah0k",
  "sv8vZ4S6HReke4POr6Q20k",
  "D4GymajbHzeZpvwBTNS_aF",
  "piwJyvFrHi95X3jUfRFDFk",
  "etYNaragHfy9KZdnK0Ljhk",
  "NJEjrqM1FSXCCMHuGjAJaV",
  "IwRtSBGsIsenXLJ85ELV8F",
  "KK3nhk83H1mi6d8R0ncPLV",
  "Y8rvmmMqHnL7dMVYFerzAF",
  "884e0DCKFmu_m1Zm22LjGV",
  "ma5kX8ewI_eg0Er3jos_ck",
  "K_T9ThznIbqWh25CP7e0Ak",
  "vsMTZiZGIXywLY3u4bKlj0",
  "wdfdv96OGsqTuc0AiOlRWF",
  "O2hRD5wSH8DwbWbbe3JWEF",
  "JLWLY_HFIZ57HNqf9YhcHV",
  "4n_2845iIpXMa_40AD1RTV",
  "Hntp6bbQGJuhpKvlPTOjAk",
  "i7yeVmTvH7Djn6r5Uqfckk",
  "VnKMLnNAFxi0UlDwEXICIk",
  "dC1CuqCSHlTsyUu5qHN4B0",
  "6EWNFX2eGd96NCA1BtMYqk",
  "SopN0TtzIlPIvWsPzJoLP0",
  "rxzYOGGGFiDPj7Kg0EwoiF",
  "xyjOca3QG5DwJNz4X267SF",
  "Dsr0SlMMGUyrKyOEANr2EV",
  "z8au0aKGIJ9_pnee1qYdek",
  "yCIG6c7HIgyeHhPEEIpIe0",
  "TKABbaLaF31rdNbkAHWgtV",
  "_qq03nXRF6X53mQQom6f8F",
  "jlTYBCT9H8yBUYF9RqlNOV",
  "Gz2BFrI2HBuhfuxbKtj9Kk",
  "pOKAohk0HgH9Yk3e44gxyk",
  "0O_wBO2cGUPVPL8aLNiOGV",
  "H10CVrluFf1mMmZPQ5ogEV",
  "1oei44qAG_eq6D0OxmbiiV",
  "aon0hw4gHMDGR1XxhKh3d0",
  "hT28GVDeIteAJKtSI155lV",
  "SZTPGQp5HSaNJo3_iwQi0F",
  "WkxVsJYKG4q_xrPOXQpVMV",
  "SfGfNBqHH4mkJVkL7ukz_0",
  "mJmmRLtfHmHX2hfNjiqh0V",
  "ypiBQf4gIdqcfg7lDtOhJ0",
  "dzZ0xa1bGzTM38jLqvcyc0",
  "ktR4OWiAGcyqA8fLv0g8A0",
  "0zDI5MCQGUPcE1cIoxFb8k",
  "aGcbISzAIR9rDMZvTsHhIk",
  "0Z3uNRt1IZP9Lz25xr8TyF",
  "YpMfrnBRIx97Dd4yf7p40F",
  "14H4pubvHs5UZq_VI9BqsF",
  "cfFnEaBCGbPvUyZq9YOp90",
  "CbIESVsKHU90_nmZVxj54V",
  "Koc80gwiGj9onRHPf0YYlV",
  "rN15TKDuGNTczJzAa3fPlk",
  "w2G4JhzFF6eSV8qPlU4QA0",
  "u7BBNoxqH9aS07zlbSkwmk",
  "nGEcwV9RIpm4ANasqkmOzV",
  "X_aYEZB5FdqeLfHQJ_gSXV",
  "TFBJ10laF3m8AGBCrZ3kwV",
  "_kn1UoqQIBq0jwwp5wNxsV",
  "IWmYRjEAIkL4IjoIRY4hy0",
  "HVwqPlbfGB12exUJHUDVgV",
  "ChT7eftQIyD2HX6d30jE3F",
  "g_pdsT5qHuLHqGPT61Q_r0",
  "6IeqJll0IRmJfC6YmPdF7F",
  "nav1BxTsI7e_hBNAugkTKF",
  "Dll0JXTVHC1hBpLp5iZ8BV",
  "1HCRJfiYIOL3RuL3iIdfsF",
  "3OABcm_3FP5S0QKXI25gTF",
  "8seL1sE3I7e8ucBhS10mjV",
  "vXrki32aHWupMqoFLENw1V",
  "fuaVr02_IqqfXZsCR8UDs0",
  "hW0b6epSIE520mG6_Dy36k",
  "twO10lNHH7Px0t1t5BJItk",
  "_Li8HLWCFo177Jmn577IGV",
  "vwMaz2lJHw54LQ6LECozl0",
  "_G7ndqqtGMPq0_2x1K4xC0",
  "Ah9wXrqvGneJsepLW3VISV",
  "lbBoGDFgH6qUkAVXT3hnx0",
  "7jWUUE2gHIukq7yr2EHgdk",
  "2rnMyQE5G0yMRjiIOTSmeF",
  "m0x5bk3PFH1aCh595NW0zk",
  "ga5y5yg7FxewTXnJWip1yk",
  "AY3Ry36EGi95jU8MnRlyhk",
  "HhVc4__GIjHbm8B8a25XgF",
  "cgrYbJnJIkyYMGXc0k9y00",
  "RI1yEBzFGwiHDFeR3pnH8F",
  "PG5VeWD5ICHL9gI50IXQgV",
  "6Qxk4eyAI8iHHLDBQGw0RV",
  "Lg06q3JKIxTD0SyAcbcRCk",
  "e0E4GSTnI8eZqwBSQERsF0",
  "DTYZGDdOGne9Qh0jgXTXsV",
  "up0o2J7lG_q7D7bNFOybLk",
  "kJJB0pgHFn5x9rNU2O_caV",
  "xOSm0nT_IoiqAsykzeGpl0",
  "8QhXnkQFHaXsq7MAvTg55V",
  "0bRlgATCFTerGQwdf0A3vV",
  "KFDsncjuF9TnTsfZynHKgF",
  "DHUiR23qIYPXlYgyVHt1jF",
  "JB0aLCYTHumWozOxiwd95V",
  "bjyg0VvOHnyUboTd0Se2g0",
  "NrSAJqvnH3yeCkx8IEM0xF",
  "fYPr2IWoF7yCFhUuSZgBHk",
  "1kNymvaPH_eTzFt3WIWxB0",
  "v85TRbtRHkDyvy1U8ex23V",
  "ZaK6BmkaF7iy0BxJj5UWrk",
  "4oaqTSI1HWi0_fnh9vzvLV",
  "u0FwPpUyHVmxrPlxk3Be0F",
  "Fz5k3cHwIlHTaytGti0DA0",
  "ExaesMK8FTiDcsAzxTImhk",
  "IddNzqjRGLm947026GtBjV",
  "khemJPUhH_q3pY79HFo_EV",
  "DlTqNDp0IhmP4yCrLrlHQk",
  "5bXJdKOmFQyGyWaUQEM_f0",
  "sQzelMbnGA1kVzzBOyfiIF",
  "LgUUV2kfF99eynjBPzoz9F",
  "O0FHMFisG5aZ1vcY6jZFYF",
  "CaEk_q26I8PS75VodzpVUV",
  "1CD1PG8xF_usdGts0muny0",
  "sjTX3EQFIWXygwyw60uaK0",
  "WVazTYhhHmT0FtK0VzQ7t0",
  "v6HeNoPbG7yx6bcqPpzHN0",
  "VZIka0WdIyqVE0uVUltZBF",
  "MfU8HRDIHQHGqMl0JHYfi0",
  "d92yVmjpH8XXcJLDHRizKF",
  "0l6qmBFBIVq01TcvcEr1BF",
  "jWjzf0tUIvqiCmFSHWaKEF",
  "pB6myPN0Gweezm4lP9eVJV",
  "k1QyoNZ8FQTRMLdpDeQ9pF",
  "6LlMgRfIFCaPAhUkswe6d0",
  "HWu1Jj3CHsTC1aWEvsvyNV",
  "r0ovhwyUGWLdprPqYz3sWV",
  "LCsLZ4xgIMaJZmtRMAnRxF",
  "XTUeLew3FZenL2tRsDNvPF",
  "reiL5bjdIW1hFa_VE40Xvk",
  "UpbhJZiUIQ5ZJKn_OgCfWk",
  "CMuIWXqHF8uzRQ034DkjEk",
  "0of9N0AgFSiGStwctPxgB0",
  "knCM0NFOIXTLF1GrmFh4z0",
  "7PwB3n1tGeeCm5X3wWlEOV",
  "WF_9fXZAHeqLJqQT2POOnk",
  "El7PCOJUFea_evDy9qGCCk",
  "AzCpe74QFNy8v04Hw4P7zk",
  "AetLmFULFc5yRu2SmHVafk",
  "3i50XnZ5FoqHbVTv_VYg1F",
  "vVCNzdtuIJHb0eTtu4vDxV",
  "lHBlDZawFbXaKDfcO6NHAF",
  "W0_DYJpWIDuQbh1TIgYu9V",
  "KtvGFCdOIi1bI6vNONSpxk",
  "00K7Z0c4IX1bM0WFGLOopV",
  "BwsNqOhWGfaJr0voee080V",
  "AhWwFPX5FZH0VnpGkfjRbV",
  "MwBgMzLrIP5mN5_tT8w8l0",
  "eXiQ4AvdFEmUrWZeUVtWNk",
  "dPmMR4KEGbyW9QCpa63Ckk",
  "6V6I3LG6FLD1kWH3rRTIkk",
  "zr20AUVlHkXY84FpsnZ2rk",
  "9QNVg8YwFgTP0yXw34oPcV",
  "zu0ZioR0GNHEhx0Zsx1oFF",
  "C0CtJiTmG4ySsDqFn1lCs0",
  "RV7A4dtiIRLmiTVXDUOLv0",
  "t3iyEMR4FoHr9yCkE0TQdF",
  "WEG_hqskGkeG3yLiK542ak",
  "zUDSbbeIIA1ickLDMaUUpk",
  "N4UkdoVzFjy_tVCpHiZAEk",
  "J740pmEsGpypdcIlS6zzTV",
  "9EzDoODVIlempNMEbBEBCV",
  "g7QlZ048H1DCynR_BAz6QV",
  "5nT0Ki9dF7mMb0zrXyi4CV",
  "wxizGxWeIsHrhx9SBJZhCF",
  "SY5JT3p0IeDh6bV2npjm70",
  "S09GbmssF0DRJNzStAZYik",
  "U3y2G9atG2yS1ac7aPh91k"
];
