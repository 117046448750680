import React from "react";

import { BrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import {
  WSAvatar,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import { Redirect, useLocation } from "react-router-dom";
import { FAQ1099 } from "../../components/FAQ1099";
import { OnboardingLayout } from "../../../../components/OnboardingLayout/OnboardingLayout";

export const RouteRequestOnboardingLinkSuccess: React.FC = () => {
  const location = useLocation<{ email: string }>();
  const email = location.state?.email;

  if (!email) {
    return <Redirect to="/1099/request-onboarding-link" />;
  }

  return (
    <>
      <BrowserPageTitle title="Request 1099" />
      <OnboardingLayout>
        <WSGrid>
          <WSGrid.Item span={{ m: "6" }}>
            <WSElement mb="2XL">
              <WSFlexBox.CenterX mb="M">
                <WSAvatar.Icon
                  icon="check"
                  color="white"
                  colorBackground="green500"
                />
              </WSFlexBox.CenterX>
              <WSText.Heading4 align="center">
                Request submitted!
              </WSText.Heading4>
            </WSElement>

            <WSText color="gray600">
              If an account exists for {location.state?.email}, we’ll send an
              email with a link to log in. It should arrive in your inbox
              shortly.
            </WSText>

            <WSText color="gray500" mt="XL">
              <WSIcon name="info-circle" color="gray500" size="XS" mr="M" />
              Contact your payer for help if you don’t receive the email
            </WSText>
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "2" }} />
          <WSGrid.Item span={{ m: "4" }}>
            <FAQ1099 />
          </WSGrid.Item>
        </WSGrid>
      </OnboardingLayout>
    </>
  );
};
