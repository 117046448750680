import { MutationConfig } from "react-query";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { QUERY_USERS_ACTIVITIES } from "../../../query/users/keys";
import { usersService } from "../../../services/users";
import { WSServiceError } from "../../../utils/serviceHelper";

export const useMutationUpdateCurrentStep = (config?: MutationConfig<void>) => {
  const userId = useUserId();

  return useWSMutation<void, WSServiceError, { newStep: number }>(
    async ({ newStep }) => {
      await usersService.activity.update(userId, {
        flows: {
          paymentsSetup: {
            currentStep: newStep
          }
        }
      });
    },
    {
      dependencies: [QUERY_USERS_ACTIVITIES],
      ...config
    }
  );
};
