import React from "react";

import { WSElementProps } from "../../WSElement/WSElement.component";
import { WSText } from "../WSText/WSText.component";

export const WSFormLabel: React.FC<WSElementProps> = ({
  children,
  className,
  ...props
}) => (
  <WSText.ParagraphSm color="gray500" mb="XS" className={className} {...props}>
    {children}
  </WSText.ParagraphSm>
);
