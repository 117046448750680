import { useQueryCustomerEntity } from "../../../../query/onboarding/queries/useQueryCustomerEntity";
import { FormImmaterialChangeBusiness } from "../../components/FormImmaterialChangeBusiness";
import { FormImmaterialChangePersonal } from "../../components/FormImmaterialChangePersonal";
import { OnboardingStep } from "../../types";

export const Update: React.FC<OnboardingStep> = ({ title, onNext }) => {
  const queryMonitoredEntity = useQueryCustomerEntity();

  if (!queryMonitoredEntity.data) {
    return null;
  }

  if (queryMonitoredEntity.data.type === "Business") {
    return (
      <FormImmaterialChangeBusiness
        onSubmit={onNext}
        country={queryMonitoredEntity.data.country || "US"}
      />
    );
  }

  return (
    <FormImmaterialChangePersonal
      onSubmit={onNext}
      country={queryMonitoredEntity.data.country || "US"}
    />
  );
};
