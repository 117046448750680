import { IMember } from "@wingspanhq/users/dist/lib/interfaces";
import { selectorIsCompany } from "../../selectors/selectorIsCompany";
import { selectorIsBusinessTypeOnePerson } from "../../../modules/PaymentsSetup/selectors/selectorIsBusinessTypeOnePerson";

export const selectorIsNameRequired = (member: IMember) => {
  const isCompany = selectorIsCompany(member);
  const isSinglePersonCompany = selectorIsBusinessTypeOnePerson(
    member.profile.company?.structure
  );

  return !isCompany || isSinglePersonCompany;
};
