import {
  CellsConfig,
  ContextItem,
  RequirementTableCallback
} from "../../../../types";
import { IRequirementResponse } from "@wingspanhq/payments/dist/interfaces";
import { WSTableAction, WSTableCell } from "@wingspanhq/fe-component-library";
import { WSTableRowMenuAction } from "@wingspanhq/fe-component-library/dist/lib/components/WSTable/types";
import { getBackgroundChecksRequestFromCache } from "../helpers/getBackgroundChecksRequestFromCache";
import { useBackgroundChecksStartModal } from "../../modals/useBackgroundChecksStartModal";
import { renderNameCell } from "../helpers/renderNameCell";
import { BackgroundCheckRequestStatus } from "@wingspanhq/background-checks/dist/lib/interfaces";
import React from "react";
import { selectorRequirementHumanReadableType } from "../../../../selectors/selectorRequirementHumanReadableType";

export const usePayerBackgroundChecksConfig = (
  callback: RequirementTableCallback
): CellsConfig<IRequirementResponse> => {
  const backgroundChecksStartModal = useBackgroundChecksStartModal();

  return {
    nameCell: renderNameCell,
    typeCell: (requirement, context) => {
      return (
        <WSTableCell
          icon="shield-check"
          text={selectorRequirementHumanReadableType(
            requirement.requirementType
          )}
        />
      );
    },
    statusCell: (requirement: IRequirementResponse, context: ContextItem) => {
      const backgroundChecksRequest = getBackgroundChecksRequestFromCache(
        requirement,
        context
      );

      if (requirement.dataSourceId && !backgroundChecksRequest) {
        return (
          <WSTableCell
            text="--"
            secondaryText="error loading"
            secondaryTextColor="amber400"
          />
        );
      }

      return (
        <WSTableCell
          pill={
            backgroundChecksRequest?.status ===
              BackgroundCheckRequestStatus.New ||
            backgroundChecksRequest?.status ===
              BackgroundCheckRequestStatus.Pending ||
            backgroundChecksRequest?.status ===
              BackgroundCheckRequestStatus.Processing
              ? {
                  theme: "warning",
                  text: "Pending",
                  icon: true
                }
              : backgroundChecksRequest?.status ===
                BackgroundCheckRequestStatus.Success
              ? {
                  theme: "success",
                  text: "Complete",
                  icon: true
                }
              : {
                  theme: "error",
                  text: "Failed",
                  icon: true
                }
          }
          secondaryText={
            backgroundChecksRequest?.status === BackgroundCheckRequestStatus.New
              ? "Invite email in progress"
              : backgroundChecksRequest?.status ===
                BackgroundCheckRequestStatus.Pending
              ? "Awaiting your completion"
              : backgroundChecksRequest?.status ===
                BackgroundCheckRequestStatus.Processing
              ? "Processing results"
              : undefined
          }
        />
      );
    },
    rowActions: (requirement, context) => {
      const actions: WSTableAction[] = [];

      const backgroundChecksRequest = getBackgroundChecksRequestFromCache(
        requirement,
        context
      );

      if (
        backgroundChecksRequest?.vendorMetadata.applicationUrl &&
        backgroundChecksRequest.status === BackgroundCheckRequestStatus.Pending
      ) {
        actions.push({
          name: "Open",
          onAsyncClick: async () => {
            await backgroundChecksStartModal.open({
              backgroundCheckRequestId:
                backgroundChecksRequest.backgroundCheckRequestId
            });
          },
          text: "Start",
          kind: "Link",
          hideOn: []
        });
      }

      return actions;
    },
    rowMenuActions: (requirement, context) => {
      const actions: WSTableRowMenuAction<IRequirementResponse>[] = [];

      const backgroundChecksRequest = getBackgroundChecksRequestFromCache(
        requirement,
        context
      );

      if (
        backgroundChecksRequest?.vendorMetadata.applicationUrl &&
        backgroundChecksRequest.status === BackgroundCheckRequestStatus.Pending
      ) {
        actions.push({
          name: "Open",
          onAsyncClick: async () => {
            await backgroundChecksStartModal.open({
              backgroundCheckRequestId:
                backgroundChecksRequest.backgroundCheckRequestId
            });
          },
          label: "Open background check",
          icon: "open-tab"
        });
      }

      return actions;
    }
  };
};
