import { IMemberClient } from "@wingspanhq/payments/dist/interfaces";
import {
  RequirementStatus,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";

export const selectorHasDocumentsToSign = (memberClients: IMemberClient[]) =>
  memberClients.some(memberClient =>
    memberClient.eligibilityRequirements?.some(
      eligibilityRequirement =>
        eligibilityRequirement.requirementType === RequirementType.Signature &&
        eligibilityRequirement.status !== RequirementStatus.Complete
    )
  );
