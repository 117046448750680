import { CellsConfig, RequirementTableCallback } from "../../../../types";
import { IRequirementResponse } from "@wingspanhq/payments/dist/interfaces";
import { WSTableRowMenuAction } from "@wingspanhq/fe-component-library/dist/lib/components/WSTable/types";
import { getBackgroundChecksRequestFromCache } from "../helpers/getBackgroundChecksRequestFromCache";
import { useBackgroundChecksPackageDetailsModal } from "../../modals/useBackgroundChecksPackageDetailsModal";
import { openInNewTab } from "../../../../../../shared/utils/openInNewTab";
import { renderNameCell } from "../helpers/renderNameCell";
import { WSTableCell } from "@wingspanhq/fe-component-library";
import { BackgroundCheckRequestStatus } from "@wingspanhq/background-checks/dist/lib/interfaces";
import React from "react";
import { selectorRequirementHumanReadableType } from "../../../../selectors/selectorRequirementHumanReadableType";

export const usePayeeBackgroundChecksConfig = (
  callback: RequirementTableCallback
): CellsConfig<IRequirementResponse> => {
  const backgroundChecksPackageDetailsModal = useBackgroundChecksPackageDetailsModal();

  return {
    nameCell: renderNameCell,
    typeCell: (requirement, context) => {
      const backgroundChecksRequest = getBackgroundChecksRequestFromCache(
        requirement,
        context
      );

      return (
        <WSTableCell
          icon="shield-check"
          text={selectorRequirementHumanReadableType(
            requirement.requirementType
          )}
          secondaryText={backgroundChecksRequest?.package?.title}
        />
      );
    },
    statusCell: (requirement, context) => {
      const backgroundChecksRequest = getBackgroundChecksRequestFromCache(
        requirement,
        context
      );

      if (requirement.dataSourceId && !backgroundChecksRequest) {
        return (
          <WSTableCell
            text="--"
            secondaryText="error loading"
            secondaryTextColor="amber400"
          />
        );
      }

      return (
        <WSTableCell
          pill={
            backgroundChecksRequest?.status ===
              BackgroundCheckRequestStatus.New ||
            backgroundChecksRequest?.status ===
              BackgroundCheckRequestStatus.Pending ||
            backgroundChecksRequest?.status ===
              BackgroundCheckRequestStatus.Processing
              ? {
                  theme: "warning",
                  text: "Pending",
                  icon: true
                }
              : backgroundChecksRequest?.status ===
                BackgroundCheckRequestStatus.Success
              ? {
                  theme: "success",
                  text: "Complete",
                  icon: true
                }
              : {
                  theme: "error",
                  text: "Failed",
                  icon: true
                }
          }
          secondaryText={
            backgroundChecksRequest?.status === BackgroundCheckRequestStatus.New
              ? "Invite email in progress"
              : backgroundChecksRequest?.status ===
                BackgroundCheckRequestStatus.Pending
              ? backgroundChecksRequest.statusMessage ||
                "Awaiting contractor completion"
              : backgroundChecksRequest?.status ===
                BackgroundCheckRequestStatus.Processing
              ? "Processing results"
              : backgroundChecksRequest?.status ===
                BackgroundCheckRequestStatus.Failed
              ? backgroundChecksRequest.statusMessage
              : undefined
          }
        />
      );
    },
    rowActions: (requirement, context) => {
      return [];
    },
    rowMenuActions: (requirement, context) => {
      const actions: WSTableRowMenuAction<IRequirementResponse>[] = [];
      const backgroundChecksRequest = getBackgroundChecksRequestFromCache(
        requirement,
        context
      );

      if (backgroundChecksRequest) {
        actions.push({
          name: "Details",
          onAsyncClick: async () => {
            await backgroundChecksPackageDetailsModal.open({
              backgroundChecksRequest
            });
          },
          label: "Background check details",
          icon: "info-circle"
        });

        if (backgroundChecksRequest.vendorMetadata.manageReportUrl) {
          actions.push({
            name: "Manage",
            onAsyncClick: async () => {
              openInNewTab(
                backgroundChecksRequest.vendorMetadata.manageReportUrl!
              );
            },
            label: "Manage Report",
            icon: "open-tab"
          });
        }

        if (backgroundChecksRequest.vendorMetadata.viewReportUrl) {
          actions.push({
            name: "View",
            onAsyncClick: async () => {
              openInNewTab(
                backgroundChecksRequest.vendorMetadata.viewReportUrl!
              );
            },
            label: "View Report",
            icon: "open-tab"
          });
        }
      }

      return requirement?.dataSourceId ? actions : [];
    }
  };
};
