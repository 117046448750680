import HelloSign from "hellosign-embedded";
import { IS_DEV_ENV } from "../shared/constants/environment";

export const helloSignClient = new HelloSign({
  clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
  testMode: IS_DEV_ENV,
  skipDomainVerification: IS_DEV_ENV
});

export const helloSignPromise = () =>
  new Promise<"signed" | "canceled" | "closed" | "declined">(resolve => {
    function signListener() {
      removeListeners();
      resolve("signed");
    }

    function cancelListener() {
      removeListeners();
      resolve("canceled");
    }

    function closeListener() {
      removeListeners();
      resolve("closed");
    }

    function declineListener() {
      removeListeners();
      resolve("declined");
    }

    function removeListeners() {
      helloSignClient.off("sign", signListener);
      helloSignClient.off("cancel", cancelListener);
      helloSignClient.off("close", closeListener);
      helloSignClient.off("decline", declineListener);
    }

    helloSignClient.on("sign", signListener);
    helloSignClient.on("cancel", cancelListener);
    helloSignClient.on("close", closeListener);
    helloSignClient.on("decline", declineListener);
  });
