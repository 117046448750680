import { useUserProfileData } from "./utils/useUserProfileData";
import { isUserIdsIncluded } from "./utils/isUserIdsIncluded";
import { HIDE_BATCH_IMPORTS_NAV_IDS } from "./helpers";

export const useShowBatchImportsNav = () => {
  const userData = useUserProfileData();
  // if not collaborator node, show new batch imports nav
  const showBatchImportsNav = !isUserIdsIncluded(
    userData,
    HIDE_BATCH_IMPORTS_NAV_IDS
  );

  return showBatchImportsNav;
};
