import {
  Form1099DisputeStatus,
  Form1099Status,
  IMemberClientForm1099Balances
} from "@wingspanhq/payments/dist/interfaces";
import getLatest1099NECIndex from "../../../../utils/getLatest1099NECIndex";
import getIsCorrectionPossible from "./getIsCorrectionPossible";
import { selectorIsForm1099BalanceSubmitted } from "../../../../shared/selectors/selectorIsForm1099BalanceSubmitted";
import { selectorIsForm1099CorrectionPresent } from "../../../../shared/selectors/selectorIsForm1099CorrectionPresent";

const getBalanceFlags = (form1099Balance: IMemberClientForm1099Balances) => {
  const { submittedForCollaboratorReviewAt, acceptedByCollaboratorAt } =
    form1099Balance.events || {};
  const isFormSubmitted = selectorIsForm1099BalanceSubmitted(form1099Balance);
  const isPreFiling =
    !isFormSubmitted && form1099Balance.status !== Form1099Status.Submitted;

  return {
    isSuggestionPossible:
      isPreFiling &&
      Boolean(submittedForCollaboratorReviewAt) &&
      !acceptedByCollaboratorAt &&
      !form1099Balance.dispute &&
      form1099Balance.status !== Form1099Status.Excluded,
    isSuggestionOpen:
      form1099Balance.dispute?.status === Form1099DisputeStatus.Open,
    isNotAcceptedByCollaborator: !acceptedByCollaboratorAt,
    isFormNotSubmitted: !isFormSubmitted,
    isCorrectionPossible: getIsCorrectionPossible(form1099Balance),
    isCorrectionPresent: selectorIsForm1099CorrectionPresent(form1099Balance),
    latest1099NECIndex: getLatest1099NECIndex(form1099Balance)
  };
};

export default getBalanceFlags;
