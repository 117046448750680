import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import {
  FormManuallyAddAccount,
  FormManuallyAddAccountProps
} from "../FormManuallyAddAccount";

type Props = {
  onClose: (account: IAccount) => void;
} & FormManuallyAddAccountProps;

export const ModalFormManuallyAddAccount: React.FC<Props> = ({
  onClose,
  ...props
}) => {
  return (
    <FormManuallyAddAccount
      {...props}
      onSuccess={(...args) => {
        onClose(...args);
        props.onSuccess?.(...args);
      }}
    />
  );
};
