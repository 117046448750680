import { WSService } from "../../../utils/WSService";
import { operations } from "./types";

const service = new WSService("/v2/onboarding/verifications");

export const getVerifications = async (): Promise<operations["getVerifications"]["responses"]["200"]["content"]["application/json"]> => {
  const { data } = await service.get("");
  return data;
};

export const performVerification = async (
  verificationLevel: operations["performVerification"]["parameters"]["path"]["verificationLevel"]
): Promise<operations["performVerification"]["responses"]["200"]["content"]["application/json"]> => {
  const { data } = await service.post("/" + verificationLevel);
  return data;
};
