import {
  ICollaboratorSchema,
  ICustomField,
  IFeeHandlingConfig,
  IFrequency,
  IInvoice,
  IInvoiceAttachments,
  IInvoiceCollaborator,
  IInvoiceLineItem,
  IInvoiceLineItemsCreateRequest,
  ILateFeeConfig,
  Intervals,
  PaymentMethod
} from "@wingspanhq/payments/dist/interfaces";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { quantityToTime, timeToQuantity } from ".";
import { getCollaborator } from "../../query/payments/selectors";
import {
  getFormDataCustomFields,
  getRequestDataCustomFields
} from "../../shared/components/FormPartialCustomFields";
import { useSetWSStore, useWSStore } from "../../store";
import {
  convertDateToMidday,
  getWSDay,
  isSameDate,
  isToday
} from "../../utils/dates";
import { useHistoryPushWithFilters } from "../../utils/router";
import { updateArray, updateArrayString } from "../../utils/serviceHelper";
import { FormDataCreditFeeHandling } from "../components/InvoicesForm/AdvancedSection";
import { DueSectionValues } from "../components/InvoicesForm/DueSection";
import { FrequencyValues } from "../components/InvoicesForm/FrequencySection";
import { InvoiceCollaboratorValues } from "../components/InvoicesForm/InvoiceCollaboratorsSection";
import { LateFeeValues } from "../components/InvoicesForm/LateFeeSection";
import { FormDataLineItem } from "../components/InvoicesForm/LineItemsSection/types";
import { AttachmentValues } from "../components/InvoicesForm/OtherSection";
import { PaymentMethodsValues } from "../components/InvoicesForm/PaymentMethodsSection";
import { SendSectionValues } from "../components/InvoicesForm/SendSection";

export const LINE_ITEM_HOUR_RATE = "Hour";
export const LINE_ITEM_UNIT_RATE = "Unit";

const defaultEndDate = new Date();
defaultEndDate.setMonth(defaultEndDate.getMonth() + 1);
defaultEndDate.setDate(defaultEndDate.getDate() + 1);

export const defaultFrequency: FrequencyValues = {
  every: 1,
  interval: Intervals.Month,
  end: "never",
  endDate: defaultEndDate,
  endAfter: 2,
  start: "onSend",
  startDate: new Date()
};

// Send date

export const generateSendDate = (formValues: SendSectionValues): Date => {
  if (formValues.send.type === "immediately" || isToday(formValues.send.date)) {
    return new Date();
  } else {
    // Convert date to midday if it's in the future, so it's always going to be the same day in utc and user's TZ
    return convertDateToMidday(formValues.send.date);
  }
};

// Due date

export const generateDueDate = (options: {
  formValues: DueSectionValues;
  sendDate: Date;
}): Date => {
  if (
    options.formValues.due === "on-receipt" ||
    (options.formValues.due === "custom" &&
      isToday(options.formValues.customDueDate))
  ) {
    return options.sendDate;
  } else {
    let dueDate;
    if (options.formValues.due === "7-days") {
      dueDate = moment(options.sendDate)
        .add(7, "days")
        .toDate();
    } else if (options.formValues.due === "30-days") {
      dueDate = moment(options.sendDate)
        .add(30, "days")
        .toDate();
    } else {
      dueDate = options.formValues.customDueDate;
    }

    if (isToday(dueDate)) {
      return new Date();
    } else {
      // Convert date to midday if it's in the future, so it's always going to be the same day in utc and user's TZ
      return convertDateToMidday(dueDate);
    }
  }
};

export const generateDefaultDueDateSetByPayer = (options: {
  dueInDays: number;
  sendDate: Date;
}): Date => {
  if (options.dueInDays === 0) {
    // on-receipt
    return options.sendDate;
  } else {
    let dueDate;
    if (options.dueInDays === 7) {
      // 7 days after receipt/open date
      dueDate = moment(options.sendDate)
        .add(7, "days")
        .toDate();
    } else if (options.dueInDays === 15) {
      // 15 days after receipt/open date
      dueDate = moment(options.sendDate)
        .add(15, "days")
        .toDate();
    } else {
      // 30 days after receipt/open date
      dueDate = moment(options.sendDate)
        .add(30, "days")
        .toDate();
    }

    if (isToday(dueDate)) {
      return new Date();
    } else {
      // Convert date to midday if it's in the future, so it's always going to be the same day in utc and user's TZ
      return convertDateToMidday(dueDate);
    }
  }
};

// Late fees

export const lateFeeToFormData = (config?: ILateFeeConfig): LateFeeValues => ({
  amount: config?.lateFeeAmount
    ? config.lateFeeAmount
    : config?.lateFeePercentage
    ? config.lateFeePercentage
    : 5,
  type: config?.lateFeeAmount ? "fixed" : "percent",
  every: config?.frequency?.every ? config.frequency.every : 1,
  interval: config?.frequency?.interval || Intervals.Week,
  overriden: false
});

export const generateLateFeeConfig = (data: {
  formValues: LateFeeValues;
  dueDate: Date;
}): ILateFeeConfig | undefined => {
  const every = data.formValues.every;

  const startDate = new Date(data.dueDate);
  if (data.formValues.interval === Intervals.Month) {
    startDate.setMonth(startDate.getMonth() + every);
  } else {
    startDate.setDate(startDate.getDate() + 7 * every);
  }

  return data.formValues.overriden
    ? {
        lateFeeAmount: data.formValues.compoundedAmount,
        frequency: {
          interval: Intervals.Week,
          every: 1,
          dayInInterval:
            data.formValues.interval === Intervals.Week
              ? getWSDay(data.dueDate)
              : data.dueDate.getDate(),
          startDate: moment(data.dueDate)
            .add(1, "day")
            .toDate(),
          endDate: moment(data.dueDate)
            .add(7, "days")
            .toDate()
        }
      }
    : {
        ...(data.formValues.type === "percent"
          ? { lateFeePercentage: data.formValues.amount }
          : {}),
        ...(data.formValues.type === "fixed"
          ? { lateFeeAmount: data.formValues.amount }
          : {}),
        frequency: {
          interval: data.formValues.interval,
          every,
          dayInInterval:
            data.formValues.interval === Intervals.Week
              ? getWSDay(startDate)
              : startDate.getDate(),
          startDate
        }
      };
};

// Recurring frequency

export const generateFrequencyConfig = (data: {
  formValues: FrequencyValues;
  sendDate: Date;
}): IFrequency => {
  const every = data.formValues.every;

  let startDate;

  if (data.formValues.start === "custom") {
    startDate = new Date(data.formValues.startDate);
  } else {
    // If send date is provided frequency config should start on the next occasion
    startDate = new Date(data.sendDate);
    if (data.formValues.interval === Intervals.Month) {
      startDate.setMonth(startDate.getMonth() + every);
    } else {
      startDate.setDate(startDate.getDate() + 7 * every);
    }
  }

  startDate = convertDateToMidday(startDate);

  let endDate;

  if (data.formValues.end === "date" && data.formValues.endDate) {
    if (
      data.formValues.endDate < startDate ||
      isSameDate(data.formValues.endDate, startDate)
    ) {
      endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 1);
    } else {
      endDate = new Date(data.formValues.endDate);
    }
  } else if (data.formValues.end === "after") {
    endDate = new Date(startDate);
    // Frequency config starts with next occurrence (see start date calculation above), so this takes -1 occurrence. If we woudn't count on this condition, in regular case it also needs to do -1 to have correct end date. That's how -2 appears here.
    const count = data.formValues.endAfter - 2;

    if (count > 0) {
      if (data.formValues.interval === Intervals.Month) {
        endDate.setMonth(endDate.getMonth() + every * count);
      } else {
        endDate.setDate(endDate.getDate() + 7 * every * count);
      }
    }
  }

  return {
    interval: data.formValues.interval,
    every,
    dayInInterval:
      data.formValues.interval === Intervals.Week
        ? getWSDay(startDate)
        : startDate.getDate(),
    startDate,
    endDate
  };
};

export const convertToLineItemFormData = (
  lineItem: IInvoiceLineItem,
  customFields?: ICustomField[]
): FormDataLineItem => {
  const rate = lineItem.unit
    ? lineItem.unit === LINE_ITEM_HOUR_RATE
      ? "hourly"
      : lineItem.unit === LINE_ITEM_UNIT_RATE
      ? "quantity"
      : "other"
    : "fixed";
  const [hours, minutes] = quantityToTime(lineItem.quantity);

  const integration = lineItem.integration ? lineItem.integration : undefined;

  return {
    description: lineItem.description || "",
    totalCost:
      rate === "fixed"
        ? lineItem.totalCost
        : (lineItem.costPerUnit || 0) * (lineItem.quantity || 0),
    rate,
    costPerUnit: lineItem.costPerUnit || 0,
    quantity:
      lineItem.unit === LINE_ITEM_HOUR_RATE ? hours : lineItem.quantity || 0,
    minutes: lineItem.unit === LINE_ITEM_HOUR_RATE ? minutes : 0,
    customUnit: rate === "other" ? lineItem.unit : "",
    discount: {
      amount: lineItem.discount?.amount || 0,
      percentage: lineItem.discount?.percentage || 0,
      type: lineItem.discount?.amount ? "amount" : "percentage",
      description: lineItem.discount?.description || ""
    },
    remove: "",
    reimbursableExpense: lineItem.reimbursableExpense || false,
    integration,
    customFields:
      customFields && lineItem.labels
        ? getFormDataCustomFields(customFields, lineItem.labels)
        : undefined
  };
};

export const convertToLineItemsCreateRequest = (
  formLineItems: FormDataLineItem[]
): IInvoiceLineItemsCreateRequest[] =>
  formLineItems
    .map(convertToLineItemRequest)
    .filter(item => !!item) as IInvoiceLineItemsCreateRequest[];

export const convertToLineItemsUpdateRequest = (
  formLineItems: FormDataLineItem[]
): Array<IInvoiceLineItemsCreateRequest | null> =>
  formLineItems.map(convertToLineItemRequest);

export const convertToLineItemsUpdateRequest2 = (
  initialLineItems: IInvoiceLineItem[],
  formLineItems: FormDataLineItem[]
): Array<IInvoiceLineItemsCreateRequest | null> =>
  updateArray(initialLineItems, formLineItems.map(convertToLineItemRequest));

export const convertToLineItemRequest = (
  formValues: FormDataLineItem
): IInvoiceLineItemsCreateRequest | null => {
  const integration = formValues.integration;

  const discount =
    formValues.discount &&
    formValues.discount.type === "percentage" &&
    formValues.discount.percentage
      ? {
          percentage: formValues.discount.percentage,
          description: formValues.discount.description
        }
      : formValues.discount &&
        formValues.discount.type === "amount" &&
        formValues.discount.amount
      ? {
          amount: formValues.discount.amount,
          description: formValues.discount.description
        }
      : undefined;

  const labels = formValues.customFields
    ? getRequestDataCustomFields(formValues.customFields)
    : undefined;

  if (formValues.remove) {
    return null;
  } else if (formValues.rate === "fixed") {
    return {
      description: formValues.description,
      totalCost: formValues.totalCost,
      discount,
      integration,
      reimbursableExpense: formValues.reimbursableExpense,
      labels
    };
  } else {
    return {
      description: formValues.description,
      unit:
        formValues.rate === "hourly"
          ? LINE_ITEM_HOUR_RATE
          : formValues.rate === "quantity"
          ? LINE_ITEM_UNIT_RATE
          : formValues.customUnit,
      costPerUnit: formValues.costPerUnit,
      quantity:
        formValues.rate === "hourly"
          ? timeToQuantity(formValues.quantity || 0, formValues.minutes || 0)
          : formValues.quantity,
      discount,
      integration,
      reimbursableExpense: formValues.reimbursableExpense,
      labels
    };
  }
};

// Payment methods

export const paymentMethodsToFormData = (
  acceptedPaymentMethods?: PaymentMethod[]
): PaymentMethodsValues => ({
  acceptAllPMs: acceptedPaymentMethods
    ? acceptedPaymentMethods.length === 3
    : true,
  acceptCreditCard:
    acceptedPaymentMethods?.includes(PaymentMethod.Credit) || false,
  acceptACH: acceptedPaymentMethods?.includes(PaymentMethod.ACH) || false,
  acceptManual: acceptedPaymentMethods?.includes(PaymentMethod.Manual) || false
});

export const paymentMethodsFormDataToCreateRequest = (
  values: PaymentMethodsValues
): Array<PaymentMethod> => {
  if (values.acceptAllPMs) {
    return [PaymentMethod.Credit, PaymentMethod.ACH, PaymentMethod.Manual];
  } else {
    const paymentMethods: PaymentMethod[] = [];

    if (values.acceptCreditCard) {
      paymentMethods.push(PaymentMethod.Credit);
    }
    if (values.acceptACH) {
      paymentMethods.push(PaymentMethod.ACH);
    }
    if (values.acceptManual) {
      paymentMethods.push(PaymentMethod.Manual);
    }

    return paymentMethods;
  }
};

export const paymentMethodsFormDataToUpdateRequest = (
  values: PaymentMethodsValues
): Array<PaymentMethod | null> => {
  if (values.acceptAllPMs) {
    return [PaymentMethod.Credit, PaymentMethod.ACH, PaymentMethod.Manual];
  } else {
    const paymentMethods: Array<PaymentMethod | null> = [];

    paymentMethods.push(values.acceptCreditCard ? PaymentMethod.Credit : null);
    paymentMethods.push(values.acceptACH ? PaymentMethod.ACH : null);
    paymentMethods.push(values.acceptManual ? PaymentMethod.Manual : null);

    return paymentMethods;
  }
};

// Collaborator

export const collaboratorToFormData = (
  invoiceCollaborators: IInvoiceCollaborator[],
  collaborators: ICollaboratorSchema[]
) =>
  invoiceCollaborators.map(invoiceCollaborator => {
    const collaborator = getCollaborator(
      collaborators,
      invoiceCollaborator.memberClientId
    );

    return {
      email: collaborator?.member.user.email || "",
      description: invoiceCollaborator.description,
      amount: invoiceCollaborator.amount,
      remove: ""
    };
  });

export const invoiceCollaboratorToFormData = (
  invoiceCollaborators: IInvoiceCollaborator[],
  collaborators: ICollaboratorSchema[]
): InvoiceCollaboratorValues[] =>
  invoiceCollaborators.map(invoiceCollaborator => {
    const collaborator = getCollaborator(
      collaborators,
      invoiceCollaborator.memberClientId
    );

    return {
      payee: {
        payeeId: collaborator?.memberId || "",
        payerPayeeEngagementId: collaborator?.collaboratorId || ""
      },
      description: invoiceCollaborator.description,
      amount: invoiceCollaborator.amount,
      remove: ""
    };
  });

// Attachments

export const attachmentsToFormData = (
  ids?: Array<string>
): AttachmentValues[] => {
  return (ids || []).map(id => ({ fileId: id }));
};

export const attachmentsFormDataToCreateRequest = (
  formValues?: AttachmentValues[]
): Array<string> => {
  return (formValues || []).filter(a => !a.remove).map(a => a.fileId);
};

export const attachmentsFormDataToUpdateRequest = (
  formValues?: AttachmentValues[],
  oldIds?: IInvoiceAttachments["customAttachmentIds"]
): Array<string | null> => {
  return updateArrayString(
    oldIds || [],
    (formValues || []).map(i => (i.remove ? null : i.fileId))
  );
};

export const useInvoiceFormGoBack = () => {
  const store = useWSStore();
  const setStore = useSetWSStore();
  const location = useLocation<{ backPath?: string }>();
  const historyPushWithFilters = useHistoryPushWithFilters();

  return useCallback(() => {
    historyPushWithFilters(
      store.invoiceFormBackPath ||
        location.state?.backPath ||
        "/member/invoices"
    );
    setStore({ invoiceFormBackPath: undefined });
  }, [store.invoiceFormBackPath, location.state?.backPath]);
};

export const creditFeeToFormData = (
  invoice: IInvoice
): FormDataCreditFeeHandling => {
  if (
    invoice.creditFeeHandling?.memberPays === 100 ||
    invoice.creditFeeHandling?.clientAbsolutePercentage === 0
  ) {
    return {
      clientPaysProcessingFees: false,
      clientProcessingFeePercentage: 2.9
    };
  }

  return {
    clientPaysProcessingFees: true,
    clientProcessingFeePercentage:
      invoice.creditFeeHandling?.clientAbsolutePercentage || 2.9
  };
};

export const creditFeeFormDataToRequest = (
  formData: FormDataCreditFeeHandling
): IFeeHandlingConfig =>
  formData?.clientPaysProcessingFees
    ? {
        clientAbsolutePercentage: formData.clientProcessingFeePercentage,
        clientPays: 0,
        memberPays: 0
      }
    : {
        clientAbsolutePercentage: 0,
        clientPays: 0,
        memberPays: 100
      };
