import {
  useModalContext,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useDeleteSubscriptionGrant } from "../../query/subscriptions/mutations";
import { useDeleteAuthorization } from "../../query/users/mutations";
import { ITeamMemberRecord } from "../../shared/utils/teamUtils";

export interface DeleteTeammateModalProps {
  teamMember: ITeamMemberRecord;
}

export const DELETE_TEAMMATE_MODAL = "DELETE_TEAMMATE_MODAL";

export const DeleteTeammateModal: React.FC = () => {
  const { closeModal } = useModalContext();
  const [
    deleteAuthorization,
    deleteAuthorizationMeta
  ] = useDeleteAuthorization();
  const [
    deleteSubscriptionGrant,
    deleteSubscriptionGrantMeta
  ] = useDeleteSubscriptionGrant();
  return (
    <WSModal
      name={DELETE_TEAMMATE_MODAL}
      size="S"
      title="Are you sure you want to delete?"
    >
      {(props: DeleteTeammateModalProps) => (
        <>
          <WSText mb="M">Once confirmed, this action can’t be undone.</WSText>

          <WSButtons format="modal">
            <WSButton
              destructive
              name="deleteTeamMember"
              loading={
                deleteSubscriptionGrantMeta.isLoading ||
                deleteAuthorizationMeta.isLoading
              }
              onClick={async () => {
                if (!!props.teamMember.subscriptionGrant) {
                  await deleteSubscriptionGrant(
                    props.teamMember.subscriptionGrant.subscriptionGrantId
                  );
                }
                if (
                  props.teamMember.authorization &&
                  props.teamMember.authorization.allowedScopeGroupIds.length > 0
                ) {
                  await Promise.all(
                    props.teamMember.authorization?.allowedScopeGroupIds.map(
                      async ({ authorizationId }: any) => {
                        await deleteAuthorization(authorizationId);
                      }
                    )
                  );
                }
                closeModal(DELETE_TEAMMATE_MODAL);
              }}
            >
              Delete
            </WSButton>
            <WSButton.Tertiary
              onClick={() => closeModal(DELETE_TEAMMATE_MODAL)}
            >
              Cancel
            </WSButton.Tertiary>
          </WSButtons>
        </>
      )}
    </WSModal>
  );
};
