import {
  WSAvatar,
  WSButton,
  WSButtonProps,
  WSElement,
  WSElementColorsType,
  WSFlexBox,
  WSIcon,
  WSIconProps,
  WSPanel,
  WSPanelProps,
  useIsMobile
} from "@wingspanhq/fe-component-library";
import React from "react";

export type PayableActionBlockProps = WSPanelProps & {
  icon: WSIconProps["name"];
  iconColor?: WSElementColorsType;
  iconSecondaryColor?: WSElementColorsType;
  contentButton?: WSButtonProps;
  rightButton?: WSButtonProps;
};

export const PayableActionBlock: React.FC<PayableActionBlockProps> = ({
  icon,
  iconColor = "green50",
  iconSecondaryColor = "green500",
  contentButton,
  rightButton,
  children,
  onClick,
  ...other
}) => {
  const isMobile = useIsMobile();

  return (
    <WSPanel p="L" onClick={onClick} {...other}>
      {isMobile ? (
        <WSElement>
          <WSFlexBox.CenterY justify="space-between">
            <WSAvatar.Icon
              icon={icon}
              size="M"
              color={iconSecondaryColor}
              colorBackground={iconColor}
              mr="L"
            />
            {rightButton ? (
              <WSButton size="S" kind="Primary" {...rightButton} />
            ) : onClick ? (
              <WSIcon block name="chevron-right" color="blue500" />
            ) : null}
          </WSFlexBox.CenterY>
          <WSElement mt="L">
            {children}
            {contentButton ? (
              <WSButton size="S" mt="S" kind="Secondary" {...contentButton} />
            ) : null}
          </WSElement>
        </WSElement>
      ) : (
        <WSFlexBox.CenterY wrap="nowrap" justify="space-between">
          <WSFlexBox.CenterY wrap="nowrap" pr="2XL">
            <WSAvatar.Icon
              icon={icon}
              size="M"
              color="green500"
              colorBackground="green50"
              mr="L"
            />
            <WSElement>
              {children}
              {contentButton ? (
                <WSButton size="S" kind="Secondary" mt="M" {...contentButton} />
              ) : null}
            </WSElement>
          </WSFlexBox.CenterY>

          {rightButton ? (
            <WSButton kind="Primary" size="S" {...rightButton} />
          ) : onClick ? (
            <WSIcon block name="chevron-right" color="blue500" />
          ) : null}
        </WSFlexBox.CenterY>
      )}
    </WSPanel>
  );
};
