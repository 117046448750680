import {
  useWSModal,
  WSButton,
  WSButtons,
  WSElement,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

const ModalGoogleLoginInfo: React.FC<{
  email: string;
  onClose(result: boolean): void;
}> = ({ onClose, email }) => {
  return (
    <WSElement mt="XS">
      <WSText color="gray600">
        To confirm your tax information and preferences, you must sign-in with
        the email address <b>{email}</b> where the invite email was sent,
        otherwise the correct information may not exist.
      </WSText>
      <WSText color="gray600" mt="M">
        Please ensure you are signing in with the correct Google email address
      </WSText>

      <WSButtons mt="2XL" forceFullWidth>
        <WSButton.Primary
          onClick={() => {
            onClose(true);
          }}
        >
          Continue with Sign-in
        </WSButton.Primary>
        <WSButton.Secondary onClick={() => onClose(false)}>
          Cancel
        </WSButton.Secondary>
      </WSButtons>
    </WSElement>
  );
};

export const useModalGoogleLoginInfo = () =>
  useWSModal(ModalGoogleLoginInfo, {
    title: "Sign in with Google",
    size: "S"
  });
