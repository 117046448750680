import {
  useWSSnackbar,
  WSButton,
  WSCheckboxToggle,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { useEnterpriseQuery } from "../../../../query/platform/queries";
import { useMutationSubmit } from "./useMutationSubmit";
import { TextBulleted } from "../../../../shared/components/TextBulleted";

export type FormW9CertifyProps = {
  onClose: () => void;
} & WSElementProps;

export const W9Certify: React.FC<FormW9CertifyProps> = ({
  onClose,
  ...elementProps
}) => {
  const { openSnackbar } = useWSSnackbar();
  const [agreement, setAgreement] = useState(false);
  const [submit, submitMeta] = useMutationSubmit({
    onSuccess() {
      openSnackbar({
        type: "success",
        duration: 3000,
        message: `"Certify W-9 information" complete`
      });
      onClose();
    }
  });

  return (
    <WSElement {...elementProps}>
      <WSText mb="M">
        By using Wingspan’s services you agree that the information provided for
        Form W-9 is accurate and that you certify the provided information as
        follows:
      </WSText>
      <WSText mb="M">"I certify, under penalty of perjury that:</WSText>
      <TextBulleted mb="M">
        <li>
          Taxpayer Identification Number or Social Security Number I provided is
          correct;
        </li>
        <li>
          I am not subject to backup withholding because: (a) I am exempt from
          backup withholding, or (b) I have not been notified by the Internal
          Revenue Service (IRS) that I am subject to backup withholding as a
          result of a failure to report all interest or dividends, or (c) the
          IRS has n otified me that I am no longer subject to backup
          withholding; and
        </li>
        <li>I am a U.S. citizen or other U.S. person; and</li>
        <li>
          The FATCA code(s) entered (if any) indicating that I am exempt from
          FATCA reporting is correct."
        </li>
      </TextBulleted>

      <WSCheckboxToggle
        my="3XL"
        name="agreement"
        label="By providing the information on this form, I hereby certify,
                to the best of my knowledge, that the information provided
                is complete and correct."
        value={agreement}
        onChange={value => {
          setAgreement(value);
        }}
      />

      <WSButton
        disabled={!agreement}
        mb="M"
        onClick={submit}
        fullWidth
        name="next"
        loading={submitMeta.isLoading}
      >
        Accept
      </WSButton>
    </WSElement>
  );
};
