import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { AccountStatus, IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { useCreateAccount } from "../query/users/mutations";
import { useWSPlaidLink } from "./usePlaidLink";

type Config = {
  onSuccess: (accounts: IAccount[]) => void;
};

export const useWSPlaidLinkExtended = (config: Config) => {
  const [createAccount] = useCreateAccount();
  const { openSnackbar } = useWSSnackbar();

  return useWSPlaidLink({
    onSuccess: async (publicToken: string) => {
      openSnackbar({
        message: "Linking accounts",
        icon: {
          name: "loader"
        }
      });

      const createResponse = await createAccount({
        publicToken,
        status: AccountStatus.Pending
      });

      const createdAccounts = createResponse
        ? Array.isArray(createResponse)
          ? createResponse
          : [createResponse]
        : [];

      config.onSuccess(createdAccounts);
    }
  });
};
