import { IAddress } from "@wingspanhq/users";

export const getUserAddressValue = (
  address: IAddress
): {
  firstLine: string;
  secondLine?: string;
  thirdLine: string;
} => {
  const res = {
    firstLine: address?.addressLine1 || "",
    secondLine: address?.addressLine2 || "",
    thirdLine: `${address?.city || ""}, ${address?.state ||
      ""} ${address?.postalCode || ""}, ${address?.country || ""}`
  };
  return res;
};
