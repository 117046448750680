import { IPayerEngagementListResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { servicePayments } from "../servicePayments";
import { ListRequestQuery } from "../../utils/serviceHelper";

export const getPayerEngagements = async (
  payerId: string,
  query?: ListRequestQuery<any>
): Promise<IPayerEngagementListResponse> => {
  const { data } = await servicePayments.get(`/payer/${payerId}/engagement`, {
    params: { ...query }
  });
  return data;
};
