import { WSButton, WSButtons, WSText } from "@wingspanhq/fe-component-library";
import { useHistory, useRouteMatch } from "react-router-dom";
import { TextBulleted } from "../../../../shared/components/TextBulleted";

interface RequestCorrectionWarningModalProps {
  onClose: () => void;
  taxFormId: string;
}

export const RequestCorrectionWarningModal: React.FC<RequestCorrectionWarningModalProps> = ({
  taxFormId,
  onClose
}) => {
  const history = useHistory();

  const onContinue = () => {
    history.push(`/member/tax-documents/${taxFormId}/request-correction`);
  };

  return (
    <>
      <TextBulleted color="gray500" mb="2XL">
        <li>
          Only one (1) correction request can be made through Wingspan to your
          payer for a tax year. Please ensure all the information in your
          correction request is accurate before submitting to your payer.
        </li>
        <li>
          Once your request for correction has been submitted, it cannot be
          cancelled.
        </li>
        <li>
          The payer company has sole discretion to accept or reject the
          requested change(s). They may reach out to you regarding the requested
          change(s).
        </li>
        <li>
          Changes to taxpayer identification number (EIN or SSN), name,
          classification, or address - once you submit the correction request,
          your Wingspan profile will also be updated (whether the correction
          request is accepted or not by the payer for tax purposes) to ensure
          the most up-to-date information is reflected in Wingspan.
        </li>
      </TextBulleted>

      <WSButtons forceFullWidth>
        <WSButton.Primary onClick={onContinue}>Continue</WSButton.Primary>
        <WSButton.Tertiary onClick={onClose}>Cancel</WSButton.Tertiary>
      </WSButtons>
    </>
  );
};
