import {
  PayerPayeeEngagementStatus,
  PaymentsEligibility,
  RequirementEmbeddedStatus,
  RequirementStatus
} from "@wingspanhq/payments/dist/interfaces";
import { IPayerEngagement } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { EngagementType } from "@wingspanhq/payments/dist/interfaces/engagement";

export function getEngagementLabelByType(
  type: EngagementType,
  options?: { descriptive?: boolean }
): string {
  switch (type) {
    case EngagementType.AgencyOfRecord:
      return options?.descriptive ? "Agency of Record (AOR)" : "AOR";
    case EngagementType.ContractorVendor:
      return options?.descriptive ? "Independent contractor (IC)" : "IC";
    case EngagementType.EmployerOfRecord:
      return options?.descriptive ? "Employer of Record (EOR)" : "EOR";
    default:
      return type;
  }
}

export function getEngagementsRequirementsCompleted(
  engagement: IPayerEngagement
): boolean {
  return (engagement.requirements || []).every(
    requirement => requirement.status === RequirementEmbeddedStatus.Complete
  );
}

export function getEngagementEligibilityRequirementsCompleted(
  engagement: IPayerEngagement
): boolean {
  return engagement.eligibilityRequirements?.every(
    requirement => requirement.status === RequirementStatus.Complete
  );
}

export function getAllEngagementsEligibilityRequirementsCompleted(
  engagements: IPayerEngagement[]
): boolean {
  return (
    engagements.every(engagement => {
      const hasEngagements = !!engagement.engagementId;
      return hasEngagements
        ? getEngagementsRequirementsCompleted(engagement)
        : getEngagementEligibilityRequirementsCompleted(engagement);
    }) &&
    engagements
      .filter(
        engagement => engagement.status === PayerPayeeEngagementStatus.Active
      )
      .some(
        engagement =>
          engagement.paymentsEligibility === PaymentsEligibility.Eligible
      )
  );
}

export function getHasEligibleEngagements(
  engagements: IPayerEngagement[]
): boolean {
  return engagements
    .filter(
      engagement => engagement.status === PayerPayeeEngagementStatus.Active
    )
    .some(
      engagement =>
        engagement.paymentsEligibility === PaymentsEligibility.Eligible
    );
}
