import { useHistory } from "react-router-dom";
import { FormCreateInternalAccount } from "../../Onboarding/components/FormCreateInternalAccount";
import { Layout } from "../../Onboarding/components/Layout";

export const RouteActivateBanking = () => {
  const history = useHistory();
  return (
    <Layout title="Activate banking features">
      <FormCreateInternalAccount
        type="Banking"
        currency="USD"
        onBack={() => {
          history.push("/member/wallet");
        }}
        onContinue={() => {
          history.push("/member/wallet");
        }}
      />
    </Layout>
  );
};
