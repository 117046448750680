import {
  useWSModal,
  useWSSnackbar,
  WSAlert,
  WSButton,
  WSButtons,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayerResponse,
  PayerPayeeStatus
} from "@wingspanhq/payments/dist/interfaces";
import { ClientInfoPanel } from "../../components/ClientInfoPanel";
import { useUpdatePayer } from "../../../../query/payers/mutations/useUpdatePayer";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";

export interface ArchiveClientModalProps {
  client: IPayerResponse;
  onSuccess?: (client: IPayerResponse) => void;
  onClose: () => void;
}

export const ArchiveClientModal: React.FC<ArchiveClientModalProps> = ({
  client,
  onClose
}) => {
  const { openSnackbar } = useWSSnackbar();
  const [archiveClient, archiveClientMeta] = useUpdatePayer();

  const handleArchiveClient = async () => {
    await archiveClient(
      {
        id: client.payerId,
        payeeOwnedData: {
          status: PayerPayeeStatus.Inactive
        }
      },
      {
        onSuccess: () => {
          onClose();
          openSnackbar({
            duration: 5000,
            type: "success",
            message: "Client archived successfully"
          });
        },
        onError: () => {
          openSnackbar({
            duration: 5000,
            type: "warning",
            message: `Failed to archive client`
          });
        }
      }
    );
  };

  return (
    <>
      <WSText.ParagraphSm mb="L" weight="book">
        Are you sure you want to archive this client?
      </WSText.ParagraphSm>

      <ClientInfoPanel client={client} mb="XL" />

      <WSAlert
        mb="XL"
        theme="info"
        icon="info-circle"
        size="M"
        title="Archiving client"
      >
        After a client is archived, no additional invoices can be created for
        this client. Existing invoices will remain on the Invoicing page until
        you delete them. You can always restore the client when needed.
      </WSAlert>

      <WSErrorMessage
        error={archiveClientMeta.error}
        mb="XL"
        contextKey="ArchiveClient"
      />

      <WSButtons format="modal">
        <WSButton
          size="M"
          name="archiveClient"
          ml="M"
          loading={archiveClientMeta.isLoading}
          onClick={handleArchiveClient}
        >
          Archive client
        </WSButton>
        <WSButton.Tertiary size="M" onClick={onClose}>
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </>
  );
};

export const useArchiveClientModal = () =>
  useWSModal(ArchiveClientModal, {
    title: "Archive client",
    size: "M"
  });
