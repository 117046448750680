import React from "react";
import {
  WSBanner,
  WSElement,
  WSElementProps,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useUserId } from "../../../../query/hooks/helpers";
import { useAccounts, useClientQuery } from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { getPossiblePaymentsAccounts } from "../../../../query/users/selectors";
import { BankAccount } from "../../../../shared/components/BankAccount";
import { BankCard } from "../../../../shared/components/BankCard";
import { IAccount, ICreditCard } from "@wingspanhq/users";
import { IDefaultPaymentMethod } from "@wingspanhq/users/dist/lib/interfaces";
import { useCustomization } from "../../../customization";
import { useUserOrganizationName } from "../../../../userFeatures/useUserOrganizationName";

type Props = WSElementProps;

const getDefaultPaymentMethod = (
  accounts: IAccount[],
  cards: ICreditCard[],
  defaultPaymentMethod: IDefaultPaymentMethod
): IAccount | ICreditCard | null => {
  return (
    accounts.find(
      account => account.accountId === defaultPaymentMethod.accountId
    ) ||
    cards.find(
      card => card.paymentMethodId === defaultPaymentMethod.paymentMethodId
    ) ||
    null
  );
};

export const ManagedPaymentSettings: React.FC<Props> = ({
  ...elementProps
}) => {
  const userId = useUserId();
  const accountsQuery = useAccounts();
  const clientQuery = useClientQuery(userId);
  const organizationName = useUserOrganizationName();
  const { support } = useCustomization();
  const payoutInformationUrl = support(false)?.documentation
    ?.payoutInformationUrl;

  return (
    <WSElement {...elementProps}>
      <WSText.Heading5 mb="XS">Send payments</WSText.Heading5>
      <WSPanel mt="L">
        <WSText.Paragraph mb="XS" weight="book">
          Payment method
        </WSText.Paragraph>
        <WSText color="gray500" mb="XL">
          Default method to send payments to others
        </WSText>

        <WSQueries queries={{ accountsQuery, clientQuery }}>
          {({
            accountsQuery: { data: accounts },
            clientQuery: { data: client }
          }) => {
            const paymentAccounts = getPossiblePaymentsAccounts(accounts);
            const cards = client.profile.savedPaymentMethods || [];

            const defaultPaymentMethod = getDefaultPaymentMethod(
              paymentAccounts,
              cards,
              client.profile.defaultPaymentMethod
            );

            if (defaultPaymentMethod) {
              return (
                <WSPanel mb="2XL">
                  {"accountId" in defaultPaymentMethod ? (
                    <BankAccount
                      accountId={defaultPaymentMethod.accountId}
                      skipError
                    />
                  ) : (
                    <BankCard
                      type="credit"
                      cardId={defaultPaymentMethod.paymentMethodId as string}
                    />
                  )}
                </WSPanel>
              );
            }

            return <WSText.Paragraph mb="2XL">None</WSText.Paragraph>;
          }}
        </WSQueries>

        {payoutInformationUrl ? (
          <WSBanner mt="L" theme="info">
            Payment method is managed by <b>{organizationName}</b> - contact
            them for support.
          </WSBanner>
        ) : null}
      </WSPanel>
    </WSElement>
  );
};
