import {
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSDivider,
  WSElement,
  WSFormOld,
  WSGrid,
  WSInput,
  WSRadioInputGroup,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import {
  ADMIN_SCOPE_GROUP_ID,
  COLLABORATORS_SCOPE_GROUP_ID,
  DOCUMENTS_SCOPE_GROUP_ID,
  FINANCES_SCOPE_GROUP_ID,
  PAYMENTS_SCOPE_GROUP_ID,
  ScopeGroupId,
  scopeGroups
} from "../../../../shared/utils/teamUtils";
import { validatorEmail } from "../../../../shared/validators/validatorEmail";
import {
  validatorFirstName,
  validatorLastName
} from "../../../../utils/validators";
import { SUBSCRIPTION_SELECT_OPTIONS } from "../../constants/subscriptionOptions";
import {
  MembershipSelector,
  MembershipSelectorProps
} from "../MembershipSelector";
import { useModalSelectOrganizations } from "../ModalSelectOrganizations/useModalSelectOrganizations";

import { OptionLabel } from "../OptionLabel";
import styles from "./index.module.scss";

export type FormTeamMemberDefaultValues = {
  email: string;
  firstName: string;
  lastName: string;
  selectedOrganizations: string[];
  permissions: Partial<{ [key in ScopeGroupId]: boolean }>;
  subscription: MembershipSelectorProps["value"];
};

export interface TeamMemberFormProps {
  isEdit?: boolean;
  hasOrganizations?: boolean;
  title: string;
  submitButtonLabel: string;
  onBack: () => void;
  defaultValues: FormTeamMemberDefaultValues;
  onSubmit: (data: FormTeamMemberDefaultValues) => Promise<void>;
}

const ERROR_MESSAGE =
  "At least one of permissions or membership must be selected.";

export const FormTeamMember: React.FC<TeamMemberFormProps> = ({
  isEdit,
  title,
  hasOrganizations,
  onBack,
  submitButtonLabel,
  onSubmit,
  defaultValues
}) => {
  const modal = useModalSelectOrganizations();

  return (
    <WSFormOld
      onSubmit={async ({
        selectedOrganizationsType,
        permissions,
        selectedOrganizations,
        ...formData
      }) => {
        await onSubmit({
          selectedOrganizations:
            selectedOrganizationsType === "all" ? ["*"] : selectedOrganizations,
          permissions: permissions[ADMIN_SCOPE_GROUP_ID]
            ? { [ADMIN_SCOPE_GROUP_ID]: true }
            : permissions,
          ...formData
        });
      }}
      defaultValues={{
        ...defaultValues,
        selectedOrganizations: defaultValues.selectedOrganizations.includes("*")
          ? []
          : defaultValues.selectedOrganizations,
        selectedOrganizationsType:
          defaultValues.selectedOrganizations.includes("*") ||
          defaultValues.selectedOrganizations.length === 0
            ? "all"
            : "specific"
      }}
      validationSchema={Yup.object().shape({
        email: validatorEmail.required("Email is required"),
        firstName: validatorFirstName,
        lastName: validatorLastName,
        selectedOrganizations: hasOrganizations
          ? Yup.array().when("selectedOrganizationsType", {
              is: "specific",
              then: Yup.array().min(
                1,
                `At least one Organization account is required if "Specific organization accounts" selected`
              )
            })
          : Yup.array(),
        selectedOrganizationsType: hasOrganizations
          ? Yup.string()
              .oneOf(["all", "specific"])
              .required()
          : Yup.string(),
        permissions: Yup.object().test("permissions", ERROR_MESSAGE, data => {
          return Object.values(data).filter(Boolean).length > 0;
        }),
        subscription: Yup.string().when("permissions", {
          is: (p: { [key: string]: boolean }) =>
            Object.values(p).filter(Boolean).length === 0,
          then: Yup.string()
            .notOneOf([SUBSCRIPTION_SELECT_OPTIONS[0].title], ERROR_MESSAGE)
            .required(ERROR_MESSAGE)
        })
      })}
    >
      <WSText.Heading4 mb="XL">{title}</WSText.Heading4>

      <WSGrid gutter="XS">
        <WSGrid.Item span={{ s: "6" }}>
          <WSFormOld.Field
            mb="2XL"
            name="firstName"
            label="First name"
            component={WSTextInput}
            componentProps={{
              disabled: isEdit
            }}
          />
        </WSGrid.Item>
        <WSGrid.Item span={{ s: "6" }}>
          <WSFormOld.Field
            mb="2XL"
            name="lastName"
            label="Last name"
            component={WSTextInput}
            componentProps={{
              disabled: isEdit
            }}
          />
        </WSGrid.Item>
      </WSGrid>

      <WSFormOld.Field
        mb="2XL"
        name="email"
        label="Email"
        component={WSTextInput}
        componentProps={{
          type: "email",
          disabled: isEdit
        }}
      />

      <WSText.Heading5 mb="XS">Permissions</WSText.Heading5>
      <WSText.ParagraphSm mb="XL" color="gray400">
        What permissions should this team member have for the selected
        organization accounts?
      </WSText.ParagraphSm>

      <WSFormOld.Context>
        {({ setValue }) => (
          <WSFormOld.Field
            name={`permissions[${ADMIN_SCOPE_GROUP_ID}]`}
            component={WSCheckboxToggle}
            componentProps={{
              onChange(value) {
                if (value) {
                  setValue("selectedOrganizationsType", "all");
                  setValue("selectedOrganizations", []);
                }

                Object.keys(scopeGroups).forEach(key => {
                  setValue(`permissions[${key}]`, value);
                });
              },
              label: (
                <OptionLabel
                  title="Admin"
                  details="complete account access, inclusive of permission modification for other users"
                />
              )
            }}
            mb="XL"
          />
        )}
      </WSFormOld.Context>

      <WSFormOld.Value name={`permissions[${ADMIN_SCOPE_GROUP_ID}]`}>
        {isAdmin => <WSElement className={isAdmin ? styles.preBlocker : ""} />}
      </WSFormOld.Value>
      <WSElement className={styles.disabled}>
        <WSFormOld.Field
          name={`permissions[${PAYMENTS_SCOPE_GROUP_ID}]`}
          component={WSCheckboxToggle}
          componentProps={{
            label: (
              <OptionLabel
                title="Payments"
                details="invoicing, payouts and contact"
              />
            )
          }}
          mb="XL"
        />

        <WSFormOld.Field
          name={`permissions[${COLLABORATORS_SCOPE_GROUP_ID}]`}
          component={WSCheckboxToggle}
          componentProps={{
            label: (
              <OptionLabel
                title="Contractors"
                details="see and update contractors, requirements and groups"
              />
            )
          }}
        />

        <WSText.ParagraphXs color="gray500" mt="XL" mb="XL" weight="medium">
          OTHER
        </WSText.ParagraphXs>

        <WSFormOld.Field
          name={`permissions[${FINANCES_SCOPE_GROUP_ID}]`}
          component={WSCheckboxToggle}
          componentProps={{
            label: (
              <OptionLabel
                title="Income & expense"
                details="access to income and expense tracking"
              />
            )
          }}
        />

        <WSFormOld.Field
          mt="XL"
          name={`permissions[${DOCUMENTS_SCOPE_GROUP_ID}]`}
          component={WSCheckboxToggle}
          componentProps={{
            label: (
              <OptionLabel
                title="Documents"
                details="access to the file vault"
              />
            )
          }}
        />

        <WSDivider my="XL" />

        {hasOrganizations ? (
          <>
            <WSText.Heading5 mb="XS">Organization accounts</WSText.Heading5>
            <WSText.ParagraphSm mb="XL" color="gray400">
              What organization accounts should this team member have access to?
            </WSText.ParagraphSm>

            <WSFormOld.Field
              name="selectedOrganizations"
              component={WSInput}
              hidden
            />

            <WSFormOld.Context>
              {({ setValue }) => (
                <WSFormOld.Field
                  name="selectedOrganizationsType"
                  component={WSRadioInputGroup}
                  componentProps={{
                    onChange: async event => {
                      if (event.target.value === "all") {
                        setValue("selectedOrganizations", []);
                        setValue("selectedOrganizationsType", "all");
                      } else if (event.target.value === "specific") {
                        const result = await modal.open({
                          selectedAccounts: defaultValues.selectedOrganizations.includes(
                            "*"
                          )
                            ? []
                            : defaultValues.selectedOrganizations
                        });
                        setValue("selectedOrganizations", result || []);
                        setValue("selectedOrganizationsType", "specific");
                      }
                    },
                    options: [
                      {
                        label: "Primary organization account",
                        value: "all"
                      },
                      {
                        label: "Specific organization accounts",
                        value: "specific"
                      }
                    ]
                  }}
                />
              )}
            </WSFormOld.Context>

            <WSFormOld.Values
              names={["selectedOrganizations", "selectedOrganizationsType"]}
            >
              {(props: {
                selectedOrganizationsType: "all" | "specific";
                selectedOrganizations: Array<string>;
              }) => {
                if (props.selectedOrganizationsType === "all") {
                  return null;
                }

                const selectedOrganizationsLength: number =
                  props.selectedOrganizations?.length || 0;

                return (
                  <WSFormOld.Context>
                    {({ setValue }) => (
                      <WSButton.Link
                        size="S"
                        ml="3XL"
                        type="button"
                        onClick={async () => {
                          const result = await modal.open({
                            selectedAccounts: props.selectedOrganizations
                          });

                          if (Array.isArray(result)) {
                            setValue("selectedOrganizations", result);
                          }
                        }}
                      >
                        {selectedOrganizationsLength === 0
                          ? "No items selected"
                          : selectedOrganizationsLength === 1
                          ? "1 item selected"
                          : `${selectedOrganizationsLength} items selected`}
                      </WSButton.Link>
                    )}
                  </WSFormOld.Context>
                );
              }}
            </WSFormOld.Values>

            <WSFormOld.Error my="M" name="selectedOrganizations" />

            <WSDivider my="XL" />
          </>
        ) : null}
      </WSElement>

      <WSElement mb="XL">
        <WSText.Heading5 mb="M">Membership</WSText.Heading5>

        <WSFormOld.Field
          component={MembershipSelector}
          name="subscription"
          mb="2XL"
          style={{ width: "100%" }}
        />
      </WSElement>

      <WSButtons forceFullWidth>
        <WSFormOld.SubmitButton name="save">
          {submitButtonLabel}
        </WSFormOld.SubmitButton>
        <WSButton.Tertiary onClick={onBack} name="cancel">
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </WSFormOld>
  );
};
