import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";
import { formatMoney } from "accounting";
import React, { useState } from "react";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { CertifiedSecureDigitalPayments } from "../../components/CertifiedSecureDigitalPayments/CertifiedSecureDigitalPayments";
import {
  getClientInvoiceMemberName,
  getClientName
} from "../../query/payments/selectors";
import { getRedactedMemberName } from "../../query/users/selectors";
import { VerticalDivider } from "../../shared/components/VerticalDivider";
import { useWSStore } from "../../store";
import { Card } from "../components/Card/Card";
import { ClientInvoiceDetailsDrawer } from "../components/ClientInvoiceDetailsDrawer/ClientInvoiceDetailsDrawer";
import { ClientInvoiceWrapper } from "../components/ClientInvoiceWrapper/ClientInvoiceWrapper";
import { ClientPaymentsWrapper } from "../components/ClientPaymentsWrapper/ClientPaymentsWrapper";
import { DonwloadPdfButton } from "../components/DownloadPdfButton/DonwloadPdfButton";
import { InfoRow } from "../components/InfoRow/InfoRow";
import {
  getAcceptedPaymentMethods,
  getPathToPaymentMethod,
  getPaymentStepText,
  isClientInvoiceCancelled,
  isClientInvoicePaid
} from "../utils";

type Props = RouteComponentProps<{ invoiceId: string }>;

export const ClientPaymentsInvoiceDetails: React.FC<Props> = ({ match }) => {
  const history = useHistory();
  const invoiceId = match.params.invoiceId;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const store = useWSStore();

  return (
    <ClientInvoiceWrapper invoiceId={invoiceId}>
      {clientInvoice => {
        const paymentMethods = getAcceptedPaymentMethods(clientInvoice);

        return isClientInvoicePaid(clientInvoice) ? (
          <Redirect to={`/payment/${invoiceId}/success`} />
        ) : (
          <ClientPaymentsWrapper
            clientInvoice={clientInvoice}
            companyName={clientInvoice.memberCompany}
            clientEmail={clientInvoice.memberClient.emailTo}
            memberName={getClientInvoiceMemberName(clientInvoice)}
            companyLogoUrl={clientInvoice.memberLogoUrl}
          >
            {drawerVisible && (
              <ClientInvoiceDetailsDrawer
                invoiceId={invoiceId}
                onClose={() => {
                  setDrawerVisible(false);
                }}
              />
            )}

            <WSText.ParagraphSm>
              {getPaymentStepText(clientInvoice, "details")}
            </WSText.ParagraphSm>
            <WSText.Heading4 mb="2XL">Invoice details</WSText.Heading4>

            {isClientInvoiceCancelled(clientInvoice) && (
              <Card mb="XL">
                <WSText.Heading4 mb="M">Invoice cancelled</WSText.Heading4>
                <WSText color="gray500">
                  {getClientInvoiceMemberName(clientInvoice)} cancelled this
                  invoice. Contact them for more information.
                </WSText>
              </Card>
            )}

            <Card mb="3XL">
              <WSFlexBox justify="space-between" wrap="nowrap">
                <WSElement>
                  <WSText.Heading1 weight="book" mb="XS">
                    {formatMoney(clientInvoice.amount)}
                  </WSText.Heading1>
                  <WSText.ParagraphSm color="gray500" mb="2XL">
                    Due {moment(clientInvoice.dueDate).format("MMM D, YYYY")}
                  </WSText.ParagraphSm>
                </WSElement>
                <WSFlexBox.CenterY pt="M">
                  <WSButton.Link
                    onClick={() => {
                      setDrawerVisible(true);
                    }}
                  >
                    View
                  </WSButton.Link>
                  {clientInvoice.attachments?.invoicePdf && (
                    <>
                      <VerticalDivider mx="M" my="XL" />
                      <DonwloadPdfButton clientInvoice={clientInvoice} />
                    </>
                  )}
                </WSFlexBox.CenterY>
              </WSFlexBox>

              <InfoRow
                label={store.createdWithPPL ? "From" : "To"}
                value={getClientName(clientInvoice.memberClient)}
                mb="M"
              />
              <InfoRow
                label={store.createdWithPPL ? "To" : "From"}
                value={getRedactedMemberName(clientInvoice.memberClient.member)}
                mb="M"
              />
              {clientInvoice.metadata?.purchaseOrderNumber ? (
                <InfoRow
                  label="PO Number"
                  longName
                  value={clientInvoice.metadata?.purchaseOrderNumber}
                  mb="M"
                />
              ) : null}
              {clientInvoice.invoiceNotes && (
                <InfoRow
                  label="Note"
                  value={clientInvoice.invoiceNotes}
                  mb="M"
                />
              )}

              <WSButton
                fullWidth
                mt="2XL"
                onClick={() => {
                  if (paymentMethods.length === 1) {
                    history.push(
                      getPathToPaymentMethod(invoiceId, paymentMethods[0])
                    );
                  } else if (paymentMethods.length > 1) {
                    history.push(`/payment/${invoiceId}/2`);
                  }
                }}
                name="choosePaymentMethod"
                disabled={
                  isClientInvoiceCancelled(clientInvoice) ||
                  paymentMethods.length === 0
                }
              >
                {paymentMethods.length > 1
                  ? "Choose payment method"
                  : "Pay invoice"}
              </WSButton>

              {paymentMethods.length === 0 && (
                <WSText color="red500" mt="XL">
                  Invoice cannot be paid online.
                </WSText>
              )}
            </Card>
            <CertifiedSecureDigitalPayments />
          </ClientPaymentsWrapper>
        );
      }}
    </ClientInvoiceWrapper>
  );
};
