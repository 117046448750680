import { WSButton, WSGrid, WSText } from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import { useUserId } from "../../../../query/hooks/helpers";
import { useMemberProfile } from "../../../../query/users/queries";
import { LayoutFullscreen } from "../../../../shared/components/LayoutFullscreen";
import { FlowOption } from "../../components/FlowOption";

export const RouteAddInternational: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const [value, setValue] = useState<"intrnational" | "domestic" | undefined>();
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  return (
    <LayoutFullscreen
      headTitle="Add payout method"
      title="Select the type of payout account to add"
      onBack={() => {
        history.push("/member/settings/payment-methods/edit");
      }}
    >
      <WSQueries queries={{ queryMember }}>
        {({ queryMember: { data: member } }) => (
          <WSGrid>
            <WSGrid.Item>
              <FlowOption
                kind="vertical"
                icon={{
                  name: "globe",
                  color: "gray500",
                  secondaryColor: "white"
                }}
                name="International (non-US) account"
                desctiption="Wingspan will deposit into your bank account in the currency of the account in a non-US country or in US-dollars for USD accounts."
                onClick={() => {
                  setValue("intrnational");
                }}
                isSelected={value === "intrnational"}
                tooltip={
                  <>
                    <WSText mb="XS" color="white">
                      <b>International bank account</b>
                    </WSText>
                    <WSText color="white">
                      Receive free payments in over 37 currencies and 180+
                      countries. This includes international US-dollar accounts.
                      Currency conversion provided as needed. Payouts usually
                      take 3-5 business days.
                    </WSText>
                  </>
                }
              />
            </WSGrid.Item>
            <WSGrid.Item>
              <FlowOption
                kind="vertical"
                icon={{
                  name: "bank",
                  color: "black",
                  secondaryColor: "white"
                }}
                name="US based account"
                desctiption="Wingspan will keep your deposits in USD and deposit it into your U.S. based account."
                onClick={() => {
                  setValue("domestic");
                }}
                isSelected={value === "domestic"}
                tooltip={
                  <>
                    <WSText mb="XS" color="white">
                      <b>US-based bank account</b>
                    </WSText>
                    <WSText color="white">
                      Receive payments to a US-based bank account or debit card.
                      Choose from these payout methods: Direct Deposit (ACH)
                      through Plaid or entering your account information
                    </WSText>
                  </>
                }
              />
            </WSGrid.Item>
          </WSGrid>
        )}
      </WSQueries>

      <WSButton
        mt="XL"
        fullWidth
        disabled={!value}
        onClick={() => {
          if (value === "intrnational") {
            history.push(
              "/member/settings/payment-methods/add-international-manually"
            );
          } else if (value === "domestic") {
            history.push("/member/settings/payment-methods/add-standard");
          }
        }}
      >
        Continue
      </WSButton>
    </LayoutFullscreen>
  );
};
