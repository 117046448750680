import {
  WSButton,
  WSCentered,
  WSContainer,
  WSElement,
  WSFormOld,
  WSImage,
  WSInputNumberOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import QueryString from "qs";
import React, { useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import * as Yup from "yup";
import logo from "../../assets/images/ws-logo.svg";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { OverlaySpinner } from "../../components/OverlaySpinner";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../query/hooks/helpers";
import { useCreateClientInvoice } from "../../query/payments/mutations";
import { useUserProfile, useUserTag } from "../../query/users/queries";
import { getRedactedUserName } from "../../query/users/selectors";
import { validatorEmail } from "../../shared/validators/validatorEmail";
import { useSetWSStore } from "../../store";
import { Card } from "../components/Card/Card";
import { ClientPaymentsWrapper } from "../components/ClientPaymentsWrapper/ClientPaymentsWrapper";
import { openIntercom } from "../../shared/utils/intercom";

type Props = RouteComponentProps<{ tag: string }>;

export const ClientPaymentsPPL: React.FC<Props> = ({ match, location }) => {
  const history = useHistory();
  const tag = match.params.tag;
  const userTagQuery = useUserTag(tag, {
    retry: false
  });
  const userId = useUserId();
  const userQuery = useUserProfile(userId, {
    retry: false
  });
  const [
    createClientInvoice,
    createClientInvoiceMeta
  ] = useCreateClientInvoice();
  const setStore = useSetWSStore();

  const searchQuery = QueryString.parse(location.search.slice(1));

  const isInvoiceReadyToBeCreated =
    !!searchQuery.item &&
    !!searchQuery.amount &&
    !!searchQuery.email &&
    userTagQuery.isSuccess;

  useEffect(() => {
    if (isInvoiceReadyToBeCreated) {
      createClientInvoice(
        {
          lineItems: [
            {
              description: searchQuery.item as string,
              totalCost: Number(searchQuery.amount as string)
            }
          ],
          dueDate: new Date(),
          clientEmail: searchQuery.email as string,
          memberId: userTagQuery.data?.userId || ""
        },
        {
          onSuccess: createdClientInvoice => {
            setStore({ createdWithPPL: true });
            history.push(`/payment/${createdClientInvoice.invoiceId}`);
          }
        }
      );
    }
  }, [isInvoiceReadyToBeCreated]);

  if (userTagQuery.isError && userTagQuery.error) {
    const errorCode = userTagQuery.error.response?.status || "Error";
    return (
      <WSContainer>
        <WSCentered span={{ xs: "8", m: "6" }}>
          <WSImage
            mt="2XL"
            placeholder="Wingspan Logo"
            src={logo}
            height={22}
          />
          <WSText.Heading1 mt="3XL">{errorCode} ☹️</WSText.Heading1>
          {errorCode === 404 ? (
            <>
              <WSText.Heading4 mt="XL">
                Unfortunately it looks like this Personal Pay Link does not
                exist
              </WSText.Heading4>
              <WSText mt="XL">
                We're sorry about the trouble! Making payments on Wingspan is
                usually a breeze. If you received this link from one of our
                members, please contact them to ensure you have the right link.
              </WSText>
              <WSText mt="XL">
                If you think this is an error, please{" "}
                <WSButton.Link onClick={openIntercom}>
                  contact our friendly support team
                </WSButton.Link>{" "}
                and we'll figure it out.
              </WSText>
            </>
          ) : (
            <>
              <WSText.Heading4 mt="XL">
                Unfortunately we experienced a small issue while retrieving this
                Personal Pay Link
              </WSText.Heading4>
              <WSText mt="XL">
                We're sorry about the trouble! Making payments on Wingspan is
                usually a breeze. You can try refreshing your page, but if that
                does not work, please{" "}
                <WSButton.Link onClick={openIntercom}>
                  contact our friendly support team
                </WSButton.Link>{" "}
                and we'll figure it out.
              </WSText>
            </>
          )}
        </WSCentered>
      </WSContainer>
    );
  }

  if (userTagQuery.data) {
    if (isInvoiceReadyToBeCreated && !createClientInvoiceMeta.error) {
      return <OverlaySpinner />;
    }

    return (
      <ClientPaymentsWrapper
        companyName={userTagQuery.data.member?.profile?.company?.name || ""}
        clientEmail={userTagQuery.data.user.email}
        memberName={
          getRedactedUserName(userTagQuery.data.user) ||
          userTagQuery.data.user.email
        }
        companyLogoUrl={userTagQuery.data.member?.profile?.logoUrl}
      >
        <BrowserPageTitle
          title={`Pay to ${userTagQuery.data.member?.profile?.company?.name ||
            `${userTagQuery.data.user.profile?.firstName}||${userTagQuery.data.user.profile?.lastName}`}`}
        />
        <WSText.ParagraphSm>1 of 3</WSText.ParagraphSm>
        <WSText.Heading4 mb="2XL">Payment details</WSText.Heading4>

        <Card mb="3XL">
          <WSFormOld<{
            amount: number;
            email: string;
            note: string;
          }>
            defaultValues={{
              amount: searchQuery.amount
                ? Number(searchQuery.amount)
                : undefined,
              note: searchQuery.item as string,
              email: (searchQuery.email as string) || userQuery.data?.email
            }}
            validationSchema={Yup.object().shape({
              amount: Yup.number().required("Required"),
              email: validatorEmail.required("Required"),
              note: Yup.string().required("Required")
            })}
            onSubmit={async values => {
              createClientInvoice(
                {
                  lineItems: [
                    {
                      description: values.note,
                      totalCost: values.amount
                    }
                  ],
                  dueDate: new Date(),
                  clientEmail: values.email,
                  memberId: userTagQuery.data?.userId || ""
                },
                {
                  onSuccess: createdClientInvoice => {
                    setStore({ createdWithPPL: true });
                    history.push(
                      `/payment/${createdClientInvoice.invoiceId}/2`
                    );
                  }
                }
              );
            }}
          >
            {({ register }) => (
              <>
                {!!searchQuery.item && !!searchQuery.amount ? (
                  <>
                    <WSElement mb="XL">
                      <WSText.ParagraphSm color="gray400" mb="XS">
                        For
                      </WSText.ParagraphSm>
                      <WSText>{searchQuery.item}</WSText>
                      <input
                        ref={register()}
                        type="hidden"
                        name="note"
                        defaultValue={searchQuery.item as string}
                      />
                    </WSElement>
                    <WSElement mb="XL">
                      <WSText.ParagraphSm color="gray400" mb="XS">
                        Amount
                      </WSText.ParagraphSm>
                      <WSText formatMoney>{searchQuery.amount}</WSText>
                      <input
                        ref={register()}
                        type="hidden"
                        name="amount"
                        defaultValue={searchQuery.amount as string}
                      />
                    </WSElement>
                    <WSFormOld.Field
                      label="Your email"
                      name="email"
                      component={WSTextInput}
                      mb="XL"
                    />
                  </>
                ) : (
                  <>
                    <WSFormOld.Field
                      label="Amount"
                      name="amount"
                      component={WSInputNumberOld}
                      componentProps={{
                        mode: "currency",
                        currency: "USD"
                      }}
                      mb="XL"
                    />

                    <WSFormOld.Field
                      label="Your email to get a receipt"
                      name="email"
                      component={WSTextInput}
                      mb="XL"
                    />

                    <WSFormOld.Field
                      label="Note"
                      name="note"
                      component={WSTextInput}
                      mb="2XL"
                    />
                  </>
                )}

                <WSButton
                  fullWidth
                  loading={createClientInvoiceMeta.isLoading}
                  name="choosePaymentMethod"
                >
                  Choose payment method
                </WSButton>

                <WSErrorMessage
                  mt="XL"
                  contextKey="CreateInvoiceWithPPL"
                  error={createClientInvoiceMeta.error}
                />
              </>
            )}
          </WSFormOld>
        </Card>
      </ClientPaymentsWrapper>
    );
  }

  return <OverlaySpinner />;
};
