import {
  IPayeeEngagementResponse,
  RequirementEmbeddedStatus
} from "@wingspanhq/payments/dist/interfaces";
import { servicePayments } from "../servicePayments";

export const updateEngagementRequirement = async (
  payeeId: string,
  engagementId: string,
  requirementId: string,
  request: { status: RequirementEmbeddedStatus | null }
): Promise<IPayeeEngagementResponse> => {
  const { data } = await servicePayments.patch(
    `/payee/${payeeId}/engagement/${engagementId}/requirement/${requirementId}`,
    request
  );
  return data;
};
