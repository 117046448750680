import {
  useWSModal,
  WSActions,
  WSAlert,
  WSDivider,
  WSInfoBox,
  WSList
} from "@wingspanhq/fe-component-library";

export interface CardActivatedProps {
  onClose: () => void;
}

const CardActivated: React.FC<CardActivatedProps> = ({ onClose }) => {
  return (
    <WSList gap="L">
      <WSAlert theme="success">
        Your physical business debit card has been activated!
      </WSAlert>
      <WSInfoBox>Manage your card from your Wingspan Wallet page.</WSInfoBox>
      <WSDivider />
      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            onClick: async () => {
              onClose();
            },
            kind: "Primary"
          }
        ]}
      ></WSActions>
    </WSList>
  );
};

export function useCardActivatedModal() {
  return useWSModal(CardActivated, {
    title: "Card activated",
    size: "S"
  });
}
