import { IMember } from "@wingspanhq/users";
import * as Yup from "yup";
import { selectorIsDomesticMember } from "../../selectors/selectorIsDomesticMember";
import { validatorDob } from "../../validators/validatorDob";
import { validatorName } from "../../validators/validatorName";
import { validatorSsn } from "../../validators/validatorSsn";
import { validatorStringTextNumbersSymbols } from "../../validators/validatorStringTextNumbersSymbols";
import { validationSchemaAddress } from "../FormPartialAddress";
import { selectorIsNameRequired } from "./selectorIsNameRequired";
import { selectorIsPersonalAddressRequired } from "./selectorIsPersonalAddressRequired";
import { selectorIsSsnRequired } from "./selectorIsSsnRequired";

export const getValidationSchema = (
  member: IMember,
  skipTaxVerification?: boolean
) => {
  const isNameRequired = selectorIsNameRequired(member);
  const isDomesticMember = selectorIsDomesticMember(member);

  return Yup.object().shape({
    firstName: isNameRequired
      ? validatorName.required("Required")
      : validatorName,
    lastName: isNameRequired
      ? validatorName.required("Required")
      : validatorName,
    preferredName: validatorStringTextNumbersSymbols,
    foreignTaxId: isDomesticMember
      ? Yup.string()
      : Yup.string().required("Required"),
    ssn: Yup.string().when("isUSPerson", {
      is: (isUSPerson: boolean) => {
        return selectorIsSsnRequired(member, skipTaxVerification, isUSPerson);
      },
      then: validatorSsn.required("Required")
    }),
    personalAddress: Yup.object().when("isPersonalAddressTheSame", {
      is: (isPersonalAddressTheSame: boolean) => {
        return selectorIsPersonalAddressRequired(
          member,
          isPersonalAddressTheSame
        );
      },
      then: validationSchemaAddress
    }),
    dob: skipTaxVerification ? validatorDob : validatorDob.required("Required")
  });
};
