import { RouteComponentProps, useHistory } from "react-router-dom";
import React from "react";
import { useCardQuery } from "../../../../query/cards/queries/useCardQuery";
import { WSQueries } from "../../../../query/WSQuery";
import { CardDetails } from "./CardDetails";
import { LayoutFullscreen } from "../../../../shared/components/LayoutFullscreen";
import { WSLoader } from "@wingspanhq/fe-component-library";

type Props = RouteComponentProps<{ cardId: string }>;

export const RouteCardDetails: React.FC<Props> = ({
  match: {
    params: { cardId }
  }
}) => {
  const history = useHistory();
  const queryCard = useCardQuery(cardId);

  return (
    <WSQueries
      queries={{ queryCard }}
      renderLoader={() => (
        <LayoutFullscreen
          onBack={() => {
            history.push("/member/wallet");
          }}
          headTitle="Manage Card"
          panel={false}
        >
          <WSLoader.Spinner />
        </LayoutFullscreen>
      )}
    >
      {({ queryCardData }) => <CardDetails card={queryCardData} />}
    </WSQueries>
  );
};
