import {
  useWSModal,
  WSActions,
  WSDivider,
  WSInfoBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useActivateCard } from "../../../../query/cards/mutations/useActivateCard";

export interface ReactivateCardProps {
  cardId: string;
  cardName: string;
  onSuccess: () => void;
  onClose: () => void;
}

const ReactivateCard: React.FC<ReactivateCardProps> = ({
  cardId,
  cardName,
  onSuccess,
  onClose
}) => {
  const [activateCard, activateCardMeta] = useActivateCard();

  return (
    <WSList gap="L">
      <WSText.ParagraphSm mb="S">
        Are you sure you want to reactivate {cardName}?
      </WSText.ParagraphSm>
      <WSInfoBox>
        The card will be reactivated. The card can be used after reactivation.
      </WSInfoBox>
      <WSErrorMessage contextKey="Other" error={activateCardMeta.error} />
      <WSDivider />
      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Reactivate card",
            onAsyncClick: async () => {
              await activateCard(
                { cardId },
                {
                  onSuccess() {
                    onSuccess();
                    onClose();
                  }
                }
              );
            },
            kind: "Primary"
          },
          {
            label: "Cancel",
            onClick: onClose,
            kind: "Secondary"
          }
        ]}
      />
    </WSList>
  );
};

export function useReactivateCardModal() {
  return useWSModal(ReactivateCard, {
    title: "Reactivate card",
    size: "S"
  });
}
