import React from "react";
import { useMediaQuery } from "react-responsive";

const breakpoints = {
  XS: 0,
  S: 768,
  M: 992,
  L: 1280,
  XL: 1520
};

const getPrevMaxByNextMin = (nextMin: number) => nextMin - 0.02;

export const WSScreenSizes = ["XS", "S", "M", "L", "XL"] as const;
export type WSScreenSizesType = typeof WSScreenSizes[number];

export const useIsXS = () =>
  useMediaQuery({
    maxWidth: getPrevMaxByNextMin(breakpoints.S)
  });

export const useIsS = () =>
  useMediaQuery({
    minWidth: breakpoints.S,
    maxWidth: getPrevMaxByNextMin(breakpoints.M)
  });

export const useIsM = () =>
  useMediaQuery({
    minWidth: breakpoints.M,
    maxWidth: getPrevMaxByNextMin(breakpoints.L)
  });

export const useIsL = () =>
  useMediaQuery({
    minWidth: breakpoints.L,
    maxWidth: getPrevMaxByNextMin(breakpoints.XL)
  });

export const useIsXL = () =>
  useMediaQuery({
    minWidth: breakpoints.XL
  });

export const useIsMobile = useIsXS;

export const useIsTablet = () => {
  const isS = useIsS();
  const isM = useIsM();
  return isS || isM;
};

export const useIsDesktop = () => {
  const isL = useIsL();
  const isXL = useIsXL();
  return isL || isXL;
};

export const useCurrentScreenSize = (): WSScreenSizesType => {
  const isS = useIsS();
  const isM = useIsM();
  const isL = useIsL();
  const isXL = useIsXL();
  if (isXL) return "XL";
  if (isL) return "L";
  if (isM) return "M";
  if (isS) return "S";
  return "XS";
};

export const WSScreenKinds = [
  "All",
  "Mobile",
  "MobileAndTablet",
  "Tablet",
  "TabletAndDesktop",
  "Desktop",
  undefined
] as const;
type WSScreenKindsType = typeof WSScreenKinds[number];

export interface WSScreenProps {
  kind?: WSScreenKindsType;
  children: React.ReactNode;
  XS?: boolean;
  S?: boolean;
  M?: boolean;
  L?: boolean;
  XL?: boolean;
}

export const WSScreen: React.FC<WSScreenProps> & {
  Mobile: React.FC<WSScreenProps>;
  MobileAndTablet: React.FC<WSScreenProps>;
  Tablet: React.FC<WSScreenProps>;
  TabletAndDesktop: React.FC<WSScreenProps>;
  Desktop: React.FC<WSScreenProps>;
} = ({ kind, children, XS, S, M, L, XL }) => {
  const isXS = useIsXS();
  const isS = useIsS();
  const isM = useIsM();
  const isL = useIsL();
  const isXL = useIsXL();

  const isMobile = isXS;
  const isTablet = isS;
  const isDesktop = isM || isL || isXL;

  const getShouldShowChildren = (screenKind: WSScreenKindsType): boolean => {
    switch (screenKind) {
      case "All":
        return true;
      case "Mobile":
        return isMobile;
      case "MobileAndTablet":
        return isMobile || isTablet;
      case "Tablet":
        return isTablet;
      case "TabletAndDesktop":
        return isTablet || isDesktop;
      case "Desktop":
        return isDesktop;
      default:
        return false;
    }
  };

  const shouldShowChildren =
    getShouldShowChildren(kind) ||
    (XS && isXS) ||
    (S && isS) ||
    (M && isM) ||
    (L && isL) ||
    (XL && isXL);

  return <>{shouldShowChildren && children}</>;
};

WSScreen.Mobile = (props) => <WSScreen kind="Mobile" {...props} />;
WSScreen.MobileAndTablet = (props) => (
  <WSScreen kind="MobileAndTablet" {...props} />
);
WSScreen.Tablet = (props) => <WSScreen kind="Tablet" {...props} />;
WSScreen.TabletAndDesktop = (props) => (
  <WSScreen kind="TabletAndDesktop" {...props} />
);
WSScreen.Desktop = (props) => <WSScreen kind="Desktop" {...props} />;
