import { toWSDateString, toWSMoneyString, WSDateFormatsType } from "..";

export type FormatterInputBasic = string | boolean | null | undefined;
export type FormatterInputNumber = number;
export type FormatterInputDate = Date;
export type FormatterInput =
  | FormatterInputBasic
  | FormatterInputNumber
  | FormatterInputDate;

export type FormatterOptionsNumber = {
  percentage?: boolean;
  currency?: boolean;
};
export type FormatterOptionsDate = {
  dateType?: WSDateFormatsType;
};
export type FormatterOptions = FormatterOptionsNumber & FormatterOptionsDate;

type Defaults = {
  noDataIndicator: string;
  trueIndicator: string;
  falseIndicator: string;
};

export class Formatter {
  private defaults: Defaults = {
    noDataIndicator: "n/a",
    trueIndicator: "True",
    falseIndicator: "False"
  };

  constructor(defaults?: Partial<Defaults>) {
    this.defaults = {
      ...this.defaults,
      ...defaults
    };
  }

  format(value: FormatterInputBasic): string;

  format(value: FormatterInputNumber, options?: FormatterOptionsNumber): string;

  format(value: FormatterInputDate, options?: FormatterOptionsDate): string;

  format(value: FormatterInput, options?: FormatterOptions) {
    if (typeof value === "string") {
      return value;
    }

    if (typeof value === "number") {
      if (options?.currency) {
        return toWSMoneyString(value);
      }

      if (options?.percentage) {
        return value + "%";
      }

      return String(value);
    }

    if (typeof value === "boolean") {
      return value ? this.defaults.trueIndicator : this.defaults.falseIndicator;
    }

    if (value instanceof Date) {
      return toWSDateString(value, options?.dateType || "monthDate");
    }

    return this.defaults.noDataIndicator;
  }
}

export const defaultFormatter = new Formatter();
