import {
  useModalContext,
  WSButton,
  WSElement,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IClientInvoice,
  PaymentMethod
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { selectorClientCreditCardFee } from "../../../shared/selectors/selectorClientCreditCardFee";
import { getAcceptedPaymentMethods } from "../../utils";
import { PaymentType } from "../PaymentType/PaymentType";
import styles from "./ACHErrorModal.module.scss";
import { openIntercom } from "../../../shared/utils/intercom";

export const ACH_ERROR_MODAL = "ACH_ERROR_MODAL";

export enum ACHErrorType {
  PlaidInstitutionNotFound = "PlaidInstitutionNotFound",
  PlaidInstitutionDidNotRespond = "PlaidInstitutionDidNotRespond",
  PlaidInstitutionForbidden = "PlaidInstitutionForbidden"
}

type Props = {
  onCreditCard?: () => void;
  onManual?: () => void;
  onACH?: () => void;
  onManualACH?: () => void;
  clientInvoice: IClientInvoice;
};

export const ACHErrorModal: React.FC<Props> = ({
  onCreditCard,
  onManual,
  onACH,
  onManualACH,
  clientInvoice
}) => {
  const { closeModal } = useModalContext();
  return (
    <WSModal name={ACH_ERROR_MODAL} size="S">
      {props => (
        <>
          {props?.errorType === ACHErrorType.PlaidInstitutionNotFound ? (
            <>
              <WSText.Heading5 mb="M">
                Unable to find your bank?
              </WSText.Heading5>
              <WSText>
                While Plaid currently integrates with nearly 11,000 financial
                institutions, some institutions are not yet supported. Please
                try an alternative payment method.{" "}
                <WSButton.Link rightIcon="arrow-right" onClick={openIntercom}>
                  Contact support
                </WSButton.Link>
              </WSText>
            </>
          ) : props?.errorType ===
            ACHErrorType.PlaidInstitutionDidNotRespond ? (
            <>
              <WSText.Heading5 mb="M">
                Wingspan can't currently connect to{" "}
                {props?.institutionName || "Institution"}
              </WSText.Heading5>
              <WSText>
                This can occur for a variety of reasons, from bank server
                reliability, to online banking system upgrades or changes, to a
                decision on the part of the institution. Please try an
                alternative payment method.{" "}
                <WSButton.Link rightIcon="arrow-right" onClick={openIntercom}>
                  Contact support
                </WSButton.Link>
              </WSText>
            </>
          ) : props?.errorType === ACHErrorType.PlaidInstitutionForbidden ? (
            <>
              <WSText.Heading5 mb="M">
                Wingspan can't connect to{" "}
                {props?.institutionName || "Institution"}
              </WSText.Heading5>
              <WSText>
                This can occur for a variety of reasons, from bank server
                reliability, to online banking system upgrades or changes, to a
                decision on the part of the institution. Please try an
                alternative payment method.{" "}
                <WSButton.Link rightIcon="arrow-right" onClick={openIntercom}>
                  Contact support
                </WSButton.Link>
              </WSText>
            </>
          ) : props?.error ? (
            <>
              <WSText.Heading5 mb="M">
                We encountered an error when making a payment with this account.
              </WSText.Heading5>
              <WSErrorMessage
                mb="M"
                error={props.error}
                contextKey="PayInvoice"
              />
              <WSText>Please select an alternative payment method.</WSText>
            </>
          ) : (
            <>
              <WSText.Heading5 mb="M">
                Unable to link your bank account?
              </WSText.Heading5>
              <WSText>
                Please try an alternative payment method.{" "}
                <WSButton.Link rightIcon="arrow-right" onClick={openIntercom}>
                  Contact support
                </WSButton.Link>
              </WSText>
            </>
          )}

          <WSElement mt="2XL">
            {onCreditCard &&
              getAcceptedPaymentMethods(clientInvoice).includes(
                PaymentMethod.Credit
              ) && (
                <PaymentType
                  className={styles.paymentType}
                  icon="card"
                  title="Credit card"
                  feeText={selectorClientCreditCardFee(
                    clientInvoice.creditFeeHandling
                  )}
                  pill={{
                    text: "Fastest"
                  }}
                  mb="XL"
                  onClick={() => {
                    closeModal(ACH_ERROR_MODAL);
                    onCreditCard();
                  }}
                  data-testid="creditCardButton"
                />
              )}

            {onManual &&
              getAcceptedPaymentMethods(clientInvoice).includes(
                PaymentMethod.Manual
              ) && (
                <PaymentType
                  className={styles.paymentType}
                  icon="send"
                  title="Wire / Manual ACH"
                  feeText="Free"
                  mb="XL"
                  onClick={() => {
                    closeModal(ACH_ERROR_MODAL);
                    onManual();
                  }}
                  data-testid="manualButton"
                />
              )}

            {onACH && (
              <PaymentType
                className={styles.paymentType}
                icon="bank"
                title="Try Another Account"
                feeText="Free"
                onClick={() => {
                  closeModal(ACH_ERROR_MODAL);
                  onACH();
                }}
                data-testid="achButton"
              />
            )}

            {onManualACH && (
              <PaymentType
                className={styles.paymentType}
                icon="bank"
                title="Set up payment method manually"
                feeText="Free"
                onClick={() => {
                  closeModal(ACH_ERROR_MODAL);
                  onManualACH();
                }}
                data-testid="achButton"
              />
            )}
          </WSElement>
        </>
      )}
    </WSModal>
  );
};
