import React from "react";
import { Route, Switch } from "react-router-dom";
import { RouteRequestOnboardingLink } from "./RouteRequestOnboardingLink";
import { RouteRequestOnboardingLinkSuccess } from "./RouteRequestOnboardingLinkSuccess";

export const RequestOnboardingLink: React.FC = () => (
  <Switch>
    <Route
      path="/1099/request-onboarding-link"
      component={RouteRequestOnboardingLink}
      exact
    />
    <Route
      path="/1099/request-onboarding-link/success"
      component={RouteRequestOnboardingLinkSuccess}
      exact
    />
  </Switch>
);
