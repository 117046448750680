import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import React from "react";
import { getFsExcludeClassName } from "../../../utils/getFsExcludeClassName";
import { getInputTestIdByName } from "../../../utils/getInputTestIdByName";
import { WSInputBaseProps } from "../../types/forms";
import { detachFieldProps, WSField, WSFieldProps } from "../WSField/WSField";
import {
  WSInputContainer,
  WSInputContainerProps
} from "../WSInputContainer/WSInputContainer";

export type WSInputTextProps = WSInputBaseProps &
  Omit<WSInputContainerProps, "align" | "size" | "onClick"> &
  WSFieldProps & {
    type?: "text" | "email" | "url";
    containerRef?: React.RefObject<HTMLElement>;
    minLength?: number;
    maxLength?: number;
  };

export const WSInputText = React.forwardRef<HTMLInputElement, WSInputTextProps>(
  (props, ref) => {
    const {
      name,
      status,
      className,
      icon,
      iconRight,
      inputAction,
      disabled,
      onChange,
      inputClassName,
      containerRef,
      fsExclude,
      type = "text",
      ...otherProps
    } = props;

    const { fieldProps, ...inputProps } = detachFieldProps(otherProps);

    return (
      <WSField {...fieldProps} status={status}>
        <WSInputContainer
          status={status}
          icon={icon}
          iconRight={iconRight}
          inputAction={inputAction}
          disabled={disabled}
          className={className}
          ref={containerRef}
        >
          <InputText
            ref={ref}
            disabled={disabled}
            onChange={(e) => onChange(e.target.value, e)}
            type={type}
            className={classNames(
              inputClassName,
              getFsExcludeClassName(fsExclude)
            )}
            data-testid={getInputTestIdByName(name)}
            {...inputProps}
          />
        </WSInputContainer>
      </WSField>
    );
  }
);

WSInputText.displayName = "WSInputText";
