// these schemas are established here https://www.notion.so/wingspan/fe955d2904ec47aa8f48d4aae43bc4ef?v=6fa33777de0341c093feb16515a629bb

import {
  IActivityContext,
  IGrowthSourceNames
} from "@wingspanhq/users/dist/lib/interfaces";

import { Location } from "history";
import { WHITE_LABEL_UNDERWRITER } from "../shared/constants/whiteLabel";

export const GrowthSourceNames = IGrowthSourceNames;

export interface GrowthSource {
  source: IGrowthSourceNames;
  cleaned: string | undefined;
}

export const GrowthSources: Array<GrowthSource> = [
  {
    source: IGrowthSourceNames.Partner,
    cleaned: "p"
  },
  {
    source: IGrowthSourceNames.Referral,
    cleaned: "r"
  },
  {
    source: IGrowthSourceNames.Collaborator,
    cleaned: "c"
  },
  {
    source: IGrowthSourceNames.Team,
    cleaned: "t"
  },
  {
    source: IGrowthSourceNames.Invoice,
    cleaned: "i"
  },
  {
    source: IGrowthSourceNames.Content,
    cleaned: undefined
  },
  {
    source: IGrowthSourceNames.Enterprise,
    cleaned: "e"
  },
  {
    source: IGrowthSourceNames.EnterpriseClient,
    cleaned: "autopay"
  }
];

export interface GrowthAttributionDetails {
  growthSource: IGrowthSourceNames;
  growthName?: string;
  growthUserId?: string;
}

export const isUnderWriterMember = (context: IActivityContext) => {
  if (context?.growthSourceName) {
    return context.growthSourceName === WHITE_LABEL_UNDERWRITER;
  }
  return false;
};

export const getGrowthSourceFromPathname = (
  location: Location<{ growthSource?: string }>
): GrowthSource | undefined => {
  let currentCleaned: string | undefined;

  const growthUrlMatchWithSignUp = location.pathname.match(
    /\/([a-z])(?:\/(?:1099-)?(?:pre-)?(?:employer-)?sign-up)/
  );
  const growthUrlMatchWithAutopay = location.pathname.match(/\/(autopay)\//);

  currentCleaned =
    growthUrlMatchWithSignUp?.[1] || growthUrlMatchWithAutopay?.[1];

  if (currentCleaned) {
    const foundGrowthSource = GrowthSources.find(
      ({ source, cleaned }) => cleaned === currentCleaned
    );

    if (foundGrowthSource) {
      return foundGrowthSource;
    }
  }

  const growthSource = location.state?.growthSource;

  if (growthSource) {
    const foundGrowthSource = GrowthSources.find(
      ({ source, cleaned }) => source === growthSource
    );

    return foundGrowthSource;
  }

  return undefined;
};
