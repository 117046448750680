import { WSButton, WSModal, WSText } from "@wingspanhq/fe-component-library";
import React from "react";
import { openIntercom } from "../../utils/intercom";
import { openInNewTab } from "../../utils/openInNewTab";
import { WS_LINKS } from "../../../types/wsLinks";

export const MODAL_WHY_DO_WE_COLLECT_BUSINESS_INFORMATION =
  "MODAL_WHY_DO_WE_COLLECT_BUSINESS_INFORMATION";

export const ModalWhyDoWeCollectBusinessInformation: React.FC = () => (
  <WSModal
    name={MODAL_WHY_DO_WE_COLLECT_BUSINESS_INFORMATION}
    title="Why do we collect this information?"
    size="S"
  >
    <WSText mb="XL">
      Wingspan collects your W-9 or W-8BEN to facilitate client’s tax reporting
      requirements.
    </WSText>
    <WSButton.Link
      mb="XL"
      onClick={() => {
        openInNewTab(WS_LINKS.security);
      }}
    >
      Wingspan is SOC 2 and PCI certified secure.
    </WSButton.Link>
    <WSText>
      Have a question?{" "}
      <WSButton.Link onClick={openIntercom}>Contact support</WSButton.Link>
    </WSText>
  </WSModal>
);
