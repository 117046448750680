import {
  IMemberFileResponse,
  IMemberFileSummaryResponse,
  IMemberPublicFileResponse,
  ITemplate
} from "@wingspanhq/files/dist/lib/interfaces";
import { QueryConfig } from "react-query";
import { PrivateFilesAPIFilter, filesService } from "../../services/files";
import { getAllEntries, WSServiceError } from "../../utils/serviceHelper";
import { standardQueryOptions, useWSQuery } from "../helpers";
import {
  QUERY_FILES_MEMBER_PRIVATE_FILES,
  QUERY_FILES_MEMBER_PUBLIC_FILES,
  QUERY_FILE_MEMBER_PUBLIC_FILE,
  QUERY_FILE_MEMBER_PUBLIC_FILE_SUMMARY,
  QUERY_TEMPLATE,
  QUERY_TEMPLATES
} from "./keys";

export const useMemberPublicFiles = (
  queryConfig?: QueryConfig<IMemberPublicFileResponse[], WSServiceError>
) =>
  useWSQuery<IMemberPublicFileResponse[], WSServiceError>(
    QUERY_FILES_MEMBER_PUBLIC_FILES,
    filesService.member.public.list,
    {
      ...standardQueryOptions,
      ...queryConfig
    }
  );

export const useGetMemberPublicFile = (
  id: string,
  queryConfig?: QueryConfig<IMemberPublicFileResponse, WSServiceError>
) =>
  useWSQuery<IMemberPublicFileResponse, WSServiceError>(
    [QUERY_FILE_MEMBER_PUBLIC_FILE, id],
    () => filesService.member.public.get(id),
    {
      ...standardQueryOptions,
      ...queryConfig
    }
  );

export const useMemberPublicFileSummaryQuery = (
  id: string,
  queryConfig?: QueryConfig<IMemberFileSummaryResponse, WSServiceError>
) =>
  useWSQuery<IMemberFileSummaryResponse, WSServiceError>(
    [QUERY_FILE_MEMBER_PUBLIC_FILE_SUMMARY, id],
    () => filesService.member.public.summary(id),
    {
      ...standardQueryOptions,
      ...queryConfig
    }
  );

type PrivateFilesFilter = Partial<{ hidden: boolean; tags: string[] }>;
const mapFilesFilter = (filters?: PrivateFilesFilter) => {
  const _filters: PrivateFilesAPIFilter = {};
  _filters.hidden = filters?.hidden;
  if (filters?.tags && filters.tags?.length > 0) {
    _filters.tags = {
      in: filters.tags
    };
  }
  return { filter: _filters };
};

export const useMemberPrivateFiles = (
  filters?: PrivateFilesFilter,
  queryConfig?: QueryConfig<IMemberFileResponse[], WSServiceError>
) =>
  useWSQuery<IMemberFileResponse[], WSServiceError>(
    [QUERY_FILES_MEMBER_PRIVATE_FILES, filters],
    () => filesService.member.private.list(mapFilesFilter(filters)),
    {
      ...standardQueryOptions,
      ...queryConfig
    }
  );

export const useMemberPrivateFileQuery = (
  fileId: string,
  queryConfig?: QueryConfig<IMemberFileResponse, WSServiceError>
) =>
  useWSQuery<IMemberFileResponse, WSServiceError>(
    QUERY_FILES_MEMBER_PRIVATE_FILES,
    () => filesService.member.private.get(fileId),
    {
      ...standardQueryOptions,
      ...queryConfig
    }
  );

export const useTemplatesQuery = (
  config?: QueryConfig<ITemplate[], WSServiceError>
) => {
  return useWSQuery<ITemplate[], WSServiceError>(
    QUERY_TEMPLATES,
    () => getAllEntries(filesService.template.list),
    config
  );
};

export const useTemplateQuery = (
  id: string,
  queryConfig?: QueryConfig<ITemplate, WSServiceError>
) =>
  useWSQuery<ITemplate, WSServiceError>(
    [QUERY_TEMPLATE, id],
    () => filesService.template.get(id),
    {
      ...standardQueryOptions,
      ...queryConfig
    }
  );
