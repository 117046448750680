import { EngagementListQueryResponse } from "../../../services/engagements";
import flatten from "lodash/flatten";

export const selectorInfinityEngagements = (
  queryResponses: EngagementListQueryResponse[] = []
) => {
  return {
    listSize: queryResponses[0]?.summary.listSize,
    list: flatten(queryResponses.map(response => response.data))
  };
};
