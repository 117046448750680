import {
  toWSMoneyString,
  WSAvatar,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSLoader,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import { useWithholdingBalance } from "../../query/taxes/queries";

type Props = WSElementProps;

export const MethodTaxWithholdings: React.FC<Props> = ({ ...elementProps }) => {
  const queryWithholdingBalance = useWithholdingBalance();

  const title = "Tax Withholdings";
  const description = queryWithholdingBalance.data ? (
    `${toWSMoneyString(queryWithholdingBalance.data.available)} is available`
  ) : (
    <WSLoader.Spinner size="S" />
  );

  return (
    <WSFlexBox wrap="nowrap" alignItems="center" {...elementProps}>
      <WSAvatar.Icon
        colorBackground="garnet"
        color="white"
        icon="wallet"
        mr="M"
      />

      <WSScreen.Mobile>
        <WSElement>
          <WSText weight="medium" mr="M">
            {title}
          </WSText>
          <WSText.ParagraphSm color="gray500">{description}</WSText.ParagraphSm>
        </WSElement>
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        <WSText weight="medium" mr="M">
          {title}
        </WSText>
        <WSText color="gray500">{description}</WSText>
      </WSScreen.TabletAndDesktop>
    </WSFlexBox>
  );
};
