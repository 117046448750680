import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFileInput,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSIcon,
  WSLoader,
  WSText,
  WSTextArea,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React, { ChangeEvent } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Overlay } from "../../../components/Overlay";
import { Tooltip } from "../../../components/Tooltip";
import { useMemberPublicCreate } from "../../../query/files/mutations";
import { useGetMemberPublicFile } from "../../../query/files/queries";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { useInvoicesFormContext } from "./InvoicesForm";

export type AttachmentValues = {
  fileId: string;
  remove?: string;
};

export type OtherSectionValues = {
  other: {
    projectName: string;
    notes: string;
    attachments: AttachmentValues[];
  };
};

type Props = { fullWidth?: boolean; disabled?: boolean } & WSElementProps;

export const OtherSection: React.FC<Props> = ({
  fullWidth,
  disabled,
  ...elementProps
}) => {
  const { register, control, trigger } = useFormContext();
  const { invoicingConfigForPayee } = useInvoicesFormContext();
  const qFeatureFlags = useFeatureFlags();
  const [memberPublicCreate, memberPublicCreateMeta] = useMemberPublicCreate();
  const { fields: attachmentsField, append, remove, insert } = useFieldArray<
    AttachmentValues
  >({
    control,
    name: "other.attachments"
  });

  return (
    <WSElement {...elementProps}>
      <WSGrid>
        <WSGrid.Item span={{ m: fullWidth ? "12" : "7" }}>
          <WSFormOld.Field
            mb="XL"
            name="other.projectName"
            label={
              invoicingConfigForPayee?.requireProjectName?.enabled
                ? "Project name*"
                : "Project name (Optional)"
            }
            component={WSTextInput}
          />

          <WSFormOld.Field
            mb="XL"
            name="other.notes"
            label="Notes (Optional)"
            component={WSTextArea}
          />

          <WSElement>
            {disabled && <Overlay white overParent />}

            {qFeatureFlags.data?.purchaseOrderNumber ? (
              <WSFormOld.Field
                mb="XL"
                name="purchaseOrderNumber"
                label={
                  invoicingConfigForPayee?.requirePONumber?.enabled
                    ? "PO Number*"
                    : "PO Number (Optional)"
                }
                component={WSTextInput}
              />
            ) : null}

            <WSFormOld.Label>
              {invoicingConfigForPayee?.requireAttachments?.enabled
                ? "Attachments*"
                : "Attachments (Optional)"}
              {"  "}
              <Tooltip>
                Attachments will be accessible by anyone with the invoice link.
                Do not include sensitive information.
              </Tooltip>
            </WSFormOld.Label>

            {attachmentsField.map((attachment, index: number) =>
              attachment.fileId ? (
                <>
                  <input
                    ref={register()}
                    type="hidden"
                    name={`other.attachments[${index}].remove`}
                    defaultValue={attachment.remove}
                  />
                  <input
                    ref={register()}
                    type="hidden"
                    name={`other.attachments[${index}].fileId`}
                    defaultValue={attachment.fileId}
                  />
                  <AttachmentPreview
                    key={`${index}_${attachment.fileId}`}
                    hidden={!!attachment.remove}
                    mb="M"
                    fileId={attachment.fileId}
                    onRemove={async () => {
                      remove(index);
                      insert(index, { ...attachment, remove: "1" });
                      // manually trigger validation for attachments
                      await trigger("other.attachments");
                    }}
                  />
                </>
              ) : null
            )}

            {memberPublicCreateMeta.isLoading && <WSLoader.Spinner mb="M" />}

            <WSFileInput
              name="other.attachmentsFileUpload"
              onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
                event.preventDefault();
                if (event.target && event.target.files && event.target.files) {
                  const file = event.target.files[0];
                  const data = new FormData();
                  data.append("file", file);
                  await memberPublicCreate(data, {
                    onSuccess: async createdFiles => {
                      createdFiles.forEach(createdFile => {
                        append({
                          fileId: createdFile.fileId
                        });
                      });
                      // manually trigger validation for attachments
                      await trigger("other.attachments");
                    }
                  });
                }
              }}
            >
              <WSButton.Link size="M" type="button" icon="plus-circle">
                Attachment
              </WSButton.Link>
              <WSFormOld.Error name="other.attachments" />
            </WSFileInput>

            {memberPublicCreateMeta.isError && (
              <WSText.ParagraphSm color="garnet" my="M">
                Ooops! Something wrong with this file..
              </WSText.ParagraphSm>
            )}
          </WSElement>
        </WSGrid.Item>
      </WSGrid>
    </WSElement>
  );
};

type AttachmentProps = {
  fileId: string;
  onRemove: () => void;
} & WSElementProps;

const AttachmentPreview: React.FC<AttachmentProps> = ({
  fileId,
  onRemove,
  ...elementProps
}) => {
  const getMemberPublicFile = useGetMemberPublicFile(fileId);

  return (
    <WSElement {...elementProps}>
      {getMemberPublicFile.isLoading ? (
        <WSLoader.Spinner />
      ) : (
        <WSFlexBox.CenterY>
          <WSText.ParagraphSm mr="M">
            {getMemberPublicFile.data?.filename}
          </WSText.ParagraphSm>
          <WSIcon
            block
            name="minus-circle"
            onClick={() => {
              onRemove();
            }}
            color="blue400"
          />
        </WSFlexBox.CenterY>
      )}
    </WSElement>
  );
};
