import {
  useWSModal,
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSElement,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { ReportsColumn } from "../../types";
import styles from "./index.module.scss";

type StateItem = ReportsColumn<any> & { state: boolean };
const ModalReportsSelectColumns: React.FC<{
  onClose(): void;
  selectedColumns: string[];
  columns: ReportsColumn<any>[];
  onSubmit(props: { columns: string[] }): void;
}> = ({ columns, onSubmit, selectedColumns, onClose }) => {
  const [state, setState] = useState<StateItem[]>(() => {
    return columns.map(column => ({
      ...column,
      state: selectedColumns.includes(column.value)
    }));
  });

  const notDisabledColumns = columns.filter(column => !column.disabled);

  const groups = columns.reduce<(string | undefined)[]>(
    (acc, column) => {
      if (column.group && !acc.includes(column.group)) {
        acc.push(column.group);
      }
      return acc;
    },
    [undefined]
  );

  const error = "Select at least 2 columns";
  const currentSelectedColumns = state
    .filter(column => column.state)
    .map(column => column.value);

  const isAllSelected = notDisabledColumns.every(option =>
    currentSelectedColumns.includes(option.value)
  );

  return (
    <WSElement mt="XS">
      <WSFlexBox.CenterY direction="row">
        {isAllSelected ? (
          <WSButton.Link
            my="M"
            type="button"
            onClick={() => {
              setState(
                state
                  .filter(
                    column =>
                      !column.disabled ||
                      (column.disabled && column.preselected)
                  )
                  .map(column => ({
                    ...column,
                    state: column.disabled && column.preselected ? true : false
                  }))
              );
            }}
            name="selectNone"
          >
            Select none
          </WSButton.Link>
        ) : (
          <WSButton.Link
            name="selectAll"
            my="M"
            type="button"
            onClick={() => {
              setState(
                state
                  .filter(
                    column =>
                      !column.disabled ||
                      (column.disabled && column.preselected)
                  )
                  .map(column => ({ ...column, state: true }))
              );
            }}
          >
            Select all
          </WSButton.Link>
        )}
      </WSFlexBox.CenterY>

      <WSElement>
        {groups.map((group, groupIndex) => (
          <WSElement key={`${group} ${groupIndex}`} mb="XL">
            {groups.length > 1 ? (
              <WSText weight="medium" color="gray600">
                {group || "General fields"}
              </WSText>
            ) : null}
            <WSFlexBox direction="row">
              {state
                .filter(column => column.group === group)
                .map((column, columnIndex) => (
                  <WSCheckboxToggle
                    className={styles.checkbox}
                    mt="M"
                    name={`column-${column.value}-${columnIndex}`}
                    value={column.state}
                    label={column.label}
                    disabled={column.disabled}
                    onChange={newValue => {
                      setState(
                        state.map(_column => {
                          if (_column.value === column.value) {
                            return { ..._column, state: newValue };
                          }

                          return _column;
                        })
                      );
                    }}
                  />
                ))}
            </WSFlexBox>
          </WSElement>
        ))}
      </WSElement>

      {currentSelectedColumns.length >= 2 ? null : (
        <WSText mt="M" color="garnet">
          {error}
        </WSText>
      )}

      <WSButtons mt="2XL" format="modal">
        <WSButton.Primary
          style={{ flex: 1 }}
          disabled={currentSelectedColumns.length < 2}
          onClick={() => {
            onClose();
            onSubmit({
              // To save order
              columns: state
                .filter(column => column.state)
                .map(column => column.value)
            });
          }}
          data-testid="submitColumnsButton"
        >
          Save
        </WSButton.Primary>
        <WSButton.Secondary style={{ flex: 1 }} onClick={onClose}>
          Cancel
        </WSButton.Secondary>
      </WSButtons>
    </WSElement>
  );
};

export const useSelectColumnsModal = () =>
  useWSModal(ModalReportsSelectColumns, {
    title: "Selected columns",
    size: "M",
    allowClickOutsideToClose: true
  });
