import { IPayerEngagementResponse } from "@wingspanhq/payments/dist/interfaces";
import { IPayeeEngagementResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { RequirementTableItemData } from "../types";
import { selectorIsRequirement } from "./selectorIsRequirement";

export const selectorRequirementsFromEngagement = (
  engagement: IPayeeEngagementResponse | IPayerEngagementResponse
): RequirementTableItemData[] => {
  const allRequirements = engagement.engagementId
    ? engagement.requirements
    : engagement.eligibilityRequirements;

  const extendedRequirements = allRequirements.map(req => ({
    ...req,
    engagementId: engagement.payerPayeeEngagementId,
    relatedEngagementRequirementIds: [
      {
        engagementId: engagement.payerPayeeEngagementId,
        relatedRequirementId: selectorIsRequirement(req) && req.requirementId
      }
    ]
  }));

  return extendedRequirements;
};
