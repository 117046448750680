import React, { useEffect } from "react";

export const useBrowserPageTitle = (
  title: string,
  postfix = " - Wingspan Collective"
) => {
  return useEffect(() => {
    window.document.title = title + postfix;
  }, [title, postfix]);
};

export const BrowserPageTitle: React.FC<{ title: string }> = ({ title }) => {
  useBrowserPageTitle(title);

  return null;
};
