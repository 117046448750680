import {
  WSPill,
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAllOrganizationUsers } from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { getSearchedOrganizations } from "../../utils/getSearchedOrganizations";
import { getUserName } from "../../../../query/users/selectors";

import styles from "./index.module.scss";
import debounce from "lodash/debounce";

export type ModalSelectOrganizationsProps = {
  selectedAccounts: Array<string>;
  onClose: (result?: Array<string>) => void;
} & WSElementProps;

export const ModalSelectOrganizations: React.FC<ModalSelectOrganizationsProps> = ({
  onClose,
  selectedAccounts = [],
  ...elementProps
}) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [search, setSearch] = useState("");
  const [selectedOrganizations, setSelectedOrganizations] = useState(
    selectedAccounts.reduce(
      (acc, key) => {
        acc[key] = true;
        return acc;
      },
      {} as {
        [key: string]: boolean;
      }
    )
  );

  const queryOrganizationUsers = useAllOrganizationUsers();

  const selectedOrganizationsArray = useMemo(() => {
    return Object.entries(selectedOrganizations)
      .filter(([, value]) => value)
      .map(([key]) => key);
  }, [selectedOrganizations]);

  const handleSearch = useCallback(
    debounce((query: string) => {
      setSearch(query);
    }, 1000),
    [setSearch]
  );

  useEffect(() => {
    handleSearch(searchInputValue);
  }, [searchInputValue, handleSearch]);

  return (
    <WSElement {...elementProps}>
      <WSText mb="XL">
        What organization accounts would you like to grant {""}access to?
      </WSText>

      <WSQueries queries={{ queryOrganizationUsers }}>
        {({ queryOrganizationUsersData }) => {
          const searchedOrganizations = getSearchedOrganizations(
            search,
            queryOrganizationUsersData
          );

          return (
            <>
              <WSElement>
                <WSTextInput
                  icon="search"
                  mb="2XL"
                  pl="M"
                  value={searchInputValue}
                  className={styles.search}
                  inputClassName={styles.searchInput}
                  onChange={event => {
                    setSearchInputValue(event.target.value);
                  }}
                />

                <WSFlexBox mt="M" mb="XL">
                  {searchedOrganizations
                    .filter(o => selectedOrganizations[o.userId])
                    .map(organization => (
                      <WSPill
                        key={organization.userId}
                        mr="M"
                        mt="XS"
                        text={getUserName(organization) || organization.email}
                        onDismiss={() =>
                          setSelectedOrganizations(prev => ({
                            ...prev,
                            [organization.userId]: false
                          }))
                        }
                      />
                    ))}
                </WSFlexBox>
                <WSDivider mb="XL" />

                <WSElement className={styles.list}>
                  {searchedOrganizations.map(organization => (
                    <WSElement key={organization.userId}>
                      <WSFlexBox.CenterY
                        wrap="nowrap"
                        justify="space-between"
                        my="M"
                      >
                        <WSText.ParagraphSm>
                          {getUserName(organization) || organization.email}
                        </WSText.ParagraphSm>
                        <WSCheckboxToggle
                          mb="M"
                          name={`selectedOrganizations[${organization.userId}]`}
                          label=""
                          value={selectedOrganizations[organization.userId]}
                          onChange={value => {
                            setSelectedOrganizations(prev => ({
                              ...prev,
                              [organization.userId]: value
                            }));
                          }}
                        />
                      </WSFlexBox.CenterY>
                      <WSDivider mb="XL" />
                    </WSElement>
                  ))}
                  {queryOrganizationUsersData.length &&
                  !searchedOrganizations.length ? (
                    <WSFlexBox.Center my="XL" direction="column">
                      <WSText.Heading5 color="gray500">
                        No results for "{search}"
                      </WSText.Heading5>
                      <WSText.ParagraphSm color="gray400" mt="M">
                        {selectedOrganizationsArray.length
                          ? `${selectedOrganizationsArray.length} items selected are hidden. `
                          : ""}
                        Clear search to see all items
                      </WSText.ParagraphSm>
                      <WSButton.Tertiary
                        mt="M"
                        type="button"
                        onClick={() => {
                          setSearchInputValue("");
                          setSearch("");
                        }}
                      >
                        Clear search
                      </WSButton.Tertiary>
                    </WSFlexBox.Center>
                  ) : null}
                  {queryOrganizationUsersData.length === 0 ? (
                    <WSFlexBox.Center my="XL">
                      <WSText.ParagraphSm color="gray400">
                        You currently do not have any accounts
                      </WSText.ParagraphSm>
                    </WSFlexBox.Center>
                  ) : null}
                </WSElement>
              </WSElement>

              <WSButtons forceFullWidth mt="2XL">
                <WSButton.Primary
                  mb="M"
                  disabled={selectedOrganizationsArray.length === 0}
                  type="button"
                  onClick={() => {
                    onClose(selectedOrganizationsArray);
                  }}
                  fullWidth
                  name="next"
                >
                  Continue
                </WSButton.Primary>
                <WSButton.Secondary
                  fullWidth
                  name="cancel"
                  type="button"
                  onClick={() => onClose(undefined)}
                >
                  Cancel
                </WSButton.Secondary>
              </WSButtons>
            </>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
