import { WSElement, WSText } from "@wingspanhq/fe-component-library";
import React, { ReactNode } from "react";

export type InvoiceActivity = {
  title: ReactNode;
  description: string;
  date: Date;
};

type Props = {
  activities: InvoiceActivity[];
};

export const InvoiceDetailsActivity: React.FC<Props> = ({ activities }) => (
  <>
    {activities.map((activity, index) => (
      <WSElement
        mb={index === activities.length - 1 ? undefined : "S"}
        key={index}
      >
        <WSText weight="medium" mb="XS" color="gray600">
          {activity.title}
        </WSText>
        <WSText color="gray500">{activity.description}</WSText>
      </WSElement>
    ))}
  </>
);
