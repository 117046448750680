import { WSModal, useModalContext } from "@wingspanhq/fe-component-library";
import React from "react";
import { FormManuallyAddInternationalAccount } from "../FormManuallyAddInternationalAccount";

export const MODAL_MANUALLY_ADD_INTERNATIONAL_ACCOUNT =
  "MODAL_MANUALLY_ADD_INTERNATIONAL_ACCOUNT";

export type ModalManuallyAddInternationalAccountProps = {
  onSuccess?: () => void;
};

export const ModalManuallyAddInternationalAccount: React.FC = () => {
  const { closeModal } = useModalContext();

  return (
    <WSModal
      name={MODAL_MANUALLY_ADD_INTERNATIONAL_ACCOUNT}
      size="S"
      title="Where should we pay you?"
    >
      {(props?: ModalManuallyAddInternationalAccountProps) => (
        <FormManuallyAddInternationalAccount
          onSuccess={() => {
            closeModal(MODAL_MANUALLY_ADD_INTERNATIONAL_ACCOUNT);
            props?.onSuccess?.();
          }}
        />
      )}
    </WSModal>
  );
};
