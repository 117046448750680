import {
  WSActions,
  WSElement,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { OnboardingStep } from "../../types";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { PayerRequirements } from "./PayerRequirements";
import { useQueryAllPayersWithEngagements } from "../../queries/useQueryAllPayersWithEngagements";
import { WSQueries } from "../../../../query/WSQuery";
import {
  getActivePayersWithEngagement,
  isEligibilityRequirementsCompleted
} from "../../selectors/getActivePayersWithEngagement";
import { useUserId } from "../../../../query/hooks/helpers";

export const EligibilityRequirements: React.FC<OnboardingStep> = ({
  title,
  onNext,
  onSkip
}) => {
  const userId = useUserId();
  const queryPayersEngagements = useQueryAllPayersWithEngagements();

  const isCompleted = queryPayersEngagements.isFetched
    ? queryPayersEngagements.data &&
      isEligibilityRequirementsCompleted(queryPayersEngagements.data)
    : false;

  const isSkipActive = queryPayersEngagements.isFetched
    ? queryPayersEngagements.data &&
      isEligibilityRequirementsCompleted(queryPayersEngagements.data, true)
    : false;

  return (
    <WSList gap="2XL">
      <WSPanel p="XL">
        <WSSectionToolbar title={title} />
        <WSText.ParagraphSm mt="L" color="gray500">
          Complete the following eligibility requirements to get paid by your
          client(s). If you skip for now or don’t complete any requirements,
          these setup tasks will appear on your “Get started” homepage.
          Incomplete tasks will delay your ability to get paid.
        </WSText.ParagraphSm>
      </WSPanel>

      <WSQueries queries={{ queryPayersEngagements }}>
        {({ queryPayersEngagementsData }) => {
          const data = getActivePayersWithEngagement(
            queryPayersEngagementsData
          );

          return isCompleted ? (
            <WSPanel p="XL">
              <WSSectionToolbar
                title={`All eligibility requirements is completed!`}
                mb="M"
              />
              <WSText.ParagraphSm mt="L" color="gray500">
                Press Continue to proceed to the next step.
              </WSText.ParagraphSm>
            </WSPanel>
          ) : (
            data.map(({ payer, engagements }) => {
              const names = wsName({
                user: payer.user,
                member: payer.member,
                payeeOwnedData: payer.payeeOwnedData
              });

              return (
                <WSElement key={payer.payerId}>
                  <WSSectionToolbar
                    title={`${names.getResolvedName()} requirements`}
                    mb="M"
                  />
                  <PayerRequirements
                    payerId={payer.payerId}
                    payeeId={userId}
                    engagements={engagements}
                  />
                </WSElement>
              );
            })
          );
        }}
      </WSQueries>

      <WSActions
        alignment="fill"
        orientation="vertical"
        buttons={[
          {
            label: "Continue",
            kind: "Primary",
            disabled: !isCompleted,
            onClick: onNext
          },
          ...(isSkipActive && !isCompleted
            ? [
                {
                  label: "Skip for now",
                  kind: "Link",
                  onClick: onSkip
                } as const
              ]
            : [])
        ]}
      />
    </WSList>
  );
};
