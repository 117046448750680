import { WSButton } from "@wingspanhq/fe-component-library";
import React from "react";
import { bulkActionFactory } from "../../../../utils/bulkActionFactory";

export async function runner<T>({
  failMessage,
  undoAction,
  tryAction,
  items,
  openSnackbar,
  successMessage,
  noUndo = false
}: {
  tryAction: (item: T) => Promise<any>;
  undoAction: (item: T) => Promise<any>;
  items: T[];
  successMessage: (successItems: T[]) => string;
  failMessage: (failedItems: T[]) => string;
  openSnackbar: any;
  noUndo?: boolean;
}) {
  const run = async (currentItems: T[]) => {
    const { successItems, failedItems } = await bulkActionFactory<T>({
      action: tryAction
    }).run(currentItems);

    if (successItems.length) {
      openSnackbar({
        duration: 4000,
        type: "success",
        message: successMessage(successItems),
        renderAction: noUndo
          ? undefined
          : (onClose: any) => {
              return (
                <WSButton.Link
                  onClick={async () => {
                    await Promise.all(
                      currentItems.map(async item => {
                        await undoAction(item);
                      })
                    );

                    onClose();
                  }}
                >
                  Undo
                </WSButton.Link>
              );
            }
      });
    }

    if (failedItems.length) {
      openSnackbar({
        duration: 8000,
        type: "warning",
        message: failMessage(failedItems),
        renderAction(onClose: any) {
          return (
            <WSButton.Link
              onClick={async () => {
                await run(failedItems);

                onClose();
              }}
            >
              Retry
            </WSButton.Link>
          );
        }
      });
    }
  };

  await run(items);
}
