import { useHistory, useRouteMatch } from "react-router-dom";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { StartNewCorrectionFlow } from "../StartNewCorrectionFlow";

export const RouteStartNewCorrection: React.FC<SetupRouteComponentProps> = ({
  onNext,
  onBack
}) => {
  const history = useHistory();
  const match = useRouteMatch<{ taxFormId: string }>();
  const { taxFormId } = match.params;

  const goBack = () => {
    if (onBack) {
      onBack();
      return;
    }

    history.push(`/member/1099-filing/details/${taxFormId}`);
  };

  return (
    <StartNewCorrectionFlow
      basePath={`/member/1099-filing/details/:taxFormId/start-correction`}
      onBack={goBack}
      onFinish={onNext}
    />
  );
};
