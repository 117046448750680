import cn from "classnames";
import React, { forwardRef } from "react";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import styles from "./WSIcon.module.scss";
import { WSIconName, icons } from "./icons";

export type WSIconSize = "XS" | "S" | "M" | "L";

export interface WSIconProps extends Omit<WSElementProps, "name"> {
  name: WSIconName;
  size?: WSIconSize;
  block?: boolean;
}

export const WSIcon = forwardRef<HTMLSpanElement, WSIconProps>(
  ({ name, size = "M", className, block, ...componentProps }, ref) => {
    const SVG = icons[name];

    const classNames = cn(
      className,
      styles.base,
      styles[`size-${size}`],
      block && styles.block
    );

    return (
      <WSElement
        ref={ref}
        as="i"
        className={classNames}
        data-testid={`${name}Icon`}
        {...componentProps}
      >
        <SVG className={styles.svg} />
      </WSElement>
    );
  }
);

export type { WSIconName };

export const isValidIconName = (name: string): name is WSIconName =>
  icons[name as WSIconName] !== undefined;
