import { useUserProfileData } from "./utils/useUserProfileData";
import { isUserIdsIncluded } from "./utils/isUserIdsIncluded";
import { HIDE_SEND_PAYMENTS_NAV_IDS } from "./helpers";

export const useShowSendPaymentsNav = () => {
  const userData = useUserProfileData();

  const showSendPaymentsNav = !isUserIdsIncluded(
    userData,
    HIDE_SEND_PAYMENTS_NAV_IDS
  );

  return showSendPaymentsNav;
};
