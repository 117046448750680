import { WSLoader } from "@wingspanhq/fe-component-library";
import React from "react";

const SettingsPattern: React.FC<any> = props => (
  <svg
    height="60"
    viewBox="0 0 1030 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    {...props}
  >
    <rect y="1" width="1030" height="1" fill="#E8EAEF" />
    <path d="M12 26H652V42H12V26Z" fill="#ECE8EF" />
    <path d="M901 26H981V42H901V26Z" fill="#ECE8EF" />
  </svg>
);

interface SettingsLoaderProps {
  repeat?: number;
}

export const SettingsLoader: React.FC<SettingsLoaderProps> = ({
  repeat = 10
}) => {
  return <WSLoader pattern={SettingsPattern} repeat={repeat} />;
};
