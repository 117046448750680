import { WSFormOld, WSTextInput } from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import React from "react";
import * as Yup from "yup";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import { FormActions } from "./FormActions";
import styles from "./SettingsUpdateForms.module.scss";

interface UpdateForeignTaxIdFormProps {
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: () => void;
}

export const UpdateForeignTaxIdForm: React.FC<UpdateForeignTaxIdFormProps> = ({
  onSubmit,
  defaultValues,
  onCancel
}) => {
  const userId = useUserId();
  const [updateMemberProfile] = useUpdateMemberProfile();
  const onFormSubmit = async (data: any) => {
    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        foreignTaxId: data.foreignTaxId
      }
    };
    await updateMemberProfile(request, {
      onSettled: () => {
        onSubmit(data);
      }
    });
  };

  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={{
        foreignTaxId: defaultValues.foreignTaxId
      }}
      validationSchema={Yup.object().shape({
        foreignTaxId: Yup.string().required("Required")
      })}
    >
      <WSFormOld.Field mb="XL" name="foreignTaxId" component={WSTextInput} />

      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <FormActions onCancel={onCancel} loading={isSubmitting} />
        )}
      </WSFormOld.Context>
    </WSFormOld>
  );
};
