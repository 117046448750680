import React from "react";
import { WSEmptyState, WSPage } from "@wingspanhq/fe-component-library";
import { PageHeader } from "../../../shared/components/PageHeader";
import { PAYERS_PATH } from "./RouteClients";
import { WSQueries } from "../../../query/WSQuery";
import { ClientsTable } from "../components/ClientsTable";
import { useBrowserPageTitle } from "../../../shared/hooks/useBrowserPageTitle";
import { useHistory } from "react-router-dom";
import { useQueryPayerRows } from "../../../query/search/payer/queries/useQueryPayerRows";
import { PayerPayeeStatus } from "@wingspanhq/payments/dist/interfaces";

export interface RouteArchivedClientsProps {}

export const RouteArchivedClients: React.FC<RouteArchivedClientsProps> = () => {
  useBrowserPageTitle("Archived clients");
  const history = useHistory();

  const queryPayerRows = useQueryPayerRows({
    filter: {
      "payeeOwnedData.status": PayerPayeeStatus.Inactive
    }
  });
  return (
    <WSPage>
      <PageHeader
        mb="L"
        title="Clients"
        rootPath={PAYERS_PATH}
        breadcrumbs={[
          {
            label: "Clients",
            onClick: () => {
              history.push(PAYERS_PATH);
            }
          },
          {
            label: "Archived clients"
          }
        ]}
      />
      <WSQueries queries={{ queryPayerRows }}>
        {({ queryPayerRowsData: archivedClients }) => {
          return archivedClients.length === 0 ? (
            <WSEmptyState
              hasBorder
              fullWidth
              bgColor="gray"
              type="people"
              title="No archived clients found"
              description="Once a client is archived, they will appear here."
            />
          ) : (
            <ClientsTable
              listPath={PAYERS_PATH}
              clients={archivedClients}
              isArchivedOnly
            />
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
