import { useUserId } from "../../../../query/hooks/helpers";
import { useUpdateActivity } from "../../../../query/users/mutations";
import { FormPayrollSettings } from "../../components/FormPayrollSettings";
import { OnboardingStep } from "../../types";

export const PayrollSchedule: React.FC<OnboardingStep> = ({
  title,
  onNext
}) => {
  const userId = useUserId();

  const [updateActivity] = useUpdateActivity(userId);

  const updateActivityAndContinue = async () => {
    updateActivity({
      flows: {
        onboardingPayrollSettings: {
          complete: true
        }
      }
    });
    onNext?.();
  };

  return <FormPayrollSettings onSuccess={updateActivityAndContinue} />;
};
