import cn from "classnames";
import React from "react";
import { toCamelCase } from "../../../utils/stringHelper";
import styles from "./TextField.module.scss";
import { Link } from "react-router-dom";

export interface TextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  type?: "text" | "email" | "password" | "number" | "phone" | "date";
  name: string;
  error: string;
  labelLink?: {
    to: string;
    text: string;
    testId?: string;
  };
}

export const TextField: React.FC<TextFieldProps> = ({
  label,
  className,
  error,
  labelLink,
  ...inputProps
}) => (
  <div className={styles.base}>
    <div className={styles.labelBase}>
      <div className={styles.label}>{label}</div>
      {labelLink && (
        <Link
          to={labelLink.to}
          tabIndex={1}
          className={styles.labelLink}
          data-testid={labelLink.testId}
        >
          {labelLink.text}
        </Link>
      )}
    </div>
    <input
      className={cn(styles.input, className)}
      data-testid={toCamelCase([inputProps.name, "field"])}
      {...inputProps}
    />
  </div>
);
