import {
  WSDivider,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";

type Props = { label: string } & WSElementProps;

export const Field: React.FC<Props> = ({ label, children, ...otherProps }) => (
  <WSElement {...otherProps}>
    <WSText weight="medium">{label}</WSText>
    <WSDivider my="M" />
    <WSElement>{children}</WSElement>
  </WSElement>
);
