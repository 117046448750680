import { serviceBackgroundChecks } from "./serviceBackgroundChecks";
import {
  IBackgroundCheckSettingsCreateRequest,
  IBackgroundCheckSettingsResponse
} from "@wingspanhq/background-checks/dist/lib/interfaces";

export const createBackgroundChecksSettings = async (
  request: IBackgroundCheckSettingsCreateRequest
): Promise<IBackgroundCheckSettingsResponse> => {
  const { data } = await serviceBackgroundChecks.post(`/settings`, request);
  return data;
};
