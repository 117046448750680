import { useWSAnalytics } from "../../../utils/WSAnalytics";
import { IChannelNotificationResponse } from "@wingspanhq/notifications/dist/lib/interfaces";
import pick from "lodash/pick";

export const useTrackClickNotification = (userId: string) => {
  const { track } = useWSAnalytics();

  return (notification: IChannelNotificationResponse) => {
    track.other("Notification Clicked", {
      userId,
      ...pick(
        notification,
        "notificationId",
        "channelMessageId",
        "type",
        "context",
        "priority",
        "handle",
        "subject"
      )
    });
  };
};
