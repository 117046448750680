import {
  useModalContext,
  WSButton,
  WSDateRangeSelectOptions,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useActivities } from "../../../query/users/queries";
import { useUserId } from "../../../query/hooks/helpers";
import {
  TAXES_MODAL_SCHEDULE_C_INFO,
  TaxesModalScheduleCInfo
} from "../modals/TaxesModalScheduleCInfo";
import {
  TAXES_MODAL_TAX_SUMMARY_SHEET,
  TaxesModalTaxSummarySheet
} from "../modals/TaxesModalTaxSummarySheet";
import {
  useBookkeepingStatus,
  useCatchUpTransactionsExpense,
  useCatchUpTransactionsIncome,
  useProfitAndLoss
} from "../../../query/bookkeeping/queries";
import {
  TAXES_MODAL_PENDING_REVIEW,
  TaxesModalPendingReview
} from "../modals/TaxesModalPendingReview";
import {
  getTotalCatchUpExpenseTransactions,
  getTotalCatchUpIncomeTransactions
} from "../../../query/bookkeeping/selectors";
import {
  TAXES_MODAL_REQUEST_SCHEDULE_C_PDF,
  TaxesModalRequestScheduleCPDF
} from "../modals/TaxesModalRequestScheduleCPDF";
import {
  TAXES_MODAL_REQUEST_TAX_SUMMARY_SHEET,
  TaxesModalRequestTaxSummarySheet
} from "../modals/TaxesModalRequestTaxSummarySheetConfirm";
import {
  TAXES_MODAL_ENABLE_BOOKKEEPING,
  TaxesModalEnableBookkeeping
} from "../modals/TaxesModalEnableBookkeeping";
import { isValidDateRange } from "../../utils";
import {
  useBookkeepingFilters,
  useBookkeepingFiltersYear
} from "./BookkeepingIndex";

export const TaxesDocuments: React.FC = () => {
  const { openModal } = useModalContext();
  const userId = useUserId();
  const { filters } = useBookkeepingFilters();

  const dateRange = (isValidDateRange(filters.customDateRange)
    ? filters.customDateRange
    : WSDateRangeSelectOptions.LastYear.range) as any;

  const qProfitAndLoss = useProfitAndLoss(
    {
      startDate: dateRange?.[0] || new Date(),
      endDate: dateRange?.[1] || new Date()
    },
    {
      refetchOnMount: true,
      retry: false
    }
  );

  const qCatchUpExpenseTransactions = useCatchUpTransactionsExpense(userId);
  const qCatchUpIncomeTransactions = useCatchUpTransactionsIncome(userId);
  const qActivity = useActivities(userId);
  const qBookkeepingService = useBookkeepingStatus();

  const currentYear = useBookkeepingFiltersYear();

  const totalIncomeTransactions =
    qCatchUpIncomeTransactions.data && qActivity.data
      ? getTotalCatchUpIncomeTransactions(
          qCatchUpIncomeTransactions.data,
          qActivity.data
        )
      : 0;
  const totalExpenseTransactions =
    qCatchUpExpenseTransactions.data && qActivity.data
      ? getTotalCatchUpExpenseTransactions(
          qCatchUpExpenseTransactions.data,
          qActivity.data
        )
      : 0;

  const transactionsCount = totalIncomeTransactions + totalExpenseTransactions;

  const taxAmountSavings =
    (qProfitAndLoss.data?.taxAmountSavings || 0) > 0
      ? qProfitAndLoss.data?.taxAmountSavings
      : 0;

  return (
    <>
      <WSText.Heading5>Tax-ready documents</WSText.Heading5>
      <TaxesModalRequestScheduleCPDF />
      <TaxesModalPendingReview />
      <TaxesModalRequestTaxSummarySheet />
      <TaxesModalEnableBookkeeping />

      <>
        <WSElement>
          <WSText mt="M">
            You're projected to save{" "}
            <WSText
              as="b"
              formatMoney="default"
              inline
              shimmer={qProfitAndLoss.isLoading}
            >
              {taxAmountSavings}
            </WSText>{" "}
            on your tax payments with the deductions we found. Use the documents
            below to file your annual taxes, or send to your accountant.
          </WSText>
          <WSFlexBox.CenterY mt="XL" wrap="nowrap">
            <WSButton.Link
              icon="download"
              onClick={() => {
                if (!qBookkeepingService.data?.enabled) {
                  openModal(TAXES_MODAL_ENABLE_BOOKKEEPING);
                  return;
                }

                if (transactionsCount) {
                  openModal(TAXES_MODAL_PENDING_REVIEW, transactionsCount);
                } else {
                  openModal(TAXES_MODAL_REQUEST_SCHEDULE_C_PDF);
                }
              }}
            >
              {currentYear} Schedule C PDF
            </WSButton.Link>
            <TaxesModalScheduleCInfo />
            <WSIcon
              block
              onClick={() => openModal(TAXES_MODAL_SCHEDULE_C_INFO)}
              ml="M"
              size="XS"
              color="gray400"
              name="info-circle"
            />
          </WSFlexBox.CenterY>
          <WSFlexBox.CenterY
            mt="M"
            wrap="nowrap"
            shimmer={qProfitAndLoss.isLoading}
          >
            <WSButton.Link
              icon="download"
              onClick={() => {
                if (!qBookkeepingService.data?.enabled) {
                  openModal(TAXES_MODAL_ENABLE_BOOKKEEPING);
                  return;
                }

                openModal(TAXES_MODAL_REQUEST_TAX_SUMMARY_SHEET);
              }}
            >
              {currentYear} Tax Summary Sheet
            </WSButton.Link>
            <TaxesModalTaxSummarySheet />
            <WSIcon
              block
              onClick={() => openModal(TAXES_MODAL_TAX_SUMMARY_SHEET)}
              ml="M"
              size="XS"
              color="gray400"
              name="info-circle"
            />
          </WSFlexBox.CenterY>
        </WSElement>
      </>
    </>
  );
};
