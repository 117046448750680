export const EXPIRATION_IN_DAYS = ["7", "30", "60", "90"];

export const getExpirationSelectOptions = () => {
  let options: {
    label: string;
    value: string;
  }[] = [];
  EXPIRATION_IN_DAYS.forEach(days => {
    options.push({
      value: days,
      label: `${days} days`
    });
  });

  return [
    ...options,
    ...[
      {
        value: "CUSTOM",
        label: "Custom..."
      },
      {
        value: "NO_EXPIRATION",
        label: "No expiration"
      }
    ]
  ];
};
