import { NotificationTemplate } from "../index";

export const Payroll: NotificationTemplate<never> = {
  renderTitle: props => "Run your first payroll",
  renderBody: props => {
    return "Set your payroll preferences and start your payroll experience. Task marked as completed when upon your payroll run.";
  },
  buttonAction: (props, history) => () => {
    return history.push("/member/settings/payroll");
  },
  buttonText: () => "Run payroll"
};
