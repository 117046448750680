import {
  useIsMobile,
  useModalContext,
  WSButton,
  WSContainer,
  WSElement,
  WSFlexBox,
  WSCentered,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { AccountType, IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useCreateAccount,
  useUpdateActivity
} from "../../../query/users/mutations";
import { identifyWithCommonFields, track } from "../../../utils/analytics";
import { useMemberPlaidLink } from "../../../utils/usePlaidLink";
import { AccountsCheckLoading } from "../../components/AccountsCheckList/AccountsCheckList";
import { CURRENT_YEAR } from "../../utils";
import { BOOKKEEPING_NO_ACCOUNTS_CONNECTED } from "../modals/NoAccountsConnected";

export const BookkeepingSetUpImportStart: React.FC = () => {
  const userId = useUserId();
  const history = useHistory();
  const isMobile = useIsMobile();
  const { openModal } = useModalContext();
  const [createAccountWithToken] = useCreateAccount();
  const [updateActivity] = useUpdateActivity(userId);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    track("Bookkeeping Setup Started");
  }, []);

  const plaidHandler = useMemberPlaidLink({
    async onSuccess(publicToken, meta) {
      setIsLoading(true);

      identifyWithCommonFields(userId, {
        plaidSuccesfullyIntegratedAt: new Date()
      });

      const data = (await createAccountWithToken({
        publicToken,
        canBeUsedFor: { bookkeeping: true }
      })) as IAccount[];

      const accounts = Array.isArray(data)
        ? data.filter(a =>
            [AccountType.Credit, AccountType.Depository].includes(a.type)
          )
        : [];

      if (accounts.length > 0) {
        await updateActivity({
          flows: {
            bookkeepingSetup: {
              version: 4,
              currentStep: 2,
              totalSteps: 6,
              complete: false
            }
          }
        });

        history.push(
          `/member/bookkeeping/setup/import/plaid/${accounts[0].institutionId}/sync`
        );
      } else {
        openModal(BOOKKEEPING_NO_ACCOUNTS_CONNECTED);
        setIsLoading(false);

        return {
          success: false,
          data: []
        };
      }

      setIsLoading(false);

      return {
        success: true,
        data: accounts
      };
    },
    product: ["transactions"],
    skipError: true
  });

  useBrowserPageTitle("Bookkeeping - Setup Start");

  return (
    <ProjectOnboardingLayout progress={(100 / 5) * 1} noBack>
      {isLoading ? (
        <WSContainer verticalPadding>
          <WSCentered span={{ m: "6" }}>
            <WSText.ParagraphSm color="gray500" mt="M" pt="M">
              2 of 5
            </WSText.ParagraphSm>
            <WSText.Heading4>
              Sync ongoing business transactions
            </WSText.Heading4>

            <WSPanel mt="2XL">
              <WSText.Heading5 mt="XL">
                Which accounts contain business transactions?
              </WSText.Heading5>

              <WSText mt="XL">
                We make reviewing easier, so connect all applicable accounts.
              </WSText>

              <AccountsCheckLoading />
            </WSPanel>
          </WSCentered>
        </WSContainer>
      ) : (
        <WSContainer verticalPadding>
          <WSCentered span={{ m: "6" }}>
            <WSText.ParagraphSm color="gray500" mt="M" pt="M">
              1 of 5
            </WSText.ParagraphSm>
            <WSText.Heading4>
              Import historic business transactions
            </WSText.Heading4>

            <WSPanel mt="2XL">
              <WSText.Heading5 mt="XL">
                Quickly get on track for {CURRENT_YEAR}
              </WSText.Heading5>

              <WSText mt="XL">
                Wingspan uses Plaid to import your transactions.
              </WSText>

              <WSText mt="XL">
                Link your account and we’ll automatically find your past
                business transactions.
              </WSText>

              <WSFlexBox.Center mt="2XL">
                <WSButton.Primary
                  data-testId="submitSetupTransactionSync"
                  onClick={() => plaidHandler.open()}
                >
                  Link account
                </WSButton.Primary>
              </WSFlexBox.Center>
            </WSPanel>

            <>
              <WSText.ParagraphSm align="center" mt="XL" color="gray600">
                {isMobile ? (
                  "Already started? Use a computer or tablet to upload via CSV."
                ) : (
                  <>
                    Already started?{" "}
                    <WSElement
                      as="a"
                      data-testId="goToCSV"
                      onClick={async () => {
                        await updateActivity({
                          flows: {
                            bookkeepingSetup: {
                              version: 4,
                              currentStep: 2,
                              totalSteps: 6,
                              complete: false
                            }
                          }
                        });

                        history.push("/member/bookkeeping/setup/csv");
                      }}
                    >
                      Upload transactions via CSV.
                    </WSElement>
                  </>
                )}
              </WSText.ParagraphSm>
              <WSText.ParagraphSm align="center" mt="2XL" color="gray600">
                <WSElement
                  as="a"
                  data-testId="skipSetupTransactionSync"
                  onClick={async () => {
                    await updateActivity({
                      flows: {
                        bookkeepingSetup: {
                          version: 4,
                          currentStep: 5,
                          totalSteps: 6,
                          complete: false
                        }
                      }
                    });

                    history.push(
                      "/member/bookkeeping/setup/no-sync-tax-profile"
                    );
                  }}
                >
                  Skip import
                </WSElement>
              </WSText.ParagraphSm>
            </>
          </WSCentered>
        </WSContainer>
      )}
    </ProjectOnboardingLayout>
  );
};
