import React from "react";

import {
  WSContainer,
  WSFlexBox,
  WSIcon,
  WSText,
  WSTable,
  WSTableColumn,
  useIsMobile,
  WSElement
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";

import { useGetPaymentHistory } from "../../query/subscriptions/queries";

import { WSQueries } from "../../query/WSQuery";

export const columns: Array<WSTableColumn> = [
  {
    config: {
      header: {
        text: "Name"
      },
      gridTemplateSizeMax: "1fr",
      hideOnScreens: ["XS", "S"]
    },
    renderFunction: row => <WSText>Membership Payment - {row.data.name}</WSText>
  },
  {
    config: {
      header: {
        text: "Date"
      },
      justify: "center",
      hideOnScreens: ["XS", "S"]
    },
    renderFunction: row => (
      <WSText formatDate={"monthDayYear"}>{row.data.paidAtDate}</WSText>
    )
  },
  {
    config: {
      header: {
        text: "Amount"
      },
      justify: "center",
      hideOnScreens: ["XS", "S"]
    },
    renderFunction: row => <WSText formatMoney>{row.data.amountPaid}</WSText>
  },
  {
    config: {
      header: {
        text: "Name"
      },
      gridTemplateSizeMax: "1fr",
      hideOnScreens: ["M", "L", "XL"]
    },
    renderFunction: row => (
      <>
        <WSText singleLine>Membership Payment</WSText>
        <WSText.ParagraphSm singleLine>{row.data.name}</WSText.ParagraphSm>
      </>
    )
  },
  {
    config: {
      header: {
        text: "Amount/Date"
      },
      hideOnScreens: ["M", "L", "XL"],
      justify: "end"
    },
    renderFunction: row => (
      <WSElement>
        <WSText singleLine formatMoney>
          {row.data.amountPaid}
        </WSText>
        <WSText.ParagraphSm singleLine formatDate="monthDayYear">
          {row.data.paidAtDate}
        </WSText.ParagraphSm>
      </WSElement>
    )
  }
];

export const MembershipBillingHistory: React.FC = () => {
  const history = useHistory();
  const qHistory = useGetPaymentHistory();

  const isMobile = useIsMobile();

  return (
    <WSContainer verticalPadding>
      <WSFlexBox.CenterY
        onClick={() => history.push("/member/settings/membership")}
      >
        <WSIcon block name="chevron-left" color="gray500" size="XS" mr="XS" />
        <WSText.ParagraphSm weight="medium" color="gray500">
          Membership
        </WSText.ParagraphSm>
      </WSFlexBox.CenterY>
      <WSText.Heading4 mt="M">Billing history</WSText.Heading4>
      <WSQueries queries={{ historyData: qHistory }}>
        {({ historyData }) => {
          const tableData = historyData.data.map(history => {
            return {
              data: {
                source: history,
                name: history.invoiceId,
                paidAtDate: history.paidAt,
                amountPaid: history.amountPaid,
                invoicePdf: history.invoicePdf
              },
              id: history.invoiceId
            };
          });

          return (
            <WSTable
              mt="XL"
              showHeader={!isMobile}
              columns={columns}
              tableData={tableData}
              bottomMessage={
                !tableData.length && "You have no past membership payments"
              }
            />
          );
        }}
      </WSQueries>
    </WSContainer>
  );
};
