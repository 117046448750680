import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  WSButton,
  WSContainer,
  WSFlexBox,
  WSCentered,
  WSImage,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";

import image from "../../../../assets/images/benefits-landing-preview-desktop-celebration.png";
import imageGif from "../../../../assets/images/jim-and-pam.gif";
import { ProjectOnboardingLayout } from "../../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { useUpdateIntegrationQuickbooks } from "../../../../query/integrations/mutations";
import { IntegrationsQuickbooksSyncStatus } from "@wingspanhq/integrations/dist/lib/interfaces";

export const QuickbooksSetupStep8: React.FC<RouteComponentProps<any>> = ({
  history
}) => {
  const [
    updateIntegration,
    mUpdateIntegration
  ] = useUpdateIntegrationQuickbooks();

  useEffect(() => {
    updateIntegration(
      {
        syncStatus: IntegrationsQuickbooksSyncStatus.Synced
      },
      {
        throwOnError: true
      }
    );
  }, []);

  return (
    <ProjectOnboardingLayout progress={(100 / 8) * 8}>
      <WSContainer verticalPadding>
        <WSCentered span={{ m: "8" }}>
          <WSText.ParagraphSm color="gray500" mt="M" pt="M">
            8 of 8
          </WSText.ParagraphSm>
          <WSText.Heading4>You're done!</WSText.Heading4>

          <WSPanel mt="2XL">
            <WSText.Heading4 align="center">
              We succesfully set up your integration with Quickbooks.
            </WSText.Heading4>
            <WSText align="center" mt="XL">
              Now your invoice and payables activity should sync to your
              Quickbooks automatically.
            </WSText>
            <WSImage
              mt="3XL"
              placeholder={<WSImage src={image} placeholder={<></>} mb="3XL" />}
              src={imageGif}
              height={330}
            />
            <WSFlexBox.Center mt="2XL">
              <WSButton.Primary
                loading={mUpdateIntegration.isLoading}
                name="goToIntegrations"
                onClick={async () => {
                  history.push("/member/settings/integrations");
                }}
              >
                Go to Integrations
              </WSButton.Primary>
            </WSFlexBox.Center>
          </WSPanel>
        </WSCentered>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
