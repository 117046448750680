import forge from "node-forge";

// Lithic API public key in PEM format
const publicKeyPem = `-----BEGIN RSA PUBLIC KEY-----
MIICCgKCAgEAuBxXhZjTv6OGsFNJC5SoBYT3KArN4m98ywzXYe5lihY0F08K8Mla
+YzseES+f3ZwPUK2RJnhQlotOE4qAtB7/BCNsZB2FPnFUl8hQceR16pnhkeEOJYL
vgtN97Kfldil4ge8/k2qhg3j1brhXX9qUM+1jXGFmFdNM/A+4ox5OJrjxEUbNffz
diztw73qWZeb8rA0sb8MDhAfNXty0nE6ggx33uHScyQO2NjYYBs2OXMsdN5LvP9/
7ggsmq7vr2V72G72COD4jQ4cBxEnhboJm13i/Ow2ophX6wMlGXAJ4PiuZ/vw2Qm5
L9ptr/VIpqfz2jeoUa3ljQZ2NO+MxQkSf9h62xu+O+Gd1D83n7GxwfUYXNwQYv/+
y7HyYWi65JEOCIfjE+mppWh8UsL+6LSqHXC8Mraq9cpI6vNKQcKWCdvkgPIOUTPj
eCQeLjJ5aHB7h2rvdWC2a3qyJjqigUKYF+oRVOzav6iS5o/Id3XQ6rOpDq5LK4fY
vc6bBdXM6ky3enyEhAEzjDbxY9bbRvApTGbVZzfvyKbG/UP0KHFJca8djJvbNvv9
yd/eGsDlnCd4ROwG8a0Moo6ALM+oer3XvLYV1JLnSd7GO7AgI7An7lFdXFYy1WlT
OlHHRT3KG977GhHjCsV+g1vH5JmcJGLPBi+xbvVhUIj5YRPZfsn4uJkCAwEAAQ==
-----END RSA PUBLIC KEY-----`;

function randomInt(low: number, high: number) {
  // Generate random integer between low and high, inclusive
  return Math.floor(Math.random() * (high - low + 1) + low);
}

export const encryptCardPin = async (pin: string) => {
  // Function to convert PEM to ArrayBuffer
  const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);

  const pinBlock: { nonce: number; pin: string } = {
    nonce: randomInt(1e8, 1e12),
    pin
  };

  const ciphertext = publicKey.encrypt(JSON.stringify(pinBlock), "RSA-OAEP", {
    md: forge.md.sha1.create(),
    mgf1: {
      md: forge.md.sha1.create()
    }
  });

  // Use this for the "pin" field value when creating or updating cards
  return forge.util.encode64(ciphertext);
};
