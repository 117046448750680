import {
  WSButton,
  WSButtons,
  WSElement,
  WSRadioInputGroup,
  WSText
} from "@wingspanhq/fe-component-library";
import { VerificationStatus } from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { SetupParams } from ".";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import { EditLayout } from "../../components/EditLayout";
import { FormEditRecipient } from "../../components/FormEditRecipient";
import { RecipientVerificationFailed } from "../../components/RecipientVerificationFailed";

export const RouteTaxpayerInformation: React.FC<SetupRouteComponentProps<
  SetupParams
>> = ({ onNext, onBack, params }) => {
  const [provideInfo, setProvideInfo] = useState(params?.provideInfo);

  if (!params?.taxForm)
    return <Redirect to="/member/1099-filing/add-recipient" />;

  return (
    <EditLayout title="Add recipient" onBack={onBack}>
      <WSText.Heading4>W-9 information</WSText.Heading4>

      <WSText weight="medium" mt="XL">
        Do you want to add W-9 information for the recipient now?
      </WSText>

      <WSRadioInputGroup
        mt="XL"
        value={provideInfo}
        name="provideInfo"
        options={[
          {
            value: "yes",
            label: "Yes"
          },
          {
            value: "no",
            label: "No, request the information directly from recipient.",
            description:
              "Wingspan will reach out to the email provided to confirm W-9 information directly."
          }
        ]}
        onChange={event => {
          setProvideInfo((event.target as any).value);
        }}
      />

      <WSElement hidden={provideInfo !== "yes"} mt="2XL">
        {(params.taxForm.tinVerification?.ssn?.status ===
          VerificationStatus.Failed ||
          params.taxForm.tinVerification?.ein?.status ===
            VerificationStatus.Failed) && (
          <RecipientVerificationFailed taxForm={params.taxForm} mb="2XL" />
        )}

        <FormEditRecipient
          taxForm={params.taxForm}
          onSuccess={() => {
            onNext({ provideInfo });
          }}
          onBack={onBack}
          skipEventHistory
        />
      </WSElement>

      {provideInfo !== "yes" && (
        <WSButtons mt="XL" forceFullWidth>
          <WSButton.Primary
            onClick={() => {
              if (provideInfo) {
                onNext({ provideInfo });
              } else {
                onNext({ provideInfo });
              }
            }}
          >
            Continue
          </WSButton.Primary>

          <WSButton.Secondary onClick={onBack}>Cancel</WSButton.Secondary>
        </WSButtons>
      )}
    </EditLayout>
  );
};
