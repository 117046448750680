import * as Yup from "yup";

const regExp = new RegExp(/^(11|22|33)\d{8}$/);

export const validatorCanadaQuebecEnterpriseNumber = Yup.string().test(
  "canadaQuebecEnterpriseNumber",
  "Not valid",
  value =>
    value && value.length > 0 ? value.length === 10 && regExp.test(value) : true
);
