import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { FormManuallyAddAccount } from "../../../../shared/components/FormManuallyAddAccount";
import { LayoutFullscreen } from "../../../../shared/components/LayoutFullscreen";

export const RouteAddInstantManually: React.FC<RouteComponentProps> = () => {
  const history = useHistory();

  return (
    <LayoutFullscreen
      headTitle="Add payout method"
      title="Enter your account info"
      onBack={() => {
        history.push("/member/settings/payment-methods/add-instant");
      }}
      narrow
    >
      <FormManuallyAddAccount
        rtpRequired
        setInstantPayoutDestination
        onSuccess={() => {
          history.push("/member/settings/payment-methods");
        }}
      />
    </LayoutFullscreen>
  );
};
