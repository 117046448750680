import { WSTableCell, toWSMoneyString } from "@wingspanhq/fe-component-library";
import { useBulkPayableBatchSummary } from "../../query/bulkPayable/queries";

export function BulkBatchImportTotal({ batchId }: { batchId: string }) {
  const qBulkPayableBatchItems = useBulkPayableBatchSummary(batchId);
  return (
    <WSTableCell
      // shimmer={qBulkPayableBatchItems.isLoading}
      text={toWSMoneyString(qBulkPayableBatchItems.data?.netImportAmount)}
    />
  );
}
