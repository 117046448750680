import {
  useModalContext,
  WSButton,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IAccount,
  ICreditCard,
  IDefaultPaymentMethod
} from "@wingspanhq/users/dist/lib/interfaces";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  addNotification,
  NotificationStatus
} from "../../../components/Notification/Notification";
import { PaymentMethodType } from "../../../components/WSAccountsListV2/types";
import { WSAccountsListV2 } from "../../../components/WSAccountsListV2/WSAccountsListV2.component";
import { ChooseAccountForPaymentMethodsModal } from "../../../Invoices/components/ChooseAccountForPaymentMethodsModal/ChooseAccountForPaymentMethodsModal";
import { useUserId } from "../../../query/hooks/helpers";
import { useQueryPaymentsCards } from "../../../query/paymentCards/queries/useQueryPaymentsCards";
import { useAccounts, useClientQuery } from "../../../query/users/queries";
import { getPossiblePaymentsAccounts } from "../../../query/users/selectors";
import { WSQueries } from "../../../query/WSQuery";
import {
  CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL,
  ChooseAddPaymentMethodFlowModal
} from "./ChooseAddPaymentMethodFlowModal";
import { DELETE_PAYMENT_METHOD_MODAL } from "./DeletePaymentMethodModal";
import {
  EDIT_PAYMENT_METHOD_MODAL,
  EditPaymentMethodModal
} from "./EditPaymentMethodModal";
import { SavedPaymentMethodCreditCardModal } from "./SavedPaymentMethodCreditCardModal";
import { Wallet } from "./Wallet";

type Props = WSElementProps;

export const PaymentMethodsList: React.FC<Props> = ({ ...elementProps }) => {
  const { openModal } = useModalContext();
  const userId = useUserId();
  const accountsQuery = useAccounts();
  const clientQuery = useClientQuery(userId);
  const history = useHistory();
  const queryPaymentCards = useQueryPaymentsCards();

  useEffect(() => {
    const queries = queryString.parse(window.location.search);
    if (queries.addPm) {
      openModal(CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL, {
        shouldSetDefaultPaymentMethod: true,
        onFinish: () => {
          addNotification({
            text: (
              <WSText>
                Default payment method is added successfully.
                <WSButton.Link
                  onClick={() =>
                    history.push(
                      (queries.nextUrl as string) || "/member/invoices"
                    )
                  }
                >
                  Continue
                </WSButton.Link>{" "}
              </WSText>
            ),
            status: NotificationStatus.success,
            timeout: 3000
          });
        }
      });
    }
  }, [history, openModal]);

  return (
    <WSElement {...elementProps}>
      <WSText.Heading5 mb="XS">Payment method</WSText.Heading5>
      <WSText color="gray500" mb="XL">
        Saved methods, including default for autopay
      </WSText>

      <WSQueries queries={{ accountsQuery, clientQuery, queryPaymentCards }}>
        {({
          accountsQuery: { data: accounts },
          clientQuery: { data: client },
          queryPaymentCards: { data: paymentCards }
        }) => {
          const paymentAccounts = getPossiblePaymentsAccounts(accounts);
          const cards = client.profile.savedPaymentMethods || [];

          const defaultPaymentMethod = client.profile.defaultPaymentMethod;
          const shouldSetDefaultPaymentMethod =
            !defaultPaymentMethod?.accountId &&
            !defaultPaymentMethod?.paymentMethodId;

          return (
            <>
              <WSElement mb="2XL">
                {(paymentAccounts.length > 0 ||
                  cards.length > 0 ||
                  paymentCards.length > 0) && (
                  <WSAccountsListV2
                    data-testid="paymentAccountsList"
                    accounts={paymentAccounts}
                    creditCards={cards}
                    paymentCards={paymentCards}
                    defaultPaymentMethod={defaultPaymentMethod}
                    onEditAccount={(
                      pm: IAccount | ICreditCard,
                      pmType?: PaymentMethodType
                    ) => {
                      openModal(EDIT_PAYMENT_METHOD_MODAL, {
                        paymentMethod: pm,
                        defaultPaymentMethod,
                        paymentMethodType: pmType
                      });
                    }}
                    onDeleteAccount={async (
                      account: IAccount,
                      defaultPaymentMethod?: IDefaultPaymentMethod
                    ) => {
                      openModal(DELETE_PAYMENT_METHOD_MODAL, {
                        paymentMethod: account,
                        defaultPaymentMethod,
                        paymentMethodType: PaymentMethodType.BANK_ACCOUNT
                      });
                    }}
                  />
                )}
                <Wallet />
              </WSElement>

              <WSButton
                kind="Link"
                icon="plus-circle"
                onClick={() =>
                  openModal(CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL, {
                    shouldSetDefaultPaymentMethod: shouldSetDefaultPaymentMethod
                  })
                }
                name="addPaymentMethod"
              >
                Add payment method
              </WSButton>
            </>
          );
        }}
      </WSQueries>
      <EditPaymentMethodModal />
      <ChooseAddPaymentMethodFlowModal />
      <ChooseAccountForPaymentMethodsModal />
      <SavedPaymentMethodCreditCardModal />
    </WSElement>
  );
};
