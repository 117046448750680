import {
  WSFlexBox,
  WSIcon,
  WSText,
  WSTextKindsType,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import { IPayableBatchImportSummary } from "@wingspanhq/payments/dist/interfaces";
import { formatNumber } from "accounting";
import camelCase from "lodash/camelCase";
import { ListValueType, RowData } from "../../../../components/LabelValueList";
import { isNil } from "../../../../utils";
import { ILabelInfoValueOptions } from "../../types";
import {
  DEDUCTIONS_CREATED,
  LINE_ITEMS_COUNT_MODAL,
  TOTAL_ITEMS_IMPORTED_MODAL
} from "./InfoModals";

export enum ISummaryStatCategory {
  Payables = "Payables",
  Deductions = "Deductions",
  Total = "Total"
}

export enum ISummaryRowType {
  Default = "Default",
  SubTotal = "SubTotal",
  Total = "Total"
}

export interface PayableBatchImportSummaryRow extends RowData {
  category: ISummaryStatCategory;
}

export function getPayableBatchSummaryItems(
  bulkBatchSummary?: IPayableBatchImportSummary,
  options?: ILabelInfoValueOptions
): PayableBatchImportSummaryRow[] {
  if (isNil(bulkBatchSummary)) {
    return [];
  }
  return [
    {
      name: "payablesCount",
      modalName: LINE_ITEMS_COUNT_MODAL,
      label: "Line items count",
      value: bulkBatchSummary.payablesCount,
      type: ListValueType.Number,
      category: ISummaryStatCategory.Payables
    },
    {
      name: "totalPayablesAmount",
      label: "Total payables",
      value: bulkBatchSummary.totalPayablesAmount,
      category: ISummaryStatCategory.Payables,
      rowType: ISummaryRowType.SubTotal
    },
    {
      name: "deductionsCreated",
      modalName: DEDUCTIONS_CREATED,
      label: "Deductions created",
      value: bulkBatchSummary.deductionsCreatedCount,
      type: ListValueType.Number,
      category: ISummaryStatCategory.Deductions
    },
    {
      name: "deductionsTotalAmount",
      label: "Deduction total",
      value: bulkBatchSummary.totalDeductionsAmount,
      category: ISummaryStatCategory.Deductions,
      rowType: ISummaryRowType.SubTotal
    },
    {
      name: "importTotal",
      label: "Import total",
      value: bulkBatchSummary.netImportAmount,
      category: ISummaryStatCategory.Total,
      rowType: ISummaryRowType.Total
    },
    {
      name: "totalItemsImported",
      modalName: TOTAL_ITEMS_IMPORTED_MODAL,
      label: "Total items imported",
      value: bulkBatchSummary.totalItemsImported,
      type: ListValueType.Number,
      category: ISummaryStatCategory.Total
    }
  ].map(row => {
    const valueTextKind: WSTextKindsType = [
      ISummaryRowType.Total,
      ISummaryRowType.SubTotal
    ].includes(row?.rowType || ISummaryRowType.Default)
      ? "ParagraphSm"
      : "ParagraphSm";
    let labelTextKind: WSTextKindsType = "ParagraphSm";
    if (row.rowType === ISummaryRowType.Total) {
      labelTextKind = "Paragraph";
    } else if (row.rowType === ISummaryRowType.SubTotal) {
      labelTextKind = "ParagraphSm";
    }
    return {
      id: row.name,
      name: row.name,
      type: ListValueType.Text,
      category: row.category,
      value: (
        <WSText
          data-testid={camelCase(row.name)}
          kind={valueTextKind}
          weight={row.rowType ? "bold" : undefined}
          mr="XS"
          color={options?.valueColor || "gray500"}
        >
          {row.type === ListValueType.Number
            ? formatNumber(row.value)
            : toWSMoneyString(row.value)}
        </WSText>
      ),
      label: row.modalName ? (
        <WSFlexBox.CenterY>
          <WSText
            kind={labelTextKind}
            weight={row.rowType ? "bold" : undefined}
            mr="XS"
            color={options?.labelColor || "gray500"}
          >
            {row.label}
          </WSText>
          <WSIcon
            block
            name="info-circle"
            size="XS"
            onClick={() => options?.onClickInfo?.(row.modalName)}
          />
        </WSFlexBox.CenterY>
      ) : (
        <WSText
          kind={labelTextKind}
          weight={row.rowType ? "bold" : undefined}
          mr="XS"
          color={options?.labelColor || "gray500"}
        >
          {row.label}
        </WSText>
      )
    };
  });
}
