import { WSText } from "@wingspanhq/fe-component-library";
import React from "react";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { CertifiedSecureDigitalPayments } from "../../components/CertifiedSecureDigitalPayments/CertifiedSecureDigitalPayments";
import { getClientInvoiceMemberName } from "../../query/payments/selectors";
import { ClientInvoiceWrapper } from "../components/ClientInvoiceWrapper/ClientInvoiceWrapper";
import { ClientPaymentsWrapper } from "../components/ClientPaymentsWrapper/ClientPaymentsWrapper";
import { PaymentTypeSelect } from "../components/PaymentTypeSelect/PaymentTypeSelect";
import {
  getAcceptedPaymentMethods,
  getPathToPaymentMethod,
  getPaymentStepText,
  isClientInvoiceCancelled,
  isClientInvoicePaid
} from "../utils";

type Props = RouteComponentProps<{ invoiceId: string }>;

export const ClientPaymentsInvoicePaymentType: React.FC<Props> = ({
  match
}) => {
  const history = useHistory();
  const invoiceId = match.params.invoiceId;

  return (
    <ClientInvoiceWrapper invoiceId={invoiceId}>
      {clientInvoice => {
        const paymentMethods = getAcceptedPaymentMethods(clientInvoice);

        return isClientInvoicePaid(clientInvoice) ? (
          <Redirect to={`/payment/${invoiceId}/success`} />
        ) : isClientInvoiceCancelled(clientInvoice) ? (
          <Redirect to={`/payment/${invoiceId}`} />
        ) : paymentMethods.length === 1 ? (
          <Redirect to={getPathToPaymentMethod(invoiceId, paymentMethods[0])} />
        ) : (
          <ClientPaymentsWrapper
            companyName={clientInvoice.memberCompany}
            clientEmail={clientInvoice.memberClient.emailTo}
            memberName={getClientInvoiceMemberName(clientInvoice)}
            companyLogoUrl={clientInvoice.memberLogoUrl}
            onBack={() => {
              history.push(`/payment/${invoiceId}`);
            }}
          >
            <WSText.ParagraphSm>
              {getPaymentStepText(clientInvoice, "paymentType")}
            </WSText.ParagraphSm>
            <WSText.Heading4 mb="2XL">Payment method</WSText.Heading4>

            <PaymentTypeSelect
              availablePaymentMethods={getAcceptedPaymentMethods(clientInvoice)}
              options={{ creditFeeHandling: clientInvoice.creditFeeHandling }}
              onSelect={paymentMethod => {
                history.push(getPathToPaymentMethod(invoiceId, paymentMethod));
              }}
            />

            <CertifiedSecureDigitalPayments />
          </ClientPaymentsWrapper>
        );
      }}
    </ClientInvoiceWrapper>
  );
};
