import { WSAvatarIconProps } from "@wingspanhq/fe-component-library";
import {
  ClientWorkFlowStatus,
  InvoiceStatus,
  IPayableSchema
} from "@wingspanhq/payments/dist/interfaces";

export const selectorPayableIcon = (
  payable: IPayableSchema
): Omit<WSAvatarIconProps, "type"> =>
  payable.status === InvoiceStatus.Draft
    ? {
        icon: "edit",
        color: "gray500",
        colorBackground: "gray50"
      }
    : payable.client.workflowStatus === ClientWorkFlowStatus.Approved &&
      (payable.status === InvoiceStatus.Open ||
        payable.status === InvoiceStatus.Overdue)
    ? {
        icon: "calendar",
        color: "gray500",
        colorBackground: "gray50"
      }
    : payable.status === InvoiceStatus.Open
    ? {
        icon: "envelope",
        color: "blue500",
        colorBackground: "blue50"
      }
    : payable.status === InvoiceStatus.Overdue
    ? {
        icon: "envelope",
        color: "blue500",
        colorBackground: "blue50",
        badge: true,
        badgeProps: {
          theme: "red"
        }
      }
    : payable.status === InvoiceStatus.PaymentInTransit
    ? {
        icon: "check",
        color: "gray500",
        colorBackground: "gray50"
      }
    : payable.status === InvoiceStatus.Paid
    ? {
        icon: "check",
        color: "gray500",
        colorBackground: "gray50"
      }
    : payable.status === InvoiceStatus.Pending
    ? {
        icon: "time",
        color: "gray500",
        colorBackground: "gray50"
      }
    : {
        icon: "edit",
        color: "gray500",
        colorBackground: "gray50"
      };
