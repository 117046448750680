import { QueryConfig } from "react-query";
import { useWSQuery } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { getBackgroundChecksPackages } from "../../../services/backgroundChecks";
import { IBackgroundChecksPackageResponse } from "@wingspanhq/background-checks/dist/lib/interfaces";

export const QUERY_BACKGROUND_CHECKS_PACKAGES =
  "QUERY_BACKGROUND_CHECKS_PACKAGES";

export const useBackgroundChecksPackagesQuery = (
  queryConfig?: QueryConfig<IBackgroundChecksPackageResponse[], WSServiceError>
) => {
  return useWSQuery(
    QUERY_BACKGROUND_CHECKS_PACKAGES,
    getBackgroundChecksPackages,
    queryConfig
  );
};
