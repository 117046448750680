import {
  SUBSCRIPTION_SELECT_OPTIONS,
  SubscriptionGrantOptionTitle
} from "../constants/subscriptionOptions";
import {
  SubscriptionPackage,
  SubscriptionPackageTier
} from "@wingspanhq/users/dist/lib/interfaces/subscription";

export const selectorTitleBySubscription = (
  packageItem?: SubscriptionPackage,
  packageTier?: SubscriptionPackageTier
) => {
  return (
    (SUBSCRIPTION_SELECT_OPTIONS.find(
      option =>
        option.value.package === packageItem &&
        option.value.packageTier === packageTier
    )?.title as SubscriptionGrantOptionTitle) ||
    SUBSCRIPTION_SELECT_OPTIONS[0].title
  );
};
