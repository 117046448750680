import React, { useCallback, useMemo, useState } from "react";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import ConfirmElectronic1099Consent from "../../../../components/ConfirmElectronic1099Consent";
import useSnackbar from "../../../../hooks/useSnackbar";
import { useUserId } from "../../../../query/hooks/helpers";
import { useActivities } from "../../../../query/users/queries";
import { getCurrentFilingYearStr } from "../../../../utils/getCurrentFilingYear";
import download1099NecPdf from "../utils/download1099NecPdf";

const CONSENT_SLUG = "electronic-delivery-consent";

const use1099NECDownload = (ownerId: string, isConsentRequired: boolean) => {
  const history = useHistory();
  const match = useRouteMatch();
  const userId = useUserId();
  const activityQuery = useActivities(userId);
  const snackbar = useSnackbar();
  const [formFilingYear, setFormFilingYear] = useState<string | null>(null);

  const currentFilingYearStr = useMemo(() => getCurrentFilingYearStr(), []);

  const handle1099NECDownload = useCallback(
    (index: number, filingYear?: string) => {
      const isElectronic1099Consent =
        activityQuery.data?.events.electronic1099Consent;

      if (isConsentRequired && !isElectronic1099Consent) {
        if (filingYear) setFormFilingYear(filingYear);
        history.push(`${match.url}/${CONSENT_SLUG}`);
        return;
      }

      download1099NecPdf(
        ownerId,
        filingYear || currentFilingYearStr,
        index,
        snackbar
      );
    },
    [
      activityQuery.data?.events.electronic1099Consent,
      currentFilingYearStr,
      history,
      isConsentRequired,
      match.url,
      ownerId,
      snackbar
    ]
  );

  const handleConsentNext = useCallback(() => {
    history.push(match.url);
    download1099NecPdf(
      ownerId,
      formFilingYear || currentFilingYearStr,
      0,
      snackbar
    );
    if (formFilingYear) setFormFilingYear(null);
  }, [
    currentFilingYearStr,
    formFilingYear,
    history,
    match.url,
    ownerId,
    snackbar
  ]);

  const ConditionalConsentDialog: React.FC = () => (
    <Route path={`${match.path}/${CONSENT_SLUG}`} exact>
      <ConfirmElectronic1099Consent
        onBack={() => history.push(match.url)}
        onNext={handleConsentNext}
        modal
      />
    </Route>
  );

  return {
    handle1099NECDownload,
    ConditionalConsentDialog
  };
};

export default use1099NECDownload;
