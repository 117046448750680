import { useUserProfileData } from "./utils/useUserProfileData";
import { isUserIdsIncluded } from "./utils/isUserIdsIncluded";
import { SHOW_PAYOUT_NAV_IDS } from "./helpers";

export const useShowPayoutNav = () => {
  const userData = useUserProfileData();

  const showPayoutNav = isUserIdsIncluded(userData, SHOW_PAYOUT_NAV_IDS);

  return showPayoutNav;
};
