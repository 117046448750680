import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import { useHistory, useRouteMatch } from "react-router-dom";

import { SetupStartNewCorrectionFlowProps } from "./index";
import { usePayerTaxForm } from "../../../../../query/taxForm/queries/usePayerTaxForm";
import { EditLayout } from "../../../components/EditLayout";
import { PreventLeave } from "../../../../../components/PreventLeave/PreventLeave";
import { WSQueries } from "../../../../../query/WSQuery";
import { CURRENT_YEAR } from "../../../constants/currentYear";
import { SelectCorrectionTypeForm } from "../../../../TaxDocuments/components/SelectCorrectionTypeForm";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";
import { TaxFormViewer } from "../../../../TaxDocuments/types";
import { openInNewTab } from "../../../../../shared/utils/openInNewTab";

interface SelectCorrectionTypeFormData {
  corrections: string[];
}

export const RouteSelectCorrectionType: React.FC<SetupStartNewCorrectionFlowProps> = ({
  onNext
}) => {
  const history = useHistory();
  const match = useRouteMatch<{ taxFormId: string }>();
  const { taxFormId } = match.params;
  const queryTaxForm = usePayerTaxForm(taxFormId);

  const onFormSubmit = (data: SelectCorrectionTypeFormData) => {
    onNext(data.corrections);
  };

  const onCancel = () => {
    history.push(`/member/1099-filing/details/${taxFormId}`);
  };

  return (
    <EditLayout title="Start new correction">
      <WSQueries queries={{ queryTaxForm }}>
        {({ queryTaxFormData: taxForm }) => (
          <>
            <WSText.Heading2 mb="L">
              {selectorTaxFormRecipientName(taxForm)} {CURRENT_YEAR} Form
              1099-NEC
            </WSText.Heading2>
            <SelectCorrectionTypeForm
              taxFormViewer={TaxFormViewer.Payer}
              onCancel={onCancel}
              onContinue={onFormSubmit}
            />

            <WSButton.Link
              rightIcon="chevron-right"
              onClick={() =>
                openInNewTab(
                  "https://help.wingspan.app/en/articles/8892926-requesting-corrections-for-unsupported-1099-nec-error-types"
                )
              }
            >
              There is another issue with my Form 1099
            </WSButton.Link>
          </>
        )}
      </WSQueries>
    </EditLayout>
  );
};
