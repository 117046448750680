import {
  IPayeeTaxFormResponse,
  ITaxFormCorrectionRequest
} from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { FormRequestCorrectionData } from "./types";
import {
  PayeeCorrectionReason,
  TinType
} from "@wingspanhq/payments/dist/interfaces/taxForm";
import { TaxFormViewer } from "../../types";
import { isEmpty } from "lodash";

export const createRequestCorrectionPayload = (
  correctionsFormData: FormRequestCorrectionData,
  taxForm: IPayeeTaxFormResponse,
  taxFormViewer: TaxFormViewer = TaxFormViewer.Payee,
  isForce = false
) => {
  let data: ITaxFormCorrectionRequest["data"] = {};
  let payeeOwnedData: ITaxFormCorrectionRequest["payeeOwnedData"] = {};
  let payerOwnedData: ITaxFormCorrectionRequest["payerOwnedData"] = {};

  // amount correction request object
  if (correctionsFormData?.necTotalAmount !== taxForm.data?.totalAmount) {
    data = {
      totalAmount: correctionsFormData?.necTotalAmount
    };
    if (taxFormViewer === TaxFormViewer.Payee) {
      // recipient request correction
      payeeOwnedData = {
        comment: correctionsFormData?.amountCorrectionHelpText
      };
    } else if (taxFormViewer === TaxFormViewer.Payer) {
      // payer editing correction request OR payer initiating a correction
      payerOwnedData = {
        comment: correctionsFormData?.amountCorrectionHelpText
      };
    }
  }

  // tin/name change
  let w9TinInfo = {};
  if (correctionsFormData.identificationNumberType === TinType.Business) {
    w9TinInfo = {
      ...(correctionsFormData.ein
        ? {
            ein: correctionsFormData.ein,
            tinType: TinType.Business
          }
        : {}),
      ...(correctionsFormData.businessName
        ? { legalBusinessName: correctionsFormData.businessName }
        : {}),
      ...(correctionsFormData.disregardedEntityName
        ? { disregardedEntityName: correctionsFormData.disregardedEntityName }
        : {}),
      ...(correctionsFormData.taxClassification
        ? { companyStructure: correctionsFormData.taxClassification }
        : {})
    };
  } else {
    w9TinInfo = {
      ...(correctionsFormData.ssn
        ? {
            ssn: correctionsFormData?.ssn,
            tinType: TinType.Individual
          }
        : {}),
      ...(correctionsFormData.firstName
        ? { firstName: correctionsFormData?.firstName }
        : {}),
      ...(correctionsFormData.lastName
        ? { lastName: correctionsFormData?.lastName }
        : {}),
      ...(correctionsFormData.taxClassification
        ? { companyStructure: correctionsFormData.taxClassification }
        : {})
    };
  }

  data = {
    ...data,
    w9Info: w9TinInfo
  };

  // only provide payeeOwnedData if the payee is the one requesting the correction
  if (taxFormViewer === TaxFormViewer.Payee) {
    payeeOwnedData = {
      ...payeeOwnedData,
      ...(correctionsFormData?.reasonForChange
        ? { reason: correctionsFormData?.reasonForChange }
        : {})
    };
    if (correctionsFormData?.reasonForChange === PayeeCorrectionReason.Other) {
      payeeOwnedData = {
        ...payeeOwnedData,
        reasonComment: correctionsFormData?.otherReason
      };
    }
  }

  let w9Address = {};
  // address change
  if (correctionsFormData?.formW9Address) {
    w9Address = {
      addressLine1: correctionsFormData?.formW9Address?.addressLine1,
      addressLine2: correctionsFormData?.formW9Address?.addressLine2,
      city: correctionsFormData?.formW9Address?.city,
      state: correctionsFormData?.formW9Address?.state,
      postalCode: correctionsFormData?.formW9Address?.zipCode,
      country: correctionsFormData?.formW9Address?.country
    };
    data = {
      ...data,
      w9Info: {
        ...data.w9Info,
        ...w9Address
      }
    };
  }

  return {
    data,
    ...(!isEmpty(payeeOwnedData) && { payeeOwnedData }),
    ...(!isEmpty(payerOwnedData) && { payerOwnedData }),
    forceCorrection: isForce
  };
};
