import {
  WSButton,
  WSAvatar,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSImage,
  WSInputMaskOld,
  WSMessageBox,
  WSText,
  toWSDateString,
  useWSModal
} from "@wingspanhq/fe-component-library";
import {
  AccountVerificationType,
  IAccount
} from "@wingspanhq/users/dist/lib/interfaces";
import * as Yup from "yup";
import imageTransactions from "../../assets/images/microDepositTransactions.png";
import { useWSMutation } from "../../query/helpers";
import { QUERY_USERS_ACCOUNTS } from "../../query/users/keys";
import { usersService } from "../../services/users";
import { openIntercom } from "../../shared/utils/intercom";
import { WSServiceError } from "../../utils/serviceHelper";
import { WSErrorMessage } from "../WSErrorMessage/WSErrorMessage";
import styles from "./WSAccountsListV2.module.scss";

type Props = {
  account: IAccount;
  onClose: () => void;
  onSuccess: () => void;
};

type FormData = { amount1: string | null; amount2: string | null };

export const ModalVerifyAccount: React.FC<Props> = ({
  account,
  onClose,
  onSuccess
}) => {
  const [verify, verifyMeta] = useWSMutation<void, WSServiceError, FormData>(
    async data => {
      const amount1 = Number("0." + data.amount1);
      const amount2 = Number("0." + data.amount2);

      await usersService.account.verify(account.accountId, {
        type: AccountVerificationType.MicroDeposit,
        verifications: {
          microDepositAmount1: amount1,
          microDepositAmount2: amount2
        }
      });
    },
    {
      onSuccess: () => {
        onSuccess();
        onClose();
      },
      dependencies: [QUERY_USERS_ACCOUNTS]
    }
  );

  return (
    <WSFormOld<FormData>
      defaultValues={{ amount1: null, amount2: null }}
      validationSchema={Yup.object().shape({
        amount1: Yup.string()
          .required("Should be greater than 0")
          .not(["00"], "Should be greater than 0"),
        amount2: Yup.string()
          .required("Should be greater than 0")
          .not(["00"], "Should be greater than 0")
      })}
      onSubmit={data => {
        verify(data);
      }}
    >
      <WSText mb="XL">
        Wingspan made <b>two deposits</b> in USD into your account ending in •••
        {account.numbers.account.slice(-4)} on{" "}
        {toWSDateString(new Date(), "monthDate")}. Enter the amounts to verify
        your account.
      </WSText>

      <WSMessageBox.Info mb="XL" size="Large">
        <WSFlexBox.CenterY mb="XL">
          <WSAvatar.Icon
            mr="M"
            icon="bank"
            color="white"
            colorBackground="gray500"
          />

          <WSText>•••{account.numbers.account.slice(-4)} Bank Statement</WSText>
        </WSFlexBox.CenterY>

        <WSImage mb="2XL" src={imageTransactions} placeholder="" />

        <WSText mb="XL">Enter amounts:</WSText>

        <WSGrid gutter="M">
          <WSGrid.Item>
            <WSFormOld.Field
              name="amount1"
              component={WSInputMaskOld}
              componentProps={{
                className: styles.whiteInput,
                prefix: "$0.",
                mask: "99",
                placeholder: "00",
                slotChar: "0"
              }}
            />
          </WSGrid.Item>

          <WSGrid.Item>
            <WSFormOld.Field
              name="amount2"
              component={WSInputMaskOld}
              componentProps={{
                className: styles.whiteInput,
                prefix: "$0.",
                mask: "99",
                placeholder: "00",
                slotChar: "0"
              }}
            />
          </WSGrid.Item>
        </WSGrid>
      </WSMessageBox.Info>

      <WSErrorMessage
        mb="XL"
        contextKey="BankAccountVerification"
        error={verifyMeta.error}
      />

      <WSFormOld.Values names={["amount1", "amount2"]}>
        {values => {
          const isAmount1Valid = !!values.amount1 && values.amount1 !== "00";
          const isAmount2Valid = !!values.amount2 && values.amount2 !== "00";
          const isValid = isAmount1Valid && isAmount2Valid;

          return (
            <WSButton
              mb="XL"
              fullWidth
              disabled={!isValid}
              loading={verifyMeta.isLoading}
            >
              Verify bank account
            </WSButton>
          );
        }}
      </WSFormOld.Values>

      <WSText color="gray500" mb="XL">
        Payments to invoices made from this account will be in pending status
        until the verification process is complete. Once the account is
        verified, invoices paid with this account will be processed.
      </WSText>

      <WSText.ParagraphSm>Didn’t receive the deposits?</WSText.ParagraphSm>
      <WSButton.Link size="S" onClick={openIntercom} type="button">
        Contact support
      </WSButton.Link>
    </WSFormOld>
  );
};

export const useModalVerifyAccount = () =>
  useWSModal(ModalVerifyAccount, { title: "Verify Account" });
