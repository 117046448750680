import {
  IInvoice,
  InvoicePayoutDestinationType
} from "@wingspanhq/payments/dist/interfaces";
import { queryCache } from "react-query";
import { QUERY_INVOICE } from "../../query/payments/keys";
import { QUERY_PAYOUT } from "../../query/payouts/keys";
import { components } from "../../services/api/banking/types";
import { getPayout } from "../../services/api/payments/payouts";
import { paymentsService } from "../../services/payments";
import {
  IPayoutListResponse,
  IPayoutTransfer
} from "../../services/api/payments/payouts/types";

class InvoicePayoutService {
  async getInvoicePayouts(invoiceId: string) {
    let invoice = queryCache.getQueryData<IInvoice>([QUERY_INVOICE, invoiceId]);

    if (!invoice) {
      invoice = await paymentsService.invoice.get(invoiceId);
    }

    const payoutIds = (invoice.payoutDestinations || [])
      .filter(
        destination =>
          destination.destinationType === InvoicePayoutDestinationType.Payout &&
          destination.destinationId
      )
      .map(destination => destination.destinationId as string);

    const payouts = await Promise.all(
      payoutIds.map(payoutId => getPayout(payoutId))
    );

    payouts.forEach(payout => {
      queryCache.setQueryData([QUERY_PAYOUT, payout.payoutId], payout);
    });

    return payouts;
  }

  getAllExternalTransfers(payouts: IPayoutListResponse) {
    return payouts.reduce<IPayoutTransfer[]>((acc, payout) => {
      return [...acc, ...(payout?.externalFundsTransfers || [])];
    }, []);
  }

  getAllBankingTransfers(payouts: IPayoutListResponse) {
    return payouts
      .filter(payout => !!payout.bankingFundsTransfer)
      .map(payout => payout.bankingFundsTransfer) as IPayoutTransfer[];
  }

  getExternalTransferDescription(payoutTransfer: IPayoutTransfer) {
    const destination = payoutTransfer.destination;

    if (!destination) {
      return;
    }

    return [
      destination.institutionName,
      destination.name,
      destination.mask ? `(${destination.mask})` : undefined
    ]
      .filter(Boolean)
      .join(" ");
  }

  getBankingTransferDescription(
    internalAccount?: components["schemas"]["InternalAccount"]
  ) {
    const mask = internalAccount?.numbers?.account?.slice(-4);
    return ["Wingspan Wallet", mask ? `(${mask})` : undefined]
      .filter(Boolean)
      .join(" ");
  }
}

export const invoicePayoutService = new InvoicePayoutService();
