import { useMemo } from "react";
import { useQueryVerificationMissingData } from "../../../query/onboarding/queries/useQueryVerificationMissingFields";

export const useMissingData = (verificationLevel: string) => {
  const queryMissingDataTax = useQueryVerificationMissingData("Tax");
  const queryMissingDataBanking = useQueryVerificationMissingData("Banking");

  return useMemo(
    () =>
      verificationLevel === "Tax"
        ? queryMissingDataTax.data
        : queryMissingDataBanking.data,
    [queryMissingDataBanking.data, queryMissingDataTax.data, verificationLevel]
  );
};
