import {
  WSControl,
  WSElement,
  WSForm,
  WSInfoBox,
  WSList,
  WSMarginProps,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";

export interface FormW8BenConsentData {
  w8BenConsent: boolean;
  shareW8Ben: boolean;
}

interface FormW8BenConsentProps extends WSMarginProps {
  payerNames: string[];
}

export const getValidationSchemaFormW8Ben = (payerNames: string[]) => {
  return Yup.object().shape({
    w8BenConsent: Yup.bool()
      .required()
      .oneOf([true], "You must certify the information is accurate"),
    shareW8Ben:
      payerNames.length > 0
        ? Yup.bool()
            .required()
            .oneOf(
              [true],
              "You must authorize Wingspan to share the tax information with your payer(s)"
            )
        : Yup.boolean()
  });
};

export const FormW8BenConsent: React.FC<FormW8BenConsentProps> = ({
  payerNames
}) => {
  return (
    <WSPanel>
      <WSList gap="2XL">
        <WSElement>
          <WSText.Paragraph weight="medium" color="gray600" mb="M">
            Certify your taxpayer information (W-8BEN) is accurate
          </WSText.Paragraph>
          <WSText.ParagraphSm weight="book" color="gray600">
            By using Wingspan’s services you agree that the information provided
            on the Form W-8 BEN is accurate and that you certify the provided
            information as follows:
          </WSText.ParagraphSm>
        </WSElement>

        <WSInfoBox>
          <p>
            By submitting this electronic Form W-8BEN, you certify, under
            penalties of perjury, that:
          </p>
          <ol>
            <li>
              I am the individual that is the beneficial owner (or am authorized
              to sign for the individual that is the beneficial owner) of all
              the income or proceeds to which this form relates or am using this
              form to document myself for chapter 4 purposes;
            </li>
            <li>
              The person identified as the beneficial owner in this electronic
              form is not a U.S. person;
            </li>
            <li>
              This form relates to:
              <ul style={{ listStyleType: "lower-alpha" }}>
                <li>
                  income not effectively connected with the conduct of a trade
                  or business in the United States;
                </li>
                <li>
                  income effectively connected with the conduct of a trade or
                  business in the United States but is not subject to tax under
                  an applicable income tax treaty;
                </li>
                <li>
                  the partner's share of a partnership's effectively connected
                  taxable income; or
                </li>
                <li>
                  the partner's amount realized from the transfer of a
                  partnership interest subject to withholding under section
                  1446(f);
                </li>
              </ul>
            </li>

            <li>
              The person identified as the beneficial owner in this electronic
              form is a resident of the treaty country specified (if any) within
              the meaning of the income tax treaty between the United States and
              that country; and
            </li>
            <li>
              For broker transactions or barter exchanges, the beneficial owner
              is an exempt foreign person as defined in the instructions.
            </li>
          </ol>
        </WSInfoBox>

        <WSList gap="M">
          <WSForm.Field
            name="w8BenConsent"
            component={WSControl}
            componentProps={{
              type: "checkbox",
              label:
                "By providing the information on this form, I hereby certify, to the best of my knowledge, that the information provided is complete and correct.",
              size: "S"
            }}
          />

          {payerNames.length > 0 && (
            // TODO: implement edit payers list
            <WSForm.Field
              name="shareW8Ben"
              component={WSControl}
              componentProps={{
                type: "checkbox",
                label: `I authorize Wingspan to share the tax information I've provided with my payer(s) on the Wingspan platform (${payerNames.join(
                  ", "
                )}). I understand this information will be used for tax reporting purposes in accordance with applicable laws and regulations.`,
                size: "S"
              }}
            />
          )}
        </WSList>
      </WSList>
    </WSPanel>
  );
};
