import { ICardResponse, ICreateCardRequest } from "./types";
import { cardsService } from "./cardsService";

export const createCard = async (
  request: ICreateCardRequest
): Promise<ICardResponse> => {
  const { data } = await cardsService.post("", request);

  return data;
};
