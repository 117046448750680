import { toWSDate, WSGrid } from "@wingspanhq/fe-component-library";
import { IRequirementDefinitionResponse } from "@wingspanhq/payments/dist/interfaces";
import { useMemo } from "react";
import {
  FieldView,
  FieldViewProps
} from "../../../../shared/components/FieldView/FieldView";
import { ISignedDocumentTemplateResponse } from "@wingspanhq/signed-documents/dist/lib/interfaces";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { ISharedFileRequestSettingsResponse } from "@wingspanhq/shared-files/dist/lib/interfaces";
import { mimeTypesToLabel } from "../../../Requirements/modules/SharedFile/createRequirementDefinition/utils";
import { IBackgroundCheckSettingsResponse } from "@wingspanhq/background-checks/dist/lib/interfaces";

type Props = {
  requirementDefinition: IRequirementDefinitionResponse;
  singedDocumentTemplate?: ISignedDocumentTemplateResponse;
  sharedFileSettings?: ISharedFileRequestSettingsResponse;
  backgroundChecksSettings?: IBackgroundCheckSettingsResponse;
};

export const RequirementInformation: React.FC<Props> = ({
  requirementDefinition,
  singedDocumentTemplate,
  sharedFileSettings,
  backgroundChecksSettings
}) => {
  const fields = useMemo(() => {
    // @ts-ignore
    const fieldsByType: Record<RequirementType, FieldViewProps[]> = {
      [RequirementType.Signature]: [
        {
          label: "Required signatures",
          value:
            singedDocumentTemplate?.roles?.length === 2
              ? "Both contractors and client signatures required"
              : "Contractor signature required"
        }
      ],
      [RequirementType.SharedFile]: [
        {
          label: "File types",
          value: mimeTypesToLabel(sharedFileSettings?.mimeTypes || [])
        },
        {
          label: "Instructions",
          value: sharedFileSettings?.instructions
        }
      ],
      [RequirementType.BackgroundCheck]: [
        {
          label: "Package",
          value: backgroundChecksSettings?.package?.title
        },
        {
          label: "Name",
          value: backgroundChecksSettings?.title
        },
        {
          label: "Instructions",
          value: backgroundChecksSettings?.instructions
        }
      ]
    };

    const result: FieldViewProps[] = [
      {
        label: "Creation date",
        value: toWSDate(requirementDefinition.createdAt).formattedValue
      },
      {
        label: "Expiration",
        value: "Does not expire"
      },
      {
        label: "Description",
        value: requirementDefinition.description
      },
      ...fieldsByType[requirementDefinition.requirementType]
    ].filter(i => i.value);

    return result;
  }, [
    singedDocumentTemplate,
    sharedFileSettings,
    requirementDefinition,
    backgroundChecksSettings
  ]);

  return (
    <WSGrid gutter="2XL">
      {fields.map(({ label, value }) => (
        <WSGrid.Item key={label} span={{ xs: "12", s: "6", l: "6" }}>
          <FieldView label={label} value={value} />
        </WSGrid.Item>
      ))}
    </WSGrid>
  );
};
