import {
  toWSMoneyString,
  WSAvatar,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { IInvoiceCollaborator } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { useCollaboratorsQuery } from "../../../query/payments/queries";
import { getCollaboratorName } from "../../../query/payments/selectors";
import { WSQueries } from "../../../query/WSQuery";

type Props = WSElementProps & {
  invoiceCollaborators: IInvoiceCollaborator[];
};

export const InvoiceDetailsCollaborators: React.FC<Props> = ({
  invoiceCollaborators,
  ...elementProps
}) => {
  const collaboratorsQuery = useCollaboratorsQuery();
  const history = useHistory();

  return (
    <WSElement {...elementProps}>
      <WSQueries queries={{ collaboratorsQuery }}>
        {({ collaboratorsQuery: { data: collaborators } }) => (
          <>
            {invoiceCollaborators.map(invoiceCollaborator => {
              const collaborator = collaborators.find(
                c => c.collaboratorId === invoiceCollaborator.memberClientId
              );

              if (collaborator) {
                return (
                  <WSFlexBox.CenterY
                    mb="M"
                    wrap="nowrap"
                    onClick={
                      invoiceCollaborator.invoiceId
                        ? () => {
                            history.push(
                              `/member/invoices/payables/all/${invoiceCollaborator.invoiceId}`
                            );
                          }
                        : undefined
                    }
                    key={collaborator.collaboratorId}
                  >
                    <WSAvatar.Text
                      text={getCollaboratorName(collaborator)}
                      mr="M"
                    />
                    <WSElement style={{ flex: 1 }}>
                      <WSText mb="XS">
                        {getCollaboratorName(collaborator)}
                      </WSText>
                      <WSText.ParagraphXs color="gray600">
                        {invoiceCollaborator.description}
                      </WSText.ParagraphXs>
                    </WSElement>
                    <WSText>
                      {toWSMoneyString(invoiceCollaborator.amount)}
                    </WSText>
                  </WSFlexBox.CenterY>
                );
              } else {
                return null;
              }
            })}
          </>
        )}
      </WSQueries>
    </WSElement>
  );
};
