import {
  useModalContext,
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSFormOld,
  WSInputNumberOld,
  WSModal,
  WSSelectOld,
  WSText
} from "@wingspanhq/fe-component-library";
import { Intervals } from "@wingspanhq/payments/dist/interfaces";
import { formatMoney } from "accounting";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import styles from "./LateFeeSection.module.scss";

export type LateFeeValues = {
  amount: number;
  type: "percent" | "fixed";
  every: number;
  interval: Intervals;
  overriden: boolean;
  compoundedAmount?: number;
};

export type LateFeeSectionValues = {
  includesLateFee: boolean;
  lateFee: LateFeeValues;
};

type Props = {} & WSElementProps;

export const LateFeeSection: React.FC<Props> = ({ ...elementProps }) => {
  const { register, setValue } = useFormContext();
  const { openModal } = useModalContext();

  useEffect(() => {
    register("lateFee.overriden");
  }, [register]);

  return (
    <WSElement {...elementProps}>
      <WSFormOld.Field
        name="includesLateFee"
        component={WSCheckboxToggle}
        componentProps={{
          label: <WSText.Heading5>Add late fees when overdue</WSText.Heading5>
        }}
      />

      <WSFormOld.Value name="includesLateFee">
        {includesLateFee => {
          if (includesLateFee) {
            return (
              <>
                <WSFormOld.Value name="lateFee.overriden">
                  {overriden =>
                    overriden ? (
                      <>
                        <WSText mt="XL">
                          Current late fee is{" "}
                          <WSFormOld.Value name="lateFee.compoundedAmount">
                            {compoundedAmount => formatMoney(compoundedAmount)}
                          </WSFormOld.Value>
                          .{" "}
                          <WSButton.Link
                            type="button"
                            onClick={() => {
                              openModal(OVERRIDE_LATE_FEE_MODAL);
                            }}
                          >
                            Change
                          </WSButton.Link>
                        </WSText>
                      </>
                    ) : (
                      <>
                        <WSFormOld.Label mt="XL">
                          Late fee charge
                        </WSFormOld.Label>
                        <WSFlexBox wrap="nowrap" mb="2XL">
                          <WSFormOld.Value name="lateFee.type">
                            {type => {
                              if (type === "percent") {
                                return (
                                  <WSFormOld.Field
                                    key="percent"
                                    className={styles.smallField}
                                    name="lateFee.amount"
                                    component={WSInputNumberOld}
                                    componentProps={{
                                      suffix: "%",
                                      min: 0,
                                      max: 100,
                                      minFractionDigits: 1,
                                      maxFractionDigits: 2
                                    }}
                                    mr="M"
                                  />
                                );
                              } else if (type === "fixed") {
                                return (
                                  <WSFormOld.Field
                                    key="fixed"
                                    className={styles.smallField}
                                    name="lateFee.amount"
                                    component={WSInputNumberOld}
                                    componentProps={{
                                      mode: "currency",
                                      currency: "USD",
                                      min: 0
                                    }}
                                    mr="M"
                                  />
                                );
                              } else {
                                return null;
                              }
                            }}
                          </WSFormOld.Value>
                          <WSFormOld.Field
                            className={styles.typeField}
                            name="lateFee.type"
                            component={WSSelectOld}
                            componentProps={{
                              options: [
                                {
                                  value: "percent",
                                  label: "% of invoice"
                                },
                                {
                                  value: "fixed",
                                  label: "fixed amount"
                                }
                              ]
                            }}
                          />
                        </WSFlexBox>
                        <WSFormOld.Label>Every</WSFormOld.Label>
                        <WSFlexBox wrap="nowrap" alignItems="center">
                          <WSFormOld.Field
                            className={styles.smallField}
                            name="lateFee.every"
                            component={WSInputNumberOld}
                            componentProps={{
                              min: 0
                            }}
                            mr="M"
                          />
                          <WSFormOld.Field
                            className={styles.intervalField}
                            name="lateFee.interval"
                            component={WSSelectOld}
                            componentProps={{
                              options: [
                                {
                                  value: Intervals.Week,
                                  label: "weeks"
                                },
                                {
                                  value: Intervals.Month,
                                  label: "months"
                                }
                              ]
                            }}
                            mr="M"
                          />
                          <WSText>past due</WSText>
                        </WSFlexBox>

                        <WSFormOld.Value name="lateFee.compoundedAmount">
                          {compoundedAmount =>
                            Number(compoundedAmount) > 0 ? (
                              <WSText>
                                Current late fee is{" "}
                                {formatMoney(compoundedAmount)}.{" "}
                                <WSButton.Link
                                  type="button"
                                  onClick={() => {
                                    openModal(OVERRIDE_LATE_FEE_MODAL);
                                  }}
                                >
                                  Override
                                </WSButton.Link>
                              </WSText>
                            ) : null
                          }
                        </WSFormOld.Value>
                      </>
                    )
                  }
                </WSFormOld.Value>
              </>
            );
          } else {
            return false;
          }
        }}
      </WSFormOld.Value>
      <OverrideModal
        onSubmit={amount => {
          setValue("lateFee.overriden", true);
          setValue("lateFee.compoundedAmount", amount);
        }}
      />
    </WSElement>
  );
};

type OverrideModalProps = {
  onSubmit: (amount: number) => void;
};

const OVERRIDE_LATE_FEE_MODAL = "overrideLateFee";

const OverrideModal: React.FC<OverrideModalProps> = ({ onSubmit }) => {
  const { closeModal } = useModalContext();
  const [value, setValue] = useState<number>(0);

  return (
    <WSModal name={OVERRIDE_LATE_FEE_MODAL} size="S" title="Late fee override">
      <WSText mb="M">Adjust late fee based on negotiation with client.</WSText>

      <WSInputNumberOld
        name="amount"
        value={value}
        onChange={event => {
          setValue(event.value || 0);
        }}
        mode="currency"
        currency="USD"
        min={0}
        mb="XL"
      />
      <WSButtons format="modal">
        <WSButton
          type="button"
          onClick={() => {
            onSubmit(value);
            closeModal(OVERRIDE_LATE_FEE_MODAL);
          }}
        >
          Update late fee
        </WSButton>
        <WSButton.Secondary
          type="button"
          onClick={() => {
            closeModal(OVERRIDE_LATE_FEE_MODAL);
          }}
        >
          Cancel
        </WSButton.Secondary>
      </WSButtons>
    </WSModal>
  );
};
