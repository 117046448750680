import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSMenuTrigger,
  WSPill,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayerTaxFormResponse,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces";
import { TaxFormViewer } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { useHistory } from "react-router";
import { useTaxFormDownloadDocument } from "../../../../../query/taxForm/mutations/useTaxFormDownloadDocument";
import { downloadFileFromBlob } from "../../../../../utils/files";
import { selectorTaxFormIsEmailResendAvailable } from "../../../selectors/selectorTaxFormIsEmailResendAvailable";
import { selectorTaxFormIsPDFDownloadAvailableForPayer } from "../../../selectors/selectorTaxFormIsPDFDownloadAvailableForPayer";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";
import { useModalResendElectronicCopy } from "../RouteDashboard/useModalResendElectronicCopy";
import { useModalResendMailCopy } from "../RouteDashboard/useModalResendMailCopy";
import { useRecipientCorrectionExistsModal } from "./ModalRecipientCorrectionExists";

type Props = {
  taxForm: IPayerTaxFormResponse;
  pill?: string;
} & WSElementProps;

export const Header: React.FC<Props> = ({ taxForm, pill, ...elementProps }) => {
  const history = useHistory();
  const [downloadDocument, downloadDocumentMeta] = useTaxFormDownloadDocument();
  const modalResendElectronicCopy = useModalResendElectronicCopy();
  const modalResendMailCopy = useModalResendMailCopy();
  const recipientCorrectionExistsModal = useRecipientCorrectionExistsModal();
  const name = selectorTaxFormRecipientName(taxForm);
  const externalId = taxForm.memberExternalId;
  const group = !!taxForm.groupSize;

  const onBack = () => {
    history.push("/member/1099-filing/dashboard");
  };

  return (
    <WSElement {...elementProps}>
      <WSScreen.Mobile>
        <WSButton.Link mb="M" icon="chevron-left" onClick={onBack}>
          Back
        </WSButton.Link>
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        <WSFlexBox.CenterY justify="space-between" mb="XL">
          <WSFlexBox.CenterY wrap="nowrap">
            <WSText.ParagraphSm
              weight="medium"
              color="gray400"
              onClick={onBack}
            >
              1099-NEC Filing Dashboard
            </WSText.ParagraphSm>
            <WSText.ParagraphSm weight="medium" mx="M" color="gray400">
              –
            </WSText.ParagraphSm>
            <WSText.ParagraphSm weight="medium">{name}</WSText.ParagraphSm>
            {pill && <WSPill ml="M" text={pill} theme="blue" />}
          </WSFlexBox.CenterY>
          <WSFlexBox.CenterY justify="flex-end">
            {selectorTaxFormIsPDFDownloadAvailableForPayer(taxForm) && (
              <WSMenuTrigger
                isSameWidthAsTrigger
                name="downloadButton"
                renderTrigger={({ onToggle, ref }) => (
                  <WSElement ref={ref} onClick={onToggle}>
                    <WSButton.Secondary rightIcon="chevron-down" size="S">
                      Download Form 1099-NEC
                    </WSButton.Secondary>
                  </WSElement>
                )}
                items={[
                  {
                    label: "Download payer copy",
                    onClick: async () => {
                      await downloadDocument(
                        {
                          taxFormId: taxForm.taxFormId,
                          submissionId: taxForm.currentSubmissionId!,
                          viewer: TaxFormViewer.payer
                        },
                        {
                          throwOnError: true,
                          onSuccess(pdf) {
                            downloadFileFromBlob(
                              pdf,
                              `1099-NEC_${taxForm.year || ""}-payer-copy.pdf`
                            );
                          }
                        }
                      );
                    }
                  },
                  {
                    label: "Download payee copy",
                    onClick: async () => {
                      await downloadDocument(
                        {
                          taxFormId: taxForm.taxFormId,
                          submissionId: taxForm.currentSubmissionId!,
                          viewer: TaxFormViewer.payee
                        },
                        {
                          throwOnError: true,
                          onSuccess(pdf) {
                            downloadFileFromBlob(
                              pdf,
                              `1099-NEC_${taxForm.year || ""}-payee-copy.pdf`
                            );
                          }
                        }
                      );
                    }
                  }
                ]}
              />
            )}
            {selectorTaxFormIsEmailResendAvailable(taxForm) && (
              <WSMenuTrigger
                name="actionsButton"
                renderTrigger={({ onToggle }) => (
                  <WSIcon
                    onClick={onToggle}
                    block
                    name="dots-v"
                    color="gray500"
                    ml="S"
                    size="S"
                  />
                )}
                items={[
                  {
                    label: taxForm?.pendingCorrection
                      ? taxForm.status === TaxFormStatus.AcceptedByIrs
                        ? "Review correction request"
                        : "View correction"
                      : "Start new correction",
                    onClick: () => {
                      if (taxForm?.pendingCorrection) {
                        if (taxForm.status === TaxFormStatus.AcceptedByIrs) {
                          history.push(
                            `/member/1099-filing/details/${taxForm.taxFormId}/review-correction-request`
                          );
                        } else {
                          history.push(
                            `/member/1099-filing/details/${taxForm.taxFormId}/view-correction`
                          );
                        }
                      } else {
                        history.push(
                          `/member/1099-filing/details/${taxForm.taxFormId}/start-correction`
                        );
                      }
                    }
                  },

                  {
                    label: "Re-send electronic copy",
                    onClick: () => {
                      modalResendElectronicCopy.open({
                        taxForm
                      });
                    }
                  },
                  {
                    label: "Re-mail tax forms",
                    onClick: () => {
                      modalResendMailCopy.open({
                        taxForm
                      });
                    }
                  }
                ]}
              />
            )}
          </WSFlexBox.CenterY>
        </WSFlexBox.CenterY>
      </WSScreen.TabletAndDesktop>
      <WSFlexBox.CenterY>
        <WSText.Heading1>{name}</WSText.Heading1>
        {externalId ? (
          <WSText ml="2XL" color="gray400">
            {externalId}
          </WSText>
        ) : null}
        {group ? (
          <WSFlexBox.CenterY ml="2XL">
            <WSIcon block name="group" color="gray400" />
            <WSText mx="M" color="gray400">
              Grouped forms
            </WSText>
            {/*<WSIcon block name="info-circle" color="gray400" onClick={() => {}} />*/}
          </WSFlexBox.CenterY>
        ) : null}
      </WSFlexBox.CenterY>
    </WSElement>
  );
};
