import { ICreditCard, ISubscription } from "@wingspanhq/users";
import {
  IGrantedSubscriberResponse,
  INextgenSubscription,
  INextgenSubscriptionPlan,
  IPaymentReceiptWithCharge,
  ISubscriptionGrantResponse
} from "@wingspanhq/users/dist/lib/interfaces";
import { QueryConfig } from "react-query";
import { IGCSSubscriptionPackages, usersService } from "../../services/users";
import { WSServiceError } from "../../utils/serviceHelper";
import { useWSQuery } from "../helpers";
import {
  GRANTED_SUBSCRIBER_LIST,
  QUERY_NEXT_GEN_SUBSCRIPTION_LIST,
  QUERY_NEXT_GEN_SUBSCRIPTION_PLAN_LIST,
  QUERY_SUBSCRIPTION_BILLING_HISTORY,
  QUERY_SUBSCRIPTION_GRANT_LIST,
  QUERY_SUBSCRIPTION_PACKAGES,
  QUERY_SUBSCRIPTION_PAYMENT_METHOD,
  QUERY_SUBSCRIPTION_V3
} from "./keys";

export const useGetPaymentHistory = (
  queryConfig?: QueryConfig<IPaymentReceiptWithCharge[], unknown>
) => {
  return useWSQuery(
    QUERY_SUBSCRIPTION_BILLING_HISTORY,
    usersService.subscription.payment.history.list,
    {
      retry: false,
      ...queryConfig
    }
  );
};

export const useGetPaymentMethod = (
  queryConfig?: QueryConfig<ICreditCard, WSServiceError>
) => {
  return useWSQuery(
    QUERY_SUBSCRIPTION_PAYMENT_METHOD,
    usersService.subscription.payment.method.get,
    {
      refetchOnMount: false,
      retry: false,
      ...queryConfig
    }
  );
};

export const useSubscriptionV3Query = (
  memberId: string,
  queryConfig?: QueryConfig<ISubscription, WSServiceError>
) => {
  return useWSQuery(
    QUERY_SUBSCRIPTION_V3,
    () =>
      usersService.subscriptionV3.get(memberId, {
        headers: { "x-ws-fe-flow": "query" }
      }),
    {
      retry: false,
      ...queryConfig
    }
  );
};

export const useSubscriptionPackages = (
  queryConfig?: QueryConfig<IGCSSubscriptionPackages, unknown>
) => {
  return useWSQuery(
    QUERY_SUBSCRIPTION_PACKAGES,
    usersService.subscriptionV3.packages.list,
    {
      refetchOnMount: false,
      retry: false,
      ...queryConfig
    }
  );
};

export const useSubscriptionGrantListQuery = (
  queryConfig?: QueryConfig<ISubscriptionGrantResponse[], unknown>
) => {
  return useWSQuery(
    QUERY_SUBSCRIPTION_GRANT_LIST,
    usersService.subscriptionGrant.list,
    {
      retry: false,
      ...queryConfig
    }
  );
};

export const useGrantedSubscriberListQuery = (
  queryConfig?: QueryConfig<IGrantedSubscriberResponse[], unknown>
) => {
  return useWSQuery(
    GRANTED_SUBSCRIBER_LIST,
    usersService.grantedSubscriber.list,
    {
      retry: false,
      ...queryConfig
    }
  );
};

// Next gen subscriptions

export const useNextGenSubscriptions = (
  memberId: string,
  queryConfig?: QueryConfig<Array<INextgenSubscription>, unknown>
) => {
  return useWSQuery(
    QUERY_NEXT_GEN_SUBSCRIPTION_LIST,
    () => usersService.nextGenSubscription.list(memberId),
    {
      refetchOnMount: false,
      ...queryConfig
    }
  );
};

export const useNextGenSubscriptionPlans = (
  memberId: string,
  queryConfig?: QueryConfig<Array<INextgenSubscriptionPlan>, unknown>
) => {
  return useWSQuery(
    QUERY_NEXT_GEN_SUBSCRIPTION_PLAN_LIST,
    () => usersService.nextGenSubscription.plans.list(memberId),
    {
      refetchOnMount: false,
      ...queryConfig
    }
  );
};
