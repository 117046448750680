import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation, WSMutationConfig } from "../../helpers";
import {
  QUERY_PAYMENTS_TAXFORM_LIST_PAYER,
  QUERY_PAYMENTS_TAXFORM_PAYER
} from "../keys";
import { paymentsService } from "../../../services/payments";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";

export const useTaxFormResync = (
  config?: WSMutationConfig<IPayerTaxFormResponse, WSServiceError, string>
) =>
  useWSMutation(taxFormId => paymentsService.taxForm.resync(taxFormId), {
    dependencies: [
      QUERY_PAYMENTS_TAXFORM_PAYER,
      QUERY_PAYMENTS_TAXFORM_LIST_PAYER
    ],
    ...config
  });
