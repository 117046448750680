import classNames from "classnames";
import React from "react";
import { WSActions, WSActionsProps } from "../WSActions/WSActions";
import { WSElement, WSElementProps } from "../WSElement/WSElement.component";
import styles from "./WSCard.module.scss";
import { WSCardHeader, WSCardHeaderProps } from "./WSCardHeader";

export type WSCardProps = {
  header?: WSCardHeaderProps;
  actions?: WSActionsProps;
  divider?: boolean;
} & WSElementProps;

export const WSCard: React.FC<WSCardProps> = ({
  header,
  actions,
  divider,
  children,
  className,
  ...elementProps
}) => (
  <WSElement
    className={classNames(styles.card, className, divider && styles.divider)}
    {...elementProps}
  >
    {header && <WSCardHeader className={styles.header} {...header} />}
    {children && <WSElement className={styles.content}>{children}</WSElement>}
    {actions && <WSActions className={styles.actions} {...actions} />}
  </WSElement>
);
