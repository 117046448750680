import React, { useEffect } from "react";
import { useWSModal, WSList, WSLoader } from "@wingspanhq/fe-component-library";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useActivateCard } from "../../../../query/cards/mutations/useActivateCard";

type ActivateVirtualCardModalProps = {
  onClose: () => void;
  onSuccess: () => void;
  cardId: string;
};

const ActivateVirtualCardModal: React.FC<ActivateVirtualCardModalProps> = ({
  cardId,
  onClose,
  onSuccess
}) => {
  const [activateCard, activateCardMeta] = useActivateCard();

  useEffect(() => {
    activateCard({ cardId }, { onSuccess });
  }, [cardId]);

  return (
    <WSList gap="L">
      {activateCardMeta.isError ? (
        <WSErrorMessage error={activateCardMeta.error} contextKey="BankCard" />
      ) : (
        <WSLoader.Spinner />
      )}
    </WSList>
  );
};

export const useActivateVirtualCardModal = () =>
  useWSModal(ActivateVirtualCardModal, {
    title: "Activate virtual card",
    size: "S"
  });
