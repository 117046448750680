import {
  ITaxEstimate,
  ITaxEstimateBase
} from "@wingspanhq/bookkeeping/dist/lib/interfaces/taxEstimate";
import { ITransaction } from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";
import { openInNewTab } from "../utils/openInNewTab";
import {
  IBalanceResponse,
  ICashbackEligibilityResponse,
  ICatchUpExpenseTransactions,
  ICatchUpIncomeTransactions,
  IInternalAccount,
  IPendingWithholding,
  IPendingWithholdingCreate,
  IPendingWithholdingUpdate,
  IPostedCashbackBalanceResponse,
  IProfitAndLossResponse,
  IRedeemCashbackBalanceRequest,
  IRedeemCashbackBalanceResponse,
  WSCategory
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { IWithholdingTransactionResponse } from "@wingspanhq/bookkeeping/dist/lib/interfaces/withholdingTransaction";
import { WSService } from "../utils/WSService";
import { IBalanceBaseResponse } from "@wingspanhq/bookkeeping/dist/lib/interfaces/banking";
import {
  IPendingTransactionCreate,
  IPendingTransactionResponse,
  IPendingTransactionUpdate
} from "@wingspanhq/bookkeeping/dist/lib/interfaces/pendingTransaction";
import { Currency } from "@wingspanhq/users/dist/lib/interfaces";

const service = new WSService("/bookkeeping");

const enable = async (userId: string): Promise<any> => {
  const { data } = await service.patch(`/service/${userId}`, { enabled: true });
  return data;
};

export type BookkeepingEnablement = {
  enabled: boolean;
};
const getStatus = async (): Promise<BookkeepingEnablement> => {
  const { data } = await service.get("/service");
  return data;
};

const createTaxEstimate = async (
  options: ITaxEstimateBase
): Promise<ITaxEstimate> => {
  const { data } = await service.post("/tax-estimate", options);
  return data;
};

export type BookkeepingTransactionsResponse = {
  data: ITransaction[];
  summary: {
    listSize: number;
  };
};

const fetchTransactions = async (
  params: any
): Promise<BookkeepingTransactionsResponse> => {
  const { data, headers } = await service.get("/transaction", {
    params
  });
  return { data, summary: { listSize: Number(headers["x-ws-list-size"]) } };
};

export type CreateTransactionData = Pick<
  ITransaction,
  | "name"
  | "amount"
  | "category"
  | "date"
  | "business"
  | "deductibleAmount"
  | "businessAmount"
  | "status"
  | "location"
  | "currency"
  | "type"
  | "wsCategory"
>;

//TODO: fix import issue;
export const CurrencyUSD = Currency.USD as any;

const createTransaction = async (
  transaction: CreateTransactionData
): Promise<ITransaction> => {
  const { data } = await service.post("/transaction", transaction);
  return data;
};

export type UpdateTransactionData = Partial<ITransaction> &
  Pick<ITransaction, "transactionId">;

const updateTransaction = async (
  transaction: UpdateTransactionData
): Promise<ITransaction> => {
  const { data } = await service.patch(
    `/transaction/${transaction.transactionId}`,
    transaction
  );
  return data;
};

const getTransaction = async (transactionId: string): Promise<ITransaction> => {
  const { data } = await service.get(`/transaction/${transactionId}`);
  return data;
};

const deleteTransaction = async (transactionId: string): Promise<any> => {
  const { data } = await service.delete(`/transaction/${transactionId}`);
  return data;
};

const downloadScheduleCPDF = async ({
  startDate,
  endDate,
  inNewTab
}: {
  startDate: Date;
  endDate: Date;
  inNewTab?: boolean;
}): Promise<any> => {
  const { data } = await service.get("/report/f1040sc", {
    params: {
      startDate,
      endDate
    },
    responseType: "blob"
  });

  const blob = window.URL.createObjectURL(
    new Blob([data], { type: "application/pdf" })
  );

  if (inNewTab) {
    openInNewTab(blob);
  } else {
    const tempLink = document.createElement("a");
    tempLink.href = blob;
    tempLink.setAttribute("download", "wingspan-f1040sc.pdf");
    const tempElement = document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempElement);
  }

  setTimeout(() => {
    window.URL.revokeObjectURL(blob);
  }, 1000);
};

const getCatchUpIncomeTransactions = async (
  memberId: string,
  catchUpYear?: string
): Promise<ICatchUpIncomeTransactions> => {
  const { data } = await service.get(
    `/transaction/insights/catch-up/income/${memberId}`,
    {
      params: { catchUpYear }
    }
  );
  return data;
};

const getCatchUpExpenseTransactions = async (
  memberId: string,
  catchUpYear?: string
): Promise<ICatchUpExpenseTransactions> => {
  const { data } = await service.get(
    `/transaction/insights/catch-up/expenses/${memberId}`,
    {
      params: {
        catchUpYear
      }
    }
  );
  return data;
};

export const getWithholdingTransactions = async (
  params: any
): Promise<IWithholdingTransactionResponse[]> => {
  const { data } = await service.get("/withholding-transaction", {
    params
  });
  return data;
};
export const getWithholdingTransaction = async (
  transactionId: string
): Promise<IWithholdingTransactionResponse> => {
  const { data } = await service.get(
    `/withholding-transaction/${transactionId}`
  );
  return data;
};

export const getWithholdingBalance = async (): Promise<IBalanceResponse> => {
  const { data } = await service.get("/withholding-balance");
  return data;
};

export const getSubcategories = async (
  wsCategory?: WSCategory
): Promise<{ subcategories: Partial<{ [key in WSCategory]: string[] }> }> => {
  const { data } = await service.get(
    `/subcategory${wsCategory ? `?wsCategory=${wsCategory}` : ""}`
  );

  return data;
};

export const getProfitAndLoss = async (
  startDate: Date,
  endDate: Date
): Promise<IProfitAndLossResponse> => {
  // We add one millisecond to the end date bc the date select in our app gives us
  // the end date at 23:59:59:999 and the p+l endpoint uses a < for the range and
  // i guess we don't wanna miss transactions in that single millisecond range??
  // ¯\_(ツ)_/¯
  const { data } = await service.get("/profit-and-loss", {
    params: {
      startDate,
      endDate: new Date(+endDate + 1)
    }
  });
  return data;
};

export const createPendingWithholding = async (
  request: IPendingWithholdingCreate
) => {
  const { data } = await service.post("/withholding/pending", request);

  return data as IPendingWithholding;
};

export const updatePendingWithholding = async (
  id: string,
  request: IPendingWithholdingUpdate
) => {
  const { data } = await service.patch(`/withholding/pending/${id}`, request);

  return data as IPendingWithholding;
};

export const getPendingWithholdingList = async () => {
  const { data } = await service.get("/withholding/pending");

  return data as IPendingWithholding[];
};

const getBankingBalance = async (): Promise<IBalanceBaseResponse> => {
  const response = await service.get(`/banking/balance`);
  return response.data;
};

const getBankingAccount = async (): Promise<IInternalAccount> => {
  const response = await service.get(`/banking/account`);
  return response.data;
};

const createPendingBankingTransaction = async (
  data: IPendingTransactionCreate
): Promise<IPendingTransactionResponse> => {
  const response = await service.post(`/banking/pending`, data);
  return response.data;
};

const getPendingBankingTransactions = async (): Promise<Array<
  IPendingTransactionResponse
>> => {
  const response = await service.get(`/banking/pending`, {
    params: {
      page: {
        size: 1000
      }
    }
  });
  return response.data;
};

const getPendingBankingTransaction = async (
  id: string
): Promise<IPendingTransactionResponse> => {
  const response = await service.get(`/banking/pending/${id}`);
  return response.data;
};

const updatePendingBankingTransaction = async (
  id: string,
  data: IPendingTransactionUpdate
): Promise<IPendingTransactionResponse> => {
  const response = await service.patch(`/banking/pending/${id}`, data);
  return response.data;
};

const getPostedCashbackBalance = async (
  id: string
): Promise<IPostedCashbackBalanceResponse> => {
  const response = await service.get(`/cashback/balance/posted`);
  return response.data;
};

const getCashbackEligibility = async (): Promise<ICashbackEligibilityResponse> => {
  const response = await service.get(`/cashback/eligibility`);
  return response.data;
};

const redeemCashbackBalance = async (
  data: IRedeemCashbackBalanceRequest
): Promise<IRedeemCashbackBalanceResponse> => {
  const response = await service.post(`cashback/balance/redeem`, data);
  return response.data;
};

const bookkeepingService = {
  enable,
  getStatus,
  createTaxEstimate,
  fetchTransactions,
  getTransaction,
  createTransaction,
  updateTransaction,
  deleteTransaction,
  downloadScheduleCPDF,
  getCatchUpIncomeTransactions,
  getCatchUpExpenseTransactions,
  getWithholdingTransactions,
  getWithholdingTransaction,
  getWithholdingBalance,
  getProfitAndLoss,
  getSubcategories,
  createPendingWithholding,
  updatePendingWithholding,
  getPendingWithholdingList,
  getBankingBalance,
  getBankingAccount,
  createPendingBankingTransaction,
  getPendingBankingTransaction,
  getPendingBankingTransactions,
  updatePendingBankingTransaction,
  getPostedCashbackBalance,
  getCashbackEligibility,
  redeemCashbackBalance
};

export default bookkeepingService;
