import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";

export const selectorPayeeTaxFormSupportContacts = (
  taxForm: IPayeeTaxFormResponse
) => {
  const supportEmail =
    taxForm.payer?.profile?.company?.supportEmail || taxForm.payer?.user.email;
  const supportPhone =
    // @ts-ignore
    taxForm.payer?.profile?.company?.supportPhoneNumber ||
    taxForm.payer?.user?.phone?.number;

  return { supportEmail, supportPhone };
};
