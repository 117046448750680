import {
  WSCard,
  WSGrid,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { useAuthorizedScopeGroups } from "../../../../shared/utils/teamUtils";

export const RouteDashboardReports: React.FC = () => {
  const history = useHistory();
  const featureFlags = useFeatureFlags();

  const {
    hasPaymentsScope,
    hasCollaboratorsScope
  } = useAuthorizedScopeGroups();

  return (
    <WSPage title="Reports">
      {hasPaymentsScope ? (
        <>
          <WSText.Heading4 mb="XL">Payables Reports</WSText.Heading4>

          <WSGrid>
            <WSGrid.Item span={{ s: "6", l: "5" }}>
              <WSCard
                header={{
                  label: "Payroll Line Items"
                }}
                actions={{
                  buttons: [
                    {
                      kind: "Link",
                      label: "Open report",
                      onClick() {
                        history.push("/member/reports/payroll");
                      }
                    }
                  ]
                }}
              >
                <WSText.ParagraphSm color="gray600">
                  All line items associated with a completed payroll run
                  including invoice data, payment dates, line item details,
                  amounts, and payout statuses.
                </WSText.ParagraphSm>
              </WSCard>
            </WSGrid.Item>
            <WSGrid.Item span={{ s: "6", l: "5" }}>
              <WSCard
                header={{
                  label: "Open Payable Aging"
                }}
                actions={{
                  buttons: [
                    {
                      kind: "Link",
                      label: "Open report",
                      onClick() {
                        history.push("/member/reports/open-payable-aging");
                      }
                    }
                  ]
                }}
              >
                <WSText.ParagraphSm color="gray600">
                  Accounts payable aging summary of open payables grouped in
                  either 7-day or 30-day due date increments.
                </WSText.ParagraphSm>
              </WSCard>
            </WSGrid.Item>
            <WSGrid.Item span={{ s: "6", l: "5" }}>
              <WSCard
                header={{
                  label: "Open Line Item Aging"
                }}
                actions={{
                  buttons: [
                    {
                      kind: "Link",
                      label: "Open report",
                      onClick() {
                        history.push("/member/reports/open-line-item-aging");
                      }
                    }
                  ]
                }}
              >
                <WSText.ParagraphSm color="gray600">
                  Accounts payable aging summary of open line items grouped in
                  either 7-day or 30-day due date increments.
                </WSText.ParagraphSm>
              </WSCard>
            </WSGrid.Item>
          </WSGrid>

          {featureFlags.data?.showArAgingReport && (
            <>
              <WSText.Heading4 mt="2XL" mb="XL">
                Receivable Reports
              </WSText.Heading4>
              <WSGrid>
                <WSGrid.Item span={{ s: "6", l: "5" }}>
                  <WSCard
                    header={{
                      label: "Open Receivable Aging"
                    }}
                    actions={{
                      buttons: [
                        {
                          kind: "Link",
                          label: "Open report",
                          onClick() {
                            history.push(
                              "/member/reports/open-receivable-aging"
                            );
                          }
                        }
                      ]
                    }}
                  >
                    <WSText.ParagraphSm color="gray600">
                      "Accounts receivable aging summary of open receivables
                      grouped in either 7-day or 30-day due date increments."
                    </WSText.ParagraphSm>
                  </WSCard>
                </WSGrid.Item>
              </WSGrid>
            </>
          )}
        </>
      ) : null}
      {hasCollaboratorsScope ? (
        <>
          <WSText.Heading4 mt="2XL" mb="XL">
            Contractor Reports
          </WSText.Heading4>

          <WSGrid>
            <WSGrid.Item span={{ s: "6", l: "5" }}>
              <WSCard
                header={{
                  label: "Contractor"
                }}
                actions={{
                  buttons: [
                    {
                      kind: "Link",
                      label: "Open report",
                      onClick() {
                        history.push("/member/reports/collaborators");
                      }
                    }
                  ]
                }}
              >
                <WSText.ParagraphSm color="gray600">
                  A list of contractors including personal details, onboarding
                  status and group details.
                </WSText.ParagraphSm>
              </WSCard>
            </WSGrid.Item>
            <WSGrid.Item span={{ s: "6", l: "5" }}>
              <WSCard
                header={{
                  label: "Contractor Payables Summary"
                }}
                actions={{
                  buttons: [
                    {
                      kind: "Link",
                      label: "Open report",
                      onClick() {
                        history.push(
                          "/member/reports/collaborator-payables-summary"
                        );
                      }
                    }
                  ]
                }}
              >
                <WSText.ParagraphSm color="gray600">
                  Summary of the total payments made to each contractor within a
                  specified period.
                </WSText.ParagraphSm>
              </WSCard>
            </WSGrid.Item>
          </WSGrid>
        </>
      ) : null}
    </WSPage>
  );
};
