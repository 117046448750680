import React, { useState } from "react";

import { ITransaction } from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";

import {
  useModalContext,
  useWSSnackbar,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";

import { useDeleteTransaction } from "../../../../query/bookkeeping/mutations";
import { runner } from "./utils.";

type BookkeepingModalBulkDeleteProps = {
  selectedTransactions: ITransaction[] | undefined;
  onActionFinish(): void;
};

export const BOOKKEEPING_MODAL_BULK_DELETE = "BOOKKEEPING_MODAL_BULK_DELETE";

export const BookkeepingModalBulkDelete: React.FC = () => {
  const { closeModal } = useModalContext();
  const { openSnackbar } = useWSSnackbar();

  const [isUpdating, setIsUpdating] = useState(false);

  const [deleteTransaction] = useDeleteTransaction();

  // TODO: Need to prevent closing during operation
  return (
    <WSModal
      blockClose={isUpdating}
      name={BOOKKEEPING_MODAL_BULK_DELETE}
      size="S"
    >
      {({
        selectedTransactions,
        onActionFinish
      }: BookkeepingModalBulkDeleteProps) => (
        <>
          {selectedTransactions && selectedTransactions.length > 0 ? (
            <>
              <WSText.Heading5 mb="M">
                Delete {selectedTransactions.length} transaction
                {selectedTransactions.length > 1 && "s"}?
              </WSText.Heading5>
              <WSText my="XL">
                Once confirmed, this action can’t be undone.
              </WSText>
              <WSButtons mt="M" forceFullWidth format="modal">
                <WSButton
                  destructive
                  loading={isUpdating}
                  onClick={async () => {
                    setIsUpdating(true);

                    await runner({
                      items: selectedTransactions,
                      openSnackbar,
                      noUndo: true,
                      successMessage: successItems =>
                        `${successItems.length} transactions deleted`,
                      failMessage: failedItems =>
                        `${failedItems.length} deletes failed`,
                      tryAction: async transaction => {
                        await deleteTransaction(transaction.transactionId, {
                          throwOnError: true
                        });
                      },
                      undoAction: () => Promise.resolve()
                    });

                    setIsUpdating(false);
                    onActionFinish();
                    closeModal(BOOKKEEPING_MODAL_BULK_DELETE);
                  }}
                >
                  Delete
                </WSButton>
                <WSButton.Tertiary
                  disabled={isUpdating}
                  onClick={() => closeModal(BOOKKEEPING_MODAL_BULK_DELETE)}
                >
                  Cancel
                </WSButton.Tertiary>
              </WSButtons>
            </>
          ) : (
            closeModal(BOOKKEEPING_MODAL_BULK_DELETE)
          )}
        </>
      )}
    </WSModal>
  );
};
