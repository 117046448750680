import {
  WSAvatar,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ICheckbookCard,
  IPaymentCard,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import classNames from "classnames";
import React from "react";
import { useBankingAccount } from "../../../../query/bookkeeping/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import { useQueryPaymentsCardsDebit } from "../../../../query/paymentCards/queries/useQueryPaymentsCardsDebit";
import {
  usePayoutSettings,
  usePayoutSettingsDebitCards
} from "../../../../query/payments/queries";
import { useAccounts } from "../../../../query/users/queries";
import { selectorDefaultDestination } from "../../../../shared/selectors/selectorDefaultDestination";
import { selectorInstantAccounts } from "../../selectors/selectorInstantAccounts";
import { selectorStandardAccounts } from "../../selectors/selectorStandardAccounts";
import styles from "./index.module.scss";

type Props = {
  account?: IAccount;
  debitCard?: ICheckbookCard;
  paymentCard?: IPaymentCard;
};

export const DeleteBanner: React.FC<Props> = ({
  account,
  debitCard,
  paymentCard
}) => {
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryInternalAccount = useBankingAccount();
  const queryAccounts = useAccounts();
  const queryDebitCards = usePayoutSettingsDebitCards(userId);
  const queryPaymentCards = useQueryPaymentsCardsDebit();

  if (
    !queryPayoutSettings.data ||
    queryPayoutSettings.isLoading ||
    queryInternalAccount.isLoading ||
    queryAccounts.isLoading ||
    queryDebitCards.isLoading ||
    queryPaymentCards.isLoading
  )
    return null;

  const payoutSettings = queryPayoutSettings.data;
  const internalAccount = queryInternalAccount.data;

  const defaultDestination = selectorDefaultDestination(payoutSettings);
  const destinationId = account?.accountId || debitCard?.cardId;
  const standardAccounts = selectorStandardAccounts(queryAccounts.data || []);
  const instantAccounts = selectorInstantAccounts(queryAccounts.data || []);
  const debitCards = queryDebitCards.data || [];
  const paymentCards = queryPaymentCards.data || [];

  // Flags
  const isDefaultDestination =
    defaultDestination?.destinationId === destinationId;

  const isUsedInStandardAndInstant =
    account &&
    standardAccounts.some(a => a.accountId === account.accountId) &&
    instantAccounts.some(a => a.accountId === account.accountId);

  const hasWallet = !!internalAccount;

  const hasOtherStandardMethods =
    standardAccounts.filter(a => a.accountId !== account?.accountId).length > 0;

  const hasOtherInstantMethods =
    instantAccounts.filter(a => a.accountId !== account?.accountId).length >
      0 ||
    debitCards.filter(dc => dc.cardId !== debitCard?.cardId).length > 0 ||
    paymentCards.filter(pc => pc.paymentCardId !== paymentCard?.paymentCardId)
      .length > 0;

  const hasOtherMethods =
    defaultDestination?.payoutMethod === PayoutPreferences.Standard
      ? hasOtherStandardMethods
      : defaultDestination?.payoutMethod === PayoutPreferences.Instant
      ? hasOtherInstantMethods
      : false;

  // Not default destination
  if (!isDefaultDestination && isUsedInStandardAndInstant) {
    return (
      <Banner
        title="Remove both Instant and Standard payout methods"
        description="Removing a payout method removes both Standard and Instant (where eligible) payout methods for the account."
      />
    );
  }

  // Default destination + fallback to wallet
  if (isDefaultDestination && hasWallet && isUsedInStandardAndInstant) {
    return (
      <Banner
        title="Switch to Wingspan Wallet"
        description="Removing a payout method removes both Standard and Instant (where eligible) payout methods for the account. Wingspan Wallet will be set as your default method to get paid (unless you add other payout methods to set as default)."
        showWalletIcon
      />
    );
  }

  if (isDefaultDestination && hasWallet && !isUsedInStandardAndInstant) {
    return (
      <Banner
        title="Switch to Wingspan Wallet"
        description="Wingspan Wallet will be set as your default method to get paid (unless you add other payout methods to set as default)."
        showWalletIcon
      />
    );
  }

  // Default destination, has no wallet but has other methods
  if (
    isDefaultDestination &&
    !hasWallet &&
    hasOtherMethods &&
    isUsedInStandardAndInstant
  ) {
    return (
      <Banner
        title="Remove both Instant and Standard payout methods"
        description="Removing a payout method removes both Standard and Instant (where eligible) payout methods for the account. This is currently your default method to get paid, please select another payout method to set as default."
      />
    );
  }

  if (
    isDefaultDestination &&
    !hasWallet &&
    hasOtherMethods &&
    !isUsedInStandardAndInstant
  ) {
    return (
      <Banner
        title={`Remove ${defaultDestination?.payoutMethod} payout method`}
        description="This is currently your default method to get paid, please select another payout method to set as default."
      />
    );
  }

  // Default destination, has no wallet but has NO other methods
  if (
    isDefaultDestination &&
    !hasWallet &&
    !hasOtherMethods &&
    isUsedInStandardAndInstant
  ) {
    return (
      <Banner
        title="You have no other payout methods"
        description="Removing a payout method removes both Standard and Instant (where eligible) payout methods for the account. After removing this payout method, there will be no remaining options for your default payout method. You will need to add another payout method to get paid."
      />
    );
  }

  if (
    isDefaultDestination &&
    !hasWallet &&
    !hasOtherMethods &&
    !isUsedInStandardAndInstant
  ) {
    return (
      <Banner
        title="You have no other payout methods"
        description="After removing this payout method, there will be no remaining options for your default payout method. You will need to add another payout method to get paid."
      />
    );
  }

  return null;
};

type BannerProps = {
  title: string;
  description: string;
  showWalletIcon?: boolean;
};

const Banner: React.FC<BannerProps> = ({
  title,
  description,
  showWalletIcon
}) => (
  <WSPanel className={classNames(styles.bannerPanel, styles.warning)} noBorder>
    <WSFlexBox wrap="nowrap" alignItems="center">
      {showWalletIcon ? (
        <WSAvatar.Icon
          color="white"
          colorBackground="red400"
          icon="wallet"
          mr="M"
        />
      ) : (
        <WSIcon block name="alert-circle" mr="M" size="M" color="red500" />
      )}
      <WSElement>
        <WSText weight="medium" mb="XS" color="red500">
          {title}
        </WSText>
        <WSText color="gray400">{description}</WSText>
      </WSElement>
    </WSFlexBox>
  </WSPanel>
);
