import {
  WSButton,
  WSCentered,
  WSContainer,
  WSElement,
  WSFlexBox,
  WSIconList,
  WSInfoBox,
  WSPanel,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { useCollaboratorGroupsQuery } from "../../../query/payments/queries";
import { WSQueries } from "../../../query/WSQuery";
import { useUpdateBulkCollaboratorBatch } from "../../../modules/BulkImporter/query/bulkCollaborator/mutations";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";
import { useCustomFieldsAll } from "../../../query/customFields/queries/useCustomFieldsAll";
import { useEngagementsListAllQuery } from "../../../query/engagements/queries/useEngagementsListAllQuery";
import { useUserId } from "../../../query/hooks/helpers";
import { useUserProfile } from "../../../query/users/queries";
import { selectorIsEngagementsActive } from "../../../shared/selectors/selectorIsEngagementsActive";
import { InfoTooltip } from "../../../shared/components/InfoTooltip/InfoTooltip";

export interface BulkUploadCollaboratorsStep2Props {}

export const defaultCollaboratorGroupOption = {
  label: "Do not add contractors to group",
  value: "NONE"
};

export const BulkUploadCollaboratorsStep2: React.FC<BulkUploadCollaboratorsStep2Props> = () => {
  useBrowserPageTitle("Wingspan - Bulk upload contractors");
  const history = useHistory();
  const userId = useUserId();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const bulkBatchId = match.params.bulkBatchId;

  const [selectedCollaboratorGroup, setSelectedCollaboratorGroup] = useState(
    defaultCollaboratorGroupOption
  );
  const [selectedEngagement, setSelectedEngagement] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const queryUser = useUserProfile(userId);
  const qFeatureFlags = useFeatureFlags();
  const qCollaboratorGroups = useCollaboratorGroupsQuery();
  const queryEngagements = useEngagementsListAllQuery({
    filter: {
      isDefault: false
    }
  });

  const qCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });
  const [
    updateBulkCollaboratorBatch,
    updateBulkCollaboratorBatchMeta
  ] = useUpdateBulkCollaboratorBatch();
  const collaboratorCustomFields = qCollaboratorCustomFields.data || [];

  const requiredFields = ["First Name", "Last Name", "Email"];
  const optionalFields = ["Phone number", "External Contractor ID"];

  collaboratorCustomFields.forEach(field => {
    if (field.required) {
      requiredFields.push(field.name);
    } else {
      optionalFields.push(field.name);
    }
  });

  const title = qFeatureFlags.data?.bulkImporterCollaborator
    ? "Import contractors with a spreadsheet"
    : "Bulk upload contractors with a spreadsheet";

  const gotoBulkBatchDetails = async () => {
    if (selectedCollaboratorGroup.value === "NONE" && !selectedEngagement) {
      history.push(`/member/imports/collaborators/${bulkBatchId}`);
    } else {
      await updateBulkCollaboratorBatch(
        {
          id: bulkBatchId,
          data: {
            labels: {
              engagementId: selectedEngagement?.value as string,
              collaboratorGroupId: selectedCollaboratorGroup.value
            }
          }
        },
        {
          onSuccess: () => {
            history.push(`/member/imports/collaborators/${bulkBatchId}`);
          }
        }
      );
    }
  };

  return (
    <ProjectOnboardingLayout progress={(100 / 3) * 2}>
      <WSContainer verticalPadding>
        <WSCentered span={{ s: "6", m: "8" }}>
          {qFeatureFlags.data?.bulkImporterCollaborator ? null : (
            <WSText.ParagraphSm color="gray500" mt="M" mb="XL" pt="M">
              2 of 3
            </WSText.ParagraphSm>
          )}
          <WSText.Heading4>{title}</WSText.Heading4>
          <WSPanel mt="2XL">
            <WSQueries
              queries={{
                queryUser,
                qCollaboratorGroups,
                queryEngagements
              }}
            >
              {({
                queryUserData: user,
                qCollaboratorGroups: { data: collaboratorGroups },
                queryEngagementsData: engagements
              }) => {
                const collaboratorGroupOptions = [
                  defaultCollaboratorGroupOption,
                  ...collaboratorGroups.map(collaboratorGroup => ({
                    label: collaboratorGroup.name,
                    value: (collaboratorGroup as any).collaboratorGroupId
                  }))
                ];
                const engagementOptions = engagements
                  .map(engagement => ({
                    label: engagement.name,
                    value: engagement.engagementId
                  }))
                  .filter(option => option.label && option.value); // filter out empty values

                const onSelectCollaboratorGroup = (value: any) => {
                  const collaboratorGroup = collaboratorGroupOptions.find(
                    cg => cg.value === value
                  );
                  setSelectedCollaboratorGroup(
                    collaboratorGroup ?? defaultCollaboratorGroupOption
                  );
                };

                const onSelectEngagement = (value: any) => {
                  const engagement = engagementOptions.find(
                    e => e.value === value
                  );
                  setSelectedEngagement(engagement ?? null);
                };

                return (
                  <>
                    <WSInfoBox mb="XL">
                      After adding a contractor, they will be invited by the
                      email provided to sign-up for a Wingspan account in order
                      to receive payments from you.
                    </WSInfoBox>

                    <WSText.ParagraphSm>
                      Don't worry if your spreadsheet has other details, but
                      please make sure it contains the following columns.
                    </WSText.ParagraphSm>
                    <WSFlexBox mt="2XL" mb="XL">
                      <WSElement style={{ flex: 1 }}>
                        <WSFlexBox.CenterY mb="M">
                          <WSText.ParagraphSm mr="S" color="gray500">
                            Required
                          </WSText.ParagraphSm>
                          <InfoTooltip
                            text={`Send an invite to the contractor’s personal email or the email of the contractor’s business that they operate under. 
                            
We do not recommend using emails provided by your company since the contractor’s account is portable for their own use as a contractor.

This information will be confirmed by the contractor when they onboard`}
                            tooltipProps={{
                              title: "Contractor email and full name"
                            }}
                          />
                        </WSFlexBox.CenterY>

                        <WSIconList.Checks
                          items={requiredFields}
                          iconProps={{
                            name: "check-circle",
                            color: "gray500"
                          }}
                          textProps={{
                            kind: "ParagraphSm"
                          }}
                          mb="XL"
                        />
                      </WSElement>
                      <WSElement style={{ flex: 1 }}>
                        <WSText.ParagraphSm color="gray500" mb="M">
                          Optional
                        </WSText.ParagraphSm>
                        <WSIconList.Checks
                          items={optionalFields}
                          iconProps={{
                            name: "check-circle",
                            color: "gray500"
                          }}
                          textProps={{
                            kind: "ParagraphSm"
                          }}
                          mb="XL"
                        />
                      </WSElement>
                    </WSFlexBox>

                    {/* Show engagement selection only for non-default engagements */}
                    {selectorIsEngagementsActive(user) &&
                      engagementOptions.length > 0 && (
                        <WSSelect
                          label="Engagement (optional)"
                          value={selectedEngagement?.value}
                          onChange={onSelectEngagement}
                          tooltip={{
                            dark: true,
                            title: "Engagement (optional)",
                            children: `An engagement is a type of work arrangement that allows payers to organize their diverse work relationships in a flexible way. One engagement can be assigned when adding a new contractor. Other engagements can be added later.
                            
If you have not set up engagements or do not plan to use engagements, you can skip this field. Engagements can be updated in the Contractors section of the app at any time.`
                          }}
                          options={engagementOptions}
                          internalSearch
                          placeholder="Select an engagement"
                        />
                      )}

                    {/* Supporting legacy collaborator groups if engagements is not enabled */}
                    {!selectorIsEngagementsActive(user) &&
                      collaboratorGroupOptions.length > 0 && (
                        <WSSelect
                          label="Contractor group"
                          value={selectedCollaboratorGroup.value}
                          onChange={onSelectCollaboratorGroup}
                          options={collaboratorGroupOptions}
                          internalSearch
                          placeholder="Select a collaborator group"
                        />
                      )}

                    <WSButton
                      size="M"
                      mt="XL"
                      name="bulkImportCollaboratorsStep2"
                      icon="upload"
                      fullWidth
                      onClick={gotoBulkBatchDetails}
                      loading={updateBulkCollaboratorBatchMeta.isLoading}
                    >
                      Import contractors
                    </WSButton>
                  </>
                );
              }}
            </WSQueries>
          </WSPanel>
        </WSCentered>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
