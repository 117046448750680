import {
  IMemberClientRequirementResponse,
  IRequirementResponse,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import {
  WSTableAction,
  WSTableRowMenuAction
} from "@wingspanhq/fe-component-library";
import { RequirementEvent } from "./utils";
import { CreateRequirementDefinitionSharedFilePartialForm } from "./modules/SharedFile/types";
import { CreateRequirementDefinitionSignaturePartialForm } from "./modules/Signature/types";
import { ISignedDocumentResponse } from "@wingspanhq/signed-documents/dist/lib/interfaces";
import { ISharedFileRequestResponse } from "@wingspanhq/shared-files/dist/lib/interfaces";
import { CreateRequirementDefinitionBackgroundChecksPartialForm } from "./modules/BackgroundChecks/types";
import { IBackgroundCheckRequestResponse } from "@wingspanhq/background-checks/dist/lib/interfaces";

export enum RequirementContextType {
  Payer = "Payer",
  Payee = "Payee"
}

export type RequirementTableItemData =
  | IMemberClientRequirementResponse
  | IRequirementResponse;

type DataItem<T extends RequirementType> = T extends RequirementType.Signature
  ? RequirementTableItemData
  : IRequirementResponse;

export type ContextItem = {
  [RequirementType.SharedFile]?: ISharedFileRequestResponse[];
  [RequirementType.Signature]?: ISignedDocumentResponse[];
  [RequirementType.BackgroundCheck]?: IBackgroundCheckRequestResponse[];
  payerId?: string;
  payeeId?: string;
  showOverrideActions?: boolean;
};

export type RenderCellFunction<T extends RequirementType> = (
  dataItem: DataItem<T>,
  context: ContextItem
) => React.ReactNode;

export type CellsByContext<T extends RequirementType> = {
  [keyof in RequirementContextType]: RenderCellFunction<T>;
};

export type RequirementCellsConfig<T extends RequirementType> = {
  nameCell: CellsByContext<T>;
  typeCell: CellsByContext<T>;
  statusCell: CellsByContext<T>;
  rowActions: {
    [keyof in RequirementContextType]: (
      dataItem: DataItem<T>,
      contextItem: ContextItem
    ) => Array<WSTableAction>;
  };
  rowMenuActions: {
    [keyof in RequirementContextType]: (
      dataItem: DataItem<T>,
      contextItem: ContextItem
    ) => WSTableRowMenuAction<DataItem<T>>[];
  };
  fetchContext?: (
    dataItems: DataItem<T>[]
  ) => Promise<
    T extends RequirementType.Signature
      ? ISignedDocumentResponse[]
      : T extends RequirementType.SharedFile
      ? ISharedFileRequestResponse[]
      : T extends RequirementType.BackgroundCheck
      ? IBackgroundCheckRequestResponse[]
      : unknown
  >;
};

export type CellsConfig<R extends RequirementTableItemData> = {
  nameCell(dataItem: R, contextItem: ContextItem): React.ReactNode;
  typeCell(dataItem: R, contextItem: ContextItem): React.ReactNode;
  statusCell(dataItem: R, contextItem: ContextItem): React.ReactNode;
  rowActions(dataItem: R, contextItem: ContextItem): WSTableAction[];
  rowMenuActions(
    dataItem: R,
    contextItem: ContextItem
  ): WSTableRowMenuAction<R>[];
};

export type RequirementTableRowConfig = {
  [K in RequirementType]: RequirementCellsConfig<K>;
};

export type RequirementTableCallback = (
  eventName: RequirementEvent,
  item: RequirementTableItemData
) => void;

export type BaseCreateRequirementDefinitionFormValues = {
  name: string;
  type: RequirementType;
  description?: string;
};

export type CreateRequirementDefinitionFormValues = BaseCreateRequirementDefinitionFormValues &
  CreateRequirementDefinitionSignaturePartialForm &
  CreateRequirementDefinitionSharedFilePartialForm &
  CreateRequirementDefinitionBackgroundChecksPartialForm;
