import {
  WSElement,
  WSElementProps,
  WSTable,
  WSTableCell,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import { IPayableSchema } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { calculateLineItemsTotal } from "../../utils";

type Props = { payables: IPayableSchema[] } & WSElementProps;

export const PayablesMiniTable: React.FC<Props> = ({
  payables,
  ...elementProps
}) => (
  <WSElement {...elementProps}>
    <WSTable
      tableData={payables.map(payable => ({
        id: payable.payableId,
        data: payable
      }))}
      columns={[
        {
          renderFunction: row => (
            <WSTableCell text={`#${row.data.invoiceNumber}`} />
          )
        },
        {
          config: {
            justify: "end"
          },
          renderFunction: row => (
            <WSTableCell
              text={toWSMoneyString(
                calculateLineItemsTotal(row.data.lineItems)
              )}
            />
          )
        }
      ]}
    />
  </WSElement>
);
