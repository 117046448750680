import { InfiniteQueryConfig } from "react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSInfiniteQuery } from "../../helpers";
import { QUERY_ENGAGEMENT_LIST } from "../keys";
import {
  EngagementApiFilter,
  EngagementFilter,
  EngagementListQueryResponse,
  getEngagements
} from "../../../services/engagements";

export function mapEngagementsFilters(filters?: EngagementFilter) {
  let filter: EngagementApiFilter = {};

  if (filters?.type) {
    filter.type = filters.type;
  }

  if (filters && "isDefault" in filters) {
    filter.isDefault = filters.isDefault;
  }

  return filter;
}

export type EngagementListQueryParams = {
  sort?: { updatedAt?: "desc" | "asc"; createdAt?: "desc" | "asc" };
  filter?: EngagementFilter;
};

export const useEngagementListQuery = (
  params?: EngagementListQueryParams,
  config?: InfiniteQueryConfig<EngagementListQueryResponse, WSServiceError>
) => {
  const { sort, filter } = params || {};

  return useWSInfiniteQuery<EngagementListQueryResponse, WSServiceError>(
    [QUERY_ENGAGEMENT_LIST, params],
    async (query, queryParams, pageNumber = 1) => {
      const { data, summary } = await getEngagements({
        filter: mapEngagementsFilters(filter),
        page: { size: 10, number: pageNumber },
        sort
      });

      return { data, summary };
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.data.length < 10) {
          return undefined;
        }

        return allPages.length + 1;
      },
      ...config
    }
  );
};
