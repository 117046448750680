import {
  WSElement,
  WSElementProps,
  WSIcon
} from "@wingspanhq/fe-component-library";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { IS_PRODUCTION_ENV } from "../../shared/constants/environment";
import styles from "./InstitutionLogo.module.scss";

export interface IInstitutionLogo extends WSElementProps {
  institutionId?: string;
}

export const InstitutionLogo: React.FC<IInstitutionLogo> = ({
  institutionId,
  className,
  ...elementProps
}) => {
  const bucketName = IS_PRODUCTION_ENV
    ? "wingspan-shared-content"
    : "wingspan-staging-shared-content";

  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setImage("");
    const img = new Image();
    const url = `https://storage.cloud.google.com/${bucketName}/plaid/logos/${institutionId}.png`;
    img.onload = () => {
      setImage(url);
      setLoading(false);
    };
    img.onerror = () => {
      setImage("");
      setLoading(false);
    };
    img.src = url;

    return () => {
      img.onerror = null;
      img.onabort = null;
    };
  }, [institutionId, bucketName]);

  if (loading) {
    return (
      <WSElement
        className={cn(styles.defaultLogo, className)}
        {...elementProps}
      />
    );
  }

  if (!institutionId || !image) {
    return (
      <WSElement
        className={cn(styles.defaultLogo, className)}
        {...elementProps}
      >
        <WSIcon block name="bank" />
      </WSElement>
    );
  }

  return (
    <WSElement
      className={cn(styles.logo, className)}
      style={{ backgroundImage: `url("${image}")` }}
      {...elementProps}
    />
  );
};
