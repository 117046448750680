import {
  useIsDesktop,
  WSElement,
  WSLoader
} from "@wingspanhq/fe-component-library";
import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ProductActivationWrapper } from "../components/ProductActivationWrapper/ProductActivationWrapper";
import { useBookkeepingStatus } from "../query/bookkeeping/queries";
import { useUserId } from "../query/hooks/helpers";
import {
  useAccounts,
  useActivities,
  useMemberProfile
} from "../query/users/queries";
import { CatchUpExpenseEdit } from "./screens/catchup/CatchUpExpenseEdit";
import { CatchUpExpenseOnboarding } from "./screens/catchup/CatchUpExpenseOnboarding";
import { CatchUpIncomeEdit } from "./screens/catchup/CatchUpIncomeEdit";
import { CatchUpIncomeOnboarding } from "./screens/catchup/CatchUpIncomeOnboarding";
import { CatchUpSuccess } from "./screens/catchup/CatchUpSuccess";
import { BookkeepingEditAccounts } from "./screens/dashboard/BookkeepingEditAccounts";
import { BookkeepingEditAccountsBusinessOnly } from "./screens/dashboard/BookkeepingEditAccountsBusinessOnly";
import { BookkeepingEditAccountsSync } from "./screens/dashboard/BookkeepingEditAccountsSync";
import { BookkeepingTransactionDetailsV2 } from "./screens/dashboard/BookkeepingTransactionV2";

import { BookkeepingSetUpAccounts } from "./screens/onboarding/BookkeepingSetUpAccounts";
import { BookkeepingSetUpAccountsBusinessOnly } from "./screens/onboarding/BookkeepingSetUpAccountsBusinessOnly";
import { BookkeepingSetUpAccountsSync } from "./screens/onboarding/BookkeepingSetUpAccountsSync";
import { BookkeepingSetUpImportStart } from "./screens/onboarding/BookkeepingSetUpImportStart";
import { BookkeepingSetUpPlaidInstitutionBusinessOnly } from "./screens/onboarding/BookkeepingSetUpPlaidInstitutionBusinessOnly";
import { BookkeepingSetUpPlaidInstitutionSync } from "./screens/onboarding/BookkeepingSetUpPlaidInstitutionSync";
import { LandingPage } from "./screens/onboarding/LandingPage";
import { TaxProfile } from "./screens/onboarding/TaxProfile";
import { CURRENT_YEAR, PREV_YEAR } from "./utils";
import { BookkeepingCSVStart } from "./screens/csv/BookkeepingCSVStart";
import { BookkeepingCSVIsBusiness } from "./screens/csv/BookkeepingCSVIsBusiness";
import { BookkeepingCSVVerifyType } from "./screens/csv/BookkeepingCSVVerifyType";
import { BookkeepingAddTransactionV2 } from "./screens/dashboard/BookkeepingAddTransactionV2";
import { BookkeepingIndex } from "./screens/dashboard/BookkeepingIndex";
import { useOnQueriesFetched } from "../shared/hooks/useOnQueriesFetched";
import { useIsQueriesFetched } from "../shared/hooks/useIsQueriesFetched";

const CatchUp: React.FC = () => (
  <Switch>
    <Redirect
      from="/member/bookkeeping/catch-up"
      exact
      to="/member/bookkeeping/catch-up/income"
    />
    <Route
      path="/member/bookkeeping/catch-up/income"
      render={() => (
        <CatchUpIncomeEdit next="/member/bookkeeping/catch-up/expense" />
      )}
    />
    <Route
      path="/member/bookkeeping/catch-up/expense"
      render={() => <CatchUpExpenseEdit next="/member/bookkeeping" />}
    />
  </Switch>
);

const BookkeepingOnboardingRootContainer: React.FC = () => {
  const history = useHistory();
  const userId = useUserId();
  const qActivity = useActivities(userId);
  const qAccounts = useAccounts();
  const isFetched = useIsQueriesFetched(qActivity, qAccounts);

  useOnQueriesFetched(
    () => {
      const bookkeepingAccounts =
        qAccounts.data?.filter(a => a.canBeUsedFor?.bookkeeping) || [];

      const currentStep =
        qActivity.data?.flows.bookkeepingSetup?.currentStep || 0;

      if (!currentStep) {
        history.push("/member/bookkeeping/setup", history.location.state);
      } else if (
        currentStep < 4 &&
        bookkeepingAccounts.length &&
        bookkeepingAccounts.every(a => !a.usedFor?.bookkeeping) &&
        bookkeepingAccounts[0].institutionId
      ) {
        history.push(
          `/member/bookkeeping/setup/import/plaid/${bookkeepingAccounts[0].institutionId}/sync`
        );
      }
    },
    qActivity,
    qAccounts
  );

  if (!isFetched) {
    return (
      <WSElement my="5XL">
        <WSLoader.Spinner />
      </WSElement>
    );
  }

  return (
    <ProductActivationWrapper>
      <Switch>
        <Route path="/member/bookkeeping/setup" exact component={LandingPage} />

        <Route
          path="/member/bookkeeping/setup/import/start"
          exact
          component={BookkeepingSetUpImportStart}
        />

        <Route
          path="/member/bookkeeping/setup/accounts"
          exact
          component={BookkeepingSetUpAccounts}
        />

        <Route
          path="/member/bookkeeping/setup/accounts/:institutionId/sync"
          exact
          component={BookkeepingSetUpAccountsSync}
        />

        <Route
          path="/member/bookkeeping/setup/accounts/:institutionId/business-only"
          exact
          component={BookkeepingSetUpAccountsBusinessOnly}
        />

        <Route
          path="/member/bookkeeping/setup/csv"
          exact
          render={() => (
            <BookkeepingCSVStart next="/member/bookkeeping/setup/csv/is-business" />
          )}
        />

        <Route
          path="/member/bookkeeping/setup/csv/is-business"
          exact
          render={() => (
            <BookkeepingCSVIsBusiness next="/member/bookkeeping/setup/csv/verify-type" />
          )}
        />

        <Route
          path="/member/bookkeeping/setup/csv/verify-type"
          exact
          render={() => (
            <BookkeepingCSVVerifyType next="/member/bookkeeping/setup/tax-profile" />
          )}
        />

        <Route
          path="/member/bookkeeping/setup/import/plaid/:institutionId/sync"
          exact
          component={BookkeepingSetUpPlaidInstitutionSync}
        />

        <Route
          path="/member/bookkeeping/setup/import/plaid/:institutionId/business-only"
          exact
          component={BookkeepingSetUpPlaidInstitutionBusinessOnly}
        />

        {/* Step 5 */}
        <Route
          path="/member/bookkeeping/setup/tax-profile"
          exact
          component={TaxProfile}
        />

        <Route
          path="/member/bookkeeping/setup/no-sync-tax-profile"
          exact
          render={() => <TaxProfile noSync={true} />}
        />

        {/* And then catch-up */}

        <Route
          path="/member/bookkeeping/setup/catch-up/income/prev-year"
          render={() => (
            <CatchUpIncomeOnboarding
              year={PREV_YEAR}
              next="/member/bookkeeping/setup/catch-up/expense/prev-year"
            />
          )}
        />

        <Route
          path="/member/bookkeeping/setup/catch-up/expense/prev-year"
          render={() => (
            <CatchUpExpenseOnboarding
              year={PREV_YEAR}
              next="/member/bookkeeping/setup/catch-up/success/prev"
            />
          )}
        />

        <Route
          path="/member/bookkeeping/setup/catch-up/income"
          render={() => (
            <CatchUpIncomeOnboarding
              year={CURRENT_YEAR}
              next="/member/bookkeeping/setup/catch-up/expense"
            />
          )}
        />

        <Route
          path="/member/bookkeeping/setup/catch-up/expense"
          render={() => (
            <CatchUpExpenseOnboarding
              year={CURRENT_YEAR}
              next="/member/bookkeeping/setup/catch-up/success"
            />
          )}
        />

        <Route
          path="/member/bookkeeping/setup/catch-up/success"
          exact
          render={() => <CatchUpSuccess year={CURRENT_YEAR} />}
        />

        <Route
          path="/member/bookkeeping/setup/catch-up/success/prev"
          exact
          render={() => <CatchUpSuccess year={PREV_YEAR} />}
        />

        <Redirect from="*" to="/member/bookkeeping/setup/accounts" />
      </Switch>
    </ProductActivationWrapper>
  );
};

export const BookkeepingRoutes = () => {
  const userId = useUserId();

  const history = useHistory<{ accountId?: string }>();
  const accountId = history.location.state?.accountId;

  const qBookkeepingService = useBookkeepingStatus();
  const qMember = useMemberProfile(userId);
  const isDesktop = useIsDesktop();
  const isFetched = useIsQueriesFetched(qMember, qBookkeepingService);

  if (!isFetched) {
    return (
      <WSElement my="5XL">
        <WSLoader.Spinner />
      </WSElement>
    );
  }

  const taxInfo = qMember.data?.profile.taxInfo;
  const isServiceEnabled = qBookkeepingService.data?.enabled;
  const bookkeepingIsEnabled =
    taxInfo?.stateOfResidence &&
    taxInfo?.filingCode &&
    taxInfo?.incomeEstimateForYear &&
    taxInfo?.expensesEstimateForYear &&
    isServiceEnabled;

  return (
    <Switch>
      {!isDesktop ? (
        <Route
          key="add-transaction"
          path={[
            "/member/bookkeeping/dashboard/summary/add-transaction",
            "/member/bookkeeping/transactions/add-transaction"
          ]}
          component={BookkeepingAddTransactionV2}
          exact
        />
      ) : null}

      {!isDesktop ? (
        <Route
          path={"/member/bookkeeping/transactions/:transactionId"}
          component={BookkeepingTransactionDetailsV2}
          exact
        />
      ) : null}

      <Route
        path="/member/bookkeeping/imports"
        exact
        component={BookkeepingEditAccounts}
      />

      <Route
        path="/member/bookkeeping/imports/:institutionId/business-only"
        exact
        component={BookkeepingEditAccountsBusinessOnly}
      />

      <Route
        path="/member/bookkeeping/imports/csv"
        exact
        render={() => (
          <BookkeepingCSVStart next="/member/bookkeeping/imports/csv/is-business" />
        )}
      />

      <Route
        path="/member/bookkeeping/imports/csv/is-business"
        exact
        render={() => (
          <BookkeepingCSVIsBusiness next="/member/bookkeeping/imports/csv/verify-type" />
        )}
      />

      <Route
        path="/member/bookkeeping/imports/csv/verify-type"
        exact
        render={() => (
          <BookkeepingCSVVerifyType
            next={
              "/member/bookkeeping/transactions" +
              (accountId ? `?accountId%5B0%5D=${accountId}` : "")
            }
          />
        )}
      />

      <Route
        path="/member/bookkeeping/imports/:institutionId/sync"
        exact
        component={BookkeepingEditAccountsSync}
      />

      <Route path="/member/bookkeeping/catch-up" component={CatchUp} />

      <Route
        path="/member/bookkeeping/setup"
        component={BookkeepingOnboardingRootContainer}
      />

      {bookkeepingIsEnabled ? (
        <Route path="/member/bookkeeping" component={BookkeepingIndex} />
      ) : (
        <Redirect from="*" to="/member/bookkeeping/setup" />
      )}

      <Redirect from="*" to="/member/bookkeeping" />
    </Switch>
  );
};
