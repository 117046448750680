import { IIntegrationVendor } from "@wingspanhq/integrations/dist/lib/interfaces/vendor";
import { IIntegrationCustomer } from "@wingspanhq/integrations/dist/lib/interfaces/customer";

export const getIntegrationUserSelectLabel = (
  data: IIntegrationVendor | IIntegrationCustomer
) => {
  const email = (data.primaryEmailAddress?.split(",")[0] || "").trim();

  if (data.displayName?.includes(email)) {
    return data.displayName;
  }

  return `${data.displayName} ${email ? `(${email})` : ""}`;
};
