import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSPanel,
  WSText,
  useIsMobile,
  useWSModal
} from "@wingspanhq/fe-component-library";
import { AccountStatus } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import { useBankingAccount } from "../../../../query/bookkeeping/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import { useQueryPaymentsCardsDebit } from "../../../../query/paymentCards/queries/useQueryPaymentsCardsDebit";
import {
  usePayoutSettings,
  usePayoutSettingsDebitCards
} from "../../../../query/payments/queries";
import { useAccounts, useMemberProfile } from "../../../../query/users/queries";
import { selectorIsDomesticMember } from "../../../../shared/selectors/selectorIsDomesticMember";
import { NotificationBanner } from "../NotificationBanner";
import { DefaultPayoutMehotd } from "./DefaultPayoutMehotd";
import { InfoModal } from "./InfoModal";

type Props = WSElementProps;

export const GetPaid: React.FC<Props> = ({ ...elementProps }) => {
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryBankingAccount = useBankingAccount();
  const queryAccounts = useAccounts();
  const queryPayoutSettingsDebitCards = usePayoutSettingsDebitCards(userId);
  const queryPaymentCards = useQueryPaymentsCardsDebit();
  const queryMember = useMemberProfile(userId);
  const history = useHistory();
  const modalInfo = useWSModal(InfoModal, {
    title: "How do payments on Wingspan work?",
    allowClickOutsideToClose: true
  });
  const isMobile = useIsMobile();

  return (
    <WSElement {...elementProps}>
      <WSText.Heading4 mb="XL">Get paid</WSText.Heading4>

      <NotificationBanner mb="XL" />

      <WSPanel px={isMobile ? "M" : "XL"}>
        <WSFlexBox mb="M" wrap="nowrap" justify="space-between">
          <WSText.Heading5>Payout method</WSText.Heading5>
          <WSButton.Link
            size="S"
            onClick={() => {
              modalInfo.open();
            }}
          >
            Learn more
          </WSButton.Link>
        </WSFlexBox>
        <WSText mb="XL">Default method to get paid</WSText>
        <WSQueries
          queries={{
            queryPayoutSettings,
            queryMember,
            queryAccounts,
            queryPayoutSettingsDebitCards,
            queryPaymentCards
          }}
        >
          {({
            queryPayoutSettings: { data: payoutSettings },
            queryMember: { data: member },
            queryAccounts: { data: accounts },
            queryPayoutSettingsDebitCards: { data: debitCards },
            queryPaymentCards: { data: paymentCards }
          }) => {
            const hasAvailablePayoutMethods =
              accounts.some(
                account =>
                  account.status !== AccountStatus.Inactive &&
                  (account.canBeUsedFor?.payouts || account.canBeUsedFor?.rtp)
              ) ||
              debitCards.length > 0 ||
              paymentCards.length > 0 ||
              !!queryBankingAccount.data;

            const hasPayoutMethod =
              !!payoutSettings.payoutDestinations?.length ||
              !!queryBankingAccount.data;

            return (
              <>
                <WSPanel
                  mb="XL"
                  p={isMobile ? "S" : "M"}
                  data-testid="activePayoutMethod"
                >
                  {hasPayoutMethod ? (
                    <DefaultPayoutMehotd
                      payoutSettings={payoutSettings}
                      internalAccount={queryBankingAccount.data}
                    />
                  ) : (
                    <WSText color="gray500">No payout method selected</WSText>
                  )}
                </WSPanel>
                {hasAvailablePayoutMethods ? (
                  <WSButton.Link
                    icon="edit"
                    onClick={() => {
                      history.push("/member/settings/payment-methods/edit");
                    }}
                  >
                    Edit / add payout methods
                  </WSButton.Link>
                ) : (
                  <WSButton
                    name="addPayoutMethod"
                    fullWidth
                    icon="plus"
                    onClick={() => {
                      if (selectorIsDomesticMember(member)) {
                        history.push("/member/settings/payment-methods/add");
                      } else {
                        history.push(
                          "/member/settings/payment-methods/add-international"
                        );
                      }
                    }}
                  >
                    Add payout method
                  </WSButton>
                )}
              </>
            );
          }}
        </WSQueries>
      </WSPanel>
    </WSElement>
  );
};
