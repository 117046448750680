import { WSElementProps, WSPill } from "@wingspanhq/fe-component-library";
import { PayoutPreferences } from "@wingspanhq/payments/dist/interfaces";
import React from "react";

type Props = { payoutPreference: PayoutPreferences } & WSElementProps;

export const BadgePayoutPreference: React.FC<Props> = ({
  payoutPreference,
  ...elementProps
}) =>
  payoutPreference === PayoutPreferences.Standard ? (
    <WSPill {...elementProps} text="Standard" type="light" icon="in" />
  ) : payoutPreference === PayoutPreferences.Instant ? (
    <WSPill {...elementProps} text="Instant" type="dark" icon="instant" />
  ) : null;
