import {
  CorrectionType,
  IPayerTaxFormResponse,
  TaxFormCorrectionStatus,
  TaxFormEventDriver,
  TaxFormEventType
} from "@wingspanhq/payments/dist/interfaces";

export const selectorPayerTaxFormIsLatestAddressCorrection = (
  taxForm: IPayerTaxFormResponse
) => {
  if (taxForm.corrections?.length) {
    const latestCorrection =
      taxForm.corrections[taxForm.corrections.length - 1];

    return (
      latestCorrection.status ===
        TaxFormCorrectionStatus.AppliedWithoutSubmission &&
      latestCorrection.correctionType === CorrectionType.None &&
      taxForm.eventHistory?.some(
        event =>
          event.correctionId === latestCorrection.taxFormCorrectionId &&
          event.eventType === TaxFormEventType.RecipientUpdatedData &&
          event.triggeredBy === TaxFormEventDriver.Payee
      )
    );
  }

  return false;
};
