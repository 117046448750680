import { IAdditionalDataUpdateRequest } from "@wingspanhq/payments/dist/interfaces";
import { customFieldsService } from "../../../services/customFields";
import { useWSMutation } from "../../helpers";
import { QUERY_CUSTOM_FIELDS_ALL, QUERY_CUSTOM_FIELD } from "../keys";

export const useUpdateCustomField = () =>
  useWSMutation(
    (payload: { id: string; data: IAdditionalDataUpdateRequest }) =>
      customFieldsService.customField.update(payload),
    {
      dependencies: [QUERY_CUSTOM_FIELDS_ALL, QUERY_CUSTOM_FIELD]
    }
  );
