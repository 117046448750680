import { WSText } from "@wingspanhq/fe-component-library";
import { PayoutDestinationType } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { INVOICE_ACCOUNT_NAME } from "../Invoices/utils";
import { WSQueries } from "../query/WSQuery";
import { useUserId } from "../query/hooks/helpers";
import { usePayoutSettings } from "../query/payments/queries";
import { useAccounts } from "../query/users/queries";
import { getPayoutAccount } from "../query/users/selectors";
import { LayoutPaymentsSetup } from "../shared/components/LayoutPaymentsSetup";

export const OnboarderComplete: React.FC = () => {
  const userId = useUserId();
  const qAccounts = useAccounts();
  const qPayoutSettings = usePayoutSettings(userId);

  return (
    <LayoutPaymentsSetup>
      <WSQueries queries={{ qAccounts, qPayoutSettings }}>
        {({
          qAccounts: { data: accounts },
          qPayoutSettings: { data: payoutSettings }
        }) => {
          const payoutAccount = getPayoutAccount(payoutSettings, accounts);

          const giftcardPayoutDestination = payoutSettings.payoutDestinations.find(
            destination =>
              destination.destinationType === PayoutDestinationType.WeGift
          );

          const depositAccountDetailsText =
            payoutAccount && payoutAccount.name === INVOICE_ACCOUNT_NAME
              ? `Your payments will be deposited into your direct deposit (${payoutAccount.numbers.account.slice(
                  -4
                )}) account via direct deposit (ACH).`
              : payoutAccount
              ? `Your payments will be deposited into your ${payoutAccount.name} (${payoutAccount.mask}) account via direct deposit (ACH).`
              : giftcardPayoutDestination
              ? "You will receive payments as a gift card which will be delivered to your email address."
              : "Your payments will be deposited to your Wingspan Wallet.";

          return (
            <>
              <WSText.Heading4 mb="XS" data-testid="heading">
                You’re ready to receive payments
              </WSText.Heading4>

              <WSText mb="3XL">{depositAccountDetailsText}</WSText>
            </>
          );
        }}
      </WSQueries>
    </LayoutPaymentsSetup>
  );
};
