import { useUserProfileData } from "./utils/useUserProfileData";
import { isUserIdsIncluded } from "./utils/isUserIdsIncluded";
import { HIDE_MEMBERSHIP_SETTINGS } from "./helpers";

export const useShowMembershipSettings = () => {
  const userData = useUserProfileData();
  const showMembershipSettings = !isUserIdsIncluded(
    userData,
    HIDE_MEMBERSHIP_SETTINGS
  );

  return showMembershipSettings;
};
