import cn from "classnames";
import React, { useMemo } from "react";

import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSText, WSTextProps } from "../../core/WSText/WSText.component";
import {
  IconType,
  WSPill,
  WSPillExtraTheme,
  WSPillProps,
  WSPillStatusTheme,
  WSPillTheme,
  WSPillType
} from "../WSPill/WSPill.component";
import styles from "./WSChip.component.module.scss";

export type WSChipTheme = WSPillStatusTheme | WSPillExtraTheme;

const RIGHT_TEXT_DEFAULT_PROPS: WSTextProps = {
  kind: "ParagraphXs",
  color: "gray500",
  weight: "medium",
  formatMoney: true
};

interface WSChipPropsBase
  extends Omit<WSElementProps, "color" | "colorBackground"> {
  pillText: string;
}

interface RightTextProps {
  count?: number;
  rightText: string | number;
  rightTextProps?: WSTextProps;
  leftText?: never;
}

interface LeftTextProps {
  leftText?: string;
  count?: never;
  rightText?: never;
  rightTextProps?: never;
}

type WSChipCommonThemePropsIconType<
  T extends WSPillTheme = WSPillTheme
> = WSChipPropsBase & {
  theme?: T;
  pillType?: WSPillType;
  icon?: IconType<T>;
  active?: boolean;
} & (RightTextProps | LeftTextProps);

export type WSChipProps = WSChipCommonThemePropsIconType;

export const WSChip = React.forwardRef<HTMLLabelElement, WSChipProps>(
  function WSChip(
    {
      theme,
      pillType,
      icon,
      active,
      pillText: pillTextBase,
      count,
      leftText,
      rightText,
      rightTextProps,
      className,
      ...otherProps
    },
    ref
  ) {
    const pillText = useMemo(
      () => (count ? `${pillTextBase} (${count})` : pillTextBase),
      [count, pillTextBase]
    );

    const pillProps: WSPillProps = useMemo(() => {
      const baseProps = {
        text: pillText,
        icon
      };

      return {
        ...baseProps,
        theme,
        type: pillType
      };
    }, [pillType, pillText, icon, theme]);

    return (
      <WSElement
        as="label"
        ref={ref}
        className={cn(
          styles.chip,
          className,
          active && styles.active,
          !!otherProps.onClick && styles.clickable
        )}
        {...otherProps}
      >
        {leftText && !rightText && (
          <WSText.ParagraphXs
            color="gray500"
            weight="book"
            singleLine
            className={styles.text}
          >
            {leftText}
          </WSText.ParagraphXs>
        )}
        <WSPill {...pillProps} />
        {rightText !== undefined && (
          <WSText.ParagraphXs
            weight="medium"
            singleLine
            className={styles.text}
            {...(rightTextProps || RIGHT_TEXT_DEFAULT_PROPS)}
          >
            {rightText}
          </WSText.ParagraphXs>
        )}
      </WSElement>
    );
  }
);
