import { IExternalDestination } from "../../../services/api/payments/payouts/types";

export const getAccountDetailsName = (
  destination: IExternalDestination
): string => {
  const { institutionName, name, mask } = destination;

  let details = `${institutionName || ""} ${name || ""}`.trim();
  if (mask) {
    details += ` (...${mask})`;
  }

  return details;
};
