import {
  IRequirementDefinitionCreateRequest,
  IRequirementDefinitionResponse
} from "@wingspanhq/payments/dist/interfaces";
import { servicePayments } from "../servicePayments";

export const createRequirement = async (
  payload: IRequirementDefinitionCreateRequest
): Promise<IRequirementDefinitionResponse> => {
  const response = await servicePayments.post(`/requirement`, payload);
  return response.data;
};
