import React, { useMemo } from "react";
import { WSElement } from "../WSElement/WSElement.component";
import { WSBadge } from "../common";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import { WSLoader } from "../core/WSLoader/WSLoader.component";
import { WSText } from "../core/WSText/WSText.component";
import { FilterProps } from "./Filter";
import styles from "./WSFilters.module.scss";
import { WSFilterMultiValue } from "./types";

export const FilterHeader: React.FC<FilterProps> = (props) => {
  const {
    type,
    title,
    nonExpandable,
    nonCountable,
    loading,
    value,
    expanded,
    onExpandedChange
  } = props;

  const selectedCount = useMemo(() => {
    if (nonCountable) {
      return 0;
    }

    if (type === "checkbox") {
      return (value as WSFilterMultiValue)?.length;
    } else if (!!value) {
      return 1;
    }

    return 0;
  }, [nonCountable, type, value]);

  if (!title) {
    return null;
  }

  return (
    <WSElement
      className={styles.filterHeader}
      onClick={loading || nonExpandable ? undefined : onExpandedChange}
    >
      {loading ? (
        <WSLoader.Spinner size="XS" />
      ) : selectedCount ? (
        <WSBadge theme="dark" size="M" number={selectedCount} />
      ) : null}

      <WSText
        kind={nonExpandable ? "Caption" : "ParagraphSm"}
        weight="medium"
        className={styles.filterTitle}
      >
        {title}
      </WSText>

      {!nonExpandable ? (
        <WSIcon
          name={expanded ? "caret-up" : "caret-down"}
          size="S"
          color="gray500"
        />
      ) : null}
    </WSElement>
  );
};
