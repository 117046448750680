import { useUserProfileData } from "./utils/useUserProfileData";
import { isUserIdsIncluded } from "./utils/isUserIdsIncluded";
import { SHOW_PAYMENT_NAV_IDS } from "./helpers";

export const useShowPaymentNav = () => {
  const userData = useUserProfileData();

  const showPaymentNav = isUserIdsIncluded(userData, SHOW_PAYMENT_NAV_IDS);

  return showPaymentNav;
};
