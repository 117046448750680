import { useRouteMatch } from "react-router-dom";
import { FlowAddPayrollFundingMethod } from "../components/FlowAddPayrollFundingMethod";
import { OnboardingStep } from "../types";

export const PayrollFundingMethod: React.FC<OnboardingStep> = ({ onNext }) => {
  const match = useRouteMatch();

  return (
    <FlowAddPayrollFundingMethod basePath={match.path} onSuccess={onNext} />
  );
};
