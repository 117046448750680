import {
  ICardResponse,
  IPaymentCardLimitType
} from "../../../../services/api/banking/cards/types";
import {
  toWSMoneyString,
  WSFlexBox,
  WSPanel,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useBankingBalance } from "../../../../query/bookkeeping/queries";
import { useCardLimits } from "../../../../query/cards/queries/useCardLimits";

export const CardBalance: React.FC<{ card: ICardResponse }> = ({ card }) => {
  const queryBankingBalance = useBankingBalance();
  const queryCardLimits = useCardLimits(card.cardId);

  const availableBalance = queryBankingBalance.data?.available || 0;

  const limitType = queryCardLimits.data?.limitType || "Monthly";
  const limit = (queryCardLimits.data?.limitInCents || 0) / 100;
  const availableLimit =
    (queryCardLimits.data?.availableLimitInCents || 0) / 100;
  const spendLimit = limit - availableLimit;

  const limitTexts: Record<IPaymentCardLimitType, string> = {
    Monthly: `of ${toWSMoneyString(availableLimit)} daily limit`,
    Forever: `of ${toWSMoneyString(availableLimit)} limit`,
    Annually: `of ${toWSMoneyString(availableLimit)} annual limit`,
    Transaction: `of ${toWSMoneyString(availableLimit)} transaction limit`
  };

  return (
    <WSPanel>
      <WSText weight="medium" color="gray600">
        Available balance
      </WSText>

      <WSText.Heading1
        weight="book"
        color="gray500"
        mb="XL"
        shimmer={!queryBankingBalance.isSuccess}
      >
        {toWSMoneyString(availableBalance)}
      </WSText.Heading1>

      <WSFlexBox.CenterY
        justify="space-between"
        mb="M"
        shimmer={!queryCardLimits.isSuccess}
      >
        <WSText.ParagraphSm color="gray500">Spend limits</WSText.ParagraphSm>

        <WSFlexBox.CenterY gap="S">
          <WSText.ParagraphSm
            color="gray600"
            shimmer={!queryBankingBalance.isSuccess}
          >
            {toWSMoneyString(spendLimit)}
          </WSText.ParagraphSm>
          <WSPill theme="neutral" text={limitTexts[limitType]} />
        </WSFlexBox.CenterY>
      </WSFlexBox.CenterY>
    </WSPanel>
  );
};
