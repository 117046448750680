import { QueryResult } from "react-query";
import { ISessionResponse } from "@wingspanhq/users/dist/lib/interfaces";
import { WSServiceError } from "../../utils/serviceHelper";
import { useEffect, useState } from "react";
import { useCustomizationQuery } from "../../query/customization/queries/useCustomizationQuery";
import "./styles.scss";

export const useInitCustomization = (
  querySession: QueryResult<ISessionResponse, WSServiceError>
) => {
  const [isLoading, setIsLoading] = useState(true);
  const isReadyToFetch = !!querySession.data?.userId;
  const isSessionFetched = querySession.isFetched;

  const queryCustomization = useCustomizationQuery({
    enabled: isReadyToFetch,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    forceFetchOnMount: false,
    onSuccess(data) {
      if (data.support?.portal?.generalUrl) {
        window.document.body.setAttribute("data-no-intercom", "true");
      }
    }
  });

  const isCustomizationFetched = queryCustomization.isFetched;

  useEffect(() => {
    if (isSessionFetched && isReadyToFetch && isCustomizationFetched) {
      setIsLoading(false);
    } else if (isSessionFetched && !isReadyToFetch) {
      setIsLoading(false);
    }
  }, [isSessionFetched, isCustomizationFetched, isReadyToFetch]);

  return {
    isLoading: isLoading
  };
};
