import cn from "classnames";
import React from "react";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import styles from "./WSContainer.module.scss";

export interface WSContainerProps extends WSElementProps {
  verticalPadding?: boolean;
}

export const WSContainer: React.FC<WSContainerProps> = ({
  className,
  children,
  verticalPadding,
  ...elementProps
}) => (
  <WSElement
    className={cn(
      styles.base,
      { [styles.verticalPadding]: verticalPadding },
      className
    )}
    {...elementProps}
  >
    {children}
  </WSElement>
);
