import {
  WSElement,
  WSIcon,
  useIsMobile
} from "@wingspanhq/fe-component-library";
import React from "react";
import {
  useCMSWebflowEnterprise,
  useCMSWebflowPartner
} from "../../../query/cms/queries";
import { useUserId } from "../../../query/hooks/helpers";
import { useActivities, useUserProfile } from "../../../query/users/queries";
import { useWSStore } from "../../../store";
import { selectorEnterpriseId } from "../../selectors/selectorEnterpriseId";
import { selectorPartnerId } from "../../selectors/selectorPartnerId";
import styles from "./index.module.scss";
import { selectorPartnerLogoData } from "./selectorPartnerLogoData";

type Props = {
  customLogo?: string;
};

export const LogoPartner: React.FC<Props> = ({ customLogo }) => {
  const userId = useUserId();
  const store = useWSStore();
  const activityQuery = useActivities(userId);
  const userQuery = useUserProfile(userId);
  const enterpriseId = selectorEnterpriseId(
    store,
    activityQuery.data,
    userQuery.data
  );
  const isMobile = useIsMobile();
  const partnerId = selectorPartnerId(store, activityQuery.data);
  const queryEnterprise = useCMSWebflowEnterprise(enterpriseId || "", {
    enabled: !!enterpriseId
  });
  const queryPartner = useCMSWebflowPartner(partnerId || "", {
    enabled: !!partnerId
  });

  const logoData = selectorPartnerLogoData(
    customLogo,
    queryEnterprise.data,
    queryPartner.data
  );

  return logoData ? (
    <>
      <WSIcon
        block
        name="exit"
        mx={isMobile ? "M" : "XL"}
        size="XS"
        color="gray700"
      />
      <WSElement className={styles.partnerLogo}>
        <img src={logoData.src} alt={logoData.alt} />
      </WSElement>
    </>
  ) : null;
};
