import { IInvoicingConfig } from "@wingspanhq/payments/dist/interfaces";
export function getInvoicingConfigFormDefaultValues(
  invoicingConfig: IInvoicingConfig
) {
  return {
    additionalGuidelines: invoicingConfig.additionalGuidelines.enabled,
    additionalGuidelinesValue: invoicingConfig.additionalGuidelines.value,
    allowOnlyPredefinedLineItems:
      invoicingConfig.allowOnlyPredefinedLineItems.enabled,
    allowOnlyPredefinedLineItemsValue:
      invoicingConfig.allowOnlyPredefinedLineItems.value ?? [],
    allowLineItemDiscounts: invoicingConfig.allowLineItemDiscounts.enabled,
    allowLineItemReimbursableExpenses:
      invoicingConfig.allowLineItemReimbursableExpenses.enabled,
    allowLateFees: invoicingConfig.allowLateFees.enabled,
    allowProcessingFeePercentage:
      invoicingConfig.allowProcessingFeePercentage.enabled,
    allowRecurringInvoices: invoicingConfig.allowRecurringInvoices.enabled,
    dueInDays: invoicingConfig.dueInDays.enabled,
    dueInDaysValue: invoicingConfig.dueInDays.value,
    allowCollaboratorSplits: invoicingConfig.allowCollaboratorSplits.enabled,
    requireAttachments: invoicingConfig.requireAttachments.enabled,
    requireProjectName: invoicingConfig.requireProjectName.enabled,
    requirePONumber: invoicingConfig.requirePONumber.enabled,
    allowPaymentReminders: invoicingConfig.allowPaymentReminders.enabled
  };
}
