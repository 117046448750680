import { IInternalAccount } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  WSElement,
  WSElementProps,
  WSFlexBox
} from "@wingspanhq/fe-component-library";
import {
  IPayoutSettingsResponse,
  PayoutDestinationType
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { MethodWallet } from "../../../../shared/components/MethodWallet";
import { selectorDefaultDestination } from "../../../../shared/selectors/selectorDefaultDestination";
import { BadgePayoutPreference } from "../BadgePayoutPreference";
import { BankAccount } from "../BankAccount";
import { ContainerAccount } from "../ContainerAccount";
import { ContainerDebitCard } from "../ContainerDebitCard";
import { DebitCard } from "../DebitCard";

type Props = {
  payoutSettings: IPayoutSettingsResponse;
  internalAccount?: IInternalAccount;
} & WSElementProps;

export const DefaultPayoutMehotd: React.FC<Props> = ({
  payoutSettings,
  internalAccount,
  ...elementProps
}) => {
  const destination = selectorDefaultDestination(payoutSettings);

  return (
    <WSFlexBox
      wrap="nowrap"
      justify="space-between"
      alignItems="center"
      {...elementProps}
    >
      <WSElement>
        {destination && destination.destinationId ? (
          destination.destinationType === PayoutDestinationType.Account ? (
            <ContainerAccount accountId={destination.destinationId}>
              {account => <BankAccount account={account} />}
            </ContainerAccount>
          ) : destination.destinationType === PayoutDestinationType.Card ? (
            <ContainerDebitCard cardId={destination.destinationId}>
              {debitCard => <DebitCard debitCard={debitCard} />}
            </ContainerDebitCard>
          ) : null
        ) : internalAccount ? (
          <MethodWallet />
        ) : null}
      </WSElement>
      <BadgePayoutPreference
        payoutPreference={payoutSettings.payoutPreferences}
      />
    </WSFlexBox>
  );
};
