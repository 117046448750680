import {
  useWSModal,
  useWSSnackbar,
  WSActions,
  WSDivider,
  WSForm,
  WSInputText,
  WSInputTextarea,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import {
  IEngagementResponse,
  IEngagementUpdateRequest
} from "@wingspanhq/payments/dist/interfaces";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { useUpdateEngagement } from "../../../../query/engagements/mutations/useUpdateEngagement";

type Props = {
  engagement: IEngagementResponse;
  onClose: () => void;
};

const EditEngagementModal: React.FC<Props> = ({ engagement, onClose }) => {
  const { openSnackbar } = useWSSnackbar();
  const [updateEngagement, updateEngagementMeta] = useUpdateEngagement();
  const onSubmit = async (data: IEngagementUpdateRequest) => {
    await updateEngagement(
      {
        ...data,
        engagementId: engagement.engagementId
      },
      {
        onSuccess: () => {
          openSnackbar({
            message: "Engagement updated successfully",
            type: "success"
          });
          onClose();
        }
      }
    );
  };
  return (
    <>
      {" "}
      <WSText.Paragraph weight="medium" mb="S">
        Engagement information
      </WSText.Paragraph>
      <WSForm<IEngagementUpdateRequest>
        defaultValues={{
          name: engagement.name,
          description: engagement.description
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
          description: Yup.string()
        })}
        onSubmit={onSubmit}
      >
        <WSList gap="M">
          <WSForm.Field
            name="name"
            label="Engagement name"
            placeholder="Engagement name"
            component={WSInputText}
            componentProps={{
              required: true
            }}
          />
          <WSForm.Field
            name="description"
            label="Description"
            placeholder="Engagement description"
            component={WSInputTextarea}
          />
          <WSErrorMessage
            contextKey={ErrorContextKey.UpdateEngagement}
            error={updateEngagementMeta.error}
          />

          <WSDivider />

          <WSActions
            orientation="horizontal"
            alignment="fill"
            buttons={[
              {
                label: "Cancel",
                onClick: onClose,
                kind: "Tertiary",
                type: "button"
              },
              {
                label: "Update",
                kind: "Primary",
                type: "submit"
              }
            ]}
          />

          <WSText.ParagraphXs weight="book" color="gray400">
            Once the engagement has been created, you can add requirements,
            contractors, and team members to the engagement.
          </WSText.ParagraphXs>
        </WSList>
      </WSForm>
    </>
  );
};

export function useEditEngagementModal() {
  return useWSModal(EditEngagementModal, {
    title: "Edit engagement",
    size: "S"
  });
}
