import { useIntegrationsQuickbooks } from "../../../query/integrations/queries";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { isQuickbooksServiceActive } from "../../../query/integrations/selectors";
import React, { useState } from "react";
import { WSQueries } from "../../../query/WSQuery";

export const QuickbooksWrapper: React.FC = props => {
  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qFeatureFlags = useFeatureFlags();
  const [render, setRender] = useState(false);

  return (
    <WSQueries
      queries={{
        qIntegrationsQuickbooks,
        qFeatureFlags
      }}
      onSuccess={() => {
        setTimeout(() => setRender(true), 100);
      }}
      renderErrors={() => null}
      renderLoader={() => null}
    >
      {({ qIntegrationsQuickbooks, qFeatureFlags }) => {
        const isQuickbooksActive =
          qFeatureFlags.data.qboOnboarding &&
          (qFeatureFlags.data?.forceShowQBO ||
            isQuickbooksServiceActive(qIntegrationsQuickbooks.data));

        if (render && isQuickbooksActive) {
          return <>{props.children}</>;
        }

        return null;
      }}
    </WSQueries>
  );
};
