import { WSPanel, WSSidebar } from "@wingspanhq/fe-component-library";
import React from "react";

export type DrawerLayoutProps = {
  title: string;
  onBack: () => void;
};

export const DrawerLayout: React.FC<DrawerLayoutProps> = ({
  title,
  onBack,
  children
}) => {
  return (
    <WSSidebar onClose={onBack} header={title}>
      <WSPanel noBorder>{children}</WSPanel>
    </WSSidebar>
  );
};
