import {
  WSContainer,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSCentered,
  WSIcon,
  WSInputMaskOld,
  WSLayout,
  WSSelectOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { CertifiedSecureDigitalPaymentsTooltip } from "../../components/CertifiedSecureDigitalPaymentsTooltip/CertifiedSecureDigitalPaymentsTooltip";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { US_STATES_OPTIONS } from "../../constants/user";
import { WSQueries } from "../../query/WSQuery";
import { useUpdateBenefitsService } from "../../query/benefits";
import { useUserId } from "../../query/hooks/helpers";
import {
  useUpdateActivity,
  useUpdateMemberProfile
} from "../../query/users/mutations";
import { useMemberProfile, useUserProfile } from "../../query/users/queries";
import { validatorEmail } from "../../shared/validators/validatorEmail";
import { useSetWSStore } from "../../store";
import {
  validatorAddressLine1,
  validatorAddressLine2,
  validatorZipCode
} from "../../utils/validators";
import { BenefitsLogoHeader } from "../components/BenefitsLogoHeader/BenefitsLogoHeader.module";
import styles from "./Benefits.module.scss";

export const BenefitsSetUpStep3: React.FC = () => {
  const history = useHistory();

  const userId = useUserId();
  const qUserProfile = useUserProfile(userId);
  const qMemberProfile = useMemberProfile(userId);

  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);
  const [
    updateMemberProfile,
    updateMemberProfileMeta
  ] = useUpdateMemberProfile({ throwOnError: true });

  const [
    updateBenefitsService,
    updateBenefitsServiceMeta
  ] = useUpdateBenefitsService({ throwOnError: true });
  const setStore = useSetWSStore();

  useBrowserPageTitle("Benefits - Confirm your address");

  return (
    <WSLayout
      headerLeft={
        <WSFlexBox.CenterY
          onClick={() => history.push("/member/benefits/set-up/2")}
        >
          <WSIcon block name="chevron-left" size="XS" color="gray500" mr="XS" />
          <WSText.ParagraphSm pl="XS" color="gray500">
            Back
          </WSText.ParagraphSm>
        </WSFlexBox.CenterY>
      }
      headerCenter={<BenefitsLogoHeader />}
    >
      <WSFlexBox.Center className={styles.subHeaderInfo} wrap="nowrap">
        <WSText>
          Wingspan is HIPAA Compliant.
          <CertifiedSecureDigitalPaymentsTooltip ml="XS" />
        </WSText>
      </WSFlexBox.Center>

      <WSContainer verticalPadding>
        <WSQueries
          queries={{ userData: qUserProfile, memberData: qMemberProfile }}
        >
          {({ userData, memberData }) => {
            const user = userData.data;
            const member = memberData.data;

            return (
              <WSCentered span={{ m: "6" }}>
                <WSText.ParagraphSm color="gray500" mt="M" pt="M">
                  2 of 2
                </WSText.ParagraphSm>
                <WSText.Heading4>Confirm your address</WSText.Heading4>
                <WSText.ParagraphSm mt="XL">
                  We need to confirm your information to enroll in Premium Perks
                  and to ship you your membership card and packet. You will
                  receive your membership packet within a week.
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="XL">
                  Your information is{" "}
                  <a
                    href="https://www.wingspan.app/security"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Certified Secure
                  </a>
                  .
                </WSText.ParagraphSm>

                <WSFormOld
                  onSubmit={async formData => {
                    await updateMemberProfile({
                      memberId: userId,
                      profile: {
                        address: {
                          country: "US",
                          addressLine1: formData.addressLine1,
                          addressLine2: formData.addressLine2,
                          city: formData.city,
                          postalCode: formData.zipCode,
                          state: formData.state
                        }
                      }
                    });

                    await updateBenefitsService({
                      enabled: true,
                      userId
                    });
                    setStore({ productActivationAfterOnboarding: false });

                    await updateActivity({
                      flows: {
                        benefitsSetup: {
                          version: 2,
                          totalSteps: 4,
                          currentStep: 3,
                          complete: false
                        }
                      }
                    });

                    history.push("/member/benefits/set-up/celebration");
                  }}
                  defaultValues={{
                    email: user.email,
                    addressLine1: member.profile.address?.addressLine1 || "",
                    addressLine2: member.profile.address?.addressLine2 || "",
                    city: member.profile.address?.city || "",
                    state: member.profile.address?.state || "",
                    zipCode: member.profile.address?.postalCode || ""
                  }}
                  validationSchema={Yup.object().shape({
                    email: validatorEmail.required("Email is required"),
                    addressLine1: validatorAddressLine1,
                    addressLine2: validatorAddressLine2,
                    city: Yup.string().required("City is required"),
                    state: Yup.string().required("Required"),
                    zipCode: validatorZipCode
                  })}
                >
                  <WSFormOld.Field
                    mt="XL"
                    name="addressLine1"
                    component={WSTextInput}
                    label="Home street address"
                  />

                  <WSFormOld.Field
                    mt="XL"
                    name="addressLine2"
                    component={WSTextInput}
                    placeholder="Apartment, suite, building, etc. (optional)"
                  />

                  <WSGrid>
                    <WSGrid.Item span={{ m: "6" }}>
                      <WSFormOld.Field
                        mt="XL"
                        name="city"
                        component={WSTextInput}
                        label="City"
                      />
                    </WSGrid.Item>
                    <WSGrid.Item span={{ s: "6", m: "3" }}>
                      <WSFormOld.Field
                        mt="XL"
                        name="state"
                        component={WSSelectOld}
                        componentProps={{
                          options: US_STATES_OPTIONS,
                          placeholder: "Select",
                          searchable: true
                        }}
                        label="State"
                      />
                    </WSGrid.Item>
                    <WSGrid.Item span={{ s: "6", m: "3" }}>
                      <WSFormOld.Field
                        mt="XL"
                        name="zipCode"
                        component={WSInputMaskOld}
                        componentProps={{
                          mask: "99999"
                        }}
                        label="Zip code"
                      />
                    </WSGrid.Item>
                  </WSGrid>

                  <WSFormOld.Field
                    mt="XL"
                    name="email"
                    componentProps={{
                      disabled: true
                    }}
                    component={WSTextInput}
                    label="Email address"
                  />

                  <WSErrorMessage
                    mt="XL"
                    contextKey="BenefitsSetUp"
                    error={
                      updateMemberProfileMeta.error ||
                      updateActivityMeta.error ||
                      updateBenefitsServiceMeta.error
                    }
                  />

                  <WSFormOld.SubmitButton name="getBenefit" mt="2XL" fullWidth>
                    Enroll in Premium Perks
                  </WSFormOld.SubmitButton>
                </WSFormOld>
              </WSCentered>
            );
          }}
        </WSQueries>
      </WSContainer>
    </WSLayout>
  );
};
