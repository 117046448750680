import { Redirect, useHistory } from "react-router-dom";
import { useUserId } from "../../../query/hooks/helpers";
import { useActivities } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { getRedirectPath } from "./PayerSetupFlow/utils/getRedirectPath";

export const RouteInit: React.FC = () => {
  const history = useHistory();
  const userId = useUserId();
  const queryActivity = useActivities(userId, {
    onError: () => {
      history.push("/member/1099-filing/preview");
    }
  });

  return (
    <WSQueries queries={{ queryActivity }}>
      {({ queryActivityData }) => {
        return <Redirect to={getRedirectPath(queryActivityData)} />;
      }}
    </WSQueries>
  );
};
