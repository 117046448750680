import React from "react";
import styles from "./WSCheckbox.module.scss";
import {
  detachLayoutProps,
  WSElement,
  WSElementProps
} from "../../WSElement/WSElement.component";
import { WSText } from "../WSText/WSText.component";
import { toCamelCase } from "../../../../utils";
import classNames from "classnames";

export interface WSCheckboxProps extends WSElementProps<HTMLInputElement> {
  value: any;
  name: string;
  label?: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
}

export const WSCheckbox = React.forwardRef<HTMLInputElement, WSCheckboxProps>(
  (props, ref) => {
    const {
      className,
      label,
      value,
      name,
      checked,
      disabled,
      layoutProps,
      systemProps,
      ...inputProps
    } = detachLayoutProps(props);
    return (
      <WSElement
        // capture the click event here to keep it from propagating
        onClick={() => {}}
        {...systemProps}
        className={classNames(styles.checkboxItem, className, {
          [styles.disabled]: disabled
        })}
        data-testid={toCamelCase(name, "field", value, "option")}
        {...layoutProps}
        as="label"
      >
        <input
          type="checkbox"
          value={value}
          name={name}
          ref={ref}
          checked={checked}
          disabled={disabled}
          {...inputProps}
        />
        <WSElement as="i" className={styles.checkbox} />
        {typeof label === "string" ? (
          <WSText.ParagraphSm>{label}</WSText.ParagraphSm>
        ) : (
          label
        )}
      </WSElement>
    );
  }
);
