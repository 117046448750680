import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { Form } from "./Form";
import { useMutationSubmit } from "./useMutationSubmit";

export type FormManuallyAddAccountProps = {
  rtpRequired?: boolean;
  onBack?: () => void;
  onSuccess?: (account: IAccount) => void;
  submitButtonText?: string;
  setStandardPayoutDestination?: boolean;
  setInstantPayoutDestination?: boolean;
  setFundingSource?: boolean;
  requestVerification?: boolean;
  withPanel?: boolean;
};

export const FormManuallyAddAccount: React.FC<FormManuallyAddAccountProps> = ({
  onSuccess,
  onBack,
  submitButtonText,
  rtpRequired,
  setStandardPayoutDestination,
  setInstantPayoutDestination,
  setFundingSource,
  requestVerification,
  withPanel
}) => {
  const [submit, submitMeta] = useMutationSubmit(
    {
      setStandardPayoutDestination,
      setInstantPayoutDestination,
      requestVerification,
      setFundingSource
    },
    { onSuccess }
  );

  return (
    <Form
      rtpRequired={rtpRequired}
      onSubmit={submit}
      submitButtonText={submitButtonText}
      error={submitMeta.error}
      isLoading={submitMeta.isLoading}
      withPanel={withPanel}
      onBack={onBack}
    />
  );
};
