import { KEY_REMEMBER_DEFAULT_SELECTED_ORGANIZATION_ACCOUNT } from "../constants/storage";
import { useUserId } from "../../../query/hooks/helpers";
import { useMemo, useState } from "react";

const getStoredEmail = (userId: string) => {
  let email: string | undefined = undefined;

  try {
    let storageData: string | null = "";

    storageData = window.localStorage.getItem(
      KEY_REMEMBER_DEFAULT_SELECTED_ORGANIZATION_ACCOUNT
    );

    const parsedData = JSON.parse(storageData || "");
    email = parsedData[userId];
  } catch (e) {}

  return email;
};

export const useStoredDefaultSelectedAccount = () => {
  const userId = useUserId();

  const [email, setEmail] = useState(() => getStoredEmail(userId));

  const setEmailWithStorage = useMemo(() => {
    return (email: string | undefined) => {
      let data: Record<string, string | undefined> = {};
      try {
        const stringData = window.localStorage.getItem(
          KEY_REMEMBER_DEFAULT_SELECTED_ORGANIZATION_ACCOUNT
        );
        data = JSON.parse(stringData || "") || {};
      } catch (e) {}

      data[userId] = email;

      window.localStorage.setItem(
        KEY_REMEMBER_DEFAULT_SELECTED_ORGANIZATION_ACCOUNT,
        JSON.stringify(data)
      );
      setEmail(email);
    };
  }, [setEmail]);

  return [email, setEmailWithStorage] as const;
};
