import { useMemo, useState } from "react";
import { KEY_SELECTED_ORGANIZATION_ACCOUNTS } from "../constants/storage";
import { useUserId } from "../../../query/hooks/helpers";

const getStoredEmail = (userId: string) => {
  let email: string | undefined = undefined;

  try {
    let storageData: string | null = "";

    storageData = window.sessionStorage.getItem(
      KEY_SELECTED_ORGANIZATION_ACCOUNTS
    );

    const parsedData = JSON.parse(storageData || "");
    email = parsedData[userId];
  } catch (e) {}

  return email;
};

export const useStoredSelectedAccountEmail = () => {
  const userId = useUserId();

  const [email, setEmail] = useState(() => getStoredEmail(userId));

  const setEmailWithStorage = useMemo(() => {
    return (email: string | undefined) => {
      let data: Record<string, string | undefined> = {};
      try {
        const stringData = window.sessionStorage.getItem(
          KEY_SELECTED_ORGANIZATION_ACCOUNTS
        );
        data = JSON.parse(stringData || "") || {};
      } catch (e) {}
      data[userId] = email;

      window.sessionStorage.setItem(
        KEY_SELECTED_ORGANIZATION_ACCOUNTS,
        JSON.stringify(data)
      );
      setEmail(email);
    };
  }, [setEmail]);

  return [email, setEmailWithStorage] as const;
};
