export const BUILD_ENV = process.env.REACT_APP_ENV;

// Intentionally not using BUILD_ENV and using process.env.REACT_APP_ENV directly, so webpack (terser plugin) can find this condition and remove development-related code in produciton builds
export const IS_PRODUCTION_ENV = process.env.REACT_APP_ENV === "production";

export const IS_DEV_ENV = !IS_PRODUCTION_ENV;

export const IS_LOCAL_ENV = BUILD_ENV === "local";

export const IS_HOSTED_ENV =
  BUILD_ENV === "staging" || BUILD_ENV === "dev" || BUILD_ENV === "production";
