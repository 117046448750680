import { yupResolver } from "@hookform/resolvers";
import cn from "classnames";
import React, { ReactElement } from "react";
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  UnpackNestedValue,
  useForm,
  UseFormMethods
} from "react-hook-form";
import { deepTrim } from "../../../../utils/deepTrim";
import {
  detachLayoutProps,
  WSElement,
  WSElementProps
} from "../../WSElement/WSElement.component";
import styles from "./WSForm.module.scss";
import { WSFormContext } from "./WSFormContext.component";
import { WSFormContextView } from "./WSFormContextView.component";
import { WSFormDebug } from "./WSFormDebug.component";
import { WSFormError } from "./WSFormError.component";
import { WSFormField } from "./WSFormField.component";
import { WSFormInput } from "./WSFormInput.component";
import { WSFormLabel } from "./WSFormLabel.component";
import { WSFormSubmitButton } from "./WSFormSubmitButton.component";
import { WSFormValue } from "./WSFormValue.component";
import { WSFormValues } from "./WSFormValues.component";

export type WSFormPropsOld<
  TFieldValues extends FieldValues = FieldValues
> = Omit<WSElementProps, "onSubmit"> & {
  onSubmit?: (data: UnpackNestedValue<TFieldValues>) => void;
  defaultValues?: UnpackNestedValue<DeepPartial<TFieldValues>>;
  validationSchema?: Parameters<typeof yupResolver>[0];
  children:
    | ((context: UseFormMethods<TFieldValues>) => React.ReactNode)
    | React.ReactNode;
};

export type WSFormComponentOld = (<
  TFieldValues extends FieldValues = FieldValues
>(
  props: WSFormPropsOld<TFieldValues>
) => ReactElement<any, any> | null) & {
  Error: typeof WSFormError;
  Field: typeof WSFormField;
  Input: typeof WSFormInput;
  Label: typeof WSFormLabel;
  Context: typeof WSFormContext;
  ContextView: typeof WSFormContextView;
  Value: typeof WSFormValue;
  Values: typeof WSFormValues;
  SubmitButton: typeof WSFormSubmitButton;
  Debug: typeof WSFormDebug;
};

export const WSFormOld: WSFormComponentOld = ({
  children,
  className,
  onSubmit,
  validationSchema,
  defaultValues,
  ...otherProps
}) => {
  const classNames = cn(styles.form, className);
  const methods = useForm({
    defaultValues,
    resolver: (values) => {
      const trimmedValues = deepTrim(values);

      return validationSchema
        ? yupResolver(validationSchema)(trimmedValues)
        : ({
            values: trimmedValues,
            errors: {}
          } as any);
    }
  });
  const { layoutProps, systemProps, ...formProps } = detachLayoutProps(
    otherProps
  );

  return (
    <FormProvider {...methods}>
      <WSElement
        as="form"
        className={classNames}
        onSubmit={async (event) => {
          event.stopPropagation();

          if (onSubmit) {
            await methods.handleSubmit(onSubmit)(event);

            const form = event.target as HTMLElement;
            const element = form.querySelector("[data-error]") as HTMLElement;
            if (element) {
              setTimeout(() => {
                element?.focus();
              }, 200);

              element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
          }
        }}
        {...layoutProps}
        {...formProps}
      >
        {children instanceof Function ? children(methods) : children}
      </WSElement>
    </FormProvider>
  );
};

WSFormOld.Error = WSFormError;
WSFormOld.Field = WSFormField;
WSFormOld.Input = WSFormInput;
WSFormOld.Label = WSFormLabel;
WSFormOld.Context = WSFormContext;
WSFormOld.ContextView = WSFormContextView;
WSFormOld.Value = WSFormValue;
WSFormOld.Values = WSFormValues;
WSFormOld.SubmitButton = WSFormSubmitButton;
WSFormOld.Debug = WSFormDebug;
