import { WSText, toWSMoneyString } from "@wingspanhq/fe-component-library";
import { formatNumber } from "accounting";
import { RowData, ListValueType } from "../../../../components/LabelValueList";
import { ILabelInfoValueOptions } from "../../types";
import { ICollaboratorBatchImportSummary } from "../../services/types";
import { isNil } from "../../../../utils";
import camelCase from "lodash/camelCase";

export function getCollaboratorBatchSummaryItems(
  bulkBatchSummary?: ICollaboratorBatchImportSummary,
  options?: ILabelInfoValueOptions
): RowData[] {
  if (isNil(bulkBatchSummary)) {
    return [];
  }
  return [
    {
      name: "totalContractors",
      label: "Total contractors",
      value: bulkBatchSummary.collaboratorsCount,
      type: ListValueType.Number
    }
  ].map(row => {
    return {
      id: row.name,
      name: row.name,
      type: ListValueType.Text,
      value: (
        <WSText.ParagraphSm
          mr="XS"
          color={options?.valueColor || "gray500"}
          data-testid={camelCase(row.name)}
        >
          {row.type === ListValueType.Number
            ? formatNumber(row.value)
            : toWSMoneyString(row.value)}
        </WSText.ParagraphSm>
      ),
      label: (
        <WSText.ParagraphSm mr="XS" color={options?.labelColor || "gray500"}>
          {row.label}
        </WSText.ParagraphSm>
      )
    };
  });
}
