import { Reason } from "../steps/EntityChange/Reason";
import { Review } from "../steps/EntityChange/Review";
import { Update } from "../steps/EntityChange/Update";
import {
  OnboardingContext,
  OnboardingModuleEntityChange,
  OnboardingStep
} from "../types";

export const getEntityChangeStepConfig = (
  _: OnboardingModuleEntityChange,
  __: OnboardingContext
): OnboardingStep => {
  const slug = "entity-change";

  const steps: OnboardingStep[] = [
    {
      title: "Reason for change",
      slug: "reason",
      component: Reason
    },
    {
      title: "Update taxpayer info",
      slug: "update",
      component: Update
    },
    {
      title: "Review & submit",
      slug: "review",
      component: Review
    }
  ];

  return {
    title: "Update taxpayer info",
    slug,
    steps,
    status: "None"
  };
};
