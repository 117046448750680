import { IChannelNotificationResponse } from "@wingspanhq/notifications/dist/lib/interfaces";
import { selectorActiveNotifications } from "./selectorActiveNotifications";

import { NotificationHandleType } from "../components/Notifications";
import { FileSharingRequestContractorContext } from "../components/Notifications/templates/FileSharingRequestContractor";

export const selectorFileSharingRequestContractorNotifications = (
  responses: IChannelNotificationResponse[]
) => {
  return selectorActiveNotifications(responses).filter(response => {
    return (
      response.handle === NotificationHandleType.FileSharingRequestContractor
    );
  }) as Array<
    Omit<IChannelNotificationResponse, "context"> & {
      context: FileSharingRequestContractorContext;
    }
  >;
};
