import { History } from "history";
import { useHistory } from "react-router-dom";

export const getParentPath = (history: History<any>) => {
  const pathname = history.location.pathname || "";

  return pathname.substring(0, pathname.lastIndexOf("/"));
};

export const goToParentRoute = (
  history: History<any>,
  persistSearch?: boolean
) => {
  history.push({
    pathname: getParentPath(history),
    ...(persistSearch ? { search: history.location.search } : null)
  });
};

export const useGoBackOrToParent = (persistSearch?: boolean) => {
  const history = useHistory();

  return () => {
    if (history.length) {
      history.goBack();
    } else {
      goToParentRoute(history, persistSearch);
    }
  };
};

export const goBackOrToParent = (
  history: History<any>,
  persistSearch?: boolean
) => {
  if (history.length > 2) {
    history.goBack();
  } else {
    goToParentRoute(history, persistSearch);
  }
};
