import React from "react";
import { WSElement, WSElementProps } from "../WSElement/WSElement.component";
import { WSChip, WSMenuProps, WSMenuTrigger } from "../common";
import { WSButton } from "../core/WSButton/WSButton.component";
import { WSText } from "../core/WSText/WSText.component";
import { WSFlexBox } from "../layout";
import styles from "./WSTableToolbar.module.scss";

export type WSTableToolbarProps = {
  onClear: () => void;
  count: number;
  secondaryCount?: number;
  actions?: WSMenuProps["items"];
  actionsLabel?: string;
} & WSElementProps;

export const WSTableToolbar: React.FC<WSTableToolbarProps> = ({
  onClear,
  count,
  secondaryCount,
  actions,
  actionsLabel = "Actions",
  ...elementProps
}) => {
  return (
    <WSElement className={styles.tableToolbar} {...elementProps}>
      <WSFlexBox wrap="nowrap" alignItems="center">
        <WSText.ParagraphXs color="gray500" mr="S">
          Selected
        </WSText.ParagraphXs>
        <WSChip
          active
          pillText={`${count} item${count > 1 ? "s" : ""}`}
          pillType="noBackground"
          icon="exit"
          onClick={onClear}
          rightText={secondaryCount}
        />
      </WSFlexBox>

      {actions && (
        <WSMenuTrigger
          name={actionsLabel}
          items={actions}
          renderTrigger={({ onToggle }) => (
            <WSButton.Secondary
              size="S"
              rightIcon="chevron-down"
              onClick={onToggle}
            >
              {actionsLabel}
            </WSButton.Secondary>
          )}
        />
      )}
    </WSElement>
  );
};
