import React, { useState } from "react";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";

import styles from "./WSStars.module.scss";
import { WSFlexBox } from "../../layout/WSFlexBox/WSFlexBox.component";
import { WSIcon } from "../../core/WSIcon/WSIcon.component";
import { WSText } from "../../core/WSText/WSText.component";
import { WSScreen } from "../../layout/WSScreen/WSScreen.component";

export interface WSStarsProps extends Omit<WSElementProps, "onSelect"> {
  onSelect(value: number): void;
}

export const WSStars: React.FC<WSStarsProps> = ({ onSelect, ...other }) => {
  const [star, setStar] = useState(-1);

  return (
    <WSElement className={styles.container} {...other}>
      <WSFlexBox.CenterY className={styles.stars} justify="space-between">
        {[1, 2, 3, 4, 5].map((value) => (
          <WSElement
            key={value}
            onClick={() => {
              setStar(value);
              onSelect(value);
            }}
            className={styles.star}
          >
            {value <= star ? (
              <WSIcon block name="star-fill" size="L" color="blue400" />
            ) : (
              <WSIcon block name="star" size="L" color="gray600" />
            )}
          </WSElement>
        ))}
      </WSFlexBox.CenterY>
      <WSScreen.TabletAndDesktop>
        <WSFlexBox.CenterY justify="space-between" mt="XS">
          <WSText.ParagraphSm color="gray600">Very Bad</WSText.ParagraphSm>
          <WSText.ParagraphSm color="gray600">Very Good</WSText.ParagraphSm>
        </WSFlexBox.CenterY>
      </WSScreen.TabletAndDesktop>
    </WSElement>
  );
};
