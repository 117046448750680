import { ITaxFormW9Info, TinType } from "@wingspanhq/payments/dist/interfaces";

// copy from BE
// https://github.com/wingspanHQ/payments/blob/2d09346e58049716c896d5f443bf8229f0780632/src/services/taxFiling/taxFormService.ts
// checkW9DataComplete
export const selectorIsW9FormDataComplete = (
  w9Info: ITaxFormW9Info
): boolean => {
  let validW9 = false;

  const isUSAddressComplete =
    w9Info.country === "US" &&
    w9Info.addressLine1 &&
    w9Info.city &&
    w9Info.state &&
    w9Info.country &&
    w9Info.postalCode;

  // State not required if country is not US
  const isNonUSAddressComplete =
    w9Info.country !== "US" &&
    w9Info.addressLine1 &&
    w9Info.city &&
    w9Info.country &&
    w9Info.postalCode;
  const isAddressComplete = isUSAddressComplete || isNonUSAddressComplete;

  // All fields for individual provided
  if (
    w9Info.tinType === TinType.Individual &&
    w9Info.firstName &&
    w9Info.lastName &&
    w9Info.ssnLastFour &&
    isAddressComplete
  ) {
    validW9 = true;

    // All fields for business provided
  } else if (
    w9Info.tinType === TinType.Business &&
    w9Info.einLastFour &&
    w9Info.legalBusinessName &&
    isAddressComplete
  ) {
    validW9 = true;

    // Special case for some users with poor data where we use legalBusinessName instead of first + last
  } else if (
    w9Info.tinType === TinType.Individual &&
    w9Info.legalBusinessName &&
    w9Info.ssnLastFour &&
    isAddressComplete
  ) {
    validW9 = true;
  }

  return validW9;
};
