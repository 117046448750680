import {
  WSActions,
  WSElement,
  WSEmptyState,
  WSSectionToolbar,
  WSTable,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import queryString from "qs";
import { useHistory } from "react-router-dom";

import { usePayeeRowsQuery } from "../../../../query/search/payee/queries/usePayeeRowsQuery";
import { WSQueries } from "../../../../query/WSQuery";
import {
  IPayeeRow,
  PayeeRowsQuery,
  PayeeRowsQueryFilter,
  PayerPayeeEngagementSearchableStatus
} from "../../../../services/search";
import { useUrlQueryFilters } from "../../../../utils/router";
import { EligibilityStatusCell } from "../../components/PayeeRowsTable";
import { PAYEES_ROOT_PATH } from "../../utils";

export interface RecentContractorsWidgetProps {
  engagementId: string;
}

export const RecentContractorsWidget: React.FC<RecentContractorsWidgetProps> = ({
  engagementId
}) => {
  const history = useHistory();
  const DEFAULT_FILTERS: PayeeRowsQueryFilter = {
    searchableStatus: {
      "!=": PayerPayeeEngagementSearchableStatus.Inactive
    },
    "engagements.engagementId": engagementId
  };
  const { filters } = useUrlQueryFilters<PayeeRowsQuery>({
    filter: DEFAULT_FILTERS,
    page: {
      number: 1,
      size: 5
    },
    sort: {
      updatedAt: "desc"
    }
  });
  const queryPayeeRows = usePayeeRowsQuery(filters);

  return (
    <WSQueries
      queries={{
        queryPayeeRows
      }}
    >
      {({ queryPayeeRowsData: payees }) => {
        if (payees.length === 0) {
          return (
            <WSEmptyState
              type="people"
              title="No contractors yet"
              description="Once a contractor is added to this engagement, they will appear here"
              buttons={[
                {
                  label: "Add contractor",
                  kind: "Secondary",
                  onClick: () => {
                    history.push(PAYEES_ROOT_PATH + "?add_contractor=true");
                  }
                }
              ]}
              hasBorder={true}
              bgColor="white"
            />
          );
        }
        return (
          <WSElement>
            <WSSectionToolbar
              mb="S"
              title="Recent contractors"
              button={{
                label: "Add contractor",
                kind: "Link",
                onClick: () => {
                  history.push(PAYEES_ROOT_PATH + "?add_contractor=true");
                }
              }}
            />

            <WSTable<IPayeeRow>
              tableData={payees.map(payee => ({
                id: payee.payeeId,
                data: payee
              }))}
              onRowClick={row => {
                history.push(`/member/payees/${row.id}`);
              }}
              columns={[
                {
                  config: {
                    gridTemplateSizeMin: "0",
                    gridTemplateSizeMax: "1.5fr",
                    header: {
                      text: "Name"
                    }
                  },
                  renderFunction: ({ data }) => {
                    const names = wsName({
                      user: data.user!,
                      member: data.member,
                      payerOwnedData: data.payerOwnedData
                    });
                    return (
                      <WSTableCell
                        avatar={{
                          type: "text",
                          text: names.getResolvedName(true) || "--"
                        }}
                        text={names.getResolvedName(true)}
                        secondaryText={names.getResolvedSecondaryName()}
                      />
                    );
                  }
                },
                {
                  config: {
                    gridTemplateSizeMin: "0",
                    gridTemplateSizeMax: "1fr",
                    header: {
                      text: "Eligibility status"
                    }
                  },
                  renderFunction: ({ data }) => {
                    return <EligibilityStatusCell data={data} />;
                  }
                }
              ]}
            />
            <WSActions
              alignment="left"
              size="M"
              mt="M"
              buttons={[
                {
                  label: "View all",
                  kind: "Link",
                  onClick: () => {
                    const query = queryString.stringify({
                      filter: {
                        "engagements.engagementId": {
                          in: [engagementId]
                        }
                      }
                    });
                    history.push(`/member/payees?${query}`);
                  }
                }
              ]}
            />
          </WSElement>
        );
      }}
    </WSQueries>
  );
};
