import React, { useMemo } from "react";
import {
  defaultFormatter,
  Formatter,
  FormatterInput,
  FormatterOptions
} from "../../../utils/formatters";
import { WSAvatar, WSAvatarProps } from "../common/WSAvatar/WSAvatar.component";
import { WSPill, WSPillProps } from "../common/WSPill/WSPill.component";
import { WSIcon, WSIconName } from "../core/WSIcon/WSIcon.component";
import { WSText } from "../core/WSText/WSText.component";
import { WSElement } from "../WSElement/WSElement.component";
import { CopyButton } from "./CopyButton";
import styles from "./Value.module.scss";

const DEFAULT_ICON_SIZE = "S";
const DEFAULT_ICON_COLOR = "gray600";
const DEFAULT_AVATAR_SIZE = "S";

export type WSDataItemValueProps = {
  formatter?: Formatter;
  value?: FormatterInput;
  formatterOptions?: FormatterOptions;
  pill?: WSPillProps;
  avatar?: WSAvatarProps;
  icon?: WSIconName;
  copy?: boolean;
};

export const Value: React.FC<WSDataItemValueProps> = ({
  formatter: formatterProp,
  value,
  formatterOptions,
  icon,
  avatar,
  pill,
  copy
}) => {
  const formatter = useMemo(() => formatterProp || defaultFormatter, [
    formatterProp
  ]);

  const formattedValue = useMemo(
    () => formatter.format(value as any, formatterOptions as any),
    [formatter, formatterOptions, value]
  );

  return (
    <WSElement className={styles.valueWrapper}>
      <WSElement className={styles.value}>
        {icon && (
          <WSIcon
            name={icon}
            size={DEFAULT_ICON_SIZE}
            color={DEFAULT_ICON_COLOR}
          />
        )}
        {avatar && <WSAvatar size={DEFAULT_AVATAR_SIZE} {...avatar} />}
        <WSText kind="ParagraphSm">{formattedValue}</WSText>
        {copy && formattedValue && <CopyButton value={formattedValue} />}
      </WSElement>

      {pill && <WSPill {...pill} />}
    </WSElement>
  );
};
