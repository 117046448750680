import {
  IDocumentFilesResponse,
  IDocumentResponse,
  IDocumentSigningUrlsResponse,
  IMemberFileResponse,
  IMemberFileSummaryResponse,
  IMemberPublicFileResponse,
  ITemplate,
  ITemplateCreateRequest
} from "@wingspanhq/files/dist/lib/interfaces";
import { ListRequestQuery } from "../utils/serviceHelper";
import { WSService } from "../utils/WSService";

const service = new WSService("/files", [
  {
    regex: /File Already Exist/,
    message: "Sorry! A file with that name already exists."
  },
  {
    regex: /must be less than or equal to 100mb in size/,
    message: "Sorry! That file is too large. Max size is 100MB."
  },
  {
    regex: /Invalid file/,
    message: "Sorry! Cannot upload executable files."
  }
]);

// Private files

export interface PrivateFilesAPIFilter {
  hidden?: boolean;
  tags?: {
    in: string[];
  };
}

const getPrivateFiles = async (
  query?: ListRequestQuery<PrivateFilesAPIFilter>
): Promise<IMemberFileResponse[]> => {
  const { data } = await service.get("/member/private", {
    params: query
  });
  return data;
};

const createPrivateFile = async (
  formData: FormData,
  hidden?: boolean,
  tags?: string[],
  viewerIds?: string[]
): Promise<IMemberFileResponse[]> => {
  const { data } = await service.post("/member/private/upload", formData, {
    params: {
      hidden,
      tags: tags?.join(","),
      viewerIds: viewerIds?.join(",")
    }
  });
  return data;
};

const getPrivateFile = async (id: string): Promise<IMemberFileResponse> => {
  const { data } = await service.get(`/member/private/${id}`);
  return data;
};

const downloadPrivateFile = async (id: string): Promise<ArrayBuffer> => {
  const { data } = await service.get(`/member/private/${id}/download`, {
    responseType: "arraybuffer"
  });
  return data;
};

const deletePrivateFile = async (id: string): Promise<void> => {
  await service.delete(`/member/private/${id}`);
};

// Public files
const getPublicFiles = async (): Promise<IMemberPublicFileResponse[]> => {
  const { data } = await service.get("/member/public");
  return data;
};

const createPublicFile = async (
  formData: FormData
): Promise<IMemberPublicFileResponse[]> => {
  const { data } = await service.post("/member/public/upload", formData);
  return data;
};

const getPublicFile = async (
  id: string
): Promise<IMemberPublicFileResponse> => {
  const { data } = await service.get(`/member/public/${id}`);
  return data;
};

const getPublicFileSummary = async (
  id: string
): Promise<IMemberFileSummaryResponse> => {
  const { data } = await service.get(`/member/public/${id}/summary`);
  return data;
};

const downloadPublicFile = async (id: string): Promise<any> => {
  const { data } = await service.get(`/member/public/${id}/download`, {
    responseType: "arraybuffer"
  });
  return data;
};

const deletePublicFile = async (id: string): Promise<void> => {
  await service.delete(`/member/public/${id}`);
};

// Documents

const getDocument = async (id: string): Promise<IDocumentResponse> => {
  const { data } = await service.get(`/document/${id}`);
  return data;
};

const getDocumentSigningUrls = async (
  id: string
): Promise<IDocumentSigningUrlsResponse> => {
  const { data } = await service.get(`/document/${id}/signing-urls`);
  return data;
};

const saveDocumentFiles = async (
  id: string
): Promise<IDocumentFilesResponse> => {
  const { data } = await service.get(`/document/${id}/save-files`);
  return data;
};

// Tempates

export interface TemplateFilter {
  templateId?: {
    in: string[];
  };
}

const getTemplates = async (
  query?: ListRequestQuery<TemplateFilter>
): Promise<ITemplate[]> => {
  const { data } = await service.get("/template", {
    params: query
  });
  return data;
};

const getTemplate = async (id: string): Promise<ITemplate> => {
  const { data } = await service.get(`/template/${id}`);
  return data;
};

const createTemplate = async (
  requestData: ITemplateCreateRequest
): Promise<ITemplate> => {
  const { data } = await service.post("/template", requestData);
  return data;
};

const updateTemplate = async (
  id: string,
  requestData: ITemplateCreateRequest
): Promise<ITemplate> => {
  const { data } = await service.patch("/template/" + id, requestData);
  return data;
};

export const filesService = {
  member: {
    public: {
      list: getPublicFiles,
      create: createPublicFile,
      get: getPublicFile,
      download: downloadPublicFile,
      summary: getPublicFileSummary,
      delete: deletePublicFile
    },
    private: {
      list: getPrivateFiles,
      create: createPrivateFile,
      get: getPrivateFile,
      download: downloadPrivateFile,
      delete: deletePrivateFile
    }
  },
  document: {
    get: getDocument,
    getSigningUrls: getDocumentSigningUrls,
    saveFiles: saveDocumentFiles
  },
  template: {
    list: getTemplates,
    get: getTemplate,
    create: createTemplate,
    update: updateTemplate
  }
};
