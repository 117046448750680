import {
  WSButton,
  WSButtons,
  WSForm,
  WSInputTextarea,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";

export interface AdditionalGuidelinesModalProps {
  additionalGuidelinesValue: string;
  onSubmit: (data: { additionalGuidelinesValue: string }) => void;
  onClose: () => void;
}

export const AdditionalGuidelinesModal: React.FC<AdditionalGuidelinesModalProps> = ({
  additionalGuidelinesValue = "",
  onSubmit,
  onClose
}) => {
  return (
    <WSForm
      onSubmit={data => {
        onSubmit(data);
        onClose();
      }}
      defaultValues={{
        additionalGuidelinesValue
      }}
      validationSchema={Yup.object().shape({
        additionalGuidelinesValue: Yup.string().required(
          "This field is required"
        )
      })}
    >
      <WSText.ParagraphSm mb="S">
        Enter text to show contractors when they create invoices:
      </WSText.ParagraphSm>

      <WSForm.Field
        name="additionalGuidelinesValue"
        component={WSInputTextarea}
      />

      <WSButtons p="S" format="modal">
        <WSButton.Primary size="S" type="submit">
          Save
        </WSButton.Primary>
        <WSButton.Tertiary size="S" onClick={onClose}>
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </WSForm>
  );
};
