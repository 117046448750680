import { useHistory, useRouteMatch } from "react-router-dom";
import cn from "classnames";
import React, { ReactNode } from "react";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import { PayerSetupLayout } from "../../components/PayerSetupLayout";
import {
  WSButton,
  WSIconList,
  WSMessageBox,
  WSText,
  WSButtons,
  WSFlexBox,
  IconListItem
} from "@wingspanhq/fe-component-library";
import {
  useAllBulkCollaboratorBatchItems,
  useBulkCollaboratorBatch
} from "../../../BulkImporter/query/bulkCollaborator/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { pluralize } from "../../../../shared/utils/pluralize";

import styles from "./styles.module.scss";
import {
  BulkStatus,
  CustomFieldResourceType
} from "@wingspanhq/payments/dist/interfaces";
import { CSVLink } from "react-csv";
import { useCreateBulkCollaboratorBatch } from "../../../BulkImporter/query/bulkCollaborator/mutations";
import { prepareCSVCollaboratorFromBatchItem } from "../../../BulkImporter/routes/BulkImportCollaboratorBatchDetails/prepareCSVCollaboratorFromBatchItem";
import { prepareCollaboratorCSVFile } from "../../../BulkImporter/routes/BulkImportCollaboratorBatchDetails/prepareCollaboratorCSVFile";
import { getUploadedFilename } from "../../../BulkImporter/utils/getUploadedFilename";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { recipientPluralizeOptions } from "./RouteRecipientsImportPreview";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  useActivities,
  useAllOrganizationUsers
} from "../../../../query/users/queries";
import { useUpdateActivity } from "../../../../query/users/mutations";
import { get1099SetupProgress } from "../../../../query/users/selectors";
import { KEY_IMPORT_RECIPIENTS_BULK_BATCH_ID } from "../../constants/storage";
import { BulkResource } from "../../../BulkImporter/types";

export const RouteRecipientsImportErrors: React.FC<SetupRouteComponentProps & {
  isEdit?: boolean;
  Layout?: React.FC<{
    title: string;
    children: ReactNode;
    onBack?: () => void;
  }>;
}> = ({ onNext, isEdit = false, Layout }) => {
  const history = useHistory();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const qCollaboratorBulkBatch = useBulkCollaboratorBatch(bulkBatchId);
  const qAllFailedBulkCollaboratorBatchItems = useAllBulkCollaboratorBatchItems(
    bulkBatchId,
    {
      filter: {
        status: BulkStatus.Failed
      }
    },
    {
      enabled: (qCollaboratorBulkBatch.data?.statistics?.itemsFailed || 0) > 0
    }
  );
  const qCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });

  const collaboratorCustomFields = qCollaboratorCustomFields.data || [];
  const qAllOrganizationUsers = useAllOrganizationUsers();

  const userId = useUserId();
  const qActivity = useActivities(userId);
  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);

  const [
    createBulkCollaboratorBatch,
    createBulkCollaboratorBatchMeta
  ] = useCreateBulkCollaboratorBatch();

  const uploadSpreadsheet = async () => {
    await createBulkCollaboratorBatch(
      {},
      {
        onSuccess: response => {
          onNext({
            bulkBatchId: response.bulkCollaboratorBatchId
          });
        }
      }
    );
  };

  const onSkip = async () => {
    // skip updating activity if we are in edit mode
    if (!isEdit) {
      const nec1099SetupProgress = get1099SetupProgress(qActivity.data);
      await updateActivity(
        {
          flows: {
            nec1099Setup: {
              ...nec1099SetupProgress,
              currentStep: 3
            }
          }
        },
        {
          onSuccess: () => {
            onNext({
              skipErrors: true
            });
          }
        }
      );
    } else {
      history.push("/member/1099-filing/dashboard/all");
    }

    window.localStorage.removeItem(KEY_IMPORT_RECIPIENTS_BULK_BATCH_ID);
  };
  const LayoutComponent = Layout || PayerSetupLayout;

  return (
    <LayoutComponent title="Add recipients">
      <WSQueries
        queries={{
          qCollaboratorBulkBatch,
          qAllOrganizationUsers
        }}
      >
        {({
          qCollaboratorBulkBatchData: collaboratorBulkBatch,
          qAllOrganizationUsersData: allOrganizationUsers
        }) => {
          const failedCSVCollaborators = (
            qAllFailedBulkCollaboratorBatchItems.data ?? []
          ).map(failedBatchItem =>
            prepareCSVCollaboratorFromBatchItem(
              failedBatchItem,
              collaboratorCustomFields,
              allOrganizationUsers,
              { includeErrors: true }
            )
          );

          const errorsCSVFileProps = prepareCollaboratorCSVFile(
            `Errors_${getUploadedFilename(
              collaboratorBulkBatch,
              BulkResource.Collaborator
            )}`,
            collaboratorCustomFields,
            failedCSVCollaborators,
            { includeErrors: true }
          );
          return (
            <>
              <WSText.Heading4 mb="XL">
                {`There was an error on ${collaboratorBulkBatch.statistics?.itemsFailed} rows of your spreadsheet in your last import`}
              </WSText.Heading4>
              <WSMessageBox.Info mb="XL" size="Large">
                <WSText weight="medium" mb="XL">
                  Import summary
                </WSText>
                <WSIconList
                  mb="XL"
                  items={
                    [
                      ...((collaboratorBulkBatch.statistics?.itemsProcessed ||
                        0) > 0
                        ? [
                            {
                              description: `${pluralize(
                                collaboratorBulkBatch.statistics
                                  ?.itemsProcessed ?? 0,
                                recipientPluralizeOptions
                              )} imported successfully.`,
                              icon: {
                                name: "check",
                                color: "green500",
                                size: "M"
                              }
                            } as IconListItem
                          ]
                        : []),
                      ...((collaboratorBulkBatch.statistics?.itemsFailed ?? 0) >
                      0
                        ? [
                            {
                              description: `${pluralize(
                                collaboratorBulkBatch.statistics?.itemsFailed ??
                                  0,
                                recipientPluralizeOptions
                              )} not imported.`,
                              icon: {
                                name: "alert",
                                color: "red500",
                                size: "M"
                              }
                            }
                          ]
                        : [])
                    ] as IconListItem[]
                  }
                />
                <WSMessageBox.Error>
                  <WSText weight="medium" mb="XS" color="red500">
                    {`There was an error importing ${collaboratorBulkBatch.statistics?.itemsFailed} rows of your spreadsheet`}
                  </WSText>
                  Download a copy of the spreadsheet, fix the errors, save, and
                  re-import
                </WSMessageBox.Error>
              </WSMessageBox.Info>

              <WSText weight="medium" mb="XL">
                Here's how you can fix this
              </WSText>

              <WSText as="ol" mb="M" className={styles.bulletedList}>
                <WSText.ParagraphSm weight="medium" as="li">
                  1. Download CSV of errors
                  <WSText color="gray600">
                    The column "errors" includes the errors you need to resolve
                    prior to re-upload.
                  </WSText>
                </WSText.ParagraphSm>

                <WSText.ParagraphSm weight="medium" as="li">
                  2. Upload a new spreadsheet with the errors fixed
                  <WSText color="gray600">
                    Please make sure your spreadsheet contains the following
                    columns:
                  </WSText>
                </WSText.ParagraphSm>
              </WSText>

              <WSButtons mt="3XL">
                <CSVLink {...errorsCSVFileProps}>
                  <WSButton.Secondary
                    className={cn(styles.actionBtn, styles.downloadBtn)}
                    fullWidth
                    icon="download"
                    p="S"
                  >
                    Download Errors CSV
                  </WSButton.Secondary>
                </CSVLink>

                <WSButton.Primary
                  className={cn(styles.actionBtn, styles.uploadBtn)}
                  fullWidth
                  icon="upload"
                  p="S"
                  data-testid="importRecipients"
                  onClick={uploadSpreadsheet}
                >
                  Upload corrected spreadsheet
                </WSButton.Primary>
              </WSButtons>

              <WSFlexBox.Center my="XL">
                <WSButton.Link
                  type="button"
                  onClick={onSkip}
                  loading={updateActivityMeta.isLoading}
                >
                  Skip for now
                </WSButton.Link>
              </WSFlexBox.Center>
            </>
          );
        }}
      </WSQueries>
    </LayoutComponent>
  );
};
