import flatten from "lodash/flatten";
import { InfiniteQueryConfig } from "react-query";
import { useWSInfiniteQuery } from "../../../query/helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import {
  IInvoiceTemplateRow,
  InvoiceTemplateRowsQuery,
  getInvoiceTemplateRows
} from "../service";

const PAGE_SIZE = 20;

export const QUERY_KEY_INOVICE_TEMPLATE_ROWS =
  "QUERY_KEY_INOVICE_TEMPLATE_ROWS";

export const useQueryInvoiceTemplateRows = (
  requestQuery?: InvoiceTemplateRowsQuery,
  config?: InfiniteQueryConfig<IInvoiceTemplateRow[], WSServiceError>
) => {
  const query = useWSInfiniteQuery<IInvoiceTemplateRow[], WSServiceError>(
    [QUERY_KEY_INOVICE_TEMPLATE_ROWS, requestQuery],
    (_, __, pageNumber = 1) => {
      return getInvoiceTemplateRows({
        ...requestQuery,
        page: {
          size: PAGE_SIZE,
          number: pageNumber
        }
      });
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.length < PAGE_SIZE) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  return {
    ...query,
    data: query.data ? flatten(query.data) : undefined
  };
};
