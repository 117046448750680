import cn from "classnames";
import React from "react";
import styles from "./WSLoader.module.scss";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { ComponentWithKinds } from "../../WSElement/WSElement.helpers";

export enum WSLoaderKindMap {
  Shimmer = "Shimmer",
  Spinner = "Spinner"
}

export type WSLoaderKind = keyof typeof WSLoaderKindMap;

interface WSSpinnerProps extends WSElementProps {
  size?: "L" | "M" | "S" | "XS";
}

const WSSpinner: React.FC<WSSpinnerProps> = ({
  size = "M",
  className,
  ...props
}) => (
  <WSElement as="i" className={cn(styles.loader, className)} {...props}>
    <WSElement as="i" className={cn(styles.loaderIcon, styles[size])} />
  </WSElement>
);

interface WSShimmerProps extends WSElementProps {
  pattern?: React.FC<React.SVGAttributes<React.ReactSVG>>;
  repeat?: number;
}
const WSShimmer: React.FC<WSShimmerProps> = ({
  pattern,
  repeat = 1,
  className,
  ...componentProps
}) => {
  const classNames = cn(className, styles.shimmer);

  const SVG = pattern as React.FC<React.SVGAttributes<React.ReactSVG>>;
  return (
    <WSElement shimmer className={classNames} {...componentProps}>
      {Array.from({ length: repeat }, (item, index) => (
        <SVG
          key={index}
          className={styles.svg}
          width="100%"
          preserveAspectRatio="none"
        />
      ))}
    </WSElement>
  );
};

export interface WSLoaderProps extends WSShimmerProps, WSSpinnerProps {
  kind?: WSLoaderKind;
}

export const WSLoader: ComponentWithKinds<WSLoaderProps, WSLoaderKind> = ({
  kind = WSLoaderKindMap.Shimmer,
  ...otherProps
}) => {
  if (kind === WSLoaderKindMap.Shimmer) {
    return <WSShimmer {...otherProps} />;
  }
  return <WSSpinner {...otherProps} />;
};

WSLoader.Shimmer = (props: WSShimmerProps) => (
  <WSLoader kind="Shimmer" {...props} />
);
WSLoader.Spinner = (props: WSSpinnerProps) => (
  <WSLoader kind="Spinner" {...props} />
);
