export enum TaxFormViewer {
  Payer = "Payer",
  Payee = "Payee"
}

export interface ICorrectionType {
  corrections: string[];
  addressStateNotChanged: boolean;
  isVoidType?: boolean;
  isCompanyStructureChangeOnly?: boolean;
}
