import { IAddress } from "@wingspanhq/users/dist/lib/interfaces";
import { FormData } from "./types";

export const selectorDefaultValues = (address?: IAddress): FormData => ({
  country: address?.country || "US",
  addressLine1: address?.addressLine1 || "",
  addressLine2: address?.addressLine2 || "",
  city: address?.city || "",
  state: address?.state || "",
  zipCode: address?.postalCode || ""
});
