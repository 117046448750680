import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RouteDashboardReports } from "./routes/RouteDashboardReports";
import { RoutePayrollLineItemsReport } from "./routes/RoutePayrollLineItemsReport";
import { RouteCollaboratorsReport } from "./routes/RouteCollaboratorsReport";
import { RouteOpenPayableAgingReport } from "./routes/RouteOpenPayableAgingReport";
import { RouteOpenLineItemAgingReport } from "./routes/RouteOpenLineItemAgingReport";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { RouteCollaboratorPayablesSummaryReport } from "./routes/RouteCollaboratorPayablesReport";
import { RouteOpenReceivableAgingReport } from "./routes/RouteOpenReceivableAging";

export const ReportsIndex: React.FC = () => {
  useBrowserPageTitle("Reports");

  return (
    <Switch>
      <Route
        path="/member/reports/payroll"
        component={RoutePayrollLineItemsReport}
      />
      <Route
        path="/member/reports/collaborators"
        component={RouteCollaboratorsReport}
      />

      <Route
        path="/member/reports/open-payable-aging"
        component={RouteOpenPayableAgingReport}
      />
      <Route
        path="/member/reports/open-line-item-aging"
        component={RouteOpenLineItemAgingReport}
      />

      <Route
        path="/member/reports/open-receivable-aging"
        component={RouteOpenReceivableAgingReport}
      />

      <Route
        path="/member/reports/collaborator-payables-summary"
        component={RouteCollaboratorPayablesSummaryReport}
      />

      <Route path="/member/reports" exact component={RouteDashboardReports} />

      <Redirect from="*" to="/member/reports" />
    </Switch>
  );
};
