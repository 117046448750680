import { cardsService } from "./cardsService";
import { ICardLimitsResponse } from "./types";

export const getCardLimits = async (
  cardId: string
): Promise<ICardLimitsResponse> => {
  const { data } = await cardsService.get(`${cardId}/limits`);

  return data;
};
