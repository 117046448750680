import {
  WSElement,
  WSElementProps,
  WSGrid,
  WSPill,
  WSPillProps,
  WSText
} from "@wingspanhq/fe-component-library";
import styles from "./Statuses.module.scss";

export type StatusItem = {
  label: string;
  pill: WSPillProps;
  onClick?(): void;
};

type Props = {
  items: StatusItem[];
} & WSElementProps;

export const Statuses: React.FC<Props> = ({ items, ...elementProps }) => (
  <WSElement className={styles.container} {...elementProps}>
    <WSGrid>
      {items.map(item => (
        <WSGrid.Item key={item.label} span={{ s: "6" }}>
          <WSElement className={styles.item} onClick={item.onClick}>
            <WSText mr="M" singleLine>
              {item.label}
            </WSText>
            <WSPill {...item.pill} />
          </WSElement>
        </WSGrid.Item>
      ))}
    </WSGrid>
  </WSElement>
);
