import { WSList, WSText } from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  useMemberProfile,
  useUserProfile
} from "../../../../query/users/queries";
import { verificationService } from "../../../../shared/services/verificaiton";
import { FormBusinessInformation } from "../../components/FormBusinessInformation";
import { getProfileDataBusiness } from "../../components/FormBusinessInformation/getProfileDataBusiness";
import { useOnboardignModules, useOnboardingContext } from "../../Context";
import { useMissingFields } from "../../hooks/useMissingFields";
import { OnboardingModuleCreateAccount, OnboardingStep } from "../../types";
import { CreateAccountContext } from "./types";

export const BusinessInformation: React.FC<OnboardingStep> = ({
  onNext,
  onBack
}) => {
  const [context, setContext] = useOnboardingContext<CreateAccountContext>();

  const modules = useOnboardignModules();
  const onboardingModule = modules.find(m => m.type === "create_account") as
    | OnboardingModuleCreateAccount
    | undefined;
  const show1099Message = onboardingModule?.options?.show1099Message;
  const verificationLevel =
    onboardingModule?.options?.verificationLevel || "Tax";

  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const profileData = useMemo(() => getProfileDataBusiness(queryMember.data), [
    queryMember.data
  ]);

  const missingFields = useMissingFields(verificationLevel);
  const isMismatch = useMemo(
    () => verificationService.getIsBusinessMismatch(missingFields),
    [missingFields]
  );

  return (
    <WSList gap="2XL">
      <FormBusinessInformation
        country={context.accountType?.country || "US"}
        defaultValues={context.businessInformation || profileData}
        onBack={onBack}
        onSubmit={data => {
          setContext(prev => ({ ...prev, businessInformation: data }));
          onNext?.();
        }}
        show1099Message={show1099Message}
        personalEmail={queryUser.data?.email}
        mismatch={isMismatch}
      />

      <WSText.ParagraphXs color="gray400">
        The information on this page is required to setup your Wingspan account,
        if you exit or leave without completing this step, you will return to
        this page the next time you sign-in
      </WSText.ParagraphXs>
    </WSList>
  );
};
