import React from "react";
import { WSPill, WSPillProps } from "../common";
import { WSButton, WSButtonProps } from "../core";
import { WSText } from "../core/WSText/WSText.component";
import { WSElement } from "../WSElement/WSElement.component";
import styles from "./WSCardHeaderLabel.module.scss";

export type WSCardHeaderValueProps = {
  value?: string;
  action?: WSButtonProps<"Link">;
  pill?: WSPillProps;
  stacked?: boolean;
};

export const WSCardHeaderValue: React.FC<WSCardHeaderValueProps> = (props) => {
  const valueElement = props.value ? (
    <WSText kind="Paragraph" weight="medium" color="gray700">
      {props.value}
    </WSText>
  ) : undefined;

  const actionElement = props.action ? (
    <WSButton.Link {...props.action} />
  ) : undefined;

  const pillElement = props.pill ? <WSPill {...props.pill} /> : undefined;

  return (
    <WSElement className={styles.value}>
      {valueElement}
      {actionElement}
      {pillElement}
    </WSElement>
  );
};
