import {
  WSButton,
  WSDivider,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { InvoiceStatus } from "@wingspanhq/payments/dist/interfaces";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";
import { formatMoney } from "accounting";
import React, { useState } from "react";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { CertifiedSecureDigitalPayments } from "../../components/CertifiedSecureDigitalPayments/CertifiedSecureDigitalPayments";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../query/hooks/helpers";
import { useUpdateClientInvoice } from "../../query/payments/mutations";
import {
  getClientInvoiceMemberName,
  getClientName
} from "../../query/payments/selectors";
import { getRedactedMemberName } from "../../query/users/selectors";
import { useWSStore } from "../../store";
import { addBusinessDays } from "../../utils/dates";
import { Card } from "../components/Card/Card";
import { ClientInvoiceDetailsDrawer } from "../components/ClientInvoiceDetailsDrawer/ClientInvoiceDetailsDrawer";
import { ClientInvoiceWrapper } from "../components/ClientInvoiceWrapper/ClientInvoiceWrapper";
import { ClientPaymentsWrapper } from "../components/ClientPaymentsWrapper/ClientPaymentsWrapper";
import { DonwloadPdfButton } from "../components/DownloadPdfButton/DonwloadPdfButton";
import { InfoRow } from "../components/InfoRow/InfoRow";
import { ManualWireTransferDetails } from "../components/ManualWireTransferDetails/ManualWireTransferDetails";
import { PrivacyPolicy } from "../components/PrivacyPolicy/PrivacyPolicy";
import {
  getPaymentStepText,
  isClientInvoiceCancelled,
  isClientInvoicePaid
} from "../utils";
import { VerticalDivider } from "../../shared/components/VerticalDivider";

type Props = RouteComponentProps<{ invoiceId: string }>;

export const ClientPaymentsInvoicePaymentManual: React.FC<Props> = ({
  match
}) => {
  const userId = useUserId();
  const history = useHistory();
  const invoiceId = match.params.invoiceId;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [updateClientInvoice, updateClientInvoiceMeta] = useUpdateClientInvoice(
    invoiceId
  );
  const [
    isInternationalBankDetailsVisible,
    setIsInternationalBankDetailsVisible
  ] = useState(false);

  const store = useWSStore();

  return (
    <ClientInvoiceWrapper invoiceId={invoiceId}>
      {clientInvoice => {
        const bankName = isInternationalBankDetailsVisible
          ? clientInvoice.internationalBankTransferInfo?.bankName
          : clientInvoice.bankTransferInfo?.bankName;

        const routingNumber = isInternationalBankDetailsVisible
          ? clientInvoice.internationalBankTransferInfo?.routingNumber
          : clientInvoice.bankTransferInfo?.routingNumber;

        const accountNumber = isInternationalBankDetailsVisible
          ? clientInvoice.internationalBankTransferInfo?.accountNumber
          : clientInvoice.bankTransferInfo?.accountNumber;

        const swiftCode =
          clientInvoice.internationalBankTransferInfo?.swiftCode;

        return isClientInvoicePaid(clientInvoice) ? (
          <Redirect to={`/payment/${invoiceId}/success`} />
        ) : isClientInvoiceCancelled(clientInvoice) ? (
          <Redirect to={`/payment/${invoiceId}`} />
        ) : (
          <ClientPaymentsWrapper
            clientInvoice={clientInvoice}
            companyName={clientInvoice.memberCompany}
            clientEmail={clientInvoice.memberClient.emailTo}
            memberName={getClientInvoiceMemberName(clientInvoice)}
            companyLogoUrl={clientInvoice.memberLogoUrl}
            onBack={() => {
              history.goBack();
            }}
          >
            {drawerVisible && (
              <ClientInvoiceDetailsDrawer
                invoiceId={invoiceId}
                onClose={() => {
                  setDrawerVisible(false);
                }}
              />
            )}

            <WSText.ParagraphSm>
              {getPaymentStepText(clientInvoice, "payment")}
            </WSText.ParagraphSm>
            <WSText.Heading4 mb="2XL">Review & pay</WSText.Heading4>

            <Card title="Wire / Manual ACH information" mb="M">
              <ManualWireTransferDetails
                bankTransferInfo={clientInvoice.bankTransferInfo}
                internationalBankTransferInfo={
                  clientInvoice.internationalBankTransferInfo
                }
                invoiceNumber={clientInvoice.invoiceNumber}
              />
            </Card>

            <Card mb="M">
              <WSText.Heading5 mb="XS">Payment timing</WSText.Heading5>
              <WSText mb="XS">Within 5 business days</WSText>
              <WSText.ParagraphSm color="gray500">
                Estimated arrival:{" "}
                {moment(addBusinessDays(new Date(), 5)).format("MMMM D, YYYY")}
              </WSText.ParagraphSm>
            </Card>

            <Card mb="3XL">
              <WSFlexBox justify="space-between" mb="XS">
                <WSText.ParagraphSm>Total</WSText.ParagraphSm>
                <WSFlexBox.CenterY>
                  <WSButton.Link
                    onClick={() => {
                      setDrawerVisible(true);
                    }}
                  >
                    View
                  </WSButton.Link>
                  {clientInvoice.attachments?.invoicePdf && (
                    <>
                      <VerticalDivider mx="M" my="XL" />
                      <DonwloadPdfButton clientInvoice={clientInvoice} />
                    </>
                  )}
                </WSFlexBox.CenterY>
              </WSFlexBox>
              <WSText.Heading1 weight="book" mb="2XL">
                {formatMoney(clientInvoice.amount)}
              </WSText.Heading1>
              <InfoRow
                label={store.createdWithPPL ? "From" : "To"}
                value={getClientName(clientInvoice.memberClient)}
                mb="M"
              />
              <InfoRow
                label={store.createdWithPPL ? "To" : "From"}
                value={getRedactedMemberName(clientInvoice.memberClient.member)}
                mb="M"
              />
              {clientInvoice.invoiceNotes && (
                <InfoRow
                  label="Note"
                  value={clientInvoice.invoiceNotes}
                  mb="M"
                />
              )}
              <WSButton
                fullWidth
                mt="2XL"
                onClick={async () => {
                  updateClientInvoice(
                    {
                      status: InvoiceStatus.PaymentInTransit
                    },
                    {
                      onSuccess: () => {
                        history.push(`/payment/${invoiceId}/success`, {
                          paymentMethod: "manual"
                        });
                      }
                    }
                  );
                }}
                loading={updateClientInvoiceMeta.isLoading}
                name="pay"
              >
                I've sent a wire transfer
              </WSButton>

              {userId && clientInvoice.memberClient.memberId === userId && (
                <WSText.ParagraphSm mt="XL" color="gray500">
                  You are the link owner. This is what your customers will see.
                </WSText.ParagraphSm>
              )}

              <WSErrorMessage
                mt="XL"
                contextKey="PayInvoice"
                error={updateClientInvoiceMeta.error}
              />
            </Card>

            <CertifiedSecureDigitalPayments mb="M" />
            <PrivacyPolicy />
          </ClientPaymentsWrapper>
        );
      }}
    </ClientInvoiceWrapper>
  );
};
