import React from "react";
import { FormInternationalPayoutSettings } from "../../../../shared/components/FormInternationalPayoutSettings";
import { LayoutSetup } from "../../../../shared/components/LayoutSetup";
import { RouteComponentProps } from "../../types";

export const RoutePayoutsInternational: React.FC<RouteComponentProps> = ({
  onBack,
  onNext
}) => {
  return (
    <LayoutSetup onBack={onBack}>
      <FormInternationalPayoutSettings onSuccess={onNext} />
    </LayoutSetup>
  );
};
