import React from "react";
import { WSAlert } from "@wingspanhq/fe-component-library";
import { IBeneficiaryVerificationResponse } from "@wingspanhq/payments/dist/interfaces";

type AccountBeneficiaryVerificationProps = IBeneficiaryVerificationResponse;

export const AccountBeneficiaryVerificationAlert: React.FC<AccountBeneficiaryVerificationProps> = ({
  reasonCode,
  actualName
}) => {
  switch (reasonCode) {
    // warnings
    case "AV300":
      return (
        <WSAlert theme="warning" title="Possible name mismatch">
          {actualName ? (
            <>
              The details you've provided closely match our records, but the
              account holder name is {actualName}. Sending payments to an
              incorrect account may result in unrecoverable funds. Please
              carefully verify the account information before proceeding, or
              click 'Continue' if you're certain the details are correct.
            </>
          ) : (
            <>
              The details you've provided closely match our records, but there
              may be a discrepancy with the account holder name. Sending
              payments to an incorrect account may result in unrecoverable
              funds. Please carefully verify the account information before
              proceeding, or click 'Continue' if you're certain the details are
              correct.
            </>
          )}
        </WSAlert>
      );
    case "AV301":
      return (
        <WSAlert theme="warning" title="Possible account type mismatch">
          {actualName ? (
            <>
              The details you've provided closely match our records, but the
              account belongs to a business named {actualName}. Sending payments
              to an incorrect account may result in unrecoverable funds. Please
              carefully verify the account information before proceeding, or
              click 'Continue' if you're certain the details are correct.
            </>
          ) : (
            <>
              The details you've provided closely match our records, but the
              account belongs to a different business name. Sending payments to
              an incorrect account may result in unrecoverable funds. Please
              carefully verify the account information before proceeding, or
              click 'Continue' if you're certain the details are correct.
            </>
          )}
        </WSAlert>
      );
    case "AV302":
      return (
        <WSAlert theme="warning" title="Possible account type mismatch">
          {actualName ? (
            <>
              The details you've provided closely match our records, but the
              account belongs to an individual named {actualName}. Sending
              payments to an incorrect account may result in unrecoverable
              funds. Please carefully verify the account information before
              proceeding, or click 'Continue' if you're certain the details are
              correct.
            </>
          ) : (
            <>
              The details you've provided closely match our records, but the
              account belongs to a different individual name. Sending payments
              to an incorrect account may result in unrecoverable funds. Please
              carefully verify the account information before proceeding, or
              click 'Continue' if you're certain the details are correct.
            </>
          )}
        </WSAlert>
      );
    case "AV303":
      return (
        <WSAlert theme="warning" title="Account type mismatch">
          The details you've provided closely match our records, but the account
          is registered as a business account, not an individual account (or
          vice versa). Sending payments to an incorrect account may result in
          unrecoverable funds. Please carefully verify the account information
          before proceeding, or click 'Continue' if you're certain the details
          are correct.
        </WSAlert>
      );
    case "AV304":
      return (
        <WSAlert theme="warning" title="Account type mismatch">
          The details you've provided closely match our records, but the account
          is registered as a individual account, not a company account. Sending
          payments to an incorrect account may result in unrecoverable funds.
          Please carefully verify the account information before proceeding, or
          click 'Continue' if you're certain the details are correct.
        </WSAlert>
      );
    case "AV305":
      return (
        <WSAlert theme="warning" title="Account has been transferred">
          The details you've provided closely match our records, but it appears
          this account has been transferred to a different bank. Sending
          payments to an incorrect account may result in unrecoverable funds.
          Please carefully verify the account information is up-to-date before
          proceeding, or click 'Continue' if you're certain the details are
          correct.
        </WSAlert>
      );
    // errors
    case "AV200":
    case "AV201":
    case "AV202":
    case "AV203":
    case "AV204":
    case "AV205":
    case "AV206":
    case "AV207":
      return (
        <WSAlert theme="error" title="Unable to verify account details">
          We couldn't match the account number and sort code you provided with
          our records. Sending payments to an incorrect account may result in
          unrecoverable funds. Please carefully verify the information you've
          entered and make any necessary updates. If you're certain the details
          are correct, you can click 'Continue'.
        </WSAlert>
      );
    // service errors
    case "SERVICE_ERROR":
    default:
      return (
        <WSAlert
          theme="error"
          title="We were unable to verify your account information"
        >
          We're unable to verify your account details at the moment due to a
          temporary issue. Please note that sending payments to an incorrect
          account may result in unrecoverable funds. You can try again later or
          click 'Continue' if you're confident the details are correct.
        </WSAlert>
      );
  }
};
