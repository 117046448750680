import classNames from "classnames";
import React from "react";
import { WSElement, WSMarginProps } from "../WSElement/WSElement.component";
import { WSButton, WSButtonProps } from "../core/WSButton/WSButton.component";
import { WSText } from "../core/WSText/WSText.component";
import styles from "./WSAlert.module.scss";
import { WSIcon, WSIconName } from "../core/WSIcon/WSIcon.component";
import { WSAvatar, WSAvatarProps } from "../common/WSAvatar/WSAvatar.component";

export type WSAlertTheme = "neutral" | "info" | "success" | "warning" | "error";
export type WSAlertSize = "S" | "M";

export type WSAlertAction = {
  onClick: WSButtonProps<"Link">["onClick"];
  loading?: WSButtonProps<"Link">["loading"];
  text: string;
};

export interface WSAlertProps extends WSMarginProps {
  className?: string;
  theme?: WSAlertTheme;
  size?: WSAlertSize;
  icon?: WSIconName;
  avatar?: WSAvatarProps;
  title?: string;
  action?: WSAlertAction;
  onDismiss?: () => void;
}

export const WSAlert: React.FC<WSAlertProps> = ({
  theme = "neutral",
  size = "M",
  title,
  className,
  action,
  onDismiss,
  icon,
  avatar,
  children,
  ...elementProps
}) => {
  return (
    <WSElement
      className={classNames(
        styles.alert,
        styles[`type-${theme}`],
        styles[`size-${size}`],
        className
      )}
      {...elementProps}
    >
      {icon || avatar ? (
        <WSElement className={styles.iconWrapper}>
          {icon ? (
            <WSIcon
              size={size === "S" ? "S" : "M"}
              name={icon}
              className={styles.icon}
            />
          ) : null}
          {avatar ? <WSAvatar {...avatar} /> : null}
        </WSElement>
      ) : null}
      <WSElement className={styles.content}>
        {title ? (
          <WSText
            className={styles.title}
            weight="medium"
            kind={size === "S" ? "ParagraphXs" : "ParagraphSm"}
          >
            {title}
          </WSText>
        ) : null}
        {children ? (
          <WSText
            mt={title ? "XS" : "NONE"}
            kind={size === "S" ? "ParagraphXs" : "ParagraphSm"}
            color="gray600"
          >
            {children}
          </WSText>
        ) : null}
        {action ? (
          <WSButton.Link
            type="button"
            className={styles.action}
            size={size === "S" ? "S" : "M"}
            onClick={action.onClick}
            loading={action.loading}
            rightIcon="chevron-right"
            mt={title || children ? "XS" : "NONE"}
          >
            {action.text}
          </WSButton.Link>
        ) : null}
      </WSElement>
      {typeof onDismiss === "function" ? (
        <WSElement className={styles.closeWrapper}>
          <WSIcon
            size={size === "S" ? "S" : "M"}
            name="exit"
            className={styles.close}
            onClick={onDismiss}
          />
        </WSElement>
      ) : null}
    </WSElement>
  );
};
