import { EntityType } from "../types";

export const getEntityTypeLabel = (type: EntityType, country: string) => {
  if (!type) {
    throw new Error("Invalid");
  }

  if (type === "Business") {
    if (country === "US") {
      return "Business - I operate with an Employer Identification Number (EIN)";
    }

    if (country === "CA") {
      return "Business - I operate with a Business Number (BN)";
    }

    return "Business";
  }

  if (type === "Individual") {
    if (country === "US") {
      return "Individual - I operate with a Social Security Number (SSN)";
    }

    if (country === "CA") {
      return "Individual - I operate with a Social Insurance Number (SIN)";
    }

    return "Individual";
  }
};
