import {
  IPayerTaxFormResponse,
  ITaxFormRedactedEvent,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
import { TextBulleted } from "../../../../../shared/components/TextBulleted";
import {
  CorrectionType,
  ITaxFormSubmissionData,
  TaxFormDocumentType,
  TaxFormEventDriver,
  TaxFormEventType
} from "@wingspanhq/payments/dist/interfaces/taxForm";
import { getChangedData } from "../../../../../utils/getChangedData";
import isArray from "lodash/isArray";
import concat from "lodash/concat";
import flatMap from "lodash/flatMap";
import isPlainObject from "lodash/isPlainObject";
import keys from "lodash/keys";
import map from "lodash/map";
import get from "lodash/get";
import startCase from "lodash/startCase";
import { selectorMemberName } from "../../../../../shared/selectors/selectorMemberName";
import {
  toWSMoneyString,
  WSButton,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { getFillingStatusBadgeProps } from "../../../components/FillingStatusBadge";
import { useTaxFormDownloadDocument } from "../../../../../query/taxForm/mutations/useTaxFormDownloadDocument";
import { TaxFormViewer } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { downloadFileFromBlob } from "../../../../../utils/files";

function paths(obj: Record<any, any>, parentKey?: string | number): Array<any> {
  let result: Array<any> = [];
  if (isArray(obj)) {
    let idx = 0;
    result = flatMap(obj, obj => {
      return paths(obj, (parentKey || "") + "[" + idx++ + "]");
    });
  } else if (isPlainObject(obj)) {
    result = flatMap(keys(obj), function(key) {
      return map(paths(obj[key], key), function(subkey) {
        return (parentKey ? parentKey + "." : "") + subkey;
      });
    });
  } else {
    result = [];
  }
  return concat(result, parentKey || []);
}

const moneyNumbers = new Set([
  "totalAmount",
  "platformIncome",
  "paymentProcessingFees",
  "adjustments"
]);

export function renderValue(key: string, value: any) {
  if ((key === "ssn" || key === "ein") && typeof value === "string") {
    return `•••• ${value?.slice(-4)}`;
  }

  if (key === "tinType" && value === TinType.Individual) {
    return "SSN";
  }

  if (key === "tinType" && value === TinType.Business) {
    return "EIN";
  }

  if (key === "companyStructure" && typeof value === "string") {
    return startCase(value);
  }

  if (
    value === null ||
    value === undefined ||
    (typeof value === "string" && value.trim() === "") ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return null;
  }

  if (typeof value === "number" && moneyNumbers.has(key)) {
    return toWSMoneyString(value);
  }

  if (typeof value === "number") {
    return `${value}`;
  }

  const string = JSON.stringify(value);
  return string.slice(1, -1);
}

export function renderName(key: string) {
  const fieldName = startCase(key).trim();

  switch (key) {
    case "ein":
      return "EIN";
    case "ssn":
      return "SSN";
    case "tinType":
      return "TIN Type";
    case "totalAmount":
      return "Amount";
    case "dob":
    case "ssnLastFour":
    case "einLastFour":
    case "adjustments":
      return null;
    default: {
      return fieldName;
    }
  }
}

const getBadges = (
  event: ITaxFormRedactedEvent,
  taxForm: IPayerTaxFormResponse
) => {
  switch (event.triggeredBy) {
    case TaxFormEventDriver.System:
      return ["Automated"];
    case TaxFormEventDriver.Payer:
      return [
        (event.eventActor && selectorMemberName(event.eventActor)) ||
          (taxForm.payer && selectorMemberName(taxForm.payer)) ||
          "Payer"
      ];
    case TaxFormEventDriver.Payee:
      return [
        (event.eventActor && selectorMemberName(event.eventActor)) ||
          (taxForm.payee && selectorMemberName(taxForm.payee)) ||
          "Payee"
      ];
    default:
      return ["Unknown"];
  }
};

type StandardEventTypes = Exclude<
  TaxFormEventType,
  TaxFormEventType.RecipientUpdatedData | TaxFormEventType.PayerUpdatedData
>;

const TEXTS: {
  [key in StandardEventTypes]: string;
} = {
  [TaxFormEventType.CorrectionSubmitted]: "Correction submitted",
  [TaxFormEventType.CorrectionAcceptedByIRS]: "Corrections accepted by the IRS",
  [TaxFormEventType.CorrectionRejectedByIrs]: "Corrections rejected by the IRS",
  [TaxFormEventType.CorrectionRejected]: "Correction rejected",
  [TaxFormEventType.CorrectionRequested]: "Correction requested",
  [TaxFormEventType.Emailed1099CopyDelivered]: "Emailed 1099 copy delivered",
  [TaxFormEventType.Emailed1099CopyDownloaded]: "Emailed 1099 copy downloaded",
  [TaxFormEventType.Emailed1099CopyOpened]: "Emailed 1099 copy opened",
  [TaxFormEventType.FormAcceptedByIRS]: "Form accepted by IRS",
  [TaxFormEventType.FormRejectedByIRS]: "Form rejected by IRS",
  [TaxFormEventType.FormSubmittedToIRS]: "Form submitted to IRS",
  [TaxFormEventType.Mailed1099CopySent]: "Mailed 1099 copy sent",
  [TaxFormEventType.Mailed1099CopyDelivered]: "Mailed 1099 copy delivered",
  [TaxFormEventType.Mailed1099CopyReturnedToSender]:
    "Mailed 1099 copy returned to sender",
  [TaxFormEventType.Remailed1099CopyDelivered]: "Remailed 1099 copy delivered",
  [TaxFormEventType.TINVerificationFailed]: "TIN verification failed",
  [TaxFormEventType.InvitationSent]: "Tax information confirmation invite sent",
  [TaxFormEventType.InvitationOpened]:
    "Tax information confirmation invite opened",
  [TaxFormEventType.RecipientSignedUp]: "Recipient signed up",
  [TaxFormEventType.RecipientSharedW9Info]: "Recipient shared W-9 information",
  [TaxFormEventType.RecipientSyncEnabled]: "Automatic sync enabled",
  [TaxFormEventType.TINVerificationRequested]: "TIN verification requested",
  [TaxFormEventType.TINVerificationSucceeded]: "TIN verified by IRS",
  [TaxFormEventType.PayerManuallyUpdatedStatus]: "Status updated by payer",
  [TaxFormEventType.AutomaticallyUpdatedStatus]: "Status updated automatically"
};

export const getTimeLineList = (taxForm: IPayerTaxFormResponse) => {
  return [...taxForm.eventHistory]
    .reverse()
    .map(eventHistory => getTimelineItem(eventHistory, taxForm)!)
    .filter(Boolean);
};

const DownloadButton: React.FC<{
  submissionId: string;
  taxFormId: string;
  documentType?: TaxFormDocumentType;
}> = ({ taxFormId, submissionId, documentType }) => {
  const [downloadDocument, downloadDocumentMeta] = useTaxFormDownloadDocument();

  return (
    <WSButton.Link
      size="S"
      onAsyncClick={async () => {
        await downloadDocument(
          {
            viewer: TaxFormViewer.payer,
            taxFormId,
            submissionId,
            documentType
          },
          {
            throwOnError: true,
            onSuccess(pdf) {
              downloadFileFromBlob(pdf, `Form 1099-NEC payer copy.pdf`);
            }
          }
        );
      }}
    >
      download
    </WSButton.Link>
  );
};
export const getTimelineItem = (
  event: ITaxFormRedactedEvent,
  taxForm: IPayerTaxFormResponse
) => {
  const payerName =
    (event.eventActor && selectorMemberName(event.eventActor)) ||
    (taxForm.payer && selectorMemberName(taxForm.payer)) ||
    "payer";

  const recipientName =
    (event.eventActor && selectorMemberName(event.eventActor)) ||
    (taxForm.payee && selectorMemberName(taxForm.payee)) ||
    "recipient";

  const actorName =
    (event.eventActor && selectorMemberName(event.eventActor)) ||
    event.triggeredBy === TaxFormEventDriver.Payer
      ? payerName
      : recipientName;

  const correction = taxForm.corrections?.find(
    correction => correction.taxFormCorrectionId === event.correctionId
  );

  if (
    [
      TaxFormEventType.RecipientUpdatedData,
      TaxFormEventType.PayerUpdatedData
    ].includes(event.eventType)
  ) {
    const changedData =
      getChangedData(event.previousData || event.data, event.data) || {};

    const changedFields = (paths(changedData) as Array<
      keyof ITaxFormSubmissionData
    >)
      .map((key, index, allPaths) => {
        const prevData = get(event.previousData, key);
        const nextData = get(event.data, key);
        const subKey = key
          .split(".")
          .pop()
          ?.trim()!;
        const fieldName = renderName(subKey);
        const prevValue = renderValue(subKey, prevData);
        const nextValue = renderValue(subKey, nextData);

        if (!fieldName || nextValue === null || prevValue === nextValue) {
          return null;
        }

        if (allPaths.some(path => path.startsWith(`${key}.`))) {
          return null;
        }

        return {
          prevValue,
          nextValue,
          fieldName,
          prevData,
          nextData,
          subKey,
          key
        };
      })
      .filter(Boolean);

    if (changedFields.length === 0) {
      return null;
    }

    return {
      date: event.timestamp,
      text: (
        <>
          Information updated by{" "}
          {event.eventType === TaxFormEventType.PayerUpdatedData
            ? payerName
            : recipientName}
          <TextBulleted color="gray600" mt="M">
            {changedFields.map(data => {
              const { fieldName, prevData, nextData, subKey, key } = data!;

              return prevData ? (
                <li key={key}>
                  {fieldName} updated from {renderValue(subKey, prevData)} to{" "}
                  {nextData === null || nextData === undefined
                    ? "empty"
                    : renderValue(subKey, nextData)}
                </li>
              ) : (
                <li key={key}>
                  {nextData === null || nextData === undefined ? (
                    <>{fieldName} set to empty</>
                  ) : (
                    <>
                      {fieldName} set to {renderValue(subKey, nextData)}
                    </>
                  )}
                </li>
              );
            })}
          </TextBulleted>
        </>
      ),
      badges: getBadges(event, taxForm)
    };
  } else if (
    [
      TaxFormEventType.AutomaticallyUpdatedStatus,
      TaxFormEventType.PayerManuallyUpdatedStatus
    ].includes(event.eventType) &&
    event.status
  ) {
    const prevStatus =
      event.previousStatus &&
      getFillingStatusBadgeProps(event.previousStatus).text;
    const nextStatus = getFillingStatusBadgeProps(event.status).text;

    return {
      date: event.timestamp,
      text: (
        <>
          {event.triggeredBy === TaxFormEventDriver.System ? null : (
            <WSText.ParagraphSm color="gray600" mb="S">
              <>Information updated by {actorName}</>
            </WSText.ParagraphSm>
          )}
          <TextBulleted color="gray600" mt="M">
            {prevStatus ? (
              <li>
                Status updated from {prevStatus} to {nextStatus}
              </li>
            ) : (
              <li>
                {!nextStatus ? (
                  <>Status set to empty</>
                ) : (
                  <>Status set to {nextStatus}</>
                )}
              </li>
            )}
          </TextBulleted>
        </>
      ),
      badges: getBadges(event, taxForm)
    };
  } else if (TaxFormEventType.CorrectionRequested === event.eventType) {
    const isPayee = event.triggeredBy === TaxFormEventDriver.Payee;

    const correctionReason = isPayee
      ? correction?.payeeOwnedData?.reasonComment
      : correction?.payerOwnedData?.comment;

    return {
      date: event.timestamp,
      text: (
        <>
          <WSText.ParagraphSm color="gray600" mb="S">
            {actorName} submitted correction request
          </WSText.ParagraphSm>
          {correctionReason ? (
            <WSMessageBox.Info noBorder color="gray600" mt="S">
              <TextBulleted color="gray600">
                "{correctionReason}" - {actorName}
              </TextBulleted>
            </WSMessageBox.Info>
          ) : null}
        </>
      ),
      badges: getBadges(event, taxForm)
    };
  } else if (TaxFormEventType.CorrectionSubmitted === event.eventType) {
    const submission = taxForm.submissions?.find(submission => {
      return submission.taxFormSubmissionId === event.submissionId;
    });

    return {
      date: event.timestamp,
      text: (
        <>
          <WSText.ParagraphSm color="gray600" mb="S">
            {actorName} submitted correction to the IRS
          </WSText.ParagraphSm>
          <TextBulleted color="gray600" mt="S">
            {correction?.correctionType &&
              [CorrectionType.Type1, CorrectionType.Type2].includes(
                correction?.correctionType
              ) && (
                <li>
                  Type
                  {correction?.correctionType === CorrectionType.Type1
                    ? " 1 "
                    : " 2 "}
                  correction filed with the IRS
                </li>
              )}
            {correction?.correctionType === CorrectionType.Type1 &&
            submission ? (
              <li>
                Updated Form 1099 filed for recipient{" "}
                <DownloadButton
                  submissionId={submission.taxFormSubmissionId}
                  taxFormId={taxForm.taxFormId}
                />
              </li>
            ) : null}
            {correction?.correctionType === CorrectionType.Type2 &&
            submission ? (
              <>
                <li>
                  Updated Form 1099 filed for recipient{" "}
                  <DownloadButton
                    submissionId={submission.taxFormSubmissionId}
                    taxFormId={taxForm.taxFormId}
                    documentType={TaxFormDocumentType.Secondary}
                  />
                </li>
                <li>
                  Previous Form 1099 zero’d out to $0{" "}
                  <DownloadButton
                    submissionId={submission.taxFormSubmissionId}
                    taxFormId={taxForm.taxFormId}
                    documentType={TaxFormDocumentType.Primary}
                  />
                </li>
              </>
            ) : null}

            {correction?.payerOwnedData?.comment && (
              <li>
                Comment to recipient:
                <WSMessageBox.Info noBorder color="gray600" mt="XS">
                  <TextBulleted color="gray600">
                    "{correction?.payerOwnedData?.comment}"
                  </TextBulleted>
                </WSMessageBox.Info>
              </li>
            )}
          </TextBulleted>
        </>
      ),
      badges: getBadges(event, taxForm)
    };
  } else if (TaxFormEventType.CorrectionRejected === event.eventType) {
    return {
      date: event.timestamp,
      text: (
        <>
          <WSText.ParagraphSm color="gray600" mb="S">
            {actorName} rejected correction request
          </WSText.ParagraphSm>
          {correction?.payerOwnedData?.comment && (
            <>
              Comment to recipient:
              <WSMessageBox.Info noBorder color="gray600" mt="XS" mb="S">
                <TextBulleted color="gray600">
                  "{correction?.payerOwnedData?.comment}" - {actorName}
                </TextBulleted>
              </WSMessageBox.Info>
            </>
          )}
          {correction?.payerOwnedData?.commentInternal && (
            <>
              Internal note for team members:
              <WSMessageBox.Info noBorder color="gray600" mt="XS">
                <TextBulleted color="gray600">
                  "{correction?.payerOwnedData?.commentInternal}" - {actorName}
                </TextBulleted>
              </WSMessageBox.Info>
            </>
          )}
        </>
      ),
      badges: getBadges(event, taxForm)
    };
  } else {
    return {
      date: event.timestamp,
      text: TEXTS[event.eventType as StandardEventTypes],
      badges: getBadges(event, taxForm)
    };
  }
};
