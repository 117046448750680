import { IChannelNotificationResponse } from "@wingspanhq/notifications/dist/lib/interfaces";
import { selectorActiveNotifications } from "./selectorActiveNotifications";

import { NotificationHandleType } from "../components/Notifications";

export const selectorW9ConsentNotifications = (
  responses: IChannelNotificationResponse[]
) => {
  return selectorActiveNotifications(responses).filter(response => {
    return response.handle === NotificationHandleType.W9Consent;
  });
};
