import * as Yup from "yup";
import React, { useState } from "react";

import { ITransaction } from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";

import {
  useModalContext,
  useWSSnackbar,
  WSButton,
  WSButtons,
  WSFormOld,
  WSModal,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";

import { useUpdateTransaction } from "../../../../query/bookkeeping/mutations";
import { runner } from "./utils.";

type BookkeepingModalBulkRenameProps = {
  selectedTransactions: ITransaction[] | undefined;
  onActionFinish(): void;
};

export const BOOKKEEPING_MODAL_BULK_RENAME = "BOOKKEEPING_MODAL_BULK_RENAME";

export const BookkeepingModalBulkRename: React.FC = () => {
  const { closeModal } = useModalContext();
  const { openSnackbar } = useWSSnackbar();

  const [isUpdating, setIsUpdating] = useState(false);

  const [updateTransaction] = useUpdateTransaction();

  // TODO: Need to prevent closing during operation
  return (
    <WSModal
      blockClose={isUpdating}
      name={BOOKKEEPING_MODAL_BULK_RENAME}
      size="S"
    >
      {({
        selectedTransactions,
        onActionFinish
      }: BookkeepingModalBulkRenameProps) => (
        <>
          {selectedTransactions && selectedTransactions.length > 0 ? (
            <>
              <WSText.Heading5 mb="M">
                Rename {selectedTransactions.length} transaction
                {selectedTransactions.length > 1 && "s"}
              </WSText.Heading5>

              <WSFormOld
                defaultValues={{
                  name: ""
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string()
                    .max(100, "Must be at most 100 characters")
                    .required("Name is required")
                })}
                onSubmit={async formData => {
                  setIsUpdating(true);

                  await runner({
                    tryAction: async transaction =>
                      await updateTransaction(
                        {
                          transactionId: transaction.transactionId,
                          labels: {
                            ...transaction.labels,
                            overrideName: formData.name
                          }
                        },
                        {
                          throwOnError: true
                        }
                      ),
                    undoAction: async transaction =>
                      await updateTransaction({
                        transactionId: transaction.transactionId,
                        labels: transaction.labels.overrideName
                          ? transaction.labels
                          : ({
                              ...transaction.labels,
                              overrideName: null
                            } as any)
                      }),
                    successMessage: successItems =>
                      `${successItems.length} renamed to "${formData.name}"`,
                    failMessage: failedItems =>
                      `${failedItems.length} renames failed`,
                    openSnackbar,
                    items: selectedTransactions
                  });

                  setIsUpdating(false);
                  onActionFinish();
                  closeModal(BOOKKEEPING_MODAL_BULK_RENAME);
                }}
              >
                <WSFormOld.Field
                  my="XL"
                  label="Name"
                  name="name"
                  component={WSTextInput}
                />
                <WSButtons mt="M" forceFullWidth format="modal">
                  <WSButton fullWidth loading={isUpdating}>
                    Update
                  </WSButton>
                  <WSButton.Tertiary
                    disabled={isUpdating}
                    onClick={() => closeModal(BOOKKEEPING_MODAL_BULK_RENAME)}
                  >
                    Cancel
                  </WSButton.Tertiary>
                </WSButtons>
              </WSFormOld>
            </>
          ) : (
            closeModal(BOOKKEEPING_MODAL_BULK_RENAME)
          )}
        </>
      )}
    </WSModal>
  );
};
