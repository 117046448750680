import {
  IRequirement,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import { ISharedFileRequestResponse } from "@wingspanhq/shared-files/dist/lib/interfaces";
import { ContextItem } from "../../../../types";

export const getSharedFileFromCache = (
  requirement: IRequirement,
  contextItem: ContextItem
): ISharedFileRequestResponse | undefined => {
  return contextItem?.[RequirementType.SharedFile]?.find(sharedFile => {
    return sharedFile.sharedFileRequestId === requirement.dataSourceId;
  });
};
