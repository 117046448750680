import { copyText as wsCopyText } from "@wingspanhq/fe-component-library";
import { addNotification } from "../components/Notification/Notification";

export const copyText = async (text: string, successMessage?: string) => {
  await wsCopyText(text);
  if (successMessage) {
    addNotification({
      text: successMessage
    });
  }
};
