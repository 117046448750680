import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSMessageBox,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { useHistory } from "react-router";
import { TAX_DOCUMENTS_PATH } from "../..";
import { useRequestCorrectionWarningModal } from "../../components/RequestCorrectionWarningModal/useRequestCorrectionWarningModal";
import { selectorPayeeTaxFormPayerName } from "../../../1099NECFiling/selectors/selectorPayeeTaxFormPayerName";
import { useDownloadTaxDocument } from "../../components/useDownloadTaxDocument";
import { selectorTaxFormIsPDFDownloadAvailableForPayee } from "../../../1099NECFiling/selectors/selectorTaxFormIsPDFDownloadAvailableForPayee";
import React from "react";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { WSQueries } from "../../../../query/WSQuery";
import { Tooltip } from "../../../../components/Tooltip";
import { selectorPayeeTaxFormCanRequestCorrection } from "../../../1099NECFiling/selectors/selectorPayeeTaxFormCanRequestCorrection";

type Props = { taxForm: IPayeeTaxFormResponse } & WSElementProps;

export const Header: React.FC<Props> = ({ taxForm, ...elementProps }) => {
  const history = useHistory();
  const queryFeatureFlags = useFeatureFlags();
  const requestCorrectionWarningModal = useRequestCorrectionWarningModal();
  const downloadModal = useDownloadTaxDocument();

  const isCorrectionAvailable = selectorPayeeTaxFormCanRequestCorrection(
    taxForm
  );

  const onBack = () => {
    history.push(TAX_DOCUMENTS_PATH);
  };

  const payerName = selectorPayeeTaxFormPayerName(taxForm);

  return (
    <WSQueries queries={{ queryFeatureFlags }}>
      {({ queryFeatureFlagsData: featureFlags }) => {
        const actionButtons = (
          <>
            {featureFlags.recipientRequestCorrection ? (
              isCorrectionAvailable ? (
                <WSButton.Primary
                  mr="S"
                  size="S"
                  onClick={() => {
                    requestCorrectionWarningModal.open({
                      taxFormId: taxForm.taxFormId
                    });
                  }}
                >
                  Request correction
                </WSButton.Primary>
              ) : null
            ) : (
              <Tooltip
                trigger={
                  <WSButton.Tertiary mr="S" size="S">
                    Request correction
                  </WSButton.Tertiary>
                }
              >
                <WSText.ParagraphSm weight="medium" color="white">
                  Request correction
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="M" color="white">
                  Notice an error? You can request a correction starting
                  February 1st.
                </WSText.ParagraphSm>
              </Tooltip>
            )}

            {selectorTaxFormIsPDFDownloadAvailableForPayee(taxForm) && (
              <WSButton.Secondary
                size="S"
                onClick={() => {
                  downloadModal.open({
                    taxForm,
                    documentName: `1099-NEC_${taxForm.year}`
                  });
                }}
              >
                Download Form 1099-NEC
              </WSButton.Secondary>
            )}
          </>
        );

        return (
          <WSElement {...elementProps}>
            <WSScreen.Mobile>
              <WSButton.Link mb="M" icon="chevron-left" onClick={onBack}>
                Back
              </WSButton.Link>
            </WSScreen.Mobile>
            <WSScreen.TabletAndDesktop>
              <WSFlexBox.CenterY wrap="nowrap" justify="space-between" mb="2XL">
                <WSFlexBox.CenterY>
                  <WSText.ParagraphSm
                    weight="medium"
                    color="gray400"
                    onClick={onBack}
                  >
                    Tax documents
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm weight="medium" mx="M" color="gray400">
                    –
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm weight="medium">
                    {payerName}
                  </WSText.ParagraphSm>
                </WSFlexBox.CenterY>
                <WSFlexBox.CenterY>{actionButtons}</WSFlexBox.CenterY>
              </WSFlexBox.CenterY>
            </WSScreen.TabletAndDesktop>
            <WSFlexBox.CenterY>
              {taxForm.payer && (
                <WSText.Heading1 mr="2XL">
                  {payerName} {taxForm.year} Form 1099-NEC
                </WSText.Heading1>
              )}
            </WSFlexBox.CenterY>
            <WSScreen.Mobile>
              <WSFlexBox.CenterY mt="S">{actionButtons}</WSFlexBox.CenterY>
            </WSScreen.Mobile>

            {!isCorrectionAvailable ? (
              <WSMessageBox.Info noBorder p="M" my="M">
                <WSText.Heading4>
                  Correction request limit reached
                </WSText.Heading4>
                <WSText.ParagraphSm mt="M">
                  You have already submitted a correction request to the payer.{" "}
                  <br />
                  <br />
                  <b>Starting February 12th, 2024</b>, you will be notified when
                  the payer accepts or rejects your correction request. <br />
                  <br />
                  <b>
                    Only one (1) correction request can be made through Wingspan
                    to your payer for a tax year.
                  </b>{" "}
                  If there are additional errors to correct after the payer’s
                  response, please reach out to the payer directly to resolve
                  the issue. <br />
                  <br />
                  Your original 2023 Form 1099-NEC from {payerName} is
                  available.
                </WSText.ParagraphSm>
              </WSMessageBox.Info>
            ) : null}
          </WSElement>
        );
      }}
    </WSQueries>
  );
};
