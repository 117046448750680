import { WSFlexBox, WSLoader, WSText } from "@wingspanhq/fe-component-library";
import queryString from "query-string";
import React, { useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { paymentsService } from "../services/payments";
import { usersService } from "../services/users";
import {
  ClientWorkFlowStatus,
  InvoiceStatus,
  IPayableCreateRequest
} from "@wingspanhq/payments/dist/interfaces";
import { Currency } from "@wingspanhq/users/dist/lib/interfaces";

type RouteProps = RouteComponentProps;

type Props = RouteProps;

export const CreateTestPayable: React.FC<Props> = () => {
  const history = useHistory();

  // Allowed params:
  // c - count of payables to create
  const queries = queryString.parse(window.location.search);
  const [done, setDone] = React.useState(false);

  const run = async () => {
    try {
      const session = await usersService.session.get();

      if (session.userId) {
        const count = queries.c ? parseInt(queries.c as string) : 1;

        const collaborator = await paymentsService.collaborator.create({
          clientId: session.userId,
          memberCompany: `Test company`,
          memberEmail: `test__fe_e2e___${Date.now()}@wingspan.test`,
          memberName: `Test collaborator`
        });

        for (let i = 0; i < count; i++) {
          const randomChars = (Math.random() + 1).toString(36).substring(7);
          const randomDate = new Date(Date.now() - 24 * 60 * 60 * 1000 * i * 3);

          const params: IPayableCreateRequest = {
            collaboratorId: collaborator.collaboratorId,
            currency: Currency.USD,
            invoiceNotes: `Test notes ${randomChars}`,
            labels: {
              projectName: `Test project ${randomChars}`,
              invoiceType: "approvedInvoicesPayment"
            },
            client: {
              payDate: randomDate
            },
            creditFeeHandling: {
              memberPays: 0,
              clientPays: 100
            },
            dueDate: randomDate,
            lineItems: [
              {
                description: `Test line item ${randomChars}`,
                quantity: 1,
                totalCost: 99
              }
            ]
          };

          const payable = await paymentsService.payable.create(params);

          await paymentsService.payable.update(payable.payableId, {
            status: InvoiceStatus.Open,
            client: {
              workflowStatus: ClientWorkFlowStatus.Approved
            }
          });
        }
        setDone(true);
      } else {
        history.push("/member/sign-in");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    run();
  }, []);

  return (
    <WSFlexBox.Center py="5XL">
      {done ? (
        <WSText data-testid="successResult">Done!</WSText>
      ) : (
        <WSLoader.Spinner />
      )}
    </WSFlexBox.Center>
  );
};
