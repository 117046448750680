import {
  useWSModal,
  WSButton,
  WSButtons,
  WSElement,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

const ModalNotYouInfo: React.FC<{
  email: string;
  onClose(result: boolean): void;
}> = ({ onClose, email }) => {
  return (
    <WSElement mt="XS">
      <WSText color="gray600">
        To confirm your tax information and preferences, you must sign-in with
        the email address <b>{email}</b> where the invite email was sent,
        otherwise the correct information may not exist.
      </WSText>
      <WSText color="gray600" mt="M">
        Please ensure you are signing in with the correct email address. You can
        change your email later.
      </WSText>

      <WSButtons mt="2XL" forceFullWidth>
        <WSButton.Primary
          onClick={() => {
            onClose(true);
          }}
        >
          It's not me!
        </WSButton.Primary>
        <WSButton.Secondary onClick={() => onClose(false)}>
          Cancel
        </WSButton.Secondary>
      </WSButtons>
    </WSElement>
  );
};

export const useNotYouInfoModal = () =>
  useWSModal(ModalNotYouInfo, {
    title: "Not you?",
    size: "S"
  });
