import { WSInfoBox } from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { PATH_ONBOARDING_CHANGE } from "../../../../Onboarding";

export const InfoBoxChangeRequest: React.FC = () => {
  const history = useHistory();

  return (
    <WSInfoBox title="Please ensure your information is up to date and accurate">
      <ol>
        <li>Click edit to edit your information.</li>
        <li>
          Your account type and fields with a green checkmark cannot be edited
          once your account has been verified.
        </li>
        <li>
          To request an update to verified fields,{" "}
          <a
            onClick={() => {
              history.push(PATH_ONBOARDING_CHANGE);
            }}
          >
            submit a request here
          </a>
          .
        </li>
      </ol>
    </WSInfoBox>
  );
};
