import {
  IRequirementResponse,
  RequirementEmbeddedStatus
} from "@wingspanhq/payments/dist/interfaces";
import React, {
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode
} from "react";
import { RequirementContextType } from "../../types";
import { WSPillProps, WSPillTheme } from "@wingspanhq/fe-component-library";

type TableCellProps = {
  text?: string;
  secondaryText?: string;
  secondaryTextColor?: string;
  icon?: string;
  avatar?: any;
  pill?: any;
  truncationOptions?: {
    text?: {
      allowTwoLines?: boolean;
    };
  };
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export const warningPill = {
  theme: "warning" as WSPillTheme,
  text: "Pending",
  icon: true
};
export const completePill = {
  theme: "success" as WSPillTheme,
  text: "Complete",
  icon: true
};
export const failedPill = {
  theme: "error" as WSPillTheme,
  text: "Failed",
  icon: true
};

export const getOverrideStatusDetails = (
  status: RequirementEmbeddedStatus | null,
  requirementContextType: RequirementContextType
): {
  secondaryText: string;
  pill: WSPillProps;
} => {
  switch (status) {
    case RequirementEmbeddedStatus.Complete:
      return {
        secondaryText:
          requirementContextType === RequirementContextType.Payee
            ? "Marked complete by you"
            : "Marked complete by client",
        pill: completePill
      };
    case RequirementEmbeddedStatus.Incomplete:
      return {
        secondaryText:
          requirementContextType === RequirementContextType.Payee
            ? "Marked failed by you"
            : "Marked failed by client",
        pill: failedPill
      };
    default:
      return {
        secondaryText: "Background check required",
        pill: warningPill
      };
  }
};

export const getOriginalRequirementStatus = (
  status: RequirementEmbeddedStatus | null
): {
  pill: WSPillProps;
} => {
  switch (status) {
    case RequirementEmbeddedStatus.Complete:
      return {
        pill: completePill
      };
    case RequirementEmbeddedStatus.Incomplete:
      return {
        pill: warningPill
      };
    default:
      return {
        pill: warningPill
      };
  }
};

export const useRequirementOverrideStatuses = (
  requirementContextType: RequirementContextType
) => {
  return (
    item: IRequirementResponse,
    statusNodeCell: ReactNode
  ): ReactNode | null => {
    if (!item?.payerOverrideStatus || !isValidElement(statusNodeCell)) {
      return null;
    }

    const overrideStatusDetails = getOverrideStatusDetails(
      item.payerOverrideStatus,
      requirementContextType
    );

    const overriddenStatusCell = cloneElement(
      statusNodeCell as ReactElement<TableCellProps>,
      {
        secondaryText: overrideStatusDetails.secondaryText,
        pill: overrideStatusDetails.pill
      }
    );

    return overriddenStatusCell;
  };
};
