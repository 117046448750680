import { useUserProfileData } from "./utils/useUserProfileData";
import { E_SIGNATURE_AGREEMENT_TEMPLATES_INFO } from "./helpers";

export const useESignatureAgreementTemplatesInfo = () => {
  const userData = useUserProfileData();
  const ownerIds = userData?.userRoles?.ownerIds || [];

  return E_SIGNATURE_AGREEMENT_TEMPLATES_INFO.find(({ ids }) => {
    return ownerIds.length
      ? ownerIds.some(ownerId => ids.some(id => ownerId?.includes(id)))
      : false;
  });
};
