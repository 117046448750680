import { useHistory } from "react-router-dom";
import { OverlaySpinner } from "../../../../components/OverlaySpinner";
import { WSQueries } from "../../../../query/WSQuery";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { QUERY_USERS_ACTIVITIES } from "../../../../query/users/keys";
import { useActivities, useMemberProfile } from "../../../../query/users/queries";
import { usersService } from "../../../../services/users";
import { FlowSetup } from "../../../../shared/components/FlowSetup";
import { useWSAnalytics } from "../../../../utils/WSAnalytics";
import { RouteGenerateForms } from "./routes/RouteGenerateForms";
import { RouteSetupImportRecipientsQuestion } from "./routes/RouteSetupImportRecipientsQuestion";
import { RouteSetupCalculationSettings } from "./routes/RouteSetupCalculationSettings";
import { RouteSetupStateFiling } from "./routes/RouteSetupStateFiling";
import { RouteSetupPayerInformation } from "./routes/RouteSetupPayerInformation";
import { RouteSetupImportRecipients } from "./routes/RouteSetupImportRecipients";
import { getRedirectPath } from "./utils/getRedirectPath";
import { useEffect } from "react";
import { IActivity } from "@wingspanhq/users";

type PayerSetupFlowProps = {
  basePath: string;
  onBack: () => Promise<void> | void;
  onFinish: () => Promise<void> | void;
};
export const PayerSetupFlow: React.FC<PayerSetupFlowProps> = ({
  basePath,
  onBack,
  onFinish
}) => {
  const history = useHistory();
  const userId = useUserId();
  const qActivity = useActivities(userId);
  const memberQuery = useMemberProfile(userId);
  const { track } = useWSAnalytics();

  useEffect(() => {
    const redirectPath = getRedirectPath(qActivity.data as IActivity);
    history.push(redirectPath);
  }, [qActivity.data, history]);

  const [finishOnboarding, finishOnboardingMeta] = useWSMutation(
    async () => {
      await usersService.activity.update(userId, {
        flows: {
          nec1099Setup: {
            version: 1,
            currentStep: 4,
            totalSteps: 4,
            complete: true
          }
        }
      });

      track.other("Product activation");

      await onFinish();
    },
    {
      dependencies: [QUERY_USERS_ACTIVITIES]
    }
  );

  return (
    <WSQueries queries={{ memberQuery, qActivity }}>
      {({ memberQueryData: member }) => {
        return (
          <>
            {finishOnboardingMeta.isLoading && <OverlaySpinner />}

            <FlowSetup
              basePath={basePath}
              routes={[
                {
                  path: "/confirm-payer-info",
                  component: RouteSetupPayerInformation,
                  onBack: onBack,
                  onNext: () => {
                    history.push(basePath + "/select-import-recipients");
                  }
                },
                {
                  path: "/select-import-recipients",
                  component: RouteSetupImportRecipientsQuestion,
                  onBack: () => {
                    history.push(basePath + "/confirm-payer-info");
                  },
                  onNext: context => {
                    if (context?.shouldImportCollaborators) {
                      history.push(
                        basePath + `/recipients-import/${context?.bulkBatchId}`
                      );
                    } else {
                      history.push(basePath + "/calculation-settings");
                    }
                  }
                },
                {
                  path: "/recipients-import",
                  component: RouteSetupImportRecipients,
                  parentPath: basePath,
                  onBack: () => {
                    history.push(basePath + "/select-import-recipients");
                  },
                  onNext: context => {
                    history.push(basePath + "/calculation-settings");
                  }
                },
                {
                  path: "/calculation-settings",
                  component: RouteSetupCalculationSettings,
                  onBack: () => {
                    history.push(basePath + "/select-import-recipients");
                  },
                  onNext: () => {
                    history.push(basePath + "/state-filing-settings");
                  }
                },
                {
                  path: "/state-filing-settings",
                  component: RouteSetupStateFiling,
                  onBack: () => {
                    history.push(basePath + "/calculation-settings");
                  },
                  onNext: context => {
                    history.push(
                      basePath +
                        `/generate-forms/${context?.bulkCalculation1099BatchId}`
                    );
                  }
                },
                {
                  path: "/generate-forms/:bulkCalculation1099BatchId",
                  component: RouteGenerateForms,
                  onBack: () => {
                    history.push(basePath + "/state-filing-settings");
                  },
                  onNext: () => {
                    finishOnboarding();
                  }
                }
              ]}
            />
          </>
        );
      }}
    </WSQueries>
  );
};
