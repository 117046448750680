import {
  toWSDateString,
  WSElement,
  WSElementProps,
  WSLoader
} from "@wingspanhq/fe-component-library";
import {
  ICollaboratorSchema,
  IPayableSchema
} from "@wingspanhq/payments/dist/interfaces";
import { IAuthorizationListResponse, INewUser } from "@wingspanhq/users";
import React, { useMemo } from "react";
import { useUserId } from "../../../query/hooks/helpers";
import { useCollaboratorQuery } from "../../../query/payments/queries";
import {
  useAuthorizations,
  useUserProfile
} from "../../../query/users/queries";
import { usePayableQuery } from "../../screens/payables/PayableDetails";
import {
  InvoiceActivity,
  InvoiceDetailsActivity
} from "../InvoiceDetailsActivity/InvoiceDetailsActivity";

type Props = { payableId: string; collaboratorId: string } & WSElementProps;

export const PayableActivity: React.FC<Props> = ({
  payableId,
  collaboratorId,
  ...elementProps
}) => {
  const userId = useUserId();
  const queryPayable = usePayableQuery(payableId);
  const queryCollaborator = useCollaboratorQuery(collaboratorId);
  const queryUser = useUserProfile(userId);
  const queryAuthorization = useAuthorizations();

  if (!queryPayable.data || !queryCollaborator.data || !queryUser.data) {
    return (
      <WSElement {...elementProps}>
        <WSLoader.Spinner />
      </WSElement>
    );
  }

  return (
    <WSElement {...elementProps}>
      <View
        payable={queryPayable.data}
        collaborator={queryCollaborator.data}
        user={queryUser.data}
        authorizations={queryAuthorization.data}
      />
    </WSElement>
  );
};

const getEventActorEmail = (
  eventActorId: string | undefined,
  user: INewUser,
  authorizations?: IAuthorizationListResponse
) => {
  if (eventActorId) {
    const authorization = authorizations?.find(
      a => a.requestingUserId === eventActorId
    );

    if (authorization?.requestingUser.email) {
      return authorization.requestingUser.email;
    }
  }

  return user.email;
};

const View: React.FC<{
  payable: IPayableSchema;
  collaborator: ICollaboratorSchema;
  user: INewUser;
  authorizations?: IAuthorizationListResponse;
}> = ({ payable, collaborator, user, authorizations = [] }) => {
  const approverEmail = getEventActorEmail(
    payable.eventActors?.approvedBy,
    user,
    authorizations
  );
  const preApproverEmail = getEventActorEmail(
    payable.eventActors?.preApprovedBy,
    user,
    authorizations
  );
  const unapproverEmail = getEventActorEmail(
    payable.eventActors?.unapprovedBy,
    user,
    authorizations
  );

  const activities = useMemo(() => {
    const activities: InvoiceActivity[] = [];

    activities.push({
      title: "Payable was created",
      description: toWSDateString(payable.createdAt),
      date: payable.createdAt
    });

    if (payable.events.openedAt) {
      activities.push({
        title: `Sent to ${user.email}`,
        description: toWSDateString(payable.events.openedAt),
        date: payable.events.openedAt
      });
    }

    if (
      payable.events.emailReceivedAt &&
      payable.events.emailReceivedAt.length > 0
    ) {
      activities.push({
        title: `Received by ${user.email}`,
        description: toWSDateString(payable.events.emailReceivedAt[0]),
        date: payable.events.emailReceivedAt[0]
      });
    }

    // Work with viewed at only if it is before paidAt and depositedAt
    // Viewed event should never come after paid and deposited
    const allowedViewedAt = (payable.events.emailViewedAt || []).filter(date =>
      payable.events.paidAt
        ? payable.events.paidAt > date
        : true && payable.events.depositedAt
        ? payable.events.depositedAt > date
        : true
    );

    if (allowedViewedAt.length > 0) {
      allowedViewedAt.sort((a, b) => b.getTime() - a.getTime());

      const firstViewedAt = allowedViewedAt[allowedViewedAt.length - 1];

      if (firstViewedAt) {
        activities.push({
          title: `Viewed by ${user.email}`,
          description: toWSDateString(firstViewedAt),
          date: firstViewedAt
        });
      }

      const secondViewedAt = firstViewedAt
        ? allowedViewedAt.find(
            date => date.getTime() - firstViewedAt.getTime() > 60 * 60 * 1000
          )
        : undefined;

      if (secondViewedAt) {
        activities.push({
          title: `Viewed by ${user.email}`,
          description: toWSDateString(secondViewedAt),
          date: secondViewedAt
        });
      }
    }

    if (payable.events.approvedAt) {
      activities.push({
        title: `Approved by ${approverEmail}`,
        description: toWSDateString(payable.events.approvedAt),
        date: payable.events.approvedAt
      });
    }

    if (payable.events.unapprovedAt) {
      activities.push({
        title: `Unapproved by ${unapproverEmail}`,
        description: toWSDateString(payable.events.unapprovedAt),
        date: payable.events.unapprovedAt
      });
    }

    if (payable.events.preApprovedAt) {
      activities.push({
        title: `Pre-approved by ${preApproverEmail}`,
        description: toWSDateString(payable.events.preApprovedAt),
        date: payable.events.preApprovedAt
      });
    }

    if (payable.events.sentDueIn3DaysAt) {
      activities.push({
        title: `Reminder sent to ${user.email}`,
        description: toWSDateString(payable.events.sentDueIn3DaysAt),
        date: payable.events.sentDueIn3DaysAt
      });
    }

    if (payable.events.sentDueTodayAt) {
      activities.push({
        title: `Reminder sent to ${user.email}`,
        description: toWSDateString(payable.events.sentDueTodayAt),
        date: payable.events.sentDueTodayAt
      });
    }

    if (payable.events.sentDue3DaysAgoAt) {
      activities.push({
        title: `Reminder sent to ${user.email}`,
        description: toWSDateString(payable.events.sentDue3DaysAgoAt),
        date: payable.events.sentDue3DaysAgoAt
      });
    }

    if (payable.events.sentDue7DaysAgoAt) {
      activities.push({
        title: `Reminder sent to ${user.email}`,
        description: toWSDateString(payable.events.sentDue7DaysAgoAt),
        date: payable.events.sentDue7DaysAgoAt
      });
    }

    if (payable.events.paymentInTransitAt) {
      activities.push({
        title: `Payment in transit on ${toWSDateString(
          payable.events.paymentInTransitAt
        )}`,
        description: "Waiting on wire transfer to arrive",
        date: payable.events.paymentInTransitAt
      });
    }

    if (payable.events.paidAt) {
      activities.push({
        title: `Paid on ${toWSDateString(payable.events.paidAt)}`,
        description: payable.events.estimatedDepositAt
          ? `Expected deposit on ${toWSDateString(
              payable.events.estimatedDepositAt,
              "monthDayYear"
            )}`
          : "Payable was paid outside of Wingspan",
        date: payable.events.paidAt
      });
    }

    if (payable.events.memberDisputedAt) {
      activities.push({
        title: `Disputed by ${collaborator.member.user.email}`,
        description: `Dispute reason: ${payable.member.comment}`,
        date: payable.events.memberDisputedAt
      });
    }

    if (payable.events.clientResolvedDisputeAt) {
      activities.push({
        title: "Dispute resolved",
        description: `You resolved dispute on ${toWSDateString(
          payable.events.clientResolvedDisputeAt
        )}`,
        date: payable.events.clientResolvedDisputeAt
      });
    }

    if (payable.events.memberAcceptedAt) {
      activities.push({
        title: `Invoice accepted`,
        description: `Accepted by contractor on ${toWSDateString(
          payable.events.memberAcceptedAt,
          "monthDate"
        )}`,
        date: payable.events.memberAcceptedAt
      });
    }

    if (payable.events.clientDeclinedAt) {
      activities.push({
        title: `Rejected by ${user.email}`,
        description: `Reject reason: ${payable.client.comment}`,
        date: payable.events.clientDeclinedAt
      });
    }

    if (payable.events.memberResubmittedAt) {
      activities.push({
        title: `Resubmitted by ${collaborator.member.user.email}`,
        description: `Contractor resolved dispute at ${toWSDateString(
          payable.events.memberResubmittedAt
        )}`,
        date: payable.events.memberResubmittedAt
      });
    }

    return activities.sort((a, b) => {
      if (a.date > b.date) return -1;
      if (a.date < b.date) return 1;
      return 0;
    });
  }, [
    authorizations,
    collaborator.member.user.email,
    payable.client.comment,
    payable.createdAt,
    payable.eventActors.approvedBy,
    payable.events.approvedAt,
    payable.events.clientDeclinedAt,
    payable.events.clientResolvedDisputeAt,
    payable.events.depositedAt,
    payable.events.emailReceivedAt,
    payable.events.emailViewedAt,
    payable.events.estimatedDepositAt,
    payable.events.memberAcceptedAt,
    payable.events.memberDisputedAt,
    payable.events.memberResubmittedAt,
    payable.events.openedAt,
    payable.events.paidAt,
    payable.events.paymentInTransitAt,
    payable.events.sentDue3DaysAgoAt,
    payable.events.sentDue7DaysAgoAt,
    payable.events.sentDueIn3DaysAt,
    payable.events.sentDueTodayAt,
    payable.member.comment,
    user.email
  ]);

  return <InvoiceDetailsActivity activities={activities} />;
};
