import { DEFAULT_CUSTOMIZATION } from "./defaultData";
import { queryCache } from "react-query";
import { useUserId } from "../../query/hooks/helpers";
import defaultsDeep from "lodash/defaultsDeep";
import {
  QUERY_CUSTOMIZATION,
  QUERY_PUBLIC_CUSTOMIZATION
} from "../../query/customization/keys";
import { IPublicCustomizationSettingsResponse } from "@wingspanhq/users/dist/lib/interfaces/api/organizationAccountConfig";

type UseDefault = boolean;

type OptionalIfFalse<T, B extends UseDefault = true> = B extends false
  ? Partial<T> | undefined
  : T;

type Terminology = typeof DEFAULT_CUSTOMIZATION["terminology"];
type Branding = typeof DEFAULT_CUSTOMIZATION["branding"];
type Support = typeof DEFAULT_CUSTOMIZATION["support"];

export const useCustomization = (userId?: string) => {
  const sessionUserId = useUserId();

  const customizationData = sessionUserId
    ? queryCache.getQueryData<IPublicCustomizationSettingsResponse>([
        QUERY_CUSTOMIZATION
      ])
    : queryCache.getQueryData<IPublicCustomizationSettingsResponse>([
        QUERY_PUBLIC_CUSTOMIZATION,
        userId
      ]);

  return {
    terminology<B extends UseDefault = true>(
      useDefault?: B
    ): OptionalIfFalse<Terminology, B> {
      const currentUseDefault =
        typeof useDefault === "boolean" ? useDefault : true;

      if (currentUseDefault) {
        return defaultsDeep(
          {},
          customizationData?.terminology,
          DEFAULT_CUSTOMIZATION.terminology
        );
      } else {
        return customizationData?.terminology as OptionalIfFalse<
          Terminology,
          B
        >;
      }
    },
    branding<B extends UseDefault = true>(
      useDefault?: B
    ): OptionalIfFalse<Branding, B> {
      const currentUseDefault =
        typeof useDefault === "boolean" ? useDefault : true;

      if (currentUseDefault) {
        return defaultsDeep(
          {},
          customizationData?.branding,
          DEFAULT_CUSTOMIZATION.branding
        );
      } else {
        return customizationData?.branding as OptionalIfFalse<Branding, B>;
      }
    },
    support<B extends UseDefault = true>(
      useDefault?: B
    ): OptionalIfFalse<Support, B> {
      const currentUseDefault =
        typeof useDefault === "boolean" ? useDefault : true;

      if (currentUseDefault) {
        return defaultsDeep(
          {},
          customizationData?.support,
          DEFAULT_CUSTOMIZATION.support
        );
      } else {
        return customizationData?.support as OptionalIfFalse<Support, B>;
      }
    }
  };
};
