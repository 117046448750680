import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation, WSMutationConfig } from "../../helpers";
import { paymentsService } from "../../../services/payments";
import {
  ITaxFormRedeliveryRequest,
  ITaxFormResponse
} from "@wingspanhq/payments/dist/interfaces";

export const useTaxFormResendEmail = (
  config?: WSMutationConfig<
    ITaxFormResponse,
    WSServiceError,
    { taxFormId: string } & ITaxFormRedeliveryRequest
  >
) =>
  useWSMutation(
    ({ taxFormId, ...other }) =>
      paymentsService.taxForm.resendEmailCopy(taxFormId, other),
    {
      dependencies: [],
      ...config
    }
  );
