import {
  useIsTablet,
  WSCard,
  WSGrid,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { IBenefitCard, IBenefitCardSection } from "../../screens/BenefitsV3";

interface BenefitCardsSectionProps {
  cardsData: IBenefitCardSection;
}

export const BenefitCardsSection: React.FC<BenefitCardsSectionProps> = ({
  cardsData
}) => {
  const { title, cards } = cardsData;
  const isTablet = useIsTablet();
  return (
    <>
      {title && typeof title === "string" ? (
        <WSText.Heading4 mb="M" color="gray600">
          {title}
        </WSText.Heading4>
      ) : (
        title
      )}
      <WSGrid>
        {cards.map((card: IBenefitCard) => (
          <WSGrid.Item span={{ xs: "6", m: "4" }}>
            <WSCard
              header={{
                label: {
                  avatar: {
                    type: "icon",
                    colorBackground: "blue50",
                    color: "blue500",
                    icon: card.icon
                  }
                }
              }}
            >
              <WSText.ParagraphSm color="gray600">{}</WSText.ParagraphSm>
            </WSCard>
          </WSGrid.Item>
        ))}
      </WSGrid>
    </>
  );
};
