import {
  WSButton,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSPill,
  WSSelectOld,
  WSTable,
  WSText
} from "@wingspanhq/fe-component-library";
import { IAdditionalData } from "@wingspanhq/payments/dist/interfaces";
import {
  ILineItemsAgingReportResponse,
  ReportAgingType
} from "@wingspanhq/payments/dist/interfaces/api/reports";
import { formatMoney } from "accounting";
import React, { useMemo, useState } from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import { useOpenLineItemAgingReportsQuery } from "../../../../query/payments/queries";
import { VerticalDivider } from "../../../../shared/components/VerticalDivider";
import { track } from "../../../../utils/analytics";
import { DownloadReportButton } from "../../components/DownloadReportButton/DownloadReportButton";
import { useSelectColumnsModal } from "../../components/ModalSelectColumns";
import { useReportColumns } from "../../hooks/useReportColumns";
import { ReportFormat, ReportType } from "../../types";
import { formatReportsValue } from "../../utils/formatReportsValue";
import { selectorColumnsWithAdditionalData } from "../../utils/selectorColumnsWithAdditionalData";
import { COLUMNS, LineItemAgingTableItem } from "./columns";
import { selectorTableColumns } from "../../utils/selectorTableColumns";

const SELECTED_COLUMNS_STORAGE_KEY =
  "REPORTS_OPEN_LINE_ITEM_AGING_SELECTED_COLUMNS_STORAGE_KEY";

export const OpenLineItemAgingReportDashboard: React.FC<{
  customFieldsData: IAdditionalData[];
}> = ({ customFieldsData }) => {
  const userId = useUserId();
  const [selectedAgeGrouping, setSelectedAgeGrouping] = useState<
    ReportAgingType
  >(ReportAgingType.Weekly);

  const modalSelectColumns = useSelectColumnsModal();

  const columns = selectorColumnsWithAdditionalData<LineItemAgingTableItem>(
    COLUMNS,
    customFieldsData
  );

  const [selectedColumns, setSelectedColumns] = useReportColumns(
    SELECTED_COLUMNS_STORAGE_KEY,
    userId,
    columns
  );

  const getColumnByKey = (key: string) =>
    columns.find(_column => key === _column.value)!;

  const [reportsData, setReportsData] = useState<
    {
      [key in ReportAgingType]?: ILineItemsAgingReportResponse[];
    }
  >({});

  const currentReportsData = reportsData[selectedAgeGrouping!];

  const tableData = useMemo(() => {
    let result: LineItemAgingTableItem[] = [];

    if (currentReportsData) {
      const reports = currentReportsData || [];

      reports.forEach(({ lineItems, ...report }) => {
        lineItems.forEach(lineItem => {
          result.push({
            ...report,
            lineItem,
            key: `${report.invoiceId}-${lineItem.detail}-${lineItem.description}`
          });
        });
      });
    }

    return result;
  }, [selectedAgeGrouping, currentReportsData]);

  const isRunButtonDisabled = !selectedAgeGrouping || !selectedColumns.length;

  const isDownloadButtonDisabled = !tableData.length;

  const queryReport = useOpenLineItemAgingReportsQuery(
    {
      type: selectedAgeGrouping
    },
    {
      enabled: false
    }
  );

  const getReportJSON = () => {
    if (!tableData.length) {
      return [];
    }
    return [
      selectedColumns.map(getColumnByKey).map(c => c?.label),
      ...tableData
        .map(data => {
          return selectedColumns.map(column => {
            let result;
            const currentColumn = getColumnByKey(column);

            try {
              result = formatReportsValue(currentColumn?.getTableCell?.(data));
            } catch (e) {
              console.error(`REPORT ERROR! "${currentColumn?.label}": `, e);
              result = undefined;
            }

            return result;
          });
        })
        .filter(arr => arr.some(Boolean))
    ];
  };

  return (
    <>
      <WSDivider mb="M" />
      <WSGrid>
        <WSGrid.Item span={{ s: "4" }}>
          <WSSelectOld
            searchable={false}
            onChange={(v: any) => {
              setSelectedAgeGrouping(v);
            }}
            name="ageGrouping"
            value={selectedAgeGrouping as string}
            placeholder="Select age grouping"
            error={false}
            options={[
              {
                value: ReportAgingType.Weekly,
                label: "7-days"
              },
              {
                value: ReportAgingType.Monthly,
                label: "30-days"
              }
            ]}
          />
        </WSGrid.Item>
        <WSGrid.Item span={{ s: "8" }}>
          <WSFlexBox.CenterY>
            <VerticalDivider mr="XL" />
            <WSFlexBox.CenterY
              onClick={() =>
                modalSelectColumns.open({
                  selectedColumns,
                  columns,
                  onSubmit: ({ columns }) => {
                    setSelectedColumns(columns);
                  }
                })
              }
              data-testid="selectColumns"
            >
              <WSButton.Link icon="menu">Selected columns</WSButton.Link>
              <WSPill ml="M" theme="blue" text={`${selectedColumns.length}`} />
            </WSFlexBox.CenterY>

            <VerticalDivider mx="XL" />
            <WSButton.Primary
              name="runReport"
              disabled={isRunButtonDisabled}
              onAsyncClick={async () => {
                track("Report Ran", {
                  reportName: ReportType.OpenLineItemAging,
                  selectedColumnsCount: selectedColumns.length,
                  selectedColumns,
                  filters: { type: selectedAgeGrouping }
                });
                const result = await queryReport.refetch({
                  throwOnError: true
                });

                setReportsData(data => ({
                  ...data,
                  ...(result ? { [selectedAgeGrouping!]: result } : {})
                }));
              }}
            >
              Run report
            </WSButton.Primary>
          </WSFlexBox.CenterY>
        </WSGrid.Item>
      </WSGrid>
      <WSDivider mt="M" />
      <WSFlexBox.CenterY mt="2XL" mb="XL" justify="flex-end">
        <DownloadReportButton
          disabled={isDownloadButtonDisabled}
          getData={getReportJSON}
          onClick={(format: ReportFormat) => {
            track("Report Downloaded", {
              reportName: ReportType.OpenLineItemAging,
              selectedColumnsCount: selectedColumns.length,
              selectedColumns,
              filters: { type: selectedAgeGrouping },
              format
            });
          }}
          fileName={`Wingspan Open Age Grouping Report`}
          sheetName={`Sheet ${selectedAgeGrouping}`}
        />
      </WSFlexBox.CenterY>

      {tableData.length ? (
        <WSElement
          style={{
            overflowX: selectedColumns.length > 6 ? "scroll" : undefined,
            transform: "rotateX(180deg)"
          }}
        >
          <WSTable<LineItemAgingTableItem>
            mb="M"
            mt="XL"
            style={{
              minWidth:
                selectedColumns.length > 6
                  ? selectedColumns.length * 175
                  : undefined,
              transform: "rotateX(180deg)"
            }}
            getGroupName={item => {
              const amount = tableData
                .filter(({ ageGroup }) => {
                  return item.data.ageGroup === ageGroup;
                })
                .reduce((acc, { lineItem }) => {
                  return acc + (lineItem?.amount || 0);
                }, 0);

              return `${item.data.ageGroup} (${formatMoney(amount)} total)`;
            }}
            columns={selectorTableColumns(
              selectedColumns.map(getColumnByKey).filter(Boolean)
            )}
            tableData={tableData.map((data, i) => ({
              data,
              id: `id_${i}_${data.invoiceId}`
            }))}
          />
        </WSElement>
      ) : (
        <WSFlexBox.Center mt="2XL">
          <WSText color="gray500">
            {currentReportsData?.length === 0
              ? `No invoices available`
              : `Select a grouping type then click 'Run Report'`}
          </WSText>
        </WSFlexBox.Center>
      )}
    </>
  );
};
