import cn from "classnames";
import React, { useCallback, useRef } from "react";
import { toCamelCase } from "../../../../utils";
import {
  detachLayoutProps,
  WSElement,
  WSElementProps
} from "../../WSElement/WSElement.component";
import styles from "./WSTextArea.module.scss";

export interface WSTextAreaProps extends Omit<WSElementProps, "onChange"> {
  name: string;
  placeholder?: string;
  error?: boolean;
  inputClassName?: string;
  value?: string;
  onChange?: (value: string) => void;
}

export const WSTextArea = React.forwardRef<HTMLSpanElement, WSTextAreaProps>(
  (props, ref) => {
    const {
      error = false,
      className = "",
      inputClassName = "",
      layoutProps,
      systemProps,
      value,
      onChange,
      onBlur,
      onKeyUp,
      onKeyDown,
      ...inputProps
    } = detachLayoutProps(props);
    const containerClassNames = cn(styles.inputContainer, className, {
      [styles.error]: error
    });

    const defaultValue = useRef(value);

    const inputClassNames = cn(styles.input, inputClassName);

    const emitChange = useCallback(
      (event) => {
        if (event?.target) {
          const newValue = event.target.innerText;

          if (onChange && newValue !== value) {
            onChange(newValue);
          }
        }
      },
      [onChange, value]
    );

    return (
      <WSElement
        {...systemProps}
        className={containerClassNames}
        {...layoutProps}
      >
        <WSElement
          className={inputClassNames}
          ref={ref}
          data-testid={toCamelCase(inputProps.name, "field")}
          role="textbox"
          onInput={emitChange}
          onBlur={onBlur || emitChange}
          onKeyUp={onKeyUp || emitChange}
          onKeyDown={onKeyDown || emitChange}
          dangerouslySetInnerHTML={{
            __html: defaultValue.current?.replace(/\n/g, "<br />") || ""
          }}
          contentEditable
          {...inputProps}
        />
      </WSElement>
    );
  }
);

WSTextArea.displayName = "WSTextArea";
