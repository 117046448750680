import { validatorStringGeneric } from "./validatorStringGeneric";

const regExp = new RegExp("^[ a-zA-Z0-9\u00C0-\u017F'\\#’.,:;()?&*-]+$");

export const validatorStringTextNumbersSymbols = validatorStringGeneric.matches(
  regExp,
  {
    excludeEmptyString: true,
    message:
      "May contain only characters: a-z, A-Z, 0-9, #, ', ., &, -, inner spaces and accented characters"
  }
);
