import { useWSMutation } from "../../helpers";
import { createBackgroundChecksSettings } from "../../../services/backgroundChecks/createBackgroundChecksSettings";
import { QUERY_BACKGROUND_CHECKS_SETTINGS } from "../queries/useBackgroundChecksSettingsQuery";

export const useCreateBackgroundCheckSettings = () =>
  useWSMutation(createBackgroundChecksSettings, {
    dependencies(response, request) {
      return [
        QUERY_BACKGROUND_CHECKS_SETTINGS,
        response.backgroundCheckPackageId
      ];
    }
  });
