import {
  ChannelMessageStatus,
  IChannelNotificationResponse
} from "@wingspanhq/notifications/dist/lib/interfaces";

export const selectorActiveNotifications = (
  responses: IChannelNotificationResponse[]
) => {
  return responses
    .filter(response => {
      return !response.isDismissed;
    })
    .sort((a, b) => {
      return a.priority - b.priority;
    });
};
