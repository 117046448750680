import {
  WSButton,
  WSFormOld,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useResetPassword } from "../../../shared/hooks/useResetPassword";
import { validatorPassword } from "../../../shared/validators/validatorPassword";

type Props = {
  signInPath: string;
  requestResetPath: string;
  resetToken: string;
  userId: string;
};

export const FormUpdatePassword: React.FC<Props> = ({
  signInPath,
  resetToken,
  userId,
  requestResetPath
}) => {
  const history = useHistory();

  const { reset, isLoading, error } = useResetPassword(
    userId,
    resetToken,
    signInPath
  );

  return (
    <WSFormOld
      defaultValues={{ password: "" }}
      validationSchema={Yup.object().shape({
        password: validatorPassword.required("Password is required")
      })}
      onSubmit={values => {
        reset(values.password);
      }}
    >
      <WSFormOld.Field
        key="password"
        mb="XL"
        name="password"
        component={WSTextInput}
        componentProps={{
          type: "password",
          placeholder: "New password"
        }}
      />

      <WSErrorMessage my="XL" contextKey="PasswordReset" error={error} />

      <WSButton.Tertiary
        fullWidth
        type="submit"
        loading={isLoading}
        data-testid="submit"
      >
        Reset Password
      </WSButton.Tertiary>

      <WSButton.Secondary
        fullWidth
        mt="M"
        type="button"
        onClick={() => {
          history.push(requestResetPath);
        }}
      >
        Request another password reset
      </WSButton.Secondary>
    </WSFormOld>
  );
};
