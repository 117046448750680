import classNames from "classnames";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { WSField } from "../WSField/WSField";
import {
  WSInputContainer,
  WSInputContainerAction
} from "../WSInputContainer/WSInputContainer";
import { WSAvatar } from "../common/WSAvatar/WSAvatar.component";
import { WSText } from "../core/WSText/WSText.component";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import { WSFlexBox } from "../layout/WSFlexBox/WSFlexBox.component";
import { Menu } from "./Menu";
import styles from "./WSSelect.module.scss";
import { WSSelectProps } from "./types";

export type * from "./types";

export const WSSelect: React.FC<WSSelectProps> = ({
  name,
  options: initialOptions,
  mode,
  value,
  onChange,
  disabled,
  placeholder = "Select an option",
  onBlur,
  onFocus,
  externalSearchText,
  onExternalSearchChange,
  internalSearch,
  isListLoading,
  onReachMenuEnd,
  menuFooterText,
  menuFooterAction,
  autoFocus,
  autoComplete,
  hideClearAction,
  ...fieldProps
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const hasValue = useMemo(() => {
    return !!value;
  }, [value]);

  const clearAction = useMemo((): WSInputContainerAction | undefined => {
    if (!hasValue || hideClearAction) return undefined;

    return {
      icon: "exit",
      onClick: () => {
        onChange(null);
      }
    };
  }, [hasValue, onChange, hideClearAction]);

  const selectedOption = useMemo(
    () => initialOptions.find((option) => option.value === value),
    [initialOptions, value]
  );

  // Search state
  const [internalSearchText, internalSetSearchText] = useState("");
  const searchText = useMemo(
    () => (internalSearch ? internalSearchText : externalSearchText),
    [externalSearchText, internalSearch, internalSearchText]
  );
  const onSearchChange = useMemo(
    () => (internalSearch ? internalSetSearchText : onExternalSearchChange),
    [onExternalSearchChange, internalSearch]
  );

  // Sort and filter options
  const options = useMemo(() => {
    let filteredOptions = initialOptions.filter((option) => !option.delisted);

    if (internalSearch && internalSearchText) {
      filteredOptions = filteredOptions.filter((option) =>
        [option.label, option.searchText, ...(option.labels || [])]
          .map((str) => str?.toLowerCase().trim())
          .some((str) => str?.includes(internalSearchText.toLowerCase().trim()))
      );
    }

    if (!selectedOption || !!searchText) {
      return filteredOptions;
    }

    const restOptions = filteredOptions.filter(
      (option) => option.value !== selectedOption.value
    );
    return [selectedOption, ...restOptions];
  }, [
    initialOptions,
    internalSearch,
    internalSearchText,
    searchText,
    selectedOption
  ]);

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    if (autoFocus) {
      setIsMenuVisible(true);
    }
  }, [autoFocus]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isMenuVisible && event.key === "Escape") {
        setIsMenuVisible(false);
      } else if (!isMenuVisible && event.key === "ArrowDown") {
        setIsMenuVisible(true);
      }
    },
    [isMenuVisible]
  );

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const node = ref.current;
    node.addEventListener("keydown", handleKeyDown);

    return () => {
      node.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const manualInputRef = useRef<HTMLInputElement>(null);

  const handleManualInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toLowerCase();
      const matchingOptions = options.filter(
        (option) =>
          option.value.toLowerCase() === value ||
          option.label.toLowerCase() === value ||
          option.searchText?.toLowerCase() === value
      );

      if (matchingOptions.length === 1) {
        onChange(matchingOptions[0].value);
      }
    },
    [onChange, options]
  );

  return (
    <WSField
      {...fieldProps}
      className={classNames(
        styles.select,
        fieldProps.className,
        disabled && styles.disabled
      )}
      as="div"
    >
      <WSInputContainer
        status={fieldProps.status}
        disabled={disabled}
        iconRight={isMenuVisible ? "caret-up" : "caret-down"}
        inputAction={clearAction}
        onClick={() => setIsMenuVisible((prev) => !prev)}
        ref={ref}
        tabIndex={0}
        className={styles.inputContainer}
      >
        <input
          autoComplete={autoComplete}
          className={styles.manualInput}
          ref={manualInputRef}
          type="text"
          name={name}
          onChange={handleManualInputChange}
        />
        {selectedOption ? (
          <WSFlexBox wrap="nowrap" alignItems="center" gap="S">
            {selectedOption.icon ? (
              <WSIcon name={selectedOption.icon} size="S" color="gray600" />
            ) : selectedOption.avatar ? (
              <WSAvatar
                color="white"
                colorBackground="gray500"
                size="S"
                {...selectedOption.avatar}
              />
            ) : null}
            <WSText color="gray600" singleLine>
              {selectedOption.label}
            </WSText>
          </WSFlexBox>
        ) : placeholder ? (
          <WSText color="gray400">{placeholder}</WSText>
        ) : null}
      </WSInputContainer>

      {isMenuVisible && (
        <Menu
          options={options}
          value={value}
          onChange={onChange}
          onClose={() => {
            setIsMenuVisible(false);
            onSearchChange?.("");
          }}
          targetRef={ref}
          searchText={searchText}
          onSearchChange={onSearchChange}
          isListLoading={isListLoading}
          onReachEnd={onReachMenuEnd}
          footerText={menuFooterText}
          footerAction={menuFooterAction}
        />
      )}
    </WSField>
  );
};
