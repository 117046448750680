import React, { RefObject, useEffect } from "react";
import { useIsDesktop } from "@wingspanhq/fe-component-library";
import { useLocation } from "react-router-dom";

export const useScrollIntoViewByHash = (
  id: string,
  desktopOnly: boolean = true,
  options?: ScrollIntoViewOptions
) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const { behavior = "smooth", block = "start", inline = "nearest" } =
    options || {};

  const isDesktop = useIsDesktop();
  const location = useLocation();
  const isEnabled = desktopOnly ? isDesktop : true;

  useEffect(() => {
    if (ref.current && isEnabled && location.hash.split("#")[1] === id) {
      ref.current.scrollIntoView({ behavior, block, inline });
    }
  }, [location.hash, isEnabled, ref.current]);

  return ref;
};
