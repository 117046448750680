import { IMember } from "@wingspanhq/users/dist/lib/interfaces";
import { FormDataAccountType } from ".";
import { components } from "../../../../services/api/onboarding/types";

export function getProfileDataAccountType(
  monitoredEntity?: components["schemas"]["CustomerEntity"],
  member?: IMember
): Partial<FormDataAccountType> {
  return {
    country: monitoredEntity?.country || member?.profile?.address?.country,
    type: monitoredEntity?.type
  };
}
