import React from "react";
import {
  WSContainer,
  WSCentered,
  WSText
} from "@wingspanhq/fe-component-library";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { useUserId } from "../../../query/hooks/helpers";
import { useHistory } from "react-router-dom";
import { useUpdateActivity } from "../../../query/users/mutations";
import { identifyWithCommonFields } from "../../../utils/analytics";
import { AccountsDashboard } from "../../components/AccountsDashboard/AccountsDashboard";
import { queryCache } from "react-query";
import {
  QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_EXPENSE,
  QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_INCOME
} from "../../../query/bookkeeping/keys";

export const BookkeepingSetUpAccounts: React.FC = () => {
  const userId = useUserId();

  const history = useHistory();
  const [updateActivity] = useUpdateActivity(userId);

  useBrowserPageTitle("Bookkeeping - Finish Setup");

  return (
    <ProjectOnboardingLayout progress={(100 / 5) * 1} noBack>
      <WSContainer verticalPadding>
        <WSCentered span={{ m: "6" }}>
          <WSText.ParagraphSm color="gray500" mt="M" pt="M">
            3 of 5
          </WSText.ParagraphSm>
          <WSText.Heading4>Finish adding import methods</WSText.Heading4>

          <AccountsDashboard
            onCSV={() => {
              history.push(`/member/bookkeeping/setup/csv`);
            }}
            onSave={async accounts => {
              identifyWithCommonFields(userId, {
                plaidBookkeepingSyncedAccountsCount:
                  accounts.filter(a => a.usedFor?.bookkeeping).length || 0
              });

              identifyWithCommonFields(userId, {
                plaidAccountsCount: accounts.length || 0
              });

              await updateActivity({
                flows: {
                  bookkeepingSetup: {
                    version: 4,
                    totalSteps: 6,
                    currentStep: 5,
                    complete: false
                  }
                }
              });

              await queryCache.removeQueries(
                QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_EXPENSE
              );
              await queryCache.removeQueries(
                QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_INCOME
              );

              history.push("/member/bookkeeping/setup/tax-profile");
            }}
            saveButtonText="Review transactions"
            onContinueSetup={(institutionId, accounts) => {
              history.push(
                `/member/bookkeeping/setup/accounts/${institutionId}/sync`
              );
            }}
            onManageInstitution={({ id }) => {
              history.push(`/member/bookkeeping/setup/accounts/${id}/sync`);
            }}
            onPlaidFinish={(institutionId, accounts) => {
              history.push(
                `/member/bookkeeping/setup/accounts/${accounts[0].institutionId}/sync`
              );
            }}
          />
        </WSCentered>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
