import { BulkResource } from "../types";
import {
  useCreateBulkCalculate1099Batch,
  useUpdateBulkCalculate1099Batch
} from "./bulkCalculation1099/mutations";
import {
  useBulkCalculate1099Batch,
  useBulkCalculate1099BatchSummary
} from "./bulkCalculation1099/queries";
import {
  useCreateBulkCollaboratorBatch,
  useDeleteBulkCollaboratorBatch,
  useUpdateBulkCollaboratorBatch
} from "./bulkCollaborator/mutations";
import {
  useAllBulkCollaboratorBatchItems,
  useBulkCollaboratorBatch,
  useBulkCollaboratorBatchSummary
} from "./bulkCollaborator/queries";
import {
  useCreateBulkPayableBatch,
  useDeleteBulkPayableBatch,
  useUpdateBulkPayableBatch
} from "./bulkPayable/mutations";
import {
  useAllBulkPayableBatchItems,
  useBulkPayableBatch,
  useBulkPayableBatchSummary
} from "./bulkPayable/queries";

const bulkBatchQueryMap = {
  [BulkResource.Payable]: {
    useBulkBatch: useBulkPayableBatch,
    useCreateBulkBatch: useCreateBulkPayableBatch,
    useBulkBatchSummary: useBulkPayableBatchSummary,
    useUpdateBulkBatch: useUpdateBulkPayableBatch,
    useDeleteBulkBatch: useDeleteBulkPayableBatch,
    useAllBulkBatchItems: useAllBulkPayableBatchItems
  },
  [BulkResource.Collaborator]: {
    useBulkBatch: useBulkCollaboratorBatch,
    useCreateBulkBatch: useCreateBulkCollaboratorBatch,
    useBulkBatchSummary: useBulkCollaboratorBatchSummary,
    useUpdateBulkBatch: useUpdateBulkCollaboratorBatch,
    useDeleteBulkBatch: useDeleteBulkCollaboratorBatch,
    useAllBulkBatchItems: useAllBulkCollaboratorBatchItems
  },
  [BulkResource.Calculate1099]: {
    useBulkBatch: useBulkCalculate1099Batch,
    useCreateBulkBatch: useCreateBulkCalculate1099Batch,
    useBulkBatchSummary: useBulkCalculate1099BatchSummary,
    useUpdateBulkBatch: useUpdateBulkCalculate1099Batch,
    useDeleteBulkBatch: () => {
      throw new Error("Bulk Calculate 1099 delete not implemented");
    },
    useAllBulkBatchItems: () => {
      throw new Error("Bulk Calculate 1099 all batch items not implemented");
    }
  }
};

export function getBulkQueriesByResource(bulkResource: BulkResource) {
  return bulkBatchQueryMap[bulkResource];
}
