import { Flatfile } from "@flatfile/sdk";
import { usersService } from "../services/users";
import { FlatfileError } from "@flatfile/sdk/dist/errors/FlatfileError";
export interface WSFlatfileOptions {
  embedId: string;
  onError?: (error: FlatfileError) => void;
}

export function useFlatfile(options: WSFlatfileOptions) {
  const { embedId, onError } = options;

  const closeFlatfile = () => {
    // NOTE: there is no support from Flatfile to programmatically close flatfile modal
    // so we are doing this hacky way
    document
      .querySelector("div.flatfile-sdk > button.flatfile-close")
      ?.dispatchEvent(new Event("click"));
  };

  const flatfile = new Flatfile({
    token: async () => {
      const { token } = await usersService.flatfileAuthToken.create({
        embedId
      });
      return token;
    },
    onError: ({ error }: { error: FlatfileError }) => {
      console.error("Bulk Import Error: name", error.name);
      console.error("Bulk Import Error: code", error.code);
      console.error("Bulk Import Error: debug", error.debug);

      // NOTE: Flatfile is throwing two different errors for the same case
      // ChunkTimeoutError and ChunkTimeoutExpiredError so onError callback is called twice
      // which is causing duplicate error modals are rendering.
      onError && onError(error);
      closeFlatfile();
    }
  });

  return flatfile;
}
