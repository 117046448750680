import {
  WSActions,
  WSFlexBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { OnboardingStep } from "../types";

import { useRef, useState } from "react";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateActivity } from "../../../query/users/mutations";
import {
  FormBusinessProfile,
  FormBusinessProfileRef
} from "../components/FormBusinessProfile";

export const BusinessProfile: React.FC<OnboardingStep> = ({
  title,
  onNext
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const businessProfileFormRef = useRef<FormBusinessProfileRef>(null);
  const userId = useUserId();
  const [updateActivity] = useUpdateActivity(userId);

  const updateActivityAndContinue = async () => {
    updateActivity({
      flows: {
        onboardingBusinessProfile: {
          complete: true
        }
      }
    });
    onNext?.();
  };
  return (
    <>
      <WSPanel mb="2XL">
        <WSFlexBox direction="column">
          <WSText.Heading4 weight="medium" color="gray600">
            {title}
          </WSText.Heading4>
          <WSText.ParagraphSm weight="book" color="gray500">
            Complete your business profile with a logo, links, and web presence
            to help contractors identify your business.
          </WSText.ParagraphSm>

          <FormBusinessProfile
            ref={businessProfileFormRef}
            setLoading={setLoading}
            onSuccess={updateActivityAndContinue}
            my="2XL"
          />
        </WSFlexBox>
      </WSPanel>
      <WSActions
        mb="2XL"
        orientation="vertical"
        buttons={[
          {
            label: "Continue",
            loading,
            onClick: () => {
              businessProfileFormRef.current?.requestSubmit();
            }
          },
          {
            label: "Skip for now",
            kind: "Link",
            onClick: updateActivityAndContinue
          }
        ]}
      />
      <WSText.ParagraphXs weight="book" color="gray400">
        Business profile settings can be viewed and updated at any time in your
        settings.
      </WSText.ParagraphXs>
    </>
  );
};
