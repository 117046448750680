import { useWSModal } from "@wingspanhq/fe-component-library";

import { AdditionalGuidelinesModal } from "./AdditionalGuidelinesModal";

export function useAdditionalGuidelinesModal() {
  return useWSModal(AdditionalGuidelinesModal, {
    title: "Additional guidelines to show contractors",
    size: "S"
  });
}
