import {
  FundingSourceType,
  PayoutDestinationType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { Currency, IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { queryCache } from "react-query";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  QUERY_PAYOUT_SETTINGS,
  QUERY_PAYROLL_SETTINGS
} from "../../../../query/payments/keys";
import { paymentsService } from "../../../../services/payments";
import { avatarBankAccount } from "../../../../shared/constants/avatars";
import { selectorAccountFullName } from "../../../../shared/selectors/selectorAccountFullName";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { StepOptions } from "../Options";

type Props = {
  title: string;
  description?: string;
  accounts: IAccount[];
  onBack?: () => void;
  onContinue?: () => void;
  setFundingSource?: boolean;
  setStandardPayoutDestination?: boolean;
  setInstantPayoutDestination?: boolean;
};

export const FormSelectAccount: React.FC<Props> = ({
  title,
  description,
  accounts,
  onBack,
  onContinue,
  setFundingSource,
  setStandardPayoutDestination,
  setInstantPayoutDestination
}) => {
  const userId = useUserId();
  const [submit, meta] = useWSMutation<
    void,
    WSServiceError,
    { accountId: string }
  >(
    async ({ accountId }) => {
      if (setFundingSource) {
        const payrollSettings = await paymentsService.payrollSettings.update(
          userId,
          {
            fundingSource: {
              fundingSourceCurrency: Currency.USD,
              fundingSourceId: accountId,
              fundingSourceType: FundingSourceType.Account
            }
          }
        );

        queryCache.setQueryData(QUERY_PAYROLL_SETTINGS, payrollSettings);
      }

      if (setStandardPayoutDestination) {
        const payoutSettings = await paymentsService.payoutSettings.update(
          userId,
          {
            payoutPreferences: PayoutPreferences.Standard,
            standard: {
              destinationId: accountId,
              destinationType: PayoutDestinationType.Account
            }
          }
        );

        queryCache.setQueryData(QUERY_PAYOUT_SETTINGS, payoutSettings);
      }

      if (setInstantPayoutDestination) {
        const payoutSettings = await paymentsService.payoutSettings.update(
          userId,
          {
            payoutPreferences: PayoutPreferences.Instant,
            instant: {
              destinationId: accountId,
              destinationType: PayoutDestinationType.Account
            }
          }
        );

        queryCache.setQueryData(QUERY_PAYOUT_SETTINGS, payoutSettings);
      }
    },
    {
      onSuccess: onContinue,
      dependencies: [QUERY_PAYROLL_SETTINGS, QUERY_PAYOUT_SETTINGS]
    }
  );

  return (
    <StepOptions
      key="accounts"
      title={title}
      description={description}
      options={accounts.map(account => ({
        label: selectorAccountFullName(account),
        value: account.accountId,
        avatar: avatarBankAccount
      }))}
      onBack={onBack}
      onContinue={accountId => {
        submit({ accountId });
      }}
      error={meta.error}
      isLoading={meta.isLoading}
    />
  );
};
