import React, { useMemo } from "react";
import { WSInputText } from "../WSInputText/WSInputText";
import {
  FilterChangeHandler,
  WSFilterInput,
  WSFilterSingleValue
} from "./types";
import styles from "./WSFilters.module.scss";

type Props = {
  value?: WSFilterSingleValue;
  onChange: FilterChangeHandler;
} & Omit<WSFilterInput, "type">;

export const FilterFieldInput: React.FC<Props> = ({
  name,
  value: uncastedValue,
  onChange,
  placeholder
}) => {
  const value = useMemo(() => {
    return uncastedValue ? uncastedValue : "";
  }, [uncastedValue]);

  return (
    <WSInputText
      className={styles.filterInput}
      value={value}
      onChange={(newValue) => {
        onChange(name, newValue);
      }}
      placeholder={placeholder}
    />
  );
};
