import {
  IPayerTaxFormResponse,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
import { selectorPayerTaxFormIsBusiness } from "./selectorPayerTaxFormIsBusiness";
import { selectorPayerTaxFormIsIndividual } from "./selectorPayerTaxFormIsIndividual";

export const selectorPayerTaxFormDefaultTinType = (
  data: IPayerTaxFormResponse
) => {
  return selectorPayerTaxFormIsBusiness(data)
    ? TinType.Business
    : selectorPayerTaxFormIsIndividual(data)
    ? TinType.Individual
    : data.data.w9Info?.einLastFour && data.tinVerification?.ein?.status
    ? TinType.Business
    : data.data.w9Info?.ssnLastFour && data.tinVerification?.ssn?.status
    ? TinType.Individual
    : undefined;
};
