import {
  toWSDateString,
  WSButton,
  WSFlexBox,
  WSGrid,
  WSInfoBox,
  WSPage,
  WSPageToolbar,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { FC } from "react";
import { PAYOUTS_PATH } from "../..";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import { PayoutInfoPanel } from "../../components/PayoutInfoPanel";
import { PayoutSummaryPanel } from "../../components/PayoutSummaryPanel";
import { PayoutAccountsPanel } from "../../components/PayoutAccountsPanel";
import { PayoutAccountDetails } from "../../components/PayoutAccountDetails";
import { usePayoutQuery } from "../../../../query/payouts/queries/usePayoutQuery";
import { WSQueries } from "../../../../query/WSQuery";

export const RoutePayoutDetails: FC = () => {
  const history = useHistory();
  const match = useRouteMatch<{ payoutId: string }>();

  const queryPayout = usePayoutQuery(match.params.payoutId);

  return (
    <>
      <Route
        path={`${PAYOUTS_PATH}/:payoutId/account-details`}
        component={PayoutAccountDetails}
      />
      <WSPage>
        <WSQueries
          queries={{
            queryPayout
          }}
        >
          {({ queryPayoutData }) => {
            const payoutData = queryPayoutData;

            return (
              <>
                <WSPageToolbar
                  breadcrumbs={[
                    {
                      label: "Clients",
                      onClick: () => {
                        history.push(PAYOUTS_PATH);
                      }
                    },
                    {
                      label: `Payout (${toWSDateString(
                        payoutData.createdAt,
                        "monthDayYear"
                      )})`
                    }
                  ]}
                  // actions={[
                  //   {
                  //     label: "Download PDF",
                  //     buttonKind: "Secondary"
                  //     // onClick: handleCreateInvoice
                  //   }
                  // ]} //todo
                />
                <WSFlexBox.CenterY mb="L">
                  <WSText.Heading3 mr="L">
                    {toWSDateString(payoutData.createdAt, "monthDayYear")}
                  </WSText.Heading3>
                  <WSText color="gray500">{"Wingspan Payout"}</WSText>
                </WSFlexBox.CenterY>
                <WSInfoBox mb="XL">
                  Reminder: Payouts may contain payments for multiple invoices.
                  Click on the individual invoice to view detailed invoice
                  information.
                </WSInfoBox>
                <WSGrid gutter="L">
                  <WSGrid.Item span={{ m: "6" }}>
                    <PayoutInfoPanel payout={payoutData} />
                  </WSGrid.Item>

                  <WSGrid.Item span={{ m: "6" }}>
                    <WSGrid.Item>
                      <WSSectionToolbar title="Payout summary" mb={"S"} />
                      <PayoutSummaryPanel mb={"S"} payout={payoutData} />
                    </WSGrid.Item>

                    <WSGrid.Item>
                      <WSSectionToolbar
                        button={{
                          label: "View details",
                          onClick: () => {
                            history.push(
                              `${PAYOUTS_PATH}/${payoutData.payoutId}/account-details`
                            );
                          }
                        }}
                        title="Payout accounts"
                        mt={"XL"}
                        mb={"S"}
                      />
                      <PayoutAccountsPanel mb={"S"} payout={payoutData} />
                    </WSGrid.Item>
                  </WSGrid.Item>
                </WSGrid>
              </>
            );
          }}
        </WSQueries>
      </WSPage>
    </>
  );
};
