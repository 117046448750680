import {
  useWSModal,
  WSActions,
  WSDivider,
  WSInfoBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useCreateCard } from "../../../../query/cards/mutations/useCreateCard";
import { useUserId } from "../../../../query/hooks/helpers";

export interface ReplaceCardProps {
  cardId: string;
  onSuccess: () => void;
  onClose: () => void;
}

const ReplaceCard: React.FC<ReplaceCardProps> = ({
  cardId,
  onClose,
  onSuccess
}) => {
  const userId = useUserId();
  const [createCard, createCardMeta] = useCreateCard();

  return (
    <WSList gap="L">
      <WSText.ParagraphSm mb="S">
        Would you like to replace an existing lost or damaged card?
      </WSText.ParagraphSm>
      <WSInfoBox>
        Your existing card will be closed and we will send you a replacement
        card with new numbers.
      </WSInfoBox>
      <WSErrorMessage contextKey="Other" error={createCardMeta.error} />
      <WSDivider />
      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Replace card",
            onAsyncClick: async () => {
              await createCard(
                {
                  replacementForPaymentCardId: cardId,
                  userId,
                  // TODO; replace with actual address
                  address: {
                    address1: "123 Main St",
                    city: "Anytown",
                    state: "CA",
                    postal_code: "12345",
                    country: "US"
                  }
                },
                {
                  onSuccess() {
                    onSuccess();
                    onClose();
                  }
                }
              );
            },
            kind: "Primary"
          },
          {
            label: "Cancel",
            onClick: onClose,
            kind: "Secondary"
          }
        ]}
      ></WSActions>
    </WSList>
  );
};

export function useReplaceCardModal() {
  return useWSModal(ReplaceCard, {
    title: "Replace card",
    size: "S"
  });
}
