import { useWSMutation } from "../../helpers";
import { updatePayer } from "../../../services/payers";
import { IPayerUpdateRequest } from "@wingspanhq/payments/dist/interfaces";
import { QUERY_ALL_PAYER_LIST, QUERY_PAYER } from "../keys";
import { QUERY_PAYER_ROWS } from "../../search/payer/queries/useQueryPayerRows";
import { QUERY_PAYER_ROWS_SUMMARY } from "../../search/payer/queries/useQueryPayerRowsSummary";

export const useUpdatePayer = () =>
  useWSMutation(
    ({ id, ...payload }: IPayerUpdateRequest & { id: string }) =>
      updatePayer(id, payload),
    {
      dependencies: [
        QUERY_ALL_PAYER_LIST,
        QUERY_PAYER,
        QUERY_PAYER_ROWS,
        QUERY_PAYER_ROWS_SUMMARY
      ]
    }
  );
