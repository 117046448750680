import {
  useModalContext,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { TAXES_MODAL_REQUEST_SCHEDULE_C_PDF } from "./TaxesModalRequestScheduleCPDF";

export const TAXES_MODAL_PENDING_REVIEW = "TAXES_MODAL_PENDING_REVIEW";

export const TaxesModalPendingReview: React.FC = () => {
  const history = useHistory();
  const { closeModal, openModal } = useModalContext();

  return (
    <WSModal name={TAXES_MODAL_PENDING_REVIEW} size="S">
      {count => (
        <>
          <WSText.Heading5 mb="M">
            {count} transactions pending review
          </WSText.Heading5>
          <WSText mb="XS">
            To make your export more accurate, finalize review now.
          </WSText>
          <WSButtons mt="2XL" format="modal">
            <WSButton.Primary
              ml="M"
              onClick={() => {
                closeModal(TAXES_MODAL_PENDING_REVIEW);
                history.push("/member/bookkeeping/catch-up");
              }}
            >
              Finish review
            </WSButton.Primary>
            <WSButton.Tertiary
              onClick={() => {
                closeModal(TAXES_MODAL_PENDING_REVIEW);
                openModal(TAXES_MODAL_REQUEST_SCHEDULE_C_PDF);
              }}
            >
              Skip
            </WSButton.Tertiary>
          </WSButtons>
        </>
      )}
    </WSModal>
  );
};
