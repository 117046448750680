import {
  useWSSnackbar,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSTable,
  WSText,
  WSTooltip
} from "@wingspanhq/fe-component-library";
import React, { useRef } from "react";
import { useBankingAccount } from "../../../query/bookkeeping/queries";
import { WSQueries } from "../../../query/WSQuery";

type Props = { onClose: () => void };

export const ModalAccountDetails: React.FC<Props> = () => {
  const queryBankingAccount = useBankingAccount();
  const { openSnackbar } = useWSSnackbar();

  return (
    <WSQueries queries={{ queryBankingAccount }}>
      {({ queryBankingAccountData: bankingAccount }) => (
        <>
          <WSElement mb="XL">
            <WSText weight="medium">Account details</WSText>
            <WSText.ParagraphXs color="gray400">
              Information about your Wingspan Wallet
            </WSText.ParagraphXs>
          </WSElement>

          <WSTable
            mb="L"
            tableData={[
              {
                id: "holder",
                data: {
                  label: "Account holder",
                  value: bankingAccount.name
                }
              },
              {
                id: "bank",
                data: {
                  label: "Bank name",
                  value: "Lead Bank"
                }
              },
              {
                id: "routing",
                data: {
                  label: "Routing number",
                  value: bankingAccount.numbers?.routing
                }
              },
              {
                id: "number",
                data: {
                  label: "Account number",
                  value: bankingAccount.numbers?.account
                }
              }
            ]}
            columns={[
              {
                renderFunction: ({ id, data }) => {
                  if (id === "routing") {
                    return <RoutingLabel />;
                  }
                  return (
                    <WSText.ParagraphSm color="gray500">
                      {data.label}
                    </WSText.ParagraphSm>
                  );
                }
              },
              {
                renderFunction: ({ data }) => {
                  return (
                    <WSText.ParagraphSm weight="medium" color="gray600">
                      {data.value}
                    </WSText.ParagraphSm>
                  );
                }
              }
            ]}
            rowActions={({ id, data }) => {
              if (!["number", "routing"].includes(id) || !data.value) {
                return [];
              }

              return [
                {
                  text: "Copy",
                  onClick: () => {
                    navigator.clipboard.writeText(data.value || "");
                    openSnackbar({
                      message: data.label + " copied to clipboard"
                    });
                  }
                }
              ];
            }}
          />
          <WSText.ParagraphSm color="gray500">
            Banking services provided by Lead Bank, Member FDIC.
          </WSText.ParagraphSm>
        </>
      )}
    </WSQueries>
  );
};

const RoutingLabel = () => {
  const tooltipIconRef = useRef<HTMLElement>(null);

  return (
    <WSFlexBox wrap="nowrap" gap="S" alignItems="center">
      <WSText.ParagraphSm color="gray500">Routing number</WSText.ParagraphSm>
      <WSIcon ref={tooltipIconRef} name="info-circle" />
      <WSTooltip target={tooltipIconRef} title="Routing number">
        A routing number is a unique nine-digit code used to identify a specific
        bank or financial institution in the United States.
      </WSTooltip>
    </WSFlexBox>
  );
};
