import { WSControl, WSInfoBox, WSList } from "@wingspanhq/fe-component-library";
import React from "react";
import { operations } from "../../../services/api/onboarding/types";
import { bankingAcknowledgements } from "../../../shared/constants/acknowledgements";
import { Acknowledgements } from "./Acknowledgements";

type ConfirmationDisclosuresProps = {
  consent: boolean;
  setConsent: (value: boolean) => void;
  verificationLevel?: operations["getVerificationMissingData"]["parameters"]["path"]["verificationLevel"];
};

export const ConfirmationDisclosures: React.FC<ConfirmationDisclosuresProps> = ({
  consent,
  setConsent,
  verificationLevel
}) => {
  if (verificationLevel === "Tax") {
    return (
      <WSList gap="2XL">
        <WSInfoBox>
          This information will be used to customize your Wingspan experience.
          Once you continue, you cannot return to this step and the information
          cannot be changed. Please verify all information provided is accurate.
        </WSInfoBox>

        <WSControl
          type="checkbox"
          value={consent}
          onChange={setConsent}
          size="S"
          label="By checking this box, I confirm that all information is accurate. I agree to the processing of my information for identity verification as detailed in the Privacy Policy."
        />
      </WSList>
    );
  }

  return (
    <WSList gap="2XL">
      <WSInfoBox>
        <p>
          This information will be used to customize your Wingspan experience.
          Once you continue, you cannot return to this step and the information
          cannot be changed. Please verify all information provided is accurate.
        </p>
        <p>
          By clicking continue you agree that you have read, understood, and
          agree to the following:
        </p>

        <Acknowledgements aknowledgements={bankingAcknowledgements} />

        <p>
          Wingspan is not a financial services technology company and is not a
          bank. Banking services provided by Lead Bank, member FDIC.
        </p>
        <p>
          The responsibility for ensuring full tax compliance, including any
          additional documentation that may be required in specific local
          jurisdictions, rests with the payer and the contractor. We recommend
          that both clients and contractors consult with qualified tax
          professionals in their respective jurisdictions to ensure they meet
          all local tax obligations and reporting requirements. Wingspan's role
          is to facilitate the collection of standard tax information, but we
          cannot guarantee that this covers every possible local requirement in
          all cases.
        </p>
      </WSInfoBox>
      <WSControl
        type="checkbox"
        value={consent}
        onChange={setConsent}
        size="S"
        label="By checking this box, I confirm that all information is accurate. I agree to the processing of my information for identity verification as detailed in the Privacy Policy. I have read, and agree to all the above-mentioned documents and their contents."
      />
    </WSList>
  );
};
