import { getBackgroundChecksRequestFromCache } from "./getBackgroundChecksRequestFromCache";
import { WSTableCell } from "@wingspanhq/fe-component-library";
import React from "react";
import { IRequirementResponse } from "@wingspanhq/payments/dist/interfaces/api/requirement";
import { ContextItem } from "../../../../types";

export const renderNameCell = (
  requirement: IRequirementResponse,
  context: ContextItem
) => {
  const backgroundChecksRequest = getBackgroundChecksRequestFromCache(
    requirement,
    context
  );

  if (requirement.dataSourceId && !backgroundChecksRequest) {
    return (
      <WSTableCell
        text="--"
        secondaryText="error loading"
        secondaryTextColor="amber400"
      />
    );
  }

  return <WSTableCell text={backgroundChecksRequest?.settings?.title} />;
};
