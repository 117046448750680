import {
  useIsMobile,
  WSButton,
  WSContainer,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { Fullscreen } from "../../../components/Fullscreen/Fullscreen";
import { useWSStore } from "../../../store";
import { useBrowserPageTitle } from "../../hooks/useBrowserPageTitle";
import { LogoApp } from "../LogoApp";
import styles from "./index.module.scss";
import { LogoPartner } from "./LogoPartner";

export type LayoutSetupProps = {
  onBack?: () => void;
  title?: string;
  description?: ReactNode;
  banner?: ReactNode;
  wide?: boolean;
  customLogo?: string;
} & WSElementProps;

export const LayoutSetup: React.FC<LayoutSetupProps> = ({
  onBack,
  children,
  title,
  description,
  banner,
  wide,
  customLogo,
  ...props
}) => {
  const isMobile = useIsMobile();
  useBrowserPageTitle(title);
  const {
    appContext: { isEmbedded }
  } = useWSStore();

  return (
    <Fullscreen>
      {isEmbedded ? (
        <WSElement p="S">
          <WSFlexBox.Center>
            <WSText mr="M">Powered by</WSText>
            <LogoApp />
          </WSFlexBox.Center>
        </WSElement>
      ) : (
        <WSElement className={styles.header} {...props}>
          <WSContainer>
            <WSElement className={styles.headerInner}>
              {onBack && (
                <WSButton.Link
                  icon="chevron-left"
                  onClick={onBack}
                  className={styles.backButton}
                >
                  Back
                </WSButton.Link>
              )}

              <WSFlexBox.CenterY wrap="nowrap">
                <LogoApp />
                <LogoPartner customLogo={customLogo} />
              </WSFlexBox.CenterY>
            </WSElement>
          </WSContainer>
        </WSElement>
      )}

      {banner ? (
        <WSElement className={styles.banner}>
          <WSContainer>
            <WSElement className={styles.bannerInner}>{banner}</WSElement>
          </WSContainer>
        </WSElement>
      ) : (
        <WSDivider />
      )}

      <WSContainer
        verticalPadding
        className={classNames(styles.container, {
          [styles.containerWide]: wide
        })}
      >
        <WSElement className={styles.content}>
          {title && (
            <WSText.Heading4
              mb={description ? "XS" : isMobile ? "XL" : "2XL"}
              data-testid="heading"
            >
              {title}
            </WSText.Heading4>
          )}

          {description && (
            <WSText mb={isMobile ? "XL" : "2XL"}>{description}</WSText>
          )}

          {children}
        </WSElement>
      </WSContainer>
    </Fullscreen>
  );
};
