import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

export interface SetupParams {
  [key: string]: any;
}

export interface SetupRouteComponentProps<P = SetupParams>
  extends Partial<RouteComponentProps> {
  onBack?: (context?: any) => void;
  onNext: (context?: any) => void;
  params?: P;
}

export type SetupRouteConfig = {
  path: string;
  component: React.FC<SetupRouteComponentProps>;
  onBack?: (context?: { [key: string]: any }) => void;
  onNext: (context?: { [key: string]: any }) => void;
  [key: string]: any;
};

type Props = {
  routes: SetupRouteConfig[];
  basePath: string;
  params?: SetupParams;
  initialPath?: string;
};

export const FlowSetup: React.FC<Props> = ({
  routes,
  basePath,
  initialPath,
  params
}) => {
  const redirectPath = initialPath || routes[0].path;

  return (
    <Switch>
      {routes.map(route => {
        const { path, component: Component, ...otherProps } = route;

        return (
          <Route
            key={path}
            path={basePath + path}
            render={({ history, location, match }) => (
              <Component
                history={history}
                location={location}
                match={match}
                params={params}
                {...otherProps}
              />
            )}
          />
        );
      })}

      {redirectPath && (
        <Redirect exact from={basePath} to={basePath + redirectPath} />
      )}
    </Switch>
  );
};
