import { WSGrid, WSPage } from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { PayrollSettings } from "../../Invoices/screens/payables/PayrollSettings";

export const Payroll: React.FC = props => {
  const history = useHistory();

  return (
    <WSPage title="Payroll">
      <WSGrid>
        <WSGrid.Item span={{ m: "10" }}>
          <PayrollSettings />
        </WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
