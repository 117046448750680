export const EXPIRATION_MONTH_OPTIONS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12"
].map(s => ({
  value: s,
  label: s,
  searchText: s
}));

// ref: https://stackoverflow.com/questions/1575271/range-of-years-in-javascript-for-a-select-box
const currentYear = new Date().getFullYear();
const range = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => `${start + i * step}`
  );

export const EXPIRATION_YEAR_OPTIONS = range(
  currentYear,
  currentYear + 30,
  1
).map(s => ({
  value: s,
  label: s,
  searchText: s
}));
