import { useWSMutation } from "../../helpers";
import { QUERY_SIGNED_DOCUMENT } from "../keys";
import { helloSignClient, helloSignPromise } from "../../../utils/helloSign";
import { ISignedDocumentResponse } from "@wingspanhq/signed-documents/dist/lib/interfaces";
import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { useUserId } from "../../hooks/helpers";

export const useSignSignedDocument = () => {
  const userId = useUserId();
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation(
    async (document: ISignedDocumentResponse) => {
      const signature = document.signatures.find(
        signature => signature.userId === userId
      );

      const url = signature?.url;

      if (url) {
        helloSignClient.open(url);

        const hsStatus = await helloSignPromise();
        if (hsStatus === "signed") {
          openSnackbar({
            icon: {
              name: "check"
            },
            message: "Document signed"
          });
        }
      } else {
        openSnackbar({
          icon: {
            name: "alert"
          },
          type: "error",
          message: "No sign url on a document"
        });
        throw new Error("No sign url on a document");
      }
    },
    {
      dependencies(payload, document) {
        return [QUERY_SIGNED_DOCUMENT, document.signedDocumentId];
      }
    }
  );
};
