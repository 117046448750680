import {
  INextgenSubscriptionPlan,
  ISubscription,
  NextgenSubscriptionPartner,
  NextgenSubscriptionPlanType,
  SubscriptionPackage,
  SubscriptionPackageTier,
  SubscriptionStatus,
  SubscriptionTerm
} from "@wingspanhq/users/dist/lib/interfaces";
import { IDisplaySubscriptionPackageWithoutAmount } from "../components/MembershipPackages/subscriptionPackages";
import { IDisplayNextGenSubscriptionPlan } from "../components/NextGenSubscriptionPlans/nextGenSubscriptionPlans";
import {
  IGCSSubscriptionPackages,
  IGCSSubscriptionPackageTier
} from "./../../services/users";

export interface PackageTierTerm {
  package: SubscriptionPackage;
  packageTier: SubscriptionPackageTier;
  term: SubscriptionTerm;
}

// WS Frontend Features
export enum WSFrontendFeature {
  None = "None",
  PPL = "PPL",
  TransactionSync = "TransactionSync",
  ExpenseTracking = "ExpenseTracking",
  TaxWitholdings = "TaxWitholdings",
  NEC1099Filing = "NEC1099Filing",
  FileVault = "FileVault",
  Teams = "Teams",
  InviteAndPayCollaborator = "InviteAndPayCollaborator",
  EnrollBenefits = "EnrollBenefits",
  Reports = "Reports"
}

const noSubscriptionFrontendFeatures = [
  WSFrontendFeature.PPL,
  WSFrontendFeature.TransactionSync,
  WSFrontendFeature.ExpenseTracking,
  WSFrontendFeature.TaxWitholdings,
  WSFrontendFeature.FileVault
];

const benefitSubscriptionFrontendFeatures = [
  ...noSubscriptionFrontendFeatures,
  WSFrontendFeature.EnrollBenefits
];

const professionalSubscriptionFrontendFeatures = [
  ...benefitSubscriptionFrontendFeatures,
  WSFrontendFeature.Teams,
  WSFrontendFeature.InviteAndPayCollaborator,
  WSFrontendFeature.NEC1099Filing,
  WSFrontendFeature.Reports
];

export const subscriptionAndFeaturesMap: {
  [key in SubscriptionPackage]?: WSFrontendFeature[];
} = {
  [SubscriptionPackage.None]: noSubscriptionFrontendFeatures,
  [SubscriptionPackage.Benefits]: benefitSubscriptionFrontendFeatures,
  [SubscriptionPackage.Professional]: professionalSubscriptionFrontendFeatures
};

export const isFeatureIncludedInSubscription = (
  feature: WSFrontendFeature,
  subscription?: ISubscription
) => {
  const currentSubscriptionPackage =
    subscription?.package ?? SubscriptionPackage.None;
  if (subscription) {
    return subscriptionAndFeaturesMap[currentSubscriptionPackage]?.includes(
      feature
    );
  } else {
    return noSubscriptionFrontendFeatures.includes(feature);
  }
};

export const hasSubscription = (subscription?: ISubscription) => {
  if (!subscription) {
    return false;
  }
  return (
    new Date() <= subscription?.gracePeriodEnd &&
    [SubscriptionStatus.active, SubscriptionStatus.trialing].includes(
      subscription?.status
    )
  );
};

export const isPremiumSubscription = (subscription?: ISubscription) => {
  if (!subscription) {
    return false;
  }
  return (
    subscription.package === SubscriptionPackage.Professional &&
    subscription.packageTier === SubscriptionPackageTier.Premium
  );
};

export interface IDisplaySubscriptionPackage
  extends IDisplaySubscriptionPackageWithoutAmount,
    IGCSSubscriptionPackageTier {}

export const prepareSubscriptionPackagesData = (
  subscriptionPackagesWithAmount: IGCSSubscriptionPackages,
  fePackages: IDisplaySubscriptionPackageWithoutAmount[]
) => {
  const subscriptionPackages: Array<IDisplaySubscriptionPackage> = [];
  fePackages.forEach(subPackage => {
    const packageWithAmount = {
      ...subPackage,
      ...subscriptionPackagesWithAmount[subPackage.package][subPackage.tier]
    };
    subscriptionPackages.push(packageWithAmount);
  });
  return subscriptionPackages;
};

export const isProfessionalSubscription = (subscription?: ISubscription) => {
  if (!subscription) {
    return false;
  }
  return subscription.package === SubscriptionPackage.Professional;
};

export const isSubscriptionCanceled = (subscription: ISubscription) => {
  const isCancellationScheduled =
    (subscription.scheduledChanges || [])?.filter(
      sc => sc.status === SubscriptionStatus.canceled
    ).length > 0;
  return (
    subscription.status === SubscriptionStatus.canceled ||
    isCancellationScheduled
  );
};

export const getCurrentPackage = (
  subscription: ISubscription
): PackageTierTerm | null => {
  if (!subscription) {
    return null;
  }
  if (
    subscription &&
    subscription.status === SubscriptionStatus.canceled &&
    (subscription.scheduledChanges || []).length === 0
  ) {
    // Individuals Package
    return {
      package: SubscriptionPackage.None,
      packageTier: SubscriptionPackageTier.Basic,
      term: SubscriptionTerm.Yearly
    };
  }
  const { scheduledChanges = [] } = subscription;
  /**
   * Checking 3 conditions
   * 1. scheduledChanges length should be > 0
   * 2. scheduledChanges[0] should not have `status` property OR if it has
   *    it shouldn't be SubscriptionStatus.canceled
   * 3. scheduledChanges[0] object should have package, packageTier & term properties
   */
  if (
    scheduledChanges.length > 0 &&
    (scheduledChanges[0].status === undefined ||
      (scheduledChanges[0].status &&
        scheduledChanges[0].status !== SubscriptionStatus.canceled)) &&
    scheduledChanges[0].package &&
    scheduledChanges[0].packageTier &&
    scheduledChanges[0].term
  ) {
    const { package: packageName, packageTier, term } = scheduledChanges[0];
    return {
      package: packageName as SubscriptionPackage,
      packageTier: packageTier as SubscriptionPackageTier,
      term: term as SubscriptionTerm
    };
  }
  if (subscription.package && subscription.packageTier && subscription.term) {
    return {
      package: subscription.package,
      packageTier: subscription.packageTier,
      term: subscription.term
    };
  }
  return {
    package: SubscriptionPackage.None,
    packageTier: SubscriptionPackageTier.Basic,
    term: SubscriptionTerm.Yearly
  };
};

export const isSubscriptionTrialing = (subscription: ISubscription) => {
  if (!subscription) {
    return false;
  }
  /**
   * `previouslyBilled` property will be set on the subscription object
   * when the trial started for upgrade
   */
  return (
    subscription.status === SubscriptionStatus.trialing ||
    (subscription.previouslyBilled &&
      subscription.previouslyBilled.package &&
      subscription.previouslyBilled.packageTier &&
      subscription.previouslyBilled.term)
  );
};

// Next gen subscription utils

export interface INextGenSubscriptionPlanWithInfo
  extends IDisplayNextGenSubscriptionPlan,
    INextgenSubscriptionPlan {}

export const prepareNextGenSubscriptionPlansData = (
  plans: INextgenSubscriptionPlan[],
  fePlans: IDisplayNextGenSubscriptionPlan[],
  planType: NextgenSubscriptionPlanType,
  partner: NextgenSubscriptionPartner
): INextGenSubscriptionPlanWithInfo[] => {
  const filteredPlans = plans
    .filter(plan => plan.partner === partner)
    .filter(plan => plan.planType === planType);
  const filteredFEPlans = fePlans.filter(
    fePlan => fePlan.planType === planType
  );
  if (filteredFEPlans.length === 0) {
    return [];
  }
  return filteredPlans.map(plan => ({
    ...plan,
    // TODO: Add desc & value props for each plan
    ...(filteredFEPlans.find(fePlan => fePlan.name.includes(plan.name)) ||
      filteredFEPlans[0])
  }));
};
