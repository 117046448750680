import { IMemberClientForm1099Balances } from "@wingspanhq/payments/dist/interfaces";
import { ListValueType } from "../../../../components/LabelValueList";
import { isFieldCorrected } from "./getIsCorrectionPossible";

export const nonEditableItems = [
  {
    label: "Federal Income Tax Withheld",
    value: 0,
    type: ListValueType.Money
  },
  {
    label: "State Tax Withheld",
    value: 0,
    type: ListValueType.Money
  }
].map(item => ({ ...item, id: item.label }));

const NON_EMPLOYEE_COMPENSATION_LABEL = "Non-Employee Compensation";

export const getNonEmployeeCompensation = (
  form1099Balance?: IMemberClientForm1099Balances,
  shouldConsiderCorrection: boolean = true
) => {
  if (!form1099Balance) return undefined;

  if (
    shouldConsiderCorrection &&
    isFieldCorrected(form1099Balance.correction?.totalAmount)
  )
    return form1099Balance.correction?.totalAmount;

  const platformIncome = form1099Balance?.platformIncome;
  const adjustments = form1099Balance?.adjustments;
  const paymentProcessingFees = form1099Balance?.paymentProcessingFees;

  const compensation = Number(
    platformIncome + adjustments + paymentProcessingFees
  );

  return Number.isNaN(compensation) ? undefined : compensation;
};

const getTotalsItems = (form1099Balance?: IMemberClientForm1099Balances) =>
  form1099Balance && [
    {
      id: NON_EMPLOYEE_COMPENSATION_LABEL,
      label: NON_EMPLOYEE_COMPENSATION_LABEL,
      value: getNonEmployeeCompensation(form1099Balance),
      type: ListValueType.Money
    },
    ...nonEditableItems
  ];

export default getTotalsItems;
