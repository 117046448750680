import { WSButton } from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../query/hooks/helpers";
import { useUserProfile } from "../../query/users/queries";
import {
  deleteCurrentAdminSessionToken,
  deleteWingspanAdminSessionToken,
  getAdminSessionToken,
  getWingspanAdminSessionToken,
  pushSessionToken
} from "../../services/sessionStorage";
import { StaticBanner } from "../StaticBanner/StaticBanner";
import { queryCache } from "react-query";
import { useResetWSStore } from "../../store";
import { INewUser } from "@wingspanhq/users";
import { reset } from "../../utils/analytics";

export const BackToAdminAccountBanner: React.FC = () => {
  const history = useHistory();
  const userId = useUserId();
  const userQuery = useUserProfile(userId);
  const adminToken = getAdminSessionToken();
  const resetWSStore = useResetWSStore();

  const handleClick = () => {
    const adminToken = getAdminSessionToken();
    if (adminToken) {
      reset();
      pushSessionToken(adminToken);
      deleteCurrentAdminSessionToken();

      if (getWingspanAdminSessionToken() === adminToken) {
        deleteWingspanAdminSessionToken();
        history.push("/member/admin/sign-in-as-member");
      }

      if (window.Cypress) {
        resetWSStore();
        queryCache.clear();
      } else {
        window.location.reload();
      }
    }
  };
  const { email, profile } = userQuery.data as INewUser;

  const getDisplayName = () => {
    if (profile.firstName && profile.lastName) {
      return `${profile.firstName} ${profile.lastName}`;
    } else if (email) {
      return email;
    }
    return `User ${userId}`;
  };

  return (
    <StaticBanner
      name="backToAdminAccount"
      isVisible={!!adminToken}
      onClick={handleClick}
    >
      You're viewing dashboard as {getDisplayName()}. Click{" "}
      <WSButton.Link onClick={handleClick} name="backToYourAccount">
        here
      </WSButton.Link>{" "}
      to return to your account
    </StaticBanner>
  );
};
