import {
  WSButton,
  WSButtons,
  WSContainer,
  WSGrid,
  WSImage,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";

import { openIntercom } from "../shared/utils/intercom";

const RenderDefaultPageErrorMessage: React.FC<{ refetch?: () => any }> = ({
  refetch
}) => {
  const history = useHistory();
  // TODO(artemk): review this grid
  return (
    <WSContainer>
      <WSGrid py="3XL" style={{ flexDirection: "revert" }}>
        <WSScreen.Mobile>
          <WSGrid.Item>
            <WSImage
              placeholder={"Oops image :)"}
              src={require("../assets/images/opps-error.png").default}
            />
          </WSGrid.Item>
        </WSScreen.Mobile>
        <WSGrid.Item span={{ s: "6", m: "5" }}>
          <WSText.Heading1>
            Oops, something went wrong on our end.{" "}
          </WSText.Heading1>
          <WSText color="gray500" mt="XL">
            If this problem persists,{" "}
            <WSButton.Link onClick={openIntercom}>
              contact support.
            </WSButton.Link>
          </WSText>
          <WSButtons mt="2XL">
            <WSButton onClick={() => history.push("/member/dashboard")}>
              Go to Dashboard
            </WSButton>

            {refetch && (
              <WSButton.Secondary onClick={refetch}>Retry</WSButton.Secondary>
            )}
          </WSButtons>
        </WSGrid.Item>

        <WSScreen.TabletAndDesktop>
          <WSGrid.Item span={{ s: "6", m: "5" }}>
            <WSImage
              placeholder={""}
              src={require("../assets/images/opps-error.png").default}
            />
          </WSGrid.Item>
        </WSScreen.TabletAndDesktop>
      </WSGrid>
    </WSContainer>
  );
};

export default RenderDefaultPageErrorMessage;
