import {
  WSButton,
  WSFormOld,
  WSInput,
  WSModal,
  WSText,
  useIsMobile
} from "@wingspanhq/fe-component-library";
import * as React from "react";
import { useForm } from "react-hook-form";
import { trackTemplateRequest } from "../../../utils/analytics";

import styles from "./RequestTemplateModal.module.scss";

interface RequestTemplateFormProps {
  isMobile: boolean;
  onSubmit: (data: { message: string }) => void;
}

const RequestTemplateForm: React.FC<RequestTemplateFormProps> = ({
  isMobile,
  onSubmit
}) => {
  const { register, errors } = useForm();
  return (
    <WSFormOld className={styles.requestTemplateForm} onSubmit={onSubmit}>
      <WSInput
        className={styles.requestTemplateInputContainer}
        inputClassName={
          isMobile
            ? styles.requestTemplateInputMobile
            : styles.requestTemplateInput
        }
        name="message"
        ref={register({
          required:
            "Please describe the type of template you'd like to see here. We may contact you for further details!"
        })}
        placeholder="Write your template request"
        error={errors.message?.message}
      />
      <WSButton.Primary>Send</WSButton.Primary>
    </WSFormOld>
  );
};

interface RequestTemplateModalProps {
  onSubmit: () => void;
  onClose: () => void;
}
export const RequestTemplateModal: React.FC<RequestTemplateModalProps> = ({
  onSubmit,
  onClose
}) => {
  const isMobile = useIsMobile();

  const onRequestTemplateFormSubmit = (data: { message: string }) => {
    trackTemplateRequest(data.message);
    onSubmit();
  };

  return (
    <WSModal onClose={onClose}>
      <WSText weight="medium">Send us your template requests</WSText>
      <WSText mt="XS">
        Have other documents you want assistance drafting? Let us know and we’ll
        give you a hand.
      </WSText>
      <RequestTemplateForm
        isMobile={isMobile}
        onSubmit={onRequestTemplateFormSubmit}
      />
    </WSModal>
  );
};
