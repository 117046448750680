import { useHistory } from "react-router-dom";
import {
  useWSModal,
  useWSSnackbar,
  WSActions,
  WSCheckboxToggle,
  WSForm,
  WSList,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../services/platform";
import React from "react";
import { useRequirementDefinitionListAllQuery } from "../../../query/requirementDefinitions/query/useRequirementDefinitionListAllQuery";
import { WSQueries } from "../../../query/WSQuery";
import { ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH } from "../../EngagementRequirementsSettings";
import { selectorRequirementHumanReadableType } from "../../Requirements/selectors/selectorRequirementHumanReadableType";
import { useAddRequirementToEngagement } from "../../../query/engagements/mutations/useAddRequirementToEngagement";
import { AddRequirementInformation } from "../../Requirements/components/AddRequirementInformation/AddRequirementInformation";

type AddRequirementToEngagementModalProps = {
  engagementId: string;
  requirementsIds?: string[];
  onClose: () => void;
};

type FormData = {
  requirementId: string | undefined;
};

const validationSchema = Yup.object().shape({
  requirementId: Yup.string().required("Required"),
  confirm: Yup.boolean().required("Required")
});

const AddRequirementToEngagementModal: React.FC<AddRequirementToEngagementModalProps> = ({
  requirementsIds = [],
  engagementId,
  onClose
}) => {
  const history = useHistory();
  const { openSnackbar } = useWSSnackbar();

  const queryRequirementsDefinitions = useRequirementDefinitionListAllQuery();
  const [
    addRequirementToEngagement,
    addRequirementToEngagementMeta
  ] = useAddRequirementToEngagement(engagementId);

  return (
    <WSQueries queries={{ queryRequirementsDefinitions }}>
      {({ queryRequirementsDefinitionsData }) => {
        return (
          <>
            <WSText.Paragraph weight="medium" mb="S">
              Engagement information
            </WSText.Paragraph>
            <WSForm<FormData>
              validationSchema={validationSchema}
              onSubmit={async values => {
                await addRequirementToEngagement(
                  {
                    requirementId: values.requirementId!
                  },
                  {
                    onSuccess() {
                      openSnackbar({
                        message: "Requirement added to engagement",
                        type: "success"
                      });
                      onClose();
                    }
                  }
                );
              }}
            >
              <WSList gap="XL">
                <WSForm.Field
                  name="requirementId"
                  label="Requirement"
                  component={WSSelect}
                  componentProps={{
                    required: true,
                    menuFooterAction: {
                      label: "Create new requirement",
                      icon: "plus",
                      onClick() {
                        history.push({
                          pathname: `${ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH}/create`,
                          state: {
                            engagementId
                          }
                        });
                      }
                    },
                    menuFooterText: "Don’t see a requirement?",
                    options: queryRequirementsDefinitionsData.map(
                      requirement => ({
                        value: requirement.requirementDefinitionId,
                        label: requirement.name,
                        labels: [
                          selectorRequirementHumanReadableType(
                            requirement.requirementType
                          )
                        ],
                        delisted: requirementsIds.includes(
                          requirement.requirementDefinitionId
                        )
                      })
                    )
                  }}
                />
                <WSForm.Values names={["requirementId"]}>
                  {({ requirementId }) => {
                    const requirementDefinition = queryRequirementsDefinitionsData.find(
                      r => r.requirementDefinitionId === requirementId
                    );

                    return (
                      <AddRequirementInformation
                        engagementId={engagementId}
                        requirementDefinition={requirementDefinition}
                      />
                    );
                  }}
                </WSForm.Values>

                <WSForm.Field
                  name="confirm"
                  component={WSCheckboxToggle}
                  componentProps={{
                    label: `Confirm applying this requirement to existing and future contractors by proceeding`
                  }}
                />
                <WSErrorMessage
                  contextKey={ErrorContextKey.Other}
                  error={addRequirementToEngagementMeta.error}
                />
                <WSForm.Values names={["requirementId", "confirm"]}>
                  {({ requirementId, confirm }) => {
                    const isValid = requirementId && confirm;

                    return (
                      <WSActions
                        alignment="fill"
                        buttons={[
                          {
                            label: "Add requirement",
                            kind: "Primary",
                            name: "submit",
                            type: "submit",
                            disabled: !isValid
                          },
                          {
                            label: "Cancel",
                            kind: "Secondary",
                            type: "button",
                            onClick() {
                              onClose();
                            }
                          }
                        ]}
                      />
                    );
                  }}
                </WSForm.Values>
              </WSList>
            </WSForm>
          </>
        );
      }}
    </WSQueries>
  );
};

export function useAddRequirementToEngagementModal() {
  return useWSModal(AddRequirementToEngagementModal, {
    title: "Add requirement",
    size: "S"
  });
}
