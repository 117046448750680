import {
  loadStripe,
  StripeCardElementChangeEvent,
  StripeError
} from "@stripe/stripe-js";
import {
  useIsMobile,
  WSButton,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSStripeInput,
  WSStripeInputRef,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { ICreditCard } from "@wingspanhq/users/dist/lib/interfaces";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../../query/hooks/helpers";
import { useCreateOrUpdateSubscriptionV3 } from "../../../query/subscriptions/mutations";
import { ErrorContextKey } from "../../../services/platform";
import { BankCardLogo } from "../../../shared/components/BankCardLogo";
import styles from "./MembershipPaymentMethod.module.scss";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

type MembershipPaymentMethodOwnProps = { card?: ICreditCard };
type MembershipPaymentMethodProps = MembershipPaymentMethodOwnProps;

export const MembershipPaymentMethod: React.FC<MembershipPaymentMethodProps> = ({
  card
}) => {
  const history = useHistory();

  const [isEdit, setIsEdit] = useState(
    !!queryString.parse(window.location.search).editPm
  );

  const [otherPaymentError, setOtherPaymentError] = useState(false);

  const [promoCode, setPromoCode] = useState("");
  const [stripeComplete, setStripeComplete] = useState(false);

  const userId = useUserId();
  const [
    createOrUpdateSubscriptionV3,
    createOrUpdateSubscriptionV3Meta
  ] = useCreateOrUpdateSubscriptionV3(userId);

  const [stripeInputState, setStripeInputState] = useState<
    StripeCardElementChangeEvent | undefined
  >();

  const [stripeTokenResponseError, setStripeTokenResponseError] = useState<
    StripeError | undefined
  >();

  const stripeInput = useRef<WSStripeInputRef>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    const queries = queryString.parse(window.location.search);
    if (queries.editPm) {
      setIsEdit(true);
      const targetEl = document.getElementById("paymentMethodSection");
      setTimeout(() => {
        window?.scrollTo({
          behavior: targetEl ? "smooth" : "auto",
          top: targetEl ? targetEl.offsetTop + 700 : 0
        });
      }, 100);
    }
  }, [window.location, window.location.search]);
  return (
    <WSElement id="paymentMethodSection">
      {isEdit ? (
        <WSFormOld
          className={styles.form}
          onSubmit={async (data: { promo: string; stripeComplete: string }) => {
            createOrUpdateSubscriptionV3Meta.reset();
            if (!(promoCode === "" && !stripeComplete)) {
              setOtherPaymentError(false);
              try {
                var token;
                if (stripeInputState?.complete) {
                  const result = await stripeInput?.current?.createPaymentMethod();
                  if (result?.token) {
                    token = result?.token.id;
                  }
                }
                await createOrUpdateSubscriptionV3(
                  {
                    paymentMethodId: token,
                    discountCode: data.promo
                  },
                  {
                    onSuccess: () => {
                      setIsEdit(false);
                      history.push(history.location.pathname);
                    }
                  }
                );
              } catch (e) {
                console.error(e);
                setOtherPaymentError(true);
              }
            }
          }}
          defaultValues={{
            stripeComplete: "",
            promo: ""
          }}
        >
          {({ setValue, formState }) => (
            <WSElement className={styles.section}>
              <WSGrid>
                <WSGrid.Item span={{ s: "6", m: "5" }}>
                  <WSFormOld.Label>
                    Payment Information (Secured by Stripe)
                  </WSFormOld.Label>
                  <WSStripeInput
                    ref={stripeInput}
                    stripeInstance={stripePromise}
                    onInputChange={event => {
                      setStripeInputState(event);
                      setStripeComplete(event.complete);
                      setValue("stripeComplete", `${event.complete}`);
                    }}
                    name="start"
                  />
                  <WSFormOld.Field
                    hidden
                    name="stripeComplete"
                    component={WSTextInput}
                    componentProps={{
                      disabled: true
                    }}
                  />
                </WSGrid.Item>
              </WSGrid>
              <WSGrid>
                <WSGrid.Item span={{ s: "6", m: "4" }}>
                  <WSFormOld.Field
                    name="promo"
                    label="Promo code (Optional)"
                    mt="M"
                    component={WSTextInput}
                    componentProps={{
                      onChange: event => {
                        setPromoCode(event.target.value);
                      }
                    }}
                  />
                </WSGrid.Item>
              </WSGrid>
              {(createOrUpdateSubscriptionV3Meta.error ||
                stripeTokenResponseError ||
                otherPaymentError) && (
                <WSErrorMessage
                  my="2XL"
                  error={
                    createOrUpdateSubscriptionV3Meta.error?.response?.data[
                      "error"
                    ] ||
                    "Sorry! There was an error processing your payment info. Please try again."
                  }
                  contextKey={ErrorContextKey.AddPaymentMethod}
                />
              )}
              <WSFlexBox direction={isMobile ? "column" : "row"} mt="3XL">
                <WSButton.Primary
                  type="submit"
                  disabled={promoCode === "" && !stripeComplete}
                  loading={formState.isSubmitting}
                  name="update"
                  fullWidth={isMobile}
                >
                  Update
                </WSButton.Primary>
                <WSButton.Tertiary
                  onClick={() => {
                    setIsEdit(false);
                    history.replace(window.location.pathname);
                  }}
                  fullWidth={isMobile}
                  name="cancel"
                  ml={isMobile ? undefined : "S"}
                  mt={isMobile ? "M" : undefined}
                >
                  Cancel
                </WSButton.Tertiary>
              </WSFlexBox>
            </WSElement>
          )}
        </WSFormOld>
      ) : card && card.last4 ? (
        <WSFlexBox.CenterY justify="space-between" className={styles.section}>
          <WSElement>
            <WSText.ParagraphSm color="gray500" mt="2XL">
              Payment
            </WSText.ParagraphSm>
            <WSFlexBox.CenterY mt="XL">
              <BankCardLogo
                className={styles.cardLogo}
                type="credit"
                brand={card.cardBrand}
              />
              <WSText.ParagraphSm color="gray500">
                • • • • {card.last4}
              </WSText.ParagraphSm>
            </WSFlexBox.CenterY>
            {card.discount?.percentOff && (
              <WSText.ParagraphSm mt="M">
                {card.discount?.percentOff}% off promo code applied
              </WSText.ParagraphSm>
            )}
          </WSElement>
          <WSButton.Link
            onClick={() => setIsEdit(true)}
            name="changePaymentMethod"
          >
            Update
          </WSButton.Link>
        </WSFlexBox.CenterY>
      ) : card && !card.last4 ? (
        <WSFlexBox.CenterY justify="space-between" className={styles.section}>
          <WSElement>
            <WSText.ParagraphSm weight="medium" color="gray500" mt="2XL">
              Payment method
            </WSText.ParagraphSm>
            <WSText.ParagraphSm color="gray500" mt="XL">
              No credit card on file
            </WSText.ParagraphSm>
            {card.discount?.percentOff && (
              <WSText.ParagraphSm mt="M">
                {card.discount?.percentOff}% off promo code applied
              </WSText.ParagraphSm>
            )}
          </WSElement>
          <WSButton.Link
            onClick={() => setIsEdit(true)}
            name="changePaymentMethod"
          >
            Add
          </WSButton.Link>
        </WSFlexBox.CenterY>
      ) : (
        <WSFlexBox.CenterY justify="space-between" className={styles.section}>
          <WSElement>
            <WSText.ParagraphSm weight="medium" color="gray500" mt="2XL">
              Payment method
            </WSText.ParagraphSm>
            <WSText.ParagraphSm color="gray500" mt="XL">
              No payment method yet.
            </WSText.ParagraphSm>
          </WSElement>
          <WSButton.Link
            onClick={() => setIsEdit(true)}
            name="changePaymentMethod"
          >
            Add
          </WSButton.Link>
        </WSFlexBox.CenterY>
      )}
    </WSElement>
  );
};
