import { QueryConfig } from "react-query";
import {
  concurrentActions,
  WSServiceError
} from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { QUERY_PAYEE_ENGAGEMENTS_LIST_ALL } from "../keys";
import { getPayeeEngagements } from "../../../services/payeeEngagement";
import times from "lodash/times";
import flatten from "lodash/flatten";
import { IPayeeEngagementListResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";

export const usePayeeEngagementsListAllQuery = (
  payeeId: string,
  params = {} as any,
  config?: QueryConfig<IPayeeEngagementListResponse, WSServiceError>
) => {
  const { sort, ...filter } = params;

  return useWSQuery<IPayeeEngagementListResponse, WSServiceError>(
    [QUERY_PAYEE_ENGAGEMENTS_LIST_ALL, payeeId, params],
    async () => {
      const pageSize = 1000;
      const {
        summary: { listSize },
        data
      } = await getPayeeEngagements(payeeId, {
        filter,
        page: { size: pageSize, number: 1 },
        sort
      });

      const pages = Math.ceil(listSize / pageSize) - 1;

      const actions = times(pages).map((_, i) => () =>
        getPayeeEngagements(payeeId, {
          filter,
          page: { size: pageSize, number: i + 2 },
          sort
        })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return flatten([data, ...allPages.map(p => p.data)]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};
