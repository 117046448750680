import { selectorCountryName } from "../../../shared/selectors/selectorCountryName";
import { EntityType } from "../types";

export const getTaxIdLabel = (type: EntityType, country: string) => {
  if (type === "Business") {
    if (country === "US") {
      return "Employer Identification Number (EIN)";
    }

    if (country === "CA") {
      return "Business Number (BN)";
    }
  }

  if (type === "Individual") {
    if (country === "US") {
      return "Social Security Number (SSN)";
    }

    if (country === "CA") {
      return "Social Insurance Number (SIN)";
    }
  }

  return country ? selectorCountryName(country) + " Tax ID" : "Tax ID";
};

export const getTaxIdMask = (type: EntityType, country: string) => {
  if (type === "Business") {
    if (country === "US") {
      return "99-9999999"; // EIN format
    }

    if (country === "CA") {
      return "999999999"; // BN format
    }
  }

  if (type === "Individual") {
    if (country === "US") {
      return "999-99-9999"; // SSN format
    }

    if (country === "CA") {
      return "999-999-999"; // SIN format
    }
  }

  return "999-9999999"; // Default placeholder
};
