import { QueryConfig } from "react-query";
import { QUERY_PAYOUTS } from "../keys";
import { useWSQuery } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { getPayouts } from "../../../services/api/payments/payouts";
import { IPayoutListResponse } from "../../../services/api/payments/payouts/types";

export const usePayoutsQuery = (
  queryConfig?: QueryConfig<IPayoutListResponse, WSServiceError>
) => {
  return useWSQuery<IPayoutListResponse, WSServiceError>(
    [QUERY_PAYOUTS],
    async () => await getPayouts(),
    queryConfig
  );
};
