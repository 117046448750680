import {
  WSElement,
  WSText,
  WSElementProps,
  detachLayoutProps
} from "@wingspanhq/fe-component-library";

export interface InvoicingConfigSectionProps extends WSElementProps {
  title: string;
}

export const InvoicingConfigSection: React.FC<InvoicingConfigSectionProps> = ({
  title,
  children,
  ...otherProps
}) => {
  const { layoutProps } = detachLayoutProps(otherProps);
  return (
    <WSElement {...layoutProps}>
      <WSText.Heading5 weight="medium" mb="M">
        {title}
      </WSText.Heading5>
      {children}
    </WSElement>
  );
};
