import { useUserProfileData } from "./utils/useUserProfileData";
import { isUserIdsIncluded } from "./utils/isUserIdsIncluded";
import { HIDE_ONE_TIME_INVOICE_NAV_IDS } from "./helpers";

export const useShowNewOneTimeInvoiceNav = () => {
  const userData = useUserProfileData();
  // if not client node, show new one time invoice nav
  const showNewOneTimeInvoiceNav = !isUserIdsIncluded(
    userData,
    HIDE_ONE_TIME_INVOICE_NAV_IDS
  );

  return showNewOneTimeInvoiceNav;
};
