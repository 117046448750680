import {
  useModalContext,
  WSButton,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router";

export const ADD_DEFAULT_PAYMENT_METHOD_WARNING_MODAL =
  "ADD_DEFAULT_PAYMENT_METHOD_WARNING_MODAL";
interface AddDefaultPaymentMethodWarningModalProps {
  nextUrl?: string;
}
export const AddDefaultPaymentMethodWarningModal: React.FC = () => {
  const { closeModal } = useModalContext();
  const history = useHistory();
  const onContinue = ({
    nextUrl
  }: AddDefaultPaymentMethodWarningModalProps) => {
    closeModal(ADD_DEFAULT_PAYMENT_METHOD_WARNING_MODAL);
    history.push(`/member/settings/payment-methods?addPm=1&nextUrl=${nextUrl}`);
  };
  return (
    <WSModal
      name={ADD_DEFAULT_PAYMENT_METHOD_WARNING_MODAL}
      size="XS"
      title="Add default payment method"
    >
      {(modalProps: AddDefaultPaymentMethodWarningModalProps) => (
        <>
          <WSText mb="XL">
            Before approving invoices, you need to configure the default payment
            account that will be used to process the invoice payment.
          </WSText>
          <WSButton onClick={() => onContinue(modalProps)}>Continue</WSButton>
        </>
      )}
    </WSModal>
  );
};
