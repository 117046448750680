import { WSButton, WSFlexBox, WSText } from "@wingspanhq/fe-component-library";
import { IPaymentCard } from "@wingspanhq/payments/dist/interfaces";
import { PaymentCard } from "../../ClientPayments/components/PaymentCard";
import { useMutationDeletePaymentCard } from "../../query/paymentCards/mutations/useMutationDeletePaymentCard";
import { WSErrorMessage } from "../WSErrorMessage/WSErrorMessage";

type Props = {
  paymentCard: IPaymentCard;
  onSuccess?: () => void;
  onClose: () => void;
};

export const ModalDeletePaymentCard: React.FC<Props> = ({
  paymentCard,
  onClose,
  onSuccess
}) => {
  const [deleteCard, meta] = useMutationDeletePaymentCard();

  return (
    <>
      <WSText.Heading3 mb="XS">Remove payment card</WSText.Heading3>
      <WSText color="gray500" mb="M">
        Are you sure you want to remove payment card?
      </WSText>
      <PaymentCard mb="M" paymentCard={paymentCard} />
      <WSErrorMessage mb="M" contextKey="CardActions" error={meta.error} />
      <WSFlexBox>
        <WSButton
          size="M"
          onClick={() => {
            deleteCard(
              {
                paymentCardId: paymentCard.paymentCardId
              },
              {
                onSuccess: () => {
                  onSuccess?.();
                  onClose();
                }
              }
            );
          }}
          destructive
          loading={meta.isLoading}
          mr="S"
        >
          Confirm
        </WSButton>
        <WSButton.Secondary size="M" onClick={onClose}>
          Cancel
        </WSButton.Secondary>
      </WSFlexBox>
    </>
  );
};
