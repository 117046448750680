import { PayerPayeeStatus } from "@wingspanhq/payments/dist/interfaces";
import { ListRequestQuery } from "../../utils/serviceHelper";
import { servicePayments } from "../servicePayments";
import { PayersApiFilter } from "./getPayers";

export interface PayersFilter {
  status?:
    | PayerPayeeStatus
    | {
        in: PayerPayeeStatus[];
      };
}

export const getClientsListSize = async (
  query?: ListRequestQuery<
    PayersApiFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >
): Promise<number> => {
  const { headers } = await servicePayments.get(`/payer`, {
    params: { ...query, page: { size: 1 } }
  });
  return Number(headers["x-ws-list-size"]);
};
