import {
  WSContainer,
  WSFlexBox,
  WSIcon
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React from "react";
import { Fullscreen } from "../../../components/Fullscreen/Fullscreen";
import { ProgressPoint } from "../../../components/NumberPoint/ProgressPoint";
import styles from "./CatchUpLayout.module.scss";

type Props = {
  progress?: number;
  steps?: {
    title: string;
    name: string;
    checked?: boolean;
    current?: boolean;
  }[];
  onBack?(): void;
};

export const CatchUpLayout: React.FC<Props> = ({
  progress,
  children,
  steps,
  onBack
}) => {
  return (
    <Fullscreen>
      <header
        className={classNames(styles.header, {
          [styles.withSteps]: steps
        })}
      >
        {!onBack ? (
          <span />
        ) : (
          <WSIcon
            block
            name="arrow-left"
            onClick={onBack}
            className={styles.back}
          />
        )}

        {steps ? (
          <div className={styles.steps}>
            {steps.map(s => {
              return (
                <div key={s.name} className={styles.step}>
                  <ProgressPoint
                    name={s.name}
                    className={styles.stepPoint}
                    checked={s.checked}
                    current={s.current}
                  />
                  <div className={styles.stepTitle}>
                    {s.current ? <b>{s.title}</b> : s.title}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}

        {progress ? (
          <hr className={styles.progress} style={{ width: `${progress}%` }} />
        ) : null}
      </header>

      <WSContainer>
        <WSFlexBox
          direction="column"
          justify="center"
          alignItems="center"
          className={styles.content}
          wrap="nowrap"
        >
          {children}
        </WSFlexBox>
      </WSContainer>
    </Fullscreen>
  );
};
