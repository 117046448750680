import React, { useMemo } from "react";
import { Sidebar, SidebarProps } from "primereact/sidebar";
import { classNames } from "primereact/utils";
import styles from "./WSSidebar.module.scss";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSText } from "../../core/WSText/WSText.component";
import { WSIcon } from "../../core/WSIcon/WSIcon.component";

export type WSSidebarPosition = "left" | "right" | "top" | "bottom";
export type WSSidebarSize = "S" | "M" | "L" | "AUTO";

export type WSSidebarProps = WSSidebarContainerProps &
  WSSidebarLayoutProps & {
    contentElementProps?: WSElementProps;
  };

export interface WSSidebarContainerProps extends WSElementProps {
  visible?: boolean;
  position?: WSSidebarPosition;
  size?: WSSidebarSize;
  noCloseIcon?: boolean;
  onClose?: () => void;
  onShow?: () => void;
  blockScroll?: boolean;
  dismissable?: boolean;
  closeOnEscape?: boolean;
  baseZIndex?: number;
  className?: string;
  children?: React.ReactNode;
}

export interface WSSidebarLayoutProps extends WSElementProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  contentClassName?: string;
}

const buildContainerProps = (props: WSSidebarContainerProps): SidebarProps => {
  const {
    position = "right",
    className: customClassName,
    blockScroll = true,
    dismissable = true,
    closeOnEscape = true,
    baseZIndex = 1100,
    visible = true,
    size = "M",
    children,
    noCloseIcon,
    onClose,
    onShow,
    ...otherProps
  } = props;

  const className = classNames(
    styles.sidebar,
    styles[position],
    styles[`size${size}`],
    customClassName
  );

  const closeClassName = classNames(
    styles.close,
    "p-sidebar-close p-sidebar-icon p-link"
  );

  return {
    visible,
    onShow,
    onHide() {
      onClose?.();
    },
    content: (
      <WSElement
        className={styles.sidebarContainerContent}
        tabIndex={0}
        {...otherProps}
      >
        {noCloseIcon ? null : (
          <WSElement
            as="button"
            className={closeClassName}
            data-testid="exitIcon"
            onClick={onClose}
          >
            <WSIcon size="M" color="gray600" name="exit" />
          </WSElement>
        )}
        {children}
      </WSElement>
    ),
    position,
    blockScroll,
    dismissable,
    closeOnEscape,
    className,
    baseZIndex,
    showCloseIcon: false,
    maskClassName: classNames(styles.mask, {
      [styles.clickableMask]: dismissable
    })
  };
};

export const Container: React.FC<WSSidebarContainerProps> = (props) => {
  const sidebarProps = useMemo(() => buildContainerProps(props), [props]);

  return <Sidebar {...sidebarProps} />;
};

Container.displayName = "WSSidebar.Container";

const Layout: React.FC<WSSidebarLayoutProps> = ({
  header,
  footer,
  contentClassName,
  children,
  ...otherProps
}) => {
  return (
    <WSElement className={styles.sidebarContent}>
      {header ? (
        <WSElement className={styles.sidebarHeader}>
          {typeof header === "string" ? (
            <WSText.Paragraph className={styles.sidebarHeaderTitle}>
              {header}
            </WSText.Paragraph>
          ) : (
            header
          )}
        </WSElement>
      ) : null}
      <WSElement
        className={classNames(styles.sidebarBody, contentClassName)}
        p="L"
        colorBackground="gray100"
        {...otherProps}
      >
        {children}
      </WSElement>
      {footer ? (
        <WSElement className={styles.sidebarFooter}>{footer}</WSElement>
      ) : null}
    </WSElement>
  );
};

Layout.displayName = "WSSidebar.Layout";

const WSSidebarComponent: React.FC<WSSidebarProps> = (props) => {
  const {
    header,
    footer,
    contentClassName,
    contentElementProps,
    children,
    ...otherProps
  } = props;

  return (
    <Container {...otherProps}>
      <Layout
        footer={footer}
        header={header}
        contentClassName={contentClassName}
        {...contentElementProps}
      >
        {children}
      </Layout>
    </Container>
  );
};

WSSidebarComponent.displayName = "WSSidebar";

export const WSSidebar = Object.assign(WSSidebarComponent, {
  Layout,
  Container
});
