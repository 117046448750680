import { SelectOptionOld } from "@wingspanhq/fe-component-library";
import { IIntegrationAccountResponse } from "@wingspanhq/integrations/dist/lib/interfaces/account";
import { IIntegrationItemResponse } from "@wingspanhq/integrations/dist/lib/interfaces";

export const selectQuickbooksAccountOrItemOptions = (
  items?: (IIntegrationAccountResponse | IIntegrationItemResponse)[]
) => {
  const qboItems = items || [];
  const quickbooksItemOptions: SelectOptionOld[] = qboItems
    .map(qboItem => {
      return {
        label: qboItem.fullyQualifiedName,
        value:
          (qboItem as IIntegrationAccountResponse).accountId ||
          (qboItem as IIntegrationItemResponse).itemId,
        searchText: [qboItem.fullyQualifiedName, qboItem.name]
          .filter(Boolean)
          .join(" ")
      };
    })
    .sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label));

  return quickbooksItemOptions;
};
