import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";
import { IWebflowBenefitCategory } from "@wingspanhq/cms/dist/lib/interfaces/webflow/benefits";
import {
  useIsMobile,
  WSButton,
  WSElement,
  WSFlexBox,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import { INewUser } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { RouteComponentProps } from "react-router";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { useBenefitsService } from "../../query/benefits";
import { useCMSWebflowBenefits } from "../../query/cms/queries";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { useSubscriptionV3Query } from "../../query/subscriptions/queries";
import { useActivities, useUserProfile } from "../../query/users/queries";
import { WSQueries } from "../../query/WSQuery";
import { isPremiumSubscription } from "../../Settings/utils/subscriptionUtils";
import {
  getSegmentAnonymousIdFromLocalStorage,
  track
} from "../../utils/analytics";
import { BenefitBlock } from "../components/BenefitBlock/BenefitBlock.module";
import { BenefitsRequestBenefit } from "../modals/BenefitsRequestBenefit";
import {
  isBenefitAlwaysAdditional,
  isBenefitHidden,
  isBenefitIncludedInSubscriptionLevel,
  sortCMSBenefits
} from "../utils";
import { getBenefitsByTitle } from "../utils/getBenefitByTitle";
import styles from "./Benefits.module.scss";
import { BenefitsCMSBlock } from "./BenefitsCMSBlock";

export const AdditionalCoverage: React.FC<RouteComponentProps> = ({
  history
}) => {
  const isMobile = useIsMobile();
  const userId = useUserId();
  const userProfileQuery = useUserProfile(userId);
  const qService = useBenefitsService();

  const qSubscription = useSubscriptionV3Query(userId);
  const qActivity = useActivities(userId);

  const qFeatureFlags = useFeatureFlags();
  const qCMSWebflowBenefits = useCMSWebflowBenefits();

  useBrowserPageTitle("Benefits - Insurance");

  return (
    <WSPage title="Insurance">
      <BenefitsRequestBenefit />
      <WSText className={styles.text}>
        Add-on options from our hand-picked partners to round out your coverage
        so you can feel secure.
      </WSText>

      <WSQueries
        queries={{
          qActivity,
          qService,
          qFeatureFlags,
          qCMSWebflowBenefits,
          qSubscription
        }}
      >
        {({
          qActivity,
          qService,
          qFeatureFlags,
          qCMSWebflowBenefits,
          qSubscription
        }) => {
          const cmsBenefits = qCMSWebflowBenefits.data.filter(
            benefit =>
              !isBenefitHidden(benefit) &&
              (isBenefitAlwaysAdditional(benefit) ||
                !isBenefitIncludedInSubscriptionLevel(
                  benefit,
                  qSubscription.data
                ))
          );

          const isPremium = isPremiumSubscription(qSubscription.data);
          const benefitsOnly = qActivity.data.events.benefitsOnlySignup;

          return (
            <>
              <WSFlexBox.CenterY mt="XL">
                <WSButton.Primary
                  mr={isMobile ? "NONE" : "XL"}
                  fullWidth={isMobile}
                  onClick={() => {
                    const anonymousId = getSegmentAnonymousIdFromLocalStorage();
                    const {
                      email,
                      profile: { firstName, lastName }
                    } = userProfileQuery.data as INewUser;
                    const fullName = `${firstName} ${lastName}`;
                    const typeFormReference = createPopup(
                      `https://wingspan.typeform.com/to/xXS6mQu8#name=${fullName}&email=${email}&userid=${userId}&anonymousid=${anonymousId}`,
                      {
                        onSubmit: responseId => {
                          track("Request a new benefit form submitted", {
                            responseId: responseId
                          });
                        }
                      }
                    );
                    typeFormReference.open();
                  }}
                >
                  Request a new benefit
                </WSButton.Primary>
                {benefitsOnly ? (
                  <WSButton.Link
                    fullWidth={isMobile}
                    mt={isMobile ? "M" : "NONE"}
                    onClick={() => {
                      history.push("/member/benefits/my-benefits");
                    }}
                  >
                    Explore Premium Perks
                  </WSButton.Link>
                ) : null}
              </WSFlexBox.CenterY>
              <WSFlexBox.CenterY
                mt={isMobile ? "3XL" : "2XL"}
                justify="space-between"
              >
                <WSText.Heading4>Recommended for you</WSText.Heading4>
                <WSButton.Link
                  onClick={() =>
                    history.push("/member/benefits/additional-coverage/all")
                  }
                >
                  View all
                </WSButton.Link>
              </WSFlexBox.CenterY>
              <WSElement>
                {isPremium ? (
                  <>
                    <BenefitBlock
                      isPremium={isPremium}
                      parentPath="additional-coverage"
                      {...getBenefitsByTitle("Health Insurance")}
                    />
                  </>
                ) : (
                  <>
                    <BenefitBlock
                      isPremium={isPremium}
                      parentPath="additional-coverage"
                      {...getBenefitsByTitle("Health Insurance")}
                    />
                    {cmsBenefits
                      ?.filter(benefit =>
                        [
                          IWebflowBenefitCategory.Wellness,
                          IWebflowBenefitCategory.Other
                        ].includes(benefit.category)
                      )
                      .sort((a, b) => sortCMSBenefits(a, b))
                      .map(benefit => (
                        <BenefitsCMSBlock
                          benefit={benefit}
                          subscription={qSubscription.data}
                          parentPath="additional-coverage"
                        />
                      ))}
                  </>
                )}
              </WSElement>

              <WSText.Heading4 mt="2XL">Coming soon</WSText.Heading4>
              <WSElement>
                <BenefitBlock
                  parentPath="additional-coverage"
                  isPremium={isPremium}
                  {...getBenefitsByTitle("Therapy")}
                />
                <BenefitBlock
                  parentPath="additional-coverage"
                  isPremium={isPremium}
                  {...getBenefitsByTitle("Retirement Plans")}
                />
              </WSElement>
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
