import {
  WSButton,
  WSElementProps,
  WSFlexBox,
  WSMenuItem
} from "@wingspanhq/fe-component-library";
import { ReactElement } from "react";
import { Actions } from "../Actions";

interface DropdownButtonProps extends WSElementProps {
  text: string | ReactElement;
  menuItems?: WSMenuItem[];
  name?: string;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  text,
  onClick,
  menuItems,
  name = "dropdownButton",
  ...elementProps
}) => {
  if (!menuItems || menuItems.length === 0) {
    return (
      <WSButton name={name} onClick={onClick} {...elementProps}>
        {text}
      </WSButton>
    );
  }

  return (
    <WSFlexBox.CenterY {...elementProps}>
      <WSButton mr="M" name={name} onClick={onClick}>
        {text}
      </WSButton>
      <Actions name={name} items={menuItems} />
    </WSFlexBox.CenterY>
  );
};

export default DropdownButton;
