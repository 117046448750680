import {
  IEngagement,
  IEngagementAddRequirementRequest
} from "@wingspanhq/payments/dist/interfaces";
import { servicePayments } from "../servicePayments";

export const addRequirementToEngagement = async (
  id: string,
  request: IEngagementAddRequirementRequest
): Promise<IEngagement> => {
  const response = await servicePayments.post(
    `/engagement/${id}/requirement`,
    request
  );
  return response.data;
};
