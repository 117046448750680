import React, { useCallback, useEffect } from "react";
import classNames from "classnames";

import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";

import styles from "./WSInfiniteScroll.component.module.scss";
import { WSFlexBox } from "../../layout";
import throttle from "lodash/throttle";
import { WSText } from "../../core/WSText/WSText.component";

const isElementInViewport = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export interface WSInfiniteScrollProps extends WSElementProps {
  endOfList?: boolean;
  loading?: boolean;
  loadMore?: boolean;
  onLoad: () => void;
}

export const WSInfiniteScroll: React.FC<WSInfiniteScrollProps> = ({
  children,
  endOfList,
  loading,
  loadMore,
  onLoad,
  ...elementProps
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdates = useCallback(
    throttle(() => {
      if (!endOfList && loadMore) {
        onLoad();
      }
    }, 1000),
    [onLoad, endOfList, loadMore]
  );

  useEffect(() => {
    if (loading) {
      return () => {};
    }

    const interval = window.setInterval(() => {
      if (ref.current && isElementInViewport(ref.current)) {
        handleUpdates();
      }
    }, 250);

    return () => {
      window.clearInterval(interval);
    };
  }, [handleUpdates, loading]);

  return (
    <>
      {children}
      {loadMore ? (
        <WSFlexBox.Center mt="M" {...elementProps}>
          {endOfList ? (
            <WSText.ParagraphSm color="gray600">
              All items shown
            </WSText.ParagraphSm>
          ) : (
            <WSElement ref={ref} className={classNames(styles.loader)} />
          )}
        </WSFlexBox.Center>
      ) : null}
    </>
  );
};
