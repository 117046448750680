import { WSTableCell } from "@wingspanhq/fe-component-library";
import { IBulkBatch } from "@wingspanhq/payments/dist/interfaces";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { BulkResource } from "../../types";
import { getUploadedFilename } from "../../utils/getUploadedFilename";

export function BulkBatchFilename({
  batchId,
  bulkResource
}: {
  batchId: string;
  bulkResource: BulkResource;
}) {
  const { useBulkBatch } = getBulkQueriesByResource(bulkResource);

  const qBulkBatch = useBulkBatch(batchId);
  const bulkBatch = qBulkBatch.data as IBulkBatch;
  return (
    <WSTableCell
      // shimmer={qBulkBatch.isLoading}
      text={getUploadedFilename(bulkBatch, bulkResource) || "N/A"}
    />
  );
}
