import { useHistory } from "react-router-dom";
import {
  WSContainer,
  WSGrid,
  WSLayout,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { FormCardAcknowledgements } from "../components/FormCardAcknowledgements";
import { WalletCardType } from "../types";
import {
  WALLET_CONFIRM_ORDER_PHYSICAL_CARD_PATH,
  WALLET_ROOT_PATH
} from "../index";

export const RouteOrderPhysicalCard = () => {
  const history = useHistory();
  return (
    <WSLayout
      headerCenter={
        <WSText.ParagraphSm weight="medium">Order card</WSText.ParagraphSm>
      }
      smallHeader={true}
      onClose={() => {
        history.push(WALLET_ROOT_PATH);
      }}
      line
    >
      <WSContainer verticalPadding>
        <WSGrid>
          <WSGrid.Item span={{ m: "2" }} />
          <WSGrid.Item span={{ m: "8" }}>
            <FormCardAcknowledgements
              cardType={WalletCardType.Physical}
              onBack={() => {
                history.push(WALLET_ROOT_PATH);
              }}
              onContinue={() => {
                history.push(WALLET_CONFIRM_ORDER_PHYSICAL_CARD_PATH);
              }}
            />
          </WSGrid.Item>
        </WSGrid>
      </WSContainer>
    </WSLayout>
  );
};
