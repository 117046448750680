import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSSidebar,
  WSText,
  toWSDateString,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import {
  FrequencyAndScheduleStatus,
  ScheduleStatus
} from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { Card } from "../../ClientPayments/components/Card/Card";
import { PublicAttachmentDownloadLink } from "../../components/PublicAttachmentDownloadLink/PublicAttachmentDownloadLink";
import { WSQueries } from "../../query/WSQuery";
import {
  useSendInvoiceTemplate,
  useSendNowInvoiceTemplate
} from "../../query/payments/mutations";
import {
  useInvoiceTemplateQuery,
  useMemberClientQuery
} from "../../query/payments/queries";
import {
  getClientCompany,
  getClientName
} from "../../query/payments/selectors";
import { useSetWSStore } from "../../store";
import { isToday } from "../../utils/dates";
import { InvoiceDetailsCollaborators } from "../components/InvoiceDetailsCollaborators/InvoiceDetailsCollaborators";
import { InvoiceDetailsHeader } from "../components/InvoiceDetailsHeader/InvoiceDetailsHeader";
import { SeriesList } from "../components/SeriesList/SeriesList";
import { Totals, TotalsSection } from "../components/Totals/Totals";
import {
  calculateInvoiceTempalteDueDate,
  calculateLineItemsTotal
} from "../utils";
import { getLineItemsTotals } from "../utils/getLineItemsTotals";
import styles from "./InvoicesInvoiceDetails.module.scss";

type Props = RouteComponentProps<{
  invoiceTemplateId: string;
  listName?: string;
}>;

export const InvoicesInvoiceTemplateDetails: React.FC<Props> = ({ match }) => {
  const history = useHistory();
  const location = useLocation<{ backPath?: string }>();

  const onBack = () => {
    const newPath = location.state?.backPath
      ? location.state?.backPath
      : location.pathname.includes("series")
      ? "/member/invoices/scheduled"
      : `/member/invoices${
          match.params.listName ? `/${match.params.listName}` : ""
        }`;

    history.push({
      pathname: newPath,
      search: location.search
    });
  };

  return (
    <WSSidebar onClose={onBack} header={"Invoice # Scheduled"}>
      <Inner
        invoiceTemplateId={match.params.invoiceTemplateId}
        onBack={onBack}
      />
    </WSSidebar>
  );
};

type InnerProps = {
  invoiceTemplateId: string;
  onBack: () => void;
};

const Inner: React.FC<InnerProps> = ({ invoiceTemplateId, onBack }) => {
  const [detailsVisible, setDetailsVisible] = useState(true);
  const [seriesVisible, setSeriesVisible] = useState(false);
  const invoiceTemplateQuery = useInvoiceTemplateQuery(invoiceTemplateId);
  const memberClientQuery = useMemberClientQuery(
    invoiceTemplateQuery.data?.invoiceData.memberClientId as string,
    {
      retry: false,
      enabled: !!invoiceTemplateQuery.data?.invoiceData.memberClientId
    }
  );
  const history = useHistory();
  const [
    sendInvoiceTemplate,
    sendInvoiceTemplateMeta
  ] = useSendInvoiceTemplate();
  const [
    sendNowInvoiceTemplate,
    sendNowInvoiceTemplateMeta
  ] = useSendNowInvoiceTemplate();
  const location = useLocation();
  const setStore = useSetWSStore();

  return (
    <WSElement className={styles.wrapper}>
      <WSQueries queries={{ invoiceTemplateQuery, memberClientQuery }}>
        {({
          invoiceTemplateQuery: { data: invoiceTemplate },
          memberClientQuery: { data: memberClient }
        }) => {
          const totalsSections: TotalsSection[] = [];

          const totalsLineItemsSection = getLineItemsTotals(
            invoiceTemplate.invoiceData.lineItems
          );

          totalsSections.push(totalsLineItemsSection);

          const totalsWithholdingsSection: TotalsSection = {
            items: [
              {
                bold: true,
                left: "Total",
                right: toWSMoneyString(
                  calculateLineItemsTotal(invoiceTemplate.invoiceData.lineItems)
                )
              }
            ]
          };

          totalsSections.push(totalsWithholdingsSection);

          const dueDate = calculateInvoiceTempalteDueDate(invoiceTemplate);

          const sendDateInFuture =
            invoiceTemplate.scheduleDates?.[0]?.date &&
            invoiceTemplate.scheduleDates[0].date > new Date() &&
            !isToday(invoiceTemplate.scheduleDates[0].date);

          return (
            <>
              <WSElement className={styles.main}>
                <Card mb="XL">
                  <WSText.Heading4 mb="M">
                    {getClientCompany(memberClient)}
                  </WSText.Heading4>
                  {invoiceTemplate.status ===
                    FrequencyAndScheduleStatus.Active &&
                  invoiceTemplate.frequency ? (
                    <InvoiceDetailsHeader
                      title="Invoice is scheduled"
                      description={`${
                        invoiceTemplate.scheduleDates?.[0]?.status ===
                        ScheduleStatus.Completed
                          ? "Next"
                          : "First"
                      } invoice will be sent ${toWSDateString(
                        invoiceTemplate.scheduleDates?.[0]?.status ===
                          ScheduleStatus.Completed
                          ? invoiceTemplate.nextInvoiceDate
                          : invoiceTemplate.scheduleDates?.[0]?.date,
                        "monthDayYear"
                      )}`}
                      buttonProps={{
                        children: "Edit series",
                        onClick: () => {
                          setStore({ invoiceFormBackPath: location.pathname });
                          history.push(
                            `/member/invoices/template/${invoiceTemplateId}/edit`
                          );
                        }
                      }}
                      invoiceTemplate={invoiceTemplate}
                      memberClient={memberClient}
                    />
                  ) : invoiceTemplate.status ===
                    FrequencyAndScheduleStatus.Active ? (
                    <InvoiceDetailsHeader
                      title="Invoice is scheduled"
                      description={`Send date is ${toWSDateString(
                        invoiceTemplate.scheduleDates?.[0]?.date,
                        "monthDayYear"
                      )}`}
                      buttonProps={{
                        children: "Send now",
                        onClick: () => {
                          sendNowInvoiceTemplate({ invoiceTemplateId });
                        },
                        loading: sendNowInvoiceTemplateMeta.isLoading
                      }}
                      invoiceTemplate={invoiceTemplate}
                      memberClient={memberClient}
                    />
                  ) : (
                    <InvoiceDetailsHeader
                      title="This is a draft"
                      description="Send now or schedule for later"
                      buttonProps={{
                        children: sendDateInFuture ? "Schedule" : "Send now",
                        onClick: () => {
                          if (sendDateInFuture) {
                            sendInvoiceTemplate({ invoiceTemplateId });
                          } else {
                            sendNowInvoiceTemplate({ invoiceTemplateId });
                          }
                        },
                        loading:
                          sendInvoiceTemplateMeta.isLoading ||
                          sendNowInvoiceTemplateMeta.isLoading
                      }}
                      invoiceTemplate={invoiceTemplate}
                      memberClient={memberClient}
                    />
                  )}
                </Card>

                <Card mb="XL">
                  <WSFlexBox wrap="nowrap" justify="space-between">
                    <WSButton.Link
                      onClick={() => {
                        setDetailsVisible(!detailsVisible);
                      }}
                      rightIcon={detailsVisible ? "caret-up" : "caret-down"}
                    >
                      {detailsVisible ? "Hide" : "Show"} invoice details
                    </WSButton.Link>
                    <WSText.Heading5>
                      {toWSMoneyString(
                        calculateLineItemsTotal(
                          invoiceTemplate.invoiceData.lineItems
                        )
                      )}
                    </WSText.Heading5>
                  </WSFlexBox>
                  {detailsVisible && (
                    <WSElement mt="XL">
                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          Bill to
                        </WSText.ParagraphSm>
                        <WSText>{getClientName(memberClient)}</WSText>
                        <WSText>
                          {[
                            memberClient.emailTo,
                            ...(memberClient.emailCC || [])
                          ].join(", ")}
                        </WSText>
                      </WSElement>

                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          Due date
                        </WSText.ParagraphSm>
                        <WSText>
                          {toWSDateString(dueDate, "monthDayYear")}
                        </WSText>
                      </WSElement>

                      <WSElement>
                        <Totals sections={totalsSections} />
                      </WSElement>

                      {invoiceTemplate.invoiceData.attachments
                        ?.customAttachmentIds && (
                        <WSElement mt="XL">
                          {invoiceTemplate.invoiceData.attachments?.customAttachmentIds.map(
                            id => (
                              <PublicAttachmentDownloadLink id={id} mb="M" />
                            )
                          )}
                        </WSElement>
                      )}

                      {invoiceTemplate.invoiceData.collaborators &&
                        invoiceTemplate.invoiceData.collaborators.length >
                          0 && (
                          <WSElement mt="3XL">
                            <WSText.Heading5 mb="M">
                              Contractors
                            </WSText.Heading5>
                            <InvoiceDetailsCollaborators
                              invoiceCollaborators={
                                invoiceTemplate.invoiceData.collaborators
                              }
                            />
                          </WSElement>
                        )}
                    </WSElement>
                  )}
                </Card>

                <Card>
                  <WSButton.Link
                    key={seriesVisible ? "Hide" : "Show"}
                    onClick={() => {
                      setSeriesVisible(v => !v);
                    }}
                    rightIcon={seriesVisible ? "caret-up" : "caret-down"}
                  >
                    {seriesVisible ? "Hide" : "Show"} series details
                  </WSButton.Link>
                  {seriesVisible && (
                    <SeriesList invoiceTemplateId={invoiceTemplateId} mt="XL" />
                  )}
                </Card>
              </WSElement>
            </>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
