import {
  useModalContext,
  WSButton,
  WSButtons,
  WSDivider,
  WSElement,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { openInNewTab } from "../../../utils/openInNewTab";

export const CASHBACK_INFO_MODAL = "CASHBACK_INFO_MODAL";

export const CashbackInfoModal: React.FC = () => {
  const { closeModal } = useModalContext();

  return (
    <WSModal
      name={CASHBACK_INFO_MODAL}
      size="XS"
      onClose={() => closeModal(CASHBACK_INFO_MODAL)}
      allowClickOutsideToClose
    >
      <WSElement p="S">
        <WSText.Heading4 mb="XL">Cashback</WSText.Heading4>
        <WSText>
          All Qualifying Purchases will receive one percent (1%) cashback for
          each dollar ($1) spent.
        </WSText>
        <WSText mt="M">
          Cashback will be posted to your Cashback Balance within five (5)
          business days following the end of the calendar month, based on the
          Qualifying Purchases made with your Card, plus any Bonus Cashback
          earned, but minus any returns or refunds for the preceding month.
        </WSText>

        <WSButtons mt="XL">
          <WSButton.Link
            onClick={() => {
              openInNewTab(
                "https://www.wingspan.app/banking-disclosures/wingspan-cash-back-terms-conditions"
              );
            }}
          >
            View terms and conditions
          </WSButton.Link>
        </WSButtons>
      </WSElement>
    </WSModal>
  );
};
