import React, { useState } from "react";
import {
  WSCheckboxToggle,
  WSContainer,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSIconList,
  WSCentered,
  WSPanel,
  WSSelectOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import {
  useCreateAccount,
  useUpdateMemberProfile
} from "../../../query/users/mutations";
import { useAccounts, useMemberProfile } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { getAccountName } from "../../../query/bookkeeping/selectors";
import { useFlatLineImporter } from "../../../query/hooks/flatlineImporter";
import * as Yup from "yup";
import {
  AccountStatus,
  AccountType,
  IAccount,
  TaxFilingCode
} from "@wingspanhq/users/dist/lib/interfaces";
import { track } from "../../../utils/analytics";
import { useSetBookkeepingEnable } from "../../../query/bookkeeping/mutations";
import { useUserId } from "../../../query/hooks/helpers";
import { useBookkeepingStatus } from "../../../query/bookkeeping/queries";

export const BookkeepingCSVStart: React.FC<{ next: string }> = ({ next }) => {
  const history = useHistory();
  const userId = useUserId();
  const qAccounts = useAccounts();
  const [createAccount] = useCreateAccount();
  const flatLineImporter = useFlatLineImporter();
  const [formError, setFormError] = useState("");
  const [disableNewAccount, setDisableNewAccount] = useState(false);
  const [setBookkeepingEnable] = useSetBookkeepingEnable();
  const qMember = useMemberProfile(userId);
  const [updateMemberProfile] = useUpdateMemberProfile();
  const qBookkeepingService = useBookkeepingStatus();

  useBrowserPageTitle("Bookkeeping - CSV");

  return (
    <ProjectOnboardingLayout progress={(100 / 5) * 2} noBack>
      <WSQueries queries={{ qAccounts, qMember, qBookkeepingService }}>
        {({ qAccounts, qMember, qBookkeepingService }) => {
          const accounts = qAccounts.data.filter(a => a.usedFor?.bookkeeping);

          return (
            <WSContainer verticalPadding>
              <WSFormOld
                validationSchema={Yup.object().shape({
                  accountId: Yup.mixed().oneOf(
                    [...accounts.map(a => a.accountId), ""],
                    "Select account"
                  ),
                  accountName: Yup.string(),
                  last4: Yup.string().max(4),
                  isCredit: Yup.boolean()
                })}
                defaultValues={{
                  accountId: "",
                  accountName: "",
                  last4: "",
                  isCredit: false
                }}
                onSubmit={async formData => {
                  setFormError("");

                  if (!formData.accountId && !formData.accountName) {
                    setFormError(
                      accounts.length > 0
                        ? "Please pick an account from the list or fill in your new account details"
                        : "Please fill in your new account details"
                    );
                    return;
                  }

                  track("Transaction Spreadsheet Upload Started", {
                    type: next.includes("setup") ? "initial" : "incremental"
                  });

                  const results = await flatLineImporter();

                  if (!results.length) {
                    setFormError(
                      "Sorry! We could not find any transactions in the file you uploaded"
                    );
                    return;
                  }

                  let accountId;

                  if (formData.accountId) {
                    accountId = formData.accountId;
                  } else if (formData.accountName) {
                    const newAccounts = (await createAccount({
                      name: formData.accountName,
                      usedFor: {
                        bookkeeping: true
                      },
                      type: formData.isCredit
                        ? AccountType.Credit
                        : AccountType.Depository,
                      status: AccountStatus.Active,
                      ...{
                        ...(formData.last4 ? { mask: formData.last4 } : {})
                      }
                    })) as IAccount[];
                    accountId = newAccounts[0].accountId;
                  }

                  if (!qBookkeepingService.data.enabled) {
                    await updateMemberProfile({
                      memberId: userId,
                      profile: {
                        taxInfo: {
                          stateOfResidence:
                            qMember.data.profile.taxInfo?.stateOfResidence ??
                            "",
                          filingCode:
                            qMember.data.profile.taxInfo?.filingCode ??
                            TaxFilingCode.Single,
                          incomeEstimateForYear:
                            qMember.data.profile.taxInfo
                              ?.incomeEstimateForYear ?? 0,
                          expensesEstimateForYear:
                            qMember.data.profile.taxInfo
                              ?.expensesEstimateForYear ?? 0,
                          numExemptions:
                            qMember.data.profile.taxInfo?.numExemptions ?? 0
                        }
                      }
                    });

                    await setBookkeepingEnable(userId);
                  }

                  history.replace(next, {
                    accountId,
                    transactions: results
                  });
                }}
              >
                <WSCentered span={{ m: "6" }}>
                  <WSText.ParagraphSm color="gray500" mt="M" pt="M">
                    2 of 5
                  </WSText.ParagraphSm>
                  <WSText.Heading4>
                    Import historic business transactions
                  </WSText.Heading4>

                  <WSPanel mt="2XL">
                    <WSText.Heading5 mb="XL">Specify account</WSText.Heading5>

                    {accounts.length ? (
                      <>
                        <WSFormOld.Field
                          mb="XL"
                          label="Existing account"
                          name="accountId"
                          component={WSSelectOld}
                          componentProps={{
                            onChange: value =>
                              value && setDisableNewAccount(true),
                            options: accounts.map(a => ({
                              value: a.accountId,
                              label: getAccountName(a)
                            }))
                          }}
                        />
                        {disableNewAccount ? null : (
                          <WSDivider label="Or add new account" mb="XL" />
                        )}
                      </>
                    ) : null}

                    {disableNewAccount ? null : (
                      <>
                        <WSFormOld.Field
                          mb="XL"
                          label="Account Name"
                          name="accountName"
                          component={WSTextInput}
                        />

                        <WSFormOld.Field
                          mb="XL"
                          label="Last four digits of account number (Optional)"
                          name="last4"
                          component={WSTextInput}
                        />

                        <WSFormOld.Field
                          mb="XL"
                          componentProps={{
                            label: "This is a credit account"
                          }}
                          name="isCredit"
                          component={WSCheckboxToggle}
                        />
                      </>
                    )}
                  </WSPanel>

                  <WSPanel mt="2XL">
                    <WSText.Heading5>
                      Required transaction details for upload
                    </WSText.Heading5>

                    <WSText mt="XL">
                      Don’t worry if your spreadsheet has other details, but
                      please make sure it contains the following columns.
                    </WSText>

                    <WSFlexBox mt="2XL">
                      <WSElement style={{ flex: 1 }}>
                        <WSText weight="medium" mb="M">
                          Required
                        </WSText>
                        <WSIconList.Checks
                          items={["Date", "Description", "Amount"]}
                          mb="XL"
                        />
                      </WSElement>
                      <WSElement style={{ flex: 1 }}>
                        <WSText weight="medium" mb="M">
                          Optional
                        </WSText>
                        <WSIconList.Checks items={["Category"]} mb="XL" />
                      </WSElement>
                    </WSFlexBox>

                    {formError !== "" && (
                      <WSText mt="2XL" color="red400">
                        {formError}
                      </WSText>
                    )}

                    <WSFormOld.SubmitButton fullWidth mt="2XL" name="uploadCsv">
                      Upload CSV
                    </WSFormOld.SubmitButton>
                  </WSPanel>
                </WSCentered>
              </WSFormOld>
            </WSContainer>
          );
        }}
      </WSQueries>
    </ProjectOnboardingLayout>
  );
};
