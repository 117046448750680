import { WSButton, useWSModal } from "@wingspanhq/fe-component-library";
import cn from "classnames";
import React from "react";
import GoogleLogin from "react-google-login";
import signInWithGoogleIcon from "../../../assets/icons/signInWithGoogleIcon.png";
import styles from "./GoogleAuthButton.module.scss";
import { ModalGoogleAuthWarning } from "./ModalGoogleAuthWarning";

type Props = {
  onLoginSuccess: (token: string) => void;
  onLoginFailure: (error: any) => void;
  className?: string;
  isLoading?: boolean;
  clientEmailToPrefill?: string;
};

export const GoogleAuthButton: React.FC<Props> = ({
  onLoginSuccess,
  onLoginFailure,
  className,
  isLoading,
  clientEmailToPrefill,
  children
}) => {
  const modalGoogleAuthWarning = useWSModal(ModalGoogleAuthWarning, {
    size: "S"
  });

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_SIGN_IN_WITH_GOOGLE_CLIENT_ID || ""}
      onSuccess={(response: any) => {
        onLoginSuccess(response.tokenId);
      }}
      onFailure={error => {
        if (
          !(
            error.error === "idpiframe_initialization_failed" ||
            error.error === "popup_closed_by_user"
          )
        ) {
          console.error("Google auth error:" + JSON.stringify(error));
          onLoginFailure(error);
        }
      }}
      render={renderProps => (
        <WSButton.Secondary
          className={cn(styles.base, className)}
          type="button"
          onClick={() => {
            if (clientEmailToPrefill) {
              modalGoogleAuthWarning.open({
                onContinue: renderProps.onClick
              });
            } else {
              renderProps.onClick();
            }
          }}
          disabled={renderProps.disabled}
          fullWidth
          loading={isLoading}
        >
          <img src={signInWithGoogleIcon} className={styles.icon} alt="" />
          {children}
        </WSButton.Secondary>
      )}
    />
  );
};
