import { IWebflowEnterprise } from "@wingspanhq/cms/dist/lib/interfaces";
import { IActivity } from "@wingspanhq/users/dist/lib/interfaces";
import logoWingspan from "../../../assets/images/ws-logo.svg";
import {
  WHITE_LABEL_ADP,
  WHITE_LABEL_UNDERWRITER,
  WHITE_LABEL_VELOCITY_GLOBAL
} from "../../constants/whiteLabel";
import logoAdp from "./logoAdp.svg";
import logoUnderwriterservicesassoc from "./logoUnderwriterservicesassoc.png";
import logoVelocityGlobal from "./logoVelocityGlobal.png";

export const selectorLogoData = (
  activity?: IActivity,
  enterprise?: IWebflowEnterprise
) => {
  let logo = {
    src: logoWingspan,
    alt: "Wingspan"
  };

  if (activity?.context?.growthSourceName === WHITE_LABEL_UNDERWRITER) {
    logo = {
      src: logoUnderwriterservicesassoc,
      alt: "Under Writer"
    };
  } else if (activity?.context?.growthSourceName === WHITE_LABEL_ADP) {
    logo = {
      src: logoAdp,
      alt: "ADP"
    };
  } else if (
    activity?.context?.growthSourceName === WHITE_LABEL_VELOCITY_GLOBAL
  ) {
    logo = {
      src: logoVelocityGlobal,
      alt: "Velocity global"
    };
  } else if (enterprise?.logo?.src && enterprise?.isWhiteLabelLogo) {
    logo = {
      src: enterprise.logo.src,
      alt: enterprise.logo.placeholder || enterprise.name
    };
  }

  return logo;
};
