import { FlatfileRecord } from "@flatfile/sdk";

/**
 * It takes a Backend error message and spreadsheet row as parameters
 * and look for row object's each key name in error message, if found any
 * it will return the array of errors for the identified columns otherwise
 * it will return the array of errors for the entire row.
 *
 * ASSUMPTION: spreadsheet row object's key names should match with Backend's API
 * request object's key names.
 */
export function getRecordRejectionErrors(
  errorMessage: string,
  csvRow?: FlatfileRecord["data"]
) {
  const recordFieldErrors: { field: string; message: string }[] = [];
  const recordErrors: { field: string; message: string }[] = [];

  for (const csvField in csvRow) {
    const lookupRegEx = new RegExp("\\b" + csvField + "\\b", "i");
    if (lookupRegEx.test(errorMessage)) {
      recordFieldErrors.push({ field: csvField, message: errorMessage });
    }
    recordErrors.push({ field: csvField, message: errorMessage });
  }
  return recordFieldErrors.length > 0 ? recordFieldErrors : recordErrors;
}
