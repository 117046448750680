import classNames from "classnames";
import React from "react";
import { WSElement } from "../WSElement/WSElement.component";
import { WSErrorBoundary } from "../WSErrorBoundary/WSErrorBoundary";
import { WSTableCell } from "../WSTableCell/WSTableCell";
import styles from "./WSTable.module.scss";
import { WSTableColumn, WSTableItem } from "./types";

const CellFallback = () => (
  <WSTableCell
    text="--"
    secondaryText="error loading"
    secondaryTextColor="amber400"
  />
);

const safeCellRenderFunction = (
  column: WSTableColumn,
  rowItem: WSTableItem
): React.ReactNode => {
  try {
    return column.renderFunction(rowItem);
  } catch (error) {
    let errorMessage = "Unknown error";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    console.warn(
      `WSTable cell ${rowItem.id} encountered an error during render: ${errorMessage}`
    );
    return <CellFallback />;
  }
};

type Props = {
  tableData: Array<WSTableItem>;
  columns: Array<WSTableColumn>;
  selection: Array<WSTableItem>;
  getCellOnClick?: (
    column: WSTableColumn,
    rowItem: WSTableItem
  ) => (() => void) | undefined;
  getCellOnControlClick?: (
    column: WSTableColumn,
    rowItem: WSTableItem
  ) => (() => void) | undefined;
  getCellOnMousewheelClick?: (
    column: WSTableColumn,
    rowItem: WSTableItem
  ) => (() => void) | undefined;
  showHover?: boolean;
};

export const TableData: React.FC<Props> = ({
  tableData,
  columns,
  selection,
  getCellOnClick,
  getCellOnControlClick,
  getCellOnMousewheelClick,
  showHover
}) => {
  return (
    <>
      {tableData.map((rowItem, rowIndex) => {
        const isSelected = selection.some((item) => item.id === rowItem.id);

        return (
          <WSElement
            key={`row-${rowIndex}`}
            className={classNames(
              styles.row,
              {
                [styles.rowWithHover]: showHover
              },
              isSelected && styles.selected
            )}
          >
            {columns.map((column, columnIndex) => (
              <WSElement
                key={`row-${rowItem.id}-cell-${columnIndex}`}
                onClick={getCellOnClick?.(column, rowItem)}
                onControlClick={getCellOnControlClick?.(column, rowItem)}
                onMousewheelClick={getCellOnMousewheelClick?.(column, rowItem)}
                className={classNames(
                  styles.cell,
                  column.config?.className,
                  column.config?.justify &&
                    styles[`justify-${column.config.justify}`]
                )}
              >
                <WSErrorBoundary fallback={<CellFallback />}>
                  {safeCellRenderFunction(column, rowItem)}
                </WSErrorBoundary>
              </WSElement>
            ))}
          </WSElement>
        );
      })}
    </>
  );
};
