import {
  toWSDateString,
  WSButton,
  WSFlexBox
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { ReportFormat } from "../../types";
import { downloadCSV } from "../../utils/sheetjs/downloadCSV";
import { downloadXLSX } from "../../utils/sheetjs/downloadXLSX";
import { Actions } from "../../../../components/Actions";

export const DownloadReportButton: React.FC<{
  disabled: boolean;
  getData(): any;
  onClick?(format: ReportFormat): void;
  fileName: string;
  sheetName: string;
}> = ({ disabled, getData, onClick, fileName, sheetName }) => {
  const [loading, setLoading] = useState(false);
  const onDownloadXLSX = async () => {
    onClick && onClick(ReportFormat.XLSX);
    setLoading(true);
    await downloadXLSX(
      getData(),
      `${fileName} ${toWSDateString(new Date(), "mobileInput")}`,
      sheetName
    );
    setLoading(false);
  };

  const onDownloadCSV = async () => {
    onClick && onClick(ReportFormat.CSV);
    setLoading(true);
    await downloadCSV(
      getData(),
      `${fileName} ${toWSDateString(new Date(), "mobileInput")}`,
      sheetName
    );
    setLoading(false);
  };

  return (
    <WSFlexBox.CenterY>
      <WSButton
        mr="M"
        name="downloadReportButton"
        onClick={onDownloadXLSX}
        disabled={disabled}
      >
        Download report
      </WSButton>
      <Actions
        disabled={disabled}
        name="actions"
        items={[
          {
            label: "Download CSV",
            onClick: onDownloadCSV
          },
          {
            label: "Download XLSX",
            onClick: onDownloadXLSX
          }
        ]}
      />
    </WSFlexBox.CenterY>
  );
};
