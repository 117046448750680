import {
  WSButton,
  WSCentered,
  WSFlexBox,
  WSFormOld,
  WSRadioInputGroup,
  WSText,
  useIsMobile
} from "@wingspanhq/fe-component-library";
import * as React from "react";
import * as Yup from "yup";

import { IOnboardingQuestion } from "../../types";
import { WSVirtualAssistant } from "../WSVirtualAssistant/WSVirtualAssistant";

import styles from "./OnboardingQuestion.module.scss";

interface OnboardingQuestionProps {
  name: string;
  question: IOnboardingQuestion;
  defaultValues?: any;
  onSubmit: (data: any) => void;
  isSubmitting?: boolean;
}

export const OnboardingQuestion: React.FC<OnboardingQuestionProps> = ({
  name,
  question: { question: questionText, options },
  onSubmit,
  isSubmitting,
  defaultValues
}) => {
  const isMobile = useIsMobile();
  const optionList = options.map(option => ({
    value: option.value,
    label: option.label
  }));

  const renderFooter = (value: string) => {
    const selectedOption = options.find(option => option.value === value);
    return (
      selectedOption && (
        <WSCentered span={{ s: "10", m: "8" }}>
          <WSFlexBox.Center my="2XL">
            <WSButton
              className={styles.submitBtn}
              fullWidth
              type="submit"
              loading={isSubmitting}
              name="submitQuestion"
            >
              {selectedOption?.ctaBtnText}
            </WSButton>
          </WSFlexBox.Center>

          <WSVirtualAssistant
            message={selectedOption?.virtualAssistantMsg || ""}
          />
        </WSCentered>
      )
    );
  };
  return (
    <WSFlexBox.Center
      className={styles.question}
      justify={isMobile ? "flex-start" : "center"}
    >
      <WSText.Heading4 color="gray700" mb="2XL" className={styles.questionText}>
        {questionText}
      </WSText.Heading4>
      <WSFormOld
        className={styles.form}
        onSubmit={onSubmit}
        defaultValues={{
          [name]: defaultValues[name]
        }}
        validationSchema={Yup.object().shape({
          [name]: Yup.string().required("Required")
        })}
      >
        <WSFormOld.Field name={name} className={styles.form}>
          <WSFormOld.Input
            render={props => (
              <WSRadioInputGroup
                {...props}
                onChange={(event: any) => {
                  const option = options.find(
                    o => o.value === event.target?.value
                  );

                  if (option?.disabled) {
                    return;
                  }

                  props.onChange(event);
                }}
                options={optionList}
                radioInputClassName={styles.option}
              />
            )}
          />
          <WSFormOld.Error />
        </WSFormOld.Field>
        <WSFormOld.Value name={name}>
          {value => renderFooter(value)}
        </WSFormOld.Value>
      </WSFormOld>
    </WSFlexBox.Center>
  );
};
