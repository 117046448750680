import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

export interface WSFormValuesProps {
  children(props: any): React.ReactNode;
  names: string[];
}

export const WSFormValues = ({ children, names }: WSFormValuesProps) => {
  const { control } = useFormContext();

  const value = useWatch({
    control,
    name: names
  });

  return <>{children(value)}</>;
};
