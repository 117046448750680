import { WSButton, WSInfoBox } from "@wingspanhq/fe-component-library";
import { verificationService } from "../../../shared/services/verificaiton";

type Props = {
  footprintToken: string;
};

export const MismatchInfoBox: React.FC<Props> = ({ footprintToken }) => {
  return (
    <WSInfoBox
      theme="warning"
      title="We could not match your Social Security Number with IRS records"
    >
      <ol>
        <li>
          <b>Re-verify your details</b>
          <ul>
            <li>
              Ensure your full name and SSN match exactly with your Social
              Security Card
            </li>
          </ul>
        </li>

        <li>
          <b>Contact the IRS</b>
          <ul>
            <li>
              Reach out to the IRS Individual Taxpayer Line to confirm the SSN
              and name on file.
            </li>
            <li>
              <a href="tel:+18008294933">+1 800-829-4933</a>
            </li>
          </ul>
        </li>

        <li>
          <b>Changed your name recently?</b>
          <ul>
            <li>
              If you've had a legal name change due to marriage, divorce, or
              other reasons, you may need to update the Social Security
              Administration (SSA) records.
            </li>
            <li>Visit your local SSA office with the necessary documents.</li>
          </ul>
        </li>

        <li>
          <b>Upload confirmation documents</b>
          <ul>
            <li>
              Share a clear photo or scan of your Social Security card for
              manual verification following these instructions. When you’ve
              gathered your documents, upload them by clicking the button:
            </li>
          </ul>
        </li>

        <WSButton.Secondary
          my="L"
          fullWidth
          size="S"
          onClick={() => {
            verificationService.openFootprintPlaybook(footprintToken);
          }}
        >
          Upload confirmation documents
        </WSButton.Secondary>

        <li>
          <b>Need more help?</b>
          <ul>
            <li>Review our guide on how to handle TIN verification errors.</li>
          </ul>
        </li>
      </ol>
    </WSInfoBox>
  );
};
