import { INewUser } from "@wingspanhq/users";
import { IAuthorization } from "@wingspanhq/users/dist/lib/interfaces";
import uniq from "lodash/uniq";
import { getUserName } from "../../../query/users/selectors";

export const getOrganizationScopesList = (
  authorization: IAuthorization,
  organizations: Array<INewUser>
) => {
  if (authorization?.scopeModifications?.users?.organizationAccount?.length) {
    const organizationAccounts =
      authorization?.scopeModifications?.users?.organizationAccount;

    if (organizationAccounts.some(scope => scope.value === "*")) {
      return ["All"];
    }

    const organizationNames: string[] = [];

    organizationAccounts?.forEach(scope => {
      scope.value.split(",").forEach((organizationId: string) => {
        const organization = organizations.find(
          organization => organization.userId === organizationId
        );

        if (organization) {
          const organizationName =
            getUserName(organization) || organization.email;

          organizationNames.push(organizationName);
        }
      });
    });

    return uniq(organizationNames);
  } else {
    return ["None"];
  }
};

export const getOrganizationScopesCell = (
  authorization: IAuthorization,
  organizations: Array<INewUser>
) => {
  const scopesList = getOrganizationScopesList(authorization, organizations);

  return `${scopesList.slice(0, 2).join(", ")}${
    scopesList.length > 2 ? ` + ${scopesList.slice(2).length} more` : ""
  }`;
};
