import { WSElement, WSScreen, WSText } from "@wingspanhq/fe-component-library";
import kebabCase from "lodash/kebabCase";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Tabs } from "../../../components/Tabs";
import {
  SettingsSection,
  SettingsSectionProps
} from "./SettingsSection.component";

export interface SettingsSectionsProps {
  sections: SettingsSectionProps[];
  title?: string;
  parentPathName: string;
}

export const SettingsSections: React.FC<SettingsSectionsProps> = ({
  sections,
  parentPathName,
  title
}) => {
  const parentPath = parentPathName;

  return (
    <WSElement>
      <WSScreen.MobileAndTablet>
        {title && <WSText.Heading5>{title}</WSText.Heading5>}
        <Tabs
          tabs={sections.map(({ title }) => ({
            label: title,
            path: `${parentPath}/${kebabCase(title)}`
          }))}
        />
        <Switch>
          {sections.map(({ title, render }) => (
            <Route
              key={title}
              path={`${parentPath}/${kebabCase(title)}`}
              render={() => {
                return <SettingsSection title={title} render={render} />;
              }}
            />
          ))}
          <Redirect
            from={parentPath}
            exact
            to={`${parentPath}/${kebabCase(sections[0].title)}`}
          />
        </Switch>
      </WSScreen.MobileAndTablet>

      <WSScreen.Desktop>
        {sections.map(props => (
          <SettingsSection key={`section-${props.title}`} {...props} />
        ))}
      </WSScreen.Desktop>
    </WSElement>
  );
};
