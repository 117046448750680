import { servicePayments } from "../servicePayments";
import { ListRequestQuery } from "../../utils/serviceHelper";
import { IRequirementDefinitionResponse } from "@wingspanhq/payments/dist/interfaces";

export type RequirementDefinitionListResponse = {
  data: IRequirementDefinitionResponse[];
  summary: {
    listSize: number;
  };
};

export const listRequirementDefinitions = async (
  params?: ListRequestQuery
): Promise<RequirementDefinitionListResponse> => {
  const { data, headers } = await servicePayments.get(`/requirement`, {
    params
  });

  return { summary: { listSize: Number(headers["x-ws-list-size"]) }, data };
};
