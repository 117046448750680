import {
  useModalContext,
  WSButton,
  WSButtons,
  WSElement,
  WSModal
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router";

export const BOOKKEEPING_SUCCESS_ONBOARDING = "BOOKKEEPING_SUCCESS_ONBOARDING";

export const SuccessOnboarding: React.FC = () => {
  const history = useHistory();
  const { closeModal } = useModalContext();

  return (
    <WSModal
      name={BOOKKEEPING_SUCCESS_ONBOARDING}
      size="M"
      title="Welcome to your Income & Expenses, let’s show you around!"
    >
      <WSElement
        style={{
          position: "relative",
          paddingBottom: "60.015467904099%",
          height: "0"
        }}
      >
        <iframe
          title="Income & Expenses Video"
          src="https://www.loom.com/embed/184f2c3def754a429ec3799916bb36f2"
          frameBorder="0"
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
        />
      </WSElement>

      <WSButtons mt="2XL" format="modal">
        <WSButton.Primary
          name="continueGettingStarted"
          onClick={() => {
            closeModal(BOOKKEEPING_SUCCESS_ONBOARDING);
            history.push("/member/dashboard");
          }}
        >
          Continue getting started
        </WSButton.Primary>
        <WSButton.Secondary
          name="exploreIncome&Expenses"
          onClick={() => {
            closeModal(BOOKKEEPING_SUCCESS_ONBOARDING);
          }}
        >
          Explore Income & Expenses
        </WSButton.Secondary>
      </WSButtons>
    </WSModal>
  );
};
