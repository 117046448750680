import cn from "classnames";
import React from "react";
import { toCamelCase } from "../../../../utils";
import {
  detachLayoutProps,
  WSElement,
  WSElementProps
} from "../../WSElement/WSElement.component";
import { WSText } from "../WSText/WSText.component";
import { WSRadioInput } from "./WSRadioInput.component";
import styles from "./WSRadioInput.module.scss";

export interface WSRadioInputOption {
  value: any;
  label: React.ReactNode;
  disabled?: boolean;
  description?: string;
}

export interface WSRadioInputGroupProps extends WSElementProps {
  name: string;
  value: any;
  options: WSRadioInputOption[];
  itemElementProps?: WSElementProps;
  radioInputClassName?: string;
  optionHasBorder?: boolean;
  disabled?: boolean;
}

export const WSRadioInputGroup: React.FC<WSRadioInputGroupProps> = (props) => {
  const {
    options,
    value,
    name,
    layoutProps,
    systemProps,
    className = "",
    radioInputClassName = "",
    disabled,
    itemElementProps,
    ...other
  } = detachLayoutProps(props);

  return (
    <WSElement
      className={cn(styles.radioGroup, className)}
      tabIndex={0}
      data-testid={toCamelCase(name, "field")}
      {...layoutProps}
    >
      {options.map((option) => (
        <WSElement
          key={`WSRadioInputGroup-${option.value}`}
          className={styles.item}
        >
          <WSRadioInput
            key={`WSRadioInputGroup-${option.value}`}
            checked={value === option.value}
            value={option.value}
            name={name}
            label={option.label}
            className={radioInputClassName}
            disabled={!!option.disabled || !!disabled}
            {...itemElementProps}
            {...other}
          />
          {option.description && (
            <WSText.ParagraphSm mt="XS" color="gray400">
              {option.description}
            </WSText.ParagraphSm>
          )}
        </WSElement>
      ))}
    </WSElement>
  );
};
