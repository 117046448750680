import { IPayoutSettingsResponse } from "@wingspanhq/payments/dist/interfaces";
import { selectorInstantPayoutDefaultFee } from "../selectors/selectorInstantPayoutDefaultFee";

class InstantPayoutService {
  calculateInvoiceInstantPayoutFee(
    amount: number,
    payoutSettings?: IPayoutSettingsResponse
  ) {
    const instantPayoutDefaultFee = selectorInstantPayoutDefaultFee(
      payoutSettings
    );
    const instantPayoutDefaultFeeMultiplier = instantPayoutDefaultFee / 100;

    const instantPayoutFee = amount * instantPayoutDefaultFeeMultiplier;

    if (instantPayoutFee < 0.5) {
      return 0.5;
    }

    return instantPayoutFee;
  }
}

export const instantPayoutService = new InstantPayoutService();
