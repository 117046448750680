import classNames from "classnames";
import Downshift from "downshift";
import { search, sortKind } from "fast-fuzzy";
import React, { useEffect, useState } from "react";
import { toCamelCase } from "../../../../utils";
import { WSFlexBox } from "../../layout/WSFlexBox/WSFlexBox.component";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSIcon } from "../WSIcon/WSIcon.component";
import { WSText } from "../WSText/WSText.component";
import styles from "./WSSearch.module.scss";
import {
  WSAvatar,
  WSAvatarIconProps,
  WSAvatarImageProps,
  WSAvatarTextProps
} from "../../common/WSAvatar/WSAvatar.component";

export type WSSearchItem<V> = {
  value: string;
  searchText?: string;
  data: V;
};

export type WSSearchProps<V> = WSElementProps & {
  name: string;
  value: string;
  placeholder?: string;
  items: WSSearchItem<V>[];
  itemsHeader?: () => React.ReactNode;
  threshold?: number;
  defaultItemsHeader?: () => React.ReactNode;
  defaultItems?: WSSearchItem<V>[];
  emptyResults?: () => React.ReactNode;
  onChange: (selectedItem: any) => void;
  getIcon?: (
    data: V
  ) =>
    | Omit<WSAvatarIconProps, "type">
    | Omit<WSAvatarTextProps, "type">
    | Omit<WSAvatarImageProps, "type">;
  getTitle: (data: V) => string;
  getDetails?: (data: V) => string;
  getSideDetails?: (data: V) => React.ReactNode;
  error?: boolean;
  itemToString?: (item: WSSearchItem<V> | null) => string;
  disabled?: boolean;
  defaultItemsLimit?: number;
  resultItemsLimit?: number;
  autoFocus?: boolean;
  defaultInputValue?: string;
};

export type WSSearchComponent = <V extends unknown>(
  props: WSSearchProps<V>
) => React.ReactElement<any, any>;

const itemToSearchString = (item: WSSearchItem<any>) =>
  item.searchText || item.value;

const defaultItemToString = (item: WSSearchItem<any> | null) =>
  item ? item.value : "";

export const wsSearch = search;
export const wsSearchSortKind = sortKind;

export const WSSearch: WSSearchComponent = ({
  className,
  value,
  name,
  items,
  itemsHeader,
  onChange,
  onFocus,
  onBlur,
  getTitle,
  getIcon,
  getSideDetails,
  getDetails,
  defaultItems,
  defaultItemsHeader,
  defaultItemsLimit = 99,
  resultItemsLimit = 99,
  threshold = 0.7,
  error,
  emptyResults = () => null,
  placeholder,
  itemToString = defaultItemToString,
  disabled,
  autoFocus,
  defaultInputValue,
  ...elementProps
}) => {
  const [defaultQuery, setDefaultQuery] = useState(defaultInputValue);

  useEffect(() => {
    setDefaultQuery(defaultInputValue);
  }, [defaultInputValue]);

  return (
    <Downshift
      selectedItem={items.find((i) => i.value === value) || null}
      onChange={(newSelectedItem) => onChange(newSelectedItem?.value)}
      itemToString={itemToString}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        getRootProps,
        clearSelection,
        openMenu
      }) => {
        const renderItem = (item: WSSearchItem<any>, index: number) => {
          const iconProps = getIcon ? getIcon(item.data) : undefined;

          return (
            <WSFlexBox.CenterY
              wrap="nowrap"
              justify="space-between"
              className={classNames(styles.option, {
                [styles.hover]: highlightedIndex === index,
                [styles.selected]: selectedItem === item
              })}
              data-testid={toCamelCase(name, item.value, "option")}
              data-option-value={item.value}
              {...getItemProps({
                key: item.value + item.searchText,
                index,
                item
              })}
            >
              <WSFlexBox.CenterY
                wrap="nowrap"
                style={
                  getSideDetails
                    ? { minWidth: "65%", maxWidth: "65%" }
                    : undefined
                }
              >
                {iconProps ? (
                  "icon" in iconProps ? (
                    <WSAvatar.Icon mr="M" {...iconProps} />
                  ) : "text" in iconProps ? (
                    <WSAvatar.Text mr="M" {...iconProps} />
                  ) : "image" in iconProps ? (
                    <WSAvatar.Image mr="M" {...iconProps} />
                  ) : null
                ) : null}
                <WSElement>
                  <WSText.ParagraphSm color="gray600">
                    {getTitle(item.data)}
                  </WSText.ParagraphSm>
                  {getDetails ? (
                    <WSText.ParagraphSm singleLine color="gray500">
                      {getDetails(item.data)}
                    </WSText.ParagraphSm>
                  ) : null}
                </WSElement>
              </WSFlexBox.CenterY>
              {getSideDetails ? (
                <WSFlexBox.CenterY
                  justify="flex-end"
                  style={{ minWidth: "35%", maxWidth: "35%" }}
                >
                  {getSideDetails(item.data)}
                </WSFlexBox.CenterY>
              ) : null}
            </WSFlexBox.CenterY>
          );
        };

        const trimmedValue = (inputValue || defaultQuery || "").trim();
        const filteredItems = search(trimmedValue, items, {
          normalizeWhitespace: true,
          ignoreCase: true,
          keySelector: itemToSearchString,
          threshold,
          sortBy: sortKind.bestMatch
        });

        return (
          <WSElement
            className={classNames(styles.search, className, {
              [styles.error]: error,
              [styles.disabled]: disabled
            })}
            {...elementProps}
            {...getRootProps(undefined, { suppressRefError: true })}
          >
            <input
              autoFocus={autoFocus}
              className={styles.input}
              data-testid={toCamelCase(name, "field", "input")}
              {...getInputProps({
                disabled,
                placeholder,
                value: trimmedValue,
                onFocus: (event) => {
                  onFocus?.(event);
                  openMenu();
                },
                onBlur: (event) => {
                  onBlur?.(event);
                }
              })}
            />
            <WSFlexBox.CenterY className={styles.icons}>
              {trimmedValue || (isOpen && trimmedValue) ? (
                <WSFlexBox.Center
                  p="M"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    clearSelection();
                    setDefaultQuery("");
                  }}
                >
                  <WSIcon block name="exit" color="gray600" />
                </WSFlexBox.Center>
              ) : (
                !disabled && (
                  <WSIcon block name="search" size="S" mr="M" color="gray600" />
                )
              )}
            </WSFlexBox.CenterY>

            <WSElement className={styles.options} {...getMenuProps()}>
              {isOpen ? (
                trimmedValue ? (
                  filteredItems.length ? (
                    <>
                      {itemsHeader ? (
                        <WSFlexBox.CenterY className={styles.itemsHeader} p="M">
                          {itemsHeader()}
                        </WSFlexBox.CenterY>
                      ) : null}
                      {filteredItems
                        .slice(0, resultItemsLimit)
                        .map((item, index) => renderItem(item, index))}
                    </>
                  ) : (
                    emptyResults()
                  )
                ) : defaultItems ? (
                  <>
                    {defaultItemsHeader ? (
                      <WSFlexBox.CenterY
                        className={styles.defaultItemsHeader}
                        p="M"
                      >
                        {defaultItemsHeader()}
                      </WSFlexBox.CenterY>
                    ) : null}
                    {defaultItems
                      .slice(0, defaultItemsLimit)
                      .map((item, index) => renderItem(item, index))}
                  </>
                ) : null
              ) : null}
            </WSElement>
          </WSElement>
        );
      }}
    </Downshift>
  );
};
