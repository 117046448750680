import {
  IAdditionalData,
  WSDataTypes
} from "@wingspanhq/payments/dist/interfaces";

export function getCustomFieldsLabelsRequest(
  formFields: IAdditionalData[],
  values: { [key: string]: any }
) {
  const labels: any = {};
  formFields.forEach(field => {
    switch (field.type) {
      case WSDataTypes.String:
        labels[field.key] = values[field.key] ? String(values[field.key]) : "";
        break;

      case WSDataTypes.Boolean:
        labels[field.key] = values[field.key] === true ? "true" : "false";
        break;
    }
  });
  return labels;
}
