import {
  WSAvatar,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { IconInstitution } from "../../../../shared/components/IconInstitution";
import { selectorCountryName } from "../../../../shared/selectors/selectorCountryName";
import { getCountyFlagLink } from "../../../../shared/utils/countries";
import { selectorAccountInstitution } from "../../../../shared/selectors/selectorAccountInstitution";
import { selectorAccountName } from "../../../../shared/selectors/selectorAccountName";
import { selectorIsAccountInternational } from "../../../../shared/selectors/selectorIsAccountInternational";

type Props = { account: IAccount } & WSElementProps;

export const BankAccount: React.FC<Props> = ({ account, ...elementProps }) => {
  const title = selectorIsAccountInternational(account)
    ? `${selectorCountryName(account.country as string)} (${account.currency})`
    : selectorAccountInstitution(account);
  const description = selectorAccountName(account);

  return (
    <WSFlexBox wrap="nowrap" alignItems="center" {...elementProps}>
      {account.country && account.country !== "US" ? (
        <WSAvatar.Image
          image={getCountyFlagLink(account.country.toLowerCase())}
          colorBackground="gray50"
          mr="M"
        />
      ) : (
        <IconInstitution institutionId={account.institutionId} mr="M" />
      )}
      <WSScreen.Mobile>
        <WSElement>
          {title && (
            <WSText weight="medium" mr="M">
              {title}
            </WSText>
          )}
          <WSText.ParagraphSm color="gray500">{description}</WSText.ParagraphSm>
        </WSElement>
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        {title && (
          <WSText weight="medium" mr="M">
            {title}
          </WSText>
        )}
        <WSText color="gray500">{description}</WSText>
      </WSScreen.TabletAndDesktop>
    </WSFlexBox>
  );
};
