import { WSElementProps } from "../../WSElement/WSElement.component";

export enum WSGridBreakpoint {
  XS = "xs",
  S = "s",
  M = "m",
  L = "l",
  XL = "xl"
}

export type WSGridGutter =
  | "none"
  | "XS"
  | "S"
  | "M"
  | "L"
  | "XL"
  | "2XL"
  | "3XL";

export type WSGridItemSpan =
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12";

export interface WSGridProps extends WSElementProps {
  gutter?: WSGridGutter | { [key in WSGridBreakpoint]?: WSGridGutter };
}

export interface WSGridItemProps extends WSElementProps {
  span?: WSGridItemSpan | { [key in WSGridBreakpoint]?: WSGridItemSpan };
}

export interface WSGridComponent extends React.FC<WSGridProps> {
  Item: React.FC<WSGridItemProps>;
}
