import { useState } from "react";

import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";

import {
  WSButton,
  WSElement,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  TinType,
  VerificationStatus
} from "@wingspanhq/payments/dist/interfaces";
import {
  IMember,
  INewUser,
  ITinVerification
} from "@wingspanhq/users/dist/lib/interfaces";
import { TextBulleted } from "../../../../../../shared/components/TextBulleted";
import { openInNewTab } from "../../../../../../shared/utils/openInNewTab";
import { WSServiceError } from "../../../../../../utils/serviceHelper";
import { selectorUserLegalName } from "../../../../../../shared/selectors/selectorUserLegalName";

export const ValidationFailedInstructions: React.FC<{
  error?: WSServiceError | null;
  member: IMember;
  user: INewUser;
}> = ({ member, user, error }) => {
  const [
    shouldAskIfUploadedExtraDocuments,
    setShouldAskIfUploadedExtraDocuments
  ] = useState(false);

  const userId = user.userId;
  const tinVerification = member.profile.tinVerification as ITinVerification;
  const tinPreference = member.profile.formW9Options?.tinPreference;

  const isTINFailed =
    tinVerification?.ssn?.status === VerificationStatus.Failed ||
    tinVerification?.ein?.status === VerificationStatus.Failed;

  const openUploadConfirmationDocuments = () => {
    const { email } = user;
    const fullName = selectorUserLegalName(user);
    const typeFormReference = createPopup(
      `https://wingspan.typeform.com/to/${
        isTINFailed ? "iAz9sv9T" : "xUzT9J6E"
      }#email=${email}&name=${fullName}&user_id=${userId}`,
      {
        onSubmit: responseId => {
          console.log(
            `Typeform upload confirmation documents response ${responseId}`
          );
        }
      }
    );
    typeFormReference.open();
    setShouldAskIfUploadedExtraDocuments(true);
  };

  const UploadAdditionalDocuments = () => (
    <WSElement my="XL">
      {shouldAskIfUploadedExtraDocuments ? (
        <>
          <WSButton.Secondary
            mb="XL"
            onClick={() => setShouldAskIfUploadedExtraDocuments(false)}
            fullWidth
            type="button"
          >
            I've uploaded my documents
          </WSButton.Secondary>
          <WSButton.Link
            onClick={openUploadConfirmationDocuments}
            fullWidth
            type="button"
          >
            Upload {isTINFailed ? "confirmation" : "additional"} documents
          </WSButton.Link>
        </>
      ) : (
        <WSButton.Secondary
          fullWidth
          type="button"
          onClick={openUploadConfirmationDocuments}
        >
          Upload confirmation documents
        </WSButton.Secondary>
      )}
    </WSElement>
  );

  if (
    error?.response?.data.error === "SSN or EIN cannot be updated at this time."
  ) {
    return (
      <WSMessageBox.Warning noBorder mb="2XL">
        <WSText weight="medium" mb="M" color="amber400">
          We need additional information to update your tax profile.
        </WSText>
        <WSText mb="M" color="gray500">
          The changes you are making to your taxpayer information will impact
          your existing Wingspan accounts and features. To avoid disruptions
          with these active accounts including send and receiving payments, we
          advise that you follow the steps below:
        </WSText>

        <TextBulleted as="ol">
          <li>
            <b>Follow the instructions on this help center</b>
            <br />
            Additional document verification is required to complete the change.{" "}
            <WSButton.Link
              type="button"
              onClick={() => {
                openInNewTab(
                  "https://help.wingspan.app/en/articles/8646093-tin-or-ssn-mismatch"
                );
              }}
            >
              Learn more about the process here.
            </WSButton.Link>{" "}
            When you've gathered your documents, upload by clicking this button:
            {UploadAdditionalDocuments()}
          </li>
          <li>
            <b>Our team will review and be in touch</b>
            <br />
            Once you have provided the necessary documents and the document
            review is complete, our team will reach out to you
          </li>
          <li>
            <b>You can continue with this form</b>
            <br />
            While the review is underway, you can proceed with the remainder of
            your tax information and 1099 delivery preferences.
          </li>
          <li>
            <b> Need more help?</b>
            <br />
            <WSButton.Link
              type="button"
              onClick={() => openInNewTab("https://www.wingspan.app/support")}
            >
              Talk to a live representative.
            </WSButton.Link>
          </li>
        </TextBulleted>
      </WSMessageBox.Warning>
    );
  } else if (
    tinPreference === TinType.Individual &&
    tinVerification?.ssn?.status === VerificationStatus.Failed
  ) {
    return (
      <WSMessageBox.Error noBorder mb="2XL">
        <WSText weight="medium" mb="M" color="red500">
          We could not match your Social Security Number with IRS records
        </WSText>
        <TextBulleted as="ol">
          <li>
            <b>Re-verify your details</b>
            <br />
            Ensure your full name and SSN match exactly with what is on your
            Social Security Card
          </li>
          <li>
            <b>Contact the IRS</b>
            <br />
            Contact the IRS Business and Specialty Tax Line to confirm the EIN
            and business name on file.
            <br />
            <WSButton.Link
              type="button"
              onClick={() => {
                openInNewTab("tel:+18008294933");
              }}
            >
              +1 800-829-4933
            </WSButton.Link>
          </li>
          <li>
            <b>Changed Your Name Recently?</b>
            <br />
            If you've had a legal name change due to marriage, divorce, or other
            reasons, you may need to update the Social Security Administration
            (SSA) records.{" "}
            <WSButton.Link type="button">
              Visit your local SSA office with the necessary documents.
            </WSButton.Link>
          </li>
          <li>
            <b>Upload Confirmation Documents:</b>
            <br />
            Share a clear photo or scan of your Social Security card for manual
            verification. When you've gathered your documents, upload by
            clicking this button:
            {UploadAdditionalDocuments()}
          </li>
          <li>
            <b>Need more help?</b>
            <br />
            Review our guide on how to handle TIN verification{" "}
            <WSButton.Link
              type="button"
              onClick={() =>
                openInNewTab(
                  "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
                )
              }
            >
              errors here to get more help
            </WSButton.Link>
            .
          </li>
        </TextBulleted>
      </WSMessageBox.Error>
    );
  } else if (tinVerification?.ein?.status === VerificationStatus.Failed) {
    return (
      <WSMessageBox.Error noBorder mb="2XL">
        <WSText weight="medium" mb="M" color="red500">
          We could not match your Employee Identification Number with IRS
          records
        </WSText>
        <TextBulleted as="ol">
          <li>
            <b>Double-Check Your Information</b>
            <br />
            Confirm your business name, legal name, and EIN matches exactly what
            appears on your business’s EIN confirmation document (either IRS
            form 147c or IRS form CP-575).
          </li>
          <li>
            <b>Contact the IRS</b>
            <br />
            Contact the IRS Business and Specialty Tax Line to confirm the EIN
            and business name on file.
            <br />
            <WSButton.Link
              type="button"
              onClick={() => {
                openInNewTab("tel:+18008294933");
              }}
            >
              +1 800-829-4933
            </WSButton.Link>
          </li>
          <li>
            <b>Made Recent Business Changes? </b>
            <br />
            If your business name or structure has changed, you'll need to fill
            out
            <WSButton.Link type="button">IRS Form 8822-B</WSButton.Link>.
            Re-enter your taxpayer information once you’ve received update
            confirmation from the IRS.
          </li>
          <li>
            <b>Upload Confirmation Documents:</b>
            <br />
            Upload your EIN confirmation documents (either IRS form 147c or IRS
            form CP-575) for manual verification. When you have gathered your
            documents, upload them by clicking this button:
            {UploadAdditionalDocuments()}
          </li>
          <li>
            <b>Need more help?</b>
            <br />
            Review our guide on how to handle TIN verification{" "}
            <WSButton.Link
              type="button"
              onClick={() =>
                openInNewTab(
                  "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
                )
              }
            >
              errors here to get more help
            </WSButton.Link>
            .
          </li>
        </TextBulleted>
      </WSMessageBox.Error>
    );
  }

  return null;
};
