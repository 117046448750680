import { useWSModal, WSMenuItem } from "@wingspanhq/fe-component-library";
import { FrequencyAndScheduleStatus } from "@wingspanhq/payments/dist/interfaces";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSetWSStore } from "../../../../store";
import { IInvoiceTemplateRow } from "../../service";
import { ModalDelete } from "./ModalDelete";

export const useGetActions = () => {
  const history = useHistory();
  const location = useLocation();
  const setStore = useSetWSStore();
  const modalDelete = useWSModal(ModalDelete, { size: "S" });

  return useCallback(
    (invoiceTemplate: IInvoiceTemplateRow) => {
      const menuItems: WSMenuItem[] = [];

      if (
        [
          FrequencyAndScheduleStatus.Draft,
          FrequencyAndScheduleStatus.Active
        ].includes(invoiceTemplate.status)
      ) {
        menuItems.push({
          icon: "edit",
          label: invoiceTemplate.frequency ? "Edit series" : "Edit invoice",
          onClick: () => {
            setStore({ invoiceFormBackPath: location.pathname });
            history.push(
              `/member/invoices/template/${invoiceTemplate.invoiceTemplateId}/edit`
            );
          }
        });
      }

      if (
        [
          FrequencyAndScheduleStatus.Draft,
          FrequencyAndScheduleStatus.Active
        ].includes(invoiceTemplate.status)
      ) {
        menuItems.push({
          icon: "plus-circle",
          label: "Duplicate",
          onClick: () => {
            history.push(
              `/member/invoices/template/${invoiceTemplate.invoiceTemplateId}/duplicate`
            );
          }
        });
      }

      if ([FrequencyAndScheduleStatus.Draft].includes(invoiceTemplate.status)) {
        menuItems.push({
          icon: "exit",
          label: "Remove",
          onClick: () => {
            modalDelete.open({ invoiceTemplate });
          }
        });
      }

      if (
        [FrequencyAndScheduleStatus.Active].includes(invoiceTemplate.status)
      ) {
        menuItems.push({
          icon: "exit",
          label: "Cancel series",
          onClick: () => {
            modalDelete.open({ invoiceTemplate });
          }
        });
      }

      return menuItems;
    },
    [history, location.pathname, modalDelete, setStore]
  );
};
