import { IAddress } from "@wingspanhq/users/dist/lib/interfaces";
import classNames from "classnames";
import React from "react";
import wingspanLogo from "../../../assets/images/ws-logo.svg";
import styles from "./InvoiceFooter.module.scss";
import { WSText } from "@wingspanhq/fe-component-library";

export interface InvoiceFooterProps {
  data: {
    name?: string;
    email: string;
    address?: IAddress;
  };
  className?: string;
}

export const InvoiceFooter: React.FC<InvoiceFooterProps> = ({
  data,
  className
}) => (
  <div className={classNames(styles.invoiceFooter, className)}>
    {data.name && <WSText className={styles.name}>{data.name}</WSText>}

    <WSText className={styles.email}>
      <a href={`mailto:${data.email}`}>{data.email}</a>
    </WSText>

    {data.address && (
      <WSText className={styles.address}>
        {data.address.addressLine1}
        {data.address.addressLine2 && `, ${data.address.addressLine2}`}
        <br />
        {data.address.city}, {data.address.state}
        <br />
        {data.address.postalCode}
      </WSText>
    )}

    <WSText className={styles.poweredBy}>Powered by</WSText>
    <WSText className={styles.wingspanLogo}>
      <img src={wingspanLogo} alt="Wingspan Logo" />
    </WSText>
  </div>
);
