import {
  WSMenuItem,
  copyText,
  useWSModal
} from "@wingspanhq/fe-component-library";
import {
  InvoiceStatus,
  MemberWorkFlowStatus
} from "@wingspanhq/payments/dist/interfaces";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ModalRefund } from "../../../../Invoices/components/ModalRefund";
import { calculateAmountAvailableToRefund } from "../../../../Invoices/utils/calculateAmountAvailableToRefund";
import { addNotification } from "../../../../components/Notification/Notification";
import { useUserId } from "../../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import {
  useAcceptInvoice,
  useDownloadInvoicePdf,
  useMarkInvoiceAsPaid,
  useRetryInvoicePayment,
  useSendInvoice,
  useSendInvoiceReminder
} from "../../../../query/payments/mutations";
import { usePayoutSettings } from "../../../../query/payments/queries";
import {
  getHasInvoiceEditAccess,
  getIsInvoiceCreator,
  getIsInvoiceDisputed
} from "../../../../query/payments/selectors";
import { useMemberProfile } from "../../../../query/users/queries";
import { useSetWSStore } from "../../../../store";
import { IInvoiceRow } from "../../service";
import { ModalDelete } from "./ModalDelete";
import { ModalDispute } from "./ModalDispute";
import { selectorIsInstantPayoutAllowed } from "./selectorIsInstantPayoutAllowed";
import { selectorIsInvoiceDeposited } from "./selectorIsInvoiceDeposited";

export const useInvoiceActions = () => {
  const userId = useUserId();
  const history = useHistory();
  const location = useLocation();
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryMember = useMemberProfile(userId);
  const [sendInvoice] = useSendInvoice();
  const [markInvoiceAsPaid] = useMarkInvoiceAsPaid();
  const [sendInvoiceReminder] = useSendInvoiceReminder();
  const [downloadInvoicePdf] = useDownloadInvoicePdf();
  const [acceptInvoice] = useAcceptInvoice();
  const [retryPayment] = useRetryInvoicePayment();
  const featureFlagsQuery = useFeatureFlags();
  const setStore = useSetWSStore();
  const modalRefund = useWSModal(ModalRefund, { size: "S" });
  const modalDelete = useWSModal(ModalDelete, { size: "S" });
  const modalDispute = useWSModal(ModalDispute, { size: "S" });

  return useCallback(
    (invoice: IInvoiceRow) => {
      const menuItems: WSMenuItem[] = [];

      // Edit
      if (
        [
          InvoiceStatus.Draft,
          InvoiceStatus.Pending,
          InvoiceStatus.Open,
          InvoiceStatus.Overdue
        ].includes(invoice.oldStatus) &&
        (getHasInvoiceEditAccess(invoice, userId) ||
          featureFlagsQuery.data?.editAllInvoices)
      ) {
        menuItems.push({
          icon: "edit",
          label: "Edit",
          onClick: () => {
            setStore({ invoiceFormBackPath: location.pathname });
            history.push(`/member/invoices/${invoice.invoiceId}/edit`);
          }
        });
      }

      // Duplicate
      menuItems.push({
        icon: "plus-circle",
        label: "Duplicate",
        onClick: () => {
          history.push(`/member/invoices/${invoice.invoiceId}/duplicate`);
        }
      });

      // Delete
      if (
        [InvoiceStatus.Draft].includes(invoice.oldStatus) &&
        getHasInvoiceEditAccess(invoice, userId)
      ) {
        menuItems.push({
          icon: "exit",
          label: "Remove",
          onClick: () => {
            modalDelete.open({ invoice });
          }
        });
      }

      // Cancel
      if (
        [
          InvoiceStatus.Open,
          InvoiceStatus.Overdue,
          InvoiceStatus.Pending
        ].includes(invoice.oldStatus) &&
        getHasInvoiceEditAccess(invoice, userId)
      ) {
        menuItems.push({
          icon: "exit",
          label: "Cancel invoice",
          onClick: () => {
            modalDelete.open({ invoice });
          }
        });
      }

      // Send
      if (
        [InvoiceStatus.Draft].includes(invoice.oldStatus) &&
        getHasInvoiceEditAccess(invoice, userId)
      ) {
        menuItems.push({
          icon: "send",
          label: "Send now",
          onClick: () => {
            sendInvoice({ invoiceId: invoice.invoiceId });
          }
        });
      }

      // Mark deposited
      if (
        [
          InvoiceStatus.Open,
          InvoiceStatus.Overdue,
          InvoiceStatus.PaymentInTransit
        ].includes(invoice.oldStatus) &&
        getHasInvoiceEditAccess(invoice, userId)
      ) {
        menuItems.push({
          icon: "dollar-circle",
          label: "Mark deposited",
          onClick: () => {
            markInvoiceAsPaid(
              {
                invoiceId: invoice.invoiceId
              },
              {
                onSuccess: () => {
                  addNotification({
                    text: `Invoice #${invoice.invoiceNumber} is marked as deposited`
                  });
                }
              }
            );
          }
        });
      }

      // Download PDF
      if (
        [
          InvoiceStatus.Open,
          InvoiceStatus.Overdue,
          InvoiceStatus.Paid
        ].includes(invoice.oldStatus)
      ) {
        menuItems.push({
          icon: "download",
          label: "Download PDF",
          onClick: () => {
            downloadInvoicePdf({
              invoiceId: invoice.invoiceId,
              status: invoice.oldStatus
            });
          }
        });
      }

      // Send reminder
      if (
        [InvoiceStatus.Open, InvoiceStatus.Overdue].includes(invoice.oldStatus)
      ) {
        menuItems.push({
          icon: "calendar",
          label: "Send reminder",
          onClick: () => {
            sendInvoiceReminder({ invoice: invoice });
          }
        });
      }

      // Copy link
      if (
        invoice.attachments?.invoiceLink &&
        [
          InvoiceStatus.Open,
          InvoiceStatus.Overdue,
          InvoiceStatus.Paid,
          InvoiceStatus.PaymentInTransit
        ].includes(invoice.oldStatus)
      ) {
        menuItems.push({
          icon: "download",
          label: "Copy invoice link",
          onClick: () => {
            if (invoice.attachments?.invoiceLink) {
              copyText(invoice.attachments.invoiceLink);
            }
          }
        });
      }

      // Accept
      if (
        [
          InvoiceStatus.Open,
          InvoiceStatus.Overdue,
          InvoiceStatus.Pending
        ].includes(invoice.oldStatus) &&
        !getIsInvoiceCreator(invoice, userId) &&
        invoice.member.workflowStatus !== MemberWorkFlowStatus.Accepted
      ) {
        menuItems.push({
          icon: "check",
          label: "Accept invoice",
          onClick: () => {
            acceptInvoice({ invoiceId: invoice.invoiceId });
          }
        });
      }

      // Dispute
      if (
        [
          InvoiceStatus.Open,
          InvoiceStatus.Overdue,
          InvoiceStatus.Pending
        ].includes(invoice.oldStatus) &&
        !getIsInvoiceCreator(invoice, userId) &&
        !getIsInvoiceDisputed(invoice)
      ) {
        menuItems.push({
          icon: "alert-circle",
          label: "Dispute invoice",
          onClick: () => {
            modalDispute.open({ invoice });
          }
        });
      }

      // Refund
      if (
        featureFlagsQuery.data?.refundButton &&
        selectorIsInvoiceDeposited(invoice) &&
        calculateAmountAvailableToRefund(invoice) > 0
      ) {
        menuItems.push({
          icon: "dollar-circle",
          label: "Refund",
          onClick: () => {
            modalRefund.open({ invoice });
          }
        });
      }

      // Retry payment
      if (
        [InvoiceStatus.Open, InvoiceStatus.Overdue].includes(
          invoice.oldStatus
        ) &&
        !!invoice.invoiceTemplateId
      ) {
        menuItems.push({
          icon: "refresh-h",
          label: "Retry payment",
          onClick: () => {
            retryPayment({ invoiceId: invoice.invoiceId });
          }
        });
      }

      // Instant payout
      if (
        selectorIsInstantPayoutAllowed(
          invoice,
          queryPayoutSettings.data,
          queryMember.data
        )
      ) {
        menuItems.push({
          icon: "instant-circle",
          label: "Request instant payout",
          onClick: () => {
            history.push(
              `${location.pathname}/${invoice.invoiceId}/instant-deposit`
            );
          }
        });
      }

      return menuItems;
    },
    [
      acceptInvoice,
      downloadInvoicePdf,
      featureFlagsQuery.data?.editAllInvoices,
      featureFlagsQuery.data?.refundButton,
      history,
      location.pathname,
      markInvoiceAsPaid,
      modalDelete,
      modalDispute,
      modalRefund,
      queryMember.data,
      queryPayoutSettings.data,
      retryPayment,
      sendInvoice,
      sendInvoiceReminder,
      setStore,
      userId
    ]
  );
};
