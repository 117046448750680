import { WSIcon } from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React from "react";
import styles from "./NumberPoint.module.scss";

export type ProgressPointProps = {
  name: string;
  checked?: boolean;
  current?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const ProgressPoint: React.FC<ProgressPointProps> = ({
  name,
  checked,
  className,
  current,
  ...props
}) => (
  <div
    className={classNames(
      styles.point,
      { [styles.checked]: checked },
      { [styles.current]: current },
      className
    )}
    {...props}
  >
    {checked ? <WSIcon block name="check" size="XS" /> : name}
  </div>
);
