import {
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSIcon,
  WSInputMaskOld,
  WSMessageBox,
  WSRadioInputGroup,
  WSSelectOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { TinType } from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { FormPartialAddress } from "../../../../shared/components/FormPartialAddress";
import {
  SELECT_OPTIONS_BUSINESS_TYPE,
  SELECT_OPTIONS_BUSINESS_TYPE_SSN
} from "../../../../shared/constants/member";
import { selectorTaxClassificationLabel } from "../../../../shared/selectors/selectorTaxClassificationLabel";
import { getSsnInputProps } from "../../../../shared/utils/getSsnInputProps";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { getValidationSchema } from "./getValidationSchema";
import { selectorNo1099 } from "./selectorNo1099";
import { FormTaxpayerInformationData } from "./types";

export const FormTaxpayerInformation: React.FC<{
  warning?: React.ReactNode;
  isEdit?: boolean;
  onCancel?: () => void;
  error: WSServiceError | null;
  isLoading: boolean;
  defaultValues: FormTaxpayerInformationData;
  onSubmit: (data: FormTaxpayerInformationData) => void;
  isSSNProvided?: boolean;
  onBack?: (skipConfirm?: boolean) => void;
}> = ({
  defaultValues,
  onBack,
  onSubmit,
  warning,
  error,
  isLoading,
  isEdit,
  onCancel,
  isSSNProvided
}) => {
  const [isOptionalInfoVisible, setIsOptionalInfoVisible] = useState(false);

  return (
    <WSFormOld<FormTaxpayerInformationData>
      defaultValues={defaultValues}
      validationSchema={getValidationSchema(isSSNProvided)}
      onSubmit={onSubmit}
    >
      {warning}

      <WSElement mb="2XL">
        <WSText weight="medium" mb="M">
          Identification number
        </WSText>
        <WSFormOld.Field
          mb="XL"
          name="identificationNumberType"
          component={WSRadioInputGroup}
          componentProps={{
            optionHasBorder: true,
            options: [
              {
                label: "Social Security Number (SSN)",
                value: TinType.Individual
              },
              {
                label: "Employer Identification Number (EIN)",
                value: TinType.Business
              }
            ]
          }}
        />

        <WSFormOld.Value name="identificationNumberType">
          {identificationNumberType => (
            <>
              <WSFormOld.Field
                name="ssn"
                component={WSInputMaskOld}
                componentProps={getSsnInputProps({
                  showPlaceholder: true,
                  isValueProvided: isSSNProvided
                })}
                hidden={identificationNumberType !== TinType.Individual}
              />
              <WSFormOld.Field
                name="ein"
                component={WSInputMaskOld}
                componentProps={{
                  mask: "99-9999999",
                  placeholder: "Employer Identification Number",
                  unmask: true,
                  fsExclude: true
                }}
                hidden={identificationNumberType !== TinType.Business}
              />
            </>
          )}
        </WSFormOld.Value>
      </WSElement>

      <WSFormOld.Value name="identificationNumberType">
        {identificationNumberType => (
          <>
            <WSElement
              mb="2XL"
              hidden={identificationNumberType === TinType.Business}
            >
              <WSText weight="medium" mb="M">
                Recipient legal name
              </WSText>

              <WSGrid gutter="M">
                <WSGrid.Item span={{ xs: "6" }}>
                  <WSFormOld.Field
                    label="First name"
                    name="firstName"
                    component={WSTextInput}
                  />
                </WSGrid.Item>
                <WSGrid.Item span={{ xs: "6" }}>
                  <WSFormOld.Field
                    label="Last name"
                    name="lastName"
                    component={WSTextInput}
                  />
                </WSGrid.Item>
              </WSGrid>
              <WSText.ParagraphSm color="gray500" mt="XS">
                Name should match exactly with what's listed on federal tax
                return to avoid discrepancies with the IRS.
              </WSText.ParagraphSm>
            </WSElement>
            <WSElement
              mb="2XL"
              hidden={identificationNumberType === TinType.Individual}
            >
              <WSText weight="medium" mb="M">
                Business Name
              </WSText>
              <WSFormOld.Field
                mb="M"
                hidden={identificationNumberType === TinType.Individual}
                name="businessName"
                component={WSTextInput}
                componentProps={{ placeholder: "Business name" }}
                label="Business name"
                description="Name should match exactly with what's listed on federal tax return to avoid discrepancies with the IRS."
              />

              <WSFormOld.Field
                hidden={identificationNumberType === TinType.Individual}
                name="optionalBusinessName"
                component={WSTextInput}
                componentProps={{
                  placeholder: "Business name/disregarded entity (optional)"
                }}
              />
            </WSElement>
          </>
        )}
      </WSFormOld.Value>

      <WSElement mb="2XL">
        <WSText weight="medium" mb="M">
          Federal tax classification
        </WSText>
        <WSFormOld.Value name="identificationNumberType">
          {identificationNumberType => (
            <WSFormOld.Field
              key={identificationNumberType}
              name="taxClassification"
              component={WSSelectOld}
              componentProps={{
                options:
                  identificationNumberType === TinType.Business
                    ? SELECT_OPTIONS_BUSINESS_TYPE
                    : SELECT_OPTIONS_BUSINESS_TYPE_SSN
              }}
            />
          )}
        </WSFormOld.Value>

        <WSFormOld.Value name="taxClassification">
          {taxClassification =>
            selectorNo1099(taxClassification) && (
              <WSMessageBox.Info noBorder mt="M">
                <WSText.ParagraphSm weight="medium" mb="S">
                  No 1099-NEC form will be issued
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mb="M" color="gray500">
                  As a {selectorTaxClassificationLabel(taxClassification)},
                  you're not eligible for a 1099 according to IRS guidelines.
                  Instead, Wingspan will provide a summary of your 2023 payments
                  to assist you during tax season. If this doesn't align with
                  your business structure, update your details promptly.
                </WSText.ParagraphSm>
              </WSMessageBox.Info>
            )
          }
        </WSFormOld.Value>
      </WSElement>

      <WSElement mb="2XL">
        <WSFormOld.Value name="identificationNumberType">
          {identificationNumberType => (
            <WSText weight="medium" mb="M">
              {identificationNumberType === TinType.Business
                ? "Business"
                : "Recipient"}{" "}
              address
            </WSText>
          )}
        </WSFormOld.Value>

        <FormPartialAddress name="address" mb="M" />
        <WSText.ParagraphSm color="gray400">
          Your form 1099 will be mailed to this address.
        </WSText.ParagraphSm>
      </WSElement>

      <WSElement mb="2XL">
        <WSFlexBox.CenterY
          wrap="nowrap"
          justify="space-between"
          onClick={() => {
            setIsOptionalInfoVisible(prev => !prev);
          }}
        >
          <WSText weight="medium">
            Add exemptions or optional information
          </WSText>
          <WSIcon
            block
            name={isOptionalInfoVisible ? "chevron-up" : "chevron-down"}
            size="XS"
          />
        </WSFlexBox.CenterY>

        <WSElement hidden={!isOptionalInfoVisible} mt="XL">
          <WSElement mb="XL">
            <WSFormOld.Field
              name="exemptPayeeCodeVisible"
              component={WSCheckboxToggle}
              componentProps={{
                label: "I have an exempt payee code"
              }}
            />
            <WSFormOld.Value name="exemptPayeeCodeVisible">
              {exemptPayeeCodeVisible => (
                <WSFormOld.Field
                  mt="M"
                  hidden={!exemptPayeeCodeVisible}
                  name="exemptPayeeCode"
                  component={WSTextInput}
                  componentProps={{
                    placeholder: "Exempt payee code (if any)"
                  }}
                />
              )}
            </WSFormOld.Value>
          </WSElement>

          <WSElement mb="XL">
            <WSFormOld.Field
              name="exemptFatcaReportingCodeVisible"
              component={WSCheckboxToggle}
              componentProps={{
                label: "I have an exempt FATCA reporting code"
              }}
            />
            <WSFormOld.Value name="exemptFatcaReportingCodeVisible">
              {exemptFatcaReportingCodeVisible => (
                <WSFormOld.Field
                  mt="M"
                  hidden={!exemptFatcaReportingCodeVisible}
                  name="exemptFatcaReportingCode"
                  component={WSTextInput}
                  componentProps={{
                    placeholder: "Exemption from FATCA reporting code"
                  }}
                />
              )}
            </WSFormOld.Value>
          </WSElement>
        </WSElement>
      </WSElement>

      <WSErrorMessage mb="2XL" contextKey="Other" error={error} />

      <WSFormOld.Context>
        {context => {
          return (
            <WSButtons forceFullWidth>
              <WSButton
                disabled={!context.formState.isDirty}
                fullWidth
                type="submit"
                loading={isLoading}
              >
                {isEdit ? "Save changes" : "Continue"}
              </WSButton>
              {onBack && (
                <WSButton.Secondary
                  onClick={
                    isEdit && context.formState.isDirty && onCancel
                      ? onCancel
                      : () => {
                          context.formState.isDirty ? onBack() : onBack(true);
                        }
                  }
                  type="button"
                >
                  {isEdit && context.formState.isDirty && onCancel
                    ? "Cancel"
                    : "Back"}
                </WSButton.Secondary>
              )}
            </WSButtons>
          );
        }}
      </WSFormOld.Context>
    </WSFormOld>
  );
};
