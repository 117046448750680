import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSPill,
  WSSearch,
  WSSearchProps,
  WSText,
  useModalContext
} from "@wingspanhq/fe-component-library";
import {
  ICollaboratorSchema,
  MemberClientStatus
} from "@wingspanhq/payments/dist/interfaces";
import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ADD_COLLABORATOR_MODAL } from "../AddCollaboratorModal/AddCollaboratorModal";
import { useAuthorizedScopeGroups } from "../../../shared/utils/teamUtils";

export type CollaboratorFieldProps = {
  collaborators: ICollaboratorSchema[];
  name: string;
  label: string;
  email?: string;
};

export const CollaboratorField: React.FC<CollaboratorFieldProps> = ({
  collaborators = [],
  name,
  label,
  email
}) => {
  const { openModal } = useModalContext();
  const { setValue } = useFormContext();
  const [newCollaboratorEmail, setNewCollaboratorEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const { hasCollaboratorsScope } = useAuthorizedScopeGroups();

  const filteredCollaborators = collaborators.filter(
    c => c.status !== MemberClientStatus.Inactive
  );
  const dataKey = filteredCollaborators.map(c => c.collaboratorId).join(",");

  const recentCollaborators = sortBy(filteredCollaborators, "updatedAt")
    .reverse()
    .slice(0, 3);

  useEffect(() => {
    if (
      filteredCollaborators.find(
        collaborator =>
          collaborator.member.user.email?.toLowerCase() ===
          newCollaboratorEmail.toLowerCase().trim()
      )
    ) {
      setValue(name, newCollaboratorEmail, {
        shouldValidate: false
      });
      setLoading(false);
      setNewCollaboratorEmail("");
    }
  }, [dataKey, newCollaboratorEmail]);

  const itemsHeader = hasCollaboratorsScope
    ? () => (
        <WSButton.Link
          type="button"
          onClick={() =>
            openModal(ADD_COLLABORATOR_MODAL, {
              onSuccess(email: string) {
                setLoading(true);
                setNewCollaboratorEmail(email);
              }
            })
          }
          data-testid="addCollaboratorButton"
        >
          Add contractor
        </WSButton.Link>
      )
    : undefined;

  return (
    <WSElement shimmer={loading}>
      <WSFormOld.Field<WSSearchProps<ICollaboratorSchema>>
        key={dataKey}
        name={name}
        label={label}
        component={WSSearch}
        componentProps={{
          autoFocus: !!email,
          defaultInputValue: email,
          placeholder: "Search by email, name, id..",
          threshold: 0.8,
          itemToString: item =>
            [
              item?.data.member.user.profile?.firstName,
              item?.data.member.user.profile?.lastName
            ]
              .filter(Boolean)
              .join(" ")
              .trim() ||
            item?.data.member.user.email ||
            "",
          defaultItems: recentCollaborators.map(collaborator => ({
            value: collaborator.member.user.email || "",
            searchText: collaborator.collaboratorId,
            data: collaborator
          })),
          itemsHeader,
          defaultItemsHeader: itemsHeader,
          emptyResults: () => (
            <WSFlexBox.Center p="L" direction="column">
              <WSText.ParagraphSm color="gray400">
                No Results
              </WSText.ParagraphSm>
              <WSButton.Link
                type="button"
                onClick={() =>
                  openModal(ADD_COLLABORATOR_MODAL, {
                    onSuccess(email: string) {
                      setLoading(true);
                      setNewCollaboratorEmail(email);
                    }
                  })
                }
                data-testid="addCollaboratorButton"
              >
                Add contractor
              </WSButton.Link>
            </WSFlexBox.Center>
          ),
          items: filteredCollaborators.map(collaborator => ({
            value: collaborator.member.user.email || "",
            searchText: [
              collaborator.member.user.profile?.firstName,
              collaborator.member.user.profile?.lastName,
              collaborator.member.user.email,
              collaborator.member.profile?.company?.name,
              collaborator.collaboratorId,
              collaborator.labels.contractorId
            ]
              .filter(Boolean)
              .join(" "),
            data: collaborator
          })),
          getTitle: collaborator =>
            [
              collaborator.member.user.profile?.firstName,
              collaborator.member.user.profile?.lastName
            ]
              .filter(Boolean)
              .join(" "),
          getDetails: collaborator => `${collaborator.member.user.email}`,
          getSideDetails: collaborator =>
            collaborator.member.profile?.company?.name ? (
              <WSPill
                theme="blue"
                text={collaborator.member.profile?.company?.name}
              />
            ) : null
        }}
      />
    </WSElement>
  );
};
