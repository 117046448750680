import {
  useModalContext,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import * as React from "react";
import { useHistory } from "react-router-dom";

export const BOOKKEEPING_ESTIMATED_TAXES_MODAL =
  "BOOKKEEPING_ESTIMATED_TAXES_MODAL";

export const EstimatedTaxesModal: React.FC = () => {
  const history = useHistory();
  const { closeModal } = useModalContext();

  return (
    <WSModal
      name={BOOKKEEPING_ESTIMATED_TAXES_MODAL}
      size="S"
      title="How are my estimated taxes calculated?"
    >
      <WSText mt="XL" color="gray500">
        This calculator is not intended to tell you your legal tax burden. The
        estimate is based off of your estimated income, filing state and status.
        Wingspan then determines the right tax bracket for you.
      </WSText>
      <WSText mt="XL" color="gray500">
        We're not taking into account a lot of the things that may change your
        exact tax burden.
      </WSText>
      <WSText mt="XL" color="gray500">
        You are responsible for filing your taxes according to state and federal
        law.
      </WSText>
      <WSButtons mt="XL" format="modal">
        <WSButton.Primary
          onClick={() => {
            closeModal(BOOKKEEPING_ESTIMATED_TAXES_MODAL);
            history.push("/member/settings/tax-profile");
          }}
        >
          Edit Tax Profile
        </WSButton.Primary>
      </WSButtons>
    </WSModal>
  );
};
