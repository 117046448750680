import {
  IServiceEnablementResponse,
  IServiceEnablementUpdate
} from "@wingspanhq/benefits/dist/lib/interfaces/api/service";
import { IEnrollment } from "@wingspanhq/benefits/dist/lib/interfaces/enrollment";
import { WSService } from "../utils/WSService";

const service = new WSService("/benefits");

const updateService = async (
  userId: string,
  data: IServiceEnablementUpdate
): Promise<IServiceEnablementResponse> => {
  const response = await service.patch(`/service/${userId}`, data);
  return response.data;
};

const getService = async (): Promise<IServiceEnablementResponse> => {
  const { data } = await service.get("/service");
  return data;
};

const getEnrollment = async (userId: string): Promise<IEnrollment> => {
  const { data } = await service.get(`/enrollment/${userId}`);
  return data;
};

export const benefitsService = {
  service: {
    get: getService,
    update: updateService
  },
  enrollment: {
    get: getEnrollment
  }
};
