import FlatfileImporter from "@flatfile/adapter";
import { format, isDate, isFuture, parseISO } from "date-fns";
import { TRANSACTION_CATEGORIES, dateIsValid } from "../../Bookkeeping/utils";
import { IS_DEV_ENV } from "../../shared/constants/environment";
import { getFloatFromString } from "../../utils/amounts";
import { flatfileTheme } from "../../utils/flatfileTheme";
import { useUserProfile } from "../users/queries";
import { useUserId } from "./helpers";

const apiKey = process.env.REACT_APP_FLATFILE_API_KEY || "";

const importer = new FlatfileImporter(apiKey, {
  ...flatfileTheme,
  type: "Transactions",
  title: "Import Transactions to Wingspan",
  autoDetectHeaders: true,
  devMode: IS_DEV_ENV,
  managed: true,
  disableManualInput: true,
  displayEncoding: false,
  fields: [
    {
      label: "Name",
      key: "name",
      description: "The transaction description (e.g. Amazon)",
      alternates: ["Description"],
      validators: [{ validate: "required" }]
    },
    {
      label: "Date",
      key: "date",
      description: "The date of the transaction",
      alternates: ["Transaction Date", "Posted Date"],
      validators: [{ validate: "required" }]
    },
    {
      label: "Amount",
      description:
        "The transaction amount. Negative numbers represent expenses and positive numbers represents income.",
      key: "amount",
      validators: [
        { validate: "required_without", fields: ["debit", "credit"] }
      ]
    },
    {
      label: "Credit",
      description:
        "The transaction amount if the transaction if the transaction is income (e.g. a refund or income)",
      key: "credit",
      validators: [
        {
          validate: "required_without",
          fields: ["amount"]
        }
      ]
    },
    {
      label: "Debit",
      description:
        "The transaction amount if the transaction if the transaction is an expense",
      key: "debit",
      validators: [{ validate: "required_without", fields: ["amount"] }]
    },
    {
      label: "Category",
      description: "The ",
      key: "wsCategory",
      type: "select",
      options: TRANSACTION_CATEGORIES.map(category => ({
        label: category,
        value: category,
        alternates: [category]
      }))
    },
    {
      label: "Account",
      key: "account",
      alternates: ["Account Number"],
      description: "The account associated with the transaction"
    },
    {
      label: "Note",
      key: "note",
      description: "Any memo or description to be added to the transaction",
      alternates: ["memo"]
    }
  ]
});

importer.registerStepHook("review", ({ headers_matched }: any) => {
  if (
    !headers_matched.find(
      (v: { matched_key: string }) => v.matched_key === "amount"
    ) &&
    headers_matched.find(
      (v: { matched_key: string }) => v.matched_key === "credit"
    ) &&
    headers_matched.find(
      (v: { matched_key: string }) => v.matched_key === "debit"
    )
  ) {
    importer.addVirtualField(
      {
        label: "Amount",
        key: "amount",
        description: `If you're CSV contains two columns of debit and credit transactions, they will be combined into one amount.`
      },
      {
        order: 0,
        hideFields: ["credit", "debit"]
      }
    );
  }
  return true;
});

importer.registerRecordHook(
  (
    record: {
      credit: string;
      debit: string;
      name: string;
      amount: string;
      date: string | number | Date;
    },
    index: any,
    mode: string
  ) => {
    let out: any = {};
    if (record.credit && mode === "init") {
      out.amount = {
        value: getFloatFromString(record.credit) * -1,
        info: [
          {
            message:
              "Credit and debit amounts have been combined into one field: amount",
            level: "info"
          }
        ]
      };
    }
    if (record.debit && mode === "init") {
      out.amount = {
        value: getFloatFromString(record.debit),
        info: [
          {
            message:
              "Credit and debit amounts have been combined into one field: amount",
            level: "info"
          }
        ]
      };
    }

    if (record.name) {
      const cleanName = record.name
        .trim()
        .replace(/\s\s+/g, " ")
        .split(" ")
        .map(str =>
          str
            .toLowerCase()
            .trim()
            .replace(/(ach)|\*|[0-9]|-|~|\./g, "")
        )
        .join(" ")
        .replace(/\b(\w)/g, s => s.toUpperCase());
      out.name = {
        value: cleanName,
        info: [
          {
            message: "Transaction names have been cleaned",
            level: "info"
          }
        ]
      };
    }

    if (record.amount && mode === "init") {
      out.amount = {
        value: getFloatFromString(record.amount),
        info: [
          {
            message: "Amount has been converted to a number",
            level: "info"
          }
        ]
      };
    }

    if (record.date) {
      const errorDate = {
        info: [
          {
            message: "Please check that the date is formatted YYYY-MM-DD.",
            level: "error"
          }
        ]
      };

      if (dateIsValid(record.date)) {
        //reformat the date to ISO format
        let thisDate = format(new Date(record.date), "yyyy-MM-dd");
        //create var that holds the date value of the reformatted date as
        //thisDate is only a string
        let realDate = parseISO(thisDate);
        if (isDate(realDate)) {
          out.date = {
            value: thisDate,
            info: isFuture(realDate)
              ? [
                  {
                    message: "Date cannot be in the future.",
                    level: "error"
                  }
                ]
              : []
          };
        } else {
          out.date = errorDate;
        }
      } else {
        out.date = errorDate;
      }
    }
    return out;
  }
);

export const useFlatLineImporter = () => {
  const userId = useUserId();
  const user = useUserProfile(userId);

  importer.setCustomer({
    userId: userId,
    name: `${user.data?.profile.firstName} ${user.data?.profile.lastName}`
  });

  return async () => {
    try {
      const results = await importer.requestDataFromUser();
      await importer.close();

      return results.validData as Array<any>;
    } catch (error) {
      console.info(error || "window close");
      return [];
    }
  };
};
