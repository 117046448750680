import { WSLoader } from "@wingspanhq/fe-component-library";
import { ICheckbookCard } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import { usePayoutSettingsDebitCard } from "../../../../query/payments/queries";

type Props = {
  cardId: string;
  children: (debitCard: ICheckbookCard) => React.ReactElement | null;
};

export const ContainerDebitCard: React.FC<Props> = ({ cardId, children }) => {
  const userId = useUserId();
  const queryDebitCard = usePayoutSettingsDebitCard(userId, cardId);

  if (!queryDebitCard.data) {
    return <WSLoader.Spinner size="XS" />;
  }

  return children(queryDebitCard.data);
};
