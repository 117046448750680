import * as React from "react";
import { Route, Switch } from "react-router-dom";

import { TeamDashboard } from "./RouteTeamDashboard";
import { TeamAddMember } from "./RouteTeamAddMember";
import { TeamEditMember } from "./RouteTeamEditMember";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";

export const TeamIndex = () => {
  return (
    <>
      <BrowserPageTitle title="Team" />
      <Switch>
        <Route path="/member/settings/team" component={TeamDashboard} exact />
        <Route
          path="/member/settings/team/add-team-member"
          component={TeamAddMember}
          exact
        />
        <Route
          path="/member/settings/team/:teamMemberUserId/edit-team-member"
          component={TeamEditMember}
          exact
        />
      </Switch>
    </>
  );
};
