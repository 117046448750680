import { WSElement } from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React from "react";
import classes from "./styles.module.scss";

export interface OverlayProps {
  className?: string;
  zIndex?: number;
  onClick?: () => void;
  white?: boolean;
  overParent?: boolean;
}

export const Overlay: React.FC<OverlayProps> = ({
  className,
  zIndex,
  onClick,
  white,
  overParent,
  children
}) => (
  <WSElement
    className={classNames(
      classes.overlay,
      { [classes.white]: white },
      { [classes.overParent]: overParent },
      className
    )}
    style={{ zIndex }}
    onClick={onClick}
  >
    {children}
  </WSElement>
);
