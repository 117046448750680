import React, { useMemo, useState } from "react";
import { WSInputDate } from "../WSInputDate/WSInputDate";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import { FilterFieldRadio } from "./FilterFieldRadio";
import styles from "./WSFilters.module.scss";
import {
  FilterChangeHandler,
  WSFilterDateRange,
  WSFilterDateRangeOption,
  WSFilterDateRangeValue,
  WSFilterOption,
  WSFilterValue
} from "./types";
import { dateRangeLabelMap } from "./utils/dateRange";
import { WSElement } from "../WSElement/WSElement.component";

type Props = {
  value?: WSFilterDateRangeValue;
  onChange: FilterChangeHandler;
} & Omit<WSFilterDateRange, "type">;

export const FilterFieldDateRage: React.FC<Props> = ({
  name,
  options: dateRangeOptions,
  value,
  onChange
}) => {
  const showCustom = useMemo(() => dateRangeOptions.includes("custom"), [
    dateRangeOptions
  ]);

  const startName = "start";
  const endName = "end";
  // Keep custom values saved in internal state, so user not lose them when switching between radio options
  const [startInternal, setStartInternal] = useState(
    typeof value === "object" ? value?.start : null
  );
  const [endInternal, setEndInternal] = useState(
    typeof value === "object" ? value?.end : null
  );

  const radioOptions: WSFilterOption[] = useMemo(
    () =>
      dateRangeOptions.map((v) => ({
        name: v,
        label: dateRangeLabelMap[v]
      })),
    [dateRangeOptions]
  );

  const radioValue = useMemo(() => {
    if (value === undefined) {
      return "all";
    }

    if (typeof value === "object") {
      return "custom";
    }

    return value;
  }, [value]);

  const handleRadioChange = (_: string, newRadioValue?: WSFilterValue) => {
    let newValue: WSFilterDateRangeValue | undefined;
    switch (newRadioValue as WSFilterDateRangeOption) {
      case "pastWeek":
        newValue = "pastWeek";
        break;
      case "pastMonth":
        newValue = "pastMonth";
        break;
      case "yearToDate":
        newValue = "yearToDate";
        break;
      case "custom":
        newValue = {
          start: startInternal || undefined,
          end: endInternal || undefined
        };
        break;
      default:
        newValue = undefined;
    }

    onChange(name, newValue);
  };

  return (
    <>
      <FilterFieldRadio
        name={name}
        options={radioOptions}
        value={radioValue}
        onChange={handleRadioChange}
      />

      {showCustom && (
        <WSElement className={styles.customDateRange}>
          <WSInputDate
            label="Start date"
            name={startName}
            value={startInternal}
            onFocus={() => {
              if (radioValue !== "custom") {
                onChange(name, {
                  start: startInternal || undefined,
                  end: endInternal || undefined
                });
              }
            }}
            onChange={(newDate) => {
              setStartInternal(newDate);
              onChange(name, {
                start: newDate || undefined,
                end: endInternal || undefined
              });
            }}
          />
          <WSIcon name="minus" mt="XL" color="gray500" />
          <WSInputDate
            label="End date"
            name={endName}
            value={endInternal}
            onChange={(newDate) => {
              setEndInternal(newDate);
              onChange(name, {
                start: startInternal || undefined,
                end: newDate || undefined
              });
            }}
          />
        </WSElement>
      )}
    </>
  );
};
