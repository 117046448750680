import {
  useWSSnackbar,
  WSButton,
  WSElement,
  WSFlexBox,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useCreateCard } from "../../../query/payments/mutations";
import { useCardsQuery } from "../../../query/payments/queries";
import { WSQueries } from "../../../query/WSQuery";
import { DebitCard } from "./DebitCard";

export interface DebitCardsListProps {}

export const DebitCardsList: React.FC<DebitCardsListProps> = () => {
  const { openSnackbar } = useWSSnackbar();
  const cardsQuery = useCardsQuery();

  const [createCard, createCardMeta] = useCreateCard();
  const onClickAddCard = async () => {
    await createCard(
      {},
      {
        onSuccess: () => {
          openSnackbar({
            duration: 5000,
            type: "success",
            message: "Card created successfully!"
          });
        },
        onError: () => {
          openSnackbar({
            duration: 5000,
            type: "warning",
            message: `Failed to create a card!`
          });
        }
      }
    );
  };
  return (
    <>
      <WSPage
        title="Cards"
        primaryButtonComponent={props => (
          <WSButton
            loading={createCardMeta.isLoading}
            onClick={onClickAddCard}
            icon="plus"
            {...props}
            name="addCard"
          >
            Add card
          </WSButton>
        )}
      >
        <WSElement my="3XL">
          <WSQueries queries={{ cardsQuery }}>
            {({ cardsQuery: { data: cards } }) => {
              if (cards.length === 0) {
                return (
                  <WSFlexBox.Center>
                    <WSText>
                      No cards yet!{" "}
                      <WSButton.Link
                        onClick={onClickAddCard}
                        loading={createCardMeta.isLoading}
                      >
                        Create
                      </WSButton.Link>{" "}
                      a card for yourself now!
                    </WSText>
                  </WSFlexBox.Center>
                );
              }
              return cards.map(card => (
                <DebitCard key={card.cardId} card={card} />
              ));
            }}
          </WSQueries>
        </WSElement>
      </WSPage>
    </>
  );
};
