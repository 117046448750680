import {
  useIsDesktop,
  useIsMobile,
  useIsTablet,
  useWSModal,
  WSButton,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFormOld,
  WSGrid,
  WSText
} from "@wingspanhq/fe-component-library";
import { ICustomField } from "@wingspanhq/payments/dist/interfaces";
import { formatMoney } from "accounting";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Overlay } from "../../../../components/Overlay";
import { RefreshModal } from "../../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import {
  InvoicesFormStep1Values,
  useInvoicesFormContext
} from "../InvoicesForm";
import { LineItem } from "./LineItem";
import { ModalMobileForm } from "./ModalMobileForm";
import { FormDataLineItem } from "./types";
import { useQuickbooksProps } from "./useQuickbooksProps";
import { calculateAmount, calculateTotalAmount } from "./utils";

export type LineItemsSectionValues = {
  lineItems: FormDataLineItem[];
};

type Props = {
  isIntegrationQuickbooksItems?: boolean;
  isIntegrationQuickbooksAccounts?: boolean;
  disabled?: boolean;
  customFields?: ICustomField[];
} & WSElementProps;

export const LineItemsSection: React.FC<Props> = ({
  isIntegrationQuickbooksAccounts,
  isIntegrationQuickbooksItems,
  disabled,
  customFields = [],
  ...elementProps
}) => {
  const { control, setValue, getValues } = useFormContext<
    InvoicesFormStep1Values
  >();
  const { fields: lineItems, append, remove } = useFieldArray<FormDataLineItem>(
    {
      control,
      name: "lineItems"
    }
  );

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const modalMobileForm = useWSModal(ModalMobileForm);
  const { invoicingConfigForPayee } = useInvoicesFormContext();

  useEffect(() => {
    if (isMobile || isTablet) {
      const values = getValues();

      if (!values.lineItems?.[0]?.totalCost) {
        remove(0);
      }
    }
  }, [isMobile, isTablet, getValues, remove]);

  const quickbooksProps = useQuickbooksProps({
    isIntegrationQuickbooksItems,
    isIntegrationQuickbooksAccounts
  });

  return (
    <WSElement {...elementProps}>
      {disabled && <Overlay white overParent />}

      <RefreshModal />

      <WSElement mb="XS">
        <WSGrid gutter="M">
          <WSGrid.Item span={{ xs: "6", s: "7" }}>
            <WSText.ParagraphSm color="gray500">Description</WSText.ParagraphSm>
          </WSGrid.Item>
          <WSGrid.Item span={{ xs: "6", s: "5" }}>
            <WSText.ParagraphSm color="gray500">Amount</WSText.ParagraphSm>
          </WSGrid.Item>
        </WSGrid>
      </WSElement>

      <WSDivider mb="M" />

      {lineItems.map((lineItem, index) => (
        <LineItem
          key={lineItem.id}
          data={lineItem}
          index={index}
          onRemove={
            lineItems.length > 1
              ? () => {
                  setValue(`lineItems[${index}].remove`, "1");
                }
              : undefined
          }
          onRateChange={() => {
            const values = getValues();

            if (values.lineItems[index].rate !== "fixed") {
              setValue(
                `lineItems[${index}].totalCost`,
                calculateAmount(values.lineItems[index])
              );
            }
          }}
          quickbooksProps={quickbooksProps}
          customFields={customFields}
        />
      ))}

      <WSButton.Link
        size="M"
        mt="M"
        type="button"
        icon="plus-circle"
        onClick={() => {
          if (isDesktop) {
            append({});
          } else {
            modalMobileForm.open({
              onSubmit: values => {
                append(values);
              },
              quickbooksProps,
              customFields: customFields,
              invoicingConfigForPayee
            });
          }
        }}
        name="addLineItem"
      >
        Add item
      </WSButton.Link>

      <WSFormOld.Error name="lineItems" />

      <WSDivider mt="M" mb="M" />

      <WSFormOld.Value name="lineItems">
        {(lineItems: FormDataLineItem[]) => {
          const total = calculateTotalAmount(lineItems);

          return (
            <>
              <WSElement>
                <WSGrid gutter="M">
                  <WSGrid.Item span={{ xs: "6", s: "7" }}>
                    <WSText.ParagraphSm color="gray500">
                      Amount due
                    </WSText.ParagraphSm>
                  </WSGrid.Item>
                  <WSGrid.Item span={{ xs: "6", s: "5" }}>
                    <WSText.Heading5 data-testid="totalAmount">
                      {total ? formatMoney(total) : "$—.——"}
                    </WSText.Heading5>
                  </WSGrid.Item>
                </WSGrid>
              </WSElement>
            </>
          );
        }}
      </WSFormOld.Value>
    </WSElement>
  );
};
