import {
  WSButton,
  WSButtons,
  WSDivider,
  WSElement,
  WSFormOld,
  WSText
} from "@wingspanhq/fe-component-library";
import { SetupRequestCorrectionFlowProps } from "./index";
import { CURRENT_YEAR } from "../../../1099NECFiling/constants/currentYear";
import { EditLayout } from "../../../1099NECFiling/components/EditLayout";
import { useHistory, useRouteMatch } from "react-router-dom";
import React from "react";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../constants/corrections";
import { AmountCorrectionForm } from "../../components/AmountCorrectionForm";
import { TINOrNameCorrectionForm } from "../../components/TINOrNameCorrectionForm";
import { AddressCorrectionForm } from "../../components/AddressCorrectionForm";
import { useCancelCorrectionModal } from "../../components/CorrectionFormActions";
import { getFormValidationSchema } from "./getFormValidationSchema";
import { useUserId } from "../../../../query/hooks/helpers";
import { useMemberProfile } from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { usePayeeTaxForm } from "../../../../query/taxForm/queries/usePayeeTaxForm";
import { selectorPayeeTaxFormPayerName } from "../../../1099NECFiling/selectors/selectorPayeeTaxFormPayerName";
import {
  getDefaultValuesForCorrectionsForm,
  getPayeeFormW9Address,
  getPayeeTINorName
} from "./getDefaultValuesForCorrectionsForm";
import { TAX_DOCUMENTS_PATH } from "../../index";
import { PreventLeave } from "../../../../components/PreventLeave/PreventLeave";
import { getCorrectionType } from "../../utils/getCorrectionType";
import { FormRequestCorrectionData } from "./types";
import { TinType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { useVoidOriginalTaxFormWarningModal } from "../../components/useVoidOriginalTaxFormWarningModal";
import { selectorNo1099 } from "../../../1099NECFiling/components/FormTaxpayerInformation/selectorNo1099";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";

export const RouteAddCorrectionValues: React.FC<SetupRequestCorrectionFlowProps> = ({
  onNext
}) => {
  const history = useHistory<{
    isEdit: boolean;
    corrections: string[];
    correctionsFormData: any;
  }>();
  const match = useRouteMatch<{ taxFormId: string }>();
  const { taxFormId } = match.params;
  const queryTaxForm = usePayeeTaxForm(taxFormId);

  const isEdit = history.location.state?.isEdit;
  const corrections = history.location.state?.corrections ?? [];
  const correctionsFormData = history.location.state?.correctionsFormData;

  const userId = useUserId();
  const queryMember = useMemberProfile(userId);
  const cancelCorrectionModal = useCancelCorrectionModal();
  const voidOriginalTaxFormWarningModal = useVoidOriginalTaxFormWarningModal();

  const onCancel = () => {
    history.push(`${TAX_DOCUMENTS_PATH}/${taxFormId}`);
  };

  const confirmCancel = () => {
    cancelCorrectionModal.open({
      onCancel
    });
  };

  const onFormSubmit = (data: FormRequestCorrectionData) => {
    const isTaxFormIneligible = selectorNo1099(
      data.taxClassification as CompanyStructure
    );
    if (isTaxFormIneligible) {
      voidOriginalTaxFormWarningModal.open({
        onContinue: () => {
          onNext({
            corrections,
            correctionsFormData: data
          });
        }
      });
    } else {
      onNext({
        corrections,
        correctionsFormData: data
      });
    }
  };
  return (
    <EditLayout title="Request correction">
      <WSQueries queries={{ queryMember, queryTaxForm }}>
        {({ queryMemberData: member, queryTaxFormData: taxForm }) => {
          const payerName = selectorPayeeTaxFormPayerName(taxForm);
          const originalTinData = getPayeeTINorName(taxForm);
          const correctionTypeToFormComponentMap: Record<
            string,
            JSX.Element
          > = {
            [CORRECTION_AMOUNT]: (
              <AmountCorrectionForm
                taxForm={taxForm}
                amount={taxForm.data?.totalAmount || 0}
                payerName={payerName}
              />
            ),
            [CORRECTION_TIN_OR_NAME]: (
              <TINOrNameCorrectionForm
                taxForm={taxForm}
                originalTinData={originalTinData}
                identificationNumberType={
                  originalTinData?.ein ? TinType.Business : TinType.Individual
                }
                showRequestedValue={false}
              />
            ),
            [CORRECTION_ADDRESS]: (
              <AddressCorrectionForm
                taxForm={taxForm}
                originalAddress={getPayeeFormW9Address(taxForm)}
              />
            )
          };
          return (
            <>
              <WSText.Heading4 mb="2XL">
                {payerName} {CURRENT_YEAR} Form 1099-NEC
              </WSText.Heading4>
              <WSFormOld
                defaultValues={
                  isEdit
                    ? correctionsFormData
                    : getDefaultValuesForCorrectionsForm(corrections, taxForm)
                }
                validationSchema={getFormValidationSchema(
                  corrections,
                  member.profile.ssnProvided
                )}
                onSubmit={onFormSubmit}
              >
                {corrections.map((correctionType, index) => {
                  const FormComponent =
                    correctionTypeToFormComponentMap[correctionType];
                  const isLast = index === corrections.length - 1;
                  return (
                    <WSElement mb="2XL" key={correctionType}>
                      {FormComponent}
                      {isLast ? null : <WSDivider my="3XL" />}
                    </WSElement>
                  );
                })}

                <WSFormOld.Values
                  names={[
                    "necTotalAmount",
                    "amountCorrectionHelpText",
                    "identificationNumberType",
                    "ssn",
                    "ein",
                    "firstName",
                    "lastName",
                    "businessName",
                    "disregardedEntityName",
                    "taxClassification",
                    "reasonForChange",
                    "otherReason",
                    "formW9Address"
                  ]}
                >
                  {formData => {
                    const correctionTypeData = getCorrectionType(
                      taxForm,
                      formData
                    );
                    return (
                      <>
                        <PreventLeave
                          isEnabled={correctionTypeData.corrections.length > 0}
                        />
                        <WSButtons forceFullWidth>
                          <WSFormOld.SubmitButton
                            disabled={
                              correctionTypeData.corrections.length === 0
                            }
                          >
                            Continue
                          </WSFormOld.SubmitButton>
                          <WSButton.Tertiary
                            type="button"
                            onClick={confirmCancel}
                          >
                            Cancel
                          </WSButton.Tertiary>
                        </WSButtons>
                      </>
                    );
                  }}
                </WSFormOld.Values>
              </WSFormOld>
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
