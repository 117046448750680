import {
  ITaxFormResponse,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces";

export const selectorTaxFormIsEmailResendAvailable = (
  taxForm: ITaxFormResponse
): boolean => {
  return [TaxFormStatus.AcceptedByIrs, TaxFormStatus.RejectedByIrs].includes(
    taxForm.status
  );
};
