/* Custom fields */

import {
  CustomFieldResourceType,
  ICustomField,
  IAdditionalDataUpdateRequest,
  IAdditionalData
} from "@wingspanhq/payments/dist/interfaces";
import { WSService } from "../../utils/WSService";
import { ListRequestQuery } from "../../utils/serviceHelper";

const service = new WSService("/payments");

export interface CustomFieldListFilters {
  resourceType?: CustomFieldResourceType[];
  sort?: {
    createdAt?: "asc" | "desc";
  };
}

export interface CustomFieldsApiFilter {
  resourceType?: {
    in?: CustomFieldResourceType[];
  };
}

export function mapCustomFieldListFilters(filters?: CustomFieldListFilters) {
  const apiFilters: CustomFieldsApiFilter = {};

  if (filters?.resourceType && filters.resourceType.length !== 2) {
    apiFilters.resourceType = {
      in: filters.resourceType
    };
  }
  return apiFilters;
}

const getCustomFields = async (
  query?: ListRequestQuery<
    CustomFieldsApiFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >
): Promise<Array<ICustomField>> => {
  const response = await service.get(`/custom-fields`, {
    params: query
  });
  return response.data;
};

const getCustomField = async (id: string) => {
  const response = await service.get(`/custom-fields/${id}`);
  return response.data as ICustomField;
};

const createCustomField = async (data: IAdditionalData) => {
  const response = await service.post("/custom-fields", data);
  return response.data as ICustomField;
};

const updateCustomField = async (payload: {
  id: string;
  data: IAdditionalDataUpdateRequest;
}) => {
  const response = await service.patch(
    `/custom-fields/${payload.id}`,
    payload.data
  );
  return response.data;
};

const deleteCustomField = async (id: string) => {
  const response = await service.delete(`/custom-fields/${id}`);
  return response.data;
};

export const customFieldsService = {
  customField: {
    list: getCustomFields,
    get: getCustomField,
    update: updateCustomField,
    create: createCustomField,
    delete: deleteCustomField
  }
};
