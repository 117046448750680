import React from "react";
import * as Yup from "yup";

import { BrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import {
  WSElement,
  WSFormOld,
  WSGrid,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { validatorEmail } from "../../../../shared/validators/validatorEmail";
import { FAQ1099 } from "../../components/FAQ1099";
import styles from "./styles.module.scss";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { OnboardingLayout } from "../../../../components/OnboardingLayout/OnboardingLayout";
import { useRequestLink } from "./useRequestLink";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";

export const RouteRequestOnboardingLink: React.FC = () => {
  const history = useHistory();
  const [requestLink, requestLinkMeta] = useRequestLink();

  return (
    <>
      <BrowserPageTitle title="Request 1099" />
      <OnboardingLayout>
        <WSGrid>
          <WSGrid.Item span={{ m: "6" }}>
            <WSText.Heading1>
              Request new onboarding link to access your tax forms via Wingspan
            </WSText.Heading1>
            <WSText color="gray600" mt="2XL">
              Enter your email below to receive a fresh link for setting up your
              Wingspan account and accessing tax forms.
            </WSText>
            <WSText color="gray600" mt="XL">
              Before You Request, Please Check:
            </WSText>
            <WSElement as="ul" pl="XL">
              <WSElement as="li" color="gray600">
                <WSText color="gray600" mt="XS">
                  <b>Your Spam/Junk Mail</b>: Look for an email from Wingspan in
                  your spam or junk folder.
                </WSText>
              </WSElement>
              <WSElement as="li" color="gray600">
                <WSText color="gray600" mt="XS">
                  <b>Email Address with Your Platform</b>: Ensure the email
                  address registered with your payer is up to date. Check any
                  alternate emails you might have used, or update your email
                  with your platform.
                </WSText>
              </WSElement>
              <WSElement as="li" color="gray600">
                <WSText color="gray600" mt="XS">
                  <b>Correct and Active Email</b>: Make sure the email linked to
                  your account is correct and capable of receiving emails.
                </WSText>
              </WSElement>
            </WSElement>
            <WSElement className={styles.panel} mt="2XL">
              <WSText.Heading4>Request a login link</WSText.Heading4>
              <WSFormOld
                defaultValues={{
                  email: ""
                }}
                validationSchema={Yup.object().shape({
                  email: validatorEmail.required("Email is required")
                })}
                onSubmit={async formData => {
                  await requestLink(formData, {
                    onSuccess: () => {
                      history.push("/1099/request-onboarding-link/success", {
                        email: formData.email
                      });
                    }
                  });
                }}
              >
                <WSFormOld.Field
                  mt="XL"
                  name="email"
                  component={WSTextInput}
                  componentProps={{
                    type: "email",
                    autoComplete: "email",
                    placeholder: "example@example.com"
                  }}
                ></WSFormOld.Field>

                <WSErrorMessage
                  my="M"
                  error={requestLinkMeta.error}
                  contextKey="NEC1099Onboarding"
                />

                <WSFormOld.Value name="email">
                  {email => (
                    <WSFormOld.SubmitButton disabled={!email} mt="XL">
                      Submit
                    </WSFormOld.SubmitButton>
                  )}
                </WSFormOld.Value>
              </WSFormOld>
            </WSElement>
            <WSText color="gray600" mt="XL">
              To learn more about Wingspan, check out the articles below
            </WSText>
            <WSText.ParagraphSm
              weight="medium"
              color="blue500"
              mt="M"
              onClick={() =>
                openInNewTab(
                  "https://help.wingspan.app/en/articles/8705224-how-can-i-find-my-wingspan-email-notification-for-providing-my-tax-information"
                )
              }
            >
              How can I find my Wingspan email notification for providing my tax
              information?
            </WSText.ParagraphSm>
            <WSText color="gray600" mt="M">
              If you’ve tried to request a login link, check your inbox and
              spam, and still can’t find your invite, you’ll need to reach out
              to your payer for assistance.
            </WSText>
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "2" }} />
          <WSGrid.Item span={{ m: "4" }}>
            <FAQ1099 />
          </WSGrid.Item>
        </WSGrid>
      </OnboardingLayout>
    </>
  );
};
