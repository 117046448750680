import { useConfirmCreateRequirementModal } from "../../../components/CreateRequirementDefinitionForm/useConfirmCreateRequirementModal";
import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { CreateRequirementDefinitionFormValues } from "../../../types";
import {
  DefinitionDataSourceType,
  IRequirementDefinitionResponse,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import { CreateRequirementDefinitionBackgroundChecksPartialForm } from "../types";
import { useCreateBackgroundCheckSettings } from "../../../../../query/backgroundChecks/mutations/useCreateBackgroundCheckSettings";
import { useBackgroundChecksPackagesQuery } from "../../../../../query/backgroundChecks/queries/useBackgroundChecksPackagesQuery";
import { selectorBackgroundChecksActivePackages } from "../../../selectors/selectorBackgroundChecksActivePackages";
import { useFeatureFlags } from "../../../../../query/hooks/useFeatureFlags";

type Params = {
  engagementId?: string;
  onSuccess: (requirement: IRequirementDefinitionResponse) => void;
};

export const useCreateBackgroundChecksRequirementDefinition = ({
  engagementId,
  onSuccess
}: Params) => {
  const { openSnackbar } = useWSSnackbar();

  const featureFlags = useFeatureFlags();

  const [
    createSettings,
    createSettingsMeta
  ] = useCreateBackgroundCheckSettings();

  const queryBackgroundChecks = useBackgroundChecksPackagesQuery({
    enabled: featureFlags.data?.backgroundChecks
  });

  const confirmCreateRequirementModal = useConfirmCreateRequirementModal();

  const onSubmit = async (
    formValues: CreateRequirementDefinitionFormValues &
      CreateRequirementDefinitionBackgroundChecksPartialForm
  ) => {
    const settings = await createSettings(
      {
        title: formValues.name,
        backgroundCheckPackageId: formValues.package,
        instructions: formValues.instructions
      },
      {
        throwOnError: true
      }
    );

    if (settings) {
      // open confirmation modal to create requirement
      await confirmCreateRequirementModal.open({
        engagementId,
        dataSourceType: DefinitionDataSourceType.BackgroundCheckRequestSettings,
        dataSourceId: settings.backgroundCheckRequestSettingsId,
        name: formValues.name,
        description: formValues.description,
        requirementType: RequirementType.BackgroundCheck,
        onSuccess(requirement) {
          onSuccess?.(requirement);
        }
      });
    }
  };

  return {
    onSubmit,
    errors: createSettingsMeta.error,
    isLoading: createSettingsMeta.isLoading || queryBackgroundChecks.isLoading,
    isEnabled: queryBackgroundChecks.data
      ? selectorBackgroundChecksActivePackages(queryBackgroundChecks.data)
          .length > 0
      : false
  };
};
