import { WSService } from "../../../utils/WSService";
import { operations } from "./types";

const service = new WSService("/v2/banking/internal-accounts");

export const listInternalAccounts = async (
  type?: "Clearing" | "TaxWithholding" | "Banking" | "InternationalClearing"
): Promise<operations["listInternalAccounts"]["responses"]["200"]["content"]["application/json"]> => {
  const { data } = await service.get("", {
    params: {
      type
    }
  });
  return data;
};

export const createInternalAccount = async (
  request: operations["createInternalAccount"]["requestBody"]["content"]["application/json"]
): Promise<operations["createInternalAccount"]["responses"]["200"]["content"]["application/json"]> => {
  const { data } = await service.post("", request);
  return data;
};

export type BankingInternalAccount = operations["getInternalAccount"]["responses"]["200"]["content"]["application/json"];

export const getInternalAccount = async (
  internalAccountId: string
): Promise<BankingInternalAccount> => {
  const { data } = await service.get("/" + internalAccountId);
  return data;
};
