import { MemberTaxDocumentSharePermission } from "@wingspanhq/payments/dist/interfaces";
import { MutationConfig } from "react-query";
import { useWSMutation } from "../../../../query/helpers";
import { paymentsService } from "../../../../services/payments";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { QUERY_MEMBER_CLIENTS } from "../../../../query/payments/keys";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../../../../query/notifications/keys";

export const useMutationSubmit = (config?: MutationConfig<void>) => {
  return useWSMutation<any, WSServiceError, string[]>(
    async memberClientIds => {
      return await Promise.all(
        memberClientIds.map(memberClientId =>
          paymentsService.memberClient.update(memberClientId, {
            memberData: {
              shareTaxDocument: MemberTaxDocumentSharePermission.Allow
            }
          })
        )
      );
    },
    {
      dependencies: [QUERY_MEMBER_CLIENTS],
      awaitDependencies: [QUERY_NOTIFICATIONS_NOTIFICATIONS],
      ...config
    }
  );
};
