import React from "react";
import { FormManuallyAddAccount } from "../../../../shared/components/FormManuallyAddAccount";
import { LayoutPaymentsSetup } from "../../../../shared/components/LayoutPaymentsSetup";
import { RouteComponentProps } from "../../types";

export const RoutePayoutsConnectBankManual: React.FC<RouteComponentProps> = ({
  onBack,
  onNext
}) => {
  return (
    <LayoutPaymentsSetup
      onBack={onBack}
      title="Enter your bank routing & account numbers"
    >
      <FormManuallyAddAccount setStandardPayoutDestination onSuccess={onNext} />
    </LayoutPaymentsSetup>
  );
};
