import { QueryConfig } from "react-query";
import { useWSQuery } from "../../helpers";
import { QUERY_PAYMENTS_TAXFORM_PAYER } from "../keys";
import { paymentsService } from "../../../services/payments";
import { WSServiceError } from "../../../utils/serviceHelper";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";

export const usePayerTaxForm = (
  taxFormId: string,
  queryConfig?: QueryConfig<IPayerTaxFormResponse, unknown>
) =>
  useWSQuery<IPayerTaxFormResponse, WSServiceError>(
    [QUERY_PAYMENTS_TAXFORM_PAYER, taxFormId],
    () => paymentsService.taxForm.get(taxFormId),
    queryConfig
  );
