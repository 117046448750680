import { AllEngagementsByPayerIds } from "../queries/useQueryAllPayersWithEngagements";
import { isEligibilityRequirementsCompleted } from "../selectors/getActivePayersWithEngagement";
import { EligibilityRequirements } from "../steps/EligibilityRequirements/EligibilityRequirements";
import {
  OnboardingContext,
  OnboardingModuleEligibilityRequirements,
  OnboardingStep
} from "../types";

const getStatus = (payersEngagements?: AllEngagementsByPayerIds) => {
  if (
    payersEngagements &&
    isEligibilityRequirementsCompleted(payersEngagements)
  ) {
    return "Complete";
  }

  return "None";
};

export const getEligibilityRequirementsStepConfig = (
  _: OnboardingModuleEligibilityRequirements,
  __: OnboardingContext,
  payersEngagements?: AllEngagementsByPayerIds
): OnboardingStep => {
  const slug = "requirements";

  const status = getStatus(payersEngagements);

  return {
    title: "Eligibility requirements",
    slug,
    component: EligibilityRequirements,

    status,
    result: {
      Complete: {
        title: "Your eligibility requirements are complete",
        description:
          "Your eligibility requirements are complete. Your eligibility requirements from your clients are complete. If additional requirements are needed in the future, these tasks will appear on your “Get started” homepage. Click continue for the next step."
      }
    }
  };
};
