import {
  WSChip,
  WSDateRangeSelectOptions,
  WSElement,
  WSElementProps,
  WSFiltersOld,
  WSFlexBox,
  WSFormOld,
  WSInputDateOld,
  WSLoader,
  WSRadioInputGroup,
  WSSearch,
  WSSearchProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { formatNumber } from "accounting";
import React, { useMemo } from "react";
import {
  InvoicesDateRangeFilter,
  useMemberClientsQuery
} from "../../../../query/payments/queries";
import {
  getClientName,
  getClients
} from "../../../../query/payments/selectors";
import styles from "./index.module.scss";
import { IMemberClient } from "@wingspanhq/payments/dist/interfaces";

export const getDateRange = (
  dateRange?: InvoicesDateRangeFilter,
  custom?: Date[]
) => {
  switch (dateRange) {
    case InvoicesDateRangeFilter.Custom: {
      return custom;
    }
    case InvoicesDateRangeFilter.PastWeek: {
      return WSDateRangeSelectOptions.PastWeek.range;
    }
    case InvoicesDateRangeFilter.PastMonth: {
      return WSDateRangeSelectOptions.PastMonth.range;
    }
    case InvoicesDateRangeFilter.PastYear: {
      return WSDateRangeSelectOptions.Past12Months.range;
    }
    default:
      return undefined;
  }
};

export interface Filters {
  memberClientId: string;
  dueDateRange?: InvoicesDateRangeFilter;
  customDueDateRange?: Date[];
  paidDateRange?: InvoicesDateRangeFilter;
  customPaidDateRange?: Date[];
  search?: string;
}

type ToolbarProps = {
  filters?: Filters;
  setFilters?: (newFilters: Filters) => void;
  count?: number;
  totalAmount?: number;
  defaultFilters?: Filters;
} & WSElementProps;

export const Toolbar: React.FC<ToolbarProps> = ({
  filters,
  setFilters,
  count,
  totalAmount,
  defaultFilters,
  ...elementProps
}) => {
  const memberClientsQuery = useMemberClientsQuery();
  const clients = getClients(memberClientsQuery.data || []);

  const filtersWithoutSearch = useMemo(() => {
    const filtersWithoutSearch = { ...filters };
    delete filtersWithoutSearch.search;
    return filtersWithoutSearch;
  }, [filters]);

  return (
    <WSElement {...elementProps} className={styles.toolbar}>
      {filters && setFilters ? (
        <WSFiltersOld
          filters={filtersWithoutSearch}
          defaultFilters={defaultFilters}
          onFilter={newFilters =>
            setFilters({
              ...filters,
              ...newFilters
            })
          }
        >
          {memberClientsQuery.isLoading ? (
            <WSLoader.Spinner />
          ) : (
            <WSFormOld.Field<WSSearchProps<IMemberClient>>
              mb="2XL"
              name="memberClientId"
              label="Client"
              component={WSSearch}
              componentProps={{
                placeholder: "Search by email, name..",
                threshold: 0.8,
                itemToString: item => item?.data && getClientName(item?.data),
                defaultItems: clients
                  .sort((a, b) => (a.updatedAt > b.updatedAt ? 1 : 0))
                  .map(client => ({
                    value: client.memberClientId,
                    searchText: [client.emailTo, client.name, client.company]
                      .filter(Boolean)
                      .join(" "),
                    data: client
                  })),
                emptyResults: () => (
                  <WSFlexBox.Center p="L" direction="column">
                    <WSText.ParagraphSm color="gray400">
                      No Results
                    </WSText.ParagraphSm>
                  </WSFlexBox.Center>
                ),
                items: [
                  ...clients.map(client => ({
                    value: client.memberClientId,
                    searchText: [client.emailTo, client.name, client.company]
                      .filter(Boolean)
                      .join(" "),
                    data: client
                  }))
                ],
                getTitle: client => getClientName(client),
                getDetails: client => client.emailTo || ""
              }}
            />
          )}{" "}
          {defaultFilters?.dueDateRange && (
            <WSFormOld.Field
              name="dueDateRange"
              label="Due date range"
              mb="2XL"
              component={WSRadioInputGroup}
              componentProps={{
                options: [
                  {
                    label: "All time",
                    value: InvoicesDateRangeFilter.All
                  },
                  {
                    label: "Past week",
                    value: InvoicesDateRangeFilter.PastWeek
                  },
                  {
                    label: "Past month",
                    value: InvoicesDateRangeFilter.PastMonth
                  },
                  {
                    label: "Past year",
                    value: InvoicesDateRangeFilter.PastYear
                  },
                  {
                    label: (
                      <WSFormOld.Context>
                        {({ setValue }) => (
                          <WSFlexBox alignItems="center">
                            <WSText mr="M">Custom range</WSText>
                            <WSFormOld.Field
                              name="customDueDateRange"
                              component={WSInputDateOld}
                              componentProps={{
                                selectionMode: "range",
                                onFocus() {
                                  setValue(
                                    "dueDateRange",
                                    InvoicesDateRangeFilter.Custom
                                  );
                                }
                              }}
                            />
                          </WSFlexBox>
                        )}
                      </WSFormOld.Context>
                    ),
                    value: InvoicesDateRangeFilter.Custom
                  }
                ]
              }}
            />
          )}
          {defaultFilters?.paidDateRange && (
            <WSFormOld.Field
              name="paidDateRange"
              label="Paid date range"
              mb="2XL"
              component={WSRadioInputGroup}
              componentProps={{
                options: [
                  {
                    label: "All time",
                    value: InvoicesDateRangeFilter.All
                  },
                  {
                    label: "Past week",
                    value: InvoicesDateRangeFilter.PastWeek
                  },
                  {
                    label: "Past month",
                    value: InvoicesDateRangeFilter.PastMonth
                  },
                  {
                    label: "Past year",
                    value: InvoicesDateRangeFilter.PastYear
                  },
                  {
                    label: (
                      <WSFormOld.Context>
                        {({ setValue }) => (
                          <WSFlexBox alignItems="center">
                            <WSText mr="M">Custom range</WSText>
                            <WSFormOld.Field
                              name="customPaidDateRange"
                              component={WSInputDateOld}
                              componentProps={{
                                selectionMode: "range",
                                onFocus() {
                                  setValue(
                                    "paidDateRange",
                                    InvoicesDateRangeFilter.Custom
                                  );
                                }
                              }}
                            />
                          </WSFlexBox>
                        )}
                      </WSFormOld.Context>
                    ),
                    value: InvoicesDateRangeFilter.Custom
                  }
                ]
              }}
            />
          )}
        </WSFiltersOld>
      ) : (
        <WSElement></WSElement>
      )}
      {count !== undefined && (
        <WSFlexBox alignItems="center" wrap="nowrap">
          <WSText.ParagraphXs color="gray500" mr="M">
            Showing
          </WSText.ParagraphXs>

          <WSChip
            pillType="noBackground"
            pillText={`${formatNumber(count)} item${count === 1 ? "" : "s"}`}
            rightText={totalAmount}
          />
        </WSFlexBox>
      )}
    </WSElement>
  );
};
