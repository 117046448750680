import { WSElement, WSElementProps } from "@wingspanhq/fe-component-library";
import { ICheckbookCard } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { WSQueries } from "../../../query/WSQuery";
import { useUserId } from "../../../query/hooks/helpers";
import { useMemberProfile, useUserProfile } from "../../../query/users/queries";
import { Form } from "./Form";
import { useMutationSubmit } from "./useMutationSubmit";

type Props = {
  onSuccess?: (debitCard: ICheckbookCard) => void;
  onBack?: () => void;
  setInstantPayoutPreference?: boolean;
  withPanel?: boolean;
} & WSElementProps;

export const FormAddDebitCard: React.FC<Props> = ({
  onSuccess,
  setInstantPayoutPreference,
  onBack,
  withPanel,
  ...elementProps
}) => {
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const [submit, submitMeta] = useMutationSubmit(
    { setInstantPayoutPreference },
    { onSuccess }
  );

  return (
    <WSElement {...elementProps}>
      <WSQueries
        queries={{
          queryUser,
          queryMember
        }}
      >
        {({ queryUser: { data: user }, queryMember: { data: member } }) => (
          <Form
            user={user}
            member={member}
            onSubmit={submit}
            error={submitMeta.error}
            isLoading={submitMeta.isLoading}
            onBack={onBack}
            withPanel={withPanel}
          />
        )}
      </WSQueries>
    </WSElement>
  );
};
