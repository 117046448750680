import { ICustomField } from "@wingspanhq/payments/dist/interfaces";
import { QueryConfig } from "react-query";
import { customFieldsService } from "../../../services/customFields";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { QUERY_CUSTOM_FIELD } from "../keys";

export const useCustomField = (
  id: string,
  config?: QueryConfig<ICustomField, WSServiceError>
) => {
  return useWSQuery<ICustomField, WSServiceError>(
    [QUERY_CUSTOM_FIELD, id],
    async () => await customFieldsService.customField.get(id),
    {
      refetchOnMount: false,
      ...config
    }
  );
};
