import {
  WSButtonProps,
  WSElement,
  WSLoader
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";

type Props = {
  isDefault?: boolean;
  onSetDefault: () => void;
  isLoading?: boolean;
} & WSButtonProps;

export const DefaultButton: React.FC<Props> = ({
  isDefault,
  onSetDefault,
  isLoading,
  ...elementProps
}) => (
  <WSElement
    {...elementProps}
    data-testid="setAsDefaultButton"
    as="button"
    onClick={isDefault ? undefined : onSetDefault}
    className={classNames(styles.button, { [styles.default]: isDefault })}
    {...{ disabled: isDefault }}
  >
    {isLoading ? (
      <WSLoader.Spinner size="XS" />
    ) : isDefault ? (
      "Default"
    ) : (
      "Set as default"
    )}
  </WSElement>
);
