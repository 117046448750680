import React from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { WSText } from "@wingspanhq/fe-component-library";
import { EditLayout } from "../../../components/EditLayout";
import { FormPayerInformation } from "../../../components/FormPayerInformation";

export const RouteEditPayerInformation: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  return (
    <EditLayout title="Payer information" onBack={onBack}>
      <WSText.Heading4>Edit payer information</WSText.Heading4>

      <WSText mt="M">
        Does this information look right? If you make changes here, they will
        appear on your company profile and 1099-NEC forms.
      </WSText>

      <FormPayerInformation onNext={onNext} onBack={onBack} isEdit />
    </EditLayout>
  );
};
