import {
  WSPill,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSLoader,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  AccountStatus,
  AccountUsage,
  IAccount
} from "@wingspanhq/users/dist/lib/interfaces";
import cn from "classnames";
import React, { useState } from "react";
import { selectorAccountName } from "../../shared/selectors/selectorAccountName";
import { selectorAccountVerificationPending } from "../../shared/selectors/selectorAccountVerificationPending";
import { PlaidReAuth } from "../PlaidReAuth/PlaidReAuth";
import { useModalVerifyAccount } from "./ModalVerifyAccount";
import { WSAccountsListV2Props } from "./WSAccountsListV2.component";
import styles from "./WSAccountsListV2.module.scss";
import { PaymentMethodType } from "./types";

export interface AccountTemplateProps extends Partial<WSAccountsListV2Props> {
  account: IAccount;
}

export const AccountTemplate: React.FC<AccountTemplateProps> = ({
  onEditAccount,
  onDeleteAccount,
  account,
  defaultPaymentMethod,
  isSingleAccountPreview
}) => {
  const modalVerifyAccount = useModalVerifyAccount();
  const [showSuccessfullyVerified, setShowSuccessfullyVerified] = useState(
    false
  );

  return (
    <WSElement
      onClick={
        onEditAccount && account.status !== AccountStatus.Disconnected
          ? () => onEditAccount(account, PaymentMethodType.BANK_ACCOUNT)
          : undefined
      }
      data-testid={`account-block-${account.accountId}`}
    >
      <WSFlexBox.CenterY
        ml="3XL"
        justify="space-between"
        wrap="nowrap"
        className={cn(styles.account, {
          [styles.hasSingleAccount]: isSingleAccountPreview
        })}
      >
        <WSText.ParagraphSm>{selectorAccountName(account)}</WSText.ParagraphSm>
        <WSFlexBox.CenterY wrap="nowrap">
          {account.usage === AccountUsage.Business && (
            <WSPill mx="M" text="Business" />
          )}
          {defaultPaymentMethod?.accountId === account.accountId && (
            <WSPill mx="M" text="Default" />
          )}
          <PlaidReAuth accountId={account.accountId}>
            {({ onOpen, status, loading }) =>
              status && (
                <WSElement mx="M" onClick={loading ? undefined : onOpen}>
                  {loading ? (
                    <WSLoader.Spinner size="S" />
                  ) : (
                    <WSText
                      weight="medium"
                      data-testid={`reconnectAccount${account.accountId}`}
                      color="red500"
                    >
                      Reconnect
                    </WSText>
                  )}
                </WSElement>
              )
            }
          </PlaidReAuth>
          {account.status === AccountStatus.Disconnected && onDeleteAccount && (
            <WSIcon
              block
              mx="M"
              size="S"
              color="blue400"
              name="trash"
              onClick={() => onDeleteAccount?.(account, defaultPaymentMethod)}
            />
          )}
          {onEditAccount && account.status !== AccountStatus.Disconnected && (
            <WSIcon block mx="M" size="S" color="blue400" name="edit" />
          )}
        </WSFlexBox.CenterY>
      </WSFlexBox.CenterY>

      {showSuccessfullyVerified ? (
        <WSMessageBox.Success
          icon="check"
          title="Account verified"
          onClose={() => {
            setShowSuccessfullyVerified(false);
          }}
        >
          You’re all set! The payment account was verified
        </WSMessageBox.Success>
      ) : (
        selectorAccountVerificationPending(account) && (
          <WSMessageBox.Warning
            icon="alert-circle"
            title="Action required"
            button={{
              kind: "Link",
              children: "Verify account",
              rightIcon: "chevron-right",
              onClick: () => {
                modalVerifyAccount.open({
                  account,
                  onSuccess: () => {
                    setShowSuccessfullyVerified(true);
                  }
                });
              }
            }}
          >
            Wingspan sent micro-deposits to your account ending in •••
            {account.numbers.account?.slice(-4)}. Enter the amounts deposited to
            verify your account.{" "}
          </WSMessageBox.Warning>
        )
      )}

      {isSingleAccountPreview ? null : <WSDivider />}
    </WSElement>
  );
};
