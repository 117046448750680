import { WSElement, WSElementProps } from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import styles from "./index.module.scss";

type Props = WSElementProps;

export const VerticalDivider: React.FC<Props> = props => (
  <WSElement
    {...props}
    className={classNames(styles.verticalDivider, props.className)}
  />
);
