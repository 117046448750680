export const toggleValues = (values: string[], ...options: string[]) => {
  const filteredValues = values.filter((value) => !options.includes(value));
  const isAdding = options.some((option) => !values.includes(option));

  if (isAdding) {
    return [...filteredValues, ...options];
  } else {
    return filteredValues;
  }
};
