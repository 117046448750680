import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { EditLayout } from "../../components/EditLayout";
import { OverlaySpinner } from "../../../../components/OverlaySpinner";
import { track } from "../../../../utils/analytics";
import { useCreateBulkCalculate1099Batch } from "../../../BulkImporter/query/bulkCalculation1099/mutations";
import { CURRENT_YEAR } from "../../constants/currentYear";

export const RouteInitGenerate1099NECAmounts: React.FC = () => {
  const history = useHistory<{
    canSkip?: boolean;
  }>();
  const [createBulk1099CalculateBatch] = useCreateBulkCalculate1099Batch();

  useEffect(() => {
    createBulk1099CalculateBatch(
      {
        labels: {
          filingYear: `${CURRENT_YEAR}`
        }
      },
      {
        onSuccess: bulkBatch => {
          track("1099 Filing Bulk 1099 Calculation Started");
          history.replace({
            pathname: `/member/1099-filing/generate-1099-nec-amounts/${bulkBatch.bulkCalculation1099BatchId}`,
            state: history.location.state || {}
          });
        }
      }
    );
  }, []);

  return (
    <EditLayout title="Generate amounts and statuses">
      <OverlaySpinner />
    </EditLayout>
  );
};
