import React from "react";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksAccountExpenses,
  useIntegrationsQuickbooksVendors
} from "../../../../query/integrations/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { IntegrationsSection } from "./index";
import { IntegrationResourceType } from "./types";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import {
  WSLoader,
  WSPanel,
  WSSectionToolbar
} from "@wingspanhq/fe-component-library";
import { selectorIsQBOActive } from "../../../../shared/selectors/selectorIsQBOActive";

type IntegrationWidgetProps = {
  isInactive: boolean;
  payeeId: string;
  vendorId?: string;
  expenseAccountId?: string;
  engagementId?: string;
};

export const PayeeIntegrationWidget: React.FC<IntegrationWidgetProps> = ({
  payeeId,
  isInactive,
  engagementId,
  vendorId,
  expenseAccountId
}) => {
  const qFeatureFlags = useFeatureFlags();
  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();

  const isQuickbooksActive = selectorIsQBOActive(
    qFeatureFlags.data,
    qIntegrationsQuickbooks.data
  );

  const qIntegrationsQuickbooksVendors = useIntegrationsQuickbooksVendors({
    enabled: isQuickbooksActive
  });
  const qIntegrationsQuickbooksExpenseAccounts = useIntegrationsQuickbooksAccountExpenses(
    {
      enabled: isQuickbooksActive
    }
  );

  if (!isQuickbooksActive) {
    return null;
  }

  return (
    <WSQueries
      renderErrors={() => null}
      renderLoader={() => (
        <>
          <WSSectionToolbar
            title="Integration mappings"
            button={{
              label: "Edit",
              disabled: true
            }}
          />

          <WSPanel mb="XL">
            <WSLoader.Spinner size="M" />
          </WSPanel>
        </>
      )}
      queries={{
        qIntegrationsQuickbooksVendors,
        qIntegrationsQuickbooksExpenseAccounts
      }}
    >
      {({
        qIntegrationsQuickbooksVendorsData,
        qIntegrationsQuickbooksExpenseAccountsData
      }) => {
        const mappedVendor = qIntegrationsQuickbooksVendorsData.find(
          vendor => vendorId === vendor.vendorId
        );
        const mappedExpenseAccount = qIntegrationsQuickbooksExpenseAccountsData.find(
          acct => acct.accountId === expenseAccountId
        );

        const defaultAccount = qIntegrationsQuickbooksExpenseAccountsData.find(
          acct =>
            acct.accountId ===
            qIntegrationsQuickbooks.data?.defaults?.expenseAccountId
        );

        return isQuickbooksActive ? (
          <IntegrationsSection
            isInactive={isInactive}
            resourceType={IntegrationResourceType.Payee}
            payeeIntegration={{
              payeeId,
              engagementId,
              vendor: mappedVendor,
              expenseAccount: mappedExpenseAccount,
              defaultAccount
            }}
          />
        ) : null;
      }}
    </WSQueries>
  );
};
