import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";

export const selectorPayeeTaxFormPayerContact = (
  taxForm: IPayeeTaxFormResponse
) => {
  let payerName = "";

  if (
    taxForm.payer?.user.profile?.firstName &&
    taxForm.payer?.user.profile?.lastName
  ) {
    payerName = `${taxForm.payer.user.profile.firstName} ${taxForm.payer.user.profile.lastName}`;
  } else if (taxForm.payer?.profile?.company?.supportEmail) {
    payerName = taxForm.payer.profile.company.supportEmail;
  } else if (taxForm.payer?.user.email) {
    payerName = taxForm.payer.user.email;
  }

  let supportEmail =
    taxForm.payer?.profile?.company?.supportEmail ||
    taxForm.payer?.user.email ||
    "";

  return [payerName, supportEmail] as const;
};
