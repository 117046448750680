import {
  IEngagement,
  IEngagementUpdateRequest
} from "@wingspanhq/payments/dist/interfaces";
import { servicePayments } from "../servicePayments";

export const updateEngagement = async (
  id: string,
  request: IEngagementUpdateRequest
): Promise<IEngagement> => {
  const response = await servicePayments.patch(`/engagement/${id}`, request);
  return response.data;
};
