import { WSButton, WSElement, WSPanel } from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import { useBankingAccount } from "../../../../query/bookkeeping/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import { useQueryPaymentsCardsDebit } from "../../../../query/paymentCards/queries/useQueryPaymentsCardsDebit";
import {
  usePayoutSettings,
  usePayoutSettingsDebitCards
} from "../../../../query/payments/queries";
import { useAccounts, useMemberProfile } from "../../../../query/users/queries";
import { LayoutFullscreen } from "../../../../shared/components/LayoutFullscreen";
import { selectorIsDomesticMember } from "../../../../shared/selectors/selectorIsDomesticMember";
import { InstantMethods } from "../../components/InstantMethods";
import { InternationalMethods } from "../../components/InternationalMethods";
import { StandardMethods } from "../../components/StandardMethods";

export const RouteEdit: React.FC<RouteComponentProps> = () => {
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryBankingAccount = useBankingAccount();
  const queryAccounts = useAccounts();
  const queryPayoutSettingsDebitCards = usePayoutSettingsDebitCards(userId);
  const queryPaymentCards = useQueryPaymentsCardsDebit();
  const queryMember = useMemberProfile(userId);
  const history = useHistory();

  return (
    <LayoutFullscreen
      headTitle="Edit payout methods"
      title="Edit payout methods"
      description="Add a new payout method or set an existing payout method as default"
      onBack={() => {
        history.push("/member/settings/payment-methods");
      }}
    >
      <WSQueries
        queries={{
          queryPayoutSettings,
          queryAccounts,
          queryPayoutSettingsDebitCards,
          queryMember,
          queryPaymentCards
        }}
      >
        {({
          queryPayoutSettings: { data: payoutSettings },
          queryAccounts: { data: accounts },
          queryPayoutSettingsDebitCards: { data: debitCards },
          queryMember: { data: member },
          queryPaymentCards: { data: paymentCards }
        }) => {
          const isDomesticMember = selectorIsDomesticMember(member);

          return (
            <WSElement>
              {isDomesticMember ? (
                <>
                  <StandardMethods
                    payoutSettings={payoutSettings}
                    internalAccount={queryBankingAccount.data}
                    accounts={accounts}
                    mb="XL"
                  />
                  <InstantMethods
                    payoutSettings={payoutSettings}
                    accounts={accounts}
                    debitCards={debitCards}
                    paymentCards={paymentCards}
                  />
                </>
              ) : (
                <>
                  <StandardMethods
                    showInternationalTitle
                    payoutSettings={payoutSettings}
                    accounts={accounts}
                    mb="XL"
                  />
                  <InternationalMethods
                    payoutSettings={payoutSettings}
                    accounts={accounts}
                  />
                </>
              )}

              <WSPanel
                mt="2XL"
                mb="XL"
                onClick={() => {
                  if (isDomesticMember) {
                    history.push("/member/settings/payment-methods/add");
                  } else {
                    history.push(
                      "/member/settings/payment-methods/add-international"
                    );
                  }
                }}
              >
                <WSButton.Link icon="plus-circle">
                  Add new payout method
                </WSButton.Link>
              </WSPanel>

              <WSButton
                fullWidth
                onClick={() => {
                  history.push("/member/settings/payment-methods");
                }}
              >
                Save changes
              </WSButton>
            </WSElement>
          );
        }}
      </WSQueries>
    </LayoutFullscreen>
  );
};
