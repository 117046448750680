import {
  WSButton,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { Timeline } from "./Timeline";
import React, { useState } from "react";
import { getTimeLineList } from "./getTimelineItem";
import { Documents } from "./Documents";

type Props = {
  taxForm: IPayerTaxFormResponse;
} & WSElementProps;

export const HistorySection: React.FC<Props> = ({
  taxForm,
  ...elementProps
}) => {
  const timelineItems = getTimeLineList(taxForm);
  const isDocumentsExist = !!taxForm.submissions?.length;
  const [showDocuments, setShowDocuments] = useState(false);

  return (
    <>
      <WSElement pt="L" pb="M" />
      <WSFlexBox.CenterY wrap="nowrap" justify="space-between" mt="XS" mb="M">
        <WSText.Heading4>History</WSText.Heading4>
        {isDocumentsExist && (
          <WSButton.Link
            size="S"
            onClick={() => {
              setShowDocuments(!showDocuments);
            }}
          >
            {showDocuments ? "View all history" : "View all documents"}
          </WSButton.Link>
        )}
      </WSFlexBox.CenterY>

      <WSDivider my="M" />
      {showDocuments ? (
        <Documents taxForm={taxForm} />
      ) : (
        <Timeline items={timelineItems} />
      )}
    </>
  );
};
