import { SessionType } from "@wingspanhq/users/dist/lib/interfaces";
import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { OptVerification } from "../components/OptVerification";
import { useUserId } from "../query/hooks/helpers";
import { useFeatureFlags } from "../query/hooks/useFeatureFlags";
import { useSession } from "../query/session";
import { useCreateClient, useCreateMember } from "../query/users/mutations";
import { useClientQuery, useMemberProfile } from "../query/users/queries";
import { paymentsService } from "../services/payments";
import { ClientSignInModal } from "./components/ClientSignInModal/ClientSignInModal";
import { ClientPaymentsInvoiceDetails } from "./screens/ClientPaymentsInvoiceDetails";
import { ClientPaymentsInvoicePaymentACH } from "./screens/ClientPaymentsInvoicePaymentACH";
import { ClientPaymentsInvoicePaymentCreditCard } from "./screens/ClientPaymentsInvoicePaymentCreditCard";
import { ClientPaymentsInvoicePaymentCreditCardFootprint } from "./screens/ClientPaymentsInvoicePaymentCreditCardFootprint";
import { ClientPaymentsInvoicePaymentManual } from "./screens/ClientPaymentsInvoicePaymentManual";
import { ClientPaymentsInvoicePaymentSuccess } from "./screens/ClientPaymentsInvoicePaymentSuccess";
import { ClientPaymentsInvoicePaymentType } from "./screens/ClientPaymentsInvoicePaymentType";
import { ClientPaymentsPPL } from "./screens/ClientPaymentsPPL";
import { RoutePasswordReset } from "./screens/RoutePasswordReset";
import { ProtectedRoutes } from "./screens/protected";

export const ClientPayments: React.FC = () => {
  const sessionQuery = useSession();
  const userId = useUserId();
  const clientQuery = useClientQuery(userId, {
    enabled: sessionQuery.data?.sessionType === SessionType.user
  });
  const memberQuery = useMemberProfile(userId, {
    enabled: sessionQuery.data?.sessionType === SessionType.user
  });
  const [createClient] = useCreateClient(userId);
  const [createMember] = useCreateMember(userId);
  const queryFeatureFlags = useFeatureFlags();

  useEffect(() => {
    if (sessionQuery.data?.sessionType === SessionType.user) {
      // Handle client creation
      if (clientQuery.isIdle) {
        clientQuery.refetch();
      }

      if (clientQuery.isFetched) {
        if (!clientQuery.data && clientQuery.error?.response?.status === 400) {
          createClient({ userId });
        }
      }

      // Handle member creation
      if (memberQuery.isIdle) {
        memberQuery.refetch();
      }

      if (memberQuery.isFetched) {
        if (!memberQuery.data && memberQuery.error?.response?.status === 400) {
          createMember(
            { userId },
            {
              onSuccess: () => {
                // Get payment serivce to create record in db
                paymentsService.service.get();
              }
            }
          );
        }
      }
    }
  }, [
    clientQuery,
    createClient,
    createMember,
    memberQuery,
    sessionQuery,
    userId
  ]);

  return (
    <>
      <ClientSignInModal />
      <OptVerification />
      <Switch>
        <Route
          path={[
            "/payment/history",
            "/payment/account",
            "/payment/add-bank-account-manually"
          ]}
          component={ProtectedRoutes}
        />

        <Route
          exact
          path="/payment/reset-password"
          component={RoutePasswordReset}
        />

        <Route exact path="/payment/ppl/:tag" component={ClientPaymentsPPL} />
        <Route
          exact
          path="/payment/:invoiceId"
          component={ClientPaymentsInvoiceDetails}
        />
        <Route
          exact
          path="/payment/:invoiceId/2"
          component={ClientPaymentsInvoicePaymentType}
        />
        <Redirect
          exact
          path="/payment/:invoiceId/3"
          to="/payment/:invoiceId/2"
        />
        <Route
          exact
          path="/payment/:invoiceId/3/ach"
          component={ClientPaymentsInvoicePaymentACH}
        />
        <Route
          exact
          path="/payment/:invoiceId/3/creditCard"
          component={
            queryFeatureFlags.data?.paymentCards
              ? ClientPaymentsInvoicePaymentCreditCardFootprint
              : ClientPaymentsInvoicePaymentCreditCard
          }
        />
        <Route
          exact
          path="/payment/:invoiceId/3/manual"
          component={ClientPaymentsInvoicePaymentManual}
        />
        <Route
          exact
          path="/payment/:invoiceId/success"
          component={ClientPaymentsInvoicePaymentSuccess}
        />
      </Switch>
    </>
  );
};
