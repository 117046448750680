import React, { useEffect, useState } from "react";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import styles from "./WSCopyText.module.scss";
import { WSButton } from "../../core/WSButton/WSButton.component";
import { WSText } from "../../core/WSText/WSText.component";
import { useIsDesktop } from "../../layout/WSScreen/WSScreen.component";

export interface WSCopyTextProps extends WSElementProps {
  data: string;
  shareTitle: string;
  isURL: boolean;
  onCopy?: () => void;
  onShare?: () => void;
  onSuccess?: () => void;
}

export const copyText = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch {
    window.prompt(
      "Oops! Looks like something went wrong.\nPlease copy by hand:",
      text
    );
  }
};

export const shareText = async (
  isURL: boolean,
  data: string,
  title: string
) => {
  try {
    await window.navigator.share({
      title,
      text: isURL ? undefined : data,
      url: isURL ? data : undefined
    });
  } catch {
    await copyText(data);
  }
};

export const WSCopyText: React.FC<WSCopyTextProps> = ({
  data,
  onCopy,
  onShare,
  isURL,
  shareTitle = "Share",
  children,
  onSuccess,
  ...other
}) => {
  const [isDone, setIsDone] = useState(false);
  const isDesktop = useIsDesktop();
  const isSharing = !isDesktop && !!window.navigator.share;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isDone) {
      const timer = setTimeout(() => {
        setIsDone(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isDone]);

  const onClick = async () => {
    if (isSharing) {
      await shareText(isURL, data, shareTitle);
      setIsDone(true);
      onShare?.();
    } else {
      await copyText(data);
      setIsDone(true);
      onCopy?.();
    }
    onSuccess?.();
  };

  return (
    <WSElement className={styles.container} {...other}>
      <WSElement className={styles.body}>
        <WSText.ParagraphSm className={styles.text}>{data}</WSText.ParagraphSm>
      </WSElement>
      <WSButton
        size="S"
        onClick={onClick}
        className={styles.copyBtn}
        disabled={isDone}
        type="button"
        icon={isDone ? "check" : "link"}
      />
    </WSElement>
  );
};
