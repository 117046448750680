import {
  WSButton,
  WSCheckboxToggle,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { IClientInvoice } from "@wingspanhq/payments/dist/interfaces";
import React, { ReactNode } from "react";
import { getRedactedMemberName } from "../../../query/users/selectors";
import { WS_LINKS } from "../../../types/wsLinks";
import { openInNewTab } from "../../../utils/openInNewTab";
import { getIsAutopayRequired } from "../../utils";
import { Card } from "../Card/Card";

type Props = {
  clientInvoice: IClientInvoice;
  submitSection: ReactNode;
  agreementValue: boolean;
  onAgreemenChange: (newValue: boolean) => void;
} & WSElementProps;

export const AutopayCard: React.FC<Props> = ({
  clientInvoice,
  submitSection,
  agreementValue,
  onAgreemenChange,
  ...elementProps
}) => (
  <Card {...elementProps}>
    <WSText mb="M">
      Easily automate your{" "}
      {getRedactedMemberName(clientInvoice.memberClient.member)} invoice
      payments with our Autopay feature.
    </WSText>
    <WSText mb="XL">
      By enrolling, you authorize us to securely charge your payment method for
      each invoice, ensuring timely and hassle-free transactions. Review the
      terms, provide your consent, and let Autopay manage the rest.
    </WSText>

    <WSCheckboxToggle
      name="consentAutopay"
      value={agreementValue}
      label={`I agree to enroll in Autopay for ${getRedactedMemberName(
        clientInvoice.memberClient.member
      )}`}
      disabled={getIsAutopayRequired(clientInvoice)}
      onChange={onAgreemenChange}
    />

    {submitSection}

    <WSText.ParagraphXs color="gray500" mt="XL">
      You can always turn off autopay:{" "}
      <WSButton.Link
        size="S"
        onClick={() => openInNewTab(WS_LINKS.autopayHelpGuide)}
      >
        Autopay Help Guide
      </WSButton.Link>
    </WSText.ParagraphXs>
  </Card>
);
