import { CardBrand } from "@wingspanhq/payments/dist/interfaces";
import amexLogo from "./logos/amex.svg";
import discoverLogo from "./logos/discover.svg";
import jcbLogo from "./logos/jcb.svg";
import mastercardLogo from "./logos/mastercard.svg";
import unionPayLogo from "./logos/unionpay.svg";
import visaLogo from "./logos/visa.svg";
import { LogoData } from "./types";

export const creditCardLogoMap: {
  [key: string]: LogoData | undefined;
} = {
  "american express": {
    src: amexLogo,
    alt: "American Express Logo"
  },
  discover: {
    src: discoverLogo,
    alt: "Discover Logo"
  },
  jcb: {
    src: jcbLogo,
    alt: "JCB Logo"
  },
  mastercard: {
    src: mastercardLogo,
    alt: "MasterCard Logo"
  },
  unionpay: {
    src: unionPayLogo,
    alt: "UnionPay Logo"
  },
  visa: {
    src: visaLogo,
    alt: "Visa Logo"
  }
};

export const debitCardLogoMap: {
  [key: string]: LogoData | undefined;
} = {
  [CardBrand.AmericanExpress]: {
    src: amexLogo,
    alt: "American Express Logo"
  },
  [CardBrand.Discover]: {
    src: discoverLogo,
    alt: "Discover Logo"
  },
  [CardBrand.DinersClub]: undefined,
  [CardBrand.JCB]: {
    src: jcbLogo,
    alt: "JCB Logo"
  },
  [CardBrand.Mastercard]: {
    src: mastercardLogo,
    alt: "MasterCard Logo"
  },
  [CardBrand.Visa]: {
    src: visaLogo,
    alt: "Visa Logo"
  }
};
