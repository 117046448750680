import {
  detachLayoutProps,
  WSElement,
  WSEmptyState,
  WSFileInput,
  WSFlexBox,
  WSForm,
  WSIcon,
  WSInputText,
  WSList,
  WSMarginProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { ChangeEvent, useImperativeHandle, useState } from "react";
import * as Yup from "yup";

import styles from "./styles.module.scss";
import { useMemberPublicCreate } from "../../../../query/files/mutations";
import { useUpdateMemberProfile } from "../../../../query/users/mutations";
import { IMemberUpdateRequest } from "@wingspanhq/users/dist/lib/interfaces";
import { useUserId } from "../../../../query/hooks/helpers";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";

interface FormBusinessProfileValues {
  logoFileId?: string;
  supportEmail?: string;
  websiteUrl?: string;
  linkedInUrl?: string;
  instagramUrl?: string;
  xUrl?: string;
  otherUrl?: string;
}

export interface FormBusinessProfileProps extends WSMarginProps {
  setLoading?: (loading: boolean) => void;
  onSuccess?: () => void;
}
export type FormBusinessProfileRef =
  // Define any custom props/methods to expose via ref
  HTMLFormElement | null;
function _FormBusinessProfile(
  props: FormBusinessProfileProps,
  ref: React.Ref<FormBusinessProfileRef>
) {
  const { setLoading, onSuccess, ...otherProps } = props;
  const userId = useUserId();
  const businessProfileFormRef = React.useRef<HTMLFormElement>(null);
  const { layoutProps } = detachLayoutProps(otherProps);
  const [fileToUpload, setFileToUpload] = useState<File>();

  const [uploadFile, uploadFileMeta] = useMemberPublicCreate();
  const [
    updateMemberProfile,
    updateMemberProfileMeta
  ] = useUpdateMemberProfile();

  useImperativeHandle(
    ref,
    () => {
      return {
        requestSubmit: () => {
          businessProfileFormRef.current?.requestSubmit();
        }
      } as FormBusinessProfileRef;
    },
    []
  );

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    uploadFileMeta.reset();
    if (event.target && event.target.files && event.target.files) {
      const logoFile = event.target.files[0];
      setFileToUpload(logoFile);
    }
  };

  const onSubmit = async (data: FormBusinessProfileValues) => {
    uploadFileMeta.reset();
    updateMemberProfileMeta.reset();
    setLoading?.(true);
    const fileFormData = new FormData();
    let uploadResponse;
    if (fileToUpload) {
      fileFormData.append("file", fileToUpload);
      uploadResponse = await uploadFile(fileFormData);
    }
    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        logoFileId: uploadResponse?.[0]?.fileId,
        company: {
          supportEmail: data.supportEmail || undefined,
          website: data.websiteUrl || undefined,
          links: {
            websiteUrl: data.websiteUrl || undefined,
            linkedInUrl: data.linkedInUrl || undefined,
            instagramUrl: data.instagramUrl || undefined,
            twitterUrl: data.xUrl || undefined,
            otherUrl: data.otherUrl || undefined
          }
        }
      }
    };
    await updateMemberProfile(request, {
      onSuccess: () => {
        onSuccess?.();
      }
    });
    setLoading?.(false);
  };

  return (
    <WSElement className={styles.container} {...layoutProps}>
      <WSForm<FormBusinessProfileValues>
        ref={businessProfileFormRef}
        defaultValues={{
          logoFileId: "",
          supportEmail: "",
          websiteUrl: "",
          linkedInUrl: "",
          instagramUrl: "",
          xUrl: "",
          otherUrl: ""
        }}
        validationSchema={Yup.object().shape({
          logoFileId: Yup.string(),
          supportEmail: Yup.string().email("Invalid email"),
          websiteUrl: Yup.string().url("Invalid URL"),
          linkedInUrl: Yup.string().url("Invalid URL"),
          instagramUrl: Yup.string().url("Invalid URL"),
          xUrl: Yup.string().url("Invalid URL"),
          otherUrl: Yup.string().url("Invalid URL")
        })}
        onSubmit={onSubmit}
      >
        <WSList gap="XL">
          <WSElement>
            <WSText.ParagraphSm mb="S" color="gray500">
              Logo
            </WSText.ParagraphSm>
            {fileToUpload ? (
              <WSElement mb="XL" p="XL" className={styles.selectedFile}>
                <WSFlexBox.CenterY wrap="nowrap">
                  <WSText className={styles.selectedFileName} singleLine>
                    {fileToUpload.name}
                  </WSText>
                  <WSIcon
                    block
                    color="gray500"
                    size="M"
                    mx="M"
                    name="exit"
                    onClick={() => {
                      setFileToUpload(undefined);
                      uploadFileMeta.reset();
                    }}
                  />
                </WSFlexBox.CenterY>
              </WSElement>
            ) : (
              <WSFileInput name="logoFileId" onChange={onFileChange}>
                <WSEmptyState
                  type="images"
                  title="No logo yet"
                  description="Only PNG/JPG formats supported. Images with 120px height works best. If your image is too large, we'll try to resize it for you."
                  orientation="horizontal"
                  size="M"
                  hasBorder
                  buttons={[
                    {
                      className: styles.addLogoBtn,
                      label: "Add logo",
                      kind: "Secondary",
                      type: "button"
                    }
                  ]}
                />
              </WSFileInput>
            )}
          </WSElement>

          <WSForm.Field
            name="supportEmail"
            component={WSInputText}
            label="Support Email (optional)"
            componentProps={{
              helperText:
                "The business support email that contractors can reach for help",
              placeholder: "Enter support email"
            }}
          />

          <WSForm.Field
            name="websiteUrl"
            component={WSInputText}
            label="Business website URL (optional)"
            componentProps={{
              placeholder: "https://"
            }}
          />

          <WSForm.Field
            name="linkedInUrl"
            component={WSInputText}
            label="LinkedIn URL (optional)"
            componentProps={{
              placeholder: "https://"
            }}
          />

          <WSForm.Field
            name="instagramUrl"
            component={WSInputText}
            label="Instagram URL (optional)"
            componentProps={{
              placeholder: "https://"
            }}
          />

          <WSForm.Field
            name="xUrl"
            component={WSInputText}
            label="X URL (optional)"
            componentProps={{
              placeholder: "https://"
            }}
          />

          <WSForm.Field
            name="otherUrl"
            component={WSInputText}
            label="Other websites (optional)"
            componentProps={{
              helperText:
                "Include any websites, help articles, or resources that would benefit your contractors",
              placeholder: "https://"
            }}
          />

          <WSErrorMessage
            contextKey={ErrorContextKey.OnboardingBusinessProfile}
            error={uploadFileMeta.error || updateMemberProfileMeta.error}
          />
        </WSList>
      </WSForm>
    </WSElement>
  );
}

export const FormBusinessProfile = React.forwardRef<
  FormBusinessProfileRef,
  FormBusinessProfileProps
>(_FormBusinessProfile);
