import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces";

export const SELECT_OPTIONS_BUSINESS_TYPE = [
  {
    label: "Sole proprietorship",
    value: CompanyStructure.SoleProprietorship
  },
  {
    label: "S-corp",
    value: CompanyStructure.CorporationS
  },
  {
    label: "C-corp",
    value: CompanyStructure.CorporationC
  },
  {
    label: "Partnership",
    value: CompanyStructure.Partnership
  },
  {
    label: "LLC (single member)",
    value: CompanyStructure.LlcSingleMember
  },
  {
    label: "LLC (Taxed as S-corp)",
    value: CompanyStructure.LLCCorporationS
  },
  {
    label: "LLC (Taxed as C-corp)",
    value: CompanyStructure.LLCCorporationC
  },
  {
    label: "LLC (Taxed as Partnership)",
    value: CompanyStructure.LLCPartnership
  }
];

export const SELECT_OPTIONS_BUSINESS_TYPE_SSN = [
  {
    label: "Sole proprietorship",
    value: CompanyStructure.SoleProprietorship
  },
  {
    label: "LLC (single member)",
    value: CompanyStructure.LlcSingleMember
  }
];
