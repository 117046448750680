import React from "react";
import {
  useWSModal,
  WSButton,
  WSButtons,
  WSElement,
  WSText
} from "@wingspanhq/fe-component-library";

const ModalBeforeEdit: React.FC<{
  onClose(result: boolean): void;
}> = ({ onClose }) => {
  return (
    <WSElement mt="XS">
      <WSText color="gray600">
        Automatic updates will be disabled for this 1099 after you make edits.
        You can always undo your edits to resume automatic updates later.
      </WSText>

      <WSButtons mt="2XL" forceFullWidth>
        <WSButton.Primary
          onClick={() => {
            onClose(true);
          }}
        >
          Confirm
        </WSButton.Primary>
        <WSButton.Secondary onClick={() => onClose(false)}>
          Cancel
        </WSButton.Secondary>
      </WSButtons>
    </WSElement>
  );
};

export const useModalBeforeEdit = () =>
  useWSModal(ModalBeforeEdit, {
    title: "Editing will disable automatic updates",
    size: "S"
  });
