import {
  WSElementProps,
  WSMessageBox,
  WSMessageBoxProps
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React from "react";
import styles from "./StaticBanner.module.scss";

type Props = WSElementProps &
  WSMessageBoxProps & {
    isVisible: boolean;
    name: string;
  };

export const StaticBanner: React.FC<Props> = ({
  isVisible,
  name,
  className,
  ...elementProps
}) =>
  isVisible ? (
    <WSMessageBox
      key={name}
      className={classNames(styles.staticBanner, className)}
      centered
      {...elementProps}
    />
  ) : null;
