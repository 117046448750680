import getCurrentFilingYear from "./getCurrentFilingYear";

const FIRST_FILING_YEAR = 2019;

const is2And3Digits = (value: string) => /^2\d{3}$/.test(value);

function isFilingYear(yearStr: string) {
  if (!is2And3Digits(yearStr)) return false;

  const year = parseInt(yearStr);

  return year >= FIRST_FILING_YEAR && year <= getCurrentFilingYear()
    ? true
    : false;
}

export default isFilingYear;
