import { WSTableCell } from "@wingspanhq/fe-component-library";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";

export const NameCell: React.FC<{ taxForm: IPayerTaxFormResponse }> = ({
  taxForm
}) => {
  const externalId = taxForm["memberExternalId"];
  return (
    <WSTableCell
      truncationOptions={{
        text: {
          allowTwoLines: true
        }
      }}
      text={selectorTaxFormRecipientName(taxForm) || "-"}
      secondaryText={externalId}
    />
  );
};
