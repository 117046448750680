import {
  useModalContext,
  WSButton,
  WSFormOld,
  WSInputMaskOld,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { nonSherpaLinksByState } from "../../constants/statesWithoutHealthSherpa";
import { useUserId } from "../../query/hooks/helpers";
import { useUpdateMemberProfile } from "../../query/users/mutations";
import { openInNewTab } from "../../utils/openInNewTab";
import { validatorZipCode } from "../../utils/validators";
import { createHealthSherpaLink } from "../utils";

export const BENEFITS_ADD_ZIP_CODE_MODAL = "BENEFITS_ADD_ZIP_CODE_MODAL";

export const BenefitsAddZipCode: React.FC = () => {
  const memberId = useUserId();
  const [
    updateMemberProfile,
    updateMemberProfileMeta
  ] = useUpdateMemberProfile();

  const { closeModal } = useModalContext();

  return (
    <WSModal
      name={BENEFITS_ADD_ZIP_CODE_MODAL}
      size="S"
      title="Zip code is required"
    >
      <WSText mt="M">Please enter your zip code</WSText>
      <WSFormOld
        onSubmit={async formData => {
          const member = await updateMemberProfile(
            {
              memberId,
              profile: {
                address: {
                  country: "US",
                  postalCode: formData.zipCode
                }
              }
            },
            {
              throwOnError: true
            }
          );

          const state = member?.profile.address?.state as string;
          const zipCode = member?.profile.address?.postalCode as string;

          if (state && zipCode) {
            if (nonSherpaLinksByState[state]) {
              openInNewTab(nonSherpaLinksByState[state]);
            } else {
              openInNewTab(createHealthSherpaLink(zipCode));
            }
          }

          closeModal(BENEFITS_ADD_ZIP_CODE_MODAL);
        }}
        defaultValues={{
          zipCode: ""
        }}
        validationSchema={Yup.object().shape({
          zipCode: validatorZipCode
        })}
      >
        <WSFormOld.Field
          mt="M"
          name="zipCode"
          component={WSInputMaskOld}
          componentProps={{
            placeholder: "Zip",
            mask: "99999"
          }}
        />

        <WSErrorMessage
          mt="M"
          contextKey="BenefitsAddZipCode"
          error={updateMemberProfileMeta.error}
        />

        <WSButton.Primary
          mt="XL"
          loading={updateMemberProfileMeta.isLoading}
          type="submit"
          fullWidth
        >
          Save
        </WSButton.Primary>
      </WSFormOld>
    </WSModal>
  );
};
