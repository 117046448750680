import {
  IIntegrationCustomer,
  IIntegrationVendor
} from "@wingspanhq/integrations/dist/lib/interfaces";
import { validateEmail } from "../../../utils/validators";

export const getSortedAndGroupedQuickbooksUserList = <
  User extends IIntegrationCustomer | IIntegrationVendor
>(
  users: User[]
) => [
  ...users
    .filter(user => !!user.primaryEmailAddress)
    .filter(user => validateEmail(user.primaryEmailAddress as string))
    .sort((a, b) =>
      a.displayName && b.displayName
        ? a.displayName
            .toLocaleLowerCase()
            .localeCompare(b.displayName.toLocaleLowerCase())
        : 0
    )
    .sort((a, b) =>
      a.primaryEmailAddress && b.primaryEmailAddress
        ? a.primaryEmailAddress
            .split("@")[1]
            .toLocaleLowerCase()
            .localeCompare(
              b.primaryEmailAddress.split("@")[1].toLocaleLowerCase()
            )
        : 0
    ),
  ...users
    .filter(user => !user.primaryEmailAddress)
    .sort((a, b) =>
      a.displayName && b.displayName
        ? a.displayName
            .toLocaleLowerCase()
            .localeCompare(b.displayName.toLocaleLowerCase())
        : 0
    )
];
