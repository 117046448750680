import {
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import styles from "./CompanyLogo.module.scss";

export type CompanyLogoProps = {
  companyName: string;
  companyLogoUrl?: string;
} & WSElementProps;

export const CompanyLogo: React.FC<CompanyLogoProps> = ({
  companyName,
  companyLogoUrl,
  ...elementProps
}) => (
  <WSElement {...elementProps}>
    {companyLogoUrl ? (
      <WSElement className={styles.logo}>
        <img src={companyLogoUrl} alt={companyName} />
      </WSElement>
    ) : (
      <WSText.Heading5>{companyName}</WSText.Heading5>
    )}
  </WSElement>
);
