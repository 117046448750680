import React from "react";
import { PageHeader } from "../../../../shared/components/PageHeader";
import { ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH } from "../..";
import { useHistory } from "react-router-dom";
import {
  WSChip,
  WSElement,
  WSEmptyState,
  WSFlexBox,
  WSInfiniteScroll,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import { useRequirementDefinitionListQuery } from "../../../../query/requirementDefinitions/query/useRequirementDefinitionListQuery";
import { WSQueries } from "../../../../query/WSQuery";

import styles from "./styles.module.scss";
import flatten from "lodash/flatten";
import { RequirementsTable } from "../../components/RequirementsTable";
import { useEngagementsListAllQuery } from "../../../../query/engagements/queries/useEngagementsListAllQuery";
import { WS_LINKS } from "../../../../types/wsLinks";
import { openInNewTab } from "../../../../utils/openInNewTab";

export const RouteRequirements: React.FC = () => {
  const history = useHistory();
  const requirementsDefinitionsListQuery = useRequirementDefinitionListQuery();
  const engagementsListAllQuery = useEngagementsListAllQuery();
  return (
    <WSPage>
      <WSElement>
        <PageHeader
          title="Engagement Requirements"
          rootPath={ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH}
          buttons={[
            {
              label: "Create requirement",
              onClick: () => {
                history.push(
                  `${ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH}/create`
                );
              }
            }
          ]}
        />
        <WSQueries
          queries={{
            requirementsDefinitionsListQuery,
            engagementsListAllQuery
          }}
        >
          {({
            requirementsDefinitionsListQueryData,
            engagementsListAllQueryData
          }) => {
            const list = flatten(
              requirementsDefinitionsListQueryData.map(r => r.data) || []
            );
            return (
              <>
                <WSFlexBox.CenterY mb="L" className={styles.statsContainer}>
                  <WSElement className={styles.resultsStats}>
                    <WSText.ParagraphXs color="gray500">
                      Showing:
                    </WSText.ParagraphXs>
                    <WSChip
                      pillText={`${requirementsDefinitionsListQueryData[0].summary.listSize} items`}
                      theme="neutral"
                      pillType="noBackground"
                    />
                  </WSElement>
                </WSFlexBox.CenterY>
                {list.length === 0 ? (
                  <WSEmptyState
                    type="documents"
                    title="No requirements found"
                    description="Once you create requirements, they will appear here."
                    hasBorder
                    bgColor="gray"
                    buttons={[
                      {
                        label: "Learn about requirements",
                        kind: "Secondary",
                        onClick: () =>
                          openInNewTab(WS_LINKS.eligibilityRequirements)
                      }
                    ]}
                  />
                ) : (
                  <WSInfiniteScroll
                    onLoad={() => {
                      requirementsDefinitionsListQuery.fetchMore();
                    }}
                    loadMore={
                      requirementsDefinitionsListQuery.data &&
                      requirementsDefinitionsListQuery.data.length > 0
                    }
                    endOfList={!requirementsDefinitionsListQuery.canFetchMore}
                    loading={!!requirementsDefinitionsListQuery.isFetchingMore}
                  >
                    <RequirementsTable
                      listPath={ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH}
                      requirements={list}
                      engagements={engagementsListAllQueryData}
                    />
                  </WSInfiniteScroll>
                )}
              </>
            );
          }}
        </WSQueries>
      </WSElement>
    </WSPage>
  );
};
