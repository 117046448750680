import cn from "classnames";
import React, { useMemo } from "react";

import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import {
  WSIcon,
  WSIconName,
  WSIconSize
} from "../../core/WSIcon/WSIcon.component";
import { WSText, WSTextKindsType } from "../../core/WSText/WSText.component";
import styles from "./WSBadge.component.module.scss";

export type WSBadgeTheme =
  | "dark"
  | "neutral"
  | "red"
  | "amber"
  | "green"
  | "blue"
  | "violet";

const BADGE_THEME_DEFAULT: WSBadgeTheme = "dark";

type WSBadgeUniversalSize = "S" | "M" | "L";
type WSBadgeXSSize = "XS";

export type WSBadgeSize = WSBadgeUniversalSize | WSBadgeXSSize;

const BADGE_SIZE_DEFAULT: WSBadgeSize = "M";

const textKindsMap: Record<Exclude<WSBadgeSize, "XS">, WSTextKindsType> = {
  S: "Caption",
  M: "ParagraphXs",
  L: "ParagraphSm"
};

const iconSizeMap: Record<Exclude<WSBadgeSize, "XS">, WSIconSize> = {
  S: "XS",
  M: "S",
  L: "S"
};

interface WSBadgePropsBase
  extends Omit<WSElementProps, "color" | "colorBackground" | "size"> {
  theme?: WSBadgeTheme;
}

interface WSXSBadgeProps extends WSBadgePropsBase {
  size?: WSBadgeXSSize;
  icon?: never;
  number?: never;
}

type WSIconNumberBadgeProps = WSBadgePropsBase &
  (
    | { size?: WSBadgeUniversalSize; icon?: WSIconName; number?: never }
    | { size?: WSBadgeUniversalSize; icon?: never; number?: number }
  );

export type WSBadgeProps = WSXSBadgeProps | WSIconNumberBadgeProps;

export const WSBadge = React.forwardRef<HTMLDivElement, WSBadgeProps>(
  function WSBadge(
    {
      theme = BADGE_THEME_DEFAULT,
      size = BADGE_SIZE_DEFAULT,
      icon,
      number,
      className,
      ...otherProps
    },
    ref
  ) {
    const badgeClassName = useMemo(
      () =>
        cn(
          styles.badge,
          styles[`theme-${theme}`],
          styles[`size-${size}`],
          typeof number === "number" && styles.badgeNumber,
          className
        ),
      [theme, size, number, className]
    );

    const textKind = useMemo(
      () => (size !== "XS" ? textKindsMap[size] : undefined),
      [size]
    );

    return (
      <WSElement ref={ref} className={badgeClassName} {...otherProps}>
        {icon && (
          <WSIcon
            name={icon}
            size={size !== "XS" ? iconSizeMap[size] : undefined}
          />
        )}
        {typeof number === "number" && (
          <NumberText number={number} textKind={textKind} />
        )}
      </WSElement>
    );
  }
);

type NumberTextProps = { number: number; textKind?: WSTextKindsType };

const NumberText: React.FC<NumberTextProps> = ({ number, textKind }) => {
  const formatted = useMemo(() => new Intl.NumberFormat().format(number), [
    number
  ]);

  return (
    <WSText kind={textKind} className={styles.text}>
      {formatted}
    </WSText>
  );
};
