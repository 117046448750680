import { isPlainObject } from "lodash";

interface AnyObject {
  [key: string]: any;
}

// Function to remove specified fields from an object, including nested objects
export const removeSpecifiedFields = (
  obj: AnyObject,
  fields: string[]
): AnyObject => {
  return Object.entries(obj).reduce((acc: AnyObject, [key, value]) => {
    if (!fields.includes(key)) {
      // Recursively process nested objects
      if (isPlainObject(value)) {
        const cleanedValue = removeSpecifiedFields(value, fields);
        if (Object.keys(cleanedValue).length > 0) {
          acc[key] = cleanedValue;
        }
      } else {
        acc[key] = value;
      }
    }
    return acc;
  }, {});
};

// Main function to clean optional form values
export const cleanOptionalFormValues = (obj: any, fields?: string[]): any => {
  // If fields are specified, remove them
  if (fields && fields.length > 0) {
    return removeSpecifiedFields(obj, fields);
  }

  if (Array.isArray(obj)) {
    // Process arrays recursively
    return obj
      .map(item => cleanOptionalFormValues(item))
      .filter(
        item =>
          !(
            item === undefined ||
            item === null ||
            item === "" ||
            (isPlainObject(item) && Object.keys(item).length === 0) ||
            (Array.isArray(item) && item.length === 0)
          )
      );
  } else if (isPlainObject(obj)) {
    // Process objects recursively
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const cleanedValue = cleanOptionalFormValues(value);

      if (
        cleanedValue === undefined ||
        cleanedValue === null ||
        cleanedValue === "" ||
        (isPlainObject(cleanedValue) &&
          Object.keys(cleanedValue).length === 0) ||
        (Array.isArray(cleanedValue) && cleanedValue.length === 0)
      ) {
        return acc;
      }

      return { ...acc, [key]: cleanedValue };
    }, {});
  }

  return obj;
};
