import React, { useRef } from "react";
import { toWSDateString } from "../../../../utils/formatters/toWSDate";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSIcon } from "../../core/WSIcon/WSIcon.component";
import { WSInputDateOld } from "../../core/WSInput/WSInputDate.component";
import { WSText } from "../../core/WSText/WSText.component";
import { WSFlexBox } from "../../layout/WSFlexBox/WSFlexBox.component";
import { WSMenuTrigger } from "../WSMenu/WSMenu.component";
import styles from "./WSDateRangeSelect.module.scss";

export enum WSDateRangeSelectRangeName {
  AllTime = "AllTime",
  PastWeek = "PastWeek",
  PastMonth = "PastMonth",
  Past6Months = "Past6Months",
  Past12Months = "Past12Months",
  ThisYear = "ThisYear",
  LastYear = "LastYear",
  Custom = "Custom"
}

const DATE_TODAY_SOD = new Date(new Date().setHours(0, 0, 0, 0));
const DATE_TODAY_EOD = new Date(new Date().setHours(23, 59, 59, 999));
const THIS_YEAR = DATE_TODAY_SOD.getFullYear();
const LAST_YEAR = DATE_TODAY_SOD.getFullYear() - 1;

export type WSDateRangeSelectRangeValue = [Date, Date] | null;

export const WSDateRangeSelectOptions: {
  [key in keyof typeof WSDateRangeSelectRangeName]: {
    label: string;
    range: WSDateRangeSelectRangeValue;
  };
} = {
  [WSDateRangeSelectRangeName.AllTime]: {
    label: "All time",
    range: null
  },
  [WSDateRangeSelectRangeName.PastWeek]: {
    label: "Past week",
    range: [
      new Date(
        DATE_TODAY_SOD.getFullYear(),
        DATE_TODAY_SOD.getMonth(),
        DATE_TODAY_SOD.getDate() - 7
      ),
      DATE_TODAY_EOD
    ]
  },
  [WSDateRangeSelectRangeName.PastMonth]: {
    label: "Past month",
    range: [
      new Date(
        DATE_TODAY_SOD.getFullYear(),
        DATE_TODAY_SOD.getMonth() - 1,
        DATE_TODAY_SOD.getDate()
      ),
      DATE_TODAY_EOD
    ]
  },
  [WSDateRangeSelectRangeName.Past6Months]: {
    label: "Past 6 months",
    range: [
      new Date(
        DATE_TODAY_SOD.getFullYear(),
        DATE_TODAY_SOD.getMonth() - 6,
        DATE_TODAY_SOD.getDate()
      ),
      DATE_TODAY_EOD
    ]
  },
  [WSDateRangeSelectRangeName.Past12Months]: {
    label: "Past 12 months",
    range: [
      new Date(
        DATE_TODAY_SOD.getFullYear(),
        DATE_TODAY_SOD.getMonth() - 12,
        DATE_TODAY_SOD.getDate()
      ),
      DATE_TODAY_EOD
    ]
  },
  [WSDateRangeSelectRangeName.ThisYear]: {
    label: "This year",
    range: [
      new Date(THIS_YEAR, 0, 1, 0, 0, 0, 0),
      new Date(THIS_YEAR, 11, 31, 23, 59, 59, 999)
    ]
  },
  [WSDateRangeSelectRangeName.LastYear]: {
    label: "Last year",
    range: [
      new Date(LAST_YEAR, 0, 1, 0, 0, 0, 0),
      new Date(LAST_YEAR, 11, 31, 23, 59, 59, 999)
    ]
  },
  [WSDateRangeSelectRangeName.Custom]: {
    label: "Custom",
    range: null
  }
};

type Props = Omit<WSElementProps, "onChange"> & {
  onChange(value: WSDateRangeSelectRangeValue, label: string): void;
  label: string;
  value: WSDateRangeSelectRangeValue;
  options: WSDateRangeSelectRangeName[];
};

export const WSDateRangeSelect: React.FC<Props> = ({
  onChange,
  label,
  value,
  options,
  ...elementProps
}) => {
  const ref = useRef<HTMLElement>();

  return (
    <WSElement className={styles.container} {...elementProps}>
      <WSMenuTrigger
        name="dateRangeSelect"
        renderTrigger={({ onToggle }) => (
          <WSFlexBox.CenterY onClick={onToggle}>
            <WSText weight="medium">{label}</WSText>
            <WSIcon
              block
              name="chevron-down"
              size="XS"
              ml="M"
              color="blue500"
            />
          </WSFlexBox.CenterY>
        )}
        items={options.map((rangeName) => ({
          label: WSDateRangeSelectOptions[rangeName].label,
          onClick: () => {
            if (rangeName === WSDateRangeSelectRangeName.Custom) {
              onChange(value, WSDateRangeSelectOptions[rangeName].label);
              if (ref.current) {
                ref.current.querySelector("input")?.focus();
              }
            } else {
              onChange(
                WSDateRangeSelectOptions[rangeName].range,
                WSDateRangeSelectOptions[rangeName].label
              );
            }
          }
        }))}
      />
      <WSElement className={styles.dateInput} ref={ref as any}>
        <WSInputDateOld
          name="dateRange"
          selectionMode="range"
          value={value || WSDateRangeSelectOptions.PastMonth.range}
          onChange={(newValue: any) =>
            onChange(
              newValue,
              newValue[0] && newValue[1]
                ? `${toWSDateString(
                    newValue[0],
                    "monthDate"
                  )} – ${toWSDateString(newValue[1], "monthDate")}`
                : WSDateRangeSelectOptions.Custom.label
            )
          }
        />
      </WSElement>
    </WSElement>
  );
};
