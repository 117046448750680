import {
  WSElement,
  WSElementProps,
  WSPill
} from "@wingspanhq/fe-component-library";
import { IIntegrationsQuickbooksSyncActivity } from "@wingspanhq/integrations/dist/lib/interfaces";
import React from "react";

type Props = {
  latestActivity: IIntegrationsQuickbooksSyncActivity;
} & WSElementProps;

export const QuickbooksSyncBadge: React.FC<Props> = ({
  latestActivity,
  ...elementProps
}) => (
  <WSElement {...elementProps}>
    {latestActivity.isError ? (
      <WSPill theme="error" icon text="Error" />
    ) : (
      <WSPill theme="success" icon text="Synced" />
    )}
  </WSElement>
);
