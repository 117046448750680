import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import {
  CreateTokenCardData,
  PaymentMethod,
  Stripe,
  StripeCardElementChangeEvent,
  StripeError,
  Token
} from "@stripe/stripe-js";
import cn from "classnames";
import React, { ReactFragment, useImperativeHandle, useState } from "react";
import { toCamelCase } from "../../../../utils";
import { useIsMobile } from "../../layout";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSIcon } from "../WSIcon/WSIcon.component";
import { WSText } from "../WSText/WSText.component";
import styles from "./WSStripeInput.module.scss";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: "Centra No2, sans-serif",
      fontSize: "15px",
      color: "#282d37",
      "::placeholder": {
        color: "#6b727f"
      }
    },
    invalid: {
      color: "#282d37",
      iconColor: "#e43a2f"
    }
  }
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      family: "Centra No2",
      src:
        'url("https://storage.googleapis.com/wingspan-stripe-content/fonts/CentraNo2-Book.woff2") format("woff2")'
    }
  ]
};

interface StripeInputPassthroughProps {
  onInputChange: (event: StripeCardElementChangeEvent) => void;
  onInputFocus?: () => void;
  onInputBlur?: () => void;
  onInputReady?: (element: any) => void;
  onStripeToken?: (token: PaymentMethod | undefined) => void;
  onStripeError?: (error: StripeError | undefined) => void;
}

interface StripeInputPros extends StripeInputPassthroughProps {
  cardElementId: string;
}

const StripeInput = React.forwardRef<ReactFragment, StripeInputPros>(
  (
    {
      cardElementId,
      onInputChange,
      onInputFocus,
      onInputBlur,
      onInputReady,
      onStripeError,
      onStripeToken
    },
    ref
  ) => {
    const [stripeInputError, setStripeInputError] = useState("");
    const [stripeInputFocused, setStripeInputFocused] = useState(false);

    const isMobile = useIsMobile();

    const stripe = useStripe();
    const elements = useElements();

    const createPaymentMethod = async () => {
      if (!stripe || !elements) {
        return { error: undefined, token: undefined };
      }

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        return { error: undefined, token: undefined };
      }

      if (onStripeError) {
        onStripeError(undefined);
      }
      if (onStripeToken) {
        onStripeToken(undefined);
      }

      const { error, paymentMethod: token } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement
      });

      if (error) {
        if (onStripeError) {
          onStripeError(error);
        }
      } else if (onStripeToken) {
        onStripeToken(token);
      }

      return { error, token };
    };

    const createCardToken = async (otherData?: CreateTokenCardData) => {
      if (!stripe || !elements) {
        return { error: undefined, token: undefined };
      }

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        return { error: undefined, token: undefined };
      }

      if (onStripeError) {
        onStripeError(undefined);
      }
      if (onStripeToken) {
        onStripeToken(undefined);
      }

      const { error, token } = await stripe.createToken(cardElement, otherData);

      if (error || !token) {
        if (onStripeError) {
          onStripeError(error);
        }
      }

      return { error, token };
    };

    useImperativeHandle(ref, () => ({
      createPaymentMethod,
      createCardToken
    }));

    const classNames = cn(
      styles.stripeInput,
      stripeInputFocused && styles.stripeInputFocused,
      stripeInputError !== "" && styles.stripeInputError
    );

    const handleInputChange = (event: StripeCardElementChangeEvent) => {
      if (event.error?.message) {
        setStripeInputError(event.error.message);
      } else {
        setStripeInputError("");
      }
      onInputChange(event);
    };

    const handleInputBlur = () => {
      setStripeInputFocused(false);
      if (onInputBlur) {
        onInputBlur();
      }
    };

    const handleInputFocus = () => {
      setStripeInputFocused(true);
      if (onInputFocus) {
        onInputFocus();
      }
    };

    const [isInputReady, setIsInputReady] = useState(false);
    const handleInputReady = (element: any) => {
      setIsInputReady(true);
      if (onInputReady) {
        onInputReady(element);
      }

      // Force reflow iframe
      // Workaround for some webkit bug, observed in Safari on iOS 14.4
      const iframe = document
        .querySelector(`.${styles.stripeInput}`)
        ?.querySelector("iframe");

      if (iframe) {
        const initialDisplay = iframe.style.display;
        iframe.style.display = "none";
        iframe.style.display = initialDisplay;
      }
    };

    return (
      <>
        <WSElement className={classNames}>
          {!isInputReady &&
            (isMobile
              ? "Stripe secure payments loading..."
              : "Please wait, Stripe secure payments loading...")}
          <CardElement
            className={cn(!isInputReady && styles.hideUntilReady)}
            id={cardElementId}
            options={CARD_ELEMENT_OPTIONS}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onReady={handleInputReady}
          />
        </WSElement>
        {stripeInputError !== "" && (
          <WSElement className={styles.stripeInputErrorMessage}>
            <WSIcon
              block
              name="alert-circle"
              size="XS"
              className={styles.errorIcon}
            />
            <WSText.ParagraphSm className={styles.errorText}>
              {stripeInputError}
            </WSText.ParagraphSm>
          </WSElement>
        )}
      </>
    );
  }
);

export interface WSStripeInputProps
  extends WSElementProps,
    StripeInputPassthroughProps {
  name: string;
  stripeInstance: Promise<Stripe | null>;
  onInputComplete?: (isComplete: boolean) => void;
}

export interface WSStripeInputRef extends HTMLElement {
  createPaymentMethod(): Promise<{
    error: StripeError | undefined;
    token: PaymentMethod | undefined;
  }>;

  createCardToken(
    otherData?: CreateTokenCardData
  ): Promise<{
    error: StripeError | undefined;
    token?: Token | undefined;
  }>;
}

export const WSStripeInput = React.forwardRef<
  WSStripeInputRef,
  WSStripeInputProps
>(
  (
    {
      className,
      name,
      stripeInstance,
      onInputChange,
      onInputFocus,
      onInputBlur,
      onInputReady,
      onInputComplete,
      onStripeError,
      onStripeToken,
      ...props
    },
    ref
  ) => {
    const testId = toCamelCase(name, "StripeInput");
    const cardElementId = toCamelCase(testId, "CardElement");

    const checkIfInputIsCompleteAndForwardEvents = (
      event: StripeCardElementChangeEvent
    ) => {
      if (onInputChange) {
        onInputChange(event);
      }
      if (onInputComplete) {
        onInputComplete(event.complete);
      }
    };

    return (
      <WSElement className={className} data-testid={testId} {...props}>
        <Elements options={ELEMENTS_OPTIONS} stripe={stripeInstance}>
          <StripeInput
            ref={ref}
            cardElementId={cardElementId}
            onInputChange={checkIfInputIsCompleteAndForwardEvents}
            onInputFocus={onInputFocus}
            onInputBlur={onInputBlur}
            onInputReady={onInputReady}
            onStripeError={onStripeError}
            onStripeToken={onStripeToken}
          />
        </Elements>
      </WSElement>
    );
  }
);
