import {
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSMenuItem,
  WSMenuTrigger
} from "@wingspanhq/fe-component-library";

interface ActionsProps extends WSElementProps {
  items: WSMenuItem[];
  name?: string;
  disabled?: boolean;
}

export const Actions: React.FC<ActionsProps> = ({
  items,
  name = "actions",
  disabled,
  ...elementProps
}) => {
  return (
    <WSFlexBox.CenterY {...elementProps}>
      <WSMenuTrigger
        name={name}
        items={items}
        renderTrigger={({ onToggle }) => (
          <WSIcon
            data-testid={name + "Menu"}
            onClick={disabled ? undefined : onToggle}
            block
            name="dots-v"
            size="M"
            color={disabled ? "gray400" : "blue400"}
          />
        )}
      />
    </WSFlexBox.CenterY>
  );
};
