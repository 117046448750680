import React from "react";
import { WSDivider } from "@wingspanhq/fe-component-library";

export interface DividerProps {
  or?: boolean;
}

export const Divider: React.FC<DividerProps> = ({ or }) => (
  <WSDivider my="2XL" label={or ? "OR" : ""} />
);
