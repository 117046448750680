import isEqual from "lodash/isEqual";
import isPlainObject from "lodash/isPlainObject";
import keys from "lodash/keys";
import pick from "lodash/pick";

export function getChangedData<Result = any>(
  oldData: any = {},
  newData: Result,
  options?: { dismissTimeInDates?: boolean }
) {
  function isChanged(_old: any, _new: any): any {
    const isObject = isPlainObject(_new) && isPlainObject(_old);
    const isArray = Array.isArray(_new) && Array.isArray(_old);

    if (isArray && _new.length !== _old.length) {
      return true;
    } else if (isObject || isArray) {
      const fields = keys(_new);

      return fields.some(field => {
        return isChanged(_old[field], _new[field]);
      });
    } else if (_old instanceof Date && _new instanceof Date) {
      if (options?.dismissTimeInDates) {
        return !(
          _old.getDate() === _new.getDate() &&
          _old.getMonth() === _new.getMonth() &&
          _old.getFullYear() === _new.getFullYear()
        );
      } else {
        return !isEqual(_old, _new);
      }
    } else {
      return !isEqual(_old, _new);
    }
  }
  const changedFields = keys(newData).filter(field =>
    isChanged(oldData[field], (newData as any)[field])
  );

  return pick(newData, changedFields);
}
