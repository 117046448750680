import { useWSMutation } from "../../helpers";
import { QUERY_SHARED_FILE } from "../keys";
import { updateSharedFile } from "../../../services/sharedFile/updateSharedFile";
import {
  ISharedFileRequestResponse,
  ISharedFileRequestUpdateRequest
} from "@wingspanhq/shared-files/dist/lib/interfaces";
import { WSServiceError } from "../../../utils/serviceHelper";

type CreateSharedFilePayload = ISharedFileRequestUpdateRequest & {
  sharedFileId: string;
};

export const useUpdateSharedFile = () =>
  useWSMutation<
    ISharedFileRequestResponse,
    WSServiceError,
    CreateSharedFilePayload
  >(({ sharedFileId, ...payload }) => updateSharedFile(sharedFileId, payload), {
    dependencies(payload) {
      return [QUERY_SHARED_FILE, payload.sharedFileRequestId];
    }
  });
