import React, { useState } from "react";
import {
  WSButton,
  WSContainer,
  WSIcon,
  WSCentered,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import {
  useUpdateAccounts,
  useUpdateActivity
} from "../../../query/users/mutations";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { useUserId } from "../../../query/hooks/helpers";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import {
  AccountsCheckList,
  AccountsCheckLoading
} from "../../components/AccountsCheckList/AccountsCheckList";
import {
  AccountStatus,
  AccountUsage
} from "@wingspanhq/users/dist/lib/interfaces/account";
import { useAccounts } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";

export const updateForBusiness = async (
  accounts: IAccount[],
  updateAccounts: any
) => {
  await updateAccounts(
    accounts.map(({ accountId, usage, status, events }) => ({
      accountId,
      usage:
        usage === AccountUsage.Business
          ? AccountUsage.Business
          : AccountUsage.Mixed,
      ...(status !== AccountStatus.Disconnected
        ? { status: AccountStatus.Active }
        : {}),
      events: {
        ...events,
        initialSetupAt: new Date()
      }
    }))
  );
};

export const BookkeepingSetUpPlaidInstitutionBusinessOnly: React.FC<RouteComponentProps<{
  institutionId: string;
}>> = ({ match }) => {
  const userId = useUserId();
  const history = useHistory();

  const institutionId = match.params.institutionId;

  const [updateActivity] = useUpdateActivity(userId);
  const { updateAccounts, updateAccountsLoading } = useUpdateAccounts();

  useBrowserPageTitle("Bookkeeping - Setup");

  const [accounts, setAccounts] = useState<IAccount[]>();

  const qAccounts = useAccounts();

  return (
    <ProjectOnboardingLayout progress={(100 / 5) * 3}>
      <WSContainer verticalPadding>
        <WSCentered span={{ m: "6" }}>
          <WSText.ParagraphSm color="gray500" mt="M" pt="M">
            3 of 5
          </WSText.ParagraphSm>
          <WSText.Heading4>Sync ongoing business transactions</WSText.Heading4>

          <WSPanel mt="2XL">
            <WSText.Heading5 mt="XL">
              Any dedicated business accounts?
            </WSText.Heading5>

            <WSText mt="XL">
              We’ll automatically mark all transactions as business so you can
              skip manual review.
            </WSText>

            <WSQueries
              queries={{ qAccounts }}
              renderLoader={() => <AccountsCheckLoading />}
            >
              {({ qAccounts }) => {
                const currentAccounts =
                  accounts ??
                  qAccounts.data.filter(
                    a =>
                      a.usedFor?.bookkeeping &&
                      a.institutionId === institutionId &&
                      a.status !== AccountStatus.Inactive
                  );

                if (!currentAccounts.length) {
                  return <Redirect to="/member/bookkeeping/setup/accounts" />;
                }

                return (
                  <>
                    <AccountsCheckList
                      data-testId="businessOnlyChecklist"
                      mt="XL"
                      accounts={currentAccounts}
                      getValue={ac => ac.usage === AccountUsage.Business}
                      onToggle={ac => ({
                        ...ac,
                        usage:
                          ac.usage === AccountUsage.Business
                            ? AccountUsage.Mixed
                            : AccountUsage.Business
                      })}
                      onChange={setAccounts}
                      selectedMark={
                        <WSIcon block name="briefcase" size="S" mx="XL" />
                      }
                    />

                    <WSButton.Primary
                      mt="2XL"
                      fullWidth
                      data-testId="continue"
                      loading={updateAccountsLoading}
                      onClick={async () => {
                        await updateForBusiness(
                          currentAccounts,
                          updateAccounts
                        );

                        await updateActivity({
                          flows: {
                            bookkeepingSetup: {
                              version: 4,
                              currentStep: 4,
                              totalSteps: 6,
                              complete: false
                            }
                          }
                        });

                        history.push(`/member/bookkeeping/setup/accounts`);
                      }}
                    >
                      Continue
                    </WSButton.Primary>
                  </>
                );
              }}
            </WSQueries>
          </WSPanel>
        </WSCentered>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
