import {
  WSButton,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSTable,
  WSTableCell,
  WSText,
  toWSDateString,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import {
  IPayableSchema,
  IPayerTaxFormResponse,
  InvoiceStatus
} from "@wingspanhq/payments/dist/interfaces";
import { flatten } from "lodash";
import times from "lodash/times";
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { PayableDetails } from "../../../../../Invoices/screens/payables/PayableDetails";
import { WSQueries } from "../../../../../query/WSQuery";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useQueryPayables } from "../../../../../query/payments/queries";
import { useUserProfile } from "../../../../../query/users/queries";
import { paymentsService } from "../../../../../services/payments";
import { ButtonDownloadCsv } from "../../../../../shared/components/ButtonDownloadCsv";
import { selectorUserLegalName } from "../../../../../shared/selectors/selectorUserLegalName";
import { concurrentActions } from "../../../../../utils/serviceHelper";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";

type Props = {
  taxForm: IPayerTaxFormResponse;
};

export const Payables: React.FC<Props> = ({ taxForm }) => {
  const userId = useUserId();
  const queryPayables = useQueryPayables(10, {
    filters: {
      memberId: taxForm.memberId,
      status: InvoiceStatus.Paid,
      "lineItems.labels.taxForm1099": taxForm.year
    },
    userId: userId !== taxForm.clientId ? taxForm.clientId : undefined
  });
  const queryUser = useUserProfile(userId);
  const payeeName = selectorTaxFormRecipientName(taxForm);
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  return (
    <>
      <Route
        path={match.path + "/payments/:payableId"}
        component={PayableDetails}
      />

      <WSFlexBox.CenterY wrap="nowrap" justify="space-between">
        <WSText weight="medium">Payables</WSText>

        {queryPayables.data && queryPayables.data.summary.listSize > 0 && (
          <ButtonDownloadCsv
            getData={async () => {
              const pageSize = 100;
              const allCount = queryPayables.data?.summary.listSize || 0;
              const pagesCount = Math.ceil(allCount / pageSize);

              const actions = times(pagesCount).map((_, i) => () =>
                paymentsService.payable.list(
                  {
                    filter: {
                      memberId: taxForm.memberId,
                      status: InvoiceStatus.Paid,
                      "lineItems.labels.taxForm1099": taxForm.year
                    }
                  },
                  userId !== taxForm.clientId ? taxForm.clientId : undefined
                )
              );

              const allPages = await concurrentActions(actions, {
                concurrentLimit: 5
              });

              return flatten(allPages.map(page => page.data)).map(payable => ({
                "Invoice Number": payable.invoiceNumber,
                "Non-employee compensation": payable.amount,
                "Paid Date": payable.events.paidAt
              }));
            }}
            fileName={`${payeeName} Tax Form Payables`}
            customTrigger={<WSButton.Link>Download CSV</WSButton.Link>}
          />
        )}
      </WSFlexBox.CenterY>

      <WSDivider my="XL" />

      <WSElement
        mb="2XL"
        p="L"
        color="gray100"
        style={{ backgroundColor: "currentcolor" }}
      >
        <WSText.Display2>
          {toWSMoneyString(taxForm.data.totalAmount)}
        </WSText.Display2>
        {taxForm.data.adjustments && (
          <WSFlexBox justify="space-between" wrap="nowrap" mt="XL">
            <WSText color="gray500">
              Manual adjustment
              {queryUser.data
                ? ` by ${selectorUserLegalName(queryUser.data)}`
                : ""}
            </WSText>
            <WSText>
              {taxForm.data.adjustments > 0 ? "+" : ""}
              {toWSMoneyString(taxForm.data.adjustments)}
            </WSText>
          </WSFlexBox>
        )}
      </WSElement>

      <WSQueries queries={{ queryPayables }}>
        {({ queryPayablesData: { data: payables } }) => {
          return (
            <>
              <WSTable<IPayableSchema>
                tableData={payables.map(payable => ({
                  id: payable.payableId,
                  data: payable
                }))}
                onRowClick={
                  userId === taxForm.clientId
                    ? ({ data: payable }) => {
                        history.push(
                          location.pathname + "/payments/" + payable.payableId,
                          {
                            backPath: location.pathname
                          }
                        );
                      }
                    : undefined
                }
                columns={[
                  {
                    config: {
                      gridTemplateSizeMax: "1fr",
                      header: {
                        text: `Invoices paid in ${taxForm.year}`
                      }
                    },
                    renderFunction: ({ data: payable }) => (
                      <WSTableCell
                        avatar={{
                          type: "icon",
                          icon: "check-double",
                          color: "green500",
                          colorBackground: "green50"
                        }}
                        text={payable.invoiceNumber}
                      />
                    )
                  },
                  {
                    config: {
                      gridTemplateSizeMax: "1fr",
                      justify: "start",
                      header: {
                        text: "Non-employee compensation"
                      }
                    },
                    renderFunction: ({ data: payable }) => (
                      <WSTableCell
                        text={toWSMoneyString(payable.amount)}
                        secondaryText={`Paid on ${toWSDateString(
                          payable.events.paidAt,
                          "monthDate"
                        )}`}
                      />
                    )
                  }
                ]}
              />
              {payables.length === 0 && (
                <WSText.ParagraphSm align="center" mt="M">
                  No invoices found
                </WSText.ParagraphSm>
              )}
              {queryPayables.canFetchMore && (
                <WSButton.Link
                  mt="M"
                  fullWidth
                  onClick={() => {
                    queryPayables.fetchMore();
                  }}
                  loading={!!queryPayables.isFetchingMore}
                >
                  Load more
                </WSButton.Link>
              )}
            </>
          );
        }}
      </WSQueries>
    </>
  );
};
