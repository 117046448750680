import {
  toWSDateString,
  toWSMoneyString,
  useModalContext,
  WSButton,
  WSElement,
  WSLoader,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import { IClientInvoice } from "@wingspanhq/payments/dist/interfaces";
import React, { useEffect, useState } from "react";
import { getClientInvoiceMemberName } from "../../../query/payments/selectors";
import { useGetGuestAccountNumbers } from "../../../query/users/mutations";
import { useAccount } from "../../../query/users/queries";
import { usersService } from "../../../services/users";
import { InfoRow } from "../InfoRow/InfoRow";

export const AUTHORIZE_ACH_PAYMENT_MODAL = "AUTHORIZE_ACH_PAYMENT_MODAL";

export const AuthorizeACHPaymentModal: React.FC = () => (
  <WSModal name={AUTHORIZE_ACH_PAYMENT_MODAL} size="S">
    {props => <AuthorizeModalBody {...props} />}
  </WSModal>
);

const AuthorizeModalBody: React.FC<{
  accountId?: string;
  clientInvoice: IClientInvoice;
  onSubmit: () => void;
  isLoading: boolean;
  plaidAccountId?: string;
  plaidPublicToken?: string;
}> = ({
  accountId,
  clientInvoice,
  onSubmit,
  isLoading,
  plaidAccountId,
  plaidPublicToken
}) => {
  const accountQuery = useAccount(accountId || "", {
    enabled: !!accountId
  });
  const account = accountQuery.data;
  const { closeModal } = useModalContext();
  const [plaidAccountNumber, setPlaidAccountNumber] = useState("");
  const [plaidRoutingNumber, setPlaidRoutingNumber] = useState("");
  const [
    getGuestAccountNumbers,
    getGuestAccountNumbersMeta
  ] = useGetGuestAccountNumbers();

  useEffect(() => {
    const callback = async () => {
      if (
        plaidAccountId &&
        plaidPublicToken &&
        !plaidAccountNumber &&
        !plaidRoutingNumber
      ) {
        await getGuestAccountNumbers(
          {
            accountId: plaidAccountId,
            publicToken: plaidPublicToken
          },
          {
            onSuccess: numbers => {
              setPlaidAccountNumber(numbers.account);
              setPlaidRoutingNumber(numbers.routing || "");
            }
          }
        );
      }
    };

    callback();
  }, [plaidAccountId, plaidPublicToken]);

  return (
    <>
      <WSText mb="XL">
        By clicking the "Authorize" button/continuing with this transaction, I
        authorize Wingspan to debit the following amount from my bank account on
        the date shown below.
      </WSText>
      <WSElement mb="XL">
        {(accountId && accountQuery.isLoading) ||
        getGuestAccountNumbersMeta.isLoading ? (
          <WSLoader.Spinner size="S" />
        ) : (
          <>
            <InfoRow
              longName
              label="Amount:"
              value={toWSMoneyString(clientInvoice.amount)}
            />
            <InfoRow
              longName
              label="Date:"
              value={toWSDateString(new Date(), "monthDayYear")}
            />
            <InfoRow
              longName
              label="Routing #:"
              value={account?.numbers?.routing ?? plaidRoutingNumber ?? "–"}
            />
            <InfoRow
              longName
              label="Account #:"
              value={account?.numbers?.account ?? plaidAccountNumber ?? "–"}
            />
          </>
        )}
      </WSElement>
      <WSText mb="XL">
        Once I authorize this payment, I understand there cannot be any changes
        or corrections.
      </WSText>
      <WSText mb="XL">
        Transactions made after 4:00 PM ET or on a weekend or holiday will be
        processed the next business day. It is recommended that you print a copy
        of this authorization and maintain it for your records.
      </WSText>
      <WSButton
        fullWidth
        loading={isLoading}
        onClick={() => {
          closeModal(AUTHORIZE_ACH_PAYMENT_MODAL);
          onSubmit();
        }}
        name="authorize"
      >
        Authorize
      </WSButton>
    </>
  );
};
