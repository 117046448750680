import {
  WSElement,
  WSElementProps,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React from "react";
import styles from "./HelpButton.module.scss";
import { openIntercom } from "../../../shared/utils/intercom";

type Props = WSElementProps;

export const HelpButton: React.FC<Props> = ({ className, ...elementProps }) => (
  <WSElement
    className={classNames(styles.helpButton, className)}
    onClick={openIntercom}
    {...elementProps}
  >
    <WSIcon block name="chat" className={styles.icon} />
    <WSText className={styles.text}>Help</WSText>
  </WSElement>
);
