import * as Yup from "yup";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";

export const backgroundChecksValidationSchema = Yup.object().shape({
  package: Yup.mixed().when("type", {
    is: RequirementType.BackgroundCheck,
    then: Yup.string().required("Package is required"),
    otherwise: Yup.mixed().notRequired()
  })
});
