import {
  useIsMobile,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSPanel,
  WSPanelProps,
  WSText
} from "@wingspanhq/fe-component-library";
import styles from "./TrackYourIncome.module.scss";
import React from "react";
import { useHistory } from "react-router-dom";

interface TrackYourIncomeProps extends WSPanelProps {}

export const TrackYourIncome: React.FC<TrackYourIncomeProps> = ({
  ...otherProps
}) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  return (
    <WSPanel
      mb="2XL"
      className={styles.panel}
      color="gray600"
      px={isMobile ? "M" : "XL"}
      py="XL"
      onClick={() => history.push("/member/taxes/dashboard")}
      {...otherProps}
    >
      <WSFlexBox.CenterY>
        <WSElement className={styles.icon} mr={isMobile ? "M" : "XL"} />
        <WSElement className={styles.content} mr="M">
          <WSText.Heading5>
            Track your payments through Wingspan
          </WSText.Heading5>
          <WSText mt="XS">
            Automate your payment workflow and get reports on income by client.
          </WSText>
        </WSElement>
        <WSIcon
          block
          name="chevron-right"
          size={isMobile ? "XS" : "S"}
          color="gray500"
        />
      </WSFlexBox.CenterY>
    </WSPanel>
  );
};
