import {
  WSActions,
  WSElement,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { useRef, useState } from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUpdateActivity } from "../../../../query/users/mutations";
import {
  FormInvoicingConfig,
  FormInvoicingConfigRef
} from "../../components/FormInvoicingConfig";
import { OnboardingStep } from "../../types";

export const PayablesSettings: React.FC<OnboardingStep> = ({
  title,
  onBack,
  onNext
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const invoicingConfigFormRef = useRef<FormInvoicingConfigRef>(null);
  const userId = useUserId();
  const [updateActivity] = useUpdateActivity(userId);

  const updateActivityAndContinue = async () => {
    updateActivity({
      flows: {
        onboardingPayableSettings: {
          complete: true
        }
      }
    });
    onNext?.();
  };
  return (
    <>
      <WSPanel mb="2XL">
        <WSElement mb="2XL">
          <WSSectionToolbar title={title} />
          <WSText.ParagraphSm color="gray500">
            Configure what inputs can be edited on payables (invoices)
          </WSText.ParagraphSm>
        </WSElement>

        <FormInvoicingConfig
          ref={invoicingConfigFormRef}
          setLoading={setLoading}
          hideSaveButton
          onSaveSuccess={updateActivityAndContinue}
        />
      </WSPanel>

      <WSActions
        mb="2XL"
        alignment="fill"
        buttons={[
          {
            onClick: onBack,
            kind: "Secondary",
            label: "Back",
            visible: !!onBack
          },
          {
            loading,
            onClick: () => {
              invoicingConfigFormRef.current?.requestSubmit();
            },
            label: "Save & continue"
          }
        ]}
      />
      <WSText.ParagraphXs weight="book" color="gray400">
        Payroll settings (payroll schedule and payables settings) can be viewed
        and updated at any time in your settings.
      </WSText.ParagraphXs>
    </>
  );
};
