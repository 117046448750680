import { useUserProfileData } from "./utils/useUserProfileData";
import { isUserIdsIncluded } from "./utils/isUserIdsIncluded";
import { HIDE_CUSTOM_FIELDS_NAV_IDS } from "./helpers";

export const useShowCustomFieldsNav = () => {
  const userData = useUserProfileData();
  // if not collaborator node, show new collaborator payment nav
  const showCustomFieldsNav = !isUserIdsIncluded(
    userData,
    HIDE_CUSTOM_FIELDS_NAV_IDS
  );

  return showCustomFieldsNav;
};
