import { useHistory } from "react-router-dom";
import { PATH_ONBOARDING_CHANGE } from "..";
import { PATH_SETTINGS_ACCOUNT } from "../../Settings/routes/RouteAccount";
import { OnboardingFlow } from "../components/OnboardingFlow";
import { OnboardingModule } from "../types";

const modules: OnboardingModule[] = [
  {
    type: "entity_change"
  },
  {
    type: "certify_tax_payer_info"
  }
];

export const RouteChange: React.FC = () => {
  const history = useHistory();

  return (
    <OnboardingFlow
      onBack={() => {
        history.push(PATH_SETTINGS_ACCOUNT);
      }}
      basePath={PATH_ONBOARDING_CHANGE}
      modules={modules}
      onSuccess={() => {
        history.push(PATH_SETTINGS_ACCOUNT);
      }}
    />
  );
};
