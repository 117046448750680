import {
  WSElement,
  WSLayout,
  WSLayoutProps,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";

type Props = {
  headTitle?: string;
  description?: string;
  narrow?: boolean;
  whiteBackground?: boolean;
  panel?: boolean;
} & WSLayoutProps;

export const LayoutFullscreen: React.FC<Props> = ({
  children,
  headTitle,
  title,
  description,
  narrow,
  panel = true,
  whiteBackground,
  ...props
}) => {
  const ContentWrapper = panel ? WSPanel : WSElement;

  return (
    <WSLayout
      line
      smallHeader
      headerClassName={styles.header}
      headerCenter={
        headTitle ? (
          <WSText.ParagraphSm py="L" weight="medium">
            {headTitle}
          </WSText.ParagraphSm>
        ) : null
      }
      {...props}
      contentClassName={classNames(styles.content, {
        [styles.whiteBackground]: whiteBackground
      })}
    >
      <ContentWrapper
        className={classNames(styles.container, {
          [styles.narrow]: narrow
        })}
      >
        {title || description ? (
          <WSElement mb="2XL">
            {title && (
              <WSText.Paragraph weight="medium" color="gray600">
                {title}
              </WSText.Paragraph>
            )}
            {description && (
              <WSText.ParagraphSm mt="M" color="gray500">
                {description}
              </WSText.ParagraphSm>
            )}
          </WSElement>
        ) : null}

        {children}
      </ContentWrapper>
    </WSLayout>
  );
};
