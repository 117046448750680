import React from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { PayerSetupLayout } from "../../../components/PayerSetupLayout";
import { FormStateFiling } from "../../../components/FormStateFiling";

export const RouteSetupStateFiling: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  return (
    <PayerSetupLayout title="State filing">
      <FormStateFiling onNext={onNext} onBack={onBack} />
    </PayerSetupLayout>
  );
};
