import {
  IBulkCollaboratorBatch,
  IBulkPayableBatch
} from "@wingspanhq/payments/dist/interfaces";

/**
 *
 * @param bulkBatch Bulk (payable|collaborator|...) batch
 * @returns string full name of the user who imported the line items
 */
export function getBatchCreatorName(
  bulkBatch: IBulkPayableBatch | IBulkCollaboratorBatch
) {
  if (
    bulkBatch.client.user.profile?.firstName &&
    bulkBatch.client.user.profile?.lastName
  ) {
    return `${bulkBatch.client.user.profile?.firstName} ${bulkBatch.client.user.profile?.lastName}`;
  }
  return bulkBatch.client.user.email;
}
