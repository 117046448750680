import {
  useWSModal,
  useWSSnackbar,
  WSActions,
  WSAlert,
  WSElement,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ICollaboratorGroupResponse,
  IPayeeResponse,
  PayerPayeeStatus
} from "@wingspanhq/payments/dist/interfaces";
import { useUpdatePayee } from "../../../query/payee/mutations/useUpdatePayee";
import { ContractorInformation } from "./ContractorInformation";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { IPayeeEngagementListResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { PayerPayeeEngagementStatus } from "@wingspanhq/payments/dist/interfaces/payerPayeeEngagement";
import { useArchivePayeeEngagements } from "../../../query/payeeEngagements/mutations/useArchivePayeeEngagements";
import { selectorPayeeEngagementNameTmp } from "../selectors/selectorPayeeEngagementsTmp";
import { useIsEngagementsActive } from "../../../shared/hooks/useIsEngagementsActive";
import { selectorPayerPayeeEngagementName } from "../selectors/selectorPayerPayeeEngagementName";

export interface ArchiveContractorModalProps {
  payee: IPayeeResponse;
  engagements?: IPayeeEngagementListResponse;
  groups: ICollaboratorGroupResponse[];
  onClose: () => void;
}

export const ArchiveContractorModal: React.FC<ArchiveContractorModalProps> = ({
  payee,
  engagements,
  groups,
  onClose
}) => {
  const { openSnackbar } = useWSSnackbar();
  const [updatePayee, updatePayeeMeta] = useUpdatePayee();
  const [
    archiveEngagements,
    archiveEngagementsMeta
  ] = useArchivePayeeEngagements();

  const visibleEngagements = engagements?.filter(
    e => e.status !== PayerPayeeEngagementStatus.Inactive
  );
  const isEngagementsActive = useIsEngagementsActive();

  return (
    <>
      <WSText.ParagraphSm mb="L" weight="book">
        Are you sure you want to archive this contractor?
      </WSText.ParagraphSm>
      <WSPanel>
        <ContractorInformation payee={payee} />

        {visibleEngagements?.length ? (
          <WSElement mt="2XL">
            <WSText.ParagraphSm color="gray500">
              {`${visibleEngagements.length} engagement${
                visibleEngagements.length === 1 ? "" : "s"
              } will be deactivated:`}
            </WSText.ParagraphSm>
            {visibleEngagements.slice(0, 5).map((e, index) => (
              <WSText.ParagraphSm mt="S" key={e.payerPayeeEngagementId}>
                {isEngagementsActive
                  ? selectorPayerPayeeEngagementName(e, true)
                  : selectorPayeeEngagementNameTmp(e, groups, true)}
              </WSText.ParagraphSm>
            ))}
            {visibleEngagements.length > 5 ? (
              <WSText.ParagraphSm mt="S" color="gray500">
                + {visibleEngagements.length - 5} more
              </WSText.ParagraphSm>
            ) : null}
          </WSElement>
        ) : null}
      </WSPanel>

      <WSAlert
        my="2XL"
        theme="info"
        icon="info-circle"
        size="M"
        title="Archiving contractor"
      >
        After a contractor is archived, no new payments can be created or sent
        to the contractor. The contractor will be made archived and hidden from
        engagements they were on.
        <WSElement mt="M" />
        Existing invoices and the contractor profile will remain visible until
        you delete them. You can always restore the contractor when needed. The
        contractor’s eligibility status will be recalculated upon restore.
      </WSAlert>

      <WSErrorMessage
        error={updatePayeeMeta.error}
        mb="XL"
        contextKey="ArchiveClient"
      />

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Cancel",
            onClick: onClose,
            kind: "Secondary"
          },
          {
            label: "Archive contractor",
            loading: updatePayeeMeta.isLoading,
            onAsyncClick: async () => {
              if (visibleEngagements?.length) {
                await archiveEngagements({
                  payeeId: payee.payeeId,
                  engagementIds: visibleEngagements.map(
                    e => e.payerPayeeEngagementId
                  )
                });
              }

              await updatePayee(
                {
                  payeeId: payee.payeeId,
                  payerOwnedData: {
                    status: PayerPayeeStatus.Inactive
                  }
                },
                {
                  onSuccess: () => {
                    openSnackbar({
                      duration: 5000,
                      type: "success",
                      message: "Contractor archived successfully"
                    });
                    onClose();
                  },
                  onError: () => {
                    openSnackbar({
                      duration: 5000,
                      type: "warning",
                      message: `Failed to archive contractor`
                    });
                  }
                }
              );
            }
          }
        ]}
      />
    </>
  );
};

export const useArchiveContractorModal = () =>
  useWSModal(ArchiveContractorModal, {
    title: "Archive contractor",
    size: "M"
  });
