import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";
import { WSFlexBox, WSText } from "@wingspanhq/fe-component-library";
import * as React from "react";

type SideNavMenuItemProps = {
  linkTo: string;
  testId: string;
  exact?: boolean;
  label: string;
  isVisible: boolean | undefined;
};

export const SideNavMenuItem: React.FC<SideNavMenuItemProps> = ({
  linkTo,
  testId,
  exact,
  label,
  isVisible
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <NavLink
      activeClassName={styles.activeNavLink}
      className={styles.navLink}
      to={linkTo}
      data-testid={testId}
      exact={exact}
    >
      <WSFlexBox.CenterY className={styles.subMenuOption}>
        <WSText.ParagraphSm color="gray600">{label}</WSText.ParagraphSm>
      </WSFlexBox.CenterY>
    </NavLink>
  );
};
