import {
  WSButton,
  WSButtons,
  WSControlGroup,
  WSForm
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";

export interface DefaultDueDateModalProps {
  dueInDaysValue: string;
  onSubmit: (data: { dueInDaysValue: string }) => void;
  onClose: () => void;
}

export const DUE_DATE_OPTIONS = [
  {
    label: "On created date",
    value: "0"
  },
  {
    label: "7 days after created date",
    value: "7"
  },
  {
    label: "15 days after created date",
    value: "15"
  },
  {
    label: "30 days after created date",
    value: "30"
  }
];

export const DefaultDueDateModal: React.FC<DefaultDueDateModalProps> = ({
  dueInDaysValue = "0",
  onSubmit,
  onClose
}) => {
  return (
    <WSForm
      onSubmit={data => {
        onSubmit(data);
        onClose();
      }}
      defaultValues={{
        dueInDaysValue
      }}
      validationSchema={Yup.object().shape({
        dueInDaysValue: Yup.string()
          .oneOf(DUE_DATE_OPTIONS.map(option => option.value))
          .required("This field is required")
      })}
    >
      <WSForm.Field
        name="dueInDaysValue"
        component={WSControlGroup}
        componentProps={{
          type: "radio",
          options: DUE_DATE_OPTIONS
        }}
      />

      <WSButtons p="S" format="modal">
        <WSButton.Primary size="S" type="submit">
          Save
        </WSButton.Primary>
        <WSButton.Tertiary size="S" onClick={onClose}>
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </WSForm>
  );
};
