import {
  WSElement,
  WSButton,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { ReactNode, useState } from "react";
import { Entries } from "../../../../types/utilities";

enum FaqType {
  DoesSuggestionHaveToBeAccepted = "0",
  WhatIfSuggestionNotAccepted = "1",
  CanIChangeSuggestion = "2"
}

interface FaqItem {
  title: string;
  content: string | ReactNode;
}

type Faq = { [key in FaqType]: FaqItem };

const faq: Faq = {
  [FaqType.DoesSuggestionHaveToBeAccepted]: {
    title: "Does my suggestion have to be accepted before filing?",
    content:
      "No, your payer has the discretion to accept or reject your suggestion and enter a different value before submitting the form to the IRS for filing."
  },
  [FaqType.WhatIfSuggestionNotAccepted]: {
    title: "What happens if my suggestion is not accepted?",
    content:
      "If your suggestion is not accepted, the payer will submit the 1099 form with the NEC amount they have determined. This means that the amount reported on the form may differ from what you suggested. Once the form is accepted by the IRS, you will receive a copy in February."
  },
  [FaqType.CanIChangeSuggestion]: {
    title: "If I make an error in my suggestion, can I change it?",
    content: (
      <WSElement color="gray600">
        <WSText my="XL">
          If you need to make a correction or update your suggestion, please
          contact <a href="mailto:support@wingspan.app">support@wingspan.app</a>{" "}
          or the payer associated with this 1099 form before the form is
          submitted for filing.
        </WSText>
        <WSText my="XL">
          They will be able to assist you with any errors. Please note that we
          currently only allow one suggestion to simplify the time-sensitive
          filing process.
        </WSText>
        <WSText my="XL">
          If you need to make a post-filing correction, you can do so after you
          receive your filed 1099 in February, in the same way you suggested a
          change before filing.
        </WSText>
      </WSElement>
    )
  }
};

const SuggestionFaq: React.FC = () => {
  const [openedFaq, setOpenedFaq] = useState<FaqType | null>(null);

  return (
    <WSElement mt="2XL">
      {(Object.entries(faq) as Entries<Faq>).map(([faqType, faq]) => (
        <>
          <WSButton.Link mt="XS" onClick={() => setOpenedFaq(faqType)}>
            {faq.title}
          </WSButton.Link>
          {openedFaq === faqType && (
            <WSModal
              size="S"
              allowClickOutsideToClose
              onClose={() => setOpenedFaq(null)}
            >
              <WSText.Heading5 my="XL">{faq.title}</WSText.Heading5>
              {typeof faq.content === "string" ? (
                <WSText color="gray600" my="XL">
                  {faq.content}
                </WSText>
              ) : (
                faq.content
              )}
            </WSModal>
          )}
        </>
      ))}
    </WSElement>
  );
};

export default SuggestionFaq;
