import {
  WSActions,
  WSButton,
  WSCentered,
  WSContainer,
  WSDivider,
  WSForm,
  WSGrid,
  WSInputText,
  WSInputTextarea,
  WSLayout,
  WSList,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import {
  IRequirementDefinitionResponse,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import React, { useMemo } from "react";
import { openInNewTab } from "../../../../utils/openInNewTab";
import { WS_LINKS } from "../../../../types/wsLinks";
import { CreateRequirementDefinitionFormValues } from "../../types";
import { CreateSignaturePartialForm } from "../../modules/Signature/createRequirementDefinition/CreateSignaturePartialForm";
import { useCreateSignatureRequirementDefinition } from "../../modules/Signature/createRequirementDefinition/useCreateSignatureRequirementDefinition";
import { validationSchema } from "./validationSchema";
import { signatureDefaultValues } from "../../modules/Signature/createRequirementDefinition/signatureDefaultValues";
import { CreateSharedFilePartialForm } from "../../modules/SharedFile/createRequirementDefinition/CreateSharedFilePartialForm";
import { sharedFileDefaultValues } from "../../modules/SharedFile/createRequirementDefinition/sharedFileDefaultValues";
import { useCreateSharedFileRequirementDefinition } from "../../modules/SharedFile/createRequirementDefinition/useCreateSharedFileRequirementDefinition";
import { cleanOptionalFormValues } from "../../../../shared/utils/cleanOptionalFormValues";
import { CreateBackgroundChecksPartialForm } from "../../modules/BackgroundChecks/createRequirementDefinition/CreateBackgroundChecksPartialForm";
import { useCreateBackgroundChecksRequirementDefinition } from "../../modules/BackgroundChecks/createRequirementDefinition/useCreateBackgroundChecksRequirementDefinition";
import { backgroundChecksDefaultValues } from "../../modules/BackgroundChecks/createRequirementDefinition/backgroundChecksDefaultValues";
import { REQUIREMENT_TYPE_TO_LABEL } from "../../selectors/selectorRequirementHumanReadableType";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";

type CreateRequirementDefinitionFormProps = {
  engagementId?: string;
  onBack: () => void;
  onSuccess: (requirement: IRequirementDefinitionResponse) => void;
};

const defaultValues: Partial<CreateRequirementDefinitionFormValues> = {
  name: "",
  description: "",
  type: "" as RequirementType,
  ...signatureDefaultValues,
  ...sharedFileDefaultValues,
  ...backgroundChecksDefaultValues
};

export const CreateRequirementDefinitionForm: React.FC<CreateRequirementDefinitionFormProps> = ({
  engagementId,
  onBack,
  onSuccess
}) => {
  const featureFlags = useFeatureFlags();

  const signatureRequirementCreation = useCreateSignatureRequirementDefinition({
    engagementId,
    onSuccess
  });

  const sharedFileRequirementCreation = useCreateSharedFileRequirementDefinition(
    {
      engagementId,
      onSuccess
    }
  );

  const backgroundCheckRequirementCreation = useCreateBackgroundChecksRequirementDefinition(
    {
      engagementId,
      onSuccess
    }
  );

  const requirementTypeOptions: {
    value: RequirementType;
    label: string;
  }[] = useMemo(() => {
    const options = [
      {
        value: RequirementType.Signature,
        label: REQUIREMENT_TYPE_TO_LABEL[RequirementType.Signature]
      },
      {
        value: RequirementType.SharedFile,
        label: REQUIREMENT_TYPE_TO_LABEL[RequirementType.SharedFile]
      }
    ];

    if (
      featureFlags.data?.backgroundChecks &&
      backgroundCheckRequirementCreation.isEnabled
    ) {
      options.push({
        value: RequirementType.BackgroundCheck,
        label: REQUIREMENT_TYPE_TO_LABEL[RequirementType.BackgroundCheck]
      });
    }

    return options;
  }, [featureFlags.data, backgroundCheckRequirementCreation.isEnabled]);

  return (
    <WSLayout
      headerCenter={
        <WSText.ParagraphSm weight="medium">
          Create requirement
        </WSText.ParagraphSm>
      }
      onClose={onBack}
    >
      <WSContainer verticalPadding>
        <WSCentered span={{ s: "10", m: "8", l: "6" }}>
          <WSText.Paragraph weight="medium" mb="S">
            Requirement information
          </WSText.Paragraph>

          <WSForm<CreateRequirementDefinitionFormValues>
            defaultValues={defaultValues}
            validationSchema={validationSchema}
            onSubmit={async formValues => {
              const submitByType: Record<
                RequirementType,
                (
                  formValues: CreateRequirementDefinitionFormValues
                ) => Promise<void>
              > = {
                [RequirementType.Signature]:
                  signatureRequirementCreation.onSubmit,
                [RequirementType.SharedFile]:
                  sharedFileRequirementCreation.onSubmit,
                [RequirementType.BackgroundCheck]:
                  backgroundCheckRequirementCreation.onSubmit
              };

              const cleanFormValues = cleanOptionalFormValues(formValues);

              if (submitByType[formValues.type]) {
                await submitByType[formValues.type](cleanFormValues);
              } else {
                throw new Error("Invalid requirement type");
              }
            }}
          >
            <WSList gap="XL">
              <WSGrid gutter={{ m: "2XL" }}>
                <WSGrid.Item span={{ m: "6", s: "12", xs: "12" }}>
                  <WSForm.Field
                    name="name"
                    label="Name"
                    placeholder="Requirement name"
                    component={WSInputText}
                    componentProps={{
                      required: true
                    }}
                  />
                </WSGrid.Item>

                <WSGrid.Item span={{ m: "6", s: "12", xs: "12" }}>
                  <WSForm.Field
                    name="type"
                    label="Type"
                    placeholder="Requirement type"
                    component={WSSelect}
                    componentProps={{
                      required: true,
                      options: requirementTypeOptions
                    }}
                  />
                </WSGrid.Item>
              </WSGrid>

              <WSForm.Field
                name="description"
                label="Description"
                placeholder="Requirement description"
                component={WSInputTextarea}
              />

              <WSDivider />
              <WSForm.Value name="type">
                {(type: RequirementType) => {
                  const partialFormByType: Record<
                    RequirementType,
                    React.ReactNode
                  > = {
                    [RequirementType.Signature]: <CreateSignaturePartialForm />,
                    [RequirementType.SharedFile]: (
                      <CreateSharedFilePartialForm />
                    ),
                    [RequirementType.BackgroundCheck]: (
                      <CreateBackgroundChecksPartialForm />
                    )
                  };

                  return partialFormByType[type] || null;
                }}
              </WSForm.Value>

              <WSErrorMessage
                contextKey={ErrorContextKey.Other}
                error={signatureRequirementCreation.errors}
              />
              <WSForm.Data<CreateRequirementDefinitionFormValues>>
                {formData => (
                  <WSActions
                    alignment="fill"
                    orientation="horizontal"
                    buttons={[
                      {
                        kind: "Tertiary",
                        label: "Cancel",
                        onClick: onBack
                      },
                      {
                        kind: "Primary",
                        label: "Continue",
                        type: "submit",
                        loading: signatureRequirementCreation.isLoading,
                        disabled: !validationSchema.isValidSync(formData)
                      }
                    ]}
                  />
                )}
              </WSForm.Data>
            </WSList>
          </WSForm>
          <WSButton.Link
            rightIcon="chevron-right"
            type="button"
            size="S"
            my="XL"
            onClick={() => openInNewTab(WS_LINKS.eligibilityRequirements)}
          >
            Learn more about requirements
          </WSButton.Link>
        </WSCentered>
      </WSContainer>
    </WSLayout>
  );
};
