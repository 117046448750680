import {
  WSActions,
  WSAlert,
  WSInfoBox,
  WSList,
  WSPanel,
  WSSectionToolbar
} from "@wingspanhq/fe-component-library";
import { OnboardingStep } from "../../types";

export const Reason: React.FC<OnboardingStep> = ({ title, onBack, onNext }) => {
  return (
    <WSList gap="2XL">
      <WSAlert
        icon="info-circle"
        theme="info"
        title="Requesting update(s) to your verified or tax-related information"
      >
        <WSInfoBox transparent>
          <p>
            The verified or tax-related fields are marked with a green checkmark
            in your account settings.
          </p>
          <p>
            Updates to tax-related information will update our records to ensure
            compliance with tax laws and regulations which can vary by country
            or region. Some update requests may require verification with the
            applicable tax authorities or the submission of support
            documentation - we will inform you if that is the case.
          </p>
          <p>
            <b>Select the reason(s) for your update request to proceed.</b>
          </p>
          <p>
            Or, if you need to make an update to any of the following
            information, use the links below instead (these do not require an
            update request):
          </p>
          <ul>
            <li>
              <b>Delivery Method</b>: update delivery method in{" "}
              <a>Tax Settings</a>.
            </li>
            <li>
              <b>Industry</b>: update industry or other editable fields in
              <a>Account Settings</a>.
            </li>
            <li>
              <b>Payout Information</b>: update payout method in{" "}
              <a>Payout Settings</a>.
            </li>
            <li>
              <b>Backup withholding</b>: notified of backup withholding
              requirements by tax authorities? <a>Click here</a>.
            </li>
          </ul>
        </WSInfoBox>
      </WSAlert>

      <WSPanel>
        <WSSectionToolbar title="Reasons for verified update request" />
      </WSPanel>

      <WSActions
        mb="2XL"
        alignment="fill"
        buttons={[
          {
            label: "Back",
            kind: "Secondary",
            onClick: onBack,
            visible: !!onBack
          },
          {
            label: "Continue",
            onClick: onNext
          }
        ]}
      />
    </WSList>
  );
};
