import {
  WSActions,
  WSEmptyState,
  WSEmptyStateProps,
  WSList,
  WSPanel
} from "@wingspanhq/fe-component-library";
import { OnboardingStepStatus } from "../types";

type Props = {
  status: OnboardingStepStatus;
  type?: WSEmptyStateProps["type"];
  title: WSEmptyStateProps["title"];
  description: WSEmptyStateProps["description"];
  onNext?: () => void;
};

export const StepResult: React.FC<Props> = ({
  status,
  type,
  title,
  description,
  onNext
}) => {
  const defaultType = status === "Complete" ? "done" : "documents";

  return (
    <WSList gap="2XL">
      <WSPanel>
        <WSEmptyState
          orientation="horizontal"
          type={type || defaultType}
          title={title}
          description={description}
        />
      </WSPanel>

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            onClick: onNext,
            visible: !!onNext
          }
        ]}
      />
    </WSList>
  );
};
