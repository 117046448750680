import { WSText } from "@wingspanhq/fe-component-library";
import React from "react";
import { FormBusinessInfo } from "../../../../shared/components/FormBusinessInfo";
import { LayoutPaymentsSetup } from "../../../../shared/components/LayoutPaymentsSetup";
import { useMutationUpdateCurrentStep } from "../../mutations/useMutationUpdateCurrentStep";
import { RouteComponentProps } from "../../types";

export const RouteBusiness: React.FC<RouteComponentProps> = ({
  onBack,
  onNext,
  params
}) => {
  const [updateCurrentStep] = useMutationUpdateCurrentStep();

  return (
    <LayoutPaymentsSetup onBack={onBack}>
      {!params?.skipTaxVerification && (
        <WSText.Heading5 mb="XL">Verify business information</WSText.Heading5>
      )}

      <FormBusinessInfo
        skipTaxVerification={params?.skipTaxVerification}
        onSuccess={() => {
          updateCurrentStep({ newStep: 2 });
          onNext();
        }}
      />
    </LayoutPaymentsSetup>
  );
};
