import {
  WSDateRangeSelectOptions,
  WSDivider,
  WSSidebar,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSInputDateOld,
  WSText,
  useIsMobile
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { getIRSQuarters } from "../../utils/taxes";
import styles from "./HeroRangeSelect.module.scss";

type DateRange = [Date, Date];

interface HeroRangeSelectProps extends Omit<WSElementProps, "onChange"> {
  value: DateRange;
  onChange(option: DateRangeOption): void;
  position?: "left" | "right";
  customLabel?: string;
}

const CURRENT_DATE = new Date();

type DateRangeOption = {
  value: DateRange;
  label: string;
};

const getStartOfDay = (year: number, month: number, day: number) => {
  return new Date(year, month, day, 0, 0, 0, 0);
};

const getEndOfDay = (year: number, month: number, day: number) => {
  return new Date(year, month, day, 23, 59, 59, 999);
};

const years = [CURRENT_DATE.getFullYear() - 1, CURRENT_DATE.getFullYear()];

const getMonths: (year: number) => DateRangeOption[] = year => {
  return [
    {
      value: [getStartOfDay(year, 0, 1), getEndOfDay(year, 11, 31)],
      label: "All"
    },
    ...[...Array(12).keys()].map(month => {
      const start = getStartOfDay(year, month, 1);
      const end = new Date(getStartOfDay(year, month + 1, 1).valueOf() - 1);
      const middle = new Date((end.valueOf() + start.valueOf()) / 2);

      return {
        value: [start, end] as DateRange,
        label: middle.toLocaleDateString("en-US", { month: "short" })
      };
    })
  ];
};

const getQuarters: (year: number) => DateRangeOption[] = year => {
  return getIRSQuarters(year).map(quarter => {
    return {
      label: `Q${quarter.quarter}`,
      value: [
        new Date(Date.parse(quarter.range[0])),
        new Date(Date.parse(quarter.range[1]))
      ] as DateRange
    };
  });
};

export type ExportType = {
  label: string;
  short: string;
  full: string;
};

export const getHeroRangeSelectLabel: (
  range: DateRange
) => ExportType = range => {
  const now = new Date();

  for (let y of years) {
    for (let m of getMonths(y)) {
      if (
        range[0].valueOf() === m.value[0].valueOf() &&
        range[1].valueOf() === m.value[1].valueOf()
      ) {
        if (m.label === "All") {
          break;
        }

        if (
          now.valueOf() >= m.value[0].valueOf() &&
          now.valueOf() < m.value[1].valueOf()
        ) {
          return {
            label: m.label,
            short: `${m.label} ${y}`,
            full: "This month"
          };
        }

        return {
          label: m.label,
          short: `${m.label} ${y}`,
          full: `In ${m.label} ${y}`
        };
      }
    }

    for (let q of getQuarters(y)) {
      if (
        range[0].valueOf() === q.value[0].valueOf() &&
        range[1].valueOf() === q.value[1].valueOf()
      ) {
        if (
          now.valueOf() >= q.value[0].valueOf() &&
          now.valueOf() < q.value[1].valueOf()
        ) {
          return {
            label: q.label,
            short: `${q.label} ${y}`,
            full: "This quarter"
          };
        }

        return {
          label: q.label,
          short: `${q.label} ${y}`,
          full: `In ${q.label} ${y}`
        };
      }
    }

    if (
      range[0].valueOf() === getStartOfDay(y, 0, 1).valueOf() &&
      range[1].valueOf() === getEndOfDay(y, 11, 31).valueOf()
    ) {
      if (now.getFullYear() === y) {
        return { label: `${y}`, short: `${y}`, full: "This year" };
      }

      return { label: `${y}`, short: `${y}`, full: `In ${y}` };
    }
  }

  const selectedOption = Object.values(WSDateRangeSelectOptions).find(
    option =>
      option.range?.[0].valueOf() === range[0].valueOf() &&
      option.range?.[1].valueOf() === range[1].valueOf()
  );

  if (selectedOption) {
    return {
      label: selectedOption.label,
      short: selectedOption.label,
      full: `From ${range[0].toLocaleDateString("en-US", {
        month: "short",
        day: "numeric"
      })} to ${range[1].toLocaleDateString("en-US", {
        month: "short",
        day: "numeric"
      })}`
    };
  }

  return {
    label: `Custom`,
    short: `Custom`,
    full: `From ${range[0].toLocaleDateString("en-US", {
      month: "short",
      day: "numeric"
    })} to ${range[1].toLocaleDateString("en-US", {
      month: "short",
      day: "numeric"
    })}`
  };
};

type DropDownProps = HeroRangeSelectProps & { onClose(): void };

const DropDown: React.FC<DropDownProps> = ({
  value,
  onChange,
  onClose,
  position = "right"
}) => {
  const isMobile = useIsMobile();
  const [currentYear, setCurrentYear] = useState(value[0].getFullYear());
  const [customRange, setCustomRange] = useState(value);
  const months = getMonths(currentYear);
  const quarters = getQuarters(currentYear);
  const currentMonth = months.find(
    r =>
      r.value[0].valueOf() === value[0].valueOf() &&
      r.value[1].valueOf() === value[1].valueOf()
  );
  const currentQuarter = quarters.find(
    r =>
      r.value[0].valueOf() === value[0].valueOf() &&
      r.value[1].valueOf() === value[1].valueOf()
  );

  useEffect(() => {
    setCustomRange(value);
  }, value);

  const content = (
    <>
      <WSFlexBox.Center p="S">
        {years.map(y => {
          return (
            <WSText
              inline
              p="S"
              key={y}
              color={y === currentYear ? "blue500" : "gray500"}
              onClick={event => {
                if (y === currentYear) {
                  return;
                }

                setCurrentYear(y);
                onChange(getMonths(y)[0]);
              }}
            >
              {y}
            </WSText>
          );
        })}
      </WSFlexBox.Center>
      <WSDivider />
      <WSFlexBox justify="space-evenly" p="S">
        {months.map(m => {
          return (
            <WSText
              inline
              p="S"
              key={m.label}
              color={m === currentMonth ? "blue500" : "gray500"}
              onClick={() => {
                onChange(m);
              }}
            >
              {m.label}
            </WSText>
          );
        })}
      </WSFlexBox>
      <WSDivider />
      <WSFlexBox.Center p="S">
        {quarters.map(q => {
          return (
            <WSText
              inline
              p="S"
              key={q.label}
              color={q === currentQuarter ? "blue500" : "gray500"}
              onClick={() => {
                onChange(q);
              }}
            >
              {q.label}
            </WSText>
          );
        })}
      </WSFlexBox.Center>
      <WSDivider />
      <WSElement p="S">
        <WSInputDateOld
          selectionMode="range"
          name="dateRange"
          value={customRange}
          onChange={(range: any) => {
            if (Array.isArray(range)) {
              const value = range as DateRange;
              setCustomRange(value);

              if (range[0] && range[1]) {
                onChange({
                  value,
                  label: "Custom"
                });
              }
            }
          }}
        />
      </WSElement>
    </>
  );

  return isMobile ? (
    <WSSidebar onClose={onClose}>{content}</WSSidebar>
  ) : (
    <WSElement
      className={classNames(
        styles.dropDown,
        position === "left" ? styles.positionLeft : styles.positionRight
      )}
    >
      {content}
    </WSElement>
  );
};

export const HeroRangeSelect: React.FC<HeroRangeSelectProps> = ({
  value,
  onChange,
  customLabel,
  position,
  color = "gray700",
  ...props
}) => {
  const isMobile = useIsMobile();
  const [open, setIsOpen] = useState(false);

  return (
    <WSElement className={styles.triggerWrapper} color={color} {...props}>
      <WSFlexBox.CenterY
        className={styles.trigger}
        onClick={() => setIsOpen(p => !p)}
        wrap="nowrap"
        data-testid="heroRangeSelect"
      >
        <WSIcon block name="calendar" mr="M" size="S" color={color} />
        <WSText color={color}>
          {customLabel ?? getHeroRangeSelectLabel(value).short}
        </WSText>
      </WSFlexBox.CenterY>
      {open ? (
        <>
          {isMobile ? null : (
            <WSElement
              className={styles.overlay}
              onClick={() => setIsOpen(false)}
            />
          )}
          <DropDown
            onClose={() => setIsOpen(false)}
            position={position}
            value={value}
            onChange={v => {
              setIsOpen(false);
              onChange(v);
            }}
          />
        </>
      ) : null}
    </WSElement>
  );
};
