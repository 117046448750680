import { IChannelNotificationResponse } from "@wingspanhq/notifications/dist/lib/interfaces";
import { selectorActiveNotifications } from "./selectorActiveNotifications";

import { NotificationHandleType } from "../components/Notifications";
import { BackgroundCheckRequestContractorContext } from "../components/Notifications/templates/BackgroundCheckRequestContractor";

export const selectorBackgroundCheckRequestContractorNotifications = (
  responses: IChannelNotificationResponse[]
) => {
  return selectorActiveNotifications(responses).filter(response => {
    return (
      response.handle ===
      NotificationHandleType.BackgroundCheckRequestContractor
    );
  }) as Array<
    Omit<IChannelNotificationResponse, "context"> & {
      context: BackgroundCheckRequestContractorContext;
    }
  >;
};
