import * as React from "react";
import {
  useIsMobile,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSIconName,
  WSText
} from "@wingspanhq/fe-component-library";
import styles from "./styles.module.scss";
import { toCamelCase } from "../../utils/stringHelper";
import { Tooltip } from "../Tooltip";
import classNames from "classnames";

interface CollapsibleMenuProps extends WSElementProps {
  icon: WSIconName;
  label: string;
  isExpanded: boolean;
  isSideNavOpened?: boolean;
  blocked?: boolean;
  isVisible: boolean | undefined;
}

export const CollapsibleMenu: React.FC<CollapsibleMenuProps> = ({
  icon,
  label,
  isExpanded,
  children,
  onClick,
  isSideNavOpened,
  isVisible,
  blocked
}) => {
  const isMobile = useIsMobile();

  if (!isVisible) {
    return null;
  }

  const content = (
    <WSFlexBox.CenterY
      px="XL"
      className={styles.collapsibleMenu}
      onClick={onClick}
      wrap="nowrap"
      data-testid={toCamelCase([label, "menu"])}
    >
      <WSIcon
        block
        name={icon}
        color={isSideNavOpened ? "gray600" : blocked ? "gray400" : "gray600"}
      />
      <WSText
        ml="XL"
        color={blocked ? "gray400" : "gray600"}
        className={styles.label}
      >
        {label}
        {blocked && (isSideNavOpened || isMobile) ? (
          <WSElement as="span" className={styles.icon} ml="S">
            <WSIcon block name="lock" color="gray500" size="XS" />
          </WSElement>
        ) : null}
      </WSText>
      <WSIcon
        block
        name={isExpanded ? "chevron-up" : "chevron-down"}
        color="gray600"
        data-testid="menu-arrow-indicator"
      />
    </WSFlexBox.CenterY>
  );
  return (
    <>
      {isSideNavOpened || isMobile ? (
        content
      ) : (
        <Tooltip className={styles.tooltip} trigger={content} placement="right">
          <WSText color="white" className={styles.label}>
            {label}
          </WSText>
        </Tooltip>
      )}
      <WSFlexBox
        className={classNames(styles.subMenuContainer, {
          [styles.expanded]: isExpanded
        })}
        direction="column"
      >
        {children}
      </WSFlexBox>
    </>
  );
};
