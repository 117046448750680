import {
  PaymentCardListQuery,
  paymentsService
} from "../../../services/payments";
import { useWSQuery } from "../../helpers";
import { QUERY_PAYMENT_CARDS } from "../keys";

export const useQueryPaymentsCards = (query?: PaymentCardListQuery) =>
  useWSQuery([QUERY_PAYMENT_CARDS, query], () =>
    paymentsService.paymentCard.list({
      page: { size: 1000, number: 1 },
      ...query
    })
  );
