import { useHistory } from "react-router-dom";
import { PATH_ONBOARDING_PAYEE } from "..";
import { OnboardingFlow } from "../components/OnboardingFlow";
import { OnboardingModule } from "../types";

const payeeOnboardingModules: OnboardingModule[] = [
  {
    type: "create_account",
    options: {
      accountTypes: ["Business", "Individual"],
      verificationLevel: "Tax",
      show1099Message: true
    }
  },
  {
    type: "certify_tax_payer_info"
  },
  {
    type: "payout_method"
  },
  {
    type: "eligibility_requirements"
  }
];

export const RoutePayeeOnboarding: React.FC = () => {
  const history = useHistory();

  return (
    <OnboardingFlow
      basePath={PATH_ONBOARDING_PAYEE}
      modules={payeeOnboardingModules}
      onSuccess={() => {
        history.push("/member/dashboard");
      }}
    />
  );
};
