import React from "react";
import { WSMessageBox } from "@wingspanhq/fe-component-library";

export const MISSING_FIELD = {
  name: {
    title: "Recipient name missing",
    getMessage: () => (
      <>
        The recipient is missing a Name. This must be the legal business name
        for companies or the legal first and last name for individuals.{" "}
      </>
    )
  },
  companyStructure: {
    title: "Federal tax classification missing",
    getMessage: () => <>The recipient is missing a tax status.</>
  },
  address: {
    title: "Recipient address missing",
    getMessage: () => (
      <>The recipient address where the 1099 should be sent is missing. </>
    )
  },
  tin: {
    title: "Recipient TIN (Tax Identification Number) missing",
    getMessage: () => (
      <>
        The recipient is missing a TIN. This can be either an SSN (Social
        Security Number) or an EIN (Employer Identification Number).{" "}
      </>
    )
  },
  unverifiedSSN: {
    title: "Unverified SSN",
    getMessage: () => null
  },
  unverifiedEIN: {
    title: "Unverified EIN",
    getMessage: () => null
  }
};

export const MissingFieldErrorMessages: React.FC<{
  name: keyof typeof MISSING_FIELD;
}> = ({ name }) => {
  return (
    <WSMessageBox.Error
      mb="2XL"
      title={MISSING_FIELD[name].title}
      noBorder
      icon="alert-circle"
    >
      {MISSING_FIELD[name].getMessage()}
    </WSMessageBox.Error>
  );
};
