// @ts-nocheck
// https://github.com/featurist/browser-pdf-support
export const isPDFSupported: () => boolean = () => {
  function hasAcrobatInstalled() {
    function getActiveXObject(name) {
      try {
        return new ActiveXObject(name);
      } catch (e) {}
    }

    return getActiveXObject("AcroPDF.PDF") || getActiveXObject("PDF.PdfCtrl");
  }

  return !!(navigator.mimeTypes["application/pdf"] || hasAcrobatInstalled());
};
