import {
  toWSDateString,
  WSButton,
  WSButtons,
  WSInputDateOld,
  WSFormOld,
  WSMessageBox,
  WSModal,
  WSPage,
  WSScreen,
  WSSelectOld,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { addDays, addYears } from "date-fns";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { useUpdateApiSession } from "../../query/users/mutations";
import { useGetAPISession } from "../../query/users/queries";
import { WSQuery } from "../../query/WSQuery";
import { goToParentRoute } from "../../utils/goToParentRoute";
import {
  EXPIRATION_IN_DAYS,
  getExpirationSelectOptions
} from "../utils/developerUtils";
import styles from "./AddNewToken.module.scss";

export interface EditNewTokenProps {}

export const EditNewToken: React.FC<EditNewTokenProps> = () => {
  const history = useHistory();
  const params: { sessionId: string } = useParams();
  const [expirationValue, setExpirationValue] = useState("30");
  const [updateApiSession] = useUpdateApiSession();
  const getApiSessionQuery = useGetAPISession(params.sessionId);

  const onFormSubmit = async (data: any) => {
    // For NO_EXPIRATION, we are setting expiry for 10yrs but
    // we don't use it as we set isUnlimitedExpiration to true.
    let expirationDate = addYears(new Date(), 10);
    let isUnlimitedExpiration = false;
    if (EXPIRATION_IN_DAYS.includes(data.expiration)) {
      expirationDate = addDays(new Date(), Number(data.expiration));
    } else if (data.expiration === "CUSTOM") {
      expirationDate = data.customExpiryDate;
    } else if (data.expiration === "NO_EXPIRATION") {
      isUnlimitedExpiration = true;
    }
    // TODO: fix IApiSessionUpdateRequest interface
    const request: any = {
      expiration: expirationDate,
      isUnlimitedExpiration,
      labels: {
        description: data.whatIsThisFor
      }
    };
    await updateApiSession(
      {
        ...request,
        id: getApiSessionQuery.data?.sessionId as string
      },
      {
        onSuccess: () => {
          history.push("/member/settings/tokens");
        }
      }
    );
  };

  const editTokenForm = (
    <WSQuery query={getApiSessionQuery}>
      {({ data: session }) => {
        return (
          <WSFormOld
            defaultValues={{
              whatIsThisFor: session.labels?.description || "",
              expiration: expirationValue
            }}
            validationSchema={Yup.object().shape({
              expiration: Yup.string().required("Expiration is required."),
              customExpiryDate: Yup.date().when("expiration", {
                is: "CUSTOM",
                then: Yup.date().required("Custom expiry date is required.")
              })
            })}
            onSubmit={onFormSubmit}
          >
            <WSFormOld.Field
              mb="XL"
              name="whatIsThisFor"
              label="What's this token for? (optional)"
              component={WSTextInput}
            />

            <WSFormOld.Field
              mb="XL"
              name="expiration"
              label="Expiration"
              component={WSSelectOld}
              componentProps={{
                onChange: val => {
                  setExpirationValue(val);
                },
                options: getExpirationSelectOptions()
              }}
              description={
                EXPIRATION_IN_DAYS.includes(expirationValue)
                  ? `The token will expire on ${toWSDateString(
                      addDays(new Date(), Number(expirationValue)),
                      "monthDayYear"
                    )}`
                  : undefined
              }
            />

            {expirationValue === "CUSTOM" ? (
              <WSFormOld.Field
                my="XL"
                name="customExpiryDate"
                component={WSInputDateOld}
                componentProps={{
                  minDate: new Date()
                }}
              />
            ) : null}

            {expirationValue === "NO_EXPIRATION" ? (
              <WSMessageBox icon="alert-circle" mb="XL">
                Tokens that live forever are scary. Expiration dates are highly
                recommended!
              </WSMessageBox>
            ) : null}

            <WSButtons format="modal">
              <WSFormOld.SubmitButton>Generate</WSFormOld.SubmitButton>
              <WSButton.Tertiary
                onClick={() => history.replace("/member/settings/tokens")}
              >
                Cancel
              </WSButton.Tertiary>
            </WSButtons>
          </WSFormOld>
        );
      }}
    </WSQuery>
  );
  return (
    <>
      <BrowserPageTitle title="Generate token" />
      <WSScreen.MobileAndTablet>
        <WSPage
          title="Edit API token"
          breadcrumb={{
            label: "Developer settings",
            icon: "arrow-left",
            onClick: () => goToParentRoute(history)
          }}
        >
          {editTokenForm}
        </WSPage>
      </WSScreen.MobileAndTablet>
      <WSScreen.Desktop>
        <WSModal
          size="S"
          onClose={() => goToParentRoute(history)}
          title="Edit API token"
          className={styles.addNewTokenModal}
          blockClose
        >
          {editTokenForm}
        </WSModal>
      </WSScreen.Desktop>
    </>
  );
};
