import {
  toWSDateString,
  useModalContext,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { ScheduleStatus } from "@wingspanhq/payments/dist/interfaces";
import classNames from "classnames";
import React from "react";
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import {
  useInvoicesQuery,
  useInvoiceTemplatesQuery,
  useMemberClientQuery
} from "../../../query/payments/queries";
import {
  getClientCompany,
  getClientName,
  getInvoice,
  getInvoiceTemplate
} from "../../../query/payments/selectors";
import { WSQueries } from "../../../query/WSQuery";
import { InvoicesInvoiceDetails } from "../../screens/InvoicesInvoiceDetails";
import { InvoicesSeriesInvoiceEdit } from "../../screens/InvoicesSeriesInvoiceEdit";
import {
  SeriesInvoiceActionsModal,
  SERIES_INVOICE_ACTIONS_MODAL
} from "../SeriesInvoiceActionsModal/SeriesInvoiceActionsModal";
import styles from "./SeriesList.module.scss";

type Props = WSElementProps & {
  invoiceTemplateId: string;
};

export const SeriesList: React.FC<Props> = ({
  invoiceTemplateId,
  ...elementProps
}) => {
  const invoicesQuery = useInvoicesQuery();
  const invoiceTemplatesQuery = useInvoiceTemplatesQuery();
  const invoiceTemplate = getInvoiceTemplate(
    invoiceTemplatesQuery.data || [],
    invoiceTemplateId
  );
  const memberClientQuery = useMemberClientQuery(
    invoiceTemplate?.invoiceData.memberClientId as string,
    {
      retry: false,
      enabled: !!invoiceTemplate?.invoiceData.memberClientId
    }
  );
  const { openModal } = useModalContext();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  return (
    <WSElement {...elementProps}>
      <SeriesInvoiceActionsModal />

      <Route
        path={`${match.path}/:scheduleDateIndex`}
        component={InvoicesSeriesInvoiceEdit}
        exact
      />
      <Route
        path={`${match.path}/invoice/:invoiceId`}
        component={InvoicesInvoiceDetails}
        exact
      />
      <WSQueries
        queries={{ invoiceTemplatesQuery, memberClientQuery, invoicesQuery }}
      >
        {({
          invoiceTemplatesQuery: { data: invoiceTemplates },
          memberClientQuery: { data: memberClient },
          invoicesQuery: { data: invoices }
        }) => {
          const invoiceTemplate = getInvoiceTemplate(
            invoiceTemplates,
            invoiceTemplateId
          );

          if (!invoiceTemplate) {
            return null;
          }

          return (
            <>
              {(invoiceTemplate.scheduleDates || []).map(
                (scheduleDate, index) => {
                  const invoiceId =
                    scheduleDate.status === ScheduleStatus.Completed &&
                    scheduleDate.invoiceId
                      ? scheduleDate.invoiceId
                      : undefined;

                  const invoice = invoiceId
                    ? getInvoice(invoices, invoiceId)
                    : undefined;

                  return (
                    <WSElement
                      className={classNames(styles.item, {
                        [styles.completed]: scheduleDate.date < new Date()
                      })}
                      onClick={() => {
                        if (invoice) {
                          history.push({
                            pathname: `${location.pathname}/invoice/${invoice.invoiceId}`,
                            state: {
                              backPath: location.pathname
                            }
                          });
                        } else {
                          openModal(SERIES_INVOICE_ACTIONS_MODAL, {
                            parentInvoiceTemplateId: invoiceTemplateId,
                            scheduleDateIndex: index
                          });
                        }
                      }}
                      mb="XL"
                    >
                      <WSText.ParagraphSm mb="XS">
                        {scheduleDate.status === ScheduleStatus.Completed &&
                        invoice
                          ? `Due ${toWSDateString(
                              invoice.dueDate,
                              "monthDate"
                            )}`
                          : `${
                              scheduleDate.status === ScheduleStatus.Skipped
                                ? scheduleDate.date > new Date()
                                  ? "Skipping"
                                  : "Skipped"
                                : "Scheduled"
                            } ${toWSDateString(
                              scheduleDate.date,
                              "monthDate"
                            )}`}
                      </WSText.ParagraphSm>
                      <WSText weight="medium" mb="XS">
                        {getClientCompany(memberClient) ||
                          getClientName(memberClient)}{" "}
                        – Invoice #
                        {scheduleDate.status === ScheduleStatus.Skipped
                          ? " Skipped"
                          : invoice
                          ? invoice.invoiceNumber
                          : " Scheduled"}
                      </WSText>
                      <WSText>
                        Invoice {index + 1} of{" "}
                        {invoiceTemplate.scheduleDates?.length}
                      </WSText>
                    </WSElement>
                  );
                }
              )}
            </>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
