import {
  CompanyStructure,
  IMember,
  INewUser
} from "@wingspanhq/users/dist/lib/interfaces";
import { selectorAddressDefaultValues } from "../FormPartialAddress";
import { selectorIndustryOptions } from "../../selectors/selectorIndustryOptions";
import { FormData } from "./types";

export const selectorDefaultValues = (
  user: INewUser,
  member: IMember,
  isOnlyOneBusinessOwner?: boolean | null
): FormData => ({
  iHaveEin: !!member.profile.company?.taxId,
  company: {
    legalBusinessName: member.profile.company?.legalBusinessName || "",
    businessType: member.profile.company?.structure || CompanyStructure.None,
    ein: member.profile.company?.taxId || "",
    stateOfIncorporation: member.profile.company?.stateOfIncorporation || "",
    yearOfIncorporation: member.profile.company?.yearOfIncorporation || "",
    legalFirstName: user.profile.firstName || "",
    legalLastName: user.profile.lastName || "",
    isOnlyOneBusinessOwner:
      typeof isOnlyOneBusinessOwner === "boolean"
        ? isOnlyOneBusinessOwner
        : true
  },
  businessName: member.profile.company?.name || "",
  industry: member.profile.industry || selectorIndustryOptions(user)[0].value,
  businessAddress: member.profile.address
    ? selectorAddressDefaultValues(member.profile.address)
    : {
        country: "US",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: ""
      }
});
