import cn from "classnames";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSIcon } from "../../core/WSIcon/WSIcon.component";
import { WSText } from "../../core/WSText/WSText.component";
import { WSBadge } from "../WSBadge/WSBadge.component";
import styles from "./WSTabs.module.scss";

export interface WSTab {
  label: string;
  render?: () => React.ReactNode;
  badge?: number;
  onClick?: () => void;
}

export type TabChangeHandler = (newIndex: number) => void;

export interface WSTabsNavigationProps extends WSElementProps {
  tabs: Omit<WSTab, "render">[];
  activeIndex?: number;
  onTabChange?: TabChangeHandler;
}

export const WSTabsNavigation: React.FC<WSTabsNavigationProps> = ({
  tabs,
  activeIndex,
  onTabChange,
  className,
  ...otherProps
}) => {
  const refContent = useRef<HTMLDivElement>(null);
  const [isButtonBackwardVisible, setIsButtonBackwardVisible] = useState(false);
  const [isButtonForwardVisible, setIsButtonForwardVisible] = useState(false);

  const updateButtonsState = useCallback(() => {
    if (!refContent.current) return;

    const { scrollLeft, scrollWidth, clientWidth: width } = refContent.current;

    setIsButtonBackwardVisible(scrollLeft > 0);
    setIsButtonForwardVisible(Math.ceil(scrollLeft) >= scrollWidth - width);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateButtonsState);
    updateButtonsState();
    return () => window.removeEventListener("resize", updateButtonsState);
  }, [updateButtonsState]);

  const onButtonBackwardClick = useCallback(() => {
    if (!refContent.current) return;

    const { scrollLeft, clientWidth: width } = refContent.current;
    const pos = scrollLeft - width;

    refContent.current.scrollLeft = pos <= 0 ? 0 : pos;
  }, []);

  const onButtonForwardClick = useCallback(() => {
    if (!refContent.current) return;

    const { scrollLeft, clientWidth: width, scrollWidth } = refContent.current;
    const pos = scrollLeft + width;

    refContent.current.scrollLeft = pos >= scrollWidth ? scrollWidth : pos;
  }, []);

  // TODO(artem): revisit icons once we update WSIcon
  const buttonBackward = useMemo(
    () => (
      <WSElement
        onClick={onButtonBackwardClick}
        className={styles.buttonPrevious}
      >
        <WSIcon block size="M" name="chevron-left" />
      </WSElement>
    ),
    [onButtonBackwardClick]
  );
  const buttonForward = useMemo(
    () => (
      <WSElement onClick={onButtonForwardClick} className={styles.buttonNext}>
        <WSIcon block size="M" name="chevron-right" />
      </WSElement>
    ),
    [onButtonForwardClick]
  );

  return (
    <WSElement
      className={cn(styles.navigationContainer, className)}
      {...otherProps}
    >
      {isButtonBackwardVisible && buttonBackward}

      <WSElement
        ref={refContent}
        className={styles.navigationContent}
        onScroll={updateButtonsState}
      >
        <WSElement className={styles.navigation}>
          {tabs.map((tab, index) => {
            const isActive = activeIndex === index;
            return (
              <WSElement
                key={tab.label}
                className={cn(styles.item, isActive && styles.active)}
                onClick={() => {
                  onTabChange?.(index);
                  tab.onClick?.();
                }}
              >
                <WSText.ParagraphSm className={styles.label}>
                  {tab.label}
                </WSText.ParagraphSm>

                {tab.badge !== undefined && (
                  <WSBadge
                    theme={isActive ? "dark" : "neutral"}
                    size="S"
                    ml="S"
                    number={tab.badge}
                  />
                )}
              </WSElement>
            );
          })}
        </WSElement>
      </WSElement>

      {!isButtonForwardVisible && buttonForward}
    </WSElement>
  );
};

export interface WSTabsContentProps extends WSElementProps {}

export const WSTabsContent: React.FC<WSTabsContentProps> = ({
  className,
  ...otherProps
}) => <WSElement className={cn(styles.content, className)} {...otherProps} />;

export type WSTabsProps = {
  tabs: WSTab[];
  initialIndex?: number;
  onTabChange?: TabChangeHandler;
  navigationClassName?: string;
  contentClassName?: string;
} & WSElementProps;

export const WSTabs: React.FC<WSTabsProps> = ({
  tabs,
  onTabChange,
  initialIndex,
  className,
  navigationClassName,
  contentClassName,
  ...otherProps
}) => {
  const [activeIndex, setActiveIndex] = useState(initialIndex ?? 0);

  const activeTab = tabs[activeIndex];

  return (
    <WSElement className={className} {...otherProps}>
      <WSTabsNavigation
        tabs={tabs}
        activeIndex={activeIndex}
        onTabChange={(index) => {
          setActiveIndex(index);
          onTabChange?.(index);
        }}
        className={navigationClassName}
      />
      {activeTab.render && (
        <WSTabsContent className={contentClassName}>
          {activeTab.render()}
        </WSTabsContent>
      )}
    </WSElement>
  );
};
