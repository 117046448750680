import * as React from "react";
import {
  useIsMobile,
  WSBadge,
  WSFlexBox,
  WSIcon,
  WSIconName,
  WSText
} from "@wingspanhq/fe-component-library";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";
import { Tooltip } from "../Tooltip";

interface NormalLinkOptionProps {
  to: string;
  testId: string;
  icon: WSIconName;
  label: string;
  isSideNavOpened?: boolean;
  count?: number;
  isVisible: boolean | undefined;
}

export const SideNavRootLinkOption: React.FC<NormalLinkOptionProps> = ({
  to,
  icon,
  label,
  testId,
  count,
  isVisible,
  isSideNavOpened
}) => {
  const isMobile = useIsMobile();
  const isFullSize = isSideNavOpened || isMobile;

  if (!isVisible) {
    return null;
  }

  const content = (
    <NavLink
      activeClassName={styles.activeNavLink}
      className={styles.navLink}
      to={to}
      data-testid={testId}
    >
      <WSFlexBox.CenterY px="XL" className={styles.defaultMainMenuOption}>
        <WSIcon block name={icon} color="gray600" />
        {isFullSize ? (
          <WSText ml="XL" color="gray600" className={styles.label}>
            {label}
          </WSText>
        ) : null}
      </WSFlexBox.CenterY>

      {count ? (
        <WSBadge
          className={styles.eventsCounter}
          number={1}
          theme="amber"
          size="S"
        />
      ) : null}
    </NavLink>
  );
  return isFullSize ? (
    content
  ) : (
    <Tooltip trigger={content} placement="right">
      <WSText color="white" className={styles.label}>
        {label}
      </WSText>
    </Tooltip>
  );
};
