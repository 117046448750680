import React, { ComponentType, useCallback, useContext, useState } from "react";

export interface ModalCloseContext {
  [key: string]: any;
}
export interface ModalBaseProps {
  onClose?: (context?: ModalCloseContext) => void;
}

export interface ModalRecord<Props extends ModalBaseProps = any> {
  name: string;
  visible: boolean;
  props: Props;
}

export interface IWSModalContext<Props extends ModalBaseProps = any> {
  modals: ModalRecord<Props>[];
  addModal: (name: string) => void;
  removeModal: (name: string) => void;
  openModal: (name: string, props?: Props) => void;
  openModalAsync: (
    name: string,
    props?: Props
  ) => Promise<ModalCloseContext | void>;
  closeModal: (name: string, context?: ModalCloseContext) => void;
}

export const WSModalContext = React.createContext<IWSModalContext>({
  modals: [],
  addModal: () => {},
  removeModal: () => {},
  openModal: () => {},
  openModalAsync: () => new Promise(() => {}),
  closeModal: () => {}
});

export const useModalContext = () => useContext(WSModalContext);

export const WSModalProvider: React.FC = ({ children }) => {
  const [modals, setModals] = useState<ModalRecord[]>([]);

  const addModal = useCallback(
    (name: string) => {
      setModals((prevModals) => [
        ...prevModals,
        {
          name,
          visible: false,
          props: {}
        }
      ]);
    },
    [setModals]
  );

  const removeModal = useCallback(
    (name: string) => {
      setModals((prevModals) =>
        prevModals.filter((modal) => modal.name !== name)
      );
    },
    [setModals]
  );

  const updateModal = useCallback(
    (name: string, data: Partial<ModalRecord>) => {
      setModals((prevModals) =>
        prevModals.map((modal) => {
          if (modal.name === name) {
            return {
              ...modal,
              ...data
            };
          }
          return modal;
        })
      );
    },
    [setModals]
  );

  const openModal = useCallback(
    (name: string, props?: any) => {
      updateModal(name, { visible: true, props });
    },
    [updateModal]
  );

  const openModalAsync = useCallback(
    (name: string, props?: any) =>
      new Promise<ModalCloseContext | void>((resolve) => {
        openModal(name, {
          ...props,
          onClose: resolve
        });
      }),
    [openModal]
  );

  const closeModal = useCallback(
    (name: string, context?: ModalCloseContext) => {
      const modalRecord = modals.find((record) => record.name === name);

      updateModal(name, { visible: false });

      if (modalRecord?.props?.onClose) {
        modalRecord.props.onClose(context);
      }
    },
    [modals, updateModal]
  );

  return (
    <>
      <WSModalContext.Provider
        value={{
          modals,
          addModal,
          removeModal,
          openModal,
          openModalAsync,
          closeModal
        }}
      >
        {children}
      </WSModalContext.Provider>
    </>
  );
};

export const withWSModalProvider = (Component: ComponentType) => () => (
  <WSModalProvider>
    <Component />
  </WSModalProvider>
);
