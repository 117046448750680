import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { InvoiceStatus } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import { TablePayables } from "../../../Invoices/components/TablePayables";
import { CompletedDeductionDetails } from "../../../Invoices/screens/deductions/CompletedDuductionDetails";
import { ScheduledDeductionDetails } from "../../../Invoices/screens/deductions/ScheduledDuductionDetails";
import { PayableDetails } from "../../../Invoices/screens/payables/PayableDetails";
import { WSQueries } from "../../../query/WSQuery";
import {
  useCollaboratorsQuery,
  useQueryPayables
} from "../../../query/payments/queries";
import { DownloadCsv } from "./DownloadCsv";

const filtersOverdue = {
  status: {
    in: [InvoiceStatus.Overdue]
  }
};

const filtersOpen = {
  status: {
    in: [InvoiceStatus.Pending, InvoiceStatus.Open]
  }
};

const filtersPaid = {
  status: {
    in: [InvoiceStatus.Paid, InvoiceStatus.PaymentInTransit]
  }
};

type Props = WSElementProps;

export const PaymentHistory: React.FC<Props> = ({ ...elementProps }) => {
  const queryPayablesOverdue = useQueryPayables(10, {
    filters: filtersOverdue
  });
  const queryPayablesOpen = useQueryPayables(10, { filters: filtersOpen });
  const queryPayablesPaid = useQueryPayables(10, { filters: filtersPaid });
  const queryCollaborators = useCollaboratorsQuery();
  const match = useRouteMatch();
  const history = useHistory();

  return (
    <WSElement {...elementProps}>
      <Route
        path={`${match.path}/:payableId/deduction/scheduled/:deductionId`}
        component={ScheduledDeductionDetails}
      />
      <Route
        path={`${match.path}/:payableId/deduction/completed/:deductionId`}
        component={CompletedDeductionDetails}
      />
      <Route
        path={`${match.path}/:payableId`}
        component={PayableDetails}
        exact
      />

      <WSQueries
        queries={{
          queryPayablesOverdue,
          queryPayablesPaid,
          queryPayablesOpen,
          queryCollaborators
        }}
      >
        {({
          queryPayablesOverdue: {
            data: { data: overduePayables }
          },
          queryPayablesPaid: {
            data: { data: paidPayables }
          },
          queryPayablesOpen: {
            data: { data: openPayables }
          },
          queryCollaborators: { data: collaborators }
        }) => (
          <>
            {overduePayables.length > 0 && (
              <WSElement mb="2XL">
                <WSFlexBox wrap="nowrap" justify="space-between" mb="XL">
                  <WSText.Heading5>Overdue invoices</WSText.Heading5>

                  <DownloadCsv
                    collaborators={collaborators}
                    filters={filtersOverdue}
                    fileName="Overdue payables"
                  />
                </WSFlexBox>

                <TablePayables
                  showHeaders
                  payables={overduePayables}
                  collaborators={collaborators}
                  onRowClick={({
                    data: {
                      payable: { payableId }
                    }
                  }) => {
                    history.push(`/payment/${payableId}`);
                  }}
                  showRefundedLabel
                />
                {queryPayablesOverdue.canFetchMore && (
                  <WSButton.Link
                    mt="M"
                    fullWidth
                    onClick={() => {
                      queryPayablesOverdue.fetchMore();
                    }}
                    loading={!!queryPayablesOverdue.isFetchingMore}
                  >
                    Load more
                  </WSButton.Link>
                )}
              </WSElement>
            )}

            {openPayables.length > 0 && (
              <WSElement mb="2XL">
                <WSFlexBox wrap="nowrap" justify="space-between" mb="XL">
                  <WSText.Heading5>Open invoices</WSText.Heading5>
                  <DownloadCsv
                    collaborators={collaborators}
                    filters={filtersOpen}
                    fileName="Open payables"
                  />
                </WSFlexBox>

                <TablePayables
                  showHeaders
                  payables={openPayables}
                  collaborators={collaborators}
                  onRowClick={({
                    data: {
                      payable: { payableId }
                    }
                  }) => {
                    history.push(`/payment/${payableId}`);
                  }}
                  showRefundedLabel
                />

                {queryPayablesOpen.canFetchMore && (
                  <WSButton.Link
                    mt="M"
                    fullWidth
                    onClick={() => {
                      queryPayablesOpen.fetchMore();
                    }}
                    loading={!!queryPayablesOpen.isFetchingMore}
                  >
                    Load more
                  </WSButton.Link>
                )}
              </WSElement>
            )}
            {paidPayables.length > 0 && (
              <WSElement>
                <WSFlexBox wrap="nowrap" justify="space-between" mb="XL">
                  <WSText.Heading5>Payment history</WSText.Heading5>
                  <DownloadCsv
                    collaborators={collaborators}
                    filters={filtersPaid}
                    fileName="Payment history"
                  />
                </WSFlexBox>

                <TablePayables
                  showHeaders
                  payables={paidPayables}
                  collaborators={collaborators}
                  onRowClick={({
                    data: {
                      payable: { payableId }
                    }
                  }) => {
                    history.push(`${match.path}/${payableId}`, {
                      backPath: match.path
                    });
                  }}
                  showRefundedLabel
                />

                {queryPayablesPaid.canFetchMore && (
                  <WSButton.Link
                    mt="M"
                    fullWidth
                    onClick={() => {
                      queryPayablesPaid.fetchMore();
                    }}
                    loading={!!queryPayablesPaid.isFetchingMore}
                  >
                    Load more
                  </WSButton.Link>
                )}
              </WSElement>
            )}

            {openPayables.length === 0 && paidPayables.length === 0 && (
              <>
                <WSText.Heading5 mb="XS">Payment history</WSText.Heading5>
                <WSText color="gray400">No invoices found</WSText>
              </>
            )}
          </>
        )}
      </WSQueries>
    </WSElement>
  );
};
