import {
  useIsMobile,
  WSContainer,
  WSFlexBox,
  WSIcon,
  WSLayout,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { WingspanProductAspects } from "@wingspanhq/users/dist/lib/interfaces";
import queryString from "query-string";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Header } from "../../Auth/components/Header/Header";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { useCMSWebflowPartner } from "../../query/cms/queries";
import { useLocalStorage } from "../../shared/hooks/useLocalStorage";
import { useSetWSStore, useWSStore } from "../../store";
import { GrowthSourceNames } from "../../utils/growthAttribution";
import { OnboardingQuestion } from "../components/OnboardingQuestion/OnboardingQuestion";
import styles from "./styles.module.scss";

interface OnboardingStep3BenefitsProps {}

export const OnboardingStep3Benefits: React.FC<OnboardingStep3BenefitsProps> = () => {
  const [answer, setAnswer] = useLocalStorage("pre-sign-up:q3", "");
  const isMobile = useIsMobile();
  const history = useHistory();
  const location = useLocation();
  const setStore = useSetWSStore();
  const store = useWSStore();

  const queries = queryString.parse(window.location.search);
  const partnerId =
    (store.growthAttributionDetails?.growthSource ===
      GrowthSourceNames.Partner &&
      store.growthAttributionDetails?.growthName) ||
    "";
  const qPartner = useCMSWebflowPartner(partnerId, {
    enabled: partnerId,
    onSuccess(partner) {
      setStore({ signUpType: partner.signUpType });
      setStore({ discountCode: partner.stripeDiscountCode });
    }
  });

  const partner = qPartner.data;

  const onBack = () => {
    history.push(`/member/pre-sign-up/questionnaire/2${location.search}`);
  };

  const onSubmit = async (data: any) => {
    setAnswer(data.productAspectImportance);
    history.push(`/member/sign-up${location.search}`);
  };
  return (
    <WSLayout
      onBack={isMobile ? undefined : onBack}
      line
      headerCenter={<Header hideBorder partnerLogo={partner?.logo?.src} />}
    >
      <BrowserPageTitle title="Onboarding: Step 3 | Benefits" />
      <WSMessageBox kind="Warning" noBorder centered className={styles.banner}>
        {" "}
      </WSMessageBox>
      <WSContainer verticalPadding>
        {isMobile ? (
          <WSFlexBox.CenterY mb="M" onClick={() => history.goBack()}>
            <WSIcon
              block
              name="chevron-left"
              size="XS"
              mr="XS"
              color="gray600"
            />
            <WSText.ParagraphSm color="gray500">
              <b>Back</b>
            </WSText.ParagraphSm>
          </WSFlexBox.CenterY>
        ) : null}
        <OnboardingQuestion
          name="productAspectImportance"
          question={{
            question: "What could be better?",
            options: [
              {
                label: "Finding health insurance",
                value: WingspanProductAspects.HealthInsurance,
                virtualAssistantMsg:
                  "Health insurance is HARD. The Wingspan insurance team will work with you to navigate the complexities of finding and purchasing health insurance.",
                ctaBtnText: "Try Benefits"
              },
              {
                label: "Saving money on medical bills",
                value: WingspanProductAspects.SavingMoneyOnMedicine,
                virtualAssistantMsg:
                  "Skip the doctor and get free 24/7 access to virtual care, 1:1 support negotiating insurance bills, savings on prescriptions, and more included in your Wingspan Membership.",
                ctaBtnText: "Try Benefits"
              },
              {
                label: "Legal and financial support",
                value: WingspanProductAspects.LegalAndFinancialSupport,
                virtualAssistantMsg:
                  "Through Wingspan, you can get help with basic legal matters and work with a financial wellness coach to guide you through life’s toughest logistics.",
                ctaBtnText: "Try Benefits"
              },
              {
                label: "Mind and body support",
                value: WingspanProductAspects.MindAndBodySupport,
                virtualAssistantMsg:
                  "Get discounts on alternative medicine and chiropractic through Wingspan. Keep an eye out for Therapy Benefits as well, coming soon!",
                ctaBtnText: "Try Benefits"
              }
            ]
          }}
          onSubmit={onSubmit}
          defaultValues={{
            productAspectImportance: answer
          }}
        />
      </WSContainer>
    </WSLayout>
  );
};
