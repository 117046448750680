import classNames from "classnames";
import { WSIconName } from "../../core/WSIcon/WSIcon.component";
import { WSBadgeTheme } from "../WSBadge/WSBadge.component";
import styles from "./WSPill.component.module.scss";
import {
  WSPillExtraTheme,
  WSPillStatusTheme,
  WSPillTheme,
  WSPillType
} from "./types";

export const isWSPillStatusTheme = (
  theme: string
): theme is WSPillStatusTheme =>
  ["error", "warning", "success"].includes(theme);

export const isWSPillExtraTheme = (theme: string): theme is WSPillExtraTheme =>
  ["neutral", "blue", "violet"].includes(theme);

export const badgeThemeMap: Record<WSPillTheme, WSBadgeTheme> = {
  error: "red",
  warning: "amber",
  success: "green",
  neutral: "dark",
  blue: "blue",
  violet: "violet"
};

export const statusIconMap: Record<WSPillStatusTheme, WSIconName> = {
  error: "alert-circle",
  warning: "alert",
  success: "check-circle"
};

export const getPillClassNames = (
  theme: WSPillTheme,
  type: WSPillType,
  outline?: boolean,
  customClassName?: string,
  truncate?: boolean
) =>
  classNames(
    styles.pill,
    styles[`theme-${theme}--${type}`],
    { [styles[`theme-${theme}--outline`]]: outline },
    customClassName,
    truncate && styles.truncated
  );
