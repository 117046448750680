import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { InstitutionLogo } from "../InstitutionLogo/InstitutionLogo";
import styles from "./BankAccountPreview.module.scss";

type Props = {
  institutionId?: string;
  name: string;
  mask?: string;
} & WSElementProps;

export const BankAccountPreview: React.FC<Props> = ({
  institutionId,
  name,
  mask,
  ...elementProps
}) => (
  <WSElement {...elementProps}>
    <WSFlexBox wrap="nowrap" alignItems="center">
      <InstitutionLogo
        institutionId={institutionId}
        className={styles.logo}
        mr="M"
      />
      <WSText>
        {name}
        {mask && ` ••••${mask}`}
      </WSText>
    </WSFlexBox>
  </WSElement>
);
