import { IRequirementDefinitionCreateRequest } from "@wingspanhq/payments/dist/interfaces";

import { createRequirement } from "../../../services/requirementDefinitions/createRequirement";
import { useWSMutation } from "../../helpers";
import { QUERY_REQUIREMENT_DEFINITIONS_LIST } from "../keys";

export const useCreateRequirementDefinition = () =>
  useWSMutation(
    (payload: IRequirementDefinitionCreateRequest) =>
      createRequirement(payload),
    {
      dependencies: [QUERY_REQUIREMENT_DEFINITIONS_LIST]
    }
  );
