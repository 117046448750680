import {
  WSInfiniteScroll,
  WSTable,
  WSTableCell,
  WSTableItem,
  WSText,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import { IInvoice } from "@wingspanhq/payments/dist/interfaces";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import React, { useCallback } from "react";
import { InfiniteQueryConfig } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { WSQueries } from "../../../query/WSQuery";
import { useWSInfiniteQuery } from "../../../query/helpers";
import { QUERY_PAYROLL_RUNS } from "../../../query/payments/keys";
import { getPayrollRunTitle } from "../../../query/payments/selectors";
import { paymentsService } from "../../../services/payments";
import { useUrlQueryFilters } from "../../../utils/router";
import { WSServiceError } from "../../../utils/serviceHelper";
import { PayablesEmptyState } from "../../components/NewPayablesTable/PayablesTable";
import { calculateLineItemsTotal } from "../../utils";

type PayrollRunsFilters = {
  sort: { "events.paidAt": "asc" | "desc" };
};

const defaultPayrollRunsFilters: PayrollRunsFilters = {
  sort: {
    "events.paidAt": "desc"
  }
};

export const usePayrollRunsFilters = () =>
  useUrlQueryFilters<PayrollRunsFilters>(defaultPayrollRunsFilters);
export const usePayrollRunsQuery = (
  params: PayrollRunsFilters,
  config?: InfiniteQueryConfig<IInvoice[], WSServiceError>
) => {
  const PAGE_SIZE = 100;

  return useWSInfiniteQuery<IInvoice[], WSServiceError>(
    [QUERY_PAYROLL_RUNS, params],
    async (queryKey, queryParams, pageNumber = 1) => {
      return await paymentsService.invoice.list({
        filter: {
          "labels.invoiceType": "approvedInvoicesPayment"
        },
        page: {
          size: PAGE_SIZE,
          number: pageNumber
        },
        sort: isEmpty(params.sort)
          ? {
              "events.paidAt": "desc"
            }
          : params.sort
      });
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.length < PAGE_SIZE) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );
};

type PayrollRunsTableItem = WSTableItem<PayablesTableItemData>;

export type PayablesTableItemData = {
  invoice?: IInvoice;
};

export const PayablesPayrollRuns: React.FC = () => {
  useBrowserPageTitle("Payroll Runs");

  const history = useHistory();
  const location = useLocation();

  const { filters, setFilters } = usePayrollRunsFilters();

  const payrollRunsQuery = usePayrollRunsQuery(filters);

  const onView = useCallback((item: PayrollRunsTableItem) => {
    if (item.data.invoice) {
      history.push({
        pathname: `/member/invoices/payables/payroll-runs/${item.id}`,
        search: location.search
      });
    }
  }, []);

  return (
    <>
      <WSText.Heading1 mb="M">Payroll Runs</WSText.Heading1>
      <WSText color="gray600" mb="2XL">
        The full history of payments sent.
      </WSText>

      <WSQueries queries={{ payrollRunsQuery }}>
        {() => {
          const payrollRuns = flatten(payrollRunsQuery.data);

          const data = [
            ...payrollRuns.map(invoice => ({
              id: invoice.invoiceId,
              data: {
                invoice
              }
            }))
          ];

          return (
            <>
              <WSInfiniteScroll
                onLoad={() => {
                  payrollRunsQuery.fetchMore();
                }}
                loadMore={payrollRuns.length > 0}
                endOfList={!payrollRunsQuery.canFetchMore}
                loading={!!payrollRunsQuery.isFetchingMore}
              >
                <WSTable<PayablesTableItemData>
                  tableData={data}
                  columns={[
                    {
                      config: {
                        header: {
                          text: "Date paid"
                        },
                        gridTemplateSizeMax: "1fr",
                        sortDirection:
                          filters.sort?.["events.paidAt"] === "asc"
                            ? "ascending"
                            : "descending",
                        onColumnSort(direction) {
                          return setFilters({
                            sort: {
                              "events.paidAt":
                                direction === "ascending" ? "asc" : "desc"
                            }
                          });
                        }
                      },
                      renderFunction: ({ data }) =>
                        data.invoice && (
                          <WSTableCell
                            avatar={{
                              type: "icon",
                              icon: "check",
                              color: "gray500",
                              colorBackground: "gray50"
                            }}
                            text={getPayrollRunTitle(data.invoice)}
                          />
                        )
                    },
                    {
                      config: {
                        hideOnScreens: ["XS"],
                        header: {
                          text: "Total payouts"
                        },
                        gridTemplateSizeMax: "1fr"
                      },
                      renderFunction: ({ data: { invoice } }) => (
                        <WSTableCell
                          text={String(
                            invoice?.collaborators?.length ||
                              invoice?.labels.payableCount ||
                              0
                          )}
                        />
                      )
                    },
                    {
                      config: {
                        header: {
                          text: "Amount"
                        },
                        gridTemplateSizeMin: "min-content",
                        gridTemplateSizeMax: "1fr"
                      },
                      renderFunction: ({ data: { invoice } }) =>
                        invoice ? (
                          <WSTableCell
                            text={toWSMoneyString(
                              calculateLineItemsTotal(invoice.lineItems)
                            )}
                          />
                        ) : (
                          ""
                        )
                    }
                  ]}
                  onRowClick={onView}
                />
                {data.length === 0 && (
                  <PayablesEmptyState
                    firstTime="A record of every payroll run and its corresponding details will display here."
                    standart="No payroll runs."
                  />
                )}
              </WSInfiniteScroll>
            </>
          );
        }}
      </WSQueries>
    </>
  );
};
