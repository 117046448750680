import {
  detachLayoutProps,
  WSCarousel,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSScreen
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useSubscriptionPackages } from "../../../query/subscriptions/queries";
import { WSQuery } from "../../../query/WSQuery";
import { prepareSubscriptionPackagesData } from "../../utils/subscriptionUtils";
import { MembershipPackageAddOnCard } from "./MembershipPackageAddOnCard";
import styles from "./MembershipPackageCards.module.scss";
import { IDisplaySubscriptionPackageWithoutAmount } from "./subscriptionPackages";

export interface MembershipPackageCardsProps extends WSElementProps {
  isAnnualBilling: boolean;
  packages: IDisplaySubscriptionPackageWithoutAmount[];
}

export const MembershipPackageCards: React.FC<MembershipPackageCardsProps> = ({
  isAnnualBilling,
  packages,
  ...otherProps
}) => {
  const qSubscriptionPackages = useSubscriptionPackages();
  const { layoutProps } = detachLayoutProps(otherProps);
  return (
    <WSQuery query={qSubscriptionPackages}>
      {({ data: subscriptionPackagesWithAmount }) => {
        const subscriptionPackages = prepareSubscriptionPackagesData(
          subscriptionPackagesWithAmount,
          packages
        );
        return (
          <WSElement {...layoutProps}>
            <WSScreen.Desktop>
              <WSFlexBox alignItems="flex-end" wrap="nowrap" mx="XL">
                {subscriptionPackages.map(subscriptionPackage => (
                  <MembershipPackageAddOnCard
                    key={subscriptionPackage.title}
                    isAnnualBilling={isAnnualBilling}
                    subscriptionPackage={subscriptionPackage}
                  />
                ))}
              </WSFlexBox>
            </WSScreen.Desktop>
            <WSScreen.MobileAndTablet>
              <WSCarousel
                className={styles.carousel}
                defaultItemIdx={1}
                items={subscriptionPackages.map(subscriptionPackage => (
                  <MembershipPackageAddOnCard
                    key={subscriptionPackage.title}
                    isAnnualBilling={isAnnualBilling}
                    subscriptionPackage={subscriptionPackage}
                  />
                ))}
                width={280}
              />
            </WSScreen.MobileAndTablet>
          </WSElement>
        );
      }}
    </WSQuery>
  );
};
