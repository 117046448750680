import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useLocation
} from "react-router-dom";
import { MemberSignUpV2 } from "./screens/MemberSignUp";
import { useSetWSStore, useWSStore } from "../store";
import { GrowthSourceNames } from "../utils/growthAttribution";
import { OnboardingStep1 } from "./screens/OnboardingStep1";
import { OnboardingStep2 } from "./screens/OnboardingStep2";
import { OnboardingStep3Benefits } from "./screens/OnboardingStep3Benefits";
import { OnboardingStep3Bookkeeping } from "./screens/OnboardingStep3Bookkeeping";
import { OnboardingStep3Payments } from "./screens/OnboardingStep3Payments";
import { useCMSWebflowPartner } from "../query/cms/queries";

export const SignUpRoutes: React.FC<RouteComponentProps<any>> = props => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const store = useWSStore();
  const setStore = useSetWSStore();

  useEffect(() => {
    if (location.hash !== "") {
      setStore({ signUpHash: location.hash });
    }
  }, [location.hash]);

  useEffect(() => {
    if (location.pathname === "/member/employer-sign-up") {
      setStore({ isEmployerSignUp: true });
    }
  }, [location.pathname]);

  const growthSource = store.growthAttributionDetails?.growthSource;
  const growthName = store.growthAttributionDetails?.growthName;

  // Pre-fetch if necessary
  useCMSWebflowPartner(growthName || "", {
    enabled: !!(growthSource === GrowthSourceNames.Partner && growthName)
  });

  // adapt old growth capturing to new pattern
  if (params.get("partner")) {
    const isPreSignUp = location.pathname.includes("pre-sign-up");
    return (
      <Redirect
        to={{
          pathname: `/p/${isPreSignUp ? "pre-" : ""}sign-up`,
          search: `?growth_name=${params.get("partner")}`,
          state: { growthSource }
        }}
      />
    );
  }

  return (
    <Switch>
      {/* Pre-sign-up routes and redirects */}

      {/* disableDTC experiment cleanup should remove all pre-sign-up flows */}
      <Redirect from="/member/pre-sign-up" to="/member/sign-up" />

      <Route
        path="/member/pre-sign-up/questionnaire/1"
        component={OnboardingStep1}
      />
      <Route
        path="/member/pre-sign-up/questionnaire/2"
        component={OnboardingStep2}
      />
      <Route
        path="/member/pre-sign-up/questionnaire/3/benefits"
        component={OnboardingStep3Benefits}
      />
      <Route
        path="/member/pre-sign-up/questionnaire/3/bookkeeping"
        component={OnboardingStep3Bookkeeping}
      />
      <Route
        path="/member/pre-sign-up/questionnaire/3/payments"
        component={OnboardingStep3Payments}
      />

      <Redirect
        from="/autopay/:tag"
        to={{
          pathname: `/member/sign-up/`,
          search: location.search + `&autopay=1&tag=${props.match.params.tag}`,
          state: { growthSource }
        }}
      />

      <Redirect
        from="/member/pre-sign-up/"
        exact
        to={{
          pathname: `/member/pre-sign-up/questionnaire/1`,
          search: location.search,
          state: { growthSource }
        }}
      />
      <Redirect
        from="/*/pre-sign-up/"
        to={{
          pathname: `/member/pre-sign-up/questionnaire/1`,
          search: location.search,
          state: { growthSource }
        }}
      />

      {/* Sign-up routes and redirects */}
      <Route
        path={["/member/sign-up", "/member/employer-sign-up", "/member/1099"]}
        component={MemberSignUpV2}
      />

      <Redirect
        from="/*/1099"
        to={{
          pathname: `/member/1099`,
          search: location.search,
          state: {
            growthSource
          }
        }}
      />
      <Redirect
        from="/*/sign-up"
        to={{
          pathname: `/member/sign-up/`,
          search: location.search,
          state: { growthSource }
        }}
      />
    </Switch>
  );
};
