import {
  toCamelCase,
  WSButton,
  WSButtons,
  WSElementProps,
  WSFormOld,
  WSRadioInputGroup,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import {
  WSDataTypes,
  AdditionalDataResourceType,
  CustomFieldResourceType
} from "@wingspanhq/payments/dist/interfaces";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { useCreateCustomField } from "../../../../query/customFields/mutations/useCreateCustomField";

type Props = WSElementProps & {
  resourceType: CustomFieldResourceType;
  onClose(result: boolean): void;
};

export const AddCustomFieldModal: React.FC<Props> = ({
  resourceType,
  onClose
}) => {
  const [createCustomField, createCustomFieldMeta] = useCreateCustomField();

  const queryCustomFieldList = useCustomFieldsAll({
    resourceType: [resourceType]
  });

  return (
    <WSFormOld
      defaultValues={{
        name: "",
        fieldType: WSDataTypes.String,
        required: "yes"
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .test(
            "name",
            "A custom field with this name already exists",
            name => {
              const key = toCamelCase(name ?? "");
              const existingCustomFields =
                queryCustomFieldList.data?.map(a => a.key) || [];
              return !existingCustomFields.includes(key);
            }
          )
          .required("Name is required"),
        fieldType: Yup.string(),
        required: Yup.string()
      })}
      onSubmit={async formData => {
        await createCustomField(
          {
            name: formData.name,
            required: formData.required === "yes",
            type: formData.fieldType,
            key: toCamelCase(formData.name),
            resourceType: (resourceType as unknown) as AdditionalDataResourceType
          },
          {
            onSuccess() {
              onClose(true);
            }
          }
        );
      }}
    >
      <WSFormOld.Field
        mb="XL"
        label="Field name"
        name="name"
        component={WSTextInput}
      />

      <WSFormOld.Field
        mb="2XL"
        label=" "
        name="required"
        component={WSRadioInputGroup}
        componentProps={{
          options: [
            {
              label: "Required",
              value: "yes"
            },
            {
              label: "Optional",
              value: "no"
            }
          ]
        }}
      />

      <WSFormOld.Field
        mb="XL"
        label="Field type"
        name="fieldType"
        component={WSRadioInputGroup}
        componentProps={{
          options: [
            {
              label: "Text field",
              value: WSDataTypes.String
            },
            {
              label: "Checkbox",
              value: WSDataTypes.Boolean
            }
          ]
        }}
      />

      <WSErrorMessage
        mt="XL"
        error={createCustomFieldMeta.error}
        contextKey="CreateCustomField"
      />

      <WSButtons mt="3XL" forceFullWidth>
        <WSFormOld.SubmitButton
          data-testid="submit"
          loading={createCustomFieldMeta.isLoading}
        >
          Create field
        </WSFormOld.SubmitButton>
        <WSButton.Tertiary onClick={() => onClose(false)}>
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </WSFormOld>
  );
};
