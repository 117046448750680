import {
  WSElement,
  WSText,
  WSTimeline,
  toWSDateString,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import {
  IInvoice,
  IInvoiceRefundDestination,
  IInvoiceRefundEvents,
  InvoicePayoutDestinationType
} from "@wingspanhq/payments/dist/interfaces";
import { useBankingAccount } from "../../../query/bookkeeping/queries";
import { BankAccount } from "../../../shared/components/BankAccount";
import { BankCard } from "../../../shared/components/BankCard";
import { MethodWallet } from "../../../shared/components/MethodWallet";
import { TextBulleted } from "../../../shared/components/TextBulleted";

type Props = {
  invoice: IInvoice;
};

const getEventDetails = (event: keyof IInvoiceRefundEvents, date: Date) => {
  switch (event) {
    case "refundStartedAt":
      return `Refund started (${toWSDateString(date, "monthDayYear")})`;
    case "refundSuccessfulAt":
      return `Successfully refunded (${toWSDateString(date, "monthDayYear")})`;
    case "refundFailedAt":
      return `Refund failed (${toWSDateString(date, "monthDayYear")})`;
  }
};

export const InvoiceRefunds: React.FC<Props> = ({ invoice }) => {
  return (
    <>
      <WSTimeline
        items={(invoice.refundDestinations || []).map(refundDestination => ({
          date: refundDestination.createdAt,
          badges: [`${toWSMoneyString(refundDestination.amount)} refund`],
          content: <RefundDestination refundDestination={refundDestination} />
        }))}
      />
    </>
  );
};

const RefundDestination: React.FC<{
  refundDestination: IInvoiceRefundDestination;
}> = ({ refundDestination }) => {
  const queryBankingAccount = useBankingAccount();

  const events = Object.keys(refundDestination.events).map(key => ({
    event: key as keyof IInvoiceRefundEvents,
    date: refundDestination.events[key as keyof IInvoiceRefundEvents] as Date
  }));

  events.sort((a, b) => a.date.getTime() - b.date.getTime());

  return (
    <>
      {refundDestination.destinationId && (
        <WSElement mb="M">
          <WSText.ParagraphSm color="gray400" mb="XS">
            Refund method:
          </WSText.ParagraphSm>

          {refundDestination.destinationType ===
          InvoicePayoutDestinationType.Account ? (
            <BankAccount accountId={refundDestination.destinationId} />
          ) : refundDestination.destinationType ===
            InvoicePayoutDestinationType.Card ? (
            <BankCard type="debit" cardId={refundDestination.destinationId} />
          ) : queryBankingAccount.data ? (
            <MethodWallet />
          ) : null}
        </WSElement>
      )}

      <TextBulleted color="gray600">
        {events.map(event => (
          <li key={event.event}>
            <WSText.ParagraphSm>
              {getEventDetails(event.event, event.date)}
            </WSText.ParagraphSm>
          </li>
        ))}
      </TextBulleted>
    </>
  );
};
