import {
  IPayoutSettingsResponse,
  InvoiceStatus,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import {
  IMember,
  UserAccountType
} from "@wingspanhq/users/dist/lib/interfaces";
import { selectorIsDomesticMember } from "../../../../shared/selectors/selectorIsDomesticMember";
import { IInvoiceRow } from "../../service";

export const selectorIsInstantPayoutAllowed = (
  invoice: IInvoiceRow,
  payoutSettings?: IPayoutSettingsResponse,
  member?: IMember
) =>
  !!payoutSettings &&
  !!member &&
  invoice.oldStatus === InvoiceStatus.Paid &&
  payoutSettings.payoutPreferences !== PayoutPreferences.Instant &&
  invoice.member.payoutPreferences !== PayoutPreferences.Instant &&
  !!invoice.amountDetails &&
  !invoice.events.depositedAt &&
  selectorIsDomesticMember(member) &&
  member.user.settings?.userAccountType !== UserAccountType.enterprise;
