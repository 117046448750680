import cn from "classnames";
import React from "react";
import {
  toWSDate,
  WSDateFormatsType
} from "../../../../utils/formatters/toWSDate";
import {
  toWSMoney,
  WSMoneyFormatsType
} from "../../../../utils/formatters/toWSMoney";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { ComponentWithKinds } from "../../WSElement/WSElement.helpers";
import styles from "./WSText.module.scss";

export const WSTextKinds = [
  "Display1",
  "Display2",
  "Heading1",
  "Heading2",
  "Heading3",
  "Heading4",
  "Heading5",
  "Paragraph",
  "ParagraphSm",
  "ParagraphXs",
  "Caption"
] as const;
export type WSTextKindsType = typeof WSTextKinds[number];

export const WSTextAlign = ["center", "left", "right"] as const;
export type WSTextAlignType = typeof WSTextAlign[number];

export type WSTextWeight = "thin" | "light" | "book" | "medium" | "bold";

export interface WSTextProps extends WSElementProps {
  kind?: WSTextKindsType;
  inline?: boolean;
  singleLine?: boolean;
  tabularNums?: boolean;
  align?: WSTextAlignType;
  formatMoney?: boolean | WSMoneyFormatsType;
  formatDate?: boolean | WSDateFormatsType;
  weight?: WSTextWeight;
  strike?: boolean;
  italic?: boolean;
  underline?: boolean;
}

export const WSText: ComponentWithKinds<WSTextProps, WSTextKindsType> = ({
  kind = "Paragraph",
  className,
  children,
  color = "gray700",
  inline = false,
  singleLine = false,
  tabularNums = false,
  align,
  formatMoney = false,
  formatDate = false,
  strike = false,
  weight,
  italic = false,
  underline = false,
  ...componentProps
}) => {
  if (formatMoney) {
    if (formatMoney === true) {
      formatMoney = "default";
    }
    tabularNums = true;
    const moneyFormatObject = toWSMoney(children, formatMoney);
    if (formatMoney === "taxes") {
      color = moneyFormatObject.value < 0 ? "red500" : color;
    }

    children = moneyFormatObject.formattedValue;
  } else if (formatDate) {
    if (formatDate === true) {
      formatDate = "default";
    }
    const dateFormatObject = toWSDate(children, formatDate);
    children = dateFormatObject.formattedValue;
  }

  const classNames = cn(
    styles.base,
    styles[kind],
    align ? styles[align] : null,
    { [styles.tabularNums]: tabularNums },
    { [styles.inline]: inline },
    { [styles.singleLine]: singleLine },
    { [styles.strike]: strike },
    { [styles.italic]: italic },
    { [styles.underline]: underline },
    weight ? styles[`weight-${weight}`] : null,
    className
  );
  return (
    <WSElement color={color} className={classNames} {...componentProps}>
      {children}
    </WSElement>
  );
};

WSText.Display1 = (props) => <WSText as="h1" kind="Display1" {...props} />;
WSText.Display2 = (props) => <WSText as="h1" kind="Display2" {...props} />;

WSText.Heading1 = (props) => <WSText as="h1" kind="Heading1" {...props} />;
WSText.Heading2 = (props) => <WSText as="h2" kind="Heading2" {...props} />;
WSText.Heading3 = (props) => <WSText as="h3" kind="Heading3" {...props} />;
WSText.Heading4 = (props) => <WSText as="h4" kind="Heading4" {...props} />;
WSText.Heading5 = (props) => <WSText as="h5" kind="Heading5" {...props} />;

WSText.Paragraph = (props) => <WSText as="p" kind="Paragraph" {...props} />;
WSText.ParagraphSm = (props) => <WSText as="p" kind="ParagraphSm" {...props} />;
WSText.ParagraphXs = (props) => <WSText as="p" kind="ParagraphXs" {...props} />;

WSText.Caption = (props) => <WSText as="span" kind="Caption" {...props} />;
