import { ITaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { MAX_FAILURE_COUNT } from "../routes/W9Flow/routes/RouteVerifyYourIdentityById";

export const selectorUnverifiedTaxForms = (taxForms: ITaxFormResponse[]) => {
  return taxForms
    .filter(
      taxForm =>
        !(
          taxForm.recipientVerification?.verified === true ||
          taxForm.recipientVerification?.verified === undefined
        )
    )
    .filter(
      taxForm =>
        (taxForm.recipientVerification?.failureCount || 0) < MAX_FAILURE_COUNT
    );
};
