import {
  useWSSnackbar,
  WSActions,
  WSForm,
  WSInputText
} from "@wingspanhq/fe-component-library";
import { useRequirementDefinitionQuery } from "../../../../query/requirementDefinitions/query/useRequirementDefinitionQuery";
import { WSQueries } from "../../../../query/WSQuery";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { useUpdateRequirementDefinition } from "../../../../query/requirementDefinitions/mutation/useUpdateRequirementDefinition";

interface RenameRequirementModalProps {
  requirementId: string;
  onClose: (result: boolean) => void;
}

export const RenameRequirementModal: React.FC<RenameRequirementModalProps> = ({
  requirementId,
  onClose
}) => {
  const { openSnackbar } = useWSSnackbar();
  const queryRequirement = useRequirementDefinitionQuery(requirementId);
  const [
    updateRequirement,
    updateRequirementMeta
  ] = useUpdateRequirementDefinition(requirementId);
  return (
    <WSQueries queries={{ queryRequirement }}>
      {({ queryRequirementData }) => {
        return (
          <WSForm
            defaultValues={{
              name: queryRequirementData.name
            }}
            onSubmit={async ({ name }) => {
              await updateRequirement(
                {
                  name
                },
                {
                  onSuccess: () => {
                    onClose(true);
                    openSnackbar({
                      message: "Requirement updated successfully",
                      type: "success"
                    });
                  }
                }
              );
            }}
          >
            <WSForm.Field
              name="name"
              label="Name"
              placeholder="Requirement name"
              component={WSInputText}
              componentProps={{
                required: true
              }}
            />

            <WSErrorMessage
              my="XL"
              contextKey={ErrorContextKey.RenameRequirement}
              error={updateRequirementMeta.error}
            />
            <WSForm.Values names={["name"]}>
              {({ name }) => (
                <WSActions
                  mt="3XL"
                  alignment="fill"
                  buttons={[
                    {
                      label: "Rename requirement",
                      kind: "Primary",
                      type: "submit",
                      loading: updateRequirementMeta.isLoading,
                      disabled: !name || name === queryRequirementData.name
                    },
                    {
                      label: "Cancel",
                      kind: "Secondary",
                      type: "button",
                      onClick() {
                        onClose(false);
                      }
                    }
                  ]}
                />
              )}
            </WSForm.Values>
          </WSForm>
        );
      }}
    </WSQueries>
  );
};
