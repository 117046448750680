import {
  IPayerCreateRequest,
  IPayerResponse
} from "@wingspanhq/payments/dist/interfaces";
import { servicePayments } from "../servicePayments";

export const createPayer = async (
  payload: IPayerCreateRequest
): Promise<IPayerResponse> => {
  const response = await servicePayments.post(`/payer`, payload);
  return response.data;
};
