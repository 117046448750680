import {
  WSAvatar,
  WSElement,
  WSElementProps,
  WSIcon,
  WSText,
  useIsMobile
} from "@wingspanhq/fe-component-library";
import { TransferType } from "../../types";
import styles from "./index.module.scss";

type Props = {
  type: TransferType;
} & WSElementProps;

export const TransferSchema: React.FC<Props> = ({ type, ...elementProps }) => {
  const isMobile = useIsMobile();

  return (
    <WSElement className={styles.transferSchema} {...elementProps}>
      {type === "deposit" ? <BankAccount /> : <Wingspan />}
      <WSIcon
        block
        name={isMobile ? "arrow-down" : "arrow-right"}
        className={styles.icon}
      />
      {type === "withdrawal" ? <BankAccount /> : <Wingspan />}
    </WSElement>
  );
};

const Wingspan: React.FC = () => {
  return (
    <WSElement className={styles.badge}>
      <WSAvatar.Icon
        color="white"
        colorBackground="garnet"
        icon="wallet"
        size="S"
        mr="M"
      />
      <WSText>Wingspan</WSText>
    </WSElement>
  );
};

const BankAccount: React.FC = () => {
  return (
    <WSElement className={styles.badge}>
      <WSAvatar.Icon
        color="white"
        colorBackground="blue500"
        icon="bank"
        size="S"
        mr="M"
      />
      <WSText>Your bank account</WSText>
    </WSElement>
  );
};
