import {
  useWSModal,
  WSActions,
  WSAlert,
  WSDivider,
  WSInfoBox,
  WSList
} from "@wingspanhq/fe-component-library";

export interface CardCreatedProps {
  onSuccess: () => void;
  onClose: () => void;
}

const CardCreatedModal: React.FC<CardCreatedProps> = ({
  onClose,
  onSuccess
}) => {
  return (
    <WSList gap="L">
      <WSAlert theme="success">
        Your virtual debit card has been activated!
      </WSAlert>
      <WSInfoBox>Manage your card from your Wingspan Wallet page.</WSInfoBox>
      <WSDivider />
      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            onClick: async () => {
              onSuccess();
              onClose();
            },
            kind: "Primary"
          }
        ]}
      ></WSActions>
    </WSList>
  );
};

export function useCardCreatedModal() {
  return useWSModal(CardCreatedModal, {
    title: "Card activated",
    size: "S"
  });
}
