import classNames from "classnames";
import React from "react";
import { toCamelCase } from "../../../../utils";
import {
  detachLayoutProps,
  WSElement,
  WSElementProps
} from "../../WSElement/WSElement.component";
import { WSIcon } from "../WSIcon/WSIcon.component";
import { WSText } from "../WSText/WSText.component";
import { SelectActionOld } from "./WSSelect.types";
import styles from "./WSSelectDisplay.module.scss";

type Value = string | number;

export interface WSSelectDisplayItem {
  value: Value;
  label: React.ReactNode;
}

export interface WSWSSelectDisplayProps extends WSElementProps {
  name: string;
  value?: Value | Value[];
  options: WSSelectDisplayItem[];
  actions?: SelectActionOld[];
  multiselect?: boolean;
  onChange?(event: any): void;
}

export const WSSelectDisplay: React.FC<WSWSSelectDisplayProps> = (props) => {
  const {
    options,
    actions,
    name,
    value,
    multiselect,
    layoutProps,
    onChange,
    onFocus,
    onBlur
  } = detachLayoutProps(props);

  return (
    <WSElement
      className={styles.container}
      {...layoutProps}
      tabIndex={0}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {actions?.map((action, index) => (
        <WSElement
          key={`action-${action.label}_${index}`} // eslint-disable-line
          data-testid={toCamelCase(name, action.label, "option")}
          className={classNames(styles.option, styles.action)}
          onClick={() => {
            action.callback("", options as any);
            onChange?.("");
          }}
        >
          {action.icon ? (
            <WSIcon block name={action.icon} size="XS" color="blue400" mr="M" />
          ) : null}
          <WSText.ParagraphSm color="blue500">
            {action.label}
          </WSText.ParagraphSm>
        </WSElement>
      ))}
      {options.map((option, index) => (
        <WSElement
          key={`${option.value}_${index}`} // eslint-disable-line
          data-testid={toCamelCase(name, option.value, "option")}
          className={classNames(styles.option, {
            [styles.selected]: multiselect
              ? Array.isArray(value) && value.includes(option.value)
              : value === option.value
          })}
          onClick={() => {
            if (multiselect && Array.isArray(value)) {
              if (value.includes(option.value)) {
                onChange?.(value.filter((v) => v !== option.value));
              } else {
                onChange?.([...value, option.value]);
              }
            } else {
              onChange?.(option.value);
            }
          }}
        >
          <WSText.ParagraphSm
            color={value === option.value ? "green500" : "gray500"}
          >
            {option.label}
          </WSText.ParagraphSm>
        </WSElement>
      ))}
    </WSElement>
  );
};
