import { Redirect, useHistory } from "react-router-dom";
import { FlowSetup } from "../../../../shared/components/FlowSetup";
import { RouteCertifyW9 } from "./routes/RouteCertifyW9";
import { RouteEinUpload } from "./routes/RouteEinUpload";
import { RoutePaperlessDelivery } from "./routes/RoutePaperlessDelivery";
import { RoutePersonalInformationVerification } from "./routes/RoutePersonalInformationVerification";
import { RouteShareW9 } from "./routes/RouteShareW9";
import { RouteSsnUpload } from "./routes/RouteSsnUpload";
import {
  RouteTaxpayerIdentificationNumber,
  RouteTaxpayerIdentificationNumberContext
} from "./routes/RouteTaxpayerIdentificationNumber";
import { RouteTaxpayerInformation } from "./routes/RouteTaxpayerInformation";
import { RouteVerification } from "./routes/RouteVerification";
import { RouteConfirmPreferences } from "./routes/RouteConfirmPreferences";
import { RouteConfirmation } from "./routes/RouteConfirmation";
import { RouteVerifyYourIdentityById } from "./routes/RouteVerifyYourIdentityById";
import { useMemberProfile } from "../../../../query/users/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import { WSQueries } from "../../../../query/WSQuery";
import { usePayeeTaxForms } from "../../../../query/taxForm/queries/usePayeeTaxForms";
import { CURRENT_YEAR } from "../../constants/currentYear";
import { selectorPayeeTaxFormIsW9Completed } from "../../selectors/selectorPayeeTaxFormIsW9Completed";
import { RouteNonUSPersons } from "./routes/RouteNonUSPersons";
import { useUpdateActivity } from "../../../../query/users/mutations";
import { VerificationStatus } from "@wingspanhq/payments/dist/interfaces";

type W9FlowProps = {
  basePath: string;
  onBack: () => Promise<void> | void;
  onFinish: () => Promise<void> | void;
};
export const W9Flow: React.FC<W9FlowProps> = ({
  basePath,
  onBack,
  onFinish
}) => {
  const history = useHistory();
  const userId = useUserId();
  const queryMember = useMemberProfile(useUserId());
  const queryTaxForms = usePayeeTaxForms(CURRENT_YEAR);
  const [updateActivity] = useUpdateActivity(userId);

  const handleFinish = async (complete: boolean = true) => {
    window.localStorage.removeItem("signUp1099");
    await updateActivity({
      flows: {
        w9Confirmation: {
          totalSteps: 2,
          complete,
          currentStep: 2,
          version: 1
        }
      }
    });

    onFinish();
  };

  return (
    <WSQueries queries={{ queryMember, queryTaxForms }}>
      {({ queryMemberData, queryTaxFormsData }) => {
        if (
          selectorPayeeTaxFormIsW9Completed(
            queryTaxFormsData,
            queryMemberData
          ) &&
          history.location.pathname !== `${basePath}/confirmation`
        ) {
          return <Redirect to={`${basePath}/confirmation`} />;
        }

        return (
          <FlowSetup
            basePath={basePath}
            routes={[
              {
                path: "/verify-identity/:taxFormId",
                exact: true,
                component: RouteVerifyYourIdentityById,
                onNext: context => {
                  history.push(basePath + "/verify-identity");
                }
              },
              {
                path: "/personal-info",
                component: RoutePersonalInformationVerification,
                onNext: context => {
                  history.push(basePath + "/taxpayer-identification-number");
                }
              },
              {
                path: "/non-us-persons",
                component: RouteNonUSPersons,
                onNext: context => {
                  handleFinish(false);
                },
                onBack: () => {
                  history.push(basePath + "/taxpayer-identification-number");
                }
              },
              {
                path: "/taxpayer-identification-number",
                component: RouteTaxpayerIdentificationNumber,
                onBack: () => {
                  history.push(basePath + "/personal-info");
                },
                onNext: (
                  context: RouteTaxpayerIdentificationNumberContext | undefined
                ) => {
                  if (context?.isUSPerson === false) {
                    history.push(basePath + "/non-us-persons");
                  } else {
                    history.push(basePath + "/taxpayer-information");
                  }
                }
              },
              {
                path: "/taxpayer-information",
                component: RouteTaxpayerInformation,
                onBack: () => {
                  history.push(basePath + "/taxpayer-identification-number");
                },
                onNext: context => {
                  history.push(basePath + "/verification");
                }
              },
              {
                path: "/verification",
                component: RouteVerification,
                onBack: context => {
                  history.push(basePath + "/taxpayer-information", {
                    verificationFailed:
                      context?.status === VerificationStatus.Failed
                  });
                },
                onNext: context => {
                  history.push(basePath + "/certify-w9");
                }
              },
              {
                path: "/ssn-upload",
                component: RouteSsnUpload,
                onBack: () => {
                  history.push(basePath + "/taxpayer-information");
                },
                onNext: context => {
                  history.push(basePath + "/verification");
                }
              },
              {
                path: "/ein-upload",
                component: RouteEinUpload,
                onBack: () => {
                  history.push(basePath + "/taxpayer-information");
                },
                onNext: context => {
                  history.push(basePath + "/verification");
                }
              },
              {
                path: "/certify-w9",
                component: RouteCertifyW9,
                onBack: () => {
                  history.push(basePath + "/taxpayer-information");
                },
                onNext: context => {
                  history.push(basePath + "/share-w9");
                }
              },
              {
                path: "/share-w9",
                component: RouteShareW9,
                onBack: () => {
                  history.push(basePath + "/certify-w9");
                },
                onNext: context => {
                  history.push(basePath + "/paperless-delivery");
                }
              },
              {
                path: "/paperless-delivery",
                component: RoutePaperlessDelivery,
                onBack: () => {
                  history.push(basePath + "/share-w9");
                },
                onNext: () => {
                  history.push(basePath + "/confirm-preferences");
                }
              },
              {
                path: "/edit-paperless-delivery",
                params: { edit: true },
                component: RoutePaperlessDelivery,
                onBack: () => {
                  history.push(basePath + "/confirm-preferences");
                },
                onNext: () => {
                  history.push(basePath + "/confirm-preferences");
                }
              },
              {
                path: "/edit-taxpayer-information",
                component: RouteTaxpayerInformation,
                params: { edit: true },
                onBack: () => {
                  history.push(basePath + "/confirm-preferences");
                },
                onNext: context => {
                  history.push(basePath + "/edit-verification");
                }
              },
              {
                path: "/edit-verification",
                component: RouteVerification,
                onBack: () => {
                  history.push(basePath + "/edit-taxpayer-information");
                },
                onNext: context => {
                  history.push(basePath + "/confirm-preferences");
                }
              },
              {
                path: "/confirm-preferences",
                component: RouteConfirmPreferences,
                onNext: context => {
                  history.push(basePath + "/confirmation");
                }
              },
              {
                path: "/confirmation",
                component: RouteConfirmation,
                onNext: context => {
                  handleFinish(true);
                }
              }
            ]}
          />
        );
      }}
    </WSQueries>
  );
};
