import { WSElementProps } from "@wingspanhq/fe-component-library";
import { ICollaboratorSchema } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { mapPayablesTableToCSV } from "../../../Invoices/screens/payables/csvUtils";
import { PayablesFilter, paymentsService } from "../../../services/payments";
import { ButtonDownloadCsv } from "../../../shared/components/ButtonDownloadCsv";
import { getAllEntries } from "../../../utils/serviceHelper";

type Props = {
  collaborators: ICollaboratorSchema[];
  filters: PayablesFilter;
  fileName: string;
} & WSElementProps;

export const DownloadCsv: React.FC<Props> = ({
  collaborators,
  filters,
  fileName,
  ...elementProps
}) => {
  return (
    <ButtonDownloadCsv
      getData={async () => {
        const payables = await getAllEntries(
          async (...args) =>
            (await paymentsService.payable.list(...(args as any))).data,
          filters
        );

        return mapPayablesTableToCSV(
          payables.map(payable => ({
            payable,
            collaborator: collaborators.find(
              collaborator =>
                collaborator.collaboratorId === payable.collaboratorId
            )
          }))
        );
      }}
      fileName={fileName}
      {...elementProps}
    />
  );
};
