import {
  useIsMobile,
  WSFormOld,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import React from "react";
import * as Yup from "yup";

import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import styles from "./SettingsUpdateForms.module.scss";

import { useQueryVerifications } from "../../../query/onboarding/queries/useQueryVerifications";
import { openIntercomMessage } from "../../../shared/utils/intercom";

interface LegalBusinessNameFormProps {
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (data: any) => void;
}

export const LegalBusinessNameForm: React.FC<LegalBusinessNameFormProps> = ({
  onSubmit,
  defaultValues,
  onCancel
}) => {
  const userId = useUserId();
  const isMobile = useIsMobile();
  const queryVerifications = useQueryVerifications();
  const isVerified = queryVerifications.data?.tax === "Verified";

  const [updateMemberProfile] = useUpdateMemberProfile();

  const onFormSubmit = async (data: any) => {
    if (defaultValues.name && isVerified) {
      openIntercomMessage(
        `Hi there! I want to update my legal business name to "${data.name}".\n\n`
      );
      onSubmit(data);
      return;
    }

    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        company: {
          legalBusinessName: data.name
        }
      }
    };
    await updateMemberProfile(request, {
      onSuccess: () => {
        onSubmit(data);
      }
    });
  };
  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("This field is required")
      })}
    >
      <WSFormOld.Field name="name">
        <WSFormOld.Input render={props => <WSTextInput {...props} />} />
      </WSFormOld.Field>

      <WSFormOld.SubmitButton
        type="submit"
        name="update"
        mt="2XL"
        fullWidth={isMobile}
      >
        {defaultValues.name && isVerified
          ? "Send request to customer support"
          : "Update"}
      </WSFormOld.SubmitButton>
    </WSFormOld>
  );
};
