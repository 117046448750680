import { TaxEstimateType } from "@wingspanhq/bookkeeping/dist/lib/interfaces/taxEstimate";
import {
  useModalContext,
  WSButton,
  WSButtons,
  WSCentered,
  WSContainer,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSLayout,
  WSLoader,
  WSStepper,
  WSText
} from "@wingspanhq/fe-component-library";
import { MemberWithholdingStatus } from "@wingspanhq/users/dist/lib/interfaces/member";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import {
  FILLING_CODE_OPTIONS,
  US_STATES_OPTIONS
} from "../../../constants/user";
import { WSQueries } from "../../../query/WSQuery";
import { useUserId } from "../../../query/hooks/helpers";
import { useQueryVerifications } from "../../../query/onboarding/queries/useQueryVerifications";
import { useTaxEstimate } from "../../../query/taxes/queries";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import { useActivities, useMemberProfile } from "../../../query/users/queries";
import { getIncomeOptionsByFilingCode } from "../../../query/users/selectors";
import { selectorIsOnboardingComplete } from "../../../shared/selectors/selectorIsOnboardingComplete";
import { TaxesModalTips } from "../modals/TaxesModalTips";
import styles from "./TaxesOnboardingTaxRate.module.scss";

interface FieldSectionProps {
  label: React.ReactNode;
  value: React.ReactNode;

  onEdit(): void;
}

const withholdingRateHelpText = (
  withholdingRate: number,
  suggestedTaxRate: number
) => {
  const CONSERVATIVE_RATE = 0.1;
  const AGGRESSIVE_RATE = -0.1;
  const percentChange = (withholdingRate - suggestedTaxRate) / suggestedTaxRate;
  if (percentChange > CONSERVATIVE_RATE) {
    return <WSText>Conservative rate, you may receive a tax refund</WSText>;
  } else if (percentChange < AGGRESSIVE_RATE) {
    return <WSText>Aggressive rate, you may owe more later</WSText>;
  } else {
    return (
      <WSText>
        <WSElement as="span" color="green500">
          Recommended!
        </WSElement>{" "}
        Most accurate to tax profile.
      </WSText>
    );
  }
};

const FieldSection: React.FC<FieldSectionProps> = ({
  value,
  label,
  onEdit
}) => (
  <WSFlexBox
    justify="space-between"
    alignItems="center"
    className={styles.section}
    pb="XL"
    mb="XL"
  >
    <WSElement>
      <WSText.ParagraphSm color="gray500">{label}</WSText.ParagraphSm>
      <WSText mt="XL">{value}</WSText>
    </WSElement>
    <WSButton.Link type="button" onClick={onEdit}>
      Edit
    </WSButton.Link>
  </WSFlexBox>
);

const headerLeft = (history: any, isOnboarding: boolean) =>
  isOnboarding ? null : (
    <WSIcon
      block
      name="chevron-left"
      size="M"
      color="gray500"
      onClick={() => history.push("/member/taxes/dashboard")}
    />
  );
const headerCenter = <WSText weight="medium">My tax rate</WSText>;

export const TaxesFormTaxRate: React.FC<{
  editPath: string;
  isOnboarding?: boolean;
}> = ({ editPath, isOnboarding = false }) => {
  const history = useHistory();

  const year = moment().year();

  const qTaxEstimate = useTaxEstimate({
    type: TaxEstimateType.Base,
    year
  });

  const memberId = useUserId();
  const qMemberProfile = useMemberProfile(memberId);
  const activityQuery = useActivities(memberId);
  const queryVerifications = useQueryVerifications();

  const [
    updateMemberProfile,
    updateMemberProfileMeta
  ] = useUpdateMemberProfile();

  useBrowserPageTitle("Update Tax Profile - Tax Rate", "");

  const { openModal } = useModalContext();

  return (
    <WSQueries
      queries={{
        member: qMemberProfile,
        taxEstimate: qTaxEstimate,
        activityQuery
      }}
      renderLoader={() => (
        <WSLayout
          line
          headerCenter={headerCenter}
          headerLeft={headerLeft(history, isOnboarding)}
        >
          <WSLoader.Spinner my="3XL" />
        </WSLayout>
      )}
      renderErrors={errors => (
        <WSLayout
          line
          headerCenter={headerCenter}
          headerLeft={headerLeft(history, isOnboarding)}
        >
          <WSText color="garnet" align="center" mt="3XL">
            {errors.filter(Boolean).map(e => e.message)}
          </WSText>
        </WSLayout>
      )}
    >
      {({ member, taxEstimate, activityQuery: { data: activity } }) => {
        const { taxInfo, withholdings } = member.data.profile;
        const filingCode = taxInfo?.filingCode;
        const defaultTaxRate = Math.round(taxEstimate.data.taxRates.effective);
        const isOnboardingComplete = selectorIsOnboardingComplete(
          activity,
          queryVerifications.data
        );

        const afterSubmit = () => {
          if (isOnboarding) {
            if (isOnboardingComplete) {
              history.push("/member/taxes/dashboard");
            } else {
              history.push("/member/taxes/onboarding/no-payments");
            }
          } else {
            history.push("/member/taxes/dashboard");
          }
        };

        return (
          <>
            <TaxesModalTips />
            <WSFormOld
              onSubmit={async formData => {
                await updateMemberProfile(
                  {
                    memberId,
                    profile: {
                      withholdings: {
                        tax: {
                          rate: formData.taxRate,
                          status: isOnboarding
                            ? isOnboardingComplete
                              ? MemberWithholdingStatus.Active
                              : MemberWithholdingStatus.Paused
                            : member.data.profile?.withholdings?.tax?.status ||
                              MemberWithholdingStatus.Paused
                        }
                      }
                    }
                  },
                  { onSuccess: afterSubmit }
                );
              }}
              defaultValues={{
                taxRate: withholdings?.tax?.rate || defaultTaxRate
              }}
              validationSchema={Yup.object().shape({
                taxRate: Yup.number()
                  .min(1)
                  .max(100)
                  .required("Tax rate is required")
              })}
            >
              <WSLayout
                headerLeft={headerLeft(history, isOnboarding)}
                headerCenter={headerCenter}
                line
              >
                <WSContainer verticalPadding>
                  <WSFlexBox.Center mb="XL">
                    <WSText>Your estimated tax rate is</WSText>
                  </WSFlexBox.Center>

                  <WSCentered span={{ s: "8", m: "6", l: "4" }}>
                    <WSFormOld.Field
                      name="taxRate"
                      mb="XL"
                      component={WSStepper}
                      componentProps={{ min: 1, max: 100, postfix: "%" }}
                    />
                  </WSCentered>
                  <WSFlexBox.Center mb="M">
                    <WSFormOld.Value name="taxRate">
                      {(value: number) =>
                        withholdingRateHelpText(value, defaultTaxRate)
                      }
                    </WSFormOld.Value>
                  </WSFlexBox.Center>
                  <WSFlexBox.Center mb="2XL">
                    <WSButton.Link
                      onClick={() => openModal("tips")}
                      type="button"
                    >
                      Tax rate tips
                    </WSButton.Link>
                  </WSFlexBox.Center>
                  <WSCentered span={{ s: "10", m: "8", l: "6" }}>
                    <FieldSection
                      label="Filing status"
                      value={
                        FILLING_CODE_OPTIONS.find(
                          ({ value }) => value === filingCode
                        )?.label
                      }
                      onEdit={() => history.push(editPath + "/filing-status")}
                    />
                    <FieldSection
                      label="Estimated annual income"
                      value={
                        "$" +
                        getIncomeOptionsByFilingCode(filingCode as any).find(
                          ({ value }) =>
                            value === taxInfo?.incomeEstimateForYear
                        )?.label
                      }
                      onEdit={() => history.push(editPath + "/expected-income")}
                    />
                    <FieldSection
                      label="State of residence"
                      value={
                        US_STATES_OPTIONS.find(
                          ({ value }) => value === taxInfo?.stateOfResidence
                        )?.label
                      }
                      onEdit={() => history.push(editPath + "/state")}
                    />
                  </WSCentered>
                  <WSCentered span={{ s: "8", m: "6", xl: "4" }}>
                    {updateMemberProfileMeta.isError ? (
                      <WSText color="garnet">Something went wrong</WSText>
                    ) : null}

                    <WSFormOld.Value name="taxRate">
                      {(value: number) => (
                        <WSButtons forceFullWidth>
                          <WSFormOld.SubmitButton
                            mt="2XL"
                            type="submit"
                            data-testid="submitTaxRate"
                          >
                            Save {value}% of my income for taxes
                          </WSFormOld.SubmitButton>
                          <WSButton.Secondary
                            type="button"
                            mt="2XL"
                            loading={updateMemberProfileMeta.isLoading}
                            onClick={async () => {
                              await updateMemberProfile(
                                {
                                  memberId,
                                  profile: {
                                    withholdings: {
                                      tax: {
                                        rate: value,
                                        status: MemberWithholdingStatus.Paused
                                      }
                                    }
                                  }
                                },
                                { onSuccess: afterSubmit }
                              );
                            }}
                          >
                            Don’t save for taxes
                          </WSButton.Secondary>
                        </WSButtons>
                      )}
                    </WSFormOld.Value>
                    <WSFlexBox.Center mb="5XL" mt="M">
                      <WSText.ParagraphSm align="center" color="gray400">
                        Your taxes will be set-aside into an FDIC-ensured bank
                        account. You can pay taxes directly from Wingspan. You
                        can withdraw or deposit at anytime.
                      </WSText.ParagraphSm>
                    </WSFlexBox.Center>
                  </WSCentered>
                </WSContainer>
              </WSLayout>
            </WSFormOld>
          </>
        );
      }}
    </WSQueries>
  );
};
