import React from "react";
import {
  WSContainer,
  WSFlexBox,
  WSIcon
} from "@wingspanhq/fe-component-library";
import styles from "./styles.module.scss";
import logo from "../../../../assets/images/ws-logo.svg";

type Props = {
  organizationLogo?: string;
  clientLogo?: string;
};

export const InvitationAuthHeader: React.FC<Props> = ({
  organizationLogo = logo,
  clientLogo
}) => (
  <WSFlexBox.Center className={styles.header} colorBackground="white">
    <WSContainer>
      <WSFlexBox.Center direction="column" gap="S">
        {clientLogo && organizationLogo ? (
          <WSFlexBox.CenterY gap="XS" wrap="nowrap">
            <img
              className={styles.logo}
              src={organizationLogo}
              alt="Organization logo"
            />
            <WSIcon name="exit" size="M" color="black" />
            <img className={styles.logo} src={clientLogo} alt="Client logo" />
          </WSFlexBox.CenterY>
        ) : (
          <img
            className={styles.logo}
            src={organizationLogo}
            alt="Organization logo"
          />
        )}
      </WSFlexBox.Center>
    </WSContainer>
  </WSFlexBox.Center>
);
