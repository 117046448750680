import { useIsDesktop, WSGrid, WSPage } from "@wingspanhq/fe-component-library";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useUserId } from "../../../../../query/hooks/helpers";
import { usePayerTaxForm } from "../../../../../query/taxForm/queries/usePayerTaxForm";
import { useAuthorizedAccounts } from "../../../../../query/users/queries";
import { WSQueries } from "../../../../../query/WSQuery";
import { selectorMemberName } from "../../../../../shared/selectors/selectorMemberName";
import { FormEditRecipient } from "../../../components/FormEditRecipient";

import { VerificationStatus } from "@wingspanhq/payments/dist/interfaces";
import { useCallback, useEffect, useState } from "react";
import { RecipientVerificationFailed } from "../../../components/RecipientVerificationFailed";
import { RecipientVerificationProgress } from "../../../components/RecipientVerificationProgress";
import { selectorPayerTaxFormDefaultStatus } from "../../../selectors/selectorPayerTaxFormDefaultStatus";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";
import { useModalUpdateStatus } from "../RouteTaxFormDetails/ModalUpdateStatus";
import { Header } from "./Header";
import { useModalCancelEdit } from "./ModalCancelEdit";

export const RouteTaxFormEditRecipient: React.FC<RouteComponentProps<{
  taxFormId: string;
}>> = ({
  match: {
    params: { taxFormId }
  }
}) => {
  const userId = useUserId();
  const queryPayerTaxForm = usePayerTaxForm(taxFormId);
  const queryAuthorizedAccounts = useAuthorizedAccounts(userId);
  const history = useHistory();
  const isDesktop = useIsDesktop();

  const [verificationInProgress, setVerificationInProgress] = useState(false);
  const [isVerificationFailed, setIsVerificationFailed] = useState(false);
  const cancelModal = useModalCancelEdit();
  const updateStatusModal = useModalUpdateStatus();

  const onFinish = useCallback(() => {
    history.push(`/member/1099-filing/details/${taxFormId}`);
  }, [history, taxFormId]);

  const onVerificationFailed = () => {
    setVerificationInProgress(false);
    setIsVerificationFailed(true);
  };

  const onVerificationPending = () => {
    setVerificationInProgress(true);
  };

  useEffect(() => {
    if (verificationInProgress) {
      const interval = setInterval(async () => {
        const taxForm = await queryPayerTaxForm.refetch();
        if (taxForm) {
          const verificationStatus = selectorPayerTaxFormDefaultStatus(taxForm);

          if (verificationStatus === VerificationStatus.Verified) {
            onFinish();
          } else if (verificationStatus === VerificationStatus.Failed) {
            onVerificationFailed();
          }
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [onFinish, queryPayerTaxForm, verificationInProgress]);

  return (
    <WSPage>
      <WSQueries
        queries={{
          queryPayerTaxForm,
          queryAuthorizedAccounts
        }}
        onSuccess={({ queryPayerTaxForm }) => {
          const verificationStatus = selectorPayerTaxFormDefaultStatus(
            queryPayerTaxForm.data
          );

          if (verificationStatus === VerificationStatus.Failed) {
            onVerificationFailed();
          }
        }}
      >
        {({
          queryPayerTaxFormData: taxForm,
          queryAuthorizedAccountsData: authorizedAccounts
        }) => {
          const name = selectorTaxFormRecipientName(taxForm);
          const organizationName =
            authorizedAccounts.length && taxForm.payer
              ? selectorMemberName(taxForm.payer)
              : undefined;

          return verificationInProgress ? (
            <WSGrid>
              {isDesktop && <WSGrid.Item span={{ m: "3" }}></WSGrid.Item>}
              <WSGrid.Item span={{ m: "6" }}>
                <RecipientVerificationProgress
                  ms={10000}
                  onComplete={onFinish}
                />
              </WSGrid.Item>
            </WSGrid>
          ) : (
            <>
              <Header
                mb="2XL"
                taxFormId={taxFormId}
                name={name}
                pill={organizationName}
              />

              <WSGrid>
                <WSGrid.Item span={{ s: "8", m: "6" }}>
                  {isVerificationFailed && (
                    <RecipientVerificationFailed mb="2XL" taxForm={taxForm} />
                  )}
                  <FormEditRecipient
                    key={`key-for-reset-${taxForm.updatedAt}`}
                    isEdit={true}
                    taxForm={taxForm}
                    onCancel={async () => {
                      const result = await cancelModal.open();
                      if (result) {
                        onFinish();
                      }
                    }}
                    onBack={async () => {
                      onFinish();
                    }}
                    onSuccess={async responseTaxForm => {
                      if (
                        responseTaxForm &&
                        responseTaxForm?.status !== taxForm.status
                      ) {
                        await updateStatusModal.open({
                          taxForm: responseTaxForm
                        });
                      }

                      const verificationStatus = selectorPayerTaxFormDefaultStatus(
                        responseTaxForm
                      );

                      if (verificationStatus === VerificationStatus.Verified) {
                        onFinish();
                      } else if (
                        verificationStatus === VerificationStatus.Failed
                      ) {
                        onVerificationFailed();
                      } else {
                        onVerificationPending();
                      }
                    }}
                  />
                </WSGrid.Item>
              </WSGrid>
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
