import React from "react";
import {
  WSFormOld,
  WSSelectOld,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { IMember, IMemberUpdateRequest } from "@wingspanhq/users";
import * as Yup from "yup";

import { FormActions } from "./FormActions";

import styles from "./SettingsUpdateForms.module.scss";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import { useUserId } from "../../../query/hooks/helpers";
import { US_STATES_OPTIONS_SHORT_NAME } from "../../../constants/user";

interface FormProps {
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (data: any) => void;
}

export const StateOfIncorporationForm: React.FC<FormProps> = ({
  onSubmit,
  defaultValues,
  onCancel
}) => {
  const userId = useUserId();
  const [updateMemberProfile] = useUpdateMemberProfile();
  const onFormSubmit = async (data: any) => {
    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        company: {
          stateOfIncorporation: data.stateOfIncorporation
        }
      }
    };
    await updateMemberProfile(request, {
      onSuccess: () => {
        onSubmit(data);
      }
    });
  };
  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        stateOfIncorporation: Yup.string().required("This field is required")
      })}
    >
      <WSFormOld.Field
        name="stateOfIncorporation"
        component={WSSelectOld}
        componentProps={{
          options: US_STATES_OPTIONS_SHORT_NAME,
          searchable: true
        }}
        label="State of incorporation"
      />
      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <FormActions onCancel={onCancel} loading={isSubmitting} />
        )}
      </WSFormOld.Context>
    </WSFormOld>
  );
};
