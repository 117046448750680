import {
  WSButton,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSImage,
  WSMenuItem,
  WSTable,
  WSTableCell,
  WSTableItem,
  WSText,
  useIsMobile,
  useWSSnackbar
} from "@wingspanhq/fe-component-library";
import { UPLOAD_DOCUMENTS_ALLOWED_FORMATS } from "./constants";
import { DocumentsSectionType } from "./types";
import { useUploadDocumentModal } from "./useUploadDocumentModal";

import { IMemberFileResponse } from "@wingspanhq/files/dist/lib/interfaces/api/file";
import React from "react";
import noDocumentsImg from "../../../assets/images/documents/documents.svg";
import { WSQueries } from "../../../query/WSQuery";
import { useDownloadPrivateDocument } from "../../../query/files/mutations";
import { useMemberPrivateFiles } from "../../../query/files/queries";
import { useUserId } from "../../../query/hooks/helpers";
import { downloadFileFromBuffer } from "../../../utils/files";
import { selectorIsFileVisibleByUserId } from "../../selectors/selectorIsFileVisibleByUserId";
import styles from "./styles.module.scss";
import { useConfirmDeleteDocumentModal } from "./useConfirmDeleteDocumentModal";

function DocumentName({
  filename,
  description
}: {
  filename: string;
  description?: string;
}) {
  return (
    <WSElement data-testid="fileName">
      <WSText singleLine mb="XS">
        {filename}
      </WSText>
      {description ? (
        <WSText.ParagraphXs color="gray400">
          Visible to contractor
        </WSText.ParagraphXs>
      ) : null}
    </WSElement>
  );
}

export interface DocumentsSectionProps extends WSElementProps {
  type: DocumentsSectionType;
  payerId: string;
  payeeId: string;
}

export const DocumentsSection: React.FC<DocumentsSectionProps> = ({
  type,
  payerId,
  payeeId,
  ...otherProps
}) => {
  const { openSnackbar } = useWSSnackbar();
  const userId = useUserId();
  const isMobile = useIsMobile();
  const uploadDocumentModal = useUploadDocumentModal();
  const confirmDeleteDocumentModal = useConfirmDeleteDocumentModal();
  const queryDocuments = useMemberPrivateFiles({
    hidden: true,
    tags: [
      type === DocumentsSectionType.Collaborator
        ? `payee:${payeeId}`
        : `payer:${payerId}`
    ]
  });
  const [downloadDocument] = useDownloadPrivateDocument();

  const onClickAddDocument = () => {
    uploadDocumentModal.open({
      payerId,
      payeeId
    });
  };

  const renderNoDocumentsView = () => {
    const noDataView =
      type === DocumentsSectionType.Collaborator ? (
        <WSFlexBox wrap="nowrap">
          <WSImage
            className={styles.noDocumentsImg}
            placeholder="no documents"
            src={noDocumentsImg}
          />
          <WSFlexBox.CenterX direction="column" p="M">
            <WSText.Heading5 mb="S">No documents yet</WSText.Heading5>
            <WSText.ParagraphSm mb="M" color="gray600">
              Only {UPLOAD_DOCUMENTS_ALLOWED_FORMATS.label} supported. File
              limit of 100MB per file.
            </WSText.ParagraphSm>
            <WSButton.Secondary onClick={onClickAddDocument}>
              Add document
            </WSButton.Secondary>
          </WSFlexBox.CenterX>
        </WSFlexBox>
      ) : (
        <WSFlexBox.Center wrap="nowrap">
          <WSImage
            className={styles.noDocumentsImg}
            placeholder="no documents"
            src={noDocumentsImg}
          />
          <WSText.Heading5>No documents yet</WSText.Heading5>
        </WSFlexBox.Center>
      );
    return (
      <>
        <WSDivider mb="M" />
        {noDataView}
      </>
    );
  };

  const onDownload = async (row: WSTableItem<IMemberFileResponse>) => {
    await downloadDocument(row.data?.fileId, {
      onSuccess: pdfBlob => {
        downloadFileFromBuffer(pdfBlob, row.data?.filename, row.data?.mimetype);
      },
      onError: () => {
        openSnackbar({
          message: "Download failed",
          type: "warning"
        });
      }
    });
  };

  const rowMenuActions = (
    row: WSTableItem<IMemberFileResponse>
  ): WSMenuItem[] =>
    type === DocumentsSectionType.Collaborator
      ? [
          {
            label: "Download",
            icon: "download",
            onClick: () => onDownload(row)
          },
          {
            label: "Delete",
            icon: "trash",
            onClick: () =>
              confirmDeleteDocumentModal.open({
                documentId: row.data.fileId
              })
          }
        ]
      : [];

  return (
    <WSQueries queries={{ queryDocuments }}>
      {({ queryDocumentsData: documents }) => {
        return (
          <WSElement {...otherProps}>
            <WSFlexBox.CenterY justify="space-between" wrap="nowrap" mb="S">
              <WSFlexBox direction="column" className={styles.headerContent}>
                <WSText.Heading5>Documents</WSText.Heading5>
                <WSText.ParagraphSm color="gray400">
                  {type === DocumentsSectionType.Collaborator
                    ? "Documents added here are visible to you and the contractor only"
                    : "Documents added here are visible to you and the client only"}
                </WSText.ParagraphSm>
              </WSFlexBox>
              {type === DocumentsSectionType.Collaborator ? (
                <WSButton.Link onClick={onClickAddDocument}>
                  Add document
                </WSButton.Link>
              ) : null}
            </WSFlexBox.CenterY>
            {documents.length > 0 ? (
              <WSTable<IMemberFileResponse>
                tableData={documents.map((document: IMemberFileResponse) => {
                  return {
                    id: document.fileId,
                    data: document
                  };
                })}
                columns={[
                  {
                    config: {
                      gridTemplateSizeMin: "auto",
                      gridTemplateSizeMax:
                        type === DocumentsSectionType.Client ? "0.7fr" : "1fr"
                    },
                    renderFunction: row => {
                      let description;

                      if (
                        selectorIsFileVisibleByUserId(
                          payeeId,
                          row.data.userRoles
                        )
                      ) {
                        description = "Visible to contractor";
                      }

                      return (
                        <WSTableCell
                          avatar={{
                            type: "icon",
                            icon: "paperclip",
                            colorBackground: "gray50",
                            color: "gray500"
                          }}
                          text={row.data.filename}
                          secondaryText={description}
                        />
                      );
                    }
                  }
                ]}
                rowActions={
                  type === DocumentsSectionType.Client
                    ? row => [
                        {
                          onClick: () => onDownload(row),
                          text: "Download"
                        }
                      ]
                    : undefined
                }
                rowMenuActions={rowMenuActions}
              />
            ) : (
              renderNoDocumentsView()
            )}
          </WSElement>
        );
      }}
    </WSQueries>
  );
};
