import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSImage,
  WSLayout,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import handsUpImage from "../../assets/icons/hands_up.svg";
import { useUserId } from "../../query/hooks/helpers";
import { List } from "../../ClientPayments/components/List/List";
import { useUpdateActivity } from "../../query/users/mutations";
import styles from "./Benefits.module.scss";
import { useHistory } from "react-router-dom";
import { getPathAfterSuccess } from "../utils";
import { BenefitsLogoHeader } from "../components/BenefitsLogoHeader/BenefitsLogoHeader.module";

export const BenefitsSetUpSuccess: React.FC = () => {
  const userId = useUserId();
  const history = useHistory();
  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);

  return (
    <WSLayout headerCenter={<BenefitsLogoHeader />}>
      <WSElement className={styles.wrapper}>
        <WSMessageBox.Success className={styles.successMessage}>
          <WSFlexBox.Center>
            <WSIcon block name="check" mr="M" color="green500" />
            <WSText>Benefits activated successfully.</WSText>
          </WSFlexBox.Center>
        </WSMessageBox.Success>

        <WSElement as="main" className={styles.main}>
          <WSImage src={handsUpImage} placeholder={<></>} width={60} mb="XL" />
          <WSText.Heading5 mb="XS" data-testid="sentText">
            Your benefits are processing
          </WSText.Heading5>
          <WSText mb="2XL" color="gray500">
            We’re working hard to process your enrollment.
          </WSText>

          <WSText weight="medium">
            Just a few things you will get access to in 1-2 days, included with
            your Wingspan membership...
          </WSText>

          <List
            items={[
              "We'll help you find, enroll in, and get the most out of your health insurance plan",
              "Talk to a doctor for free with 24/7 access to telemedicine",
              "Dedicated support from your Health Advocate to negotiate bills",
              "Save 15% to 50% on dental expenses, 30% on alternative medicine",
              "Discounts on pet care"
            ]}
            icon="check"
            mb="2XL"
          />

          <WSButton.Primary
            loading={updateActivityMeta.isLoading}
            onClick={async () => {
              history.push(getPathAfterSuccess() || "/member/benefits");
            }}
          >
            Explore Benefits
          </WSButton.Primary>
        </WSElement>
      </WSElement>
    </WSLayout>
  );
};
