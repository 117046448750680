import { IBalanceResponse } from "@wingspanhq/bookkeeping/dist/lib/interfaces/withholdingTransaction";
import { IS_DEV_ENV } from "../../../shared/constants/environment";

export const selectorWithholdingBalance = (balance: IBalanceResponse) => {
  // TODO: We are using legacy "taxWithholdingBalance" just for test on staging, in prod we are using only unit balances, until we add fake unit balance on BE for staging
  if (IS_DEV_ENV) {
    return Math.abs(balance.taxWithholdingBalance || 0);
  }

  return balance.balance || 0;
};
