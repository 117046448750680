import sortBy from "lodash/sortBy";
import {
  IPayerEngagementResponse,
  PayerPayeeEngagementStatus
} from "@wingspanhq/payments/dist/interfaces";
import uniqBy from "lodash/uniqBy";
import { RequirementTableItemData } from "../types";
import { selectorIsRequirement } from "./selectorIsRequirement";
import { IPayeeEngagementResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";

export const selectorUniqRequirementsFromEngagementsList = (
  engagements: Array<IPayeeEngagementResponse | IPayerEngagementResponse>
) => {
  const allRequirements = sortBy<RequirementTableItemData>(
    engagements
      .filter(
        engagement => engagement.status === PayerPayeeEngagementStatus.Active
      )
      .map(engagement =>
        engagement.engagementId
          ? engagement.requirements
          : engagement.eligibilityRequirements
      )
      .flat(),
    "engagementName"
  );

  const uniqRequirements = uniqBy(allRequirements, requirement => {
    return selectorIsRequirement(requirement)
      ? requirement.requirementDefinitionId
      : requirement.eligibilityRequirementId;
  });

  const uniqRequirementsWithEngagementIds = uniqRequirements.map(
    requirement => {
      return {
        ...requirement,
        engagementId: engagements.find(engagement =>
          selectorIsRequirement(requirement)
            ? (engagement.requirements || []).includes(requirement)
            : engagement.eligibilityRequirements.includes(requirement)
        )!.payerPayeeEngagementId
      };
    }
  );

  const uniqRequirementsWithRelatedEngagementIds = uniqRequirementsWithEngagementIds.map(
    requirement => {
      const relatedEngagementRequirementIds = engagements
        .map(engagement => {
          const matchingRequirement = engagement.requirements?.find(
            req =>
              selectorIsRequirement(requirement) &&
              req.requirementDefinitionId ===
                requirement.requirementDefinitionId
          );

          return matchingRequirement
            ? {
                engagementId: engagement.payerPayeeEngagementId,
                relatedRequirementId: matchingRequirement.requirementId
              }
            : null;
        })
        .filter(Boolean);

      return {
        ...requirement,
        relatedEngagementRequirementIds
      };
    }
  );

  return uniqRequirementsWithRelatedEngagementIds;
};
