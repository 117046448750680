import { ISharedFileRequestResponse } from "@wingspanhq/shared-files/dist/lib/interfaces";
import { serviceSharedFiles } from "./serviceSharedFiles";

export const getSharedFile = async (sharedFileId: string) => {
  const { data } = await serviceSharedFiles.get<ISharedFileRequestResponse>(
    `/request/${sharedFileId}`
  );

  return data;
};
