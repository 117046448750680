import React from "react";
import { FieldValues, useFormContext, useWatch } from "react-hook-form";

export interface WSFormDataProps<TFieldValues> {
  children(props: TFieldValues): React.ReactNode;
}

export const WSFormData = <TFieldValues extends FieldValues = FieldValues>({
  children
}: WSFormDataProps<TFieldValues>) => {
  const { control } = useFormContext();

  const data = useWatch({
    control
  });

  return <>{children(data as TFieldValues)}</>;
};
