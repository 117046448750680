import {
  ICollaboratorGroupResponse,
  PayerPayeeEngagementStatus
} from "@wingspanhq/payments/dist/interfaces";
import { IPayeeEngagementResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { getTmpEngagementName } from "./getTmpEngagementName";
import { ISearchablePayerPayeeEngagement } from "../../../services/search";

export const selectorPayeeEngagementsTmp = <
  T extends {
    status: PayerPayeeEngagementStatus;
    collaboratorGroupIds?: string[];
  }
>(
  engagements: T[],
  forceShowAll: boolean,
  includeDefaultEngagement: boolean,
  includeInactiveEngagements: boolean
): T[] => {
  if (forceShowAll) {
    return engagements;
  }

  let isDefaultEngagementIncluded = false;

  return engagements.filter(engagement => {
    const canShowEngagement =
      includeInactiveEngagements ||
      engagement.status === PayerPayeeEngagementStatus.Active;

    // always showing for engagement in groups
    if (canShowEngagement && engagement.collaboratorGroupIds?.length) {
      return true;
    }

    if (
      includeDefaultEngagement &&
      !isDefaultEngagementIncluded &&
      engagement.status === PayerPayeeEngagementStatus.Active
    ) {
      isDefaultEngagementIncluded = true;
      return true;
    }

    return false;
  });
};

export const selectorPayeeEngagementNameTmp = <D extends boolean = boolean>(
  engagement: IPayeeEngagementResponse | ISearchablePayerPayeeEngagement,
  groups: ICollaboratorGroupResponse[],
  showDefaultName: boolean
) => {
  if (
    "engagementId" in engagement &&
    engagement.engagementId &&
    engagement.engagementName
  ) {
    return engagement.engagementName;
  }

  const relatedGroups = groups.filter(group =>
    engagement.collaboratorGroupIds?.includes(group.collaboratorGroupId)
  );

  if (relatedGroups.length) {
    return relatedGroups.map(group => group.name).join(", ");
  }

  if (showDefaultName) {
    return getTmpEngagementName(engagement);
  }

  return undefined as D extends true ? string : string | undefined;
};
