import * as Yup from "yup";
import React, { useState } from "react";

import { ITransaction } from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";

import {
  useModalContext,
  useWSSnackbar,
  WSButton,
  WSButtons,
  WSFormOld,
  WSModal,
  WSRadioInputGroup,
  WSText
} from "@wingspanhq/fe-component-library";

import { useUpdateTransaction } from "../../../../query/bookkeeping/mutations";
import { runner } from "./utils.";

type BookkeepingModalBulkBusinessProps = {
  selectedAreAllBusiness: boolean;
  selectedTransactions: ITransaction[] | undefined;
  onActionFinish(): void;
};

export const BOOKKEEPING_MODAL_BULK_BUSINESS =
  "BOOKKEEPING_MODAL_BULK_BUSINESS";

export const BookkeepingModalBulkBusiness: React.FC = () => {
  const { closeModal } = useModalContext();
  const { openSnackbar } = useWSSnackbar();

  const [isUpdating, setIsUpdating] = useState(false);

  const [updateTransaction] = useUpdateTransaction();

  // TODO: Need to prevent closing during operation
  return (
    <WSModal
      blockClose={isUpdating}
      name={BOOKKEEPING_MODAL_BULK_BUSINESS}
      size="S"
    >
      {({
        selectedTransactions,
        selectedAreAllBusiness,
        onActionFinish
      }: BookkeepingModalBulkBusinessProps) => (
        <>
          {selectedTransactions && selectedTransactions.length > 0 ? (
            <>
              <WSText.Heading5 mb="M">
                {selectedTransactions.length > 1
                  ? `Are these ${selectedTransactions.length} transactions business-related?`
                  : "Is this transaction business-related?"}
              </WSText.Heading5>
              <WSFormOld
                defaultValues={{
                  business: selectedAreAllBusiness ? "business" : ""
                }}
                validationSchema={Yup.object().shape({
                  business: Yup.string()
                })}
                onSubmit={async formData => {
                  setIsUpdating(true);

                  await runner({
                    items: selectedTransactions,
                    openSnackbar,
                    tryAction: async transaction =>
                      await updateTransaction(
                        {
                          transactionId: transaction.transactionId,
                          business: !!formData.business
                        },
                        {
                          throwOnError: true
                        }
                      ),
                    undoAction: async transaction =>
                      await updateTransaction({
                        transactionId: transaction.transactionId,
                        business: transaction.business
                      }),
                    successMessage: successItems =>
                      `${successItems.length} marked "${
                        formData.business ? "Business" : "Non-Business"
                      }"`,
                    failMessage: failedItems =>
                      `${failedItems.length} updates to "${
                        formData.business ? "Business" : "Non-Business"
                      }" failed`
                  });

                  setIsUpdating(false);
                  onActionFinish();
                  closeModal(BOOKKEEPING_MODAL_BULK_BUSINESS);
                }}
              >
                <WSFormOld.Field
                  my="XL"
                  label="Transaction"
                  name="briefcase"
                  component={WSRadioInputGroup}
                  componentProps={{
                    options: [
                      {
                        value: "business",
                        label: "Business"
                      },
                      {
                        value: "",
                        label: "Non-business (ignore)"
                      }
                    ]
                  }}
                />
                <WSButtons mt="M" forceFullWidth format="modal">
                  <WSButton loading={isUpdating}>Update</WSButton>
                  <WSButton.Tertiary
                    disabled={isUpdating}
                    onClick={() => closeModal(BOOKKEEPING_MODAL_BULK_BUSINESS)}
                  >
                    Cancel
                  </WSButton.Tertiary>
                </WSButtons>
              </WSFormOld>
            </>
          ) : (
            closeModal(BOOKKEEPING_MODAL_BULK_BUSINESS)
          )}
        </>
      )}
    </WSModal>
  );
};
