import React, { useEffect, useState } from "react";
import {
  useModalContext,
  useWSModal,
  WSElement,
  WSFlexBox,
  WSLoader,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";

import {
  useCreateIntegrationsQuickbooksAccountAssets,
  useCreateIntegrationsQuickbooksAccountExpenses,
  useCreateIntegrationsQuickbooksAccountLiabilities,
  useCreateIntegrationsQuickbooksAccountRevenues,
  useCreateIntegrationsQuickbooksCustomers,
  useCreateIntegrationsQuickbooksItems,
  useCreateIntegrationsQuickbooksVendors,
  useUpdateIntegrationQuickbooks
} from "../../../../query/integrations/mutations";
import { IntegrationsQuickbooksSyncStatus } from "@wingspanhq/integrations/dist/lib/interfaces/quickbooks-api/integrationsQuickbooks";

export const INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT =
  "INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT";

export enum QUICKBOOKS_ENTITY {
  CUSTOMERS = "CUSTOMERS",
  VENDORS = "VENDORS",
  ITEMS = "ITEMS",
  EXPENSES = "EXPENSES",
  ACCOUNTS = "ACCOUNTS"
}

const entityToNameMap = {
  [QUICKBOOKS_ENTITY.CUSTOMERS]: "Customers",
  [QUICKBOOKS_ENTITY.VENDORS]: "Vendors",
  [QUICKBOOKS_ENTITY.ITEMS]: "Items",
  [QUICKBOOKS_ENTITY.EXPENSES]: "Expense Accounts",
  [QUICKBOOKS_ENTITY.ACCOUNTS]: "Accounts"
};

export const IntegrationsQuickbooksResyncSelectModal: React.FC<{
  entity: QUICKBOOKS_ENTITY;
}> = ({ entity }) => {
  const { closeModal } = useModalContext();

  const [
    createIntegrationsQuickbooksAccountAssets
  ] = useCreateIntegrationsQuickbooksAccountAssets();
  const [
    createIntegrationsQuickbooksAccountExpenses
  ] = useCreateIntegrationsQuickbooksAccountExpenses();
  const [
    createIntegrationsQuickbooksAccountLiabilities
  ] = useCreateIntegrationsQuickbooksAccountLiabilities();
  const [
    createIntegrationsQuickbooksCustomers
  ] = useCreateIntegrationsQuickbooksCustomers();
  const [
    createIntegrationsQuickbooksItems
  ] = useCreateIntegrationsQuickbooksItems();
  const [
    createIntegrationsQuickbooksVendors
  ] = useCreateIntegrationsQuickbooksVendors();

  const [currentStep, setCurrentStep] = useState("Starting resync");

  const doSync = async () => {
    setCurrentStep("Beginning sync");

    // await updateIntegration({
    //   syncStatus: IntegrationsQuickbooksSyncStatus.PendingSync
    // });

    if (entity === QUICKBOOKS_ENTITY.CUSTOMERS) {
      setCurrentStep("Syncing customers");
      await createIntegrationsQuickbooksCustomers();
    } else if (entity === QUICKBOOKS_ENTITY.VENDORS) {
      setCurrentStep("Syncing vendors");
      await createIntegrationsQuickbooksVendors();
    } else if (entity === QUICKBOOKS_ENTITY.EXPENSES) {
      setCurrentStep("Syncing expense accounts");
      await createIntegrationsQuickbooksAccountExpenses();
    } else if (entity === QUICKBOOKS_ENTITY.ITEMS) {
      setCurrentStep("Syncing items");
      await createIntegrationsQuickbooksItems();
    } else if (entity === QUICKBOOKS_ENTITY.ACCOUNTS) {
      setCurrentStep("Syncing asset accounts");
      await createIntegrationsQuickbooksAccountAssets();
      setCurrentStep("Syncing liability accounts");
      await createIntegrationsQuickbooksAccountLiabilities();
    }

    closeModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT);
  };

  useEffect(() => {
    doSync();
  }, []);

  return (
    <>
      <WSText.Heading5
        mb="XL"
        data-testid="ReSyncQboAccountsModal"
      >{`Resyncing your QBO ${entityToNameMap[entity]} 🔧`}</WSText.Heading5>
      <WSText mb="3XL">
        We're syncing Wingspan with your Quickbooks. This should only take a
        moment.
      </WSText>

      <WSElement mb="XL">
        <WSLoader.Spinner mb="M" />
        <WSFlexBox justify="center">
          <WSText.ParagraphXs>{currentStep}</WSText.ParagraphXs>
        </WSFlexBox>
      </WSElement>
    </>
  );
};

export const RefreshModal: React.FC = () => {
  return (
    <>
      <WSModal
        blockClose={true}
        showCloseIcon={false}
        name={INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT}
      >
        {({ entity }) => (
          <IntegrationsQuickbooksResyncSelectModal entity={entity} />
        )}
      </WSModal>
    </>
  );
};

// Refactored version of RefreshModal
export interface QBORefreshModalProps {
  entity: QUICKBOOKS_ENTITY;
}

export const QBORefreshModal: React.FC<QBORefreshModalProps> = ({ entity }) => {
  return <IntegrationsQuickbooksResyncSelectModal entity={entity} />;
};

export function useQboRefreshModal() {
  return useWSModal(QBORefreshModal, {
    blockClose: true,
    showCloseIcon: false
  });
}
