import { ICollaboratorSchema } from "@wingspanhq/payments/dist/interfaces";
import { IDeductionResponse } from "@wingspanhq/payments/dist/interfaces/api/deductions";
import isEmpty from "lodash/isEmpty";
import {
  getCollaboratorCompany,
  getCollaboratorName
} from "../../../query/payments/selectors";
import { cleanCSVData } from "../../../shared/utils/csv";

export type DeductionTableItem = {
  deduction: IDeductionResponse;
  collaborator?: ICollaboratorSchema;
};
const mapDeductionTableItemData = (tableItem: DeductionTableItem) => {
  const { deduction, collaborator } = tableItem;

  if (!deduction) {
    return {};
  }

  return {
    "Created Date": deduction.createdAt,
    "Start Date": deduction.startDate,
    Name: collaborator ? getCollaboratorName(collaborator) : undefined,
    "First Name": collaborator?.member?.user?.profile?.firstName,
    "Last Name": collaborator?.member?.user?.profile?.lastName,
    "Preferred Name": (collaborator?.member?.user?.profile as any)
      ?.preferredName,
    "Company Name": collaborator
      ? getCollaboratorCompany(collaborator)
      : undefined,
    Email: collaborator?.member?.user?.email,
    "Contractor ID": collaborator?.collaboratorId,
    "External ID": collaborator?.clientData?.externalId,
    "Deduction Status": deduction.status,
    "Deduction Type": deduction.type,
    "Deducted Payable IDs": deduction.application
      .map(a => a.payableId)
      .join(", "),
    "Deduction ID": deduction.deductionId,
    "Deduction Template ID": deduction.deductionTemplateId,
    Amount: deduction.amount,
    "Amount Deducted": deduction.application
      .map(a => a.amountDeducted)
      .reduce((a, b) => a + b, 0),
    Currency: deduction.currency,
    Priority: deduction.priority,
    "Deduction Name": deduction.name
  };
};
export const mapDeductionsTableToCSV = (data: Array<DeductionTableItem>) => {
  let cleanResult = [];

  const dirtyResult = data
    .map(d => {
      try {
        return mapDeductionTableItemData(d);
      } catch (error) {
        console.error(error);
      }
      return {};
    })
    .filter(d => !isEmpty(d));

  cleanResult = dirtyResult.map(d => cleanCSVData(d, dirtyResult)) as Array<
    any
  >;

  return cleanResult;
};
