import { servicePayments } from "../servicePayments";

export const downloadPayeeW9 = async (payeeId: string): Promise<Blob> => {
  const { data: blob } = await servicePayments.get(
    `/payee/${payeeId}/download-w9`,
    {
      responseType: "blob"
    }
  );

  return blob;
};
