import React, { useEffect } from "react";
import {
  WSButton,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import { track } from "../../utils/analytics";
import { openIntercom } from "../../shared/utils/intercom";

type Props = {
  includeSupport?: boolean;
  message?: string;
  doReload?: () => void;
};

export const NotFoundMessage: React.FC<Props> = ({
  includeSupport = true,
  message = "Sorry, what you're looking for doesn't exist!",
  doReload
}) => {
  useEffect(() => {
    track("NotFound Component Rendered");
  }, []);

  return (
    <WSFlexBox.Center
      p="XL"
      direction="column"
      wrap="nowrap"
      style={{ height: "100%", flex: "1" }}
      data-testid="serviceError"
    >
      <WSIcon block name="alert-circle" size="L" color="amber400" />
      <WSText.Heading5 color="gray600" mt="XL">
        {message}
      </WSText.Heading5>

      {doReload && (
        <WSFlexBox.Center pt="3XL">
          <WSButton onClick={doReload}>Reload</WSButton>
        </WSFlexBox.Center>
      )}

      {includeSupport && (
        <WSFlexBox.Center pt="2XL">
          <WSText.ParagraphSm align="center">
            If you need help, please{" "}
            <WSButton.Link onClick={openIntercom}>
              contact our support
            </WSButton.Link>{" "}
            and our team will be happy to help you
          </WSText.ParagraphSm>
        </WSFlexBox.Center>
      )}
    </WSFlexBox.Center>
  );
};
