import { IInvoice } from "@wingspanhq/payments/dist/interfaces";

export const getPayoutSummaryFees = (invoices: IInvoice[]) => {
  return invoices.reduce((total, invoice) => {
    const { amountDetails, processingFees } = invoice;
    return (
      total +
      (amountDetails?.processingFee || 0) +
      (processingFees?.instantPayoutFee?.amount || 0)
    );
  }, 0);
};
