import {
  IMemberClient,
  IPayeeTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces";

export const getPaymentsLinkForTaxDocument = (
  memberClients: IMemberClient[],
  taxForm: IPayeeTaxFormResponse
) => {
  const memberClient = memberClients.find(
    memberClient => memberClient.clientId === taxForm.clientId
  )!;

  return (
    `/member/invoices/list?status%5B0%5D=Paid` +
    `&memberClientId=${memberClient.memberClientId}` +
    `&customPaidDateRange%5B0%5D=${taxForm.year}-01-01T00%3A00%3A00.000Z` +
    `&customPaidDateRange%5B1%5D=${taxForm.year}-12-31T00%3A00%3A00.000Z` +
    `&paidDateRange=Custom` +
    `&navBack=1`
  );
};
