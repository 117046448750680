import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { OnboardingModule } from "./types";

export const Context = createContext<{
  value: object;
  setValue: Dispatch<SetStateAction<object>>;
  modules: OnboardingModule[];
}>({ value: {}, setValue: () => {}, modules: [] });

export const useOnboardingContext = <Value>() => {
  const { value: context, setValue: setContext } = useContext(Context);

  return [context, setContext] as [Value, Dispatch<SetStateAction<Value>>];
};

export const useOnboardignModules = () => {
  const { modules } = useContext(Context);
  return modules;
};
