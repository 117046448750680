import { QueryConfig } from "react-query";
import { useWSQuery } from "../../helpers";
import { QUERY_PAYMENTS_TAXFORM_LIST_PAYEE } from "../keys";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { paymentsService } from "../../../services/payments";
import { WSServiceError } from "../../../utils/serviceHelper";

export const usePayeeTaxForms = (
  year: number,
  queryConfig?: QueryConfig<IPayeeTaxFormResponse[], unknown>
) =>
  useWSQuery<IPayeeTaxFormResponse[], WSServiceError>(
    [QUERY_PAYMENTS_TAXFORM_LIST_PAYEE, year],
    () => paymentsService.taxForm.payeeList(year),
    queryConfig
  );
