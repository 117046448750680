import React from "react";
import {
  WSFormOld,
  WSCheckboxGroup,
  WSTextInput,
  WSText,
  WSFlexBox
} from "@wingspanhq/fe-component-library";
import { IMemberOccupation, IMemberUpdateRequest } from "@wingspanhq/users";
import * as Yup from "yup";

import { FormActions } from "./FormActions";

import styles from "./SettingsUpdateForms.module.scss";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import { useUserId } from "../../../query/hooks/helpers";

interface CustomOccupationInputProps {}

const CustomOccupationInput: React.FC<CustomOccupationInputProps> = props => {
  return (
    <WSFlexBox.CenterY>
      <WSText.ParagraphSm mr="M">Other</WSText.ParagraphSm>
      <WSFormOld.Field name="customOccupation">
        <WSFormOld.Input render={props => <WSTextInput {...props} />} />
        <WSFormOld.Error />
      </WSFormOld.Field>
    </WSFlexBox.CenterY>
  );
};

interface MemberOccupationsFormProps {
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (data: any) => void;
}

export const MemberOccupationsForm: React.FC<MemberOccupationsFormProps> = ({
  onSubmit,
  defaultValues,
  onCancel
}) => {
  const userId = useUserId();
  const [updateMemberProfile] = useUpdateMemberProfile();
  const onFormSubmit = async (data: any) => {
    const { occupations: prevOccupations } = defaultValues;
    const newOccupations1 = prevOccupations.map((occupation: string) => {
      if (data.occupations.includes(occupation)) {
        return {
          category: occupation,
          customCategory:
            occupation === "Other" ? data.customOccupation : undefined
        };
      }
      return null;
    });
    const newOccupations2: Array<IMemberOccupation> = [];
    data.occupations.forEach((occupation: string) => {
      if (prevOccupations.includes(occupation) === false) {
        newOccupations2.push({
          category: occupation,
          customCategory:
            occupation === "Other" ? data.customOccupation : undefined
        });
      }
    });
    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        taxInfo: {
          occupations: [...newOccupations1, ...newOccupations2]
        }
      }
    };
    await updateMemberProfile(request, {
      onSuccess: () => {
        onSubmit(data);
      }
    });
  };
  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        occupations: Yup.array(Yup.string().required())
          .required("Type of work is required")
          .min(1),
        customOccupation: Yup.string()
      })}
    >
      <WSFormOld.Field name="occupations">
        <WSFormOld.Input
          render={props => (
            <>
              <WSCheckboxGroup
                {...props}
                options={[
                  {
                    label: "Art, Design and Entertainment",
                    value: "Art, Design and Entertainment"
                  },
                  {
                    label: "Media & Advertising",
                    value: "Media & Advertising"
                  },
                  {
                    label: "Professional Services",
                    value: "Professional Services"
                  },
                  {
                    label: "Technology",
                    value: "Technology"
                  },
                  {
                    label: "Wellness & Personal Services",
                    value: "Wellness & Personal Services"
                  },
                  {
                    label: <CustomOccupationInput />,
                    value: "Other"
                  }
                ]}
              />
              <WSFormOld.Error />
            </>
          )}
        />
      </WSFormOld.Field>
      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <FormActions onCancel={onCancel} loading={isSubmitting} />
        )}
      </WSFormOld.Context>
    </WSFormOld>
  );
};
