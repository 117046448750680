import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces";
import { FormRequestCorrectionData } from "../routes/RequestCorrectionFlow/types";
import {
  getPayeeFormW9Address,
  getPayeeTINorName
} from "../routes/RequestCorrectionFlow/getDefaultValuesForCorrectionsForm";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../constants/corrections";
import { getLast4 } from "../../../shared/utils/getLast4";
import { ICorrectionType } from "../types";
import { selectorNo1099 } from "../../1099NECFiling/components/FormTaxpayerInformation/selectorNo1099";

export function getCorrectionType(
  taxForm: IPayeeTaxFormResponse | IPayerTaxFormResponse,
  correctedData: FormRequestCorrectionData
): ICorrectionType {
  let addressStateNotChanged = false;
  const corrections = [];

  // type1 correction
  if (
    correctedData?.amountCorrectionHelpText?.trim() &&
    correctedData?.necTotalAmount >= 0 &&
    correctedData?.necTotalAmount !== taxForm?.data?.totalAmount
  ) {
    corrections.push(CORRECTION_AMOUNT);
  }

  const originalTinData = getPayeeTINorName(taxForm);
  // type2 TIN or name correction

  if (
    (correctedData?.ein &&
      (correctedData?.businessName !== originalTinData?.businessName ||
        correctedData?.taxClassification !==
          originalTinData?.taxClassification ||
        correctedData?.disregardedEntityName !==
          originalTinData?.disregardedEntityName ||
        correctedData?.ein !== originalTinData?.ein)) ||
    (correctedData?.ssn &&
      (getLast4(correctedData?.ssn) !== originalTinData?.ssnLastFour ||
        correctedData?.firstName !== originalTinData?.firstName ||
        correctedData?.lastName !== originalTinData?.lastName))
  ) {
    corrections.push(CORRECTION_TIN_OR_NAME);
  }

  const address = getPayeeFormW9Address(taxForm);
  // type2 address correction
  if (
    correctedData?.formW9Address &&
    (correctedData?.formW9Address?.addressLine1 !== address.addressLine1 ||
      correctedData?.formW9Address?.addressLine2 !== address.addressLine2 ||
      correctedData?.formW9Address?.city !== address.city ||
      correctedData?.formW9Address?.state !== address.state ||
      correctedData?.formW9Address?.zipCode !== address.zipCode ||
      correctedData?.formW9Address?.country !== address.country)
  ) {
    corrections.push(CORRECTION_ADDRESS);
  }

  // type2 address correction -- state not changed
  if (
    correctedData?.formW9Address &&
    correctedData.formW9Address?.state === address?.state &&
    correctedData.formW9Address?.country === address?.country
  ) {
    addressStateNotChanged = true;
  }

  const isVoidType =
    correctedData?.taxClassification &&
    selectorNo1099(correctedData.taxClassification);
  const noChangeInTinData =
    correctedData?.businessName === originalTinData?.businessName &&
    correctedData?.disregardedEntityName ===
      originalTinData?.disregardedEntityName &&
    correctedData?.ein === originalTinData?.ein &&
    correctedData?.firstName === originalTinData?.firstName &&
    correctedData?.lastName === originalTinData?.lastName;

  const isCompanyStructureChangeOnly =
    isVoidType &&
    noChangeInTinData &&
    corrections.length === 1 &&
    corrections[0] === CORRECTION_TIN_OR_NAME;

  return {
    corrections,
    addressStateNotChanged,
    isVoidType,
    isCompanyStructureChangeOnly
  };
}
