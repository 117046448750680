import {
  useWSSnackbar,
  WSMenuItem,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSTableProps
} from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import {
  IPayerListResponse,
  IPayerResponse,
  PayerPayeeStatus
} from "@wingspanhq/payments/dist/interfaces";
import { useHistory } from "react-router-dom";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { useUpdatePayer } from "../../../../query/payers/mutations/useUpdatePayer";
import { IPayerRow } from "../../../../services/search";

export interface ClientsTableProps extends Omit<WSTableProps, "tableData"> {
  isArchivedOnly?: boolean;
  clients: IPayerListResponse | IPayerRow[];
  listPath: string;
}

export const ClientsTable: React.FC<ClientsTableProps> = ({
  clients,
  listPath,
  isArchivedOnly,
  loading,
  ...elementProps
}) => {
  const history = useHistory();
  const { openSnackbar } = useWSSnackbar();
  const [restoreClient] = useUpdatePayer();

  const tableData = useMemo(
    () =>
      clients.map(client => ({
        id: client.payerId,
        data: {
          client
        }
      })),
    [clients]
  );

  const columns: Array<WSTableColumn> = [
    {
      config: {
        header: {
          text: "Name"
        }
      },
      renderFunction: rowItem => (
        <WSTableCell
          avatar={{
            type: "text",
            text: wsName(rowItem.data.client).getResolvedName()
          }}
          text={wsName(rowItem.data.client).getResolvedName()}
        />
      )
    },
    {
      config: {
        header: {
          text: "Contact"
        }
      },
      renderFunction: rowItem => (
        <WSTableCell text={wsName(rowItem.data.client).email} />
      )
    }
    // FIX: temporarily hide this engagements column
    // {
    //   config: {
    //     header: {
    //       text: "Engagements"
    //     },
    //     hideOnScreens: ["XS"]
    //   },
    //   renderFunction: ({
    //     data: {
    //       client: { engagements }
    //     }
    //   }) =>
    //     engagements.length > 0 ? (
    //       <>
    //         <WSText.ParagraphSm weight="book">
    //           {engagements[0].name}
    //         </WSText.ParagraphSm>
    //         {engagements.length > 1 && (
    //           <WSText.ParagraphXs weight="book" color="gray400">
    //             +{engagements.length - 1} more
    //           </WSText.ParagraphXs>
    //         )}
    //       </>
    //     ) : (
    //       <>
    //         <WSText.ParagraphSm weight="book">--</WSText.ParagraphSm>
    //         <WSText.ParagraphXs weight="book" color="gray400">
    //           N/A
    //         </WSText.ParagraphXs>
    //       </>
    //     )
    // }
  ];

  const handleRestoreClient = async (client: IPayerResponse | IPayerRow) => {
    await restoreClient(
      {
        id: client.payerId,
        payeeOwnedData: {
          status: PayerPayeeStatus.Active
        }
      },
      {
        onSuccess: () => {
          openSnackbar({
            duration: 5000,
            type: "success",
            message: "Client restored successfully"
          });
        },
        onError: () => {
          openSnackbar({
            duration: 5000,
            type: "warning",
            message: `Failed to restore client`
          });
        }
      }
    );
  };

  return (
    <WSTable
      {...elementProps}
      loading={loading}
      tableData={tableData}
      columns={columns}
      onRowClick={item => {
        history.push(`${listPath}/${item.id}`, {
          backPath: listPath
        });
      }}
      onRowControlClick={item => {
        openInNewTab(`${listPath}/${item.id}`);
      }}
      onRowMousewheelClick={item => {
        openInNewTab(`${listPath}/${item.id}`);
      }}
      data-testid="clientsTable"
      rowMenuActions={({ data: { client } }) =>
        isArchivedOnly
          ? [
              {
                label: "Restore client",
                icon: "repeat-circle",
                onClick: () => {
                  handleRestoreClient(client);
                }
              } as WSMenuItem
            ]
          : []
      }
    />
  );
};
