import {
  WSElement,
  WSFlexBox,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import cn from "classnames";

import { OriginalValuePreview } from "../OriginalValuePreview";
import { CorrectedValuePreview } from "../CorrectedValuePreview";
import {
  CORRECTION_REASON_OPTIONS,
  correctionReasonMap
} from "../../constants/corrections";
import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse,
  ITaxFormCorrectionResponse,
  PayeeCorrectionReason,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import { getLast4 } from "../../../../shared/utils/getLast4";
import { TaxFormViewer } from "../../types";
import { selectorNo1099 } from "../../../1099NECFiling/components/FormTaxpayerInformation/selectorNo1099";
import { selectorTaxClassificationLabel } from "../../../../shared/selectors/selectorTaxClassificationLabel";
import { TextBulleted } from "../../../../shared/components/TextBulleted";
import styles from "../styles.module.scss";
import { selectorTaxFormRecipientName } from "../../../1099NECFiling/selectors/selectorTaxFormRecipientName";
import { selectorTaxFormCorrectionRecipientName } from "../../../1099NECFiling/selectors/selectorTaxFormCorrectionRecipientName";

const getTIN = (tinData: TinData, identificationNumberType: TinType) => {
  if (tinData?.einLastFour || tinData?.ein) {
    return `Employer Identification Number (EIN) ••• ${tinData.einLastFour ||
      getLast4(tinData.ein)}`;
  } else if (tinData?.ssnLastFour || tinData.ssn) {
    return `Social Security Number (SSN) ••• ${tinData.ssnLastFour ||
      getLast4(tinData.ssn)}`;
  }
  if (identificationNumberType === TinType.Individual) {
    return "Social Security Number (SSN)";
  }
  return "Employer Identification Number (EIN)";
};
export interface TinData {
  ssn: string;
  ssnLastFour?: string;
  firstName?: string;
  lastName?: string;

  ein: string;
  einLastFour?: string;
  businessName: string;
  disregardedEntityName?: string;
  taxClassification: CompanyStructure;
}

export interface OriginalValueTINOrNamePreviewProps {
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  originalTinData: TinData;
  identificationNumberType: TinType;
}

export const OriginalValueTINOrNamePreview: React.FC<OriginalValueTINOrNamePreviewProps> = ({
  taxForm,
  originalTinData,
  identificationNumberType
}) => {
  const name = selectorTaxFormRecipientName(taxForm);
  return (
    <OriginalValuePreview>
      <WSFlexBox direction="column">
        <WSElement mb="3XL">
          <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
            Tax identification number
          </WSText.ParagraphSm>
          <WSText.ParagraphSm color="gray600" strike>
            {getTIN(originalTinData, identificationNumberType)}
          </WSText.ParagraphSm>
        </WSElement>

        <WSElement
          mb="3XL"
          hidden={identificationNumberType === TinType.Individual}
        >
          <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
            Business name
          </WSText.ParagraphSm>
          <WSText.ParagraphSm color="gray600" strike>
            {name}
          </WSText.ParagraphSm>
        </WSElement>

        <WSElement
          mb="3XL"
          hidden={identificationNumberType === TinType.Business}
        >
          <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
            Legal name
          </WSText.ParagraphSm>
          <WSText.ParagraphSm weight="medium" color="gray600">
            {name}
          </WSText.ParagraphSm>
        </WSElement>

        <WSElement mb="XL">
          <WSText.ParagraphSm weight="medium" color="gray500" mb="XS">
            Federal tax classification
          </WSText.ParagraphSm>
          <WSText.ParagraphSm weight="medium" color="gray700" strike>
            {selectorTaxClassificationLabel(originalTinData.taxClassification)}
          </WSText.ParagraphSm>
        </WSElement>
      </WSFlexBox>
    </OriginalValuePreview>
  );
};

export interface TINOrNameCorrectionDiffPreviewProps {
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  taxFormViewer?: TaxFormViewer;
  isVoidType?: boolean;
  isForce?: boolean;
  onEdit?: () => void;
  originalTinData: TinData;
  correctedTinData: TinData;
  reasonForChange: string;
  otherReason?: string;
  identificationNumberType: TinType;
  showRequestedValue?: boolean;
  showRejectOptionText?: boolean;
  hasOtherEligibleCorrectionChanges?: boolean;
}

export const TINOrNameCorrectionDiffPreview: React.FC<TINOrNameCorrectionDiffPreviewProps> = ({
  taxForm,
  taxFormViewer = TaxFormViewer.Payee,
  isVoidType,
  isForce,
  originalTinData,
  correctedTinData,
  reasonForChange,
  otherReason,
  onEdit,
  identificationNumberType,
  showRequestedValue = true,
  showRejectOptionText,
  hasOtherEligibleCorrectionChanges
}) => {
  return (
    <>
      <WSText.Heading5 mb="M">
        Taxpayer identification number or name
      </WSText.Heading5>
      <WSText.ParagraphSm mb="XL" color="gray500">
        The Taxpayer Identification Number (SSN or EIN) or name is incorrect.
      </WSText.ParagraphSm>

      <OriginalValueTINOrNamePreview
        taxForm={taxForm}
        identificationNumberType={identificationNumberType}
        originalTinData={originalTinData}
      />

      {showRequestedValue ? (
        <WSElement mb="XL">
          <CorrectedValuePreview
            taxForm={taxForm}
            taxFormViewer={taxFormViewer}
            onEdit={onEdit}
          >
            <WSFlexBox direction="column">
              <WSElement mb="XL">
                <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
                  Tax identification number
                </WSText.ParagraphSm>
                <WSText.ParagraphSm weight="medium" color="gray600">
                  {getTIN(correctedTinData, identificationNumberType)}
                </WSText.ParagraphSm>
              </WSElement>

              <WSElement
                mb="XL"
                hidden={identificationNumberType === TinType.Individual}
              >
                <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
                  Business name
                </WSText.ParagraphSm>
                <WSText.ParagraphSm weight="medium" color="gray600">
                  {taxFormViewer === TaxFormViewer.Payee ||
                  !taxForm.pendingCorrection
                    ? correctedTinData.businessName
                    : selectorTaxFormCorrectionRecipientName(
                        taxForm.pendingCorrection as ITaxFormCorrectionResponse
                      )}
                </WSText.ParagraphSm>
              </WSElement>

              <WSElement
                mb="XL"
                hidden={identificationNumberType === TinType.Business}
              >
                <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
                  Legal name
                </WSText.ParagraphSm>
                <WSText.ParagraphSm weight="medium" color="gray600">
                  {taxFormViewer === TaxFormViewer.Payee ||
                  !taxForm.pendingCorrection
                    ? [correctedTinData.firstName, correctedTinData.lastName]
                        .join(" ")
                        .trim()
                    : selectorTaxFormCorrectionRecipientName(
                        taxForm.pendingCorrection as ITaxFormCorrectionResponse
                      )}
                </WSText.ParagraphSm>
              </WSElement>

              <WSElement mb="XL">
                <WSText.ParagraphSm weight="medium" color="gray500" mb="XS">
                  Federal tax classification
                </WSText.ParagraphSm>
                <WSText.ParagraphSm weight="medium" color="gray700">
                  {selectorTaxClassificationLabel(
                    correctedTinData.taxClassification
                  )}
                </WSText.ParagraphSm>
              </WSElement>

              {reasonForChange ? (
                <WSElement mb="XL">
                  <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
                    Reason
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm weight="medium" color="gray600" inline>
                    {
                      CORRECTION_REASON_OPTIONS.find(
                        o => o.value === reasonForChange
                      )!.label
                    }
                    :{" "}
                    <WSText inline>
                      {reasonForChange === PayeeCorrectionReason.Other
                        ? otherReason
                        : correctionReasonMap[
                            reasonForChange as PayeeCorrectionReason
                          ]}
                    </WSText>
                  </WSText.ParagraphSm>
                </WSElement>
              ) : null}
            </WSFlexBox>
          </CorrectedValuePreview>

          {/* will be shown only when isVoidType is set in RouteSubmitCorrection */}
          {isVoidType === true ? (
            <WSMessageBox.Info noBorder mt="S">
              <WSText.ParagraphSm weight="medium" mb="S" color="gray600">
                Note:
              </WSText.ParagraphSm>
              <WSText.ParagraphSm mb="M" color="gray500">
                As a{" "}
                {selectorTaxClassificationLabel(
                  correctedTinData.taxClassification
                )}
                , the recipient is not eligible for a 1099 according to IRS
                guidelines. Please verify recipient information is accurate
                before proceeding.
              </WSText.ParagraphSm>
              <WSText.ParagraphSm weight="medium" mb="M" color="gray600">
                You are choosing to submit with the following option:
                <TextBulleted
                  ml="S"
                  color="gray500"
                  className={cn(styles.gray600, styles.list)}
                >
                  {isForce ? (
                    <li>
                      Continue to submit a correction with only the eligible
                      changes <br />
                      (ignore the change to an ineligible tax classification)
                    </li>
                  ) : (
                    <li>
                      Continue to submit a correction to void the original
                      filing
                    </li>
                  )}
                </TextBulleted>
              </WSText.ParagraphSm>
            </WSMessageBox.Info>
          ) : taxFormViewer === TaxFormViewer.Payer &&
            originalTinData.taxClassification !==
              correctedTinData.taxClassification &&
            selectorNo1099(correctedTinData.taxClassification) ? (
            <WSMessageBox.Info noBorder mt="S">
              <WSText.ParagraphSm weight="medium" mb="S" color="gray600">
                Note:
              </WSText.ParagraphSm>
              <WSText.ParagraphSm mb="M" color="gray500">
                As a{" "}
                {selectorTaxClassificationLabel(
                  correctedTinData.taxClassification
                )}
                , the recipient is not eligible for a 1099 according to IRS
                guidelines. Please verify recipient information is accurate
                before proceeding.
              </WSText.ParagraphSm>

              <WSText.ParagraphSm weight="medium" mb="M" color="gray600">
                You have the following options:
                <TextBulleted
                  ml="S"
                  color="gray500"
                  className={cn(styles.gray600, styles.list)}
                >
                  {showRejectOptionText ? (
                    <li>Reject the correction (original filing remains)</li>
                  ) : null}
                  <li>
                    Continue to submit a correction to void the original filing
                  </li>
                  {hasOtherEligibleCorrectionChanges ? (
                    <li>
                      Continue to submit a correction with only the eligible
                      changes <br />
                      (ignore the change to an ineligible tax classification)
                    </li>
                  ) : null}
                </TextBulleted>
              </WSText.ParagraphSm>
            </WSMessageBox.Info>
          ) : null}
        </WSElement>
      ) : null}
    </>
  );
};
