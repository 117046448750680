import { useDebounce } from "primereact/hooks";
import React, { useEffect } from "react";
import { WSElement, WSMarginProps } from "../WSElement/WSElement.component";
import { WSInputText } from "../WSInputText/WSInputText";
import { WSButton } from "../core/WSButton/WSButton.component";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import { WSText } from "../core/WSText/WSText.component";
import styles from "./WSSearchPanel.module.scss";

export type WSSearchPanelProps = {
  onBack?: () => void;
  value: string;
  onChange: (newValue: string) => void;
  className?: string;
  placeholder?: string;
} & WSMarginProps;

export const WSSearchPanel: React.FC<WSSearchPanelProps> = ({
  onBack,
  value: externalValue,
  onChange,
  placeholder
}) => {
  const [value, debouncedValue, setValue] = useDebounce(externalValue, 300);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  useEffect(() => {
    setValue(externalValue);
  }, [externalValue, setValue]);

  const handleClear = () => {
    setValue("");
  };

  return (
    <WSElement className={styles.searchPanel}>
      {onBack && (
        <WSElement className={styles.backButton} onClick={onBack}>
          <WSIcon name="arrow-left" color="gray400" size="M" />
          <WSText.ParagraphXs color="gray400">Back</WSText.ParagraphXs>
        </WSElement>
      )}
      <WSElement className={styles.inputContainer}>
        <WSInputText
          icon="search"
          value={value}
          onChange={setValue}
          placeholder={placeholder}
          inputAction={
            value
              ? {
                  icon: "exit",
                  onClick: handleClear
                }
              : undefined
          }
        />
        <WSButton.Secondary size="M" disabled={!value} onClick={handleClear}>
          Clear all
        </WSButton.Secondary>
      </WSElement>
    </WSElement>
  );
};
