import {
  WSButton,
  WSFlexBox,
  WSInfiniteScroll,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSText,
  toWSDateString
} from "@wingspanhq/fe-component-library";
import { IBulkCollaboratorBatch } from "@wingspanhq/payments/dist/interfaces";
import format from "date-fns/format";
import { Route, useHistory } from "react-router-dom";

import { WSQueries } from "../../../../query/WSQuery";
import { BulkBatchFilename } from "../../components/BulkBatchFilename";
import { BulkBatchImportStatus } from "../../components/BulkBatchImportStatus";
import { TotalCollaboratorsCount } from "../../components/TotalCollaboratorsCount";
import { useBulkCollaboratorBatchesQuery } from "../../query/bulkCollaborator/queries";
import { BulkResource } from "../../types";
import { getBatchCreatorName } from "../../utils/getBatchCreator";
import { BulkImportCollaboratorBatchDetails } from "../BulkImportCollaboratorBatchDetails";

export function BulkImportCollaborators() {
  const history = useHistory();
  const qBulkCollaboratorBatches = useBulkCollaboratorBatchesQuery();

  const doFirstImport = () => {
    history.push("/member/invoices/contacts/collaborators/bulk-create/info");
  };

  const columns: Array<WSTableColumn<IBulkCollaboratorBatch>> = [
    {
      config: {
        gridTemplateSizeMax: "0.4fr",
        justify: "start",
        header: { text: "Created" }
      },
      renderFunction: row => (
        <WSTableCell
          text={toWSDateString(row.data.createdAt, "monthDayYear")}
          secondaryText={format(row.data.createdAt, "hh:mm aaa")}
        />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.5fr",
        justify: "start",
        header: { text: "Imported by" }
      },
      renderFunction: row => (
        <WSTableCell text={getBatchCreatorName(row.data)} />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.8fr",
        justify: "start",
        header: { text: "Name" }
      },
      renderFunction: row => (
        <BulkBatchFilename
          batchId={row.data.bulkCollaboratorBatchId}
          bulkResource={BulkResource.Collaborator}
        />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.4fr",
        justify: "start",
        header: { text: "Contractors count" }
      },
      renderFunction: row => (
        <TotalCollaboratorsCount batchId={row.data.bulkCollaboratorBatchId} />
      )
    },
    {
      config: {
        gridTemplateSizeMax: "0.4fr",
        justify: "start",
        header: { text: "Import status" }
      },
      renderFunction: row => (
        <BulkBatchImportStatus
          batchId={row.data.bulkCollaboratorBatchId}
          bulkResource={BulkResource.Collaborator}
        />
      )
    }
  ];

  return (
    <>
      <Route
        path="/member/imports/collaborators/:bulkBatchId"
        component={BulkImportCollaboratorBatchDetails}
        exact
      />
      <WSQueries queries={{ qBulkCollaboratorBatches }}>
        {({ qBulkCollaboratorBatches: { data: bulkCollaboratorBatches } }) => {
          const tableData = bulkCollaboratorBatches.map(collaboratorBatch => {
            return {
              data: collaboratorBatch,
              id: collaboratorBatch.bulkCollaboratorBatchId
            };
          });

          return (
            <WSInfiniteScroll
              onLoad={() => {
                qBulkCollaboratorBatches.fetchMore();
              }}
              loadMore={bulkCollaboratorBatches.length > 0}
              endOfList={!qBulkCollaboratorBatches.canFetchMore}
              loading={!!qBulkCollaboratorBatches.isFetchingMore}
            >
              <WSTable<IBulkCollaboratorBatch>
                mt="2XL"
                bottomMessage={
                  qBulkCollaboratorBatches.isFetched &&
                  tableData.length === 0 ? (
                    <WSFlexBox.Center direction="column" mt="3XL">
                      <WSText>No imports found.</WSText>
                      <WSButton.Link onClick={doFirstImport}>
                        Import contractors
                      </WSButton.Link>
                    </WSFlexBox.Center>
                  ) : null
                }
                tableData={tableData}
                columns={columns}
                onRowClick={rowItem => {
                  history.push(`/member/imports/collaborators/${rowItem.id}`);
                }}
              />
            </WSInfiniteScroll>
          );
        }}
      </WSQueries>
    </>
  );
}
