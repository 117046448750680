import React, { useEffect } from "react";
import {
  useWSModal,
  WSButton,
  WSButtons,
  WSAvatar,
  WSElement,
  WSFlexBox,
  WSProgressBar,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { IRedactedTaxFormW9Info } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { useTaxFormResync } from "../../../../../query/taxForm/mutations/useTaxFormResync";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";

const getLabel = (key: keyof IRedactedTaxFormW9Info) => {
  const LABELS: { [key in keyof IRedactedTaxFormW9Info]: string } = {
    ein: "EIN",
    companyStructure: "Company structure",
    city: "City",
    state: "State",
    addressLine1: "Address line 1",
    addressLine2: "Address line 2",
    country: "Country",
    tinType: "TIN type",
    dob: "Date of birth",
    disregardedEntityName: "Disregarded entity name",
    einLastFour: "EIN last four",
    exemptFatcaCode: "Exempt FATCA code",
    exemptPayeeCode: "Exempt payee code",
    firstName: "First name",
    lastName: "Last name",
    legalBusinessName: "Legal business name",
    postalCode: "Postal code",
    ssnLastFour: "SSN last four"
  };

  return LABELS[key];
};

const getRecipientChangeValue = (key: keyof IRedactedTaxFormW9Info) => {
  const LABELS: { [key in keyof IRedactedTaxFormW9Info]: string } = {
    ein: "EIN",
    companyStructure: "Company structure",
    city: "City",
    state: "State",
    addressLine1: "Address line 1",
    addressLine2: "Address line 2",
    country: "Country",
    tinType: "TIN type",
    dob: "Date of birth",
    disregardedEntityName: "Disregarded entity name",
    einLastFour: "EIN last four",
    exemptFatcaCode: "Exempt FATCA code",
    exemptPayeeCode: "Exempt payee code",
    firstName: "First name",
    lastName: "Last name",
    legalBusinessName: "Legal business name",
    postalCode: "Postal code",
    ssnLastFour: "SSN last four"
  };

  return LABELS[key];
};

const ModalRevertChanges: React.FC<{
  taxForm: IPayerTaxFormResponse;
  onClose(result: boolean): void;
}> = ({ onClose, taxForm }) => {
  const [resync, resyncMeta] = useTaxFormResync();
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    resync(taxForm.taxFormId, {
      onSuccess: () => {
        setProgress(100);
      },
      onError: () => {
        setProgress(100);
      }
    });
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setProgress(p => {
        if (p >= 80) {
          clearInterval(interval);
        }

        return p + 10;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <WSElement mt="XS">
        {resyncMeta.isSuccess ? (
          <>
            <WSFlexBox.Center mb="M">
              <WSAvatar.Icon
                icon="check"
                color="white"
                colorBackground="green500"
                mr="M"
              />
              <WSText color="gray600">
                Your changes successfully reverted!
              </WSText>
            </WSFlexBox.Center>
          </>
        ) : (
          <WSText color="gray600">
            Your changes are being reverted. This could take a minute
          </WSText>
        )}

        <WSProgressBar mt="XL" noAnimation={false} percent={progress} />

        <WSErrorMessage
          mt="XL"
          error={resyncMeta.error}
          contextKey="NEC1099AddRecipient"
        />

        <WSButtons forceFullWidth>
          <WSButton.Primary
            disabled={!resyncMeta.isSuccess}
            onAsyncClick={async () => {
              onClose(true);
            }}
          >
            Continue
          </WSButton.Primary>
        </WSButtons>

        <WSText color="gray500" mt="XL">
          Do not close this window while reversion is in progress.
        </WSText>
      </WSElement>
    </>
  );
};

export const useModalRevertingChanges = () =>
  useWSModal(ModalRevertChanges, {
    title: "Reverting changes",
    size: "S"
  });
