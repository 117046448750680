import { PayoutPreferences } from "@wingspanhq/payments/dist/interfaces";
import { components, operations } from "../types";

export type IPayoutListResponse = operations["getPayouts"]["responses"]["200"]["content"]["application/json"];

export type IPayoutResponse = operations["getPayout"]["responses"]["200"]["content"]["application/json"];

export type IPayoutStatus = components["schemas"]["PayoutStatus"];
export type IPayoutTransfer = components["schemas"]["PayoutTransfer"];
export type IExternalDestination = components["schemas"]["ExternalDestination"];
export type IPayoutType = components["schemas"]["PayoutType"];
export type IPayoutItem = components["schemas"]["PayoutItem"];

export enum PayoutStatusEnum {
  Created = "Created",
  Clearing = "Clearing",
  Failed = "Failed",
  Complete = "Complete"
}

export const PayoutTypeEnum = {
  PayoutType_Instant: "Instant" as PayoutPreferences,
  PayoutType_Standard: "Standard" as PayoutPreferences
};
