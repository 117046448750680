import { IIntegrationsQuickbooksSyncActivity } from "@wingspanhq/integrations/dist/lib/interfaces";

export const getQuickbooksObjectPath = (
  syncActivity: IIntegrationsQuickbooksSyncActivity
) =>
  syncActivity.qboEntityName === "Invoice"
    ? `/member/invoices/${syncActivity.entityId}`
    : syncActivity.qboEntityName === "Bill"
    ? `/member/invoices/payables/dashboard/${syncActivity.entityId}`
    : syncActivity.qboEntityName === "Customer"
    ? `/member/invoices/contacts/clients/${syncActivity.entityId}`
    : syncActivity.qboEntityName === "Vendor"
    ? `/member/invoices/contacts/collaborators/${syncActivity.entityId}`
    : ``;
