import { parseJwt } from "../utils/jwt";

export const SESSION_STORAGE_PREFIX = "ws:";

export const SESSION_TOKEN_STORAGE_KEY = "auth_token";
export const ADMIN_SESSION_TOKEN_LIST_STORAGE_KEY = "admin_auth_token_list";
export const WINGSPAN_ADMIN_SESSION_TOKEN_STORAGE_KEY =
  "wingspan_admin_auth_token";
export const REQUESTING_USER_SESSION_TOKEN_STORAGE_KEY =
  "requesting_user_auth_token";
export const REQUESTING_TOKEN_FROM_URL_KEY = "requesting_token_from_url";

export function deleteRequestingTokenFromURL() {
  window.sessionStorage.removeItem(REQUESTING_TOKEN_FROM_URL_KEY);
  window.localStorage.removeItem(REQUESTING_TOKEN_FROM_URL_KEY);
}

export function setRequestingTokenFromURL(
  token: string,
  options?: { toSessionStorage?: boolean }
) {
  deleteRequestingTokenFromURL();

  const isSessionStorage =
    typeof options?.toSessionStorage === "boolean"
      ? options.toSessionStorage
      : true;

  if (isSessionStorage) {
    window.sessionStorage.setItem(REQUESTING_TOKEN_FROM_URL_KEY, token);
  } else {
    window.localStorage.setItem(REQUESTING_TOKEN_FROM_URL_KEY, token);
  }
}

export function getRequestingTokenFromURL() {
  return (
    window.sessionStorage.getItem(REQUESTING_TOKEN_FROM_URL_KEY) ||
    window.localStorage.getItem(REQUESTING_TOKEN_FROM_URL_KEY)
  );
}

const getUserIdFromToken = (token: string | null) => {
  // token looks like
  // {
  //   "userId": "",
  //   "sessionId": "",
  //   "iat": 1691764815
  // }
  let userId = "";
  try {
    const parsedToken: any = parseJwt(token as string);
    userId = parsedToken?.userId || "";
  } catch {}
  return userId;
};

export function pushSessionToken(
  token: string,
  options?: { toSessionStorage?: boolean }
) {
  deleteSessionToken();

  const isSessionStorage =
    typeof options?.toSessionStorage === "boolean"
      ? options.toSessionStorage
      : true;

  if (isSessionStorage) {
    window.sessionStorage.setItem(SESSION_TOKEN_STORAGE_KEY, token);
  } else {
    window.localStorage.setItem(SESSION_TOKEN_STORAGE_KEY, token);
  }
}

export function pullSessionToken() {
  const token =
    window.sessionStorage.getItem(SESSION_TOKEN_STORAGE_KEY) ||
    window.localStorage.getItem(SESSION_TOKEN_STORAGE_KEY);

  return token;
}

export function getUserSessionUserId() {
  return getUserIdFromToken(pullSessionToken());
}

export function deleteSessionToken() {
  window.sessionStorage.removeItem(SESSION_TOKEN_STORAGE_KEY);
  window.localStorage.removeItem(SESSION_TOKEN_STORAGE_KEY);
}

export function setRequestingUserSessionToken(token: string) {
  window.sessionStorage.setItem(
    REQUESTING_USER_SESSION_TOKEN_STORAGE_KEY,
    token
  );
}

export function getRequestingUserSessionToken() {
  const token = window.sessionStorage.getItem(
    REQUESTING_USER_SESSION_TOKEN_STORAGE_KEY
  );
  return token;
}

export function getRequestingUserSessionUserId() {
  return getUserIdFromToken(getRequestingUserSessionToken());
}

export function deleteRequestingUserSessionToken() {
  window.sessionStorage.removeItem(REQUESTING_USER_SESSION_TOKEN_STORAGE_KEY);
}

export function setWingspanAdminSessionToken(token: string) {
  window.sessionStorage.setItem(
    WINGSPAN_ADMIN_SESSION_TOKEN_STORAGE_KEY,
    token
  );
}

export function getWingspanAdminSessionToken() {
  return window.sessionStorage.getItem(
    WINGSPAN_ADMIN_SESSION_TOKEN_STORAGE_KEY
  );
}

export function deleteWingspanAdminSessionToken() {
  window.sessionStorage.removeItem(WINGSPAN_ADMIN_SESSION_TOKEN_STORAGE_KEY);
}

const getAdminTokenList = () => {
  let tokenList: string[] = [];

  try {
    const json =
      window.sessionStorage.getItem(ADMIN_SESSION_TOKEN_LIST_STORAGE_KEY) || "";
    const data = JSON.parse(json);

    if (Array.isArray(data)) {
      tokenList = data;
    }
  } catch {}

  return tokenList;
};

export function addAdminSessionToken(token: string) {
  let tokenList = getAdminTokenList();

  window.sessionStorage.setItem(
    ADMIN_SESSION_TOKEN_LIST_STORAGE_KEY,
    JSON.stringify([token, ...tokenList])
  );
}

export function getAdminSessionToken() {
  let tokenList = getAdminTokenList();
  return tokenList[0];
}

export function deleteAllAdminSessionTokens() {
  deleteWingspanAdminSessionToken();

  window.sessionStorage.removeItem(ADMIN_SESSION_TOKEN_LIST_STORAGE_KEY);
}

export function deleteCurrentAdminSessionToken() {
  let tokenList = getAdminTokenList();

  const [token, ...newList] = tokenList;

  window.sessionStorage.setItem(
    ADMIN_SESSION_TOKEN_LIST_STORAGE_KEY,
    JSON.stringify(newList)
  );

  return token;
}
