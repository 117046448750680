import { QueryConfig } from "react-query";
import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { resendInvite } from "../../../services/payees/resendInvite";
import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { IPayeeResendInviteResponse } from "@wingspanhq/payments/dist/interfaces";

export const useResendPayeeInvite = (
  config?: QueryConfig<IPayeeResendInviteResponse, WSServiceError>
) => {
  const { openSnackbar } = useWSSnackbar();

  return useWSMutation((payeeId: string) => resendInvite(payeeId), {
    onSuccess: () => {
      openSnackbar({
        message: "Invitation email resent to contractor",
        type: "success"
      });
    },
    onError: () => {
      openSnackbar({
        message: "Failed to send invitation to contractor",
        type: "error"
      });
    },
    ...config
  });
};
