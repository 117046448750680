import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSForm,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IInvoicingConfigUpdateRequest,
  InvoiceSettingKeys
} from "@wingspanhq/payments/dist/interfaces";
import { InvoicingConfigSection } from "../../components/InvoicingConfigSection/InvoicingConfigSection";
import { InvoicingConfigField } from "../../components/InvoicingConfigField/InvoicingConfigField";
import { useHistory } from "react-router-dom";

import styles from "./InvoicingConfig.module.scss";
import { useQueryInvoicingConfigForPayer } from "../../../query/invoicingConfig/queries/useQueryInvoicingConfigForPayer";
import { WSQueries } from "../../../query/WSQuery";
import { useAdditionalGuidelinesModal } from "../../components/EditInvoicingConfigModals/useAdditionalGuidelinesModal";
import { useEffect, useState } from "react";
import { usePredefinedLineItemsModal } from "../../components/EditInvoicingConfigModals/usePredefinedLineItemsModal";
import { IPredefinedLineItem } from "../../components/EditInvoicingConfigModals/PredefinedLineItemsModal";
import { openInNewTab } from "../../../shared/utils/openInNewTab";
import { useUpdateInvoicingConfig } from "../../../query/invoicingConfig/mutations/useUpdateInvoicingConfig";
import { useDefaultDueDateModal } from "../../components/EditInvoicingConfigModals/useDefaultDueDateModal";
import { FormInvoicingConfig } from "../../../modules/Onboarding/components/FormInvoicingConfig";

export const dueInDaysValueMap: any = {
  0: "On created date",
  7: "7 days after created date",
  15: "15 days after created date",
  30: "30 days after created date"
};

export interface InvoicingConfigProps {
  isEditing?: boolean;
}

export const InvoicingConfig: React.FC<InvoicingConfigProps> = ({
  isEditing
}) => {
  const history = useHistory();
  const [additionalGuidelinesValue, setAdditionalGuidelinesValue] = useState<
    string
  >("");
  const [predefinedLineItems, setPredefinedLineItems] = useState<
    IPredefinedLineItem[]
  >([]);
  const [dueInDaysValue, setDueInDaysValue] = useState<string>("0"); // string number
  const queryInvoicingConfigForPayer = useQueryInvoicingConfigForPayer();

  const additionalGuidelinesModal = useAdditionalGuidelinesModal();
  const predefinedLineItemsModal = usePredefinedLineItemsModal();
  const defaultDueDateModal = useDefaultDueDateModal();

  const [
    updateInvoicingConfig,
    updateInvoicingConfigMeta
  ] = useUpdateInvoicingConfig();
  useEffect(() => {
    const invoicingConfigForPayer = queryInvoicingConfigForPayer?.data;
    if (invoicingConfigForPayer) {
      setAdditionalGuidelinesValue(
        invoicingConfigForPayer?.[InvoiceSettingKeys.additionalGuidelines]
          ?.enabled &&
          invoicingConfigForPayer?.[InvoiceSettingKeys.additionalGuidelines]
            ?.value
          ? invoicingConfigForPayer[InvoiceSettingKeys.additionalGuidelines]
              ?.value
          : ""
      );
      try {
        const predefinedLineItems =
          invoicingConfigForPayer?.[
            InvoiceSettingKeys.allowOnlyPredefinedLineItems
          ]?.value ?? [];
        setPredefinedLineItems(predefinedLineItems);
      } catch (e) {
        setPredefinedLineItems([]);
      }

      setDueInDaysValue(
        invoicingConfigForPayer?.[InvoiceSettingKeys.dueInDays]?.value
          ? invoicingConfigForPayer?.[InvoiceSettingKeys.dueInDays]?.value + ""
          : ""
      );
    }
  }, [queryInvoicingConfigForPayer?.data]);

  const onSubmit = async (data: any) => {
    // iterate over data object and create a request object of type IInvoicingConfigUpdateRequest
    // send the request object to the updateInvoicingConfig mutation
    const payload = Object.keys(data).reduce((acc: any, key) => {
      if (key === InvoiceSettingKeys.additionalGuidelines) {
        acc[key] = {
          enabled: data[key],
          value: additionalGuidelinesValue
        };
      } else if (key === InvoiceSettingKeys.allowOnlyPredefinedLineItems) {
        acc[key] = {
          enabled: data[key],
          value: predefinedLineItems
        };
      } else if (key === InvoiceSettingKeys.dueInDays) {
        acc[key] = {
          enabled: data[key],
          value: Number(dueInDaysValue)
        };
      } else {
        acc[key] = {
          enabled: data[key]
        };
      }
      return acc;
    }, {} as IInvoicingConfigUpdateRequest);

    await updateInvoicingConfig(payload, {
      onSuccess: () => {
        history.push("/member/settings/billing/payables");
      }
    });
  };

  const gotoInvoicingConfig = () => {
    history.push("/member/settings/billing/payables");
  };
  return (
    <WSQueries queries={{ queryInvoicingConfigForPayer }}>
      {({ queryInvoicingConfigForPayerData: invoicingConfigForPayer }) => {
        const content = (
          <WSElement
            className={styles.settingsContainer}
            colorBackground="gray50"
            p="2XL"
          >
            <InvoicingConfigSection title="Basic info" mb="XL">
              <InvoicingConfigField
                mb="XS"
                label="Client (selection)"
                badgeText="Required"
                enabled
              />
              <InvoicingConfigField
                mb="XS"
                editable={isEditing}
                name={InvoiceSettingKeys.additionalGuidelines}
                label="Additional guidelines to show contractors"
                helpText="Turn this on to show a custom message or guidelines (under the client name) to all contractors when they create an invoice."
                valuePreviewText={additionalGuidelinesValue}
                {...(invoicingConfigForPayer
                  ? invoicingConfigForPayer?.[
                      InvoiceSettingKeys.additionalGuidelines
                    ]
                  : {})}
              >
                <WSForm.Values
                  names={[InvoiceSettingKeys.additionalGuidelines]}
                >
                  {values => {
                    if (values[InvoiceSettingKeys.additionalGuidelines]) {
                      return (
                        <WSFlexBox alignItems="center" ml="2XL" wrap="nowrap">
                          {additionalGuidelinesValue ? (
                            <WSText.ParagraphXs color="gray400">
                              {additionalGuidelinesValue}
                            </WSText.ParagraphXs>
                          ) : (
                            <WSText.ParagraphXs color="gray400">
                              No text added yet.
                            </WSText.ParagraphXs>
                          )}
                          <WSButton.Link
                            type="button"
                            ml="XL"
                            size="S"
                            icon="edit"
                            onClick={() => {
                              additionalGuidelinesModal.open({
                                additionalGuidelinesValue,
                                onSubmit: data => {
                                  setAdditionalGuidelinesValue(
                                    data.additionalGuidelinesValue
                                  );
                                  console.log(
                                    "additionalGuidelinesValue",
                                    data
                                  );
                                }
                              });
                            }}
                          >
                            Edit
                          </WSButton.Link>
                        </WSFlexBox>
                      );
                    }
                    return null;
                  }}
                </WSForm.Values>
              </InvoicingConfigField>
            </InvoicingConfigSection>

            <InvoicingConfigSection title="Work summary (line items)" mb="XL">
              <InvoicingConfigField
                mb="XS"
                editable={isEditing}
                name={InvoiceSettingKeys.allowOnlyPredefinedLineItems}
                label="Allow only pre-defined line item descriptions"
                helpText="Turn this on to restrict line item descriptions to a predefined set created by you that a contractor can select from."
                valuePreviewText={`${predefinedLineItems.length ??
                  0} options added.`}
                {...invoicingConfigForPayer?.[
                  InvoiceSettingKeys.allowOnlyPredefinedLineItems
                ]}
              >
                <WSForm.Values
                  names={[InvoiceSettingKeys.allowOnlyPredefinedLineItems]}
                >
                  {values => {
                    if (
                      values[InvoiceSettingKeys.allowOnlyPredefinedLineItems]
                    ) {
                      return (
                        <WSFlexBox alignItems="center" ml="2XL">
                          <WSText.ParagraphXs color="gray400">
                            {predefinedLineItems?.length || 0} options added.
                          </WSText.ParagraphXs>
                          <WSButton.Link
                            type="button"
                            ml="XL"
                            size="S"
                            icon="edit"
                            onClick={() => {
                              predefinedLineItemsModal.open({
                                predefinedLineItemsValue: predefinedLineItems,
                                onSubmit: data => {
                                  console.log("data", data);
                                  setPredefinedLineItems(
                                    data?.predefinedLineItemsValue
                                      ?.map((item: { value: string }) => ({
                                        description: item.value
                                      }))
                                      .filter(Boolean)
                                  );
                                }
                              });
                            }}
                          >
                            Edit
                          </WSButton.Link>
                        </WSFlexBox>
                      );
                    }
                    return null;
                  }}
                </WSForm.Values>
              </InvoicingConfigField>

              <InvoicingConfigField
                mb="XS"
                editable={isEditing}
                name={InvoiceSettingKeys.allowLineItemDiscounts}
                label="Allow line item discounts"
                {...invoicingConfigForPayer?.[
                  InvoiceSettingKeys.allowLineItemDiscounts
                ]}
              />

              <InvoicingConfigField
                mb="XS"
                editable={isEditing}
                name={InvoiceSettingKeys.allowLineItemReimbursableExpenses}
                label="Allow line item reimbursable expenses"
                {...invoicingConfigForPayer?.[
                  InvoiceSettingKeys.allowLineItemReimbursableExpenses
                ]}
              />

              <InvoicingConfigField
                mb="XS"
                editable={isEditing}
                name={InvoiceSettingKeys.allowLateFees}
                label="Allow late fees"
                {...invoicingConfigForPayer?.[InvoiceSettingKeys.allowLateFees]}
              />

              <InvoicingConfigField
                mb="XS"
                editable={isEditing}
                name={InvoiceSettingKeys.allowProcessingFeePercentage}
                label="Allow processing fee percentage (%)"
                helpText="Wingspan charges 2.9% for card payments. Turn this on to allow contractors to pass on all, part, or more of this fee to you (the payer)."
                {...invoicingConfigForPayer?.[
                  InvoiceSettingKeys.allowProcessingFeePercentage
                ]}
              />
            </InvoicingConfigSection>

            <InvoicingConfigSection title="Due date and frequency" mb="XL">
              <InvoicingConfigField
                mb="XS"
                editable={isEditing}
                name={InvoiceSettingKeys.allowRecurringInvoices}
                label="Allow recurring payables (invoices)"
                {...invoicingConfigForPayer?.[
                  InvoiceSettingKeys.allowRecurringInvoices
                ]}
              />

              <InvoicingConfigField
                mb="XS"
                editable={isEditing}
                name={InvoiceSettingKeys.dueInDays}
                label="Set a default due date"
                valuePreviewText={dueInDaysValueMap[dueInDaysValue]}
                {...invoicingConfigForPayer?.[InvoiceSettingKeys.dueInDays]}
              >
                <WSForm.Values names={[InvoiceSettingKeys.dueInDays]}>
                  {values => {
                    if (values[InvoiceSettingKeys.dueInDays]) {
                      return (
                        <WSFlexBox alignItems="center" ml="2XL" wrap="nowrap">
                          {Object.keys(dueInDaysValueMap).includes(
                            dueInDaysValue
                          ) ? (
                            <WSText.ParagraphXs color="gray400">
                              {dueInDaysValueMap[dueInDaysValue]}
                            </WSText.ParagraphXs>
                          ) : (
                            <WSText.ParagraphXs color="gray400">
                              Default due date not selected yet.
                            </WSText.ParagraphXs>
                          )}
                          <WSButton.Link
                            type="button"
                            ml="XL"
                            size="S"
                            icon="edit"
                            onClick={() => {
                              defaultDueDateModal.open({
                                dueInDaysValue,
                                onSubmit: data => {
                                  setDueInDaysValue(data.dueInDaysValue);
                                  console.log("dueInDaysValue", data);
                                }
                              });
                            }}
                          >
                            Edit
                          </WSButton.Link>
                        </WSFlexBox>
                      );
                    }
                    return null;
                  }}
                </WSForm.Values>
              </InvoicingConfigField>
            </InvoicingConfigSection>

            <InvoicingConfigSection title="Contractors" mb="XL">
              <InvoicingConfigField
                mb="XS"
                editable={isEditing}
                name={InvoiceSettingKeys.allowCollaboratorSplits}
                label="Allow contractor splits"
                helpText="Turn this on to allow contractors to route payments to subcontractors when invoice is paid."
                {...invoicingConfigForPayer?.[
                  InvoiceSettingKeys.allowCollaboratorSplits
                ]}
              />
            </InvoicingConfigSection>

            <InvoicingConfigSection title="Additional info" mb="XL">
              <InvoicingConfigField
                mb="XS"
                editable={isEditing}
                name={InvoiceSettingKeys.requireProjectName}
                label="Require project name"
                {...invoicingConfigForPayer?.[
                  InvoiceSettingKeys.requireProjectName
                ]}
              />

              <InvoicingConfigField
                mb="XS"
                editable={isEditing}
                name={InvoiceSettingKeys.requirePONumber}
                label="Require PO number"
                {...invoicingConfigForPayer?.[
                  InvoiceSettingKeys.requirePONumber
                ]}
              />

              <InvoicingConfigField
                mb="XS"
                editable={isEditing}
                name={InvoiceSettingKeys.requireAttachments}
                label="Require attachment(s)"
                {...invoicingConfigForPayer?.[
                  InvoiceSettingKeys.requireAttachments
                ]}
              />

              <InvoicingConfigField
                mb="XS"
                editable={isEditing}
                name={InvoiceSettingKeys.allowPaymentReminders}
                label="Allow payment reminders sent to you"
                helpText="Turn this on to allow reminder emails sent to you when payables (invoices) are almost due and overdue"
                {...invoicingConfigForPayer?.[
                  InvoiceSettingKeys.allowPaymentReminders
                ]}
              />
            </InvoicingConfigSection>

            <WSText.ParagraphSm weight="light">
              Learn more about{" "}
              <WSButton.Link
                size="M"
                type="button"
                rightIcon="open-tab"
                onClick={() => {
                  openInNewTab(
                    "https://docs.wingspan.app/docs/payables-configuration"
                  );
                }}
              >
                payable settings
              </WSButton.Link>
            </WSText.ParagraphSm>
          </WSElement>
        );
        return (
          <WSElement mt="M" className={styles.main}>
            <WSFlexBox
              direction="row"
              alignItems="center"
              justify="space-between"
              wrap="nowrap"
              px="2XL"
              py="M"
            >
              <WSFlexBox direction="column">
                <WSText.Paragraph weight="medium">
                  Payable details
                </WSText.Paragraph>
                <WSText.ParagraphXs color="gray400">
                  Configure what inputs can be edited on payables (invoices)
                </WSText.ParagraphXs>
              </WSFlexBox>

              {!isEditing && (
                <WSButton.Link
                  size="M"
                  onClick={() => {
                    history.push("/member/settings/billing/payables/edit");
                  }}
                >
                  Edit
                </WSButton.Link>
              )}
            </WSFlexBox>

            {isEditing ? (
              <FormInvoicingConfig
                p="2XL"
                formContainerClassName={styles.settingsContainer}
                onSaveSuccess={gotoInvoicingConfig}
                onCancel={gotoInvoicingConfig}
              />
            ) : (
              content
            )}
          </WSElement>
        );
      }}
    </WSQueries>
  );
};
