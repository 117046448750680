import { FormDataAddress } from "../../shared/components/FormPartialAddress";
import { US_STATES_OPTIONS } from "../components/SettingsUpdateForms/TaxExpensesEstimateForYearForm";

export const getSelectedOptionByValue = (
  options: Array<{ label: string; value: any }>,
  value: any
) => {
  return options.find(option => option.value === value);
};

export const getAddressDisplayValue = (address: FormDataAddress): string => {
  return [
    address?.addressLine1,
    address?.addressLine2,
    address?.city,
    getSelectedOptionByValue(US_STATES_OPTIONS, address?.state)?.label,
    address?.zipCode
  ]
    .filter(Boolean)
    .join(", ");
};
