import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { ICreditCard } from "@wingspanhq/users";
import React from "react";
import { BankCardLogo } from "../../../shared/components/BankCardLogo";
import { VerticalDivider } from "../../../shared/components/VerticalDivider";
import { selectorCreditCardName } from "../../../shared/selectors/selectorCreditCardName";
import styles from "./CreditCardPreview.module.scss";

type Props = {
  paymentMethod: ICreditCard;
} & WSElementProps;

export const CreditCardPreview: React.FC<Props> = ({
  paymentMethod,
  ...elementProps
}) => (
  <WSElement {...elementProps}>
    <WSFlexBox wrap="nowrap" alignItems="center">
      <WSElement className={styles.logo} mr="M">
        <BankCardLogo type="credit" brand={paymentMethod.cardBrand} />
      </WSElement>

      <WSText color="gray600">{selectorCreditCardName(paymentMethod)}</WSText>

      {paymentMethod.expiryDate && <VerticalDivider my="M" mx="M" />}

      {paymentMethod.expiryDate && (
        <WSText.ParagraphSm color="gray500">
          Exp {paymentMethod.expiryDate.slice(0, 2)}/
          {paymentMethod.expiryDate.slice(3)}
        </WSText.ParagraphSm>
      )}
    </WSFlexBox>
  </WSElement>
);
