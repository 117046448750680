import {
  SelectOptionOld,
  useWSSnackbar,
  WSActions,
  WSDivider,
  WSForm,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useRef } from "react";
import * as Yup from "yup";
import {
  QUICKBOOKS_ENTITY,
  RefreshModal,
  useQboRefreshModal
} from "../../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import { WSQueries } from "../../../../query/WSQuery";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksAccountExpenses,
  useIntegrationsQuickbooksVendors
} from "../../../../query/integrations/queries";
import { useCreatePayeeEngagement } from "../../../../query/payeeEngagements/mutations/useCreatePayeeEngagement";
import { useCollaboratorGroupsQuery } from "../../../../query/payments/queries";
import { selectQuickbooksAccountOrItemOptions } from "../../../Integrations/selectors/selectQuickbooksAccountOrItemOptions";
import { selectQuickbooksUserOptions } from "../../../Integrations/selectors/selectQuickbooksUserOptions";
import flatten from "lodash/flatten";
import { IPayeeEngagementListResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { selectorIsQBOActive } from "../../../../shared/selectors/selectorIsQBOActive";

export interface AddToGroupModalProps {
  payeeId: string;
  engagements: IPayeeEngagementListResponse;

  onClose(result: boolean): void;
}

export const AddToGroupModal: React.FC<AddToGroupModalProps> = ({
  payeeId,
  engagements,
  onClose
}) => {
  const { openSnackbar } = useWSSnackbar();
  const tooltipIconRef = useRef<HTMLElement>(null);

  const queryFeatureFlags = useFeatureFlags();
  const qboRefreshModal = useQboRefreshModal();
  const queryCollaboratorGroups = useCollaboratorGroupsQuery();
  const queryIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const queryIntegrationsQuickbooksVendors = useIntegrationsQuickbooksVendors();
  const queryIntegrationsQuickbooksExpenseAccounts = useIntegrationsQuickbooksAccountExpenses();

  const isQuickbooksActive = selectorIsQBOActive(
    queryFeatureFlags?.data,
    queryIntegrationsQuickbooks.data
  );

  const [createEngagement, createEngagementMeta] = useCreatePayeeEngagement(
    payeeId
  );

  return (
    <WSQueries
      queries={{
        queryCollaboratorGroups
      }}
    >
      {({ queryCollaboratorGroupsData: collaboratorGroups }) => {
        const quickbooksVendorOptions: SelectOptionOld[] = selectQuickbooksUserOptions(
          queryIntegrationsQuickbooksVendors.data
        );

        const quickbooksAccountsOptions: SelectOptionOld[] = selectQuickbooksAccountOrItemOptions(
          queryIntegrationsQuickbooksExpenseAccounts.data
        );

        const defaultAccount = (
          queryIntegrationsQuickbooksExpenseAccounts.data ?? []
        ).find(
          acct =>
            acct.accountId ===
            queryIntegrationsQuickbooks.data?.defaults?.expenseAccountId
        );

        return (
          <>
            <WSForm
              defaultValues={{
                qboVendorId: "",
                qboExpenseAccountId: "",
                collaboratorGroupId: (collaboratorGroups.find(
                  i => i.defaultGroup
                ) as any)?.collaboratorGroupId
              }}
              validationSchema={Yup.object().shape({
                collaboratorGroupId: Yup.string()
                  .required("Group is required")
                  .notOneOf(
                    flatten(engagements.map(e => e.collaboratorGroupIds)),
                    "You already have engagements in this group"
                  )
              })}
              onSubmit={async data => {
                const result = await createEngagement({
                  payerOwnedData: {
                    integration: {
                      quickbooks: {
                        vendorId: data.qboVendorId,
                        expenseAccountId: data.qboExpenseAccountId
                      }
                    }
                  },
                  collaboratorGroupId: data.collaboratorGroupId
                });

                if (result) {
                  onClose(true);
                  openSnackbar({
                    message: "Engagement created successfully",
                    type: "success"
                  });
                }
              }}
            >
              {/*
              <WSElement>
                <WSFlexBox.CenterY mb="XS">
                  <WSText.ParagraphSm color="gray500" mr="XS">
                    Engagement name
                  </WSText.ParagraphSm>
                  <WSTooltip dark target={tooltipIconRef}>
                    Engagement name can not be changed
                  </WSTooltip>
                  <WSIcon
                    ref={tooltipIconRef}
                    name="info-circle-fill"
                    color="gray600"
                    size="S"
                  />
                </WSFlexBox.CenterY>
                <WSText.ParagraphSm weight="book" mb="XL">
                  {getTmpEngagementName({ createdAt: new Date() } as any)}
                </WSText.ParagraphSm>
              </WSElement>
              */}

              {collaboratorGroups.length > 0 ? (
                <WSForm.Field
                  name="collaboratorGroupId"
                  label="Group"
                  component={WSSelect}
                  componentProps={{
                    required: true,
                    options: collaboratorGroups.map(collaboratorGroup => ({
                      label: collaboratorGroup.name,
                      value: (collaboratorGroup as any).collaboratorGroupId
                    }))
                  }}
                  mb="XL"
                />
              ) : (
                <WSText.ParagraphSm my="XL">
                  No groups available
                </WSText.ParagraphSm>
              )}

              {isQuickbooksActive &&
                quickbooksVendorOptions.length > 0 &&
                quickbooksAccountsOptions.length > 0 && (
                  <>
                    <WSDivider mb="XL" label="Quickbooks Mapping" />
                    <WSText mb="XL">
                      Because you have an active integration with QBO, please
                      map this new Contractor to an existing Vendor, or leave
                      blank and we will create a new one. You can also set a
                      default expense account for payable line items.
                    </WSText>
                    <WSForm.Field
                      mb="XL"
                      name="qboVendorId"
                      component={WSSelect}
                      componentProps={{
                        placeholder: "Not mapped, create new Vendor",
                        options: quickbooksVendorOptions,
                        menuFooterAction: {
                          label: "Resync QBO Vendors",
                          icon: "refresh-v",
                          onClick: () => {
                            qboRefreshModal.open({
                              entity: QUICKBOOKS_ENTITY.VENDORS
                            });
                          }
                        }
                      }}
                      label="QBO Vendor"
                    />
                    <WSForm.Field
                      mb="2XL"
                      name="qboExpenseAccountId"
                      component={WSSelect}
                      componentProps={{
                        options: quickbooksAccountsOptions,
                        placeholder: `Use default "${defaultAccount?.fullyQualifiedName}"`,
                        menuFooterAction: {
                          label: "Resync QBO Expenses Accounts",
                          icon: "refresh-v",
                          onClick: () => {
                            qboRefreshModal.open({
                              entity: QUICKBOOKS_ENTITY.EXPENSES
                            });
                          }
                        }
                      }}
                      label="Default QBO Expense Account"
                    />
                    <RefreshModal />
                  </>
                )}

              <WSActions
                mt="3XL"
                alignment="fill"
                buttons={[
                  {
                    label: "Add to group",
                    kind: "Primary",
                    type: "submit",
                    loading: createEngagementMeta.isLoading,
                    disabled: collaboratorGroups.length === 0
                  },
                  {
                    label: "Cancel",
                    kind: "Secondary",
                    type: "button",
                    onClick() {
                      onClose(false);
                    }
                  }
                ]}
              />
            </WSForm>
          </>
        );
      }}
    </WSQueries>
  );
};
