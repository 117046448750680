import {
  toWSDateString,
  WSEmptyState,
  WSFilters,
  WSPage,
  WSPageToolbar,
  WSSelect,
  WSTable,
  WSTableCell,
  WSTableColumn
} from "@wingspanhq/fe-component-library";
import { WSQueries } from "../../../query/WSQuery";
import { useStatementsQuery } from "../../../query/bankStatements/queries/useStatementsQuery";
import { useDownloadBankStatements } from "../../../query/bankStatements/mutations/useDownloadBankStatements";
import { InternalAccountType } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { useUserId } from "../../../query/hooks/helpers";
import { useMemberProfile } from "../../../query/users/queries";
import { accountTypeToHumanReadable } from "../utils/accountTypeToHumanReadable";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useUrlQueryFilters } from "../../../utils/router";
import styles from "./styles.module.scss";
import {
  mapAndRevertParamsSortToTable,
  mapAndRevertTableSortToParams
} from "../../../utils/tableSortingHelpers";
import { sortData } from "../utils/sortData";
import {
  IStatementInternalAccountType,
  IStatementResponse
} from "../../../services/api/banking/statements/types";
import { MAIN_FILTER_OPTIONS } from "../utils/constants";
import { StatementPeriod } from "../utils/types";
import { useInternalAccountsForStatements } from "../queries/useInternalAccountsForStatements";
import { BankingInternalAccount } from "../../../services/api/banking/internalAccounts";
import { convertDateStringToUTCMidday } from "../../../utils/dates";

export type SortKey =
  | "accountType"
  | "financialPlatform"
  | "endDateString"
  | "internalAccount.numbers.account"
  | string;

type Filters = {
  accountType?: IStatementInternalAccountType[];
};

type StatementsFilters = {
  filters: Filters;
  sort: Record<SortKey, "asc" | "desc">;
};

const DEFAULT_FILTERS: StatementsFilters = {
  filters: { accountType: undefined },
  sort: { accountType: "desc" }
};

export type ViewInternalAccountStatement = IStatementResponse & {
  internalAccount: BankingInternalAccount;
};

export const RouteStatementDashboard: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);
  const queryBankStatements = useStatementsQuery();

  const internalAccountIds =
    queryBankStatements.data?.map(statement => statement.internalAccountId) ||
    [];

  const queryInternalAccounts = useInternalAccountsForStatements(
    internalAccountIds,
    {
      enabled: !!internalAccountIds.length
    }
  );

  const [download, downloadMeta] = useDownloadBankStatements();

  const { filters, setFilters } = useUrlQueryFilters<StatementsFilters>(
    DEFAULT_FILTERS
  );

  const onSetFilters = (filterValue: Filters) => {
    setFilters({
      ...filters,
      filters: filterValue
    });
  };

  const sortByAccountNumber = () => {
    setFilters({
      ...filters,
      sort: {
        "internalAccount.numbers.account": mapAndRevertTableSortToParams(
          filters.sort?.["internalAccount.numbers.account"]
        )
      }
    });
  };
  const sortByAccountType = () => {
    setFilters({
      ...filters,
      sort: {
        accountType: mapAndRevertTableSortToParams(filters.sort?.accountType)
      }
    });
  };
  const sortByStatementDate = () => {
    setFilters({
      ...filters,
      sort: {
        endDateString: mapAndRevertTableSortToParams(
          filters.sort?.endDateString
        )
      }
    });
  };
  const sortByPartner = () => {
    setFilters({
      ...filters,
      sort: {
        financialPlatform: mapAndRevertTableSortToParams(
          filters.sort?.financialPlatform
        )
      }
    });
  };

  const columns: Array<WSTableColumn<ViewInternalAccountStatement>> = [
    {
      config: {
        header: {
          text: "Statement period"
        },
        onColumnSort: sortByStatementDate,
        sortDirection: mapAndRevertParamsSortToTable(
          filters?.sort?.endDateString
        ),
        gridTemplateSizeMax: "1fr"
        // justify: "end"
      },
      renderFunction: ({ data }) => (
        <WSTableCell
          text={`${toWSDateString(
            convertDateStringToUTCMidday(data.startDateString),
            "monthDayYear"
          )} - ${toWSDateString(
            convertDateStringToUTCMidday(data.endDateString),
            "monthDayYear"
          )}`}
        />
      )
    },
    {
      config: {
        header: {
          text: "Account number"
        },
        onColumnSort: sortByAccountNumber,
        sortDirection: mapAndRevertParamsSortToTable(
          filters?.sort?.["internalAccount.numbers.account"]
        ),
        gridTemplateSizeMax: "1fr",
        hideOnScreens: ["XS"]
      },
      renderFunction: ({ data }) => (
        <WSTableCell text={data.internalAccount.numbers?.account || "N/A"} />
      )
    },
    {
      config: {
        header: {
          text: "Account type"
        },
        onColumnSort: sortByAccountType,
        sortDirection: mapAndRevertParamsSortToTable(
          filters?.sort?.accountType
        ),
        gridTemplateSizeMax: "1fr"
      },
      renderFunction: ({ data }) => (
        <WSTableCell
          text={accountTypeToHumanReadable(data.internalAccountType)}
          // secondaryText={data.internalAccount.name}
        />
      )
    },
    {
      config: {
        header: {
          text: "Partner bank"
        },
        onColumnSort: sortByPartner,
        sortDirection: mapAndRevertParamsSortToTable(
          filters?.sort?.financialPlatform
        ),
        hideOnScreens: ["XS"],
        gridTemplateSizeMax: "1fr"
        // justify: "center"
      },
      renderFunction: ({ data }) => {
        return <WSTableCell text={"Lead Bank"} />;
      }
    }
    // {
    //   config: {
    //     header: {
    //       text: "Ending Balance"
    //     },
    //     gridTemplateSizeMax: "1fr",
    //     hideOnScreens: ["XS", "S"]
    //     // justify: "center"
    //   },
    //   renderFunction: ({ data }) => (
    //     <WSTableCell
    //       text={formatStatementsMoney(data.endingBalance)}
    //       secondaryText={data.currency}
    //     />
    //   )
    // }
  ];

  const filterByAccountType = (statement: IStatementResponse) => {
    if (
      Array.isArray(filters.filters.accountType) &&
      filters.filters.accountType.length
    ) {
      return filters.filters.accountType.includes(
        statement.internalAccountType
      );
    }

    return true;
  };

  return (
    <WSPage>
      <WSPageToolbar
        title={"Statements"}
        titleAfter={
          <WSSelect
            hideClearAction={true}
            mode="single"
            // placeholder="Year"
            value={StatementPeriod.Year2024}
            onChange={period => {
              if (period !== StatementPeriod.Year2024) {
                history.push(`/member/statements/${period}`);
              }
            }}
            options={MAIN_FILTER_OPTIONS}
          />
        }
      />
      <WSQueries queries={{ queryBankStatements, queryMember }}>
        {({ queryBankStatementsData, queryMemberData }) => {
          const tableList = queryBankStatementsData
            .filter(filterByAccountType)
            .sort(sortData(filters.sort!))
            .map(bankStatement => ({
              id: bankStatement.accountStatementId,
              data: {
                ...bankStatement,
                internalAccount: queryInternalAccounts.data?.find(
                  account =>
                    account.internalAccountId ===
                    bankStatement.internalAccountId
                )!
              }
            }));

          return (
            <>
              <WSFilters<Filters>
                values={filters.filters}
                onFilter={onSetFilters}
                info={{ count: tableList.length }}
                primaryFilters={[
                  {
                    name: "accountType",
                    title: "Account Type",
                    type: "checkbox",
                    options: [
                      {
                        label: accountTypeToHumanReadable(
                          InternalAccountType.Clearing
                        ),
                        name: InternalAccountType.Clearing
                      },
                      {
                        label: accountTypeToHumanReadable(
                          InternalAccountType.TaxWithholding
                        ),
                        name: InternalAccountType.TaxWithholding
                      },
                      {
                        label: accountTypeToHumanReadable(
                          InternalAccountType.Banking
                        ),
                        name: InternalAccountType.Banking
                      }
                    ]
                  }
                ]}
                quickFilters={[
                  {
                    label: accountTypeToHumanReadable(
                      InternalAccountType.Clearing
                    ),
                    preset: {
                      accountType: [InternalAccountType.Clearing]
                    },
                    shortLabel: "Clearing",
                    theme: "neutral"
                  },
                  {
                    label: accountTypeToHumanReadable(
                      InternalAccountType.TaxWithholding
                    ),
                    preset: {
                      accountType: [InternalAccountType.TaxWithholding]
                    },
                    shortLabel: "Tax Withholding",
                    theme: "neutral"
                  },
                  {
                    label: accountTypeToHumanReadable(
                      InternalAccountType.Banking
                    ),
                    preset: {
                      accountType: [InternalAccountType.Banking]
                    },
                    shortLabel: "Wingspan Wallet",
                    theme: "neutral"
                  }
                ]}
              />
              {queryBankStatementsData.length === 0 ? (
                <WSEmptyState
                  type="search"
                  title="No statement available yet"
                  description="Statements will appear here once available."
                />
              ) : tableList.length === 0 ? (
                <WSEmptyState
                  type="search"
                  title="No results"
                  description="Try adjusting your filters to find what you're looking for. Clear filters to see all items."
                  buttons={[
                    {
                      label: "Clear filters",
                      kind: "Secondary",
                      onClick: () => {
                        setFilters(DEFAULT_FILTERS);
                      }
                    }
                  ]}
                />
              ) : (
                <WSTable<ViewInternalAccountStatement>
                  mt="XL"
                  loading={queryInternalAccounts.isLoading}
                  showHeader={true}
                  rowActions={({ data }) => [
                    {
                      label: " ",
                      text: "Download",
                      icon: "download",
                      className: styles.downloadIcon,
                      disabled: downloadMeta.isLoading,
                      hideOn: [],
                      onAsyncClick: async () => {
                        await download({
                          statementId: data.accountStatementId,
                          member: queryMemberData
                        });
                      }
                    }
                  ]}
                  columns={columns}
                  tableData={tableList}
                />
              )}
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
