import {
  IBulkPayableBatch,
  IBulkPayableBatchCreate,
  IBulkPayableBatchUpdate
} from "@wingspanhq/payments/dist/interfaces";
import { useWSMutation } from "../../../../query/helpers";
import { WSServiceError } from "../../../../utils/serviceHelper";
import {
  BULK_PAYABLE_BATCH_LIST,
  QUERY_BULK_PAYABLE_BATCH,
  QUERY_BULK_PAYABLE_BATCH_SUMMARY
} from "./keys";
import { bulkPayableService } from "../../services/bulkPayable";

export const useCreateBulkPayableBatch = () =>
  useWSMutation(
    (data: IBulkPayableBatchCreate) => bulkPayableService.batch.create(data),
    {
      dependencies: [BULK_PAYABLE_BATCH_LIST]
    }
  );

export const useUpdateBulkPayableBatch = () =>
  useWSMutation(
    (payload: { id: string; data: IBulkPayableBatchUpdate }) =>
      bulkPayableService.batch.update(payload.id, payload.data),
    {
      dependencies: [QUERY_BULK_PAYABLE_BATCH, QUERY_BULK_PAYABLE_BATCH_SUMMARY]
    }
  );

export const useDeleteBulkPayableBatch = () => {
  return useWSMutation<
    IBulkPayableBatch,
    WSServiceError,
    {
      bulkBatchId: string;
    }
  >(
    async ({ bulkBatchId }) => {
      return await bulkPayableService.batch.delete(bulkBatchId);
    },
    {
      dependencies: [BULK_PAYABLE_BATCH_LIST]
    }
  );
};
