import { IFeeHandlingConfig } from "@wingspanhq/payments/dist/interfaces";

export const selectorClientCreditCardFee = (
  creditFeeHandling?: IFeeHandlingConfig
) => {
  if (creditFeeHandling?.clientAbsolutePercentage)
    return `${creditFeeHandling.clientAbsolutePercentage}%`;
  if (creditFeeHandling?.clientPays)
    return `${(creditFeeHandling.clientPays / 100) * 2.9}%`;
  return "Free";
};
