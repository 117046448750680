import React from "react";

export const safePageKeyboardEventHandling = <
  E extends KeyboardEvent | React.KeyboardEvent
>(
  callback: (event: E) => void
) => {
  return (event: E) => {
    const target = event.target || event.currentTarget;
    if (target instanceof HTMLElement) {
      if (
        target instanceof HTMLInputElement ||
        target instanceof HTMLTextAreaElement
      ) {
        return;
      }

      if (target.closest("form, input, textarea, [contenteditable]")) {
        return;
      }

      if (target.closest("[data-active-modal]")) {
        return;
      }

      if (target.closest("[class^=WSSidebar]")) {
        return;
      }
    }

    callback(event);
  };
};
