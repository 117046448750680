import React, { ReactNode } from "react";
import {
  WSSidebar,
  WSElement,
  WSFlexBox,
  WSLayout,
  WSPanel,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";

import styles from "./styles.module.scss";

export interface SectionTitleProps {
  title?: string;
  titleAside?: ReactNode;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  titleAside
}) => {
  if (!title && !titleAside) return null;

  return (
    <WSFlexBox justify="space-between" alignItems="center">
      {title ? <WSText.Heading5>{title}</WSText.Heading5> : <WSElement />}
      {titleAside}
    </WSFlexBox>
  );
};

export interface SidePanelSectionProps extends SectionTitleProps {
  first?: boolean;
}

export const SidePanelSection: React.FC<SidePanelSectionProps> = ({
  title,
  titleAside,
  first,
  children,
  ...otherProps
}) => (
  <WSPanel noBorder mt={first ? "NONE" : "XL"} {...otherProps}>
    <SectionTitle title={title} titleAside={titleAside} />
    {children}
  </WSPanel>
);

export interface SidePanelProps {
  title: string;
  onClose?: () => void;
  onBack?: () => void;
}

const SidePanel: React.FC<SidePanelProps> = ({
  title,
  onClose,
  onBack,
  children
}) => {
  return (
    <>
      <WSScreen.Mobile>
        <WSLayout title={title} onClose={onClose} onBack={onBack} line>
          <WSElement className={styles.main}>{children}</WSElement>
        </WSLayout>
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        <WSSidebar onClose={onClose || onBack || (() => {})} noCloseIcon>
          <WSLayout
            title={title}
            onClose={onClose}
            onBack={onBack}
            line
            smallHeader
          >
            <WSElement className={styles.main}>{children}</WSElement>
          </WSLayout>
        </WSSidebar>
      </WSScreen.TabletAndDesktop>
    </>
  );
};

export default SidePanel;
