import { PopperProps } from "@material-ui/core/Popper";
import { WSIcon, WSText } from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import { isNumber } from "lodash";
import React, { useRef, useState } from "react";
import { Popper } from "../Popper";
import classes from "./styles.module.scss";

export interface TooltipProps extends Omit<PopperProps, "open"> {
  className?: string;
  toolTipClassName?: string;
  arrowClassName?: string;
  trigger?: React.ReactNode;
  leaveDelay?: number;
  onTriggerClick?: () => void;
  show?: boolean;
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  trigger,
  className,
  toolTipClassName,
  arrowClassName,
  leaveDelay,
  onTriggerClick,
  show = false,
  ...popperProps
}) => {
  const [visible, setVisible] = useState(show);
  const triggerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classNames(classes.tooltip, className)}
      onClick={onTriggerClick}
      onMouseEnter={() => {
        setVisible(true);
      }}
      onMouseLeave={() => {
        if (isNumber(leaveDelay) && leaveDelay > 0) {
          window.setTimeout(() => {
            setVisible(false);
          }, leaveDelay);
        } else {
          setVisible(false);
        }
      }}
    >
      <div ref={triggerRef} className={classes.trigger}>
        {trigger || (
          <WSIcon
            block
            name="info-circle"
            className={classes.icon}
            size="XS"
            color="gray400"
          />
        )}
      </div>

      <Popper
        open={visible}
        anchorEl={triggerRef.current}
        className={classNames(classes.popper, toolTipClassName)}
        modifiers={{
          arrow: {
            enabled: true,
            element: "span[data-popper-arrow]"
          },
          offset: {
            enabled: true,
            offset: "0,15"
          }
        }}
        {...popperProps}
      >
        <WSText color="gray50">{children}</WSText>
        <span
          data-popper-arrow
          className={classNames(classes.arrow, arrowClassName)}
        />
      </Popper>
    </div>
  );
};
