import {
  WSButton,
  WSButtons,
  WSElement,
  WSMessageBox,
  WSText,
  useWSModal
} from "@wingspanhq/fe-component-library";

interface VoidOriginalTaxFormWarningModalProps {
  onContinue(): void;
  onClose(): void;
}

const VoidOriginalTaxFormWarningModal: React.FC<VoidOriginalTaxFormWarningModalProps> = ({
  onContinue,
  onClose
}) => {
  return (
    <WSElement>
      <WSMessageBox.Warning
        noBorder
        icon="alert"
        title="Submit correction request to void original 1099 filing"
        mb="M"
      >
        <WSText.Paragraph color="gray700" mb="M">
          As an S-Corporation, you are not eligible for a 1099 according to IRS
          guidelines. Please verify this is accurate before proceeding.
        </WSText.Paragraph>

        <WSText.Paragraph color="gray700" mb="M">
          If the payer accepts and files this correction with the IRS, it will
          void the original 1099 filing.
        </WSText.Paragraph>
      </WSMessageBox.Warning>

      <WSButtons forceFullWidth>
        <WSButton.Primary onClick={onContinue}>
          Continue to review and submit
        </WSButton.Primary>
        <WSButton.Secondary onClick={onClose}>Back</WSButton.Secondary>
      </WSButtons>
    </WSElement>
  );
};

export const useVoidOriginalTaxFormWarningModal = () =>
  useWSModal(VoidOriginalTaxFormWarningModal, {
    title: "Are you sure?",
    size: "S"
  });
