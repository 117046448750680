import React, { useEffect } from "react";
import { useWSModal, WSList, WSLoader } from "@wingspanhq/fe-component-library";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { useUserId } from "../../../../query/hooks/helpers";
import { useCreateCard } from "../../../../query/cards/mutations/useCreateCard";
import { ICardResponse } from "../../../../services/api/banking/cards/types";

type ActivateVirtualCardModalProps = {
  onClose: () => void;
  onSuccess: (card: ICardResponse) => void;
};

const CreateVirtualCardModal: React.FC<ActivateVirtualCardModalProps> = ({
  onClose,
  onSuccess
}) => {
  const userId = useUserId();
  const [createCard, createCardMeta] = useCreateCard();

  useEffect(() => {
    createCard(
      {
        isVirtualCard: true,
        userId,
        // TODO; replace with actual address
        address: {
          city: "New York",
          country: "US",
          address1: "123 Main St",
          state: "NY",
          postal_code: "10001"
        }
      },
      {
        onSuccess: card => {
          onSuccess(card);
          onClose();
        }
      }
    );
    // createCard(
    //   {},
    //   {
    //     onSuccess: card => {
    //       onSuccess(card);
    //       onClose();
    //     }
    //   }
    // );
  }, []);

  return (
    <WSList gap="L">
      {createCardMeta.error ? (
        <WSErrorMessage
          mb="XL"
          error={createCardMeta.error}
          contextKey={ErrorContextKey.RevealCardDataOPT}
        />
      ) : (
        <WSLoader.Spinner />
      )}
    </WSList>
  );
};

export const useCreateVirtualCardModal = () =>
  useWSModal(CreateVirtualCardModal, {
    title: "Activate virtual card",
    size: "S"
  });
