import {
  IMemberClientRequirementResponse,
  IRequirementResponse,
  RequirementEmbeddedStatus
} from "@wingspanhq/payments/dist/interfaces";
import { WSTableRowMenuAction } from "@wingspanhq/fe-component-library";
import { ContextItem, RequirementContextType } from "../../types";
import { useOverwriteStatusModal } from "../../components/OverviewStatusModal/useOverwiewStatusModal";

export const useRequirementOverrideActions = (
  contextType: RequirementContextType
) => {
  const overwriteStatusModal = useOverwriteStatusModal();

  const onSuccess = () => {
    overwriteStatusModal.close();
  };

  const handleRevertRequirementStatus = async (
    requirement: IRequirementResponse,
    context: ContextItem
  ) => {
    overwriteStatusModal.open({
      requirement,
      status: null,
      context,
      onSuccess
    });
  };

  const handleMarkRequirementAsCompleted = async (
    requirement: IRequirementResponse,
    context: ContextItem
  ) => {
    overwriteStatusModal.open({
      requirement,
      status: RequirementEmbeddedStatus.Complete,
      context,
      onSuccess
    });
  };

  const handleMarkRequirementAsCanceled = async (
    requirement: IRequirementResponse,
    context: ContextItem
  ) => {
    overwriteStatusModal.open({
      requirement,
      status: RequirementEmbeddedStatus.Incomplete,
      context,
      onSuccess
    });
  };

  const createRevertAction = (
    item: IRequirementResponse,
    context: ContextItem
  ): WSTableRowMenuAction<IMemberClientRequirementResponse> => ({
    label: "Revert to synced status",
    helpText:
      "Undo overwritten status and \n revert to synced status from \n background check service",
    icon: "undo",
    onAsyncClick: async () => handleRevertRequirementStatus(item, context)
  });

  const createOverrideActions = (
    item: IRequirementResponse,
    context: ContextItem
  ): WSTableRowMenuAction<IMemberClientRequirementResponse> => ({
    label: "Overwrite status",
    helpText:
      "Overwritten status will be \n updated for both you and the \n contractor",
    icon: "edit-box",
    items: [
      {
        label: "Mark requirement complete",
        icon: "check-circle",
        onClick: async () =>
          await handleMarkRequirementAsCompleted(item, context)
      },
      {
        label: "Mark requirement failed",
        icon: "exit-circle",
        onClick: async () =>
          await handleMarkRequirementAsCanceled(item, context)
      }
    ]
  });

  return (
    item: IRequirementResponse,
    context: ContextItem
  ): WSTableRowMenuAction<IMemberClientRequirementResponse> | null => {
    //if we received legacy requirement - do not provide override actions
    if (
      (item as any).eligibilityRequirementId ||
      contextType === RequirementContextType.Payer
    )
      return null;

    if (item?.payerOverrideStatus) {
      return createRevertAction(item, context);
    } else {
      return createOverrideActions(item, context);
    }
  };
};
