import { IOrganizationAccountConfig } from "@wingspanhq/users";
import { useWSMutation } from "../../helpers";
import { QUERY_CUSTOMIZATION } from "../keys";
import { updateCustomization } from "../../../services/customizations";
import { DeepPartial } from "../../../utils";

export const useUpdateCustomization = (userId: string) =>
  useWSMutation(
    (payload: DeepPartial<IOrganizationAccountConfig>) =>
      updateCustomization(userId, payload),
    {
      dependencies: [QUERY_CUSTOMIZATION]
    }
  );
