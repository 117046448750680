import {
  useModalContext,
  WSButton,
  WSFlexBox,
  WSIcon,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import styles from "./MoneyGuaranteeModal.module.scss";

export const MONEY_GUARANTEE_MODAL = "MONEY_GUARANTEE_MODAL";

export const MoneyGuaranteeModal: React.FC = () => {
  const { closeModal } = useModalContext();
  return (
    <WSModal name={MONEY_GUARANTEE_MODAL} size="S">
      <WSFlexBox direction="column" alignItems="stretch">
        <WSFlexBox justify="space-between" mb="XL">
          <WSFlexBox.Center className={styles.badgeIconContainer}>
            <WSIcon block name="reward" color="blue400" size="L" />
          </WSFlexBox.Center>
          <WSFlexBox direction="column">
            <WSText.Heading4>100% satisfaction</WSText.Heading4>
            <WSText.Heading4>guaranteed</WSText.Heading4>
          </WSFlexBox>
        </WSFlexBox>
        <WSText.Heading5 mb="M">
          We stand by our 90-day happiness guarantee
        </WSText.Heading5>
        <WSText weight="medium" mb="XS">
          How does it work?
        </WSText>
        <WSText mb="XL">
          We are pleased to back Wingspan with a 100% money back guarantee. To
          qualify, a member must send their first invoice in the first 90 days.
          If you no longer feel that Wingspan is right for you, send us an email
          at <a href="mailto:members@wingspan.app">members@wingspan.app</a> and
          we’ll provide a full refund, no questions asked.
        </WSText>
        <WSButton fullWidth onClick={() => closeModal(MONEY_GUARANTEE_MODAL)}>
          Got it
        </WSButton>
      </WSFlexBox>
    </WSModal>
  );
};
