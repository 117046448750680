import {
  ICollaboratorEvents,
  ICollaboratorSchema
} from "@wingspanhq/payments/dist/interfaces";
import { getRedactedUserName } from "../query/users/selectors";

export const getCollaboratorName = (collaborator: ICollaboratorSchema) =>
  getRedactedUserName(collaborator.member.user) ||
  getCollaboratorCompanyName(collaborator) ||
  getCollaboratorEmail(collaborator);

export const getCollaboratorCompanyName = (collaborator: ICollaboratorSchema) =>
  collaborator.member.profile?.company?.name;

export const getCollaboratorEmail = (collaborator: ICollaboratorSchema) =>
  collaborator.member.user.email as string;

export const getIsTaxDataVerified = (memberEvents?: ICollaboratorEvents) =>
  Boolean(memberEvents?.taxDocumentationVerifiedAt);
