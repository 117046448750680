import {
  WSButton,
  WSFormOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { UserType } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useRequestPasswordReset } from "../../../query/users/mutations";
import { validatorEmail } from "../../../shared/validators/validatorEmail";
import { WS_LINKS } from "../../../types/wsLinks";

type Props = {
  userType: UserType;
  signInPath: string;
};

export const FormRequestReset: React.FC<Props> = ({ userType, signInPath }) => {
  const history = useHistory();

  const [request, requestMeta] = useRequestPasswordReset(signInPath);

  return (
    <WSFormOld
      defaultValues={{
        email: ""
      }}
      validationSchema={Yup.object().shape({
        email: validatorEmail.required("Email is required")
      })}
      onSubmit={values => {
        request({ ...values, userType });
      }}
    >
      <WSText.ParagraphSm mb="S">
        Please ensure this is the email used for your Wingspan account
      </WSText.ParagraphSm>

      <WSFormOld.Field
        mb="XL"
        name="email"
        component={WSTextInput}
        componentProps={{
          type: "email",
          placeholder: "Email"
        }}
      />

      <WSErrorMessage
        my="XL"
        contextKey="PasswordReset"
        error={requestMeta.error}
      />

      <WSButton.Tertiary
        type="submit"
        loading={requestMeta.isLoading}
        fullWidth
        data-testid="submit"
      >
        Send reset instructions
      </WSButton.Tertiary>

      <WSButton.Secondary
        fullWidth
        mt="M"
        type="button"
        onClick={() => {
          history.push(signInPath);
        }}
      >
        Back
      </WSButton.Secondary>

      <WSText.ParagraphXs color="gray500" mt="XL">
        This site is protected by reCAPTCHA and the Google{" "}
        <a href={WS_LINKS.googlePrivacyPolicy}>Privacy Policy</a> and{" "}
        <a href={WS_LINKS.googleTermOfService}>Terms of Service</a> apply.
      </WSText.ParagraphXs>
    </WSFormOld>
  );
};
