import { IAccount } from "@wingspanhq/users/dist/lib/interfaces/account";
import { INVOICE_ACCOUNT_NAME } from "../../Invoices/utils";

export const getAccountName = (account: IAccount): string => {
  const { institution, subType, mask, name } = account;
  let formattedSubType;
  if (subType) {
    formattedSubType = subType.replace(/([A-Z])/g, " $1").trim();
  }

  if (name === INVOICE_ACCOUNT_NAME) {
    return `Manual Bank Account (${account.numbers.account.slice(-4)})`;
  }

  if (institution && formattedSubType && mask) {
    return `${institution} ${formattedSubType} (${mask})`;
  }
  if (institution && formattedSubType) {
    return `${institution} ${formattedSubType}`;
  }
  return name;
};

export const getAccountSubName = (account: IAccount): string => {
  const { subType, mask, name } = account;
  let formattedSubType;
  if (subType) {
    formattedSubType = subType.replace(/([A-Z])/g, " $1").trim();
  }

  if (formattedSubType && mask) {
    return `${formattedSubType} (${mask})`;
  }
  if (formattedSubType) {
    return formattedSubType;
  }
  return name;
};
