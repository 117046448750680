import {
  TaxFilingType,
  Visibility
} from "@wingspanhq/users/dist/lib/interfaces";
import { MutationConfig, queryCache } from "react-query";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import {
  QUERY_USERS_MEMBER_PROFILE,
  QUERY_USERS_USER_PROFILE
} from "../../../query/users/keys";
import { useMemberProfile, useUserProfile } from "../../../query/users/queries";
import { usersService } from "../../../services/users";
import { getChangedData } from "../../../utils/getChangedData";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useIsOnlyOneBusinessOwner } from "../../hooks/useIsOnlyOneBusinessOwner";
import { stringToUndefinedIfEmpty } from "../../utils/stringToUndefinedIfEmpty";
import { formDataToRequestAddress } from "../FormPartialAddress";
import { FormData } from "./types";

export const useMutationSubmit = (config?: MutationConfig<void>) => {
  const userId = useUserId();
  const userQuery = useUserProfile(userId);
  const memberQuery = useMemberProfile(userId);
  const [
    _isOnlyOneBusinessOwner,
    setIsOnlyOneBusinessOwner
  ] = useIsOnlyOneBusinessOwner();

  return useWSMutation<void, WSServiceError, FormData>(
    async data => {
      const isCompany = data.iHaveEin;

      if (isCompany) {
        await usersService.user.update(userId, {
          profile: getChangedData(userQuery.data?.profile, {
            firstName: stringToUndefinedIfEmpty(data.company.legalFirstName),
            lastName: stringToUndefinedIfEmpty(data.company.legalLastName)
          })
        });
      }

      const member = await usersService.member.update(userId, {
        memberId: userId,
        profile: getChangedData(memberQuery.data?.profile, {
          company: {
            industry: data.industry,
            name: stringToUndefinedIfEmpty(data.businessName),
            ...(isCompany
              ? {
                  taxId: data.company.ein,
                  stateOfIncorporation: data.company.stateOfIncorporation,
                  yearOfIncorporation: data.company.yearOfIncorporation,
                  structure: data.company.businessType,
                  legalBusinessName: data.company.legalBusinessName
                }
              : {})
          },
          taxInfo: {
            filingType: isCompany
              ? TaxFilingType.business
              : TaxFilingType.personal
          },
          address: formDataToRequestAddress(data.businessAddress),
          visibilityMap: {
            phone: Visibility.Public,
            address: Visibility.Public
          }
        })
      });

      queryCache.setQueryData(QUERY_USERS_MEMBER_PROFILE, member);

      setIsOnlyOneBusinessOwner(
        isCompany ? data.company.isOnlyOneBusinessOwner : true
      );
    },
    {
      dependencies: [QUERY_USERS_USER_PROFILE, QUERY_USERS_MEMBER_PROFILE],
      ...config
    }
  );
};
