import {
  useModalContext,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ClientWorkFlowStatus,
  InvoiceStatus
} from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { queryCache } from "react-query";
import { addNotification } from "../../../components/Notification/Notification";
import { QUERY_PAYABLE, QUERY_PAYABLES } from "../../../query/payments/keys";
import { paymentsService } from "../../../services/payments";
import { bulkActionFactory } from "../../../utils/bulkActionFactory";
import { PayablesTableItemData } from "./PayablesTable";

type BulkApproveModalProps = {
  data: PayablesTableItemData[];
  onFinish?(): void;
};

export const PAYABLES_BULK_APPROVE = "PAYABLES_BULK_APPROVE";

export const BulkApproveModal: React.FC = () => {
  const { closeModal } = useModalContext();

  const [isUpdating, setIsUpdating] = useState(false);

  let paidItems: string[] = [];

  const bulkApprove = bulkActionFactory({
    onStart: () => {
      setIsUpdating(true);
      paidItems = [];
    },
    action: async (item: PayablesTableItemData) => {
      const payableId = item.payable?.payableId || "";

      if (item.payable?.status === InvoiceStatus.Paid) {
        paidItems.push(payableId);
      } else {
        return await paymentsService.payable.update(payableId, {
          client: {
            workflowStatus: ClientWorkFlowStatus.Approved
          }
        });
      }
    },
    onFinish: ({ successItems, failedItems }) => {
      queryCache.invalidateQueries(QUERY_PAYABLES);
      queryCache.invalidateQueries("PAYABLES_TO_APPROVE");
      successItems.forEach(item => {
        queryCache.invalidateQueries([QUERY_PAYABLE, item.payable?.payableId]);
      });

      addNotification({
        timeout: 6000,
        text:
          `${successItems.length} payable${
            successItems.length > 1 ? "s" : ""
          } have been approved` +
          (paidItems.length > 0
            ? `, ${paidItems.length} ${
                paidItems.length > 1 ? "were" : "was"
              } already paid`
            : "") +
          (failedItems.length > 0 ? `, ${failedItems.length} failed` : "")
      });

      setIsUpdating(false);
    }
  });

  return (
    <WSModal blockClose={isUpdating} name={PAYABLES_BULK_APPROVE} size="S">
      {({ data, onFinish }: BulkApproveModalProps) => (
        <>
          {data && data.length > 0 ? (
            <>
              <WSText.Heading5 mb="M">
                Approve {data.length} payable{data.length > 1 && "s"}?
              </WSText.Heading5>
              <WSText mb="XL">Approve payables to be paid</WSText>

              <WSButtons format="modal">
                <WSButton
                  loading={isUpdating}
                  onClick={async () => {
                    await bulkApprove.run(data);
                    onFinish?.();
                    closeModal(PAYABLES_BULK_APPROVE);
                  }}
                >
                  Approve
                </WSButton>
                <WSButton.Tertiary
                  disabled={isUpdating}
                  onClick={() => closeModal(PAYABLES_BULK_APPROVE)}
                >
                  Cancel
                </WSButton.Tertiary>
              </WSButtons>
            </>
          ) : (
            closeModal(PAYABLES_BULK_APPROVE)
          )}
        </>
      )}
    </WSModal>
  );
};
