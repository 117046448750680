import { WSFlexBox, WSLoader } from "@wingspanhq/fe-component-library";
import { InvoiceStatus } from "@wingspanhq/payments/dist/interfaces";
import { Currency } from "@wingspanhq/users/dist/lib/interfaces";
import queryString from "query-string";
import React, { useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { paymentsService } from "../services/payments";
import { usersService } from "../services/users";

type RouteProps = RouteComponentProps;

type Props = RouteProps;

export const CreateTestInvoice: React.FC<Props> = () => {
  const history = useHistory();

  // Allowed params:
  // p - sign out and redirect to payment screen after invoice creation
  const queries = queryString.parse(window.location.search);

  const createTestInvoice = async () => {
    try {
      const session = await usersService.session.get();

      if (session.userId) {
        const clientSuffix = new Date().valueOf();
        const memberClient = await paymentsService.memberClient.create({
          name: "Test client",
          company: `Test company ${clientSuffix}`,
          emailTo: `test__fe_e2e__${clientSuffix}@wingspan.test`
        });

        let invoice = await paymentsService.invoice.create({
          memberClientId: memberClient.memberClientId,
          lineItems: [
            {
              description: "Building website",
              totalCost: 15
            }
          ],
          dueDate: new Date(),
          creditFeeHandling: {
            clientPays: 100,
            memberPays: 0
          },
          currency: Currency.USD
        });

        invoice = await paymentsService.invoice.update(invoice.invoiceId, {
          status: InvoiceStatus.Open
        });

        if (queries.p) {
          await usersService.session.delete();
          history.push(`/payment/${invoice.invoiceId}`);
        } else {
          history.push(`/member/invoices/${invoice.invoiceId}`);
        }
      } else {
        history.push("/member/sign-in");
      }
    } catch (error) {
      console.error(error);
      history.push("/member/dashboard");
    }
  };

  useEffect(() => {
    createTestInvoice();
  }, []);

  return (
    <WSFlexBox.Center py="5XL">
      <WSLoader.Spinner />
    </WSFlexBox.Center>
  );
};
