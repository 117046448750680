import { IAddress } from "@wingspanhq/users/dist/lib/interfaces";
import { FormData } from "./types";

export const formDataToRequest = (data: FormData): IAddress => ({
  country: data.country,
  addressLine1: data.addressLine1,
  addressLine2: data.addressLine2,
  city: data.city,
  state: data.state,
  postalCode: data.zipCode
});
