import {
  useWSModal,
  WSActions,
  WSDivider,
  WSInfoBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useCloseCard } from "../../../../query/cards/mutations/useCloseCard";

export interface CloseCardProps {
  cardId: string;
  cardName: string;
  onClose: () => void;
  onSuccess: () => void;
}

const CloseCard: React.FC<CloseCardProps> = ({
  cardId,
  cardName,
  onClose,
  onSuccess
}) => {
  const [closeCard, closeCardMeta] = useCloseCard();

  return (
    <WSList gap="L">
      <WSText.ParagraphSm mb="S">
        Are you sure you want to close {cardName} permanently?
      </WSText.ParagraphSm>
      <WSInfoBox>
        Your existing card will be closed the card can no longer be used. If
        you’d like a new card in the future, you can order it from Wingspan
        Wallet.
      </WSInfoBox>
      <WSErrorMessage contextKey="Other" error={closeCardMeta.error} />
      <WSDivider />
      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Cancel",
            onClick: onClose,
            kind: "Secondary"
          },
          {
            label: "Close card",
            onAsyncClick: async () => {
              await closeCard(cardId, {
                onSuccess() {
                  onClose();
                  onSuccess();
                }
              });
            },
            kind: "Secondary",
            destructive: true
          }
        ]}
      ></WSActions>
    </WSList>
  );
};

export function useCloseCardModal() {
  return useWSModal(CloseCard, {
    title: "Close card",
    size: "S"
  });
}
