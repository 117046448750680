import { useUserId } from "../../query/hooks/helpers";
import { queryCache } from "react-query";
import { QUERY_USERS_USER_PROFILE } from "../../query/users/keys";
import { INewUser } from "@wingspanhq/users";
import { useUserProfile } from "../../query/users/queries";

export const useUserProfileData = () => {
  const userId = useUserId();
  const userData = queryCache.getQueryData(QUERY_USERS_USER_PROFILE) as
    | INewUser
    | undefined;

  useUserProfile(userId, {
    enabled: !!userId && !userData
  });

  return userData;
};
