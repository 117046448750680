import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { RouteRootDashboard } from "./routes/RouteRootDashboard";
import { RouteActivateVirtualCard } from "./routes/RouteActivateVirtualCard";
import { RouteOrderPhysicalCard } from "./routes/RouteOrderPhysicalCard";
import { useBrowserPageTitle } from "../../shared/hooks/useBrowserPageTitle";
import { RouteCardDetails } from "./routes/RouteCardDetails";
import { RouteConfirmOrderPhysicalCard } from "./routes/RouteConfirmOrderPhysicalCard";

export const WALLET_ROOT_PATH = "/member/wallet";
export const WALLET_CARD_DETAILS_PATH = WALLET_ROOT_PATH + "/card/:cardId";
export const WALLET_ACTIVATE_VIRTUAL_CARD_PATH =
  WALLET_ROOT_PATH + "/activate-virtual-card";
export const WALLET_ORDER_PHYSICAL_CARD_PATH =
  WALLET_ROOT_PATH + "/order-physical-card";
export const WALLET_CONFIRM_ORDER_PHYSICAL_CARD_PATH =
  WALLET_ROOT_PATH + "/confirm-order-physical-card";

export const useRedirectToCardDetails = () => {
  const history = useHistory();
  return (cardId: string) =>
    history.push(WALLET_CARD_DETAILS_PATH.replace(":cardId", cardId));
};

export const Wallet = () => {
  useBrowserPageTitle("Wallet");

  return (
    <Switch>
      <Route path={WALLET_CARD_DETAILS_PATH} component={RouteCardDetails} />
      <Route
        path={WALLET_ACTIVATE_VIRTUAL_CARD_PATH}
        component={RouteActivateVirtualCard}
      />
      <Route
        path={WALLET_ORDER_PHYSICAL_CARD_PATH}
        component={RouteOrderPhysicalCard}
      />
      <Route
        path={WALLET_CONFIRM_ORDER_PHYSICAL_CARD_PATH}
        component={RouteConfirmOrderPhysicalCard}
      />
      <Route path={WALLET_ROOT_PATH} component={RouteRootDashboard} />
      <Redirect from="*" to={WALLET_ROOT_PATH} />
    </Switch>
  );
};
