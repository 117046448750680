import { WSContainer, WSMessageBox } from "@wingspanhq/fe-component-library";
import { IClientInvoice } from "@wingspanhq/payments/dist/interfaces";
import React, { useEffect } from "react";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { OverlaySpinner } from "../../../components/OverlaySpinner";
import { useClientInvoiceQuery } from "../../../query/payments/queries";
import { useSetWSStore } from "../../../store";
import { GrowthSourceNames } from "../../../utils/growthAttribution";

type Props = {
  invoiceId: string;
  children(clientInvoice: IClientInvoice): React.ReactNode;
};

export const ClientInvoiceWrapper: React.FC<Props> = ({
  invoiceId,
  children
}) => {
  const setStore = useSetWSStore();
  const clientInvoiceQuery = useClientInvoiceQuery(invoiceId);

  useEffect(() => {
    if (clientInvoiceQuery.data) {
      setStore({
        growthAttributionDetails: {
          growthSource: GrowthSourceNames.Invoice,
          growthName: clientInvoiceQuery.data.memberClient.memberId
        }
      });
    }
  }, [clientInvoiceQuery.data]);

  if (clientInvoiceQuery.isLoading) {
    return <OverlaySpinner />;
  }

  if (
    clientInvoiceQuery.error &&
    clientInvoiceQuery.error.response?.status === 404
  ) {
    return (
      <WSContainer verticalPadding>
        <WSMessageBox.Error>No such invoice found</WSMessageBox.Error>
      </WSContainer>
    );
  }

  if (clientInvoiceQuery.data) {
    return (
      <>
        <BrowserPageTitle
          title={`Pay invoice #${clientInvoiceQuery.data.invoiceNumber ||
            "N/A"}`}
        />
        {children(clientInvoiceQuery.data)}
      </>
    );
  }

  return (
    <WSContainer verticalPadding>
      <WSMessageBox.Error>Sorry, something went wrong</WSMessageBox.Error>
    </WSContainer>
  );
};
