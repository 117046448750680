import classNames from "classnames";
import React from "react";
import { WSIcon } from "../../core/WSIcon/WSIcon.component";
import { WSText } from "../../core/WSText/WSText.component";
import { WSElement } from "../../WSElement/WSElement.component";
import { WSContainer } from "../WSContainer/WSContainer.component";
import { WSFlexBox } from "../WSFlexBox/WSFlexBox.component";
import styles from "./WSLayout.module.scss";

export interface WSLayoutProps {
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  headerCenter?: React.ReactNode;
  footer?: React.ReactNode;
  line?: boolean;
  progress?: number;
  onBack?: () => void;
  onClose?: () => void;
  title?: string;
  smallHeader?: boolean;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
}

export const WSLayout: React.FC<WSLayoutProps> = ({
  children,
  headerCenter,
  headerLeft,
  headerRight,
  line,
  progress,
  footer,
  onBack,
  onClose,
  title,
  smallHeader,
  className,
  headerClassName,
  contentClassName
}) => (
  <WSElement className={classNames(styles.wrapper, className)}>
    <WSElement
      className={classNames(
        styles.header,
        {
          [styles.smallHeader]: smallHeader
        },
        headerClassName
      )}
    >
      <WSContainer>
        <WSFlexBox.CenterY wrap="nowrap" justify="space-between">
          <WSElement className={styles.headerLeft}>
            {headerLeft ||
              (onBack && (
                <WSElement onClick={onBack} className={styles.headerIcon}>
                  <WSIcon
                    block
                    name="arrow-left"
                    onClick={onBack}
                    color="gray600"
                  />
                </WSElement>
              ))}
          </WSElement>
          <WSElement className={styles.headerCenter}>
            {headerCenter ||
              (title && (
                <WSText
                  kind={smallHeader ? "Heading4" : "Heading3"}
                  className={styles.headerTitle}
                >
                  {title}
                </WSText>
              ))}
          </WSElement>
          <WSElement className={styles.headerRight}>
            {headerRight ||
              (onClose && (
                <WSElement onClick={onClose} className={styles.headerIcon}>
                  <WSIcon block name="exit" onClick={onBack} color="gray600" />
                </WSElement>
              ))}
          </WSElement>
        </WSFlexBox.CenterY>
      </WSContainer>
    </WSElement>
    {typeof progress === "number" ? (
      <WSElement className={styles.line}>
        <WSElement
          className={styles.progress}
          style={{ width: `${progress}%` }}
        />
      </WSElement>
    ) : (
      <WSElement className={line ? styles.line : styles.emptyLine} />
    )}
    <WSElement className={classNames(styles.content, contentClassName)}>
      <WSElement className={styles.body}>{children}</WSElement>
      {footer ? (
        <WSContainer className={styles.footer}>{footer}</WSContainer>
      ) : null}
    </WSElement>
  </WSElement>
);
