import {
  WSActions,
  WSDivider,
  WSFlexBox,
  WSGrid,
  WSList
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { useQueryInternalAccounts } from "../../../../query/banking/queries/useQueryInternalAccounts";
import { LayoutFullscreen } from "../../../../shared/components/LayoutFullscreen";
import React from "react";
import { ICardResponse } from "../../../../services/api/banking/cards/types";
import { CardBillingInfo } from "./CardBillingInfo";
import { CardBalance } from "./CardBalance";
import { useActionButtons } from "./useActionButtons";
import { CardView } from "../../components/BankCard";

type Props = {
  card: ICardResponse;
};

export const CardDetails: React.FC<Props> = ({ card }) => {
  const history = useHistory();
  const queryInternalAccounts = useQueryInternalAccounts();

  const buttons = useActionButtons(card);

  return (
    <LayoutFullscreen
      onBack={() => {
        history.push("/member/wallet");
      }}
      headTitle="Manage Card"
      title={card.isVirtualCard ? "Virtual debit card" : "Physical debit card"}
      panel={false}
    >
      <WSGrid gutter="XL">
        <WSGrid.Item span={{ m: "6" }}>
          <WSList gap="XL">
            <WSFlexBox.Center>
              <CardView card={card} />
            </WSFlexBox.Center>

            <CardBillingInfo card={card} />
          </WSList>
        </WSGrid.Item>
        <WSGrid.Item span={{ m: "6" }}>
          <WSList gap="XL">
            <CardBalance card={card} />

            <WSActions orientation="vertical" buttons={buttons} />
            <WSDivider />
            <WSActions
              orientation="vertical"
              buttons={[
                {
                  label: "Back to Wallet",
                  onClick: () => {
                    history.push("/member/wallet");
                  },
                  kind: "Primary"
                }
              ]}
            />
          </WSList>
        </WSGrid.Item>
      </WSGrid>
    </LayoutFullscreen>
  );
};
