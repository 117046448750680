import { wsSearch, wsSearchSortKind } from "@wingspanhq/fe-component-library";
import {
  getAuthorizedAccountName,
  getMemberName,
  ITeamMemberRecord
} from "../../../shared/utils/teamUtils";
import isString from "lodash/isString";

export const getSearchedTeamMembers = (
  searchText: string,
  teammates: ITeamMemberRecord[]
) => {
  if (searchText.trim()) {
    return wsSearch(searchText, teammates, {
      normalizeWhitespace: true,
      ignoreCase: true,
      keySelector: teamMember =>
        [
          getAuthorizedAccountName(
            teamMember.teamMemberWSMember,
            teamMember.teamMemberWSUser
          ),
          getMemberName(teamMember.teamMemberWSMember),
          teamMember.teamMemberWSUser.email,
          teamMember.teamMemberWSUser.profile?.firstName,
          teamMember.teamMemberWSUser.profile?.lastName
        ]
          .filter(isString)
          .map(s => s.trim())
          .filter(Boolean),
      threshold: 0.7,
      sortBy: wsSearchSortKind.bestMatch
    });
  } else {
    return teammates;
  }
};
