import {
  detachLayoutProps,
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSModal
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import styles from "./Editable.module.scss";

export type EditableProps = {
  renderEdit(
    onClose: () => void,
    setLoading: (value: boolean) => void,
    loading: boolean
  ): React.ReactNode;
  renderView: (onOpen?: () => void) => React.ReactNode;
  openButtonLabel?: React.ReactNode;
  overflowModal?: "visible" | "scroll";
  testid?: string;
  noEdit?: boolean;
} & WSElementProps;

export const Editable: React.FC<EditableProps> = ({
  renderEdit,
  renderView,
  overflowModal,
  openButtonLabel,
  testid,
  noEdit,
  ...otherProps
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const { layoutProps } = detachLayoutProps(otherProps);

  const label = noEdit ? null : openButtonLabel === undefined ? (
    <WSButton.Link data-testid={testid} onClick={() => setIsEdit(true)}>
      Edit
    </WSButton.Link>
  ) : openButtonLabel ? (
    <WSButton.Link onClick={() => setIsEdit(true)}>
      {openButtonLabel}
    </WSButton.Link>
  ) : null;

  const view = (
    <WSFlexBox.CenterY justify="space-between" wrap="nowrap" {...layoutProps}>
      <WSElement className={styles.content} mr={label ? "3XL" : "NONE"}>
        {renderView(() => setIsEdit(true))}
      </WSElement>

      {label}
    </WSFlexBox.CenterY>
  );

  return (
    <>
      {isEdit ? (
        <>
          <WSModal
            onClose={() => setIsEdit(false)}
            size="S"
            className={overflowModal === "visible" ? styles.modal : undefined}
          >
            {renderEdit(() => setIsEdit(false), setLoading, loading)}
          </WSModal>
          {view}
        </>
      ) : (
        view
      )}
    </>
  );
};
