import { ReportsColumn } from "../../types";
import { IPayableAgingReportResponse } from "@wingspanhq/payments/dist/interfaces/api/reports";
import { formatReportsDate } from "../../utils/formatReportsDate";
import { formatReportMoney } from "../../utils/formatReportMoney";
import {
  getRedactedMemberName,
  getUserLegalName
} from "../../../../query/users/selectors";

export const COLUMNS: ReportsColumn<IPayableAgingReportResponse>[] = [
  {
    label: "Contractor ID",
    value: "Collaborator ID",
    getTableCell(data) {
      return data.memberClientId;
    }
  },
  {
    label: "Contractor External ID",
    value: "Collaborator External ID",
    getTableCell(data) {
      return data.memberExternalId;
    }
  },
  {
    label: "Contractor Name",
    value: "Collaborator Name",
    preselected: true,
    getTableCell(data) {
      return (
        getUserLegalName(data.member.user) ||
        data.member.profile?.company?.legalBusinessName ||
        data.member.profile?.company?.name ||
        data.member.user?.email
      );
    }
  },
  {
    label: "Contractor Preferred Name",
    value: "Collaborator Preferred Name",
    getTableCell(data) {
      return (data.member?.user?.profile as any)?.preferredName;
    }
  },
  {
    label: "Contractor Email",
    value: "Collaborator Email",
    getTableCell(data) {
      return data.member.user.email;
    }
  },
  {
    label: "Contractor Company",
    value: "Collaborator Company",
    getTableCell(data) {
      return (
        data.member.profile?.company?.legalBusinessName ||
        data.member.profile?.company?.name
      );
    }
  },
  {
    label: "Invoice Number",
    value: "Invoice Number",
    preselected: true,
    getTableCell(data) {
      return data.invoiceNumber;
    }
  },
  {
    label: "Invoice URL",
    value: "Invoice URL",
    getTableCell(data) {
      return `${window.location.host}/member/invoices/${data.invoiceId}`;
    }
  },
  {
    label: "Invoice PDF",
    value: "Invoice PDF",
    getTableCell(data) {
      return data.invoicePdf;
    }
  },
  {
    label: "Receipt PDF",
    value: "Receipt PDF",
    getTableCell(data) {
      return data.receiptPdf;
    }
  },
  {
    label: "Sent Date",
    value: "Sent Date",
    preselected: true,
    getTableCell(data) {
      return formatReportsDate(data.sentDate);
    }
  },
  {
    label: "Pay Date",
    value: "Pay Date",
    getTableCell(data) {
      return formatReportsDate(data.paidDate);
    }
  },
  {
    label: "Due Date",
    value: "Due Date",
    preselected: true,
    getTableCell(data) {
      return formatReportsDate(data.dueDate);
    }
  },
  {
    label: "Purchase Order Number",
    value: "Purchase Order Number",
    getTableCell(data) {
      return data.purchaseOrderNumber;
    }
  },
  {
    label: "Payout Destination",
    value: "Payout Destination",
    getTableCell(data) {
      return data.payoutDestination;
    }
  },
  {
    label: "Project Name",
    value: "Project Name",
    getTableCell(data) {
      return data.projectName;
    }
  },
  {
    label: "Notes",
    value: "Invoice Notes",
    getTableCell(data) {
      return data.notes;
    }
  },
  {
    label: "Invoice Amount",
    value: "Invoice Amount",
    preselected: true,
    getTableCell(data) {
      return formatReportMoney(data.amount);
    }
  },
  {
    label: "Pre-Approver Name",
    value: "Invoice Pre-Approver Name",
    getTableCell(data) {
      const redactedMember = data.eventActors?.preApprovedBy;
      return redactedMember ? getRedactedMemberName(redactedMember) : null;
    }
  },
  {
    label: "Approver Name",
    value: "Approver Name",
    getTableCell(data) {
      const redactedMember = data.eventActors?.approvedBy; // be does not return this yet
      return redactedMember
        ? getRedactedMemberName(redactedMember)
        : data.approverName;
    }
  },
  {
    label: "Age Group",
    value: "Age Group",
    preselected: true,
    disabled: true,
    getTableCell(data) {
      return data.ageGroup;
    }
  }
];
