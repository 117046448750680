import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { WSFlexBox, WSIcon, WSText } from "@wingspanhq/fe-component-library";
import { Tooltip } from "../Tooltip";
import classNames from "classnames";

declare global {
  interface Window {
    Headway: any;
  }
}

export interface HeadwayWidget {
  show(): void;
  getUnseenCount(): number;
  getPrivateToken(): string;
}

export interface HeadwayNotificationProps {
  isMobile: boolean;
  isSideNavOpened?: boolean;
  className?: string;
  onClick?(): void;
  kind: "nav" | "help";
}

export const HeadwayNotification: React.FC<HeadwayNotificationProps> = ({
  isMobile,
  isSideNavOpened,
  className,
  onClick,
  kind
}) => {
  const [showNewIndicator, setShowNewIndicator] = useState(false);

  const embedHeadway = () => {
    const scriptDataId = "wingspan-headway-script";

    const callbacks = {
      onWidgetReady: function(w: HeadwayWidget) {
        if (window.Headway.getUnseenCount() > 0) {
          setShowNewIndicator(true);
        }
      },
      onShowWidget: function() {
        setShowNewIndicator(false);
      },
      onHideWidget: function() {
        window.document.documentElement.removeAttribute("data-headway");
      }
    };

    const config = {
      selector: "#headway-widget",
      account: "J0g6n7",
      callbacks
    };

    const loadHeadwayAndInit = () => {
      const script = document.createElement("script");
      script.setAttribute("data-id", scriptDataId);
      script.src = "https://cdn.headwayapp.co/widget.js";
      document.head.appendChild(script);
      script.onload = () => {
        window.Headway.init(config);
      };
    };

    // load the headway script if we haven't already
    const headwayScript = document.querySelector(
      `script[data-id="${scriptDataId}"]`
    );
    if (!headwayScript) {
      //if headway script doesn't exist
      loadHeadwayAndInit();
    } else if (window.Headway) {
      //if the headway object exists (script is loaded, but not inited)
      window.Headway.init(config);
    } else {
      //if the script is loaded but the object doesn't exist, remove script and reload
      headwayScript.parentNode?.removeChild(headwayScript);
      loadHeadwayAndInit();
    }
  };
  useEffect(embedHeadway, [isSideNavOpened, isMobile]);

  const clickHeadwayWidget = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    const headway = document.getElementById("HW_badge_cont");
    if (headway) {
      headway.click();
    }
  };

  const content = (
    <WSFlexBox.CenterY
      px="XL"
      className={classNames(styles.headwayNotification, className)}
      onClick={clickHeadwayWidget}
      onMouseDown={() => {
        window.document.documentElement.setAttribute("data-headway", kind);
        onClick?.();
      }}
      wrap="nowrap"
      data-testid="whatsNewButton"
    >
      <WSIcon block name="launch" color="gray600" />
      {isSideNavOpened || isMobile ? (
        <WSText ml="XL" color="gray600" className={styles.label}>
          What's New
        </WSText>
      ) : null}

      {showNewIndicator ? <span className={styles.indicatorNew} /> : null}
      <span className={styles.headwayWidget} id="headway-widget" />
    </WSFlexBox.CenterY>
  );

  return isSideNavOpened || isMobile ? (
    content
  ) : (
    <Tooltip trigger={content} placement="right">
      <WSText color="white" className={styles.label}>
        What's New
      </WSText>
    </Tooltip>
  );
};
