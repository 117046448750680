import {
  IPayableSchema,
  InvoiceStatus
} from "@wingspanhq/payments/dist/interfaces";

export const selectorIsPayableOpen = (payable: IPayableSchema) => {
  return (
    payable.status === InvoiceStatus.Open ||
    payable.status === InvoiceStatus.Overdue ||
    payable.status === InvoiceStatus.Pending
  );
};
