import {
  WSButton,
  WSDivider,
  WSFlexBox,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { ITransaction } from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";
import { WSQueries } from "../../../query/WSQuery";
import { useTaxEstimate } from "../../../query/taxes/queries";
import { TaxEstimateType } from "@wingspanhq/bookkeeping/dist/lib/interfaces/taxEstimate";
import { getTaxSavingsForTransaction } from "../../utils";
import { getCurrentIRSYear } from "../../../shared/utils/taxes";

export const BOOKKEEPING_HOW_TAX_SAVINGS_CALCULATED =
  "BOOKKEEPING_HOW_TAX_SAVINGS_CALCULATED";

export const HowTaxSavingsCalculated: React.FC<{
  transaction: ITransaction;
}> = ({ transaction }) => {
  const history = useHistory();
  const qTaxEstimate = useTaxEstimate({
    type: TaxEstimateType.Real,
    year: getCurrentIRSYear()
  });

  return (
    <WSModal
      name={BOOKKEEPING_HOW_TAX_SAVINGS_CALCULATED}
      size="S"
      title="How are my tax savings calculated?"
    >
      <WSQueries
        queries={{
          qTaxEstimate
        }}
      >
        {({ qTaxEstimate }) => {
          const {
            expense,
            expenseMultiplier,
            deduction,
            estimatedTaxRate,
            totalSavings
          } = getTaxSavingsForTransaction(transaction, qTaxEstimate.data);

          return (
            <>
              <WSFlexBox.CenterY justify="space-between">
                <WSText.ParagraphSm>Expense</WSText.ParagraphSm>
                <WSText.ParagraphSm formatMoney="default">
                  {expense}
                </WSText.ParagraphSm>
              </WSFlexBox.CenterY>
              <WSFlexBox.CenterY mt="XS" justify="space-between">
                <WSText.ParagraphSm>Expense multiplier</WSText.ParagraphSm>
                <WSText.ParagraphSm tabularNums>
                  x {Math.round(expenseMultiplier * 100) / 100}%
                </WSText.ParagraphSm>
              </WSFlexBox.CenterY>
              <WSDivider my="M" />
              <WSFlexBox.CenterY justify="space-between">
                <WSText.ParagraphSm>Deduction</WSText.ParagraphSm>
                <WSText.ParagraphSm formatMoney="default">
                  {deduction}
                </WSText.ParagraphSm>
              </WSFlexBox.CenterY>
              <WSFlexBox.CenterY mt="XS" justify="space-between">
                <WSText.ParagraphSm>Estimated tax rate</WSText.ParagraphSm>
                <WSText.ParagraphSm tabularNums>
                  x {Math.round(estimatedTaxRate * 100) / 100}%
                </WSText.ParagraphSm>
              </WSFlexBox.CenterY>
              <WSDivider my="M" />
              <WSFlexBox.CenterY mb="XL" justify="space-between">
                <WSText.ParagraphSm>Total savings</WSText.ParagraphSm>
                <WSText weight="medium" color="green500" formatMoney="default">
                  {totalSavings}
                </WSText>
              </WSFlexBox.CenterY>

              <WSText.Heading5 mb="M">
                What is an expense multiplier?
              </WSText.Heading5>
              <WSText mb="M">
                The portion of the amount spent related to tax write-offs based
                on this transaction or your tax profile
              </WSText>
              <WSText.Heading5 mb="M">
                What is my estimated tax rate?
              </WSText.Heading5>
              <WSText mb="M">
                Based on your profile, including but not limited to how much you
                make, where you work, and your filing status.{" "}
                <WSButton.Link
                  onClick={() => history.push("/member/settings/tax-profile")}
                >
                  Update tax profile.
                </WSButton.Link>
              </WSText>
            </>
          );
        }}
      </WSQueries>
    </WSModal>
  );
};
