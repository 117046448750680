import { WSButton } from "@wingspanhq/fe-component-library";
import { useModalFormW9Certify } from "../../ModalW9Certify/useModalFormW9Certify";
import { NotificationTemplate } from "../index";

export const W9InfoCollaborator: NotificationTemplate<{
  collaborationsSummary: [
    {
      memberClientId: string;
      companyName: string;
    }
  ];
}> = {
  renderTitle: props => "Missing W-9 information",
  renderBody: props => {
    const collaborationsSummary: any[] =
      props.context?.collaborationsSummary || [];

    const clientNames =
      collaborationsSummary.reduce<string[]>((acc, item) => {
        acc.push(item.companyName);
        return acc;
      }, []) || [];

    const clientNamesString = clientNames.join(", and ");

    return (
      <>
        Our records show that you have received more than $600 in annual
        payments from <b>{clientNamesString}</b>. You must complete your W-9
        information in order to get paid.
      </>
    );
  },
  Footer: relatedData => {
    const consentModal = useModalFormW9Certify();

    return (
      <WSButton.Link
        size="S"
        rightIcon="chevron-right"
        onClick={() => {
          consentModal.open({});
        }}
      >
        Certify W-9 information
      </WSButton.Link>
    );
  }
};
