import {
  toWSDateString,
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSPanelProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  INTEGRATIONS_QUICKBOOKS_SYNC_ACTIVITIES_QUERY_FILTERS_DEFAULT,
  useIntegrationsQuickbooksSyncActivities
} from "../../../query/integrations/queries";
import { WSQueries } from "../../../query/WSQuery";
import { QuickbooksSyncBadge } from "../../../Settings/components/QuickbooksSyncBadge/QuickbooksSyncBadge";

export const QuickbooksHistory: React.FC<{
  entityId: string;
  Wrapper: React.FC;
} & WSElementProps &
  WSPanelProps> = ({ entityId, Wrapper, ...props }) => {
  const qIntegrationsQuickbooksSyncActivities = useIntegrationsQuickbooksSyncActivities(
    {
      ...INTEGRATIONS_QUICKBOOKS_SYNC_ACTIVITIES_QUERY_FILTERS_DEFAULT,
      entityId
    },
    {
      size: 100
    }
  );
  const history = useHistory();

  return (
    <WSQueries
      queries={{ qIntegrationsQuickbooksSyncActivities }}
      renderErrors={() => null}
      renderLoader={() => null}
    >
      {({ qIntegrationsQuickbooksSyncActivities }) => {
        const activities =
          qIntegrationsQuickbooksSyncActivities.data.data || [];

        if (!activities.length) {
          return null;
        }

        return (
          <Wrapper {...props} data-testid="qboHistory">
            <WSFlexBox
              wrap="nowrap"
              alignItems="center"
              justify="space-between"
              mb="XL"
            >
              <WSText.Heading5>QBO history</WSText.Heading5>
              <QuickbooksSyncBadge latestActivity={activities[0]} />
            </WSFlexBox>
            {qIntegrationsQuickbooksSyncActivities.data.data.map(
              (activity, index) => (
                <WSElement
                  mb={index === activities.length - 1 ? undefined : "S"}
                  key={index}
                >
                  <WSText weight="medium" mb="XS" color="gray600">
                    {`${
                      activity.isError ? `Failed to ` : `Successfully `
                    }${activity.action.toLocaleLowerCase()}${
                      activity.isError ? `` : `d`
                    } ${activity.qboEntityName}${
                      activity.message === "MarkedAsPaid"
                        ? ", marked as paid."
                        : ""
                    }`}
                  </WSText>
                  <WSText color="gray500">
                    {toWSDateString(activity.createdAt)}
                  </WSText>
                </WSElement>
              )
            )}
            <WSButton.Link
              mt="XL"
              rightIcon="arrow-right"
              onClick={() => {
                history.push(
                  "/member/settings/integrations/quickbooks/history/" +
                    activities[0].entityId
                );
              }}
            >
              View sync details
            </WSButton.Link>
          </Wrapper>
        );
      }}
    </WSQueries>
  );
};
