import {
  WSButton,
  WSCentered,
  WSContainer,
  WSCopyText,
  WSDivider,
  WSIcon,
  WSLayout,
  WSText,
  useIsDesktop
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { WSQueries } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import { useUserProfile } from "../../query/users/queries";
import { IS_PRODUCTION_ENV } from "../../shared/constants/environment";

const linkPrefix = IS_PRODUCTION_ENV ? "wingspan.me/" : "staging.wingspan.me/";

export const PersonalPayLinkSuccess: React.FC = () => {
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const memberId = useUserId();
  const qUserProfile = useUserProfile(memberId);

  useBrowserPageTitle("Personal Pay Link - Success", "");

  return (
    <WSQueries
      queries={{ userData: qUserProfile }}
      renderLoader={() => <WSLayout />}
    >
      {({ userData }) => {
        return (
          <WSLayout
            headerLeft={
              <WSIcon
                block
                name="exit"
                size="M"
                color="gray500"
                onClick={() => history.push("/member/personal-pay-link")}
              />
            }
            line
            headerCenter={<WSText weight="medium">Create my pay link</WSText>}
          >
            <WSContainer verticalPadding>
              <WSCentered span={{ s: "10", m: "8", l: "6" }}>
                <WSText.Heading1 align={isDesktop ? "center" : "left"}>
                  Congrats!
                </WSText.Heading1>
                <WSText
                  weight="medium"
                  mt="XS"
                  align={isDesktop ? "center" : "left"}
                >
                  Your new wingspan.me link is live
                </WSText>
                <WSText
                  color="gray600"
                  mt="M"
                  align={isDesktop ? "center" : "left"}
                >
                  Share with a client now to get paid any amount. Have a
                  website? We also recommend posting this link there.
                </WSText>

                <WSCopyText
                  isURL
                  shareTitle="My wingspan.me link"
                  data={linkPrefix + userData.data.tag}
                  mt="XL"
                  data-testid="copyPpl"
                />

                <WSDivider my="XL" />

                <WSText color="gray600" mb="XL" align="center">
                  Formalize and track your payment request with an invoice.
                </WSText>
              </WSCentered>
              <WSCentered span={{ s: "4" }}>
                <WSButton.Primary
                  fullWidth
                  mb="M"
                  type="button"
                  onClick={() => history.push("/member/invoices/create")}
                >
                  Create an invoice
                </WSButton.Primary>
                <WSButton.Secondary
                  fullWidth
                  type="button"
                  onClick={() => history.push("/member/personal-pay-link")}
                >
                  Back to pay link
                </WSButton.Secondary>
              </WSCentered>
            </WSContainer>
          </WSLayout>
        );
      }}
    </WSQueries>
  );
};
