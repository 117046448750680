import {
  ICatchUpExpenseTransactions,
  IProfitAndLoss
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  useIsMobile,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSPanel,
  WSPanelProps,
  WSText
} from "@wingspanhq/fe-component-library";
import styles from "./Review.module.scss";
import React from "react";
import { useHistory } from "react-router-dom";
import { getTotalCatchUpExpenseTransactions } from "../../../query/bookkeeping/selectors";
import { IActivity } from "@wingspanhq/users/dist/lib/interfaces";
import { ITransaction } from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";
import { groupBy, sortBy, sumBy } from "lodash";

interface ReviewProps extends WSPanelProps {
  profitAndLoss: IProfitAndLoss;
  expenseCatchUp: ICatchUpExpenseTransactions;
  activity: IActivity;
  isShort?: boolean;
}

export const Review: React.FC<ReviewProps> = ({
  expenseCatchUp,
  activity,
  isShort,
  ...otherProps
}) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const { expensesReviewedAt = new Date(0) } = activity.events;

  const totalExpenseTransactions = getTotalCatchUpExpenseTransactions(
    expenseCatchUp,
    activity
  );

  const hasTransactionsToReview = !(totalExpenseTransactions === 0);

  const allTransactions = [
    ...expenseCatchUp.accountExpenses,
    ...expenseCatchUp.merchantExpenses
  ]
    .reduce<ITransaction[]>((acc, group) => [...acc, ...group.transactions], [])
    .filter(t => t.createdAt > expensesReviewedAt);

  const merchantGroups = groupBy(
    allTransactions.filter(t => t.merchantName),
    "merchantName"
  );

  const merchants = sortBy(Object.keys(merchantGroups), [
    name => merchantGroups[name].length
  ]);

  const totalAmount = sumBy(allTransactions, "amount");

  let merchantsText = "";

  if (merchants.length) {
    const firstMerchants = merchants.slice(0, 2);

    merchantsText = `from ${firstMerchants.join(", ")}`;

    const otherMerchantLength = merchants.length - firstMerchants.length;
    if (otherMerchantLength > 0) {
      merchantsText = `${merchantsText}, and ${otherMerchantLength} other merchants`;
    }
  }

  const isSmall = isShort || isMobile;

  return (
    <WSPanel
      mb="2XL"
      className={styles.panel}
      color="gray600"
      px={isSmall ? "M" : "XL"}
      py="XL"
      onClick={() =>
        history.push(
          hasTransactionsToReview
            ? "/member/bookkeeping/catch-up"
            : "/member/bookkeeping/transactions/add-transaction"
        )
      }
      {...otherProps}
    >
      <WSFlexBox.CenterY>
        <WSElement className={styles.icon} mr={isSmall ? "M" : "XL"} />
        <WSElement className={styles.content} mr="M">
          {isSmall ? (
            <>
              {hasTransactionsToReview ? (
                <>
                  <WSText.Heading5>New write-offs!</WSText.Heading5>
                  <WSText mt="XS">
                    You have {totalExpenseTransactions} new transactions worth
                    up to{" "}
                    <WSText inline formatMoney>
                      {totalAmount}
                    </WSText>
                    .
                  </WSText>
                </>
              ) : (
                <>
                  <WSText.Heading5>
                    Add transactions to find write-offs
                  </WSText.Heading5>
                  <WSText mt="XS">
                    Independent professionals can save an average of $3,271.00,
                    We’ll help make finding savings easy.
                  </WSText>
                </>
              )}
            </>
          ) : (
            <>
              {hasTransactionsToReview ? (
                <>
                  <WSText.Heading5>New write-offs to review!</WSText.Heading5>
                  <WSText mt="XS">
                    You have {totalExpenseTransactions} new transactions{" "}
                    {merchantsText} worth up to{" "}
                    <WSText inline formatMoney>
                      {totalAmount}
                    </WSText>
                    .
                  </WSText>
                </>
              ) : (
                <>
                  <WSText.Heading5>
                    Add transactions to find write-offs
                  </WSText.Heading5>
                  <WSText mt="XS">
                    Independent professionals can save an average of $3,271.00,
                    We’ll help make finding savings easy.
                  </WSText>
                </>
              )}
            </>
          )}
        </WSElement>
        <WSIcon
          block
          name="chevron-right"
          size={isSmall ? "XS" : "S"}
          color="gray500"
        />
      </WSFlexBox.CenterY>
    </WSPanel>
  );
};
