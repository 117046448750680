import {
  WSButton,
  WSAvatar,
  WSDropFileInput,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { useWSMutation } from "../../../../../query/helpers";
import { filesService } from "../../../../../services/files";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { LayoutW9 } from "../../../components/LayoutW9";

export const RouteSsnUpload: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [upload, uploadMeta] = useWSMutation(
    async () => {
      if (files) {
        const data = new FormData();
        files.forEach((file, index) => data.append(`files[${index}]`, file));

        const uploadedFiles = await filesService.member.private.create(data);

        return uploadedFiles;
      } else {
        throw new Error("Please, choose a file");
      }
    },
    {
      onSuccess: onNext,
      dependencies: []
    }
  );

  return (
    <LayoutW9
      title="Upload SSN confirmation documents"
      description="Upload a clear and legible copy of your Social Security card for TIN verification."
    >
      <WSDropFileInput
        multiple
        buttonText="Upload documents"
        onDropAccepted={(accepted: File[]) => {
          setFiles(oldFiles => [...oldFiles, ...accepted]);
        }}
      />
      {files.map((file, index) => (
        <WSFlexBox.CenterY key={`${file.name}_${index}`} mt="M" wrap="nowrap">
          <WSAvatar.Icon
            icon="paperclip"
            mr="M"
            color="green500"
            colorBackground="green50"
          />
          <WSText>{file.name}</WSText>
          <WSIcon
            block
            color="red500"
            size="XS"
            ml="M"
            name="exit"
            onClick={() => {
              setFiles(oldFiles => oldFiles.filter(f => f.name !== file.name));
            }}
          />
        </WSFlexBox.CenterY>
      ))}

      <WSErrorMessage
        mt="XL"
        contextKey="UploadFile"
        error={uploadMeta.error}
      />

      <WSButton
        mt="XL"
        fullWidth
        onClick={upload}
        loading={uploadMeta.isLoading}
      >
        Upload documents
      </WSButton>
    </LayoutW9>
  );
};
