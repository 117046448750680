import {
  WSButton,
  WSCentered,
  WSContainer,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSIcon,
  WSInputDateOld,
  WSInputMaskOld,
  WSLayout,
  WSSelectOld,
  WSText,
  WSTextInput,
  useModalContext
} from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps } from "react-router";
import * as Yup from "yup";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { US_STATES_OPTIONS } from "../../constants/user";
import { WSQueries } from "../../query/WSQuery";
import {
  useBenefitsService,
  useUpdateBenefitsService
} from "../../query/benefits";
import { useUserId } from "../../query/hooks/helpers";
import {
  useUpdateBenefitsSetupActivity,
  useUpdateMemberProfile,
  useUpdateUserProfile
} from "../../query/users/mutations";
import { useMemberProfile, useUserProfile } from "../../query/users/queries";
import { validatorEmail } from "../../shared/validators/validatorEmail";
import { useSetWSStore } from "../../store";
import { goBackOrToParent } from "../../utils/goToParentRoute";
import {
  validatorAddressLine1,
  validatorAddressLine2,
  validatorFirstName,
  validatorLastName,
  validatorZipCode
} from "../../utils/validators";
import { BENEFITS_PENDING_MODAL } from "../modals/BenefitsPending";

export const BenefitsContactForm: React.FC<RouteComponentProps> = ({
  history
}) => {
  const { openModal } = useModalContext();
  const memberId = useUserId();
  const qUserProfile = useUserProfile(memberId);
  const qMemberProfile = useMemberProfile(memberId);
  const qBenefitsService = useBenefitsService();
  const [
    updateMemberProfile,
    updateMemberProfileMeta
  ] = useUpdateMemberProfile({ throwOnError: true });
  const [updateUserProfile, updateUserProfileMeta] = useUpdateUserProfile({
    throwOnError: true
  });

  const [updateBenefitsSetupActivity] = useUpdateBenefitsSetupActivity();

  const [
    updateBenefitsService,
    updateBenefitsServiceMeta
  ] = useUpdateBenefitsService({ throwOnError: true });
  const setStore = useSetWSStore();

  useBrowserPageTitle("Benefits - Contact info");

  return (
    <WSLayout
      headerLeft={
        <WSIcon
          block
          name="chevron-left"
          size="M"
          color="gray500"
          onClick={() => goBackOrToParent(history)}
        />
      }
      headerCenter={
        <WSText.Heading5>Activate Premium Perks package</WSText.Heading5>
      }
      line
    >
      <WSContainer verticalPadding>
        <WSQueries
          queries={{ userData: qUserProfile, memberData: qMemberProfile }}
        >
          {({ userData, memberData }) => {
            const user = userData.data;
            const member = memberData.data;

            return (
              <WSCentered span={{ m: "6" }}>
                <WSText.Heading4 align="center">
                  Confirm contact info
                </WSText.Heading4>
                <WSText mt="XL">
                  Activate the benefits package included in your Wingspan
                  membership by confirming your info below. We’ll send you an
                  email with your new benefits member ID and instructions for
                  how to maximize this and other benefits.
                </WSText>

                <WSFormOld
                  onSubmit={async formData => {
                    await updateMemberProfile({
                      memberId,
                      profile: {
                        address: {
                          country: "US",
                          addressLine1: formData.addressLine1,
                          addressLine2: formData.addressLine2,
                          city: formData.city,
                          postalCode: formData.zipCode,
                          state: formData.state
                        }
                      }
                    });

                    await updateUserProfile({
                      profile: {
                        dob: formData.dob,
                        firstName: formData.firstName,
                        lastName: formData.lastName
                      }
                    });

                    if (!qBenefitsService.data?.enabled) {
                      await updateBenefitsService({
                        enabled: true,
                        userId: memberId
                      });

                      openModal(BENEFITS_PENDING_MODAL);
                      await updateBenefitsSetupActivity(memberId);

                      setStore({ productActivationAfterOnboarding: false });
                    }

                    goBackOrToParent(history);
                  }}
                  defaultValues={{
                    firstName: user.profile.firstName || "",
                    lastName: user.profile.lastName || "",
                    email: user.email,
                    dob: user.profile.dob,
                    addressLine1: member.profile.address?.addressLine1 || "",
                    addressLine2: member.profile.address?.addressLine2 || "",
                    city: member.profile.address?.city || "",
                    state: member.profile.address?.state || "",
                    zipCode: member.profile.address?.postalCode || ""
                  }}
                  validationSchema={Yup.object().shape({
                    firstName: validatorFirstName.required("Required"),
                    lastName: validatorLastName.required("Required"),
                    email: validatorEmail.required("Email is required"),
                    addressLine1: validatorAddressLine1,
                    addressLine2: validatorAddressLine2,
                    dob: Yup.string().required("Date of birth is required"),
                    city: Yup.string().required("City is required"),
                    state: Yup.string().required("Required"),
                    zipCode: validatorZipCode
                  })}
                >
                  <WSFormOld.Field
                    mt="XL"
                    name="firstName"
                    component={WSTextInput}
                    label="First name"
                  />
                  <WSFormOld.Field
                    mt="XL"
                    name="lastName"
                    component={WSTextInput}
                    label="Last name"
                  />

                  <WSFormOld.Field
                    mt="XL"
                    name="dob"
                    component={WSInputDateOld}
                    label="Date of birth"
                  />

                  <WSFormOld.Field
                    mt="XL"
                    name="email"
                    component={WSTextInput}
                    label="Email address"
                  />

                  <WSFormOld.Field
                    mt="XL"
                    name="addressLine1"
                    component={WSTextInput}
                    label="Address line 1"
                  />

                  <WSFormOld.Field
                    mt="XL"
                    name="addressLine2"
                    component={WSTextInput}
                    label="Address line 2 (optional)"
                  />

                  <WSGrid>
                    <WSGrid.Item span={{ m: "6" }}>
                      <WSFormOld.Field
                        mt="XL"
                        name="city"
                        component={WSTextInput}
                        label="City"
                      />
                    </WSGrid.Item>

                    <WSGrid.Item span={{ s: "6", m: "3" }}>
                      <WSFormOld.Field
                        mt="XL"
                        name="state"
                        component={WSSelectOld}
                        componentProps={{
                          options: US_STATES_OPTIONS,
                          placeholder: "Select"
                        }}
                        label="State"
                      />
                    </WSGrid.Item>
                    <WSGrid.Item span={{ s: "6", m: "3" }}>
                      <WSFormOld.Field
                        mt="XL"
                        name="zipCode"
                        component={WSInputMaskOld}
                        componentProps={{
                          mask: "99999"
                        }}
                        label="Zip code"
                      />
                    </WSGrid.Item>
                  </WSGrid>

                  <WSErrorMessage
                    mb="XL"
                    contextKey="ActivateBenefit"
                    error={
                      updateMemberProfileMeta.error ||
                      updateUserProfileMeta.error ||
                      updateBenefitsServiceMeta.error
                    }
                  />

                  <WSFlexBox.CenterX mt="XL">
                    <WSButton.Primary
                      loading={
                        updateMemberProfileMeta.isLoading ||
                        updateUserProfileMeta.isLoading ||
                        updateBenefitsServiceMeta.isLoading
                      }
                      type="submit"
                    >
                      Get benefit
                    </WSButton.Primary>
                  </WSFlexBox.CenterX>
                </WSFormOld>
              </WSCentered>
            );
          }}
        </WSQueries>
      </WSContainer>
    </WSLayout>
  );
};
