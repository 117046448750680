import {
  WSControl,
  WSElement,
  WSForm,
  WSInfoBox,
  WSList,
  WSMarginProps,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";

export interface FormW9ConsentData {
  w9Consent: boolean;
  shareW9: boolean;
}

interface FormW9ConsentProps extends WSMarginProps {
  payerNames: string[];
}

export const getValidationSchemaFormW9 = (payerNames: string[]) => {
  return Yup.object().shape({
    w9Consent: Yup.bool()
      .required()
      .oneOf([true], "You must certify the information is accurate"),
    shareW9:
      payerNames.length > 0
        ? Yup.bool()
            .required()
            .oneOf(
              [true],
              "You must authorize Wingspan to share the tax information with your payer(s)"
            )
        : Yup.boolean()
  });
};

export const FormW9Consent: React.FC<FormW9ConsentProps> = ({ payerNames }) => {
  return (
    <WSPanel>
      <WSList gap="2XL">
        <WSElement>
          <WSText.Paragraph weight="medium" color="gray600" mb="M">
            Certify your taxpayer information (W-9) is accurate
          </WSText.Paragraph>
          <WSText.ParagraphSm weight="book" color="gray600">
            By using Wingspan’s services you agree that the information provided
            on the Form W-9 is accurate and that you certify the provided
            information as follows:
          </WSText.ParagraphSm>
        </WSElement>

        <WSInfoBox>
          <p>By submitting, you certify, under penalties of perjury, that:</p>
          <ol>
            <li>
              The number shown on this form is my correct taxpayer
              identification number (or I am waiting for a number to be issued
              to me); and
            </li>
            <li>
              I am not subject to backup withholding because: (a) I am exempt
              from backup withholding, or (b) I have not been notified by the
              Internal Revenue Service (IRS) that I am subject to backup
              withholding as a result of a failure to report all interest or
              dividends, or (c) the IRS has notified me that I am no longer
              subject to backup withholding; and
            </li>
            <li>
              I am a U.S. citizen or other U.S. person (defined below); and
            </li>
            <li>
              No FATCA code applies as this form relates to an account
              maintained in the United States.
            </li>
          </ol>
        </WSInfoBox>

        <WSList gap="M">
          <WSForm.Field
            name="w9Consent"
            component={WSControl}
            componentProps={{
              type: "checkbox",
              label:
                "By providing the information on this form, I hereby certify, to the best of my knowledge, that the information provided is complete and correct.",
              size: "S"
            }}
          />

          {payerNames.length > 0 && (
            // TODO: implement edit payers list
            <WSForm.Field
              name="shareW9"
              component={WSControl}
              componentProps={{
                type: "checkbox",
                label: `I authorize Wingspan to share the tax information I've provided with my payer(s) on the Wingspan platform (${payerNames.join(
                  ", "
                )}). I understand this information will be used for tax reporting purposes in accordance with applicable laws and regulations.`,
                size: "S"
              }}
            />
          )}
        </WSList>
      </WSList>
    </WSPanel>
  );
};
