import { Nullable } from "primereact/ts-helpers";
import React, { useMemo } from "react";
import { WSSelect } from "../WSSelect/WSSelect";
import { WSSelectOption, WSSelectProps } from "../WSSelect/types";

export type WSSelectSearchProps = Omit<WSSelectProps, "value" | "onChange"> & {
  value: Nullable<WSSelectOption>;
  onChange: (value: Nullable<WSSelectOption>) => void;
};

export const WSSelectSearch: React.FC<WSSelectSearchProps> = ({
  value,
  onChange,
  options: initialOptions,
  ...props
}) => {
  const inSearchMode = !!props.externalSearchText;

  const options = useMemo(() => {
    if (!value) {
      return initialOptions;
    }

    const isValueInList = initialOptions.some(
      (option) => option.value === value.value
    );

    if (!isValueInList) {
      return [{ ...value, delisted: inSearchMode }, ...initialOptions];
    }

    return initialOptions;
  }, [inSearchMode, initialOptions, value]);

  return (
    <WSSelect
      {...props}
      value={value?.value || null}
      onChange={(newValue) => {
        const selectedOption = initialOptions.find(
          (option) => option.value === newValue
        );
        onChange(selectedOption || null);
      }}
      options={options}
    />
  );
};
