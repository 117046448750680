import { WSTableColumnSortDirection } from "@wingspanhq/fe-component-library";

export type SortDirection = "asc" | "desc" | undefined;

export const mapAndRevertTableSortToParams = (
  sortDirection: SortDirection
): "asc" | "desc" => {
  return sortDirection === "asc" ? "desc" : "asc";
};

export const mapAndRevertParamsSortToTable = (
  sortDirection: SortDirection
): WSTableColumnSortDirection => {
  return sortDirection === "desc"
    ? "ascending"
    : sortDirection === "asc"
    ? "descending"
    : "none";
};
