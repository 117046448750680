import React, { useCallback, useEffect, useState } from "react";

import classNames from "classnames";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";

import styles from "./WSFilters.component.module.scss";

import { WSButton } from "../../core/WSButton/WSButton.component";
import { WSFormOld } from "../../core/WSForm/WSForm.component";
import { WSIcon } from "../../core/WSIcon/WSIcon.component";
import { WSTextInput } from "../../core/WSInput/WSTextInput.component";
import { WSText } from "../../core/WSText/WSText.component";
import { useIsDesktop } from "../../layout";
import { WSFlexBox } from "../../layout/WSFlexBox/WSFlexBox.component";
import { WSGrid, WSGridItemProps } from "../../layout/WSGrid/WSGrid.component";
import { WSModal } from "../WSModal/WSModal.component";

import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import reduce from "lodash/reduce";

export type FiltersOld = {
  [key: string]: any;
};

export interface WSFiltersOldProps extends WSElementProps {
  onFilter?(filters: FiltersOld): void;
  onSearch?(query: string): void;
  getCount?(defaultFilters: FiltersOld, filters: FiltersOld): number;
  searchGridItemProps?: WSGridItemProps;
  filtersGridItemProps?: WSGridItemProps;
  clearGridItemProps?: WSGridItemProps;
  defaultFilters?: FiltersOld;
  filters?: FiltersOld;
  searchQuery?: string;
  showClearButton?: boolean;
}

const EMPTY_SEARCH_QUERY = "";

export const WSFiltersOld: React.FC<WSFiltersOldProps> = ({
  children,
  defaultFilters,
  filters,
  searchQuery,
  getCount = (defaultValues, currentValues) =>
    reduce<FiltersOld, any>(
      currentValues,
      (result, value, key) =>
        isEqual(value, defaultValues[key]) ? result : result.concat(key),
      []
    ).length,
  onSearch,
  onFilter,
  searchGridItemProps,
  filtersGridItemProps,
  clearGridItemProps,
  showClearButton,
  ...elementProps
}) => {
  const isDesktop = useIsDesktop();
  const [search, setSearch] = useState(searchQuery);
  const [visible, setVisible] = useState(false);
  const [formKey, setFormKey] = useState(Date.now());
  const filterCount =
    defaultFilters && filters ? getCount(defaultFilters, filters) : 0;

  const [defaultFiltersInternal] = useState<any>(defaultFilters || filters);

  const [isSearchChanged, setIsSearchChanged] = useState(
    search !== EMPTY_SEARCH_QUERY
  );
  const [isFiltersChanged, setIsFiltersChanged] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((query: string) => {
      onSearch?.(query);
      setIsSearchChanged(query !== EMPTY_SEARCH_QUERY);
    }, 1000),
    [onSearch]
  );

  useEffect(() => {
    setSearch((s) => (s !== searchQuery ? searchQuery : s));
  }, [searchQuery]);

  const clearSearchAndFilters = () => {
    if (search) {
      setSearch("");
      onSearch?.("");
      setIsSearchChanged(false);
    }
    if (filters && defaultFiltersInternal) {
      onFilter?.(defaultFiltersInternal);
      setFormKey(Date.now());
      setIsFiltersChanged(false);
    }
  };

  return (
    <WSElement
      {...elementProps}
      className={classNames(styles.filtersContainer, elementProps.className)}
    >
      <WSGrid>
        {onSearch && (
          <WSGrid.Item span={{ xs: "8", s: "4" }} {...searchGridItemProps}>
            <WSTextInput
              className={styles.search}
              inputClassName={styles.searchInput}
              value={search}
              onChange={(event) => {
                const newSearchQuery = event.target.value;
                setSearch(newSearchQuery);
                handleSearch(newSearchQuery);
              }}
              name="search"
              icon="search"
              placeholder="Search"
            />
          </WSGrid.Item>
        )}
        {onFilter && (
          <WSGrid.Item span={{ xs: "4", s: "2" }} {...filtersGridItemProps}>
            <WSElement
              className={styles.filter}
              onClick={() => setVisible(true)}
            >
              {filterCount ? (
                <WSText.ParagraphSm
                  className={styles.filterCount}
                  color="gray50"
                  mr="XS"
                >
                  {filterCount}
                </WSText.ParagraphSm>
              ) : null}

              <WSText>Filters</WSText>
              <WSIcon
                block
                name="caret-down"
                size="XS"
                className={styles.filterIcon}
              />
            </WSElement>
          </WSGrid.Item>
        )}

        {showClearButton && (isFiltersChanged || isSearchChanged) && (
          <WSGrid.Item span={{ s: "2" }} {...clearGridItemProps}>
            <WSElement className={styles.clear} onClick={clearSearchAndFilters}>
              <WSText weight="medium">Clear</WSText>
              <WSIcon block name="exit" className={styles.clearIcon} />
            </WSElement>
          </WSGrid.Item>
        )}
      </WSGrid>
      {onFilter && (
        <WSElement className={styles.filtersContent}>
          {visible && (
            <WSModal
              size="S"
              onClose={() => {
                setVisible(false);
              }}
            >
              <WSText.Heading1>Filters</WSText.Heading1>
              <WSFormOld
                key={formKey}
                mt="M"
                onSubmit={(newFilters) => {
                  onFilter(newFilters);
                  setIsFiltersChanged(true);
                  setVisible(false);
                }}
                defaultValues={filters}
              >
                {(methods) => (
                  <>
                    {children instanceof Function
                      ? children(methods)
                      : children}
                    <WSFlexBox
                      mt="XL"
                      className={styles.actions}
                      direction={isDesktop ? "row" : "column-reverse"}
                    >
                      <WSButton.Tertiary
                        fullWidth={!isDesktop}
                        mr={isDesktop ? "M" : "NONE"}
                        onClick={() => {
                          onFilter(defaultFiltersInternal);
                          setFormKey(Date.now());
                          setVisible(false);
                        }}
                        type="button"
                      >
                        Clear all
                      </WSButton.Tertiary>
                      <WSButton.Primary
                        fullWidth={!isDesktop}
                        mb={isDesktop ? "NONE" : "M"}
                        type="submit"
                      >
                        Apply filters
                      </WSButton.Primary>
                    </WSFlexBox>
                  </>
                )}
              </WSFormOld>
            </WSModal>
          )}
        </WSElement>
      )}
    </WSElement>
  );
};
