import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { ICardResponse } from "../../../services/api/banking/cards/types";
import { suspendCard } from "../../../services/api/banking/cards/suspendCard";
import { QUERY_CARD } from "../queries/useCardQuery";
import { QUERY_CARDS } from "../queries/useCardsQuery";

export const useSuspendCard = () =>
  useWSMutation<ICardResponse, WSServiceError, string>(suspendCard, {
    dependencies(response, cardId) {
      return [[QUERY_CARD, cardId], QUERY_CARDS];
    }
  });
