import {
  PayoutDestinationType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import {
  AccountStatus,
  AccountType,
  AccountUsage
} from "@wingspanhq/users/dist/lib/interfaces";
import { MutationConfig, queryCache } from "react-query";
import { INVOICE_ACCOUNT_NAME } from "../../../Invoices/utils";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { QUERY_USERS_ACCOUNTS } from "../../../query/users/keys";
import { paymentsService } from "../../../services/payments";
import { usersService } from "../../../services/users";
import { WSServiceError } from "../../../utils/serviceHelper";
import { FormData } from "./types";
import { QUERY_PAYOUT_SETTINGS } from "../../../query/payments/keys";

export const useMutationSubmit = (config?: MutationConfig<void>) => {
  const userId = useUserId();

  return useWSMutation<any, WSServiceError, { data: FormData }>(
    async ({ data }) => {
      const accounts = await usersService.account.create({
        name: INVOICE_ACCOUNT_NAME,
        status: AccountStatus.Active,
        type: AccountType.Depository,
        numbers: {
          account: data.accountNumber,
          routing: data.routing,
          routingSecondary: data.routingSecondary
        },
        usage: AccountUsage.Mixed,
        country: data.country,
        currency: data.currency
      });

      const accountId = Array.isArray(accounts)
        ? accounts[0].accountId
        : accounts.accountId;

      const payoutSettings = await paymentsService.payoutSettings.update(
        userId,
        {
          payoutPreferences: PayoutPreferences.Standard,
          standard: {
            destinationType: PayoutDestinationType.Account,
            destinationId: accountId
          }
        }
      );

      queryCache.setQueryData(QUERY_PAYOUT_SETTINGS, payoutSettings);
    },
    {
      dependencies: [QUERY_USERS_ACCOUNTS, QUERY_PAYOUT_SETTINGS],
      ...config
    }
  );
};
