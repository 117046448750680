import {
  WSButton,
  WSCentered,
  WSContainer,
  WSFlexBox,
  WSIcon,
  WSLayout,
  WSText,
  WSTodoList
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { WSQueries } from "../../../query/WSQuery";
import { useUserId } from "../../../query/hooks/helpers";
import { useMemberProfile } from "../../../query/users/queries";
import { PATH_ONBOARDING_VERIFICAIONS_BANKING } from "../../Onboarding";

export const TaxesNoPaymentOverview: React.FC = () => {
  const history = useHistory();
  const memberId = useUserId();
  const qMemberProfile = useMemberProfile(memberId);

  useBrowserPageTitle("Tax Withholding Overview - Enable Payments", "");

  return (
    <WSQueries
      queries={{ member: qMemberProfile }}
      renderLoader={() => <WSLayout line />}
    >
      {({ member }) => {
        return (
          <WSLayout
            headerLeft={
              <WSIcon
                block
                name="exit"
                size="M"
                color="gray500"
                onClick={() => {
                  history.goBack();
                }}
              />
            }
            line
            footer={
              <WSCentered span={{ s: "8", m: "6", xl: "4" }}>
                <WSButton.Primary
                  fullWidth
                  mt="3XL"
                  onClick={() => {
                    history.push(PATH_ONBOARDING_VERIFICAIONS_BANKING);
                  }}
                >
                  Enable banking
                </WSButton.Primary>
                <WSButton.Tertiary
                  fullWidth
                  mt="M"
                  mb="5XL"
                  onClick={() => history.push("/member/taxes")}
                >
                  Continue to tax withholdings without setup
                </WSButton.Tertiary>
              </WSCentered>
            }
          >
            <WSContainer verticalPadding>
              <WSFlexBox.Center>
                <WSText.Heading1>
                  Enable payments to activate withholdings
                </WSText.Heading1>
              </WSFlexBox.Center>
              <WSFlexBox.Center mt="M" mb="2XL">
                <WSText>
                  Set aside {member.data.profile.withholdings?.tax?.rate}% of
                  each invoice paid through Wingspan.
                </WSText>
              </WSFlexBox.Center>

              <WSCentered span={{ s: "8", m: "6", xl: "4" }}>
                <WSTodoList
                  items={[
                    {
                      mark: "1.",
                      content: (
                        <>
                          <WSText.ParagraphSm weight="medium">
                            Confirm your business information
                          </WSText.ParagraphSm>
                          <WSText.ParagraphSm mt="XS">
                            Ensure accurate contact info for your invoices
                          </WSText.ParagraphSm>
                        </>
                      )
                    },
                    {
                      mark: "2.",
                      content: (
                        <>
                          <WSText.ParagraphSm weight="medium">
                            Connect your direct deposit account
                          </WSText.ParagraphSm>
                          <WSText.ParagraphSm mt="XS">
                            Tell us where to deposit the funds that we’re not
                            setting aside for you
                          </WSText.ParagraphSm>
                        </>
                      )
                    },
                    {
                      mark: "3.",
                      content: (
                        <>
                          <WSText.ParagraphSm weight="medium">
                            We’ll automatically turn on withholdings
                          </WSText.ParagraphSm>
                          <WSText.ParagraphSm mt="XS">
                            Wingspan holds your tax withholdings, withdraw when
                            you need!
                          </WSText.ParagraphSm>
                        </>
                      )
                    }
                  ]}
                />
              </WSCentered>
            </WSContainer>
          </WSLayout>
        );
      }}
    </WSQueries>
  );
};
