import {
  WSButton,
  WSButtons,
  WSText,
  toWSDateString,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import { FrequencyAndScheduleStatus } from "@wingspanhq/payments/dist/interfaces";
import {
  useCancelInvoiceTemplate,
  useDeleteInvoiceTemplate
} from "../../../../query/payments/mutations";
import { IInvoiceTemplateRow } from "../../service";

type Props = {
  invoiceTemplate: IInvoiceTemplateRow;
  onClose: () => void;
};

export const ModalDelete: React.FC<Props> = ({ invoiceTemplate, onClose }) => {
  const [
    deleteInvoiceTemplate,
    deleteInvoiceTemplateMeta
  ] = useDeleteInvoiceTemplate();
  const [
    cancelInvoiceTemplate,
    cancelInvoiceTemplateMeta
  ] = useCancelInvoiceTemplate();

  const isCancel = invoiceTemplate.status === FrequencyAndScheduleStatus.Active;

  const toText = "";

  const dueDate = invoiceTemplate
    ? invoiceTemplate.invoiceData.dueDate
    : new Date();
  const amount = invoiceTemplate.invoiceData.amount;

  return (
    <>
      <WSText.Heading5 mb="M">
        {isCancel ? "Cancel" : "Delete"} invoice
        {toText}
      </WSText.Heading5>
      <WSText mb="M">
        Are you sure you want to {isCancel ? "cancel" : "delete"} your{" "}
        {toWSDateString(dueDate, "monthDayYear")} invoice {toText} for{" "}
        {toWSMoneyString(amount)}?
      </WSText>
      <WSText mb="XL">
        This action can't be undone, once you {isCancel ? "cancel" : "delete"}{" "}
        an invoice you'll have to recreate it.
      </WSText>

      <WSButtons format="modal">
        <WSButton
          destructive
          name="delete"
          onClick={() => {
            if (isCancel) {
              cancelInvoiceTemplate(
                { invoiceTemplateId: invoiceTemplate.invoiceTemplateId },
                { onSuccess: onClose }
              );
            } else {
              deleteInvoiceTemplate(
                { invoiceTemplateId: invoiceTemplate.invoiceTemplateId },
                { onSuccess: onClose }
              );
            }
          }}
          loading={
            cancelInvoiceTemplateMeta.isLoading ||
            deleteInvoiceTemplateMeta.isLoading
          }
        >
          {isCancel ? "Cancel" : "Delete"} invoice
        </WSButton>
        <WSButton.Secondary onClick={onClose}>Keep invoice</WSButton.Secondary>
      </WSButtons>
    </>
  );
};
