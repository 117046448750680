import {
  WSElement,
  WSElementProps,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import cn from "classnames";
import React from "react";
import styles from "./InvoiceStatusTimeline.module.scss";

export enum InvoiceTimelineStatus {
  Open = "Open",
  Sent = "Sent",
  Viewed = "Viewed",
  PreApproved = "PreApproved",
  Approved = "Approved",
  Paid = "Paid",
  Deposited = "Deposited"
}

const statusLabelMap = {
  [InvoiceTimelineStatus.Open]: "Open",
  [InvoiceTimelineStatus.Sent]: "Sent",
  [InvoiceTimelineStatus.Viewed]: "Viewed",
  [InvoiceTimelineStatus.PreApproved]: "Pre-approved",
  [InvoiceTimelineStatus.Approved]: "Approved",
  [InvoiceTimelineStatus.Paid]: "Paid",
  [InvoiceTimelineStatus.Deposited]: "Deposited"
};

type Props = WSElementProps & {
  currentStatus?: keyof typeof InvoiceTimelineStatus;
  statuses: InvoiceTimelineStatus[];
};

export const InvoiceStatusTimeline: React.FC<Props> = ({
  className,
  currentStatus,
  statuses,
  ...elementProps
}) => {
  return (
    <WSElement className={cn(styles.container, className)} {...elementProps}>
      {statuses.map((status, index) => {
        const isActive = currentStatus
          ? statuses.indexOf(currentStatus as InvoiceTimelineStatus) >= index
          : false;
        return (
          <WSElement className={styles.status} key={status}>
            {isActive ? (
              <WSIcon block name="check" color="green500" mb="XS" />
            ) : (
              <WSElement className={styles.dot} mb="XS" />
            )}

            <WSText.ParagraphXs color={isActive ? "green500" : "gray500"}>
              {statusLabelMap[status]}
            </WSText.ParagraphXs>
          </WSElement>
        );
      })}
    </WSElement>
  );
};
