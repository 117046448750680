import { IRequirementDefinitionUpdateRequest } from "@wingspanhq/payments/dist/interfaces";

import { updateRequirement } from "../../../services/requirementDefinitions/updateRequirement";
import { useWSMutation } from "../../helpers";
import {
  QUERY_REQUIREMENT_DEFINITION,
  QUERY_REQUIREMENT_DEFINITIONS_LIST
} from "../keys";

export const useUpdateRequirementDefinition = (id: string) =>
  useWSMutation(
    (payload: IRequirementDefinitionUpdateRequest) =>
      updateRequirement(id, payload),
    {
      dependencies: [
        [QUERY_REQUIREMENT_DEFINITION, id],
        QUERY_REQUIREMENT_DEFINITIONS_LIST
      ]
    }
  );
