import {
  WSMenuItem,
  toWSDateString,
  toWSMoneyString,
  useModalContext,
  useWSModal,
  WSButton,
  WSButtons,
  WSFormOld,
  WSModal,
  WSText,
  WSTextArea
} from "@wingspanhq/fe-component-library";
import {
  FrequencyAndScheduleStatus,
  IInvoice,
  IInvoiceTemplate,
  InvoiceStatus,
  MemberWorkFlowStatus
} from "@wingspanhq/payments/dist/interfaces";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { addNotification } from "../../../components/Notification/Notification";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import {
  useAcceptInvoice,
  useCancelInvoice,
  useCancelInvoiceTemplate,
  useDeleteInvoice,
  useDeleteInvoiceTemplate,
  useDisputeInvoice,
  useDownloadInvoicePdf,
  useMarkInvoiceAsPaid,
  useRetryInvoicePayment,
  useSendInvoice,
  useSendInvoiceReminder
} from "../../../query/payments/mutations";
import {
  getHasInvoiceEditAccess,
  getIsInvoiceCreator,
  getIsInvoiceDisputed
} from "../../../query/payments/selectors";
import { useSetWSStore } from "../../../store";
import { copyText } from "../../../utils/copyText";
import { selectorIsInvoiceDeposited } from "../../selectors/selectorIsInvoiceDeposited";
import {
  calculateInvoiceTempalteDueDate,
  calculateLineItemsTotal
} from "../../utils";
import { calculateAmountAvailableToRefund } from "../../utils/calculateAmountAvailableToRefund";
import { ModalRefund } from "../ModalRefund";

const DELETE_INVOICE_MODAL = "DELETE_INVOICE_MODAL";
const DISPUTE_INVOICE_MODAL = "DISPUTE_INVOICE_MODAL";

export const useGetInvoiceActions = () => {
  const userId = useUserId();
  const history = useHistory();
  const location = useLocation();
  const { openModal } = useModalContext();
  const [sendInvoice] = useSendInvoice();
  const [markInvoiceAsPaid] = useMarkInvoiceAsPaid();
  const [sendInvoiceReminder] = useSendInvoiceReminder();
  const [downloadInvoicePdf] = useDownloadInvoicePdf();
  const [acceptInvoice] = useAcceptInvoice();
  const [retryPayment] = useRetryInvoicePayment();
  const featureFlagsQuery = useFeatureFlags();
  const setStore = useSetWSStore();
  const modalRefund = useWSModal(ModalRefund, { size: "S" });

  return useCallback(
    (invoice: IInvoice) => {
      const menuItems: WSMenuItem[] = [];

      // Edit
      if (
        [
          InvoiceStatus.Draft,
          InvoiceStatus.Pending,
          InvoiceStatus.Open,
          InvoiceStatus.Overdue
        ].includes(invoice.status) &&
        (getHasInvoiceEditAccess(invoice, userId) ||
          featureFlagsQuery.data?.editAllInvoices)
      ) {
        menuItems.push({
          icon: "edit",
          label: "Edit",
          onClick: () => {
            setStore({ invoiceFormBackPath: location.pathname });
            history.push(`/member/invoices/${invoice.invoiceId}/edit`);
          }
        });
      }

      // Duplicate
      menuItems.push({
        icon: "plus-circle",
        label: "Duplicate",
        onClick: () => {
          history.push(`/member/invoices/${invoice.invoiceId}/duplicate`);
        }
      });

      // Delete
      if (
        [InvoiceStatus.Draft].includes(invoice.status) &&
        getHasInvoiceEditAccess(invoice, userId)
      ) {
        menuItems.push({
          icon: "exit",
          label: "Remove",
          onClick: () => {
            openModal(DELETE_INVOICE_MODAL, {
              invoice
            });
          }
        });
      }

      // Cancel
      if (
        [
          InvoiceStatus.Open,
          InvoiceStatus.Overdue,
          InvoiceStatus.Pending
        ].includes(invoice.status) &&
        getHasInvoiceEditAccess(invoice, userId)
      ) {
        menuItems.push({
          icon: "exit",
          label: "Cancel invoice",
          onClick: () => {
            openModal(DELETE_INVOICE_MODAL, {
              invoice
            });
          }
        });
      }

      // Send
      if (
        [InvoiceStatus.Draft].includes(invoice.status) &&
        getHasInvoiceEditAccess(invoice, userId)
      ) {
        menuItems.push({
          icon: "send",
          label: "Send now",
          onClick: () => {
            sendInvoice({ invoiceId: invoice.invoiceId });
          }
        });
      }

      // Mark deposited
      if (
        [
          InvoiceStatus.Open,
          InvoiceStatus.Overdue,
          InvoiceStatus.PaymentInTransit
        ].includes(invoice.status) &&
        getHasInvoiceEditAccess(invoice, userId)
      ) {
        menuItems.push({
          icon: "dollar-circle",
          label: "Mark deposited",
          onClick: () => {
            markInvoiceAsPaid(
              {
                invoiceId: invoice.invoiceId
              },
              {
                onSuccess: () => {
                  addNotification({
                    text: `Invoice #${invoice.invoiceNumber} is marked as deposited`
                  });
                }
              }
            );
          }
        });
      }

      // Download PDF
      if (
        [
          InvoiceStatus.Open,
          InvoiceStatus.Overdue,
          InvoiceStatus.Paid
        ].includes(invoice.status)
      ) {
        menuItems.push({
          icon: "download",
          label: "Download PDF",
          onClick: () => {
            downloadInvoicePdf({
              invoiceId: invoice.invoiceId,
              status: invoice.status
            });
          }
        });
      }

      // Send reminder
      if (
        [InvoiceStatus.Open, InvoiceStatus.Overdue].includes(invoice.status)
      ) {
        menuItems.push({
          icon: "calendar",
          label: "Send reminder",
          onClick: () => {
            sendInvoiceReminder({ invoice: invoice });
          }
        });
      }

      // Copy link
      if (
        invoice.attachments?.invoiceLink &&
        [
          InvoiceStatus.Open,
          InvoiceStatus.Overdue,
          InvoiceStatus.Paid,
          InvoiceStatus.PaymentInTransit
        ].includes(invoice.status)
      ) {
        menuItems.push({
          icon: "download",
          label: "Copy invoice link",
          onClick: () => {
            if (invoice.attachments?.invoiceLink) {
              copyText(
                invoice.attachments.invoiceLink,
                "Invoice link copied to clipboard."
              );
            }
          }
        });
      }

      // Accept
      if (
        [
          InvoiceStatus.Open,
          InvoiceStatus.Overdue,
          InvoiceStatus.Pending
        ].includes(invoice.status) &&
        !getIsInvoiceCreator(invoice, userId) &&
        invoice.member.workflowStatus !== MemberWorkFlowStatus.Accepted
      ) {
        menuItems.push({
          icon: "check",
          label: "Accept invoice",
          onClick: () => {
            acceptInvoice({ invoiceId: invoice.invoiceId });
          }
        });
      }

      // Dispute
      if (
        [
          InvoiceStatus.Open,
          InvoiceStatus.Overdue,
          InvoiceStatus.Pending
        ].includes(invoice.status) &&
        !getIsInvoiceCreator(invoice, userId) &&
        !getIsInvoiceDisputed(invoice)
      ) {
        menuItems.push({
          icon: "alert-circle",
          label: "Dispute invoice",
          onClick: () => {
            openModal(DISPUTE_INVOICE_MODAL, {
              invoice
            });
          }
        });
      }

      // Refund
      if (
        featureFlagsQuery.data?.refundButton &&
        selectorIsInvoiceDeposited(invoice) &&
        calculateAmountAvailableToRefund(invoice) > 0
      ) {
        menuItems.push({
          icon: "dollar-circle",
          label: "Refund",
          onClick: () => {
            modalRefund.open({ invoice });
          }
        });
      }

      // Refund
      if (
        [InvoiceStatus.Open, InvoiceStatus.Overdue].includes(invoice.status) &&
        !!invoice.invoiceTemplateId
      ) {
        menuItems.push({
          icon: "dollar-circle",
          label: "Retry payment",
          onClick: () => {
            retryPayment({ invoiceId: invoice.invoiceId });
          }
        });
      }

      return menuItems;
    },
    [location.pathname]
  );
};

export const useGetInvoiceTemplateActions = () => {
  const history = useHistory();
  const location = useLocation();
  const { openModal } = useModalContext();
  const setStore = useSetWSStore();

  return useCallback(
    (invoiceTemplate: IInvoiceTemplate) => {
      const menuItems: WSMenuItem[] = [];

      if (
        [
          FrequencyAndScheduleStatus.Draft,
          FrequencyAndScheduleStatus.Active
        ].includes(invoiceTemplate.status)
      ) {
        menuItems.push({
          icon: "edit",
          label: invoiceTemplate.frequency ? "Edit series" : "Edit invoice",
          onClick: () => {
            setStore({ invoiceFormBackPath: location.pathname });
            history.push(
              `/member/invoices/template/${invoiceTemplate.invoiceTemplateId}/edit`
            );
          }
        });
      }

      if (
        [
          FrequencyAndScheduleStatus.Draft,
          FrequencyAndScheduleStatus.Active
        ].includes(invoiceTemplate.status)
      ) {
        menuItems.push({
          icon: "plus-circle",
          label: "Duplicate",
          onClick: () => {
            history.push(
              `/member/invoices/template/${invoiceTemplate.invoiceTemplateId}/duplicate`
            );
          }
        });
      }

      if ([FrequencyAndScheduleStatus.Draft].includes(invoiceTemplate.status)) {
        menuItems.push({
          icon: "exit",
          label: "Remove",
          onClick: () => {
            openModal(DELETE_INVOICE_MODAL, {
              invoiceTemplate
            });
          }
        });
      }

      if (
        [FrequencyAndScheduleStatus.Active].includes(invoiceTemplate.status)
      ) {
        menuItems.push({
          icon: "exit",
          label: invoiceTemplate.frequency ? "Cancel series" : "Cancel invoice",
          onClick: () => {
            openModal(DELETE_INVOICE_MODAL, {
              invoiceTemplate
            });
          }
        });
      }

      return menuItems;
    },
    [location.pathname]
  );
};

export const InvoiceActions: React.FC = () => {
  const { closeModal } = useModalContext();
  const history = useHistory();
  const [deleteInvoice, deleteInvoiceMeta] = useDeleteInvoice();
  const [cancelInvoice, cancelInvoiceMeta] = useCancelInvoice();
  const [
    deleteInvoiceTemplate,
    deleteInvoiceTemplateMeta
  ] = useDeleteInvoiceTemplate();
  const [
    cancelInvoiceTemplate,
    cancelInvoiceTemplateMeta
  ] = useCancelInvoiceTemplate();
  const [disputeInvoice, disputeInvoiceMeta] = useDisputeInvoice();

  return (
    <>
      <WSModal name={DELETE_INVOICE_MODAL}>
        {({
          invoice,
          invoiceTemplate,
          listName
        }: {
          invoice?: IInvoice;
          invoiceTemplate?: IInvoiceTemplate;
          listName?: "paid" | "list";
        }) => {
          const isCancel = invoice
            ? [InvoiceStatus.Open, InvoiceStatus.Overdue].includes(
                invoice.status
              )
            : invoiceTemplate
            ? invoiceTemplate.status === FrequencyAndScheduleStatus.Active
            : false;

          const isSeries = invoiceTemplate
            ? !!invoiceTemplate.frequency
            : false;

          const toText = "";

          const onSuccess = () => {
            closeModal(DELETE_INVOICE_MODAL);
            history.push(`/member/invoices${listName ? `/${listName}` : ""}`);
          };

          const dueDate = invoice
            ? invoice.dueDate
            : invoiceTemplate
            ? calculateInvoiceTempalteDueDate(invoiceTemplate)
            : new Date();

          const amount = invoice
            ? calculateLineItemsTotal(invoice.lineItems)
            : invoiceTemplate
            ? calculateLineItemsTotal(invoiceTemplate.invoiceData.lineItems)
            : 0;

          return (
            <>
              <WSText.Heading5 mb="M">
                {isCancel ? "Cancel" : "Delete"}{" "}
                {isSeries ? "series" : "invoice"}
                {toText}
              </WSText.Heading5>
              <WSText mb="M">
                Are you sure you want to {isCancel ? "cancel" : "delete"} your{" "}
                {toWSDateString(dueDate, "monthDayYear")}{" "}
                {isSeries ? "invoices series" : "invoice"}
                {toText} for {toWSMoneyString(amount)}?
              </WSText>
              <WSText mb="XL">
                This action can't be undone, once you{" "}
                {isCancel ? "cancel" : "delete"}{" "}
                {isSeries ? "a series" : "an invoice"} you'll have to recreate
                it.
              </WSText>

              <WSButtons format="modal">
                <WSButton
                  destructive
                  name="delete"
                  onClick={() => {
                    if (invoice) {
                      if (isCancel) {
                        cancelInvoice(
                          { invoiceId: invoice.invoiceId },
                          { onSuccess }
                        );
                      } else {
                        deleteInvoice(
                          { invoiceId: invoice.invoiceId },
                          { onSuccess }
                        );
                      }
                    }
                    if (invoiceTemplate) {
                      if (isCancel) {
                        cancelInvoiceTemplate(
                          {
                            invoiceTemplateId: invoiceTemplate.invoiceTemplateId
                          },
                          { onSuccess }
                        );
                      } else {
                        deleteInvoiceTemplate(
                          {
                            invoiceTemplateId: invoiceTemplate.invoiceTemplateId
                          },
                          { onSuccess }
                        );
                      }
                    }
                  }}
                  loading={
                    cancelInvoiceMeta.isLoading ||
                    deleteInvoiceMeta.isLoading ||
                    cancelInvoiceTemplateMeta.isLoading ||
                    deleteInvoiceTemplateMeta.isLoading
                  }
                >
                  {isCancel ? "Cancel" : "Delete"}{" "}
                  {isSeries ? "series" : "invoice"}
                </WSButton>
                <WSButton.Secondary
                  onClick={() => {
                    closeModal(DELETE_INVOICE_MODAL);
                  }}
                >
                  Keep {isSeries ? "series" : "invoice"}
                </WSButton.Secondary>
              </WSButtons>
            </>
          );
        }}
      </WSModal>

      <WSModal
        name={DISPUTE_INVOICE_MODAL}
        size="S"
        onClose={() => {
          disputeInvoiceMeta.reset();
        }}
        title="Dispute Invoice"
      >
        {({ invoice }: { invoice: IInvoice }) => {
          return (
            <>
              <WSFormOld<{ disputeComment: string }>
                onSubmit={({ disputeComment }) => {
                  disputeInvoice(
                    {
                      invoiceId: invoice.invoiceId,
                      disputeComment
                    },
                    {
                      onSuccess: () => {
                        closeModal(DISPUTE_INVOICE_MODAL);
                      }
                    }
                  );
                }}
              >
                <WSText mb="XL">
                  Indicate the reason for the dispute in the field below
                </WSText>

                <WSFormOld.Field
                  mb="XL"
                  name="disputeComment"
                  component={WSTextArea}
                />

                <WSErrorMessage
                  mb="XL"
                  error={disputeInvoiceMeta.error}
                  contextKey="DisputeInvoice"
                />

                <WSButtons format="modal">
                  <WSButton
                    name="disputeInvoice"
                    loading={disputeInvoiceMeta.isLoading}
                  >
                    Dispute invoice
                  </WSButton>
                  <WSButton.Secondary
                    name="disputeInvoice"
                    onClick={() => {
                      closeModal(DISPUTE_INVOICE_MODAL);
                    }}
                    type="button"
                  >
                    Cancel
                  </WSButton.Secondary>
                </WSButtons>
              </WSFormOld>
            </>
          );
        }}
      </WSModal>
    </>
  );
};
