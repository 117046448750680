import { ca } from "date-fns/locale";
import { ErrorCodeUserMessageMap } from "../types";

export const getImportErrorUserMessageByErrorCode = (
  errorCode: string,
  userMessage: string = "Something went wrong while importing your data. Please try again."
) => {
  switch (errorCode) {
    case "FF-UA-00":
    case "FF-UA-01":
    case "FF-UA-02":
    case "FF-UA-03":
    case "FF-UA-04":
    case "FF-UA-05":
      return "There was an issue establishing a secure import session.";
    case "FF-RE-00":
      return "A network request failed to complete. Please try again.";
    case "FF-IE-00":
      return "There was a configuration error preventing the data importer from loading.";
    case "FF-CT-01":
      return "It is taking too long to import the file. Please ensure you have a stable connection and try again.";
    case "FF-GE-00":
      return "An unknown issue has occurred";
    default:
      return userMessage;
  }
};
