import {
  WSButton,
  WSElement,
  WSGrid,
  WSMessageBox,
  WSPage,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { WSQueries } from "../../../query/WSQuery";
import { usePayeeTaxForms } from "../../../query/taxForm/queries/usePayeeTaxForms";
import { useBrowserPageTitle } from "../../../shared/hooks/useBrowserPageTitle";
import { openIntercom } from "../../../shared/utils/intercom";
import { FAQ1099 } from "../../1099NECFiling/components/FAQ1099";
import { CURRENT_YEAR } from "../../1099NECFiling/constants/currentYear";
import { TaxFormPreview } from "../components/TaxForm";
import { useUserId } from "../../../query/hooks/helpers";
import { useMemberProfile } from "../../../query/users/queries";

const headerContent = (
  <>
    <WSText.Heading1 mr="M" inline>
      Tax Documents
    </WSText.Heading1>
    <WSText.Heading1 inline ml="2XL" color="gray500">
      {CURRENT_YEAR}
    </WSText.Heading1>
  </>
);
export const RouteIndex: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  useBrowserPageTitle(`${CURRENT_YEAR} Tax Documents`);

  const userId = useUserId();
  const queryMember = useMemberProfile(userId);
  const queryPayerTaxForms = usePayeeTaxForms(CURRENT_YEAR);

  return (
    <WSPage headerContent={headerContent}>
      <WSGrid>
        <WSGrid.Item span={{ m: "8" }}>
          <WSQueries queries={{ queryPayerTaxForms, queryMember }}>
            {({ queryPayerTaxFormsData: taxForms, queryMemberData: member }) =>
              taxForms.length > 0 ? (
                <>
                  {taxForms.map(taxForm => (
                    <TaxFormPreview
                      key={taxForm.taxFormId}
                      mb="XL"
                      taxForm={taxForm}
                      member={member}
                    />
                  ))}
                </>
              ) : (
                <WSPanel>
                  <WSMessageBox.Warning
                    title="No Form 1099-NEC exists"
                    icon="alert-circle"
                  >
                    <WSText.ParagraphSm>
                      If you do not expect a Form 1099-NEC for 2023, then you’re
                      all set.
                    </WSText.ParagraphSm>

                    <WSText.ParagraphSm mt="M" mb="XL">
                      If you were expecting a Form 1099-NEC for 2023, here are a
                      few things you can do:
                    </WSText.ParagraphSm>
                    <ul style={{ listStyleType: "decimal" }}>
                      <WSElement as="li" mb="M">
                        <WSText.ParagraphSm weight="medium">
                          Check your sign-up or sign-in email
                        </WSText.ParagraphSm>
                        <WSText.ParagraphSm mt="XS">
                          A Form 1099-NEC is issued to the email that the payer
                          has on file. Confirm the email and try signing up or
                          signing in again.
                        </WSText.ParagraphSm>
                      </WSElement>
                      <li>
                        <WSText.ParagraphSm weight="medium">
                          Need more help?
                        </WSText.ParagraphSm>
                        <WSText.ParagraphSm mt="XS">
                          If you’ve confirmed your email and you’re still
                          getting this message, please contact Wingspan support
                          below and have the payer name(s) ready so we can
                          assist you.
                        </WSText.ParagraphSm>
                      </li>
                    </ul>
                  </WSMessageBox.Warning>

                  <WSButton.Primary mt="XL" onClick={openIntercom}>
                    Contact Wingspan support
                  </WSButton.Primary>
                </WSPanel>
              )
            }
          </WSQueries>
        </WSGrid.Item>
        <WSGrid.Item span={{ m: "4" }}>
          <WSElement>
            <FAQ1099 mb="2XL" />
            <WSElement>
              <WSText weight="medium">Need additional support?</WSText>
              <WSButton.Link onClick={openIntercom}>
                Chat with a live representative
              </WSButton.Link>
            </WSElement>
          </WSElement>
        </WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
