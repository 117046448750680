import { WSQueries } from "../../../../query/WSQuery";
import { useEngagementListQuery } from "../../../../query/engagements/queries/useEngagementListQuery";
import {
  WSEmptyState,
  WSFilters,
  WSInfiniteScroll
} from "@wingspanhq/fe-component-library";
import { EngagementRowsTable } from "../../components/EngagementRowsTable";
import { useBrowserPageTitle } from "../../../../shared/hooks/useBrowserPageTitle";
import { useCreateEngagementModal } from "../../components/CreateEngagementModal";
import { selectorInfinityEngagements } from "../../selectors/selectorInfinityEngagements";

export const RouteEngagements: React.FC = () => {
  useBrowserPageTitle("Engagements");
  const queryEngagementList = useEngagementListQuery({
    filter: {
      isDefault: false
    }
  });
  const modalCreateEngagement = useCreateEngagementModal();

  return (
    <WSQueries queries={{ queryEngagementList }}>
      {({ queryEngagementListData }) => {
        const { list, listSize } = selectorInfinityEngagements(
          queryEngagementListData
        );

        return (
          <>
            <WSFilters
              my="L"
              values={{}}
              onFilter={newValues => {}}
              primaryFilters={
                [
                  // {
                  //   title: "Type",
                  //   name: "type",
                  //   type: "checkbox",
                  //   options: [
                  //     {
                  //       label: "Independent contractor (IC)",
                  //       name: EngagementType.ContractorVendor
                  //     },
                  //     {
                  //       label: "Agency of record (AOR)",
                  //       name: EngagementType.AgencyOfRecord
                  //     },
                  //     {
                  //       label: "Employer of record (EOR)",
                  //       name: EngagementType.EmployeeOfRecord
                  //     }
                  //   ]
                  // },
                ]
              }
              info={{
                count: listSize
              }}
              quickFilters={[]}
            />
            {list.length === 0 ? (
              <WSEmptyState
                py="3XL"
                hasBorder
                fullWidth
                type="documents"
                title="No engagements yet"
                bgColor="gray"
                description="Once an engagement is added, they will appear here."
                buttons={[
                  {
                    label: "Add engagement",
                    kind: "Secondary",
                    onClick: async () => {
                      await modalCreateEngagement.open();
                    }
                  }
                ]}
              />
            ) : (
              <WSInfiniteScroll
                mt="M"
                onLoad={() => {
                  queryEngagementList.fetchMore();
                }}
                loadMore={list.length > 0}
                endOfList={!queryEngagementList.canFetchMore}
                loading={!!queryEngagementList.isFetchingMore}
              >
                <EngagementRowsTable engagements={list} />
              </WSInfiniteScroll>
            )}
          </>
        );
      }}
    </WSQueries>
  );
};
