import { QueryConfig } from "react-query";
import { useWSMutation } from "../../helpers";
import { getAllEntries, WSServiceError } from "../../../utils/serviceHelper";

import { cleanCSVData } from "../../../shared/utils/csv";
import isEmpty from "lodash/isEmpty";
import {
  CustomFieldResourceType,
  IAdditionalData,
  WSDataTypes
} from "@wingspanhq/payments/dist/interfaces";
import {
  customFieldsService,
  mapCustomFieldListFilters
} from "../../../services/customFields";
import { toWSDateString } from "@wingspanhq/fe-component-library";
import { downloadCSV } from "../../../modules/Reports/utils/sheetjs/downloadCSV";
import {
  getPayeeRows,
  IPayeeRow,
  PayeeRowsQuery
} from "../../../services/search";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import {
  PayeeTaxDocumentSharePermission,
  PaymentRequirementStrategy
} from "@wingspanhq/payments/dist/interfaces/payerPayee";

export type ContractorDataItem = {
  payee: IPayeeRow;
  additionalData: IAdditionalData[];
};

const mapCollaboratorToItemData = (item: ContractorDataItem) => {
  const { payee, additionalData } = item;
  const names = wsName({
    user: payee.user,
    member: payee.member,
    payerOwnedData: payee.payerOwnedData
  });

  if (!payee) {
    return {};
  }

  const customFields = payee.payerOwnedData.customFields || {};

  return {
    Name: names.getResolvedName(),
    "First Name": names.firstName,
    "Last Name": names.lastName,
    // "Preferred Name": names.preferredName,
    "Company Name": names.companyName,
    // "Company DBA": names.companyName,
    "Legal Business Name": names.legalBusinessName,
    "External Contractor ID": names.externalId,
    Email: names.email,
    Location: [
      payee.member.profile?.address?.country,
      payee.member.profile?.address?.state,
      payee.member.profile?.address?.city
    ]
      .filter(Boolean)
      .join(", "),
    Status: payee.searchableStatus,
    Autopay:
      payee.payerOwnedData.autoPayStrategy === PaymentRequirementStrategy.All
        ? "Yes"
        : "No",
    // "Bulk import batch": payee.payerOwnedData,//todo
    "Created At": payee.createdAt,
    "Updated At": payee.updatedAt,
    "Tax Info":
      payee.payeeOwnedData.shareTaxDocument ===
      PayeeTaxDocumentSharePermission.Allow
        ? "Yes"
        : "No",
    // TODO(engagements): change to engagement names
    // "Collaborator Group IDs": groups
    //   ?.map(g => g.collaboratorGroupId)
    //   .join(", "),
    // "Collaborator Groups": groups?.map(g => g.name).join(", "),
    "Contractor URL": `${window.location.host}/member/payees/${payee.payeeId}`,
    // "Company Structure": payee.member?.profile?.company?.,
    // "W-9 Shared"
    ...(!additionalData || isEmpty(additionalData)
      ? {}
      : additionalData.reduce((acc, data) => {
          return {
            ...acc,
            [`Custom Field ${data.name}`]:
              data.type === WSDataTypes.Boolean
                ? customFields[data.key] === "true"
                  ? "Yes"
                  : "No"
                : customFields[data.key] || "-"
          };
        }, {})),
    "Contractor ID": payee.payeeId
  };
};

export const mapCollaboratorsToCSV = (data: Array<ContractorDataItem>) => {
  let cleanResult = [];

  const dirtyResult = data
    .map(d => {
      try {
        return mapCollaboratorToItemData(d);
      } catch (error) {
        console.error(error);
      }
      return {};
    })
    .filter(d => !isEmpty(d));

  cleanResult = dirtyResult.map(d => cleanCSVData(d, dirtyResult)) as Array<
    any
  >;

  return cleanResult;
};

export const usePayeesDownloadCSV = (
  config?: QueryConfig<any, WSServiceError>
) => {
  return useWSMutation(
    async (params: PayeeRowsQuery & { fileName: string }) => {
      const { sort, filter, fileName, page } = params || {};
      let data: ContractorDataItem[] = [];

      const additionalData = await customFieldsService.customField.list({
        filter: mapCustomFieldListFilters({
          resourceType: [CustomFieldResourceType.Collaborator]
        })
      });

      const payees = await getAllEntries(getPayeeRows, filter, sort);

      data = payees.map(payee => {
        return {
          payee,
          additionalData
        };
      });

      const csvData = mapCollaboratorsToCSV(data);

      await downloadCSV(
        csvData,
        `${fileName}(${toWSDateString(new Date())}).csv`
      );
    },
    {
      ...config
    }
  );
};
