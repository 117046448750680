import { IMemberClient } from "@wingspanhq/payments/dist/interfaces";
import { RequirementStatus } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";

export const getHasEligibilityRequirements = (memberClients: IMemberClient[]) =>
  memberClients.some(
    memberClient => !!memberClient.eligibilityRequirements?.length
  );

export const getHasPendingEligibilityRequirements = (
  memberClients: IMemberClient[]
) =>
  memberClients.some(memberClient =>
    memberClient.eligibilityRequirements?.some(
      eligibilityRequirement =>
        eligibilityRequirement.status !== RequirementStatus.Complete
    )
  );
