import {
  WSIconList,
  WSImage,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import stripePartners from "../assets/images/stripe-partners.png";
import { WS_LINKS } from "../types/wsLinks";

export interface PaymentsSecurityScreenProps extends RouteComponentProps {}

export const PaymentsSecurityScreen: React.FC<PaymentsSecurityScreenProps> = ({
  history
}) => (
  <WSPage
    title="Payments security"
    breadcrumb={{
      icon: "arrow-left",
      label: "Back",
      onClick: () => {
        history.goBack();
      }
    }}
  >
    <WSText mb="XL">
      Stripe is Wingspan’s trusted partner for payment processing.{" "}
      <a href={WS_LINKS.stripe} target="_blank" rel="noopener noreferrer">
        Learn more on Stripe.
      </a>
    </WSText>
    <WSIconList
      mb="2XL"
      iconProps={{
        name: "user-account"
      }}
      items={[
        `Highest security standard. Stripe has been audited by a
        PCI-certified auditor and is certified to PCI Service Provider Level
        1. This is the most stringent level of certification available in the
        payments industry.`,
        `Fraud protection. Stripe’s fraud prevention toolset, RADAR, is
        built directly into the payment flow and contains powerful machine
        learning algorithms. This process detects patterns across payments
        processed with Stripe, assessing the risk level of each.`
      ]}
    />
    <WSText.Heading5 mb="2XL">Other stripe partners</WSText.Heading5>
    <WSImage src={stripePartners} placeholder={<>Stripe partners logos</>} />
  </WSPage>
);
