import { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { RouteStatementDashboard } from "./routes/RouteDashboard";
import { RouteStatementArchived } from "./routes/RouteArchived";
import { StatementPeriod } from "./utils/types";

export const StatementIndex: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Route
        exact
        path={`/member/statements/${StatementPeriod.Archived}`}
        component={RouteStatementArchived}
      />
      <Route path="/member/statements" component={RouteStatementDashboard} />
    </Switch>
  );
};
