import {
  useModalContext,
  WSButton,
  WSButtons,
  WSIcon,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

export const BOOKKEEPING_UNABLE_TO_SYNC = "BOOKKEEPING_UNABLE_TO_SYNC";

export const UnableToSync: React.FC = () => {
  const { closeModal } = useModalContext();

  return (
    <WSModal name={BOOKKEEPING_UNABLE_TO_SYNC} size="XS">
      {({ institution }) => (
        <>
          <WSText.Heading5 mb="M">
            <WSIcon block name="alert-circle" size="M" mr="M" color="red500" />
            Unable to sync with {institution}
          </WSText.Heading5>
          <WSText mb="M">
            Unfortunately {institution} does not support external integration.
            Please try a different method.
          </WSText>
          <WSButtons mt="2XL" format="modal">
            <WSButton.Primary
              data-testid="tryOtherImportMethod"
              onClick={() => {
                closeModal(BOOKKEEPING_UNABLE_TO_SYNC);
              }}
            >
              Try other import method
            </WSButton.Primary>
          </WSButtons>
        </>
      )}
    </WSModal>
  );
};
