import isEmpty from "lodash/isEmpty";
import { cleanCSVData } from "../../../shared/utils/csv";
import { ITaxFormSearchRow } from "../../../services/search/taxForm/getTaxFormRows";

const mapITaxFormSearchRowItems = (tableItem: ITaxFormSearchRow) => {
  if (!tableItem) {
    return {};
  }

  const {
    data,
    // eventHistory,
    memberEmail,
    memberExternalId,
    payee,
    // payer,
    status,
    taxFormId,
    tinVerification
  } = tableItem;

  /*
    Only thing missing is profile email

    Profile Email (w9Info.email)
  */

  return {
    "Member Email": memberEmail,
    "External ID": memberExternalId,
    "Delivery Method": data.deliveryMethod,
    Status: status,
    "Tax Form ID": taxFormId,
    "Address Line 1": data.w9Info?.addressLine1,
    "Address Line 2": data.w9Info?.addressLine2,
    City: data.w9Info?.city,
    "Company Structure": data.w9Info?.companyStructure,
    "Disregarded Entity Name": data.w9Info?.disregardedEntityName,
    "First Name": payee.user.profile?.firstName,
    "Last Name": payee.user.profile?.lastName,
    "Legal Business Name": data.w9Info?.legalBusinessName,
    "EIN Last Four": data.w9Info?.einLastFour,
    "SSN Last Four": data.w9Info?.ssnLastFour,
    "EIN Verification Status": tinVerification?.ein?.status,
    "SSN Verification Status": tinVerification?.ssn?.status,
    "Platform Income": data.totalAmount,
    Adjustments: data.adjustments,
    "Payment Processing Fees": data.paymentProcessingFees,
    "Profile First Name": data.w9Info?.firstName,
    "Profile Last Name": data.w9Info?.lastName
  };
};

export const mapITaxFormSearchRowItemsToCSV = (
  data: Array<ITaxFormSearchRow>
) => {
  let cleanResult = [];

  const dirtyResult = data
    .map(d => {
      try {
        return mapITaxFormSearchRowItems(d);
      } catch (error) {
        console.error(error);
      }
      return {};
    })
    .filter(d => !isEmpty(d));

  cleanResult = dirtyResult.map(d => cleanCSVData(d, dirtyResult)) as Array<
    any
  >;

  return cleanResult;
};
