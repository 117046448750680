import React from "react";
import {
  WSElement,
  WSElementColorsType,
  WSElementProps,
  WSElementSpacingsType,
  WSText,
  WSTextProps
} from "@wingspanhq/fe-component-library";

import styles from "./styles.module.scss";

export const LABEL_COLOR = "gray500";
export const LIST_MT = "M";

export type ListLabel = string | JSX.Element;
export type ListValue =
  | string
  | number
  | boolean
  | JSX.Element
  | null
  | undefined;

export enum ListValueType {
  Text = "Text",
  Select = "Select",
  Money = "Money",
  Number = "Number",
  MoneyStrike = "MoneyStrike",
  Boolean = "Boolean",
  TIN = "TIN"
}

export interface RowData {
  id: string;
  label: ListLabel;
  value: ListValue;
  type: ListValueType;
  valueWSTextProps?: WSTextProps;
  labelWSTextProps?: WSTextProps;
}

interface ListElementProps extends WSElementProps {
  value: ListValue;
  color?: WSElementColorsType;
  type?: ListValueType;
}

export const ListElement: React.FC<ListElementProps> = ({
  color,
  value,
  type,
  ...props
}) => {
  if (type === ListValueType.Text)
    return (
      <WSText color={color} {...props}>
        {value || "-"}
      </WSText>
    );

  switch (type) {
    case ListValueType.Money:
      return (
        <WSText formatMoney color={color} {...props}>
          {value}
        </WSText>
      );
    case ListValueType.MoneyStrike:
      return (
        <WSText formatMoney strike color={color} {...props}>
          {value}
        </WSText>
      );
    case ListValueType.Boolean:
      if (typeof value !== "boolean")
        return (
          <WSText color={color} {...props}>
            undefined
          </WSText>
        );
      return (
        <WSText color={color} {...props}>
          {value ? "Yes" : "No"}
        </WSText>
      );
    default:
      if (typeof value === "string" || typeof value === "number")
        return (
          <WSText color={color} {...props}>
            {value || "-"}
          </WSText>
        );

      if (typeof value === "boolean")
        return (
          <WSText color={color} {...props}>
            {value ? "Yes" : "No"}
          </WSText>
        );

      return color ? (
        <WSElement color={color} {...props}>
          {value}
        </WSElement>
      ) : (
        value || null
      );
  }
};

interface ListRowProps {
  data: RowData;
  labelColor?: WSElementColorsType;
  valueColor?: WSElementColorsType;
  rowSpace?: WSElementSpacingsType;
}

const ListRow: React.FC<ListRowProps> = ({
  data: { label, value, type },
  labelColor,
  valueColor,
  rowSpace
}) => {
  return (
    <WSElement as="li" mt={rowSpace} className={styles.listRow}>
      <ListElement color={labelColor} value={label} />
      <ListElement color={valueColor} value={value} type={type} />
    </WSElement>
  );
};

export interface LabelValueListProps {
  items?: RowData[] | null;
  labelColor?: WSElementColorsType;
  valueColor?: WSElementColorsType;
  rowSpace?: WSElementSpacingsType;
}

const LabelValueList: React.FC<LabelValueListProps> = ({
  items,
  labelColor = LABEL_COLOR,
  valueColor,
  rowSpace = LIST_MT
}) =>
  Array.isArray(items) ? (
    <ul className={styles.listContainer}>
      {items.map(item => (
        <ListRow
          key={item.id}
          data={item}
          labelColor={labelColor}
          valueColor={valueColor}
          rowSpace={rowSpace}
        />
      ))}
    </ul>
  ) : (
    <WSText color={LABEL_COLOR}>No items defined</WSText>
  );

export default LabelValueList;
