export type Await<T> = T extends PromiseLike<infer U> ? U : T;

type Nil = null | undefined;

type Falsy = null | undefined | false | "" | 0;

export const isNull = (x: unknown): x is null => x === null;

export const isUndefined = (x: unknown): x is undefined => x === undefined;

export const isString = (x: unknown): x is string => typeof x === "string";

export const isNumber = (x: unknown): x is number => typeof x === "number";

export const isNil = (x: unknown): x is Nil =>
  isNull(x) || isUndefined(x) || (isString(x) && x === "");

export const isNotNil = <T>(x: T | Nil): x is T => !isNil(x);

export const isFalsy = (x: unknown): x is Falsy =>
  x === 0 || Number.isNaN(x) || x === false || isNil(x);

export const isTruthy = (x: unknown): x is true => !isFalsy(x);

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;
