import {
  useWSSnackbar,
  WSActions,
  WSGrid,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import { PrimeReactProvider } from "primereact/api";
import { useCustomizationQuery } from "../../query/customization/queries/useCustomizationQuery";
import { CustomizableEmailTemplates } from "@wingspanhq/users/dist/lib/interfaces";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../query/hooks/helpers";
import { useUpdateCustomization } from "../../query/customization/mutations/useUpdateCustomization";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../services/platform";
import { Editor, EditorTextChangeEvent } from "primereact/editor";
import { WSQueries } from "../../query/WSQuery";
import { PreventLeave } from "../../components/PreventLeave/PreventLeave";

export const EmailSettingsAddSnippet: React.FC = () => {
  const history = useHistory();
  const { openSnackbar } = useWSSnackbar();
  const userId = useUserId();
  const customizationQuery = useCustomizationQuery();
  const [updateCustomization, updateCustomizationMeta] = useUpdateCustomization(
    userId
  );
  const [snippet, setSnippet] = useState<string | undefined>(
    customizationQuery.data?.emailCustomization?.templates?.contractorInvite
      ?.payerMessageSnippet
  );

  useEffect(() => {
    if (customizationQuery.data) {
      setSnippet(
        customizationQuery.data?.emailCustomization?.templates?.contractorInvite
          ?.payerMessageSnippet
      );
    }
  }, [customizationQuery.data]);

  return (
    <PrimeReactProvider>
      <WSPage title="Email settings">
        <WSGrid>
          <WSGrid.Item span={{ m: "10" }}>
            <WSText.Paragraph weight="medium" color="gray700" mb="S">
              Custom email snippets
            </WSText.Paragraph>
            <WSText.ParagraphSm weight="book" color="gray500" mb="XL">
              Set up custom email snippets for your invite emails to contractors
            </WSText.ParagraphSm>

            <WSQueries queries={{ customizationQuery }}>
              {({ customizationQueryData: customization }) => {
                console.log("snippet", snippet);
                return (
                  <>
                    <PreventLeave
                      isEnabled={
                        customization?.emailCustomization?.templates
                          ?.contractorInvite?.payerMessageSnippet !== snippet
                      }
                    />
                    {/* FIXME: Already added to FECL but somehow it is not rendering properly so directly using it from primereact in the app  */}
                    {/* FIXME: On reload the edit-snippet page, the default value is not visible in the editor */}
                    <Editor
                      value={snippet}
                      onTextChange={(e: EditorTextChangeEvent) =>
                        setSnippet(e.htmlValue ?? undefined)
                      }
                      style={{ height: "320px" }}
                    />
                    <WSErrorMessage
                      contextKey={ErrorContextKey.CreateEmailSnippet}
                      error={updateCustomizationMeta.error}
                      my="M"
                    />
                    <WSActions
                      my="XL"
                      alignment="right"
                      orientation="horizontal"
                      buttons={[
                        {
                          label: "Cancel",
                          kind: "Secondary",
                          onClick: () => {
                            history.push("/member/settings/email-settings");
                          }
                        },
                        {
                          label: "Save",
                          disabled: !snippet,
                          loading: updateCustomizationMeta.isLoading,
                          onClick: async () => {
                            await updateCustomization(
                              {
                                emailCustomization: {
                                  templates: {
                                    [CustomizableEmailTemplates.contractorInvite]: {
                                      payerMessageSnippet: snippet ?? ""
                                    }
                                  }
                                }
                              },
                              {
                                onSuccess: () => {
                                  history.push(
                                    "/member/settings/email-settings"
                                  );
                                  openSnackbar({
                                    message: "Snippet updated",
                                    type: "success"
                                  });
                                },
                                onError: () => {
                                  openSnackbar({
                                    message: "Snippet failed to update",
                                    type: "error"
                                  });
                                }
                              }
                            );
                          }
                        }
                      ]}
                    />
                  </>
                );
              }}
            </WSQueries>
          </WSGrid.Item>
        </WSGrid>
      </WSPage>
    </PrimeReactProvider>
  );
};
