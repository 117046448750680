import React from "react";
import {
  useWSModal,
  WSButton,
  WSButtons,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayerTaxFormResponse,
  TaxFormRecipientSyncToggle
} from "@wingspanhq/payments/dist/interfaces";
import { IRedactedTaxFormW9Info } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { getChangedData } from "../../../../../utils/getChangedData";
import { renderValue } from "./getTimelineItem";

const getLabel = (key: keyof IRedactedTaxFormW9Info) => {
  const LABELS: { [key in keyof IRedactedTaxFormW9Info]: string } = {
    ein: "EIN",
    companyStructure: "Company structure",
    city: "City",
    state: "State",
    addressLine1: "Address line 1",
    addressLine2: "Address line 2",
    country: "Country",
    tinType: "TIN type",
    dob: "Date of birth",
    disregardedEntityName: "Disregarded entity name",
    einLastFour: "EIN last four",
    exemptFatcaCode: "Exempt FATCA code",
    exemptPayeeCode: "Exempt payee code",
    firstName: "First name",
    lastName: "Last name",
    legalBusinessName: "Legal business name",
    postalCode: "Postal code",
    ssnLastFour: "SSN last four"
  };

  return LABELS[key];
};

const ModalReviewChanges: React.FC<{
  taxForm: IPayerTaxFormResponse;
  onClose(result: boolean): void;
}> = ({ onClose, taxForm }) => {
  const overwrittenChanges =
    taxForm.eventHistory
      ?.filter(
        event =>
          event.recipientSyncToggled === TaxFormRecipientSyncToggle.Disabled
      )
      .filter(event => {
        const changedData =
          getChangedData(
            event.previousData?.w9Info || event.data?.w9Info,
            event.data?.w9Info
          ) || {};

        return Object.keys(changedData).length > 0;
      }) || [];
  const recipientChanges: Array<keyof IRedactedTaxFormW9Info> = Object.keys(
    taxForm.unsyncedRecipientData?.w9Info || {}
  ) as any;

  return (
    <WSElement mt="XS">
      <WSText color="gray600">
        The recipient has made the following updates to their tax information
        which are no longer synced because of manual edits to this form. Do you
        want to revert to the original (your overwritten edits will be lost)?
      </WSText>

      {recipientChanges.length > 0 && (
        <>
          <WSText weight="medium" mt="XL">
            Recipient changes ({recipientChanges.length})
          </WSText>
          <WSDivider my="M" />
          {recipientChanges.map(key => {
            return (
              <WSFlexBox my="XL" key={key} justify="space-between">
                <WSText.ParagraphSm color="gray600">
                  {getLabel(key)}
                </WSText.ParagraphSm>

                <WSElement>
                  <WSText.ParagraphSm color="gray600">
                    <s>{renderValue(key, taxForm.data.w9Info?.[key])}</s>
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm color="gray600">
                    {renderValue(
                      key,
                      taxForm.unsyncedRecipientData?.w9Info?.[key]
                    )}
                  </WSText.ParagraphSm>
                </WSElement>
              </WSFlexBox>
            );
          })}
          <WSDivider my="M" />
        </>
      )}

      {overwrittenChanges.length > 0 && (
        <>
          <WSText weight="medium" mt="XL">
            Overwritten edits will be lost ({overwrittenChanges.length})
          </WSText>
          <WSDivider my="M" />
          {overwrittenChanges.map((event, index) => {
            const changedData =
              getChangedData(
                event.previousData?.w9Info || event.data?.w9Info,
                event.data?.w9Info
              ) || {};

            return (
              <WSElement key={index}>
                {(Object.keys(changedData) as Array<
                  keyof IRedactedTaxFormW9Info
                >)
                  .filter(key => event.data?.w9Info?.[key])
                  .map(key => {
                    return (
                      <WSFlexBox my="XL" key={key} justify="space-between">
                        <WSText.ParagraphSm color="gray600">
                          {getLabel(key)}
                        </WSText.ParagraphSm>
                        <WSElement>
                          <WSText.ParagraphSm color="gray600">
                            <s>
                              {renderValue(
                                key,
                                event.previousData?.w9Info?.[key]
                              )}
                            </s>
                          </WSText.ParagraphSm>
                          <WSText.ParagraphSm color="gray600">
                            {renderValue(key, event.data?.w9Info?.[key])}
                          </WSText.ParagraphSm>
                        </WSElement>
                      </WSFlexBox>
                    );
                  })}
              </WSElement>
            );
          })}
          <WSDivider my="M" />
        </>
      )}

      <WSMessageBox.Info noBorder size="Large" mt="XL">
        <WSText color="gray600">
          Reminder: Reverting the recipient information may result in a 1099-NEC
          amount and status change.
        </WSText>
      </WSMessageBox.Info>

      <WSButtons mt="XL" forceFullWidth>
        <WSButton
          destructive
          onClick={() => {
            onClose(true);
          }}
        >
          Revert to original
        </WSButton>
        <WSButton.Secondary onClick={() => onClose(false)}>
          Cancel
        </WSButton.Secondary>
      </WSButtons>

      <WSText color="gray600" mt="XL">
        Automatic updates will be resumed after you revert. You cannot undo a
        reversion. You will be able to make manual edits after the reversion.
      </WSText>
    </WSElement>
  );
};

export const useModalReviewChanges = () =>
  useWSModal(ModalReviewChanges, {
    title: "Review recipient updates",
    size: "S"
  });
