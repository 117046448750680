import { Controller, useFormContext } from "react-hook-form";
import {
  WSElement,
  WSElementProps,
  WSFormOld,
  WSRadioInput
} from "@wingspanhq/fe-component-library";
import React from "react";

type Props = {
  options: { value: string; label: React.ReactNode }[];
  name: string;
  label?: string;
} & WSElementProps;

export const RadioGroupWithCustomInputs: React.FC<Props> = ({
  options,
  name,
  label,
  ...elementProps
}: Props) => {
  const { control } = useFormContext();

  return (
    <WSElement {...elementProps}>
      <WSFormOld.Label>{label}</WSFormOld.Label>
      <Controller
        name={name}
        control={control}
        render={props => (
          <>
            {options.map(option => (
              <WSRadioInput
                key={option.value}
                checked={option.value === props.value}
                value={option.value}
                name={name}
                label={option.label}
                onBlur={props.onBlur}
                onChange={props.onChange}
              />
            ))}
          </>
        )}
      />
    </WSElement>
  );
};
