import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

export interface WSFormValueProps {
  children(props: any): React.ReactNode;
  name: string;
}

export const WSFormValue = ({ children, name }: WSFormValueProps) => {
  const { control } = useFormContext();

  const value = useWatch({
    control,
    name
  });

  return <>{children(value)}</>;
};
