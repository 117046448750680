import React from "react";
import {
  WSContainer,
  WSElement,
  WSGrid,
  WSIcon,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import styles from "./styles.module.scss";
import { InvitationAuthHeader } from "../InvitationAuthHeader";
import { AppFooter } from "../../../../shared/components/AppFooter";

type Props = {
  organizationLogo?: string;
  clientLogo?: string;
  title: React.ReactNode;
  description: React.ReactNode;
  items: {
    title: string;
    description: React.ReactNode;
  }[];
};

export const InvitationAuthLayout: React.FC<Props> = ({
  organizationLogo,
  clientLogo,
  title,
  description,
  items,
  children
}) => (
  <WSElement colorBackground="gray50" className={styles.layout}>
    <InvitationAuthHeader
      clientLogo={clientLogo}
      organizationLogo={organizationLogo}
    />
    <WSContainer>
      <WSElement pt="3XL" pb="2XL">
        <WSGrid gutter="2XL">
          <WSGrid.Item span={{ s: "12", m: "6" }}>
            <WSText.Heading1 weight="bold">{title}</WSText.Heading1>
            <WSText.ParagraphSm weight="book" mt="XL">
              {description}
            </WSText.ParagraphSm>
            <WSElement mt="XL">
              {items.map((item, index) => (
                <WSPanel
                  shadow="S"
                  className={styles.listItem}
                  key={index}
                  mb="L"
                  p="L"
                >
                  <WSIcon name="check-circle-fill" color="green400" />
                  <WSElement ml="XS">
                    <WSText.ParagraphSm weight="medium" color="green400">
                      {item.title}
                    </WSText.ParagraphSm>
                    <WSText.ParagraphXs weight="book" mt="XS">
                      {item.description}
                    </WSText.ParagraphXs>
                  </WSElement>
                </WSPanel>
              ))}
            </WSElement>
          </WSGrid.Item>
          <WSGrid.Item span={{ s: "12", m: "6" }}>
            <WSPanel p="NONE" colorBackground="white">
              {children}
            </WSPanel>
          </WSGrid.Item>
        </WSGrid>
      </WSElement>
      <AppFooter />
    </WSContainer>
  </WSElement>
);
