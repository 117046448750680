import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

export const WSFormDebug = () => {
  const { control } = useFormContext();

  const values = useWatch({
    control
  });

  return <pre>{JSON.stringify(values, null, 2)}</pre>;
};
