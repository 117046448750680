import {
  useModalContext,
  WSButton,
  WSButtonProps,
  WSFlexBox,
  WSIcon,
  WSModal,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ISubscription,
  SubscriptionPackage
} from "@wingspanhq/users/dist/lib/interfaces";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import { useUserId } from "../../query/hooks/helpers";
import { useSubscriptionV3Query } from "../../query/subscriptions/queries";
import {
  isFeatureIncludedInSubscription,
  WSFrontendFeature
} from "../../Settings/utils/subscriptionUtils";
import { MEMBERSHIP_UPGRADE_MODAL } from "./MembershipUpgradeModal";
import styles from "./WSPersistentUpgradeButton.module.scss";
import { makeStyles, Theme } from "@material-ui/core";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { WSQueries } from "../../query/WSQuery";

export const PERSISTENT_UPGRADE_MODAL = "PERSISTENT_UPGRADE_MODAL";

export interface WSPersistentUpgradeButtonProps extends WSButtonProps {
  feature?: WSFrontendFeature;
}

export const WSPersistentUpgradeButton: React.FC<WSPersistentUpgradeButtonProps> = props => {
  const {
    onClick,
    feature = WSFrontendFeature.None,
    ...wsButtonProps
  }: WSPersistentUpgradeButtonProps = props;

  const userId = useUserId();

  const featureFlagsQuery = useFeatureFlags();
  const subscriptionQuery = useSubscriptionV3Query(userId);
  const { openModal } = useModalContext();

  const isFeatureIncluded = isFeatureIncludedInSubscription(
    feature,
    subscriptionQuery.data as ISubscription
  );
  const tooltipContent = (
    <WSFlexBox direction="column">
      <WSFlexBox
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        mb="XL"
      >
        <WSFlexBox.Center className={styles.upgradeIconContainer} mr="M">
          <WSIcon block name="reward" color="red400" size="M" />
        </WSFlexBox.Center>
        <WSFlexBox direction="column">
          <WSText.Heading5>Upgrade to gain access</WSText.Heading5>
          <WSText.ParagraphSm color="gray600">
            Get this feature and more
          </WSText.ParagraphSm>
        </WSFlexBox>
      </WSFlexBox>
      <WSButton
        name="getFeature"
        fullWidth
        onClick={() =>
          openModal(MEMBERSHIP_UPGRADE_MODAL, {
            packageName:
              feature === WSFrontendFeature.EnrollBenefits
                ? SubscriptionPackage.Benefits
                : SubscriptionPackage.Professional
          })
        }
      >
        Get feature
      </WSButton>
    </WSFlexBox>
  );
  const useStylesBootstrap = makeStyles((theme: Theme) => ({
    arrow: {
      color: theme.palette.common.white,
      boxShadow: "0px 4px 20px rgba(171, 174, 181, 0.5)"
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(2),
      boxShadow: "0px 4px 20px rgba(171, 174, 181, 0.5)"
    }
  }));
  const classes = useStylesBootstrap();
  return (
    <WSQueries queries={{ featureFlagsQuery }}>
      {({ featureFlagsQuery: { data: featureFlags } }) => {
        return featureFlags.disableSubscriptionBlocking ||
          isFeatureIncluded ||
          feature === WSFrontendFeature.None ? (
          <WSButton onClick={onClick} {...wsButtonProps} />
        ) : (
          <>
            <WSScreen.TabletAndDesktop>
              <Tooltip
                classes={classes}
                interactive
                placement="top"
                arrow
                title={tooltipContent}
              >
                <WSButton {...wsButtonProps} />
              </Tooltip>
            </WSScreen.TabletAndDesktop>
            <WSScreen.Mobile>
              <WSModal name={PERSISTENT_UPGRADE_MODAL}>
                {tooltipContent}
              </WSModal>
              <WSButton
                onClick={() => {
                  openModal(PERSISTENT_UPGRADE_MODAL);
                }}
                {...wsButtonProps}
              />
            </WSScreen.Mobile>
          </>
        );
      }}
    </WSQueries>
  );
};
