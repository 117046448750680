import cn from "classnames";
import { InputMask, InputMaskProps } from "primereact/inputmask";
import React, { useState } from "react";
import { toCamelCase } from "../../../../utils";
import {
  WSElement,
  WSElementCustomProps,
  WSElementLayoutProps,
  detachLayoutProps
} from "../../WSElement/WSElement.component";
import { WSButton } from "../WSButton/WSButton.component";
import { WSIcon, WSIconName } from "../WSIcon/WSIcon.component";
import styles from "./WSTextInput.module.scss";

export interface WSInputMaskOldProps
  extends Omit<InputMaskProps, "pt" | "prefix" | "size" | "color">,
    WSElementLayoutProps,
    WSElementCustomProps {
  icon?: WSIconName;
  inputClassName?: string;
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
  allowToShowPassword?: boolean;
  error?: boolean;
}

export const WSInputMaskOld = React.forwardRef<InputMask, WSInputMaskOldProps>(
  (props, ref) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const {
      icon,
      error,
      className,
      inputClassName: customInputClassName,
      type: initialType = "text",
      prefix,
      postfix,
      allowToShowPassword,
      systemProps,
      layoutProps,
      ...otherProps
    } = detachLayoutProps(props);

    const type =
      initialType === "password"
        ? passwordVisible
          ? "text"
          : "password"
        : initialType;
    const inputClassName = cn(styles.input, customInputClassName);
    const testId = otherProps.name
      ? toCamelCase(otherProps.name, "field")
      : undefined;

    const containerClassName = cn(styles.inputContainer, className, {
      [styles.error]: error,
      [styles.postfix]: postfix,
      [styles.disabled]: otherProps.disabled
    });

    return (
      <WSElement
        {...systemProps}
        className={containerClassName}
        {...layoutProps}
      >
        {icon && <WSIcon block name={icon} className={styles.icon} size="XS" />}

        {prefix ? (
          <WSElement className={styles.prefix}>{prefix}</WSElement>
        ) : null}

        <InputMask
          ref={ref}
          {...otherProps}
          type={type}
          className={inputClassName}
          data-testid={testId}
        />

        {initialType === "password" && allowToShowPassword ? (
          <WSButton.Link
            type="button"
            onClick={() => {
              setPasswordVisible((v) => !v);
            }}
          >
            {passwordVisible ? "Hide" : "Show"}
          </WSButton.Link>
        ) : null}

        {postfix ? (
          <WSElement className={styles.postfix}>{postfix}</WSElement>
        ) : null}
      </WSElement>
    );
  }
);

WSInputMaskOld.displayName = "WSInputMask";
