import { ReportsColumn } from "../types";
import { useLocalStorage } from "../../../shared/hooks/useLocalStorage";
import { useMemo } from "react";

export const useReportColumns = (
  key: string,
  userId: string,
  allColumns: ReportsColumn<any>[]
) => {
  const [columns, setColumns] = useLocalStorage(
    key,
    allColumns.filter(c => c.preselected).map(c => c.value)
  );

  const resultColumns = useMemo(
    () =>
      allColumns
        .filter(column => columns.some(columnKey => column.value === columnKey))
        .map(column => column.value),
    [columns]
  );

  return [resultColumns, setColumns] as const;
};
