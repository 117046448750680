import {
  WSSelectOption,
  WSSelectSearch,
  WSSelectSearchProps
} from "@wingspanhq/fe-component-library";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { Nullable } from "primereact/ts-helpers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePayeeQuery } from "../../../../query/payee/queries/usePayeeQuery";
import { usePayeeEngagementQuery } from "../../../../query/payeeEngagements/queries/usePayeeEngagementQuery";
import { usePayeeRowsQuery } from "../../../../query/search/payee/queries/usePayeeRowsQuery";
import { useCreateContractorModal } from "../CreateContractorModal/useCreateContractorModal";
import { getPayeeEngagements } from "../../../../services/payeeEngagement";
import {
  selectorPayeeEngagementNameTmp,
  selectorPayeeEngagementsTmp
} from "../../selectors/selectorPayeeEngagementsTmp";
import { useCollaboratorGroupsQuery } from "../../../../query/payments/queries";
import { useAuthorizedScopeGroups } from "../../../../shared/utils/teamUtils";
import { useIsEngagementsActive } from "../../../../shared/hooks/useIsEngagementsActive";
import { selectorPayerPayeeEngagements } from "../../selectors/selectorPayerPayeeEngagements";
import { selectorPayerPayeeEngagementName } from "../../selectors/selectorPayerPayeeEngagementName";

type Value = {
  payeeId: string;
  payerPayeeEngagementId: string;
};
type Props = {
  value: Nullable<Value>;
  onChange: (value: Nullable<Value>) => void;
  defaultSearchText?: string;
} & Omit<WSSelectSearchProps, "options" | "value" | "onChange">;

export const SelectPayeeEngagement: React.FC<Props> = ({
  value,
  onChange,
  defaultSearchText,
  ...otherProps
}) => {
  const modalCreateContractor = useCreateContractorModal();
  const [searchText, setSearchText] = useState(defaultSearchText);
  const [selectValue, setSelectValue] = useState<Nullable<WSSelectOption>>(
    null
  );

  const queryPayeeRows = usePayeeRowsQuery({
    filter: {
      searchString: searchText || undefined
    }
  });
  const isEngagementsActive = useIsEngagementsActive();

  const { hasCollaboratorsScope } = useAuthorizedScopeGroups();

  const queryGroups = useCollaboratorGroupsQuery();

  const queryPayee = usePayeeQuery(value?.payeeId as string, {
    enabled: !!value?.payeeId
  });
  const queryPayeeEngagement = usePayeeEngagementQuery(
    value?.payeeId as string,
    value?.payerPayeeEngagementId as string,
    {
      enabled: !!value?.payeeId && !!value?.payerPayeeEngagementId
    }
  );

  const payee = queryPayee.data;
  const payeeEngagement = queryPayeeEngagement.data;
  const groups = queryGroups.data || [];

  useEffect(() => {
    if (!payee || !payeeEngagement || !payee.user) {
      if (value) {
        return;
      }

      setSelectValue(null);
      return;
    }

    const payeeName = wsName({
      user: payee.user,
      member: payee.member,
      payerOwnedData: payee.payerOwnedData
    }).getResolvedName();

    setSelectValue({
      value: payee.payeeId + "/" + payeeEngagement.payerPayeeEngagementId,
      label: `${payeeName} • ${payeeEngagement.engagementName}`,
      labels: [payee.user.email],
      avatar: {
        type: "text",
        text: payeeName
      }
    });
  }, [payee, payeeEngagement, value]);

  const options = useMemo(() => {
    const options: WSSelectOption[] = [];

    (queryPayeeRows.data || []).forEach(payee => {
      if (!payee.user) {
        return;
      }

      const payeeNames = wsName({
        user: payee.user,
        member: payee.member,
        payerOwnedData: payee.payerOwnedData
      });

      const name = payeeNames.getResolvedName();
      const secondaryName = payeeNames.getResolvedSecondaryName();
      const filteredEngagements = isEngagementsActive
        ? selectorPayerPayeeEngagements(payee.engagements, true)
        : selectorPayeeEngagementsTmp(payee.engagements, false, true, false);

      filteredEngagements.forEach((e, index, all) => {
        options.push({
          value: payee.payeeId + "/" + e.payerPayeeEngagementId,
          label: [
            name,
            isEngagementsActive
              ? selectorPayerPayeeEngagementName(e, false)
              : all.length > 1
              ? selectorPayeeEngagementNameTmp(e, groups, false)
              : undefined
          ]
            .filter(Boolean)
            .join(" • "),
          labels: [secondaryName].filter(Boolean) as string[],
          avatar: {
            type: "text",
            text: name
          }
        });
      });
    });

    return options;
  }, [queryPayeeRows.data]);

  const onReachMenuEnd = useCallback(() => {
    if (queryPayeeRows.canFetchMore) {
      queryPayeeRows.fetchMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryPayeeRows.canFetchMore]);

  const handleChange = useCallback(
    newSelectValue => {
      if (newSelectValue === null) {
        onChange(null);
        setSelectValue(null);
        return;
      }

      const [payeeId, payerPayeeEngagementId] = newSelectValue.value.split("/");
      onChange({
        payeeId,
        payerPayeeEngagementId
      });
      setSelectValue(newSelectValue);
    },
    [onChange]
  );

  return (
    <WSSelectSearch
      {...otherProps}
      value={selectValue}
      onChange={handleChange}
      // label="Payee"
      placeholder="Select an option"
      options={options}
      status={queryPayeeRows.isLoading ? "loading" : otherProps.status}
      externalSearchText={searchText}
      onExternalSearchChange={setSearchText}
      isListLoading={
        queryPayeeRows.isFetching && !queryPayeeRows.isFetchingMore
      }
      onReachMenuEnd={onReachMenuEnd}
      menuFooterText={
        hasCollaboratorsScope
          ? "Don't see a Contractor name? Add a new contractor to your contractor list."
          : undefined
      }
      menuFooterAction={
        hasCollaboratorsScope
          ? {
              label: "Add contractor",
              onClick: () => {
                modalCreateContractor.open({
                  async onSuccess(payee) {
                    if (payee) {
                      const { data: engagements } = await getPayeeEngagements(
                        payee.payeeId
                      );

                      if (engagements.length) {
                        const payeeEngagement = engagements[0];

                        const payeeNames = wsName({
                          user: payee.user!,
                          member: payee.member,
                          payerOwnedData: payee.payerOwnedData
                        });

                        const payeeName = payeeNames.getResolvedName();

                        handleChange({
                          value:
                            payee.payeeId +
                            "/" +
                            payeeEngagement.payerPayeeEngagementId,
                          label: `${payeeName} • ${payeeEngagement.engagementName}`,
                          labels: [payeeNames.email],
                          avatar: {
                            type: "text",
                            text: payeeName
                          }
                        });
                      }
                    }
                  }
                });
              }
            }
          : undefined
      }
    />
  );
};
