import { WSButton, WSButtonProps } from "@wingspanhq/fe-component-library";
import cn from "classnames";
import React from "react";
import styles from "./Button.module.scss";

export interface ButtonProps extends WSButtonProps {}

export interface ButtonComponent extends React.FC<ButtonProps> {
  Link: React.FC<ButtonProps>;
}

export const Button: ButtonComponent = ({ className, ...props }) => (
  <WSButton
    className={cn(
      styles.base,
      props.kind === "Secondary" ? styles.secondary : styles.primary,
      { [styles.withLoader]: props.loading },
      className
    )}
    textClassName={styles.label}
    fullWidth
    {...props}
  />
);

Button.Link = ({ className, ...props }) => (
  <WSButton className={cn(styles.baseLink, className)} {...props} />
);
