import {
  useWSModal,
  WSActions,
  WSDivider,
  WSForm,
  WSInfoBox,
  WSList
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import {
  FormPartialAddress,
  getValidationSchemaAddress
} from "../../../Onboarding/components/FormPartialAddress";
import { IAddress } from "@wingspanhq/users/dist/lib/interfaces";
import defaultsDeep from "lodash/defaultsDeep";

export interface UpdateBillingAddressProps {
  address: IAddress | undefined;
  onSuccess: (address: IAddress) => void;
  onClose: () => void;
}

const emptyValues: { address: IAddress } = {
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "US"
  }
};

const UpdateBillingAddress: React.FC<UpdateBillingAddressProps> = ({
  address,
  onClose,
  onSuccess
}) => {
  const country = address?.country || "US";
  const defaultValues = defaultsDeep({ address }, emptyValues);

  return (
    <WSList gap="L">
      <WSInfoBox>
        Enter the address where you want your physical business debit card to be
        sent.
      </WSInfoBox>
      <WSForm
        defaultValues={defaultValues}
        validationSchema={Yup.object().shape({
          address: getValidationSchemaAddress(country)
        })}
        onSubmit={formValues => {
          onSuccess({ ...formValues.address, country });
          onClose();
        }}
      >
        <WSList gap="XL">
          <FormPartialAddress country={country} name="address" />
          <WSDivider />
          <WSActions
            alignment="fill"
            buttons={[
              {
                label: "Save",
                type: "submit",
                kind: "Primary"
              },
              {
                label: "Cancel",
                onClick: onClose,
                kind: "Secondary"
              }
            ]}
          />
        </WSList>
      </WSForm>
    </WSList>
  );
};

export function useUpdateBillingAddressModal() {
  return useWSModal(UpdateBillingAddress, {
    title: "Edit address",
    size: "S"
  });
}
