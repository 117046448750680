import { WSGrid } from "@wingspanhq/fe-component-library";
import { IPayeeResponse } from "@wingspanhq/payments/dist/interfaces";
import {
  FieldView,
  FieldViewProps
} from "../../../shared/components/FieldView/FieldView";
import { getPayeeNames } from "../selectors/getPayeeNames";
import { useMemo } from "react";
import { useEditEmail } from "./EditEmail/useEditEmail";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";

type Props = {
  payee: IPayeeResponse;
  editable?: boolean;
};

export const ContractorInformation: React.FC<Props> = ({ payee, editable }) => {
  const editEmail = useEditEmail();
  const featureFlags = useFeatureFlags();

  const fields = useMemo(() => {
    const names = getPayeeNames(payee);
    const address = payee.member?.profile?.address;
    const { payeeName, payeeCompanyName } = payee.payerOwnedData;

    const result: FieldViewProps[] = [
      {
        label: "DBA (doing business as)",
        value: names.companyName
      },
      {
        label: "Business name",
        value: names.legalBusinessName || payeeCompanyName
      },
      {
        label: "Name",
        value: names.fullName || payeeName
      },
      {
        label: "Email",
        value: names.email,
        action:
          editable &&
          payee.payerCapabilities?.canUpdatePayeeEmail &&
          featureFlags.data?.editContractorEmail
            ? {
                label: "Edit",
                onClick: () => {
                  editEmail.run({
                    email: names.email,
                    payeeId: payee.payeeId
                  });
                }
              }
            : undefined
      },
      {
        label: "Location",
        value: [address?.city, address?.country].filter(Boolean).join(", ")
      },
      {
        label: "External ID",
        value: names.externalId
      }
    ].filter(i => i.value);

    return result;
  }, [payee]);

  return (
    <WSGrid gutter="2XL">
      {fields.map(props => (
        <WSGrid.Item key={props.label} span={{ xs: "12", s: "6", l: "4" }}>
          <FieldView {...props} />
        </WSGrid.Item>
      ))}
    </WSGrid>
  );
};
