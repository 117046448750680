import {
  WSButton,
  WSCheckboxToggle,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { TextBulleted } from "../../../../../shared/components/TextBulleted";
import { LayoutW9 } from "../../../components/LayoutW9";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useUpdateActivity } from "../../../../../query/users/mutations";

export const RouteCertifyW9: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const userId = useUserId();
  const [updateActivity] = useUpdateActivity(userId);
  const [consent, setConsent] = useState(false);

  return (
    <LayoutW9
      onBack={onBack}
      title="Certify your W-9 is accurate"
      description="By using Wingspan’s services you agree that the information provided on the Form W-9 is accurate and that you certify the provided information as follows:"
    >
      <WSMessageBox.Info mb="XL">
        <WSText mb="M" color="gray500">
          I certify, under penalty of the perjury that:
        </WSText>
        <TextBulleted mb="M" color="gray500">
          <li>
            Taxpayer Identification Number or Social Security Number I provided
            is correct
          </li>
          <li>
            I am not subject to backup withholding because: (a) I am exempt from
            backup withholding, or (b) I have not been notified by the Internal
            Revenue Service (IRS) that I am subject to backup withholding as a
            result of a failure to report all interest or dividends or (c) the
            IRS has notified me that I am no longer subject to backup
            withholidng; and
          </li>
          <li>I am a U.S. citizen or other U.S. person; and</li>
          <li>
            The FATCA code(s) entered (if any) indicating that I am exempt from
            the FATCA reporting is correct
          </li>
        </TextBulleted>
        <WSText color="gray500">
          The Internal Revenue Service does not require your consent to any
          provision of this document other than the certifications required to
          avoid backup withholding.”
        </WSText>
      </WSMessageBox.Info>

      <WSCheckboxToggle
        mb="XL"
        name="consent"
        value={consent}
        onChange={setConsent}
        label="By providing the information on this form, I hereby certify, to the best of my knowledge, that the information provided is complete and correct"
      />

      <WSButton
        disabled={!consent}
        fullWidth
        onAsyncClick={async () => {
          await updateActivity(
            {
              events: {
                w9FormAcknowledged: new Date()
              }
            },
            {
              onSuccess: onNext
            }
          );
        }}
      >
        Certify W-9 is complete and correct
      </WSButton>
    </LayoutW9>
  );
};
