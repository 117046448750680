import { useWSModal, WSList, WSText } from "@wingspanhq/fe-component-library";
import React from "react";

type Props = {
  onClose: () => void;
};

const PayoutMethodRequirementInfoModal: React.FC<Props> = ({ onClose }) => {
  return (
    <WSList gap="M">
      <WSText.ParagraphSm>
        Wingspan supports two types of payouts: standard and instant.
      </WSText.ParagraphSm>
      <WSText.ParagraphSm>
        <b>Standard payouts</b> are processed over the ACH network. Usually,
        these payouts take 1-2 business days.
      </WSText.ParagraphSm>
      <WSText.ParagraphSm>
        <b>Instant payouts</b> are sent to an eligible debit card, and arrive in
        a few minutes.
      </WSText.ParagraphSm>
    </WSList>
  );
};

export const usePayoutMethodRequirementInfoModal = () =>
  useWSModal(PayoutMethodRequirementInfoModal, {
    title: "Payment method",
    size: "S"
  });
