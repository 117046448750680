import {
  SelectOptionOld,
  useModalContext,
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFormOld,
  WSSelectOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import {
  CustomFieldResourceType,
  ICollaboratorSchema,
  ICustomField,
  WSDataTypes
} from "@wingspanhq/payments/dist/interfaces";
import orderBy from "lodash/orderBy";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksAccountExpenses,
  useIntegrationsQuickbooksVendors
} from "../../../query/integrations/queries";
import { isQuickbooksServiceActive } from "../../../query/integrations/selectors";
import { useCreateCollaborator } from "../../../query/payments/mutations";
import {
  useCollaboratorGroupsQuery,
  useCollaboratorsQuery
} from "../../../query/payments/queries";
import { getVisibleCollaborators } from "../../../query/payments/selectors";
import { useUserProfile } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import {
  INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
  QUICKBOOKS_ENTITY,
  RefreshModal
} from "../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import { validatorEmail } from "../../../shared/validators/validatorEmail";
import {
  getYupValidationSchema,
  prepareCollaboratorLabelsRequest
} from "../../../utils/jsonFormSchema";
import {
  contactCompanyValidator,
  contactNameValidator
} from "../../../utils/validators";
import { selectorIsEnterpriseUser } from "../../../shared/selectors/selectorIsEnterpriseUser";
import { useCustomFieldsAll } from "../../../query/customFields/queries/useCustomFieldsAll";
import { selectQuickbooksUserOptions } from "../../../modules/Integrations/selectors/selectQuickbooksUserOptions";
import { selectQuickbooksAccountOrItemOptions } from "../../../modules/Integrations/selectors/selectQuickbooksAccountOrItemOptions";

export const prepareDynamicFormFields = (
  additionalData: ICustomField[],
  prefix?: string
) => {
  return orderBy(additionalData, "type", "desc").map(field => {
    switch (field.type) {
      case WSDataTypes.String:
        return (
          <WSFormOld.Field
            key={field.key}
            mb="XL"
            name={prefix ? `${prefix}.${field.key}` : field.key}
            label={`${field.name}${field.required ? "" : " (optional)"}`}
            component={WSTextInput}
          />
        );
      case WSDataTypes.Boolean:
        return (
          <WSFormOld.Field
            key={field.key}
            mb="XL"
            name={prefix ? `${prefix}.${field.key}` : field.key}
            component={WSCheckboxToggle}
            componentProps={{ label: field.name }}
          />
        );
      default:
        return (
          <WSFormOld.Field
            key={field.key}
            mb="XL"
            name={prefix ? `${prefix}.${field.key}` : field.key}
            label={`${field.name}${field.required ? "" : " (optional)"}`}
            component={WSTextInput}
          />
        );
    }
  });
};

type Props = WSElementProps & {
  onSuccess: (collaborator: ICollaboratorSchema, email: string) => void;
  onCancel: () => void;
};

export const AddCollaboratorForm: React.FC<Props> = ({
  onSuccess,
  onCancel,
  ...elementProps
}) => {
  const { openModal } = useModalContext();

  const userId = useUserId();

  const qUserProfile = useUserProfile(userId);

  const qFeatureFlags = useFeatureFlags();

  const qCollaborators = useCollaboratorsQuery();

  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qIntegrationsQuickbooksVendors = useIntegrationsQuickbooksVendors();
  const qIntegrationsQuickbooksExpenseAccounts = useIntegrationsQuickbooksAccountExpenses();

  const isQuickbooksActive =
    qIntegrationsQuickbooks.data &&
    (qFeatureFlags.data?.forceShowQBO ||
      isQuickbooksServiceActive(qIntegrationsQuickbooks.data));

  const qCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });
  const [createCollaborator, createCollaboratorMeta] = useCreateCollaborator();

  const collaboratorGroupsQuery = useCollaboratorGroupsQuery();

  return (
    <WSQueries
      queries={{
        qUserProfile,
        qCollaboratorCustomFields,
        collaboratorGroupsQuery,
        qCollaborators
      }}
    >
      {({
        qUserProfile: { data: userProfile },
        qCollaboratorCustomFields: { data: collaboratorCustomFields },
        collaboratorGroupsQuery: { data: collaboratorGroups },
        qCollaboratorsData
      }) => {
        const isEnterpriseUser = selectorIsEnterpriseUser(userProfile);
        const { data: integrationState } = qIntegrationsQuickbooks;
        const {
          data: integrationQuickbooksVendors
        } = qIntegrationsQuickbooksVendors;
        const {
          data: integrationQuickbooksExpenseAccounts
        } = qIntegrationsQuickbooksExpenseAccounts;

        const quickbooksVendorOptions: SelectOptionOld[] = selectQuickbooksUserOptions(
          integrationQuickbooksVendors
        );

        const quickbooksAccountsOptions: SelectOptionOld[] = selectQuickbooksAccountOrItemOptions(
          integrationQuickbooksExpenseAccounts
        );

        const customFieldsValidationSchema = getYupValidationSchema(
          collaboratorCustomFields
        );
        const defaultAccount = (
          integrationQuickbooksExpenseAccounts ?? []
        ).find(
          acct =>
            acct.accountId === integrationState?.defaults?.expenseAccountId
        );
        return (
          <WSElement {...elementProps}>
            <WSFormOld<{
              memberCompany: string;
              contact: string;
              email: string;
              qboVendorId: string;
              qboExpenseAccountId: string;
              collaboratorGroupId: string;
              contractorId: string;
              additionalData: { [key: string]: any };
            }>
              defaultValues={{
                qboVendorId: "",
                qboExpenseAccountId: "",
                collaboratorGroupId: (collaboratorGroups.find(
                  i => i.defaultGroup
                ) as any)?.collaboratorGroupId
              }}
              onSubmit={values => {
                const {
                  memberCompany,
                  contact,
                  email,
                  qboVendorId,
                  qboExpenseAccountId,
                  collaboratorGroupId,
                  contractorId,
                  additionalData: additionalDataValues
                } = values;

                const additionalDataLabels = prepareCollaboratorLabelsRequest(
                  collaboratorCustomFields,
                  additionalDataValues
                );

                createCollaborator(
                  {
                    clientId: userId,
                    memberCompany,
                    memberName: contact,
                    memberEmail: email,
                    clientData: contractorId
                      ? {
                          externalId: contractorId
                        }
                      : undefined,
                    labels:
                      Object.keys(additionalDataLabels).length > 0
                        ? additionalDataLabels
                        : undefined,
                    collaboratorGroupId,
                    integration:
                      isQuickbooksActive &&
                      (qboVendorId !== "" || qboExpenseAccountId !== "")
                        ? {
                            quickbooks: {
                              ...(qboVendorId ? { vendorId: qboVendorId } : {}),
                              ...(qboExpenseAccountId
                                ? { expenseAccountId: qboExpenseAccountId }
                                : {})
                            }
                          }
                        : undefined
                  },
                  {
                    onSuccess(data) {
                      onSuccess(data, email);
                    }
                  }
                );
              }}
              validationSchema={Yup.object().shape({
                email: validatorEmail.required("Required").notOneOf(
                  getVisibleCollaborators(qCollaboratorsData).map(
                    c => c.member.user.email
                  ),
                  "A contractor with this email address already exists"
                ),
                company: contactCompanyValidator,
                name: contactNameValidator,
                additionalData: Yup.object().shape(customFieldsValidationSchema)
              })}
            >
              <WSFormOld.Field
                name="email"
                label="Contractor email"
                component={WSTextInput}
                mb="XL"
              />
              <WSFormOld.Field
                name="company"
                label="Contractor company (optional)"
                component={WSTextInput}
                mb="XL"
              />
              <WSFormOld.Field
                name="contact"
                label="Contractor full name (optional)"
                component={WSTextInput}
                mb="XL"
              />
              {prepareDynamicFormFields(
                collaboratorCustomFields,
                "additionalData"
              )}

              {collaboratorGroups.length > 0 && (
                <WSFormOld.Field
                  name="collaboratorGroupId"
                  label="Contractor group"
                  component={WSSelectOld}
                  componentProps={{
                    options: collaboratorGroups.map(collaboratorGroup => ({
                      label: collaboratorGroup.name,
                      value: (collaboratorGroup as any).collaboratorGroupId
                    }))
                  }}
                  mb="XL"
                />
              )}

              {isEnterpriseUser && (
                <>
                  <WSFormOld.Field
                    name="contractorId"
                    label="External Contractor ID (optional)"
                    component={WSTextInput}
                    mb="XL"
                  />
                </>
              )}

              {isQuickbooksActive &&
                quickbooksVendorOptions.length > 0 &&
                quickbooksAccountsOptions.length > 0 && (
                  <>
                    <WSDivider mb="XL" label="Quickbooks Mapping" />
                    <WSText mb="XL">
                      Because you have an active integration with QBO, please
                      map this new Contractor to an existing Vendor, or leave
                      blank and we will create a new one. You can also set a
                      default expense account for payable line items.
                    </WSText>
                    <WSFormOld.Field
                      mb="XL"
                      name="qboVendorId"
                      component={WSSelectOld}
                      componentProps={{
                        placeholder: "Not mapped, create new Vendor",
                        options: quickbooksVendorOptions,
                        searchable: true,
                        cleanable: true,
                        placeholderActions: [
                          {
                            label: "Resync QBO Vendors",
                            icon: "refresh-v",
                            callback() {
                              openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                                entity: QUICKBOOKS_ENTITY.VENDORS
                              });
                            }
                          }
                        ]
                      }}
                      label="QBO Vendor"
                    />
                    <WSFormOld.Field
                      mb="2XL"
                      name="qboExpenseAccountId"
                      component={WSSelectOld}
                      componentProps={{
                        options: quickbooksAccountsOptions,
                        placeholder: `Use default "${defaultAccount?.fullyQualifiedName}"`,
                        cleanable: true,
                        searchable: true,
                        placeholderActions: [
                          {
                            label: "Resync QBO Expenses Accounts",
                            icon: "refresh-v",
                            callback() {
                              openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                                entity: QUICKBOOKS_ENTITY.EXPENSES
                              });
                            }
                          }
                        ]
                      }}
                      label="Default QBO Expense Account"
                    />
                    <RefreshModal />
                  </>
                )}

              <WSErrorMessage
                mb="XL"
                error={createCollaboratorMeta.error}
                contextKey="CreateCollaborator"
                forceShowApiErrors
              />

              <WSButtons mt="XL" format="modal">
                <WSButton
                  name="addCollaborator"
                  ml="M"
                  loading={createCollaboratorMeta.isLoading}
                >
                  Add Contractor
                </WSButton>
                <WSButton.Tertiary onClick={onCancel}>Cancel</WSButton.Tertiary>
              </WSButtons>
            </WSFormOld>
          </WSElement>
        );
      }}
    </WSQueries>
  );
};
