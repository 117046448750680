import { QueryConfig } from "react-query";
import { getVerificationMissingData } from "../../../services/api/onboarding/missingFields";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";

export const QUERY_VERIFICATION_MISSING_DATA =
  "QUERY_VERIFICATION_MISSING_DATA";

export const useQueryVerificationMissingData = (
  verificationLevel: Parameters<typeof getVerificationMissingData>[0],
  config?: QueryConfig<
    Awaited<ReturnType<typeof getVerificationMissingData>>,
    WSServiceError
  >
) =>
  useWSQuery(
    [QUERY_VERIFICATION_MISSING_DATA, verificationLevel],
    () => getVerificationMissingData(verificationLevel),
    config
  );
