import React from "react";
import { WSPage } from "@wingspanhq/fe-component-library";
import { WSQueries } from "../../../../query/WSQuery";
import { CollaboratorReportDashboard } from "./CollaboratorReportDashboard";
import { useHistory } from "react-router";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";

export const RouteCollaboratorsReport: React.FC = () => {
  const history = useHistory();
  const qCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });

  return (
    <WSPage
      title="Contractor Report"
      breadcrumb={{
        label: "Back",
        onClick() {
          history.push("/member/reports");
        },
        icon: "arrow-left"
      }}
    >
      <WSQueries
        queries={{
          collaboratorsAdditional: qCollaboratorCustomFields
        }}
        component={CollaboratorReportDashboard}
      />
    </WSPage>
  );
};
