import {
  WSContainer,
  WSElement,
  WSGrid,
  WSIcon,
  WSLayout
} from "@wingspanhq/fe-component-library";
import React, { ReactNode } from "react";
import { useBrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import { useGoBackOrToParent } from "../../../../utils/goToParentRoute";

interface EditLayoutProps {
  title: string;
  children: ReactNode;
  onBack?: () => void;
  noBack?: boolean;
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
}

export const EditLayout: React.FC<EditLayoutProps> = ({
  title,
  children,
  onBack,
  contentLeft,
  contentRight,
  noBack
}) => {
  useBrowserPageTitle(title);

  const goBackOrToParent = useGoBackOrToParent();
  const goBack = noBack ? undefined : onBack || goBackOrToParent;

  return (
    <WSLayout
      title={title}
      onBack={goBack}
      line
      headerRight={
        goBack ? (
          <WSIcon
            block
            size="XS"
            name="exit"
            onClick={goBack}
            color="gray600"
          />
        ) : (
          undefined
        )
      }
    >
      <WSContainer verticalPadding>
        <WSGrid>
          <WSGrid.Item span={{ m: "2", l: "3" }}>
            {contentLeft ?? null}
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "8", l: "6" }}>{children}</WSGrid.Item>
          <WSGrid.Item span={{ m: "2", l: "3" }}>
            {contentRight ?? null}
          </WSGrid.Item>
        </WSGrid>
      </WSContainer>

      <WSElement pb="3XL" pt="XL" />
    </WSLayout>
  );
};
