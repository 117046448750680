import {
  IMemberClientRequirementResponse,
  IRequirement
} from "@wingspanhq/payments/dist/interfaces";
import { RequirementStatus } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { RequirementEmbeddedStatus } from "@wingspanhq/payments/dist/interfaces/requirement";
import { ISignedDocumentResponse } from "@wingspanhq/signed-documents/dist/lib/interfaces";

export const selectorIsMemberClientRequirementRequirementReadyToSignAsPayer = (
  requirement: IMemberClientRequirementResponse
) => {
  return !!(
    requirement.status === RequirementStatus.Pending &&
    requirement.document?.events?.memberSignedAt
  );
};

export const selectorIsRequirementReadyToSignAsPayer = (
  requirement: IRequirement,
  userId: string,
  document?: ISignedDocumentResponse
) => {
  const requiresPayerSignature =
    document && selectorSignatureRequiredByUser(document, userId);

  const isSignedByPayee = document?.events?.payeeSignedAt;

  return (
    requirement.dataSourceStatus !== RequirementEmbeddedStatus.Complete &&
    isSignedByPayee &&
    requiresPayerSignature
  );
};

export const selectorSignatureRequiredByUser = (
  document: ISignedDocumentResponse,
  userId: string
) => {
  return document?.signatures?.find(signature => signature.userId === userId);
};

export const selectorIsSignatureSignedByUser = (
  document: ISignedDocumentResponse,
  userId: string
) => {
  const signature = selectorSignatureRequiredByUser(document, userId);

  return signature && signature.signedAt;
};
