import { IInvoiceLineItem } from "@wingspanhq/payments/dist/interfaces";
import isEmpty from "lodash/isEmpty";
import { calculateLineItemRegularPrice } from "../../../Invoices/utils";
import { IInvoiceRow } from "../service";

const lineItemFieldToCSVString = (
  map: (a: IInvoiceLineItem, index: number) => any,
  lineItems: IInvoiceLineItem[]
) => {
  if (isEmpty(lineItems)) {
    return undefined;
  }

  const mappedArray = lineItems
    .map(map)
    .filter(l => l !== undefined && l !== "" && l !== null);

  return mappedArray.length ? mappedArray.join(", ") : undefined;
};

export const mapInvoiceToCsvTable = (invoice: IInvoiceRow) => ({
  "Invoice ID": invoice.invoiceId,
  "Parent Invoice ID": invoice.parentInvoiceId,
  "Member Client ID": invoice.memberClientId,
  "Purchase Order Number": invoice.purchaseOrderNumber,
  "Client Name": invoice.clientName,
  //   "Client First Name": memberClient?.client?.user?.profile?.firstName,
  //   "Client Last Name": memberClient?.client?.user?.profile?.lastName,
  //   "Client Preferred Name": (memberClient?.client?.user?.profile as any)
  //     ?.preferredName,
  //   "Client Company Name": memberClient?.client?.profile?.company?.name,
  "Client Email": invoice.clientEmail,
  // "Payout Net Amount": TODO
  // "Payout Gross Amount": TODO
  "Member Gross Amount": invoice.amountDetails?.memberGross,
  "Member Net Amount": invoice.amountDetails?.memberNet,
  Currency: invoice.currency?.toUpperCase(),
  "Tax Withheld": invoice.amountDetails?.memberTaxWithheld,
  "Processing Fee": invoice.amountDetails?.processingFee,
  Amount: invoice.amount,
  "Due Date": invoice.dueDate,
  "Check Date": invoice.client?.payDate,
  "Created Date": invoice.createdAt,
  "Sent Date": invoice?.events?.sentAt,
  "Opened Date": invoice?.events?.openedAt,
  "Member Disputed Date": invoice?.events?.disputedAt,
  "Invoice Declined Date": invoice?.events?.clientDeclinedAt,
  // "Pre-Approved Date": TODO
  "Approved Date": invoice?.events?.approvedAt,
  "Paid Date": invoice?.events?.paidAt,
  "Deposited Date": invoice?.events?.depositedAt,
  "Estimated Deposit Date": invoice?.events?.estimatedDepositAt,
  "Cancelled Date": invoice?.events?.cancelledAt,
  "Invoice Number": invoice.invoiceNumber,
  "Invoice Type": invoice.labels?.invoiceType,
  "Member Workflow Status": invoice.member?.workflowStatus,
  "Client Workflow Status": invoice.client?.workflowStatus,
  Status: invoice.status,
  // "Payroll Invoice Number": TODO
  // "Payroll Invoice Amount": TODO
  "Project Name": invoice.projectName,
  "Invoice PDF": invoice.attachments?.invoicePdf,
  "Receipt PDF": invoice.attachments?.receiptPdf,
  "Invoice Link": invoice.attachments?.invoiceLink,
  "Line Items": isEmpty(invoice.lineItems)
    ? undefined
    : JSON.stringify(invoice.lineItems)
        // fix json string in csv
        .replace(/"/g, '""'),
  "Invoice Notes": invoice.invoiceNotes,
  "Member Comment": invoice.member?.comment,
  "Line Item Index": lineItemFieldToCSVString(
    (l, index) => index,
    invoice.lineItems
  ),
  "Line Item Title": lineItemFieldToCSVString(
    l => l.description,
    invoice.lineItems
  ),
  "Line Item Description": lineItemFieldToCSVString(
    l => l.detail,
    invoice.lineItems
  ),
  "Line Item Amount": lineItemFieldToCSVString(
    l => calculateLineItemRegularPrice(l)?.toFixed(2),
    invoice.lineItems
  )
  //   ...(isEmpty(invoice.labels)
  //     ? {}
  //     : Object.keys(invoice.labels as any)
  //         .filter(key => !["createdBy"].includes(key))
  //         .reduce((acc, key) => {
  //           return {
  //             ...acc,
  //             [`Custom Field ${key}`]: invoice.labels[key]
  //           };
  //         }, {}))
});
