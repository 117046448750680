import {
  INewUser,
  IndustryVertical
} from "@wingspanhq/users/dist/lib/interfaces";
import { selectorIsEnterpriseUser } from "./selectorIsEnterpriseUser";

const industryOptions = [
  {
    value: IndustryVertical.LegalAccountingConsultingOrComputerProgramming,
    label: "Independent Consulting"
  },
  {
    value: IndustryVertical.AgricultureForestryFishingOrHunting,
    label: "Agriculture, Forestry, Fishing, Hunting"
  },
  {
    value: IndustryVertical.ArtsEntertainmentAndRecreation,
    label: "Arts, Entertainment, Recreation"
  },
  {
    value: IndustryVertical.BusinessSupportOrBuildingServices,
    label: "Business Support, Building Services"
  },
  { value: IndustryVertical.Cannabis, label: "Cannabis" },
  { value: IndustryVertical.Construction, label: "Construction" },
  {
    value: IndustryVertical.DirectMarketingOrTelemarketing,
    label: "Direct Marketing, Telemarketing"
  },
  {
    value: IndustryVertical.EducationalServices,
    label: "Educational Services"
  },
  {
    value: IndustryVertical.FinancialServices,
    label: "Financial Services"
  },
  { value: IndustryVertical.GamingOrGambling, label: "Gaming, Gambling" },
  {
    value: IndustryVertical.HealthCareAndSocialAssistance,
    label: "Health Care, Social Assistance"
  },
  {
    value: IndustryVertical.HospitalityAccommodationOrFoodServices,
    label: "Hospitality, Accommodation, FoodServices"
  },
  {
    value: IndustryVertical.LegalAccountingConsultingOrComputerProgramming,
    label: "Legal, Accounting, Other Consulting"
  },
  {
    value: IndustryVertical.LegalAccountingConsultingOrComputerProgramming,
    label: "Computer Programming"
  },
  { value: IndustryVertical.Manufacturing, label: "Manufacturing" },
  { value: IndustryVertical.Mining, label: "Mining" },
  { value: IndustryVertical.Nutraceuticals, label: "Nutraceuticals" },
  {
    value: IndustryVertical.PersonalCareServices,
    label: "Personal Care Services"
  },
  {
    value: IndustryVertical.PublicAdministration,
    label: "Public Administration"
  },
  { value: IndustryVertical.RealEstate, label: "Real Estate" },
  {
    value: IndustryVertical.ReligiousCivicAndSocialOrganizations,
    label: "Religious, Civic, Social Organizations"
  },
  {
    value: IndustryVertical.RepairAndMaintenance,
    label: "Repair, Maintenance"
  },
  { value: IndustryVertical.RetailTrade, label: "Retail" },
  {
    value: IndustryVertical.TechnologyMediaOrTelecom,
    label: "Technology, Media, Telecom"
  },
  {
    value: IndustryVertical.TransportationOrWarehousing,
    label: "Transportation, Warehousing"
  },
  { value: IndustryVertical.Utilities, label: "Utilities" },
  { value: IndustryVertical.WholesaleTrade, label: "Wholesale Trade" },
  {
    value: IndustryVertical.AdultEntertainmentDatingOrEscortServices,
    label: "Adult Entertainment, Dating"
  }
];

export const selectorIndustryOptions = (user?: INewUser) => {
  if (user && selectorIsEnterpriseUser(user)) {
    const enterpriseIndustryOptions = [...industryOptions];
    enterpriseIndustryOptions.shift();
    return enterpriseIndustryOptions;
  }

  return industryOptions;
};
