import {
  WSElement,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayeeResponse,
  IRequirementResponse,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import { FieldView } from "../../../../shared/components/FieldView/FieldView";
import { useMemo } from "react";
import { selectorUniqRequirementsFromEngagementsList } from "../../../Requirements/selectors/selectorUniqRequirementsFromEngagementsList";
import { IPayeeEngagementListResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { WSQueries } from "../../../../query/WSQuery";

import styles from "./styles.module.scss";
import { ISignedDocumentResponseItem } from "@wingspanhq/signed-documents/dist/lib/interfaces/signedDocument";
import {
  IRequirementWithDocument,
  useQueryAllRequirementDefinitionsWithSignedDocumentsItems
} from "../../queries/useQueryAllRequirementDefinitionsWithSignedDocumentsItems";

type Props = {
  engagements: IPayeeEngagementListResponse;
  payee: IPayeeResponse;
};

const TRUE = "Selected";
const FALSE = "Not selected";

const mapItemToValueText = (item: ISignedDocumentResponseItem) => {
  if (item.type === "checkbox" || item.type === "radio") {
    return item.value === "true" ? TRUE : FALSE;
  }

  return item.value;
};

const RequirementSection: React.FC<{
  requirement: IRequirementWithDocument;
  payeeId: string;
}> = ({ requirement, payeeId }) => {
  return (
    <WSElement className={styles.section}>
      <WSText.Paragraph color="gray600" weight="medium" mb="S">
        {requirement.name}
      </WSText.Paragraph>
      <WSList gap="L">
        {requirement.document.responseItems
          ?.filter(item => {
            if (item.userId !== payeeId) {
              return false;
            }

            if (item.type === "signature" || item.type === "date_signed") {
              return false;
            }

            if (item.type === "radio" && item.value !== "true") {
              return false;
            }

            return true;
          })
          .map(item => (
            <FieldView
              key={item.name}
              label={item.name}
              value={mapItemToValueText(item)}
            />
          ))}
      </WSList>
    </WSElement>
  );
};

export const ESignatureWidget: React.FC<Props> = ({ payee, engagements }) => {
  const eSignatureRequirements = useMemo(() => {
    return selectorUniqRequirementsFromEngagementsList(engagements).filter(
      requirement =>
        requirement.requirementType === RequirementType.Signature &&
        "requirementDefinitionId" in requirement &&
        "dataSourceId" in requirement
    ) as IRequirementResponse[];
  }, [engagements]);

  const queryAllSignedDocumentsWithItems = useQueryAllRequirementDefinitionsWithSignedDocumentsItems(
    eSignatureRequirements,
    payee.payeeId
  );

  return (
    <WSQueries
      queries={{ queryAllSignedDocumentsWithItems }}
      renderErrors={() => null}
    >
      {({ queryAllSignedDocumentsWithItemsData }) => {
        if (!queryAllSignedDocumentsWithItemsData?.length) {
          return null;
        }

        return (
          <WSElement>
            <WSSectionToolbar mb="S" title="E-signature fields" />
            <WSPanel>
              <WSList gap="XL">
                {queryAllSignedDocumentsWithItemsData.map(requirement => (
                  <RequirementSection
                    key={requirement.requirementDefinitionId}
                    requirement={requirement}
                    payeeId={payee.payeeId}
                  />
                ))}
              </WSList>
            </WSPanel>
          </WSElement>
        );
      }}
    </WSQueries>
  );
};
