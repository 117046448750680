import { WSButton, WSButtons, WSText } from "@wingspanhq/fe-component-library";
import { MemberWorkFlowStatus } from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { queryCache } from "react-query";
import { InvoicesTableItemData } from ".";
import { addNotification } from "../../../../components/Notification/Notification";
import { QUERY_INVOICE } from "../../../../query/payments/keys";
import { paymentsService } from "../../../../services/payments";
import { bulkActionFactory } from "../../../../utils/bulkActionFactory";
import { QUERY_KEY_INVOICES_ROWS } from "../../queries/useQueryInvoicesRows";

type Props = {
  data: InvoicesTableItemData[];
  onFinish?(): void;
  onClose: () => void;
};

export const ModalBulkAccept: React.FC<Props> = ({
  data,
  onFinish,
  onClose
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  let paidItems: string[] = [];

  const bulkAccept = bulkActionFactory({
    onStart: () => {
      setIsUpdating(true);
      paidItems = [];
    },
    action: async (item: InvoicesTableItemData) => {
      const invoiceId = item.invoiceId;

      return await paymentsService.invoice.update(invoiceId, {
        member: {
          workflowStatus: MemberWorkFlowStatus.Accepted
        }
      });
    },
    onFinish: ({ successItems, failedItems }) => {
      queryCache.invalidateQueries(QUERY_KEY_INVOICES_ROWS);
      successItems.forEach(item => {
        queryCache.invalidateQueries([QUERY_INVOICE, item.invoiceId]);
      });

      addNotification({
        timeout: 6000,
        text:
          `${successItems.length} invoice${
            successItems.length > 1 ? "s" : ""
          } have been accepted` +
          (paidItems.length > 0
            ? `, ${paidItems.length} ${
                paidItems.length > 1 ? "were" : "was"
              } already paid`
            : "") +
          (failedItems.length > 0 ? `, ${failedItems.length} failed` : "")
      });

      setIsUpdating(false);
    }
  });

  return (
    <>
      <WSText.Heading5 mb="M">
        Accept {data.length} invoice{data.length > 1 && "s"}?
      </WSText.Heading5>
      <WSText mb="XL">
        Accept selected invoice{data.length > 1 && "s"} to be paid
      </WSText>

      <WSButtons format="modal">
        <WSButton
          loading={isUpdating}
          onClick={async () => {
            await bulkAccept.run(data);
            onFinish?.();
            onClose();
          }}
        >
          Accept
        </WSButton>
        <WSButton.Tertiary disabled={isUpdating} onClick={onClose}>
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </>
  );
};
