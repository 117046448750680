import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSImage,
  WSText
} from "@wingspanhq/fe-component-library";
import * as React from "react";
import paymentsMethodsImage from "../../assets/images/payment-methods.png";
import { CertifiedSecureDigitalPaymentsTooltip } from "../CertifiedSecureDigitalPaymentsTooltip/CertifiedSecureDigitalPaymentsTooltip";
import styles from "./CertifiedSecureDigitalPayments.module.scss";

type Props = {
  withTooltip?: boolean;
} & WSElementProps;

export const CertifiedSecureDigitalPayments: React.FC<Props> = ({
  withTooltip,
  ...elementProps
}) => {
  return (
    <WSElement {...elementProps} className={styles.container}>
      <WSFlexBox.CenterX mb="XS">
        <WSIcon block name="lock" mr="M" color="gray500" />
        <WSText.ParagraphSm weight="medium" color="gray500">
          Certified Secure Digital Payments
          {withTooltip && <CertifiedSecureDigitalPaymentsTooltip ml="XS" />}
        </WSText.ParagraphSm>
      </WSFlexBox.CenterX>
      <WSText color="gray500">
        ACH, bank transfer, credit card, or debit card.
        <WSImage
          className={styles.cardImages}
          width={200}
          src={paymentsMethodsImage}
          placeholder={"Payments"}
        />
      </WSText>
    </WSElement>
  );
};
