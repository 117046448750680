import { useWSMutation } from "../../helpers";
import { ISignedDocumentTemplateCreateRequest } from "@wingspanhq/signed-documents/dist/lib/interfaces";
import { createSignedDocumentTemplate } from "../../../services/signedDocuments";
import { QUERY_SIGNED_DOCUMENT_TEMPLATE_LIST } from "../keys";

export const useCreateSignedDocumentTemplate = () =>
  useWSMutation(
    (payload: ISignedDocumentTemplateCreateRequest) =>
      createSignedDocumentTemplate(payload),
    {
      dependencies: [QUERY_SIGNED_DOCUMENT_TEMPLATE_LIST]
    }
  );
