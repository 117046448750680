import { BulkStatus } from "@wingspanhq/payments/dist/interfaces/bulkUpload";

/**
 *
 * @param status BulkStatus
 * @returns string
 */
export function getDisplayImportStatusValue(status: BulkStatus) {
  const statusToLabelMap = {
    [BulkStatus.Open]: "Not imported",
    [BulkStatus.Pending]: "Importing",
    [BulkStatus.Processing]: "Importing",
    [BulkStatus.Complete]: "Imported",
    [BulkStatus.Failed]: "Import failed"
  };
  return statusToLabelMap[status];
}
