import {
  TaxFormEventType,
  TaxFormNeedsActionReason
} from "@wingspanhq/payments/dist/interfaces/taxForm";
import React from "react";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { selectorMemberName } from "../../../../../shared/selectors/selectorMemberName";
import { toWSDateString } from "@wingspanhq/fe-component-library";

export const NEEDS_ACTIONS: {
  [key in TaxFormNeedsActionReason]: {
    title: string;
    getMessage(taxForm: IPayerTaxFormResponse): React.ReactNode;
  };
} = {
  [TaxFormNeedsActionReason.InvalidTinVerificationStatus]: {
    title: "Needs action: unverified TIN",
    getMessage: () => <>Resolve the TIN verification for 1099 filing</>
  },
  [TaxFormNeedsActionReason.IncompleteW9]: {
    title: "Needs action: missing information",
    getMessage: () => <>Complete the required information for 1099 filing</>
  },

  [TaxFormNeedsActionReason.ManuallySet]: {
    title: "Needs action: updated manually",
    getMessage: taxForm => {
      const event = taxForm.eventHistory
        .filter(
          event =>
            event.eventType === TaxFormEventType.PayerManuallyUpdatedStatus
        )
        .pop();

      if (event?.eventActor) {
        return (
          <>
            Filing status updated to Needs Action by{" "}
            {selectorMemberName(event.eventActor)} on{" "}
            {toWSDateString(event.timestamp, "monthDayYear")}.
          </>
        );
      } else {
        return <>Filing status updated to Needs Action manually.</>;
      }
    }
  }
};
