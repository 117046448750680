import {
  toWSDate,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSTableProps
} from "@wingspanhq/fe-component-library";
import {
  IEngagement,
  IRequirementDefinitionResponse,
  RequirementDefinitionStatus,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import {
  selectorRequirementHumanReadableType,
  selectorRequirementIcon
} from "../../../Requirements/selectors/selectorRequirementHumanReadableType";

export interface RequirementsTableProps
  extends Omit<WSTableProps, "tableData"> {
  listPath: string;
  requirements: IRequirementDefinitionResponse[];
  engagements: IEngagement[];
}

export const RequirementsTable: React.FC<RequirementsTableProps> = ({
  listPath,
  requirements,
  engagements,
  loading,
  ...elementProps
}) => {
  const history = useHistory();
  const tableData = useMemo(
    () =>
      requirements.map(requirement => ({
        id: requirement.requirementDefinitionId,
        data: requirement
      })),
    [requirements]
  );

  const columns: Array<WSTableColumn> = [
    {
      config: {
        header: {
          text: "Name"
        }
      },
      renderFunction: rowItem => (
        <WSTableCell
          text={rowItem.data.name}
          secondaryText={rowItem.data.description}
        />
      )
    },
    {
      config: {
        header: {
          text: "Type"
        },
        hideOnScreens: ["XS"]
      },
      renderFunction: rowItem => (
        <WSTableCell
          icon={selectorRequirementIcon(
            rowItem.data.requirementType as RequirementType
          )}
          text={selectorRequirementHumanReadableType(
            rowItem.data.requirementType as RequirementType
          )}
        />
      )
    },
    {
      config: {
        header: {
          text: "Engagements"
        },
        hideOnScreens: ["XS"]
      },
      renderFunction: rowItem => {
        const requirementEngagements = engagements.filter(e =>
          e.requirementIds?.includes(rowItem.id)
        );
        const firstTwoEngagementsNames = requirementEngagements
          .slice(0, 2)
          .map(e => e.name)
          .join(", ");
        return (
          <WSTableCell
            text={firstTwoEngagementsNames}
            {...(requirementEngagements.length > 2
              ? {
                  secondaryText: `+${requirementEngagements.length - 2} more`
                }
              : {})}
          />
        );
      }
    },
    {
      config: {
        header: {
          text: "Last updated"
        },
        hideOnScreens: ["XS"]
      },
      renderFunction: rowItem => (
        <WSTableCell
          text={toWSDate(rowItem.data.updatedAt, "monthDayYear").formattedValue}
        />
      )
    },
    {
      config: {
        header: {
          text: "Status"
        }
      },
      renderFunction: ({ data: requirement }) => {
        if (requirement.status === RequirementDefinitionStatus.Active) {
          return (
            <WSTableCell
              pill={{
                icon: "check-circle",
                text: "Ready",
                theme: "success"
              }}
            />
          );
        }
        return (
          <WSTableCell
            pill={{
              icon: "alert",
              text: "Pending",
              theme: "warning"
            }}
          />
        );
      }
    }
  ];

  return (
    <WSTable
      {...elementProps}
      loading={loading}
      tableData={tableData}
      columns={columns}
      onRowClick={item => {
        history.push(`${listPath}/${item.id}`, {
          backPath: listPath
        });
      }}
      onRowControlClick={item => {
        openInNewTab(`${listPath}/${item.id}`);
      }}
      onRowMousewheelClick={item => {
        openInNewTab(`${listPath}/${item.id}`);
      }}
      data-testid="requirementsTable"
    />
  );
};
