import { WSLoader } from "@wingspanhq/fe-component-library";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { useAccount } from "../../../../query/users/queries";

type Props = {
  accountId: string;
  children: (account: IAccount) => React.ReactElement | null;
};

export const ContainerAccount: React.FC<Props> = ({ accountId, children }) => {
  const queryAccount = useAccount(accountId);

  if (!queryAccount.data) {
    return <WSLoader.Spinner size="XS" />;
  }

  return children(queryAccount.data);
};
