import {
  ITaxFormCorrectionRequest,
  ITaxFormCorrectionResponse
} from "@wingspanhq/payments/dist/interfaces";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation, WSMutationConfig } from "../../helpers";
import { paymentsService } from "../../../services/payments";
import {
  QUERY_PAYMENTS_TAXFORM_LIST_PAYER,
  QUERY_PAYMENTS_TAXFORM_PAYER,
  QUERY_PAYMENTS_TAXFORM_WITH_CORRECTION_PAYER
} from "../keys";

export const useTaxFormUpdateCorrection = (
  taxFormId: string,
  config?: WSMutationConfig<
    ITaxFormCorrectionResponse,
    WSServiceError,
    { correctionId: string; requestData: ITaxFormCorrectionRequest }
  >
) =>
  useWSMutation(
    ({ correctionId, requestData }) =>
      paymentsService.taxForm.updateCorrection(
        taxFormId,
        correctionId,
        requestData
      ),
    {
      dependencies: [
        QUERY_PAYMENTS_TAXFORM_PAYER,
        QUERY_PAYMENTS_TAXFORM_LIST_PAYER,
        [QUERY_PAYMENTS_TAXFORM_WITH_CORRECTION_PAYER, taxFormId]
      ],
      ...config
    }
  );
