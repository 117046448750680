import { ListRequestQuery } from "../../utils/serviceHelper";
import { servicePayments } from "../servicePayments";

export const getCollaboratorGroupsListSize = async (
  query?: ListRequestQuery<any>
): Promise<number> => {
  const { headers } = await servicePayments.get("/collaborator-group", {
    params: { ...query, page: { size: 1 } }
  });
  return Number(headers["x-ws-list-size"]);
};
