import {
  BulkStatus,
  CustomFieldResourceType,
  BatchProcessingStrategy
} from "@wingspanhq/payments/dist/interfaces";
import cn from "classnames";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { SetupImportPaymentsComponentProps } from "./index";
import {
  useAllBulkPayableBatchItems,
  useBulkPayableBatch
} from "../../../BulkImporter/query/bulkPayable/queries";
import { useCreateBulkPayableBatch } from "../../../BulkImporter/query/bulkPayable/mutations";
import { EditLayout } from "../../components/EditLayout";
import { WSQueries } from "../../../../query/WSQuery";
import { prepareLineItemsFromBulkBatchItem } from "../../../BulkImporter/routes/BulkImportPayableBatchDetails/prepareLineItemsFromBulkBatchItem";
import { preparePayablesCSVFile } from "../../../BulkImporter/routes/BulkImportPayableBatchDetails/preparePayablesCSVFile";
import { getUploadedFilename } from "../../../BulkImporter/utils/getUploadedFilename";
import {
  IconListItem,
  WSButton,
  WSButtons,
  WSFlexBox,
  WSIconList,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { CSVLink } from "react-csv";

import styles from "./styles.module.scss";
import { pluralize } from "../../../../shared/utils/pluralize";
import { BulkResource } from "../../../BulkImporter/types";

const lineItemPluralizeOptions = {
  one: "line item",
  other: "line items"
};

export const RouteImportPaymentsErrors: React.FC<SetupImportPaymentsComponentProps> = ({
  onNext
}) => {
  const history = useHistory();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const qPayableBulkBatch = useBulkPayableBatch(bulkBatchId);
  const qAllFailedBulkPayableBatchItems = useAllBulkPayableBatchItems(
    bulkBatchId,
    {
      filter: {
        status: BulkStatus.Failed
      }
    },
    {
      enabled: (qPayableBulkBatch.data?.statistics?.itemsFailed || 0) > 0
    }
  );
  const qLineItemCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.LineItem]
  });

  const lineItemCustomFields = qLineItemCustomFields.data || [];

  const [
    createBulkPayableBatch,
    createBulkPayableBatchMeta
  ] = useCreateBulkPayableBatch();

  const uploadSpreadsheet = async () => {
    await createBulkPayableBatch(
      {
        processingStrategy: BatchProcessingStrategy.Single
      },
      {
        onSuccess: response => {
          onNext({
            bulkBatchId: response.bulkPayableBatchId
          });
        }
      }
    );
  };

  const onSkip = async () => {
    history.push({
      pathname: "/member/1099-filing/generate-1099-nec-amounts",
      state: {
        canSkip: true
      }
    });
  };

  return (
    <EditLayout title="Batch upload payments">
      <WSQueries
        queries={{
          qPayableBulkBatch
        }}
      >
        {({ qPayableBulkBatchData: payableBulkBatch }) => {
          const failedCSVLineItems = (
            qAllFailedBulkPayableBatchItems.data ?? []
          ).map(failedBatchItem =>
            prepareLineItemsFromBulkBatchItem(
              failedBatchItem,
              lineItemCustomFields,
              { includeErrors: true }
            )
          );
          const errorsCSVFileProps = preparePayablesCSVFile(
            `Errors_${getUploadedFilename(
              payableBulkBatch,
              BulkResource.Payable
            )}`,
            failedCSVLineItems,
            lineItemCustomFields,
            { includeErrors: true }
          );

          return (
            <>
              <WSText.Heading4 mb="XL">
                {`There was an error on ${payableBulkBatch.statistics?.itemsFailed} rows of your spreadsheet in your last import`}
              </WSText.Heading4>
              <WSMessageBox.Info mb="XL" size="Large">
                <WSText weight="medium" mb="XL">
                  Import summary
                </WSText>
                <WSIconList
                  mb="XL"
                  items={
                    [
                      ...((payableBulkBatch.statistics?.itemsProcessed || 0) > 0
                        ? [
                            {
                              description: `${pluralize(
                                payableBulkBatch.statistics?.itemsProcessed ??
                                  0,
                                lineItemPluralizeOptions
                              )} imported successfully.`,
                              icon: {
                                name: "check",
                                color: "green500",
                                size: "M"
                              }
                            } as IconListItem
                          ]
                        : []),
                      ...((payableBulkBatch.statistics?.itemsFailed ?? 0) > 0
                        ? [
                            {
                              description: `${pluralize(
                                payableBulkBatch.statistics?.itemsFailed ?? 0,
                                lineItemPluralizeOptions
                              )} not imported.`,
                              icon: {
                                name: "alert",
                                color: "red500",
                                size: "M"
                              }
                            }
                          ]
                        : [])
                    ] as IconListItem[]
                  }
                />
                <WSMessageBox.Error>
                  <WSText weight="medium" mb="XS" color="red500">
                    {`There was an error importing ${payableBulkBatch.statistics?.itemsFailed} rows of your spreadsheet`}
                  </WSText>
                  Download a copy of the spreadsheet, fix the errors, save, and
                  re-import
                </WSMessageBox.Error>
              </WSMessageBox.Info>

              <WSText weight="medium" mb="XL">
                Here's how you can fix this
              </WSText>

              <WSText as="ol" mb="M" className={styles.bulletedList}>
                <WSText.ParagraphSm weight="medium" as="li">
                  1. Download CSV of errors
                  <WSText color="gray600">
                    The column "errors" includes the errors you need to resolve
                    prior to re-upload.
                  </WSText>
                </WSText.ParagraphSm>

                <WSText.ParagraphSm weight="medium" as="li">
                  2. Upload a new spreadsheet with the errors fixed
                  <WSText color="gray600">
                    Please make sure your spreadsheet contains the following
                    columns:
                  </WSText>
                </WSText.ParagraphSm>
              </WSText>

              <WSButtons mt="3XL">
                <CSVLink {...errorsCSVFileProps}>
                  <WSButton.Secondary
                    className={cn(styles.actionBtn, styles.downloadBtn)}
                    fullWidth
                    icon="download"
                    p="S"
                  >
                    Download Errors CSV
                  </WSButton.Secondary>
                </CSVLink>

                <WSButton.Primary
                  className={cn(styles.actionBtn, styles.uploadBtn)}
                  fullWidth
                  icon="upload"
                  p="S"
                  data-testid="importOffPlatformPayments"
                  onClick={uploadSpreadsheet}
                >
                  Upload corrected spreadsheet
                </WSButton.Primary>
              </WSButtons>

              <WSFlexBox.Center my="XL">
                <WSButton.Link type="button" onClick={onSkip}>
                  Skip for now
                </WSButton.Link>
              </WSFlexBox.Center>
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
