import {
  useModalContext,
  WSAvatar,
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSDropFileInput,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSModal,
  WSRadioInputGroup,
  WSSearch,
  WSSearchItem,
  WSSelectOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import {
  DocumentPurpose,
  DocumentRole,
  ITemplate
} from "@wingspanhq/files/dist/lib/interfaces";
import { ICollaboratorGroupResponse } from "@wingspanhq/payments/dist/interfaces";
import {
  IEligibilityRequirement,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import React, { useRef, useState } from "react";
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import {
  useMemberPrivateFileQuery,
  useTemplateQuery,
  useTemplatesQuery
} from "../../../../query/files/queries";
import { DetailsLayout } from "../../../../components/DetailsLayout/DetailsLayout";
import { useWSMutation } from "../../../../query/helpers";
import { filesService } from "../../../../services/files";
import {
  QUERY_COLLABORATOR_GROUP,
  QUERY_ELIGIBILITY_REQUIREMENTS
} from "../../../../query/payments/keys";
import {
  useCollaboratorGroupQuery,
  useCollaboratorGroupsQuery,
  useEligibilityRequirementQuery,
  useEligibilityRequirementsQuery
} from "../../../../query/payments/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { humanJoin } from "../../../../utils/stringHelper";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { helloSignClient, helloSignPromise } from "../../../../utils/helloSign";
import { paymentsService } from "../../../../services/payments";
import { QUERY_TEMPLATES } from "../../../../query/files/keys";
import { CollaboratorSignatureDiagram } from "../../../../Invoices/components/CollaboratorSignatureDiagram/CollaboratorSignatureDiagram";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { RequirementDetails } from "../../../../Invoices/components/RequirementDetails/RequirementDetails";
import { buildGroupDetailsPath } from "../../utils";

export const CollaboratorGroupAddRequirement: React.FC<RouteComponentProps<
  {
    collaboratorGroupId: string;
  },
  any,
  { eligibilityRequirementId?: string }
>> = ({
  match: {
    params: { collaboratorGroupId }
  },
  location
}) => {
  const history = useHistory();
  const [fileId, setFileId] = useState("");
  const [requirementId, setRequirementId] = useState("");
  const fileQuery = useMemberPrivateFileQuery(fileId, { enabled: !!fileId });
  const requirementToReplace = location.state?.eligibilityRequirementId;

  return (
    <DetailsLayout
      onBack={() => {
        history.push(buildGroupDetailsPath(collaboratorGroupId));
      }}
      title={
        fileQuery.data?.filename
          ? fileQuery.data.filename
          : requirementToReplace
          ? "Replace requirement"
          : "Add requirement"
      }
    >
      <DetailsLayout.Card>
        {fileId ? (
          <RequirementForm fileId={fileId} />
        ) : requirementId ? (
          <ExistingRequirement requirementId={requirementId} />
        ) : (
          <UploadTemplate
            onSuccess={({ fileId, requirementId }) => {
              if (fileId) {
                setFileId(fileId);
              } else if (requirementId) {
                setRequirementId(requirementId);
              }
            }}
          />
        )}
      </DetailsLayout.Card>
    </DetailsLayout>
  );
};

const UploadTemplate: React.FC<{
  onSuccess: (data: { fileId?: string; requirementId?: string }) => void;
}> = ({ onSuccess }) => {
  const {
    params: { collaboratorGroupId }
  } = useRouteMatch<{ collaboratorGroupId: string }>();
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [uploadTemplate, uploadTemplateMeta] = useWSMutation(
    async () => {
      if (fileToUpload) {
        const data = new FormData();
        data.append("file", fileToUpload);
        const files = await filesService.member.private.create(data);

        return files[0];
      } else {
        throw new Error("Please, choose a file");
      }
    },
    {
      onSuccess: file => {
        onSuccess({
          fileId: file.fileId
        });
      },
      dependencies: [[QUERY_COLLABORATOR_GROUP, collaboratorGroupId]]
    }
  );
  const [selectedRequirementId, setSelectedRequirementId] = useState("");

  const collaboratorGroupsQuery = useCollaboratorGroupsQuery();
  const eligibilityRequirementsQuery = useEligibilityRequirementsQuery();
  const templatesQuery = useTemplatesQuery();
  const uploadContainerElement = useRef<HTMLElement>(null);

  return (
    <>
      <WSText weight="medium" mb="M">
        Upload a new document
      </WSText>

      <WSElement mb="XL" ref={uploadContainerElement}>
        <WSDropFileInput
          name="test"
          buttonText="Upload document"
          onDropAccepted={(accepted: File[]) => {
            setFileToUpload(accepted[0]);
          }}
          accept="application/msword,application/pdf"
        />

        {fileToUpload && (
          <WSFlexBox.CenterY mt="M" wrap="nowrap">
            <WSAvatar.Icon
              icon="paperclip"
              mr="M"
              colorBackground="green50"
              color="green50"
            />
            <WSText>{fileToUpload.name}</WSText>
            <WSIcon
              block
              color="red500"
              size="S"
              ml="M"
              name="exit"
              onClick={() => {
                setFileToUpload(undefined);
              }}
            />
          </WSFlexBox.CenterY>
        )}
      </WSElement>

      <WSQueries
        queries={{
          collaboratorGroupsQuery,
          eligibilityRequirementsQuery,
          templatesQuery
        }}
      >
        {({
          collaboratorGroupsQuery: { data: collaboratorGroups },
          eligibilityRequirementsQuery: { data: eligibilityRequirements },
          templatesQuery: { data: templates }
        }) => {
          const items: WSSearchItem<{
            eligibilityRequirement: IEligibilityRequirement;
            template?: ITemplate;
            groups: ICollaboratorGroupResponse[];
          }>[] = [];

          eligibilityRequirements.forEach(eligibilityRequirement => {
            const groups = collaboratorGroups.filter(
              group =>
                !!(group.eligibilityRequirements || []).find(
                  e =>
                    e.eligibilityRequirementId ===
                    eligibilityRequirement.eligibilityRequirementId
                )
            );

            if (
              groups.length > 0 &&
              !groups.find(g => g.collaboratorGroupId === collaboratorGroupId)
            ) {
              const template = templates.find(
                t => t.templateId === eligibilityRequirement.templateId
              );

              items.push({
                value: eligibilityRequirement.eligibilityRequirementId,
                searchText: [template?.title].filter(Boolean).join(" "),
                data: { eligibilityRequirement, template, groups }
              });
            }
          });

          return (
            items.length > 0 && (
              <WSElement mb="XL">
                <WSText weight="medium" mb="M">
                  Or select existing document
                </WSText>
                <WSSearch
                  disabled={!!fileToUpload}
                  name="requirements"
                  value={selectedRequirementId}
                  onChange={(v: any) => setSelectedRequirementId(v)}
                  placeholder="Search requirement by title"
                  itemToString={item => item?.data.template?.title || ""}
                  items={items}
                  getTitle={data => data.template?.title || ""}
                  getDetails={data =>
                    data.groups.length
                      ? `Requreid for ${humanJoin(
                          data.groups.map(g => g.name)
                        )}`
                      : ""
                  }
                  getIcon={({ template }) =>
                    (template?.roles || []).includes(DocumentRole.Client)
                      ? {
                          icon: "group",
                          color: "green500",
                          colorBackground: "green50"
                        }
                      : {
                          icon: "user",
                          color: "blue500",
                          colorBackground: "blue50"
                        }
                  }
                  defaultItems={items}
                  emptyResults={() => (
                    <WSFlexBox.Center direction="column" p="M">
                      <WSText color="gray500">No results.</WSText>
                      <WSButton.Link
                        onClick={() => {
                          uploadContainerElement.current
                            ?.querySelector("input")
                            ?.click();
                        }}
                      >
                        Upload document
                      </WSButton.Link>
                    </WSFlexBox.Center>
                  )}
                />
              </WSElement>
            )
          );
        }}
      </WSQueries>

      <WSButton
        loading={uploadTemplateMeta.isLoading}
        fullWidth
        disabled={!fileToUpload && !selectedRequirementId}
        onClick={() => {
          if (fileToUpload) {
            uploadTemplate();
          } else if (selectedRequirementId) {
            onSuccess({
              requirementId: selectedRequirementId
            });
          }
        }}
      >
        Continue
      </WSButton>
    </>
  );
};

type RequirementFormValues = {
  title: string;
  expires: boolean;
  expiresIn: string;
  expiresInInterval: "week" | "month";
  signer: "collaborator" | "mutual";
};

const RequirementForm: React.FC<{
  fileId: string;
}> = ({ fileId }) => {
  const history = useHistory();
  const location = useLocation<{ eligibilityRequirementId?: string }>();
  const {
    params: { collaboratorGroupId }
  } = useRouteMatch<{ collaboratorGroupId: string }>();
  const collaboratorGroupQuery = useCollaboratorGroupQuery(collaboratorGroupId);
  const { openModal } = useModalContext();
  const fileQuery = useMemberPrivateFileQuery(fileId);
  const existingRequirementId = location.state?.eligibilityRequirementId;

  const [edit, editMeta] = useWSMutation<
    any,
    WSServiceError,
    RequirementFormValues
  >(
    async formValues => {
      const template = await filesService.template.create({
        fileId,
        title: formValues.title,
        roles:
          formValues.signer === "collaborator"
            ? [DocumentRole.Collaborator]
            : [DocumentRole.Collaborator, DocumentRole.Client],
        purpose: DocumentPurpose.OnboardCollaborator
      });

      helloSignClient.open(template.editUrl);

      const status = await helloSignPromise();

      if (status === "closed") {
        openModal(CONFIRM_ADD_REQUIREMENT_MODAL, {
          onSubmit: () => {
            addToGroup({ templateId: template.templateId, formValues });
          },
          collaboratorGroupName: collaboratorGroupQuery.data?.name || "Group",
          requirementName: formValues.title
        });
      }
    },
    {
      dependencies: [[QUERY_COLLABORATOR_GROUP, collaboratorGroupId]]
    }
  );

  const [addToGroup, addToGroupMeta] = useWSMutation<
    any,
    WSServiceError,
    { templateId: string; formValues: RequirementFormValues }
  >(
    async ({ templateId, formValues }) => {
      const eligibilityRequirement = await paymentsService.collaboratorSettings.eligibilityRequirements.create(
        {
          requirementType: RequirementType.Signature,
          templateId: templateId,
          validFor: formValues.expires
            ? Number(formValues.expiresIn) *
              (formValues.expiresInInterval === "week" ? 7 : 30)
            : undefined
        }
      );

      const collaboratorGroup = collaboratorGroupQuery.data;

      if (existingRequirementId) {
        // Replace requirement
        await paymentsService.collaboratorGroup.eligibilityRequirement.replace(
          collaboratorGroupId,
          existingRequirementId,
          {
            newEligibilityRequirementId:
              eligibilityRequirement.eligibilityRequirementId
          }
        );
      } else {
        // Attach requirement to group
        await paymentsService.collaboratorGroup.update(collaboratorGroupId, {
          eligibilityRequirements: [
            ...(
              collaboratorGroup?.eligibilityRequirements || []
            ).map(r => ({})),
            {
              eligibilityRequirementId:
                eligibilityRequirement.eligibilityRequirementId
            }
          ]
        });
      }

      history.push(buildGroupDetailsPath(collaboratorGroupId));
    },
    {
      dependencies: [
        [QUERY_COLLABORATOR_GROUP, collaboratorGroupId],
        QUERY_ELIGIBILITY_REQUIREMENTS,
        QUERY_TEMPLATES
      ]
    }
  );

  return (
    <WSQueries queries={{ fileQuery }}>
      {({ fileQuery: { data: file } }) => (
        <>
          <AutoFillFieldsModal />
          <WSFormOld<RequirementFormValues>
            defaultValues={{
              title: file.filename,
              expires: false,
              expiresIn: "1",
              expiresInInterval: "month",
              signer: "collaborator"
            }}
            onSubmit={edit}
          >
            <WSFlexBox.CenterY mb="XL" wrap="nowrap" justify="space-between">
              <WSFlexBox.CenterY wrap="nowrap">
                <WSAvatar.Icon
                  icon="paperclip"
                  mr="M"
                  colorBackground="green50"
                  color="green500"
                />
                <WSText>{file.filename}</WSText>
              </WSFlexBox.CenterY>
              <WSIcon block name="check" color="green500" />
            </WSFlexBox.CenterY>

            <WSFormOld.Field
              mb="XL"
              name="title"
              label="Requirement title"
              component={WSTextInput}
            />

            <WSFormOld.Field
              hidden
              mb="XL"
              name="expires"
              component={WSCheckboxToggle}
              componentProps={{
                label: "This requirement expires",
                description:
                  "Countdown begins for each contractor when they complete the document"
              }}
            />

            <WSFormOld.Value name="expires">
              {expires => (
                <WSElement hidden={!expires} mb="XL">
                  <WSFormOld.Label>Expires in</WSFormOld.Label>
                  <WSFlexBox>
                    <WSFormOld.Field
                      mr="M"
                      name="expiresIn"
                      component={WSTextInput}
                    />
                    <WSFormOld.Field
                      name="expiresInInterval"
                      component={WSSelectOld}
                      componentProps={{
                        options: [
                          {
                            value: "week",
                            label: "weeks"
                          },
                          {
                            value: "month",
                            label: "months"
                          }
                        ]
                      }}
                    />
                  </WSFlexBox>
                </WSElement>
              )}
            </WSFormOld.Value>

            <WSFormOld.Field
              mb="XL"
              name="signer"
              label="Required signatures"
              component={WSRadioInputGroup}
              componentProps={{
                options: [
                  {
                    value: "collaborator",
                    label: (
                      <WSFlexBox.CenterY wrap="nowrap" justify="space-between">
                        <WSElement>
                          <WSText weight="medium">Contractor</WSText>
                          <WSText>
                            Only a contractor signature is required
                          </WSText>
                        </WSElement>
                        <WSAvatar.Icon
                          icon="user"
                          colorBackground="blue500"
                          color="blue50"
                        />
                      </WSFlexBox.CenterY>
                    )
                  },
                  {
                    value: "mutual",
                    label: (
                      <WSFlexBox.CenterY wrap="nowrap" justify="space-between">
                        <WSElement>
                          <WSText weight="medium">Mutual</WSText>
                          <WSText>
                            Contractor and company signatures are required
                          </WSText>
                        </WSElement>
                        <WSAvatar.Icon
                          icon="group"
                          color="green500"
                          colorBackground="green50"
                        />
                      </WSFlexBox.CenterY>
                    )
                  }
                ]
              }}
            />

            <WSFlexBox.CenterY wrap="nowrap" mb="XL">
              <WSFormOld.Value name="signer">
                {signer => (
                  <CollaboratorSignatureDiagram
                    mr="XL"
                    type={signer === "collaborator" ? "single" : "mutual"}
                    width={75}
                  />
                )}
              </WSFormOld.Value>
              <WSText>
                Wingspan can auto-fill known info like names, emails, custom
                fields,{" "}
                <WSButton.Link
                  type="button"
                  onClick={() => {
                    openModal(AUTO_FILL_FIELDS_MODAL);
                  }}
                >
                  and more
                </WSButton.Link>
                .
              </WSText>
            </WSFlexBox.CenterY>

            <WSErrorMessage
              mb="XL"
              contextKey="EditTemplate"
              error={editMeta.error || addToGroupMeta.error}
            />

            <WSButton
              fullWidth
              loading={editMeta.isLoading || addToGroupMeta.isLoading}
            >
              Edit document
            </WSButton>
          </WSFormOld>

          <ConfirmAddRequirementModal />
        </>
      )}
    </WSQueries>
  );
};

const AUTO_FILL_FIELDS_MODAL = "AUTO_FILL_FIELDS_MODAL";

const AutoFillFieldsModal = () => (
  <WSModal name={AUTO_FILL_FIELDS_MODAL} title="Auto-fill fields" size="XS">
    <WSText>Name</WSText>
    <WSText>Email</WSText>
    <WSText>External ID</WSText>
    <WSButton.Link
      mt="XL"
      onClick={() => {
        openInNewTab(
          "https://faq.hellosign.com/hc/en-us/articles/360051467511-Auto-Fill-Fields"
        );
      }}
    >
      Learn more
    </WSButton.Link>
  </WSModal>
);

const ExistingRequirement: React.FC<{ requirementId: string }> = ({
  requirementId
}) => {
  const history = useHistory();
  const {
    params: { collaboratorGroupId }
  } = useRouteMatch<{ collaboratorGroupId: string }>();
  const { openModal } = useModalContext();
  const location = useLocation<{ eligibilityRequirementId?: string }>();
  const existingRequirementId = location.state?.eligibilityRequirementId;

  const [submit, submitMeta] = useWSMutation(
    async () => {
      if (existingRequirementId) {
        // Replce requirement
        await paymentsService.collaboratorGroup.eligibilityRequirement.replace(
          collaboratorGroupId,
          existingRequirementId,
          {
            newEligibilityRequirementId: requirementId
          }
        );
      } else {
        // Attach requirement to group
        const collaboratorGroup = await paymentsService.collaboratorGroup.get(
          collaboratorGroupId
        );
        await paymentsService.collaboratorGroup.update(collaboratorGroupId, {
          eligibilityRequirements: [
            ...(collaboratorGroup.eligibilityRequirements || []),
            {
              eligibilityRequirementId: requirementId
            }
          ]
        });
      }

      history.push(buildGroupDetailsPath(collaboratorGroupId));
    },
    {
      dependencies: [[QUERY_COLLABORATOR_GROUP, collaboratorGroupId]]
    }
  );

  const collaboratorGroupQuery = useCollaboratorGroupQuery(collaboratorGroupId);
  const eligibilityRequirementQuery = useEligibilityRequirementQuery(
    requirementId
  );
  const templateQuery = useTemplateQuery(
    eligibilityRequirementQuery.data?.templateId as string,
    { enabled: !!eligibilityRequirementQuery.data?.templateId }
  );

  return (
    <>
      <RequirementDetails eligibilityRequirementId={requirementId} mb="3XL" />

      <WSErrorMessage
        mb="XL"
        contextKey="EditTemplate"
        error={submitMeta.error}
      />

      <WSButton
        fullWidth
        loading={submitMeta.isLoading}
        onClick={() => {
          openModal(CONFIRM_ADD_REQUIREMENT_MODAL, {
            onSubmit: submit,
            collaboratorGroupName: collaboratorGroupQuery.data?.name || "Group",
            requirementName: templateQuery.data?.title || "this document"
          });
        }}
      >
        Continue
      </WSButton>

      <ConfirmAddRequirementModal />
    </>
  );
};

const CONFIRM_ADD_REQUIREMENT_MODAL = "CONFIRM_ADD_REQUIREMENT_MODAL";

const ConfirmAddRequirementModal: React.FC = () => {
  const { closeModal } = useModalContext();

  return (
    <WSModal name={CONFIRM_ADD_REQUIREMENT_MODAL} size="XS">
      {({ onSubmit, collaboratorGroupName, requirementName }) => (
        <>
          <WSText weight="medium" mb="XL">
            Are you sure you want to make {requirementName} a requirement?
          </WSText>
          <WSText mb="XL">
            {collaboratorGroupName} contractors will be notified they need
            complete this requirement before receiving future payouts.
          </WSText>
          <WSButtons forceFullWidth>
            <WSButton.Tertiary
              onClick={() => {
                closeModal(CONFIRM_ADD_REQUIREMENT_MODAL);
              }}
            >
              Cancel
            </WSButton.Tertiary>
            <WSButton
              onClick={() => {
                closeModal(CONFIRM_ADD_REQUIREMENT_MODAL);
                onSubmit();
              }}
            >
              Continue
            </WSButton>
          </WSButtons>
        </>
      )}
    </WSModal>
  );
};
