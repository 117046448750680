import React from "react";
import { Form1099Concent } from "../../../../shared/components/Form1099Concent";
import { LayoutPaymentsSetup } from "../../../../shared/components/LayoutPaymentsSetup";
import { useMutationUpdateCurrentStep } from "../../mutations/useMutationUpdateCurrentStep";
import { RouteComponentProps } from "../../types";

export const Route1099Consent: React.FC<RouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const [updateCurrentStep] = useMutationUpdateCurrentStep();

  return (
    <LayoutPaymentsSetup
      title="Confirm electronic copy of Form 1099"
      onBack={onBack}
    >
      <Form1099Concent
        onSuccess={() => {
          updateCurrentStep({ newStep: 5 });
          onNext();
        }}
      />
    </LayoutPaymentsSetup>
  );
};
