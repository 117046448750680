import {
  WSCheckboxToggle,
  WSElement,
  WSElementProps,
  WSFormOld,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import {
  ICustomField,
  WSDataTypes
} from "@wingspanhq/payments/dist/interfaces";
import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";
import { ILabelsDictionary } from "../../../modules/BulkImporter/utils/getUploadedFilename";

type Props = {
  name: string;
  fields: ICustomField[];
  defaultValues?: { [key: string]: string | boolean | undefined };
} & WSElementProps;

export const FormPartialCustomFields: React.FC<Props> = ({
  name,
  fields,
  defaultValues,
  ...elementProps
}) => {
  if (fields.length === 0) {
    return null;
  }

  return (
    <WSElement {...elementProps}>
      {fields.map(field =>
        field.type === WSDataTypes.String ? (
          <WSFormOld.Field
            mb="XL"
            key={field.key}
            name={`${name}.${field.key}`}
            label={field.name + (field.required ? "" : " (optional)")}
            component={WSTextInput}
            defaultValue={(defaultValues?.[field.key] || "") as any}
          />
        ) : (
          <WSFormOld.Field
            mb="XL"
            key={field.key}
            name={`${name}.${field.key}`}
            component={WSCheckboxToggle}
            componentProps={{ label: field.name }}
            defaultValue={(defaultValues?.[field.key] || false) as any}
          />
        )
      )}
    </WSElement>
  );
};

export const getValidationSchemaCustomFields = (fields: ICustomField[]) => {
  const validationSchemaShape: ObjectShape = {};

  fields.forEach(field => {
    if (field.required) {
      if (field.type === WSDataTypes.Boolean) {
        validationSchemaShape[field.key] = Yup.boolean().required(
          `${field.name} is required`
        );
      } else {
        validationSchemaShape[field.key] = Yup.string().required(
          `${field.name} is required`
        );
      }
    }
  });

  return Yup.object().shape(validationSchemaShape);
};

export const getFormDataCustomFields = (
  fields: ICustomField[],
  data: ILabelsDictionary
) => {
  const defaultValues: any = {};
  fields.forEach(field => {
    switch (field.type) {
      case WSDataTypes.String:
        defaultValues[field.key] = data[field.key]
          ? String(data[field.key])
          : "";
        break;
      case WSDataTypes.Boolean:
        defaultValues[field.key] = data[field.key]
          ? data[field.key] === "true"
          : false;
        break;
    }
  });
  return defaultValues;
};

export const getRequestDataCustomFields = (formData: {
  [key: string]: string | boolean | undefined;
}) => {
  const labels: any = {};
  Object.keys(formData).forEach(key => {
    labels[key] = String(formData[key]);
  });
  return labels;
};
