import { WSFlexBox, WSLoader } from "@wingspanhq/fe-component-library";
import {
  PayoutDestinationType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import {
  AccountStatus,
  AccountSubType,
  AccountType,
  AccountUsage,
  FreelanceType,
  Gender,
  IGrowthSourceNames,
  IndustryVertical,
  INewUser,
  MemberWithholdingStatus,
  TaxFilingCode,
  TaxFilingType,
  UserAccountType,
  WingspanProductAspects,
  WingspanProducts
} from "@wingspanhq/users/dist/lib/interfaces";
import {
  SubscriptionPackage,
  SubscriptionPackageTier,
  SubscriptionTerm
} from "@wingspanhq/users/dist/lib/interfaces/subscription";
import queryString from "query-string";
import React, { useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { INVOICE_ACCOUNT_NAME } from "../Invoices/utils";
import { useCaptcha } from "../components/Captcha";
import { useCreateUser } from "../query/users/mutations";
import { benefitsService } from "../services/benefits";
import bookkeepingService from "../services/bookkeeping";
import { paymentsService } from "../services/payments";
import { usersService } from "../services/users";
import { IS_DEV_ENV } from "../shared/constants/environment";
import { useLoadCaptcha } from "../utils/useLoadCaptcha";

type RouteProps = RouteComponentProps;

type Props = RouteProps;

let PS = "";

if (IS_DEV_ENV) {
  PS = "Password1";
}

export const CreateTestUser: React.FC<Props> = () => {
  useLoadCaptcha();
  const history = useHistory();
  const queries = queryString.parse(window.location.search);

  const [createUser] = useCreateUser();
  const callWithCaptcha = useCaptcha("createTestUser");

  const onSubmit = async ({ token = "", version = "" }) => {
    const prefix = queries.t ? "test__fe_e2e__" : "throwaway_";
    const isFree = !!queries.free;
    const shouldEnableBenefits = !!queries.benefits;
    const shouldEnableTaxes = !!queries.taxes;
    const shouldEnableBookkeeping = !!queries.bookkeeping || shouldEnableTaxes;
    const shouldEnablePayments = !!queries.payments;
    const shouldMarkAsEnterprise = !!queries.enterprise;
    const email =
      (queries.email as string) ||
      `${prefix}${new Date().valueOf()}@wingspan.test`;
    const password = (queries.password as string) || PS;
    const firstName = (queries.firstName as string) || "Sleeping";
    const lastName = (queries.lastName as string) || "Dude";

    const values = {
      email,
      password,
      captchaToken: token,
      captchaVersion: version
    };

    await createUser(
      {
        ...values,
        profile: {
          firstName,
          lastName,
          gender: Gender.male,
          preferredName: "Ivan",
          dob: new Date(1992, 0, 14)
        },
        settings: shouldMarkAsEnterprise
          ? {
              userAccountType: UserAccountType.enterprise
            }
          : undefined
      },
      {
        throwOnError: true,
        onSuccess: async (user: INewUser) => {
          const userId = user.userId;

          await usersService.session.create(values);
          const member = await usersService.member.create(userId, {
            userId,
            profile: {
              freelanceType: FreelanceType.FullTime,
              productImportance: [WingspanProducts.Payments],
              productAspectImportance: [WingspanProductAspects.Invoicing],
              address: {
                country: "US",
                addressLine1: "525 W 50th St",
                addressLine2: " ",
                city: "New York",
                state: "NY",
                postalCode: "10119"
              },
              ...(shouldEnableBookkeeping
                ? {
                    taxInfo: {
                      stateOfResidence: "NY",
                      filingCode: TaxFilingCode.Single,
                      incomeEstimateForYear: 40000,
                      expensesEstimateForYear: 5000,
                      numExemptions: 0,
                      ...(shouldEnablePayments
                        ? {
                            occupations: [
                              {
                                taxCategory: "Media & Advertising",
                                category: "Media & Advertising"
                              }
                            ]
                          }
                        : null)
                    }
                  }
                : null),
              ...(shouldEnableTaxes
                ? {
                    withholdings: {
                      tax: {
                        rate: 25,
                        status: MemberWithholdingStatus.Paused
                      }
                    }
                  }
                : null),
              ...(shouldEnablePayments
                ? {
                    company: {
                      name: "ACME LLC"
                    },
                    industry:
                      IndustryVertical.LegalAccountingConsultingOrComputerProgramming,
                    ssn: "000000000",
                    taxInfo: {
                      stateOfResidence: "NY",
                      filingType: TaxFilingType.personal,
                      ...(shouldEnableBookkeeping
                        ? {
                            filingCode: TaxFilingCode.Single,
                            incomeEstimateForYear: 40000,
                            expensesEstimateForYear: 5000,
                            numExemptions: 0
                          }
                        : null),
                      occupations: [
                        {
                          taxCategory: "Media & Advertising",
                          category: "Media & Advertising"
                        }
                      ]
                    }
                  }
                : null)
            }
          });

          if (shouldEnableBenefits || !isFree) {
            await usersService.subscriptionV3.create(userId, {
              package: SubscriptionPackage.Professional,
              packageTier: SubscriptionPackageTier.Basic,
              term: SubscriptionTerm.Yearly
            });
          }

          await usersService.user.phoneVerification.begin(userId, {
            phone: "+10000000000"
          });

          await usersService.user.phoneVerification.complete(userId, {
            code: "000000"
          });

          await usersService.activity.create({
            userId
          });

          if (shouldEnableBenefits) {
            await benefitsService.service.update(userId, {
              enabled: true
            });
          }

          if (shouldEnableBookkeeping) {
            await bookkeepingService.enable(userId);
          }

          if (shouldEnablePayments) {
            await usersService.activity.update(userId, {
              events: {
                w9FormAcknowledged: new Date()
              }
            });

            const accounts = await usersService.account.create({
              name: INVOICE_ACCOUNT_NAME,
              status: AccountStatus.Active,
              type: AccountType.Depository,
              subType: AccountSubType.Checking,
              numbers: {
                account: "000123456789",
                routing: "031314707",
                routingWire: "031314707"
              },
              usage: AccountUsage.Mixed,
              owners: ["TestHolderName"]
            });

            const accountId = Array.isArray(accounts)
              ? accounts[0].accountId
              : accounts.accountId;

            await paymentsService.payoutSettings.update(userId, {
              payoutPreferences: PayoutPreferences.Standard,
              standard: {
                destinationType: PayoutDestinationType.Account,
                destinationId: accountId,
                percentage: 69
              }
            });
          }

          await usersService.activity.update(userId, {
            flows: {
              onboarding: {
                version: 3,
                currentStep: 4,
                totalSteps: 4,
                complete: true
              },
              ...(shouldEnableBenefits
                ? {
                    benefitsSetup: {
                      version: 2,
                      totalSteps: 4,
                      currentStep: 4,
                      complete: true
                    }
                  }
                : null),
              ...(shouldEnableBookkeeping
                ? {
                    bookkeepingSetup: {
                      version: 4,
                      currentStep: 6,
                      totalSteps: 6,
                      complete: true
                    }
                  }
                : null),
              ...(shouldEnableTaxes
                ? {
                    taxWithholdingsSetup: {
                      version: 1,
                      currentStep: 1,
                      totalSteps: 1,
                      complete: true
                    }
                  }
                : null),
              ...(shouldEnablePayments
                ? {
                    paymentsSetup: {
                      version: 3,
                      currentStep: 5,
                      totalSteps: 5,
                      complete: true
                    }
                  }
                : null),
              ...(shouldMarkAsEnterprise
                ? {
                    enterprise: {
                      version: 1,
                      currentStep: 1,
                      totalSteps: 3,
                      complete: false
                    }
                  }
                : null)
            },
            events: {
              w8BenFormAcknowledged: shouldEnablePayments
                ? new Date()
                : undefined,
              w9FormAcknowledged: shouldEnablePayments ? new Date() : undefined
            },
            context: shouldMarkAsEnterprise
              ? {
                  growthSource: IGrowthSourceNames.Enterprise,
                  growthSourceName: "pacesetter"
                }
              : undefined
          });

          if (shouldEnableTaxes) {
            history.push("/member/taxes");
          } else if (shouldEnableBookkeeping) {
            history.push("/member/bookkeeping");
          } else if (shouldEnableBenefits) {
            history.push("/member/benefits");
          } else if (shouldEnablePayments) {
            history.push("/member/invoices");
          } else {
            history.push("/member/dashboard");
          }
        }
      }
    );
  };

  useEffect(() => {
    if (queries.t) {
      onSubmit({});
    } else {
      callWithCaptcha(async (captchaToken, captchaVersion) => {
        await onSubmit({ token: captchaToken, version: captchaVersion });
      });
    }
  }, []);

  if (queries.t) {
    return (
      <WSFlexBox.Center py="5XL">
        <WSLoader.Spinner />
      </WSFlexBox.Center>
    );
  }

  return (
    <WSFlexBox.Center py="5XL">
      <WSLoader.Spinner />
    </WSFlexBox.Center>
  );
};
