import { ITaxFormCorrectionResponse } from "@wingspanhq/payments/dist/interfaces";
import { WSServiceError } from "../../../utils/serviceHelper";
import { WSMutationConfig, useWSMutation } from "../../helpers";
import {
  ITaxFormCorrectionRejectRequest,
  paymentsService
} from "../../../services/payments";
import {
  QUERY_PAYMENTS_TAXFORM_LIST_PAYER,
  QUERY_PAYMENTS_TAXFORM_PAYER,
  QUERY_PAYMENTS_TAXFORM_WITH_CORRECTION_PAYER
} from "../keys";

export const useTaxFormRejectCorrection = (
  taxFormId: string,
  config?: WSMutationConfig<
    ITaxFormCorrectionResponse,
    WSServiceError,
    { correctionId: string; requestData: ITaxFormCorrectionRejectRequest }
  >
) =>
  useWSMutation(
    ({ correctionId, requestData }) =>
      paymentsService.taxForm.rejectCorrection(
        taxFormId,
        correctionId,
        requestData
      ),
    {
      dependencies: [
        QUERY_PAYMENTS_TAXFORM_PAYER,
        QUERY_PAYMENTS_TAXFORM_LIST_PAYER,
        [QUERY_PAYMENTS_TAXFORM_WITH_CORRECTION_PAYER, taxFormId]
      ],
      ...config
    }
  );
