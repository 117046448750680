import {
  IPayerListResponse,
  PayerPayeeStatus
} from "@wingspanhq/payments/dist/interfaces";

import { servicePayments } from "../servicePayments";
import { ListRequestQuery } from "../../utils/serviceHelper";

export interface PayersApiFilter {
  "payeeOwnedData.status"?:
    | PayerPayeeStatus
    | {
        in: PayerPayeeStatus[];
      };
}

export const getPayers = async (
  query?: ListRequestQuery<
    PayersApiFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >
): Promise<IPayerListResponse> => {
  const { data } = await servicePayments.get(`/payer`, {
    params: query
  });
  return data;
};
