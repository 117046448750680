import {
  IEngagement,
  IEngagementAddRequirementRequest
} from "@wingspanhq/payments/dist/interfaces";
import { QueryConfig } from "react-query";
import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import {
  QUERY_ENGAGEMENT,
  QUERY_ENGAGEMENT_LIST,
  QUERY_ENGAGEMENT_LIST_SIZE
} from "../keys";
import { addRequirementToEngagement } from "../../../services/engagements";

export const useAddRequirementToEngagement = (
  id: string,
  config?: QueryConfig<IEngagement, WSServiceError>
) =>
  useWSMutation(
    (request: IEngagementAddRequirementRequest) =>
      addRequirementToEngagement(id, request),
    {
      ...config,
      dependencies: [QUERY_ENGAGEMENT_LIST, QUERY_ENGAGEMENT_LIST_SIZE],
      awaitDependencies: [[QUERY_ENGAGEMENT, id]]
    }
  );
