import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import plaidLogo from "../../../../assets/icons/plaid.png";
import { useUserId } from "../../../../query/hooks/helpers";
import { useCreateAccount } from "../../../../query/users/mutations";
import { useMemberProfile } from "../../../../query/users/queries";
import { selectorIsDomesticMember } from "../../../../shared/selectors/selectorIsDomesticMember";
import { useWSPlaidLink } from "../../../../utils/usePlaidLink";
import { FlowOption } from "../../components/FlowOption";
import { LayoutFullscreen } from "../../../../shared/components/LayoutFullscreen";
import { BannerProps } from "../../components/NotificationBanner";

export const RouteAddStandard: React.FC<RouteComponentProps> = () => {
  const history = useHistory<{ notificationBanner?: BannerProps }>();
  const [value, setValue] = useState<"plaid" | "manually" | undefined>();
  const [createAccount, createAccountMeta] = useCreateAccount();
  const plaidLink = useWSPlaidLink({
    onSuccess: async publicToken => {
      const createResponse = await createAccount({
        publicToken
      });

      const createdAccounts = createResponse
        ? Array.isArray(createResponse)
          ? createResponse
          : [createResponse]
        : [];

      const hasPayoutAccounts = createdAccounts.some(
        account => account.canBeUsedFor?.payouts
      );

      if (hasPayoutAccounts) {
        history.push("/member/settings/payment-methods/add-standard-plaid");
      } else {
        history.push({
          pathname: "/member/settings/payment-methods",
          state: {
            notificationBanner: {
              type: "error",
              message: "No valid checking or savings account(s) were found",
              description:
                "To try again or add another payout method, click add payout method below."
            }
          }
        });
      }
    }
  });
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  return (
    <LayoutFullscreen
      headTitle="Add payout method"
      title="Add standard payout method (free)"
      description="Receive your funds in 2-5 business days."
      onBack={() => {
        if (queryMember.data && !selectorIsDomesticMember(queryMember.data)) {
          history.push("/member/settings/payment-methods/add-international");
        } else {
          history.push("/member/settings/payment-methods/add");
        }
      }}
    >
      <FlowOption
        icon={plaidLogo}
        mb="M"
        name="Set up direct deposit with Plaid"
        desctiption="Instantly connect your bank accounts securely (Instant verification)"
        onClick={() => {
          setValue("plaid");
        }}
        isSelected={value === "plaid"}
        tooltip={
          <>
            <WSText mb="XS" color="white">
              <b>Direct deposit (Plaid)</b>
            </WSText>
            <WSText color="white">
              Instantly connect your financial account(s) securely with Plaid.
              Your information is protected by 256-bit encryption.{" "}
              <b>Verification is instant.</b>
            </WSText>
          </>
        }
      />

      <FlowOption
        icon={{
          name: "bank",
          color: "gray100",
          secondaryColor: "black"
        }}
        mb="M"
        name="Set up direct deposit manually"
        desctiption="Manually enter your routing and account numbers"
        onClick={() => {
          setValue("manually");
        }}
        isSelected={value === "manually"}
        tooltip={
          <>
            <WSText mb="XS" color="white">
              <b>Direct deposit (Manual)</b>
            </WSText>
            <WSText color="white">
              Manually enter your routing and account numbers from your
              preferred financial institution(s).
            </WSText>
          </>
        }
      />

      <WSButton
        mt="XL"
        fullWidth
        disabled={!value}
        onClick={() => {
          if (value === "plaid") {
            plaidLink.open();
          } else if (value === "manually") {
            history.push(
              "/member/settings/payment-methods/add-standard-manually"
            );
          }
        }}
        loading={createAccountMeta.isLoading}
        name="continue"
      >
        Continue
      </WSButton>
    </LayoutFullscreen>
  );
};
