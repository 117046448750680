import { WeekDay } from "../types/time";

export const dayInIntervalToWeekDayMapping: { [key: number]: WeekDay } = {
  1: WeekDay.Monday,
  2: WeekDay.Tuesday,
  3: WeekDay.Wednesday,
  4: WeekDay.Thursday,
  5: WeekDay.Friday,
  6: WeekDay.Saturday,
  7: WeekDay.Sunday
};

export const weekDayToDayInIntervalMapping = {
  [WeekDay.Monday]: 1,
  [WeekDay.Tuesday]: 2,
  [WeekDay.Wednesday]: 3,
  [WeekDay.Thursday]: 4,
  [WeekDay.Friday]: 5,
  [WeekDay.Saturday]: 6,
  [WeekDay.Sunday]: 7
};
