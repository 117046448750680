import {
  toWSMoneyString,
  useModalContext,
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSInputNumberOld,
  WSSelectOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import {
  ICustomField,
  IInvoicingConfig
} from "@wingspanhq/payments/dist/interfaces";
import { formatMoney } from "accounting";
import React, { useState } from "react";
import { ArrayField } from "react-hook-form";
import {
  INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
  QUICKBOOKS_ENTITY
} from "../../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import { FormPartialCustomFields } from "../../../../shared/components/FormPartialCustomFields";
import styles from "./index.module.scss";
import { FormDataLineItem, LineItemQuickbooksProps } from "./types";
import { calculateAmount } from "./utils";
import { getValidationSchemaLineItem } from "./validationSchema";

type Props = {
  data?: Partial<ArrayField<FormDataLineItem, "id">>;
  onSubmit: (values: FormDataLineItem) => void;
  onClose: () => void;
  quickbooksProps: LineItemQuickbooksProps;
  customFields: ICustomField[];
  invoicingConfigForPayee: IInvoicingConfig | null;
};

export const ModalMobileForm: React.FC<Props> = ({
  data,
  onClose,
  onSubmit,
  quickbooksProps: {
    isQuickbooksAccount,
    isQuickbooksItem,
    quickbooksOptions,
    quickbooksDefaultAccountId,
    quickbooksDefaultItemId
  },
  customFields,
  invoicingConfigForPayee
}) => {
  const { openModal } = useModalContext();
  const predefinedLineItems =
    invoicingConfigForPayee?.allowOnlyPredefinedLineItems?.value ?? [];

  const [rateVisible, setRateVisible] = useState(
    data?.rate === "hourly" ||
      data?.rate === "quantity" ||
      data?.rate === "other"
  );
  const [discountVisible, setDiscountVisible] = useState(false);
  const [showIntegrations, setShowIntegrations] = useState(false);
  const [customFieldsVisible, setCustomFieldsVisible] = useState(
    customFields.length > 0
  );

  return (
    <>
      <WSText.Heading5 mb="M">
        {data ? "Update item" : "New item"}
      </WSText.Heading5>

      <WSFormOld<FormDataLineItem>
        defaultValues={data}
        validationSchema={getValidationSchemaLineItem(customFields)}
        onSubmit={(...args) => {
          onSubmit(...args);
          onClose();
        }}
      >
        {({ getValues, setValue, register }) => {
          const calculateTotalCost = () => {
            const values = getValues();

            if (values.rate !== "fixed") {
              setValue("totalCost", calculateAmount(values));
            }
          };

          return (
            <>
              <input type="hidden" ref={register()} name="remove" />

              {invoicingConfigForPayee?.allowOnlyPredefinedLineItems
                ?.enabled ? (
                <WSFormOld.Field
                  mb="M"
                  name="description"
                  label="Description"
                  component={WSSelectOld}
                  defaultValue=""
                  componentProps={{
                    options: predefinedLineItems.map(li => ({
                      label: li.description,
                      value: li.description
                    }))
                  }}
                />
              ) : (
                <WSFormOld.Field
                  mb="M"
                  name="description"
                  label="Description"
                  component={WSTextInput}
                  defaultValue=""
                />
              )}
              <WSFormOld.Value name="rate">
                {rate => (
                  <>
                    <WSFormOld.Field
                      hidden={
                        rate === "hourly" ||
                        rate === "quantity" ||
                        rate === "other"
                      }
                      mb="M"
                      name="totalCost"
                      label="Amount"
                      component={WSInputNumberOld}
                      componentProps={{
                        mode: "currency",
                        currency: "USD"
                      }}
                      defaultValue={null as any}
                    />
                    {(rate === "hourly" ||
                      rate === "quantity" ||
                      rate === "other") && (
                      <WSText.Heading5 mb="M">
                        <WSFormOld.Value name="totalCost">
                          {totalCost => formatMoney(totalCost)}
                        </WSFormOld.Value>
                      </WSText.Heading5>
                    )}
                  </>
                )}
              </WSFormOld.Value>

              <WSElement mb="XL">
                <WSElement
                  style={{ display: rateVisible ? "block" : "none" }}
                  mb="M"
                >
                  <WSFormOld.Field
                    label="Rate"
                    name="rate"
                    component={WSSelectOld}
                    componentProps={{
                      options: [
                        {
                          value: "fixed",
                          label: "Fixed"
                        },
                        {
                          value: "hourly",
                          label: "Hourly"
                        },
                        {
                          value: "quantity",
                          label: "Quantity"
                        }
                      ],
                      onChange: calculateTotalCost
                    }}
                    mb="M"
                    defaultValue="fixed"
                  />
                  <WSFormOld.Value name="rate">
                    {rate => {
                      const unit =
                        rate === "hourly"
                          ? "Hour"
                          : rate === "quantity"
                          ? "Unit"
                          : "Item";

                      return rate !== "fixed" ? (
                        <>
                          <WSFormOld.Field
                            label={`Amount per ${unit}`}
                            name="costPerUnit"
                            component={WSInputNumberOld}
                            componentProps={{
                              onChange: calculateTotalCost,
                              mode: "currency",
                              currency: "USD"
                            }}
                            defaultValue={null as any}
                            mb="M"
                          />

                          {rate === "hourly" ? (
                            <>
                              <WSFormOld.Field
                                label="Hours"
                                name="quantity"
                                component={WSInputNumberOld}
                                componentProps={{
                                  onChange: calculateTotalCost,
                                  min: 0
                                }}
                                defaultValue={null as any}
                                mb={rate === "hourly" ? "M" : undefined}
                              />

                              <WSFormOld.Field
                                label="Minutes"
                                name="minutes"
                                component={WSInputNumberOld}
                                componentProps={{
                                  onChange: calculateTotalCost,
                                  min: 0,
                                  max: 60
                                }}
                                defaultValue={null as any}
                              />
                            </>
                          ) : (
                            <WSFormOld.Field
                              label={`${unit}(s)`}
                              name="quantity"
                              component={WSInputNumberOld}
                              componentProps={{
                                onChange: calculateTotalCost,
                                min: 0
                              }}
                              defaultValue={null as any}
                              mb={rate === "hourly" ? "M" : undefined}
                            />
                          )}
                        </>
                      ) : null;
                    }}
                  </WSFormOld.Value>
                  {invoicingConfigForPayee?.allowLineItemReimbursableExpenses
                    ?.enabled ? (
                    <WSFormOld.Field
                      mt="M"
                      name="reimbursableExpense"
                      component={WSCheckboxToggle}
                      componentProps={{ label: "Reimbursable expense" }}
                      defaultValue={false as any}
                    />
                  ) : null}
                </WSElement>

                <WSButton.Link
                  size="M"
                  onClick={() => {
                    setRateVisible(!rateVisible);
                  }}
                  type="button"
                  mb="M"
                >
                  {rateVisible ? "Hide rate details" : "Show rate details"}
                </WSButton.Link>

                {isQuickbooksAccount || isQuickbooksItem ? (
                  <>
                    <WSElement
                      style={{ display: showIntegrations ? "block" : "none" }}
                    >
                      <WSFormOld.Field
                        key={
                          quickbooksOptions.map(v => v.value)?.join("") +
                          quickbooksDefaultAccountId +
                          quickbooksDefaultItemId
                        }
                        label={
                          isQuickbooksItem
                            ? "Quickbooks item"
                            : "Quickbooks expense account"
                        }
                        name={
                          isQuickbooksItem
                            ? `integration.quickbooks.itemId`
                            : `integration.quickbooks.expenseAccountId`
                        }
                        component={WSSelectOld}
                        componentProps={{
                          options: quickbooksOptions,
                          searchable: true,
                          placeholderActions: [
                            {
                              label: "Resync QBO Items",
                              icon: "refresh-v",
                              callback() {
                                openModal(
                                  INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
                                  {
                                    entity: isQuickbooksItem
                                      ? QUICKBOOKS_ENTITY.ITEMS
                                      : QUICKBOOKS_ENTITY.EXPENSES
                                  }
                                );
                              }
                            }
                          ]
                        }}
                        defaultValue={
                          isQuickbooksItem
                            ? quickbooksDefaultItemId
                            : quickbooksDefaultAccountId
                        }
                        mt="M"
                        className={styles.quickbooksField}
                      />
                    </WSElement>
                    <WSButton.Link
                      onClick={() => {
                        setShowIntegrations(v => !v);
                      }}
                      type="button"
                      mb="M"
                      mt="M"
                      size="M"
                    >
                      {showIntegrations
                        ? "Hide integrations"
                        : "Show integrations"}
                    </WSButton.Link>
                  </>
                ) : null}

                <WSElement
                  style={{ display: discountVisible ? "block" : "none" }}
                  mb="M"
                >
                  <WSFormOld.Label>Discount</WSFormOld.Label>
                  <WSFlexBox wrap="nowrap" mb="XL">
                    <WSFormOld.Value name="discount.type">
                      {type => {
                        if (type === "percentage") {
                          return (
                            <WSFormOld.Field
                              key="percentage"
                              name="discount.percentage"
                              component={WSInputNumberOld}
                              componentProps={{
                                suffix: "%",
                                min: 0
                              }}
                              defaultValue={null as any}
                              mr="M"
                            />
                          );
                        } else if (type === "amount") {
                          return (
                            <WSFormOld.Field
                              key="amount"
                              name="discount.amount"
                              component={WSInputNumberOld}
                              componentProps={{
                                mode: "currency",
                                currency: "USD"
                              }}
                              defaultValue={null as any}
                              mr="M"
                            />
                          );
                        } else {
                          return null;
                        }
                      }}
                    </WSFormOld.Value>
                    <WSFormOld.Field
                      name="discount.type"
                      component={WSSelectOld}
                      componentProps={{
                        options: [
                          {
                            value: "percentage",
                            label: "% of item"
                          },
                          {
                            value: "amount",
                            label: "fixed amount"
                          }
                        ]
                      }}
                      defaultValue="percentage"
                    />
                  </WSFlexBox>

                  <WSFormOld.Field
                    label="Discount description"
                    name="discount.description"
                    component={WSTextInput}
                    defaultValue=""
                  />
                </WSElement>

                {invoicingConfigForPayee?.allowLineItemDiscounts?.enabled ? (
                  <WSFormOld.Value name="discount">
                    {discount =>
                      discountVisible ? (
                        <WSButton.Link
                          onClick={() => {
                            setDiscountVisible(false);
                          }}
                          type="button"
                        >
                          Apply discount
                        </WSButton.Link>
                      ) : discount &&
                        discount.type === "amount" &&
                        !!discount.amount ? (
                        <WSText>
                          {toWSMoneyString(discount.amount)} discount applied.{" "}
                          <WSButton.Link
                            onClick={() => {
                              setDiscountVisible(true);
                            }}
                            type="button"
                          >
                            Change
                          </WSButton.Link>
                        </WSText>
                      ) : discount &&
                        discount.type === "percentage" &&
                        !!discount.percentage ? (
                        <WSText>
                          {discount.percentage}% discount applied.{" "}
                          <WSButton.Link
                            onClick={() => {
                              setDiscountVisible(true);
                            }}
                            type="button"
                          >
                            Change
                          </WSButton.Link>
                        </WSText>
                      ) : (
                        <WSButton.Link
                          onClick={() => {
                            setDiscountVisible(true);
                          }}
                          type="button"
                        >
                          Apply discount
                        </WSButton.Link>
                      )
                    }
                  </WSFormOld.Value>
                ) : null}

                <FormPartialCustomFields
                  hidden={!customFieldsVisible}
                  mt="XL"
                  name="customFields"
                  fields={customFields}
                />

                {customFields.length > 0 && (
                  <WSButton.Link
                    mt="M"
                    name="toggleCusmomFields"
                    onClick={() => {
                      setCustomFieldsVisible(prev => !prev);
                    }}
                    type="button"
                  >
                    {customFieldsVisible ? "Hide" : "Show"} custom fields
                  </WSButton.Link>
                )}
              </WSElement>

              <WSButtons mt="XL" format="modal">
                <WSButton.Primary name="save" type="submit">
                  Save
                </WSButton.Primary>
                <WSButton.Tertiary type="button" onClick={onClose}>
                  Cancel
                </WSButton.Tertiary>
                {data && (
                  <WSButton.Secondary
                    name="delete"
                    destructive
                    type="button"
                    onClick={() => {
                      setValue("remove", "1");
                      // Skip validation and submit
                      onSubmit(getValues());
                      onClose();
                    }}
                  >
                    Delete item
                  </WSButton.Secondary>
                )}
              </WSButtons>
            </>
          );
        }}
      </WSFormOld>
    </>
  );
};
