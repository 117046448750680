import {
  WSIconName,
  WSPill,
  WSPillProps,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React from "react";
import { Card } from "../Card/Card";
import styles from "./PaymentType.module.scss";

export type PaymentTypeProps = {
  icon: WSIconName;
  title: string;
  description?: string;
  pill?: WSPillProps;
  feeText: string;
  withBorder?: boolean;
} & WSElementProps;

export const PaymentType: React.FC<PaymentTypeProps> = ({
  icon,
  title,
  description,
  pill,
  feeText,
  className,
  withBorder,
  ...elementProps
}) => (
  <Card
    className={classNames(className, { [styles.cardWithBorder]: withBorder })}
    {...elementProps}
  >
    <WSFlexBox.CenterY wrap="nowrap">
      <WSElement className={styles.icon} mr="M">
        <WSIcon block name={icon} />
      </WSElement>
      <WSElement className={styles.title}>
        <WSFlexBox.CenterY>
          <WSText.Heading5 mr="M">{title}</WSText.Heading5>
          {pill && <WSPill {...pill} />}
        </WSFlexBox.CenterY>
        {description && <WSText>{description}</WSText>}
      </WSElement>
      <WSText.ParagraphSm color="gray500">{feeText}</WSText.ParagraphSm>
    </WSFlexBox.CenterY>
  </Card>
);
