import { IActivity } from "@wingspanhq/users/dist/lib/interfaces";
import { BusinessProfile } from "../steps/BusinessProfile";
import {
  OnboardingContext,
  OnboardingModuleBusinessProfile,
  OnboardingStep
} from "../types";

const getStatus = (activity?: IActivity) => {
  if (activity?.flows.onboardingBusinessProfile?.complete) {
    return "Complete";
  }

  return "None";
};

export const getBusinessProfileStepConfig = (
  _: OnboardingModuleBusinessProfile,
  __: OnboardingContext,
  activity?: IActivity
): OnboardingStep => {
  const slug = "business-profile";

  const status = getStatus(activity);

  return {
    title: "Business profile",
    slug,
    component: BusinessProfile,

    status,
    result: {
      Complete: {
        title: "Your business profile was saved",
        description:
          "Your business profile was saved and will help contractors identify your business. Click continue for the next step."
      }
    }
  };
};
