import { WSModal } from "@wingspanhq/fe-component-library";
import React from "react";
import { PayrollSettings } from "./PayrollSettings";

export const PAYROLL_SETTINGS_MODAL = "PAYROLL_SETTINGS_MODAL";

export const PayrollSettingsModal: React.FC = () => {
  return (
    <WSModal name={PAYROLL_SETTINGS_MODAL} size="M" title="Payroll settings">
      <PayrollSettings />
    </WSModal>
  );
};
