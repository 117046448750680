import {
  WSContainer,
  WSElement,
  WSFlexBox,
  WSIcon
} from "@wingspanhq/fe-component-library";
import React from "react";
import { LogoApp } from "../../shared/components/LogoApp";
import { useGoBackOrToParent } from "../../utils/goToParentRoute";
import { Fullscreen } from "../Fullscreen/Fullscreen";
import styles from "./ProjectOnboardingLayout.module.scss";

type Props = {
  progress?: number;
  noBack?: boolean;
};

export const ProjectOnboardingLayout: React.FC<Props> = ({
  progress,
  children,
  noBack
}) => {
  const goBackOrToParent = useGoBackOrToParent();
  return (
    <Fullscreen>
      <header className={styles.header}>
        {noBack ? (
          <span />
        ) : (
          <WSIcon
            block
            name="arrow-left"
            onClick={() => goBackOrToParent()}
            className={styles.back}
          />
        )}

        <WSElement className={styles.logoContainer}>
          <LogoApp />
        </WSElement>

        {progress ? (
          <hr className={styles.progress} style={{ width: `${progress}%` }} />
        ) : null}
      </header>

      <WSContainer>
        <WSFlexBox
          direction="column"
          justify="center"
          alignItems="center"
          style={{ width: "100%" }}
          wrap="nowrap"
        >
          {children}
        </WSFlexBox>
      </WSContainer>
    </Fullscreen>
  );
};
