import {
  WSButton,
  WSElement,
  WSPanel,
  WSSidebar,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useUpdateBankingPendingTransaction } from "../../../query/bookkeeping/mutations";
import { usePendingBankingTransaction } from "../../../query/bookkeeping/queries";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";
import { getAmountValueAndFormat } from "../../../Bookkeeping/utils";
import { useBookkeepingFilters } from "../../../Bookkeeping/screens/dashboard/BookkeepingIndex";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { WSQueries } from "../../../query/WSQuery";
import { PendingTransactionStatus } from "@wingspanhq/bookkeeping/dist/lib/interfaces/pendingTransaction";

type Props = RouteComponentProps<{ transactionId: string }>;

export const PendingTransactionDetails: React.FC<Props> = ({
  history,
  match
}) => {
  const transactionId = match.params.transactionId;
  const { redirectWithFilters } = useBookkeepingFilters();
  const [updateTransaction] = useUpdateBankingPendingTransaction();

  const queryPendingTransaction = usePendingBankingTransaction(
    match.params.transactionId,
    {
      onError(error: any) {
        if (error?.response?.status === 404) {
          redirectWithFilters("/member/wallet/transactions");
        }
      },
      retry: false,
      forceFetchOnMount: true
    }
  );

  return (
    <WSSidebar
      header="Transaction details"
      onClose={() => redirectWithFilters("/member/wallet/transactions")}
    >
      <BrowserPageTitle title="Transaction" />
      <WSQueries
        queries={{
          queryPendingTransaction
        }}
      >
        {({ queryPendingTransactionData }) => {
          const transaction = queryPendingTransactionData;

          return (
            <WSPanel noBorder py="2XL">
              <WSElement>
                <WSText.Heading1
                  mb="M"
                  data-testid="transactionDetailsAmount"
                  {...getAmountValueAndFormat(transaction)}
                  {...(transaction.amount < 0
                    ? {
                        color: "green500"
                      }
                    : {})}
                />
                <WSText
                  weight="medium"
                  mb="M"
                  data-testid="transactionDetailsName"
                >
                  Pending transaction
                </WSText>

                <WSText.ParagraphXs>
                  {moment(transaction.createdAt).format("MMM D")}
                </WSText.ParagraphXs>
              </WSElement>
              <WSElement mt="XL">
                <WSButton.Secondary
                  size="S"
                  destructive={true}
                  onAsyncClick={async () => {
                    await updateTransaction({
                      id: transactionId,
                      status: PendingTransactionStatus.Cancelled
                    });
                  }}
                  name="cancelTransaction"
                >
                  Cancel transaction
                </WSButton.Secondary>
              </WSElement>
            </WSPanel>
          );
        }}
      </WSQueries>
    </WSSidebar>
  );
};
