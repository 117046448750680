import {
  useModalContext,
  WSButton,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import { IMember } from "@wingspanhq/users";
import React from "react";
import { selectorIsCompany } from "../../selectors/selectorIsCompany";

import { openIntercom } from "../../utils/intercom";

export type ModalPropsSsnDescription = {
  member: IMember;
  onUpdateBusinessInfo?: () => void;
};

export const MODAL_SSN_DESCRIPTION = "MODAL_SSN_DESCRIPTION";

export const ModalSsnDescription: React.FC = () => {
  const { closeModal } = useModalContext();

  return (
    <WSModal
      name={MODAL_SSN_DESCRIPTION}
      size="S"
      title="Why does Wingspan ask for your SSN?"
      allowClickOutsideToClose
    >
      {(props: ModalPropsSsnDescription) => (
        <>
          <WSText mb="M">
            This information is requested as part of Wingspan’s identity
            verification requirements. Wingspan collects, verifies, and
            maintains information on the individuals associated with every
            Wingspan account, as required by our regulators, customers and
            partners.
          </WSText>
          <WSText mb="M">
            Our Privacy Policy further explains how and why we collect, use,
            retain, disclose, and safeguard any personal data you provide to us.
          </WSText>
          {props && selectorIsCompany(props.member) && (
            <WSText mb="M">
              If you would prefer to provide an EIN instead of an SSN, please{" "}
              <WSButton.Link
                onClick={() => {
                  closeModal(MODAL_SSN_DESCRIPTION);
                  props?.onUpdateBusinessInfo?.();
                }}
              >
                update your business information
              </WSButton.Link>{" "}
              and provide an EIN.
            </WSText>
          )}
          <WSText mb="XL">
            Have a question?{" "}
            <WSButton.Link onClick={openIntercom}>
              Contact support
            </WSButton.Link>
          </WSText>
        </>
      )}
    </WSModal>
  );
};
