import React, { useMemo } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { NotFoundMessage } from "../../../../components/NotFoundMessage";
import RenderLoader from "../../../../components/RenderLoader";
import SidePanel from "../../../../components/SidePanel";
import { UrlIdKey } from "../../../../constants/routing";
import { WSQueries } from "../../../../query/WSQuery";
import {
  useMemberClientQuery,
  useMemberClientV2Query
} from "../../../../query/payments/queries";
import { getCurrentYearForm1099Balance } from "../../../../query/payments/selectors";
import { NEC_1099_SUMMARY_DISPUTE_SLUG } from "../../../../shared/constants/nec1099";
import { selectorIsForm1099BalanceSubmitted } from "../../../../shared/selectors/selectorIsForm1099BalanceSubmitted";
import Details from "./Details";
import Dispute from "./Dispute";

const NEC1099FilingDetailsSummary: React.FC<{ onClose: () => void }> = ({
  onClose
}) => {
  const history = useHistory();
  const match = useRouteMatch<{
    [UrlIdKey.Client]: string;
  }>();

  const memberClientId = match.params[UrlIdKey.Client];
  const memberClientQuery = useMemberClientQuery(memberClientId);
  const memberClientV2Query = useMemberClientV2Query(
    memberClientQuery.data?.clientId || "",
    {
      enabled: memberClientQuery.data
    }
  );

  const form1099Balance = useMemo(() => {
    return memberClientV2Query.data
      ? getCurrentYearForm1099Balance(memberClientV2Query.data)
      : undefined;
  }, [memberClientV2Query.data]);

  const isFormSumbitted = selectorIsForm1099BalanceSubmitted(form1099Balance);

  return (
    <Switch>
      <Route path={`${match.path}`} exact>
        <SidePanel
          title={
            isFormSumbitted ? "1099-NEC summary" : "1099-NEC draft summary"
          }
          onClose={onClose}
        >
          <WSQueries
            queries={{ memberClientQuery, memberClientV2Query }}
            renderLoader={RenderLoader}
          >
            {({
              memberClientQuery: { data: memberClient },
              memberClientV2Query: { data: clientV2 }
            }) => {
              return (
                <>
                  {!form1099Balance ? (
                    <NotFoundMessage message="Sorry, we don't have any current 1099 information for this client" />
                  ) : (
                    <Details memberClient={memberClient} client={clientV2} />
                  )}
                </>
              );
            }}
          </WSQueries>
        </SidePanel>
      </Route>
      <Route path={`${match.path}/${NEC_1099_SUMMARY_DISPUTE_SLUG}`} exact>
        <SidePanel
          title="Suggest correction"
          onClose={() => history.push(match.url)}
        >
          <WSQueries
            queries={{ memberClientQuery }}
            renderLoader={RenderLoader}
          >
            {({ memberClientQuery: { data: memberClient } }) => {
              return (
                <>
                  <Dispute memberClient={memberClient} />
                </>
              );
            }}
          </WSQueries>
        </SidePanel>
      </Route>
    </Switch>
  );
};

export default NEC1099FilingDetailsSummary;
