import {
  useModalContext,
  WSButton,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useUserId } from "../../../query/hooks/helpers";
import { useMemberProfile, useUserProfile } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { WS_LINKS } from "../../../types/wsLinks";
import { useIsOnlyOneBusinessOwner } from "../../hooks/useIsOnlyOneBusinessOwner";
import { openInNewTab } from "../../utils/openInNewTab";
import { Form } from "./Form";
import {
  MODAL_WHY_DO_WE_COLLECT_BUSINESS_INFORMATION,
  ModalWhyDoWeCollectBusinessInformation
} from "./ModalWhyDoWeCollectBusinessInformation";
import { useMutationSubmit } from "./useMutationSubmit";

type Props = {
  onSuccess: () => void;
  skipTaxVerification?: boolean;
} & WSElementProps;

export const FormBusinessInfo: React.FC<Props> = ({
  onSuccess,
  skipTaxVerification,
  ...elementProps
}) => {
  const { openModal } = useModalContext();
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const [isOnlyOneBusinessOwner] = useIsOnlyOneBusinessOwner();
  const [submit, submitMeta] = useMutationSubmit({ onSuccess });

  return (
    <WSElement {...elementProps}>
      <WSElement mb="2XL">
        <WSText mb="M">
          Wingspan is required to collect this information for compliance
          purposes. Your information is{" "}
          <WSButton.Link
            onClick={() => {
              openInNewTab(WS_LINKS.security);
            }}
          >
            Certified Secure
          </WSButton.Link>
          .
        </WSText>

        <WSButton.Link
          onClick={() => {
            openModal(MODAL_WHY_DO_WE_COLLECT_BUSINESS_INFORMATION);
          }}
        >
          Why do we collect this information
        </WSButton.Link>
      </WSElement>

      <ModalWhyDoWeCollectBusinessInformation />

      <WSQueries
        queries={{
          queryUser,
          queryMember
        }}
      >
        {({ queryUser: { data: user }, queryMember: { data: member } }) => (
          <Form
            user={user}
            member={member}
            isOnlyOneBusinessOwner={isOnlyOneBusinessOwner}
            onSubmit={submit}
            error={submitMeta.error}
            isSubmitLoading={submitMeta.isLoading}
            skipTaxVerification={skipTaxVerification}
          />
        )}
      </WSQueries>
    </WSElement>
  );
};
