import {
  WSElement,
  WSGrid,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { IBenefitCTAWidget, IBenefitTab } from "../../screens/BenefitsV3";
import { BenefitCardsSection } from "./BenefitCardsSection";
import { BenefitCostExample } from "./BenefitCostExample";
import styles from "./BenefitDetailsV2.module.scss";
import { BenefitDetailsWidget } from "./BenefitDetailsWidget";

interface BenefitDetailsTabProps {
  tab: IBenefitTab;
  noTabs?: boolean;
  widgetData: IBenefitCTAWidget;
}
export const BenefitDetailsTab: React.FC<BenefitDetailsTabProps> = ({
  tab,
  noTabs = false,
  widgetData
}) => {
  const { description, cardSection, costExample, videoScript } = tab;
  return (
    <WSGrid className={styles.gridContainer}>
      <WSGrid.Item span={{ xs: "6", m: "4" }}>
        {noTabs && <WSText.Heading4 mb="M">{tab.title}</WSText.Heading4>}
        {description && <WSElement mb="2XL">{description}</WSElement>}
        {videoScript && (
          <WSScreen.TabletAndDesktop>
            <WSElement mb="3XL" className={styles.videoContainer}>
              {videoScript}
            </WSElement>
          </WSScreen.TabletAndDesktop>
        )}
        {cardSection && <BenefitCardsSection cardsData={cardSection} />}
        {costExample && <BenefitCostExample costExample={costExample} />}
      </WSGrid.Item>
      <WSScreen.TabletAndDesktop>
        <WSGrid.Item span={{ xs: "6", m: "4" }}>
          <BenefitDetailsWidget widgetData={widgetData} />
        </WSGrid.Item>
      </WSScreen.TabletAndDesktop>
    </WSGrid>
  );
};
