import React from "react";
import {
  WSContainer,
  WSElement,
  WSEmptyState,
  WSFlexBox,
  WSPanel
} from "@wingspanhq/fe-component-library";
import styles from "./styles.module.scss";
import { InvitationAuthHeader } from "../InvitationAuthHeader";

export const InviteLinkNotFound: React.FC = ({}) => (
  <WSElement colorBackground="gray50" className={styles.layout}>
    <InvitationAuthHeader />
    <WSContainer>
      <WSElement pt="3XL" pb="2XL">
        <WSFlexBox.Center>
          <WSPanel p="NONE" className={styles.panel}>
            <WSEmptyState
              orientation="horizontal"
              title="Invite link not found"
              description={`It appears the sign-up link cannot be found. If you think this is an error, please reach out to your inviter or client for a working invite link.
              
Remember to double check your junk mail in case the invite landed there. `}
              type="noInternetConnection"
            />
          </WSPanel>
        </WSFlexBox.Center>
      </WSElement>
    </WSContainer>
  </WSElement>
);
