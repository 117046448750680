import {
  BulkStatus,
  IBulkBatch,
  IBulkCollaboratorBatch,
  IBulkPayableBatch
} from "@wingspanhq/payments/dist/interfaces";
import { TruncateOptions } from "lodash";
import truncate from "lodash/truncate";
import { isNil, isNotNil } from "../../../utils";
import { BulkResource } from "../types";

export interface ILabelsDictionary {
  [label: string]: string | boolean | null;
}
interface IBulkBatchWithFilename {
  status: BulkStatus;
  labels: ILabelsDictionary;
}

/**
 *
 * @param bulkBatch bulk batch response
 * @param resourceType resource type
 * @param options lodash/truncate options
 * @returns string
 */
export function getUploadedFilename(
  bulkBatch: IBulkBatchWithFilename,
  bulkResource: BulkResource,
  options?: TruncateOptions
): string {
  const resourceToFilenameMap: Record<BulkResource, string> = {
    [BulkResource.Payable]: "Payable",
    [BulkResource.Collaborator]: "Contractor",
    [BulkResource.Calculate1099]: "Calculate 1099 Balances"
  };
  if (isNil(bulkBatch)) {
    return "";
  }
  const filename = options
    ? truncate(bulkBatch.labels.filename as string, options)
    : (bulkBatch.labels.filename as string);

  if (bulkBatch.status === BulkStatus.Open) {
    return filename || "";
  } else {
    return filename || `Unnamed ${resourceToFilenameMap[bulkResource]} Batch`;
  }
}
