import { QueryConfig } from "react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation } from "../../helpers";
import { QUERY_PAYEE } from "../../payee/keys";
import {
  QUERY_PAYEE_ENGAGEMENT,
  QUERY_PAYEE_ENGAGEMENTS_LIST_ALL,
  QUERY_PAYEE_ENGAGEMENTS_LIST_SIZE
} from "../keys";
import { IPayeeEngagementResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { updatePayeeEngagement } from "../../../services/payeeEngagement";
import { PayerPayeeEngagementStatus } from "@wingspanhq/payments/dist/interfaces";
import { QUERY_PAYEE_ROWS } from "../../search/payee/queries/usePayeeRowsQuery";
import { QUERY_PAYEE_ROWS_SUMMARY } from "../../search/payee/queries/usePayeeRowsSummaryQuery";

type IPayeeEngagementArchiveParams = {
  payeeId: string;
  engagementIds: string[];
};

export const useArchivePayeeEngagements = (
  config?: QueryConfig<IPayeeEngagementResponse[], WSServiceError>
) =>
  useWSMutation(
    (request: IPayeeEngagementArchiveParams) => {
      const { engagementIds, payeeId } = request;

      return Promise.all(
        engagementIds.map(engagementId =>
          updatePayeeEngagement(payeeId, engagementId, {
            status: PayerPayeeEngagementStatus.Inactive
          })
        )
      );
    },
    {
      ...config,
      dependencies: [
        QUERY_PAYEE_ENGAGEMENT,
        QUERY_PAYEE_ENGAGEMENTS_LIST_SIZE,
        QUERY_PAYEE_ENGAGEMENTS_LIST_ALL,
        QUERY_PAYEE,
        QUERY_PAYEE_ROWS,
        QUERY_PAYEE_ROWS_SUMMARY
      ]
    }
  );
