import { WSFormOld, WSInputMaskOld } from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import React from "react";
import * as Yup from "yup";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import { FormActions } from "./FormActions";
import styles from "./SettingsUpdateForms.module.scss";

interface UpdatePostalCodeFormProps {
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (data: any) => void;
}

export const UpdatePostalCodeForm: React.FC<UpdatePostalCodeFormProps> = ({
  onSubmit,
  defaultValues,
  onCancel
}) => {
  const userId = useUserId();
  const [updateMemberProfile] = useUpdateMemberProfile();
  const onFormSubmit = async (data: any) => {
    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        address: {
          country: "US",
          postalCode: data.postalCode
        }
      }
    };
    await updateMemberProfile(request, {
      onSuccess: () => {
        onSubmit(data);
      }
    });
  };
  return (
    <WSFormOld
      onSubmit={onFormSubmit}
      className={styles.form}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        postalCode: Yup.string()
          .required("This field is required")
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(5, "Must be exactly 5 digits")
          .max(5, "Must be exactly 5 digits")
      })}
    >
      <WSFormOld.Field
        name="postalCode"
        component={WSInputMaskOld}
        componentProps={{
          mask: "99999"
        }}
        label="Zip code"
      />

      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <FormActions onCancel={onCancel} loading={isSubmitting} />
        )}
      </WSFormOld.Context>
    </WSFormOld>
  );
};
