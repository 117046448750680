import flatten from "lodash/flatten";
import { InfiniteQueryConfig } from "react-query";
import { useWSInfiniteQuery } from "../../../query/helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { IInvoiceRow, InvoiceRowsQuery, getInvoiceRows } from "../service";

const PAGE_SIZE = 20;

export const QUERY_KEY_INVOICES_ROWS = "QUERY_KEY_INVOICES_ROWS";

export const useQueryInvoicesRows = (
  requestQuery?: InvoiceRowsQuery,
  config?: InfiniteQueryConfig<IInvoiceRow[], WSServiceError>
) => {
  const query = useWSInfiniteQuery<IInvoiceRow[], WSServiceError>(
    [QUERY_KEY_INVOICES_ROWS, requestQuery],
    (_, __, pageNumber = 1) => {
      return getInvoiceRows({
        ...requestQuery,
        page: {
          size: PAGE_SIZE,
          number: pageNumber
        }
      });
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.length < PAGE_SIZE) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  return {
    ...query,
    data: query.data ? flatten(query.data) : undefined
  };
};
