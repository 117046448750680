import { WSFlexBox, WSPanel, WSPill } from "@wingspanhq/fe-component-library";

import React, { ReactNode } from "react";
import styles from "../styles.module.scss";

interface OriginalValuePreviewProps {
  children: ReactNode;
}

export const OriginalValuePreview: React.FC<OriginalValuePreviewProps> = ({
  children
}) => {
  return (
    <WSPanel noBorder mb="XL" className={styles.originalValue}>
      <WSFlexBox justify="space-between">
        {children}
        <WSPill text="Original" />
      </WSFlexBox>
    </WSPanel>
  );
};
