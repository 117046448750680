import { statementsService } from "./statementsService";
import { IStatementResponse } from "./types";

export const getStatement = async (
  statementId: string
): Promise<IStatementResponse> => {
  const { data } = await statementsService.get(
    `/internal-account-statements/${statementId}`
  );

  return data;
};
