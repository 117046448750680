import * as React from "react";
import {
  detachLayoutProps,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSScreen
} from "@wingspanhq/fe-component-library";
import styles from "./styles.module.scss";
import { LogoApp } from "../../shared/components/LogoApp";
import { HelpCenter } from "../HelpCenter/HelpCenter";
import { SideNavProps } from "./SideNav.component";

interface SideNavHeaderProps extends Omit<SideNavProps, "hideGettingStarted"> {}

export const SideNavHeader: React.FC<SideNavHeaderProps> = ({
  isDrawerOpened,
  setIsDrawerOpened = () => {},
  isSideNavOpened,
  setIsSideNavOpened = () => {},
  ...otherProps
}) => {
  const { layoutProps } = detachLayoutProps(otherProps);

  return (
    <>
      <WSScreen.Mobile>
        <WSFlexBox.CenterY
          justify="space-between"
          className={styles.navigationHeader}
          {...layoutProps}
        >
          <WSElement
            className={styles.hamburgerMenuIconContainer}
            onClick={() => setIsDrawerOpened(!isDrawerOpened)}
            data-testid="hamburgerMenuIcon"
          >
            <WSIcon
              block
              name={isDrawerOpened ? "exit" : "menu"}
              color="gray600"
            />
          </WSElement>
          <LogoApp small />
          <HelpCenter place="header">
            {onClick => (
              <WSFlexBox.Center p="M" onClick={onClick}>
                <WSIcon block name="help-circle" color="gray600" size="M" />
              </WSFlexBox.Center>
            )}
          </HelpCenter>
        </WSFlexBox.CenterY>
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        <WSFlexBox.CenterY
          justify="space-between"
          className={styles.navigationHeader}
          {...layoutProps}
        >
          {isSideNavOpened ? <LogoApp small /> : null}
          <WSIcon
            block
            name="menu"
            color="gray600"
            onClick={() => {
              setIsSideNavOpened(!isSideNavOpened);
            }}
            data-testid="hamburgerMenuIcon"
          />
        </WSFlexBox.CenterY>
      </WSScreen.TabletAndDesktop>
    </>
  );
};
