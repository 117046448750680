import { IMember, INewUser } from "@wingspanhq/users/dist/lib/interfaces";
import { selectorAddressDefaultValues } from "../FormPartialAddress";
import { FormData } from "./types";

export const selectorDefaultValues = (
  user: INewUser,
  member: IMember
): FormData => {
  return {
    firstName: user.profile.firstName || "",
    lastName: user.profile.lastName || "",
    preferredName: user.profile.preferredName || "",
    ssn: "",
    isUSPerson: false,
    // Todo: reach out backend team to add foreignTaxId property to member profile
    foreignTaxId: (member.profile as any).foreignTaxId || "",
    isPersonalAddressTheSame: !member.profile.homeAddress?.country,
    personalAddress: selectorAddressDefaultValues(member.profile.homeAddress),
    dob: user.profile.dob
  };
};
