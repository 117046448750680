import React from "react";
import {
  WSButton,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { LayoutW9 } from "../../../components/LayoutW9";

export const RouteNonUSPersons: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  return (
    <LayoutW9
      title="Confirmation of Your Status as a Non-US Person"
      description="This is a confirmation that you are not a U.S. citizen or other U.S. person, including a resident alien individual."
    >
      <WSMessageBox.Info noBorder mt="XL">
        <WSText.ParagraphSm color="gray500">
          By selecting this status, you are legally declaring that you are not a
          U.S. citizen, U.S. resident, or a U.S. tax resident.
        </WSText.ParagraphSm>
        <WSText.ParagraphSm color="gray500" mt="M">
          If you think this is an error, please{" "}
          <WSButton.Link type="button" onClick={onBack}>
            update your tax information.
          </WSButton.Link>
        </WSText.ParagraphSm>
      </WSMessageBox.Info>
      <WSButton.Primary
        mt="2XL"
        type="button"
        fullWidth
        onClick={async () => {
          onNext();
        }}
      >
        Continue to dashboard
      </WSButton.Primary>
    </LayoutW9>
  );
};
