import {
  toWSDateString,
  toWSMoneyString,
  WSButton,
  WSCentered,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSLayout,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { MemberWithholdingStatus } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Card } from "../../ClientPayments/components/Card/Card";
import { WSQueries } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import {
  useInvoiceTemplateQuery,
  useMemberClientQuery,
  useMemberClientsQuery
} from "../../query/payments/queries";
import {
  getClientCompany,
  getMemberClient
} from "../../query/payments/selectors";
import { useMemberProfile } from "../../query/users/queries";
import { getRedactedMemberName } from "../../query/users/selectors";
import { FirstInvoiceBanner } from "../components/FirstInvoiceBanner/FirstInvoiceBanner";
import { Totals } from "../components/Totals/Totals";
import { calculateLineItemsTotal } from "../utils";
import { useInvoiceFormGoBack } from "../utils/invoicesFormUtils";
import styles from "./InvoicesInvoiceConfirmation.module.scss";
import { useShouldPauseAccount } from "../../shared/hooks/useShouldPauseAccount";

export const InvoicesInvoiceTemplateConfirmation: React.FC<RouteComponentProps<{
  invoiceTemplateId: string;
}>> = ({
  match: {
    params: { invoiceTemplateId }
  }
}) => {
  const history = useHistory();
  const userId = useUserId();
  const memberQuery = useMemberProfile(userId);
  const invoiceTemplateQuery = useInvoiceTemplateQuery(invoiceTemplateId);
  const memberClientQuery = useMemberClientQuery(
    invoiceTemplateQuery.data?.invoiceData.memberClientId as string,
    {
      retry: false,
      enabled: !!invoiceTemplateQuery.data?.invoiceData.memberClientId
    }
  );
  const shouldPauseAccount = useShouldPauseAccount("taxes");
  const memberClientsQuery = useMemberClientsQuery();
  const goBack = useInvoiceFormGoBack();

  return (
    <WSLayout
      headerLeft={
        <WSIcon
          block
          name="arrow-left"
          className={styles.backIcon}
          onClick={goBack}
        />
      }
      headerCenter={<WSText weight="medium">Confirmation</WSText>}
      line
    >
      <WSElement className={styles.main} p="M">
        <WSCentered span={{ m: "4" }}>
          <WSQueries
            queries={{
              memberQuery,
              invoiceTemplateQuery,
              memberClientQuery,
              memberClientsQuery
            }}
          >
            {({
              memberQuery: { data: member },
              invoiceTemplateQuery: { data: invoiceTemplate },
              memberClientQuery: { data: memberClient },
              memberClientsQuery: { data: memberClients }
            }) => {
              const taxWithholdingsEnabled =
                member.profile.withholdings?.tax?.status ===
                MemberWithholdingStatus.Active;

              const totalBilled = calculateLineItemsTotal(
                invoiceTemplate.invoiceData.lineItems
              );
              const collaboratorsAmount = invoiceTemplate.invoiceData
                .collaborators
                ? invoiceTemplate.invoiceData.collaborators.reduce(
                    (total, collaborator) => total + collaborator.amount,
                    0
                  )
                : 0;
              const memberAmount = totalBilled - collaboratorsAmount;
              const withholdingsRate =
                member.profile.withholdings?.tax?.rate || 0;
              const withholdings = memberAmount * (withholdingsRate / 100);

              return (
                <>
                  <FirstInvoiceBanner mb="XL" />

                  <Card mb="XL">
                    <WSText.Heading4 mb="M">
                      {getClientCompany(memberClient)}
                    </WSText.Heading4>

                    <WSText.Heading5 mb="XS">
                      Invoice is scheduled
                    </WSText.Heading5>

                    <WSText color="gray500" mb="XL">
                      {invoiceTemplate.frequency
                        ? `First invoice will be sent ${toWSDateString(
                            invoiceTemplate.scheduleDates?.[0]?.date,
                            "monthDayYear"
                          )}`
                        : `Invoice will be sent ${toWSDateString(
                            invoiceTemplate.scheduleDates?.[0]?.date,
                            "monthDayYear"
                          )}`}
                    </WSText>
                    <WSButton.Secondary
                      fullWidth
                      onClick={() => {
                        history.push(
                          `/member/invoices/template/${invoiceTemplateId}/edit`
                        );
                      }}
                    >
                      {invoiceTemplate.frequency
                        ? "Edit series"
                        : "Edit invoice"}
                    </WSButton.Secondary>
                  </Card>

                  <Card mb="XL">
                    <WSText.Heading5 mb="M" data-testid="paymentTimingText">
                      Payment timing
                    </WSText.Heading5>
                    <WSText color="gray600">
                      Funds are automatically deposited within 7 business days
                      of payment.
                    </WSText>
                  </Card>

                  <Card>
                    <WSText.Heading5 mb="M">Summary</WSText.Heading5>

                    <Totals
                      mb="XL"
                      sections={[
                        {
                          items: [
                            [
                              <WSText>Total billed</WSText>,
                              <WSText formatMoney>{totalBilled}</WSText>
                            ],
                            ...(invoiceTemplate.invoiceData.collaborators
                              ? invoiceTemplate.invoiceData.collaborators.map(
                                  collaborator => {
                                    const memberClient = getMemberClient(
                                      memberClients,
                                      collaborator.memberClientId
                                    );

                                    if (memberClient) {
                                      return [
                                        <WSElement>
                                          <WSText mb="XS">
                                            {getRedactedMemberName(
                                              memberClient.member
                                            )}
                                          </WSText>
                                          <WSText.ParagraphXs>
                                            {collaborator.description}
                                          </WSText.ParagraphXs>
                                        </WSElement>,
                                        <WSText formatMoney>
                                          {-collaborator.amount}
                                        </WSText>
                                      ] as any;
                                    } else {
                                      return [];
                                    }
                                  }
                                )
                              : []),
                            taxWithholdingsEnabled
                              ? [
                                  <WSText color="gray500">
                                    Tax withholdings ({withholdingsRate}%)
                                  </WSText>,
                                  toWSMoneyString(-withholdings)
                                ]
                              : [
                                  <WSFlexBox.CenterY>
                                    <WSText color="gray500">
                                      Tax withholdings
                                    </WSText>
                                    <WSIcon
                                      block
                                      name="alert-circle"
                                      color="amber400"
                                      ml="XS"
                                      size="XS"
                                    />
                                  </WSFlexBox.CenterY>,
                                  "–"
                                ]
                          ]
                        }
                      ]}
                    />
                    {!shouldPauseAccount && !taxWithholdingsEnabled && (
                      <WSPanel className={styles.enableWithholdingsPanel}>
                        <WSFlexBox.CenterY mb="M">
                          <WSIcon
                            block
                            name="alert-circle"
                            color="amber400"
                            mr="M"
                          />
                          <WSText weight="medium">
                            Tax withholdings is not enabled
                          </WSText>
                        </WSFlexBox.CenterY>
                        <WSText.ParagraphXs color="gray600" mb="XL">
                          Enable tax withholdings today to ensure you’re setting
                          aside enough for {new Date().getFullYear()} taxes.
                        </WSText.ParagraphXs>
                        <WSButton
                          size="S"
                          fullWidth
                          className={styles.withholdingsButton}
                          onClick={() => {
                            history.push("/member/taxes");
                          }}
                        >
                          Learn more
                        </WSButton>
                      </WSPanel>
                    )}

                    <WSButton
                      mt="2XL"
                      onClick={() => {
                        history.push("/member/invoices");
                      }}
                      fullWidth
                    >
                      Back to invoices
                    </WSButton>
                  </Card>
                </>
              );
            }}
          </WSQueries>
        </WSCentered>
      </WSElement>
    </WSLayout>
  );
};
