import {
  IPayeeResponse,
  IPayeeUpdateRequest
} from "@wingspanhq/payments/dist/interfaces";
import { QueryConfig } from "react-query";
import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { QUERY_PAYEE } from "../keys";
import { updatePayee } from "../../../services/payees";
import { QUERY_PAYEE_ROWS } from "../../search/payee/queries/usePayeeRowsQuery";
import { QUERY_PAYEE_ROWS_SUMMARY } from "../../search/payee/queries/usePayeeRowsSummaryQuery";

export type PayeeUpdateRequest = IPayeeUpdateRequest & { payeeId: string };

export const useUpdatePayee = (
  config?: QueryConfig<IPayeeResponse, WSServiceError>
) =>
  useWSMutation(
    ({ payeeId, ...request }: PayeeUpdateRequest) =>
      updatePayee(payeeId, request),
    {
      ...config,
      dependencies: [QUERY_PAYEE_ROWS, QUERY_PAYEE_ROWS_SUMMARY],
      awaitDependencies: ({ payeeId }) => [[QUERY_PAYEE, payeeId]]
    }
  );
