import {
  FundingSourceType,
  IPayrollSettings
} from "@wingspanhq/payments/dist/interfaces";
import { IClient } from "@wingspanhq/users/dist/lib/interfaces";

export const selectorFundingSource = (
  payrollSettings: IPayrollSettings,
  client: IClient
):
  | {
      type: FundingSourceType;
      id: string;
    }
  | undefined => {
  if (payrollSettings.fundingSource) {
    return {
      id: payrollSettings.fundingSource.fundingSourceId,
      type: payrollSettings.fundingSource.fundingSourceType
    };
  }

  if (client.profile.defaultPaymentMethod?.accountId) {
    return {
      id: client.profile.defaultPaymentMethod?.accountId,
      type: FundingSourceType.Account
    };
  }

  if (client.profile.defaultPaymentMethod?.paymentMethodId) {
    return {
      id: client.profile.defaultPaymentMethod?.paymentMethodId,
      type: FundingSourceType.PaymentCard
    };
  }
};
