import { IAccount, ICreditCard } from "@wingspanhq/users/dist/lib/interfaces";

export interface Institution {
  name?: string;
  lastSynced?: Date;
  isDisconnected?: boolean;
  id: string;
  accounts: Array<IAccount>;
}

export interface CreditCardBrand {
  name?: string;
  cards: Array<ICreditCard>;
}

export enum PaymentMethodType {
  BANK_ACCOUNT = "BANK_ACCOUNT",
  CREDIT_CARD = "CREDIT_CARD"
}
