import {
  WSButton,
  WSElement,
  WSElementProps,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { PaymentMethodsList } from "../../../../Settings/components/PaymentMethodsList/PaymentMethodsList";
import { useUserId } from "../../../../query/hooks/helpers";
import { useLocalStorage } from "../../../../shared/hooks/useLocalStorage";

type Props = WSElementProps;

export const SendPayments: React.FC<Props> = ({ ...elementProps }) => {
  const userId = useUserId();
  const [isSendPaymentsVisible, setIsSendPaymentsVisible] = useLocalStorage(
    userId + ":settings:isSendPaymentsVisible",
    false
  );

  return (
    <WSElement {...elementProps}>
      <WSText.Heading4 mb="XL">Send payments</WSText.Heading4>

      {isSendPaymentsVisible ? (
        <WSPanel>
          <PaymentMethodsList mb="XL" />

          <WSButton.Link
            onClick={() => {
              setIsSendPaymentsVisible(false);
            }}
            size="S"
          >
            Hide (I don’t send payments)
          </WSButton.Link>
        </WSPanel>
      ) : (
        <WSButton.Link
          size="S"
          onClick={() => {
            setIsSendPaymentsVisible(true);
          }}
          name="showPaymentMethods"
        >
          Show
        </WSButton.Link>
      )}
    </WSElement>
  );
};
