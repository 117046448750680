import cn from "classnames";
import React from "react";
import styles from "./WSElement.module.scss";

const defaultElement: React.ElementType = "div";

export const WSElementSpacings = [
  "NONE",
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "2XL",
  "3XL",
  "4XL",
  "5XL",
  "6XL"
] as const;
export type WSElementSpacingsType = typeof WSElementSpacings[number];

export const WSElementColors = [
  "garnet",
  "black",
  "cream",

  "gray700",
  "gray600",
  "gray500",
  "gray400",
  "gray300",
  "gray200",
  "gray100",
  "gray50",
  "white",

  "blue600",
  "blue500",
  "blue400",
  "blue300",
  "blue200",
  "blue100",
  "blue50",

  "green500",
  "green400",
  "green300",
  "green200",
  "green100",
  "green50",

  "red500",
  "red400",
  "red300",
  "red200",
  "red100",
  "red50",

  "amber500",
  "amber400",
  "amber300",
  "amber200",
  "amber100",
  "amber50",

  "violet500",
  "violet400",
  "violet300",
  "violet200",
  "violet100",
  "violet50",

  "pink500",
  "pink400",
  "pink300",
  "pink200",
  "pink100",
  "pink50",

  "transparent"
] as const;

export type WSElementColorsType = typeof WSElementColors[number];

export const WSElementShadows = ["inner", "S", "M", "L", "XL", "2XL"] as const;
export type WSElementShadow = typeof WSElementShadows[number];

export interface WSMarginProps {
  m?: WSElementSpacingsType;
  mt?: WSElementSpacingsType;
  mr?: WSElementSpacingsType;
  mb?: WSElementSpacingsType;
  ml?: WSElementSpacingsType;
  mx?: WSElementSpacingsType;
  my?: WSElementSpacingsType;
}
export interface WSPaddingProps {
  p?: WSElementSpacingsType;
  pt?: WSElementSpacingsType;
  pr?: WSElementSpacingsType;
  pb?: WSElementSpacingsType;
  pl?: WSElementSpacingsType;
  px?: WSElementSpacingsType;
  py?: WSElementSpacingsType;
}

export const animations = {
  shimmer: styles.shimmer
};

export type WSCustomClickEvents = {
  onControlClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMousewheelClick?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
};

export type WSElementLayoutProps = WSMarginProps & WSPaddingProps;

export type WSElementCustomProps = WSCustomClickEvents & {
  shimmer?: boolean;
  as?: React.ElementType;
  color?: WSElementColorsType;
  colorBackground?: WSElementColorsType;
  shadow?: WSElementShadow;
  fsExclude?: boolean;
};

export type WSElementProps<E = HTMLElement> = WSElementLayoutProps &
  WSElementCustomProps &
  Partial<Omit<React.HTMLAttributes<E>, "color">>;

export const detachLayoutProps = <
  T extends { [k: string]: any } = { [k: string]: any }
>({
  m,
  mt,
  mr,
  mb,
  ml,
  mx,
  my,
  p,
  pt,
  pr,
  pb,
  pl,
  px,
  py,
  as,
  shimmer,
  color,
  colorBackground,
  shadow,
  fsExclude,
  ...htmlElementProps
}: T) => ({
  ...htmlElementProps,
  systemProps: {
    as,
    shimmer,
    color,
    colorBackground,
    shadow,
    fsExclude
  },
  layoutProps: {
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py
  }
});

export const WSElement = React.forwardRef<HTMLElement, WSElementProps>(
  (
    {
      shimmer,
      as = defaultElement,
      children,
      className,
      m,
      mb,
      ml,
      mr,
      mt,
      mx,
      my,
      p,
      pb,
      pl,
      pr,
      pt,
      px,
      py,
      color,
      colorBackground,
      shadow,
      onClick,
      onMouseUp,
      fsExclude,
      onControlClick,
      onMousewheelClick,
      ...other
    },
    ref
  ) => {
    /*
      This is here to capture click events inside of this element.
      If the element has a click event, this function wraps it and stops propagation
      We may want to include this stop propagation for all possible events
    */
    const handleOnClick = (
      event: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
      event.stopPropagation();
      if (onControlClick && (event.ctrlKey || event.metaKey)) {
        onControlClick(event);
      } else if (onClick) {
        onClick(event);
      }
    };

    const handleOnMouseUp = (
      event: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
      event.stopPropagation();
      if (onMousewheelClick && event.button === 1) {
        onMousewheelClick(event);
      } else if (onMouseUp) {
        onMouseUp(event);
      }
    };

    const resetClass: string = styles.reset;
    const spacingClasses = cn(
      styles.base,
      m && styles[`space_M_${m}`],
      mt && styles[`space_MT_${mt}`],
      mr && styles[`space_MR_${mr}`],
      mb && styles[`space_MB_${mb}`],
      ml && styles[`space_ML_${ml}`],
      mx && styles[`space_MX_${mx}`],
      my && styles[`space_MY_${my}`],
      p && styles[`space_P_${p}`],
      pt && styles[`space_PT_${pt}`],
      pr && styles[`space_PR_${pr}`],
      pb && styles[`space_PB_${pb}`],
      pl && styles[`space_PL_${pl}`],
      px && styles[`space_PX_${px}`],
      py && styles[`space_PY_${py}`]
    );

    const classNames = cn(resetClass, className, spacingClasses, {
      [styles.shimmer]: shimmer,
      [styles.clickable]: !!onClick,
      [styles[`color-${color}`]]: shimmer ? false : color,
      [styles[`bg-color--${colorBackground}`]]: shimmer
        ? false
        : colorBackground,
      [styles[`shadow-${shadow}`]]: shimmer ? false : shadow,
      "fs-exclude": fsExclude
    });
    return React.createElement(
      as,
      {
        className: classNames,
        onClick: onClick || onControlClick ? handleOnClick : undefined,
        onMouseUp: onMouseUp || onMousewheelClick ? handleOnMouseUp : undefined,
        ref,
        ...other
      },
      children
    );
  }
);

WSElement.displayName = "WSElement";
