import { WSImage, WSImageProps } from "@wingspanhq/fe-component-library";
import React from "react";
import mutual from "../../../assets/images/collaborator-signature-mutual.svg";
import single from "../../../assets/images/collaborator-signature-single.svg";

type Props = { type: "single" | "mutual" } & Omit<
  WSImageProps,
  "src" | "placeholder"
>;

export const CollaboratorSignatureDiagram: React.FC<Props> = ({
  type,
  ...elementProps
}) => (
  <WSImage
    {...elementProps}
    src={type === "single" ? single : mutual}
    placeholder={<></>}
  />
);
