import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSLoader,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useUserId } from "../../../query/hooks/helpers";
import { usePayoutSettingsDebitCard } from "../../../query/payments/queries";
import { useClientQuery } from "../../../query/users/queries";
import { selectorCreditCardName } from "../../selectors/selectorCreditCardName";
import { selectorDebitCardFullName } from "../../selectors/selectorDebitCardFullName";
import { BankCardLogo } from "../BankCardLogo";
import { selectorPaymentMethod } from "./selectorPaymentMethod";

type Props = {
  type: "credit" | "debit";
  cardId: string;
} & WSElementProps;

export const BankCard: React.FC<Props> = ({
  type,
  cardId,
  ...elementProps
}) => {
  const userId = useUserId();
  const queryClient = useClientQuery(userId, { enabled: type === "credit" });
  const queryPayoutSettingsDebitCard = usePayoutSettingsDebitCard(
    userId,
    cardId,
    {
      enabled: type === "debit"
    }
  );

  if (
    (type === "credit" && !queryClient.data) ||
    (type === "debit" && !queryPayoutSettingsDebitCard.data)
  ) {
    return (
      <WSElement {...elementProps}>
        <WSLoader.Spinner />
      </WSElement>
    );
  }

  const creditCard = queryClient.data
    ? selectorPaymentMethod(queryClient.data, cardId)
    : undefined;

  const debitCard = queryPayoutSettingsDebitCard.data;

  return (
    <WSElement {...elementProps}>
      <WSFlexBox.CenterY wrap="nowrap" justify="space-between">
        <WSFlexBox.CenterY wrap="nowrap" gap="S">
          <BankCardLogo.Circle
            type={type}
            brand={type === "credit" ? creditCard?.cardBrand : debitCard?.brand}
          />
          <WSText kind="ParagraphSm" weight="medium">
            {type === "credit"
              ? selectorCreditCardName(creditCard)
              : selectorDebitCardFullName(debitCard)}
          </WSText>
        </WSFlexBox.CenterY>
      </WSFlexBox.CenterY>
    </WSElement>
  );
};
