import {
  WSButton,
  WSCheckboxToggle,
  WSElement,
  WSFormOld,
  WSInputDateOld,
  WSInputMaskOld,
  WSTextInput,
  useModalContext
} from "@wingspanhq/fe-component-library";
import { IMember, INewUser } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { selectorCountryName } from "../../selectors/selectorCountryName";
import { selectorIsCompany } from "../../selectors/selectorIsCompany";
import { selectorIsDomesticMember } from "../../selectors/selectorIsDomesticMember";
import { selectorMemberCountry } from "../../selectors/selectorMemberCountry";
import { getSsnInputProps } from "../../utils/getSsnInputProps";
import { FormPartialAddress } from "../FormPartialAddress";
import {
  MODAL_SSN_DESCRIPTION,
  ModalPropsSsnDescription
} from "./ModalSsnDescription";
import { MODAL_WHAT_IS_US_PERSON } from "./ModalWhatIsUSPerson";
import { selectorDefaultValues } from "./selectorDefaultValues";
import { selectorIsNameRequired } from "./selectorIsNameRequired";
import { selectorIsPersonalAddressRequired } from "./selectorIsPersonalAddressRequired";
import { selectorIsSsnVisible } from "./selectorIsSsnVisible";
import { FormData } from "./types";
import { getValidationSchema } from "./validationSchema";

const getInternationalTaxIdLabel = (member: IMember) => {
  if (selectorMemberCountry(member) === "CA") {
    return "Canada SIN#";
  }

  return `${selectorCountryName(selectorMemberCountry(member))} Tax ID`;
};

type Props = {
  user: INewUser;
  member: IMember;
  skipTaxVerification?: boolean;
  onSubmit: (data: FormData) => void;
  onUpdateBusinessInfo?: () => void;
  error?: any;
  isLoading?: boolean;
};

export const Form: React.FC<Props> = ({
  user,
  member,
  skipTaxVerification,
  onSubmit,
  onUpdateBusinessInfo,
  error,
  isLoading
}) => {
  const { openModal } = useModalContext();
  const isDomesticMember = selectorIsDomesticMember(member);
  const isCompany = selectorIsCompany(member);
  const isNameRequired = selectorIsNameRequired(member);

  return (
    <WSFormOld<FormData>
      defaultValues={selectorDefaultValues(user, member)}
      validationSchema={getValidationSchema(member, skipTaxVerification)}
      onSubmit={onSubmit}
    >
      <WSFormOld.Field
        name="firstName"
        label={
          isNameRequired ? "Legal first name" : "Legal first name (optional)"
        }
        component={WSTextInput}
        mb="XL"
      />

      <WSFormOld.Field
        name="lastName"
        label={
          isNameRequired ? "Legal last name" : "Legal last name (optional)"
        }
        component={WSTextInput}
        mb="XL"
      />

      <WSFormOld.Field
        hidden={skipTaxVerification}
        name="preferredName"
        label="Preferred name (optional)"
        component={WSTextInput}
        mb="XL"
      />

      <WSFormOld.Field
        hidden={isDomesticMember}
        name="isUSPerson"
        component={WSCheckboxToggle}
        componentProps={{
          label: "I am a US person",
          onInfo: () => {
            openModal(MODAL_WHAT_IS_US_PERSON);
          }
        }}
        mb="XL"
      />

      <WSFormOld.Value name="isUSPerson">
        {isUSPerson => (
          <WSElement
            hidden={
              !selectorIsSsnVisible(member, skipTaxVerification, isUSPerson)
            }
          >
            <WSFormOld.Field
              name="ssn"
              label={isCompany ? "SSN (optional)" : "SSN"}
              component={WSInputMaskOld}
              componentProps={getSsnInputProps()}
              mb="XS"
            />

            <WSButton.Link
              mb="XL"
              type="button"
              onClick={() => {
                openModal(MODAL_SSN_DESCRIPTION, {
                  member,
                  onUpdateBusinessInfo
                } as ModalPropsSsnDescription);
              }}
            >
              What is this used for?
            </WSButton.Link>
          </WSElement>
        )}
      </WSFormOld.Value>

      <WSFormOld.Field
        hidden={isDomesticMember}
        mb="XL"
        name="foreignTaxId"
        label={getInternationalTaxIdLabel(member)}
        component={WSTextInput}
      />

      <WSFormOld.Field
        hidden={!isCompany}
        name="isPersonalAddressTheSame"
        component={WSCheckboxToggle}
        componentProps={{
          label: "My personal address is the same as my business address"
        }}
        mb="XL"
      />

      <WSFormOld.Value name="isPersonalAddressTheSame">
        {isPersonalAddressTheSame => (
          <>
            <FormPartialAddress
              mb="2XL"
              name="personalAddress"
              hidden={
                !selectorIsPersonalAddressRequired(
                  member,
                  isPersonalAddressTheSame
                )
              }
            />
          </>
        )}
      </WSFormOld.Value>

      <WSFormOld.Field
        hidden={skipTaxVerification}
        name="dob"
        label="Birthday"
        component={WSInputDateOld}
        mb="XL"
      />

      <WSErrorMessage mb="XL" contextKey="PaymentsSetup" error={error} />

      <WSButton mt="2XL" fullWidth loading={isLoading} name="next">
        {skipTaxVerification ? "Continue" : "Verify personal details"}
      </WSButton>
    </WSFormOld>
  );
};
