import { WSElement, WSLoader } from "@wingspanhq/fe-component-library";
import React from "react";
import wingspanLogo from "../../assets/images/ws-logo.svg";
import classes from "./styles.module.scss";
import { useCustomization } from "../../modules/customization";

export type LoadingPlaceholderProps = {
  blank?: boolean;
  customLogo?: string;
};

export const LoadingPlaceholder: React.FC<LoadingPlaceholderProps> = ({
  blank,
  customLogo
}) => {
  const { branding } = useCustomization();

  return (
    <div className={classes.loadingPlaceholder}>
      {blank ? (
        <WSLoader.Spinner size="M" />
      ) : (
        <WSElement
          as="img"
          {...{ src: customLogo || branding().primaryLogoUrl || wingspanLogo }}
        />
      )}
    </div>
  );
};
