import { WSAvatarIconProps } from "@wingspanhq/fe-component-library";
import { FrequencyAndScheduleStatus } from "@wingspanhq/payments/dist/interfaces";
import { IInvoiceTemplateRow } from "../../service";

export const buildIcon = (
  invoiceTemplate: IInvoiceTemplateRow
): Omit<WSAvatarIconProps, "type"> =>
  invoiceTemplate.status === FrequencyAndScheduleStatus.Draft
    ? {
        icon: "edit",
        colorBackground: "gray50",
        color: "gray500"
      }
    : {
        icon: "calendar",
        colorBackground: "gray50",
        color: "gray500"
      };
