import React from "react";
import {
  WSButton,
  WSContainer,
  WSFlexBox,
  WSCentered,
  WSImage,
  WSLayout,
  WSText
} from "@wingspanhq/fe-component-library";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { useUpdateActivity } from "../../query/users/mutations";
import { useUserId } from "../../query/hooks/helpers";
import image from "../../assets/images/benefits-landing-preview-desktop-celebration.png";
import imageGif from "../../assets/images/jim-and-pam.gif";
import { useHistory } from "react-router-dom";
import { getPathAfterSuccess } from "../utils";
import { BenefitsLogoHeader } from "../components/BenefitsLogoHeader/BenefitsLogoHeader.module";

export const BenefitsSetUpCelebration: React.FC = () => {
  const history = useHistory();

  const userId = useUserId();

  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);

  useBrowserPageTitle("Benefits - Yes! Your very first app!");

  return (
    <WSLayout headerCenter={<BenefitsLogoHeader />}>
      <WSContainer verticalPadding>
        <WSCentered span={{ m: "8" }}>
          <WSText.Heading4 align="center">
            Yes! Your benefits are processing!
          </WSText.Heading4>
          <WSText align="center" mt="XL">
            We're working hard to process your enrollment which should take 1-2
            days.
          </WSText>
          <WSImage
            mt="3XL"
            placeholder={<WSImage src={image} placeholder={<></>} mb="3XL" />}
            src={imageGif}
            height={330}
          />

          <WSFlexBox.Center mt="2XL">
            <WSButton.Primary
              loading={updateActivityMeta.isLoading}
              name="goToBenefits"
              onClick={async () => {
                await updateActivity({
                  flows: {
                    benefitsSetup: {
                      version: 2,
                      totalSteps: 4,
                      currentStep: 4,
                      complete: true
                    }
                  }
                });

                history.push(getPathAfterSuccess() || "/member/benefits");
              }}
            >
              Go to Benefits
            </WSButton.Primary>
          </WSFlexBox.Center>
        </WSCentered>
      </WSContainer>
    </WSLayout>
  );
};
