import {
  useWSModal,
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSElement,
  WSFormOld,
  WSText,
  WSTextArea
} from "@wingspanhq/fe-component-library";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useTaxFormRejectCorrection } from "../../../query/taxForm/mutations/useTaxFormRejectCorrection";
import { ErrorContextKey } from "../../../services/platform";

const PayerRejectReason: React.FC<{
  taxForm: IPayeeTaxFormResponse;
  onClose(): void;
}> = ({ onClose, taxForm }) => {
  const history = useHistory();
  const [rejectCorrection, rejectCorrectionMeta] = useTaxFormRejectCorrection(
    taxForm.taxFormId
  );

  return (
    <WSElement>
      <WSText weight="medium" mb="XL">
        Please describe your reason
      </WSText>
      <WSFormOld
        defaultValues={{
          includeInternalNote: false,
          details: "",
          teamMemberDetails: ""
        }}
        validationSchema={Yup.object().shape({
          includeInternalNote: Yup.boolean(),
          details: Yup.string().required("Required"),
          teamMemberDetails: Yup.string().when("includeInternalNote", {
            is: true,
            then: Yup.string().required("Required")
          })
        })}
        onSubmit={values => {
          rejectCorrection(
            {
              correctionId: taxForm?.pendingCorrection
                ?.taxFormCorrectionId as string,
              requestData: {
                payerOwnedData: {
                  comment: values.details ?? "",
                  commentInternal: values.teamMemberDetails ?? ""
                }
              }
            },
            {
              onSuccess: () => {
                history.push(
                  `/member/1099-filing/details/${taxForm.taxFormId}`
                );
              }
            }
          );
        }}
      >
        <WSFormOld.Field
          mb="XL"
          name="details"
          component={WSTextArea}
          componentProps={{
            placeholder:
              "Provide details about your reason for rejecting the request for correction."
          }}
        />
        <WSFormOld.Field
          mb="XL"
          name="includeInternalNote"
          component={WSCheckboxToggle}
          componentProps={{
            label: "Include an internal note for your team members"
          }}
        />

        <WSFormOld.Value name="includeInternalNote">
          {includeInternalNote => (
            <WSFormOld.Field
              mb="XL"
              name="teamMemberDetails"
              hidden={!includeInternalNote}
              component={WSTextArea}
              componentProps={{
                placeholder:
                  "Provide details about your reason for rejecting the request for correction for your team members."
              }}
            />
          )}
        </WSFormOld.Value>

        <WSErrorMessage
          mb="XL"
          error={rejectCorrectionMeta.error}
          contextKey={ErrorContextKey.NEC1099PayerRejectCorrection}
        />
        <WSButtons forceFullWidth mb="M">
          <WSFormOld.Values
            names={["includeInternalNote", "details", "teamMemberDetails"]}
          >
            {({ includeInternalNote, details, teamMemberDetails }) => {
              const isValid = includeInternalNote
                ? !!details && !!teamMemberDetails
                : !!details;

              return (
                <WSFormOld.SubmitButton
                  destructive
                  fullWidth
                  loading={rejectCorrectionMeta.isLoading}
                  disabled={!isValid}
                  mb="M"
                >
                  Reject correction
                </WSFormOld.SubmitButton>
              );
            }}
          </WSFormOld.Values>

          <WSButton.Secondary mb="M" onClick={onClose}>
            Cancel
          </WSButton.Secondary>
        </WSButtons>

        <WSText.ParagraphSm color="gray500">
          The recipient will be notified about the rejection for correction and
          the reason you provided.
        </WSText.ParagraphSm>
      </WSFormOld>
    </WSElement>
  );
};

export const useModalPayerRejectReason = () =>
  useWSModal(PayerRejectReason, {
    title: "Reason for rejection",
    size: "S"
  });
