import {
  WSAvatarIconProps,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSText,
  useIsMobile
} from "@wingspanhq/fe-component-library";
import {
  WSDataTypes,
  ICustomField
} from "@wingspanhq/payments/dist/interfaces";
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { RouteCustomFieldDetails } from "../RouteCustomFieldDetails";
import { WSQueries } from "../../../../query/WSQuery";
import { WSFrontendFeature } from "../../../../Settings/utils/subscriptionUtils";
import { WSPersistentUpgradeButton } from "../../../../components/Membership/WSPersistentUpgradeButton";
import { useModalAddCustomField } from "../../components/AddCustomFieldModal/useAddCustomFieldModal";
import {
  CustomFieldResourceTypeRouteSlug,
  CustomFieldResourceTypeSlug,
  ResourceTypeSlugToResourceType
} from "../../utils";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";

export function CustomFieldsTable() {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useIsMobile();
  const match = useRouteMatch<{
    resourceTypeSlug: CustomFieldResourceTypeRouteSlug;
  }>();

  const qCustomFieldList = useCustomFieldsAll({
    resourceType: [
      ResourceTypeSlugToResourceType[match.params.resourceTypeSlug]
    ]
  });

  const addCustomFieldModal = useModalAddCustomField({
    resourceType: ResourceTypeSlugToResourceType[match.params.resourceTypeSlug]
  });

  const columns: Array<WSTableColumn<ICustomField>> = [
    {
      config: {
        gridTemplateSizeMax: "5fr"
      },
      renderFunction: row => (
        <WSTableCell
          avatar={{
            type: "icon",
            icon: row.data.type === WSDataTypes.String ? "report" : "checkbox"
          }}
          text={row.data.name}
        />
      )
    }
  ];

  const resourceTypeSlugToPropsMap = {
    [CustomFieldResourceTypeSlug.Collaborator]: {
      title: "Create custom contractor field",
      description: (
        <>
          <WSText color="gray400" mt="XL">
            Custom contractor fields enable you to store custom data about your
            contractors in Wingspan. These fields can be updated by editing an
            individual contractor, or via the contractor bulk upload.
          </WSText>
          <WSText color="gray400" mt="M">
            Custom fields are visible on the contractor profile and on
            contractor reports. Your contractors can not see or edit the custom
            fields on their profiles.
          </WSText>
        </>
      )
    },
    [CustomFieldResourceTypeSlug.LineItem]: {
      title: "Create custom line item field",
      description: (
        <>
          <WSText color="gray400" mt="XL">
            Custom line item fields enable you to store custom data about your
            line items when creating payables in Wingspan. To create a payable
            that includes custom line item field data, add an additional columns
            titled with the custom field name to the bulk payable upload.
          </WSText>
          <WSText color="gray400" mt="M">
            Custom line item fields are visible on your payable reports. Your
            contractors can not see or edit the custom fields on their payables.
          </WSText>
        </>
      )
    }
  };

  const resourceTypeConfig =
    resourceTypeSlugToPropsMap[match.params.resourceTypeSlug];
  return (
    <>
      <Route
        path={`${match.path}/:customFieldId`}
        component={RouteCustomFieldDetails}
        exact
      />

      <WSQueries queries={{ qCustomFieldList }}>
        {({ qCustomFieldList: customFields }) => {
          const tableData = customFields.data.map(dataItem => {
            return {
              data: dataItem,
              id: dataItem.id
            };
          });

          return (
            <>
              <WSTable
                mt="2XL"
                bottomMessage={
                  tableData.length ? null : (
                    <WSText>
                      No custom fields yet.{" "}
                      <WSPersistentUpgradeButton
                        feature={WSFrontendFeature.InviteAndPayCollaborator}
                        kind="Link"
                        onClick={() => {
                          addCustomFieldModal.open({
                            resourceType:
                              ResourceTypeSlugToResourceType[
                                match.params.resourceTypeSlug
                              ]
                          });
                        }}
                      >
                        Add custom field
                      </WSPersistentUpgradeButton>
                    </WSText>
                  )
                }
                onRowClick={item => {
                  history.push({
                    pathname: `/member/data/custom-fields/${match.params.resourceTypeSlug}/${item.id}`,
                    search: location.search,
                    state: {
                      backPath: `${history.location.pathname}`
                    }
                  });
                }}
                columns={columns}
                tableData={tableData}
              />
              {isMobile && tableData.length ? (
                <WSPersistentUpgradeButton
                  mt="XL"
                  feature={WSFrontendFeature.InviteAndPayCollaborator}
                  kind="Primary"
                  fullWidth
                  onClick={() => {
                    addCustomFieldModal.open({
                      resourceType:
                        ResourceTypeSlugToResourceType[
                          match.params.resourceTypeSlug
                        ]
                    });
                  }}
                >
                  {resourceTypeConfig.title}
                </WSPersistentUpgradeButton>
              ) : null}
              {resourceTypeConfig.description}
            </>
          );
        }}
      </WSQueries>
    </>
  );
}
