import { WSFlexBox, WSLoader } from "@wingspanhq/fe-component-library";
import queryString from "query-string";
import React, { useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useCaptcha } from "../components/Captcha";
import { useCreateSession } from "../query/users/mutations";
import { IS_DEV_ENV } from "../shared/constants/environment";
import { useLoadCaptcha } from "../utils/useLoadCaptcha";

type RouteProps = RouteComponentProps;

type Props = RouteProps;

let EM = "";
let PS = "";

if (IS_DEV_ENV) {
  EM = "test-member-staging@wingspan.test";
  PS = "Password1";

  // EXAMPLE:
  // window.CREATE_SHARE_LINK("1111qqqq")
  // or press alt+s
  // @ts-ignore
  window.CREATE_SHARE_LINK = async (argPassword?: string) => {
    let promptPassword = "";
    if (!argPassword) {
      promptPassword =
        window.prompt("For share link, please enter your password") || "";
    }

    const link =
      window.location.origin +
      `/sign-in-test-user?email=${encodeURIComponent(
        window.userEmail
      )}&path=${encodeURIComponent(
        window.location.pathname
      )}&password=${encodeURIComponent(argPassword || promptPassword)}`;

    if (!argPassword) {
      window.prompt("Please, copy your link", link);
    }

    console.info("Please, copy your link", link);

    return link;
  };

  window.document.onkeydown = keyDownEvent => {
    if (
      keyDownEvent.target === window.document.body &&
      keyDownEvent.altKey &&
      keyDownEvent.code === "KeyS"
    ) {
      // @ts-ignore
      window.CREATE_SHARE_LINK();
    }
  };
}

export const SignInTestUser: React.FC<Props> = () => {
  useLoadCaptcha();
  const history = useHistory();
  const queries = queryString.parse(window.location.search);

  const [signIn, signInMeta] = useCreateSession();
  const callWithCaptcha = useCaptcha("signInTestUser");

  const onSubmit = async ({ token = "", version = "" }) => {
    const values = {
      email: EM,
      password: PS,
      captchaToken: token,
      captchaVersion: version
    };

    const path = queries.path ? (queries.path as string) : "/member/dashboard";

    await signIn(
      {
        ...values,
        email: queries.email ? (queries.email as string) : values.email,
        password: queries.password
          ? (queries.password as string)
          : values.password
      },
      {
        throwOnError: true,
        onSuccess() {
          let index = 0;
          const timer = window.setInterval(() => {
            if (index < 5 && window.location.pathname !== path) {
              ++index;
              history.push(path);
            } else {
              window.clearInterval(timer);
            }
          }, 1000);
        },
        onError() {
          alert("Invalid credentials!");
        }
      }
    );
  };

  useEffect(() => {
    if (queries.t) {
      onSubmit({});
    } else {
      callWithCaptcha(async (captchaToken, captchaVersion) => {
        await onSubmit({ token: captchaToken, version: captchaVersion });
      });
    }
  }, []);

  if (queries.t) {
    return (
      <WSFlexBox.Center py="5XL">
        <WSLoader.Spinner />
      </WSFlexBox.Center>
    );
  }

  return (
    <WSFlexBox.Center py="5XL">
      <WSLoader.Spinner />
    </WSFlexBox.Center>
  );
};
