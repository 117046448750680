import {
  WSButton,
  WSCheckboxToggle,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import { MemberTaxDocumentSharePermission } from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { useWSMutation } from "../../query/helpers";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { QUERY_USERS_ACTIVITIES } from "../../query/users/keys";
import { useActivities } from "../../query/users/queries";
import { get1099OnboardingProgress } from "../../query/users/selectors";
import { paymentsService } from "../../services/payments";
import { usersService } from "../../services/users";
import { SetupRouteComponentProps } from "../../shared/components/FlowSetup";
import { LayoutPaymentsSetup } from "../../shared/components/LayoutPaymentsSetup";
import { WSServiceError } from "../../utils/serviceHelper";
import styles from "./styles.module.scss";

const title = "Confirm electronic copy of Form 1099";

const ConfirmElectronic1099Consent: React.FC<SetupRouteComponentProps & {
  w9shareConsent?: boolean;
  modal?: boolean;
}> = ({ onBack, onNext, w9shareConsent, modal }) => {
  const userId = useUserId();
  const activityQuery = useActivities(userId);
  const featureFlagsQuery = useFeatureFlags();
  const [consent, setConsent] = useState(false);

  const [submit, submitMeta] = useWSMutation<any, WSServiceError>(
    async () => {
      if (w9shareConsent) {
        const memberClients = await paymentsService.memberClient.list();

        await Promise.all(
          memberClients.map(memberClient =>
            paymentsService.memberClient.update(memberClient.memberClientId, {
              memberData: {
                shareTaxDocument: MemberTaxDocumentSharePermission.Allow
              }
            } as any)
          )
        );
      }

      const onboarding1099Progress = get1099OnboardingProgress(
        activityQuery.data
      );

      await usersService.activity.update(userId, {
        flows: {
          onboarding1099: {
            ...onboarding1099Progress,
            complete: true
          }
        },
        events: {
          electronic1099Consent: consent
        }
      });
    },
    {
      dependencies: [QUERY_USERS_ACTIVITIES],
      onSuccess: onNext
    }
  );

  const isButtonDisabled = () => {
    if (featureFlagsQuery.data?.electronic1099ConsentRequired) {
      return !consent;
    }
    return false;
  };

  const content = (
    <>
      <WSText mb="M">
        By utilizing Wingspan's services you agree to and confirm that you
        understand the following:
      </WSText>
      <WSText mb="M" as="ul" className={styles.bulletedList}>
        <li>
          You are opting in to receiving an electronic copy of your Form 1099
          Information Return.
        </li>
        <li>
          You will receive an electronic Form 1099 from each business that has
          paid you via Wingspan, unless they opt out of using our Form 1099
          filing service.
        </li>
        <li>
          This consent to receive an electronic Form 1099 will remain in place
          until you opt out.
        </li>
        <li>
          Any electronic Forms 1099 made available to you will remain available
          for 2 years or until you request that your WIngspan account be closed
          and/or deleted.
        </li>
      </WSText>
      <WSText>
        For any questions, concerns, changes or any other issues, contact
        support@wingspan.app
      </WSText>

      <WSCheckboxToggle
        my="3XL"
        name="consent"
        label="I consent to receive electronic copies of Form 1099."
        value={consent}
        onChange={value => {
          setConsent(value);
        }}
      />

      <WSButton
        disabled={isButtonDisabled()}
        mb="M"
        onClick={submit}
        fullWidth
        name="next"
        loading={submitMeta.isLoading}
      >
        Continue
      </WSButton>
    </>
  );

  return modal ? (
    <WSModal allowClickOutsideToClose onClose={onBack} size="S">
      <WSText.Heading5 my="XL">{title}</WSText.Heading5>
      {content}
    </WSModal>
  ) : (
    <LayoutPaymentsSetup title={title} onBack={onBack}>
      {content}
    </LayoutPaymentsSetup>
  );
};

export default ConfirmElectronic1099Consent;
