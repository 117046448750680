import { IChannelNotificationResponse } from "@wingspanhq/notifications/dist/lib/interfaces/api/notifications";
import { serviceNotifications } from "./serviceNotifications";

export const patchViewNotification = async (
  id: string
): Promise<IChannelNotificationResponse> => {
  const { data } = await serviceNotifications.patch(`/in-app/${id}/view`);

  return data;
};
