import React from "react";
import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSPage,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import {
  useCountersignDocument,
  useSignDocument
} from "../../../../query/files/mutations";
import { useNotifications } from "../../../../query/notifications/queries/useNotifications";
import { selectorSignatureClientNotifications } from "../../selectors/selectorSignatureClientNotifications";
import { selectorSignatureCollaboratorNotifications } from "../../selectors/selectorSignatureCollaboratorNotifications";
import { useUserId } from "../../../../query/hooks/helpers";
import { useTrackClickNotification } from "../../utils/useTrackClickNotification";
import styles from "./styles.module.scss";

type DocumentToSign = {
  title: string;
  description: string;
  actionTitle: string;
  onClick: () => Promise<void>;
};

export const RouteDocumentsToSign: React.FC<{
  type: "client" | "collaborator";
}> = ({ type }) => {
  const history = useHistory();
  const userId = useUserId();
  const trackClickNotification = useTrackClickNotification(userId);
  const queryNotifications = useNotifications();

  const [countersign, countersignMeta] = useCountersignDocument();
  const [signDocument] = useSignDocument();

  return (
    <WSQueries queries={{ queryNotifications }}>
      {({ queryNotificationsData }) => {
        const documents: DocumentToSign[] = [];

        if (type === "collaborator") {
          const notifications = selectorSignatureCollaboratorNotifications(
            queryNotificationsData
          );
          notifications.forEach(notification => {
            const document = notification.context?.document;
            const clientName = notification.context?.client?.companyName;
            const memberClient = notification.context?.memberClient;

            documents.push({
              title: document?.title || "",
              description: `${clientName} requires that you sign this document to complete the onboarding process`,
              actionTitle: "Sign document",
              onClick: async () => {
                trackClickNotification(notification);
                await signDocument({
                  documentId: document?.documentId,
                  memberClientId: memberClient?.memberClientId
                });
              }
            });
          });
        } else {
          const notifications = selectorSignatureClientNotifications(
            queryNotificationsData
          );
          notifications.forEach(notification => {
            const document = notification.context?.document;
            const member = notification.context?.member;
            const memberClient = notification.context?.memberClient;

            documents.push({
              title: document?.title || "",
              description: `Your contractor ${member?.fullName} already signed this document`,
              actionTitle: "Countersign",
              onClick: async () => {
                trackClickNotification(notification);
                await countersign({
                  documentId: document?.documentId,
                  collaboratorId: memberClient?.memberClientId
                });
              }
            });
          });
        }

        return (
          <WSPage
            breadcrumb={{
              label: "Back",
              onClick() {
                history.push("/member/dashboard");
              },
              icon: "arrow-left"
            }}
          >
            <WSText.Heading4 my="XL">
              E-signatures required ({documents.length})
            </WSText.Heading4>
            {documents.length === 0 ? (
              <WSText.ParagraphSm mt="3XL" align="center" color="gray400">
                No documents to sign
              </WSText.ParagraphSm>
            ) : (
              documents.map(({ title, onClick, description, actionTitle }) => {
                return (
                  <WSPanel
                    key={title}
                    mb="M"
                    as="label"
                    className={styles.item}
                  >
                    <WSFlexBox.CenterY justify="space-between">
                      <WSElement>
                        <WSText weight="medium" pb="M">
                          {title}
                        </WSText>
                        <WSText.ParagraphSm>{description}</WSText.ParagraphSm>
                      </WSElement>
                      <WSButton.Secondary size="S" onAsyncClick={onClick}>
                        {actionTitle}
                      </WSButton.Secondary>
                    </WSFlexBox.CenterY>
                  </WSPanel>
                );
              })
            )}
          </WSPage>
        );
      }}
    </WSQueries>
  );
};
