import {
  toWSDateString,
  useWSSnackbar,
  WSButton,
  WSElement,
  WSText
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { DetailsLayout } from "../../../../components/DetailsLayout/DetailsLayout";
import {
  INTEGRATIONS_QUICKBOOKS_SYNC_ACTIVITIES_QUERY_FILTERS_DEFAULT,
  useIntegrationsQuickbooksSyncActivities
} from "../../../../query/integrations/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { openInNewTab } from "../../../../utils/openInNewTab";
import { QuickbooksSyncBadge } from "../../../components/QuickbooksSyncBadge/QuickbooksSyncBadge";
import { getQuickbooksObjectPath } from "../../../utils/quickbooks";
import styles from "./Quickbooks.module.scss";
import { doIntegrationsQuickbooksSyncActivityForceSync } from "../../../../services/integrations";
import { useWSMutation } from "../../../../query/helpers";

import { openIntercom } from "../../../../shared/utils/intercom";

const TWO_MINUTES = 1000 * 60 * 2;

type Props = RouteComponentProps<
  {
    entityId: string;
  },
  any,
  { activityId?: string }
>;

export const QuickbooksActivityDetails: React.FC<Props> = ({
  match,
  location
}) => {
  const history = useHistory();
  const { openSnackbar } = useWSSnackbar();

  const entityId = match.params.entityId;

  const [isForceSyncRunning, setIsForceSyncRunning] = useState(false);
  const [isForceSyncSuccess, setIsForceSyncSuccess] = useState(false);
  const [isForceSyncError, setIsForceSyncError] = useState(false);

  const [forceSync] = useWSMutation(
    async (id: string) => {
      setIsForceSyncError(false);
      setIsForceSyncSuccess(false);
      setIsForceSyncRunning(true);
      try {
        const response = await doIntegrationsQuickbooksSyncActivityForceSync(
          id
        );
        if (response?.status === "success") {
          setIsForceSyncSuccess(true);
          setTimeout(async () => {
            await qIntegrationsQuickbooksSyncActivities.refetch();
            setIsForceSyncSuccess(false);
          }, TWO_MINUTES);
        }
      } catch (err) {
        console.error(`QBO Force Sync Failure`, err);
        setIsForceSyncError(true);
      }
      setIsForceSyncRunning(false);
    },
    {
      dependencies: []
    }
  );

  const qIntegrationsQuickbooksSyncActivities = useIntegrationsQuickbooksSyncActivities(
    {
      ...INTEGRATIONS_QUICKBOOKS_SYNC_ACTIVITIES_QUERY_FILTERS_DEFAULT,
      entityId
    },
    {
      size: 1
    }
  );

  const latestActivity = qIntegrationsQuickbooksSyncActivities.data?.data[0];
  const activityId = latestActivity?.syncActivityId;

  const objectTitle = latestActivity
    ? `${latestActivity.qboEntityName} ${latestActivity.displayName}`
    : "";

  return (
    <DetailsLayout
      onBack={() => {
        history.push({
          pathname: "/member/settings/integrations/quickbooks/history",
          search: location.search
        });
      }}
      title={objectTitle}
    >
      <WSQueries queries={{ qIntegrationsQuickbooksSyncActivities }}>
        {({
          qIntegrationsQuickbooksSyncActivities: {
            data: { data: activities }
          }
        }) => {
          return (
            <>
              {latestActivity && (
                <DetailsLayout.Card
                  title={objectTitle}
                  titleRight={
                    <QuickbooksSyncBadge latestActivity={latestActivity} />
                  }
                  mb="XL"
                >
                  <WSElement mb="XL">
                    <WSText.ParagraphSm mb="XS" color="gray500">
                      Object
                    </WSText.ParagraphSm>
                    <WSText>{latestActivity.qboEntityName}</WSText>
                  </WSElement>

                  <WSElement mb="XL">
                    <WSText.ParagraphSm mb="XS" color="gray500">
                      Name
                    </WSText.ParagraphSm>
                    <WSText>{latestActivity.displayName}</WSText>
                  </WSElement>

                  <WSElement>
                    <WSText.ParagraphSm mb="XS" color="gray500">
                      Status
                    </WSText.ParagraphSm>
                    <WSText>
                      {latestActivity.isError
                        ? `${
                            latestActivity.action
                          } action encountered a failure on ${toWSDateString(
                            latestActivity.createdAt
                          )}`
                        : `${
                            latestActivity.action
                          } action was successful on ${toWSDateString(
                            latestActivity.createdAt
                          )}`}
                    </WSText>
                  </WSElement>

                  {latestActivity.isError && latestActivity.message && (
                    <WSElement mt="XL">
                      <WSText.ParagraphSm mb="XS" color="gray500">
                        Error message
                      </WSText.ParagraphSm>
                      <WSText color="red500">{latestActivity.message}</WSText>
                    </WSElement>
                  )}

                  <WSButton
                    mt="XL"
                    fullWidth
                    onClick={() => {
                      if (latestActivity)
                        openInNewTab(getQuickbooksObjectPath(latestActivity));
                    }}
                  >
                    Open {latestActivity?.qboEntityName.toLocaleLowerCase()}{" "}
                    details
                  </WSButton>
                </DetailsLayout.Card>
              )}
              <DetailsLayout.Card title="Sync history">
                {activities.map((activity, index) => (
                  <WSElement
                    mb={index === activities.length - 1 ? undefined : "S"}
                    key={index}
                    className={classNames({
                      [styles.highlightedActivity]:
                        activity.syncActivityId === activityId
                    })}
                  >
                    <WSText weight="medium" mb="XS" color="gray600">
                      {`${
                        activity.isError ? `Failed to ` : `Successfully `
                      }${activity.action.toLocaleLowerCase()}${
                        activity.isError ? `` : `d`
                      } ${activity.qboEntityName}${
                        activity.message === "MarkedAsPaid"
                          ? ", marked as paid."
                          : ""
                      }`}
                    </WSText>
                    <WSText color="gray500">
                      {toWSDateString(activity.createdAt)}
                    </WSText>
                  </WSElement>
                ))}
                {activityId && (
                  <>
                    <WSButton.Secondary
                      mt="XL"
                      fullWidth
                      loading={isForceSyncRunning || isForceSyncSuccess}
                      onClick={() => {
                        if (latestActivity) forceSync(activityId);
                      }}
                    >
                      Force sync
                    </WSButton.Secondary>
                    {isForceSyncSuccess && (
                      <WSText.ParagraphSm mt="M" color="gray500">
                        Force sync triggered successfully, please wait a few
                        minutes for the sync to complete.
                      </WSText.ParagraphSm>
                    )}
                    {isForceSyncError && (
                      <WSText.ParagraphSm mt="M" color="red400">
                        Sorry! There was an error force syncing this object.
                        Please try again and if the issue persists, please{" "}
                        <WSButton.Link onClick={openIntercom}>
                          contact our support
                        </WSButton.Link>
                        .
                      </WSText.ParagraphSm>
                    )}
                  </>
                )}
              </DetailsLayout.Card>
            </>
          );
        }}
      </WSQueries>
    </DetailsLayout>
  );
};
