import React, { ChangeEvent, ChangeEventHandler } from "react";
import { FileUploader, FileUploaderProps } from "baseui/file-uploader";
import { BaseProvider } from "baseui";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import cn from "classnames";

import { wsBaseWebTheme } from "../../../styles/baseWebTheme";
import {
  detachLayoutProps,
  WSElement,
  WSElementProps
} from "../../WSElement/WSElement.component";
import styles from "./WSFileInput.module.scss";
import { WSButton, WSButtonProps } from "../WSButton/WSButton.component";
import { useIsMobile } from "../../layout";

export interface WSFileInputProps
  extends Omit<WSElementProps<HTMLInputElement>, "onChange"> {
  name: string;
  disabled?: boolean;
  multiple?: boolean;
  inputClassName?: string;
  onChange: ChangeEventHandler;
  accept?: string;
}

export const WSFileInput: React.FC<WSFileInputProps> = (props) => {
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const {
    layoutProps,
    systemProps,
    className = "",
    inputClassName = "",
    children,
    onChange,
    ...inputProps
  } = detachLayoutProps(props);

  const openFileChooserDialog = () => {
    inputFileRef?.current?.click();
  };

  const onClickFileChooser = () => {
    openFileChooserDialog();
  };

  const onChooseFiles = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    onChange(e);
  };

  const containerClassNames = cn(styles.fileInputContainer, className);
  const inputClassNames = cn(
    styles.fileInput,
    {
      [styles.hide]: !!children
    },
    inputClassName
  );

  return (
    <WSElement
      className={containerClassNames}
      {...layoutProps}
      {...systemProps}
    >
      <input
        type="file"
        ref={inputFileRef}
        className={inputClassNames}
        onChange={onChooseFiles}
        {...inputProps}
      />
      {children && (
        <WSElement onClick={onClickFileChooser}>{children}</WSElement>
      )}
    </WSElement>
  );
};

const engine = new Styletron();

export type WSDropFileInputProps = FileUploaderProps & {
  loading?: boolean;
  buttonText: string;
  buttonProps?: WSButtonProps<"Secondary">;
};

export const WSDropFileInput: React.FC<WSDropFileInputProps> = ({
  loading,
  buttonProps,
  buttonText,
  ...props
}) => {
  const isMobile = useIsMobile();

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={wsBaseWebTheme}>
        <FileUploader
          disabled={loading}
          overrides={{
            FileDragAndDrop: {
              style: () =>
                isMobile
                  ? {
                      paddingTop: "none",
                      paddingBottom: "none",
                      paddingLeft: "none",
                      paddingRight: "none",
                      borderTop: "none",
                      borderBottom: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      backgroundColor: "white"
                    }
                  : {}
            },
            ButtonComponent: {
              /* eslint-disable @typescript-eslint/no-unsafe-assignment */
              component: ({ onClick }: any) => (
                <WSButton.Secondary
                  size="S"
                  fullWidth={isMobile}
                  loading={loading}
                  className={styles.dropFileButton}
                  mt={isMobile ? "NONE" : "XL"}
                  onClick={onClick}
                  {...buttonProps}
                >
                  {buttonText}
                </WSButton.Secondary>
              )
              /* eslint-enable @typescript-eslint/no-unsafe-assignment */
            },
            ContentMessage: isMobile
              ? {
                  component: () => null
                }
              : {
                  style: () => ({
                    color: "#4E535E"
                  })
                }
          }}
          {...props}
        />
      </BaseProvider>
    </StyletronProvider>
  );
};
