import { IInternalAccount } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSDivider,
  WSFlexBox,
  WSIcon,
  WSPill,
  useWSModal
} from "@wingspanhq/fe-component-library";
import {
  FundingSourceType,
  IPayrollSettings
} from "@wingspanhq/payments/dist/interfaces";
import { Currency } from "@wingspanhq/users/dist/lib/interfaces";
import React, { useMemo, useState } from "react";
import { useBankingAccount } from "../../../query/bookkeeping/queries";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { QUERY_PAYROLL_SETTINGS } from "../../../query/payments/keys";
import { usePayrollSettings } from "../../../query/payments/queries";
import { QUERY_USERS_CLIENT } from "../../../query/users/keys";
import { useUserProfile } from "../../../query/users/queries";
import { paymentsService } from "../../../services/payments";
import { usersService } from "../../../services/users";
import { MethodWallet } from "../../../shared/components/MethodWallet";
import { selectorIsEnterpriseUser } from "../../../shared/selectors/selectorIsEnterpriseUser";

type Props = {};

export const Wallet: React.FC<Props> = () => {
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryBankingAccount = useBankingAccount();
  const queryPayrollSettings = usePayrollSettings(userId);
  const editModal = useWSModal(ModalEditWallet, {
    size: "S",
    title: "Edit payment method"
  });

  if (
    !queryUser.data ||
    !queryBankingAccount.data ||
    !queryPayrollSettings.data
  )
    return null;

  if (!selectorIsEnterpriseUser(queryUser.data)) return null;

  return (
    <>
      <WSFlexBox
        wrap="nowrap"
        alignItems="center"
        justify="space-between"
        mt="2XL"
        mb="M"
      >
        <MethodWallet />

        <WSFlexBox wrap="nowrap" alignItems="center">
          {queryPayrollSettings.data?.fundingSource?.fundingSourceType ===
            FundingSourceType.InternalAccount && (
            <WSPill text="Default" mr="M" />
          )}
          <WSIcon
            block
            mx="M"
            size="S"
            color="blue400"
            name="edit"
            onClick={() => {
              if (!queryPayrollSettings.data || !queryBankingAccount.data)
                return;
              editModal.open({
                payrollSettings: queryPayrollSettings.data,
                internalAccount: queryBankingAccount.data
              });
            }}
          />
        </WSFlexBox>
      </WSFlexBox>

      <WSDivider />
    </>
  );
};

type ModalEditWalletProps = {
  internalAccount: IInternalAccount;
  payrollSettings: IPayrollSettings;
  onClose: () => void;
};

const ModalEditWallet: React.FC<ModalEditWalletProps> = ({
  internalAccount,
  payrollSettings,
  onClose
}) => {
  const userId = useUserId();
  const initialValue = useMemo(
    () =>
      payrollSettings.fundingSource?.fundingSourceType ===
      FundingSourceType.InternalAccount,
    []
  );
  const [newValue, setNewValue] = useState(initialValue);

  const [updatePaymentMethod, updatePaymentMethodMeta] = useWSMutation(
    async () => {
      if (newValue && !initialValue) {
        await usersService.client.update(userId, {
          clientId: userId,
          profile: {
            defaultPaymentMethod: {
              accountId: null,
              paymentMethodId: null
            }
          }
        });
        await paymentsService.payrollSettings.update(userId, {
          fundingSource: {
            fundingSourceType: FundingSourceType.InternalAccount,
            fundingSourceId: internalAccount.internalAccountId,
            fundingSourceCurrency: Currency.USD
          }
        });
      } else if (!newValue && initialValue) {
        await paymentsService.payrollSettings.update(userId, {
          fundingSource: null
        });
      }
    },
    {
      dependencies: [QUERY_PAYROLL_SETTINGS, QUERY_USERS_CLIENT],
      onSuccess: onClose
    }
  );

  return (
    <>
      <WSCheckboxToggle
        name="defaultPaymentMethod"
        value={newValue}
        label="Default Payment method"
        description="We'll make transactions from the default payment method"
        onChange={setNewValue}
      />

      <WSButtons mt="XL" format="modal">
        <WSButton.Primary
          loading={updatePaymentMethodMeta.isLoading}
          onClick={updatePaymentMethod}
        >
          Update
        </WSButton.Primary>
        <WSButton.Secondary onClick={onClose}>Cancel</WSButton.Secondary>
      </WSButtons>
    </>
  );
};
