import {
  WSButton,
  WSButtons,
  WSText,
  WSTimeline
} from "@wingspanhq/fe-component-library";
import {
  ICollaboratorV2,
  IMemberClient
} from "@wingspanhq/payments/dist/interfaces";
import React, { useCallback, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import LabelValueList, {
  LABEL_COLOR,
  LIST_MT,
  ListValueType
} from "../../../../components/LabelValueList";
import { SidePanelSection } from "../../../../components/SidePanel";
import useSnackbar from "../../../../hooks/useSnackbar";
import { useUpdateMemberClient } from "../../../../query/payments/mutations";
import { getCurrentYearForm1099Balance } from "../../../../query/payments/selectors";
import {
  NEC_1099_PAYMENTS_SLUG,
  NEC_1099_SUMMARY_SLUG
} from "../../../../shared/constants/nec1099";
import { getClientCompanyName, getClientEmail } from "../../../../utils/client";
import { getCurrentFilingYearStr } from "../../../../utils/getCurrentFilingYear";
import TotalsWithSuggestedChange from "../components/TotalsWithSuggestedChange";
import use1099NECDownload from "../hooks/use1099NECDownload";
import getActivityFrom1099Events from "../utils/getActivityFrom1099Events";
import getBalanceFlags from "../utils/getBalanceFlags";
import getTotalsItems from "../utils/getTotalsItems";
import SuggestionFaq from "./SuggestionFaq";

const infoLines = [
  `This is just a summary of the information that will appear on your 1099 form and should not be treated as an official record. If there is an error with your TIN, address, or delivery method, update them in your settings.`,
  `This is NOT the final 1099 and must not be used to calculate your tax liability.`
];

const Details: React.FC<{
  memberClient: IMemberClient;
  client: ICollaboratorV2;
}> = ({ memberClient, client }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const snackbar = useSnackbar();

  const { memberClientId } = memberClient;

  const currentFilingYearStr = useMemo(() => getCurrentFilingYearStr(), []);

  const {
    handle1099NECDownload,
    ConditionalConsentDialog
  } = use1099NECDownload(memberClientId, true);

  const [updateMemberClient, updateMemberClientMeta] = useUpdateMemberClient(
    memberClientId
  );

  const activity = useMemo(
    () =>
      getActivityFrom1099Events(client, snackbar, {
        showPayeeEventsOnly: true
      }),
    [client, snackbar]
  );

  const approve = useCallback(
    () =>
      updateMemberClient({
        form1099Balances: {
          [currentFilingYearStr]: {
            events: { acceptedByCollaboratorAt: new Date() }
          }
        }
      }),
    [currentFilingYearStr, updateMemberClient]
  );

  const form1099Balance = useMemo(() => getCurrentYearForm1099Balance(client), [
    client
  ]);
  if (!form1099Balance) return null;

  const {
    isSuggestionPossible,
    isSuggestionOpen,
    isNotAcceptedByCollaborator,
    isFormNotSubmitted,
    isCorrectionPossible,
    latest1099NECIndex
  } = getBalanceFlags(form1099Balance);

  const companyName = getClientCompanyName(memberClient as IMemberClient);
  const clientEmail = getClientEmail(memberClient as IMemberClient);
  const phoneNumber = memberClient?.client?.user?.phone?.number;

  const payerInfoItems = [
    {
      label: "Name",
      value: companyName || "-"
    },
    {
      label: "Phone",
      value: phoneNumber || "-"
    },
    {
      label: "Email address",
      value: clientEmail || "-"
    }
  ].map(item => ({ ...item, id: item.label, type: ListValueType.Text }));

  return (
    <>
      <ConditionalConsentDialog />

      <SidePanelSection title={companyName} first>
        {isSuggestionOpen ? (
          <TotalsWithSuggestedChange
            items={getTotalsItems(form1099Balance)}
            newValue={form1099Balance?.dispute?.amount}
          />
        ) : (
          <LabelValueList items={getTotalsItems(form1099Balance)} />
        )}
      </SidePanelSection>

      <SidePanelSection title="Payer information">
        <LabelValueList items={payerInfoItems} />
      </SidePanelSection>

      <SidePanelSection>
        <WSButton.Tertiary
          fullWidth
          onClick={() => {
            // TODO: In future, we should get filing year from URL param
            history.push(
              [
                "/member/invoices/contacts/clients",
                memberClientId,
                NEC_1099_SUMMARY_SLUG,
                currentFilingYearStr,
                NEC_1099_PAYMENTS_SLUG
              ].join("/")
            );
          }}
        >
          View payments
        </WSButton.Tertiary>

        {isSuggestionPossible && (
          <WSButton.Secondary
            fullWidth
            mt="XL"
            disabled={updateMemberClientMeta.isLoading}
            onClick={() => {
              history.push(`${match.url}/dispute`);
            }}
          >
            Suggest change
          </WSButton.Secondary>
        )}

        {isCorrectionPossible && (
          <WSButton.Secondary
            fullWidth
            mt="XL"
            disabled={updateMemberClientMeta.isLoading}
            onClick={() => {
              history.push(`${match.url}/dispute`);
            }}
          >
            Suggest correction
          </WSButton.Secondary>
        )}

        {isFormNotSubmitted &&
          isNotAcceptedByCollaborator &&
          !isSuggestionOpen && (
            <WSButton.Primary
              fullWidth
              mt="XL"
              disabled={updateMemberClientMeta.isLoading}
              loading={updateMemberClientMeta.isLoading}
              onClick={approve}
            >
              Approve draft summary
            </WSButton.Primary>
          )}

        {typeof latest1099NECIndex === "number" && (
          <WSButton.Primary
            fullWidth
            mt="XL"
            disabled={updateMemberClientMeta.isLoading}
            onClick={() => handle1099NECDownload(latest1099NECIndex)}
          >
            {latest1099NECIndex === 0
              ? "Download 1099-NEC"
              : "Download latest 1099-NEC"}
          </WSButton.Primary>
        )}

        {isFormNotSubmitted && <SuggestionFaq />}

        {isFormNotSubmitted &&
          infoLines.map(line => (
            <WSText key={line.substring(0, 40)} mt="XL" color="gray500">
              {line}
            </WSText>
          ))}
      </SidePanelSection>

      <SidePanelSection title="Activity">
        {activity.length > 0 ? (
          <WSTimeline
            items={activity.map(item => ({
              date: item.date,
              badges: item.badges,
              content: (
                <>
                  <WSText>{item.content}</WSText>
                  {item.moreActions && item.moreActions.length > 0 && (
                    <WSButtons mt="XL">
                      {item.moreActions.map((action, index) => (
                        <WSButton.Link key={String(index)} {...action} />
                      ))}
                    </WSButtons>
                  )}
                </>
              )
            }))}
            mt={LIST_MT}
          />
        ) : (
          <WSText mt={LIST_MT} color={LABEL_COLOR}>
            No events found
          </WSText>
        )}
      </SidePanelSection>
    </>
  );
};

export default Details;
