import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSForm,
  WSIcon,
  WSInputText,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import styles from "./AdditionalEmailsField.module.scss";

type Props = WSElementProps & {
  name: string;
  inputLabel: string;
  buttonLabel: string;
  buttonDescription: string;
  disabled?: boolean;
};

export const AdditionalEmailsField: React.FC<Props> = ({
  name,
  inputLabel,
  buttonLabel,
  buttonDescription,
  disabled,
  ...elementProps
}) => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: name
  });

  return (
    <WSElement {...elementProps}>
      {fields.map((email, index) => (
        <WSFlexBox.CenterY wrap="nowrap" key={`${index}_${email.id}`} mb="XL">
          <WSForm.Field
            mr="M"
            name={`${name}[${index}].email`}
            label={`${inputLabel} ${index + 1}`}
            component={WSInputText}
            className={styles.input}
            componentProps={{
              disabled
            }}
            defaultValue={email.email}
          />
          <WSIcon
            block
            name="minus-circle"
            onClick={() => {
              if (!disabled) {
                remove(index);
              }
            }}
            color="blue400"
            mt="L"
          />
        </WSFlexBox.CenterY>
      ))}

      <WSButton.Link
        size="M"
        mb="XS"
        type="button"
        icon="plus-circle"
        onClick={() => {
          append({ email: "" });
        }}
        disabled={disabled}
      >
        {buttonLabel}
      </WSButton.Link>
      <WSText.ParagraphXs
        color="gray500"
        onClick={() => {
          append({ email: "" });
        }}
      >
        {buttonDescription}
      </WSText.ParagraphXs>
    </WSElement>
  );
};
