import cn from "classnames";
import React, { ReactElement } from "react";
import {
  detachLayoutProps,
  WSElement,
  WSElementProps
} from "../../WSElement/WSElement.component";
import { WSFormDescription } from "./WSFormDescription.component";
import { WSFormError } from "./WSFormError.component";
import styles from "./WSFormField.module.scss";
import { WSFormFieldContext } from "./WSFormFieldContext";
import { WSFormInput } from "./WSFormInput.component";
import { WSFormLabel } from "./WSFormLabel.component";

export interface WSFormFieldProps<ComponentProps = any> extends WSElementProps {
  name: string;
  label?: string;
  description?: string;
  component?: React.FC<ComponentProps>;
  componentProps?: Omit<
    ComponentProps,
    "name" | "value" | "onChange" | "error"
  > & {
    onChange?: (...args: any) => void;
  };
  hidden?: boolean;
}

export type WSFormFieldComponent = <ComponentProps = any>(
  props: WSFormFieldProps<ComponentProps>
) => ReactElement<any, any> | null;

export const WSFormField: WSFormFieldComponent = ({
  name,
  children,
  className,
  label,
  description,
  component,
  componentProps,
  hidden,
  defaultValue,
  ...otherProps
}) => {
  const { layoutProps } = detachLayoutProps(otherProps);
  const Component = component;

  return (
    <WSFormFieldContext.Provider value={name}>
      <WSElement
        as="div"
        className={cn(className, { [styles.hidden]: hidden })}
        {...layoutProps}
      >
        {Component ? (
          <>
            {label && <WSFormLabel>{label}</WSFormLabel>}
            <WSFormInput
              render={({ onChange, ...inputProps }) => (
                <Component
                  {...inputProps}
                  {...(componentProps ? (componentProps as any) : {})}
                  onChange={(...args: any) => {
                    onChange(...args);
                    componentProps?.onChange?.(...args);
                  }}
                />
              )}
              defaultValue={defaultValue}
            />
            <WSFormError name={name} />
            {description && (
              <WSFormDescription>{description}</WSFormDescription>
            )}
          </>
        ) : (
          children
        )}
      </WSElement>
    </WSFormFieldContext.Provider>
  );
};
