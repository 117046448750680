import {
  WSActions,
  WSForm,
  WSInputText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import {
  contactCompanyValidator,
  contactNameValidator
} from "../../../../utils/validators";
import { IPayeeResponse } from "@wingspanhq/payments/dist/interfaces";
import { WSQueries } from "../../../../query/WSQuery";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUserProfile } from "../../../../query/users/queries";
import { selectorIsEnterpriseUser } from "../../../../shared/selectors/selectorIsEnterpriseUser";
import { useUpdatePayee } from "../../../../query/payee/mutations/useUpdatePayee";

export interface EditContractorModalProps {
  payee: IPayeeResponse;

  onClose(result: boolean): void;
}

export const EditContractorModal: React.FC<EditContractorModalProps> = ({
  onClose,
  payee
}) => {
  const userId = useUserId();
  const queryUserProfile = useUserProfile(userId);

  const [updatePayee, updatePayeeMeta] = useUpdatePayee();

  return (
    <WSQueries
      queries={{
        queryUserProfile
      }}
    >
      {({ queryUserProfileData }) => {
        const isEnterpriseUser = selectorIsEnterpriseUser(queryUserProfileData);

        return (
          <WSForm
            defaultValues={{
              company: payee.payerOwnedData.payeeCompanyName,
              name: payee.payerOwnedData.payeeName,
              externalId: payee.payerOwnedData.payeeExternalId
            }}
            validationSchema={Yup.object().shape({
              company: contactCompanyValidator,
              name: contactNameValidator,
              externalId: Yup.string()
            })}
            onSubmit={async data => {
              const result = await updatePayee({
                payeeId: payee.payeeId,
                payerOwnedData: {
                  payeeCompanyName: data.company,
                  payeeName: data.name,
                  payeeExternalId: data.externalId
                }
              });

              if (result) {
                onClose(true);
              }
            }}
          >
            <WSForm.Field
              mb="L"
              name="company"
              label="Contractor company"
              component={WSInputText}
              componentProps={{
                placeholder: "Contractor company"
              }}
            />

            <WSForm.Field
              mb="L"
              name="name"
              label="Contractor full name"
              component={WSInputText}
              componentProps={{
                placeholder: "Contractor full name"
              }}
            />

            {isEnterpriseUser && (
              <WSForm.Field
                mb="L"
                name="externalId"
                label="External ID"
                component={WSInputText}
              />
            )}

            <WSActions
              mt="3XL"
              alignment="fill"
              buttons={[
                {
                  label: "Update contractor",
                  kind: "Primary",
                  type: "submit",
                  loading: updatePayeeMeta.isLoading
                },
                {
                  label: "Cancel",
                  kind: "Secondary",
                  type: "button",
                  onClick() {
                    onClose(false);
                  }
                }
              ]}
            />
          </WSForm>
        );
      }}
    </WSQueries>
  );
};
