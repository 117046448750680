import {
  WSButton,
  WSFlexBox,
  WSFlexBoxProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import styles from "./FieldView.module.scss";

export type FieldViewProps = WSFlexBoxProps & {
  label: string;
  value?: React.ReactNode;
  action?: {
    label: string;
    onClick: () => void;
  };
};

export const FieldView: React.FC<FieldViewProps> = ({
  label,
  value = "-",
  action,
  ...otherProps
}) => {
  return (
    <WSFlexBox
      direction="column"
      gap="S"
      alignItems="stretch"
      className={styles.fieldView}
      {...otherProps}
    >
      <WSFlexBox.CenterY
        gap="S"
        justify="space-between"
        className={styles.labelWrapper}
      >
        <WSText.ParagraphSm color="gray500" singleLine>
          {label}
        </WSText.ParagraphSm>
        {action && (
          <WSButton.Link size="S" onClick={action.onClick}>
            {action.label}
          </WSButton.Link>
        )}
      </WSFlexBox.CenterY>
      <WSText.ParagraphSm
        as={React.isValidElement(value) ? "div" : undefined}
        color="gray700"
        singleLine
      >
        {value}
      </WSText.ParagraphSm>
    </WSFlexBox>
  );
};
