import {
  useIsMobile,
  useModalContext,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { track } from "../../../utils/analytics";
import { addNotification } from "../../../components/Notification/Notification";
import { useBookkeepingFiltersYear } from "../dashboard/BookkeepingIndex";
import { CURRENT_TAX_DOCUMENTS_YEAR } from "../../../shared/utils/taxes";

export const TAXES_MODAL_REQUEST_TAX_SUMMARY_SHEET =
  "TAXES_MODAL_REQUEST_TAX_SUMMARY_SHEET";

export const TaxesModalRequestTaxSummarySheet: React.FC = () => {
  const { closeModal } = useModalContext();
  const isMobile = useIsMobile();

  const currentYear = useBookkeepingFiltersYear();

  return (
    <WSModal name={TAXES_MODAL_REQUEST_TAX_SUMMARY_SHEET} size="S">
      <WSText.Heading5 mb="M">
        Generate {currentYear} Tax Summary Sheet?
      </WSText.Heading5>
      <WSText mb="XS">
        Requests will be processed within 24 hours and sent to your email.
      </WSText>
      <WSButtons mt="2XL" format="modal">
        <WSButton.Primary
          ml={isMobile ? "NONE" : "M"}
          mt={isMobile ? "M" : "NONE"}
          fullWidth={isMobile}
          onClick={async () => {
            track("Tax Summary Sheet Requested", { year: currentYear });
            closeModal(TAXES_MODAL_REQUEST_TAX_SUMMARY_SHEET);
            addNotification({
              text: `${currentYear} Tax Summary Sheet requested`
            });
          }}
        >
          Request Sheet
        </WSButton.Primary>
        <WSButton.Tertiary
          fullWidth={isMobile}
          onClick={() => {
            closeModal(TAXES_MODAL_REQUEST_TAX_SUMMARY_SHEET);
          }}
        >
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </WSModal>
  );
};
