import { cardsService } from "./cardsService";
import { ICardEmbedUrlResponse } from "./types";

export const getCardEmbedUrl = async (
  paymentCardId: string,
  pathToCSS: string
): Promise<ICardEmbedUrlResponse> => {
  const { data } = await cardsService.get(`${paymentCardId}/embed_url`, {
    params: {
      css: pathToCSS
    }
  });

  return data;
};
