import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";
import {
  WSButton,
  WSButtonProps,
  WSFlexBox,
  WSIcon,
  WSPill,
  WSText,
  useIsMobile,
  useWSSnackbar
} from "@wingspanhq/fe-component-library";
import {
  INewUser,
  ISubscription,
  SubscriptionPackage,
  SubscriptionPackageTier,
  SubscriptionStatus,
  SubscriptionTerm
} from "@wingspanhq/users/dist/lib/interfaces";
import { formatMoney } from "accounting";
import cn from "classnames";
import differenceInDays from "date-fns/differenceInDays";
import React from "react";
import { useHistory } from "react-router";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useCancelSubscriptionV3,
  useCreateOrUpdateSubscriptionV3
} from "../../../query/subscriptions/mutations";
import {
  useGetPaymentMethod,
  useSubscriptionV3Query
} from "../../../query/subscriptions/queries";
import { useUserProfile } from "../../../query/users/queries";
import {
  getSegmentAnonymousIdFromLocalStorage,
  track
} from "../../../utils/analytics";
import {
  IDisplaySubscriptionPackage,
  PackageTierTerm,
  getCurrentPackage,
  isSubscriptionCanceled,
  isSubscriptionTrialing
} from "../../utils/subscriptionUtils";
import styles from "./MembershipPackageAddOnCard.module.scss";
import { FREE_TRIAL_DAYS } from "./subscriptionPackages";

export interface MembershipPackageAddOnCardProps {
  subscriptionPackage: IDisplaySubscriptionPackage;
  isAnnualBilling: boolean;
}

export const MembershipPackageAddOnCard: React.FC<MembershipPackageAddOnCardProps> = ({
  isAnnualBilling,
  subscriptionPackage
}) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const userId = useUserId();
  const userProfileQuery = useUserProfile(userId);
  const subscriptionQuery = useSubscriptionV3Query(userId);
  const [
    createOrUpdateSubscription,
    createOrUpdateSubscriptionMeta
  ] = useCreateOrUpdateSubscriptionV3(userId);
  const [cancelSubscription, cancelSubscriptionMeta] = useCancelSubscriptionV3(
    userId
  );
  const { openSnackbar } = useWSSnackbar();
  const qPaymentMethod = useGetPaymentMethod();

  const {
    title,
    description,
    valuePropostions,
    isPopular,
    isAddOn
  } = subscriptionPackage;
  const packageTerm = isAnnualBilling
    ? SubscriptionTerm.Yearly
    : SubscriptionTerm.Monthly;
  const subscription = subscriptionQuery.data as ISubscription;
  const paymentMethod = qPaymentMethod.data;

  const isCurrentPackage = () => {
    const currentPackage = getCurrentPackage(subscription) as PackageTierTerm;
    return (
      currentPackage &&
      subscriptionPackage.package === currentPackage.package &&
      subscriptionPackage.tier === currentPackage.packageTier &&
      packageTerm === currentPackage.term
    );
  };

  const formatPackageAmount = (amount: number) => {
    const amountPerMonth = isAnnualBilling ? amount / 12 : amount;
    return formatMoney(amountPerMonth, {
      symbol: "$",
      precision: amount % 12 === 0 || !isAnnualBilling ? 0 : 2
    });
  };

  const renderPriceIncludedText = () => {
    if (subscriptionPackage.package === SubscriptionPackage.Benefits) {
      if (isBenefitsAddOnIncluded()) {
        return (
          <WSText.ParagraphSm weight="medium" mt="XS" ml="XS">
            (incl.)
          </WSText.ParagraphSm>
        );
      }
    }
    return null;
  };

  const renderPackagePrice = () => {
    let content: React.ReactNode = "";
    if (subscriptionPackage.package === SubscriptionPackage.None) {
      if (
        subscriptionPackage.tier === SubscriptionPackageTier.Basic &&
        !subscriptionPackage.isAddOn
      ) {
        content = "Free";
      } else {
        content = "Let's talk";
      }
    } else {
      content = `${formatPackageAmount(
        subscriptionPackage[packageTerm].amount
      )}/mo`;
    }
    return (
      <WSFlexBox.Center mb="M" className={styles.price}>
        <WSText.Heading1>{content}</WSText.Heading1>
        {renderPriceIncludedText()}
      </WSFlexBox.Center>
    );
  };

  const contactSalesTeam = () => {
    const anonymousId = getSegmentAnonymousIdFromLocalStorage();
    const {
      email,
      profile: { firstName, lastName }
    } = userProfileQuery.data as INewUser;
    const fullName = `${firstName} ${lastName}`;
    const typeFormReference = createPopup(
      `https://wingspan.typeform.com/to/Y5xaebW6#name=${fullName}&email=${email}&userid=${userId}&anonymousid=${anonymousId}`,
      {
        onSubmit: responseId => {
          track("Request for Enterprise subscription plan", {
            responseId: responseId
          });
        }
      }
    );
    typeFormReference.open();
  };

  const canRedirectToTrialSuccess = (subscription: ISubscription) => {
    const isTrialing = isSubscriptionTrialing(subscription);
    let date = new Date();
    if (
      subscription.scheduledChanges &&
      subscription.scheduledChanges?.length > 0 &&
      subscription.scheduledChanges[0].effectiveDate
    ) {
      date = subscription.scheduledChanges[0].effectiveDate;
    }
    const diffInDays = differenceInDays(date, new Date());
    if (isTrialing && diffInDays >= FREE_TRIAL_DAYS - 1) {
      return true;
    }
    return false;
  };

  const createOrUpdateMembership = async () => {
    await createOrUpdateSubscription(
      {
        package: subscriptionPackage.package as SubscriptionPackage,
        packageTier: subscriptionPackage.tier as SubscriptionPackageTier,
        term: packageTerm
      },
      {
        onSuccess: subscription => {
          if (canRedirectToTrialSuccess(subscription)) {
            history.push("/member/subscription/trial-success");
          } else if (!paymentMethod?.paymentMethodId) {
            history.push("/member/settings/account/membership?editPm=1");
          } else {
            history.push("/member/settings/account");
          }
        },
        onError: () => {
          openSnackbar({
            message: String(
              createOrUpdateSubscriptionMeta.error?.response?.data?.error ||
                "Something went wrong. Please try again!"
            ),
            type: "warning"
          });
        }
      }
    );
  };

  const isBenefitsAddOnIncluded = () => {
    return (
      subscription && subscription.package === SubscriptionPackage.Professional
    );
  };

  const hasBenefitsAddOn = () => {
    const isScheduled =
      (subscription?.scheduledChanges || [])?.filter(
        sc =>
          sc.status !== SubscriptionStatus.canceled &&
          sc.package === SubscriptionPackage.Benefits
      ).length > 0;
    return (
      isScheduled ||
      (subscription &&
        subscription.package === SubscriptionPackage.Benefits &&
        [SubscriptionStatus.active, SubscriptionStatus.trialing].includes(
          subscription.status
        ))
    );
  };

  const hasDedicatedCPAAddon = () => {
    return (
      subscription &&
      subscription.package === SubscriptionPackage.Professional &&
      subscription.packageTier === SubscriptionPackageTier.Premium &&
      [SubscriptionStatus.active, SubscriptionStatus.trialing].includes(
        subscription.status
      )
    );
  };

  const renderIndividualsPackageCTA = () => {
    let buttonProps: WSButtonProps = {
      fullWidth: true,
      rightIcon: isMobile ? "arrow-right" : undefined,
      disabled: true
    };
    let buttonText: React.ReactNode = "Current plan";
    if (subscription) {
      buttonProps.disabled = false;
      buttonText = "Downgrade";
    }
    return (
      <WSButton
        textClassName={styles.btnText}
        name="selectPackage"
        loading={cancelSubscriptionMeta.isLoading}
        onClick={async () => {
          await cancelSubscription();
          history.push("/member/settings/account");
        }}
        {...buttonProps}
      >
        {buttonText}
      </WSButton>
    );
  };

  const renderBusinessPackageCTA = () => {
    let buttonProps: WSButtonProps = {
      fullWidth: true,
      rightIcon: isMobile ? "arrow-right" : undefined
    };
    let buttonText: React.ReactNode = "Choose plan";
    if (!subscription) {
      buttonText = "Start free trial";
    } else if (isCurrentPackage() && isSubscriptionCanceled(subscription)) {
      buttonText = "Reactivate";
    } else if (isCurrentPackage() || hasDedicatedCPAAddon()) {
      buttonText = "Current plan";
      buttonProps = {
        ...buttonProps,
        rightIcon: undefined,
        disabled: true
      };
    }
    return (
      <WSButton
        textClassName={styles.btnText}
        name="selectPackage"
        loading={createOrUpdateSubscriptionMeta.isLoading}
        onClick={createOrUpdateMembership}
        {...buttonProps}
      >
        {buttonText}
      </WSButton>
    );
  };

  const renderEnterprisePackageCTA = () => {
    return (
      <WSButton.Secondary
        textClassName={styles.btnText}
        name="selectPackage"
        loading={createOrUpdateSubscriptionMeta.isLoading}
        onClick={contactSalesTeam}
        fullWidth
        rightIcon={isMobile ? "arrow-right" : undefined}
      >
        Contact us
      </WSButton.Secondary>
    );
  };

  const renderBenefitsAddOnCTA = () => {
    let buttonProps: WSButtonProps<"Secondary"> = {
      fullWidth: true,
      rightIcon: isMobile ? "arrow-right" : undefined
    };
    let buttonText: React.ReactNode = "Add to plan";
    if (isCurrentPackage() && isSubscriptionCanceled(subscription)) {
      buttonText = "Re-add to plan";
    } else if (hasBenefitsAddOn()) {
      buttonText = "Remove from plan";
    } else if (isBenefitsAddOnIncluded()) {
      buttonText = "Included";
      buttonProps.disabled = true;
    }
    return (
      <WSButton.Secondary
        textClassName={styles.btnText}
        name="selectPackage"
        loading={
          createOrUpdateSubscriptionMeta.isLoading ||
          cancelSubscriptionMeta.isLoading
        }
        onClick={async () => {
          if (
            subscription &&
            isCurrentPackage() &&
            isSubscriptionCanceled(subscription)
          ) {
            await createOrUpdateMembership();
          } else if (hasBenefitsAddOn()) {
            // cancel subscription
            await cancelSubscription();
            history.push("/member/settings/account");
            return;
          }
          await createOrUpdateMembership();
        }}
        {...buttonProps}
      >
        {buttonText}
      </WSButton.Secondary>
    );
  };

  const renderDedicatedCPAAddon = () => {
    let buttonProps: WSButtonProps<"Secondary"> = {
      fullWidth: true,
      rightIcon: isMobile ? "arrow-right" : undefined
    };
    let buttonText: React.ReactNode = "Add to plan";
    if (
      subscription &&
      isCurrentPackage() &&
      isSubscriptionCanceled(subscription)
    ) {
      buttonText = "Re-add to plan";
    } else if (hasDedicatedCPAAddon()) {
      buttonText = "Remove from plan";
    }
    return (
      <WSButton.Secondary
        textClassName={styles.btnText}
        name="selectPackage"
        loading={
          createOrUpdateSubscriptionMeta.isLoading ||
          cancelSubscriptionMeta.isLoading
        }
        onClick={async () => {
          if (isCurrentPackage() && isSubscriptionCanceled(subscription)) {
            await createOrUpdateMembership();
          } else if (hasDedicatedCPAAddon()) {
            // cancel subscription
            await cancelSubscription();
            history.push("/member/settings/account");
            return;
          }
          await createOrUpdateMembership();
        }}
        {...buttonProps}
      >
        {buttonText}
      </WSButton.Secondary>
    );
  };

  const renderPackageCTA = () => {
    // Individuals plan
    if (
      subscriptionPackage.package === SubscriptionPackage.None &&
      subscriptionPackage.tier === SubscriptionPackageTier.Basic &&
      !subscriptionPackage.isAddOn
    ) {
      return renderIndividualsPackageCTA();
    }
    // Business plan
    else if (
      subscriptionPackage.package === SubscriptionPackage.Professional &&
      subscriptionPackage.tier === SubscriptionPackageTier.Basic
    ) {
      return renderBusinessPackageCTA();
    }
    // Enterprise plan || S-Corp Payroll (mimicking the pkg & tier values)
    else if (
      (subscriptionPackage.package === SubscriptionPackage.None &&
        subscriptionPackage.tier === SubscriptionPackageTier.Premium) ||
      (subscriptionPackage.package === SubscriptionPackage.None &&
        subscriptionPackage.tier === SubscriptionPackageTier.Basic &&
        subscriptionPackage.isAddOn)
    ) {
      return renderEnterprisePackageCTA();
    }
    // Benefits add-on
    else if (subscriptionPackage.package === SubscriptionPackage.Benefits) {
      return renderBenefitsAddOnCTA();
    }
    // Dedicated CPA add-on
    else if (
      subscriptionPackage.package === SubscriptionPackage.Professional &&
      subscriptionPackage.tier === SubscriptionPackageTier.Premium
    ) {
      return renderDedicatedCPAAddon();
    }
  };

  return (
    <WSFlexBox
      direction="column"
      className={cn(styles.subscriptionPackageCard, {
        [styles.popular]: isPopular
      })}
      p="M"
      justify="flex-end"
      data-testid={title}
    >
      <WSFlexBox justify="space-between" mb="M">
        <WSText.Heading5 mr="M">{title}</WSText.Heading5>
        {isPopular && <WSPill theme="blue" icon="reward" text="MOST POPULAR" />}
      </WSFlexBox>
      {description && (
        <WSText
          color="gray600"
          mb="XL"
          align="left"
          className={styles.description}
        >
          {description}
        </WSText>
      )}
      {renderPackagePrice()}
      <WSFlexBox
        direction="column"
        mb={isAddOn ? "XL" : "3XL"}
        className={cn(styles.valuePropostionsContainer, {
          [styles.addOnProps]: isAddOn
        })}
      >
        {valuePropostions.map((vp: string) => (
          <WSFlexBox.CenterX mb="M" wrap="nowrap">
            <WSIcon block name="check" size="M" color="green500" mr="XS" />
            <WSText inline align="left">
              {vp}
            </WSText>
          </WSFlexBox.CenterX>
        ))}
      </WSFlexBox>
      {renderPackageCTA()}
    </WSFlexBox>
  );
};
