import ReactDOM from "react-dom";
import React, { useEffect, useState, ReactNode } from "react";

export interface WSPortalProps {
  container?: Element;
  disablePortal?: boolean;
  children: ReactNode;
}

export const WSPortal: React.FC<WSPortalProps> = (props) => {
  const { container, disablePortal, children } = props;
  const [mountNode, setMountNode] = useState<Element>();

  useEffect(() => {
    setMountNode(container || document.body);
  }, [container]);

  if (disablePortal) {
    return <>{children}</>;
  }
  if (mountNode) {
    return <>{ReactDOM.createPortal(children, mountNode)}</>;
  }
  return null;
};
