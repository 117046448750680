import {
  WSButton,
  WSCentered,
  WSContainer,
  WSFlexBox,
  WSIcon,
  WSLayout,
  WSText,
  useIsMobile
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router";
import { FREE_TRIAL_DAYS } from "../components/MembershipPackages/subscriptionPackages";

import { LogoApp } from "../../shared/components/LogoApp";
import styles from "./MembershipTrialSuccess.module.scss";

export const MembershipTrialSuccess: React.FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  return (
    <WSLayout line headerCenter={<LogoApp />}>
      <WSContainer>
        <WSCentered span={{ m: "8" }}>
          <WSFlexBox.CenterY
            mt="5XL"
            alignItems="flex-start"
            direction="column"
          >
            <WSFlexBox.Center
              p="S"
              className={styles.greenTickIconContainer}
              mb="3XL"
            >
              <WSIcon block name="check" color="white" />
            </WSFlexBox.Center>
            <WSText.Heading1 mb="M">
              Your {FREE_TRIAL_DAYS} day trial has begun!
            </WSText.Heading1>
            <WSText color="gray600" mb="XL">
              Billing won’t begin until your trial expires - we’ll email you a
              reminder.
            </WSText>
            <WSText color="gray600" mb="3XL">
              We do a card validation with the authorized amount, then cancel
              the charge after the validation. It might take a moment for the
              charge to disappear from your card and you might get an alert that
              the authorization was made.
            </WSText>
            <WSButton
              name="gotoDashboard"
              onClick={() => history.push("/member/dashboard")}
              fullWidth={isMobile}
            >
              Go to Dashboard
            </WSButton>
          </WSFlexBox.CenterY>
        </WSCentered>
      </WSContainer>
    </WSLayout>
  );
};
