import {
  WSCard,
  WSDataItem,
  WSDataItemProps,
  WSGrid,
  WSPanel,
  WSSectionToolbar
} from "@wingspanhq/fe-component-library";

type Props = {
  title: string;
  onEdit?: () => void;
  data: ((WSDataItemProps & { fsExclude?: boolean }) | null)[];
};

export const DataView: React.FC<Props> = ({ title, onEdit, data }) => {
  return (
    <WSCard
      divider
      header={{
        label: title,
        value: onEdit
          ? {
              action: {
                label: "Edit",
                onClick: onEdit
              }
            }
          : undefined
      }}
    >
      <WSGrid>
        {data.filter(Boolean).map(
          (item, colIndex) =>
            item && (
              <WSGrid.Item
                key={String(colIndex) + item.label}
                span={{ m: "6" }}
              >
                <WSDataItem {...item} />
              </WSGrid.Item>
            )
        )}
      </WSGrid>
    </WSCard>
  );
};
