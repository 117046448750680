import {
  WSActions,
  WSControl,
  WSControlGroup,
  WSForm,
  WSFormProps,
  WSInfoBox,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSSelect
} from "@wingspanhq/fe-component-library";
import merge from "lodash/merge";
import { COUNTRY_OPTIONS } from "../../../../constants/user";
import { getEntityTypeLabel } from "../../utils/entityType";
import { emptyValues } from "./emptyValues";
import { getValidationSchema } from "./validationSchema";

export type FormDataAccountType = {
  country: string;
  type: "Business" | "Individual";
  representativeConfirmation: boolean;
};

type Props = {
  defaultValues?: WSFormProps<FormDataAccountType>["defaultValues"];
  onSubmit: WSFormProps<FormDataAccountType>["onSubmit"];
  onChange: (data: FormDataAccountType) => void;
  accountTypes?: ("Individual" | "Business")[];
  verificationLevelIsBanking?: boolean;
  typeInputDisabled?: boolean;
  countryInputDisabled?: boolean;
};

export const FormAccountType: React.FC<Props> = ({
  defaultValues,
  onSubmit,
  onChange,
  countryInputDisabled,
  typeInputDisabled,
  verificationLevelIsBanking,
  accountTypes = ["Business", "Individual"]
}) => {
  return (
    <WSForm<FormDataAccountType>
      defaultValues={merge({ ...emptyValues }, defaultValues)}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema()}
    >
      {formContext => {
        const handleChange = () => {
          const values = formContext.getValues();
          onChange(values);
        };

        return (
          <>
            <WSPanel mb="2XL">
              <WSList gap="2XL">
                <WSSectionToolbar title="Account type" />

                <WSForm.Value name="type">
                  {type => (
                    <WSForm.Field
                      name="country"
                      component={WSSelect}
                      onChange={handleChange}
                      label={
                        type === "Business"
                          ? "Country of Incorporation"
                          : "Country of Tax Residence"
                      }
                      componentProps={{
                        disabled: countryInputDisabled,
                        required: true,
                        internalSearch: true,
                        options: COUNTRY_OPTIONS
                      }}
                    />
                  )}
                </WSForm.Value>
                <WSForm.Value name="country">
                  {country => (
                    <WSForm.Field
                      name="type"
                      component={WSControlGroup}
                      onChange={handleChange}
                      componentProps={{
                        required: true,
                        type: "radio",
                        disabled: typeInputDisabled,
                        chip: true,
                        options: accountTypes.map(type =>
                          type === "Business"
                            ? {
                                label: getEntityTypeLabel("Business", country),
                                value: "Business",
                                tooltip:
                                  "Your entity information will be used on invoices and tax documentation"
                              }
                            : {
                                label: getEntityTypeLabel(
                                  "Individual",
                                  country
                                ),
                                value: "Individual",
                                tooltip:
                                  "Your personal information will be used on invoices and tax documentation"
                              }
                        )
                      }}
                    />
                  )}
                </WSForm.Value>

                <WSForm.Value name="type">
                  {type =>
                    type === "Business" &&
                    verificationLevelIsBanking && (
                      <WSInfoBox title="Important:">
                        <p>
                          To create an account, you must be an authorized
                          representative of a business.
                        </p>

                        <p>
                          Only individuals with the authority to act on behalf
                          of a company or organization are eligible to register.
                          By proceeding with account creation, you confirm that
                          you have the legal right to represent and make
                          decisions for the business in question.
                        </p>

                        <p>
                          Ensure you have all beneficial owner(s) information to
                          complete the account creation process.
                        </p>

                        <WSForm.Field
                          name="representativeConfirmation"
                          component={WSControl}
                          onChange={handleChange}
                          componentProps={{
                            type: "checkbox",
                            size: "S",
                            label:
                              "I confirm that I am an authorized representative of the business"
                          }}
                        />
                      </WSInfoBox>
                    )
                  }
                </WSForm.Value>
              </WSList>
            </WSPanel>

            <WSForm.Values names={["type", "representativeConfirmation"]}>
              {values => (
                <WSActions
                  alignment="fill"
                  buttons={[
                    {
                      label: "Continue",
                      type: "submit",
                      disabled:
                        values.type === "Business" &&
                        verificationLevelIsBanking &&
                        !values.representativeConfirmation
                    }
                  ]}
                />
              )}
            </WSForm.Values>
          </>
        );
      }}
    </WSForm>
  );
};
