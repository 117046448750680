import React from "react";

import {
  WSAvatar,
  WSAvatarSize,
  WSElement,
  WSElementProps
} from "@wingspanhq/fe-component-library";
import { IS_PRODUCTION_ENV } from "../../shared/constants/environment";
import styles from "./WSInstitutionLogoV2.module.scss";

export enum InstitutionType {
  Bank = "Bank",
  CreditCard = "CreditCard"
}

export interface WSInstitutionLogoV2Props extends WSElementProps {
  institutionId?: string;
  institutionType?: InstitutionType;
  size?: WSAvatarSize;
}

export const WSInstitutionLogoV2: React.FC<WSInstitutionLogoV2Props> = ({
  size = "S",
  institutionId,
  institutionType = InstitutionType.Bank,
  ...elementProps
}) => {
  const srcGCSBucket = `https://storage.cloud.google.com/${
    IS_PRODUCTION_ENV
      ? "wingspan-shared-content"
      : "wingspan-staging-shared-content"
  }`;
  let logosPath = "plaid/logos";
  if (institutionType === InstitutionType.CreditCard) {
    logosPath = "credit-cards/logos";
  }
  const url = `${srcGCSBucket}/${logosPath}/${institutionId}.png`;

  const defaultDisplay = (
    <WSAvatar.Icon
      icon={institutionType === InstitutionType.CreditCard ? "card" : "bank"}
      size={size}
    />
  );

  return (
    <WSElement {...elementProps} className={styles.institutionLogo}>
      {institutionId ? (
        url ? (
          <WSAvatar.Image image={url} size={size} />
        ) : (
          defaultDisplay
        )
      ) : (
        defaultDisplay
      )}
    </WSElement>
  );
};
