import {
  IBulkBatchCreate,
  IBulkBatchUpdate
} from "@wingspanhq/payments/dist/interfaces";
import {
  IBulkCalculation1099Batch,
  IBulkCalculation1099Item
} from "@wingspanhq/payments/dist/interfaces/bulkCalculation1099";
import { QueryConfig, queryCache } from "react-query";
import { useWSMutation } from "../../../../query/helpers";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { bulkCalculation1099Service } from "../../services/bulkCalculation1099";
import { QUERY_BULK_CALCULATE_1099_BATCH } from "./keys";
import { IBulkCalculation1099ItemCreate } from "@wingspanhq/payments/dist/interfaces/api/bulkCalculation1099";

export const useCreateBulkCalculate1099Batch = (
  config?: QueryConfig<IBulkCalculation1099Batch, WSServiceError>
) =>
  useWSMutation(
    (data: IBulkBatchCreate) => bulkCalculation1099Service.batch.create(data),
    {
      ...config
    }
  );

export const useUpdateBulkCalculate1099Batch = () =>
  useWSMutation(
    (payload: { id: string; data: IBulkBatchUpdate }) =>
      bulkCalculation1099Service.batch.update(payload.id, payload.data),
    {
      onSuccess: response => {
        queryCache.invalidateQueries([
          QUERY_BULK_CALCULATE_1099_BATCH,
          response.bulkCalculation1099BatchId
        ]);
      }
    }
  );

export const useCreateBulk1099SubmitBatchItem = (
  config?: QueryConfig<IBulkCalculation1099Item, WSServiceError>
) =>
  useWSMutation(
    ({
      batchId,
      ...data
    }: IBulkCalculation1099ItemCreate & { batchId: string }) =>
      bulkCalculation1099Service.batchItem.create(batchId, data),
    {
      ...config
    }
  );
