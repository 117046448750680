import { WSElementProps, WSText } from "@wingspanhq/fe-component-library";
import React from "react";
import { WS_LINKS } from "../../../types/wsLinks";
import styles from "./PrivacyPolicy.module.scss";

type Props = WSElementProps;

export const PrivacyPolicy: React.FC<Props> = ({ ...elementProps }) => (
  <WSText.ParagraphXs {...elementProps} className={styles.text}>
    By proceeding you agree to Wingspan's{" "}
    <a href={WS_LINKS.wingspanPrivacyPolicyAcceptance}>Privacy Policy</a> and
    <br />
    <a href={WS_LINKS.wingspanTosAcceptance}>Terms of Use</a>.
  </WSText.ParagraphXs>
);
