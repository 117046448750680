import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";

export type CustomFieldResourceTypeRouteSlug = "collaborator" | "line-item";

export const CustomFieldResourceTypeSlug = {
  [CustomFieldResourceType.Collaborator]: "collaborator",
  [CustomFieldResourceType.LineItem]: "line-item"
};

export const ResourceTypeSlugToResourceType = {
  [CustomFieldResourceTypeSlug.Collaborator]:
    CustomFieldResourceType.Collaborator,
  [CustomFieldResourceTypeSlug.LineItem]: CustomFieldResourceType.LineItem
};
