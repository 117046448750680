import { WSModal, WSText } from "@wingspanhq/fe-component-library";

export const BULK_IMPORT_PAYABLES_AND_DEDUCTIONS_MODAL =
  "BULK_IMPORT_PAYABLES_AND_DEDUCTIONS_MODAL";

export function BulkImportPayablesAndDeductionsModal() {
  return (
    <WSModal
      name={BULK_IMPORT_PAYABLES_AND_DEDUCTIONS_MODAL}
      size="S"
      title="Imported payables and deductions"
    >
      <WSText.ParagraphSm mt="M">
        An import total may be different than the total of a corresponding
        payroll run for a few reasons: existing deductions might apply, new
        deductions might not apply, and imported payables with the same
        contractor name and due date might be merged with existing payables.
      </WSText.ParagraphSm>
      <WSText.ParagraphSm mt="M">
        Go to an import's details page to see a complete breakdown of what was
        imported and how it will impact the existing payables and deductions.
      </WSText.ParagraphSm>
    </WSModal>
  );
}
