import React from "react";
import {
  IInvitation,
  IPublicCustomizationSettingsResponse
} from "@wingspanhq/users/dist/lib/interfaces";
import { AuthPage } from "../AuthPage/AuthPage";
import { useQueryInvitation } from "../../../../query/invitations/queries/useQueryInvitation";
import { LoadingPlaceholder } from "../../../../components/LoadingPlaceholder";
import { InviteLinkNotFound } from "../InviteLinkNotFound";
import { track } from "../../../../utils/analytics";

type RouteRootInvitationProps = {
  customization?: IPublicCustomizationSettingsResponse;
  invitation: IInvitation;
};

export const RootInvitation: React.FC<RouteRootInvitationProps> = ({
  customization,
  invitation
}) => {
  const queryInvitation = useQueryInvitation(invitation.invitationToken, {
    onSuccess: data => {
      track("Invitation Opened", data);
    },
    onError: error => {
      track("Invitation Failed", {
        inviterUserId: invitation.inviterUserId,
        invitationContext: invitation.context,
        error: error.message
      });
    }
  });

  if (queryInvitation.data) {
    return (
      <AuthPage invitation={invitation} invitationData={queryInvitation.data} />
    );
  } else if (queryInvitation.isLoading || !queryInvitation.isFetched) {
    return (
      <LoadingPlaceholder
        customLogo={customization?.branding?.primaryLogoUrl}
      />
    );
  } else if (queryInvitation.isError) {
    return <InviteLinkNotFound />;
  }

  return <InviteLinkNotFound />;
};
