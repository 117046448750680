import { useUserProfileData } from "./utils/useUserProfileData";
import { isUserIdsIncluded } from "./utils/isUserIdsIncluded";
import { HIDE_RECURRING_INVOICES_NAV_IDS } from "./helpers";

export const useShowNewRecurringInvoicesNav = () => {
  const userData = useUserProfileData();
  // if not client node, show new one time invoice nav
  const showNewRecurringInvoicesNav = !isUserIdsIncluded(
    userData,
    HIDE_RECURRING_INVOICES_NAV_IDS
  );

  return showNewRecurringInvoicesNav;
};
