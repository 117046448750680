import {
  useModalContext,
  WSButton,
  WSButtons,
  WSElementProps,
  WSFormOld,
  WSModal,
  WSText,
  WSTextArea
} from "@wingspanhq/fe-component-library";
import {
  ICollaboratorSchema,
  IPayableSchema
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useRejectPayable } from "../../../query/payments/mutations";

type Props = WSElementProps;

export const REJECT_PAYABLE_MODAL = "REJECT_PAYABLE_MODAL";

export const RejectPayableModal: React.FC<Props> = () => {
  const { closeModal } = useModalContext();
  const history = useHistory();
  const [rejectPayable, rejectPayableMeta] = useRejectPayable();

  return (
    <WSModal
      name={REJECT_PAYABLE_MODAL}
      size="S"
      onClose={() => {
        rejectPayableMeta.reset();
      }}
      title="Reject Payable"
    >
      {({
        payable,
        listName
      }: {
        payable?: IPayableSchema;
        collaborator?: ICollaboratorSchema;
        listName?: "paid";
      }) => {
        return (
          <>
            <WSFormOld<{ comment: string }>
              onSubmit={({ comment }) => {
                if (payable) {
                  rejectPayable(
                    {
                      payableId: payable?.payableId,
                      comment
                    },
                    {
                      onSuccess: () => {
                        closeModal(REJECT_PAYABLE_MODAL);
                        history.push(
                          `/member/invoices/payables${
                            listName ? `/${listName}` : ""
                          }`
                        );
                      }
                    }
                  );
                }
              }}
            >
              <WSText mb="XL">
                Indicate the reason for the reject in the field below
              </WSText>

              <WSFormOld.Field mb="XL" name="comment" component={WSTextArea} />

              <WSErrorMessage
                mb="XL"
                error={rejectPayableMeta.error}
                contextKey="DisputeInvoice"
              />

              <WSButtons format="modal">
                <WSButton
                  name="rejectPayable"
                  loading={rejectPayableMeta.isLoading}
                >
                  Reject payable
                </WSButton>
                <WSButton.Secondary
                  onClick={() => {
                    rejectPayableMeta.reset();
                    closeModal(REJECT_PAYABLE_MODAL);
                  }}
                  type="button"
                >
                  Cancel
                </WSButton.Secondary>
              </WSButtons>
            </WSFormOld>
          </>
        );
      }}
    </WSModal>
  );
};
