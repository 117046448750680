import {
  toWSDateString,
  useModalContext,
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSInputDateOld,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSFormOld,
  WSInputNumberOld,
  WSModal,
  WSSelectOld,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  Intervals,
  InvoiceStatus,
  ScheduleStatus
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import * as Yup from "yup";
import { RadioGroupWithCustomInputs } from "../../../components/RadioGroupWithCustomInputs/RadioGroupWithCustomInputs";
import { isSameDate, isToday } from "../../../utils/dates";
import { getIsFirstInvoiceSent } from "../../utils";
import styles from "./FrequencySection.module.scss";
import { InvoicesFormValues, useInvoicesFormContext } from "./InvoicesForm";

export type FrequencyValues = {
  every: number;
  interval: Intervals;
  end: "never" | "date" | "after";
  endDate: Date;
  endAfter: number;
  start: "onSend" | "custom";
  startDate: Date;
};

export type FrequencySectionValues = {
  recurring: boolean;
  frequency: FrequencyValues;
};

type Props = WSElementProps;

export const FrequencySection: React.FC<Props> = ({ ...elementProps }) => {
  const { setValue } = useFormContext();
  const { invoice, invoiceTemplate } = useInvoicesFormContext();

  const hide = invoice
    ? invoice.status !== InvoiceStatus.Draft
    : invoiceTemplate
    ? true
    : false;

  return (
    <WSElement {...elementProps}>
      <WSFormOld.Field
        name="recurring"
        component={WSCheckboxToggle}
        componentProps={{
          label: (
            <WSText.Heading5>Repeat this invoice automatically</WSText.Heading5>
          )
        }}
        hidden={hide}
      />

      <WSFormOld.Value name="recurring">
        {recurring => {
          if (recurring) {
            return (
              <>
                {hide && (
                  <WSText.Heading5 mb="XL">
                    Invoice repeats automatically
                  </WSText.Heading5>
                )}

                <WSElement style={{ display: hide ? "none" : "block" }}>
                  <WSFormOld.Label mt="XL">Repeat every</WSFormOld.Label>
                  <WSElement className={styles.everyFieldsContainer} mb="XL">
                    <WSFormOld.Field
                      className={styles.everyField}
                      name="frequency.every"
                      component={WSInputNumberOld}
                      mr="M"
                    />
                    <WSFormOld.Field
                      className={styles.intervalField}
                      name="frequency.interval"
                      component={WSSelectOld}
                      componentProps={{
                        options: [
                          {
                            value: Intervals.Week,
                            label: "weeks"
                          },
                          {
                            value: Intervals.Month,
                            label: "months"
                          }
                        ]
                      }}
                    />
                  </WSElement>
                </WSElement>

                <WSElement mb="XL">
                  <NextInvoiceText />
                </WSElement>

                <RadioGroupWithCustomInputs
                  hidden
                  name="frequency.start"
                  options={[
                    {
                      value: "onSend",
                      label: "On send"
                    },
                    {
                      value: "date",
                      label: (
                        <WSFlexBox.CenterY wrap="nowrap">
                          <WSText.ParagraphSm as="pre" inline mr="M">
                            On
                          </WSText.ParagraphSm>
                          <WSFormOld.Field
                            name="frequency.startDate"
                            component={WSInputDateOld}
                          />
                        </WSFlexBox.CenterY>
                      )
                    }
                  ]}
                />

                <RadioGroupWithCustomInputs
                  label="End series"
                  name="frequency.end"
                  options={
                    hide
                      ? [
                          {
                            value: "never",
                            label: "Never"
                          },
                          {
                            value: "date",
                            label: (
                              <WSFlexBox.CenterY wrap="nowrap">
                                <WSText.ParagraphSm as="pre" inline mr="M">
                                  On
                                </WSText.ParagraphSm>
                                <WSFormOld.Field
                                  name="frequency.endDate"
                                  component={WSInputDateOld}
                                  componentProps={{
                                    onFocus: () => {
                                      setValue("frequency.end", "date");
                                    }
                                  }}
                                />
                              </WSFlexBox.CenterY>
                            )
                          }
                        ]
                      : [
                          {
                            value: "never",
                            label: "Never"
                          },
                          {
                            value: "date",
                            label: (
                              <WSFlexBox.CenterY wrap="nowrap">
                                <WSText.ParagraphSm as="pre" inline mr="M">
                                  On
                                </WSText.ParagraphSm>
                                <WSFormOld.Field
                                  name="frequency.endDate"
                                  component={WSInputDateOld}
                                  componentProps={{
                                    onFocus: () => {
                                      setValue("frequency.end", "date");
                                    }
                                  }}
                                />
                              </WSFlexBox.CenterY>
                            )
                          },
                          {
                            value: "after",
                            label: (
                              <WSFlexBox.CenterY wrap="nowrap">
                                <WSText.ParagraphSm as="pre" inline mr="M">
                                  After
                                </WSText.ParagraphSm>
                                <WSFormOld.Field
                                  mr="M"
                                  name="frequency.endAfter"
                                  component={WSInputNumberOld}
                                  componentProps={{
                                    onFocus: () => {
                                      setValue("frequency.end", "after");
                                    }
                                  }}
                                />
                                <WSText.ParagraphSm as="pre">
                                  occurrences
                                </WSText.ParagraphSm>
                              </WSFlexBox.CenterY>
                            )
                          }
                        ]
                  }
                />
              </>
            );
          } else {
            return null;
          }
        }}
      </WSFormOld.Value>
      <ScheduleModal
        onSubmit={values => {
          setValue("send.type", values.type);
          setValue("send.date", values.sendDate);
          setValue("frequency.start", "custom");
          setValue("frequency.startDate", values.startDate);
        }}
      />
    </WSElement>
  );
};

const NextInvoiceText: React.FC = () => {
  const { watch } = useFormContext<InvoicesFormValues>();
  const { send, frequency } = watch(["send", "frequency"]);
  const { openModal } = useModalContext();
  const { invoiceTemplate, isDraft } = useInvoicesFormContext();
  const history = useHistory();
  const location = useLocation();

  if (
    !isDraft &&
    invoiceTemplate &&
    invoiceTemplate.frequency &&
    invoiceTemplate.isSchedulingOnly
  ) {
    const scheduleDates = invoiceTemplate.scheduleDates || [];
    const nextScheduleDateIndex = scheduleDates.findIndex(
      scheduleDate =>
        scheduleDate.date > new Date() &&
        scheduleDate.status !== ScheduleStatus.Skipped
    );
    const followingScheduleDateIndex =
      nextScheduleDateIndex + 1 < scheduleDates.length
        ? nextScheduleDateIndex + 1
        : undefined;
    return nextScheduleDateIndex >= 0 ? (
      <WSText.ParagraphSm mb="XS">
        The next invoice will be sent{" "}
        {toWSDateString(scheduleDates[nextScheduleDateIndex].date, "monthDate")}
        {followingScheduleDateIndex
          ? `, the following invoice will be on sent on ${toWSDateString(
              scheduleDates[nextScheduleDateIndex + 1].date,
              "monthDate"
            )}`
          : ""}
        .{" "}
        <WSButton.Link
          type="button"
          onClick={() => {
            history.push({
              pathname: `/member/invoices/template/${invoiceTemplate.invoiceTemplateId}/edit/series`,
              search: location.search
            });
          }}
          name="changeSchedule"
        >
          Change
        </WSButton.Link>
      </WSText.ParagraphSm>
    ) : (
      <WSText.ParagraphSm mb="XS">
        This invoice series has ended
      </WSText.ParagraphSm>
    );
  } else if (
    !isDraft &&
    invoiceTemplate &&
    invoiceTemplate.frequency &&
    getIsFirstInvoiceSent(invoiceTemplate)
  ) {
    // First invoice was sent, we allow to change only frequency.startDate here

    const nextInvoiceDate = isSameDate(
      frequency.startDate,
      invoiceTemplate.frequency.startDate
    )
      ? invoiceTemplate.nextInvoiceDate || new Date()
      : frequency.startDate;

    let followingInvoiceDate = new Date(nextInvoiceDate);

    const every = Number(frequency.every);
    if (frequency.interval === Intervals.Month) {
      followingInvoiceDate.setMonth(followingInvoiceDate.getMonth() + every);
    } else {
      followingInvoiceDate.setDate(followingInvoiceDate.getDate() + 7 * every);
    }

    return (
      <WSText.ParagraphSm mb="XS">
        The next invoice will be sent{" "}
        {isToday(nextInvoiceDate)
          ? "Today"
          : toWSDateString(nextInvoiceDate, "monthDate")}
        , the following invoice will be on sent on{" "}
        {toWSDateString(followingInvoiceDate, "monthDate")}.
      </WSText.ParagraphSm>
    );
  } else {
    let followingInvoiceDate;

    const firstInvoiceDate =
      send.type === "immediately" ? new Date() : send.date;

    if (frequency.start === "onSend") {
      followingInvoiceDate = new Date(firstInvoiceDate);
      const every = Number(frequency.every);
      if (frequency.interval === Intervals.Month) {
        followingInvoiceDate.setMonth(followingInvoiceDate.getMonth() + every);
      } else {
        followingInvoiceDate.setDate(
          followingInvoiceDate.getDate() + 7 * every
        );
      }
    } else {
      followingInvoiceDate = frequency.startDate;
    }

    const firstInvoiceDateText = isToday(firstInvoiceDate)
      ? "Today"
      : `on ${toWSDateString(firstInvoiceDate, "monthDate")}`;

    return (
      <WSText.ParagraphSm mb="XS">
        The first invoice will be sent {firstInvoiceDateText}, the following
        invoice will be on sent on{" "}
        {toWSDateString(followingInvoiceDate, "monthDate")}.{" "}
        <WSButton.Link
          type="button"
          onClick={() => {
            openModal(SCHEDULE_MODAL);
          }}
          name="changeSchedule"
        >
          Change
        </WSButton.Link>
      </WSText.ParagraphSm>
    );
  }
};

const SCHEDULE_MODAL = "schedule";

type ScheduleValues = {
  type: "immediately" | "date";
  sendDate: Date;
  startDate: Date;
};

type ScheduleModalProps = {
  onSubmit: (values: ScheduleValues) => void;
};

const ScheduleModal: React.FC<ScheduleModalProps> = ({ onSubmit }) => {
  const { closeModal } = useModalContext();
  const { watch } = useFormContext<InvoicesFormValues>();
  const { send, frequency } = watch(["send", "frequency"]);

  let followingInvoiceDate;
  const firstInvoiceDate = send.type === "immediately" ? new Date() : send.date;

  if (frequency.start === "onSend") {
    followingInvoiceDate = new Date(firstInvoiceDate);
    const every = Number(frequency.every);
    if (frequency.interval === Intervals.Month) {
      followingInvoiceDate.setMonth(followingInvoiceDate.getMonth() + every);
    } else {
      followingInvoiceDate.setDate(followingInvoiceDate.getDate() + 7 * every);
    }
  } else {
    followingInvoiceDate = frequency.startDate;
  }

  return (
    <WSModal name={SCHEDULE_MODAL} size="S" title="Schedule invoices">
      <WSFormOld<ScheduleValues>
        defaultValues={{
          type: send.type,
          sendDate: send.date,
          startDate: followingInvoiceDate
        }}
        validationSchema={Yup.object().shape({
          type: Yup.string()
            .oneOf(["immediately", "date"])
            .required("Required"),
          sendDate: Yup.date().when("type", {
            is: "date",
            then: Yup.date().isTodayAndInFuture()
          }),
          startDate: Yup.date().when("type", {
            is: "immediately",
            then: Yup.date().isTodayAndInFuture(),
            otherwise: Yup.date().isAfter("sendDate")
          })
        })}
      >
        {({ handleSubmit, setValue }) => (
          <>
            <RadioGroupWithCustomInputs
              label="Send first invoice"
              name="type"
              options={[
                { value: "immediately", label: "Immediately" },
                {
                  value: "date",
                  label: (
                    <WSFlexBox.CenterY wrap="nowrap">
                      <WSText.ParagraphSm as="pre" inline mr="M">
                        On
                      </WSText.ParagraphSm>
                      <WSFormOld.Field
                        name="sendDate"
                        component={WSInputDateOld}
                        componentProps={{
                          onFocus: () => {
                            setValue("type", "date");
                          }
                        }}
                      />
                    </WSFlexBox.CenterY>
                  )
                }
              ]}
              mb="2XL"
            />
            <WSFormOld.Field
              label="Send next invoice"
              name="startDate"
              component={WSInputDateOld}
              mb="2XL"
            />

            <WSButtons format="modal">
              <WSButton
                type="button"
                onClick={() => {
                  handleSubmit(values => {
                    onSubmit(values);
                    closeModal(SCHEDULE_MODAL);
                  })();
                }}
              >
                Save
              </WSButton>
              <WSButton.Secondary
                type="button"
                onClick={() => {
                  closeModal(SCHEDULE_MODAL);
                }}
              >
                Cancel
              </WSButton.Secondary>
            </WSButtons>
          </>
        )}
      </WSFormOld>
    </WSModal>
  );
};
