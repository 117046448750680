import {
  ICatchUpExpenseTransactions,
  ICatchUpIncomeTransactions
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { ITransactionGroup } from "@wingspanhq/bookkeeping/dist/lib/interfaces/insights";
import {
  AccountStatus,
  IAccount,
  IActivity
} from "@wingspanhq/users/dist/lib/interfaces";

export const getTotalCatchUpIncomeTransactions = (
  groups: ICatchUpIncomeTransactions,
  activity: IActivity
) => {
  const { incomeReviewedAt = new Date(0) } = activity.events;

  return [...groups.sourceIncome, ...groups.accountIncome].reduce<number>(
    (acc, group) => {
      const transactions = group.transactions.filter(
        t => t.createdAt > incomeReviewedAt
      );

      return acc + transactions.length;
    },
    0
  );
};

export const getTotalCatchUpExpenseTransactions = (
  groups: ICatchUpExpenseTransactions,
  activity: IActivity
) => {
  const { expensesReviewedAt = new Date(0) } = activity.events;

  return [...groups.merchantExpenses, ...groups.accountExpenses].reduce<number>(
    (acc, group) => {
      const transactions = group.transactions.filter(
        t => t.createdAt > expensesReviewedAt
      );

      return acc + transactions.length;
    },
    0
  );
};

export const getAllTotalCatchUpIncomeTransactions = (
  groups: ICatchUpIncomeTransactions
) => {
  return [...groups.sourceIncome, ...groups.accountIncome].reduce<number>(
    (acc, group) => acc + group.transactions.length,
    0
  );
};

export const getAllTotalCatchUpExpenseTransactions = (
  groups: ICatchUpExpenseTransactions
) => {
  return [...groups.merchantExpenses, ...groups.accountExpenses].reduce<number>(
    (acc, group) => acc + group.transactions.length,
    0
  );
};

export const getCatchUpFilterGroups = (
  groups: ITransactionGroup[],
  reviewedAt = new Date(0)
) => {
  return groups
    .map(group => ({
      ...group,
      transactions: group.transactions.filter(t => {
        return t.createdAt > reviewedAt;
      })
    }))
    .filter(group => group.transactions.length);
};

export const getAllCatchUpFilterGroups = (groups: ITransactionGroup[]) => {
  return groups
    .map(group => ({
      ...group,
      transactions: group.transactions
    }))
    .filter(group => group.transactions.length);
};

export const getAllTransactionsSyncCompleted = (
  accounts: IAccount[]
): boolean => {
  return accounts
    .filter(account => account.usedFor?.bookkeeping)
    .filter(account => account.institutionId)
    .every(account => account.events?.transactionsSyncedAt);
};

export const getEnabledAccounts = (accounts: IAccount[]) =>
  accounts.filter(account => account.status !== AccountStatus.Inactive);

export const getEnabledPaymentsAccounts = (accounts: IAccount[]) => {
  return getEnabledAccounts(accounts).filter(
    account => account.usedFor?.payments
  );
};

export const getAccountName = (account: IAccount): string => {
  const { institution, subType, mask, name } = account;
  let formattedSubType;
  if (subType) {
    formattedSubType = subType.replace(/([A-Z])/g, " $1").trim();
  }

  if (institution && formattedSubType) {
    let prefix: string;

    if (
      institution.trim().toLowerCase() === formattedSubType.trim().toLowerCase()
    ) {
      prefix = `${institution}`;
    } else {
      prefix = `${institution} ${formattedSubType}`;
    }

    if (mask) {
      return `${prefix} (${mask})`;
    }

    return prefix;
  } else {
    return name;
  }
};
