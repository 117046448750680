import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation } from "../../helpers";
import {
  IBeneficiaryVerificationRequest,
  IBeneficiaryVerificationResponse
} from "@wingspanhq/payments/dist/interfaces";
import { checkAccountBeneficiaryVerification } from "../../../services/accounts/checkAccountBeneficiaryVerification";

export const useVerifyAccountBeneficiary = () =>
  useWSMutation<
    IBeneficiaryVerificationResponse,
    WSServiceError,
    IBeneficiaryVerificationRequest
  >(requestData => checkAccountBeneficiaryVerification(requestData));
