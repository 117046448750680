import {
  WSButton,
  WSButtons,
  WSAvatar,
  WSContainer,
  WSElement,
  WSFlexBox,
  WSCentered,
  WSIcon,
  WSIconList,
  WSPanel,
  WSText,
  WSTodoList
} from "@wingspanhq/fe-component-library";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces/additionalData";
import React from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { track } from "../../../../utils/analytics";
import styles from "./bulkUploadPayables.module.scss";
import { BatchProcessingStrategy } from "@wingspanhq/payments/dist/interfaces/bulkPayable";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { useCreateBulkPayableBatch } from "../../../../modules/BulkImporter/query/bulkPayable/mutations";
import { preparePayablesCSVFile } from "../../../../modules/BulkImporter/routes/BulkImportPayableBatchDetails/preparePayablesCSVFile";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";

export interface BulkUploadPayablesInfoProps {}

export interface DownloadPayable {
  email?: string;
  collaboratorId?: string;
  contractorId?: string;
  amount?: string;
  lineItemTitle: string;
  lineItemDescription?: string;
  lineItemHourlyRate?: string;
  lineItemHours?: string;
  reimbursable?: boolean;
  dueDate: Date;
  attachmentId?: string;
  paymentRefId?: string;
  paidDate?: Date;
  invoiceNotes?: string;
}
export interface UploadPayable extends DownloadPayable {
  $custom?: { [key: string]: string };
}

export const BulkUploadPayablesInfo: React.FC<BulkUploadPayablesInfoProps> = () => {
  useBrowserPageTitle("Wingspan - Bulk upload payables");
  const history = useHistory();
  const [
    createBulkPayableBatch,
    createBulkPayableBatchMeta
  ] = useCreateBulkPayableBatch();

  const queryLineItemCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.LineItem]
  });

  const lineItemCustomFields = queryLineItemCustomFields.data ?? [];

  const downloadExamplePayables = [
    {
      email: "wade@wignspan.app",
      collaboratorId: "",
      contractorId: "",
      amount: "$100.50",
      lineItemTitle: "Pay Period 03/01-03/15",
      lineItemDescription: "Website updates & copy",
      dueDate: "2022-12-02",
      invoiceNotes: "Website Re-brand Project",
      paidDate: "",
      attachmentId: "",
      paymentRefId: ""
    }
  ];
  const csvProps = preparePayablesCSVFile(
    "Wingspan_Bulk_Upload_Payables_Template.csv",
    downloadExamplePayables,
    lineItemCustomFields
  );

  const initiateBulkUpload = async () => {
    await createBulkPayableBatch(
      {
        // Note: Assuming default processing strategy as Merge
        processingStrategy: BatchProcessingStrategy.Merge
      },
      {
        onSuccess: response => {
          track("Payable Bulk Upload Started");
          history.push(
            `/member/invoices/payables/bulk-upload/${response.bulkPayableBatchId}/status`
          );
        }
      }
    );
  };

  const requiredFields = [
    "Email or External ID",
    "Amount (USD)",
    "Line Item Title",
    "Due Date"
  ];

  const optionalFields = [
    "Line Item Description",
    "Invoice Notes",
    "Attachment ID",
    "Payment Reference ID"
  ];

  lineItemCustomFields.forEach(customField => {
    if (customField.required) {
      requiredFields.push(customField.name);
    } else {
      optionalFields.push(customField.name);
    }
  });

  return (
    <ProjectOnboardingLayout progress={(100 / 3) * 1}>
      <WSContainer verticalPadding>
        <WSCentered span={{ s: "6", m: "8" }}>
          <WSText.Heading4 mb="M">
            Import payables with a spreadsheet
          </WSText.Heading4>
          <WSPanel>
            <WSTodoList
              mb="XL"
              items={[
                {
                  mark: "1.",
                  content: (
                    <>
                      <WSText.ParagraphSm weight="medium">
                        Upload a spreadsheet of line items
                      </WSText.ParagraphSm>
                      <WSText.ParagraphSm mt="XS">
                        Don't worry if your spreadsheet has other details, but
                        please make sure it contains the following columns.
                      </WSText.ParagraphSm>
                      <WSFlexBox mt="2XL" mb="XL">
                        <WSElement style={{ flex: 1 }}>
                          <WSText weight="medium" mb="M">
                            Required
                          </WSText>
                          <WSIconList.Checks items={requiredFields} mb="XL" />
                        </WSElement>
                        <WSElement style={{ flex: 1 }}>
                          <WSText weight="medium" mb="M">
                            Optional
                          </WSText>
                          <WSIconList.Checks items={optionalFields} mb="XL" />
                        </WSElement>
                      </WSFlexBox>
                    </>
                  )
                },
                {
                  mark: "2.",
                  content: (
                    <>
                      <WSText.ParagraphSm weight="medium">
                        Review and import line items
                      </WSText.ParagraphSm>
                      <WSText.ParagraphSm mt="XS">
                        Review your imported line items to ensure their
                        information is correct.
                      </WSText.ParagraphSm>
                    </>
                  )
                }
              ]}
            />
            <WSErrorMessage
              my="XL"
              contextKey={ErrorContextKey.Other}
              error={createBulkPayableBatchMeta.error}
            />
            <WSButtons>
              <CSVLink
                {...csvProps}
                className={styles.csvLink}
                onClick={() => {
                  initiateBulkUpload();
                }}
              >
                <WSButton.Secondary
                  fullWidth
                  icon="download"
                  loading={createBulkPayableBatchMeta.isLoading}
                >
                  Download Example
                </WSButton.Secondary>
              </CSVLink>
              <WSButton
                className={styles.nextBtn}
                name="importPayables"
                onClick={() => {
                  initiateBulkUpload();
                }}
                loading={createBulkPayableBatchMeta.isLoading}
              >
                Upload Payables
              </WSButton>
            </WSButtons>

            <WSPanel mt="XL" onClick={() => history.push("/member/imports")}>
              <WSFlexBox.CenterY justify="space-between">
                <WSFlexBox.CenterY>
                  <WSAvatar.Icon
                    icon="details"
                    color="blue500"
                    colorBackground="blue50"
                    size="M"
                    mr="M"
                  />
                  <WSText weight="medium">See imports</WSText>
                </WSFlexBox.CenterY>

                <WSIcon block name="chevron-right" color="gray500" size="M" />
              </WSFlexBox.CenterY>
            </WSPanel>
          </WSPanel>
        </WSCentered>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
