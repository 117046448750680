import { IRequirement } from "@wingspanhq/payments/dist/interfaces";
import { ISignedDocumentResponse } from "@wingspanhq/signed-documents/dist/lib/interfaces";
import { ContextItem } from "../../../../types";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";

export const getDocumentFromCache = (
  requirement: IRequirement,
  contextItem: ContextItem
): ISignedDocumentResponse | undefined => {
  return contextItem?.[RequirementType.Signature]?.find(document => {
    return document.signedDocumentId === requirement.dataSourceId;
  });
};
