import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import {
  isValidIconName,
  WSButton,
  WSCard,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSIcon,
  WSIconName,
  WSImage,
  WSList,
  WSMessageBox,
  WSPage,
  WSPageButtonProps,
  WSPanel,
  WSPillProps,
  WSText
} from "@wingspanhq/fe-component-library";

import { IWebflowBenefit } from "@wingspanhq/cms/dist/lib/interfaces/webflow/benefits";

import { openInNewTab } from "../../utils/openInNewTab";

import {
  SubscriptionPackage,
  SubscriptionPackageTier
} from "@wingspanhq/users/dist/lib/interfaces";
import { ServiceState } from "@wingspanhq/users/dist/lib/interfaces/enabledServices";
import qs from "qs";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { WSQueries } from "../../query/WSQuery";
import {
  useBenefitsEnrollment,
  useBenefitsService
} from "../../query/benefits";
import { useUserId } from "../../query/hooks/helpers";
import { useCreateOrUpdateSubscriptionV3 } from "../../query/subscriptions/mutations";
import { useUpdateActivity } from "../../query/users/mutations";
import { useMemberProfile } from "../../query/users/queries";
import { selectorMemberCompany } from "../../shared/selectors/selectorMemberCompany";
import { selectorMemberLegalBusinessName } from "../../shared/selectors/selectorMemberLegalBusinessName";
import {
  getCostOfBenefitWithSubscription,
  insertMemberAndGroupIdsIntoText,
  isBenefitIncludedInSubscriptionLevel,
  setPathAfterSuccess
} from "../utils";
import styles from "./BenefitsCMSPage.module.scss";

interface BenefitsCMSPageProps {
  benefit: IWebflowBenefit;
  isFree?: boolean;
  noPill?: boolean;
}

export const BenefitsCMSPage: React.FC<BenefitsCMSPageProps> = ({
  benefit,
  isFree,
  noPill
}) => {
  const history = useHistory();

  const [isFAQSExpanded, setIsFAQSExpanded] = useState(false);

  const userId = useUserId();
  const qEnrollment = useBenefitsEnrollment(userId);
  const qService = useBenefitsService();
  const qMember = useMemberProfile(userId);

  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);
  const [
    createOrUpdateSubscription,
    createOrUpdateSubscriptionMeta
  ] = useCreateOrUpdateSubscriptionV3(userId);

  let pill: WSPillProps | undefined = undefined;

  useBrowserPageTitle(`Benefits - ${benefit.mainHeader}`);

  const routerState: any = history.location.state;

  return (
    <WSQueries queries={{ qEnrollment, qService, qMember }}>
      {({ qEnrollment, qService, qMemberData }) => {
        const {
          category,
          logo,
          featured,
          icon,
          mainCards,
          orderPriority,
          helperContactInfoDescription,
          helperContactInfoEmail,
          helperContactInfoPhone,
          helperCostDescription,
          helperCTAPrimaryButtonLink,
          helperCTAPrimaryButtonText,
          helperHeader,
          helperHideMemberAndGroupInfo,
          helperLogo,
          helperUsageText,
          isDraft,
          itemId,
          mainDescription,
          mainDescriptionList,
          mainFAQSHeader,
          mainFAQSItems,
          mainFootnote,
          mainHeader,
          mainInfoListHeader,
          mainInfoListItems,
          mainLearnMoreLink,
          mainLearnMoreLinkText,
          mainSubheader,
          mainVideo,
          minSubscription,
          minSubscriptionPackage,
          minSubscriptionPackageTier,
          name,
          publishedOn,
          slug
        } = benefit;

        const hasHelper = !!(
          helperContactInfoDescription ||
          helperContactInfoEmail ||
          helperContactInfoPhone ||
          helperCTAPrimaryButtonLink ||
          helperCTAPrimaryButtonText ||
          helperHeader ||
          helperLogo?.src ||
          helperUsageText ||
          !helperHideMemberAndGroupInfo
        );

        const searchParams = qs.stringify({
          first_name: qMemberData.user.profile.firstName,
          last_name: qMemberData.user.profile.lastName,
          phone: qMemberData.user.phone.number?.substring(2),
          email: qMemberData.user.email,
          company_name:
            selectorMemberCompany(qMemberData) ||
            selectorMemberLegalBusinessName(qMemberData),
          line_1: qMemberData.profile.address?.addressLine1,
          line_2: qMemberData.profile.address?.addressLine2,
          city: qMemberData.profile.address?.city,
          state: qMemberData.profile.address?.state,
          zip: qMemberData.profile.address?.postalCode
        });

        const primaryButtonLink = helperCTAPrimaryButtonLink?.includes("?")
          ? `${helperCTAPrimaryButtonLink}&${searchParams}`
          : `${helperCTAPrimaryButtonLink}?${searchParams}`;

        const memberNumber =
          qEnrollment.data?.wingspanBenefits?.memberNumber || "--";
        const groupNumber =
          qEnrollment.data?.wingspanBenefits?.groupNumber || "--";

        const needsUpgrade = !isBenefitIncludedInSubscriptionLevel(benefit);
        if (needsUpgrade) {
          pill = {
            text: "Premium",
            theme: "neutral",
            icon: "lock"
          };
        } else {
          pill = {
            text: "Included",
            theme: "success"
          };
        }

        const isPending = qService.data.serviceState === ServiceState.Pending;
        const isEnabled = qService.data.serviceState === ServiceState.Enabled;

        const isNotReady = !isEnabled && !isPending;

        let primaryButton: WSPageButtonProps | undefined;

        let showHelperCTAAndContactInfo = false;

        if (isFree) {
          showHelperCTAAndContactInfo = true;
        } else if (isNotReady) {
          primaryButton = {
            label: "Start free trial",
            loading:
              createOrUpdateSubscriptionMeta.isLoading ||
              updateActivityMeta.isLoading,
            onClick: async () => {
              await createOrUpdateSubscription({
                package: SubscriptionPackage.Benefits,
                packageTier: SubscriptionPackageTier.Basic
              });
              await updateActivity({
                flows: {
                  benefitsSetup: {
                    version: 2,
                    totalSteps: 4,
                    currentStep: 1,
                    complete: false
                  }
                }
              });
              setPathAfterSuccess(history.location.pathname);
              history.push("/member/benefits/set-up/2");
            }
          };
        } else if (needsUpgrade) {
          primaryButton = {
            label: "Upgrade membership",
            onClick: async () => {
              history.push("/member/membership-plans");
            }
          };
        } else if (isPending) {
          primaryButton = {
            label: "Pending",
            disabled: true
          };
        } else if (isEnabled) {
          showHelperCTAAndContactInfo = true;
        }

        return (
          <WSPage
            key={`benefit${slug}`}
            title={mainHeader}
            breadcrumb={{
              label: "BENEFITS",
              icon: "arrow-left",
              onClick() {
                routerState && routerState["comingFromBenefitsPage"]
                  ? history.goBack()
                  : history.push("/member/benefits/my-benefits");
              }
            }}
            primaryButton={primaryButton}
            pills={noPill ? undefined : [pill]}
          >
            <WSGrid data-testid="benefitContent">
              <WSGrid.Item span={{ s: "8" }}>
                {mainSubheader && (
                  <WSText.Heading5 mb="XL">{mainSubheader}</WSText.Heading5>
                )}
                {mainVideo && mainVideo.src && (
                  <WSElement mb="XL" className={styles.videoContainer}>
                    <iframe
                      className={styles.video}
                      frameBorder="0"
                      allowFullScreen
                      title={mainVideo.title || mainHeader}
                      src={mainVideo.src}
                    ></iframe>
                  </WSElement>
                )}
                {mainDescription && (
                  <WSElement mb="XL">
                    {mainDescription
                      .split("\n")
                      .map((descriptionLine, index) => (
                        <WSText key={index} mt={index === 0 ? "NONE" : "M"}>
                          {descriptionLine}
                        </WSText>
                      ))}
                  </WSElement>
                )}
                {mainDescriptionList && (
                  <WSElement as="ul" ml="XL" mb="2XL">
                    {mainDescriptionList.split("\n").map((item, index) => (
                      <WSElement key={index} as="li">
                        <WSText mt={index === 0 ? "NONE" : "XS"}>{item}</WSText>
                      </WSElement>
                    ))}
                  </WSElement>
                )}
                {mainCards && (
                  <WSElement mb="2XL">
                    <WSGrid>
                      {mainCards.split("\n-\n").map((card, index) => {
                        const cardParts = card.split("\n");
                        if (cardParts.length > 0) {
                          let icon: WSIconName = "check-envelope";
                          let textParts: string[] = [];
                          if (cardParts.length === 1) {
                            textParts = cardParts;
                          } else {
                            if (isValidIconName(cardParts[0])) {
                              icon = cardParts[0];
                              textParts = cardParts.slice(1);
                            } else {
                              textParts = cardParts;
                            }
                          }
                          return (
                            <WSGrid.Item
                              span={{ xs: "6", l: "4" }}
                              key={card + index}
                            >
                              <WSCard
                                style={{ height: "100%" }}
                                header={{
                                  label: {
                                    avatar: {
                                      type: "icon",
                                      colorBackground: "blue50",
                                      color: "blue500",
                                      icon: icon
                                    }
                                  }
                                }}
                              >
                                <WSList gap="S">
                                  {textParts.map(text => (
                                    <WSText.ParagraphSm
                                      color="gray600"
                                      key={text}
                                    >
                                      {text}
                                    </WSText.ParagraphSm>
                                  ))}
                                </WSList>
                              </WSCard>
                            </WSGrid.Item>
                          );
                        }
                        return undefined;
                      })}
                    </WSGrid>
                  </WSElement>
                )}
                {mainInfoListItems && (
                  <WSElement mb="2XL">
                    <WSText.Heading4 mb="XL">
                      {mainInfoListHeader ? mainInfoListHeader : "How it works"}
                    </WSText.Heading4>
                    {mainInfoListItems.split("\n-\n").map((listItem, index) => {
                      const number = index + 1;
                      const splitItems = listItem.split("\n");
                      if (splitItems.length > 0) {
                        return (
                          <WSFlexBox
                            key={index}
                            mt={index === 0 ? "NONE" : "XL"}
                            wrap="nowrap"
                          >
                            <WSElement>
                              <WSText weight="medium" mr="M">
                                {number}.
                              </WSText>
                            </WSElement>
                            <WSElement>
                              <WSText weight="medium">{splitItems[0]}</WSText>
                              {(() =>
                                splitItems
                                  .slice(1)
                                  .map((item, index) => (
                                    <WSText key={index}>{item}</WSText>
                                  )))()}
                            </WSElement>
                          </WSFlexBox>
                        );
                      }
                      return undefined;
                    })}
                  </WSElement>
                )}
                {mainFAQSItems && (
                  <WSElement mb="2XL">
                    <WSText.Heading4
                      mb="XL"
                      onClick={() => setIsFAQSExpanded(!isFAQSExpanded)}
                    >
                      {mainFAQSHeader ? mainFAQSHeader : "FAQs"}
                      <WSIcon
                        ml="M"
                        name={isFAQSExpanded ? "caret-up" : "caret-down"}
                      />
                    </WSText.Heading4>
                    {isFAQSExpanded &&
                      (typeof mainFAQSItems === "string"
                        ? mainFAQSItems
                            .split("\n-\n")
                            .map((listItem, index) => {
                              const splitItems = listItem.split("\n");
                              if (splitItems.length >= 2) {
                                return (
                                  <WSElement
                                    key={index}
                                    mt={index === 0 ? "NONE" : "XL"}
                                  >
                                    <WSText weight="medium" mb="XS">
                                      {splitItems[0]}
                                    </WSText>
                                    {(() =>
                                      splitItems
                                        .slice(1)
                                        .map((item, index) => (
                                          <WSText key={index}>{item}</WSText>
                                        )))()}
                                  </WSElement>
                                );
                              }
                              return undefined;
                            })
                        : mainFAQSItems)}
                  </WSElement>
                )}
                {mainLearnMoreLink && (
                  <WSButton.Link
                    mb="2XL"
                    onClick={() => openInNewTab(mainLearnMoreLink)}
                  >
                    {mainLearnMoreLinkText
                      ? mainLearnMoreLinkText
                      : `Learn more about ${name}...`}
                  </WSButton.Link>
                )}
                {mainFootnote && (
                  <WSText color="gray500" mb="2XL">
                    {mainFootnote}
                  </WSText>
                )}
              </WSGrid.Item>
              {hasHelper && (
                <WSGrid.Item span={{ s: "4" }}>
                  <WSPanel p="NONE">
                    {helperHeader && (
                      <WSElement className={styles.helperHeaderContainer} p="S">
                        <WSText align="center" color="gray600">
                          {helperHeader}
                        </WSText>
                      </WSElement>
                    )}
                    <WSElement p="M">
                      {helperLogo?.src && (
                        <WSImage
                          mb="XL"
                          src={helperLogo.src}
                          placeholder={helperLogo.placeholder}
                        />
                      )}

                      <WSText weight="medium" mb="XL">
                        Cost: {getCostOfBenefitWithSubscription(benefit)}
                      </WSText>
                      {helperUsageText && (
                        <WSText mb="XL">
                          {insertMemberAndGroupIdsIntoText(
                            helperUsageText,
                            memberNumber,
                            groupNumber
                          )}
                        </WSText>
                      )}
                      {showHelperCTAAndContactInfo && (
                        <>
                          {helperCTAPrimaryButtonText &&
                            helperCTAPrimaryButtonLink && (
                              <WSButton
                                mb="XL"
                                onClick={() => openInNewTab(primaryButtonLink)}
                              >
                                {helperCTAPrimaryButtonText}
                              </WSButton>
                            )}
                          {helperContactInfoDescription && (
                            <WSText mb="XL">
                              {helperContactInfoDescription}
                            </WSText>
                          )}
                          {(helperContactInfoEmail ||
                            helperContactInfoPhone) && (
                            <WSMessageBox kind="Regular" mb="XL" noBorder>
                              {(() => {
                                let instruction = "Contact";
                                if (
                                  helperContactInfoPhone &&
                                  helperContactInfoEmail
                                ) {
                                  instruction = "Call or email";
                                } else if (helperContactInfoEmail) {
                                  instruction = "Email";
                                } else {
                                  instruction = "Call";
                                }
                                return (
                                  <>
                                    <WSText mb="XS">{instruction}</WSText>
                                    {helperContactInfoPhone && (
                                      <WSText.Heading4
                                        onClick={() =>
                                          openInNewTab(
                                            `tel:${helperContactInfoPhone.replace(
                                              /\D/g,
                                              ""
                                            )}`
                                          )
                                        }
                                        mb={
                                          helperContactInfoEmail ? "XS" : "NONE"
                                        }
                                      >
                                        {helperContactInfoPhone}
                                      </WSText.Heading4>
                                    )}
                                    {helperContactInfoEmail && (
                                      <WSButton.Link
                                        onClick={() =>
                                          window.open(
                                            `mailto:${helperContactInfoEmail}`
                                          )
                                        }
                                      >
                                        {helperContactInfoEmail}
                                      </WSButton.Link>
                                    )}
                                  </>
                                );
                              })()}
                            </WSMessageBox>
                          )}
                        </>
                      )}
                      {!helperHideMemberAndGroupInfo && (
                        <WSElement>
                          <WSText.ParagraphSm color="gray500">
                            Your Member ID
                          </WSText.ParagraphSm>
                          <WSText mt="XS">{memberNumber}</WSText>
                          <WSText.ParagraphSm color="gray500" mt="XL">
                            Your Group ID
                          </WSText.ParagraphSm>
                          <WSText mt="XS">{groupNumber}</WSText>
                        </WSElement>
                      )}
                    </WSElement>
                  </WSPanel>
                </WSGrid.Item>
              )}
            </WSGrid>
          </WSPage>
        );
      }}
    </WSQueries>
  );
};
