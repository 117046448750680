import {
  CorrectionType,
  IPayeeTaxFormResponse,
  TaxFormCorrectionStatus,
  TaxFormViewer
} from "@wingspanhq/payments/dist/interfaces";

export const selectorPayeeTaxFormIsLatestAddressCorrection = (
  taxForm: IPayeeTaxFormResponse
) => {
  if (taxForm.corrections?.length) {
    const latestCorrection =
      taxForm.corrections[taxForm.corrections.length - 1];

    const isOwnedByPayee = latestCorrection.ownedBy === TaxFormViewer.payee;
    const isCreatedByPayee =
      latestCorrection.eventActors.createdBy === taxForm.memberId;

    return (
      latestCorrection.status ===
        TaxFormCorrectionStatus.AppliedWithoutSubmission &&
      latestCorrection.correctionType === CorrectionType.None &&
      (isOwnedByPayee || isCreatedByPayee)
    );
  }

  return false;
};
