import { IPaymentCard } from "@wingspanhq/payments/dist/interfaces";
import {
  IPaymentCardCompleteCreateRequest,
  paymentsService
} from "../../../services/payments";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation } from "../../helpers";
import { QUERY_PAYMENT_CARDS } from "../keys";

export const useMutationCompletePaymentCardCreate = () =>
  useWSMutation<
    IPaymentCard,
    WSServiceError,
    { paymentCardId: string } & IPaymentCardCompleteCreateRequest
  >(
    ({ paymentCardId, ...data }) => {
      return paymentsService.paymentCard.completeCreate(paymentCardId, {
        ...data
      });
    },
    { dependencies: [QUERY_PAYMENT_CARDS] }
  );
