import {
  TaxFormEventType,
  TaxFormExclusionReason
} from "@wingspanhq/payments/dist/interfaces/taxForm";
import React from "react";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { selectorMemberName } from "../../../../../shared/selectors/selectorMemberName";
import { toWSDateString } from "@wingspanhq/fe-component-library";

export const EXCLUSION_REASONS: {
  [key in TaxFormExclusionReason]: {
    title: string;
    getMessage(taxForm: IPayerTaxFormResponse): React.ReactNode;
  };
} = {
  [TaxFormExclusionReason.BelowThreshold]: {
    title: "Excluded for minimum nonemployee compensation threshold",
    getMessage: () => (
      <>
        Recipients who have earned less than $600 from a single payer during the
        tax year do not meet the threshold for 1099 issuance.
      </>
    )
  },
  [TaxFormExclusionReason.IneligibleCompanyStructure]: {
    title: "Excluded for federal tax classification",
    getMessage: () => (
      <>
        Payments to C Corporations, S Corporations, and LLCs classified as
        corporations are typically exempt from receiving 1099-NECs.
      </>
    )
  },

  [TaxFormExclusionReason.NonUsEntity]: {
    title: "Excluded for Non-U.S. Status",
    getMessage: () => (
      <>
        Recipients not classified as U.S. citizens, resident aliens, or domestic
        business entities, such as partnerships and LLCs, are exempt from 1099
        forms.
      </>
    )
  },
  [TaxFormExclusionReason.ManuallyExcluded]: {
    title: "Excluded manually",
    getMessage: taxForm => {
      const event = taxForm.eventHistory
        .filter(
          event =>
            event.eventType === TaxFormEventType.PayerManuallyUpdatedStatus
        )
        .pop();

      if (event?.eventActor) {
        return (
          <>
            Manually excluded by {selectorMemberName(event.eventActor)} on{" "}
            {toWSDateString(event.timestamp, "monthDayYear")}.
          </>
        );
      } else {
        return <>This form was excluded manually.</>;
      }
    }
  }
};
