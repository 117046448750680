import { MembershipSelectorProps } from "../components/MembershipSelector";
import { SUBSCRIPTION_SELECT_OPTIONS } from "../constants/subscriptionOptions";

export const selectorSubscriptionByTitle = (
  title: MembershipSelectorProps["value"]
) => {
  return SUBSCRIPTION_SELECT_OPTIONS.find(
    option => option.title === title
  ) as typeof SUBSCRIPTION_SELECT_OPTIONS[number];
};
