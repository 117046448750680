import {
  useModalContext,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import { PayoutDestinationType } from "@wingspanhq/payments/dist/interfaces";
import { ICreditCard } from "@wingspanhq/users/dist/lib/interfaces";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces/account";
import React from "react";
import { PaymentMethodType } from "../../../components/WSAccountsListV2/types";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { QUERY_PAYOUT_SETTINGS } from "../../../query/payments/keys";
import { usePayoutSettings } from "../../../query/payments/queries";
import {
  QUERY_USERS_ACCOUNTS,
  QUERY_USERS_CLIENT
} from "../../../query/users/keys";
import { useClientQuery } from "../../../query/users/queries";
import { paymentsService } from "../../../services/payments";
import { usersService } from "../../../services/users";
import { WSServiceError } from "../../../utils/serviceHelper";

export const DELETE_PAYMENT_METHOD_MODAL = "DELETE_PAYMENT_METHOD_MODAL";

interface Props {
  paymentMethod: IAccount | ICreditCard;
  paymentMethodType: PaymentMethodType;
}

export const DeletePaymentMethodModal: React.FC<{ onSuccess: () => void }> = ({
  onSuccess
}) => {
  const userId = useUserId();
  const clientQuery = useClientQuery(userId);
  const { closeModal } = useModalContext();
  const payoutSettingsQuery = usePayoutSettings(userId);

  const [deletePaymentMethod, deletePaymentMethodMeta] = useWSMutation<
    void,
    WSServiceError,
    Props
  >(
    async ({ paymentMethod, paymentMethodType }) => {
      if (paymentMethodType === PaymentMethodType.CREDIT_CARD) {
        const cardId = (paymentMethod as ICreditCard).paymentMethodId;
        await usersService.client.update(userId, {
          clientId: userId,
          profile: {
            defaultPaymentMethod:
              clientQuery.data?.profile.defaultPaymentMethod.paymentMethodId ===
              cardId
                ? { paymentMethodId: null }
                : undefined,
            savedPaymentMethods: clientQuery.data?.profile.savedPaymentMethods?.map(
              pm => (pm.paymentMethodId === cardId ? null : pm)
            )
          }
        });
      } else {
        const accountId = (paymentMethod as IAccount).accountId;
        if (
          clientQuery.data?.profile.defaultPaymentMethod?.accountId ===
          accountId
        ) {
          await usersService.client.update(userId, {
            clientId: userId,
            profile: {
              defaultPaymentMethod: { paymentMethodId: null }
            }
          });
        }

        const payoutDestination = payoutSettingsQuery.data?.payoutDestinations.find(
          destination =>
            destination.destinationType === PayoutDestinationType.Account &&
            destination.destinationId === accountId
        );
        if (payoutDestination) {
          await paymentsService.payoutSettings.update(userId, {
            standard: null
          });
        }

        await usersService.account.delete(accountId);
      }
    },
    {
      dependencies: [
        QUERY_USERS_CLIENT,
        QUERY_USERS_ACCOUNTS,
        QUERY_PAYOUT_SETTINGS
      ],
      onSuccess: () => {
        onSuccess();
        closeModal(DELETE_PAYMENT_METHOD_MODAL);
      }
    }
  );

  return (
    <WSModal
      size="XS"
      name={DELETE_PAYMENT_METHOD_MODAL}
      title="Delete payment method?"
    >
      {(props: Props) => (
        <>
          <WSText>Once confirmed, this action can't be undone.</WSText>

          <WSButtons mt="XL" format="modal">
            <WSButton
              destructive
              loading={deletePaymentMethodMeta.isLoading}
              onClick={() => {
                deletePaymentMethod(props);
              }}
            >
              Delete
            </WSButton>
            <WSButton.Secondary
              onClick={() => {
                closeModal(DELETE_PAYMENT_METHOD_MODAL);
              }}
            >
              Cancel
            </WSButton.Secondary>
          </WSButtons>
        </>
      )}
    </WSModal>
  );
};
