import { QueryConfig } from "react-query";
import { useWSQuery } from "../../../query/helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import {
  InvoiceTemplateRowsQuery,
  InvoiceTemplateRowsSummary,
  getInvoiceTemplateRowsSummary
} from "../service";

export const QUERY_KEY_INOVICE_TEMPLATE_ROWS_SUMMARY =
  "QUERY_KEY_INOVICE_TEMPLATE_ROWS_SUMMARY";

export const useQueryInvoiceTemplateRowsSummary = (
  requestQuery?: InvoiceTemplateRowsQuery,
  config?: QueryConfig<InvoiceTemplateRowsSummary, WSServiceError>
) =>
  useWSQuery<InvoiceTemplateRowsSummary, WSServiceError>(
    [QUERY_KEY_INOVICE_TEMPLATE_ROWS_SUMMARY, requestQuery],
    () => getInvoiceTemplateRowsSummary(requestQuery),
    config
  );
