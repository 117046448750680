import { QueryConfig } from "react-query";
import { IRequirementDefinitionResponse } from "@wingspanhq/payments/dist/interfaces";

import { useWSQuery } from "../../helpers";
import { QUERY_REQUIREMENT_DEFINITION } from "../keys";
import { getRequirement } from "../../../services/requirementDefinitions/getRequirement";

export const useRequirementDefinitionQuery = (
  id: string,
  queryConfig?: QueryConfig<IRequirementDefinitionResponse, unknown>
) =>
  useWSQuery([QUERY_REQUIREMENT_DEFINITION, id], () => getRequirement(id), {
    retry: false,
    ...queryConfig
  });
