import { WSElement, WSText } from "@wingspanhq/fe-component-library";
import { ProgressBar } from "../../../MemberOnboarding/components/ProgressBar/ProgressBar";

type Props = {
  ms: number;
  onComplete: () => void;
};

export const RecipientVerificationProgress: React.FC<Props> = ({
  ms,
  onComplete
}) => {
  return (
    <WSElement>
      <WSText.Heading5 mb="XL">
        Verifying recipient taxpayer identification number
      </WSText.Heading5>

      <ProgressBar mb="XL" ms={ms} onComplete={onComplete} />

      <WSText mb="M">
        We are confirming the recipient TIN with the IRS. This can take up to 10
        seconds.
      </WSText>

      <WSText color="gray400">
        Do not close this window while confirmation is in progress.
      </WSText>
    </WSElement>
  );
};
