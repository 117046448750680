import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { getPathForBenefit, isBenefitHidden } from "./utils";
import { BenefitDetails } from "./screens/BenefitDetails";
import { BenefitDetailsV2 } from "./screens/BenefitDetailsV2";
import { BenefitsContactForm } from "./screens/BenefitsContactForm";
import { MyBenefits } from "./screens/MyBenefits";
import { AllMyBenefits } from "./screens/AllMyBenefits";
import { AdditionalCoverage } from "./screens/AdditionalCoverage";
import { AllAdditionalCoverage } from "./screens/AllAdditionalCoverage";
import { BenefitsSetUpStep1 } from "./screens/BenefitsSetUpStep1";
import { BenefitsSetUpStep2 } from "./screens/BenefitsSetUpStep2";
import { BenefitsSetUpStep3 } from "./screens/BenefitsSetUpStep3";
import { BenefitsSetUpCelebration } from "./screens/BenefitsSetUpCelebration";
import { BenefitsSetUpSuccess } from "./screens/BenefitsSetUpSuccess";
import { WSQueries } from "../query/WSQuery";
import {
  useActivities,
  useMemberProfile,
  useUserProfile
} from "../query/users/queries";
import { useUserId } from "../query/hooks/helpers";
import { ProductActivationWrapper } from "../components/ProductActivationWrapper/ProductActivationWrapper";
import { useFeatureFlags } from "../query/hooks/useFeatureFlags";
import { BenefitsCMSPage } from "./screens/BenefitsCMSPage";
import { useCMSWebflowBenefits } from "../query/cms/queries";
import { getBenefitsByTitle } from "./utils/getBenefitByTitle";
import { getMeridioHealthInsuranceBenefit } from "./screens/BenefitsV3";

export const BenefitsRoutes: React.FC = () => {
  const userId = useUserId();
  const qActivity = useActivities(userId);
  const qFeatureFlags = useFeatureFlags();
  const qCMSWebflowBenefits = useCMSWebflowBenefits();
  const qMember = useMemberProfile(userId);

  return (
    <ProductActivationWrapper>
      <WSQueries
        queries={{
          qActivity,
          qFeatureFlags,
          qCMSWebflowBenefits,
          qMember
        }}
        isPage
      >
        {({ qActivity, qCMSWebflowBenefits, qFeatureFlags, qMember }) => {
          const cmsBenefits = qCMSWebflowBenefits.data.filter(
            benefit => !isBenefitHidden(benefit)
          );
          const useCMS = cmsBenefits.length > 0;
          const enabledBenefits = [getBenefitsByTitle("Health Insurance")];
          const parentPaths = [
            "additional-coverage",
            "additional-coverage/all",
            "my-benefits",
            "my-benefits/all"
          ];

          return (
            <Switch>
              <Route
                exact
                path="/member/benefits/set-up/1"
                component={BenefitsSetUpStep1}
              />

              <Route
                exact
                path="/member/benefits/set-up/2"
                component={BenefitsSetUpStep2}
              />

              <Route
                exact
                path="/member/benefits/set-up/3"
                component={BenefitsSetUpStep3}
              />

              <Route
                exact
                path="/member/benefits/set-up/celebration"
                component={BenefitsSetUpCelebration}
              />

              <Route
                exact
                path="/member/benefits/set-up/success"
                component={BenefitsSetUpSuccess}
              />

              <Route
                path="/member/benefits/contact-info"
                component={BenefitsContactForm}
              />

              <Route
                exact
                path={["/member/benefits/set-up"]}
                render={() => {
                  const currentStep =
                    qActivity.data.flows.benefitsSetup?.currentStep || 0;
                  let nextStep: number | string = 1;

                  if (currentStep < 3) {
                    nextStep = currentStep + 1;
                  } else {
                    nextStep = "celebration";
                  }

                  return (
                    <Redirect to={`/member/benefits/set-up/${nextStep}`} />
                  );
                }}
              />

              {useCMS &&
                cmsBenefits.map(benefit => [
                  <Route
                    key={`additional-coverage/-${benefit.slug}`}
                    path={`/member/benefits/additional-coverage/${benefit.slug}`}
                    exact
                    render={() => <BenefitsCMSPage isFree benefit={benefit} />}
                  />,
                  <Route
                    key={`additional-coverage/all-${benefit.slug}`}
                    path={`/member/benefits/additional-coverage/all/*/${benefit.slug}`}
                    exact
                    render={() => <BenefitsCMSPage isFree benefit={benefit} />}
                  />,
                  <Route
                    key={`my-benefits/-${benefit.slug}`}
                    path={`/member/benefits/my-benefits/${benefit.slug}`}
                    exact
                    render={() => <BenefitsCMSPage benefit={benefit} />}
                  />,
                  <Route
                    key={`my-benefits/all-${benefit.slug}`}
                    path={`/member/benefits/my-benefits/all/*/${benefit.slug}`}
                    exact
                    render={() => <BenefitsCMSPage benefit={benefit} />}
                  />
                ])}

              {qFeatureFlags.data.meridio
                ? parentPaths.flatMap(path => (
                    <Route
                      key={getPathForBenefit("Meridio Health Insurance", path)}
                      path={getPathForBenefit("Health Insurance", path)}
                      exact
                      render={() => (
                        <BenefitsCMSPage
                          isFree
                          noPill
                          benefit={getMeridioHealthInsuranceBenefit(
                            qMember.data
                          )}
                        />
                      )}
                    />
                  ))
                : null}

              {parentPaths.flatMap(path =>
                enabledBenefits.map(benefit => (
                  <Route
                    key={benefit.title}
                    path={getPathForBenefit(benefit.title, path)}
                    exact
                    render={() =>
                      benefit.detailsJson ? (
                        <BenefitDetailsV2 isFree={true} {...(benefit as any)} />
                      ) : (
                        <BenefitDetails isFree={true} {...(benefit as any)} />
                      )
                    }
                  />
                ))
              )}

              <Route
                path="/member/benefits/my-benefits/all"
                component={AllMyBenefits}
              />

              <Route
                path="/member/benefits/additional-coverage/all"
                component={AllAdditionalCoverage}
              />

              <Route
                path="/member/benefits/additional-coverage"
                component={AdditionalCoverage}
              />

              <Route
                path="/member/benefits/my-benefits"
                component={MyBenefits}
              />

              <Redirect
                from="/member/benefits"
                to="/member/benefits/my-benefits"
              />

              <Redirect from="*" to="/member/benefits" />
            </Switch>
          );
        }}
      </WSQueries>
    </ProductActivationWrapper>
  );
};
