import {
  WSButton,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSPill,
  WSSelectOld,
  WSTable,
  WSText
} from "@wingspanhq/fe-component-library";
import { IAdditionalData } from "@wingspanhq/payments/dist/interfaces";
import {
  IInvoiceAgingReportResponse,
  ReportAgingType
} from "@wingspanhq/payments/dist/interfaces/api/reports";
import { formatMoney } from "accounting";
import React, { useState } from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import { useOpenReceivableAgingReportsQuery } from "../../../../query/payments/queries";
import { VerticalDivider } from "../../../../shared/components/VerticalDivider";
import { track } from "../../../../utils/analytics";
import { DownloadReportButton } from "../../components/DownloadReportButton/DownloadReportButton";
import { useSelectColumnsModal } from "../../components/ModalSelectColumns";
import { useReportColumns } from "../../hooks/useReportColumns";
import { ReportFormat, ReportType } from "../../types";
import { selectorColumnsWithAdditionalData } from "../../utils/selectorColumnsWithAdditionalData";
import { COLUMNS } from "./columns";
import { selectorTableColumns } from "../../utils/selectorTableColumns";

const SELECTED_COLUMNS_STORAGE_KEY =
  "REPORTS_OPEN_RECEIVABLE_AGING_SELECTED_COLUMNS_STORAGE_KEY";

export const OpenReceivableAgingReportDashboard: React.FC<{
  customFieldsData: IAdditionalData[];
}> = ({ customFieldsData }) => {
  const userId = useUserId();
  const [selectedAgeGrouping, setSelectedAgeGrouping] = useState<
    ReportAgingType
  >(ReportAgingType.Weekly);

  const modalSelectColumns = useSelectColumnsModal();

  const columns = selectorColumnsWithAdditionalData<
    IInvoiceAgingReportResponse
  >(COLUMNS, customFieldsData);

  const [selectedColumns, setSelectedColumns] = useReportColumns(
    SELECTED_COLUMNS_STORAGE_KEY,
    userId,
    columns
  );

  const getColumnByKey = (key: string) =>
    columns.find(_column => key === _column.value)!;

  const [reportsData, setReportsData] = useState<
    {
      [key in ReportAgingType]?: IInvoiceAgingReportResponse[];
    }
  >({});

  const currentReportData = reportsData[selectedAgeGrouping!];
  const tableData = currentReportData || [];

  const isRunButtonDisabled = !selectedAgeGrouping || !selectedColumns.length;

  const isDownloadButtonDisabled = !tableData.length;

  const queryReport = useOpenReceivableAgingReportsQuery(
    {
      type: selectedAgeGrouping
    },
    {
      enabled: false
    }
  );

  const getReportJSON = () => {
    return tableData.map(data => {
      return selectedColumns.reduce((result, column) => {
        const currentColumn = getColumnByKey(column)!;
        let field = {};

        try {
          field = {
            [currentColumn?.label]:
              currentColumn.getReportCell?.(data) ||
              currentColumn?.getTableCell?.(data)
          };
        } catch (e) {
          console.error(`REPORT ERROR! "${currentColumn?.label}": `, e);
        }

        return { ...result, ...field };
      }, {});
    });
  };

  return (
    <>
      <WSDivider mb="M" />
      <WSGrid>
        <WSGrid.Item span={{ s: "4" }}>
          <WSSelectOld
            searchable={false}
            onChange={(v: any) => {
              setSelectedAgeGrouping(v);
            }}
            name="ageGrouping"
            value={selectedAgeGrouping as string}
            placeholder="Select age grouping"
            error={false}
            options={[
              {
                value: ReportAgingType.Weekly,
                label: "7-days"
              },
              {
                value: ReportAgingType.Monthly,
                label: "30-days"
              }
            ]}
          />
        </WSGrid.Item>
        <WSGrid.Item span={{ s: "8" }}>
          <WSFlexBox.CenterY>
            <VerticalDivider mr="XL" />
            <WSFlexBox.CenterY
              onClick={() =>
                modalSelectColumns.open({
                  selectedColumns,
                  columns,
                  onSubmit: ({ columns }) => {
                    setSelectedColumns(columns);
                  }
                })
              }
              data-testid="selectColumns"
            >
              <WSButton.Link icon="menu">Selected columns</WSButton.Link>
              <WSPill ml="M" theme="blue" text={`${selectedColumns.length}`} />
            </WSFlexBox.CenterY>

            <VerticalDivider mx="XL" />
            <WSButton.Primary
              name="runReport"
              disabled={isRunButtonDisabled}
              onAsyncClick={async () => {
                track("Report Ran", {
                  reportName: ReportType.OpenReceivableAging,
                  selectedColumnsCount: selectedColumns.length,
                  selectedColumns,
                  filters: { type: selectedAgeGrouping }
                });
                const result = await queryReport.refetch({
                  throwOnError: true
                });

                setReportsData(data => ({
                  ...data,
                  ...(result ? { [selectedAgeGrouping!]: result } : {})
                }));
              }}
            >
              Run report
            </WSButton.Primary>
          </WSFlexBox.CenterY>
        </WSGrid.Item>
      </WSGrid>
      <WSDivider mt="M" />
      <WSFlexBox.CenterY mt="2XL" mb="XL" justify="flex-end">
        <DownloadReportButton
          disabled={isDownloadButtonDisabled}
          getData={getReportJSON}
          onClick={(format: ReportFormat) => {
            track("Report Downloaded", {
              reportName: ReportType.OpenReceivableAging,
              selectedColumnsCount: selectedColumns.length,
              selectedColumns,
              filters: { type: selectedAgeGrouping },
              format
            });
          }}
          fileName={`Wingspan Open Age Grouping Report`}
          sheetName={`Sheet ${selectedAgeGrouping}`}
        />
      </WSFlexBox.CenterY>

      {tableData.length ? (
        <WSElement
          style={{
            overflowX: selectedColumns.length > 6 ? "scroll" : undefined,
            transform: "rotateX(180deg)"
          }}
        >
          <WSTable<IInvoiceAgingReportResponse>
            mb="M"
            mt="XL"
            style={{
              minWidth:
                selectedColumns.length > 6
                  ? selectedColumns.length * 175
                  : undefined,
              transform: "rotateX(180deg)"
            }}
            getGroupName={item => {
              const amount = tableData
                .filter(({ ageGroup }) => {
                  return item.data.ageGroup === ageGroup;
                })
                .reduce((acc, { amount }) => {
                  return acc + amount;
                }, 0);

              return `${item.data.ageGroup} (${formatMoney(amount)} total)`;
            }}
            columns={selectorTableColumns(
              selectedColumns.map(getColumnByKey).filter(Boolean)
            )}
            tableData={tableData.map((data, i) => ({
              data,
              id: `id_${i}_${data.invoiceId}`
            }))}
          />
        </WSElement>
      ) : (
        <WSFlexBox.Center mt="2XL">
          <WSText color="gray500">
            {currentReportData?.length === 0
              ? `No invoices available`
              : `Select a grouping type then click 'Run Report'`}
          </WSText>
        </WSFlexBox.Center>
      )}
    </>
  );
};
