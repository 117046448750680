import {
  ClientWorkFlowStatus,
  IPayableSchema
} from "@wingspanhq/payments/dist/interfaces";
import { selectorIsPayableOpen } from "./selectorIsPayableOpen";

export const selectorIsPayableFunded = (payable: IPayableSchema) => {
  return (
    selectorIsPayableOpen(payable) &&
    (payable.client.workflowStatus === ClientWorkFlowStatus.PaymentInitiated ||
      payable.client.workflowStatus === ClientWorkFlowStatus.Funded)
  );
};
