import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { selectorAccountInstitution } from "./selectorAccountInstitution";
import { selectorAccountName } from "./selectorAccountName";

export const selectorAccountFullName = (account: IAccount): string => {
  const institution = selectorAccountInstitution(account);
  const name = selectorAccountName(account);

  if (!institution) {
    return name;
  }

  return `${institution} ${name}`;
};
