import { queryCache } from "react-query";
import { useWSMutation } from "../../../../query/helpers";
import {
  QUERY_BULK_COLLABORATOR_BATCH,
  QUERY_BULK_COLLABORATOR_BATCH_LIST,
  QUERY_BULK_COLLABORATOR_BATCH_SUMMARY
} from "./keys";
import { WSServiceError } from "../../../../utils/serviceHelper";
import {
  IBulkBatchCreate,
  IBulkBatchUpdate,
  IBulkCollaboratorBatch
} from "@wingspanhq/payments/dist/interfaces";
import { bulkCollaboratorService } from "../../services/bulkCollaborator";

export const useCreateBulkCollaboratorBatch = () =>
  useWSMutation((data: IBulkBatchCreate) =>
    bulkCollaboratorService.batch.create(data ?? {})
  );

export const useUpdateBulkCollaboratorBatch = () =>
  useWSMutation(
    (payload: { id: string; data: IBulkBatchUpdate }) =>
      bulkCollaboratorService.batch.update(payload.id, payload.data),
    {
      dependencies: [
        QUERY_BULK_COLLABORATOR_BATCH,
        QUERY_BULK_COLLABORATOR_BATCH_SUMMARY
      ]
    }
  );

export const useDeleteBulkCollaboratorBatch = () => {
  return useWSMutation<
    IBulkCollaboratorBatch,
    WSServiceError,
    {
      bulkBatchId: string;
    }
  >(
    async ({ bulkBatchId }) => {
      return await bulkCollaboratorService.batch.delete(bulkBatchId);
    },
    {
      dependencies: [QUERY_BULK_COLLABORATOR_BATCH_LIST]
    }
  );
};
