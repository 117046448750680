import { IMemberClient } from "@wingspanhq/payments/dist/interfaces";

export const selectorClientsWithEligibilityRequirements = (
  memberClients: IMemberClient[]
) =>
  memberClients.filter(
    memberClient =>
      memberClient.eligibilityRequirements &&
      memberClient.eligibilityRequirements.length > 0
  );
