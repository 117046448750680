import { IWebflowBenefitCategory } from "@wingspanhq/cms/dist/lib/interfaces/webflow/benefits";
import {
  useIsMobile,
  WSButton,
  WSElement,
  WSFlexBox,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { useCMSWebflowBenefits } from "../../query/cms/queries";
import { WSQueries } from "../../query/WSQuery";
import { BenefitsRequestBenefit } from "../modals/BenefitsRequestBenefit";
import {
  isBenefitHidden,
  isBenefitIncludedInSubscriptionLevel,
  sortCMSBenefits
} from "../utils";
import styles from "./Benefits.module.scss";
import { BenefitsCMSBlock } from "./BenefitsCMSBlock";

export const emptyBenefitsList = (
  <WSText.ParagraphXs mt="XL">
    You have no benefits through Wingspan because your membership is expired!
    Please update your <Link to="/member/membership-plans">membership</Link>.
    For more information on what you're missing out on, please see{" "}
    <Link to="/member/benefits/additional-coverage">Insurance</Link>.
  </WSText.ParagraphXs>
);

export const MyBenefits: React.FC<RouteComponentProps> = ({ history }) => {
  const isMobile = useIsMobile();
  const qCMSWebflowBenefits = useCMSWebflowBenefits();

  useBrowserPageTitle("Benefits - Premium Perks");

  return (
    <WSPage title={"Premium Perks"}>
      <BenefitsRequestBenefit />

      <WSQueries
        queries={{
          qCMSWebflowBenefits
        }}
        renderLoader={() => <WSPage />}
      >
        {({ qCMSWebflowBenefits }) => {
          const cmsBenefits = qCMSWebflowBenefits.data.filter(
            benefit =>
              !isBenefitHidden(benefit) &&
              isBenefitIncludedInSubscriptionLevel(benefit)
          );

          const myFeaturedBenefits = cmsBenefits
            ?.filter(benefit => benefit.featured)
            .sort((a, b) => sortCMSBenefits(a, b));

          const legalAndFinancialSupportBenefits = cmsBenefits
            ?.filter(benefit =>
              [
                IWebflowBenefitCategory.Finances,
                IWebflowBenefitCategory.Legal
              ].includes(benefit.category)
            )
            .sort((a, b) => sortCMSBenefits(a, b));

          return (
            <>
              <WSText className={styles.text}>
                With your Premium Perks subscription, you get access to a
                curated suite of benefits and perks that you'd typically only
                find at Fortune 500 companies. All of these benefits for just
                $10/month and they cover your dependents.
              </WSText>

              <WSFlexBox.CenterY
                mt={isMobile ? "3XL" : "2XL"}
                justify="space-between"
              >
                <WSText.Heading4>My featured benefits</WSText.Heading4>
                <WSButton.Link
                  onClick={() =>
                    history.push("/member/benefits/my-benefits/all")
                  }
                >
                  View all
                </WSButton.Link>
              </WSFlexBox.CenterY>
              <WSElement>
                {myFeaturedBenefits.length
                  ? myFeaturedBenefits.map(benefit => (
                      <BenefitsCMSBlock
                        benefit={benefit}
                        parentPath="my-benefits"
                      />
                    ))
                  : emptyBenefitsList}
              </WSElement>
              <WSText.Heading4 mt="2XL">
                Legal and financial support
              </WSText.Heading4>
              <WSElement mb="3XL">
                {legalAndFinancialSupportBenefits.length
                  ? legalAndFinancialSupportBenefits.map(benefit => (
                      <BenefitsCMSBlock
                        benefit={benefit}
                        parentPath="my-benefits"
                      />
                    ))
                  : emptyBenefitsList}
              </WSElement>
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
