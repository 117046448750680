import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFormOld,
  WSInputMaskOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { useWSMutation } from "../../query/helpers";
import { ErrorContextKey } from "../../services/platform";
import { usersService } from "../../services/users";
import { WSServiceError } from "../../utils/serviceHelper";
import { WSErrorMessage } from "../WSErrorMessage/WSErrorMessage";
import { validatorOTP } from "../../shared/validators/validatorOTP";

type FormData = { code: string };

type Props = {
  onSuccess: () => void;
} & WSElementProps;

export const SmsConfirmationForm: React.FC<Props> = ({
  onSuccess,
  ...elementProps
}) => {
  const [submit, submitMeta] = useWSMutation<void, WSServiceError, FormData>(
    async data => {
      await usersService.session.createOtpSession({
        code: data.code
      });
    },
    {
      onSuccess
    }
  );

  return (
    <WSElement {...elementProps}>
      <WSText color="gray600" mb="XL" align="left">
        A 6 digit verification code has been sent via SMS to your registered
        mobile number.
      </WSText>
      <WSFormOld<FormData>
        onSubmit={submit}
        validationSchema={Yup.object().shape({
          code: validatorOTP
        })}
      >
        <WSFormOld.Field
          mb="XL"
          label="Verification code"
          name="code"
          component={WSInputMaskOld}
          componentProps={{
            autoComplete: "one-time-code",
            mask: "999999",
            slotChar: ""
          }}
        />
        <WSErrorMessage
          mb="XL"
          error={submitMeta.error}
          contextKey={ErrorContextKey.CreateOtpSession}
        />
        <WSButton
          name="confirmTwoFactorAuth"
          fullWidth
          loading={submitMeta.isLoading}
        >
          Continue
        </WSButton>
      </WSFormOld>
    </WSElement>
  );
};
