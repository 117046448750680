import React from "react";
import { WSIcon } from "../../core/WSIcon/WSIcon.component";
import { WSPanel } from "../../core/WSPanel/WSPanel.component";
import { WSText } from "../../core/WSText/WSText.component";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import styles from "./WSInfoBanner.module.scss";

export interface WSInfoBannerProps extends Omit<WSElementProps, "title"> {
  title: React.ReactNode;
  onClose(): void;
}

export const WSInfoBanner: React.FC<WSInfoBannerProps> = ({
  title,
  onClose,
  children,
  ...other
}) => (
  <WSPanel.BS1 className={styles.container} {...other}>
    <WSElement className={styles.header}>
      <WSText weight="medium">{title}</WSText>
      <WSIcon block name="exit" onClick={onClose} />
    </WSElement>
    <WSElement className={styles.childrenContainer}>{children}</WSElement>
  </WSPanel.BS1>
);
