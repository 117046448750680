import React from "react";
import {
  WSButton,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { openIntercom } from "../../../../shared/utils/intercom";

export interface WherePayoutModalProps extends WSElementProps {
  onClose: () => void;
  onSuccess?: () => void;
}

export const WherePayoutModal: React.FC<WherePayoutModalProps> = ({
  onClose,
  onSuccess,
  ...elementProps
}) => {
  return (
    <WSElement {...elementProps}>
      <WSDivider mb="XL" />
      <WSElement mb="XL" colorBackground="gray50" p="2XL">
        <WSText color="gray500" mb="L">
          Your funds have been cleared to deposit into the accounts listed in
          the payout account details. If you don't see the funds in your
          account, here's what you can do:
        </WSText>
        <WSText color="gray500" mb="L">
          <WSText inline color="gray500" weight="medium">
            1. Contact Your Bank with the Trace Information:
          </WSText>{" "}
          In the payout account details, you'll find detailed trace information
          related to this payment. Please reach out to your bank's customer
          support line and provide them with these details.
        </WSText>
        <WSText color="gray500" mb="L">
          <WSText inline color="gray500" weight="medium">
            2. Wait Until the End of the Next Business Day:
          </WSText>{" "}
          Some banks add delays outside of Wingspan's control. Please wait for
          one more business day to see if the funds appear.
        </WSText>
        <WSText color="gray500" mb="L">
          <WSText inline color="gray500" weight="medium">
            3. Contact Support:
          </WSText>{" "}
          If the funds still don't appear, please contact Wingspan support.
          We're here to help!
        </WSText>
      </WSElement>
      <WSDivider mb="XL" />
      <WSFlexBox.CenterY justify="flex-end" wrap="nowrap">
        <WSButton.Link
          size="M"
          fullWidth={true}
          name="Contact"
          onClick={openIntercom}
        >
          Contact support
        </WSButton.Link>
        <WSButton.Primary
          size="M"
          fullWidth={true}
          name="Close"
          onClick={onClose}
        >
          Close
        </WSButton.Primary>
      </WSFlexBox.CenterY>
    </WSElement>
  );
};
