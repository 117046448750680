import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSMessageBox,
  WSPanel,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayerTaxFormResponse,
  TaxFormDeliveryMethod,
  TaxFormStatus,
  TaxFormType,
  VerificationStatus
} from "@wingspanhq/payments/dist/interfaces";
import { TinType } from "@wingspanhq/users/dist/lib/interfaces";
import { get } from "react-hook-form";
import { WSQueries } from "../../../../../query/WSQuery";
import { useUserId } from "../../../../../query/hooks/helpers";
import { usePayrollSettings } from "../../../../../query/payments/queries";
import { AddressText } from "../../../../../shared/components/AddressText/AddressText";
import { BannerTinStatus } from "../../../../../shared/components/BannerTinStatus";
import { selectorTaxClassificationLabel } from "../../../../../shared/selectors/selectorTaxClassificationLabel";
import { formatEIN } from "../../../../../shared/utils/formatEIN";
import { formatPhone } from "../../../../../shared/utils/formatPhone";
import { Field } from "../../../components/Field/Field";
import { selectorPayerTaxFormDefaultStatus } from "../../../selectors/selectorPayerTaxFormDefaultStatus";
import { selectorPayerTaxFormDefaultTinType } from "../../../selectors/selectorPayerTaxFormDefaultTinType";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";
import { MissingFieldErrorMessages } from "./missingFields";
import { NEEDS_ACTIONS } from "./needsActions";

type Props = {
  taxForm: IPayerTaxFormResponse;
  onEdit?: () => void;
  onRevert?: () => void;
};

export const RecipientDetails: React.FC<Props> = ({
  taxForm,
  onEdit,
  onRevert
}) => {
  const userId = useUserId();
  const queryPayrollSettings = usePayrollSettings(userId);
  const tinStatus = selectorPayerTaxFormDefaultStatus(taxForm);
  const name = selectorTaxFormRecipientName(taxForm);

  const ssnIsUnverified =
    selectorPayerTaxFormDefaultTinType(taxForm) === TinType.Individual &&
    !taxForm.tinVerification?.ssn?.status;
  const einIsUnverified =
    selectorPayerTaxFormDefaultTinType(taxForm) === TinType.Business &&
    !taxForm.tinVerification?.ein?.status;

  return (
    <>
      <WSElement mb="2XL">
        <WSFlexBox.CenterY mb="XL" wrap="nowrap" justify="space-between">
          <WSElement>
            <WSText weight="medium" inline>
              Recipient details
            </WSText>
            {taxForm.recipientSyncEnabled === false ? (
              <WSPill ml="M" text="Edited" />
            ) : null}
          </WSElement>
          {onEdit && <WSButton.Link onClick={onEdit}>Edit</WSButton.Link>}
        </WSFlexBox.CenterY>
        <WSPanel>
          {taxForm.unsyncedUpdateAvailable ? (
            <WSMessageBox.Info mb="2XL" noBorder icon="alert-circle">
              <WSText weight="medium" mb="XS">
                Recipient has made updates that are out of sync
              </WSText>
              Automatic updates are disabled for this 1099 because of manual
              edits. You can always revert edits to recalculate status, amount
              and recipient details.
              <WSButton.Link
                size="S"
                mt="M"
                rightIcon="chevron-right"
                onClick={onRevert}
              >
                View details
              </WSButton.Link>
            </WSMessageBox.Info>
          ) : null}

          {taxForm.status === TaxFormStatus.NeedsAction &&
          taxForm.needsActionReason ? (
            <WSMessageBox.Warning
              mb="2XL"
              title={NEEDS_ACTIONS[taxForm.needsActionReason].title}
              noBorder
              icon="alert-circle"
            >
              {NEEDS_ACTIONS[taxForm.needsActionReason].getMessage(taxForm)}
            </WSMessageBox.Warning>
          ) : null}

          <Field
            mb="XL"
            label="Name"
            value={name}
            errorMessage={
              !taxForm.data.w9Info?.firstName &&
              !taxForm.data.w9Info?.legalBusinessName && (
                <MissingFieldErrorMessages name="name" />
              )
            }
          />
          <Field
            mb="XL"
            label="Federal tax classification"
            value={selectorTaxClassificationLabel(
              taxForm.data.w9Info?.companyStructure
            )}
            errorMessage={
              !taxForm.data.w9Info?.companyStructure && (
                <MissingFieldErrorMessages name="companyStructure" />
              )
            }
          />
          <WSElement mb="XL">
            <Field
              label="Taxpayer identification number"
              errorMessage={
                ssnIsUnverified ? (
                  <MissingFieldErrorMessages name="unverifiedSSN" />
                ) : einIsUnverified ? (
                  <MissingFieldErrorMessages name="unverifiedEIN" />
                ) : (
                  (!tinStatus || tinStatus === VerificationStatus.None) && (
                    <MissingFieldErrorMessages name="tin" />
                  )
                )
              }
              value={
                selectorPayerTaxFormDefaultTinType(taxForm) === TinType.Business
                  ? `${
                      taxForm.data.w9Info?.ein
                        ? formatEIN(taxForm.data.w9Info.ein)
                        : taxForm.data.w9Info?.einLastFour
                        ? `••• ${taxForm.data.w9Info.einLastFour}`
                        : ""
                    } (Employer Identification Number)`
                  : selectorPayerTaxFormDefaultTinType(taxForm) ===
                    TinType.Individual
                  ? `••• ${taxForm.data.w9Info?.ssnLastFour} (Social Security Number)`
                  : "-"
              }
            />
            <BannerTinStatus verificationStatus={tinStatus} mt="M" />
          </WSElement>
          <Field
            label="Address"
            value={
              <WSText as="pre" color="gray500">
                <AddressText placeholder="-" {...taxForm.data.w9Info} />
              </WSText>
            }
            errorMessage={
              !taxForm.data.w9Info?.addressLine1 && (
                <MissingFieldErrorMessages name="address" />
              )
            }
          />
        </WSPanel>
      </WSElement>

      <WSElement mb="2XL">
        <WSText weight="medium" mb="XL">
          Contact information
        </WSText>
        <WSPanel>
          <Field mb="XL" label="Email" value={taxForm.payee?.user.email} />
          <Field
            mb="XL"
            label="Phone number"
            value={
              taxForm.payee?.user.phone?.number
                ? formatPhone(taxForm.payee?.user.phone?.number)
                : "-"
            }
          />
          <Field
            mb="XL"
            label="External ID"
            value={
              get(taxForm, "payerPayee.payerOwnedData.payeeExternalId") || "-"
            }
          />
          <Field
            label="Delivery preference"
            value={
              taxForm.data.deliveryMethod === TaxFormDeliveryMethod.Electronic
                ? "Electronic delivery"
                : "Mail delivery"
            }
          />
        </WSPanel>
      </WSElement>

      <WSElement>
        <WSText weight="medium" mb="XL">
          Form details & calculation settings
        </WSText>

        <WSPanel>
          <WSQueries queries={{ queryPayrollSettings }}>
            {({ queryPayrollSettingsData: payrollSettings }) => (
              <>
                <Field
                  mb="XL"
                  label="Off-platform payments"
                  value={
                    payrollSettings.calculationSettings1099?.offPlatformPayments
                  }
                />
                <Field
                  mb="XL"
                  label="Card processing fees"
                  value={
                    payrollSettings.calculationSettings1099?.cardProcessingFees
                  }
                />
                <Field
                  mb="XL"
                  label="Reimbursable expenses"
                  value={
                    payrollSettings.calculationSettings1099
                      ?.reimbursableExpenses
                  }
                />
                <Field
                  label="Form type"
                  value={
                    taxForm.type === TaxFormType.Form1099Nec ? "1099-NEC" : "-"
                  }
                />
              </>
            )}
          </WSQueries>
        </WSPanel>
      </WSElement>
    </>
  );
};
