import { WSButton, WSText, useWSModal } from "@wingspanhq/fe-component-library";

type Props = {
  onClose: () => void;
};

export const ModalVerificationRequest: React.FC<Props> = ({ onClose }) => (
  <>
    <WSText mb="XL">
      For secure verification, Wingspan will make <b>two micro-deposits</b> into
      your account in the next business day
    </WSText>

    <WSButton fullWidth onClick={onClose} mb="XL">
      Continue
    </WSButton>

    <WSText.ParagraphSm color="gray400">
      By providing your account and routing number and clicking “Continue” you
      allow Wingspan to initiate the micro-deposit
    </WSText.ParagraphSm>
  </>
);

export const useModalVerificationRequest = () =>
  useWSModal(ModalVerificationRequest, {
    title: "Authorize deposit",
    size: "S"
  });
