import { ICustomField } from "@wingspanhq/payments/dist/interfaces";
import * as Yup from "yup";
import { getValidationSchemaCustomFields } from "../../../../shared/components/FormPartialCustomFields";
import { FormDataLineItem } from "./types";

export const getValidationSchemaLineItem = (customFields?: ICustomField[]) =>
  Yup.lazy((lineItem: FormDataLineItem) => {
    if (lineItem.remove) {
      return Yup.object();
    } else {
      return Yup.object().shape({
        description: Yup.string().required("Required"),
        totalCost: Yup.number()
          .typeError("Required")
          .required("Required"),
        rate: Yup.string().oneOf(["fixed", "hourly", "quantity", "other"]),
        costPerUnit: Yup.mixed().when("rate", {
          is: (rate?: string) => rate !== "fixed",
          then: Yup.number()
            .min(0.01, "Can not be 0")
            .typeError("Required")
            .required("Required")
        }),
        quantity: Yup.mixed().when("rate", {
          is: (rate?: string) => {
            return rate !== "fixed";
          },
          then: Yup.number()
            .typeError("Required")
            .required("Required")
        }),
        minutes: Yup.mixed(),
        customUnit: Yup.string().when("rate", {
          is: "other",
          then: schema => schema.required("Required")
        }),
        reimbursableExpense: Yup.boolean(),

        ...(customFields && customFields.length > 0
          ? { customFields: getValidationSchemaCustomFields(customFields) }
          : {})
      });
    }
  });
