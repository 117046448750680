import {
  IPendingWithholding,
  PendingWithholdingStatus
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { IBalanceResponse } from "@wingspanhq/bookkeeping/dist/lib/interfaces/withholdingTransaction";
import { roundMoney } from "../../../utils/math";
import { selectorWithholdingBalance } from "./selectorWithholdingBalance";

export const selectorWithholdingAvailableBalance = (
  balance: IBalanceResponse,
  pendingTransactions: IPendingWithholding[]
) => {
  let accountBalance = selectorWithholdingBalance(balance);

  const sumOfPendingTransactions = pendingTransactions.reduce(
    (sum, transaction) => {
      if (transaction.status === PendingWithholdingStatus.Pending) {
        return sum + transaction.amount;
      }

      return sum;
    },
    0
  );

  return roundMoney(accountBalance - sumOfPendingTransactions);
};
