import { wsSearch, wsSearchSortKind } from "@wingspanhq/fe-component-library";
import { INewUser } from "@wingspanhq/users";
import { getUserName } from "../../../query/users/selectors";
import isString from "lodash/isString";

export const getSearchedOrganizations = (
  searchText: string,
  organizations: INewUser[]
) => {
  if (searchText.trim()) {
    return wsSearch(searchText, organizations, {
      normalizeWhitespace: true,
      ignoreCase: true,
      keySelector: organization =>
        [
          getUserName(organization),
          organization.email,
          organization.profile?.lastName,
          organization.profile?.firstName
        ]
          .filter(isString)
          .map(s => s.trim())
          .filter(Boolean),
      threshold: 0.7,
      sortBy: wsSearchSortKind.bestMatch
    });
  } else {
    return organizations;
  }
};
