import { WSService } from "../../../../utils/WSService";
import { IPayoutListResponse, IPayoutResponse } from "./types";

const service = new WSService("/v2/payments/payouts");

export const getPayouts = async (): Promise<IPayoutListResponse> => {
  const { data } = await service.get("");
  return data;
};

export const getPayout = async (payoutId: string): Promise<IPayoutResponse> => {
  const { data } = await service.get(`/${payoutId}`);
  return data;
};
