import React from "react";
import * as Yup from "yup";

import {
  IPayeeResponse,
  IPayeeUpdateRequest
} from "@wingspanhq/payments/dist/interfaces";
import {
  WSForm,
  WSList,
  WSInputTextarea,
  WSInfoBox,
  useWSSnackbar,
  WSActions
} from "@wingspanhq/fe-component-library";

import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useUpdatePayee } from "../../../../query/payee/mutations/useUpdatePayee";
import { ErrorContextKey } from "../../../../services/platform";

type Props = {
  payee: IPayeeResponse;
  onClose: () => void;
};

export const InternalNotesModal: React.FC<Props> = ({ payee, onClose }) => {
  const { openSnackbar } = useWSSnackbar();
  const [updatePayee, updatePayeeMeta] = useUpdatePayee();
  const onSubmit = async (data: IPayeeUpdateRequest["payerOwnedData"]) => {
    await updatePayee(
      {
        payerOwnedData: {
          internalNotes: data.internalNotes
        },
        payeeId: payee.payeeId
      },
      {
        onSuccess: () => {
          openSnackbar({
            message: "Internal notes updated successfully",
            type: "success"
          });
          onClose();
        }
      }
    );
  };
  return (
    <>
      <WSForm<IPayeeUpdateRequest["payerOwnedData"]>
        defaultValues={{
          internalNotes: payee.payerOwnedData.internalNotes || ""
        }}
        validationSchema={Yup.object().shape({
          internalNotes: Yup.string()
            .required("Required")
            .max(2000, "Max 2000 characters allowed")
        })}
        onSubmit={onSubmit}
      >
        <WSList gap="XL">
          <WSForm.Field
            name="internalNotes"
            label="Notes"
            component={WSInputTextarea}
            componentProps={{
              required: true
            }}
          />

          <WSInfoBox>
            The internal note is visible to you and your team only.
          </WSInfoBox>

          <WSErrorMessage
            contextKey={ErrorContextKey.EditPayerPayeeInternalNotes}
            error={updatePayeeMeta.error}
          />

          <WSActions
            buttons={[
              {
                label: "Cancel",
                onClick: onClose,
                kind: "Tertiary",
                fullWidth: true
              },
              {
                label: "Save changes",
                type: "submit",
                fullWidth: true,
                loading: updatePayeeMeta.isLoading
              }
            ]}
          />
        </WSList>
      </WSForm>
    </>
  );
};
