import { IActivity, INewUser } from "@wingspanhq/users/dist/lib/interfaces";
import { WSStore } from "../../store";
import { GrowthSourceNames } from "../../utils/growthAttribution";

export const selectorEnterpriseId = (
  store: WSStore,
  activity?: IActivity,
  user?: INewUser
) => {
  if (
    store.growthAttributionDetails?.growthSource ===
    GrowthSourceNames.Enterprise
  ) {
    return store.growthAttributionDetails?.growthName;
  }

  if (activity?.context?.growthSource === GrowthSourceNames.Enterprise) {
    return activity.context?.growthSourceName;
  }

  if (!!user?.labels?.growth_name) {
    return user?.labels?.growth_name as string;
  }
};
