import React from "react";
import { WSText } from "@wingspanhq/fe-component-library";

interface OptionLabelProps {
  title: string;
  details: string;
}

export const OptionLabel: React.FC<OptionLabelProps> = ({ title, details }) => {
  return (
    <WSText.ParagraphSm>
      <WSText.ParagraphSm color="gray700" inline style={{ fontWeight: "500" }}>
        {title}
      </WSText.ParagraphSm>
      {" — "}
      <WSText.ParagraphSm color="gray500" inline>
        {details}
      </WSText.ParagraphSm>
    </WSText.ParagraphSm>
  );
};
