import { TinType } from "@wingspanhq/payments/dist/interfaces";
import {
  CompanyStructure,
  IMember
} from "@wingspanhq/users/dist/lib/interfaces";

export const selectorMemberTaxFormName = (member: IMember) => {
  let name;

  if (
    (member.profile.company?.structure ===
      CompanyStructure.SoleProprietorship ||
      member.profile.company?.structure === CompanyStructure.LlcSingleMember) &&
    member.profile.company?.legalBusinessName &&
    member.user.profile.firstName &&
    member.user.profile.lastName
  ) {
    name = `${member.profile.company.legalBusinessName}\n${member.user.profile.firstName} ${member.user.profile.lastName}`;
  } else if (
    member.profile.formW9Options?.tinPreference === TinType.Individual
  ) {
    name = `${member.user.profile.firstName} ${member.user.profile.lastName}`;
  } else if (member.profile.formW9Options?.tinPreference === TinType.Business) {
    name = member.profile.company?.legalBusinessName;
  }

  return name || "";
};
