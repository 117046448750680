import { servicePayments } from "../servicePayments";
import { IPayeeEngagementListResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { ListRequestQuery } from "../../utils/serviceHelper";

export type PayeeEngagementListResponse = {
  data: IPayeeEngagementListResponse;
  summary: {
    listSize: number;
  };
};

export const getPayeeEngagements = async (
  payeeId: string,
  params?: ListRequestQuery
): Promise<PayeeEngagementListResponse> => {
  const { data, headers } = await servicePayments.get(
    `/payee/${payeeId}/engagement`,
    {
      params
    }
  );

  return { summary: { listSize: Number(headers["x-ws-list-size"]) }, data };
};
