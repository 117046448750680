import { IActivityContextType } from "@wingspanhq/users/dist/lib/interfaces";
import { usersService } from "../../../services/users";

export const init1099Activity = async (userId: string) => {
  const prevActivity = await usersService.activity.get(userId);
  const currentStep = prevActivity?.flows?.w9Confirmation?.currentStep || 0;
  if (currentStep >= 1) {
    return;
  }

  await usersService.activity.update(userId, {
    flows: {
      w9Confirmation: {
        currentStep: 1,
        complete: false,
        totalSteps: 2,
        version: 1
      }
    },
    context: {
      type: IActivityContextType.Form1099
    }
  });
};
