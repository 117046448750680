import {
  IWebflowBenefit,
  IWebflowEnterprise,
  IWebflowPartner
} from "@wingspanhq/cms/dist/lib/interfaces";
import { WSService } from "../utils/WSService";

const service = new WSService("/cms");

export const getWebflowPartners = async (): Promise<IWebflowPartner[]> => {
  const { data } = await service.get("/webflow/partners?page[size]=100");
  return data;
};

export const getWebflowPartner = async (
  key: string
): Promise<IWebflowPartner> => {
  const { data } = await service.get(`/webflow/partners/${key}`);
  return data;
};

export const getWebflowBenefits = async (): Promise<IWebflowBenefit[]> => {
  const { data } = await service.get("/webflow/benefits?page[size]=100");
  return data;
};

export const getWebflowBenefit = async (
  key: string
): Promise<IWebflowBenefit> => {
  const { data } = await service.get(`/webflow/benefits/${key}`);
  return data;
};

export const getWebflowEnterprises = async (): Promise<IWebflowEnterprise[]> => {
  const { data } = await service.get("/webflow/enterprises?page[size]=100");
  return data;
};

export const getWebflowEnterprise = async (
  key: string
): Promise<IWebflowEnterprise> => {
  const { data } = await service.get(`/webflow/enterprises/${key}`);
  return data;
};
