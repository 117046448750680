import { WSAlert, WSAlertProps } from "@wingspanhq/fe-component-library";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { pluralize, PluralizeOptions } from "../../utils/pluralize";

const incompleteFieldsPluralizeOptions: PluralizeOptions = {
  one: count => `is ${count} incomplete field`,
  other: count => `are ${count} incomplete fields`
};

interface Props extends WSAlertProps {}

export const WSFormErrorSummary: React.FC<Props> = props => {
  const { errors } = useFormContext();

  const getErrorsCount = useCallback((errObj: any) => {
    // count the number of errors(including deeply nested) in the object and return the count
    let count = 0;
    for (const key in errObj) {
      if (
        typeof errObj[key] === "object" &&
        errObj[key]["message"] === undefined // check if it's not a message object
      ) {
        count += getErrorsCount(errObj[key]);
      } else {
        count++;
      }
    }
    return count;
  }, []);

  const errorsCount = getErrorsCount(errors);

  return errorsCount > 0 ? (
    <WSAlert
      {...props}
      theme="error"
      icon="info-circle"
      title="Incomplete fields"
      data-testid="ws-form-error-summary"
    >
      There {pluralize(errorsCount, incompleteFieldsPluralizeOptions)}. All
      required fields must be complete to continue.
    </WSAlert>
  ) : null;
};
