import { RouteSelectCorrectionType } from "./RouteSelectCorrectionType";
import { useHistory, useRouteMatch } from "react-router-dom";
import { RouteAddCorrectionValues } from "./RouteAddCorrectionValues";
import { RouteReviewAndSubmitCorrection } from "./RouteReviewAndSubmitCorrection";
import { RouteSubmitCorrectionSuccess } from "./RouteSubmitCorrectionSuccess";
import {
  FlowSetup,
  SetupRouteComponentProps
} from "../../../../../shared/components/FlowSetup";

type StartNewCorrectionFlowProps = {
  basePath: string;
  onBack: () => Promise<void> | void;
  onFinish: () => Promise<void> | void;
};

export type SetupStartNewCorrectionFlowProps = SetupRouteComponentProps<{}>;

export const StartNewCorrectionFlow: React.FC<StartNewCorrectionFlowProps> = ({
  basePath,
  onBack,
  onFinish
}) => {
  const history = useHistory();
  const match = useRouteMatch();
  return (
    <>
      <FlowSetup
        basePath={basePath}
        routes={[
          {
            path: "/select",
            component: RouteSelectCorrectionType,
            basePath,
            onBack: () => {
              onBack();
            },
            onNext: corrections => {
              history.push({
                pathname: `${match.url}/add`,
                state: {
                  corrections
                }
              });
            }
          },
          {
            path: "/add",
            component: RouteAddCorrectionValues,
            basePath,
            onBack: () => {
              onBack();
            },
            onNext: context => {
              history.push({
                pathname: `${match.url}/review`,
                state: {
                  ...context
                }
              });
            }
          },
          {
            path: "/review",
            component: RouteReviewAndSubmitCorrection,
            basePath,
            onBack: () => {
              onBack();
            },
            onNext: context => {
              if (context?.isEdit) {
                history.push({
                  pathname: `${match.url}/add`,
                  state: {
                    ...context
                  }
                });
              } else {
                history.push(`${match.url}/success`);
              }
            }
          },
          {
            path: "/success",
            component: RouteSubmitCorrectionSuccess,
            basePath,
            onBack: () => {
              onBack();
            },
            onNext: () => {
              onFinish?.();
            }
          }
        ]}
      />
    </>
  );
};
