import { ListValueType, RowData } from "../../../components/LabelValueList";
import { isNil } from "../../../utils";

interface IFailedBatchItem {
  metadata?: {
    errorMessage?: string;
  };
}

export function getFailedBatchItemsDisplayErrors(
  failedBulkPayableBatchItems: IFailedBatchItem[]
) {
  const groupedErrorMessages: { [key: string]: number } = {};
  failedBulkPayableBatchItems.forEach(failedBulkPayableBatchItem => {
    const errorMessage =
      failedBulkPayableBatchItem.metadata?.errorMessage ?? "";
    if (isNil(groupedErrorMessages[errorMessage])) {
      groupedErrorMessages[errorMessage] = 1;
    } else {
      groupedErrorMessages[errorMessage] += 1;
    }
  });

  const errorItems: RowData[] = [];
  for (const errorMessage in groupedErrorMessages) {
    errorItems.push({
      id: errorMessage,
      type: ListValueType.Number,
      label: errorMessage,
      value: groupedErrorMessages[errorMessage]
    });
  }
  return errorItems;
}
