import React, { useMemo } from "react";
import { OptionItem } from "./OptionItem";
import {
  FilterChangeHandler,
  WSFilterRadio,
  WSFilterSingleValue
} from "./types";

type Props = {
  value?: WSFilterSingleValue;
  onChange: FilterChangeHandler;
} & Omit<WSFilterRadio, "type">;

export const FilterFieldRadio: React.FC<Props> = ({
  name,
  options,
  value: uncastedValue,
  onChange
}) => {
  const value = useMemo(
    () => (uncastedValue ? uncastedValue : options?.[0].name || ""),
    [options, uncastedValue]
  );

  return (
    <>
      {options?.map((option) => {
        return (
          <OptionItem
            key={option.name}
            type="radio"
            value={value === option.name}
            onChange={() => {
              onChange(name, option.name);
            }}
            {...option}
          />
        );
      })}
    </>
  );
};
