import {
  WSFlexBox,
  WSIcon,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import * as React from "react";

export const MODAL_INFO = "MODAL_INFO";

export const ModalInfo: React.FC = () => (
  <WSModal size="S" name={MODAL_INFO}>
    <WSFlexBox mb="M">
      <WSIcon block name="lock" color="gray500" mr="M" />
      <WSText.ParagraphSm weight="medium" color="gray600">
        Wingspan's security guarantee.
      </WSText.ParagraphSm>
    </WSFlexBox>
    <WSText.ParagraphSm color="gray600">
      Wingspan has been audited by a PCI-certified auditor and is a certified
      Level 1 PCI Service Provider. This is the most stringent level of
      certification available in the payments industry.
    </WSText.ParagraphSm>
  </WSModal>
);
