import {
  WSButton,
  WSAvatar,
  WSFlexBox,
  WSProgressBar,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { LayoutW9 } from "../../../components/LayoutW9";
import { useTaxFormVerifyTin } from "../../../../../query/taxForm/mutations/useTaxFormVerifyTin";
import { VerificationStatus } from "@wingspanhq/payments/dist/interfaces";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";

export const RouteVerification: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const [verify, verifyMeta] = useTaxFormVerifyTin();
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    const runVerify = async (index = 0) => {
      const response = await verify();
      if (!response) {
        return;
      }

      if (response?.verificationStatus === VerificationStatus.Verified) {
        setSuccess(true);
        setTimeout(onNext, 3000);
      } else if (response?.verificationStatus === VerificationStatus.Failed) {
        onBack?.({ status: VerificationStatus.Failed });
      } else if (
        response?.verificationStatus === VerificationStatus.Pending &&
        index <= 3
      ) {
        setTimeout(() => runVerify(++index), 3000);
      } else {
        setTimeout(onNext, 3000);
      }
    };

    runVerify(0);
  }, [verify]);

  return (
    <LayoutW9 noTitle title="Verification" onBack={onBack}>
      {verifyMeta.error ? (
        <WSErrorMessage error={verifyMeta.error} contextKey="Other" />
      ) : success ? (
        <VerificationComplete onContinue={onNext} />
      ) : (
        <VerificationInProgress onNext={onNext} />
      )}
    </LayoutW9>
  );
};

const VerificationInProgress: React.FC<{ onNext: () => void }> = ({
  onNext
}) => {
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    const interval = setInterval(() => {
      setProgress(p => {
        if (p >= 80) {
          clearInterval(interval);
        }

        return p + 10;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <WSText.Heading4 mb="XL">
        Verifying your Taxpayer Identification Number
      </WSText.Heading4>
      <WSProgressBar
        noAnimation={false}
        percent={progress}
        text="We are confirming your TIN with the IRS. This can take up to 10 seconds."
      />
      {progress > 80 ? (
        <>
          <WSText mb="M" mt="XL" color="gray500">
            You’ll be directed to the next step shortly. If it takes too long,
            click continue below.
          </WSText>
          <WSButton mb="XL" fullWidth onClick={onNext}>
            Continue
          </WSButton>
        </>
      ) : null}
    </>
  );
};

type VerificationCompleteProps = { onContinue: () => void };

const VerificationComplete: React.FC<VerificationCompleteProps> = ({
  onContinue
}) => (
  <>
    <WSFlexBox.CenterX mb="M">
      <WSAvatar.Icon icon="check" color="white" colorBackground="green500" />
    </WSFlexBox.CenterX>
    <WSText.Heading4 mb="XL" align="center">
      Your Taxpayer Identification Number has been verified
    </WSText.Heading4>
    <WSText mb="M" color="gray500">
      You’ll be directed to the next step shortly. If it takes too long, click
      continue below.
    </WSText>
    <WSButton mb="XL" fullWidth onClick={onContinue}>
      Continue
    </WSButton>
  </>
);
