import {
  WSButton,
  WSFlexBox,
  WSPanel,
  WSPill
} from "@wingspanhq/fe-component-library";

import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces";
import React, { ReactNode } from "react";
import { selectorTaxFormCorrectedValueStatusLabel } from "../../../1099NECFiling/selectors/selectorTaxFormCorrectedValueStatusLabel";
import { TaxFormViewer } from "../../types";
import styles from "../styles.module.scss";

interface CorrectedValuePreviewProps {
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  taxFormViewer?: TaxFormViewer;
  children: ReactNode;
  onEdit?: () => void;
}

export const CorrectedValuePreview: React.FC<CorrectedValuePreviewProps> = ({
  taxForm,
  taxFormViewer = TaxFormViewer.Payee,
  children,
  onEdit
}) => {
  const onClickEdit = () => {
    onEdit?.();
  };

  const correctionValueStatus = selectorTaxFormCorrectedValueStatusLabel(
    taxForm,
    taxFormViewer
  );

  const editable = typeof onEdit === "function";
  return (
    <WSPanel className={styles.requestedValue} noBorder>
      <WSFlexBox justify="space-between" wrap="nowrap">
        {children}
        <WSFlexBox.Center wrap="nowrap">
          {editable ? (
            <WSButton.Link onClick={onClickEdit} mr="M">
              Edit
            </WSButton.Link>
          ) : null}
          <WSPill theme="warning" text={correctionValueStatus} />
        </WSFlexBox.Center>
      </WSFlexBox>
    </WSPanel>
  );
};
