import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  deleteSessionToken,
  pushSessionToken
} from "../../services/sessionStorage";
import { usersService } from "../../services/users";
import { useWSAnalytics } from "../../utils/WSAnalytics";

export const useResetPassword = (
  userId: string,
  resetToken: string,
  signInPath: string
) => {
  const { track } = useWSAnalytics();
  const snackbar = useWSSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const history = useHistory();

  const reset = useCallback(
    async (password: string) => {
      setIsLoading(true);

      try {
        track.other("Reset password with code");
        pushSessionToken(resetToken);
        await usersService.user.update(userId, {
          password
        });
        deleteSessionToken();

        history.replace(signInPath);
        snackbar.openSnackbar({
          type: "success",
          message:
            "Your password has been successfully updated. Please, sign in to continue."
        });
      } catch (error) {
        setError(error as any);
      } finally {
        setIsLoading(false);
      }
    },
    [history, resetToken, signInPath, snackbar, track, userId]
  );

  return { reset, isLoading, error };
};
