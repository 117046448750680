import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSPanel,
  WSPill,
  WSSidebar,
  WSSidebarProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { formatMoney } from "accounting";
import React from "react";
import { Totals } from "../../../Invoices/components/Totals/Totals";
import { calculateLineItemsTotal } from "../../../Invoices/utils";
import { getLineItemsTotals } from "../../../Invoices/utils/getLineItemsTotals";
import { downloadFile } from "../../../utils/files";
import { ClientInvoiceWrapper } from "../ClientInvoiceWrapper/ClientInvoiceWrapper";
import { DonwloadPdfButton } from "../DownloadPdfButton/DonwloadPdfButton";
import { openIntercom } from "../../../shared/utils/intercom";

type Props = { invoiceId: string; processingFee?: number } & WSSidebarProps;

export const ClientInvoiceDetailsDrawer: React.FC<Props> = ({
  invoiceId,
  processingFee,
  ...elementProps
}) => (
  <WSSidebar.Container size="L" {...elementProps}>
    <ClientInvoiceWrapper invoiceId={invoiceId}>
      {clientInvoice => {
        const subtotal = calculateLineItemsTotal(clientInvoice.lineItems);
        const lateFee = clientInvoice.chargedFees?.lateFee?.amount || 0;

        const fees = lateFee + (processingFee || 0);

        return (
          <WSSidebar.Layout header={`Invoice #${clientInvoice.invoiceNumber}`}>
            <WSPanel noBorder>
              <WSFlexBox
                direction="column"
                justify="space-between"
                alignItems="stretch"
              >
                <WSElement>
                  {clientInvoice.metadata?.purchaseOrderNumber ? (
                    <WSText.ParagraphSm color="gray500" mb="M" inline>
                      PO Number: {clientInvoice.metadata?.purchaseOrderNumber}
                    </WSText.ParagraphSm>
                  ) : null}
                  <WSFlexBox.CenterY
                    wrap="nowrap"
                    justify="space-between"
                    mb="5XL"
                  >
                    <WSFlexBox.CenterY>
                      <WSText.Display2 mr="M">
                        {formatMoney(clientInvoice.amount)}
                      </WSText.Display2>
                      <WSPill text="Unpaid" />
                    </WSFlexBox.CenterY>
                    {clientInvoice.attachments?.invoicePdf && (
                      <DonwloadPdfButton
                        icon="download"
                        clientInvoice={clientInvoice}
                      />
                    )}
                  </WSFlexBox.CenterY>

                  {clientInvoice.projectName && (
                    <WSText.Heading1 mb="XL">
                      {clientInvoice.projectName}
                    </WSText.Heading1>
                  )}

                  <Totals
                    sections={[
                      getLineItemsTotals(clientInvoice.lineItems),
                      {
                        items: [
                          ["Subtotal", formatMoney(subtotal)],
                          ["Fees", formatMoney(fees)]
                        ]
                      },
                      {
                        items: [["Total", formatMoney(subtotal + fees)]]
                      }
                    ]}
                  />

                  {(clientInvoice.attachments?.customAttachmentInfo || []).map(
                    attachment => (
                      <WSButton.Link
                        mb="M"
                        icon="download"
                        onClick={async () => {
                          downloadFile(attachment.fileUrl, attachment.fileName);
                        }}
                      >
                        {attachment.fileName}
                      </WSButton.Link>
                    )
                  )}
                </WSElement>
                <WSElement mt="4XL">
                  <WSText mb="S">
                    Pay with a bank transfer for free, or use your credit card
                    to defer payment and earn rewards (2.9% fee).
                  </WSText>
                  <WSText mb="S">
                    {clientInvoice.memberCompany} receives a bank deposit within
                    days.
                  </WSText>
                  <WSText>
                    Questions? Contact{" "}
                    <WSButton.Link onClick={openIntercom}>
                      Wingspan Support
                    </WSButton.Link>
                    .
                  </WSText>
                </WSElement>
              </WSFlexBox>
            </WSPanel>
          </WSSidebar.Layout>
        );
      }}
    </ClientInvoiceWrapper>
  </WSSidebar.Container>
);
