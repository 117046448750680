import { WSPillProps, WSTableCell } from "@wingspanhq/fe-component-library";
import React from "react";

import {
  IPayerTaxFormResponse,
  VerificationStatus
} from "@wingspanhq/payments/dist/interfaces";
import { selectorPayerTaxFormDefaultLastFourTaxId } from "../selectors/selectorPayerTaxFormDefaultLastFourTaxId";
import { selectorPayerTaxFormDefaultStatus } from "../selectors/selectorPayerTaxFormDefaultStatus";

interface PillProps {
  props: Omit<WSPillProps, "text">;
  textSuffix: string;
}

function getBadgeProps(taxStatus?: VerificationStatus): PillProps {
  switch (taxStatus) {
    case VerificationStatus.Verified:
      return {
        props: {
          theme: "success",
          icon: "check"
        },
        textSuffix: "Match"
      };
    case VerificationStatus.Failed:
      return {
        props: {
          theme: "warning"
        },
        textSuffix: "Mismatch"
      };
    default:
      return {
        props: {
          icon: "time"
        },
        textSuffix: "Pending"
      };
  }
}

export interface TaxIDProps {
  taxForm: IPayerTaxFormResponse;
}

export const TaxID: React.FC<TaxIDProps> = ({ taxForm }) => {
  const taxIdLast4 = selectorPayerTaxFormDefaultLastFourTaxId(taxForm);
  const taxStatus = selectorPayerTaxFormDefaultStatus(taxForm);

  const { props, textSuffix } = getBadgeProps(taxStatus);

  if (!taxIdLast4) {
    return null;
  }

  return (
    <WSTableCell
      pill={{
        ...(props as any),
        text: ["•••", taxIdLast4, textSuffix].filter(Boolean).join(" ")
      }}
      text={taxForm.isGrouped ? "Grouped" : undefined}
    />
  );
};
