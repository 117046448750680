import { WSAvatar, WSAvatarProps } from "@wingspanhq/fe-component-library";
import React, { useEffect, useState } from "react";
import { IS_PRODUCTION_ENV } from "../../shared/constants/environment";

export type IInstitutionLogo = {
  institutionId?: string;
};

export const InstitutionLogoCircle: React.FC<IInstitutionLogo> = ({
  institutionId,
  ...elementProps
}) => {
  const bucketName = IS_PRODUCTION_ENV
    ? "wingspan-shared-content"
    : "wingspan-staging-shared-content";

  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setImage("");
    const img = new Image();
    const url = `https://storage.cloud.google.com/${bucketName}/plaid/logos/${institutionId}.png`;
    img.onload = () => {
      setImage(url);
      setLoading(false);
    };
    img.onerror = () => {
      setImage("");
      setLoading(false);
    };
    img.src = url;

    return () => {
      img.onerror = null;
      img.onabort = null;
    };
  }, [institutionId, bucketName]);

  if (loading) {
    return <WSAvatar.Text text="" {...elementProps} />;
  }

  if (!institutionId || !image) {
    return <WSAvatar.Icon icon="bank" {...elementProps} />;
  }

  return <WSAvatar.Image image={image} {...elementProps} />;
};
