import {
  IPaymentCard,
  IPaymentCardOneTimeCreateRequest
} from "@wingspanhq/payments/dist/interfaces";
import { paymentsService } from "../../../services/payments";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation } from "../../helpers";
import { QUERY_PAYMENT_CARDS } from "../keys";

export const useMutationCompleteOneTimePaymentCardCreate = () =>
  useWSMutation<
    IPaymentCard,
    WSServiceError,
    { paymentCardId: string } & IPaymentCardOneTimeCreateRequest
  >(
    ({ paymentCardId, ...data }) => {
      return paymentsService.paymentCard.oneTime.completeCreate(
        paymentCardId,
        data
      );
    },
    { dependencies: [QUERY_PAYMENT_CARDS] }
  );
