import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";

import isNumber from "lodash/isNumber";

export const selector1099NECTotal = (
  taxForm: IPayerTaxFormResponse
): number | undefined => {
  const { correction, data } = taxForm;

  if (isNumber(data?.totalAmount)) return data.totalAmount;

  if (isNumber(correction?.totalAmount)) return correction?.totalAmount;

  const platformIncome = data?.platformIncome;
  const adjustments = data?.adjustments;
  const paymentProcessingFees = data?.paymentProcessingFees;

  if (
    isNumber(platformIncome) &&
    isNumber(adjustments) &&
    isNumber(paymentProcessingFees)
  ) {
    return platformIncome + adjustments + paymentProcessingFees;
  }

  return undefined;
};
