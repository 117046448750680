import MaterialPopper, {
  PopperProps as MaterialPopperProps
} from "@material-ui/core/Popper";
import classNames from "classnames";
import React from "react";
import classes from "./styles.module.scss";

export interface PopperProps extends MaterialPopperProps {
  zIndex?: number;
}

export const Popper: React.FC<PopperProps> = ({ zIndex, ...props }) => (
  <MaterialPopper
    placement="bottom"
    {...props}
    className={classNames(classes.popper, props.className)}
    style={{ zIndex }}
  />
);
