import {
  WSDivider,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSPill,
  WSText,
  useWSModal
} from "@wingspanhq/fe-component-library";
import { IPaymentCard } from "@wingspanhq/payments/dist/interfaces";
import capitalize from "lodash/capitalize";
import { useMemo } from "react";
import { BankCardLogo } from "../../shared/components/BankCardLogo";
import { ModalEditPaymentCard } from "./ModalEditPaymentCard";

type Props = {
  paymentCards: IPaymentCard[];
  defaultPaymentCardId?: string;
  onEdit?: (paymentCard: IPaymentCard) => void;
};

export const PaymentCards: React.FC<Props> = ({
  paymentCards,
  defaultPaymentCardId,
  onEdit
}) => {
  // group payments cards by brands
  const paymentCardsByBrand = useMemo(
    () =>
      paymentCards.reduce<{
        [brand: string]: { name: string; cards: IPaymentCard[] };
      }>((acc, paymentCard) => {
        if (paymentCard.brand) {
          if (acc[paymentCard.brand]) {
            acc[paymentCard.brand].cards.push(paymentCard);
          } else {
            acc[paymentCard.brand] = {
              name: paymentCard.brand,
              cards: [paymentCard]
            };
          }
        }
        return acc;
      }, {}),
    [paymentCards]
  );

  const paymentCardsByBrandArray = useMemo(
    () => Object.values(paymentCardsByBrand),
    [paymentCardsByBrand]
  );

  const modalEdit = useWSModal(ModalEditPaymentCard, {
    size: "S"
  });

  return (
    <>
      {paymentCardsByBrandArray.map(brand => (
        <WSElement key={brand.name}>
          <WSFlexBox.CenterY mb="M">
            <BankCardLogo.Circle
              type="credit"
              brand={brand.name.toLowerCase()}
            />
            <WSText weight="medium" singleLine ml="M">
              {capitalize(brand.name)}
            </WSText>
          </WSFlexBox.CenterY>

          <WSDivider />

          {brand.cards.map(paymentCard => {
            const isDefault =
              paymentCard.paymentCardId === defaultPaymentCardId;

            return (
              <WSElement key={paymentCard.paymentCardId}>
                <WSFlexBox wrap="nowrap" justify="space-between" p="M" pl="3XL">
                  <WSFlexBox wrap="nowrap">
                    <WSText.ParagraphSm mr="M">
                      {paymentCard.cardType} ({paymentCard.mask})
                    </WSText.ParagraphSm>

                    <WSText.ParagraphSm color="gray500">
                      Exp {paymentCard.expirationMonth}/
                      {paymentCard.expirationYear}
                    </WSText.ParagraphSm>
                  </WSFlexBox>

                  <WSFlexBox.CenterY wrap="nowrap">
                    {isDefault && <WSPill mx="M" text="Default" />}

                    {onEdit && (
                      <WSIcon
                        size="S"
                        color="blue400"
                        name="edit"
                        onClick={() =>
                          modalEdit.open({
                            paymentCard,
                            isDefault
                          })
                        }
                      />
                    )}
                  </WSFlexBox.CenterY>
                </WSFlexBox>
                <WSDivider />
              </WSElement>
            );
          })}
        </WSElement>
      ))}
    </>
  );
};
