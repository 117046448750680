import * as Yup from "yup";
import { validatorRoutingNumber } from "../../validators/validatorRoutingNumber";

export const validationSchema = Yup.object().shape({
  accountRoutingNumber: validatorRoutingNumber,
  // accountName: Yup.string().required("Required"),
  accountNumber: Yup.string().required("Required"),
  accountNumberConfirmation: Yup.string()
    .oneOf([Yup.ref("accountNumber"), null], "Numbers must match")
    .required("Required"),
  accountHolderName: Yup.string().required("Required")
});
