import {
  VG_CLIENT_PARENT_IDS,
  VG_CONTRACTOR_PARENT_IDS,
  VG_ORG_IDS,
  VG_USERS_PARENT_IDS
} from "./vg";

export const HIDE_SEND_PAYMENTS_NAV_IDS = [...VG_CONTRACTOR_PARENT_IDS];
export const HIDE_NEW_COLLABORATOR_PAYMENT_NAV_IDS = [
  ...VG_CONTRACTOR_PARENT_IDS
];
export const HIDE_BATCH_IMPORTS_NAV_IDS = [...VG_CONTRACTOR_PARENT_IDS];
export const HIDE_CUSTOM_FIELDS_NAV_IDS = [...VG_CONTRACTOR_PARENT_IDS];
export const HIDE_PAYABLES_AND_PAYROLL_NAV_IDS = [...VG_CONTRACTOR_PARENT_IDS];
export const SHOW_MULTI_CURRENCY_INVOICES_BANNER_IDS = [
  ...VG_CONTRACTOR_PARENT_IDS
];
export const SHOW_PAYOUT_NAV_IDS = [...VG_CONTRACTOR_PARENT_IDS];

export const HIDE_GET_PAID_NAV_IDS = [
  ...VG_CLIENT_PARENT_IDS,
  ...VG_USERS_PARENT_IDS
];
export const HIDE_ONE_TIME_INVOICE_NAV_IDS = [
  ...VG_CLIENT_PARENT_IDS,
  ...VG_USERS_PARENT_IDS
];
export const HIDE_RECURRING_INVOICES_NAV_IDS = [
  ...VG_CLIENT_PARENT_IDS,
  ...VG_USERS_PARENT_IDS
];
export const PAYMENTS_SETTINGS_MANAGED_BY_ORGANIZATION = [
  ...VG_CLIENT_PARENT_IDS,
  ...VG_USERS_PARENT_IDS
];
export const SHOW_PAYMENT_NAV_IDS = [
  ...VG_CLIENT_PARENT_IDS,
  ...VG_USERS_PARENT_IDS
];

export const HIDE_MEMBERSHIP_SETTINGS = [
  ...VG_CLIENT_PARENT_IDS,
  ...VG_USERS_PARENT_IDS,
  ...VG_CONTRACTOR_PARENT_IDS
];

export const TEAMS_MANAGED_BY_ORGANIZATION = [...VG_CLIENT_PARENT_IDS];

export const PAYROLL_FUNDING_METHOD_MANAGED_BY_ORGANIZATION = [...VG_ORG_IDS];

export const ORGANIZATION_NAMES = [
  {
    name: "Velocity Global",
    ids: VG_ORG_IDS
  }
];

export const E_SIGNATURE_AGREEMENT_TEMPLATES_INFO: Array<{
  ids: string[];
  description: string;
  actionTitle: string;
  actionLink: string;
}> = [
  {
    ids: VG_ORG_IDS,
    description:
      "Velocity Global enables you to choose from 50 country-specific contractor agreements.",
    actionTitle: "View contractor agreements",
    actionLink:
      "https://drive.google.com/drive/folders/1NKvfqLDFDhi_e-IGjtWm2gQXsFLYvxdK"
  }
];
