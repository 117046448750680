import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import {
  WSButton,
  WSElement,
  WSElementColorsType,
  WSFlexBox,
  WSIcon,
  WSText,
  useModalContext
} from "@wingspanhq/fe-component-library";
import { BulkBatchDetailsLayout } from "../../components/BulkBatchDetailsLayout/BulkBatchDetailsLayout";
import LabelValueList from "../../../../components/LabelValueList";
import {
  useAllBulkPayableBatchItems,
  useBulkPayableBatch,
  useBulkPayableBatchSummary
} from "../../query/bulkPayable/queries";
import {
  CustomFieldResourceType,
  BulkStatus
} from "@wingspanhq/payments/dist/interfaces";
import { InfoModals } from "./InfoModals";
import { isFalsy } from "../../../../utils";
import { isTruthy } from "../../../../utils";
import { CSVLink } from "react-csv";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { getBatchDetailsLabelValueColors } from "../../utils/getBatchDetailsLabelValueColors";
import { preparePayablesCSVFile } from "./preparePayablesCSVFile";
import { prepareLineItemsFromBulkBatchItem } from "./prepareLineItemsFromBulkBatchItem";
import { getPayableBatchDetailsItems } from "./getPayableBatchDetailsItems";
import {
  ISummaryStatCategory,
  getPayableBatchSummaryItems
} from "./getPayableBatchSummaryItems";
import { BulkResource } from "../../types";
import { BulkBatchDetailsHeader } from "../../components/BulkBatchDetailsHeader/BulkBatchDetailsHeader";
import { BulkBatchProcessingErrors } from "../../components/BulkBatchProcessingErrors";
import { DeleteBulkBatch } from "../../components/DeleteBulkBatch";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";

export function BulkImportPayableBatchDetails() {
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const history = useHistory();
  const { openModal } = useModalContext();
  const { bulkBatchId } = match.params;
  const [isBatchProcessed, setIsBatchProcessed] = useState(false);

  const qBulkPayableBatch = useBulkPayableBatch(bulkBatchId);
  const qBulkPayableBatchSummary = useBulkPayableBatchSummary(bulkBatchId);
  const queryAllFailedBulkPayableBatchItems = useAllBulkPayableBatchItems(
    bulkBatchId,
    {
      filter: {
        status: BulkStatus.Failed
      }
    },
    {
      enabled:
        (qBulkPayableBatch.data?.statistics?.itemsFailed || 0) > 0 ||
        qBulkPayableBatch.data?.status === BulkStatus.Failed
    }
  );
  const qLineItemCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.LineItem]
  });

  const lineItemCustomFields = qLineItemCustomFields.data || [];

  const isEmptyBatch =
    qBulkPayableBatchSummary.data &&
    qBulkPayableBatchSummary.data.listSize === 0;

  const labelValueColors: {
    labelColor: WSElementColorsType;
    valueColor: WSElementColorsType;
  } = useMemo(
    () => getBatchDetailsLabelValueColors(qBulkPayableBatch.data?.status),
    [qBulkPayableBatch.data?.status]
  );

  useEffect(() => {
    if (
      !isBatchProcessed &&
      qBulkPayableBatch.data?.status &&
      [BulkStatus.Complete, BulkStatus.Failed].includes(
        qBulkPayableBatch.data?.status
      )
    ) {
      setIsBatchProcessed(true);
      qBulkPayableBatchSummary.refetch();
    }
  }, [
    qBulkPayableBatch.data?.status,
    qBulkPayableBatchSummary,
    isBatchProcessed,
    setIsBatchProcessed
  ]);

  const openLabelInfoModal = useCallback(
    (modalName: string) => {
      openModal(modalName);
    },
    [openModal]
  );

  const payableBatchDetailsItems = useMemo(
    () =>
      getPayableBatchDetailsItems(
        qBulkPayableBatch.data,
        qBulkPayableBatchSummary.data,
        {
          onClickInfo: openLabelInfoModal,
          ...labelValueColors
        }
      ),
    [
      qBulkPayableBatch.data,
      labelValueColors,
      qBulkPayableBatchSummary.data,
      openLabelInfoModal
    ]
  );

  const payableBatchSummaryItems = useMemo(
    () =>
      getPayableBatchSummaryItems(qBulkPayableBatchSummary.data, {
        onClickInfo: openLabelInfoModal,
        ...labelValueColors
      }),
    [qBulkPayableBatchSummary.data, openLabelInfoModal, labelValueColors]
  );

  return (
    <BulkBatchDetailsLayout
      title="Import details"
      onBack={() => {
        history.push("/member/imports/payables");
      }}
    >
      <WSQueries queries={{ qBulkPayableBatch, qBulkPayableBatchSummary }}>
        {({ qBulkPayableBatch: { data: bulkPayableBatch } }) => {
          const isBatchUploadFinished = [
            BulkStatus.Complete,
            BulkStatus.Failed
          ].includes(bulkPayableBatch.status);
          const failedCSVLineItems = (
            queryAllFailedBulkPayableBatchItems.data ?? []
          ).map(failedBatchItem =>
            prepareLineItemsFromBulkBatchItem(
              failedBatchItem,
              lineItemCustomFields,
              { includeErrors: true }
            )
          );
          const errorsCSVFileProps = preparePayablesCSVFile(
            `Errors_${getUploadedFilename(
              bulkPayableBatch,
              BulkResource.Payable
            )}`,
            failedCSVLineItems,
            lineItemCustomFields,
            { includeErrors: true }
          );
          return (
            <>
              <InfoModals />
              {/* header section */}
              <BulkBatchDetailsLayout.Card mb="2XL">
                <BulkBatchDetailsHeader bulkResource={BulkResource.Payable} />
              </BulkBatchDetailsLayout.Card>

              {/* errors  */}
              {failedCSVLineItems.length > 0 ? (
                <BulkBatchDetailsLayout.Card mb="2XL">
                  <WSFlexBox.CenterY mb="XL" justify="space-between">
                    <WSFlexBox.CenterY>
                      <WSText
                        weight="medium"
                        color={labelValueColors.labelColor}
                      >
                        Errors
                      </WSText>
                      <WSIcon
                        ml="XS"
                        name="alert-circle"
                        color="red500"
                        size="XS"
                      />
                    </WSFlexBox.CenterY>
                    <CSVLink {...errorsCSVFileProps}>
                      <WSButton.Link icon="download" size="S">
                        {`Errors_${getUploadedFilename(
                          bulkPayableBatch,
                          BulkResource.Payable,
                          {
                            length: 30
                          }
                        )}`}
                      </WSButton.Link>
                    </CSVLink>
                  </WSFlexBox.CenterY>
                  <BulkBatchProcessingErrors
                    bulkResource={BulkResource.Payable}
                  />
                </BulkBatchDetailsLayout.Card>
              ) : null}

              {/* payables summary stats section */}
              {isFalsy(isEmptyBatch) ? (
                <BulkBatchDetailsLayout.Card mb="2XL">
                  <WSFlexBox.CenterY
                    mb="XL"
                    justify={
                      isTruthy(isBatchUploadFinished)
                        ? "space-between"
                        : "flex-start"
                    }
                  >
                    <WSText weight="medium" color={labelValueColors.labelColor}>
                      Payables
                    </WSText>
                    {isTruthy(isBatchUploadFinished) ? (
                      <WSButton.Link
                        name="payablesViewAll"
                        rightIcon="arrow-right"
                        size="S"
                        onClick={() => {
                          history.push(
                            `/member/invoices/payables/all?bulkBatchId=${bulkPayableBatch.bulkPayableBatchId}`
                          );
                        }}
                      >
                        See all
                      </WSButton.Link>
                    ) : null}
                    {isFalsy(isBatchUploadFinished) && isFalsy(isEmptyBatch) ? (
                      <WSIcon
                        block
                        ml="XS"
                        name="time"
                        color={labelValueColors.labelColor}
                      />
                    ) : null}
                  </WSFlexBox.CenterY>
                  <LabelValueList
                    rowSpace="S"
                    items={payableBatchSummaryItems.filter(
                      item => item.category === ISummaryStatCategory.Payables
                    )}
                  />
                  <WSFlexBox.CenterY
                    mt="2XL"
                    mb="M"
                    justify={
                      isTruthy(isBatchUploadFinished)
                        ? "space-between"
                        : "flex-start"
                    }
                  >
                    <WSText weight="medium" color={labelValueColors.labelColor}>
                      Deductions
                    </WSText>
                    {isTruthy(isBatchUploadFinished) ? (
                      <WSButton.Link
                        name="deductionsViewAll"
                        rightIcon="arrow-right"
                        size="S"
                        onClick={() => {
                          history.push(
                            `/member/invoices/payables/deductions?bulkBatchId=${bulkPayableBatch.bulkPayableBatchId}`
                          );
                        }}
                      >
                        See all
                      </WSButton.Link>
                    ) : null}
                    {isFalsy(isBatchUploadFinished) && isFalsy(isEmptyBatch) ? (
                      <WSIcon
                        block
                        ml="XS"
                        name="time"
                        color={labelValueColors.labelColor}
                      />
                    ) : null}
                  </WSFlexBox.CenterY>
                  <LabelValueList
                    rowSpace="S"
                    items={payableBatchSummaryItems.filter(
                      item => item.category === ISummaryStatCategory.Deductions
                    )}
                  />
                  <WSElement mb="2XL" />
                  <LabelValueList
                    rowSpace="S"
                    items={payableBatchSummaryItems.filter(
                      item => item.category === ISummaryStatCategory.Total
                    )}
                  />
                </BulkBatchDetailsLayout.Card>
              ) : null}

              {/* details section */}
              <BulkBatchDetailsLayout.Card mb="2XL">
                <WSText weight="medium" mb="XL">
                  Details
                </WSText>
                <LabelValueList rowSpace="S" items={payableBatchDetailsItems} />
              </BulkBatchDetailsLayout.Card>

              {/* footer section */}
              {bulkPayableBatch.status === BulkStatus.Open ? (
                <BulkBatchDetailsLayout.Card mb="2XL">
                  <DeleteBulkBatch
                    bulkBatchId={bulkPayableBatch.bulkPayableBatchId}
                    isEmptyBatch={isEmptyBatch}
                    bulkResource={BulkResource.Payable}
                  />
                </BulkBatchDetailsLayout.Card>
              ) : null}
            </>
          );
        }}
      </WSQueries>
    </BulkBatchDetailsLayout>
  );
}
