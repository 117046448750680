import {
  IPayeeCreateRequest,
  IPayeeResponse
} from "@wingspanhq/payments/dist/interfaces";

import { servicePayments } from "../servicePayments";

export const createPayee = async (request: IPayeeCreateRequest) => {
  const { data } = await servicePayments.post(`/payee`, request);

  return data as IPayeeResponse;
};
