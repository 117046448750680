import {
  AutoPayRequirement,
  IClientInvoice,
  InvoiceStatus,
  PaymentMethod
} from "@wingspanhq/payments/dist/interfaces";

export const isClientInvoicePaid = (clientInvoice: IClientInvoice) =>
  clientInvoice.status === InvoiceStatus.Paid ||
  clientInvoice.status === InvoiceStatus.PaymentInTransit;

export const isClientInvoiceCancelled = (clientInvoice: IClientInvoice) =>
  clientInvoice.status === InvoiceStatus.Cancelled;

export const getInvoiceIdFromCPEPath = (path: string) => {
  try {
    if (path.startsWith("/payment")) {
      const invoiceid = path.split("/")?.[2];

      if (invoiceid && invoiceid !== "ppl") {
        return invoiceid;
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAcceptedPaymentMethods = (clientInvoice: IClientInvoice) =>
  clientInvoice.acceptedPaymentMethods || [];

export const getPathToPaymentMethod = (
  invoiceId: string,
  paymentMethod: PaymentMethod
) =>
  `/payment/${invoiceId}/3/${
    paymentMethod === PaymentMethod.ACH
      ? "ach"
      : paymentMethod === PaymentMethod.Credit
      ? "creditCard"
      : "manual"
  }`;

export const getPaymentStepText = (
  clientInvoice: IClientInvoice,
  step: "details" | "paymentType" | "payment"
) => {
  const paymentMethods = getAcceptedPaymentMethods(clientInvoice);
  const isMultiplePaymentMethods = paymentMethods.length > 1;
  const totalSteps = isMultiplePaymentMethods ? 3 : 2;

  const currentStep =
    step === "details"
      ? 1
      : step === "paymentType"
      ? 2
      : step === "payment" && isMultiplePaymentMethods
      ? 3
      : 2;

  return `${currentStep} of ${totalSteps}`;
};

export const getIsAutopayRequired = (clientInvoice: IClientInvoice) => {
  return (
    !!clientInvoice.invoiceTemplateId &&
    clientInvoice.memberClient.memberData.autoPayRequirement ===
      AutoPayRequirement.All
  );
};
