import {
  WSElement,
  WSElementProps,
  WSFormOld,
  WSRadioInputGroup,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  EmailVerificationStatus,
  OtpVerificationChannel,
  PhoneVerificationStatus
} from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { useLocation } from "react-router-dom";
import { WSQueries } from "../../query/WSQuery";
import { useWSMutation } from "../../query/helpers";
import { useUserId } from "../../query/hooks/helpers";
import { useUserProfile } from "../../query/users/queries";
import { ErrorContextKey } from "../../services/platform";
import { usersService } from "../../services/users";
import { WSServiceError } from "../../utils/serviceHelper";
import { censorEmail, censorPhone } from "../../utils/stringHelper";
import { WSErrorMessage } from "../WSErrorMessage/WSErrorMessage";

type FormData = { channel: OtpVerificationChannel };

type Props = {
  callbackUrl?: string;
  onSuccess: (data: FormData) => void;
} & WSElementProps;

export const OtpRequestForm: React.FC<Props> = ({
  onSuccess,
  callbackUrl,
  ...elementProps
}) => {
  const location = useLocation();
  const userId = useUserId();
  const userQuery = useUserProfile(userId);
  const [submit, submitMeta] = useWSMutation<
    FormData,
    WSServiceError,
    FormData
  >(
    async data => {
      await usersService.session.beginOtpSession({
        channel: data.channel,
        appRedirectPath: callbackUrl || `${location.pathname}${location.search}`
      });

      return data;
    },
    {
      onSuccess
    }
  );

  return (
    <WSElement {...elementProps}>
      <WSQueries queries={{ userQuery }}>
        {({ userQuery: { data: user } }) => {
          const channelOptions = [];
          if (
            user.emailVerificationState?.status ===
            EmailVerificationStatus.Verified
          ) {
            channelOptions.push({
              value: OtpVerificationChannel.Email,
              label: `Email (${censorEmail(user.email)})`
            });
          }

          if (
            !!user.phone.number &&
            user.phone.status === PhoneVerificationStatus.Verified
          ) {
            channelOptions.push({
              value: OtpVerificationChannel.SMS,
              label: `Phone (${censorPhone(user.phone.number as string)})`
            });
          }

          if (channelOptions.length === 0) {
            return (
              <WSText color="gray600">
                Two-factor authentication is required for your account, but no
                phone number has been set up. To continue, please add a phone
                number in your account settings.
              </WSText>
            );
          }

          return (
            <WSFormOld
              onSubmit={submit}
              defaultValues={{
                channel:
                  user.phone.status === PhoneVerificationStatus.Verified
                    ? OtpVerificationChannel.SMS
                    : OtpVerificationChannel.Email
              }}
            >
              <WSText color="gray600" mb="XL" align="left">
                A 6 digit verification code will be sent via SMS to your mobile
                device (Message and Data rates may apply) or a verification link
                will be sent to your email address.
              </WSText>

              <WSFormOld.Field
                mb="2XL"
                name="channel"
                component={WSRadioInputGroup}
                componentProps={{
                  options: channelOptions
                }}
                label="Select"
              />

              <WSErrorMessage
                mt="M"
                error={submitMeta.error}
                contextKey={ErrorContextKey.VerifyPhone}
              />
              <WSFormOld.SubmitButton
                name="requestOtp"
                fullWidth
                loading={submitMeta.isLoading}
              >
                Continue
              </WSFormOld.SubmitButton>
            </WSFormOld>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
