import { WSButton, WSElement, WSText } from "@wingspanhq/fe-component-library";
import React from "react";
import styles from "./index.module.scss";

type Props = {
  label: string;
  description?: string;
  onEdit: () => void;
};

export const Setting: React.FC<Props> = ({
  label,
  description,
  onEdit,
  children
}) => {
  return (
    <WSElement className={styles.settingContainer}>
      <WSElement>
        <WSText.ParagraphSm color="gray500" mb={description ? "XS" : "XL"}>
          {label}
        </WSText.ParagraphSm>

        {description && (
          <WSText.ParagraphSm mb="XL" color="gray400">
            {description}
          </WSText.ParagraphSm>
        )}
        {children}
      </WSElement>
      <WSButton.Link onClick={() => onEdit()}>Edit</WSButton.Link>
    </WSElement>
  );
};
