import { FiltersOld } from "@wingspanhq/fe-component-library";
import QueryString from "qs";
import { useHistory, useLocation } from "react-router-dom";
import { useWSStore } from "../store";
import { getChangedData } from "./getChangedData";
import { isSerializedDate } from "./serviceHelper";

export function useUrlQueryFilters<F = FiltersOld>(defaultFilters: F) {
  const history = useHistory();
  const location = useLocation();

  const filters = {
    ...defaultFilters,
    ...(location.search
      ? ((QueryString.parse(location.search.slice(1), {
          // synced with BE limit https://github.com/wingspanHQ/routing/pull/96/files
          arrayLimit: 200,
          decoder: function(str, defaultDecoder, charset) {
            const decodedValue = defaultDecoder(str, defaultDecoder, charset);
            if (isSerializedDate(decodedValue)) {
              return new Date(decodedValue);
            } else {
              return decodedValue;
            }
          }
        }) as any) as F)
      : {})
  };

  const changedFilters = getChangedData(defaultFilters, filters);

  return {
    defaultFilters,
    filters,
    changedFilters,
    setFilters: (newFilters: F) => {
      history.push({
        search: QueryString.stringify(
          getChangedData(defaultFilters, newFilters)
        )
      });
    },
    redirectWithFilters: (pathname: string) => {
      history.push({
        pathname,
        search: QueryString.stringify(changedFilters)
      });
    }
  };
}

export const useHistoryPushWithFilters = () => {
  const history = useHistory();
  const store = useWSStore();

  return (pathname: string) => {
    const matchingPath = Object.keys(store.savedFilters).find(p =>
      pathname.includes(p)
    );

    if (matchingPath) {
      history.push({
        pathname: pathname,
        search: QueryString.stringify(store.savedFilters[matchingPath])
      });
    } else {
      history.push(pathname);
    }
  };
};
