import { MemberTaxDocumentSharePermission } from "@wingspanhq/payments/dist/interfaces";
import { MutationConfig } from "react-query";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { useMutationUpdateAcknowledgement } from "../../../query/onboarding/mutations/useMutationUpdateAcknowledgement";
import { useMemberClientsQuery } from "../../../query/payments/queries";
import { QUERY_USERS_ACTIVITIES } from "../../../query/users/keys";
import { paymentsService } from "../../../services/payments";
import { usersService } from "../../../services/users";
import { WSServiceError } from "../../../utils/serviceHelper";
import { acknowledgementVersions } from "../../constants/acknowledgements";

export const useMutationSubmit = (config?: MutationConfig<void>) => {
  const userId = useUserId();
  const queryMemberClients = useMemberClientsQuery();
  const queryFeatureFlags = useFeatureFlags();
  const [updateAcknowledgement] = useMutationUpdateAcknowledgement();

  return useWSMutation<any, WSServiceError>(
    async () => {
      const memberClients = queryMemberClients.data || [];

      await Promise.all(
        memberClients.map(memberClient =>
          paymentsService.memberClient.update(memberClient.memberClientId, {
            memberData: {
              shareTaxDocument: MemberTaxDocumentSharePermission.Allow
            }
          } as any)
        )
      );

      await usersService.activity.update(userId, {
        events: {
          w9FormAcknowledged: new Date()
        }
      });

      if (queryFeatureFlags.data) {
        await updateAcknowledgement({
          acknowledgementName: "W9Certification",
          requestData: {
            acknowledgementName: "W9Certification",
            acknowledgementStatus: "Given",
            version: acknowledgementVersions["W9Certification"]
          }
        });
      }
    },
    {
      dependencies: [QUERY_USERS_ACTIVITIES],
      ...config
    }
  );
};
