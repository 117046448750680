import {
  toCamelCase,
  WSElement,
  WSElementProps
} from "@wingspanhq/fe-component-library";
import {
  IFeeHandlingConfig,
  PaymentMethod
} from "@wingspanhq/payments/dist/interfaces";
import React, { useCallback } from "react";
import { selectorClientCreditCardFee } from "../../../shared/selectors/selectorClientCreditCardFee";
import { PaymentType, PaymentTypeProps } from "../PaymentType/PaymentType";

type Props = {
  availablePaymentMethods: PaymentMethod[];
  options?: {
    creditFeeHandling?: IFeeHandlingConfig;
  };
  onSelect: (paymentMethod: PaymentMethod) => void;
  withBorder?: boolean;
} & Omit<WSElementProps, "onSelect">;

export const PaymentTypeSelect: React.FC<Props> = ({
  availablePaymentMethods,
  options,
  onSelect,
  withBorder,
  ...elementProps
}) => {
  const getPaymentTypeProps = useCallback(
    (paymentMethod: PaymentMethod): PaymentTypeProps =>
      paymentMethod === PaymentMethod.ACH
        ? {
            icon: "bank",
            title: "Direct ACH",
            description: "Simplest, cheapest",
            feeText: "Free",
            pill: {
              text: "Recommended",
              theme: "success"
            }
          }
        : paymentMethod === PaymentMethod.Credit
        ? {
            icon: "card",
            title: "Credit card",
            description: "Earn points, defer payment",
            feeText: selectorClientCreditCardFee(options?.creditFeeHandling),
            pill: {
              text: "Fastest"
            }
          }
        : {
            icon: "send",
            title: "Wire / Manual ACH",
            description: "Use other bill pay software",
            feeText: "Free"
          },
    [options?.creditFeeHandling]
  );

  return (
    <WSElement {...elementProps}>
      {availablePaymentMethods.map(paymentMethod => (
        <PaymentType
          key={paymentMethod}
          mb="M"
          onClick={() => {
            onSelect(paymentMethod);
          }}
          data-testid={toCamelCase(paymentMethod, "button")}
          {...getPaymentTypeProps(paymentMethod)}
          withBorder={withBorder}
        />
      ))}
    </WSElement>
  );
};
