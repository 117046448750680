import { servicePayments } from "../servicePayments";
import {
  IPayerEngagementResponse,
  IPayerEngagementUpdateRequest
} from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";

export const updatePayerEngagement = async (
  payerId: string,
  engagementId: string,
  request: IPayerEngagementUpdateRequest
): Promise<IPayerEngagementResponse> => {
  const { data } = await servicePayments.patch(
    `/payer/${payerId}/engagement/${engagementId}`,
    request
  );

  return data;
};
