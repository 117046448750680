export const nonSherpaLinksByState: { [key: string]: string } = {
  CA: "https://www.coveredca.com/",
  NY: "https://nystateofhealth.ny.gov/",
  MA: "https://www.mahealthconnector.org/",
  MD: "https://www.marylandhealthconnection.gov/",
  DC: "https://dchealthlink.com/",
  CT: "https://www.accesshealthct.com/AHCT/LandingPageCTHIX",
  CO: "https://connectforhealthco.com/",
  VT: "https://portal.healthconnect.vermont.gov/VTHBELand/welcome.action",
  ID: "https://www.yourhealthidaho.org/",
  MN: "https://www.mnsure.org/",
  NV: "https://www.nevadahealthlink.com/",
  RI: "https://healthsourceri.com/"
};
