import {
  IPayoutResponse,
  IPayoutTransfer,
  PayoutStatusEnum
} from "../../../services/api/payments/payouts/types";

export const getFailedStatusCount = (payout: IPayoutResponse) => {
  const allTransfers: IPayoutTransfer[] = payout?.bankingFundsTransfer
    ? [payout?.bankingFundsTransfer, ...(payout?.externalFundsTransfers || [])]
    : (payout?.externalFundsTransfers || []).filter(
        transfer => transfer?.fundsMovementId !== undefined
      );

  const allErrorTransfers = allTransfers.filter(
    transfer => transfer.status === PayoutStatusEnum.Failed
  );

  return {
    errorsLength: allErrorTransfers.length,
    transfersLength: allTransfers.length
  };
};
