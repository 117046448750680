import {
  toWSDateString,
  WSElement,
  WSGrid,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { useUserId } from "../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { usePayrollSettings } from "../../../query/payments/queries";
import { WSQueries } from "../../../query/WSQuery";
import {
  PayablesStatusFilter,
  PayablesTable
} from "../../components/NewPayablesTable/PayablesTable";
import { PayablesSummary } from "../../components/PayablesSummary/PayablesSummary";
import { getNextPayrollCutOffDate } from "../../utils/payables";

export const PayablesScheduled: React.FC = () => {
  useBrowserPageTitle("Scheduled Payables");

  const userId = useUserId();
  const featureFlagsQuery = useFeatureFlags();
  const payrollSettingsQuery = usePayrollSettings(userId, {
    retry: false
  });

  return (
    <>
      <WSText.Heading1 mb="M">Scheduled</WSText.Heading1>

      <WSQueries queries={{ payrollSettingsQuery }}>
        {({ payrollSettingsQuery: { data: payrollSettings } }) => (
          <WSText color="gray600" mb="2XL">
            Approved payments will be processed at 12:00pm EST on{" "}
            {toWSDateString(
              getNextPayrollCutOffDate(payrollSettings),
              "monthDate"
            )}
          </WSText>
        )}
      </WSQueries>

      <WSElement mb="2XL">
        <WSGrid>
          <WSGrid.Item span={{ xs: "2" }}>
            <PayablesSummary
              label="Total amount"
              filter={{
                status: [PayablesStatusFilter.Scheduled]
              }}
              displayAmount
            />
          </WSGrid.Item>
          <WSGrid.Item span={{ xs: "2" }}>
            <PayablesSummary
              label="Total 1099s"
              filter={{
                status: [PayablesStatusFilter.Scheduled]
              }}
            />
          </WSGrid.Item>
        </WSGrid>
      </WSElement>

      <PayablesTable
        showPayrollGroups={featureFlagsQuery.data?.payrollGroupsOnTable}
        bulkActions={["unapprove", "delete"]}
        basePath="/member/invoices/payables/scheduled"
        visibleFilters={{
          collaborator: true,
          openedAtRange: true
        }}
        defaultFilters={{
          status: [PayablesStatusFilter.Scheduled],
          sort: {
            "internal.events.paymentDueAt": "asc"
          }
        }}
        showHeaders
        showInvoiceNumber
        showDateRequested
        showDueDate
        hideSelectAll
        emptyState={{
          firstTime:
            "Approved payments will queue here before they are paid in the next payroll cycle.",
          standart: "No approved payments for the next payroll cycle."
        }}
      />
    </>
  );
};
