import cn from "classnames";
import React, { ReactNode } from "react";
import styles from "./WSProgressBar.module.scss";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSText } from "../WSText/WSText.component";

export interface WSProgressBarProps extends WSElementProps {
  percent: number;
  text?: ReactNode;
  noAnimation?: boolean;
}

export const WSProgressBar: React.FC<WSProgressBarProps> = ({
  percent = 0,
  text = " ",
  noAnimation,
  className,
  ...componentProps
}) => (
  <WSElement
    className={cn(className, styles.progressWrapper)}
    {...componentProps}
  >
    <WSElement className={styles.progressBody}>
      <WSElement
        className={cn(styles.progressLine, {
          [styles.noAnimation]: noAnimation
        })}
        style={{ width: `${percent}%` }}
      />
    </WSElement>
    {typeof text === "string" ? (
      <WSText.ParagraphSm color="gray500" py="S">
        {text}
      </WSText.ParagraphSm>
    ) : (
      text
    )}
  </WSElement>
);
