import React from "react";
import { WSButtonProps } from "../../core/WSButton/WSButton.component";
import {
  WSFlexBox,
  WSFlexBoxProps
} from "../../layout/WSFlexBox/WSFlexBox.component";
import { useIsMobile } from "../../layout/WSScreen/WSScreen.component";

export type WSButtonsFormat = "none" | "modal";

export interface WSButtonsProps extends WSFlexBoxProps {
  forceFullWidth?: boolean;
  format?: WSButtonsFormat;
  wrapped?: boolean;
}

export const WSButtons: React.FC<WSButtonsProps> = ({
  children,
  forceFullWidth,
  format,
  wrapped,
  ...props
}) => {
  const isMobile = useIsMobile();

  const fullWidth = forceFullWidth || isMobile;
  const isWrapped = wrapped && !fullWidth;

  return (
    <WSFlexBox
      direction={
        format === "modal"
          ? fullWidth
            ? "column-reverse"
            : "row-reverse"
          : fullWidth
          ? "column"
          : "row"
      }
      alignItems={fullWidth ? undefined : "center"}
      wrap={isWrapped ? "wrap" : "nowrap"}
      {...props}
    >
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          let buttonProps: Partial<WSButtonProps> = {
            mt: format !== "modal" && fullWidth && index > 0 ? "M" : "NONE",
            mb: format === "modal" && fullWidth && index > 0 ? "M" : "NONE",
            ml: format !== "modal" && !fullWidth && index > 0 ? "M" : "NONE",
            mr: format === "modal" && !fullWidth && index > 0 ? "M" : "NONE",
            fullWidth
          };

          if (isWrapped) {
            buttonProps = {
              mb: "M",
              ml: format === "modal" ? "M" : "NONE",
              mr: format !== "modal" ? "M" : "NONE"
            };
          }

          return React.cloneElement(child, {
            ...buttonProps,
            key: `button-${index}`
          });
        }
        return null;
      })}
    </WSFlexBox>
  );
};
