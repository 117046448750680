import classNames from "classnames";
import React, { useMemo } from "react";
import { WSElement, WSMarginProps } from "../WSElement/WSElement.component";
import { WSButton, WSButtonProps } from "../core/WSButton/WSButton.component";
import { WSText } from "../core/WSText/WSText.component";
import styles from "./WSBanner.module.scss";
import { WSIcon, WSIconName } from "../core/WSIcon/WSIcon.component";
import { useIsMobile } from "../layout";

export type WSBannerTheme =
  | "neutral"
  | "info"
  | "success"
  | "warning"
  | "error";

export type WSBannerSize = "S" | "M";

export type WSBannerAction = {
  kind: WSButtonProps["kind"];
  onClick: WSButtonProps<"Link">["onClick"];
  loading?: WSButtonProps<"Link">["loading"];
  text: string;
};

export interface WSBannerProps extends WSMarginProps {
  className?: string;
  theme?: WSBannerTheme;
  actions?: WSBannerAction[];
  onDismiss?: () => void;
  icon?: boolean | WSIconName;
}

const themeIconMap: Record<WSBannerTheme, WSIconName> = {
  neutral: "info-circle-fill",
  info: "info-circle-fill",
  success: "check-circle-fill",
  warning: "alert-fill",
  error: "alert-circle-fill"
};

export const WSBanner: React.FC<WSBannerProps> = ({
  theme = "neutral",
  className,
  actions,
  onDismiss,
  children,
  icon,
  ...elementProps
}) => {
  const isMobile = useIsMobile();

  const Actions = useMemo(
    () => () => {
      return actions?.length ? (
        <WSElement className={styles.actions}>
          {actions.map((action, index) => (
            <WSButton
              key={`${index}-${action.text}`}
              className={styles.action}
              fullWidth={isMobile}
              size="S"
              {...action}
            >
              {action.text}
            </WSButton>
          ))}
        </WSElement>
      ) : null;
    },
    [actions, isMobile]
  );

  return (
    <WSElement
      className={classNames(styles.banner, styles[`type-${theme}`], className)}
      {...elementProps}
    >
      <WSElement className={styles.bannerWrapper}>
        {icon ? (
          <WSElement className={styles.iconWrapper}>
            <WSIcon
              size="M"
              name={icon === true ? themeIconMap[theme] : icon}
              className={styles.icon}
            />
          </WSElement>
        ) : null}
        <WSElement className={styles.content}>
          <WSText kind="ParagraphSm" color="gray600" className={styles.text}>
            {children}
          </WSText>
          {isMobile ? null : <Actions />}
        </WSElement>
        {typeof onDismiss === "function" ? (
          <WSElement className={styles.closeWrapper}>
            <WSIcon size="M" name="exit" onClick={onDismiss} color="gray600" />
          </WSElement>
        ) : null}
      </WSElement>
      {isMobile ? <Actions /> : null}
    </WSElement>
  );
};
