import { InfiniteQueryConfig, QueryConfig } from "react-query";
import {
  ListRequestQuery,
  WSServiceError,
  concurrentActions
} from "../../../../utils/serviceHelper";
import { useWSQuery, useWSInfiniteQuery } from "../../../../query/helpers";
import {
  IBulkCollaboratorBatch,
  IBulkCollaboratorItem
} from "@wingspanhq/payments/dist/interfaces";
import {
  QUERY_BULK_COLLABORATOR_BATCH,
  QUERY_BULK_COLLABORATOR_BATCH_ITEM_LIST,
  QUERY_BULK_COLLABORATOR_BATCH_LIST,
  QUERY_BULK_COLLABORATOR_BATCH_SUMMARY
} from "./keys";
import flatten from "lodash/flatten";
import { Await } from "../../../../utils";
import { bulkCollaboratorService } from "../../services/bulkCollaborator";
import { BulkBatchItemsFilter, BulkBatchesFilters } from "../../services/types";
import times from "lodash/times";

export const useBulkCollaboratorBatch = (
  batchId: string,
  config?: QueryConfig<IBulkCollaboratorBatch, WSServiceError>
) => {
  return useWSQuery<IBulkCollaboratorBatch, WSServiceError>(
    [QUERY_BULK_COLLABORATOR_BATCH, batchId],
    () => bulkCollaboratorService.batch.get(batchId),
    config
  );
};

function mapBulkCollaboratorsBatchFilters(filters?: BulkBatchesFilters) {
  const apiFilters: any = {};

  if (filters?.status && filters.status.length !== 5) {
    apiFilters.status = {
      in: filters.status
    };
  }
  return apiFilters;
}

export const useBulkCollaboratorBatchesQuery = (
  filters?: BulkBatchesFilters,
  config?: { size?: number } & InfiniteQueryConfig<
    IBulkCollaboratorBatch[],
    WSServiceError
  >
) => {
  const size = config?.size || 100;

  const query = useWSInfiniteQuery<IBulkCollaboratorBatch[], WSServiceError>(
    [QUERY_BULK_COLLABORATOR_BATCH_LIST, { filters, size }],
    (_, __, pageNumber = 1) => {
      return bulkCollaboratorService.batch.list({
        filter: mapBulkCollaboratorsBatchFilters(filters),
        page: {
          size,
          number: pageNumber
        },
        sort: filters?.sort
          ? filters.sort
          : {
              createdAt: "desc"
            }
      });
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.length < size) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      ...config
    }
  );

  return {
    ...query,
    data: query.data ? flatten(query.data) : undefined
  };
};

export const useBulkCollaboratorBatchSummary = (
  batchId: string,
  query?: ListRequestQuery<
    BulkBatchItemsFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >,
  config?: QueryConfig<
    Await<ReturnType<typeof bulkCollaboratorService.batch.summary>>,
    WSServiceError
  >
) => {
  return useWSQuery<
    Await<ReturnType<typeof bulkCollaboratorService.batch.summary>>,
    WSServiceError
  >(
    [QUERY_BULK_COLLABORATOR_BATCH_SUMMARY, [batchId, query]],
    () => bulkCollaboratorService.batch.summary(batchId, query),
    config
  );
};

export const useAllBulkCollaboratorBatchItems = (
  batchId: string,
  query?: ListRequestQuery<
    BulkBatchItemsFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >,
  config?: QueryConfig<IBulkCollaboratorItem[], WSServiceError>
) => {
  return useWSQuery<IBulkCollaboratorItem[], WSServiceError>(
    [QUERY_BULK_COLLABORATOR_BATCH_ITEM_LIST, [batchId, query]],
    async () => {
      const pageSize = 100;
      const listSize = await bulkCollaboratorService.batchItem.listSize(
        batchId,
        query
      );
      const pages = Math.ceil(listSize / pageSize);

      const actions = times(pages).map((_, i) => () =>
        bulkCollaboratorService.batchItem.list(batchId, {
          ...query,
          page: { size: pageSize, number: i + 1 }
        })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      const allBulkCollaboratorBatchItems = flatten(allPages);

      return allBulkCollaboratorBatchItems;
    },
    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};
