import {
  WSContainer,
  WSInputDateOld,
  WSFlexBox,
  WSFormOld,
  WSCentered,
  WSIcon,
  WSLayout,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { CertifiedSecureDigitalPaymentsTooltip } from "../../components/CertifiedSecureDigitalPaymentsTooltip/CertifiedSecureDigitalPaymentsTooltip";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { WSQueries } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import {
  useUpdateActivity,
  useUpdateUserProfile
} from "../../query/users/mutations";
import { useUserProfile } from "../../query/users/queries";
import {
  validatorDOB,
  validatorFirstName,
  validatorLastName
} from "../../utils/validators";
import { BenefitsLogoHeader } from "../components/BenefitsLogoHeader/BenefitsLogoHeader.module";
import { getPathAfterSuccess } from "../utils";
import styles from "./Benefits.module.scss";

export const BenefitsSetUpStep2: React.FC = () => {
  const history = useHistory<{ back: string }>();

  const userId = useUserId();
  const qUserProfile = useUserProfile(userId);

  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);
  const [updateUserProfile, updateUserProfileMeta] = useUpdateUserProfile({
    throwOnError: true
  });

  useBrowserPageTitle("Benefits - Confirm your name and birthdate");

  return (
    <WSLayout
      headerLeft={
        <WSFlexBox.CenterY
          onClick={() =>
            history.push(getPathAfterSuccess() || "/member/benefits")
          }
        >
          <WSIcon block name="chevron-left" size="XS" color="gray500" mr="XS" />
          <WSText.ParagraphSm pl="XS" color="gray500">
            Back
          </WSText.ParagraphSm>
        </WSFlexBox.CenterY>
      }
      headerCenter={<BenefitsLogoHeader />}
    >
      <WSFlexBox.Center className={styles.subHeaderInfo} wrap="nowrap">
        <WSText>
          Wingspan is HIPAA Compliant.
          <CertifiedSecureDigitalPaymentsTooltip ml="XS" />
        </WSText>
      </WSFlexBox.Center>

      <WSContainer verticalPadding>
        <WSQueries queries={{ userData: qUserProfile }}>
          {({ userData }) => {
            const user = userData.data;

            return (
              <WSCentered span={{ m: "6" }}>
                <WSText.ParagraphSm color="gray500" mt="M" pt="M">
                  1 of 2
                </WSText.ParagraphSm>
                <WSText.Heading4>
                  Confirm your name and birthdate
                </WSText.Heading4>
                <WSText.ParagraphSm mt="XL">
                  We need to confirm your information to enroll in Premium Perks
                  and to ship you your membership card and packet. You will
                  receive your membership packet within a week.
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="XL">
                  Your information is{" "}
                  <a
                    href="https://www.wingspan.app/security"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Certified Secure
                  </a>
                  .
                </WSText.ParagraphSm>

                <WSFormOld
                  onSubmit={async formData => {
                    await updateUserProfile({
                      profile: {
                        dob: formData.dob,
                        firstName: formData.firstName,
                        lastName: formData.lastName
                      }
                    });

                    await updateActivity({
                      flows: {
                        benefitsSetup: {
                          version: 2,
                          totalSteps: 4,
                          currentStep: 2,
                          complete: false
                        }
                      }
                    });

                    history.push("/member/benefits/set-up/3");
                  }}
                  defaultValues={{
                    firstName: user.profile.firstName || "",
                    lastName: user.profile.lastName || "",
                    dob: user.profile.dob
                  }}
                  validationSchema={Yup.object().shape({
                    firstName: validatorFirstName.required("Required"),
                    lastName: validatorLastName.required("Required"),
                    dob: validatorDOB.required("Required")
                  })}
                >
                  <WSFormOld.Field
                    mt="XL"
                    name="firstName"
                    component={WSTextInput}
                    label="Legal first name"
                  />
                  <WSFormOld.Field
                    mt="XL"
                    name="lastName"
                    component={WSTextInput}
                    label="Legal last name"
                  />

                  <WSFormOld.Field
                    mt="XL"
                    name="dob"
                    component={WSInputDateOld}
                    label="Date of birth"
                  />

                  <WSErrorMessage
                    mt="XL"
                    contextKey="BenefitsSetUp"
                    error={
                      updateUserProfileMeta.error || updateActivityMeta.error
                    }
                  />

                  <WSFormOld.SubmitButton
                    name="verifyAddress"
                    mt="2XL"
                    fullWidth
                  >
                    Confirm name & birthdate
                  </WSFormOld.SubmitButton>
                </WSFormOld>
              </WSCentered>
            );
          }}
        </WSQueries>
      </WSContainer>
    </WSLayout>
  );
};
