import {
  WSElementColorsType,
  WSPillProps
} from "@wingspanhq/fe-component-library";
import {
  IPayoutResponse,
  IPayoutTransfer
} from "../../../services/api/payments/payouts/types";
import { getStatusDescriptionText } from "./getStatusDescriptionText";

export enum PayoutStatus {
  InProgress = "InProgress",
  Failed = "Failed",
  Complete = "Complete",
  Deposited = "Deposited"
}

export enum PayoutTableStatus {
  InProgress = "InProgress",
  Failed = "Failed",
  Deposited = "Deposited"
}

export function getTableStatus(payout: IPayoutResponse): PayoutTableStatus {
  const transfers: IPayoutTransfer[] = payout.externalFundsTransfers
    ? Array.from(payout.externalFundsTransfers)
    : [];

  if (payout.bankingFundsTransfer?.amount) {
    transfers.push(payout.bankingFundsTransfer);
  }

  if (payout.taxWithholdingTransfer?.amount) {
    transfers.push(payout.taxWithholdingTransfer);
  }

  const allTransfersComplete = transfers.every(
    transfer => transfer.status === PayoutStatus.Complete
  );

  if (allTransfersComplete || payout.status === PayoutStatus.Complete) {
    return PayoutTableStatus.Deposited;
  }

  if (payout.status === PayoutStatus.Failed) {
    return PayoutTableStatus.Failed;
  }

  return PayoutTableStatus.InProgress;
}

export const getPayoutsStatusPillProps = (
  payout: IPayoutResponse
): WSPillProps => {
  const tableStatus = getTableStatus(payout);

  switch (tableStatus) {
    case PayoutTableStatus.Deposited:
      return {
        theme: "success",
        icon: "check-circle",
        text: "Deposited"
      };

    case PayoutTableStatus.Failed:
      return {
        theme: "error",
        icon: true,
        text: "Payout failed"
      };

    default:
      return {
        theme: "blue",
        icon: "send-mail",
        text: "In-progress"
      };
  }
};

export const getPayoutStatusTableCellProps = (
  payout: IPayoutResponse
): {
  pill: WSPillProps;
  secondaryTextColor: WSElementColorsType;
  secondaryText: string | undefined;
} => {
  const tableStatus = getTableStatus(payout);

  const pill = getPayoutsStatusPillProps(payout);
  const secondaryTextColor =
    tableStatus === PayoutTableStatus.Failed ? "red400" : "gray400";
  const secondaryText = getStatusDescriptionText(payout);

  return { pill, secondaryTextColor, secondaryText };
};
