export const QUERY_INTEGRATIONS_QUICKBOOKS = "QUERY_INTEGRATIONS_QUICKBOOKS";
export const QUERY_INTEGRATIONS_QUICKBOOKS_SYNC_ACTIVITIES =
  "QUERY_INTEGRATIONS_QUICKBOOKS_SYNC_ACTIVITIES";
export const QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_ASSETS =
  "QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_ASSETS";
export const QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_ASSET =
  "QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_ASSET";
export const QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_EXPENSES =
  "QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_EXPENSES";
export const QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_EXPENSE =
  "QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_EXPENSE";
export const QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_LIABILITIES =
  "QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_LIABILITIES";
export const QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_LIABILITY =
  "QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_LIABILITY";
export const QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_REVENUES =
  "QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_LIABILITIES";
export const QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_REVENUE =
  "QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_LIABILITY";
export const QUERY_INTEGRATIONS_QUICKBOOKS_CUSTOMERS =
  "QUERY_INTEGRATIONS_QUICKBOOKS_CUSTOMERS";
export const QUERY_INTEGRATIONS_QUICKBOOKS_CUSTOMER =
  "QUERY_INTEGRATIONS_QUICKBOOKS_CUSTOMER";
export const QUERY_INTEGRATIONS_QUICKBOOKS_ITEMS =
  "QUERY_INTEGRATIONS_QUICKBOOKS_ITEMS";
export const QUERY_INTEGRATIONS_QUICKBOOKS_ITEM =
  "QUERY_INTEGRATIONS_QUICKBOOKS_ITEM";
export const QUERY_INTEGRATIONS_QUICKBOOKS_VENDORS =
  "QUERY_INTEGRATIONS_QUICKBOOKS_VENDORS";
export const QUERY_INTEGRATIONS_QUICKBOOKS_VENDOR =
  "QUERY_INTEGRATIONS_QUICKBOOKS_VENDOR";
