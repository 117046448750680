import {
  IAuthorizationResponse,
  IMember,
  INewUser,
  IRedactedMember,
  IRedactedUser,
  ISubscriptionGrantResponse,
  SubscriptionGrantStatus
} from "@wingspanhq/users/dist/lib/interfaces";
import { useMemo } from "react";
import { useAuthorizedScopeGroupsQuery } from "../../query/users/queries";
import {
  getAdminSessionToken,
  getRequestingTokenFromURL,
  getRequestingUserSessionToken
} from "../../services/sessionStorage";
import { IAuthorizedAccountsResponse } from "@wingspanhq/users";

export const ADMIN_SCOPE_GROUP_ID = "Qk5amjWDHoHeLXN1DCR0yk";
export const PAYMENTS_SCOPE_GROUP_ID = "qX_ZUDUzFkmQDyZeGmR3XF";
export const COLLABORATORS_SCOPE_GROUP_ID = "6270714525b71672ee07409d";
export const FINANCES_SCOPE_GROUP_ID = "mnTAr3m5HJLNoKdL8ntm10";
export const DOCUMENTS_SCOPE_GROUP_ID = "608219e297b39a1ddd573352";

export const scopeGroups = {
  [ADMIN_SCOPE_GROUP_ID]: {
    scopeGroupId: ADMIN_SCOPE_GROUP_ID,
    title: "Admin",
    scopes: "Full account access"
  },
  [PAYMENTS_SCOPE_GROUP_ID]: {
    scopeGroupId: PAYMENTS_SCOPE_GROUP_ID,
    title: "Payments",
    scopes: "Invoicing, payouts, contacts"
  },
  [COLLABORATORS_SCOPE_GROUP_ID]: {
    scopeGroupId: COLLABORATORS_SCOPE_GROUP_ID,
    title: "Contractors",
    scopes: "Add contractors"
  },
  [FINANCES_SCOPE_GROUP_ID]: {
    scopeGroupId: FINANCES_SCOPE_GROUP_ID,
    title: "Finances",
    scopes: "Income & expenses, taxes"
  },
  [DOCUMENTS_SCOPE_GROUP_ID]: {
    scopeGroupId: DOCUMENTS_SCOPE_GROUP_ID,
    title: "Documents",
    scopes: "Private & Public Documents"
  }
} as const;

export type ScopeGroupId = keyof typeof scopeGroups;

export const hasAdminScope = (scopeGroupIds: Array<string>) => {
  return scopeGroupIds.includes(ADMIN_SCOPE_GROUP_ID);
};

export const hasPaymentsScope = (scopeGroupIds: Array<string>) => {
  return scopeGroupIds.includes(PAYMENTS_SCOPE_GROUP_ID);
};

export const hasCollaboratorsScope = (scopeGroupIds: Array<string>) => {
  return scopeGroupIds.includes(COLLABORATORS_SCOPE_GROUP_ID);
};

export const hasFinancesScope = (scopeGroupIds: Array<string>) => {
  return scopeGroupIds.includes(FINANCES_SCOPE_GROUP_ID);
};

export const hasDocumentsScope = (scopeGroupIds: Array<string>) => {
  return scopeGroupIds.includes(DOCUMENTS_SCOPE_GROUP_ID);
};

export const getAuthorizedAccountName = (
  member: IMember | IRedactedMember | undefined,
  user: INewUser | IRedactedUser
): string => {
  if (member?.profile?.company?.name) {
    return member.profile.company.name as string;
  } else if (user.profile?.firstName && user.profile.lastName) {
    return `${user.profile.firstName} ${user.profile.lastName}`;
  } else if (user.email) {
    return user.email;
  } else if (member?.user?.email) {
    return member.user.email;
  }

  return "";
};

export const getMemberName = (
  member: IMember | IRedactedMember | undefined
) => {
  if (member) {
    if (member.profile?.company?.name) {
      return member.profile?.company?.name;
    }
  }
  return "";
};

type AllowedScopeGroupId = keyof typeof scopeGroups;

export interface INormalizedScopeGroupId {
  authorizationId: string;
  allowedScopeGroupId: AllowedScopeGroupId;
}

export interface INormalizedAuthorization extends IAuthorizationResponse {
  allowedScopeGroupIds: Array<INormalizedScopeGroupId>;
  organizationAccountValues: Array<string>;
  organizationAccountAuthorizationId?: string;
}

export const groupAuthorizationsByTeamMember = (
  authorizations: Array<IAuthorizationResponse>
): Array<INormalizedAuthorization> => {
  const normalizedData: { [key: string]: INormalizedAuthorization } = {};
  authorizations.forEach(authorization => {
    if (!authorization.requestingUserId) {
      return;
    }

    const organizationValues =
      authorization?.scopeModifications?.users?.organizationAccount?.map(
        scope => scope.value
      ) || [];

    const organizationAccountAuthorizationId = organizationValues.length
      ? authorization.authorizationId
      : undefined;

    if (normalizedData[authorization.requestingUserId]) {
      if (authorization.authorizationId && authorization.allowedScopeGroupId) {
        normalizedData[
          authorization.requestingUserId
        ].allowedScopeGroupIds.push({
          authorizationId: authorization.authorizationId,
          allowedScopeGroupId: authorization.allowedScopeGroupId as AllowedScopeGroupId
        });
      }

      if (organizationValues.length) {
        normalizedData[
          authorization.requestingUserId
        ].organizationAccountValues = [
          ...normalizedData[authorization.requestingUserId]
            .organizationAccountValues,
          ...organizationValues
        ];

        normalizedData[
          authorization.requestingUserId
        ].organizationAccountAuthorizationId = organizationAccountAuthorizationId;
      }
    } else {
      normalizedData[authorization.requestingUserId] = {
        ...authorization,
        organizationAccountValues: [...organizationValues],
        organizationAccountAuthorizationId,
        allowedScopeGroupIds:
          authorization.authorizationId && authorization.allowedScopeGroupId
            ? [
                {
                  authorizationId: authorization.authorizationId,
                  allowedScopeGroupId: authorization.allowedScopeGroupId as AllowedScopeGroupId
                }
              ]
            : []
      };
    }
  });
  return Object.values(normalizedData);
};

export interface ITeamMemberRecord {
  teamMemberUserId: string;
  teamMemberWSUser: INewUser | IRedactedUser;
  teamMemberWSMember?: IMember | IRedactedMember;
  authorization?: INormalizedAuthorization;
  subscriptionGrant?: ISubscriptionGrantResponse;
}

export const getTeamMemberList = (
  authorizations: Array<IAuthorizationResponse>,
  subscriptionGrantsList: Array<ISubscriptionGrantResponse>
) => {
  const filteredSubscriptionGrantsList = subscriptionGrantsList.filter(
    ({ status }) => status !== SubscriptionGrantStatus.Canceled
  );

  const teamTableRecordsMap: {
    [key: string]: ITeamMemberRecord;
  } = {};
  const normalizedAuthorizations = groupAuthorizationsByTeamMember(
    authorizations
  );
  normalizedAuthorizations.forEach(na => {
    const subscriptionGrant = filteredSubscriptionGrantsList.find(
      sg => sg.granteeId === na.requestingUserId
    );
    teamTableRecordsMap[na.requestingUserId as string] = {
      teamMemberUserId: na.requestingUserId as string,
      teamMemberWSUser: na.requestingUser,
      teamMemberWSMember: na.requestingMember,
      authorization: na,
      subscriptionGrant
    };
  });
  filteredSubscriptionGrantsList.forEach(sg => {
    if (!teamTableRecordsMap[sg.granteeId]) {
      teamTableRecordsMap[sg.granteeId] = {
        teamMemberUserId: sg.granteeId,
        teamMemberWSUser: sg.granteeUser,
        teamMemberWSMember: sg.granteeMember,
        subscriptionGrant: sg
      };
    }
  });
  return Object.values(teamTableRecordsMap);
};

export const getTeamMemberByUserId = (
  requestingUserId: string,
  authorizations: Array<IAuthorizationResponse>,
  subscriptionGrantsList: Array<ISubscriptionGrantResponse>
) => {
  return getTeamMemberList(authorizations, subscriptionGrantsList).find(
    teamMember => teamMember.teamMemberUserId === requestingUserId
  );
};

const getScopeGroupIds = (data: IAuthorizedAccountsResponse) => {
  // TODO: "scrope" whaaaat?
  return data.map((data: any) => data.scropeGroupId);
};

export const useAuthorizedScopeGroups = () => {
  const requestingUserTokenFromURL = getRequestingTokenFromURL();
  const requestingUserSessionToken = getRequestingUserSessionToken();
  const adminToken = getAdminSessionToken();
  const isAdminSessionExists = useMemo(() => !!adminToken, [adminToken]);
  const isRequestingUserTokenFromURL = useMemo(
    () => !!requestingUserTokenFromURL,
    [requestingUserTokenFromURL]
  );

  const isRequestingUserSessionExists = useMemo(
    () => !!requestingUserSessionToken,
    [requestingUserSessionToken]
  );

  // We are requesting permissions for every user, for avoid security issue
  const queryAuthorizationScopes = useAuthorizedScopeGroupsQuery(undefined, {
    retry: false
  });

  const scopeGroupIds = useMemo(
    () => getScopeGroupIds(queryAuthorizationScopes.data || []),
    [queryAuthorizationScopes.status]
  );

  const hasAdminScope = useMemo(() => {
    // if admin token exist, grant admin permission
    if (isAdminSessionExists) {
      return true;
    }

    // in other cases, waiting for the authorization scope query to be fetched
    if (queryAuthorizationScopes.isFetched) {
      // if the query is successful, check if the user has admin permission
      if (Array.isArray(queryAuthorizationScopes.data)) {
        return getScopeGroupIds(queryAuthorizationScopes.data).includes(
          ADMIN_SCOPE_GROUP_ID
        );
      }
      // if the query is failed, check if the error is because of the requestingUserId is missing, that error means that it's admin
      else if (
        queryAuthorizationScopes.error?.response?.data.error.includes(
          "Expecting requestingUserId"
        )
      ) {
        return true;
      }
      // if it's some other error, just check if we store the requesting token in the session storage, if yes, it means that it's not admin
      else {
        return isRequestingUserTokenFromURL
          ? false
          : !isRequestingUserSessionExists;
      }
    }

    return false;
  }, [
    isAdminSessionExists,
    isRequestingUserSessionExists,
    isRequestingUserTokenFromURL,
    queryAuthorizationScopes.status
  ]);

  const hasPaymentsScope = useMemo(() => {
    return hasAdminScope || scopeGroupIds.includes(PAYMENTS_SCOPE_GROUP_ID);
  }, [hasAdminScope, scopeGroupIds.length]);

  const hasCollaboratorsScope = useMemo(() => {
    return (
      hasAdminScope || scopeGroupIds.includes(COLLABORATORS_SCOPE_GROUP_ID)
    );
  }, [hasAdminScope, scopeGroupIds.length]);

  const hasFinancesScope = useMemo(() => {
    return hasAdminScope || scopeGroupIds.includes(FINANCES_SCOPE_GROUP_ID);
  }, [hasAdminScope, scopeGroupIds.length]);

  const hasDocumentsScope = useMemo(() => {
    return hasAdminScope || scopeGroupIds.includes(DOCUMENTS_SCOPE_GROUP_ID);
  }, [hasAdminScope, scopeGroupIds.length]);

  return {
    scopeGroupIds,
    isAdminSessionExists,
    isRequestingUserSessionExists,
    isRequestingUserTokenFromURL,

    hasAdminScope,
    hasPaymentsScope,
    hasCollaboratorsScope,
    hasFinancesScope,
    hasDocumentsScope
  };
};
