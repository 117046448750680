import {
  IBulkPayableBatchCreate,
  IBulkPayableBatch,
  IBulkPayableBatchUpdate,
  IBulkPayableImportSummary,
  IBulkPayableItemCreate,
  IBulkPayableItem,
  IBulkPayableItemUpdate
} from "@wingspanhq/payments/dist/interfaces";
import { WSService } from "../../../utils/WSService";
import { ListRequestQuery } from "../../../utils/serviceHelper";
import {
  BulkBatchesFilters,
  BulkBatchItemsFilter,
  IPayableBatchImportSummary
} from "./types";

const service = new WSService("/payments");

const createBulkPayableBatch = async <
  IBatchCreate = IBulkPayableBatchCreate,
  IBatchResponse = IBulkPayableBatch
>(
  data: IBatchCreate
): Promise<IBatchResponse> => {
  const response = await service.post(`/bulk/payable/batch`, data);
  return response.data;
};

const updateBulkPayableBatch = async <
  IBatchUpdate = IBulkPayableBatchUpdate,
  IBatchResponse = IBulkPayableBatch
>(
  id: string,
  data: IBatchUpdate
): Promise<IBatchResponse> => {
  const response = await service.patch(`/bulk/payable/batch/${id}`, data);
  return response.data;
};

const getBulkPayableBatch = async <IBatchResponse = IBulkPayableBatch>(
  id: string
): Promise<IBatchResponse> => {
  const response = await service.get(`/bulk/payable/batch/${id}`);
  return response.data;
};

const deleteBulkPayableBatch = async <IBatchResponse = IBulkPayableBatch>(
  id: string
): Promise<IBatchResponse> => {
  const response = await service.delete(`/bulk/payable/batch/${id}`);
  return response.data;
};

const getBulkPayableBatches = async <IBatchResponse = IBulkPayableBatch>(
  query?: ListRequestQuery<
    BulkBatchesFilters,
    {
      createdAt?: "asc" | "desc";
    }
  >
): Promise<IBatchResponse[]> => {
  const response = await service.get(`/bulk/payable/batch`, { params: query });
  return response.data;
};

const createBulkPayableBatchItem = async <
  IBatchItemCreate = IBulkPayableItemCreate,
  IBatchItemResponse = IBulkPayableItem
>(
  batchId: string,
  data: IBatchItemCreate
): Promise<IBatchItemResponse> => {
  const response = await service.post(
    `/bulk/payable/batch/${batchId}/item`,
    data
  );
  return response.data;
};

const updateBulkPayableBatchItem = async <
  IBatchItemUpdate = IBulkPayableItemUpdate,
  IBatchItemResponse = IBulkPayableItem
>(
  batchId: string,
  batchItemId: string,
  data: IBatchItemUpdate
): Promise<IBatchItemResponse> => {
  const response = await service.patch(
    `/bulk/payable/batch/${batchId}/item/${batchItemId}`,
    data
  );
  return response.data;
};

const getBulkPayableBatchItemsListSize = async (
  batchId: string,
  query?: ListRequestQuery<
    BulkBatchItemsFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >
): Promise<number> => {
  const { headers } = await service.get(`/bulk/payable/batch/${batchId}/item`, {
    params: { ...query, page: { size: 1 } }
  });
  return Number(headers["x-ws-list-size"]);
};

const getBulkPayableBatchImportSummary = async (
  batchId: string,
  query?: ListRequestQuery<
    BulkBatchItemsFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >
): Promise<IPayableBatchImportSummary> => {
  const { headers } = await service.get(
    `/bulk/payable/batch/${batchId}/item?includeBatchSummary=1`,
    {
      params: { ...query, page: { size: 1 } }
    }
  );
  return {
    payablesCount: Number(headers["x-ws-payables-count"]),
    totalPayablesAmount: Number(headers["x-ws-total-payable-amount"]),

    deductionsCreatedCount: Number(headers["x-ws-deductions-created-count"]),
    totalDeductionsAmount: Number(headers["x-ws-deductions-created-amount"]),

    netImportAmount: Number(headers["x-ws-net-import-amount"]),
    totalItemsImported: Number(headers["x-ws-total-items-imported"]),
    collaboratorsImpactedCount: Number(
      headers["x-ws-collaborators-impacted-count"]
    ),

    listSize: Number(headers["x-ws-list-size"])
  };
};

const getBulkPayableBatchItems = async <IBatchItemResponse = IBulkPayableItem>(
  batchId: string,
  query?: ListRequestQuery<
    BulkBatchItemsFilter,
    {
      createdAt?: "asc" | "desc";
    }
  >
): Promise<IBatchItemResponse[]> => {
  const { data } = await service.get(`/bulk/payable/batch/${batchId}/item`, {
    params: query
  });
  return data;
};

const getBulkPayableBatchItem = async <IBatchItemResponse = IBulkPayableItem>(
  batchId: string,
  batchItemId: string
): Promise<IBatchItemResponse> => {
  const response = await service.get(
    `/bulk/payable/batch/${batchId}/item/${batchItemId}`
  );
  return response.data;
};

export const bulkPayableService = {
  batch: {
    create: createBulkPayableBatch,
    get: getBulkPayableBatch,
    list: getBulkPayableBatches,
    delete: deleteBulkPayableBatch,
    update: updateBulkPayableBatch,
    summary: getBulkPayableBatchImportSummary
  },
  batchItem: {
    create: createBulkPayableBatchItem,
    get: getBulkPayableBatchItem,
    list: getBulkPayableBatchItems,
    listSize: getBulkPayableBatchItemsListSize,
    update: updateBulkPayableBatchItem
  }
};
