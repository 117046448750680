import React from "react";
import { WSElement } from "../WSElement/WSElement.component";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import styles from "./WSTable.module.scss";
import { WSTableItem, WSTableRowActions, WSTableRowMenuActions } from "./types";
import { WSActions } from "../WSActions/WSActions";

type RowActionsProps = {
  item: WSTableItem;
  rowMenuActions?: WSTableRowMenuActions<any>;
  rowActions?: WSTableRowActions<any>;
  onRowClick?: (item: WSTableItem) => void;
  disabled?: boolean;
};

export const RowActions: React.FC<RowActionsProps> = ({
  item,
  rowActions,
  rowMenuActions,
  onRowClick,
  disabled
}) => {
  const actions = rowActions?.(item);
  const menuActions = rowMenuActions?.(item);
  const handleRowClick = onRowClick ? () => onRowClick?.(item) : undefined;

  return (
    <>
      <WSElement className={styles.rowActions}>
        <WSActions
          disabled={disabled}
          alignment="right"
          size="S"
          buttons={actions?.map((action, index) => ({
            label: action.text,
            hideOn: ["XS"],
            kind: "Link",
            type: "button",
            ...action,
            disabled
          }))}
          menuItems={menuActions}
        />

        {!!handleRowClick && (
          <WSIcon
            onClick={handleRowClick}
            block
            name="chevron-right"
            color="gray500"
            size="S"
          />
        )}
      </WSElement>
    </>
  );
};
