import React, { useState } from "react";
import * as Yup from "yup";

import {
  ITransaction,
  TransactionType
} from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";

import {
  toWSMoney,
  useModalContext,
  useWSSnackbar,
  WSButton,
  WSButtons,
  WSFormOld,
  WSInputNumberOld,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";

import { useUpdateTransaction } from "../../../../query/bookkeeping/mutations";
import {
  getIsRefundBasedOnData,
  getSignedAmountBasedOnData
} from "../../../utils";
import { runner } from "./utils.";

type BookkeepingModalBulkAmountProps = {
  selectedTransactions: ITransaction[] | undefined;
  onActionFinish(): void;
};

export const BOOKKEEPING_MODAL_BULK_AMOUNT = "BOOKKEEPING_MODAL_BULK_AMOUNT";

export const BookkeepingModalBulkAmount: React.FC = () => {
  const { closeModal } = useModalContext();
  const { openSnackbar } = useWSSnackbar();

  const [isUpdating, setIsUpdating] = useState(false);

  const [updateTransaction] = useUpdateTransaction();

  // TODO: Need to prevent closing during operation
  return (
    <WSModal
      blockClose={isUpdating}
      name={BOOKKEEPING_MODAL_BULK_AMOUNT}
      size="S"
    >
      {({
        selectedTransactions,
        onActionFinish
      }: BookkeepingModalBulkAmountProps) => (
        <>
          {selectedTransactions && selectedTransactions.length > 0 ? (
            <>
              <WSText.Heading5 mb="M">
                Edit amount for {selectedTransactions.length} transaction
                {selectedTransactions.length > 1 && "s"}
              </WSText.Heading5>

              <WSFormOld<{ amount: number }>
                validationSchema={Yup.object().shape({
                  amount: Yup.number().required("Amount is required")
                })}
                onSubmit={async formData => {
                  setIsUpdating(true);

                  await runner({
                    items: selectedTransactions,
                    openSnackbar,
                    tryAction: async transaction => {
                      let amount = formData.amount;
                      if (transaction.type !== TransactionType.Transfer) {
                        amount = getSignedAmountBasedOnData(
                          amount,
                          transaction.type,
                          getIsRefundBasedOnData(
                            transaction.amount,
                            transaction.type
                          )
                        );
                      }
                      await updateTransaction(
                        {
                          transactionId: transaction.transactionId,
                          amount
                        },
                        {
                          throwOnError: true
                        }
                      );
                    },
                    undoAction: async transaction =>
                      await updateTransaction({
                        transactionId: transaction.transactionId,
                        amount: transaction.amount
                      }),
                    failMessage: failedItems =>
                      `${failedItems.length} amount updates failed`,
                    successMessage: successItems =>
                      `${successItems.length} amounts updated to ${toWSMoney(
                        formData.amount
                      )}`
                  });

                  setIsUpdating(false);
                  onActionFinish();
                  closeModal(BOOKKEEPING_MODAL_BULK_AMOUNT);
                }}
              >
                <WSFormOld.Field
                  mb="2XL"
                  label="Dollar amount"
                  name="amount"
                  component={WSInputNumberOld}
                  componentProps={{
                    mode: "currency",
                    currency: "USD",
                    min: 0
                  }}
                />
                <WSButtons mt="M" forceFullWidth format="modal">
                  <WSButton loading={isUpdating}>Update</WSButton>
                  <WSButton.Tertiary
                    disabled={isUpdating}
                    onClick={() => closeModal(BOOKKEEPING_MODAL_BULK_AMOUNT)}
                  >
                    Cancel
                  </WSButton.Tertiary>
                </WSButtons>
              </WSFormOld>
            </>
          ) : (
            closeModal(BOOKKEEPING_MODAL_BULK_AMOUNT)
          )}
        </>
      )}
    </WSModal>
  );
};
