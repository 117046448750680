import cn from "classnames";
import React from "react";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import styles from "./WSLogo.module.scss";
import LogoBlack from "./assets/wingspan-logo-black.svg";
import LogoCream from "./assets/wingspan-logo-cream.svg";
import LogoFullDark from "./assets/wingspan-logo-full-dark.svg";
import LogoFullLight from "./assets/wingspan-logo-full-light.svg";
import LogoGarnet from "./assets/wingspan-logo-garnet.svg";

export type LogoSize = "XS" | "S" | "M" | "L";

export type LogoColor = "dark" | "light";

const logoComponentsMap: Record<LogoColor, SvgComponent> = {
  dark: LogoFullDark,
  light: LogoFullLight
};

export type WSLogoProps = {
  size?: LogoSize;
  color?: LogoColor;
  inline?: boolean;
} & Omit<WSElementProps, "color">;

export const WSLogo: React.FC<WSLogoProps> = ({
  size = "M",
  color = "dark",
  inline,
  className,
  ...elementProps
}) => {
  const sizeClass = styles[`size_${size}`];

  const containerClass = cn(
    styles.container,
    sizeClass,
    inline && styles.inline,
    className
  );

  const SvgComponent = logoComponentsMap[color];

  return (
    <WSElement className={containerClass} {...elementProps}>
      <SvgComponent className={styles.img} />
    </WSElement>
  );
};

export type LogomarkColor = "garnet" | "black" | "cream";

const logomarkComponentsMap: Record<LogomarkColor, SvgComponent> = {
  garnet: LogoGarnet,
  black: LogoBlack,
  cream: LogoCream
};

export type WSLogomarkProps = {
  size?: LogoSize;
  color?: LogomarkColor;
  inline?: boolean;
} & Omit<WSElementProps, "color">;

export const WSLogomark: React.FC<WSLogomarkProps> = ({
  size = "M",
  color = "garnet",
  inline,
  className,
  ...elementProps
}) => {
  const sizeClass = styles[`size_${size}`];

  const containerClass = cn(
    styles.container,
    sizeClass,
    inline && styles.inline,
    className
  );

  const SvgComponent = logomarkComponentsMap[color];

  return (
    <WSElement className={containerClass} {...elementProps}>
      <SvgComponent className={styles.img} />
    </WSElement>
  );
};
