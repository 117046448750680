import classNames from "classnames";
import React from "react";
import { WSElement, WSElementProps } from "../WSElement/WSElement.component";
import {
  WSTooltipIcon,
  WSTooltipIconProps
} from "../WSTooltipIcon/WSTooltipIcon";
import { WSButton, WSButtonProps } from "../core/WSButton/WSButton.component";
import { WSText } from "../core/WSText/WSText.component";
import styles from "./WSDivider.module.scss";

export interface WSDividerProps
  extends Omit<WSElementProps<HTMLHRElement>, "color"> {
  type?: "default" | "expand" | "split";
  label?: string;
  action?: {
    onClick: WSButtonProps<"Link">["onClick"];
    loading?: WSButtonProps<"Link">["loading"];
    text: string;
  };
  tooltip?: WSTooltipIconProps["tooltip"];
}

export const WSDivider: React.FC<WSDividerProps> = ({
  type = "default",
  label,
  className,
  action,
  tooltip,
  ...elementProps
}) => {
  return (
    <WSElement
      className={classNames(
        styles.divider,
        styles[`type-${type}`],
        !!label && styles.withLabel,
        className
      )}
      color="gray200"
      {...elementProps}
    >
      {label && (
        <WSElement className={styles.label}>
          <WSText.ParagraphSm color="gray500">{label}</WSText.ParagraphSm>

          {tooltip && <WSTooltipIcon tooltip={tooltip} ml="S" />}
        </WSElement>
      )}

      {action && (
        <WSButton.Link
          type="button"
          className={styles.action}
          size="M"
          onClick={action.onClick}
          loading={action.loading}
        >
          {action.text}
        </WSButton.Link>
      )}
    </WSElement>
  );
};
