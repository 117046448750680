import {
  useWSModal,
  WSActions,
  WSAlert,
  WSDivider,
  WSInfoBox,
  WSList
} from "@wingspanhq/fe-component-library";

export interface CardSuspendedProps {
  onClose: () => void;
}

const PINChanged: React.FC<CardSuspendedProps> = ({ onClose }) => {
  return (
    <WSList gap="L">
      <WSAlert theme="success">Your PIN was updated</WSAlert>
      <WSInfoBox>Manage your card from your Wingspan Wallet page.</WSInfoBox>
      <WSDivider />
      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            onClick: async () => {
              onClose();
            },
            kind: "Primary"
          }
        ]}
      ></WSActions>
    </WSList>
  );
};

export function usePINChangedModal() {
  return useWSModal(PINChanged, {
    title: "PIN updated",
    size: "S"
  });
}
