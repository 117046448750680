import { WSButton, WSText, useWSModal } from "@wingspanhq/fe-component-library";
import { useWSPlaidLink } from "../../../../utils/usePlaidLink";

type Props = {
  token: string;
  onClose: (success?: boolean) => void;
};

const ModalReconnectAccount: React.FC<Props> = ({ token, onClose }) => {
  const plaid = useWSPlaidLink({
    token,
    onSuccess: () => {
      onClose(true);
    }
  });

  return (
    <>
      <WSText mb="XL">We need to reconnect your bank account</WSText>

      <WSButton
        fullWidth
        onClick={() => {
          plaid.open();
        }}
      >
        Reconnect
      </WSButton>
    </>
  );
};

export const useModalReconnectAccount = () =>
  useWSModal(ModalReconnectAccount, { size: "S" });
