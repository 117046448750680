import { NotificationTemplate } from "../index";

export const PayoutsInfo: NotificationTemplate<never> = {
  renderTitle: props => "Payout method missing",
  renderBody: props => {
    return "You must add a payout method in order to receive payouts";
  },
  buttonAction: (props, history) => () => {
    return history.push("/member/settings/payment-methods");
  },
  buttonText: () => "Add payout method"
};
