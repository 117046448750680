import {
  WSAvatar,
  WSElement,
  WSElementProps,
  WSIcon,
  WSImage
} from "@wingspanhq/fe-component-library";
import React from "react";
import { creditCardLogoMap, debitCardLogoMap } from "./constants";
import styles from "./index.module.scss";

type Props = {
  type: "credit" | "debit";
  brand?: string;
  asAvatar?: boolean;
} & Omit<WSElementProps, "type">;

type Component = React.FC<Props> & {
  Circle: React.FC<Props>;
};

export const BankCardLogo: Component = ({
  type,
  brand,
  asAvatar,
  ...elementProps
}) => {
  if (!brand) return null;

  let logoData =
    type === "credit"
      ? creditCardLogoMap[brand.toLowerCase()]
      : debitCardLogoMap[brand];

  if (!logoData) return null;

  if (asAvatar) {
    if (logoData.src) {
      return (
        <WSAvatar
          type="image"
          colorBackground="gray50"
          image={logoData.src}
          {...elementProps}
        />
      );
    } else {
      return (
        <WSAvatar
          type="icon"
          colorBackground="gray50"
          icon="card"
          color="gray600"
          {...elementProps}
        />
      );
    }
  }

  return (
    <WSImage
      src={logoData.src}
      placeholder={<WSIcon name="card" color="gray600" />}
      {...elementProps}
    />
  );
};

const Circle: React.FC<Props> = ({ type, brand, ...elementProps }) => (
  <WSElement className={styles.creditCardLogoCircle} {...elementProps}>
    <BankCardLogo type={type} brand={brand} />
  </WSElement>
);

BankCardLogo.Circle = Circle;
