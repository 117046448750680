import classNames from "classnames";
import React from "react";
import {
  WSElement,
  WSElementColorsType
} from "../WSElement/WSElement.component";
import { WSAvatar, WSAvatarProps, WSPill, WSPillProps } from "../common";
import { WSIcon, WSIconName } from "../core/WSIcon/WSIcon.component";
import { WSText } from "../core/WSText/WSText.component";
import styles from "./WSTableCell.module.scss";

type Props = {
  text?: string;
  secondaryText?: string;
  secondaryTextColor?: WSElementColorsType;
  icon?: WSIconName;
  avatar?: WSAvatarProps;
  pill?: WSPillProps;
  truncationOptions?: {
    text?: {
      allowTwoLines?: boolean;
    };
  };
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export const WSTableCell: React.FC<Props> = ({
  text,
  secondaryText,
  secondaryTextColor,
  icon,
  avatar,
  pill,
  truncationOptions,
  onClick
}) => {
  if (!text && !secondaryText && !icon && !avatar && !pill) {
    return (
      <WSElement className={styles.tableCell} onClick={onClick}>
        <WSText.ParagraphSm>–</WSText.ParagraphSm>
      </WSElement>
    );
  }

  const textElement = text && (
    <WSText.ParagraphSm
      className={classNames(
        styles.text,
        truncationOptions?.text?.allowTwoLines && styles.twoLines
      )}
    >
      {text}
    </WSText.ParagraphSm>
  );
  const secondaryTextElement = secondaryText && (
    <WSText.ParagraphXs
      className={classNames(styles.secondaryText, styles.twoLines)}
      color={secondaryTextColor || "gray400"}
    >
      {secondaryText}
    </WSText.ParagraphXs>
  );
  const iconElement = icon && <WSIcon name={icon} size="M" color="gray500" />;
  const avatarElement = avatar && <WSAvatar {...avatar} size="S" />;
  const pillElement = pill && (
    <WSPill {...pill} truncate className={styles.pill} />
  );

  return (
    <WSElement className={styles.tableCell} onClick={onClick}>
      {avatar ? avatarElement : icon ? iconElement : null}
      <WSElement className={styles.main}>
        {pillElement}
        {textElement}
        {secondaryTextElement}
      </WSElement>
      {avatar && icon ? iconElement : null}
    </WSElement>
  );
};
