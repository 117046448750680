import { WSModal, WSText } from "@wingspanhq/fe-component-library";

// Summary section modals

export const LINE_ITEMS_COUNT_MODAL = "LINE_ITEMS_COUNT_MODAL";
export function LineItemsCountModal() {
  return (
    <WSModal name={LINE_ITEMS_COUNT_MODAL} size="S" title="Line items count">
      <WSText.ParagraphSm mb="M">
        Total number of positive line items.
      </WSText.ParagraphSm>

      <WSText.ParagraphSm weight="medium" inline>
        New payables:{" "}
      </WSText.ParagraphSm>
      <WSText.ParagraphSm mb="M">
        Payables are invoices for you to pay. When you upload a spreadsheet
        containing payment items and the recipient's ID, our system will convert
        this data into payables. By importing payables in bulk, you can avoid
        manual data entry.
      </WSText.ParagraphSm>

      <WSText.ParagraphSm weight="medium" inline>
        Existing payables:{" "}
      </WSText.ParagraphSm>
      <WSText.ParagraphSm>
        If an imported item matches the due date and recipient of an existing
        payable in our system, it will be added to that payable as a line item
        instead of creating a new payable. We only do this if the merge strategy
        is set to merge, which is currently the only option. In the future, we
        will allow the option to create a new payable for each imported line
        item.
      </WSText.ParagraphSm>
    </WSModal>
  );
}

export const NEW_PAYABLES_MODAL = "NEW_PAYABLES_MODAL";
export function NewPayablesModal() {
  return (
    <WSModal name={NEW_PAYABLES_MODAL} size="S" title="New payables">
      <WSText.ParagraphSm mt="M">
        Payables are invoices for you to pay. When you upload a spreadsheet
        containing payment items and the recipient's ID, our system will convert
        this data into payables. By importing payables in bulk, you can avoid
        manual data entry.
      </WSText.ParagraphSm>
    </WSModal>
  );
}

export const NEW_PAYABLES_AMOUNT_MODAL = "NEW_PAYABLES_AMOUNT_MODAL";
export function NewPayablesAmountModal() {
  return (
    <WSModal
      name={NEW_PAYABLES_AMOUNT_MODAL}
      size="S"
      title="New payables amount"
    >
      <WSText.ParagraphSm mt="M">
        The total dollar amount of all new payables before deductions. This
        amount does not included line items added to existing payables as part
        of an import.
      </WSText.ParagraphSm>
    </WSModal>
  );
}

export const EXISTING_PAYABLES_UPDATED_MODAL =
  "EXISTING_PAYABLES_UPDATED_MODAL";
export function ExistingPayablesUpdatedModal() {
  return (
    <WSModal
      name={EXISTING_PAYABLES_UPDATED_MODAL}
      size="S"
      title="Existing payables updated"
    >
      <WSText.ParagraphSm mt="M">
        If an imported item matches the due date and recipient of an existing
        payable in our system, it will be added to that payable as a line item
        instead of creating a new payable. We only do this if the merge strategy
        is set to merge, which is currently the only option. In the future, we
        will allow the option to create a new payable for each imported line
        item.
      </WSText.ParagraphSm>
    </WSModal>
  );
}

export const AMOUNT_ADDED_TO_EXISTING_PAYABLES_MODAL =
  "AMOUNT_ADDED_TO_EXISTING_PAYABLES_MODAL";
export function AmountAddedToExistingPayablesModal() {
  return (
    <WSModal
      name={AMOUNT_ADDED_TO_EXISTING_PAYABLES_MODAL}
      size="S"
      title="Amount added to existing payables"
    >
      <WSText.ParagraphSm mt="M">
        The total dollar amount of all items merged with existing payables
        sharing the same due date and recipient.
      </WSText.ParagraphSm>
    </WSModal>
  );
}

export const DEDUCTIONS_CREATED = "DEDUCTIONS_CREATED";
export function DeductionsCreatedModal() {
  return (
    <WSModal name={DEDUCTIONS_CREATED} size="S" title="Deductions created">
      <WSText.ParagraphSm mt="M">
        When you import an item with an amount that is less than zero, a
        deduction is created. This value will be subtracted from upcoming
        payments to the recipient.
      </WSText.ParagraphSm>
    </WSModal>
  );
}

export const TOTAL_ITEMS_IMPORTED_MODAL = "TOTAL_ITEMS_IMPORTED_MODAL";
export function TotalItemsImportedModal() {
  return (
    <WSModal
      name={TOTAL_ITEMS_IMPORTED_MODAL}
      size="S"
      title="Total items imported"
    >
      <WSText.ParagraphSm mt="M">
        In addition to new payables, this count includes deductions and any line
        items added to existing payables.
      </WSText.ParagraphSm>
    </WSModal>
  );
}

// Details section modals

export const ORIGINAL_SPREADSHEET_MODAL = "ORIGINAL_SPREADSHEET_MODAL";

export function OriginalSpreadsheetModal() {
  return (
    <WSModal
      name={ORIGINAL_SPREADSHEET_MODAL}
      size="S"
      title="Original spreadsheet"
    >
      <WSText.ParagraphSm mt="M">
        This spreadsheet contains the information for all payables, line items,
        and deductions included in this import. Any errors that detecting during
        the import will appear after each affected row.
      </WSText.ParagraphSm>
    </WSModal>
  );
}

export const IMPORT_STRATEGY_MODAL = "IMPORT_STRATEGY_MODAL";

export function ImportStrategyModal() {
  return (
    <WSModal name={IMPORT_STRATEGY_MODAL} size="S" title="Import strategy">
      <WSText.ParagraphSm mt="M">
        <WSText.ParagraphSm weight="medium" inline>
          Merge:{" "}
        </WSText.ParagraphSm>
        <WSText.ParagraphSm inline mb="XL">
          If an imported item matches the due date and recipient of an existing
          payable in our system, it will be added to that payable as a line item
          instead of creating a new payable. This strategy simplifies payments
          by grouping items that go to the same recipient at the same time.
        </WSText.ParagraphSm>
      </WSText.ParagraphSm>
      <WSText.ParagraphSm mt="M">
        <WSText.ParagraphSm weight="medium" mt="XL" inline>
          Separate:{" "}
        </WSText.ParagraphSm>
        <WSText.ParagraphSm inline color="gray400">
          (currently unavailable) All items will be imported as new payables,
          regardless of existing payables. This strategy separates each item
          imported as a separate payable.
        </WSText.ParagraphSm>
      </WSText.ParagraphSm>
    </WSModal>
  );
}

export const DUPLICATE_PREVENTION_KEY_MODAL = "DUPLICATE_PREVENTION_KEY_MODAL";

export function DuplicatePreventionKeyModal() {
  return (
    <WSModal
      name={DUPLICATE_PREVENTION_KEY_MODAL}
      size="S"
      title="Duplicate prevention key"
    >
      <WSText.ParagraphSm mt="M">
        When you create an invoice or payable, we generate a duplicate
        prevention key called an idempotency key. This key ensures that if the
        invoice creation process is interrupted or if the same request is made
        multiple times, our system will only process the request once.
      </WSText.ParagraphSm>
      <WSText.ParagraphSm mt="M">
        In other words, if you accidentally click the "Send invoice" button
        twice due to a network error, the key will prevent duplicate invoices
        from being created.
      </WSText.ParagraphSm>
    </WSModal>
  );
}

export const INITIAL_IMPORT_STATUS_MODAL = "INITIAL_IMPORT_STATUS_MODAL";

export function InitialImportStatusModal() {
  return (
    <WSModal
      name={INITIAL_IMPORT_STATUS_MODAL}
      size="S"
      title="Initial status of created payables"
    >
      <WSText.ParagraphSm mt="M">
        When you import payables, you select one of four statuses:
        <WSText.ParagraphSm weight="medium" mt="XL">
          Draft
        </WSText.ParagraphSm>
        <WSText.ParagraphSm mb="XL">
          Hidden to contractors until opened
        </WSText.ParagraphSm>
        <WSText.ParagraphSm weight="medium">Open</WSText.ParagraphSm>
        <WSText.ParagraphSm mb="XL">
          Visible to contractors but will not be paid until approved.
        </WSText.ParagraphSm>
        <WSText.ParagraphSm weight="medium">Approved</WSText.ParagraphSm>
        <WSText.ParagraphSm mb="XL">
          Automatically paid on the next payroll date.
        </WSText.ParagraphSm>
        <WSText.ParagraphSm weight="medium">
          Paid (historical import)
        </WSText.ParagraphSm>
        <WSText.ParagraphSm mb="XL">
          Visible to recipient and marked as paid off platform (outside of
          Wingspan).
        </WSText.ParagraphSm>
      </WSText.ParagraphSm>
    </WSModal>
  );
}

export function InfoModals() {
  return (
    <>
      <LineItemsCountModal />
      <NewPayablesModal />
      <NewPayablesAmountModal />
      <ExistingPayablesUpdatedModal />
      <AmountAddedToExistingPayablesModal />
      <DeductionsCreatedModal />
      <TotalItemsImportedModal />
      <OriginalSpreadsheetModal />
      <ImportStrategyModal />
      <DuplicatePreventionKeyModal />
      <InitialImportStatusModal />
    </>
  );
}
