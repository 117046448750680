import React, { useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import LabelValueList from "../../../../components/LabelValueList";
import { WSButton, WSFlexBox } from "@wingspanhq/fe-component-library";
import { getFailedBatchItemsDisplayErrors } from "../../utils/getFailedBatchItemsDisplayErrors";
import { BulkResource } from "../../types";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";

export function BulkBatchProcessingErrors({
  bulkResource
}: {
  bulkResource: BulkResource;
}) {
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const [showAll, setShowAll] = useState(false);
  const DEFAULT_INITIAL_DISPLAY_ITEMS_COUNT = 4;
  const { useAllBulkBatchItems } = getBulkQueriesByResource(bulkResource);
  const queryAllFailedBulkBatchItems = useAllBulkBatchItems(bulkBatchId, {
    filter: {
      status: BulkStatus.Failed
    }
  });

  const failedBatchItems = useMemo(
    () =>
      getFailedBatchItemsDisplayErrors(queryAllFailedBulkBatchItems.data ?? []),
    [queryAllFailedBulkBatchItems.data]
  );
  const displayErrors = showAll
    ? failedBatchItems
    : failedBatchItems.slice(0, DEFAULT_INITIAL_DISPLAY_ITEMS_COUNT);

  return (
    <>
      <LabelValueList rowSpace="S" items={displayErrors} />
      {failedBatchItems.length > 1 &&
      failedBatchItems.length > DEFAULT_INITIAL_DISPLAY_ITEMS_COUNT ? (
        <WSFlexBox.Center>
          <WSButton.Link
            mt="5XL"
            onClick={() => {
              setShowAll(showAll => !showAll);
            }}
          >
            {showAll ? "View less" : `Show all ${failedBatchItems.length}`}
          </WSButton.Link>
        </WSFlexBox.Center>
      ) : null}
    </>
  );
}
