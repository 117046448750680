import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPaymentCard } from "@wingspanhq/payments/dist/interfaces";
import capitalize from "lodash/capitalize";
import React from "react";
import { BankCardLogo } from "../../../shared/components/BankCardLogo";

type Props = {
  paymentCard: IPaymentCard;
} & WSElementProps;

export const PaymentCard: React.FC<Props> = ({
  paymentCard,
  ...elementProps
}) => (
  <WSElement {...elementProps}>
    <WSFlexBox wrap="nowrap" alignItems="center">
      <BankCardLogo asAvatar type="credit" brand={paymentCard.brand} mr="M" />

      <WSText color="gray600" weight="medium" mr="M">
        {capitalize(paymentCard.brand)}
      </WSText>

      <WSText.Paragraph color="gray500" mr="M">
        {capitalize(paymentCard.cardType)} ({paymentCard.mask})
      </WSText.Paragraph>

      <WSText.Paragraph color="gray500">
        Exp {paymentCard.expirationMonth}/{paymentCard.expirationYear}
      </WSText.Paragraph>
    </WSFlexBox>
  </WSElement>
);
