import { IPayerEngagementListResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { RequirementsTable } from "../../../Requirements/components/RequirementsTable";
import { RequirementContextType } from "../../../Requirements/types";
import { selectorUniqRequirementsFromEngagementsList } from "../../../Requirements/selectors/selectorUniqRequirementsFromEngagementsList";

export const PayerRequirements: React.FC<{
  engagements: IPayerEngagementListResponse;
  payerId: string;
  payeeId: string;
}> = ({ engagements, payeeId, payerId }) => {
  const requirements = selectorUniqRequirementsFromEngagementsList(engagements);

  return (
    <RequirementsTable
      contextType={RequirementContextType.Payer}
      requirements={requirements}
      payeeId={payeeId}
      payerId={payerId}
    />
  );
};
