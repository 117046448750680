import React from "react";
import { useFormContext } from "react-hook-form";
import { WSButton, WSButtonProps } from "../core/WSButton/WSButton.component";

export type WSFormSubmitButtonProps = WSButtonProps;

export const WSFormSubmitButton: React.FC<WSFormSubmitButtonProps> = ({
  loading,
  type,
  ...props
}) => {
  const { formState } = useFormContext();

  return <WSButton loading={formState.isSubmitting} type="submit" {...props} />;
};
