import {
  useIsDesktop,
  WSBreadcrumbs,
  WSButton,
  WSButtons,
  WSContainer,
  WSCopyButton,
  WSElement,
  WSElementProps,
  WSGrid,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { PAYERS_PATH } from "../../modules/Payers/routes/RouteClients";
import { useGetMemberPublicFile } from "../../query/files/queries";
import { useUserId } from "../../query/hooks/helpers";
import { useUserProfile } from "../../query/users/queries";
import { WSQueries } from "../../query/WSQuery";
import { copyText } from "../../utils/copyText";
import { createPPL } from "../utils";
import { PersonalPayLinkOverview } from "./PersonalPayLinkOverview";

type CompanyLogoProps = {
  id: string;
} & WSElementProps;

export const CompanyLogo: React.FC<CompanyLogoProps> = ({
  id,
  ...elementProps
}) => {
  const qFile = useGetMemberPublicFile(id);

  if (qFile.data?.url) {
    return (
      <WSElement {...elementProps}>
        <img
          style={{
            maxHeight: 50,
            maxWidth: 80,
            display: "block"
          }}
          src={qFile.data.url}
        />
      </WSElement>
    );
  }

  return null;
};

export const PersonalPayLinkDashboard: React.FC = () => {
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const memberId = useUserId();
  const qUserProfile = useUserProfile(memberId);

  useBrowserPageTitle("Personal Pay Link - Dashboard");

  return (
    <WSQueries
      queries={{
        userData: qUserProfile
      }}
    >
      {({ userData }) => {
        return (
          <>
            {/* TODO: What should be top alignment for this page? */}
            <WSContainer verticalPadding>
              <WSElement mt={isDesktop ? "XL" : undefined} />
              <WSGrid>
                <WSGrid.Item span={{ m: "6" }}>
                  <WSBreadcrumbs
                    items={[
                      {
                        label: "Clients",
                        onClick: () => {
                          history.push(PAYERS_PATH);
                        }
                      },
                      {
                        label: "Pay link"
                      }
                    ]}
                  />
                </WSGrid.Item>
                <WSGrid.Item span={{ m: "6" }}>
                  <WSButtons mb="S">
                    <WSButton.Secondary
                      size="M"
                      mt={isDesktop ? undefined : "S"}
                      fullWidth
                      onClick={() => history.push("/member/invoices")}
                    >
                      View&nbsp;payments
                    </WSButton.Secondary>

                    <WSCopyButton
                      size="M"
                      isURL
                      data={createPPL(userData.data.tag)}
                      copyLabel={"Copy\xA0my\xA0wingspan.me\xA0link"}
                      shareLabel={"Share\xA0my\xA0wingspan.me\xA0link"}
                      shareTitle="My wingspan.me link"
                    />
                  </WSButtons>

                  <WSText.Paragraph
                    weight="book"
                    mt="XS"
                    align={isDesktop ? "left" : "center"}
                    color="gray400"
                    onClick={async () => {
                      await copyText(createPPL(userData.data.tag), "Copied!");
                    }}
                  >
                    {createPPL(userData.data.tag)}
                  </WSText.Paragraph>
                </WSGrid.Item>
              </WSGrid>
            </WSContainer>
            <PersonalPayLinkOverview isMore={true} />
          </>
        );
      }}
    </WSQueries>
  );
};
