import {
  useWSModal,
  WSButton,
  WSElement,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { openInNewTab } from "../../../shared/utils/openInNewTab";

type Props = {
  onClose: () => void;
};

const TaxFormRequirementInfoModal: React.FC<Props> = ({ onClose }) => {
  return (
    <WSList gap="M">
      <WSText weight="medium">W-9</WSText>
      <WSText.ParagraphSm>
        For U.S. citizens or other U.S. persons, Wingspan collects W-9
        information from your contractors before they are eligible for payment.
        Your contractors are required to certify, under penalty of perjury, that
        the information provided for Form W-9 is accurate.
      </WSText.ParagraphSm>
      <WSElement>
        <WSButton.Link
          mb="M"
          onClick={() => {
            openInNewTab(
              "https://www.irs.gov/businesses/small-businesses-self-employed/forms-and-associated-taxes-for-independent-contractors"
            );
          }}
        >
          Learn more about W-9
        </WSButton.Link>
      </WSElement>

      <WSText weight="medium">W-8 BEN</WSText>
      <WSText mb="M">
        For foreign persons, Wingspan collects W-8 BEN information from your
        contractors before they are eligible for payment.
      </WSText>
      <WSElement>
        <WSButton.Link
          onClick={() => {
            openInNewTab("https://www.irs.gov/forms-pubs/about-form-w-8-ben");
          }}
        >
          Learn more about W-8
        </WSButton.Link>
      </WSElement>
    </WSList>
  );
};

export const useTaxFormRequirementInfoModal = () =>
  useWSModal(TaxFormRequirementInfoModal, {
    title: "Tax forms",
    size: "S"
  });
