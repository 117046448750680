import { IActivity } from "@wingspanhq/users";
import { operations } from "../../services/api/onboarding/types";

export const selectorIsOnboardingComplete = (
  activity?: IActivity,
  verifications?: operations["getVerifications"]["responses"]["200"]["content"]["application/json"]
) =>
  (activity?.flows.paymentsSetup &&
    activity.flows.paymentsSetup.currentStep &&
    (activity.flows.paymentsSetup.currentStep >= 3 ||
      activity.flows.paymentsSetup.complete)) ||
  (verifications?.tax && verifications.tax !== "None");
