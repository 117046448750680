import { WSDivider, WSGrid, WSPage } from "@wingspanhq/fe-component-library";
import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { GetPaid } from "./components/GetPaid";
import { PayoutSplits } from "./components/PayoutSplits";
import { SendPayments } from "./components/SendPayments";
import { WingspanAccounts } from "./components/WingspanAccounts";
import { RouteAdd } from "./routes/RouteAdd";
import { RouteAddInstant } from "./routes/RouteAddInstant";
import { RouteAddInstantDebitCard } from "./routes/RouteAddInstantDebitCard";
import { RouteAddInstantManually } from "./routes/RouteAddInstantManually";
import { RouteAddInstantPlaid } from "./routes/RouteAddInstantPlaid";
import { RouteAddInternational } from "./routes/RouteAddInternational";
import { RouteAddInternationalManually } from "./routes/RouteAddInternationalManually";
import { RouteAddStandard } from "./routes/RouteAddStandard";
import { RouteAddStandardManually } from "./routes/RouteAddStandardManually";
import { RouteAddStandardPlaid } from "./routes/RouteAddStandardPlaid";
import { RouteEdit } from "./routes/RouteEdit";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";

import { ManagedPaymentSettings } from "./components/ManagedPaymentSettings";
import { usePaymentsSettingsManagedByOrganization } from "../../userFeatures/usePaymentsSettingsManagedByOrganization";

const Index = () => {
  const featureFlags = useFeatureFlags();
  const hasManagedPayments = !!usePaymentsSettingsManagedByOrganization();

  if (hasManagedPayments) {
    return (
      <WSPage title="Payment Method">
        <WSGrid gutter={{ m: "3XL" }}>
          <WSGrid.Item span={{ m: "7" }}>
            <ManagedPaymentSettings />
          </WSGrid.Item>
        </WSGrid>
      </WSPage>
    );
  }
  return (
    <WSPage title="Payout & Payment Methods">
      <WSGrid gutter={{ m: "3XL" }}>
        <WSGrid.Item span={{ m: "7" }}>
          <GetPaid mb="3XL" />
          <SendPayments />
        </WSGrid.Item>
        <WSGrid.Item span={{ m: "5" }}>
          {!featureFlags.data?.accountPause && <PayoutSplits />}
          <WSDivider my="XL" />
          <WingspanAccounts />
        </WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};

export const PaymentMethods: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/add`} component={RouteAdd} />
      <Route path={`${match.url}/add-standard`} component={RouteAddStandard} />
      <Route
        path={`${match.url}/add-standard-manually`}
        component={RouteAddStandardManually}
      />
      <Route
        path={`${match.url}/add-standard-plaid`}
        component={RouteAddStandardPlaid}
      />
      <Route path={`${match.url}/add-instant`} component={RouteAddInstant} />
      <Route
        path={`${match.url}/add-instant-manually`}
        component={RouteAddInstantManually}
      />
      <Route
        path={`${match.url}/add-instant-debit-card`}
        component={RouteAddInstantDebitCard}
      />
      <Route
        path={`${match.url}/add-instant-plaid`}
        component={RouteAddInstantPlaid}
      />
      <Route path={`${match.url}/edit`} component={RouteEdit} />

      <Route
        path={`${match.url}/add-international`}
        component={RouteAddInternational}
      />
      <Route
        path={`${match.url}/add-international-manually`}
        component={RouteAddInternationalManually}
      />

      <Route path={match.url} component={Index} exact />
    </Switch>
  );
};
