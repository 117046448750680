import { IMember, INewUser } from "@wingspanhq/users/dist/lib/interfaces";
import { FormPartialDataPerson } from ".";
import { getProfileDataAddress } from "../FormPartialAddress";
import { emptyValues } from "./emptyValues";

export function getProfileDataPerson(
  user?: INewUser,
  member?: IMember
): Partial<FormPartialDataPerson> {
  return {
    country: member?.profile.address?.country || "US",
    firstName: user?.profile.firstName,
    lastName: user?.profile.lastName,
    birthday: user?.profile.dob,
    address: { ...emptyValues.address, ...getProfileDataAddress(member) }
  };
}
