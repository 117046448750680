import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { getFsExcludeClassName } from "../../../utils/getFsExcludeClassName";
import { getInputTestIdByName } from "../../../utils/getInputTestIdByName";
import { WSInputBaseProps } from "../../types/forms";
import { detachFieldProps, WSField, WSFieldProps } from "../WSField/WSField";
import {
  WSInputContainer,
  WSInputContainerProps
} from "../WSInputContainer/WSInputContainer";

export type WSInputPasswordProps = WSInputBaseProps &
  Omit<WSInputContainerProps, "align" | "size" | "inputAction"> &
  Omit<WSFieldProps, "action"> & {
    minLength?: number;
    maxLength?: number;
  };

export const WSInputPassword = React.forwardRef<
  HTMLInputElement,
  WSInputPasswordProps
>((props, ref) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const {
    name,
    status,
    className,
    icon,
    iconRight,
    disabled,
    onChange,
    inputClassName,
    fsExclude,
    ...otherProps
  } = props;

  const { fieldProps, ...inputProps } = detachFieldProps(otherProps);

  return (
    <WSField {...fieldProps} status={status}>
      <WSInputContainer
        status={status}
        icon={icon}
        iconRight={iconRight}
        inputAction={{
          icon: passwordVisible ? "hide" : "view",
          onClick: () => {
            setPasswordVisible(!passwordVisible);
          }
        }}
        disabled={disabled}
        className={className}
      >
        <InputText
          ref={ref}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value, e)}
          type={passwordVisible ? "text" : "password"}
          className={classNames(
            inputClassName,
            getFsExcludeClassName(fsExclude)
          )}
          data-testid={getInputTestIdByName(name)}
          {...inputProps}
        />
      </WSInputContainer>
    </WSField>
  );
});

WSInputPassword.displayName = "WSInputPassword";
