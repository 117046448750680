import {
  CompanyStructure,
  IMember
} from "@wingspanhq/users/dist/lib/interfaces";
import { FormDataBusinessInformation } from ".";
import { DeepPartial } from "../../../../utils";
import { FederalTaxClassification } from "../../types";
import { getProfileDataAddress } from "../FormPartialAddress";

const taxClassificationMap: Record<
  CompanyStructure,
  FederalTaxClassification
> = {
  [CompanyStructure.None]: "SoleProprietorship",
  [CompanyStructure.SoleProprietorship]: "SoleProprietorship",
  [CompanyStructure.LlcSingleMember]: "LlcSingleMember",
  [CompanyStructure.LlcMultiMember]: "LlcPartnership",
  [CompanyStructure.CorporationS]: "CorporationS",
  [CompanyStructure.LLCCorporationS]: "LlcCorporationS",
  [CompanyStructure.LLCCorporationC]: "LlcCorporationC",
  [CompanyStructure.LLCPartnership]: "LlcPartnership",
  [CompanyStructure.CorporationC]: "CorporationC",
  [CompanyStructure.Partnership]: "Partnership"
};

export function getProfileDataBusiness(
  member?: IMember
): DeepPartial<FormDataBusinessInformation> {
  return {
    legalBusinessName: member?.profile.company?.legalBusinessName,
    taxId: member?.profile.company?.taxId,
    taxClassification: member?.profile.company?.structure
      ? taxClassificationMap[member?.profile.company?.structure]
      : undefined,
    address: getProfileDataAddress(member)
  };
}
