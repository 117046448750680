import {
  ITaxFormEvent,
  ITaxFormSubmissionData,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces/taxForm";
import {
  IRedactedMember,
  ITinVerification
} from "@wingspanhq/users/dist/lib/interfaces";

import { serviceSearch } from "../service";

export interface ITaxFormRowSearchParams {
  luceneQueryString?: string;
  searchString?: string;
  expandOrganizationsForUserId?: string;
}

// TODO: clean up this interface
export interface ITaxFormSearchRow {
  data: ITaxFormSubmissionData;
  eventHistory: ITaxFormEvent[];
  memberEmail: string;
  memberExternalId: string;
  payee: IRedactedMember;
  payer: IRedactedMember;
  status: TaxFormStatus;
  taxFormId: string;
  tinVerification?: ITinVerification;
}

export const getTaxFormRows = async (
  searchParams: ITaxFormRowSearchParams,
  params?: any
): Promise<{
  data: ITaxFormSearchRow[];
  summary: { listSize: number };
}> => {
  const {
    luceneQueryString,
    searchString,
    expandOrganizationsForUserId
  } = searchParams;
  const { data, headers } = await serviceSearch.get(`/tax-form-row`, {
    params: {
      ...(searchString ? { search: `${searchString}*` } : {}),
      ...(luceneQueryString ? { match: luceneQueryString } : {}),
      ...params
    },
    ...(expandOrganizationsForUserId
      ? {
          headers: {
            "x-wingspan-expansion": expandOrganizationsForUserId
          }
        }
      : {})
  });

  return { data, summary: { listSize: Number(headers["x-ws-list-size"]) } };
};
