import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSImage,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useEffect, useState } from "react";
import smile from "../../../assets/images/party-smile.png";
import { paymentsService } from "../../../services/payments";

type Props = WSElementProps;

export const FirstInvoiceBanner: React.FC<Props> = ({ ...elementProps }) => {
  const [hasOneInvoice, setHasOneInvoice] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const [invoices, invoiceTemplates] = await Promise.all([
        paymentsService.invoice.listSize(),
        paymentsService.invoiceTemplate.listSize()
      ]);

      setHasOneInvoice(invoices + invoiceTemplates === 1);
    };

    fetch();
  }, []);

  if (!hasOneInvoice) {
    return null;
  }

  return (
    <WSElement {...elementProps}>
      <WSMessageBox noBorder>
        <WSFlexBox.Center>
          <WSImage src={smile} placeholder={<></>} width={16} />
          <WSText ml="M">You just sent your first invoice!!</WSText>
        </WSFlexBox.Center>
      </WSMessageBox>
    </WSElement>
  );
};
