import {
  WSCheckboxToggle,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import * as React from "react";
import { useUserId } from "../../query/hooks/helpers";
import { useUpdateUserProfile } from "../../query/users/mutations";
import { useUserProfile } from "../../query/users/queries";
import { WSQuery } from "../../query/WSQuery";

export interface NotificationsTabProps {}

export const NotificationsTab: React.FC<NotificationsTabProps> = () => {
  const userId = useUserId();
  const userQuery = useUserProfile(userId);

  const [updateUserProfile] = useUpdateUserProfile();
  const onchangeTxnReviewReminders = async (value: any) => {
    await updateUserProfile({
      notificationSettings: {
        reviewNotifications: value
      }
    });
  };
  const onchangeNewsletters = async (value: any) => {
    await updateUserProfile({
      notificationSettings: {
        newsletters: value
      }
    });
  };
  const content = (
    <WSQuery query={userQuery}>
      {({ data: user }) => {
        return (
          <WSFlexBox direction="column">
            <WSText.ParagraphSm mb="M" color="gray500">
              Email notifications
            </WSText.ParagraphSm>
            <WSFormOld
              defaultValues={{
                txnReviewReminders:
                  user.notificationSettings?.reviewNotifications === false
                    ? false
                    : true,
                newsletters:
                  user.notificationSettings?.newsletters === false
                    ? false
                    : true
              }}
            >
              <WSFormOld.Field
                name="txnReviewReminders"
                component={WSCheckboxToggle}
                componentProps={{
                  label: "Weekly transaction review reminders",
                  onChange: onchangeTxnReviewReminders
                }}
                mb="M"
              />
              <WSFormOld.Field
                name="newsletters"
                component={WSCheckboxToggle}
                componentProps={{
                  label: "Helpful articles and tips about freelancing",
                  onChange: onchangeNewsletters
                }}
                mb="M"
              />
            </WSFormOld>
          </WSFlexBox>
        );
      }}
    </WSQuery>
  );
  return (
    <WSPage title="Notifications">
      <WSGrid>
        <WSGrid.Item span={{ m: "10" }}>{content}</WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
