export const formatPhone = (string: string) => {
  if (typeof string !== "string") return "";

  const str = string.trim();
  if (!str) return "";

  const isUSCountryCode = str.startsWith("+1");
  const cleaned = isUSCountryCode
    ? str.substring(2).replace(/\D/g, "")
    : str.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const localUSFormattedCode = `(${match[1]}) ${match[2]}-${match[3]}`;

    return isUSCountryCode
      ? `+1 ${localUSFormattedCode}`
      : localUSFormattedCode;
  }

  return str;
};
