import React from "react";
import {
  getClientNameFromContext,
  getDocumentNameFromContext,
  NotificationTemplate
} from "../index";
import { useHistory } from "react-router-dom";
import { useSignDocument } from "../../../../../query/files/mutations";
import { WSButton } from "@wingspanhq/fe-component-library";

export type SignatureCollaboratorContext = {
  document: {
    documentId: string;
    title: string;
  };
  client: {
    clientId: string;
    companyName: string;
    logoFileId: string;
  };
  memberClient: {
    memberClientId: string;
  };
};
export const SignatureCollaborator: NotificationTemplate<SignatureCollaboratorContext> = {
  renderTitle: props => "E-signatures required",
  renderBody: props => {
    const clientName = getClientNameFromContext(props.context);
    const documentName = getDocumentNameFromContext(props.context);

    if (props.group.length > 1) {
      return "Your clients require that you sign documents to complete the onboarding process";
    }

    return (
      <>
        Company <b>{clientName}</b> requires that you sign <b>{documentName}</b>{" "}
        to complete the onboarding process
      </>
    );
  },
  Footer: props => {
    const history = useHistory();
    const [signDocument] = useSignDocument();
    const documentName = getDocumentNameFromContext(props.context);

    if (props.group.length > 1) {
      return (
        <WSButton.Link
          size="S"
          rightIcon="chevron-right"
          onClick={() => {
            history.push("/member/dashboard/collaborators-e-signatures");
          }}
        >
          Sign documents
        </WSButton.Link>
      );
    } else {
      return (
        <WSButton.Link
          size="S"
          rightIcon="chevron-right"
          onAsyncClick={async () => {
            await signDocument({
              documentId: props.context?.document?.documentId,
              memberClientId: props.context?.memberClient?.memberClientId
            });
          }}
        >
          Sign <b>{documentName}</b>
        </WSButton.Link>
      );
    }
  }
};
