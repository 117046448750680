import {
  WSFlexBox,
  WSIcon,
  WSText,
  toWSDateString,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import {
  InvoiceStatus,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import addDays from "date-fns/addDays";
import { Tooltip } from "../../../../components/Tooltip";
import {
  getIsInvoiceAccepted,
  getIsInvoiceCreator,
  getIsInvoiceRejected,
  getIsInvoiceResubmitted
} from "../../../../query/payments/selectors";
import { addBusinessDays, isSameDate } from "../../../../utils/dates";
import { IInvoiceRow } from "../../service";

type Props = {
  invoice: IInvoiceRow;
};

export const InvoiceRecentUpdate: React.FC<Props> = ({ invoice }) => {
  return invoice.oldStatus === InvoiceStatus.Draft ? (
    isSameDate(invoice.createdAt, invoice.updatedAt) ? (
      <WSText.ParagraphXs color="gray500">
        Created {toWSDateString(invoice.createdAt, "monthDate")}
      </WSText.ParagraphXs>
    ) : (
      <WSText.ParagraphXs color="gray500">
        Last updated {toWSDateString(invoice.updatedAt, "monthDate")}
      </WSText.ParagraphXs>
    )
  ) : invoice.oldStatus === InvoiceStatus.Paid ? (
    invoice.events.depositedAt ? (
      <WSText.ParagraphXs color="gray500">
        {toWSMoneyString(Math.abs(invoice.amountDetails?.memberGross || 0))}{" "}
        arrives by{" "}
        {toWSDateString(
          addBusinessDays(invoice.events.depositedAt, 1),
          "monthDate"
        )}
      </WSText.ParagraphXs>
    ) : invoice.events.instantPayoutAt ? (
      <WSText.ParagraphXs color="gray500">
        Deposited instantly on{" "}
        {toWSDateString(invoice.events.instantPayoutAt, "monthDate")}
      </WSText.ParagraphXs>
    ) : invoice.member.payoutPreferences === PayoutPreferences.Instant ? (
      <WSText.ParagraphXs color="gray500">
        Expected instant deposit {toWSDateString(new Date(), "monthDate")}
      </WSText.ParagraphXs>
    ) : invoice.amountDetails ? (
      <WSText.ParagraphXs color="gray500">
        Expected direct deposit{" "}
        {toWSDateString(invoice.events.estimatedDepositAt, "monthDate")}
      </WSText.ParagraphXs>
    ) : (
      <WSText.ParagraphXs color="gray500">
        Paid outside of Wingspan on{" "}
        {toWSDateString(invoice.events.paidAt, "monthDate")}
      </WSText.ParagraphXs>
    )
  ) : invoice.oldStatus === InvoiceStatus.PaymentInTransit ? (
    <WSText.ParagraphXs color="gray500">
      Waiting on client's wire transfer to arrive
    </WSText.ParagraphXs>
  ) : getIsInvoiceRejected(invoice) ? (
    <Tooltip
      trigger={
        <WSFlexBox.CenterY>
          <WSText.ParagraphXs color="gray500">
            Client rejected invoice
          </WSText.ParagraphXs>
          <WSIcon block name="info-circle" size="XS" color="gray500" ml="XS" />
        </WSFlexBox.CenterY>
      }
    >
      <WSText.ParagraphXs color="white">Reject comment:</WSText.ParagraphXs>
      <WSText.ParagraphXs color="white">
        {invoice.client.comment}
      </WSText.ParagraphXs>
    </Tooltip>
  ) : getIsInvoiceResubmitted(invoice) ? (
    <WSText.ParagraphXs color="gray500">
      Client resolved dispute at{" "}
      {toWSDateString(invoice.events.clientResolvedDisputeAt, "monthDate")}
    </WSText.ParagraphXs>
  ) : invoice.oldStatus === InvoiceStatus.Pending ? (
    <WSText.ParagraphXs color="gray500">Invoice is pending</WSText.ParagraphXs>
  ) : invoice.oldStatus === InvoiceStatus.Open ? (
    addDays(new Date(), 3) > invoice.dueDate &&
    getIsInvoiceCreator(invoice, invoice.memberId) ? (
      <WSText.ParagraphXs color="amber400">
        Due soon ({toWSDateString(invoice.dueDate, "monthDate")})
      </WSText.ParagraphXs>
    ) : invoice.events.emailViewedAt &&
      invoice.events.emailViewedAt.length > 0 ? (
      <WSText.ParagraphXs color="gray500">
        Last viewed{" "}
        {toWSDateString(
          invoice.events.emailViewedAt[invoice.events.emailViewedAt.length - 1],
          "monthDate"
        )}
      </WSText.ParagraphXs>
    ) : getIsInvoiceAccepted(invoice) ? (
      <WSText.ParagraphXs color="gray500">
        Accepted by you on{" "}
        {toWSDateString(invoice.events.memberAcceptedAt, "monthDate")}
      </WSText.ParagraphXs>
    ) : (
      <WSText.ParagraphXs color="gray500">
        Sent {toWSDateString(invoice.events.openedAt, "monthDate")}
      </WSText.ParagraphXs>
    )
  ) : getIsInvoiceAccepted(invoice) ? (
    <WSText.ParagraphXs color="gray500">
      Accepted by you on{" "}
      {toWSDateString(invoice.events.memberAcceptedAt, "monthDate")}
    </WSText.ParagraphXs>
  ) : invoice.oldStatus === InvoiceStatus.Overdue ? (
    <WSText.ParagraphXs color="red400">
      Overdue ({toWSDateString(invoice.dueDate, "monthDate")})
    </WSText.ParagraphXs>
  ) : null;
};
