import { useHistory, useRouteMatch } from "react-router-dom";
import queryString from "query-string";
import { EditLayout } from "../../../components/EditLayout";
import { WSQueries } from "../../../../../query/WSQuery";
import {
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSDivider,
  WSElement,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";
import { getCorrectionTypeFromCorrectionRequest } from "./getCorrectionTypeFromCorrectionRequest";
import { AddressCorrectionDiffPreview } from "../../../../TaxDocuments/components/AddressCorrectionDiffPreview";
import { AmountCorrectionDiffPreview } from "../../../../TaxDocuments/components/AmountCorrectionDiffPreview";
import { TINOrNameCorrectionDiffPreview } from "../../../../TaxDocuments/components/TINOrNameCorrectionDiffPreview";
import {
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME,
  CORRECTION_ADDRESS
} from "../../../../TaxDocuments/constants/corrections";
import {
  getPayeeTINorName,
  getPayeeFormW9Address
} from "../../../../TaxDocuments/routes/RequestCorrectionFlow/getDefaultValuesForCorrectionsForm";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import { TinType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { UnchangedRecipientDetails } from "../StartNewCorrectionFlow/UnchangedRecipientDetails";
import { useState } from "react";
import { TextBulleted } from "../../../../../shared/components/TextBulleted";
import { selectorPayeeTaxFormPayerCompanyName } from "../../../selectors/selectorPayeeTaxFormPayerCompanyName";
import styles from "./styles.module.scss";
import {
  IPayerTaxFormResponse,
  ITaxFormCorrectionResponse
} from "@wingspanhq/payments/dist/interfaces";
import { usePayerTaxFormWithCorrection } from "../../../../../query/taxForm/queries/usePayerTaxFormWithCorrection";
import { transformCorrectionRequestToFormData } from "./transformCorrectionRequestToFormData";
import { TaxFormViewer } from "../../../../TaxDocuments/types";
import { useTaxFormApproveCorrection } from "../../../../../query/taxForm/mutations/useTaxFormApproveCorrection";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../../services/platform";

export const RouteSubmitCorrection: React.FC = () => {
  const [consent, setConsent] = useState(false);
  const history = useHistory();
  const match = useRouteMatch<{ taxFormId: string }>();
  const { taxFormId } = match.params;
  const queryTaxForm = usePayerTaxFormWithCorrection(taxFormId);
  const [
    approveCorrection,
    approveCorrectionMeta
  ] = useTaxFormApproveCorrection(taxFormId);
  const { force }: { force?: string } = queryString.parse(
    window.location.search
  );
  const forceCorrection = !!Number(force);

  const onEditUnchangedDetails = () => {
    const taxForm = queryTaxForm.data as IPayerTaxFormResponse;
    const { corrections } = getCorrectionTypeFromCorrectionRequest(taxForm);

    onEdit({
      corrections: [
        ...(!corrections.includes(CORRECTION_AMOUNT)
          ? [CORRECTION_AMOUNT]
          : []),
        ...(!corrections.includes(CORRECTION_TIN_OR_NAME)
          ? [CORRECTION_TIN_OR_NAME]
          : []),
        ...(!corrections.includes(CORRECTION_ADDRESS)
          ? [CORRECTION_ADDRESS]
          : [])
      ],
      showRequestedValue: false
    });
  };

  const onAmountEdit = () => {
    onEdit({
      corrections: [CORRECTION_AMOUNT],
      showRequestedValue: true
    });
  };

  const onTINOrNameEdit = () => {
    onEdit({
      corrections: [CORRECTION_TIN_OR_NAME],
      showRequestedValue: true
    });
  };

  const onAddressEdit = () => {
    onEdit({
      corrections: [CORRECTION_ADDRESS],
      showRequestedValue: true
    });
  };

  const onEdit = ({
    corrections,
    showRequestedValue
  }: {
    corrections: string[];
    showRequestedValue: boolean;
  }) => {
    const taxForm = queryTaxForm.data as IPayerTaxFormResponse;
    const correctionRequest = taxForm?.pendingCorrection as ITaxFormCorrectionResponse;
    history.push({
      pathname: `/member/1099-filing/details/${taxFormId}/submit-correction/edit`,
      state: {
        backPath: history.location.pathname,
        corrections,
        showRequestedValue,
        correctionsFormData: transformCorrectionRequestToFormData(
          corrections,
          taxForm,
          correctionRequest
        )
      }
    });
  };

  const onSubmitCorrection = async () => {
    const taxForm = queryTaxForm?.data as IPayerTaxFormResponse;
    await approveCorrection(
      {
        correctionId: taxForm?.pendingCorrection?.taxFormCorrectionId as string,
        requestData: {
          forceCorrection
        }
      },
      {
        onSuccess: () => {
          history.push(
            `/member/1099-filing/details/${taxForm.taxFormId}/submit-correction/success`
          );
        }
      }
    );
  };

  const onCancel = () => {
    history.push(`/member/1099-filing/details/${taxFormId}`);
  };

  return (
    <EditLayout title="Submit correction">
      <WSElement>
        <WSText.Heading2 mb="XS">
          Update 1099-NEC and submit correction
        </WSText.Heading2>
        <WSText.ParagraphSm color="gray500">
          Please review the requested corrections thoroughly before submitting a
          correction to the IRS.
        </WSText.ParagraphSm>

        <WSDivider my="XL" />

        <WSQueries queries={{ queryTaxForm }}>
          {({ queryTaxFormData: taxForm }) => {
            const recipientName = selectorTaxFormRecipientName(taxForm);
            const correctionRequest = taxForm?.pendingCorrection;
            if (!correctionRequest) {
              return (
                <WSMessageBox.Info
                  button={{
                    kind: "Link",
                    children: "Refresh",
                    onClick: () => {
                      window.location.reload();
                    }
                  }}
                >
                  Please refresh the web page if you can't see correction
                  details.
                </WSMessageBox.Info>
              );
            }

            const {
              corrections,
              isCompanyStructureChangeOnly,
              isVoidType
            } = getCorrectionTypeFromCorrectionRequest(taxForm);

            const hasUnchangedDetails =
              !corrections.includes(CORRECTION_AMOUNT) ||
              !corrections.includes(CORRECTION_TIN_OR_NAME) ||
              !corrections.includes(CORRECTION_ADDRESS);

            const isType1Correction =
              corrections.length === 1 &&
              corrections.includes(CORRECTION_AMOUNT);

            const correctionTypeToPreviewComponentMap: Record<
              string,
              JSX.Element
            > = {
              [CORRECTION_AMOUNT]: (
                <AmountCorrectionDiffPreview
                  taxForm={taxForm}
                  taxFormViewer={TaxFormViewer.Payer}
                  onEdit={onAmountEdit}
                  amount={taxForm?.data?.totalAmount ?? 0}
                  correctedAmount={correctionRequest.data?.totalAmount ?? 0}
                  comment={correctionRequest.payeeOwnedData?.comment ?? ""}
                />
              ),
              [CORRECTION_TIN_OR_NAME]: (
                <TINOrNameCorrectionDiffPreview
                  taxForm={taxForm}
                  taxFormViewer={TaxFormViewer.Payer}
                  isVoidType={isVoidType}
                  isForce={forceCorrection}
                  onEdit={onTINOrNameEdit}
                  identificationNumberType={
                    correctionRequest.data?.w9Info?.ein ||
                    correctionRequest.data?.w9Info?.einLastFour
                      ? TinType.Business
                      : TinType.Individual
                  }
                  originalTinData={getPayeeTINorName(taxForm)}
                  correctedTinData={{
                    ssn: correctionRequest?.data?.w9Info?.ssn ?? "",
                    ein: correctionRequest?.data?.w9Info?.ein ?? "",
                    businessName:
                      correctionRequest?.data?.w9Info?.legalBusinessName ?? "",
                    firstName: correctionRequest?.data?.w9Info?.firstName,
                    lastName: correctionRequest?.data?.w9Info?.lastName,
                    disregardedEntityName:
                      correctionRequest?.data?.w9Info?.disregardedEntityName,
                    taxClassification: correctionRequest?.data?.w9Info
                      ?.companyStructure as CompanyStructure
                  }}
                  reasonForChange={
                    correctionRequest?.payeeOwnedData?.reason ?? ""
                  }
                  otherReason={correctionRequest?.payeeOwnedData?.reasonComment}
                  hasOtherEligibleCorrectionChanges={
                    !isCompanyStructureChangeOnly
                  }
                />
              ),
              [CORRECTION_ADDRESS]: (
                <AddressCorrectionDiffPreview
                  taxForm={taxForm}
                  taxFormViewer={TaxFormViewer.Payer}
                  onEdit={onAddressEdit}
                  originalAddress={getPayeeFormW9Address(taxForm)}
                  correctedAddress={{
                    addressLine1:
                      correctionRequest?.data?.w9Info?.addressLine1 ?? "",
                    addressLine2:
                      correctionRequest?.data?.w9Info?.addressLine2 ?? "",
                    city: correctionRequest?.data?.w9Info?.city ?? "",
                    state: correctionRequest?.data?.w9Info?.state ?? "",
                    zipCode: correctionRequest?.data?.w9Info?.postalCode ?? "",
                    country: correctionRequest?.data?.w9Info?.country ?? ""
                  }}
                />
              )
            };
            return (
              <WSElement shimmer={!correctionRequest}>
                <WSText.Heading2 mb="L">{recipientName}</WSText.Heading2>

                {corrections.map(correctionType => {
                  const DiffPreviewComponent =
                    correctionTypeToPreviewComponentMap[correctionType];
                  return (
                    <WSElement mb="L" key={correctionType}>
                      {DiffPreviewComponent}
                      <WSDivider my="XL" />
                    </WSElement>
                  );
                })}

                {hasUnchangedDetails ? (
                  <>
                    <UnchangedRecipientDetails
                      onEdit={onEditUnchangedDetails}
                      taxForm={taxForm}
                      corrections={corrections}
                    />
                    <WSDivider my="XL" />
                  </>
                ) : null}

                <WSMessageBox.Info mb="L">
                  <WSText.ParagraphSm weight="medium" mb="S" color="gray600">
                    Are you ready to submit the correction?
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm mb="S" color="gray500">
                    If you click submit, this correction will be sent to the
                    IRS. You cannot undo this action. If needed, another
                    correction can be filed after the IRS accepts this one.
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm mb="S" weight="medium" color="gray600">
                    What to expect:
                  </WSText.ParagraphSm>
                  <TextBulleted
                    color="gray500"
                    className={styles.higherPointsList}
                  >
                    {isType1Correction ? (
                      <li>
                        One (1) updated form will be filed to the IRS based on
                        this correction
                      </li>
                    ) : (
                      <li>
                        Two (2) updated forms will be filed to the IRS based on
                        these corrections
                        <TextBulleted
                          color="gray500"
                          as="ol"
                          className={styles.lowerAlphaList}
                        >
                          <li>
                            First (1st) updated form to zero out the incorrect
                            form
                          </li>
                          <li>
                            Second (2nd) updated form with new recipient info
                          </li>
                        </TextBulleted>
                      </li>
                    )}
                    <li>
                      The updated {isType1Correction ? "1099" : "1099s"}, once
                      submitted, will be delivered immediately to the recipient
                      by mail or electronically based on their preference
                    </li>
                  </TextBulleted>
                </WSMessageBox.Info>

                <WSCheckboxToggle
                  mb="L"
                  name="consent"
                  value={consent}
                  onChange={setConsent}
                  label={`Under penalties of perjury, I declare that to the best of my knowledge. ${selectorPayeeTaxFormPayerCompanyName(
                    taxForm
                  )} attempted to obtain the payee's U.S. TIN and other identifying information under IRS Publication 1281. As a result, the information contained in the taxpayer's electronic forms 1099 file is, to the best of my knowledge and belief, true, correct and complete.`}
                />

                <WSErrorMessage
                  mb="M"
                  contextKey={ErrorContextKey.NEC1099PayerApproveCorrection}
                  error={approveCorrectionMeta.error}
                />

                <WSButtons forceFullWidth>
                  <WSButton.Primary
                    disabled={!consent}
                    onClick={onSubmitCorrection}
                    loading={approveCorrectionMeta.isLoading}
                  >
                    Submit correction to the IRS
                  </WSButton.Primary>
                  <WSButton.Tertiary onClick={onCancel}>
                    Cancel
                  </WSButton.Tertiary>
                </WSButtons>
              </WSElement>
            );
          }}
        </WSQueries>
      </WSElement>
    </EditLayout>
  );
};
