import {
  useWSModal,
  WSActions,
  WSDivider,
  WSInfoBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { useSuspendCard } from "../../../../query/cards/mutations/useSuspendCard";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";

export interface SuspendCardProps {
  cardId: string;
  cardName: string;
  onSuccess: () => void;
  onClose: () => void;
}

const SuspendCard: React.FC<SuspendCardProps> = ({
  cardId,
  cardName,
  onSuccess,
  onClose
}) => {
  const [suspendCard, suspendCardMeta] = useSuspendCard();

  return (
    <WSList gap="L">
      <WSText.ParagraphSm mb="S">
        Are you sure you want to suspend {cardName} temporarily?
      </WSText.ParagraphSm>
      <WSInfoBox>
        The card will remain suspended until you reactivate or close the card.
        The card cannot be used while it is suspended.
      </WSInfoBox>
      <WSErrorMessage contextKey="Other" error={suspendCardMeta.error} />
      <WSDivider />
      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Suspend card",
            onAsyncClick: async () => {
              await suspendCard(cardId, {
                onSuccess() {
                  onSuccess();
                  onClose();
                }
              });
            },
            kind: "Primary"
          },
          {
            label: "Cancel",
            onClick: onClose,
            kind: "Secondary"
          }
        ]}
      />
    </WSList>
  );
};

export function useSuspendCardModal() {
  return useWSModal(SuspendCard, {
    title: "Suspend card",
    size: "S"
  });
}
