import {
  WSButton,
  WSButtons,
  WSControl,
  WSText,
  useWSModal
} from "@wingspanhq/fe-component-library";
import { IPaymentCard } from "@wingspanhq/payments/dist/interfaces";
import { useCallback, useState } from "react";
import { useUserId } from "../../query/hooks/helpers";
import { useUpdateClient } from "../../query/users/mutations";
import { ModalDeletePaymentCard } from "./ModalDeletePaymentCard";

type Props = {
  paymentCard: IPaymentCard;
  onClose: () => void;
  isDefault: boolean;
};

export const ModalEditPaymentCard: React.FC<Props> = ({
  paymentCard,
  onClose,
  isDefault
}) => {
  const userId = useUserId();
  const [isDefaultValue, setIsDefaultValue] = useState<boolean>(isDefault);
  const modalDelete = useWSModal(ModalDeletePaymentCard, {
    size: "S"
  });

  const [updateClient, meta] = useUpdateClient(userId);

  const update = useCallback(() => {
    if (isDefaultValue === isDefault) {
      onClose();
      return;
    }

    if (!isDefaultValue) {
      updateClient(
        {
          clientId: userId,
          profile: {
            defaultPaymentMethod: {
              paymentMethodId: null
            }
          }
        },
        { onSuccess: onClose }
      );
      return;
    }

    updateClient(
      {
        clientId: userId,
        profile: {
          defaultPaymentMethod: {
            paymentMethodId: paymentCard.paymentCardId
          }
        }
      },
      { onSuccess: onClose }
    );
  }, [
    isDefault,
    isDefaultValue,
    onClose,
    paymentCard.paymentCardId,
    updateClient,
    userId
  ]);

  return (
    <>
      <WSText.Heading4 mb="M">Edit payment method</WSText.Heading4>

      <WSControl
        type="checkbox"
        value={isDefaultValue}
        onChange={setIsDefaultValue}
        label="Default Payment method"
      />

      <WSText.ParagraphSm color="gray500" mb="XL">
        We'll make transactions from the default payment method
      </WSText.ParagraphSm>

      <WSButtons mb="M">
        <WSButton onClick={update} loading={meta.isLoading} size="M">
          Update
        </WSButton>
        <WSButton.Secondary size="M" onClick={onClose}>
          Cancel
        </WSButton.Secondary>
      </WSButtons>

      <WSButton.Secondary
        destructive
        size="M"
        onClick={() => {
          modalDelete.open({ paymentCard, onSuccess: onClose });
        }}
      >
        Delete payment method
      </WSButton.Secondary>
    </>
  );
};
