import { WSElementProps, WSPill } from "@wingspanhq/fe-component-library";
import { TaxFormStatus } from "@wingspanhq/payments/dist/interfaces";
import React from "react";

export interface FilingStatusProps extends WSElementProps {
  status: TaxFormStatus;
  isCorrection: boolean;
}

export const TaxFormFilingStatusBadge: React.FC<FilingStatusProps> = ({
  status,
  isCorrection,
  ...props
}) => {
  switch (status) {
    // should be substituted by latest correction status
    // case TaxFormStatus.PendingCorrection:
    //   return (
    //     <WSPill
    //       theme="warning"
    //       icon: true,
    //       text="Needs action"
    //       {...props}
    //     />
    //   );
    case TaxFormStatus.ReadyToSubmitToIrs: {
      const prefix = isCorrection ? "Correction" : "Form";

      return <WSPill theme="blue" text={`${prefix} ready`} {...props} />;
    }
    case TaxFormStatus.Excluded:
      return <WSPill text="Form excluded" {...props} />;
    case TaxFormStatus.SubmittedToIrs:
    case TaxFormStatus.AcceptedByIrs:
    case TaxFormStatus.RejectedByIrs:
    default: {
      const prefix = isCorrection ? "Correction" : "Form";

      return <WSPill text={`${prefix} ${status.toLowerCase()}`} {...props} />;
    }
  }
};
