import React from "react";
import classNames from "classnames";

import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import styles from "./WSOverlay.module.scss";

export interface OverlayProps extends WSElementProps {
  white?: boolean;
}

export const WSOverlay: React.FC<OverlayProps> = ({
  className,
  onClick = () => {},
  white
}) => (
  <WSElement
    className={classNames(styles.overlay, { [styles.white]: white }, className)}
    onClick={onClick}
  />
);
