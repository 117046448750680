import {
  useModalContext,
  WSButton,
  WSButtons,
  WSElement,
  WSElementProps,
  WSFormOld,
  WSModal,
  WSSelectOld,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { IMemberClient } from "@wingspanhq/payments/dist/interfaces/memberClient";
import React, { useState } from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksAccountExpenses,
  useIntegrationsQuickbooksItems
} from "../../../query/integrations/queries";
import { isQuickbooksServiceActive } from "../../../query/integrations/selectors";
import { useUpdateMemberClient } from "../../../query/payments/mutations";
import { useMemberClientQuery } from "../../../query/payments/queries";
import { WSQueries } from "../../../query/WSQuery";
import {
  contactCompanyValidator,
  contactNameValidator
} from "../../../utils/validators";

type Props = WSElementProps & { memberClientId: string };

export const EDIT_CONTACT_NAME_MODAL = "EDIT_CONTACT_NAME_MODAL";

export const EditContactNameModal: React.FC<Props> = ({ memberClientId }) => {
  const memberClientQuery = useMemberClientQuery(memberClientId);
  const [updateMemberClient, updateMemberClientMeta] = useUpdateMemberClient(
    memberClientId
  );
  const { closeModal } = useModalContext();
  const [showIntegrations, setShowIntegrations] = useState(false);

  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qFeatureFlags = useFeatureFlags();

  const isQuickbooksActive =
    qFeatureFlags.data?.qboOnboarding &&
    qIntegrationsQuickbooks.data &&
    (qFeatureFlags.data?.forceShowQBO ||
      isQuickbooksServiceActive(qIntegrationsQuickbooks.data));

  const qIntegrationsQuickbooksItems = useIntegrationsQuickbooksItems({
    enabled: isQuickbooksActive
  });

  const qIntegrationsQuickbooksAccountExpenses = useIntegrationsQuickbooksAccountExpenses(
    {
      enabled: isQuickbooksActive
    }
  );

  return (
    <WSModal name={EDIT_CONTACT_NAME_MODAL} size="S" title="Contact name">
      <WSQueries
        queries={{
          memberClientQuery
        }}
      >
        {({ memberClientQuery: { data: memberClient } }) => (
          <WSFormOld<{
            company: string;
            contact: string;
            integration?: IMemberClient["integration"];
          }>
            defaultValues={{
              company: memberClient.company,
              contact: memberClient.name,
              integration: memberClient.integration
            }}
            onSubmit={values => {
              updateMemberClient(
                {
                  company: values.company,
                  name: values.contact,
                  integration: {
                    ...memberClient.integration,
                    quickbooks: {
                      ...memberClient.integration?.quickbooks,
                      itemId: values.integration?.quickbooks?.itemId,
                      expenseAccountId:
                        values.integration?.quickbooks?.expenseAccountId
                    }
                  }
                },
                {
                  onSuccess: () => {
                    closeModal(EDIT_CONTACT_NAME_MODAL);
                  }
                }
              );
            }}
            validationSchema={Yup.object().shape({
              company: contactCompanyValidator,
              contact: contactNameValidator.required("Required")
            })}
          >
            <WSFormOld.Field
              name="company"
              label="Client company"
              component={WSTextInput}
              mb="XL"
            />
            <WSFormOld.Field
              name="contact"
              label="Client contact"
              component={WSTextInput}
              mb="XL"
            />

            {isQuickbooksActive ? (
              <>
                <WSElement
                  style={{ display: showIntegrations ? "block" : "none" }}
                >
                  <WSFormOld.Field
                    label={"Default Quickbooks item"}
                    name={"integration.quickbooks.itemId"}
                    component={WSSelectOld}
                    componentProps={{
                      searchable: true,
                      options:
                        qIntegrationsQuickbooksItems.data?.map(item => ({
                          label: item.name,
                          value: item.itemId
                        })) || []
                    }}
                    mb="M"
                  />
                  <WSFormOld.Field
                    label={"Default Quickbooks expense account"}
                    name={"integration.quickbooks.expenseAccountId"}
                    component={WSSelectOld}
                    componentProps={{
                      searchable: true,
                      options:
                        qIntegrationsQuickbooksAccountExpenses.data?.map(
                          item => ({
                            label: item.name,
                            value: item.accountId
                          })
                        ) || []
                    }}
                    mb="M"
                  />
                </WSElement>
                <WSButton.Link
                  size="M"
                  onClick={() => {
                    setShowIntegrations(v => !v);
                  }}
                  type="button"
                >
                  {showIntegrations ? "Hide integrations" : "Show integrations"}
                </WSButton.Link>
              </>
            ) : null}

            <WSErrorMessage
              mb="XL"
              error={updateMemberClientMeta.error}
              contextKey="EditContact"
            />

            <WSButtons mt="XL" format="modal">
              <WSButton
                name="update"
                loading={updateMemberClientMeta.isLoading}
              >
                Update
              </WSButton>
              <WSButton.Tertiary
                onClick={() => {
                  closeModal(EDIT_CONTACT_NAME_MODAL);
                }}
              >
                Cancel
              </WSButton.Tertiary>
            </WSButtons>
          </WSFormOld>
        )}
      </WSQueries>
    </WSModal>
  );
};
