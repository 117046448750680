import { useRouteMatch, useHistory } from "react-router-dom";
import cn from "classnames";
import useSnackbar from "../../../../hooks/useSnackbar";
import {
  CustomFieldResourceType,
  BulkPayableStatus,
  IBulkPayableBatch
} from "@wingspanhq/payments/dist/interfaces";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { useFlatfileErrorModal } from "../../../BulkImporter/components/FlatfileError/useFlatfileErrorModal";
import { FlatfileError } from "@flatfile/sdk/dist/errors/FlatfileError";
import { BulkResource } from "../../../BulkImporter/types";
import { useFlatfile } from "../../../../hooks/useFlatfile";
import {
  WSButton,
  WSButtons,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";

import styles from "./styles.module.scss";
import { CSVLink } from "react-csv";
import { WSQueries } from "../../../../query/WSQuery";
import { processFlatfileChunk } from "../../../BulkImporter/utils/flatfileBulkPayable";
import { PartialRejection } from "@flatfile/sdk";
import { IS_PRODUCTION_ENV } from "../../../../shared/constants/environment";
import { pullSessionToken } from "../../../../services/sessionStorage";
import {
  flatfileConfig,
  flatfileTheme
} from "../../../BulkImporter/utils/flatfileThemeConfig";
import { useBulkPayableBatchSummary } from "../../../BulkImporter/query/bulkPayable/queries";
import { EditLayout } from "../../components/EditLayout";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { SetupImportPaymentsComponentProps } from "./index";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUpdateBulkPayableBatch } from "../../../BulkImporter/query/bulkPayable/mutations";

export const RouteImportPayments: React.FC<SetupImportPaymentsComponentProps> = ({
  onBack,
  onNext
}) => {
  const userId = useUserId();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const history = useHistory();
  const { warning } = useSnackbar();

  const qLineItemCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.LineItem]
  });
  const lineItemCustomFields = qLineItemCustomFields.data || [];

  const qPayableBulkBatchItems = useBulkPayableBatchSummary(bulkBatchId);
  const qFeatureFlags = useFeatureFlags();
  const [
    updateBulkPayableBatch,
    updateBulkPayableBatchMeta
  ] = useUpdateBulkPayableBatch();

  const downloadOffPlatformPaymentsTemplate = [
    {
      email: "wade@wingspan.app",
      contractorId: "",
      lineItemTitle: "1099 Project",
      amount: "1000",
      paidDate: "11/30/2023",

      dueDate: "",
      lineItemDescription: "",
      reimbursable: false
    }
  ];

  const downloadTemplateCsvProps = {
    filename: `Wingspan_Off_Platform_Payments_Template.csv`,
    headers: [
      { label: "Email", key: "email" },
      { label: "External Contractor ID", key: "contractorId" },
      { label: "Line Item Title", key: "lineItemTitle" },
      { label: "Amount (USD)", key: "amount" },
      { label: "Pay Date", key: "paidDate" },

      { label: "Due date", key: "dueDate" },
      { label: "Line Item Description", key: "lineItemDescription" },
      { label: "Reimbursable", key: "reimbursable" },

      ...lineItemCustomFields.map(field => ({
        label: field.name,
        key: field.key
      }))
    ],
    data: downloadOffPlatformPaymentsTemplate
  };

  // Flatfile error handling
  const flatfileErrorModal = useFlatfileErrorModal();

  const onFlatfileError = (error: FlatfileError) => {
    flatfileErrorModal.open({
      error,

      batchId: bulkBatchId,
      bulkResource: BulkResource.Payable,
      onStartNewImport: bulkBatch => {
        history.push(
          `/member/1099-filing/batch-upload-payments/${
            (bulkBatch as IBulkPayableBatch).bulkPayableBatchId
          }`
        );
      }
    });
  };

  const EMBED_ID = process.env
    .REACT_APP_FLATFILE_NEC_1099_FILING_PAYABLES_EMBED_ID as string;

  const flatfile = useFlatfile({
    embedId: EMBED_ID,
    onError: onFlatfileError
  });

  return (
    <EditLayout title="Batch upload payments">
      <WSQueries
        queries={{
          qLineItemCustomFields,
          qFeatureFlags
        }}
      >
        {({
          qLineItemCustomFieldsData: lineItemCustomFields,
          qFeatureFlagsData: featureFlags
        }) => {
          // Flatfile import data
          const importDataV3 = async () => {
            flatfile.requestDataFromUser({
              ...flatfileConfig,
              theme: {
                ...flatfileTheme
              },
              onInit: ({ session }) => {
                session.updateEnvironment({
                  isProdEnv: IS_PRODUCTION_ENV,
                  authToken: pullSessionToken(),
                  bulkBatchId,
                  userId,
                  organizationCollaboratorExternalIdResolution:
                    featureFlags.organizationCollaboratorExternalIdResolution
                });

                session.on("close", () => {
                  // Just refetch the batch items query to update the batch details view
                  // so that user won't upload the spreadsheet again in the same batch.
                  qPayableBulkBatchItems.refetch();
                });
              },
              customFields: lineItemCustomFields.map(({ key, name }) => {
                return {
                  field: key,
                  label: name,
                  // FIX: boolean fields are not supported in Flatfile
                  type: "string"
                };
              }),
              /**
               * Flatfile streams the uploaded spreadsheet data as chunks of size 1000 (default) to onData
               * callback function where we can process the spreadsheet data (like making an external API call)
               * and reject any spreadsheet row(s) if there are any failures during processing.
               */
              onData: async (chunk, next) => {
                const rejections = await processFlatfileChunk(
                  chunk,
                  bulkBatchId,
                  BulkPayableStatus.Paid,
                  lineItemCustomFields
                );
                next(new PartialRejection(rejections));
              },
              onComplete: async payload => {
                const csvRecords = await payload.data();
                const csvPayments = csvRecords.rows
                  .filter(row => row.valid)
                  .map(row => row.data);

                console.group("Bulk Import Off-platform payments");
                console.log("CSV Recipients", csvPayments);
                console.groupEnd();

                if (csvPayments.length === 0) {
                  warning(
                    "Sorry, Looks like the uploaded spreadsheet is empty!"
                  );
                  return;
                }
                await updateBulkPayableBatch({
                  id: bulkBatchId,
                  data: {
                    labels: {
                      flatfileBatchId: payload.batchId,
                      payableStatus: BulkPayableStatus.Paid
                    }
                  }
                });

                onNext({
                  bulkBatchId
                });
              }
            });
          };

          const onUploadFile = () => {
            importDataV3();
          };
          return (
            <>
              <WSText.Heading4 mb="XL">Batch upload payments</WSText.Heading4>
              <WSText weight="medium" mb="M">
                Invite all recipients to Wingspan
              </WSText>

              <WSText mb="XL" color="gray400">
                Pre-requisite: Ensure that you have already invited all your
                recipients who will receive a 1099 sign up for Wingspan.
              </WSText>

              <WSText.Heading5 mb="M">
                Upload a spreadsheet of payments to your recipients
              </WSText.Heading5>
              <WSText mb="XL" color="gray400">
                Please make sure your spreadsheet contains the following
                columns:
              </WSText>

              <WSMessageBox.Info mb="XL" size="Large">
                <WSText.Heading5 mb="XL" color="gray600">
                  Preparing your document
                </WSText.Heading5>

                <WSText.ParagraphSm weight="medium" mb="XL">
                  REQUIRED
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mb="M" color="gray500">
                  Identifier (email or external ID)
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mb="M" color="gray500">
                  Amount (USD)
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mb="M" color="gray500">
                  Line Item Title
                </WSText.ParagraphSm>
                <WSText.ParagraphSm color="gray500">
                  Paid Date
                </WSText.ParagraphSm>

                <WSText.ParagraphSm weight="medium" my="XL">
                  OPTIONAL
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mb="M" color="gray500">
                  Due Date
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mb="M" color="gray500">
                  Line Item Description
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mb="M" color="gray500">
                  Reimbursable
                </WSText.ParagraphSm>
                {lineItemCustomFields.length > 0 ? (
                  <>
                    <WSText.ParagraphSm weight="medium" my="XL">
                      CUSTOM FIELDS
                    </WSText.ParagraphSm>
                    {lineItemCustomFields.map(cf => (
                      <WSText.ParagraphSm mb="M" color="gray500" key={cf.id}>
                        {`${cf.name} ${cf.required ? "(Required)" : ""}`}
                      </WSText.ParagraphSm>
                    ))}
                  </>
                ) : null}
              </WSMessageBox.Info>

              <WSText my="XL" color="gray400">
                You can download a template containing all of your recipients
                below.
              </WSText>

              <WSText weight="medium" mb="M">
                Import and review line items
              </WSText>
              <WSText.ParagraphSm mb="XL" color="gray400">
                Review your uploaded line items to ensure their information is
                correct.
              </WSText.ParagraphSm>

              <WSButton.Link
                type="button"
                rightIcon="chevron-right"
                onClick={() =>
                  openInNewTab(
                    "https://docs.wingspan.app/docs/batch-uploading-off-platform-payments"
                  )
                }
              >
                Learn more about how to upload
              </WSButton.Link>

              <WSButtons mt="3XL">
                <CSVLink {...downloadTemplateCsvProps}>
                  <WSButton.Secondary
                    className={cn(styles.actionBtn, styles.downloadBtn)}
                    fullWidth
                    icon="download"
                    p="S"
                  >
                    Download template
                  </WSButton.Secondary>
                </CSVLink>

                <WSButton.Primary
                  className={cn(styles.actionBtn, styles.uploadBtn)}
                  fullWidth
                  icon="upload"
                  p="S"
                  data-testid="importOffPlatformPayments"
                  loading={updateBulkPayableBatchMeta.isLoading}
                  onClick={onUploadFile}
                >
                  Upload spreadsheet
                </WSButton.Primary>
              </WSButtons>
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
