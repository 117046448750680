import {
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSLoader,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  AccountStatus,
  AccountUsage,
  IAccount
} from "@wingspanhq/users/dist/lib/interfaces/account";
import React from "react";
import { PlaidReAuth } from "../PlaidReAuth/PlaidReAuth";
import { getAccountSubName } from "../WSAccountsListV2/utils";
import { WSInstitutionLogo } from "../WSInstitutionLogo/WSInstitutionLogo.component";
import styles from "./WSAccountsList.module.scss";
import { Actions } from "../Actions";

export interface WSAccountsListProps extends WSElementProps {
  accounts: Array<IAccount>;
  getInstitutionSubtitle?: (institution: Institution) => string;
  onDeleteInstitution?: (institution: Institution) => void;
  onManageInstitution?: (institution: Institution) => void;
  getAccountSubtitle?: (account: IAccount) => string;
  onEditAccount?: (account: IAccount) => void;
  getAccountDisplayComponent?: (account: IAccount) => React.ReactNode;
  noIcon?: boolean;
}

interface Institution {
  name?: string;
  lastSynced?: Date;
  isDisconnected?: boolean;
  id: string;
  accounts: Array<IAccount>;
}

export const WSAccountsList: React.FC<WSAccountsListProps> = ({
  accounts,
  getInstitutionSubtitle,
  onDeleteInstitution,
  onManageInstitution,
  getAccountSubtitle,
  onEditAccount,
  getAccountDisplayComponent,
  noIcon,
  ...elementProps
}) => {
  const accountsByInstitution: { [institutionId: string]: Institution } = {};

  accounts.forEach(account => {
    const { institutionId } = account;
    if (institutionId) {
      if (accountsByInstitution[institutionId]) {
        accountsByInstitution[institutionId].accounts.push(account);
      } else {
        accountsByInstitution[institutionId] = {
          id: institutionId,
          name: account.institution,
          accounts: [account]
        };
      }
    }
  });

  const getInstitutionDetailsFromAccounts = (accountsList: Array<IAccount>) => {
    let lastSynced: Date | undefined;
    let isDisconnected: boolean | undefined;
    accountsList.forEach(account => {
      const { events, status } = account;
      if (events?.transactionsSyncedAt) {
        if (
          lastSynced === undefined ||
          (lastSynced !== undefined && lastSynced < events.transactionsSyncedAt)
        ) {
          lastSynced = events.transactionsSyncedAt;
        }
      }
      if (
        isDisconnected === undefined &&
        status === AccountStatus.Disconnected
      ) {
        isDisconnected = true;
      }
    });
    return {
      lastSynced,
      isDisconnected
    };
  };

  Object.keys(accountsByInstitution).forEach(institutionId => {
    const institution = accountsByInstitution[institutionId];
    const details = getInstitutionDetailsFromAccounts(institution.accounts);
    accountsByInstitution[institutionId] = {
      ...accountsByInstitution[institutionId],
      ...details
    };
  });

  const getAccountTemplate = (
    account: IAccount,
    isInstitutionDisconnected: boolean | undefined
  ) => (
    <WSElement
      onClick={
        onEditAccount && account.status !== AccountStatus.Disconnected
          ? () => onEditAccount(account)
          : undefined
      }
      data-testid={`account-block-${account.accountId}`}
    >
      <WSFlexBox.CenterY
        ml="3XL"
        justify="space-between"
        className={styles.account}
      >
        <WSElement>
          <WSText.ParagraphXs singleLine>
            {getAccountSubName(account)}
          </WSText.ParagraphXs>
          {getAccountSubtitle && (
            <WSText.ParagraphSm singleLine>
              {getAccountSubtitle(account)}
            </WSText.ParagraphSm>
          )}
        </WSElement>
        <WSFlexBox.CenterY>
          {getAccountDisplayComponent && getAccountDisplayComponent(account)}
          {!noIcon && account.usage === AccountUsage.Business && (
            <WSPill mb="XS" icon="briefcase" />
          )}
          <PlaidReAuth accountId={account.accountId}>
            {({ onOpen, status, loading }) =>
              status && (
                <WSElement mx="M" onClick={loading ? undefined : onOpen}>
                  {loading ? (
                    <WSLoader.Spinner size="S" />
                  ) : (
                    <WSText weight="medium" color="red500">
                      Reconnect
                    </WSText>
                  )}
                </WSElement>
              )
            }
          </PlaidReAuth>
          {onEditAccount && account.status !== AccountStatus.Disconnected && (
            <WSElement px="M">
              <WSIcon block size="S" color="blue400" name="edit" />
            </WSElement>
          )}
        </WSFlexBox.CenterY>
      </WSFlexBox.CenterY>
      <WSDivider />
    </WSElement>
  );

  const getInstitutionTemplate = (institution: Institution) => (
    <WSElement>
      <WSFlexBox mb="M" wrap="nowrap">
        <WSFlexBox.CenterY wrap="nowrap" className={styles.accountsGroupHeader}>
          <WSInstitutionLogo size="M" institutionId={institution.id} />
          <WSText weight="medium" singleLine>
            {institution.name}
          </WSText>
          {getInstitutionSubtitle && (
            <WSText.ParagraphSm singleLine>
              {getInstitutionSubtitle(institution)}
            </WSText.ParagraphSm>
          )}
        </WSFlexBox.CenterY>
        <WSFlexBox.CenterY>
          {onDeleteInstitution && !onManageInstitution ? (
            <WSElement
              onClick={() => onDeleteInstitution(institution)}
              data-testid={`delete-institution-${institution.id}`}
            >
              <WSIcon block size="S" color="gray600" p="S" name="trash" />
            </WSElement>
          ) : null}
          {(onDeleteInstitution && onManageInstitution) ||
          (!onDeleteInstitution && onManageInstitution) ? (
            <Actions
              p="S"
              name="actions"
              items={[
                ...(onManageInstitution
                  ? [
                      {
                        label: "Manage synced accounts",
                        onClick: () => onManageInstitution(institution)
                      }
                    ]
                  : []),
                ...(onDeleteInstitution
                  ? [
                      {
                        label: "Delete",
                        onClick: () => onDeleteInstitution(institution)
                      }
                    ]
                  : [])
              ].filter(Boolean)}
            />
          ) : null}
        </WSFlexBox.CenterY>
      </WSFlexBox>
      <WSDivider />
      {institution.accounts
        .sort((a, b) => (getAccountSubName(a) > getAccountSubName(b) ? 1 : -1))
        .map(account => (
          <WSElement key={account.accountId}>
            {getAccountTemplate(account, institution.isDisconnected)}
          </WSElement>
        ))}
    </WSElement>
  );

  return (
    <WSElement className={styles.accountsList} {...elementProps}>
      {Object.keys(accountsByInstitution)
        .sort((a, b) => {
          const aInstitution = accountsByInstitution[a];
          const bInstitution = accountsByInstitution[b];

          if (!aInstitution.name || !bInstitution.name) {
            return 0;
          }

          return aInstitution.name > bInstitution.name ? 1 : -1;
        })
        .map((institutionId, index) => {
          const institution = accountsByInstitution[institutionId];
          return (
            <WSElement
              key={institutionId}
              mt={index > 0 ? "3XL" : "NONE"}
              data-testid={`institution-container-${institutionId}`}
            >
              {getInstitutionTemplate(institution)}
            </WSElement>
          );
        })}
    </WSElement>
  );
};
