import { timeToQuantity } from "../../../utils";
import { FormDataLineItem } from "./types";

export const calculateAmount = (values: FormDataLineItem) => {
  const quantity =
    values.rate === "hourly"
      ? timeToQuantity(values.quantity || 0, values.minutes || 0)
      : values.quantity || 0;

  return (values.costPerUnit || 0) * quantity;
};

export const calculateTotalAmount = (lineItems: FormDataLineItem[]) =>
  lineItems
    ? lineItems.reduce((total, lineItem) => {
        if (lineItem.remove) {
          return total;
        } else {
          const initialCost = Number(lineItem.totalCost);
          const discountPercentage = Number(
            lineItem?.discount?.percentage || 0
          );
          const discountAmount = Number(lineItem?.discount?.amount || 0);
          const discounType = lineItem?.discount?.type || "percentage";
          const finalCost =
            discounType === "percentage" && discountPercentage > 0
              ? initialCost - initialCost * (discountPercentage / 100)
              : discounType === "amount" && discountAmount > 0
              ? initialCost - discountAmount
              : initialCost;
          return total + finalCost;
        }
      }, 0)
    : 0;

export const calculateTotalAmountWithoutDiscount = (
  lineItems: FormDataLineItem[]
) =>
  lineItems
    ? lineItems.reduce((total, lineItem) => {
        if (lineItem.remove) {
          return total;
        } else {
          return total + Number(lineItem.totalCost);
        }
      }, 0)
    : 0;
