import {
  IPayerTaxFormResponse,
  TaxFormEventType
} from "@wingspanhq/payments/dist/interfaces";

export const selectorPayerTaxFormIsEventExist = (
  taxForm: IPayerTaxFormResponse,
  eventType: TaxFormEventType
) => {
  return taxForm.eventHistory.some(event => event.eventType === eventType);
};
