import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces";
import * as Yup from "yup";
import { validationSchemaAddress } from "../FormPartialAddress";
import { validatorCompanyName } from "../../validators/validatorCompanyName";
import { validatorName } from "../../validators/validatorName";
import { validatorTaxId } from "../../validators/validatorTaxId";
import { selectorIsBusinessTypeOnePerson } from "../../../modules/PaymentsSetup/selectors/selectorIsBusinessTypeOnePerson";
import { companyNameValidator } from "../../../utils/validators";

export const validationSchema = Yup.object({
  iHaveEin: Yup.boolean(),
  company: Yup.object().when("iHaveEin", {
    is: true,
    then: schema =>
      schema.shape({
        legalBusinessName: companyNameValidator.required("Required"),
        businessType: Yup.mixed<CompanyStructure>()
          .oneOf(Object.values(CompanyStructure))
          .required("Required"),
        ein: validatorTaxId.required("Required"),
        stateOfIncorporation: Yup.string().required("Required"),
        yearOfIncorporation: Yup.string().required("Required"),
        legalFirstName: Yup.string().when("businessType", {
          is: selectorIsBusinessTypeOnePerson,
          then: validatorName.required("Required")
        }),
        legalLastName: Yup.string().when("businessType", {
          is: selectorIsBusinessTypeOnePerson,
          then: validatorName.required("Required")
        }),
        isOnlyOneBusinessOwner: Yup.boolean()
      }),
    otherwise: schema => schema.optional()
  }),
  businessName: validatorCompanyName,
  businessAddress: validationSchemaAddress
});
