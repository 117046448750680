export const pdfHeaderInfo = {
  wingspan: {
    name: "Wingspan",
    address: {
      addressLine1: "6 W 18th St",
      addressLine2: "3rd Floor",
      city: "New York",
      state: "NY",
      postalCode: "10011",
      country: "USA"
    },
    phone: "(888) 285-2287",
    email: "wingspan.app/support"
  },
  leadBank: {
    name: "Lead Bank",
    address: {
      addressLine1: "1801 Main St.",
      addressLine2: "",
      city: "Kansas City",
      state: "MO",
      postalCode: "64108",
      country: "USA"
    },
    phone: "(866) 845-9545"
  }
};
