import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import { ClientPayments } from "../../ClientPayments";
import { useSession } from "../../query/session";
import { loadSegment } from "../../utils/analytics";
import { useIdentify } from "../../utils/useIdentify";
import { LoadingPlaceholder } from "../LoadingPlaceholder";
import { AuthApp } from "./AuthApp";
import { MemberApp } from "./MemberApp";
import { useWSStore } from "../../store";
import { RouteAuth1099 } from "../../modules/1099NECFiling/routes/SignUp/RouteAuth1099";
import { useCustomization } from "../../modules/customization";
import { useInitCustomization } from "../../modules/customization/initCustomization";

const InitUser: React.FC = () => {
  // Init identify hook
  useIdentify();
  return null;
};

export const Main = () => {
  const [canInitUser, setCanInitUser] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const store = useWSStore();

  useEffect(() => {
    loadSegment();
  }, []);

  const sessionQuery = useSession({
    retry: false
  });

  const initCustomization = useInitCustomization(sessionQuery);

  useEffect(() => {
    // pass history push to test env
    // @ts-ignore
    window._WINGSPAN_HISTORY_PUSH_ = history.push;
  }, [history.push]);

  // TODO: Make a real fix
  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setCanInitUser(true);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (sessionQuery.isLoading || initCustomization.isLoading) {
    return <LoadingPlaceholder blank />;
  }

  return (
    <>
      {canInitUser && sessionQuery.data?.userId && <InitUser />}
      {sessionQuery.isSuccess && sessionQuery.data?.token ? (
        <>
          <Switch>
            <Route
              path="/x/1099"
              render={() => <RouteAuth1099 session={sessionQuery.data} />}
            />

            {sessionQuery.data?.userId ? (
              <Route path="/member" component={MemberApp} />
            ) : null}

            <Route key="payment" path="/payment" component={ClientPayments} />
            <Redirect
              from="/invoice-payment/:invoiceId"
              to="/payment/:invoiceId"
            />
            <Redirect from="/i/:invoiceId" to="/payment/:invoiceId" />
            {!!params.get("growth_name") ? null : (
              <Redirect from="/p/:invoiceId" to="/payment/:invoiceId" />
            )}
            <Redirect from="/pay/:tag" to="/payment/ppl/:tag" />
            <Redirect from="/ppl/:tag" to="/payment/ppl/:tag" />

            {sessionQuery.data?.userId ? (
              <Switch>
                {store.signUpAutopayDetails?.memberClientId ? (
                  <Redirect
                    from="/autopay/:tag"
                    to={{
                      pathname: `/member/autopay/${store.signUpAutopayDetails.memberClientId}`
                    }}
                  />
                ) : store.signUpAutopayDetails?.payeeId ? (
                  <Redirect
                    from="/autopay/:tag"
                    to={{
                      pathname: `/member/autopay/${store.signUpAutopayDetails.payeeId}`
                    }}
                  />
                ) : null}
                <Redirect from="/" to="/member" />
                <Redirect from="*" to="/" />
              </Switch>
            ) : (
              <AuthApp />
            )}
          </Switch>
        </>
      ) : (
        <AuthApp />
      )}
    </>
  );
};
