import cn from "classnames";
import React from "react";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { ComponentWithKinds } from "../../WSElement/WSElement.helpers";
import styles from "./WSPanel.module.scss";
import { WSIcon } from "../WSIcon/WSIcon.component";

export enum WSPanelKindMap {
  BS0 = "BS0",
  BS1 = "BS1",
  BS2 = "BS2",
  BS3 = "BS3"
}

export type WSPanelKind = keyof typeof WSPanelKindMap;

export interface WSPanelProps extends WSElementProps {
  kind?: WSPanelKind;
  noBorder?: boolean;
  onClose?: () => void;
}

export const WSPanel: ComponentWithKinds<WSPanelProps, WSPanelKind> = ({
  className,
  children,
  kind = "BS0",
  colorBackground = "white",
  noBorder,
  onClose,
  ...componentProps
}) => {
  const classNames = cn(className, styles.base, styles[kind], {
    [styles.noBorder]: noBorder
  });
  return (
    <WSElement
      className={classNames}
      p="XL"
      colorBackground={colorBackground}
      {...componentProps}
    >
      {onClose && (
        <WSIcon
          block
          name="exit"
          onClick={onClose}
          className={styles.closeIcon}
        />
      )}
      {children}
    </WSElement>
  );
};

WSPanel.BS0 = (props) => <WSPanel kind="BS0" {...props} />;
WSPanel.BS1 = (props) => <WSPanel kind="BS1" {...props} />;
WSPanel.BS2 = (props) => <WSPanel kind="BS2" {...props} />;
WSPanel.BS3 = (props) => <WSPanel kind="BS3" {...props} />;
