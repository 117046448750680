import {
  ISharedFileRequestResponse,
  ISharedFileRequestUpdateRequest
} from "@wingspanhq/shared-files/dist/lib/interfaces";
import { serviceSharedFiles } from "./serviceSharedFiles";

export const updateSharedFile = async (
  sharedFileId: string,
  request: ISharedFileRequestUpdateRequest
) => {
  const { data } = await serviceSharedFiles.patch<ISharedFileRequestResponse>(
    `/request/${sharedFileId}`,
    request
  );

  return data;
};
