import {
  WSLoader,
  WSPage,
  WSPageToolbar
} from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PATH_SETTINGS } from "../..";
import { Tabs } from "../../../../components/Tabs";
import { useQueryCustomerEntity } from "../../../../query/onboarding/queries/useQueryCustomerEntity";
import { RouteAccount as RouteAccountTab } from "./routes/RouteAccount";
import { RouteBusinessInformation } from "./routes/RouteBusinessInformation";
import { RoutePersonalInformation } from "./routes/RoutePersonalInformation";

export const PATH_SETTINGS_ACCOUNT = PATH_SETTINGS + "/account";
export const PATH_SETTINGS_ACCOUNT_ACCOUNT = PATH_SETTINGS_ACCOUNT + "/account";
export const PATH_SETTINGS_ACCOUNT_PERSONAL =
  PATH_SETTINGS_ACCOUNT + "/personal";
export const PATH_SETTINGS_ACCOUNT_BUSINESS =
  PATH_SETTINGS_ACCOUNT + "/business";

export const RouteAccount = () => {
  const queryMonitoredEntity = useQueryCustomerEntity();

  const tabs = useMemo(() => {
    if (queryMonitoredEntity.data?.type === "Business") {
      return [
        {
          label: "Account",
          path: PATH_SETTINGS_ACCOUNT_ACCOUNT
        },
        {
          label: "Business information",
          path: PATH_SETTINGS_ACCOUNT_BUSINESS
        }
      ];
    }

    return [
      {
        label: "Account",
        path: PATH_SETTINGS_ACCOUNT_ACCOUNT
      },
      {
        label: "Personal information",
        path: PATH_SETTINGS_ACCOUNT_PERSONAL
      }
    ];
  }, []);

  if (queryMonitoredEntity.isLoading) {
    return (
      <WSPage>
        <WSLoader.Spinner />
      </WSPage>
    );
  }

  return (
    <WSPage>
      <WSPageToolbar title="Account settings" />

      <Tabs mb="2XL" tabs={tabs} />

      <Switch>
        <Route
          path={PATH_SETTINGS_ACCOUNT_ACCOUNT}
          component={RouteAccountTab}
        />
        <Route
          path={PATH_SETTINGS_ACCOUNT_PERSONAL}
          component={RoutePersonalInformation}
        />
        <Route
          path={PATH_SETTINGS_ACCOUNT_BUSINESS}
          component={RouteBusinessInformation}
        />

        <Redirect to={PATH_SETTINGS_ACCOUNT_ACCOUNT} />
      </Switch>
    </WSPage>
  );
};
