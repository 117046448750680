import { IOrganizationAccountConfig } from "@wingspanhq/users/dist/lib/interfaces";
import { serviceCustomization } from "./serviceCustomization";
import { DeepPartial } from "../../utils";

export const updateCustomization = async (
  userId: string,
  request: DeepPartial<IOrganizationAccountConfig>
): Promise<IOrganizationAccountConfig> => {
  const response = await serviceCustomization.patch(
    `/customization/${userId}`,
    request
  );
  return response.data;
};
