import {
  useWSModal,
  WSButton,
  WSButtons,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ICollaboratorSchema,
  IPayerTaxFormResponse,
  ITaxFormResponse,
  VerificationStatus
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { FlowSetup } from "../../../../shared/components/FlowSetup";
import { RouteCertifyW9 } from "./RouteCertifyW9";
import { RouteMatchingTin } from "./RouteMatchingTin";
import { RouteNecAmount } from "./RouteNecAmount";
import { RoutePersonalDetails } from "./RoutePersonalDetails";
import { RouteTaxpayerInformation } from "./RouteTaxpayerInformation";
import { RouteTinVerified } from "./RouteTinVerified";

export type SetupParams = {
  collaborator?: ICollaboratorSchema;
  taxForm?: IPayerTaxFormResponse;
  provideInfo?: "yes" | "no";
};

const ModalCancelWarning: React.FC<{
  onClose: () => void;
  isEdit?: boolean;
}> = ({ onClose, isEdit }) => {
  const history = useHistory();

  return (
    <>
      <WSText.Heading5 mb="M">Are you sure you want to cancel?</WSText.Heading5>
      <WSText mb="M">
        {isEdit
          ? "Any unsaved changes and information will be lost if you choose to cancel. You can edit the recipient’s tax information on the recipient’s detail page from the dashboard. Recipient information must be complete in order to be ready for filing."
          : "Any unsaved changes and information will be lost if you choose to cancel. The recipient will not be added."}
      </WSText>
      <WSButtons forceFullWidth>
        <WSButton
          destructive
          onClick={() => {
            history.push("/member/1099-filing/dashboard/all");
          }}
        >
          Yes, cancel
        </WSButton>
        <WSButton.Secondary onClick={onClose}>No, continue</WSButton.Secondary>
      </WSButtons>
    </>
  );
};

export const RouteNewRecipientFlow: React.FC = () => {
  const history = useHistory();
  const basePath = "/member/1099-filing/add-recipient";
  const [collaborator, setCollaborator] = React.useState<
    ICollaboratorSchema | undefined
  >();
  const [taxForm, setTaxForm] = React.useState<ITaxFormResponse | undefined>();
  const [provideInfo, setProvideInfo] = React.useState<
    "yes" | "no" | undefined
  >();
  const modalCancel = useWSModal(ModalCancelWarning, { size: "S" });

  return (
    <FlowSetup
      basePath={basePath}
      params={{ collaborator, taxForm, provideInfo }}
      routes={[
        {
          path: "/personal-details",
          component: RoutePersonalDetails,
          onNext: context => {
            setCollaborator(context?.collaborator);
            if (context?.taxForm) {
              setTaxForm(context.taxForm);
            }

            history.push(`${basePath}/taxpayer-information`);
          },
          onBack: () => {
            modalCancel.open();
          }
        },
        {
          path: "/taxpayer-information",
          component: RouteTaxpayerInformation,
          onNext: context => {
            if (context?.taxForm) {
              setTaxForm(context.taxForm);
            }

            setProvideInfo(context?.provideInfo);

            if (context?.provideInfo === "yes") {
              if (context?.status === VerificationStatus.Verified) {
                history.push(`${basePath}/tin-verified`);
              } else {
                history.push(`${basePath}/matching-tin`);
              }
            } else {
              history.push(`${basePath}/nec-amount`);
            }
          },
          onBack: skipConfirm => {
            if (skipConfirm) {
              history.push("/member/1099-filing/dashboard/all");
            } else {
              modalCancel.open({ isEdit: true });
            }
          }
        },
        {
          path: "/matching-tin",
          component: RouteMatchingTin,
          onNext: context => {
            if (context?.taxForm) {
              setTaxForm(context.taxForm);
            }

            if (context?.status === VerificationStatus.Verified) {
              history.push(`${basePath}/tin-verified`);
            } else if (context?.status === VerificationStatus.Pending) {
              history.push(`${basePath}/certify-w9`);
            } else {
              history.push(`${basePath}/taxpayer-information`);
            }
          },
          onBack: () => {
            modalCancel.open({ isEdit: true });
          }
        },
        {
          path: "/tin-verified",
          component: RouteTinVerified,
          onNext: () => {
            history.push(`${basePath}/certify-w9`);
          },
          onBack: () => {
            modalCancel.open({ isEdit: true });
          }
        },
        {
          path: "/certify-w9",
          component: RouteCertifyW9,
          onNext: () => {
            history.push(`${basePath}/nec-amount`);
          },
          onBack: () => {
            modalCancel.open({ isEdit: true });
          }
        },
        {
          path: "/nec-amount",
          component: RouteNecAmount,
          onNext: () => {
            history.push("/member/1099-filing/details/" + taxForm?.taxFormId);
          },
          onBack: () => {
            modalCancel.open({ isEdit: true });
          }
        }
      ]}
    />
  );
};
