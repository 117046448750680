import {
  INormalizedScopeGroupId,
  scopeGroups
} from "../../../shared/utils/teamUtils";
import { WSText } from "@wingspanhq/fe-component-library";
import uniq from "lodash/uniq";

export const getPermissionScopesList = (
  scopeGroupIds: Array<INormalizedScopeGroupId>
) => {
  return uniq(
    scopeGroupIds.map(({ allowedScopeGroupId }: INormalizedScopeGroupId) => {
      return scopeGroups[allowedScopeGroupId as keyof typeof scopeGroups].title;
    })
  ).sort();
};

export const getPermissionScopesCell = (
  scopeGroupIds: Array<INormalizedScopeGroupId>
) => {
  const scopesList = getPermissionScopesList(scopeGroupIds);

  if (scopesList.length === 0) {
    return "No permissions";
  } else {
    return (
      scopesList.slice(0, 2).join(", ") +
      (scopesList.length > 2 ? ` + ${scopesList.slice(2).length} more` : "")
    );
  }
};
