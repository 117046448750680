import {
  WSElement,
  WSText,
  WSTimeline,
  toWSDateString,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import {
  IInvoice,
  IInvoicePayment,
  InvoicePaymentStatusReason
} from "@wingspanhq/payments/dist/interfaces";
import capitalize from "lodash/capitalize";
import { TextBulleted } from "../../../shared/components/TextBulleted";

type Props = {
  invoice: IInvoice;
};

const statusReasonMap = {
  [InvoicePaymentStatusReason.InsufficientFunds]: "Insufficient funds",
  [InvoicePaymentStatusReason.AccountNotFound]: "Account not found",
  [InvoicePaymentStatusReason.Unauthorized]: "Unauthorized",
  [InvoicePaymentStatusReason.GenericBankError]: "Bank error",
  [InvoicePaymentStatusReason.GenericCardError]: "Card error",
  [InvoicePaymentStatusReason.CardCurrencyNotSupported]:
    "Card currency not supported",
  [InvoicePaymentStatusReason.CardExpired]: "Card expired",
  [InvoicePaymentStatusReason.CardInputInvalid]: "Invalid card input"
};

const getEventDetails = (
  event: keyof IInvoicePayment["events"],
  date: Date
) => {
  switch (event) {
    case "failedAt":
      return `Payment failed (${toWSDateString(date, "monthDayYear")})`;
    case "pendingAt":
      return `Payment pending (${toWSDateString(date, "monthDayYear")})`;
    case "sentAt":
      return `Payment sent (${toWSDateString(date, "monthDayYear")})`;
    case "clearedAt":
      return `Payment cleared (${toWSDateString(date, "monthDayYear")})`;
    case "returnedAt":
      return `Payment returned (${toWSDateString(date, "monthDayYear")})`;
  }
};

export const InvoicePayments: React.FC<Props> = ({ invoice }) => {
  return (
    <>
      <WSTimeline
        items={invoice.payments.map(payment => {
          const events = Object.keys(payment.events).map(key => ({
            event: key as keyof IInvoicePayment["events"],
            date: payment.events[key as keyof IInvoicePayment["events"]] as Date
          }));

          events.sort((a, b) => a.date.getTime() - b.date.getTime());

          return {
            date: payment.createdAt,
            badges: [
              payment.status,
              `${toWSMoneyString(payment.amount)} payment`
            ],
            content: (
              <>
                {payment.sourceMetadata.institution && (
                  <WSElement mb="M">
                    <WSText.ParagraphSm color="gray400">
                      Payment method:{" "}
                    </WSText.ParagraphSm>
                    <WSText color="gray600">
                      {capitalize(payment.sourceMetadata.institution)}
                      {payment.sourceMetadata.mask
                        ? ` (${payment.sourceMetadata.mask})`
                        : ""}
                    </WSText>
                  </WSElement>
                )}

                <TextBulleted color="gray600">
                  {events.map(event => (
                    <li key={event.event}>
                      <WSText.ParagraphSm>
                        {getEventDetails(event.event, event.date)}
                      </WSText.ParagraphSm>
                      {(event.event === "failedAt" ||
                        event.event === "returnedAt") &&
                        payment.statusReason && (
                          <WSText.ParagraphSm color="gray600">
                            {statusReasonMap[payment.statusReason]}
                          </WSText.ParagraphSm>
                        )}
                    </li>
                  ))}
                </TextBulleted>
              </>
            )
          };
        })}
      />
    </>
  );
};
