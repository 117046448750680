import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { EditLayout } from "../../components/EditLayout";
import { OverlaySpinner } from "../../../../components/OverlaySpinner";
import { track } from "../../../../utils/analytics";
import { useCreateBulkPayableBatch } from "../../../BulkImporter/query/bulkPayable/mutations";
import { BatchProcessingStrategy } from "@wingspanhq/payments/dist/interfaces";

export const RouteInitImportPayments: React.FC = ({}) => {
  const history = useHistory();
  const [createBulkPayableBatch] = useCreateBulkPayableBatch();

  useEffect(() => {
    createBulkPayableBatch(
      {
        processingStrategy: BatchProcessingStrategy.Single
      },
      {
        onSuccess: bulkBatch => {
          track("1099 Filing Bulk Upload Payments Started");
          history.replace(
            `/member/1099-filing/batch-upload-payments/${bulkBatch.bulkPayableBatchId}`
          );
        }
      }
    );
  }, []);

  return (
    <EditLayout title="Batch upload payments">
      <OverlaySpinner />
    </EditLayout>
  );
};
