import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import {
  IActivateCardRequest,
  ICardResponse
} from "../../../services/api/banking/cards/types";
import { QUERY_CARD } from "../queries/useCardQuery";
import { QUERY_CARDS } from "../queries/useCardsQuery";
import { activateCard } from "../../../services/api/banking/cards/activateCard";

export const useActivateCard = () =>
  useWSMutation<
    ICardResponse,
    WSServiceError,
    {
      cardId: string;
    } & IActivateCardRequest
  >(
    ({ cardId, ...request }) => {
      return activateCard(cardId, request);
    },
    {
      dependencies(response, { cardId }) {
        return [[QUERY_CARD, cardId], QUERY_CARDS];
      }
    }
  );
