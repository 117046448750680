import {
  IPayerEngagementResponse,
  PayerPayeeEngagementStatus
} from "@wingspanhq/payments/dist/interfaces";
import { getTmpEngagementName } from "./getTmpEngagementName";
import { uniqBy } from "lodash";
import { ISearchablePayerPayeeEngagement } from "../../../services/search";

export const selectorPayerEngagementsTmp = <
  T extends {
    status: PayerPayeeEngagementStatus;
    payeeOwnedData?: {
      legacyPayerName?: string;
      legacyPayerCompanyName?: string;
    };
  }
>(
  engagements: T[],
  forceShowAll: boolean,
  includeInactiveEngagements: boolean = true
): T[] => {
  if (forceShowAll) {
    return engagements;
  }

  if (!includeInactiveEngagements) {
    engagements = engagements.filter(
      engagement => engagement.status === PayerPayeeEngagementStatus.Active
    );
  }

  if (engagements.every(eng => eng.payeeOwnedData?.legacyPayerName)) {
    return uniqBy(engagements, eng => eng.payeeOwnedData?.legacyPayerName);
  }

  if (engagements.every(eng => eng.payeeOwnedData?.legacyPayerCompanyName)) {
    return uniqBy(
      engagements,
      eng => eng.payeeOwnedData?.legacyPayerCompanyName
    );
  }

  const uniqEngagements = uniqBy(
    engagements,
    eng =>
      eng.payeeOwnedData?.legacyPayerName ||
      eng.payeeOwnedData?.legacyPayerCompanyName
  );

  if (uniqEngagements.length === 1) {
    return uniqEngagements;
  }

  return uniqEngagements.filter(
    eng =>
      eng.payeeOwnedData?.legacyPayerName ||
      eng.payeeOwnedData?.legacyPayerCompanyName
  );
};

export const selectorPayerEngagementNameTmp = <D extends boolean = boolean>(
  engagement: IPayerEngagementResponse | ISearchablePayerPayeeEngagement,
  showDefaultName: D
) => {
  if (
    "engagementId" in engagement &&
    "engagementName" in engagement &&
    engagement.engagementId &&
    engagement.engagementName
  ) {
    return engagement.engagementName;
  }

  if (engagement.payeeOwnedData?.legacyPayerName) {
    return engagement.payeeOwnedData?.legacyPayerName;
  }

  if (engagement.payeeOwnedData?.legacyPayerCompanyName) {
    return engagement.payeeOwnedData?.legacyPayerCompanyName;
  }

  if (showDefaultName) {
    return getTmpEngagementName(engagement);
  }

  return undefined as D extends true ? string : string | undefined;
};
