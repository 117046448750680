import { WSService } from "../../../utils/WSService";
import { components, operations } from "./types";

const service = new WSService("/v2/onboarding/customer");

export const getCustomerEntity = async (): Promise<operations["getCustomerEntity"]["responses"]["200"]["content"]["application/json"]> => {
  const { data } = await service.get("");
  return data;
};

export const createCustomerEntity = async (
  requestData: components["schemas"]["CustomerEntity"]
): Promise<operations["createCustomerEntity"]["responses"]["200"]["content"]["application/json"]> => {
  const { data } = await service.post("", requestData);
  return data;
};

export const updateCustomerData = async (
  relationshipType: operations["updateCustomerData"]["parameters"]["path"]["relationshipType"],
  requestData: operations["updateCustomerData"]["requestBody"]["content"]["application/json"]
): Promise<operations["updateCustomerData"]["responses"]["200"]["content"]["application/json"]> => {
  const { data } = await service.patch("/" + relationshipType, requestData);
  return data;
};
