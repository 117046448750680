import { WSElement, WSElementProps } from "@wingspanhq/fe-component-library";
import React from "react";
import { WSQueries } from "../../../query/WSQuery";
import { useUserId } from "../../../query/hooks/helpers";
import { useMemberProfile, useUserProfile } from "../../../query/users/queries";
import { Form } from "./Form";
import { ModalSsnDescription } from "./ModalSsnDescription";
import { ModalWhatIsUSPerson } from "./ModalWhatIsUSPerson";
import { useMutationSubmit } from "./useMutationSubmit";

type Props = {
  skipTaxVerification?: boolean;
  onUpdateBusinessInfo?: () => void;
  onSuccess: () => void;
} & WSElementProps;

export const FormPersonalInfo: React.FC<Props> = ({
  skipTaxVerification,
  onSuccess,
  onUpdateBusinessInfo,
  ...elementProos
}) => {
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const [submit, submitMeta] = useMutationSubmit({ onSuccess });

  return (
    <WSElement {...elementProos}>
      <WSQueries
        queries={{
          queryUser,
          queryMember
        }}
      >
        {({ queryUser: { data: user }, queryMember: { data: member } }) => {
          return (
            <Form
              user={user}
              member={member}
              skipTaxVerification={skipTaxVerification}
              onSubmit={submit}
              onUpdateBusinessInfo={onUpdateBusinessInfo}
              error={submitMeta.error}
              isLoading={submitMeta.isLoading}
            />
          );
        }}
      </WSQueries>

      <ModalWhatIsUSPerson />
      <ModalSsnDescription />
    </WSElement>
  );
};
