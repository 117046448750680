import { ICardResponse } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSInputMaskOld,
  WSSwitchInput,
  WSText
} from "@wingspanhq/fe-component-library";
import { ICardCodeResponse } from "@wingspanhq/payments/dist/interfaces/api/card";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import {
  useCreateCardToken,
  useUpdateCardToken
} from "../../../query/payments/mutations";
import { ErrorContextKey } from "../../../services/platform";
import { IS_PRODUCTION_ENV } from "../../../shared/constants/environment";
import { censorPhone } from "../../../utils/stringHelper";
import styles from "./DebitCard.module.scss";

export interface DebitCardProps {
  card: ICardResponse;
}

export const DebitCard: React.FC<DebitCardProps> = ({ card }) => {
  const [
    cardCodeResponse,
    setCardCodeResponse
  ] = useState<ICardCodeResponse | null>(null);
  const [createCardToken, createCardTokenMeta] = useCreateCardToken();
  const [updateCardToken, updateCardTokenMeta] = useUpdateCardToken();
  const [showCardInfo, setShowCardInfo] = useState(false);
  const [showCodeInputForm, setShowCodeInputForm] = useState(false);
  const debitCardNumberRef = useRef<HTMLElement | null>(null);
  const debitCardCVVRef = useRef<HTMLElement | null>(null);

  const onCodeSubmit = async (data: { otp: string }) => {
    const updateCardTokenRes = await updateCardToken(
      {
        id: card.cardId,
        verificationToken:
          (cardCodeResponse?.verificationToken as string) || "",
        verificationCode: data.otp
      },
      {
        onSuccess: () => {
          setShowCodeInputForm(false);
        }
      }
    );

    const vgVaultKey = IS_PRODUCTION_ENV ? "tnt8w6nrmbu" : "tntazhyknp1";

    // @ts-ignore
    const show = VGSShow.create(vgVaultKey);
    const cardNumber = show.request({
      name: "data-text",
      method: "GET",
      path: `/cards/${updateCardTokenRes?.cardId}/secure-data/pan`,
      headers: {
        Authorization: `Bearer ${updateCardTokenRes?.token}`
      },
      htmlWrapper: "text",
      serializers: [
        show.SERIALIZERS.replace(
          "(\\d{4})(\\d{4})(\\d{4})(\\d{4})",
          "$1 $2 $3 $4"
        )
      ],
      jsonPathSelector: "data.attributes.pan"
    });
    cardNumber.render(debitCardNumberRef.current, {
      width: "100%",
      height: "100%",
      fontSize: "18px",
      display: "block",
      background: "#057ccc",
      color: "white",
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif'
    });

    const cardCVV = show.request({
      name: "data-text",
      method: "GET",
      path: `/cards/${updateCardTokenRes?.cardId}/secure-data/cvv2`,
      headers: {
        Authorization: `Bearer ${updateCardTokenRes?.token}`
      },
      htmlWrapper: "text",
      jsonPathSelector: "data.attributes.cvv2"
    });

    cardCVV.render(debitCardCVVRef.current, {
      width: "100%",
      height: "100%",
      fontSize: "18px",
      background: "#057ccc",
      color: "white",
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif'
    });
  };

  const onReveal = async (value: any) => {
    setShowCardInfo(!showCardInfo);
    setShowCodeInputForm(value);
    if (value) {
      const createCardTokenRes = await createCardToken({
        id: card.cardId,
        channel: "sms"
      });
      setCardCodeResponse(createCardTokenRes as ICardCodeResponse);
    }
  };
  const getDisplayExpiryDate = (expiryDate: string): string => {
    const expiryDateParts = expiryDate.split("-");
    return `${expiryDateParts[1]}/${expiryDateParts[0].substring(2)}`;
  };
  return (
    <WSFlexBox.Center direction="column">
      {showCardInfo ? (
        <WSElement className={styles.card}>
          <WSElement className={styles.cardNumber} ref={debitCardNumberRef} />
          <WSElement className={styles.expiry}>
            <WSText.ParagraphSm weight="medium" color="white">
              {getDisplayExpiryDate(card.expirationDate)}
            </WSText.ParagraphSm>
          </WSElement>
          <WSElement className={styles.cvv} ref={debitCardCVVRef} />
        </WSElement>
      ) : (
        <WSElement className={styles.card}>
          <WSElement className={styles.maskedCardNumber}>
            <WSText.Heading5 color="white">
              {`**** **** **** ${card.last4Digits}`}
            </WSText.Heading5>
          </WSElement>
          <WSElement className={styles.expiry}>
            <WSText.ParagraphSm weight="medium" color="white">
              {getDisplayExpiryDate(card.expirationDate)}
            </WSText.ParagraphSm>
          </WSElement>
        </WSElement>
      )}
      <WSFlexBox.Center mb="XL">
        <WSText mr="M" color={showCardInfo ? "gray500" : "gray700"}>
          Hide
        </WSText>
        <WSSwitchInput
          name="showCardInfo"
          value={showCardInfo}
          onChange={onReveal}
        />
        <WSText ml="M" color={showCardInfo ? "gray700" : "gray500"}>
          Show
        </WSText>
      </WSFlexBox.Center>
      {showCodeInputForm ? (
        <WSFlexBox.Center direction="column" alignItems="stretch" p="M">
          <WSText.Heading5 mb="M" align="center">
            Additional verification required to reveal card details
          </WSText.Heading5>
          <WSText color="gray600" mb="XL" align="left">
            A 6 digit verification code has been sent <br />
            via SMS to{" "}
            {cardCodeResponse?.phoneNumber
              ? censorPhone(cardCodeResponse?.phoneNumber)
              : "your registered mobile number"}
            .
          </WSText>
          <WSFormOld
            onSubmit={(data: { otp: string }) => onCodeSubmit(data)}
            validationSchema={Yup.object().shape({
              otp: Yup.string()
                .required("OTP is required")
                .matches(/^[0-9]+$/, "Must be only digits")
                .min(6, "Must be exactly 6 digits")
                .max(6, "Must be exactly 6 digits")
            })}
            defaultValues={{ otp: "" }}
          >
            <WSFormOld.Field
              mb="XS"
              name="otp"
              component={WSInputMaskOld}
              componentProps={{
                autoComplete: "one-time-code",
                mask: "666666"
              }}
            />
            <WSErrorMessage
              my="M"
              error={createCardTokenMeta.error}
              contextKey={ErrorContextKey.Other}
            />
            <WSFormOld.SubmitButton
              name="confirmTwoFactorAuth"
              mt="3XL"
              fullWidth
              loading={updateCardTokenMeta.isLoading}
            >
              Continue
            </WSFormOld.SubmitButton>
          </WSFormOld>
        </WSFlexBox.Center>
      ) : null}
    </WSFlexBox.Center>
  );
};
