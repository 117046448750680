import { ICollaboratorV2 } from "@wingspanhq/payments/dist/interfaces";

export type FillingYear = keyof ICollaboratorV2["form1099Balances"];

const CURRENT_FILING_YEAR: FillingYear = 2022;

const getCurrentFilingYear = (): FillingYear => {
  return CURRENT_FILING_YEAR;
};

export const getCurrentFilingYearStr = () => String(getCurrentFilingYear());

export default getCurrentFilingYear;
