import React from "react";
import {
  toWSDateString,
  useWSSnackbar,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import { IMemberClientRequirementResponse } from "@wingspanhq/payments/dist/interfaces";
import { CellsConfig, RequirementTableCallback } from "../../../../types";
import { RequirementStatus } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { SIGNATURE_REQUIREMENT_EVENT } from "../utils";
import {
  useCountersignDocument,
  useDownloadClientDocument
} from "../../../../../../query/files/mutations";
import {
  WSTableAction,
  WSTableRowMenuAction
} from "@wingspanhq/fe-component-library/dist/lib/components/WSTable/types";
import { selectorIsMemberClientRequirementRequirementReadyToSignAsPayer } from "../../../../selectors/selectorIsRequirementReadyToSignAsPayer";
import { selectorRequirementHumanReadableType } from "../../../../selectors/selectorRequirementHumanReadableType";

export const usePayeeSignatureMemberClientRequirementResponseConfig = (
  callback: RequirementTableCallback
): CellsConfig<IMemberClientRequirementResponse> => {
  const { openSnackbar } = useWSSnackbar();
  const [signAsPayer, signAsPayerMeta] = useCountersignDocument();
  const [
    downloadPDFAsPayer,
    downloadPDFAsPayerMeta
  ] = useDownloadClientDocument();

  const handleRequirementDownload = async (
    requirement: IMemberClientRequirementResponse
  ) => {
    await downloadPDFAsPayer(
      {
        documentId: requirement.documentId!
      },
      {
        onSuccess() {
          callback(SIGNATURE_REQUIREMENT_EVENT.DownloadAsPayer, requirement);
        }
      }
    );
  };

  const handleRequirementCountersign = async (
    requirement: IMemberClientRequirementResponse
  ) => {
    await signAsPayer(
      { documentId: requirement.documentId! },
      {
        onSuccess() {
          callback(SIGNATURE_REQUIREMENT_EVENT.SignAsPayer, requirement);
        }
      }
    );
  };

  return {
    nameCell: requirement => {
      return (
        <WSTableCell
          text={requirement.document?.title}
          secondaryText={
            requirement.status === RequirementStatus.Pending &&
            requirement.document?.events?.memberSignedAt
              ? "Needs your signature"
              : requirement.status === RequirementStatus.Complete &&
                requirement.document?.events?.clientSignedAt
              ? "Completed " +
                toWSDateString(
                  requirement.document.events.clientSignedAt,
                  "monthDayYear"
                )
              : requirement.status === RequirementStatus.Complete &&
                requirement.document?.events?.memberSignedAt
              ? "Completed " +
                toWSDateString(
                  requirement.document.events?.memberSignedAt,
                  "monthDayYear"
                )
              : requirement.status === RequirementStatus.Complete
              ? "Completed " + toWSDateString(new Date(), "monthDayYear")
              : "Incomplete"
          }
        />
      );
    },
    typeCell: requirement => {
      return (
        <WSTableCell
          icon="pen"
          text={selectorRequirementHumanReadableType(
            requirement.requirementType
          )}
        />
      );
    },
    statusCell: requirement => {
      return (
        <WSTableCell
          pill={
            requirement.status === RequirementStatus.Complete
              ? {
                  theme: "success",
                  text: "Complete",
                  icon: true
                }
              : {
                  theme: "warning",
                  text: "Pending",
                  icon: true
                }
          }
          secondaryText={
            requirement.status === RequirementStatus.Pending
              ? requirement.document?.events?.memberSignedAt &&
                !requirement.document?.events?.clientSignedAt
                ? "Your signature required"
                : requirement.document?.events?.clientSignedAt &&
                  !requirement.document?.events?.memberSignedAt
                ? "Awaiting contractor signature"
                : ""
              : ""
          }
        />
      );
    },
    rowActions: requirement => {
      const actions: WSTableAction[] = [];

      if (
        selectorIsMemberClientRequirementRequirementReadyToSignAsPayer(
          requirement
        )
      ) {
        actions.push({
          name: "SignDocument",
          onAsyncClick: async () => {
            await handleRequirementCountersign(requirement);
          },
          text: "Start",
          kind: "Link",
          hideOn: []
        });
      }

      return actions;
    },
    rowMenuActions: requirement => {
      const actions: WSTableRowMenuAction<
        IMemberClientRequirementResponse
      >[] = [];

      const isDocumentReadyToDownload =
        requirement.document?.documentId &&
        requirement.status === RequirementStatus.Complete;

      if (isDocumentReadyToDownload) {
        actions.push({
          onAsyncClick: async () => {
            await handleRequirementDownload(requirement);
          },
          label: "Download PDF",
          icon: "download"
        });
      }

      if (
        selectorIsMemberClientRequirementRequirementReadyToSignAsPayer(
          requirement
        )
      ) {
        actions.push({
          onAsyncClick: async () => {
            await handleRequirementCountersign(requirement);
          },
          label: "Countersign",
          icon: "file"
        });
      }

      // hide actions if only sign is available
      return isDocumentReadyToDownload ? actions : [];
    }
  };
};
