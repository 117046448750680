import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation, WSMutationConfig } from "../../helpers";
import { paymentsService } from "../../../services/payments";

export const useTaxFormDownloadDocument = (
  config?: WSMutationConfig<
    Blob,
    WSServiceError,
    Parameters<typeof paymentsService.taxForm.downloadDocument>[0]
  >
) =>
  useWSMutation(data => paymentsService.taxForm.downloadDocument(data), {
    ...config
  });
