import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { useCallback, useState } from "react";
import { getAllEntries } from "../../../utils/serviceHelper";

import { downloadCSV } from "../../../modules/Reports/utils/sheetjs/downloadCSV";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useCollaboratorsQuery,
  usePayrollSettings
} from "../../../query/payments/queries";
import { getPayables } from "../../../query/payments/selectors";
import { paymentsService } from "../../../services/payments";
import { mapPayablesTableToCSV } from "../../screens/payables/csvUtils";
import { getMatchingPayroll } from "../../utils/payables";
import { PayablesFilters, mapPayableFilters } from "./PayablesTable";

export const useDownloadCsv = (showPayrollGroups?: boolean) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { openSnackbar } = useWSSnackbar();
  const userId = useUserId();
  const payrollSettingsQuery = usePayrollSettings(userId);
  const queryCollaborators = useCollaboratorsQuery();

  const downloadCsv = useCallback(
    async (filters: PayablesFilters) => {
      try {
        setIsLoading(true);
        setError(null);

        const filter = mapPayableFilters(filters);

        const payables = getPayables(
          await getAllEntries(async (...args) => {
            return (await paymentsService.payable.list(...(args as any))).data;
          }, filter)
        );

        const csvData = mapPayablesTableToCSV(
          payables.map(payable => ({
            ...(showPayrollGroups && payrollSettingsQuery.data
              ? {
                  payrollRun: getMatchingPayroll(
                    payrollSettingsQuery.data,
                    payable
                  )
                }
              : {}),
            payable,
            collaborator: (queryCollaborators.data || []).find(
              collaborator =>
                collaborator.collaboratorId === payable.collaboratorId
            )
          }))
        );

        downloadCSV(csvData, "Invoices");

        openSnackbar({
          type: "success",
          message: "CSV downloaded"
        });
      } catch (error) {
        setError(error as any);

        console.error("Failed to download CSV", error);

        openSnackbar({
          type: "warning",
          message: "Failed to download CSV"
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      openSnackbar,
      payrollSettingsQuery.data,
      queryCollaborators.data,
      showPayrollGroups
    ]
  );

  return {
    downloadCsv,
    isLoading,
    error
  };
};
