import { IFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { IIntegrationsQuickbooksResponse } from "@wingspanhq/integrations/dist/lib/interfaces/quickbooks-api/integrationsQuickbooks";
import { isQuickbooksServiceActive } from "../../query/integrations/selectors";

export const selectorIsQBOActive = (
  featureFlags?: IFeatureFlags,
  qboService?: IIntegrationsQuickbooksResponse
) =>
  qboService &&
  (featureFlags?.forceShowQBO || isQuickbooksServiceActive(qboService));
