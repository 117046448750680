import { Intervals } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { RouteComponentProps } from "react-router";
import { isToday } from "../../utils/dates";
import { InvoicesForm } from "../components/InvoicesForm/InvoicesForm";
import { useDefaultInvoiceSubject } from "../utils";
import { defaultFrequency } from "../utils/invoicesFormUtils";

export const InvoicesCreate: React.FC<RouteComponentProps<
  any,
  any,
  {
    email?: string;
    memberClientId?: string;
    sendDate?: Date;
    isRecurring?: boolean;
    payerId?: string;
    payerPayeeEngagementId?: string;
  }
>> = ({ location }) => {
  const {
    email,
    sendDate,
    isRecurring,
    payerId = null,
    payerPayeeEngagementId = null
  } = location.state || {};
  const defaultSubject = useDefaultInvoiceSubject();

  return (
    <InvoicesForm
      defaultValues={{
        client: {
          payerId,
          payerPayeeEngagementId,
          emailsCC: []
        },
        subject: defaultSubject,
        email,
        lineItems: [
          {
            description: "",
            rate: "fixed",
            customUnit: "",
            remove: "",
            discount: {
              type: "percentage",
              description: ""
            },
            reimbursableExpense: false
          }
        ],
        other: {
          projectName: "",
          notes: "",
          attachments: []
        },
        purchaseOrderNumber: "",
        due: "on-receipt",
        customDueDate: new Date(),
        includesLateFee: false,
        lateFee: {
          amount: 5,
          type: "fixed",
          every: 1,
          interval: Intervals.Week,
          overriden: false
        },
        recurring: isRecurring || false,
        send: {
          type: sendDate
            ? isToday(sendDate)
              ? "immediately"
              : "date"
            : "immediately",
          date: sendDate || new Date()
        },
        frequency: defaultFrequency,
        collaborators: [],
        invoiceCollaborators: [],
        paymentMethods: {
          acceptAllPMs: true,
          acceptCreditCard: true,
          acceptACH: true,
          acceptManual: true
        },
        advanced: {
          sendEmails: true,
          sendReminders: true,
          creditFeeHandling: {
            clientPaysProcessingFees: true,
            clientProcessingFeePercentage: 2.9
          },
          date: new Date()
        }
      }}
    />
  );
};
