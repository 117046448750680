import {
  FundingSourceType,
  PayoutDestinationType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import {
  AccountStatus,
  AccountSubType,
  AccountType,
  AccountUsage,
  AccountVerificationType,
  Currency,
  IAccount
} from "@wingspanhq/users/dist/lib/interfaces";
import { MutationConfig, queryCache } from "react-query";
import { INVOICE_ACCOUNT_NAME } from "../../../Invoices/utils";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import {
  QUERY_PAYOUT_SETTINGS,
  QUERY_PAYROLL_SETTINGS
} from "../../../query/payments/keys";
import { QUERY_USERS_ACCOUNTS } from "../../../query/users/keys";
import { paymentsService } from "../../../services/payments";
import { usersService } from "../../../services/users";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useModalVerificationRequest } from "./ModalVerificationRequest";
import { FormData } from "./types";

export const useMutationSubmit = (
  params?: {
    setStandardPayoutDestination?: boolean;
    setInstantPayoutDestination?: boolean;
    requestVerification?: boolean;
    setFundingSource?: boolean;
  },
  config?: MutationConfig<IAccount>
) => {
  const userId = useUserId();
  const modalVerificationRequest = useModalVerificationRequest();

  return useWSMutation<IAccount, WSServiceError, FormData>(
    async values => {
      if (params?.requestVerification) {
        await modalVerificationRequest.open();
      }

      const accounts = await usersService.account.create({
        name: INVOICE_ACCOUNT_NAME,
        status: AccountStatus.Active,
        type: AccountType.Depository,
        subType:
          values.type === "checking"
            ? AccountSubType.Checking
            : AccountSubType.Savings,
        numbers: {
          account: values.accountNumber,
          routing: values.accountRoutingNumber,
          routingWire: values.accountRoutingNumber
        },
        usage: AccountUsage.Mixed,
        owners: [values.accountHolderName]
      });

      let account = Array.isArray(accounts) ? accounts[0] : accounts;

      if (params?.requestVerification) {
        account = await usersService.account.update(account.accountId, {
          accountVerification: {
            type: AccountVerificationType.MicroDeposit
          }
        });
      }

      if (params?.setStandardPayoutDestination) {
        const payoutSettings = await paymentsService.payoutSettings.update(
          userId,
          {
            payoutPreferences: PayoutPreferences.Standard,
            standard: {
              destinationId: account.accountId,
              destinationType: PayoutDestinationType.Account
            }
          }
        );

        queryCache.setQueryData(QUERY_PAYOUT_SETTINGS, payoutSettings);
      } else if (params?.setInstantPayoutDestination) {
        const payoutSettings = await paymentsService.payoutSettings.update(
          userId,
          {
            payoutPreferences: PayoutPreferences.Instant,
            instant: {
              destinationId: account.accountId,
              destinationType: PayoutDestinationType.Account
            }
          }
        );

        queryCache.setQueryData(QUERY_PAYOUT_SETTINGS, payoutSettings);
      } else if (params?.setFundingSource) {
        const payrollSettings = await paymentsService.payrollSettings.update(
          userId,
          {
            fundingSource: {
              fundingSourceCurrency: Currency.USD,
              fundingSourceId: account.accountId,
              fundingSourceType: FundingSourceType.Account
            }
          }
        );

        queryCache.setQueryData(QUERY_PAYROLL_SETTINGS, payrollSettings);
      }

      return account;
    },
    {
      dependencies: [
        QUERY_USERS_ACCOUNTS,
        QUERY_PAYROLL_SETTINGS,
        QUERY_PAYOUT_SETTINGS
      ],
      ...config
    }
  );
};
