import { IS_PRODUCTION_ENV } from "../../shared/constants/environment";

export const PPLPrefix = IS_PRODUCTION_ENV
  ? "wingspan.me/"
  : "staging.wingspan.me/";

export const createPPL = (
  tag: string,
  options?: { includeProtocol?: boolean }
) => {
  return (options?.includeProtocol ? "https://" : "") + PPLPrefix + tag;
};
