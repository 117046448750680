import {
  AuthorizedAction,
  IAuthorizationCreateRequest,
  IUserCreateRequest,
  ScopeModificationAction,
  ScopeModificationComparator,
  SubscriptionPackage,
  UserStatus
} from "@wingspanhq/users/dist/lib/interfaces";
import React, { useMemo } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  useCreateSubscriptionGrant,
  useDeleteSubscriptionGrant,
  useUpdateSubscriptionGrant
} from "../../../../query/subscriptions/mutations";
import {
  useCreateAuthorization,
  useCreateUser,
  useDeleteAuthorization
} from "../../../../query/users/mutations";
import { usersService } from "../../../../services/users";
import { WSServiceError } from "../../../../utils/serviceHelper";
import {
  getTeamMemberByUserId,
  ScopeGroupId
} from "../../../../shared/utils/teamUtils";
import {
  FormTeamMember,
  TeamMemberFormProps
} from "../../components/FormTeamMember";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import {
  useAllOrganizationUsers,
  useAuthorizations
} from "../../../../query/users/queries";
import { RouteComponentProps } from "react-router";
import { WSQueries } from "../../../../query/WSQuery";
import { useSubscriptionGrantListQuery } from "../../../../query/subscriptions/queries";
import { selectorSubscriptionByTitle } from "../../selectors/selectorSubscriptionByTitle";
import { selectorTitleBySubscription } from "../../selectors/selectorTitleBySubscription";
import flatten from "lodash/flatten";
import { queryCache } from "react-query";
import { QUERY_AUTHORIZATIONS } from "../../../../query/users/keys";
import { LayoutFullscreen } from "../../../../shared/components/LayoutFullscreen";
import { WSIcon } from "@wingspanhq/fe-component-library";

type EditTeamMemberProps = RouteComponentProps<{ teamMemberUserId: string }>;

export const TeamEditMember: React.FC<EditTeamMemberProps> = ({ match }) => {
  const history = useHistory();

  const [
    createAuthorization,
    createAuthorizationMeta
  ] = useCreateAuthorization();
  const [
    deleteAuthorization,
    deleteAuthorizationMeta
  ] = useDeleteAuthorization();
  const [createUser] = useCreateUser();
  const [createSubscriptionGrant] = useCreateSubscriptionGrant();
  const [updateSubscriptionGrant] = useUpdateSubscriptionGrant();
  const [deleteSubscriptionGrant] = useDeleteSubscriptionGrant();

  const teamMemberUserId = match.params.teamMemberUserId;

  const queryAuthorizations = useAuthorizations();
  const querySubscriptionGrantsList = useSubscriptionGrantListQuery();

  const goBack = useMemo(() => () => history.push("/member/settings/team"), [
    history
  ]);

  const queryOrganizationUsers = useAllOrganizationUsers();

  return (
    <LayoutFullscreen
      headTitle="Edit team member"
      onBack={goBack}
      headerRight={
        <WSIcon block size="XS" name="exit" onClick={goBack} color="gray600" />
      }
    >
      <WSQueries
        queries={{
          queryAuthorizations,
          querySubscriptionGrantsList,
          queryOrganizationUsers
        }}
      >
        {({
          queryAuthorizationsData,
          querySubscriptionGrantsListData,
          queryOrganizationUsersData
        }) => {
          const teamMember = getTeamMemberByUserId(
            teamMemberUserId,
            queryAuthorizationsData,
            querySubscriptionGrantsListData
          )!;

          const hasOrganizations = queryOrganizationUsersData.length > 0;

          if (!teamMember) {
            return <Redirect to="/member/settings/team" />;
          }

          const {
            authorization,
            subscriptionGrant,
            teamMemberWSUser
          } = teamMember;

          const allowedScopeGroupIds =
            authorization?.allowedScopeGroupIds || [];

          return (
            <>
              <FormTeamMember
                isEdit
                hasOrganizations={hasOrganizations}
                title={"Edit team member"}
                submitButtonLabel={"Update member"}
                onBack={goBack}
                onSubmit={async data => {
                  createAuthorizationMeta.reset();
                  deleteAuthorizationMeta.reset();

                  const request = {
                    id: authorization?.authorizationId,
                    allowedAction: AuthorizedAction.Write,
                    requestingUserId: ""
                  };

                  if (data.email) {
                    try {
                      const userByEmailRes = await usersService.user.email.get(
                        data.email
                      );
                      request.requestingUserId = userByEmailRes.userId;
                    } catch (err) {
                      if ((err as WSServiceError).response?.status === 404) {
                        const payload: IUserCreateRequest = {
                          email: data.email,
                          status: UserStatus.Pending,
                          notificationSettings: {
                            reviewNotifications: true,
                            newsletters: true
                          }
                        };
                        if (data.firstName && data.lastName) {
                          payload.profile = {
                            firstName: data.firstName,
                            lastName: data.lastName
                          };
                        }
                        const newUser = await createUser(payload);
                        request.requestingUserId = newUser?.userId!;
                      }
                    }
                  }

                  // Edit feature is implemented with Add and Remove
                  const oldScopeGroups = allowedScopeGroupIds.map(
                    ({ allowedScopeGroupId }) => allowedScopeGroupId
                  );
                  const newScopeGroups = Object.keys(data.permissions).filter(
                    key => data.permissions[key as ScopeGroupId]
                  ) as Array<ScopeGroupId>;

                  // Create authorizations if user adds new ones
                  const newScopeGroupIds = newScopeGroups.filter(
                    sg => !oldScopeGroups.includes(sg)
                  );
                  await Promise.all(
                    newScopeGroupIds.map(async scopeGroupId => {
                      const payload = {
                        ...request,
                        allowedScopeGroupId: scopeGroupId
                      };
                      await createAuthorization(payload);
                    })
                  );

                  // Delete authorization if user removes old ones
                  const removedScopeGroupIds = oldScopeGroups.filter(
                    sg => !newScopeGroups.includes(sg)
                  );
                  await Promise.all(
                    removedScopeGroupIds.map(async scopeGroupId => {
                      const allowedScopeGroup = allowedScopeGroupIds.find(
                        sg => sg.allowedScopeGroupId === scopeGroupId
                      );
                      if (allowedScopeGroup) {
                        await deleteAuthorization(
                          allowedScopeGroup.authorizationId
                        );
                      }
                    })
                  );

                  if (
                    teamMember.authorization?.organizationAccountAuthorizationId
                  ) {
                    await deleteAuthorization(
                      teamMember.authorization
                        ?.organizationAccountAuthorizationId
                    );
                  }

                  if (hasOrganizations) {
                    const payload: IAuthorizationCreateRequest = {
                      ...request,
                      allowedScope: "users.organizationAccount", // <-- MUST BE users.organizationAccount
                      allowedAction: AuthorizedAction.Write,
                      scopeModifications: {
                        users: {
                          organizationAccount: [
                            {
                              action: ScopeModificationAction.Include,
                              attribute: "userRoles.ownerIds",
                              value:
                                data.selectedOrganizations.sort().join(",") ||
                                "*",
                              comparator: ScopeModificationComparator.Includes
                            }
                          ]
                        }
                      }
                    };
                    await createAuthorization(payload);
                  }

                  const {
                    value: subscriptionPkgProps
                  } = selectorSubscriptionByTitle(data.subscription);
                  if (
                    [
                      SubscriptionPackage.Benefits,
                      SubscriptionPackage.Essentials,
                      SubscriptionPackage.Professional
                    ].includes(subscriptionPkgProps.package)
                  ) {
                    if (!!subscriptionGrant) {
                      await updateSubscriptionGrant({
                        id: subscriptionGrant.subscriptionGrantId,
                        ...subscriptionPkgProps
                      });
                    } else {
                      await createSubscriptionGrant({
                        granteeId: request.requestingUserId,
                        package: subscriptionPkgProps.package,
                        packageTier: subscriptionPkgProps.packageTier
                      });
                    }
                  }
                  // Delete subscription-grant if subscription-grant exists
                  // and None is selected
                  if (
                    !!subscriptionGrant &&
                    subscriptionPkgProps.package === SubscriptionPackage.None
                  ) {
                    await deleteSubscriptionGrant(
                      subscriptionGrant.subscriptionGrantId
                    );
                  }

                  queryCache.removeQueries(QUERY_AUTHORIZATIONS);

                  goBack();
                }}
                defaultValues={{
                  email: teamMemberWSUser.email || "",
                  firstName: teamMemberWSUser?.profile?.firstName || "",
                  lastName: teamMemberWSUser?.profile?.lastName || "",
                  permissions: allowedScopeGroupIds.reduce(
                    (acc, { allowedScopeGroupId }) => {
                      return {
                        ...acc,
                        [allowedScopeGroupId]: true
                      };
                    },
                    {} as TeamMemberFormProps["defaultValues"]["permissions"]
                  ),
                  selectedOrganizations: flatten(
                    authorization?.scopeModifications?.users?.organizationAccount?.map(
                      ({ value }) => value.split(",")
                    ) || []
                  ),
                  subscription: selectorTitleBySubscription(
                    subscriptionGrant?.package,
                    subscriptionGrant?.packageTier
                  )
                }}
              />
              <WSErrorMessage
                my="XL"
                contextKey="Other"
                forceShowApiErrors
                error={
                  deleteAuthorizationMeta.error || createAuthorizationMeta.error
                }
              />
            </>
          );
        }}
      </WSQueries>
    </LayoutFullscreen>
  );
};
