import {
  useWSSnackbar,
  WSElement,
  WSElementProps,
  WSIcon,
  WSLoader
} from "@wingspanhq/fe-component-library";
import { format } from "date-fns";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { CommonPropTypes } from "react-csv/components/CommonPropTypes";
import styles from "./styles.module.scss";

type CsvData = CommonPropTypes["data"];

interface Props extends WSElementProps {
  getData: () => CsvData | Promise<CsvData>;
  fileName: string;
  fileNameOptions?: {
    showWingspanPrefix?: boolean;
    showDate?: boolean;
  };
  customTrigger?: React.ReactNode;
}

const defautlFileNameOptions = {
  showWingspanPrefix: true,
  showDate: true
};

export const ButtonDownloadCsv: React.FC<Props> = ({
  getData,
  fileName,
  fileNameOptions,
  customTrigger,
  ...otherProps
}) => {
  const { openSnackbar } = useWSSnackbar();
  const csvLinkRef = useRef<any>(null);
  const [csvData, setCsvData] = useState<CsvData>([]);
  const [loading, setLoading] = useState(false);

  fileNameOptions = {
    ...defautlFileNameOptions,
    ...fileNameOptions
  };

  const prefix = fileNameOptions.showWingspanPrefix ? "Wingspan " : "";
  const date = fileNameOptions.showDate
    ? " " + format(new Date(), "yyyy-MM-dd")
    : "";

  const completeFileName = `${prefix}${fileName}${date}.csv`;

  const onSuccess = () => {
    openSnackbar({
      message: "CSV file downloaded",
      type: "success"
    });
  };

  const onFail = () => {
    openSnackbar({
      message: "Something went wrong",
      type: "warning"
    });
  };

  return (
    <>
      <WSElement
        onClick={async () => {
          if (loading) return;
          setLoading(true);

          try {
            const data = await getData();
            if (!data || !data.length) {
              throw new Error("No data");
            }

            setCsvData(data);
            csvLinkRef.current?.link?.click();
            onSuccess();
          } catch (error) {
            console.error("Error downloading CSV", error);
            onFail();
          } finally {
            setLoading(false);
          }
        }}
        {...otherProps}
      >
        {loading ? (
          <WSLoader size="S" kind="Spinner" />
        ) : customTrigger ? (
          customTrigger
        ) : (
          <WSIcon block name="download" color="gray400" size="M" />
        )}
      </WSElement>

      <CSVLink
        className={styles.hiddenButton}
        ref={csvLinkRef}
        data={csvData}
        filename={completeFileName}
      />
    </>
  );
};
