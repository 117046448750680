import {
  toWSMoneyString,
  useModalContext,
  WSElement,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ClientWorkFlowStatus,
  InvoiceStatus
} from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { useWSQuery } from "../../../query/helpers";
import {
  getMemberAcceptedPayables,
  getPayables,
  getPayablesTotalAmount
} from "../../../query/payments/selectors";
import { WSQueries } from "../../../query/WSQuery";
import { paymentsService } from "../../../services/payments";
import { getAllEntries } from "../../../utils/serviceHelper";
import {
  BulkApproveModal,
  PAYABLES_BULK_APPROVE
} from "../../components/NewPayablesTable/BulkApproveModal";
import {
  PayablesFilters,
  PayablesStatusFilter,
  PayablesTable,
  usePayablesQuery
} from "../../components/NewPayablesTable/PayablesTable";
import { PayableActionBlock } from "../../components/PayableActionBlock/PayableActionBlock";

const DEFAULT_FILTERS: PayablesFilters = {
  status: [PayablesStatusFilter.ToApprove]
};

export const usePyablesToApproveQuery = () =>
  useWSQuery("PAYABLES_TO_APPROVE", async () => {
    const payablesToApprove = getPayables(
      await getAllEntries(
        async (...args) => {
          return (await paymentsService.payable.list(...(args as any))).data;
        },
        {
          status: {
            in: [
              InvoiceStatus.Open,
              InvoiceStatus.Overdue,
              InvoiceStatus.Pending
            ]
          },
          "client.workflowStatus": {
            notIn: [
              ClientWorkFlowStatus.Approved,
              ClientWorkFlowStatus.PaymentInitiated,
              ClientWorkFlowStatus.Funded
            ]
          },
          "labels.invoiceType": {
            "!=": "approvedInvoicesPayment"
          },
          "labels.creationSource": {
            "!=": "personalPaylink"
          }
        }
      )
    );

    return {
      summary: {
        listSize: payablesToApprove.length,
        totalValue: getPayablesTotalAmount(payablesToApprove)
      },
      data: payablesToApprove
    };
  });

export const PayablesToApprove: React.FC = () => {
  useBrowserPageTitle("Payables To Approve");
  const history = useHistory();
  const draftPayablesQuery = usePayablesQuery({
    status: [PayablesStatusFilter.Draft]
  });

  const toApprovePayablesQuery = usePyablesToApproveQuery();

  const { openModal } = useModalContext();
  const [isApprovalInProgress, setIsApprovalInProgress] = useState(false);

  const approveAll = async () => {
    setIsApprovalInProgress(true);
    const payablesToApprove = toApprovePayablesQuery.data?.data || [];

    openModal(PAYABLES_BULK_APPROVE, {
      data: payablesToApprove.map(payable => ({
        payable: {
          payableId: payable.payableId
        }
      })),
      onFinish: () => {
        setIsApprovalInProgress(false);
      }
    });
  };

  return (
    <>
      <WSText.Heading1 mb="M">To Approve</WSText.Heading1>
      <WSText color="gray600" mb="2XL">
        All payables and requests that are not yet scheduled to be paid.
      </WSText>

      <BulkApproveModal />

      <WSElement mb="2XL">
        <WSQueries
          queries={{ draftPayablesQuery, toApprovePayablesQuery }}
          renderErrors={() => null}
        >
          {({
            draftPayablesQuery: {
              data: { summary: draftPayablesSummary }
            },
            toApprovePayablesQuery: {
              data: {
                data: payablesToApprove,
                summary: toApprovePayablesSummary
              }
            }
          }) => (
            <>
              {toApprovePayablesSummary.listSize > 1 && (
                <PayableActionBlock
                  mb="XL"
                  icon="dollar-circle"
                  rightButton={{
                    children: `Approve ${toApprovePayablesSummary.listSize} payments`,
                    loading: isApprovalInProgress,
                    onClick: approveAll
                  }}
                  onClick={approveAll}
                >
                  <WSText color="gray500" mb="XS">
                    <WSText as="b" inline color="gray700">
                      Pending approval:
                    </WSText>{" "}
                    {toApprovePayablesSummary.listSize} payables totaling{" "}
                    {toWSMoneyString(toApprovePayablesSummary.totalValue)}.
                  </WSText>

                  <WSText color="gray500">
                    {getMemberAcceptedPayables(payablesToApprove).length} have
                    been accepted
                  </WSText>
                </PayableActionBlock>
              )}

              {draftPayablesSummary.listSize > 0 && (
                <PayableActionBlock
                  onClick={() => {
                    history.push("/member/invoices/payables/draft");
                  }}
                  icon="alert-circle"
                  contentButton={{
                    children: "Manage draft payables"
                  }}
                >
                  <WSText color="gray500">
                    <WSText as="b" color="gray700">
                      You have {draftPayablesSummary.listSize} draft payable
                      {draftPayablesSummary.listSize > 1 ? "s" : ""}.
                    </WSText>
                  </WSText>
                </PayableActionBlock>
              )}
            </>
          )}
        </WSQueries>
      </WSElement>

      <PayablesTable
        bulkActions={["approve", "delete"]}
        visibleFilters={{
          collaborator: true,
          collaboratorReviewStatus: true,
          openedAtRange: true
        }}
        basePath="/member/invoices/payables/to-approve"
        defaultFilters={DEFAULT_FILTERS}
        showHeaders
        showInvoiceNumber
        showDateRequested
        showDueDate
        emptyState={{
          firstTime:
            "Every requested, scheduled, and paid payable will display here.",
          standart:
            "You’re all caught up! Nothing is waiting for your approval."
        }}
      />
    </>
  );
};
