import {
  WSButton,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { useDownloadTaxDocument } from "../../components/useDownloadTaxDocument";

type Props = {
  taxForm: IPayeeTaxFormResponse;
} & WSElementProps;

export const DocumentHistory: React.FC<Props> = ({
  taxForm,
  ...elementProps
}) => {
  const downloadModal = useDownloadTaxDocument();
  // reverse performed in place
  const documents = taxForm.submissions
    ? [...taxForm.submissions].reverse()
    : [];

  if (!documents.length) {
    return (
      <WSElement {...elementProps}>
        <WSText.Heading3>Document history</WSText.Heading3>
        <WSDivider mt="M" mb="L" />
        <WSText.ParagraphXs align="center" color="gray400" mt="M">
          No documents found
        </WSText.ParagraphXs>
      </WSElement>
    );
  }

  return (
    <WSElement {...elementProps}>
      <WSText.Heading5>Document history</WSText.Heading5>
      <WSDivider mt="M" mb="2XL" />

      {documents.map((document, index) => {
        let postfix = "";

        if (documents.length > 1) {
          if (index === documents.length - 1) {
            postfix = "(original)";
          } else if (index === 0) {
            postfix = "(current)";
          } else {
            postfix = "(updated)";
          }
        }
        const documentName = `Form 1099-NEC ${postfix}`;
        const date = document.events?.submittedAt;
        const submissionId = document.taxFormSubmissionId;

        return (
          <WSPanel mb="M" key={`${document.taxFormSubmissionId}-${index}`}>
            <WSFlexBox
              wrap="nowrap"
              alignItems="center"
              justify="space-between"
            >
              <WSElement>
                <WSText.Heading4 mb="M">{documentName}</WSText.Heading4>
                {date && (
                  <WSText.ParagraphSm
                    weight="medium"
                    mt="M"
                    color="gray500"
                    formatDate="monthDayYear"
                  >
                    {date}
                  </WSText.ParagraphSm>
                )}
              </WSElement>
              <WSButton.Secondary
                size="S"
                onClick={() => {
                  downloadModal.open({
                    taxForm,
                    documentName,
                    submissionId
                  });
                }}
              >
                Download
              </WSButton.Secondary>
            </WSFlexBox>
          </WSPanel>
        );
      })}
    </WSElement>
  );
};
