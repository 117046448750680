import {
  useWSSnackbar,
  WSButton,
  WSCheckboxToggle,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { useEnterpriseQuery } from "../../../../query/platform/queries";
import { useMutationSubmit } from "./useMutationSubmit";
import { TextBulleted } from "../../../../shared/components/TextBulleted";
import { selectorEnterpriseName } from "../../../../shared/selectors/selectorEnterpriseName";
import { WS_LINKS } from "../../../../types/wsLinks";

export type FormW8BENCertifyProps = {
  onClose: () => void;
} & WSElementProps;

export const W8BENCertify: React.FC<FormW8BENCertifyProps> = ({
  onClose,
  ...elementProps
}) => {
  const { openSnackbar } = useWSSnackbar();
  const [agreement, setAgreement] = useState(false);
  const [submit, submitMeta] = useMutationSubmit({
    onSuccess: () => {
      openSnackbar({
        type: "success",
        duration: 3000,
        message: `"Certify your W-8 is accurate" complete`
      });
      onClose();
    }
  });

  return (
    <WSElement {...elementProps}>
      <WSText mb="M">
        By using Wingspan’s services you agree that the information provided for
        Form W-8 BEN is accurate and that you certify the provided information
        as follows:
      </WSText>
      <WSText mb="M">“I certify, under penalty of perjury that:</WSText>
      <TextBulleted mb="M">
        <li>
          I am the individual that is the beneficial owner of all the income or
          proceeds to which this form relates or am using this form to document
          myself for chapter 4 purposes;
        </li>
        <li>The person named on this form is not a U.S. person;</li>
        <li>
          This form relates to:
          <ul>
            <li>
              (a) income not effectively connected with the conduct of a trade
              or business in the United States;
            </li>
            <li>
              (b) income effectively connected with the conduct of a trade or
              business in the United States but is not subject to tax under an
              applicable income tax treaty;
            </li>
            <li>
              (c) the partner's share of a partnership's effectively onnected
              taxable income;
            </li>
            <li>
              or (d) the partner's amount realized from the transfer of a
              partnership interest subject to withholding under section 1446(f);
            </li>
          </ul>
        </li>
        <li>
          The person named on this form is a resident of the treaty country
          listed on the form (if any) within the meaning of the income tax
          treaty between the United States and that country.”
        </li>
      </TextBulleted>

      <WSCheckboxToggle
        my="3XL"
        name="agreement"
        label="By providing the information on this form, I hereby certify, to the
        best of my knowledge, that the information provided is complete and
        correct."
        value={agreement}
        onChange={value => {
          setAgreement(value);
        }}
      />

      <WSButton
        disabled={!agreement}
        mb="M"
        onClick={submit}
        fullWidth
        name="next"
        loading={submitMeta.isLoading}
      >
        Accept
      </WSButton>

      <WSText.ParagraphXs color="gray600" mb="M">
        We work with Stripe to power credit payments. By continuing, you agree
        to{" "}
        <a
          href={WS_LINKS.stripeConnectedTerms}
          target="_blank"
          rel="noreferrer"
        >
          Stripe Connected Account Agreement
        </a>
        .
      </WSText.ParagraphXs>

      <WSText.ParagraphXs color="gray600">
        Wingspan is a financial services technology company and is not a bank.
        Banking services provided by Lead Bank, Member FDIC.
      </WSText.ParagraphXs>
    </WSElement>
  );
};
