import { WSElement, WSElementProps } from "@wingspanhq/fe-component-library";
import { OtpVerificationChannel } from "@wingspanhq/users/dist/lib/interfaces";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { OtpRequestForm } from "./OtpRequestForm";
import { SmsConfirmationForm } from "./SmsConfirmationForm";

type Props = {
  onSuccess: () => void;
  onClose: () => void;
} & WSElementProps;

export const ModalOtpVerification: React.FC<Props> = ({
  onSuccess,
  onClose,
  ...elementProps
}) => {
  const [selectedChannel, setSelectedChannel] = useState<
    OtpVerificationChannel | undefined
  >();

  return (
    <WSElement {...elementProps}>
      {selectedChannel && selectedChannel === OtpVerificationChannel.Email ? (
        <Redirect to="/member/authorize/2fa/email" />
      ) : selectedChannel && selectedChannel === OtpVerificationChannel.SMS ? (
        <SmsConfirmationForm
          onSuccess={() => {
            onSuccess();
            onClose();
          }}
        />
      ) : (
        <OtpRequestForm
          onSuccess={({ channel }) => {
            setSelectedChannel(channel);
          }}
        />
      )}
    </WSElement>
  );
};
