// TODO: Move to FECL

import { WSLoader } from "@wingspanhq/fe-component-library";
import React from "react";
import { Overlay, OverlayProps } from "../Overlay";
import classes from "./styles.module.scss";

export const OverlaySpinner: React.FC<OverlayProps> = ({ ...props }) => (
  <Overlay className={classes.overlay} white {...props}>
    <WSLoader.Spinner />
  </Overlay>
);
