import {
  Intervals,
  IPayrollSettings,
  PayrollWorkflowStrategy
} from "@wingspanhq/payments/dist/interfaces";
import { dayInIntervalToWeekDayMapping } from "../../../../shared/utils/time";
import { PayrollSettingsInterval } from "./types";

export const getDefaultValues = (payrollSettings?: IPayrollSettings) => {
  let interval: PayrollSettingsInterval;
  let every = 1;
  let dayInInterval = 1;

  const hasPayrollSetting =
    payrollSettings?.runsPayroll !== null &&
    payrollSettings?.runsPayroll !== undefined;

  if (payrollSettings?.frequency?.daily) {
    interval = PayrollSettingsInterval.Everyday;
  } else if (payrollSettings?.frequency?.twicePerMonth) {
    interval = PayrollSettingsInterval.SemiMonthly;
  } else if (
    payrollSettings?.frequency?.interval === Intervals.Month &&
    payrollSettings?.frequency?.every === 1 &&
    payrollSettings?.frequency?.dayInInterval === 31
  ) {
    interval = PayrollSettingsInterval.Monthly;
  } else {
    interval = PayrollSettingsInterval.Week;
    every = payrollSettings?.frequency?.every || 1;
    dayInInterval = payrollSettings?.frequency?.dayInInterval || 1;
  }

  return {
    runsPayroll: hasPayrollSetting ? payrollSettings?.runsPayroll : true,
    interval,
    every,
    dayInInterval: dayInIntervalToWeekDayMapping[dayInInterval],
    start: payrollSettings?.frequency?.startDate || new Date(),
    issue1099s: payrollSettings?.issue1099s || false,
    enableMultiStageApproval:
      payrollSettings?.workflow === PayrollWorkflowStrategy.DualStage
        ? true
        : false,
    enableProcessDaysBeforeDue:
      payrollSettings?.enableProcessDaysBeforeDue || false,
    processDaysBeforeDue: payrollSettings?.processDaysBeforeDue || 0
  };
};
