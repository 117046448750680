import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { toCamelCase } from "../../../../utils";
import {
  WSElement,
  WSElementProps,
  detachLayoutProps
} from "../../WSElement/WSElement.component";
import { WSSidebar } from "../../common/WSSidebar/WSSidebar.component";
import { WSFlexBox } from "../../layout/WSFlexBox/WSFlexBox.component";
import { useIsMobile } from "../../layout/WSScreen/WSScreen.component";
import { WSIcon } from "../WSIcon/WSIcon.component";
import { WSText } from "../WSText/WSText.component";
import styles from "./WSSelect.module.scss";
import { SelectActionOld, SelectOptionOld } from "./WSSelect.types";
import { WSSelectDisplay } from "./WSSelectDisplay.component";

export interface WSSelectPropsOld extends Omit<WSElementProps, "placeholder"> {
  name: string;
  value: string | number;
  options: SelectOptionOld[];
  placeholder?: string;
  searchPlaceholder?: string;
  error?: boolean;
  searchable?: boolean;
  label?: string;
  cleanable?: boolean;
  placeholderActions?: SelectActionOld[];
  actions?: SelectActionOld[];
}

const filterOptions = (options: SelectOptionOld[], searchText: string) =>
  options.filter((option) => {
    if (
      option.label &&
      option.label.toLowerCase().includes(searchText.toLowerCase())
    )
      return true;

    if (String(option.value).toLowerCase().includes(searchText.toLowerCase()))
      return true;

    if (
      option.searchText &&
      option.searchText.toLowerCase().includes(searchText.toLowerCase())
    )
      return true;

    return false;
  });

export const WSSelectOld: React.FC<WSSelectPropsOld> = (props) => {
  const {
    options,
    value,
    name,
    label,
    placeholder,
    searchPlaceholder,
    layoutProps,
    onChange,
    onBlur,
    error,
    onFocus,
    cleanable,
    searchable,
    actions,
    placeholderActions
  } = detachLayoutProps(props);

  const isMobile = useIsMobile();
  const inputRef = React.createRef<any>();

  const selectedOption = options.find((option) => option.value === value);

  const [searchText, setSearchText] = useState(selectedOption?.label || "");
  const [isOpen, setIsOpen] = useState(false);
  const [activeOption, setActiveOption] = useState(-1);

  let visibleOptions = options;

  if (searchable && searchText) {
    visibleOptions = filterOptions(options, searchText);
  }

  useEffect(() => {
    if (!isOpen) {
      setActiveOption(-1);
    } else {
      setActiveOption(
        selectedOption ? visibleOptions.indexOf(selectedOption) : 0
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, selectedOption, visibleOptions.map((v) => v.value).join(",")]);

  useEffect(() => {
    const active = document.querySelector(
      '[data-dropdown-active-option="true"]'
    ) as HTMLDivElement;

    if (active && active.parentNode) {
      const parent = active.parentNode as HTMLDivElement;

      if (parent.scrollHeight > parent.clientHeight) {
        parent.scrollTop = active.offsetTop;
      }
    }
  }, [activeOption]);

  const currentValue =
    !isMobile && searchable ? searchText : selectedOption?.label || "";

  return (
    <WSElement
      className={classNames(styles.select, { [styles.error]: error })}
      {...layoutProps}
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!isOpen || isMobile) {
          return;
        }

        switch (event.key) {
          case "ArrowUp": {
            event.stopPropagation();
            event.preventDefault();

            const newValue = activeOption - 1;
            const newActiveOption =
              newValue < 0 ? visibleOptions.length - 1 : newValue;
            setActiveOption(newActiveOption);
            break;
          }
          case "ArrowDown": {
            event.stopPropagation();
            event.preventDefault();

            const newValue = activeOption + 1;
            const newActiveOption =
              newValue >= visibleOptions.length ? 0 : newValue;

            setActiveOption(newActiveOption);
            break;
          }
          case "Enter": {
            event.stopPropagation();
            event.preventDefault();

            if (visibleOptions[activeOption]) {
              const { value: optionValue, label: optionLabel } = visibleOptions[
                activeOption
              ];
              onChange?.(optionValue as any);
              if (searchable)
                setSearchText(optionLabel || (optionValue as any));
              setActiveOption(-1);

              setIsOpen(false);
            }
            break;
          }
          default:
            break;
        }
      }}
      data-testid={toCamelCase(name, "field")}
      tabIndex={-1}
      onBlur={(event: any) => {
        if (isMobile) {
          return;
        }

        if (event.currentTarget.contains(event.relatedTarget)) {
          return;
        }

        if (visibleOptions.length === 1 && value !== visibleOptions[0].value) {
          onChange?.(visibleOptions[0].value as any);
          setSearchText(visibleOptions[0].label);
        } else if (selectedOption?.value) {
          setSearchText(selectedOption.label);
        } else {
          setSearchText("");
        }

        setIsOpen(false);
        onBlur?.(event);
      }}
      onFocus={() => inputRef.current.focus()}
    >
      <input
        type="text"
        ref={inputRef}
        onFocus={(event: any) => {
          if (isOpen) {
            return;
          }

          onFocus?.(event);
          setIsOpen(true);
          setSearchText("");
        }}
        className={styles.input}
        placeholder={
          searchable && isOpen ? searchPlaceholder || "Search..." : placeholder
        }
        value={currentValue}
        readOnly={isMobile || !searchable}
        onChange={(e) => {
          setIsOpen(true);
          setSearchText(e.target.value);
          setActiveOption(-1);
        }}
      />
      <WSFlexBox.CenterY>
        {cleanable && (searchText || selectedOption?.label) ? (
          <WSElement
            className={styles.cleanIconWrapper}
            mr="XS"
            onMouseDown={(event) => {
              event.stopPropagation();
              event.preventDefault();

              setSearchText("");
              setActiveOption(-1);
              onChange?.(undefined as any);
            }}
          >
            <WSIcon
              block
              className={classNames(styles.icon, styles.clean)}
              name="exit"
              color="gray600"
            />
          </WSElement>
        ) : null}
        <WSIcon
          block
          className={classNames(styles.icon, { [styles.open]: isOpen })}
          name="caret-down"
          size="M"
        />
      </WSFlexBox.CenterY>

      {isMobile && isOpen ? (
        <WSSidebar
          className={styles.mobileView}
          onClose={() => setIsOpen(false)}
          noCloseIcon
          position="bottom"
          size="AUTO"
          contentElementProps={{
            colorBackground: "white"
          }}
        >
          <WSFlexBox.CenterY justify="flex-start">
            <WSText.ParagraphSm
              onClick={() => setIsOpen(false)}
              mb="XL"
              color="gray600"
            >
              Cancel
            </WSText.ParagraphSm>
          </WSFlexBox.CenterY>
          <WSText.ParagraphSm mb="M" color="gray600">
            {label}
          </WSText.ParagraphSm>

          <WSSelectDisplay
            key={name}
            value={value}
            onChange={(newValue) => {
              setIsOpen(false);
              onChange?.(newValue);
            }}
            name={name}
            options={options}
            actions={actions}
          />
        </WSSidebar>
      ) : (
        <WSElement
          className={classNames(styles.options, { [styles.open]: isOpen })}
        >
          {searchable &&
            !visibleOptions.length &&
            placeholderActions?.map((action, index) => (
              <WSElement
                key={`placeholder-action-${action.label}-${index}`}
                className={classNames(styles.option, styles.action)}
                data-option-value={action.label}
                data-testid={toCamelCase(name, action.label, "action")}
                onClick={() => {
                  action.callback(currentValue, visibleOptions);
                  setTimeout(() => setIsOpen(false), 50);
                }}
              >
                {action.icon ? (
                  <WSIcon
                    block
                    name={action.icon}
                    size="XS"
                    p="XS"
                    color="blue400"
                    mr="M"
                  />
                ) : null}
                {action.label}
              </WSElement>
            ))}

          {actions?.map((action, index) => (
            <WSElement
              key={`action-${action.label}-${index}`}
              className={classNames(styles.option, styles.action)}
              data-option-value={action.label}
              data-testid={toCamelCase(name, action.label, "action")}
              onClick={() => {
                action.callback(currentValue, visibleOptions);
                setTimeout(() => setIsOpen(false), 50);
              }}
            >
              {action.icon ? (
                <WSIcon
                  block
                  name={action.icon}
                  size="XS"
                  p="XS"
                  color="blue400"
                  mr="M"
                />
              ) : null}
              {action.label}
            </WSElement>
          ))}

          {(searchable ? visibleOptions : options).map((option, index) => (
            <WSElement
              data-dropdown-active-option={activeOption === index}
              key={`${option.value}${option.label}`}
              className={classNames(styles.option, {
                [styles.selected]:
                  activeOption !== -1
                    ? activeOption === index
                    : option.value === value
              })}
              data-option-value={option.value}
              data-testid={toCamelCase(name, option.value, "option")}
              onClick={() => {
                onChange?.(option.value as any);
                setSearchText(option.label);
                setTimeout(() => setIsOpen(false), 50);
              }}
            >
              {option.label}
            </WSElement>
          ))}
        </WSElement>
      )}
    </WSElement>
  );
};
