import { formatNumber } from "accounting";

export interface PluralizeOptions {
  one: string | ((count: number) => string);
  other: string | ((count: number) => string);
}

interface PluralizeConfig {
  hideCount?: boolean;
}

export const pluralize = (
  count: number,
  options: PluralizeOptions,
  config?: PluralizeConfig
) => {
  const countText = config?.hideCount ? "" : `${formatNumber(count)} `;
  if (count === 1) {
    if (typeof options["one"] === "function") {
      return options["one"](count);
    }
    return `${countText}${options["one"]}`;
  }
  if (typeof options["other"] === "function") {
    return options["other"](count);
  }
  return `${countText}${options["other"]}`;
};
