import { IInvitation, UserStatus } from "@wingspanhq/users/dist/lib/interfaces";
import { InvitationAuthLayout } from "../InvitationAuthLayout";
import { InvitationAuthSignUpForm } from "../InvitationAuthSignUpForm";
import { IInvitationResponse } from "@wingspanhq/users";
import { useCustomization } from "../../../customization";
import { PlatformContextType } from "@wingspanhq/users/dist/lib/interfaces/newUser";
import { PAYEES_FEATURES, PAYERS_FEATURES } from "../../static/featuresList";
import { InvitationAuthSignInForm } from "../InvitationAuthSignInForm";

type SignUpPageProps = {
  invitation: IInvitation;
  invitationData: IInvitationResponse;
};

export const AuthPage: React.FC<SignUpPageProps> = ({
  invitation,
  invitationData
}) => {
  const { branding } = useCustomization(invitation.inviterUserId);
  const { primaryLogoUrl, name: platformName } = branding(true);
  const hasInviter = !!invitationData.inviter?.name;
  const inviterName = invitationData.inviter?.name;

  const form =
    invitationData.invitee.status === UserStatus.Active ? (
      <InvitationAuthSignInForm
        invitation={invitation}
        invitationData={invitationData}
        organizationName={platformName}
      />
    ) : (
      <InvitationAuthSignUpForm
        invitation={invitation}
        invitationData={invitationData}
        organizationName={platformName}
      />
    );

  if (invitationData.context === PlatformContextType.Payer) {
    return (
      <InvitationAuthLayout
        title={`Welcome to ${platformName}`}
        description={
          <>
            {platformName} is trusted by businesses that rely on contingent
            work. Our platform helps you save time, ensure compliance, and
            enhance contractor satisfaction by providing everything you need in
            one place.
          </>
        }
        items={PAYERS_FEATURES}
        organizationLogo={primaryLogoUrl}
        clientLogo={invitationData.inviter?.logo}
      >
        {form}
      </InvitationAuthLayout>
    );
  } else {
    return (
      <InvitationAuthLayout
        title={
          hasInviter
            ? `${inviterName} invited you to ${platformName}`
            : `${platformName} invited you to onboard`
        }
        description={
          <>
            {hasInviter
              ? `${inviterName} wants to onboard you via ${platformName}. `
              : ""}
            {platformName} is here to make your work life easier by providing
            everything you need in one place.
          </>
        }
        items={PAYEES_FEATURES}
        organizationLogo={primaryLogoUrl}
        clientLogo={invitationData.inviter?.logo}
      >
        {form}
      </InvitationAuthLayout>
    );
  }
};
