import React from "react";
import { WSAvatar, WSAvatarProps } from "../common/WSAvatar/WSAvatar.component";
import { WSText } from "../core/WSText/WSText.component";
import { WSElement } from "../WSElement/WSElement.component";
import {
  WSTooltipIcon,
  WSTooltipIconProps
} from "../WSTooltipIcon/WSTooltipIcon";
import styles from "./WSCardHeaderLabel.module.scss";

const DEFAULT_AVATAR_SIZE = "S";

export type WSCardHeaderLabelProps = {
  label?: string;
  avatar?: WSAvatarProps;
  helperText?: string;
  tooltip?: WSTooltipIconProps["tooltip"];
};

export const WSCardHeaderLabel: React.FC<WSCardHeaderLabelProps> = (props) => {
  const labelElement = props.label ? (
    <WSText weight="medium" color="gray600">
      {props.label}
    </WSText>
  ) : undefined;

  const helperTextElement = props.helperText ? (
    <WSText kind="ParagraphXs" color="gray400">
      {props.helperText}
    </WSText>
  ) : undefined;

  const avatarElement = props.avatar ? (
    <WSAvatar size={DEFAULT_AVATAR_SIZE} {...props.avatar} />
  ) : undefined;

  const tooltipElement = props.tooltip ? (
    <WSTooltipIcon tooltip={props.tooltip} />
  ) : undefined;

  return (
    <WSElement className={styles.label}>
      {avatarElement}

      {helperTextElement ? (
        <WSElement>
          {labelElement}
          {helperTextElement}
        </WSElement>
      ) : (
        labelElement
      )}

      {tooltipElement}
    </WSElement>
  );
};
