import { ISearchablePayerPayeeEngagement } from "../../../services/search";
import {
  IPayeeEngagementResponse,
  IPayerEngagementResponse
} from "@wingspanhq/payments/dist/interfaces";
import { PayerPayeeEngagementStatus } from "@wingspanhq/payments/dist/interfaces/payerPayeeEngagement";

export const selectorPayerPayeeEngagements = <
  E extends
    | IPayeeEngagementResponse
    | IPayerEngagementResponse
    | ISearchablePayerPayeeEngagement
>(
  engagements: Array<E>,
  includeDefaultEngagement: boolean = false,
  includeInactiveEngagements: boolean = false
): E[] => {
  return engagements
    .filter(engagement => {
      if (includeInactiveEngagements) {
        return true;
      }

      return engagement.status === PayerPayeeEngagementStatus.Active;
    })
    .filter(engagement => {
      return engagement.labels?.duplicate !== true;
    })
    .filter(engagement => {
      if (includeDefaultEngagement) {
        return true;
      }

      return engagement.isDefault !== true;
    });
};
