import React from "react";
import {
  useWSModal,
  WSButton,
  WSButtons,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";

type Props = {
  onClose(confirm: boolean): void;
};

const WarningChangeEmailModal: React.FC<Props> = ({ onClose }) => (
  <>
    <WSText.Heading5 inline>
      <WSIcon name="alert-circle" mr="M" size="M" color="amber400" />
      Complete all pending signatures before proceeding
    </WSText.Heading5>
    <WSText mt="XL">
      All unsigned documents linked to your current email will become
      inaccessible.
    </WSText>
    <WSButtons format="modal" forceFullWidth mt="XL">
      <WSButton.Primary onClick={() => onClose(false)}>Cancel</WSButton.Primary>
      <WSButton.Secondary onClick={() => onClose(true)}>
        Proceed
      </WSButton.Secondary>
    </WSButtons>
  </>
);

export const useWarningChangeEmailModal = () =>
  useWSModal(WarningChangeEmailModal, {
    size: "S"
  });
