import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useUserId } from "../query/hooks/helpers";
import { useUserProfile } from "../query/users/queries";
import { WSQueries } from "../query/WSQuery";
import { AdminSignInAsMember } from "./screens/AdminSignInAsMember";

export const Admin: React.FC = () => {
  const userId = useUserId();
  const userQuery = useUserProfile(userId);

  return (
    <WSQueries queries={{ userQuery }}>
      {({ userQuery: { data: user } }) => (
        <Switch>
          {user.email.endsWith("@wingspan.app") ? (
            <Route
              path="/member/admin/sign-in-as-member"
              component={AdminSignInAsMember}
            />
          ) : null}

          <Redirect from="*" to="/member/dashboard" />
        </Switch>
      )}
    </WSQueries>
  );
};
