import { useWSModal } from "@wingspanhq/fe-component-library";

import { FlatfileErrorModal } from "./index";

export function useFlatfileErrorModal() {
  return useWSModal(FlatfileErrorModal, {
    title: "Import Error",
    size: "S"
  });
}
