import { IProfitAndLoss } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { WSText } from "@wingspanhq/fe-component-library";
import styles from "./HeroText.module.scss";
import React from "react";
import { useHistory } from "react-router-dom";

interface HeroTextProps {
  isLoading: boolean;
  profitAndLoss: IProfitAndLoss;
  dateRangeText: string;
}

export const HeroText: React.FC<HeroTextProps> = ({
  isLoading,
  dateRangeText,
  profitAndLoss
}) => {
  const history = useHistory();
  let incomeAll = profitAndLoss.incomeAll ?? 0;
  let expensesDeductible = profitAndLoss.expensesDeductible ?? 0;

  return (
    <WSText
      shimmer={isLoading}
      mb="2XL"
      className={styles.text}
      color="gray600"
      align="left"
      data-testid="bookkeepingHeroText"
    >
      {incomeAll !== 0 || expensesDeductible !== 0 ? (
        <>
          {dateRangeText} you’ve made{" "}
          <WSText
            inline
            formatMoney="default"
            className={styles.boldText}
            color="gray700"
            data-testid="heroTextValue"
          >
            {incomeAll}
          </WSText>{" "}
          and we found{" "}
          <WSText
            inline
            formatMoney="default"
            className={styles.boldText}
            color="gray700"
          >
            {expensesDeductible}
          </WSText>{" "}
          in deductible expenses. 🎉
        </>
      ) : (
        <>
          No income or deductible expenses found for the current date range.{" "}
          <WSText
            color="blue500"
            inline
            className={styles.link}
            onClick={() =>
              history.push("/member/bookkeeping/dashboard/add-transaction")
            }
          >
            Add transactions.
          </WSText>
        </>
      )}
    </WSText>
  );
};
