import { useWSModal } from "@wingspanhq/fe-component-library";
import { AddCustomFieldModal } from "./index";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";

export const useModalAddCustomField = ({
  resourceType
}: {
  resourceType: CustomFieldResourceType;
}) => {
  const resourceTypeToPropsMap = {
    [CustomFieldResourceType.Collaborator]: {
      title: "Create custom contractor field"
    },
    [CustomFieldResourceType.LineItem]: {
      title: "Create custom line item field"
    }
  };

  const { title } = resourceTypeToPropsMap[resourceType];

  return useWSModal(AddCustomFieldModal, {
    title,
    size: "S",
    allowClickOutsideToClose: true
  });
};
