import {
  TRACK_FE_PERFORMANCE_DURATION,
  TRACK_SIGN_UP_HELP,
  TRACK_SIGN_UP_INVITE_ONLY_ERROR
} from "../constants/analytics";
import { useUserId } from "../query/hooks/helpers";
import { useWSStore } from "../store";
import {
  group as segmentGroup,
  identify as segmentIdentify,
  track as segmentTrack
} from "./analytics";
import {
  GrowthAttributionDetails,
  GrowthSourceNames
} from "./growthAttribution";

export function useWSAnalytics() {
  const store = useWSStore();
  const userId = useUserId();

  const group = {
    byId: (groupId: string, traits?: any) => {
      segmentGroup(groupId, traits);
    }
  };

  const identify = {
    some: (traits: any) => {
      segmentIdentify(userId, traits);
    }
  };

  const track = {
    fePerformanceDurations: (properties: {
      durationInMs: number;
      name: string;
      isEndedBecauseOfError?: boolean;
    }) => {
      segmentTrack(TRACK_FE_PERFORMANCE_DURATION, {
        duration: properties.durationInMs,
        name: properties.name,
        is_ended_because_of_error: !!properties.isEndedBecauseOfError
      });
    },
    signUp: (properties?: {
      growthAttributionDetails?: GrowthAttributionDetails;
      signUpMethod?: "Email" | "Google";
    }) => {
      // Tracks a user signing up`
      // spec: https://www.notion.so/wingspan/fe955d2904ec47aa8f48d4aae43bc4ef?v=6fa33777de0341c093feb16515a629bb

      const growthAttributionDetails: GrowthAttributionDetails = properties?.growthAttributionDetails ||
        store.growthAttributionDetails || {
          growthSource: GrowthSourceNames.Content
        };

      const signUpMethod =
        properties?.signUpMethod || store.signUpMethod || "Email";

      const props = {
        growth_source: growthAttributionDetails.growthSource,
        growth_name: growthAttributionDetails.growthName,
        growth_user_id: growthAttributionDetails.growthUserId,
        signup_method: signUpMethod
      };

      segmentTrack("Signed Up", props);
      identify.some(props);
    },
    signUpHelp: (properties?: {
      fullName: string;
      email: string;
      payerName: string;
      phoneNumber: string;
    }) => {
      segmentTrack(TRACK_SIGN_UP_HELP, {
        full_name: properties?.fullName,
        email: properties?.email,
        payer_name: properties?.payerName,
        phone_number: properties?.phoneNumber
      });
    },
    signUpInviteOnlyError: (properties?: { email: string }) => {
      segmentTrack(TRACK_SIGN_UP_INVITE_ONLY_ERROR, properties);
    },
    other: (event: string, properties?: any) => {
      segmentTrack(event, properties);
    }
  };

  return { track, identify, group };
}
