import React, { useContext, useEffect, useState } from "react";
import { wsModalContext } from "./WSModal.context";
import { WSModal, WSModalProps } from "./WSModal.component";

export type RequiredKeys<T> = {
  [K in keyof T]-?: {} extends Pick<T, K> ? never : K;
}[keyof T];

export type OptionalKeys<T> = {
  [K in keyof T]-?: {} extends Pick<T, K> ? K : never;
}[keyof T];

type OptionalComponentProps<Props extends {}> = Omit<
  Pick<Props, OptionalKeys<Props>>,
  "onClose"
>;

type RequiredComponentProps<Props extends {}> = Omit<
  Pick<Props, RequiredKeys<Props>>,
  "onClose"
>;

type OpenComponentProps<Props extends {}> = Omit<Props, "onClose">;

type WithArgumentsOpen<Props extends {}, Result extends any> = (
  props: OpenComponentProps<Props>,
  modalProps?: WSModalProps
) => Promise<Result | null>;

type EmptyArgumentsOpen<Props extends {}, Result extends any> = (
  props?: OptionalComponentProps<Props>,
  modalProps?: WSModalProps
) => Promise<Result | null>;

type OpenCallback<Props extends {}, Result extends any> = {
  onClose: any;
} extends RequiredComponentProps<Props>
  ? EmptyArgumentsOpen<Props, Result>
  : WithArgumentsOpen<Props, Result>;

export const useWSModal = <Props extends {}, Result extends any>(
  Component: React.FC<Props & { onClose: (props: Result) => void }>,
  initialModalProps: WSModalProps = {}
): {
  open: OpenCallback<Props, Result>;
  close: () => void;
} => {
  const { createModal, removeModal } = useContext(wsModalContext);

  const [key] = useState(() => `modal-key-${Date.now()}-${Math.random()}`);

  useEffect(
    () => () => {
      removeModal(key);
    },
    [key, removeModal]
  );

  return {
    open: ((props = {} as Props, modalProps: WSModalProps = {}) =>
      new Promise<Result>((resolve) => {
        const onClose = (result: Result) => {
          resolve(result);
          (modalProps?.onClose || initialModalProps.onClose)?.(result);
          removeModal(key);
        };

        createModal({
          key,
          modal: () => (
            <WSModal {...initialModalProps} {...modalProps} onClose={onClose}>
              <Component {...props} onClose={onClose} />
            </WSModal>
          )
        });
      })) as OpenCallback<Props, Result>,
    close: () => {
      removeModal(key);
    }
  };
};
