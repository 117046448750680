import React from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { PayerSetupLayout } from "../../../components/PayerSetupLayout";
import { FormPayerInformation } from "../../../components/FormPayerInformation";
import { WSText } from "@wingspanhq/fe-component-library";

export const RouteSetupPayerInformation: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  return (
    <PayerSetupLayout title="Payer information">
      <WSText.Heading4 mb="M">Confirm payer information</WSText.Heading4>
      <WSText color="gray600">
        Does this information look right? If you make changes here, they will
        appear on your company profile and 1099 forms.
      </WSText>
      <FormPayerInformation onNext={onNext} onBack={onBack} />
    </PayerSetupLayout>
  );
};
