export const QUERY_SUBSCRIPTION_PACKAGES = "QUERY_SUBSCRIPTION_PACKAGES";
export const QUERY_SUBSCRIPTION_V3 = "QUERY_SUBSCRIPTION_V3";
export const QUERY_SUBSCRIPTION_PAYMENT_METHOD =
  "QUERY_SUBSCRIPTION_PAYMENT_METHOD";
export const QUERY_SUBSCRIPTION_BILLING_HISTORY =
  "QUERY_SUBSCRIPTION_BILLING_HISTORY";
export const QUERY_SUBSCRIPTION_GRANT = "QUERY_SUBSCRIPTION_GRANT";
export const QUERY_SUBSCRIPTION_GRANT_LIST = "QUERY_SUBSCRIPTION_GRANT_LIST";
export const GRANTED_SUBSCRIBER_LIST = "GRANTED_SUBSCRIBER_LIST";

// Next gen subscription
export const QUERY_NEXT_GEN_SUBSCRIPTION_LIST =
  "QUERY_NEXT_GEN_SUBSCRIPTION_LIST";
export const QUERY_NEXT_GEN_SUBSCRIPTION_PLAN_LIST =
  "QUERY_NEXT_GEN_SUBSCRIPTION_PLAN_LIST";
