import React from "react";
import { WSElement, WSElementProps } from "../WSElement/WSElement.component";
import { WSButton } from "../core/WSButton/WSButton.component";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import { WSText } from "../core/WSText/WSText.component";
import { useIsMobile } from "../layout";
import styles from "./WSRecentSearches.module.scss";

export type WSRecentSearchesProps = {
  items: string[];
  onSelect: (item: string) => void;
  onClear: () => void;
} & WSElementProps;

export const WSRecentSearches: React.FC<WSRecentSearchesProps> = ({
  onClear,
  onSelect,
  items,
  ...elementProps
}) => {
  const isMobile = useIsMobile();

  const clearRecentButton = (
    <WSButton.Link
      className={styles.clearButton}
      size="S"
      onClick={onClear}
      type="button"
    >
      Clear recent
    </WSButton.Link>
  );

  return (
    <WSElement {...elementProps}>
      <WSElement className={styles.header}>
        <WSText.Caption>Recent searches</WSText.Caption>
        {!isMobile && clearRecentButton}
      </WSElement>

      <WSElement>
        {items.map((item, index) => (
          <WSElement
            key={item + index}
            className={styles.item}
            onClick={() => {
              onSelect(item);
            }}
          >
            <WSIcon name="search" size="S" />
            <WSText.ParagraphSm>{item}</WSText.ParagraphSm>
          </WSElement>
        ))}
      </WSElement>

      {isMobile && clearRecentButton}
    </WSElement>
  );
};
