import React, { useRef, useState } from "react";
import { useResize } from "../../../../utils";
import { WSFlexBox } from "../../layout/WSFlexBox/WSFlexBox.component";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import styles from "./WSCarousel.module.scss";
import cn from "classnames";

export interface WSCarouselProps extends WSElementProps {
  items: React.ReactNode[];
  width: number;
  defaultItemIdx?: number;
  disableTouchEvents?: boolean;
}

export const WSCarousel: React.FC<WSCarouselProps> = ({
  items,
  width,
  className,
  defaultItemIdx = 0,
  disableTouchEvents,
  ...other
}) => {
  const [selected, setSelected] = useState(defaultItemIdx);

  const componentRef = useRef<HTMLDivElement>(null);

  const xDown = useRef<number | null>(null);

  const sizes = useResize(componentRef);

  const isCarousel = sizes.width < width * items.length;

  return (
    <>
      <WSElement
        className={cn(styles.container, className)}
        onTouchStart={(event) => {
          xDown.current = event.touches[0].clientX;
        }}
        onMouseDown={(event) => {
          xDown.current = event.clientX;
        }}
        onTouchMove={(event) => {
          if (!xDown.current) {
            return;
          }

          const xUp = event.touches[0].clientX;
          const xDiff = xDown.current - xUp;
          if (xDiff > 10) {
            setSelected((prevState) => {
              const next = prevState + 1;

              return next === items.length ? prevState : next;
            });
          } else if (xDiff < -10) {
            setSelected((prevState) => {
              const next = prevState - 1;

              return next < 0 ? prevState : next;
            });
          }

          xDown.current = null;
        }}
        onMouseMove={(event) => {
          if (!xDown.current) {
            return;
          }

          const xUp = event.clientX;
          const xDiff = xDown.current - xUp;

          if (xDiff > 10) {
            setSelected((prevState) => {
              const next = prevState + 1;

              return next === items.length ? prevState : next;
            });
          } else if (xDiff < -10) {
            setSelected((prevState) => {
              const next = prevState - 1;

              return next < 0 ? prevState : next;
            });
          }
          xDown.current = null;
        }}
        {...other}
      >
        <WSElement
          style={{ height: sizes.height }}
          className={styles.itemListWrapper}
        >
          <WSElement ref={componentRef} className={styles.itemList}>
            <div
              className={`${styles.itemWrapper} ${
                isCarousel ? styles.carousel : ""
              }`}
              style={{
                transform: isCarousel
                  ? `translateX(${
                      -selected * width + sizes.width / 2 - width / 2
                    }px)`
                  : "none"
              }}
            >
              {items.map((item, index) => {
                const classNames = cn(styles.item, {
                  [styles.inactive]: isCarousel && index !== selected
                });
                return (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={classNames}
                    style={{
                      minWidth: width,
                      maxWidth: width
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </WSElement>
        </WSElement>
      </WSElement>

      {isCarousel ? (
        <WSFlexBox.CenterX>
          {items.map((item, index) => (
            <WSElement
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={styles.indicator}
              onClick={() => setSelected(index)}
            >
              {index === selected ? <i /> : null}
            </WSElement>
          ))}
        </WSFlexBox.CenterX>
      ) : null}
    </>
  );
};
