import {
  toWSMoneyString,
  WSButton,
  WSGrid,
  WSPage,
  WSPanel,
  WSTabs,
  WSText
} from "@wingspanhq/fe-component-library";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useMemberClientsQuery } from "../../../../query/payments/queries";
import { usePayeeTaxForm } from "../../../../query/taxForm/queries/usePayeeTaxForm";
import { WSQueries } from "../../../../query/WSQuery";
import { getPaymentsLinkForTaxDocument } from "../../utils/getPaymentsLinkForTaxDocument";
import { DocumentHistory } from "./DocumentHistory";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Payments } from "./Payments";
import { Summary } from "./Summary";

export const RouteDetails: React.FC<RouteComponentProps<{
  taxFormId: string;
}>> = ({
  match: {
    params: { taxFormId }
  }
}) => {
  const history = useHistory();
  const queryTaxForm = usePayeeTaxForm(taxFormId);
  const queryMemberClients = useMemberClientsQuery();

  return (
    <WSPage>
      <WSQueries queries={{ queryTaxForm, queryMemberClients }}>
        {({ queryTaxForm: { data: taxForm }, queryMemberClientsData }) => (
          <>
            <Header taxForm={taxForm} mb="2XL" />
            <WSGrid>
              <WSGrid.Item span={{ m: "6" }}>
                <WSPanel
                  mb="2XL"
                  style={{ backgroundColor: "currentColor" }}
                  color="gray50"
                >
                  <WSText.ParagraphSm color="gray500">
                    1099 NEC-Total
                  </WSText.ParagraphSm>
                  <WSText.Display2 mb="M">
                    {taxForm.data?.totalAmount
                      ? toWSMoneyString(taxForm.data?.totalAmount)
                      : "--"}
                  </WSText.Display2>
                  <WSButton.Link
                    onClick={() => {
                      history.push(
                        getPaymentsLinkForTaxDocument(
                          queryMemberClientsData,
                          taxForm
                        )
                      );
                    }}
                  >
                    See payments included in the 1099-NEC total
                  </WSButton.Link>
                </WSPanel>

                <WSTabs
                  tabs={[
                    {
                      label: "Summary",
                      render: () => <Summary taxForm={taxForm} />
                    },
                    {
                      label: "Payments",
                      render: () => <Payments taxForm={taxForm} />
                    }
                  ]}
                />
              </WSGrid.Item>
              <WSGrid.Item span={{ m: "6" }}>
                <DocumentHistory mb="2XL" taxForm={taxForm} />
              </WSGrid.Item>
            </WSGrid>
            <Footer my="2XL" />
          </>
        )}
      </WSQueries>
    </WSPage>
  );
};
