import {
  WSPill,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

export const DEDUCTION_INFO_MODAL = "DEDUCTION_INFO_MODAL";

export const DeductionInfo: React.FC = () => {
  return (
    <WSModal
      name={DEDUCTION_INFO_MODAL}
      size="S"
      title=""
      allowClickOutsideToClose
    >
      <WSText mb="XL">
        <b>Scheduled deductions</b> are completed when a contractor is paid. If
        a deduction is larger than the scheduled payables, the remaining balance
        will be deducted from the next payable.
      </WSText>
      <WSElement mb="XL">
        <WSPill icon="before" text="Pre-payment" />
      </WSElement>
      <WSText mb="XL">
        Pre-payment deductions are taken out of a payable’s subtotal prior to
        the payment. Pre-payment deductions are added as a negative line item to
        invoices.
      </WSText>
      <WSText mb="XL">
        For example, when you pay a $200 invoice with a $20 pre-payment
        deduction, your account will be debited $180.
      </WSText>
      <WSElement mb="XL">
        <WSPill icon="after" text="Post-payment" />
      </WSElement>
      <WSText mb="XL">
        Post-payment deductions are deductions that are applied after your
        payment to contractors.
      </WSText>
      <WSText mb="XL">
        For example, when you pay a $200 invoice with a $20 post-payment
        deduction, your account will be debited $200 and your contractor will
        receive $180. Post-payment deductions are commonly used for
        garnishments, and can be routed to state or federal tax authorities.
      </WSText>

      <WSText.Heading5 mb="XS">Example</WSText.Heading5>
      <WSDivider mb="XL" />
      <WSFlexBox.CenterY justify="space-between" my="M">
        <WSText.ParagraphSm color="gray500">Subtotal</WSText.ParagraphSm>
        <WSText.ParagraphSm color="gray500">$1,400.00</WSText.ParagraphSm>
      </WSFlexBox.CenterY>
      <WSFlexBox.CenterY justify="space-between" my="M">
        <WSText.ParagraphSm>Pre-payment deductions</WSText.ParagraphSm>
        <WSText.ParagraphSm>-$200.00</WSText.ParagraphSm>
      </WSFlexBox.CenterY>
      <WSDivider mb="XL" />
      <WSFlexBox.CenterY justify="space-between" my="M">
        <WSText.ParagraphSm color="gray500">Total paid</WSText.ParagraphSm>
        <WSText.ParagraphSm color="gray500">$1,200.00</WSText.ParagraphSm>
      </WSFlexBox.CenterY>
      <WSFlexBox.CenterY justify="space-between" my="M">
        <WSText.ParagraphSm>Post-payment deductions</WSText.ParagraphSm>
        <WSText.ParagraphSm>-$200.00</WSText.ParagraphSm>
      </WSFlexBox.CenterY>
      <WSDivider mb="XL" />
      <WSFlexBox.CenterY justify="space-between" my="M">
        <WSText.ParagraphSm color="gray500">
          Contractor gross income
        </WSText.ParagraphSm>
        <WSText.ParagraphSm color="gray500">$1,000.00</WSText.ParagraphSm>
      </WSFlexBox.CenterY>
    </WSModal>
  );
};
