import {
  useWSModal,
  WSActions,
  WSAlert,
  WSDivider,
  WSInfoBox,
  WSList
} from "@wingspanhq/fe-component-library";

export interface CardSuspendedProps {
  onClose: () => void;
}

const CardOrdered: React.FC<CardSuspendedProps> = ({ onClose }) => {
  return (
    <WSList gap="L">
      <WSAlert theme="success">
        Your card is on it's way! Expected delivery: 7-10 business days.
      </WSAlert>
      <WSInfoBox>
        Once you’ve received the card in the mail, come back to your Wingspan
        Wallet page to activate the card.
      </WSInfoBox>
      <WSDivider />
      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            onClick: async () => {
              onClose();
            },
            kind: "Primary"
          }
        ]}
      ></WSActions>
    </WSList>
  );
};

export function useCardOrderedModal() {
  return useWSModal(CardOrdered, {
    title: "Order confirmation",
    size: "S"
  });
}
