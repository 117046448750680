import { INewUser } from "@wingspanhq/users";

export const isLegacyUser = (user: INewUser) => {
  return !(user?.createdAt > new Date(2021, 5, 30));
};

export const getCountryCode = (isoCode: string): string => {
  switch (isoCode) {
    case "IN":
      return "+91";
    case "PL":
      return "+48";
    case "US":
      return "+1";
    default:
      return "+1";
  }
};

export const formatPhoneNumber = (phone: string): string =>
  phone ? phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") : phone;
