import { IMember } from "@wingspanhq/users/dist/lib/interfaces";
import { selectorIsCompany } from "../../selectors/selectorIsCompany";
import { selectorIsSsnVisible } from "./selectorIsSsnVisible";

export const selectorIsSsnRequired = (
  member: IMember,
  skipTaxVerification?: boolean,
  isUSPerson?: boolean
) => {
  const isSsnVisible = selectorIsSsnVisible(
    member,
    skipTaxVerification,
    isUSPerson
  );
  const isCompany = selectorIsCompany(member);

  return isSsnVisible && !isCompany;
};
