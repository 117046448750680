import React from "react";
import {
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";

import styles from "./SectionInfo.module.scss";

export interface SectionInfoProps extends WSElementProps {
  title: string;
  description?: string;
}

export const SectionInfo: React.FC<SectionInfoProps> = ({
  title,
  description,
  ...otherProps
}) => {
  return (
    <WSElement {...otherProps}>
      <WSText.Heading4>{title}</WSText.Heading4>
      {description && (
        <WSText mt="M" className={styles.description}>
          {description}
        </WSText>
      )}
    </WSElement>
  );
};
