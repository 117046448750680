import { ClientWorkFlowStatus } from "@wingspanhq/payments/dist/interfaces";

export const selectorIsClientWorkflowStatusPending = (
  workflowStatus?: ClientWorkFlowStatus
) => {
  return (
    !workflowStatus ||
    (workflowStatus !== ClientWorkFlowStatus.Approved &&
      workflowStatus !== ClientWorkFlowStatus.PaymentInitiated &&
      workflowStatus !== ClientWorkFlowStatus.Funded)
  );
};
