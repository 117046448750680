import { useEffect, useMemo, useRef, useState } from "react";

export function useOnVisible(onVisible?: () => void, stop?: boolean) {
  const ref = useRef<HTMLElement>(null);

  const [isIntersecting, setIntersecting] = useState(false);
  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIntersecting(entry.isIntersecting);
          onVisible?.();
        }
      }),
    []
  );

  useEffect(() => {
    const currentRef = ref.current;

    if (!currentRef) {
      return;
    }

    if (stop) {
      observer.disconnect();
    } else {
      observer.observe(currentRef);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, stop]);

  return [ref, isIntersecting] as const;
}
