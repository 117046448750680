import { MutationConfig } from "react-query";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { QUERY_USERS_ACTIVITIES } from "../../../query/users/keys";
import { usersService } from "../../../services/users";

export const useMutationSubmit = (config?: MutationConfig<void>) => {
  const userId = useUserId();

  return useWSMutation(
    async () => {
      await usersService.activity.update(userId, {
        events: {
          electronic1099Consent: true
        }
      });
    },
    {
      dependencies: [QUERY_USERS_ACTIVITIES],
      ...config
    }
  );
};
