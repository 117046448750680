import cn from "classnames";
import React, { useEffect, useState } from "react";
import { WSCard } from "../../WSCard/WSCard";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSList } from "../../WSList/WSList.component";
import { WSImage } from "../../core/WSImage/WSImage.component";
import { WSText } from "../../core/WSText/WSText.component";
import { WSGrid } from "../../layout/WSGrid/WSGrid.component";
import {
  WSScreen,
  useIsMobile
} from "../../layout/WSScreen/WSScreen.component";
import { WSHorizontalScrollCards } from "../WSHorizontalScrollCards/WSHorizontalScrollCards.component";
import styles from "./WSPreview.module.scss";

type Item = {
  title: string;
  description: string;
  image: string;
  imageWidth?: number;
};

type Props = WSElementProps & { list: Item[] };

export const WSPreview: React.FC<Props> = ({ list, ...elementProps }) => {
  const isMobile = useIsMobile();
  const [activeIndex, setActiveIndex] = useState(isMobile ? 0 : 1);

  useEffect(() => {
    list.forEach((item) => {
      const img = new Image();
      img.src = item.image;
    });
  }, [list]);

  const image = (
    <WSElement className={styles.imageContainer}>
      <WSImage
        className={styles.image}
        src={list[activeIndex].image}
        placeholder={list[activeIndex].title}
        style={{ maxWidth: list[activeIndex].imageWidth }}
      />
    </WSElement>
  );

  return list.length > 0 ? (
    <WSElement {...elementProps}>
      <WSScreen.Mobile>
        <WSHorizontalScrollCards>
          {list.map((item, index) => (
            <WSCard
              key={item.title}
              header={{
                label: item.title
              }}
              className={cn({ [styles.activeItem]: activeIndex === index })}
              onClick={() => {
                setActiveIndex(index);
              }}
            >
              <WSText.ParagraphSm color="gray600">
                {item.description}
              </WSText.ParagraphSm>
            </WSCard>
          ))}
        </WSHorizontalScrollCards>
        <WSElement className={styles.previewContainer} mt="L">
          {image}
        </WSElement>
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        <WSGrid>
          <WSGrid.Item span={{ s: "6" }}>
            <WSList gap="M">
              {list.map((item, index) => (
                <WSCard
                  key={item.title}
                  header={{
                    label: item.title
                  }}
                  className={cn({ [styles.activeItem]: activeIndex === index })}
                  onClick={() => {
                    setActiveIndex(index);
                  }}
                >
                  <WSText.ParagraphSm color="gray600">
                    {item.description}
                  </WSText.ParagraphSm>
                </WSCard>
              ))}
            </WSList>
          </WSGrid.Item>
          <WSGrid.Item span={{ s: "6" }}>
            <WSElement className={styles.previewContainer}>
              <WSText mb="XL">Preview</WSText>
              {image}
            </WSElement>
          </WSGrid.Item>
        </WSGrid>
      </WSScreen.TabletAndDesktop>
    </WSElement>
  ) : null;
};
