import React, { useCallback } from "react";
import { WSButton } from "../core/WSButton/WSButton.component";
import { useWSSnackbar } from "../WSSnackbar/WSSnackbar.component";

type Props = {
  value: string;
};

export const CopyButton: React.FC<Props> = ({ value }) => {
  const { openSnackbar } = useWSSnackbar();

  const copy = useCallback(() => {
    navigator.clipboard.writeText(value);
    openSnackbar({
      theme: "light",
      message: `"${value}" copied to clipboard`,
      type: "success"
    });
  }, [openSnackbar, value]);

  return (
    <WSButton kind="Link" size="S" icon="copy-fill" onClick={copy}>
      Copy
    </WSButton>
  );
};
