import { useDownloadPdf } from "../../../Invoices/utils";
import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { downloadPayeeW9 } from "../../../services/payees";

export const usePayeeDownloadW9 = () => {
  const downloadPdf = useDownloadPdf();

  return useWSMutation<
    void,
    WSServiceError,
    {
      payeeId: string;
      filename: string;
    }
  >(async ({ payeeId, filename }) => {
    const blob = await downloadPayeeW9(payeeId);
    const url = window.URL.createObjectURL(blob);
    downloadPdf(url, `${filename}-w9-form.pdf`);
  });
};
