import { WSService } from "../../../utils/WSService";
import { operations } from "./types";

const service = new WSService("/v2/onboarding/missing-data");

export const getVerificationMissingData = async (
  verificationLevel: operations["getVerificationMissingData"]["parameters"]["path"]["verificationLevel"]
): Promise<operations["getVerificationMissingData"]["responses"]["200"]["content"]["application/json"]> => {
  const { data } = await service.get("/" + verificationLevel);
  return data;
};
