import { classNames } from "primereact/utils";
import styles from "./getStatusStyles.module.scss";
import { WSFieldStatus } from "../../types/forms";

export const getStatusStyles = (status?: WSFieldStatus) => {
  const styleStatus = status || "default";

  return classNames({
    [styles[`status-${styleStatus}`]]: !!styleStatus
  });
};
