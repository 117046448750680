import {
  toWSDateString,
  WSEmptyState,
  WSFilter,
  WSFilters,
  WSInfiniteScroll
} from "@wingspanhq/fe-component-library";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces/bulkUpload";
import { PaymentRequirementStrategy } from "@wingspanhq/payments/dist/interfaces/payerPayee";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { useEngagementsListAllQuery } from "../../../../query/engagements/queries/useEngagementsListAllQuery";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { usePayeeRowsQuery } from "../../../../query/search/payee/queries/usePayeeRowsQuery";
import { usePayeeRowsSummaryQuery } from "../../../../query/search/payee/queries/usePayeeRowsSummaryQuery";
import { WSQueries } from "../../../../query/WSQuery";
import { PayerPayeeEngagementSearchableStatus } from "../../../../services/search";
import { useBrowserPageTitle } from "../../../../shared/hooks/useBrowserPageTitle";
import { useUrlQueryFilters } from "../../../../shared/hooks/useUrlQueryFilters";
import { useBulkCollaboratorBatchesQuery } from "../../../BulkImporter/query/bulkCollaborator/queries";
import { BulkResource } from "../../../BulkImporter/types";
import { getUploadedFilename } from "../../../BulkImporter/utils/getUploadedFilename";
import { useCustomization } from "../../../customization";
import { useCreateContractorModal } from "../../components/CreateContractorModal/useCreateContractorModal";
import { PayeeRowsTable } from "../../components/PayeeRowsTable";
import { buildPayeeDetailsPath, customFieldsToFilters } from "../../utils";
import {
  createListRequest,
  PayeeFilters,
  PayeeTaxInfoPermission
} from "./filtering";

export const RoutePayees: React.FC = () => {
  const { terminology } = useCustomization();

  useBrowserPageTitle(terminology().sendPaymentsContractor);
  const history = useHistory();
  const { filters, setFilters } = useUrlQueryFilters<PayeeFilters>();
  const modalCreateContractor = useCreateContractorModal();

  const queryFeatureFlags = useFeatureFlags();
  const queryCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });
  const queryPayeeGeneralSummary = usePayeeRowsSummaryQuery({
    filter: {
      searchableStatus: {
        "!=": PayerPayeeEngagementSearchableStatus.Inactive
      }
    }
  });

  const request = useMemo(
    () => createListRequest(filters, queryCustomFields.data),
    [filters, queryCustomFields.data]
  );

  const queryPayeeRows = usePayeeRowsQuery(request);
  const queryPayeeRowsSummary = usePayeeRowsSummaryQuery(request);
  const queryEngagementList = useEngagementsListAllQuery({
    filter: {
      isDefault: false
    }
  });
  const queryBulkPayableBatches = useBulkCollaboratorBatchesQuery();

  return (
    <WSQueries
      queries={{
        queryPayeeRows,
        queryPayeeRowsSummary,
        queryEngagementList,
        queryBulkPayableBatches,
        queryPayeeGeneralSummary,
        queryFeatureFlags,
        queryCustomFields
      }}
    >
      {({
        queryPayeeRowsData,
        queryPayeeRowsSummaryData,
        queryEngagementListData: engagements,
        queryBulkPayableBatchesData,
        queryPayeeGeneralSummaryData,
        queryFeatureFlagsData,
        queryCustomFieldsData
      }) => {
        const engagementOptions = engagements.map(engagement => ({
          label: engagement.name,
          name: engagement.engagementId
        }));

        const batchOptions = queryBulkPayableBatchesData
          .filter(bulkBatch =>
            [BulkStatus.Complete, BulkStatus.Failed].includes(bulkBatch.status)
          )
          .map(bulkBatch => ({
            label:
              getUploadedFilename(bulkBatch, BulkResource.Collaborator) +
              ` (created ${toWSDateString(bulkBatch.createdAt)})`,
            name: bulkBatch.bulkCollaboratorBatchId
          }));

        return (
          <>
            {queryPayeeGeneralSummaryData.listSize > 0 && (
              <WSFilters<PayeeFilters>
                my="L"
                values={filters}
                onFilter={setFilters}
                primaryFilters={[
                  ...(engagementOptions.length
                    ? [
                        {
                          title: "Engagements",
                          name: "engagementId",
                          type: "checkbox",
                          options: engagementOptions
                        } as WSFilter
                      ]
                    : []),
                  {
                    title: "Status",
                    name: "searchableStatus",
                    type: "checkbox",
                    options: [
                      {
                        label: "Not signed up",
                        name: PayerPayeeEngagementSearchableStatus.NotSignedUp
                      },
                      {
                        label: "Not eligible for payment",
                        name: PayerPayeeEngagementSearchableStatus.NotEligible
                      },
                      {
                        label: "Eligible for payment",
                        name: PayerPayeeEngagementSearchableStatus.Eligible
                      }
                      // {
                      //   label: "Archived",
                      //   name: PayerPayeeEngagementSearchableStatus.Inactive
                      // }
                    ]
                  },
                  {
                    title: "Autopay",
                    name: "autoPayStrategy",
                    type: "checkbox",
                    titleInLabel: true,
                    options: [
                      {
                        label: "On",
                        name: PaymentRequirementStrategy.All
                      },
                      {
                        label: "Off",
                        name: PaymentRequirementStrategy.None
                      }
                    ]
                  },
                  {
                    title: "Tax info",
                    name: "shareTaxDocument",
                    type: "checkbox",
                    titleInLabel: true,
                    options: [
                      {
                        label: "Not shared",
                        name: PayeeTaxInfoPermission.NotShared
                      },
                      {
                        label: "Shared",
                        name: PayeeTaxInfoPermission.Shared
                      }
                    ]
                  },
                  ...(batchOptions.length
                    ? [
                        {
                          title: "Bulk import batch",
                          name: "bulkBatchId",
                          type: "checkbox",
                          options: batchOptions
                        } as WSFilter
                      ]
                    : []),
                  ...(queryFeatureFlagsData.showCustomFieldFIltersInContractors
                    ? customFieldsToFilters(queryCustomFieldsData)
                    : [])
                ]}
                info={{
                  count: queryPayeeRowsSummaryData.listSize
                }}
                quickFilters={[
                  {
                    icon: true,
                    theme: "warning",
                    label: "Not signed up",
                    shortLabel: "Not signed up",
                    preset: {
                      searchableStatus: [
                        PayerPayeeEngagementSearchableStatus.NotSignedUp
                      ]
                    }
                  },
                  {
                    icon: true,
                    theme: "warning",
                    label: "Not eligible for payment",
                    shortLabel: "Not eligible",
                    preset: {
                      searchableStatus: [
                        PayerPayeeEngagementSearchableStatus.NotEligible
                      ]
                    }
                  },
                  {
                    icon: true,
                    theme: "success",
                    label: "Eligible for payment",
                    shortLabel: "Eligible",
                    preset: {
                      searchableStatus: [
                        PayerPayeeEngagementSearchableStatus.Eligible
                      ]
                    }
                  }
                ]}
              />
            )}
            {Object.keys(filters).length && queryPayeeRowsData.length === 0 ? (
              <WSEmptyState
                py="3XL"
                fullWidth
                hasBorder
                bgColor="gray"
                type="search"
                title="No results found"
                description="Try adjusting your search or filter to find what you’re looking for."
              />
            ) : queryPayeeRowsData.length === 0 ? (
              <WSEmptyState
                py="3XL"
                mt="L"
                hasBorder
                fullWidth
                type="people"
                title="No contractors yet"
                bgColor="gray"
                description="Once a contractor is added, they will appear here."
                buttons={[
                  {
                    label: "Add contractor",
                    kind: "Secondary",
                    onClick: async () => {
                      const result = await modalCreateContractor.open();
                      if (result) {
                        history.push(buildPayeeDetailsPath(result.payeeId));
                      }
                    }
                  },
                  {
                    label: "Bulk upload contractors",
                    kind: "Tertiary",
                    onClick: async () => {
                      history.push(
                        "/member/invoices/contacts/collaborators/bulk-create/info"
                      );
                    }
                  }
                ]}
              />
            ) : (
              <WSInfiniteScroll
                mt="M"
                onLoad={() => {
                  queryPayeeRows.fetchMore();
                }}
                loadMore={queryPayeeRowsData.length > 0}
                endOfList={!queryPayeeRows.canFetchMore}
                loading={!!queryPayeeRows.isFetchingMore}
              >
                <PayeeRowsTable payees={queryPayeeRowsData} filters={request} />
              </WSInfiniteScroll>
            )}
          </>
        );
      }}
    </WSQueries>
  );
};
