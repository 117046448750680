import classNames from "classnames";
import React, { useMemo } from "react";
import { WSElement, WSMarginProps } from "../WSElement/WSElement.component";
import styles from "./WSPageToolbar.module.scss";
import { WSText } from "../core/WSText/WSText.component";
import {
  WSPageToolbarAction,
  WSPageToolbarActions
} from "../WSPageToolbarActions/WSPageToolbarActions";
import {
  WSBreadcrumbs,
  WSBreadcrumbsItem
} from "../WSBreadcrumbs/WSBreadcrumbs";
import { WSPill, WSPillProps } from "../common";
import { useIsMobile, WSFlexBox } from "../layout";

export interface WSPageToolbarProps extends WSMarginProps {
  title?: string;
  titlePill?: WSPillProps;
  titleAfter?: React.ReactNode;
  actions?: WSPageToolbarAction[];
  breadcrumbs?: WSBreadcrumbsItem[];
  breadcrumbsAfter?: React.ReactNode;
  breadcrumbsLimit?: number;
  className?: string;
  children?: React.ReactNode;
}

export const WSPageToolbar: React.FC<WSPageToolbarProps> = ({
  className,
  title,
  titlePill,
  titleAfter,
  actions,
  breadcrumbs,
  breadcrumbsAfter,
  breadcrumbsLimit,
  children,
  ...elementProps
}) => {
  const isMobile = useIsMobile();
  const isTwoRowToolbar = !!breadcrumbs?.length;

  const titleSection = useMemo(
    () =>
      title ? (
        <WSElement className={styles.secondaryRow}>
          <WSText kind={isMobile ? "Heading4" : "Heading2"} weight="medium" singleLine className={styles.title}>
            {title}
          </WSText>
          {titlePill || titleAfter ? (
            <WSFlexBox.CenterY
              gap="M"
              wrap="nowrap"
              className={classNames(styles.titleInfo, {[styles.newRowOnMobile]: isMobile && isTwoRowToolbar })}
            >
              {titlePill ? (
                <WSPill className={styles.pillTitle} {...titlePill} />
              ) : null}
              {titleAfter ? titleAfter : null}
            </WSFlexBox.CenterY>
          ) : null}
        </WSElement>
      ) : null,
    [title, titlePill, titleAfter, isMobile, isTwoRowToolbar]
  );

  return (
    <WSElement
      mb="XL"
      className={classNames(styles.wrapper, className)}
      {...elementProps}
    >
      <WSFlexBox.CenterY
        gap="M"
        mb="L"
        justify="space-between"
        wrap="nowrap"
        className={styles.mainRow}
      >
        {isTwoRowToolbar ? (
          <WSBreadcrumbs
            items={breadcrumbs}
            after={breadcrumbsAfter}
            limit={isMobile ? 2 : breadcrumbsLimit}
          />
        ) : (
          titleSection
        )}
        <WSPageToolbarActions actions={actions} />
      </WSFlexBox.CenterY>
      {isTwoRowToolbar ? titleSection : null}
      {children}
    </WSElement>
  );
};
