import * as FullStory from "@fullstory/browser";
declare global {
  interface Window {
    FS: any;
  }
}

export const getFullStorySessionId = () => {
  if (window.FS) {
    return window.FS.getCurrentSession();
  }
};

export const loadFullStory = () => {
  try {
    if (!FullStory.isInitialized()) {
      FullStory.init({
        orgId: process.env.REACT_APP_FULLSTORY_ORG_ID || "",
        recordOnlyThisIFrame: true
      });
    }
  } catch {
    console.error("Failed to initialize FullStory");
  }
};
