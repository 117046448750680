import {
  useModalContext,
  WSButton,
  WSButtons,
  WSLoader
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import {
  useCatchUpTransactionsExpense,
  useCatchUpTransactionsIncome
} from "../../../query/bookkeeping/queries";
import {
  getAllTotalCatchUpExpenseTransactions,
  getAllTotalCatchUpIncomeTransactions
} from "../../../query/bookkeeping/selectors";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateActivityCatchUpEvents } from "../../../query/users/mutations";
import { WSQueries } from "../../../query/WSQuery";
import { track } from "../../../utils/analytics";
import { PREV_YEAR } from "../../utils";
import { BOOKKEEPING_SUCCESS_ONBOARDING } from "../modals/SuccessOnboarding";
import { CatchUpLayout } from "./CatchUpLayout";
import styles from "./Index.module.scss";

export const CatchUpSuccess: React.FC<{ year: number }> = ({ year }) => {
  const history = useHistory();
  const userId = useUserId();
  const { openModal } = useModalContext();

  const qCatchUpTransactionsExpense = useCatchUpTransactionsExpense(
    userId,
    PREV_YEAR
  );
  const qCatchUpTransactionsIncome = useCatchUpTransactionsIncome(
    userId,
    PREV_YEAR
  );

  const [
    updateActivityCatchUpEvents,
    updateActivityCatchUpEventsMeta
  ] = useUpdateActivityCatchUpEvents();

  return (
    <WSQueries
      queries={{
        qCatchUpTransactionsIncome,
        qCatchUpTransactionsExpense
      }}
      renderLoader={() => (
        <ProjectOnboardingLayout progress={0} noBack>
          <WSLoader.Spinner style={{ marginTop: 100 }} />
        </ProjectOnboardingLayout>
      )}
    >
      {({ qCatchUpTransactionsExpense, qCatchUpTransactionsIncome }) => {
        const totalIncomeTransactions = getAllTotalCatchUpIncomeTransactions(
          qCatchUpTransactionsIncome.data
        );

        const totalExpenseTransactions = getAllTotalCatchUpExpenseTransactions(
          qCatchUpTransactionsExpense.data
        );

        return (
          <CatchUpLayout
            progress={0}
            steps={[
              {
                title: "Add up income",
                name: "1",
                checked: true
              },
              {
                title: "Find business expenses",
                name: "2",
                checked: true
              },
              {
                title: "You’re all caught up!",
                name: "3",
                current: true
              }
            ]}
          >
            <BrowserPageTitle
              title={`You’re all caught up for the ${year} tax year!`}
            />
            <h1 className={styles.title}>
              You’re all caught up for the {year} tax year!
            </h1>
            <p className={styles.subtitle}>
              Congratulations on being so on top of things, we’re very proud :’)
            </p>

            <WSButtons mt="XL" mb="2XL">
              <WSButton
                loading={updateActivityCatchUpEventsMeta.isLoading}
                onClick={async () => {
                  await updateActivityCatchUpEvents({
                    userId,
                    events: {
                      incomeReviewedAt: new Date()
                    }
                  });

                  track("Bookkeeping Setup Completed");

                  history.push("/member/bookkeeping");
                  setTimeout(
                    () => openModal(BOOKKEEPING_SUCCESS_ONBOARDING),
                    500
                  );
                }}
                name="catchUpGoToBookkeeping"
              >
                Go to my income & expenses
              </WSButton>
              {year !== PREV_YEAR &&
              (totalIncomeTransactions || totalExpenseTransactions) ? (
                <WSButton
                  onClick={async () => {
                    history.push(
                      "/member/bookkeeping/setup/catch-up/income/prev-year"
                    );
                  }}
                  name="goToPrevYear"
                >
                  Go to catch up for {PREV_YEAR}
                </WSButton>
              ) : null}
            </WSButtons>
          </CatchUpLayout>
        );
      }}
    </WSQueries>
  );
};
