import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import * as React from "react";
import poweredByWingspan from "../../../assets/images/poweredByWingspan.svg";
import { WS_LINKS } from "../../../types/wsLinks";
import { openIntercom } from "../../utils/intercom";
import styles from "./index.module.scss";

type Props = {
  showContactSupport?: boolean;
  showLinks?: boolean;
  showAdditionalInfo?: boolean;
};

export const AppFooter: React.FC<Props> = ({
  showContactSupport = true,
  showLinks = true,
  showAdditionalInfo = true
}) => {
  return (
    <WSFlexBox.Center direction="column" as="footer" py="2XL" px="M">
      {showContactSupport && (
        <WSText.ParagraphSm p="M">
          Need help?
          <WSButton.Link
            className={styles.helpLink}
            size="M"
            onClick={openIntercom}
          >
            Contact support
          </WSButton.Link>
        </WSText.ParagraphSm>
      )}
      {showLinks && (
        <WSFlexBox.Center gap="S" pt="M" pb="S">
          <img src={poweredByWingspan} alt="Powered By Wingspan" />
          <WSElement className={styles.splitter} />
          <WSButton.Link
            size="S"
            onClick={() => window.open(WS_LINKS.wingspanTosAcceptance)}
            textClassName={styles.externalLink}
          >
            Terms
          </WSButton.Link>
          <WSButton.Link
            size="S"
            onClick={() =>
              window.open(WS_LINKS.wingspanPrivacyPolicyAcceptance)
            }
            textClassName={styles.externalLink}
          >
            Privacy
          </WSButton.Link>
        </WSFlexBox.Center>
      )}
      {showAdditionalInfo && (
        <WSText.ParagraphXs color="gray500">
          Wingspan is a financial services technology company and is not a bank.
          Banking services provided by Lead Bank, Member FDIC.
        </WSText.ParagraphXs>
      )}
    </WSFlexBox.Center>
  );
};
