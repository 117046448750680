import classNames from "classnames";
import sortBy from "lodash/sortBy";
import React, { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { WSElement, WSMarginProps } from "../WSElement/WSElement.component";
import {
  WSMenuBasicItem,
  WSMenuCommandEvent,
  WSMenuItem,
  WSMenuTrigger
} from "../common/WSMenu/WSMenu.component";
import {
  WSButton,
  WSButtonKind,
  WSButtonProps
} from "../core/WSButton/WSButton.component";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import {
  useCurrentScreenSize,
  WSGridBreakpoint,
  WSScreenSizes,
  WSScreenSizesType
} from "../layout";
import styles from "./WSActions.module.scss";

export type WSActionsSize = "S" | "M";
export type WSActionsAlignment = "left" | "right" | "center" | "fill";
export type WSActionsOrientation = "horizontal" | "vertical";

type ExtendedParam<V> = V | { [key in WSGridBreakpoint]?: V };

export interface WSActionsButton extends WSButtonProps {
  hideOn?: Array<WSScreenSizesType>;
  visible?: ExtendedParam<boolean>;
}

export type WSActionsMenuItem = WSMenuItem & {
  onAsyncClick?: () => Promise<void>;
};

export interface WSActionsProps extends WSMarginProps {
  className?: string;
  menuItems?: WSActionsMenuItem[];
  buttons?: WSActionsButton[];
  size?: ExtendedParam<WSActionsSize>;
  alignment?: ExtendedParam<WSActionsAlignment>;
  orientation?: ExtendedParam<WSActionsOrientation>;
  disabled?: boolean;
  sortButtons?: boolean;
}

const BUTTON_ORDER: Record<WSButtonKind, number> = {
  Primary: 5,
  Secondary: 4,
  Tertiary: 3,
  Link: 0
};

function getResponsiveValue<V = any>(
  value: { [key in WSGridBreakpoint]?: V } = {},
  screenSize: WSGridBreakpoint
): V | undefined {
  const sizes = (WSScreenSizes as any) as WSGridBreakpoint[];
  const currentIndex = sizes.indexOf(screenSize) || 0;
  let result;

  sizes.slice(0, currentIndex + 1).findLast((_size) => {
    const currentSize = (_size.toLowerCase() as any) as WSGridBreakpoint;
    if (value[currentSize] !== undefined) {
      result = value[currentSize];
      return true;
    }
  });

  return result;
}

const DEFAULT_SIZE = "M";
const DEFAULT_ALIGNMENT = "right";
const DEFAULT_ORIENTATION = "horizontal";

export const WSActions: React.FC<WSActionsProps> = ({
  className,
  menuItems,
  buttons,
  disabled,
  size = DEFAULT_SIZE,
  alignment = DEFAULT_ALIGNMENT,
  orientation = DEFAULT_ORIENTATION,
  sortButtons = true,
  ...elementProps
}) => {
  const formContext = useFormContext();
  const screenSize = useCurrentScreenSize();
  const isSubmitting = formContext?.formState?.isSubmitting;

  const { currentAlignment, currentOrientation, currentSize } = useMemo(() => {
    const breakpoint = screenSize as WSGridBreakpoint;

    const currentAlignment =
      typeof alignment === "string"
        ? alignment
        : getResponsiveValue(alignment, breakpoint) || DEFAULT_ALIGNMENT;
    const currentOrientation =
      typeof orientation === "string"
        ? orientation
        : getResponsiveValue(orientation, breakpoint) || DEFAULT_ORIENTATION;
    const currentSize =
      typeof size === "string"
        ? size
        : getResponsiveValue(size, breakpoint) || DEFAULT_SIZE;

    return { currentAlignment, currentOrientation, currentSize };
  }, [alignment, orientation, size, screenSize]);

  const updatedButtons = useMemo(() => {
    const breakpoint = screenSize as WSGridBreakpoint;

    let result = buttons
      ?.map((button) => ({
        ...button,
        loading:
          button.type === "submit"
            ? isSubmitting || button.loading
            : button.loading,
        size: currentSize || button.size,
        disabled: disabled || button.disabled
      }))
      .filter((button) => {
        const isHidden = Array.isArray(button.hideOn)
          ? button.hideOn.includes(screenSize)
          : false;

        const isVisible =
          typeof button.visible === "boolean"
            ? button.visible
            : getResponsiveValue(button.visible, breakpoint) ?? true;

        return isVisible && !isHidden;
      });

    if (sortButtons) {
      const sortedButtons = sortBy(result, (b) => {
        const kind = b.kind || "Primary";

        return b.destructive ? 2 : !b.label ? 1 : BUTTON_ORDER[kind];
      });

      return (currentAlignment === "left" &&
        currentOrientation === "horizontal") ||
        currentOrientation === "vertical"
        ? sortedButtons.reverse()
        : sortedButtons;
    }

    return result || [];
  }, [
    buttons,
    disabled,
    currentSize,
    screenSize,
    currentAlignment,
    isSubmitting
  ]);

  const [menuActionsLoading, setMenuActionsLoading] = useState(false);

  const mappedMenuActions = useMemo(() => {
    return menuItems?.map((action) => ({
      ...action,
      onClick: async (event: WSMenuCommandEvent) => {
        if (action.onAsyncClick) {
          setMenuActionsLoading(true);
          try {
            await action.onAsyncClick?.();
          } catch (error) {
            console.error(error);
          }
          setMenuActionsLoading(false);
        } else {
          (action as WSMenuBasicItem).onClick?.(event);
        }
      }
    }));
  }, [menuItems]);

  return (
    <WSElement
      className={classNames(
        styles.actions,
        styles[`alignment-${currentAlignment}`],
        styles[`orientation-${currentOrientation}`],
        styles[`size-${currentSize}`],
        {
          [styles.noSortButtons]: !sortButtons
        },
        className
      )}
      {...elementProps}
    >
      {updatedButtons?.map(({ hideOn, size, disabled, ...button }, index) => (
        <WSButton
          key={`${index} ${button.label}`}
          {...button}
          size={currentSize}
          className={classNames(
            styles.button,
            {
              [styles.fillButton]:
                !!button.label &&
                button.kind !== "Link" &&
                currentAlignment === "fill"
            },
            button.className
          )}
          disabled={disabled}
          textClassName={button.label ? styles.buttonText : undefined}
        >
          {button.label}
        </WSButton>
      ))}
      {mappedMenuActions?.length && menuActionsLoading ? (
        <WSIcon
          className={styles.menuIcon}
          block
          name="loader"
          size={currentSize}
          color={disabled ? "gray300" : "gray500"}
        />
      ) : mappedMenuActions?.length ? (
        <WSMenuTrigger
          name="actions"
          items={mappedMenuActions}
          renderTrigger={({ onToggle, ref }) => (
            <WSIcon
              className={styles.menuIcon}
              ref={ref}
              data-testid="actionsMenu"
              onClick={disabled ? undefined : onToggle}
              block
              name="dots-v"
              size={currentSize}
              color={disabled ? "gray300" : "gray500"}
            />
          )}
        />
      ) : null}
    </WSElement>
  );
};
