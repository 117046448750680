import classNames from "classnames";
import { Calendar, CalendarProps } from "primereact/calendar";
import { FormEvent, Nullable } from "primereact/ts-helpers";
import React, { useMemo } from "react";
import { locale } from "../../../utils";
import { getFsExcludeClassName } from "../../../utils/getFsExcludeClassName";
import { getInputTestIdByName } from "../../../utils/getInputTestIdByName";
import { WSInputBaseProps } from "../../types/forms";
import { detachFieldProps, WSField, WSFieldProps } from "../WSField/WSField";
import {
  WSInputContainer,
  WSInputContainerProps
} from "../WSInputContainer/WSInputContainer";
import { useIsMobile } from "../layout";
import styles from "./WSInputDate.module.scss";

export type WSInputDateProps = Omit<
  WSInputBaseProps<any, any>,
  "value" | "onChange"
> &
  Omit<WSInputContainerProps, "size" | "align"> &
  WSFieldProps &
  Omit<
    CalendarProps,
    "pt" | "prefix" | "size" | "color" | "selectionMode" | "value" | "onChange"
  > & {
    value: Nullable<Date>;
    onChange(
      value: Nullable<Date>,
      event: FormEvent<Date> | React.ChangeEvent<HTMLInputElement>
    ): void;
  };

const defaultPlaceholder = "MM/DD/YYYY";

export const WSInputDate = React.forwardRef<HTMLInputElement, WSInputDateProps>(
  (props, ref) => {
    const {
      name,
      status,
      className,
      icon,
      iconRight,
      inputAction,
      disabled,
      value,
      onChange,
      inputClassName,
      placeholder,
      fsExclude,
      ...otherProps
    } = props;

    const isMobile = useIsMobile();

    const { fieldProps, ...inputProps } = detachFieldProps(otherProps);

    const stringValue = useMemo(() => {
      if (!value) {
        return "";
      }

      return format(value);
    }, [value]);

    return (
      <WSField {...fieldProps} status={status}>
        <WSInputContainer
          status={status}
          icon={icon}
          iconRight={iconRight}
          inputAction={inputAction}
          disabled={disabled}
          className={className}
        >
          {isMobile ? (
            <input
              type="date"
              className={classNames(
                styles.input,
                inputClassName,
                getFsExcludeClassName(fsExclude)
              )}
              value={stringValue}
              onChange={(event) => {
                const newDate = event.target.valueAsDate;
                if (newDate) {
                  newDate.setHours(0, 0, 0, 0);
                  onChange?.(newDate, event);
                } else {
                  onChange?.(null, event);
                }
              }}
            />
          ) : (
            <Calendar
              inputRef={ref}
              className={classNames(
                inputClassName,
                getFsExcludeClassName(fsExclude)
              )}
              placeholder={placeholder || defaultPlaceholder}
              value={value}
              onChange={(e) => onChange?.(e.value, e)}
              pt={{
                input: { root: { className: styles.input } }
              }}
              data-testid={getInputTestIdByName(name)}
              {...(inputProps as any)}
            />
          )}
        </WSInputContainer>
      </WSField>
    );
  }
);

WSInputDate.displayName = "WSInputDate";

function format(value: Date) {
  const dayFormatted = value.toLocaleString(locale, {
    day: "2-digit"
  });

  const monthFormatted = value.toLocaleString(locale, {
    month: "2-digit"
  });

  const yearFormatted = formatYear(value.getFullYear());

  return `${yearFormatted}-${monthFormatted}-${dayFormatted}`;
}

function formatYear(year: number) {
  if (year < 10) {
    return `000${year}`;
  } else if (year < 100) {
    return `00${year}`;
  } else if (year < 1000) {
    return `0${year}`;
  } else {
    return `${year}`;
  }
}
