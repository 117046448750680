import {
  WSButton,
  WSButtons,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";

interface EmailMagicLink2FAInstructionsProps {}

export const EmailMagicLink2FAInstructions: React.FC<EmailMagicLink2FAInstructionsProps> = () => {
  const history = useHistory();
  return (
    <OnboardingLayout>
      <WSFlexBox alignContent="center" alignItems="stretch" direction="column">
        <WSText.Heading5 mb="M" align="center">
          Security confirmation
        </WSText.Heading5>
        <WSText color="gray600" mb="XL" align="left">
          An email has been sent to your registered e-mail address.
          <br />
          <br />
          Please check your inbox and click on the <b>
            Verify Your Account
          </b>{" "}
          <br />
          link to proceed further.
        </WSText>
        <WSButtons>
          <WSButton onClick={() => history.push("/member/dashboard")}>
            Go to dashboard
          </WSButton>
          <WSButton.Secondary onClick={() => history.push("/member/settings")}>
            Go to settings
          </WSButton.Secondary>
        </WSButtons>
      </WSFlexBox>
    </OnboardingLayout>
  );
};
