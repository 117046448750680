import {
  toWSDateString,
  WSButton,
  WSButtons,
  WSInputDateOld,
  WSFormOld,
  WSMessageBox,
  WSModal,
  WSPage,
  WSScreen,
  WSSelectOld,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { addDays, addYears } from "date-fns";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { useCreateApiSession } from "../../query/users/mutations";
import { goToParentRoute } from "../../utils/goToParentRoute";
import {
  EXPIRATION_IN_DAYS,
  getExpirationSelectOptions
} from "../utils/developerUtils";
import styles from "./AddNewToken.module.scss";

export interface AddNewTokenProps {}

export const AddNewToken: React.FC<AddNewTokenProps> = () => {
  const history = useHistory();
  const [expirationValue, setExpirationValue] = useState("30");
  const [createApiSession] = useCreateApiSession();

  const onFormSubmit = async (data: any) => {
    // For NO_EXPIRATION, we are setting expiry for 10yrs but
    // we don't use it as we set isUnlimitedExpiration to true.
    let expirationDate = addYears(new Date(), 10);
    let isUnlimitedExpiration = false;
    if (EXPIRATION_IN_DAYS.includes(data.expiration)) {
      expirationDate = addDays(new Date(), Number(data.expiration));
    } else if (data.expiration === "CUSTOM") {
      expirationDate = data.customExpiryDate;
    } else if (data.expiration === "NO_EXPIRATION") {
      isUnlimitedExpiration = true;
    }
    // TODO: fix IApiSessionCreateRequest interface
    const request: any = {
      expiration: expirationDate,
      isUnlimitedExpiration,
      labels: {
        description: data.whatIsThisFor
      }
    };
    await createApiSession(request, {
      onSuccess: () => {
        history.push("/member/settings/tokens");
      }
    });
  };
  const addTokenForm = (
    <WSFormOld
      defaultValues={{
        whatIsThisFor: "",
        expiration: expirationValue
      }}
      validationSchema={Yup.object().shape({
        expiration: Yup.string().required("Expiration is required."),
        customExpiryDate: Yup.date().when("expiration", {
          is: "CUSTOM",
          then: Yup.date().required("Custom expiry date is required.")
        })
      })}
      onSubmit={onFormSubmit}
    >
      <WSFormOld.Field
        mb="XL"
        name="whatIsThisFor"
        label="What's this token for? (optional)"
        component={WSTextInput}
      />

      <WSFormOld.Field
        mb="XL"
        name="expiration"
        label="Expiration"
        component={WSSelectOld}
        componentProps={{
          onChange: val => {
            setExpirationValue(val);
          },
          options: getExpirationSelectOptions()
        }}
        description={
          EXPIRATION_IN_DAYS.includes(expirationValue)
            ? `The token will expire on ${toWSDateString(
                addDays(new Date(), Number(expirationValue)),
                "monthDayYear"
              )}`
            : undefined
        }
      />

      {expirationValue === "CUSTOM" ? (
        <WSFormOld.Field
          my="XL"
          name="customExpiryDate"
          component={WSInputDateOld}
          componentProps={{
            minDate: new Date()
          }}
        />
      ) : null}

      {expirationValue === "NO_EXPIRATION" ? (
        <WSMessageBox icon="alert-circle" mb="XL">
          Tokens that live forever are scary. Expiration dates are highly
          recommended!
        </WSMessageBox>
      ) : null}

      <WSButtons format="modal">
        <WSFormOld.SubmitButton>Generate</WSFormOld.SubmitButton>
        <WSButton.Tertiary
          onClick={() => history.replace("/member/settings/tokens")}
        >
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </WSFormOld>
  );
  return (
    <>
      <BrowserPageTitle title="Generate token" />
      <WSScreen.MobileAndTablet>
        <WSPage
          title="Generate token"
          breadcrumb={{
            label: "Developer settings",
            icon: "arrow-left",
            onClick: () => goToParentRoute(history)
          }}
        >
          {addTokenForm}
        </WSPage>
      </WSScreen.MobileAndTablet>
      <WSScreen.Desktop>
        <WSModal
          size="S"
          onClose={() => goToParentRoute(history)}
          title="New API token"
          className={styles.addNewTokenModal}
          blockClose
        >
          {addTokenForm}
        </WSModal>
      </WSScreen.Desktop>
    </>
  );
};
