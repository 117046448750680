import {
  WSButton,
  WSButtons,
  WSElement,
  WSFormOld,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { WSQueries } from "../../../../../query/WSQuery";
import { useWSMutation } from "../../../../../query/helpers";
import { useUserId } from "../../../../../query/hooks/helpers";
import { QUERY_USERS_USER_PROFILE } from "../../../../../query/users/keys";
import { useUserProfile } from "../../../../../query/users/queries";
import { usersService } from "../../../../../services/users";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { getChangedData } from "../../../../../utils/getChangedData";
import { WSServiceError } from "../../../../../utils/serviceHelper";
import { LayoutW9 } from "../../../components/LayoutW9";
import {
  validatorFirstName,
  validatorLastName
} from "../../../../../utils/validators";

type FormData = {
  firstName: string;
  lastName: string;
};

export const RoutePersonalInformationVerification: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const userId = useUserId();

  const queryUser = useUserProfile(userId);

  const [submit, submitMeta] = useWSMutation<
    FormData,
    WSServiceError,
    FormData
  >(
    async data => {
      let user = await usersService.user.get(userId);
      user = await usersService.user.update(userId, {
        profile: getChangedData(user.profile, {
          firstName: data.firstName,
          lastName: data.lastName
        })
      });

      return data;
    },
    {
      dependencies: [QUERY_USERS_USER_PROFILE],
      onSuccess: onNext
    }
  );

  return (
    <LayoutW9
      title="Personal Information Verification"
      description="Wingspan collects this information to verify your identity and keep your account safe."
    >
      <WSQueries queries={{ queryUser }}>
        {({ queryUser: { data: user } }) => (
          <WSFormOld<FormData>
            defaultValues={{
              firstName: user?.profile.firstName,
              lastName: user?.profile.lastName
            }}
            validationSchema={Yup.object().shape({
              firstName: validatorFirstName.required("Required"),
              lastName: validatorLastName.required("Required")
            })}
            onSubmit={values => {
              submit(values);
            }}
          >
            <WSElement mb="2XL">
              <WSFormOld.Label mb="M">Your legal name</WSFormOld.Label>
              <WSFormOld.Field
                mb="M"
                name="firstName"
                component={WSTextInput}
                componentProps={{ placeholder: "First name" }}
                defaultValue=""
              />
              <WSFormOld.Field
                name="lastName"
                component={WSTextInput}
                componentProps={{ placeholder: "Last name" }}
                defaultValue=""
              />
            </WSElement>

            <WSErrorMessage
              mb="2XL"
              contextKey="NEC1099Onboarding"
              error={submitMeta.error}
            />

            <WSButtons forceFullWidth>
              <WSButton loading={submitMeta.isLoading}>Continue</WSButton>
            </WSButtons>
          </WSFormOld>
        )}
      </WSQueries>
    </LayoutW9>
  );
};
