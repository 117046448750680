import { Snackbar } from "../../../../hooks/useSnackbar";
import { download1099 } from "../../../../services/payments";
import { downloadFileFromBlob } from "../../../../utils/files";
import getFetchError from "../../../../utils/getFetchError";
import isFilingYear from "../../../../utils/isFilingYear";

export default async function download1099NecPdf(
  collaborationId: string,
  filingYear: string,
  index: number,
  snackbar: Snackbar
) {
  if (!isFilingYear(filingYear))
    throw new Error(`Provided incorrect filing year: "${filingYear}"!`);

  try {
    snackbar.success("Downloading 1099-NEC ...");
    const pdfBlob = await download1099(collaborationId, filingYear, index);
    downloadFileFromBlob(pdfBlob, `1099-NEC_${filingYear}.pdf`);
  } catch (error) {
    const errorData = getFetchError(error);
    // replace error message as blob with parsed JSON
    const errorInfo = errorData.data && JSON.parse(await errorData.data.text());
    if (errorInfo) errorData.data = errorInfo;

    console.error(
      `Error when downloading NEC-1099 PDF for contractor with id: ${collaborationId}!`,
      errorData
    );
    snackbar.warning("Error: downloading NEC-1099 PDF failed!");
  }
}
