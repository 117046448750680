import {
  IPayeeEngagementResponse,
  IPayerEngagementResponse
} from "@wingspanhq/payments/dist/interfaces";
import { ISearchablePayerPayeeEngagement } from "../../../services/search";

export const selectorPayerPayeeEngagementName = <R extends boolean = false>(
  engagement:
    | IPayeeEngagementResponse
    | IPayerEngagementResponse
    | ISearchablePayerPayeeEngagement,
  showDefaultName: R
) => {
  if (!showDefaultName && engagement.isDefault) {
    return undefined as R extends true ? string : undefined;
  }

  return engagement.engagementName as R extends true ? string : undefined;
};
