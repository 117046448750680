import { QueryConfig } from "react-query";
import { getVerifications } from "../../../services/api/onboarding/verifications";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";

export const QUERY_VERIFICATIONS = "QUERY_VERIFICATIONS";

export const useQueryVerifications = (
  config?: QueryConfig<
    Awaited<ReturnType<typeof getVerifications>>,
    WSServiceError
  >
) => useWSQuery(QUERY_VERIFICATIONS, getVerifications, config);
