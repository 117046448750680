// Hook for checking if a component is mounted
// https://stackoverflow.com/questions/53949393/how-to-fix-cant-perform-a-react-state-update-on-an-unmounted-component
//
// Usage:
// import { useIsMounted } from './useIsMounted';
//
// const MyComponent = () => {
//   const isMounted = useIsMounted();
//
//   useEffect(() => {
//     if (isMounted.current) {
//       // do something
//     }
//   }, [isMounted]);
// }
import { useEffect, useMemo, useRef } from "react";

export const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return useMemo(() => () => isMounted.current, []);
};
