import {
  useModalContext,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

export const NOT_YOU_MODAL = "NOT_YOU_MODAL";

export const NotYouModal: React.FC<{
  email: string;
  companyName: string;
  onLeave(): void;
  onUpdate(): void;
}> = ({ companyName, onLeave, email, onUpdate }) => {
  return (
    <WSModal name={NOT_YOU_MODAL} title="Not you?" size="M">
      <WSText mb="XL">
        By updating your email now, your new account will not be associated with{" "}
        {companyName}. You should do this only if you do not recognize the email
        address shown on this page. If you would like to update your email
        address, you’ll have an opportunity to do this later.
      </WSText>

      <WSButtons>
        <WSButton.Primary mb="XL" onClick={onLeave}>
          Leave {email}
        </WSButton.Primary>
        <WSButton.Secondary mb="XL" onClick={onUpdate}>
          Use a different email
        </WSButton.Secondary>
      </WSButtons>
    </WSModal>
  );
};
