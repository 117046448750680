import {
  WSButton,
  WSButtons,
  WSFlexBox,
  WSFormOld,
  WSMessageBox,
  WSRadioInputGroup,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";

import { CalculationSettings1099Toggle } from "@wingspanhq/payments/dist/interfaces";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUpdatePayrollSettings } from "../../../../query/payments/mutations";
import { usePayrollSettings } from "../../../../query/payments/queries";
import { useUpdateActivity } from "../../../../query/users/mutations";
import { useActivities } from "../../../../query/users/queries";
import { get1099SetupProgress } from "../../../../query/users/selectors";
import { WSQueries } from "../../../../query/WSQuery";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";

interface CalculationSettingsFormValues {
  offPlatformPayments: CalculationSettings1099Toggle;
  cardProcessingFees: CalculationSettings1099Toggle;
  reimbursableExpenses: CalculationSettings1099Toggle;
}

export const FormCalculationSettings: React.FC<SetupRouteComponentProps & {
  isEdit?: boolean;
}> = ({ onBack, onNext, isEdit = false }) => {
  const history = useHistory();
  const userId = useUserId();
  const qActivity = useActivities(userId);

  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);
  const [
    updatePayrollSettings,
    updatePayrollSettingsMeta
  ] = useUpdatePayrollSettings(userId);
  const qPayrollSettings = usePayrollSettings(userId);

  const onFormSubmit = async (data: CalculationSettingsFormValues) => {
    await updatePayrollSettings(
      {
        calculationSettings1099: {
          offPlatformPayments: data.offPlatformPayments,
          cardProcessingFees: data.cardProcessingFees,
          reimbursableExpenses: data.reimbursableExpenses
        }
      },
      {
        onSuccess: async () => {
          // skip activity update if this is edit flow
          if (!isEdit) {
            const nec1099SetupProgress = get1099SetupProgress(qActivity.data);
            await updateActivity(
              {
                flows: {
                  nec1099Setup: {
                    ...nec1099SetupProgress,
                    currentStep: 4
                  }
                }
              },
              {
                onSuccess: () => {
                  onNext();
                }
              }
            );
          } else {
            history.push("/member/1099-filing/dashboard/all");
          }
        }
      }
    );
  };
  return (
    <WSQueries queries={{ qPayrollSettings }}>
      {({ qPayrollSettings: { data: payrollSettings } }) => {
        const { calculationSettings1099 } = payrollSettings;
        return (
          <WSFormOld<CalculationSettingsFormValues>
            defaultValues={{
              offPlatformPayments: calculationSettings1099?.offPlatformPayments,
              cardProcessingFees: calculationSettings1099?.cardProcessingFees,
              reimbursableExpenses:
                calculationSettings1099?.reimbursableExpenses
            }}
            validationSchema={Yup.object().shape({
              offPlatformPayments: Yup.string().required("Required"),
              cardProcessingFees: Yup.string().required("Required"),
              reimbursableExpenses: Yup.string().required("Required")
            })}
            onSubmit={onFormSubmit}
          >
            <WSFormOld.Label>
              <WSFlexBox.CenterY mb="M">
                <WSText color="gray500">Off-platform payments</WSText>
              </WSFlexBox.CenterY>
            </WSFormOld.Label>
            <WSFormOld.Field
              mb="3XL"
              name="offPlatformPayments"
              component={WSRadioInputGroup}
              componentProps={{
                disabled:
                  calculationSettings1099?.offPlatformPayments ===
                  CalculationSettings1099Toggle.Include,
                optionHasBorder: true,
                options: [
                  {
                    label: "Include invoices paid outside of Wingspan",
                    value: CalculationSettings1099Toggle.Include
                  },
                  {
                    label: "Exclude invoices paid outside of Wingspan",
                    value: CalculationSettings1099Toggle.Exclude
                  }
                ]
              }}
            />

            <WSFormOld.Label>
              <WSFlexBox.CenterY mb="M">
                <WSText color="gray500">Card processing fees</WSText>
              </WSFlexBox.CenterY>
            </WSFormOld.Label>
            <WSFormOld.Field
              mb="3XL"
              name="cardProcessingFees"
              component={WSRadioInputGroup}
              componentProps={{
                optionHasBorder: true,
                options: [
                  {
                    label: "Include card processing fees",
                    value: CalculationSettings1099Toggle.Include
                  },
                  {
                    label: "Exclude card processing fees",
                    value: CalculationSettings1099Toggle.Exclude
                  }
                ]
              }}
            />

            <WSFormOld.Label>
              <WSFlexBox.CenterY mb="M">
                <WSText color="gray500">Reimbursable expenses</WSText>
              </WSFlexBox.CenterY>
            </WSFormOld.Label>
            <WSFormOld.Field
              mb="3XL"
              name="reimbursableExpenses"
              component={WSRadioInputGroup}
              componentProps={{
                optionHasBorder: true,
                options: [
                  {
                    label: "Include reimbursable expenses",
                    value: CalculationSettings1099Toggle.Include
                  },
                  {
                    label: "Exclude reimbursable expenses",
                    value: CalculationSettings1099Toggle.Exclude
                  }
                ]
              }}
            />

            <WSMessageBox.Info mt="2XL" size="Large">
              <WSText weight="medium" color="gray600">
                Consult a tax advisor
              </WSText>
              <WSText.ParagraphSm mt="M" color="gray500">
                Wingspan advises you to consult with a tax professional to
                choose the most suitable calculation options for your business
              </WSText.ParagraphSm>
            </WSMessageBox.Info>
            <WSButtons mt="3XL">
              <WSButton.Secondary className={styles.actionBtn} onClick={onBack}>
                Back
              </WSButton.Secondary>
              <WSFormOld.SubmitButton
                className={styles.actionBtn}
                name="submitCalculationSettings"
                loading={
                  updateActivityMeta.isLoading ||
                  updatePayrollSettingsMeta.isLoading
                }
              >
                Continue
              </WSFormOld.SubmitButton>
            </WSButtons>
          </WSFormOld>
        );
      }}
    </WSQueries>
  );
};
