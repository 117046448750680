import {
  WSButton,
  WSButtons,
  WSDivider,
  WSFormOld,
  WSRadioInputGroup,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useUserId } from "../../../../../query/hooks/helpers";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { LayoutW9 } from "../../../components/LayoutW9";
import { useUpdateMemberProfile } from "../../../../../query/users/mutations";
import { TinType } from "@wingspanhq/payments/dist/interfaces";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { WSQueries } from "../../../../../query/WSQuery";
import { useMemberProfile } from "../../../../../query/users/queries";
import { usePayeeTaxForms } from "../../../../../query/taxForm/queries/usePayeeTaxForms";
import { CURRENT_YEAR } from "../../../constants/currentYear";

type FormData = {
  identificationNumberType: TinType;
};

export type RouteTaxpayerIdentificationNumberContext = {
  isUSPerson?: boolean;
};

export const RouteTaxpayerIdentificationNumber: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const userId = useUserId();

  const [updateMember, updateMemberMeta] = useUpdateMemberProfile();
  const queryMember = useMemberProfile(userId);
  const queryTaxForms = usePayeeTaxForms(CURRENT_YEAR);

  return (
    <LayoutW9
      title="Taxpayer Identification Number"
      description="Choose the identification number you’ll use for tax reporting with clients"
      onBack={onBack}
    >
      <WSQueries queries={{ queryMember, queryTaxForms }}>
        {({ queryMemberData, queryTaxFormsData }) => (
          <WSFormOld<FormData>
            defaultValues={{
              identificationNumberType:
                queryMemberData.profile.formW9Options?.tinPreference ||
                queryTaxFormsData[0]?.recipientVerification?.tinType ||
                TinType.Individual
            }}
            onSubmit={async values => {
              await updateMember(
                {
                  memberId: userId,
                  profile: {
                    formW9Options: {
                      tinPreference: values.identificationNumberType
                    }
                  }
                },
                {
                  onSuccess: () => {
                    queryMember.remove();
                    onNext({ isUSPerson: true });
                  }
                }
              );
            }}
          >
            <WSFormOld.Field
              mb="XL"
              name="identificationNumberType"
              label="Identification number"
              component={WSRadioInputGroup}
              componentProps={{
                optionHasBorder: true,
                itemElementProps: { mb: "S" },
                options: [
                  {
                    label: "Social Security Number (SSN)",
                    description:
                      "Ideal for individuals, sole proprietors without a formal business structure. This is your personal 9-digit number.",
                    value: TinType.Individual
                  },
                  {
                    label: "Employer Identification Number (EIN)",
                    description:
                      "Choose this if you’re representing a legally registered business. If you’ve set up an LLC, Corporation, or Partnership, you likely have an EIN.",
                    value: TinType.Business
                  }
                ]
              }}
            />

            <WSErrorMessage error={updateMemberMeta.error} contextKey="Other" />

            <WSButtons forceFullWidth mt="2XL">
              <WSFormOld.SubmitButton>Continue</WSFormOld.SubmitButton>
              <WSButton.Secondary type="button" onClick={onBack}>
                Cancel
              </WSButton.Secondary>
            </WSButtons>
          </WSFormOld>
        )}
      </WSQueries>
      <WSDivider label="Or" my="XL" />
      <WSButton.Secondary
        onClick={() => onNext({ isUSPerson: false })}
        type="button"
        fullWidth
      >
        I am not a U.S. person
      </WSButton.Secondary>
      <WSText.ParagraphSm color="gray400" mt="XL">
        Choose this if you are not a U.S. citizen or other U.S. person,
        including a resident alien individual
      </WSText.ParagraphSm>
    </LayoutW9>
  );
};
