import {
  useIsMobile,
  WSButton,
  WSFormOld,
  WSInputDateOld
} from "@wingspanhq/fe-component-library";
import { IUserUpdateRequest } from "@wingspanhq/users/dist/lib/interfaces";
import format from "date-fns/format";
import React from "react";
import * as Yup from "yup";
import { useUpdateUserProfile } from "../../../query/users/mutations";
import styles from "./SettingsUpdateForms.module.scss";
import { openIntercomMessage } from "../../../shared/utils/intercom";

export interface RequestDOBChangeFormProps {
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (e: any) => void;
}

export const RequestDOBChangeForm: React.FC<RequestDOBChangeFormProps> = ({
  onSubmit,
  defaultValues
}) => {
  const isMobile = useIsMobile();
  const [updateUserProfile] = useUpdateUserProfile();

  const onFormSubmit = async (data: any) => {
    if (defaultValues.dob) {
      openIntercomMessage(
        `Hi there! I want to update my date of birth to ${format(
          new Date(data.dob),
          "MM/dd/yyyy"
        )}.\n\n`
      );
    } else {
      const request: IUserUpdateRequest = {
        profile: {
          dob: data.dob
        }
      };
      await updateUserProfile(request);
    }
    onSubmit(data);
  };
  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        dob: Yup.string().required("This field is required")
      })}
    >
      <WSFormOld.Field name="dob" component={WSInputDateOld} />
      <WSButton type="submit" fullWidth={isMobile} mt="2XL">
        {defaultValues.dob ? "Send request to customer support" : "Update"}
      </WSButton>
    </WSFormOld>
  );
};
