import { NotificationTemplate } from "../index";

export const UnitVerificationPending: NotificationTemplate<never> = {
  isPending: true,
  renderTitle: props =>
    "We're verifying your documents to unlock all banking features",
  renderBody: props => {
    return "Our team is reviewing your documents to unlock all banking features like Wingspan Wallet and Tax Withholding. This can take up to 2 business days.";
  }
};
