import { useWSMutation } from "../../helpers";
import { createPayer } from "../../../services/payers";
import { IPayerCreateRequest } from "@wingspanhq/payments/dist/interfaces";
import { QUERY_ALL_PAYER_LIST } from "../keys";
import { QUERY_PAYER_ROWS } from "../../search/payer/queries/useQueryPayerRows";
import { QUERY_PAYER_ROWS_SUMMARY } from "../../search/payer/queries/useQueryPayerRowsSummary";

export const useCreatePayer = () =>
  useWSMutation((payload: IPayerCreateRequest) => createPayer(payload), {
    dependencies: [
      QUERY_ALL_PAYER_LIST,
      QUERY_PAYER_ROWS,
      QUERY_PAYER_ROWS_SUMMARY
    ]
  });
