import { IActivity } from "@wingspanhq/users";
import { get1099SetupProgress } from "../../../../../query/users/selectors";
import { KEY_IMPORT_RECIPIENTS_BULK_BATCH_ID } from "../../../constants/storage";

export function getRedirectPath(activity: IActivity): string {
  const nec1099Setup = get1099SetupProgress(activity);
  if (!nec1099Setup?.complete) {
    if (nec1099Setup?.currentStep <= 1) {
      return "/member/1099-filing/set-up/confirm-payer-info";
    } else if (nec1099Setup?.currentStep === 2) {
      const bulkBatchId = window.localStorage.getItem(
        KEY_IMPORT_RECIPIENTS_BULK_BATCH_ID
      );
      if (bulkBatchId) {
        return `/member/1099-filing/set-up/recipients-import/${bulkBatchId}/preview`;
      } else {
        return "/member/1099-filing/set-up/select-import-recipients";
      }
    } else if (nec1099Setup?.currentStep === 3) {
      return "/member/1099-filing/set-up/calculation-settings";
    } else if (nec1099Setup?.currentStep === 4) {
      return "/member/1099-filing/set-up/state-filing-settings";
    }
  }
  return "/member/1099-filing/dashboard/all";
}
