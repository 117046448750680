import React from "react";
import { Moment } from "moment/moment";
import { useIsDesktop } from "@wingspanhq/fe-component-library";

export const NextPaymentInfo: React.FC<{
  internalDeadlineFromNow: string;
  internalProcessingDeadline: Moment;
}> = ({ internalDeadlineFromNow, internalProcessingDeadline }) => {
  const isMobile = !useIsDesktop();

  const isFuture = internalProcessingDeadline.isAfter(new Date(), "day");
  const isToday = internalProcessingDeadline.isSame(new Date(), "day");

  const diff = isFuture
    ? ` due in ${internalDeadlineFromNow} `
    : isToday
    ? " today "
    : ` was due ${internalDeadlineFromNow} ago`;

  return (
    <>
      Next quarterly payment{diff} {isMobile ? <br /> : null} (
      <b>{internalProcessingDeadline.format("MMM DD, h:mm A")} EST</b>).
    </>
  );
};
