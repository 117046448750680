import { TaxFilingCode } from "@wingspanhq/bookkeeping/dist/lib/interfaces/taxEstimate";
import {
  IPayoutSettingsResponse,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import {
  AccountStatus,
  IAccount,
  IActivity,
  IMember,
  INewUser,
  IRedactedMember,
  IRedactedUser
} from "@wingspanhq/users/dist/lib/interfaces";
import orderBy from "lodash/orderBy";
import {
  COUNTRY_OPTIONS,
  HEAD_OF_HOUSEHOLD_OPTIONS,
  MARRIED_OPTIONS,
  SINGLE_OPTIONS
} from "../../constants/user";
import { selectorAccountVerificationPending } from "../../shared/selectors/selectorAccountVerificationPending";

export const getPossiblePayoutAccounts = (accounts: IAccount[]) =>
  orderBy(
    accounts.filter(
      account =>
        account.canBeUsedFor?.payouts &&
        account.status !== AccountStatus.Inactive
    ),
    "name",
    "asc"
  );

export const getPayoutAccount = (
  payoutSettings?: IPayoutSettingsResponse,
  accounts?: IAccount[]
) => {
  const standardPayoutDestination = payoutSettings?.payoutDestinations.find(
    destination => destination.payoutMethod === PayoutPreferences.Standard
  );
  return (accounts || []).find(
    account => account.accountId === standardPayoutDestination?.destinationId
  );
};

export const getPossiblePaymentsAccounts = (accounts: IAccount[]) =>
  orderBy(
    accounts.filter(
      account =>
        (account.canBeUsedFor?.payments ||
          selectorAccountVerificationPending(account)) &&
        account.status !== AccountStatus.Inactive
    ),
    "name",
    "asc"
  );

export const getPaymentsSetupProgress = (activity?: IActivity) => {
  const paymentsSetupProgress = activity?.flows.paymentsSetup;

  return (
    paymentsSetupProgress || {
      version: 3,
      currentStep: 0,
      totalSteps: 5,
      complete: false
    }
  );
};

export const get1099SetupProgress = (activity?: IActivity) => {
  return (
    activity?.flows.nec1099Setup || {
      version: 1,
      currentStep: 0,
      totalSteps: 4,
      complete: false
    }
  );
};

export const get1099OnboardingProgress = (activity?: IActivity) => {
  return (
    activity?.flows.onboarding1099 || {
      version: 1,
      currentStep: 0,
      totalSteps: 4,
      complete: false
    }
  );
};

export const getIncomeOptionsByFilingCode = (
  code: TaxFilingCode | undefined
) => {
  return code
    ? [
        TaxFilingCode.Single,
        TaxFilingCode.MarriedFilingSeparately,
        TaxFilingCode.QualifiedWidower
      ].includes(code)
      ? SINGLE_OPTIONS
      : [TaxFilingCode.Married].includes(code)
      ? MARRIED_OPTIONS
      : HEAD_OF_HOUSEHOLD_OPTIONS
    : [];
};

export const benefitsContactFormIsComplete = (
  user: INewUser,
  member: IMember
) =>
  !!user.profile.firstName &&
  !!user.profile.lastName &&
  !!member.profile.address?.addressLine1 &&
  !!member.profile.address?.city &&
  !!member.profile.address?.state &&
  !!member.profile.address?.postalCode &&
  !!user.phone?.number &&
  !!user.profile.dob &&
  !!user.email;

export const getUserLegalName = (
  user: INewUser | IRedactedUser,
  firstName?: string,
  lastName?: string
) =>
  `${firstName || user.profile?.firstName || ""} ${lastName ||
    user.profile?.lastName ||
    ""}`.trim();

export const getUserName = (user: INewUser) =>
  user.profile.preferredName || getUserLegalName(user);

export const getRedactedUserName = (user: IRedactedUser) =>
  `${user?.profile?.firstName || ""} ${user?.profile?.lastName || ""} ${
    (user?.profile as any)?.preferredName
      ? `(${(user?.profile as any)?.preferredName})`
      : ""
  }`.trim();

export const getRedactedMemberName = (member: IRedactedMember) =>
  getRedactedUserName(member.user) ||
  member.profile?.company?.name ||
  (member.user.email as string);

export const getMemberCountry = (member: IMember) =>
  member.profile.address?.country || "US";

export const getIsDomesticMember = (member: IMember) =>
  getMemberCountry(member) === "US";

export const getCountryName = (alpha2Code: string) =>
  COUNTRY_OPTIONS.find(country => country.value === alpha2Code)?.label;

export const getMemberCountryName = (member: IMember) =>
  getCountryName(getMemberCountry(member));

export const getRedactedMemberCountry = (member: IRedactedMember) =>
  member.profile?.address?.country || "US";

export const getIsDomesticRedactedMember = (member: IRedactedMember) =>
  getRedactedMemberCountry(member) === "US";

export const getMemberCompanyName = (member: IMember | IRedactedMember) =>
  member.profile?.company?.name ||
  `${member.user.profile?.firstName} ${member.user.profile?.lastName}`;
