export const UPLOAD_DOCUMENTS_ALLOWED_FORMATS = {
  label: "images, pdf files",
  value: "application/pdf,application/msword,image/*"
};

export const DocumentVisibility = {
  Owner: "Owner",
  Viewer: "Viewer"
};

export const DOCUMENT_VISIBILITY_OPTIONS = [
  {
    label: "Only visible to you",
    value: DocumentVisibility.Owner
  },
  {
    label: "Visible to you and the contractor",
    value: DocumentVisibility.Viewer
  }
];
