import { useWSMutation } from "../../helpers";
import { QUERY_SHARED_FILE_SETTINGS } from "../keys";
import { createSharedFileSettings } from "../../../services/sharedFile";

export const useCreateSharedFileSettings = () =>
  useWSMutation(createSharedFileSettings, {
    dependencies(payload) {
      return [QUERY_SHARED_FILE_SETTINGS, payload.sharedFileRequestSettingsId];
    }
  });
