import { WSAlert } from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useQueryVerifications } from "../../../query/onboarding/queries/useQueryVerifications";
import { useAct } from "../../../shared/hooks/useAct";
import { verificationService } from "../../../shared/services/verificaiton";
import { useQueryInternalAccounts } from "../../../query/banking/queries/useQueryInternalAccounts";

export const BannerBankingVerification: React.FC = () => {
  const queryVerifications = useQueryVerifications();
  const queryInternalAccounts = useQueryInternalAccounts();

  if (queryVerifications.isLoading || queryInternalAccounts.isLoading) {
    return null;
  }

  return !queryVerifications.data ||
    queryVerifications.data?.banking === "None" ||
    queryVerifications.data?.banking === "UpdateRequired" ? (
    <RequireAdditionalInfoAlert />
  ) : queryVerifications.data?.banking === "Pending" ||
    queryVerifications.data?.banking === "Failed" ? (
    <WSAlert
      theme="info"
      icon="info-circle"
      title="Banking verification in progress"
    >
      Your information was received and our team is currently reviewing it. Once
      the information is approved, features such as Wingspan Wallet and Tax
      Withholdings can be activated. The review process typically takes less
      than one (1) business day. Thank you for your patience.
    </WSAlert>
  ) : null;
};

const RequireAdditionalInfoAlert: React.FC = () => {
  const history = useHistory();

  const queryInternalAccounts = useQueryInternalAccounts();

  const pendingAccounts = useMemo(() => {
    const internalAccounts = queryInternalAccounts.data ?? [];
    return internalAccounts.filter(acc => acc.status === "Pending");
  }, [queryInternalAccounts.data]);

  const hasRequestedWalletOnly = useMemo(() => {
    return (
      pendingAccounts.length === 1 && pendingAccounts[0].type === "Banking"
    );
  }, [pendingAccounts]);

  const hasRequestedTaxWithholdingOnly = useMemo(() => {
    return (
      pendingAccounts.length === 1 &&
      pendingAccounts[0].type === "TaxWithholding"
    );
  }, [pendingAccounts]);

  const { act, isLoading } = useAct(() =>
    verificationService.completeBankingVerification(history)
  );

  // If the user has requested both Wallet and TaxWithholding, we will show the Wallet alert
  let title = "Additional information needed to activate banking features";
  let description =
    "Additional information is required to use Wingspan Wallet or Tax Withholding, per banking regulations.";

  if (hasRequestedTaxWithholdingOnly) {
    title = "Additional information needed to activate Tax Withholding";
    description =
      "Additional information is required to use Tax Withholding, per banking regulations.";
  } else if (hasRequestedWalletOnly) {
    title = "Additional information needed to activate Wingspan Wallet";
    description =
      "Additional information is required to use Wingspan Wallet, per banking regulations.";
  }
  return (
    <WSAlert
      theme="warning"
      icon="alert"
      title={title}
      action={{
        loading: isLoading,
        text: "Complete additional information",
        onClick: act
      }}
    >
      {description}
    </WSAlert>
  );
};
