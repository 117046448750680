import { PaymentMethod } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { WSQueries } from "../../../query/WSQuery";
import { useCollaboratorQuery } from "../../../query/payments/queries";
import { FlowSetup } from "../../../shared/components/FlowSetup";
import { useWSStore } from "../../../store";
import { SetupConfirm } from "./SetupConfirm";
import { SetupConsent } from "./SetupConsent";
import { SetupCreditCard } from "./SetupCreditCard";
import { SetupPaymentMethod } from "./SetupPaymentMethod";
import { usePayeeQuery } from "../../../query/payee/queries/usePayeeQuery";

export type AutopaySetupParams = {
  collaboratorId?: string;
  payeeId?: string;
};

export const AutopaySetup: React.FC<RouteComponentProps<{
  collaboratorId?: string;
  payeeId?: string;
}>> = ({
  match: {
    params: { collaboratorId, payeeId }
  }
}) => {
  const history = useHistory();
  const store = useWSStore();
  const basePath = "/member/autopay/" + (collaboratorId ?? payeeId);

  const collaboratorQuery = useCollaboratorQuery(collaboratorId as string, {
    enabled: !!collaboratorId
  });

  const payeeQuery = usePayeeQuery(payeeId as string, {
    enabled: !!payeeId
  });

  return (
    <WSQueries
      queries={{
        ...(collaboratorId && { collaboratorQuery }),
        ...(payeeId && { payeeQuery })
      }}
    >
      {() => {
        return (
          <>
            <FlowSetup
              basePath={basePath}
              params={{ collaboratorId, payeeId }}
              routes={[
                {
                  path: "/payment-method",
                  component: SetupPaymentMethod,
                  onBack: () => {},
                  onNext: context => {
                    if (context?.paymentMethodType === PaymentMethod.Credit) {
                      history.push(basePath + "/credit-card");
                    } else {
                      history.push(basePath + "/consent");
                    }
                  }
                },
                {
                  path: "/credit-card",
                  component: SetupCreditCard,
                  onBack: () => {
                    history.push(basePath + "/payment-method");
                  },
                  onNext: () => {
                    history.push(basePath + "/consent");
                  }
                },
                {
                  path: "/consent",
                  component: SetupConsent,
                  onBack: () => {
                    history.push(basePath + "/payment-method");
                  },
                  onNext: () => {
                    history.push(basePath + "/confirm");
                  }
                },
                {
                  path: "/confirm",
                  component: SetupConfirm,
                  onBack: () => {
                    history.push(basePath + "/consent");
                  },
                  onNext: () => {
                    if (store.signUpAutopayDetails?.redirectTo) {
                      window.open(
                        store.signUpAutopayDetails.redirectTo,
                        "_self"
                      );
                    } else {
                      if (payeeId) {
                        history.push("/member/payees/" + payeeId);
                      } else {
                        history.push(
                          "/member/invoices/contacts/collaborators/" +
                            collaboratorId
                        );
                      }
                    }
                  }
                }
              ]}
            />
          </>
        );
      }}
    </WSQueries>
  );
};
