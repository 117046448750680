import React, { useMemo } from "react";
import { WSElement } from "../../WSElement/WSElement.component";
import { WSIcon, WSIconName } from "../../core/WSIcon/WSIcon.component";
import { WSText } from "../../core/WSText/WSText.component";
import { WSBadge } from "../WSBadge/WSBadge.component";
import styles from "./WSPill.component.module.scss";
import { WSPillProps, WSPillTheme, WSPillType } from "./types";
import { badgeThemeMap, getPillClassNames, isWSPillStatusTheme, statusIconMap } from "./utils";

export type * from "./types";

const THEME_DEFAULT: WSPillTheme = "neutral";
const TYPE_DEFAULT: WSPillType = "light";

export const WSPill = React.forwardRef<HTMLDivElement, WSPillProps>(
  function WSPill(
    {
      theme = THEME_DEFAULT,
      type = TYPE_DEFAULT,
      truncate = true,
      text,
      icon,
      badge,
      onDismiss,
      className,
      outline,
      ...otherProps
    },
    ref
  ) {
    const pillClassName = useMemo(
      () => getPillClassNames(theme, type, outline, className, truncate),
      [theme, type, outline, className, truncate]
    );

    const iconName: false | WSIconName = useMemo(() => {
      if (icon && isWSPillStatusTheme(theme)) return statusIconMap[theme];

      return typeof icon === "string" ? icon : false;
    }, [icon, theme]);

    return (
      <WSElement ref={ref} className={pillClassName} {...otherProps}>
        {badge && (
          <WSBadge
            theme={badgeThemeMap[theme]}
            size="XS"
            className={styles.badge}
          />
        )}
        {iconName && (
          <WSIcon name={iconName} size="S" className={styles.icon} />
        )}
        {text && (
          <WSText.ParagraphXs className={styles.text}>
            {text}
          </WSText.ParagraphXs>
        )}
        {onDismiss ? <WSIcon name="exit" onClick={onDismiss} /> : null}
      </WSElement>
    );
  }
);
