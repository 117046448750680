import React from "react";
import {
  WSButton,
  WSElement,
  WSElementProps,
  WSIconName
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import styles from "./EmptyCardPlaceholder.module.scss";

export const EmptyCardPlaceholder: React.FC<WSElementProps & {
  onAction(): void;
  actionLabel: string;
  actionIcon: WSIconName;
  cardType: "virtual" | "physical";
}> = ({ onAction, actionIcon, actionLabel, cardType, ...props }) => (
  <WSElement
    className={classNames(
      styles.placeholderCard,
      cardType === "virtual"
        ? styles.virtualPlaceholderCard
        : styles.physicalPlaceholderCard
    )}
    {...props}
  >
    <WSButton.Link
      data-testid="orderCard"
      rightIcon={actionIcon}
      size="M"
      onClick={onAction}
      className={styles.cardLink}
    >
      {actionLabel}
    </WSButton.Link>
  </WSElement>
);
