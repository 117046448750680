import { servicePayments } from "../servicePayments";
import {
  IPayeeEngagementCreateRequest,
  IPayeeEngagementResponse
} from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";

export const createPayeeEngagement = async (
  payeeId: string,
  request: IPayeeEngagementCreateRequest
): Promise<IPayeeEngagementResponse> => {
  const { data } = await servicePayments.post(
    `/payee/${payeeId}/engagement`,
    request
  );

  return data;
};
