import { QueryConfig } from "react-query";
import { useWSQuery } from "../../helpers";

import { INVOICING_CONFIG_FOR_PAYEE } from "../keys";
import { getInvoicingConfigForPayee } from "../../../services/invoicingConfig";
import { IInvoicingConfig } from "@wingspanhq/payments/dist/interfaces";

export const useQueryInvoicingConfigForPayee = (
  payerId: string,
  queryConfig?: QueryConfig<IInvoicingConfig, unknown>
) =>
  useWSQuery(
    [INVOICING_CONFIG_FOR_PAYEE, payerId],
    () => getInvoicingConfigForPayee(payerId),
    {
      retry: false,
      ...queryConfig
    }
  );
