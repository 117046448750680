import {
  IPayoutItem,
  IPayoutResponse
} from "../../../services/api/payments/payouts/types";
import { getTotalTransfersAmount } from "./getTotalTransfersAmount";

export const getPayoutTotalAmount = (payout: IPayoutResponse): number => {
  // should be equal to currentPeriodBalance - futureDebits
  const payoutTotal =
    (payout?.bankingFundsTransfer?.amount || 0) +
    getTotalTransfersAmount(payout?.externalFundsTransfers ?? []);

  return payoutTotal;
};

export const selectorIsPayoutItemVisible = (payoutItem: IPayoutItem) =>
  payoutItem.sourceType === "Invoice" &&
  !payoutItem.description?.includes("Payout");
