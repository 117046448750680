import { toWSDateString } from "@wingspanhq/fe-component-library";
import {
  ClientWorkFlowStatus,
  IPayableSchema,
  IPayrollSettings,
  InvoiceStatus
} from "@wingspanhq/payments/dist/interfaces";
import { addDays } from "date-fns";
import {
  getIsPayableAccepted,
  getIsPayableDisputed,
  getIsPayableResubmitted
} from "../../../query/payments/selectors";
import { isSameDate } from "../../../utils/serviceHelper";
import { getNextPayrollDate } from "../../utils/payables";

export const getPayableLatestUpdate = (
  payable: IPayableSchema,
  payrollSettings: IPayrollSettings
) =>
  payable.status === InvoiceStatus.Draft
    ? isSameDate(payable.createdAt, payable.updatedAt)
      ? `Created ${toWSDateString(payable.createdAt, "monthDate")}`
      : `Last updated ${toWSDateString(payable.updatedAt, "monthDate")}`
    : payable.status === InvoiceStatus.Paid
    ? `Processed (paid) ${toWSDateString(payable.events.paidAt, "monthDate")}`
    : payable.status === InvoiceStatus.PaymentInTransit
    ? "Payment in route"
    : getIsPayableDisputed(payable)
    ? "Disputed by contractor"
    : getIsPayableResubmitted(payable)
    ? `Contractor resubmitted at ${toWSDateString(
        payable.events.memberResubmittedAt,
        "monthDate"
      )}`
    : payable.status === InvoiceStatus.Open
    ? addDays(new Date(), 3) > payable.dueDate
      ? `Due soon (${toWSDateString(payable.dueDate, "monthDate")})`
      : payable.client.workflowStatus === ClientWorkFlowStatus.Approved
      ? `Approved to be paid on ${toWSDateString(
          getNextPayrollDate(payrollSettings),
          "monthDate"
        )}`
      : getIsPayableAccepted(payable)
      ? `Accepted by contractor on ${toWSDateString(
          payable.events.memberAcceptedAt,
          "monthDate"
        )}`
      : `Due (${toWSDateString(payable.dueDate, "monthDate")})`
    : payable.status === InvoiceStatus.Overdue
    ? payable.client.workflowStatus === ClientWorkFlowStatus.Approved
      ? `Approved to be paid on ${toWSDateString(
          getNextPayrollDate(payrollSettings),
          "monthDate"
        )}`
      : getIsPayableAccepted(payable)
      ? `Accepted by contractor on ${toWSDateString(
          payable.events.memberAcceptedAt,
          "monthDate"
        )}`
      : `Overdue (${toWSDateString(payable.dueDate, "monthDate")})`
    : payable.status === InvoiceStatus.Pending
    ? payable.client.workflowStatus === ClientWorkFlowStatus.PaymentInitiated ||
      payable.client.workflowStatus === ClientWorkFlowStatus.Funded
      ? `Funded for payment`
      : payable.client.workflowStatus === ClientWorkFlowStatus.Approved
      ? `Approved to be funded on ${toWSDateString(
          getNextPayrollDate(payrollSettings),
          "monthDate"
        )}`
      : getIsPayableAccepted(payable)
      ? `Accepted by contractor on ${toWSDateString(
          payable.events.memberAcceptedAt,
          "monthDate"
        )}`
      : "Eligibility pending"
    : undefined;
