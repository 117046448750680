import React, { useMemo } from "react";
import { useEngagementsListAllQuery } from "../../../../query/engagements/queries/useEngagementsListAllQuery";
import {
  WSEmptyState,
  WSTable,
  WSTableCell,
  WSTableColumn
} from "@wingspanhq/fe-component-library";
import { getEngagementLabelByType } from "../../../Payers/utils/engagement";
import { useHistory } from "react-router-dom";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";

export const EngagementsTable: React.FC<{ requirementId: string }> = ({
  requirementId
}) => {
  const history = useHistory();
  const engagementsListAllQuery = useEngagementsListAllQuery({
    filter: {
      isDefault: false
    }
  });

  const tableData = useMemo(() => {
    const engagements = engagementsListAllQuery.data ?? [];
    return engagements
      .filter(e => e.requirementIds?.includes(requirementId))
      .map(engagement => ({
        id: engagement.engagementId,
        data: engagement
      }));
  }, [engagementsListAllQuery.data, requirementId]);

  const columns: Array<WSTableColumn> = [
    {
      config: {
        gridTemplateSizeMax: "0.5fr",
        header: {
          text: "Name"
        }
      },
      renderFunction: rowItem => (
        <WSTableCell
          text={rowItem.data.name}
          secondaryText={rowItem.data.description}
        />
      )
    },
    {
      config: {
        header: {
          text: "Type"
        },
        hideOnScreens: ["XS"]
      },
      renderFunction: rowItem => (
        <WSTableCell
          text={getEngagementLabelByType(rowItem.data.type, {
            descriptive: true
          })}
        />
      )
    }
  ];

  return tableData.length > 0 ? (
    <WSTable
      loading={engagementsListAllQuery.isLoading}
      tableData={tableData}
      columns={columns}
      onRowClick={item => {
        history.push(`/member/payees/engagements/${item.id}`);
      }}
      onRowControlClick={item => {
        openInNewTab(`/member/payees/engagements/${item.id}`);
      }}
      onRowMousewheelClick={item => {
        openInNewTab(`/member/payees/engagements/${item.id}`);
      }}
      rowActions={({ id }) => [
        {
          text: "View",
          onClick: () => {
            history.push(`/member/payees/engagements/${id}`);
          }
        }
      ]}
      data-testid="requirementEngagementsTable"
    />
  ) : (
    <WSEmptyState
      py="3XL"
      hasBorder
      fullWidth
      orientation="horizontal"
      type="documents"
      title="Requirement is not assigned to engagements yet"
      description="List of engagements with this requirement will appear here."
      bgColor="gray"
      buttons={[
        {
          label: "Open engagements",
          kind: "Secondary",
          onClick: () => {
            history.push("/member/payees/engagements");
          }
        }
      ]}
    />
  );
};
