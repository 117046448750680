import {
  WSTab,
  WSTabsNavigation,
  WSTabsNavigationProps
} from "@wingspanhq/fe-component-library";
import QueryString from "qs";
import React, { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

type Tab = {
  path?: string;
  params?: any;
} & Omit<WSTab, "render">;

type Props = {
  tabs: Tab[];
} & Omit<WSTabsNavigationProps, "tabs" | "activeIndex">;

export const Tabs: React.FC<Props> = ({ tabs, ...otherProps }) => {
  const history = useHistory();
  const location = useLocation();

  const index = useMemo(() => {
    const impreciseMatches = [];

    for (let index = 0; index < tabs.length; index++) {
      const tab = tabs[index];
      if (!tab.path) {
        continue;
      }

      if (location.pathname === tab.path) {
        return index;
      }

      if (location.pathname.startsWith(tab.path)) {
        impreciseMatches.push(index);
      }
    }

    return impreciseMatches.length > 0
      ? impreciseMatches[impreciseMatches.length - 1]
      : undefined;
  }, [location.pathname, tabs]);

  return (
    <WSTabsNavigation
      tabs={tabs.map(({ path, params, onClick, ...tab }) => ({
        onClick: () => {
          onClick?.();
          if (path) {
            history.push({
              pathname: path,
              search: params ? QueryString.stringify(params) : undefined
            });
          }
        },
        ...tab
      }))}
      activeIndex={index}
      {...otherProps}
    />
  );
};
