import {
  WSButton,
  WSCheckboxToggle,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { TextBulleted } from "../TextBulleted";
import { useMutationSubmit } from "./useMutationSubmit";

type Props = { onSuccess: () => void } & WSElementProps;

export const Form1099Concent: React.FC<Props> = ({
  onSuccess,
  ...elementProps
}) => {
  const [consent, setConsent] = useState(false);
  const [submit, submitMeta] = useMutationSubmit({ onSuccess });

  return (
    <WSElement {...elementProps}>
      <WSText mb="M">
        By utilizing Wingspan's services you agree to and confirm that you
        understand the following:
      </WSText>
      <TextBulleted mb="M">
        <li>
          You are opting in to receiving an electronic copy of your Form 1099
          Information Return.
        </li>
        <li>
          You will receive an electronic Form 1099 from each business that has
          paid you via Wingspan, unless they opt out of using our Form 1099
          filing service.
        </li>
        <li>
          This consent to receive an electronic Form 1099 will remain in place
          until you opt out.
        </li>
        <li>
          Any electronic Forms 1099 made available to you will remain available
          for 2 years or until you request that your WIngspan account be closed
          and/or deleted.
        </li>
      </TextBulleted>
      <WSText>
        For any questions, concerns, changes or any other issues, contact
        support@wingspan.app
      </WSText>

      <WSCheckboxToggle
        my="3XL"
        name="consent"
        label="I consent to receive electronic copies of Form 1099."
        value={consent}
        onChange={value => {
          setConsent(value);
        }}
      />

      <WSButton
        disabled={!consent}
        mb="M"
        onClick={submit}
        fullWidth
        name="next"
        loading={submitMeta.isLoading}
      >
        Continue
      </WSButton>
    </WSElement>
  );
};
