import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSPill,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router";

type Props = {
  taxFormId: string;
  name: string;
  pill?: string;
} & WSElementProps;

export const Header: React.FC<Props> = ({
  taxFormId,
  name,
  pill,
  ...elementProps
}) => {
  const history = useHistory();

  const onBack = () => {
    history.push(`/member/1099-filing/details/${taxFormId}`);
  };

  return (
    <WSElement {...elementProps}>
      <WSScreen.Mobile>
        <WSButton.Link mb="M" icon="chevron-left" onClick={onBack}>
          Back
        </WSButton.Link>
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        <WSFlexBox.CenterY mb="XL">
          <WSText.ParagraphSm
            weight="medium"
            color="gray400"
            onClick={() => {
              history.push("/member/1099-filing/dashboard");
            }}
          >
            1099-NEC Filing Dashboard
          </WSText.ParagraphSm>
          <WSText.ParagraphSm weight="medium" mx="M" color="gray400">
            –
          </WSText.ParagraphSm>
          <WSElement onClick={onBack}>
            <WSText.ParagraphSm weight="medium" color="gray400">
              {name}
            </WSText.ParagraphSm>
            {pill && <WSPill theme="blue" ml="M" text={pill} />}
          </WSElement>
          <WSText.ParagraphSm weight="medium" mx="M" color="gray400">
            –
          </WSText.ParagraphSm>
          <WSText.ParagraphSm weight="medium">
            Edit recipient details
          </WSText.ParagraphSm>
        </WSFlexBox.CenterY>
      </WSScreen.TabletAndDesktop>

      <WSText.Heading1>Recipient Details</WSText.Heading1>
    </WSElement>
  );
};
