import {
  IRequirementDefinitionUpdateRequest,
  IRequirementDefinitionResponse
} from "@wingspanhq/payments/dist/interfaces";
import { servicePayments } from "../servicePayments";

export const updateRequirement = async (
  id: string,
  payload: IRequirementDefinitionUpdateRequest
): Promise<IRequirementDefinitionResponse> => {
  const response = await servicePayments.patch(`/requirement/${id}`, payload);
  return response.data;
};
