import { useUserProfileData } from "./utils/useUserProfileData";
import { isUserIdsIncluded } from "./utils/isUserIdsIncluded";
import { PAYMENTS_SETTINGS_MANAGED_BY_ORGANIZATION } from "./helpers";

export const usePaymentsSettingsManagedByOrganization = () => {
  const userData = useUserProfileData();
  const isPaymentsManagedByOrganization = isUserIdsIncluded(
    userData,
    PAYMENTS_SETTINGS_MANAGED_BY_ORGANIZATION
  );

  return isPaymentsManagedByOrganization;
};
