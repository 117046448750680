import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";
import {
  useIsMobile,
  WSActionsButton,
  WSButton,
  WSButtonProps,
  WSCard,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSImageEmbeddedProps,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../query/hooks/helpers";
import { useUserProfile } from "../../query/users/queries";
import {
  getSegmentAnonymousIdFromLocalStorage,
  track
} from "../../utils/analytics";
import { openInNewTab } from "../../utils/openInNewTab";

enum TemplateType {
  GoogleDoc,
  PDF
}

interface Template {
  title: string;
  description: string;
  link: string;
  type: TemplateType;
}

export const FINANCIAL_PLAN_TEMPLATES = [
  {
    title: "Company Cash Flow Tool",
    description:
      "Consultant Meredith Fennessy's customizable tool for getting a handle on your company's finances",
    link:
      "https://docs.google.com/spreadsheets/d/12AYeiVC2lLaXvpIteQZcUu8_ON5y8bl4PBgF9sDWGkM/edit#gid=443047846",
    type: TemplateType.PDF
  }
];

export const LEGAL_CHECKLIST_TEMPLATES = [
  {
    title: "Freelance Contracts 101",
    description:
      "Natasha Allen, partner at Foley & Lardner LLP, breaks down key terms every freelance contract should have",
    link:
      "https://drive.google.com/file/d/1PNj1EZXNb9cXJNySL3FnY6SF5-zYEO4k/view",
    type: TemplateType.PDF
  }
];

export const BRANDING_TEMPLATES = [
  {
    title: "Brand Naming Template",
    description:
      "Brand expert Lizzie Bildner breaks down the how and why of naming your business",
    link:
      "https://docs.google.com/document/d/1Vpz8ylZGmYAlPp2N7gkufEkWsWEImL8B88ZqeicCePE/view",
    type: TemplateType.GoogleDoc
  },
  {
    title: "Brand Messaging Template",
    description:
      "Brand expert Lizzie Bildner's template for identifying and communicating your value proposition",
    link:
      "https://docs.google.com/document/d/1VS_IL0o63Ve_86LJ6qhqyubG0NVNLdgmAkrbrDg2fRw/view",
    type: TemplateType.GoogleDoc
  },
  {
    title: "Brand Positioning Template",
    description:
      "Brand expert Lizzie Bildner's guide to developing a strong, differentiated, and concise way to position your brand and business",
    link:
      "https://docs.google.com/document/d/11Ldn_FTJ0Ch_hICaNeAdheEZvtW0aefpfT5swgEavlE/view",
    type: TemplateType.GoogleDoc
  }
];

export const TAXES_101_TEMPLATES = [
  {
    title: "Common Deductible Expenses for Taxes",
    description:
      "Little Fish Accounting's checklist to help you navigate freelance tax deductions",
    link:
      "https://drive.google.com/file/d/1Bmd2B290gGPDRt6b78gUr67ddPCJ4XbB/view",
    type: TemplateType.PDF
  }
];

const TEMPLATES: Template[] = [
  {
    title: "Consulting Agreement",
    description: "Google Doc",
    link:
      "https://docs.google.com/document/d/1oOs8HDE5aRh95nJ6vmF4KlxSghqXN81nAMMVKQQi7-E/copy",
    type: TemplateType.GoogleDoc
  },
  {
    title: "Mutual NDA",
    description: "Google Doc",
    link:
      "https://docs.google.com/document/d/1Yzjijr5btaQuRTtxTtqiMlobJayF_oNjacLf_E46WAY/copy",
    type: TemplateType.GoogleDoc
  },
  {
    title: "Form W-9",
    description: "Google Doc",
    link: "https://www.irs.gov/pub/irs-pdf/fw9.pdf",
    type: TemplateType.PDF
  },
  {
    title: "Invoice",
    description: "Google Doc",
    link:
      "https://docs.google.com/document/d/1C_KCORVqNTzZdwH24bUq-05JazoF8WjV38oOhyYpqOg/copy",
    type: TemplateType.GoogleDoc
  },
  ...BRANDING_TEMPLATES,
  ...TAXES_101_TEMPLATES,
  ...LEGAL_CHECKLIST_TEMPLATES,
  ...FINANCIAL_PLAN_TEMPLATES
];

const TemplateCard: React.FC<{ template: Template }> = ({ template }) => {
  const { type, link, title, description } = template;
  const action: WSActionsButton =
    type === TemplateType.GoogleDoc
      ? {
          kind: "Link",
          label: "View",
          rightIcon: "open-tab",
          onClick: () => openInNewTab(link)
        }
      : {
          kind: "Link",
          label: "Download",
          icon: "download",
          onClick: () => openInNewTab(link)
        };
  return (
    <WSCard
      header={{
        label: {
          label: title
        }
      }}
      actions={{
        buttons: [action]
      }}
    >
      <WSText.ParagraphSm color="gray600">{description}</WSText.ParagraphSm>
    </WSCard>
  );
};

export interface BootcampItem {
  slug: string;
  title: string;
  action: WSButtonProps<"Link">;
  image: WSImageEmbeddedProps;
  videoSrc: string;
  pageContent: React.ReactNode;
}

export const bootcampItems: BootcampItem[] = [
  {
    slug: "freelance-taxes-101",
    title: "Freelance Taxes 101 with Little Fish Accounting",
    action: {
      kind: "Link",
      label: "View presentation",
      onClick: function noRefCheck() {},
      rightIcon: "open-tab"
    },
    image: {
      placeholder: "Taxes 101",
      src:
        "https://cdn.loom.com/sessions/thumbnails/8011dd3aee134aa4a997275c1d3a6799-with-play.gif"
    },
    videoSrc: "https://www.loom.com/embed/8011dd3aee134aa4a997275c1d3a6799",
    pageContent: (
      <>
        <WSFlexBox direction="column">
          {TAXES_101_TEMPLATES.map(template => (
            <WSElement mb="XL" key={template.link + template.title}>
              <TemplateCard template={template} />
            </WSElement>
          ))}
        </WSFlexBox>
      </>
    )
  },
  {
    slug: "freelance-legal-checklist",
    title: "Freelance Legal Checklist with Foley & Lardner LLP",
    action: {
      kind: "Link",
      label: "View presentation",
      onClick: function noRefCheck() {},
      rightIcon: "open-tab"
    },
    image: {
      placeholder: "Legal Checklist",
      src:
        "https://cdn.loom.com/sessions/thumbnails/0674bb809ff0427082c475de69bc060b-with-play.gif"
    },
    videoSrc: "https://www.loom.com/embed/0674bb809ff0427082c475de69bc060b",
    pageContent: (
      <WSFlexBox direction="column">
        {LEGAL_CHECKLIST_TEMPLATES.map(template => (
          <WSElement mb="XL" key={template.link + template.title}>
            <TemplateCard template={template} />
          </WSElement>
        ))}
      </WSFlexBox>
    )
  },
  {
    slug: "build-your-financial-plan",
    title: "Building Your Financial Plan with Le Chéile",
    action: {
      kind: "Link",
      label: "View presentation",
      onClick: function noRefCheck() {},
      rightIcon: "open-tab"
    },
    image: {
      placeholder: "Financial Plan",
      src:
        "https://cdn.loom.com/sessions/thumbnails/ecbc816c568942e3b3062795986e484d-with-play.gif"
    },
    videoSrc: "https://www.loom.com/embed/ecbc816c568942e3b3062795986e484d",
    pageContent: (
      <>
        <WSFlexBox direction="column">
          {FINANCIAL_PLAN_TEMPLATES.map(template => (
            <WSElement mb="XL" key={template.link + template.title}>
              <TemplateCard template={template} />
            </WSElement>
          ))}
        </WSFlexBox>
      </>
    )
  },
  {
    slug: "build-your-brand",
    title: "Building Your Brand with Lizzie Bildner",
    action: {
      kind: "Link",
      label: "View presentation",
      onClick: function noRefCheck() {},
      rightIcon: "open-tab"
    },
    image: {
      placeholder: "Building Your Brand",
      src:
        "https://cdn.loom.com/sessions/thumbnails/044af50192e140be9e9050400108fece-with-play.gif"
    },
    videoSrc: "https://www.loom.com/embed/044af50192e140be9e9050400108fece",
    pageContent: (
      <>
        <WSFlexBox direction="column">
          {BRANDING_TEMPLATES.map(template => (
            <WSElement mb="XL" key={template.link + template.title}>
              <TemplateCard template={template} />
            </WSElement>
          ))}
        </WSFlexBox>
      </>
    )
  }
];

const BootcampItemCard: React.FC<{ item: BootcampItem }> = ({ item }) => {
  const { title, action, image } = item;
  return (
    <WSCard
      header={{
        label: {
          label: title
        }
      }}
      actions={{
        buttons: [action]
      }}
    />
  );
};

export const KnowledgeCenterDashboard = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const userId = useUserId();
  const user = useUserProfile(userId);
  const anonymousId = getSegmentAnonymousIdFromLocalStorage();

  const requestATemplate = () => {
    const typeFormReference = createPopup(
      `https://form.typeform.com/to/tujoKx05#name=${user.data?.profile
        .firstName +
        " " +
        user.data?.profile.lastName}&memberId=${userId}&email=${
        user.data?.email
      }&anonymousid=${anonymousId}`,
      {
        onSubmit: responseId => {
          track("Template Request Submitted", {
            responseId: responseId
          });
        }
      }
    );
    typeFormReference.open();
  };

  return (
    <WSPage title="Knowledge Center">
      <WSText.Heading4 mt="XL">Freelance Bootcamp</WSText.Heading4>
      <WSText>
        Experts speak on the make-or-break topics freelancers have to tackle at
        the beginning of their journey.
      </WSText>
      <WSGrid pt="XL">
        {bootcampItems.map(item => {
          item.action = {
            ...item.action,
            onClick: () => {
              history.push(`/member/knowledge-center/${item.slug}`);
            }
          };
          return (
            <WSGrid.Item span={{ s: "3" }}>
              <BootcampItemCard item={item} />
            </WSGrid.Item>
          );
        })}
      </WSGrid>
      <WSFlexBox mt="2XL" justify="space-between">
        <WSElement>
          <WSText.Heading4>Templates</WSText.Heading4>
          <WSText>
            Core documents to help you conduct business with confidence.
          </WSText>
        </WSElement>
        {!isMobile && (
          <WSElement>
            <WSButton.Secondary onClick={requestATemplate}>
              Request a template
            </WSButton.Secondary>
          </WSElement>
        )}
      </WSFlexBox>
      {isMobile && (
        <WSButton.Secondary onClick={requestATemplate} fullWidth mt="XL">
          Request a template
        </WSButton.Secondary>
      )}
      <WSGrid pt="XL">
        {TEMPLATES.map(template => (
          <WSGrid.Item span={{ xs: "6", m: "3" }}>
            <TemplateCard template={template} />
          </WSGrid.Item>
        ))}
      </WSGrid>
    </WSPage>
  );
};
