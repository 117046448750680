import {
  useModalContext,
  WSButton,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

export const BENEFITS_PENDING_MODAL = "BENEFITS_PENDING_MODAL";

export const BenefitsPending: React.FC = () => {
  const { closeModal } = useModalContext();

  return (
    <WSModal
      name={BENEFITS_PENDING_MODAL}
      size="S"
      title="Benefit will be available within 2 business days"
    >
      <WSText mt="M">
        We're currently activating your Premium Perks, which can take up to 2
        business days to complete. Once ready, we'll notify you by email.
      </WSText>

      <WSButton.Primary
        mt="XL"
        onClick={() => closeModal(BENEFITS_PENDING_MODAL)}
        type="submit"
      >
        Got it
      </WSButton.Primary>
    </WSModal>
  );
};
