import * as Yup from "yup";
import { cardNumberFormats } from "../formatters/cardNumber";

export const isCardNumberValid = (cardNumber: string) => {
  const cardNumberFormat = cardNumberFormats.find((format) =>
    format.rx.test(cardNumber)
  );
  if (!cardNumberFormat) return false;

  return (
    cardNumberFormat.blocksRx.test(cardNumber) &&
    cardNumber.length === cardNumberFormat.length
  );
};

export const validatorCardNumber = Yup.string().test(
  "cardNamberValid",
  "Card number is invalid",
  (cardNumber) => (cardNumber ? isCardNumberValid(cardNumber) : true)
);
