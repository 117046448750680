// bulk batch
export const QUERY_BULK_COLLABORATOR_BATCH_LIST =
  "QUERY_BULK_COLLABORATOR_BATCH_LIST";
export const QUERY_BULK_COLLABORATOR_BATCH = "QUERY_BULK_COLLABORATOR_BATCH";
export const QUERY_BULK_COLLABORATOR_BATCH_SUMMARY =
  "QUERY_BULK_COLLABORATOR_BATCH_SUMMARY";

// bulk items
export const QUERY_BULK_COLLABORATOR_BATCH_ITEM_LIST =
  "QUERY_BULK_COLLABORATOR_BATCH_ITEM_LIST";
