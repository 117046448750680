import {
  IBalanceBaseResponse,
  IBalanceResponse
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  SelectOptionOld,
  WSButton,
  WSAvatar,
  WSElement,
  WSLoader,
  WSSelectOld,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import { IPayoutSettingsResponse } from "@wingspanhq/payments/dist/interfaces";
import {
  AccountStatus,
  IAccount,
  IClient
} from "@wingspanhq/users/dist/lib/interfaces";
import { useEffect, useMemo } from "react";
import { useBankingBalance } from "../../../../query/bookkeeping/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import { usePayoutSettings } from "../../../../query/payments/queries";
import { useWithholdingBalance } from "../../../../query/taxes/queries";
import { useCreateAccount } from "../../../../query/users/mutations";
import { useAccounts, useClientQuery } from "../../../../query/users/queries";
import { selectorAccountFullName } from "../../../../shared/selectors/selectorAccountFullName";
import { selectorDefaultDestination } from "../../../../shared/selectors/selectorDefaultDestination";
import { useMemberPlaidLink } from "../../../../utils/usePlaidLink";
import { TransferBalance, TransferType } from "../../types";
import styles from "./index.module.scss";
import { selectorAccountDepositReady } from "./selectorAccountDepositReady";
import { selectorAccountWithdrawalReady } from "./selectorAccountWithdrawalReady";

type DropdownProps = {
  balance: TransferBalance;
  type: TransferType;
  value?: string;
  onChange: (methodId: TransferBalance | string) => void;
};

export const DropdownAccount: React.FC<DropdownProps> = props => {
  const userId = useUserId();
  const queryAccounts = useAccounts();
  const queryBankingBalance = useBankingBalance();
  const queryWithholdingBalance = useWithholdingBalance();
  const queryClient = useClientQuery(userId);
  const queryPayoutSettings = usePayoutSettings(userId);

  const [createAccount, createAccountMeta] = useCreateAccount();

  const plaidHandler = useMemberPlaidLink({
    onSuccess: async (publicToken: string) => {
      const createResponse = await createAccount({
        publicToken,
        status: AccountStatus.Pending
      });

      let accounts = createResponse
        ? Array.isArray(createResponse)
          ? createResponse
          : [createResponse]
        : [];

      if (accounts.length > 0) {
        return {
          success: true,
          data: accounts
        };
      } else {
        // If accounts is undefined or it's empty array
        console.log("No accounts created", accounts);
        return {
          success: false
        };
      }
    }
  });

  if (
    queryBankingBalance.isLoading ||
    queryWithholdingBalance.isLoading ||
    !queryAccounts.data ||
    !queryClient.data ||
    !queryPayoutSettings.data
  ) {
    return <WSLoader.Spinner size="S" />;
  }

  return (
    <DropdownView
      {...props}
      bankingBalance={queryBankingBalance.data}
      withholdingBalance={queryWithholdingBalance.data}
      accounts={queryAccounts.data}
      client={queryClient.data}
      payoutSettings={queryPayoutSettings.data}
      onAddBankAccount={() => {
        plaidHandler.open();
      }}
      addBankAccountLoading={createAccountMeta.isLoading}
    />
  );
};

type DropdownViewProps = DropdownProps & {
  bankingBalance?: IBalanceBaseResponse;
  withholdingBalance?: IBalanceResponse;
  accounts: IAccount[];
  client: IClient;
  payoutSettings: IPayoutSettingsResponse;
  onAddBankAccount: () => void;
  addBankAccountLoading?: boolean;
};

const DropdownView: React.FC<DropdownViewProps> = ({
  type,
  balance,
  value,
  onChange,
  bankingBalance,
  withholdingBalance,
  accounts,
  client,
  payoutSettings,
  onAddBankAccount,
  addBankAccountLoading
}) => {
  const accountFilter = useMemo(() => {
    return type === "deposit"
      ? selectorAccountDepositReady
      : selectorAccountWithdrawalReady;
  }, [type]);

  const options = useMemo(() => {
    const options: SelectOptionOld[] = [];

    if (balance === "taxWithholdings" && bankingBalance?.available) {
      options.push({
        value: "wallet",
        label: `Wallet (${toWSMoneyString(
          bankingBalance.available || 0
        )} is available)`
      });
    }

    if (balance === "wallet" && withholdingBalance?.available) {
      options.push({
        value: "taxWithholdings",
        label: `Tax Withholdings (${toWSMoneyString(
          withholdingBalance.available
        )} is available)`
      });
    }

    accounts.filter(accountFilter).forEach(account => {
      options.push({
        value: account.accountId,
        label: selectorAccountFullName(account)
      });
    });

    if (options.length > 0) {
      onChange(options[0].value);
    }

    return options;
  }, [
    accountFilter,
    accounts,
    balance,
    bankingBalance?.available,
    onChange,
    withholdingBalance?.available
  ]);

  useEffect(() => {
    if (type === "deposit") {
      const defaultPaymentMethodId =
        client.profile.defaultPaymentMethod?.accountId;

      if (defaultPaymentMethodId) {
        options.find(option => option.value === defaultPaymentMethodId) &&
          onChange(defaultPaymentMethodId);
      }
    } else {
      const defaultPayoutMethodId = selectorDefaultDestination(payoutSettings)
        ?.destinationId;

      if (defaultPayoutMethodId) {
        options.find(option => option.value === defaultPayoutMethodId) &&
          onChange(defaultPayoutMethodId);
      }
    }
  }, []);

  return options.length > 0 ? (
    <WSElement className={styles.badge}>
      <WSAvatar.Icon
        color="white"
        colorBackground="blue500"
        icon="bank"
        mr="M"
      />
      <WSSelectOld
        className={styles.dropdown}
        name="accountId"
        options={options}
        value={value || ""}
        error={false}
        onChange={event => {
          onChange((event as any) as string);
        }}
      />
    </WSElement>
  ) : (
    <WSButton
      kind="Secondary"
      fullWidth
      className={styles.addAccountButton}
      icon="plus"
      onClick={onAddBankAccount}
      loading={addBankAccountLoading}
    >
      Add bank account
    </WSButton>
  );
};
