import {
  IPayerTaxFormResponse,
  TaxFormEventType
} from "@wingspanhq/payments/dist/interfaces";
import { selectorPayerTaxFormIsEventExist } from "./selectorPayerTaxFormIsEventExist";
import { UserStatus } from "@wingspanhq/users/dist/lib/interfaces";

export const selectorPayerTaxFormRecipientConfirmationStatusText = (
  taxForm: IPayerTaxFormResponse
) => {
  const isEventExist = (eventType: TaxFormEventType) =>
    selectorPayerTaxFormIsEventExist(taxForm, eventType);
  const isRecipientActive = taxForm.payee?.user.status === UserStatus.Active;

  if (isEventExist(TaxFormEventType.RecipientSharedW9Info)) {
    return "W-9 shared";
  } else if (isEventExist(TaxFormEventType.InvitationSent)) {
    return "Invite sent";
  } else if (isRecipientActive) {
    return "Signed-up";
  } else {
    return "Pending Invite";
  }
};
