import { IInvoice, InvoiceStatus } from "@wingspanhq/payments/dist/interfaces";
import {
  IMember,
  MemberWithholdingStatus
} from "@wingspanhq/users/dist/lib/interfaces";

export const getInvoiceTaxWithholdings = (
  member: IMember,
  invoice: IInvoice,
  netIncome: number
): {
  amount: number;
  rate: number;
} => {
  if (invoice.status === InvoiceStatus.Paid) {
    return {
      amount: invoice.withholdings?.tax?.amountWithheld || 0,
      rate: invoice.withholdings?.tax?.rate || 0
    };
  }

  const defaultRate =
    member.profile.withholdings?.tax?.status ===
      MemberWithholdingStatus.Active && member.profile.withholdings?.tax?.rate
      ? member.profile.withholdings.tax.rate
      : 0;

  return {
    amount: netIncome * (defaultRate / 100),
    rate: defaultRate
  };
};
