import {
  WSElement,
  WSGrid,
  WSLoader,
  WSPage,
  WSPageToolbar
} from "@wingspanhq/fe-component-library";
import { Route, Switch, useHistory } from "react-router-dom";
import { Tabs } from "../../../components/Tabs";
import { useQueryInternalAccounts } from "../../../query/banking/queries/useQueryInternalAccounts";
import { selectorActiveBankingAccount } from "../../../shared/selectors/selectorActiveBankingAccount";
import { Sidebar } from "../components/Sidebar";
import { PendingState } from "../components/PendingState";
import { RouteDashboard } from "../routes/RouteDashboard";
import { RouteTransactions } from "../routes/RouteTransactions";

export const RouteRootDashboard = () => {
  const history = useHistory();
  const queryInternalAccounts = useQueryInternalAccounts();

  if (queryInternalAccounts.isLoading) {
    return (
      <WSPage>
        <WSPageToolbar title="Wingspan Wallet" mb="2XL" />
        <WSLoader.Spinner />
      </WSPage>
    );
  }

  const hasActiveBankingAcccount =
    queryInternalAccounts.data &&
    queryInternalAccounts.data.some(selectorActiveBankingAccount);

  if (!hasActiveBankingAcccount) {
    return (
      <WSPage>
        <WSPageToolbar title="Wingspan Wallet" mb="2XL" />

        <WSGrid>
          <WSGrid.Item span={{ m: "8" }}>
            <PendingState />
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "4" }}>
            <Sidebar />
          </WSGrid.Item>
        </WSGrid>
      </WSPage>
    );
  }

  return (
    <WSPage>
      <WSPageToolbar
        title="Wingspan Wallet"
        actions={[
          {
            buttonKind: "Primary",
            label: "Transfer",
            onClick: () => {
              history.push("/member/transfer?balance=wallet");
            }
          }
        ]}
      />

      <WSElement mb="2XL">
        <Tabs
          tabs={[
            {
              label: "Overview",
              path: "/member/wallet"
            },
            {
              label: "Transactions",
              path: "/member/wallet/transactions"
            }
          ]}
        />
      </WSElement>

      <Switch>
        <Route
          path="/member/wallet/transactions"
          component={RouteTransactions}
        />

        <Route path="/member/wallet" component={RouteDashboard} />
      </Switch>
    </WSPage>
  );
};
