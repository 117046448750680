import { useHistory, useRouteMatch } from "react-router-dom";
import React, { ReactNode } from "react";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import { PayerSetupLayout } from "../../components/PayerSetupLayout";
import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import { BulkBatchImportProgress } from "../../../BulkImporter/components/BulkBatchImportProgress";
import { BulkResource } from "../../../BulkImporter/types";
import { useBulkCollaboratorBatch } from "../../../BulkImporter/query/bulkCollaborator/queries";
import { WSQueries } from "../../../../query/WSQuery";
import {
  BulkStatus,
  IBulkCollaboratorBatch
} from "@wingspanhq/payments/dist/interfaces";
import { useUpdateActivity } from "../../../../query/users/mutations";
import { useUserId } from "../../../../query/hooks/helpers";
import { useActivities } from "../../../../query/users/queries";
import { get1099SetupProgress } from "../../../../query/users/selectors";
import { KEY_IMPORT_RECIPIENTS_BULK_BATCH_ID } from "../../constants/storage";

export const RouteRecipientsImportProgress: React.FC<SetupRouteComponentProps & {
  isEdit?: boolean;
  Layout?: React.FC<{
    title: string;
    children: ReactNode;
    onBack?: () => void;
  }>;
}> = ({ onNext, isEdit = false, Layout }) => {
  const history = useHistory();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const qCollaboratorBulkBatch = useBulkCollaboratorBatch(bulkBatchId);
  const userId = useUserId();
  const qActivity = useActivities(userId);
  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);

  const onImportFailed = async (
    collaboratorBulkBatch: IBulkCollaboratorBatch
  ) => {
    onNext({
      collaboratorBulkBatch
    });
  };

  const onImportSuccess = async (
    collaboratorBulkBatch: IBulkCollaboratorBatch
  ) => {
    // skip updating activity if we are in edit mode
    if (!isEdit) {
      const nec1099SetupProgress = get1099SetupProgress(qActivity.data);
      await updateActivity(
        {
          flows: {
            nec1099Setup: {
              ...nec1099SetupProgress,
              currentStep: 3
            }
          }
        },
        {
          onSuccess: () => {
            onNext({
              collaboratorBulkBatch
            });
          }
        }
      );
    } else {
      onNext({
        collaboratorBulkBatch
      });
    }
    window.localStorage.removeItem(KEY_IMPORT_RECIPIENTS_BULK_BATCH_ID);
  };

  const onContinue = async (collaboratorBulkBatch: IBulkCollaboratorBatch) => {
    if ((collaboratorBulkBatch.statistics?.itemsFailed || 0) > 0) {
      onImportFailed(collaboratorBulkBatch);
    } else {
      onImportSuccess(collaboratorBulkBatch);
    }
  };
  const LayoutComponent = Layout || PayerSetupLayout;

  return (
    <LayoutComponent title="Add recipients">
      <WSQueries queries={{ qCollaboratorBulkBatch }}>
        {({ qCollaboratorBulkBatchData: collaboratorBulkBatch }) => {
          return (
            <>
              <WSText.Heading4 mb="XL">
                Batch upload recipients with a spreadsheet
              </WSText.Heading4>
              <BulkBatchImportProgress
                batchId={bulkBatchId}
                bulkResource={BulkResource.Collaborator}
              />
              <WSButton
                onClick={() => onContinue(collaboratorBulkBatch)}
                fullWidth
                name="recipientsBatchImportDone"
                loading={updateActivityMeta.isLoading}
                disabled={
                  ![BulkStatus.Complete, BulkStatus.Failed].includes(
                    collaboratorBulkBatch.status
                  )
                }
              >
                Continue
              </WSButton>
            </>
          );
        }}
      </WSQueries>
    </LayoutComponent>
  );
};
