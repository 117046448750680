import {
  ClientWorkFlowStatus,
  IPayableSchema
} from "@wingspanhq/payments/dist/interfaces";
import { selectorIsPayableOpen } from "./selectorIsPayableOpen";

export const selectorIsPayableApproved = (payable: IPayableSchema) => {
  return (
    selectorIsPayableOpen(payable) &&
    payable.client.workflowStatus === ClientWorkFlowStatus.Approved
  );
};
