import { IPayableSchema } from "@wingspanhq/payments/dist/interfaces";
import { selectorIsClientWorkflowStatusPending } from "./selectorIsClientWorkflowStatusPending";
import { selectorIsPayableOpen } from "./selectorIsPayableOpen";

export const selectorIsPayableApprovalPending = (payable: IPayableSchema) => {
  return (
    selectorIsPayableOpen(payable) &&
    selectorIsClientWorkflowStatusPending(payable.client.workflowStatus)
  );
};
