import { WSImage, WSText } from "@wingspanhq/fe-component-library";
import React from "react";
import howPaymentsWorkImage from "../../../../assets/images/how-payments-work.png";

export const InfoModal: React.FC = () => (
  <>
    <WSImage src={howPaymentsWorkImage} placeholder={<></>} mb="XL" />
    <WSText mb="M">
      When you get paid, your net income will be deposited to your selected
      destination. If you want, you can automatically send some of each payout
      to your Wingspan Wallet for business expenses.
    </WSText>
    <WSText mb="M">
      First, you receive a payment from a client. If you've created a split
      payment with a contractor, those payments to your subcontractors will be
      deducted from the invoice and paid to your contractors. If your client
      paid with a card, the standard 2.9% processing fee will be subtracted. The
      resulting funds are your Gross Income.
    </WSText>
    <WSText mb="M">
      Wingspan then sets aside a percentage of your Gross Income for taxes
      resulting in your Net Income (this step is optional and can be changed at
      any time). Your Net Income is money that you can safely spend on business
      and personal expenses.
    </WSText>
    <WSText>
      Last, Wingspan deposits your Net Income into your payout account. You also
      have the option to divide your Net Income between any payout account and
      Wingspan Wallet to save for business expenses.
    </WSText>
  </>
);
