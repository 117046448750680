import { useUserProfileData } from "./utils/useUserProfileData";
import { isUserIdsIncluded } from "./utils/isUserIdsIncluded";
import { HIDE_GET_PAID_NAV_IDS } from "./helpers";

export const useShowGetPaidNav = () => {
  const userData = useUserProfileData();
  const showGetPaidNav = !isUserIdsIncluded(userData, HIDE_GET_PAID_NAV_IDS);

  return showGetPaidNav;
};
