import React, { ReactNode } from "react";
import { WSButton, WSScreen, WSText } from "@wingspanhq/fe-component-library";

import styles from "./DefaultSettingItem.module.scss";

export enum ValueTypeMap {
  text = "text",
  link = "link",
  secret = "secret"
}
export type ValueType = keyof typeof ValueTypeMap;

export interface ReadOnlySettingValueProps {
  value: any;
  valueType?: ValueType;
  valueRenderer?: (value: any) => ReactNode;
}

export const ReadOnlySettingValue: React.FC<ReadOnlySettingValueProps> = ({
  value,
  valueType = ValueTypeMap.text,
  valueRenderer
}) => {
  const renderValueElByType = () => {
    if (valueRenderer) {
      return valueRenderer(value);
    }
    switch (valueType) {
      case ValueTypeMap.link:
        return (
          <>
            <WSScreen.MobileAndTablet>
              <WSButton.Link
                onClick={() => window.open(value)}
                textClassName={styles.linkValue}
              >
                {value}
              </WSButton.Link>
            </WSScreen.MobileAndTablet>
            <WSScreen.Desktop>
              <WSButton.Link onClick={() => window.open(value)}>
                {value}
              </WSButton.Link>
            </WSScreen.Desktop>
          </>
        );
      case ValueTypeMap.secret:
        return (
          <WSText.ParagraphSm className={styles.value}>
            *********
          </WSText.ParagraphSm>
        );
      case ValueTypeMap.text:
        return (
          <WSText.ParagraphSm className={styles.value}>
            {value}
          </WSText.ParagraphSm>
        );
      default:
        return (
          <WSText.ParagraphSm className={styles.value}>
            {value}
          </WSText.ParagraphSm>
        );
    }
  };
  return <>{renderValueElByType()}</>;
};
