import { useEffect, useMemo, useRef } from "react";

export const useWaitFor = (
  isReady: boolean | string | number,
  dependency?: any
) => {
  const ref = useRef<any>();
  const getWaitFor = useMemo(
    () => (limit?: number) => {
      return new Promise<boolean | string | number>((resolve, reject) => {
        let timeout = 0;

        if (limit) {
          timeout = window.setTimeout(() => {
            reject("Timeout error!");
          }, limit);
        }

        if (isReady) {
          resolve(isReady);
          window.clearTimeout(timeout);
        } else {
          ref.current = resolve;
        }
      });
    },
    [isReady, ref.current, dependency]
  );

  useEffect(() => {
    if (isReady && ref.current) {
      ref.current(isReady);
    }
  }, [ref.current, isReady, dependency]);

  return getWaitFor;
};
