import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { TransferBalance, TransferType } from "./types";

export const useSearchParams = () => {
  const location = useLocation();

  return useMemo<{ balance?: TransferBalance; type?: TransferType }>(() => {
    const query = new URLSearchParams(location.search);

    const balanceParam = query.get("balance");
    const typeParam = query.get("type");

    return {
      balance:
        balanceParam &&
        (balanceParam === "wallet" || balanceParam === "taxWithholdings")
          ? balanceParam
          : undefined,
      type:
        typeParam && (typeParam === "deposit" || typeParam === "withdrawal")
          ? typeParam
          : undefined
    };
  }, [location.search]);
};
