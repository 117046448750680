import { useUrlQueryFilters } from "../../../utils/router";
import { SortDirection } from "../../../utils/tableSortingHelpers";

export type DefaultTeamFilters = {
  search?: string;
  sort?: {
    name?: SortDirection;
    status?: SortDirection;
    permission?: SortDirection;
    organization?: SortDirection;
  };
};
export const defaultTeamFilters: DefaultTeamFilters = {
  search: "",
  sort: {}
};

export const useTeamFilters = () =>
  useUrlQueryFilters<DefaultTeamFilters>(defaultTeamFilters);
