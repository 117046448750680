import React from "react";
import { WSModal, WSText } from "@wingspanhq/fe-component-library";

export const BOOKKEEPING_WHAT_IS_A_REFUND = "BOOKKEEPING_WHAT_IS_A_REFUND";

export const WhatIsARefund: React.FC = () => {
  return (
    <WSModal
      name={BOOKKEEPING_WHAT_IS_A_REFUND}
      size="S"
      title="What is a refund?"
    >
      <WSText.Heading5 mb="M">Expense refunds</WSText.Heading5>
      <WSText mb="M">
        Select ‘this is a refund’ for expense transactions where a merchant has
        refunded a purchase. For example, when you return a purchase to a
        retailer like Amazon.
      </WSText>
      <WSText mb="XL">
        When you mark an expense transaction as a refund, the refunded amount
        will be subtracted from your expenses. For example, if you paid $10 for
        an app and were later refunded $10, your expenses would net out to $0
        across both transactions.
      </WSText>
      <WSText.Heading5 mb="M">Income Refunds</WSText.Heading5>
      <WSText mb="M">
        Select ‘this is a refund’ for income transactions where you have
        refunded a client. For example, when a client has accidentally overpaid
        or when they request a refund for paid work.
      </WSText>
      <WSText mb="XL">
        When you mark an income transaction as a refund, the refunded amount
        will be subtracted from your income. For example, if you add a $1,000
        income transaction, and a $500 refunded transaction, your income would
        net out to $500.
      </WSText>
    </WSModal>
  );
};
