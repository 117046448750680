import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { ICardResponse } from "../../../services/api/banking/cards/types";
import { QUERY_CARD } from "../queries/useCardQuery";
import { QUERY_CARDS } from "../queries/useCardsQuery";
import { closeCard } from "../../../services/api/banking/cards/closeCard";

export const useCloseCard = () =>
  useWSMutation<ICardResponse, WSServiceError, string>(closeCard, {
    dependencies(response, cardId) {
      return [[QUERY_CARD, cardId], QUERY_CARDS];
    }
  });
