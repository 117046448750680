import { QueryConfig } from "react-query";
import { useWSQuery } from "../../helpers";

import { INVOICING_CONFIG_FOR_PAYER } from "../keys";
import { getInvoicingConfigForPayer } from "../../../services/invoicingConfig";
import { IInvoicingConfig } from "@wingspanhq/payments/dist/interfaces";

export const useQueryInvoicingConfigForPayer = (
  queryConfig?: QueryConfig<IInvoicingConfig, unknown>
) =>
  useWSQuery(INVOICING_CONFIG_FOR_PAYER, getInvoicingConfigForPayer, {
    retry: false,
    ...queryConfig
  });
