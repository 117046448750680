import { QueryConfig } from "react-query";
import { getAcknowledgement } from "../../../services/api/onboarding/acknowledgements";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";

export const QUERY_ACKNOWLEDGEMENT = "QUERY_ACKNOWLEDGEMENT";

export const useQueryAcknowledgement = (
  acknowledgementName: Parameters<typeof getAcknowledgement>[0],
  config?: QueryConfig<
    Awaited<ReturnType<typeof getAcknowledgement>>,
    WSServiceError
  >
) =>
  useWSQuery(
    [QUERY_ACKNOWLEDGEMENT, acknowledgementName],
    () => getAcknowledgement(acknowledgementName),
    config
  );
