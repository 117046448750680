import { useModalContext, WSModal } from "@wingspanhq/fe-component-library";
import React from "react";
import { MembershipUpgrade, MembershipUpgradeProps } from "./MembershipUpgrade";

export const MEMBERSHIP_UPGRADE_MODAL = "MEMBERSHIP_UPGRADE_MODAL";

export const MembershipUpgradeModal: React.FC = () => {
  const { closeModal } = useModalContext();

  return (
    <WSModal name={MEMBERSHIP_UPGRADE_MODAL}>
      {(props: MembershipUpgradeProps = {}) => (
        <MembershipUpgrade
          {...props}
          onExplorePlans={() => {
            closeModal(MEMBERSHIP_UPGRADE_MODAL);
          }}
        />
      )}
    </WSModal>
  );
};
