import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation, WSMutationConfig } from "../../helpers";
import {
  QUERY_PAYMENTS_TAXFORM_LIST_PAYER,
  QUERY_PAYMENTS_TAXFORM_PAYER
} from "../keys";
import { paymentsService } from "../../../services/payments";
import {
  IPayerTaxFormResponse,
  ITaxFormUpdateRequest
} from "@wingspanhq/payments/dist/interfaces";

export const usePayerTaxFormUpdate = (
  config?: WSMutationConfig<
    IPayerTaxFormResponse,
    WSServiceError,
    { taxFormId: string; requestData: ITaxFormUpdateRequest }
  >
) =>
  useWSMutation(
    ({ taxFormId, requestData }) =>
      paymentsService.taxForm.update(taxFormId, requestData),
    {
      dependencies: [
        QUERY_PAYMENTS_TAXFORM_PAYER,
        QUERY_PAYMENTS_TAXFORM_LIST_PAYER
      ],
      ...config
    }
  );
