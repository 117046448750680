import {
  ICustomField,
  PayeeTaxDocumentSharePermission,
  PaymentRequirementStrategy
} from "@wingspanhq/payments/dist/interfaces";
import {
  PayeeRowsQueryFilter,
  PayerPayeeEngagementSearchableStatus
} from "../../../../services/search";
import { FiltersValueBase } from "../../../../shared/hooks/useUrlQueryFilters";
import { customFieldToFiltersRequest } from "../../../../utils/jsonFormSchema";

export enum PayeeTaxInfoPermission {
  Shared = "Shared",
  NotShared = "NotShared"
}

export interface PayeeFilters extends FiltersValueBase {
  engagementId?: string[];
  searchableStatus?: PayerPayeeEngagementSearchableStatus[];
  autoPayStrategy?: PaymentRequirementStrategy[];
  shareTaxDocument?: PayeeTaxInfoPermission[];
  bulkBatchId?: string[];
}

function mapShareTaxDocumentRequest(filtersValue?: PayeeTaxInfoPermission[]) {
  const request: PayeeTaxDocumentSharePermission[] = [];

  (filtersValue || []).forEach(value => {
    switch (value) {
      case PayeeTaxInfoPermission.Shared:
        request.push(PayeeTaxDocumentSharePermission.Allow);
        break;
      case PayeeTaxInfoPermission.NotShared:
        request.push(
          PayeeTaxDocumentSharePermission.Unspecified,
          PayeeTaxDocumentSharePermission.Decline
        );
    }
  });

  return request;
}

export const createListRequest = (
  filters: PayeeFilters,
  customFields?: ICustomField[]
) => {
  const isSearchableStatusFiltered =
    filters.searchableStatus && filters.searchableStatus.length > 0;

  const filter: PayeeRowsQueryFilter = {
    "engagements.engagementId": { in: filters.engagementId },
    searchableStatus: isSearchableStatusFiltered
      ? { in: filters.searchableStatus }
      : {
          "!=": PayerPayeeEngagementSearchableStatus.Inactive
        },
    "payerOwnedData.autoPayStrategy": { in: filters.autoPayStrategy },
    "payeeOwnedData.shareTaxDocument": {
      in: mapShareTaxDocumentRequest(filters.shareTaxDocument)
    },
    "labels.bulkBatchId": { in: filters.bulkBatchId }
  };

  // Custom fileds

  customFields?.forEach(customField => {
    const filterValue = filters[customField.key];
    if (
      typeof filterValue === "string" ||
      Array.isArray(filterValue) ||
      filterValue === undefined
    ) {
      filter[
        `payerOwnedData.customFields.${customField.key}`
      ] = customFieldToFiltersRequest(customField, filterValue);
    }
  });

  return {
    filter
  };
};
