import { RequirementEmbeddedStatus } from "@wingspanhq/payments/dist/interfaces";
import { RequirementContextType } from "../../types";
import {
  getOriginalRequirementStatus,
  getOverrideStatusDetails
} from "../../modules/hooks/useRequirementOverrideStatuses";
import { IRequirementWithEngagementIdsResponse } from ".";
import { WSPillProps } from "@wingspanhq/fe-component-library";

type OverrideStatusPillProps = {
  pill: WSPillProps;
  secondaryText?: string;
};

type ConfirmModalConfig = {
  title: string;
  pill: WSPillProps;
  secondaryText: string | undefined;
  message: string;
  completeActionBtnText: string;
};

export const getConfirmModalConfig = (
  status: RequirementEmbeddedStatus | null,
  requirement: IRequirementWithEngagementIdsResponse
): ConfirmModalConfig => {
  //to revert the override - display the original requirement status
  const overrideStatusPillProps: OverrideStatusPillProps =
    status === null
      ? getOriginalRequirementStatus(requirement.dataSourceStatus)
      : getOverrideStatusDetails(status, RequirementContextType.Payee);

  const engagementsLength = requirement.relatedEngagementRequirementIds.length;

  switch (status) {
    case RequirementEmbeddedStatus.Complete:
      return {
        title: "Overwrite status",
        pill: overrideStatusPillProps.pill,
        secondaryText: overrideStatusPillProps.secondaryText,
        message: `By proceeding, the requirement status will be marked complete by you. This requirement is assigned to ${engagementsLength} of the contractor’s engagements and may affect their eligibility to receive payments from you. Would you like to proceed?`,
        completeActionBtnText: "Yes, mark complete"
      };
    case RequirementEmbeddedStatus.Incomplete:
      return {
        title: "Overwrite status",
        pill: overrideStatusPillProps.pill,
        secondaryText: overrideStatusPillProps.secondaryText,
        message: `By proceeding, the requirement status will be marked failed by you. This requirement is assigned to ${engagementsLength} of the contractor’s engagements and may affect their eligibility to receive payments from you. Would you like to proceed?`,
        completeActionBtnText: "Yes, mark failed"
      };
    default:
      return {
        title: "Revert to synced status",
        pill: overrideStatusPillProps.pill,
        secondaryText: overrideStatusPillProps.secondaryText,
        message: `By proceeding, the overwritten status will be removed and revert to the synced status from the requirement service. This requirement is assigned to ${engagementsLength} of the contractor’s engagements and may affect their eligibility to receive payments from you. Would you like to proceed?`,
        completeActionBtnText: "Yes, revert to synced status"
      };
  }
};
