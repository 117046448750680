import {
  InfiniteQueryConfig,
  InfiniteQueryResult,
  MutationFunction,
  queryCache,
  QueryConfig,
  QueryFunction,
  QueryKey,
  QueryResult,
  ReactQueryMutationsConfig,
  useInfiniteQuery,
  useMutation,
  useQuery
} from "react-query";
import { WSServiceError } from "../utils/serviceHelper";

export function useWSQuery<TResult = unknown, TError = unknown>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TResult>,
  queryConfig?: QueryConfig<TResult, TError>
): QueryResult<TResult, TError> {
  return useQuery(queryKey, queryFn, {
    ...standardQueryOptions,
    ...queryConfig
  });
}

export function useWSInfiniteQuery<TResult = unknown, TError = unknown>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TResult>,
  queryConfig?: InfiniteQueryConfig<TResult, TError>
): InfiniteQueryResult<TResult, TError> {
  return useInfiniteQuery(queryKey, queryFn, {
    ...standardQueryOptions,
    ...queryConfig
  });
}

export interface WSMutationConfig<TResult, TError, TVariables, TSnapshot = any>
  extends ReactQueryMutationsConfig<TResult, TError, TVariables, TSnapshot> {
  dependencies?:
    | ((result: TResult, variables: TVariables) => QueryKey[])
    | QueryKey[];
  awaitDependencies?:
    | ((result: TResult, variables: TVariables) => QueryKey[])
    | QueryKey[];
}
export function useWSMutation<
  TResult,
  TError = WSServiceError,
  TVariables = any,
  TSnapshot = any
>(
  mutationFunc: MutationFunction<TResult, TVariables>,
  config?: WSMutationConfig<TResult, TError, TVariables, TSnapshot>
) {
  return useMutation(
    config?.awaitDependencies
      ? async (...args) => {
          const response = await mutationFunc(...args);

          const dependencies = config?.awaitDependencies!;

          if (Array.isArray(dependencies)) {
            for (const dependency of dependencies) {
              await queryCache.refetchQueries(dependency, {
                exact: false,
                active: true
              });
            }
          } else if (typeof dependencies === "function") {
            const resultDynamicDependencies = dependencies(response, args[0]);
            for (const dependency of resultDynamicDependencies) {
              await queryCache.refetchQueries(dependency, {
                exact: false,
                active: true
              });
            }
          }

          return response;
        }
      : mutationFunc,
    {
      ...config,
      onSuccess: async (...args) => {
        const dependencies = config?.dependencies;

        if (Array.isArray(dependencies)) {
          for (const dependency of dependencies) {
            queryCache.invalidateQueries(dependency, {
              exact: false
            });
          }
        } else if (typeof dependencies === "function") {
          const resultDynamicDependencies = dependencies(args[0], args[1]);
          for (const dependency of resultDynamicDependencies) {
            queryCache.invalidateQueries(dependency, {
              exact: false
            });
          }
        }

        await config?.onSuccess?.(...args);
      }
    }
  );
}

export const standardQueryOptions: QueryConfig<any, any> = {
  retry: (_, error) => {
    const isNetworkError = error?.message === "Network Error";
    const isServerError =
      typeof error?.response?.status === "number" &&
      error.response.status >= 500;

    if (isNetworkError || isServerError) {
      return true;
    }

    return false;
  },
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  retryDelay: (attemptIndex: number) => Math.min(1000 * 2 ** attemptIndex, 5000)
};
