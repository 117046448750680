import { createBrowserHistory } from "history";
import { page } from "../utils/analytics";

// if we're building dev sites, put change the base route
export const history = createBrowserHistory(
  process.env.REACT_APP_CLOUD_HOSTED_DEV_BUCKET
    ? {
        basename: `/${process.env.REACT_APP_BRANCH_NAME}${
          process.env.REACT_APP_BUILD_TARGET === "onboarder"
            ? "/targets/onboarder"
            : ""
        }/index.html`
      }
    : {}
);
declare global {
  interface Window {
    Cypress: any;
    reactRouterHistory: any;
  }
}

if (window.Cypress) {
  window.reactRouterHistory = history;
}

const setPathName = (location: { pathname: string }) => {
  window.document.documentElement.setAttribute(
    "data-pathname",
    location.pathname
  );
};

const getSubPath = (pathname: string) => {
  const splittedPath = pathname.split("/");

  return `${splittedPath[1]}/${splittedPath[2]}`;
};

let prevSubPath = "";
const historyData = {
  prevPath: window.location.pathname,
  scrollPositions: new Map<string, number>()
};

history.listen(location => {
  // Log
  if (process.env.NODE_ENV !== "production") {
    console.log("LOCATION:", `${location.pathname}${location.search}`);
  }

  // Set the pathname attribute on the html element for better tests
  setPathName(location);

  // Restore scroll position
  restoreScrollPosition(location.pathname);

  // register location changes with analytics
  try {
    page();
  } catch {}
});

setPathName(history.location);

function restoreScrollPosition(newPath: string) {
  if (newPath.startsWith("/member/onboarding")) {
    window.requestAnimationFrame(() => {
      window.document.documentElement.scrollTop = 0;
    });
    return;
  }

  if (!window.document.body.style.overflow) {
    historyData.scrollPositions.set(
      historyData.prevPath,
      window.document.documentElement.scrollTop
    );
  }

  const subPath = getSubPath(newPath);
  prevSubPath = prevSubPath || subPath;
  historyData.prevPath = newPath;

  if (subPath !== prevSubPath) {
    prevSubPath = subPath;

    historyData.scrollPositions.clear();
    window.document.querySelectorAll("*").forEach(el => (el.scrollTop = 0));
  } else {
    const pathname = newPath;
    if (
      historyData.scrollPositions.has(pathname) &&
      historyData.scrollPositions.get(pathname)
    ) {
      const scrollTo = historyData.scrollPositions.get(pathname) || 0;
      window.requestAnimationFrame(() => {
        if (pathname === window.location.pathname) {
          window.document.documentElement.scrollTop = scrollTo;
        }
      });
    }
  }
}
