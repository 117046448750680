import classNames from "classnames";
import React from "react";
import { WSElement } from "../WSElement/WSElement.component";
import styles from "./WSTable.module.scss";
import { WSTableColumn, WSTableItem } from "./types";

type LoadingProps = {
  columns: WSTableColumn<any>[];
};

export const Loading: React.FC<LoadingProps> = ({ columns }) => {
  const loadingData = Array(5)
    .fill(0)
    .map(
      (v, i) =>
        ({
          data: {},
          type: "mock",
          id: `${i}`
        } as WSTableItem)
    );

  return (
    <>
      {loadingData.map((rowItem, rowIndex) => (
        <WSElement key={`row-${rowIndex}`} className={styles.row}>
          {columns.map((column, columnIndex) => (
            <WSElement
              key={`row-${rowItem.id}-cell-${columnIndex}`}
              className={classNames(styles.cell, column.config?.className)}
            >
              <WSElement shimmer>Loading</WSElement>
            </WSElement>
          ))}
        </WSElement>
      ))}
    </>
  );
};
