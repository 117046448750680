import { IChannelNotificationResponse } from "@wingspanhq/notifications/dist/lib/interfaces";
import { selectorActiveNotifications } from "./selectorActiveNotifications";

import { NotificationHandleType } from "../components/Notifications";
import { SignatureClientContext } from "../components/Notifications/templates/SignatureClient";

export const selectorSignatureClientNotifications = (
  responses: IChannelNotificationResponse[]
) => {
  return selectorActiveNotifications(responses).filter(response => {
    return response.handle === NotificationHandleType.SignatureClient;
  }) as Array<
    Omit<IChannelNotificationResponse, "context"> & {
      context: SignatureClientContext;
    }
  >;
};
