import React from "react";

import {
  WSAvatar,
  WSAvatarSize,
  WSElement,
  WSElementProps
} from "@wingspanhq/fe-component-library";
import { IS_PRODUCTION_ENV } from "../../shared/constants/environment";
import styles from "./WSInstitutionLogo.module.scss";

export interface WSInstitutionLogoProps extends WSElementProps {
  institutionId?: string;
  size?: WSAvatarSize;
}

export const WSInstitutionLogo: React.FC<WSInstitutionLogoProps> = ({
  size = "S",
  institutionId,
  ...elementProps
}) => {
  const srcGCSBucket = `https://storage.cloud.google.com/${
    IS_PRODUCTION_ENV
      ? "wingspan-shared-content"
      : "wingspan-staging-shared-content"
  }`;
  const url = `${srcGCSBucket}/plaid/logos/${institutionId}.png`;

  const defaultDisplay = <WSAvatar.Icon icon="bank" size={size} />;

  return (
    <WSElement {...elementProps} className={styles.institutionLogo}>
      {institutionId ? (
        url ? (
          <WSAvatar.Image image={url} size={size} />
        ) : (
          defaultDisplay
        )
      ) : (
        defaultDisplay
      )}
    </WSElement>
  );
};
