import * as Yup from "yup";
import { getValidationSchema as getValidationSchemaPerson } from "../FormPartialPerson/validationSchema";
import { calculateTotalStake } from "./helpers";

export const getValidationSchema = (options?: {
  includeBeneficialOwners?: boolean;
  requireTaxId?: boolean;
}) =>
  Yup.object().shape({
    representative: getValidationSchemaPerson("Representative", {
      showOwnershipStake: options?.includeBeneficialOwners,
      requireTaxId: options?.requireTaxId
    }),
    beneficialOwners: Yup.mixed().when("representative.ownershipStake", {
      is: (stake: number | null) =>
        options?.includeBeneficialOwners ? !stake || stake <= 75 : false,
      then: Yup.array(
        getValidationSchemaPerson("BeneficialOwner", {
          showOwnershipStake: true,
          requireTaxId: true
        })
      )
    }),
    allOwnersAdded: options?.includeBeneficialOwners
      ? Yup.lazy((_, context) => {
          const representativeStake =
            context.parent?.representative?.ownershipStake || 0;
          const beneficialOwners = context.parent?.beneficialOwners || [];

          if (representativeStake > 75) {
            return Yup.mixed();
          }

          const totalStake = calculateTotalStake(
            representativeStake,
            beneficialOwners
          );

          if (totalStake !== 100) {
            return Yup.boolean()
              .nullable()
              .isTrue("Required");
          }

          return Yup.mixed();
        })
      : Yup.mixed()
  });
