import React from "react";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import { ImportRecipientsFlow } from "../ImportRecipientsFlow";
import { useHistory } from "react-router-dom";
import { EditLayout } from "../../components/EditLayout";

export const RouteEditImportRecipients: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const history = useHistory();
  const goBack = () => {
    if (onBack) {
      onBack();
      return;
    }

    history.push("/member/1099-filing");
  };

  return (
    <ImportRecipientsFlow
      Layout={EditLayout}
      basePath="/member/1099-filing/batch-add-recipients"
      onBack={goBack}
      onFinish={onNext}
      isEdit={true}
    />
  );
};
