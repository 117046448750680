import * as Yup from "yup";
import { validatorStringTextNumbersSymbols } from "../../validators/validatorStringTextNumbersSymbols";
import { validatorStringTextOnly } from "../../validators/validatorStringTextOnly";

export const validationSchema = Yup.object().shape({
  addressLine1: validatorStringTextNumbersSymbols.required("Required"),
  addressLine2: validatorStringTextNumbersSymbols,
  city: validatorStringTextOnly.required("Required"),
  state: Yup.string().when("country", {
    is: "US",
    then: Yup.string().required("Required"),
    otherwise: validatorStringTextOnly
  }),
  zipCode: Yup.string().when("country", {
    is: "US",
    then: Yup.string()
      .length(5, "Should be 5 characters")
      .required("Required"),
    otherwise: Yup.string().required("Required")
  })
});
