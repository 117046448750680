import React from "react";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksCustomers,
  useIntegrationsQuickbooksItems
} from "../../../../query/integrations/queries";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { WSQueries } from "../../../../query/WSQuery";
import { isQuickbooksServiceActive } from "../../../../query/integrations/selectors";
import { IntegrationsSection } from "./index";
import { IntegrationResourceType } from "./types";
import {
  WSLoader,
  WSPanel,
  WSSectionToolbar
} from "@wingspanhq/fe-component-library";

type IntegrationWidgetProps = {
  isInactive: boolean;
  payerId: string;
  customerId?: string;
  itemId?: string;
  engagementId?: string;
};

export const PayerIntegrationWidget: React.FC<IntegrationWidgetProps> = ({
  isInactive,
  payerId,
  customerId,
  engagementId,
  itemId
}) => {
  const qFeatureFlags = useFeatureFlags();
  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();

  const isQuickbooksActive =
    qIntegrationsQuickbooks.data &&
    (qFeatureFlags.data?.forceShowQBO ||
      isQuickbooksServiceActive(qIntegrationsQuickbooks.data));

  const qIntegrationsQuickbooksCustomers = useIntegrationsQuickbooksCustomers({
    enabled: isQuickbooksActive
  });
  const qIntegrationsQuickbooksItems = useIntegrationsQuickbooksItems({
    enabled: isQuickbooksActive
  });

  if (!isQuickbooksActive) {
    return null;
  }

  return (
    <WSQueries
      renderErrors={() => null}
      renderLoader={() => (
        <>
          <WSSectionToolbar
            title="Integration mappings"
            button={{
              label: "Edit",
              disabled: true
            }}
          />

          <WSPanel mb="XL">
            <WSLoader.Spinner size="M" />
          </WSPanel>
        </>
      )}
      queries={{
        qIntegrationsQuickbooksCustomers,
        qIntegrationsQuickbooksItems
      }}
    >
      {({
        qIntegrationsQuickbooksCustomersData,
        qIntegrationsQuickbooksItemsData
      }) => {
        const mappedCustomer = qIntegrationsQuickbooksCustomersData.find(
          customer => customerId === customer.customerId
        );
        const mappedItem = qIntegrationsQuickbooksItemsData.find(
          item => itemId === item.itemId
        );
        const defaultItem = qIntegrationsQuickbooksItemsData.find(
          item => item.itemId === qIntegrationsQuickbooks.data?.defaults?.itemId
        );

        return isQuickbooksActive ? (
          <IntegrationsSection
            isInactive={isInactive}
            resourceType={IntegrationResourceType.Payer}
            payerIntegration={{
              payerId,
              engagementId,
              customer: mappedCustomer,
              item: mappedItem,
              defaultItem
            }}
          />
        ) : null;
      }}
    </WSQueries>
  );
};
