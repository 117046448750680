import { WSElement, WSIcon } from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import styles from "./StepPoint.module.scss";

type Props = {
  isActive?: boolean;
  isComplete?: boolean;
  isLoading?: boolean;
};

export const StepPoint: React.FC<Props> = ({
  isActive,
  isComplete,
  isLoading
}) => (
  <WSElement
    shimmer={isLoading}
    className={classNames(styles.point, {
      [styles.loading]: isLoading,
      [styles.active]: isLoading ? null : isActive,
      [styles.complete]: isLoading ? null : isComplete && !isActive
    })}
  >
    {isComplete && !isActive ? <WSIcon name="check" size="S" /> : null}
  </WSElement>
);
