import {
  WSButton,
  WSButtons,
  WSElement,
  WSFormOld,
  WSGrid,
  WSSelectOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { ICustomField } from "@wingspanhq/payments/dist/interfaces";
import { INewUser } from "@wingspanhq/users";
import { isString } from "lodash";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { COUNTRY_OPTIONS } from "../../../../../constants/user";
import { getUserName } from "../../../../../query/users/selectors";
import {
  FormPartialCustomFields,
  getValidationSchemaCustomFields
} from "../../../../../shared/components/FormPartialCustomFields";
import { validatorEmail } from "../../../../../shared/validators/validatorEmail";
import {
  validatorFirstName,
  validatorLastName
} from "../../../../../utils/validators";

export type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  externalId?: string;
  country: string;
  organization?: string;
  customFields?: {
    [key: string]: string;
  };
};

type Props = {
  organizations: INewUser[];
  customFields: ICustomField[];
  onSubmit: (data: FormData) => void;
  onCancel?: () => void;
  isLoading?: boolean;
  error?: any;
};

export const Form: React.FC<Props> = ({
  organizations,
  customFields,
  onSubmit,
  onCancel,
  isLoading,
  error
}) => (
  <WSFormOld<FormData>
    mt="M"
    defaultValues={{
      firstName: "",
      lastName: "",
      email: "",
      externalId: "",
      country: "US",
      organization: ""
    }}
    validationSchema={Yup.object().shape({
      firstName: validatorFirstName.required("Required"),
      lastName: validatorLastName.required("Required"),
      email: validatorEmail.required("Email is required"),
      externalId: Yup.string(),
      organization:
        organizations.length > 0
          ? Yup.string().required("Organization is required")
          : Yup.string(),
      customFields: getValidationSchemaCustomFields(customFields)
    })}
    onSubmit={onSubmit}
  >
    <WSText weight="medium" mb="M">
      Personal details
    </WSText>

    <WSElement mb="M">
      <WSGrid gutter="M">
        <WSGrid.Item span={{ xs: "6" }}>
          <WSFormOld.Field
            label="First name"
            name="firstName"
            component={WSTextInput}
          />
        </WSGrid.Item>
        <WSGrid.Item span={{ xs: "6" }}>
          <WSFormOld.Field
            label="Last name"
            name="lastName"
            component={WSTextInput}
          />
        </WSGrid.Item>
      </WSGrid>
      <WSText.ParagraphSm color="gray500" mt="XS">
        If entering for a business, this should be the contact person’s full
        name
      </WSText.ParagraphSm>
    </WSElement>

    <WSFormOld.Field
      mb="M"
      label="Email"
      name="email"
      component={WSTextInput}
      componentProps={{ type: "email" }}
    />

    <WSFormOld.Field
      mb="M"
      label="External ID (optional)"
      name="externalId"
      component={WSTextInput}
    />

    <WSFormOld.Field
      mb="M"
      label="Country (optional)"
      name="country"
      component={WSSelectOld}
      componentProps={{
        options: COUNTRY_OPTIONS,
        searchable: true,
        searchPlaceholder: "Country (optional)"
      }}
    />

    <FormPartialCustomFields
      mb="2XL"
      fields={customFields}
      name="customFields"
    />

    {organizations.length > 0 ? (
      <>
        <WSText weight="medium" mb="M">
          Organizational account
        </WSText>

        <WSFormOld.Field
          name="organization"
          component={WSSelectOld}
          componentProps={{
            options: organizations.map(org => ({
              value: org.userId,
              label: getUserName(org),
              searchText: [
                getUserName(org),
                org.email,
                org.profile?.lastName,
                org.profile?.firstName
              ]
                .filter(isString)
                .map(s => s.trim())
                .filter(Boolean)
                .join(" ")
            })),
            searchable: true
          }}
        />
      </>
    ) : null}

    <WSErrorMessage contextKey="NEC1099AddRecipient" my="2XL" error={error} />

    <WSButtons mt="2XL" forceFullWidth>
      <WSButton loading={isLoading}>Continue</WSButton>
      {onCancel && (
        <WSButton.Secondary type="button" onClick={onCancel}>
          Cancel
        </WSButton.Secondary>
      )}
    </WSButtons>
  </WSFormOld>
);
