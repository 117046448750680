import { WSButton, WSScreen } from "@wingspanhq/fe-component-library";
import React from "react";

interface FormActionsProps {
  onCancel?: (e: any) => void;
  loading?: boolean;
}

export const FormActions: React.FC<FormActionsProps> = ({
  onCancel,
  loading
}) => {
  return (
    <>
      <WSScreen.Desktop>
        <WSButton.Primary
          mt="3XL"
          type="submit"
          loading={loading}
          name="update"
        >
          Update
        </WSButton.Primary>
      </WSScreen.Desktop>
      <WSScreen.MobileAndTablet>
        <WSButton.Primary
          mt="2XL"
          mb="M"
          fullWidth
          type="submit"
          loading={loading}
          name="update"
        >
          Update
        </WSButton.Primary>
        <WSButton.Tertiary onClick={onCancel} fullWidth name="cancel">
          Cancel
        </WSButton.Tertiary>
      </WSScreen.MobileAndTablet>
    </>
  );
};
