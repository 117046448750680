import cn from "classnames";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { WSFlexBox } from "../../layout";
import { WSElementProps } from "../../WSElement/WSElement.component";
import styles from "./WSImage.module.scss";
import { WSText } from "../WSText/WSText.component";

export interface WSImageEmbeddedProps {
  src: string;
  placeholder: ReactNode;
}
export interface WSImageProps
  extends WSImageEmbeddedProps,
    Omit<WSElementProps, "placeholder"> {
  width?: number;
  height?: number;
}

export const WSImage: React.FC<WSImageProps> = ({
  src,
  placeholder,
  className,
  width,
  height,
  ...elementProps
}) => {
  const [loadedSrc, setLoadedSrc] = useState<string | undefined>(undefined);
  const onLoad = useCallback(() => {
    setLoadedSrc(src);
  }, [src]);
  useEffect(() => {
    const img = new Image();
    img.src = src;
    if (img.complete || !src) {
      setLoadedSrc(src || undefined);
    } else {
      img.addEventListener("load", onLoad);
    }
    return () => {
      img.removeEventListener("load", onLoad);
    };
  }, [src, onLoad]);
  return (
    <WSFlexBox.Center
      className={cn(styles.imageContainer, className)}
      style={{ maxWidth: width, maxHeight: height }}
      {...elementProps}
    >
      {loadedSrc === undefined ? (
        typeof placeholder === "string" ? (
          <WSText.ParagraphSm>{placeholder}</WSText.ParagraphSm>
        ) : (
          placeholder
        )
      ) : (
        <img
          src={loadedSrc}
          className={styles.image}
          style={{ maxWidth: width, maxHeight: height }}
          alt={typeof placeholder === "string" ? placeholder : ""}
        />
      )}
    </WSFlexBox.Center>
  );
};
