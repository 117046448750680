import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSInputDateOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useFormContext } from "react-hook-form";
import { RadioGroupWithCustomInputs } from "../../../components/RadioGroupWithCustomInputs/RadioGroupWithCustomInputs";
import { getIsFirstInvoiceSent } from "../../utils";
import { useInvoicesFormContext } from "./InvoicesForm";

export type SendSectionValues = {
  send: {
    type: "immediately" | "date";
    date: Date;
  };
};

type Props = {} & WSElementProps;

export const SendSection: React.FC<Props> = ({ ...elementProps }) => {
  const { isDraft, invoiceTemplate } = useInvoicesFormContext();
  const { setValue } = useFormContext();

  const hideSection =
    !isDraft &&
    (!invoiceTemplate ||
      (invoiceTemplate &&
        invoiceTemplate.frequency &&
        (invoiceTemplate.isSchedulingOnly ||
          getIsFirstInvoiceSent(invoiceTemplate))));
  const hideRadioSwitch = !!invoiceTemplate;

  return (
    <WSElement
      {...elementProps}
      style={{ display: hideSection ? "none" : "block" }}
    >
      <WSText.Heading5 mb="M">Send</WSText.Heading5>

      <RadioGroupWithCustomInputs
        name="send.type"
        style={{ display: hideRadioSwitch ? "none" : "block" }}
        options={[
          {
            value: "immediately",
            label: "Immediately"
          },
          {
            value: "date",
            label: (
              <WSFlexBox.CenterY wrap="nowrap">
                <WSText.ParagraphSm as="pre" inline mr="M">
                  On
                </WSText.ParagraphSm>
                <WSFormOld.Field
                  name="send.date"
                  component={WSInputDateOld}
                  componentProps={{
                    onFocus: () => {
                      setValue("send.type", "date");
                    },
                    onChange: () => {
                      setValue("frequency.start", "onSend");
                    }
                  }}
                />
              </WSFlexBox.CenterY>
            )
          }
        ]}
      />

      {hideRadioSwitch && (
        <WSGrid gutter="M">
          <WSGrid.Item span={{ s: "4" }}>
            <WSFormOld.Field
              name="send.date"
              component={WSInputDateOld}
              componentProps={{
                onChange: () => {
                  setValue("frequency.start", "onSend");
                }
              }}
            />
          </WSGrid.Item>
        </WSGrid>
      )}
    </WSElement>
  );
};
