import {
  WSButton,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { WS_LINKS } from "../../../../types/wsLinks";
import { openInNewTab } from "../../../../utils/openInNewTab";
import { openIntercom } from "../../../../shared/utils/intercom";

type Props = {} & WSElementProps;

export const Footer: React.FC<Props> = ({ ...elementProps }) => {
  return (
    <WSElement {...elementProps}>
      <WSText weight="medium" mb="XS">
        Need to make corrections?
      </WSText>
      <WSText mb="M" color="gray400">
        Any corrections to your 1099 will happen in Wingspan.{" "}
        <WSButton.Link
          onClick={() => {
            openInNewTab(WS_LINKS.corrections);
          }}
        >
          Learn how
        </WSButton.Link>
      </WSText>

      <WSText weight="medium" mb="XS">
        Need help?
      </WSText>
      <WSButton.Link onClick={openIntercom}>
        Chat with a live representative
      </WSButton.Link>
    </WSElement>
  );
};
