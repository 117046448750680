// Taken from https://developer.currencycloud.com/api-reference/#get-available-currencies

export const currencies = [
  {
    code: "AED",
    decimal_places: 2,
    name: "United Arab Emirates Dirham"
  },
  {
    code: "AUD",
    decimal_places: 2,
    name: "Australian Dollar"
  },
  {
    code: "BGN",
    decimal_places: 2,
    name: "Bulgarian Lev"
  },
  {
    code: "BHD",
    decimal_places: 2,
    name: "Bahraini Dinar"
  },
  {
    code: "CAD",
    decimal_places: 2,
    name: "Canadian Dollar"
  },
  {
    code: "CHF",
    decimal_places: 2,
    name: "Swiss Franc"
  },
  {
    code: "CNY",
    decimal_places: 2,
    name: "Chinese Yuan"
  },
  {
    code: "CZK",
    decimal_places: 2,
    name: "Czech Koruna"
  },
  {
    code: "DKK",
    decimal_places: 2,
    name: "Danish Krone"
  },
  {
    code: "EUR",
    decimal_places: 2,
    name: "Euro"
  },
  {
    code: "GBP",
    decimal_places: 2,
    name: "British Pound"
  },
  {
    code: "HKD",
    decimal_places: 2,
    name: "Hong Kong Dollar"
  },
  {
    code: "HRK",
    decimal_places: 2,
    name: "Croatian Kuna"
  },
  {
    code: "HUF",
    decimal_places: 2,
    name: "Hungarian Forint"
  },
  {
    code: "IDR",
    decimal_places: 0,
    name: "Indonesian Rupiah"
  },
  {
    code: "ILS",
    decimal_places: 2,
    name: "Israeli New Sheqel"
  },
  {
    code: "INR",
    decimal_places: 2,
    name: "Indian Rupee"
  },
  {
    code: "JPY",
    decimal_places: 0,
    name: "Japanese Yen"
  },
  {
    code: "KES",
    decimal_places: 2,
    name: "Kenyan Shilling"
  },
  {
    code: "KWD",
    decimal_places: 2,
    name: "Kuwaiti Dinar"
  },
  {
    code: "MXN",
    decimal_places: 2,
    name: "Mexican Peso"
  },
  {
    code: "MYR",
    decimal_places: 2,
    name: "Malaysian Ringgit"
  },
  {
    code: "NOK",
    decimal_places: 2,
    name: "Norwegian Krone"
  },
  {
    code: "NZD",
    decimal_places: 2,
    name: "New Zealand Dollar"
  },
  {
    code: "OMR",
    decimal_places: 2,
    name: "Omani Rial"
  },
  {
    code: "PHP",
    decimal_places: 2,
    name: "Philippine Peso"
  },
  {
    code: "PLN",
    decimal_places: 2,
    name: "Polish Zloty"
  },
  {
    code: "QAR",
    decimal_places: 2,
    name: "Qatari Rial"
  },
  {
    code: "RON",
    decimal_places: 2,
    name: "Romanian New Leu"
  },
  {
    code: "RUB",
    decimal_places: 2,
    name: "Russian Rouble"
  },
  {
    code: "SAR",
    decimal_places: 2,
    name: "Saudi Riyal"
  },
  {
    code: "SEK",
    decimal_places: 2,
    name: "Swedish Krona"
  },
  {
    code: "SGD",
    decimal_places: 2,
    name: "Singapore Dollar"
  },
  {
    code: "THB",
    decimal_places: 2,
    name: "Thai Baht"
  },
  {
    code: "TRY",
    decimal_places: 2,
    name: "Turkish Lira"
  },
  {
    code: "UGX",
    decimal_places: 0,
    name: "Ugandan Shilling"
  },
  {
    code: "USD",
    decimal_places: 2,
    name: "United States Dollar"
  },
  {
    code: "ZAR",
    decimal_places: 2,
    name: "South African Rand"
  }
];

export const CURRENCY_OPTIONS = currencies.map(c => ({
  value: c.code,
  label: `${c.name} (${c.code})`,
  searchText: c.name + " " + c.code
}));
