import garnetCard from "./assets/images/emptyStates/garnet/Card.svg";
import garnetDocuments from "./assets/images/emptyStates/garnet/Documents.svg";
import garnetDone from "./assets/images/emptyStates/garnet/Done.svg";
import garnetError from "./assets/images/emptyStates/garnet/Error.svg";
import garnetImages from "./assets/images/emptyStates/garnet/Images.svg";
import garnetInbox from "./assets/images/emptyStates/garnet/Inbox.svg";
import garnetMessage from "./assets/images/emptyStates/garnet/Message.svg";
import garnetNoInternetConnection from "./assets/images/emptyStates/garnet/NoInternetConnection.svg";
import garnetNotifications from "./assets/images/emptyStates/garnet/Notifications.svg";
import garnetPeople from "./assets/images/emptyStates/garnet/People.svg";
import garnetSearch from "./assets/images/emptyStates/garnet/Search.svg";
import garnetStar from "./assets/images/emptyStates/garnet/Star.svg";
import garnetTasks from "./assets/images/emptyStates/garnet/Tasks.svg";
import garnetWallet from "./assets/images/emptyStates/garnet/Wallet.svg";

import neutralCard from "./assets/images/emptyStates/neutral/Card.svg";
import neutralDocuments from "./assets/images/emptyStates/neutral/Documents.svg";
import neutralDone from "./assets/images/emptyStates/neutral/Done.svg";
import neutralError from "./assets/images/emptyStates/neutral/Error.svg";
import neutralImages from "./assets/images/emptyStates/neutral/Images.svg";
import neutralInbox from "./assets/images/emptyStates/neutral/Inbox.svg";
import neutralMessage from "./assets/images/emptyStates/neutral/Message.svg";
import neutralNoInternetConnection from "./assets/images/emptyStates/neutral/NoInternetConnection.svg";
import neutralNotifications from "./assets/images/emptyStates/neutral/Notifications.svg";
import neutralPeople from "./assets/images/emptyStates/neutral/People.svg";
import neutralSearch from "./assets/images/emptyStates/neutral/Search.svg";
import neutralStar from "./assets/images/emptyStates/neutral/Star.svg";
import neutralTasks from "./assets/images/emptyStates/neutral/Tasks.svg";
import neutralWallet from "./assets/images/emptyStates/neutral/Wallet.svg";


export const emptyStateImages = {
  garnet: {
    card: garnetCard,
    documents: garnetDocuments,
    done: garnetDone,
    error: garnetError,
    images: garnetImages,
    inbox: garnetInbox,
    message: garnetMessage,
    noInternetConnection: garnetNoInternetConnection,
    notifications: garnetNotifications,
    people: garnetPeople,
    search: garnetSearch,
    star: garnetStar,
    tasks: garnetTasks,
    wallet: garnetWallet,
  },
  neutral: {
    card: neutralCard,
    documents: neutralDocuments,
    done: neutralDone,
    error: neutralError,
    images: neutralImages,
    inbox: neutralInbox,
    message: neutralMessage,
    noInternetConnection: neutralNoInternetConnection,
    notifications: neutralNotifications,
    people: neutralPeople,
    search: neutralSearch,
    star: neutralStar,
    tasks: neutralTasks,
    wallet: neutralWallet,
  },
};

export type WSEmptyStateType = keyof typeof emptyStateImages["garnet"];
