import React from "react";
import cn from "classnames";
import styles from "./WSRadioInput.module.scss";
import {
  detachLayoutProps,
  WSElement,
  WSElementProps
} from "../../WSElement/WSElement.component";
import { WSText } from "../WSText/WSText.component";
import { toCamelCase } from "../../../../utils";

export interface WSRadioInputProps extends WSElementProps<HTMLInputElement> {
  value: any;
  name: string;
  label?: React.ReactNode;
  checked?: boolean;
  optionHasBorder?: boolean;
  disabled?: boolean;
}

export const WSRadioInput = React.forwardRef<
  HTMLInputElement,
  WSRadioInputProps
>((props, ref) => {
  const {
    className,
    label,
    value,
    name,
    checked,
    disabled,
    optionHasBorder,
    layoutProps,
    systemProps,
    ...inputProps
  } = detachLayoutProps(props);
  return (
    <WSElement
      {...systemProps}
      className={cn(styles.radioGroupItem, className, {
        [styles.radioInputBorderOption]: optionHasBorder,
        [styles.selected]: checked,
        [styles.disabled]: disabled
      })}
      data-testid={toCamelCase(name, "field", value, "option")}
      as="label"
      {...layoutProps}
    >
      <input
        type="radio"
        value={value}
        name={name}
        ref={ref}
        checked={checked}
        disabled={disabled}
        {...inputProps}
      />
      <WSElement as="i" className={styles.radioGroupIcon} />
      <WSText.ParagraphSm className={styles.label}>{label}</WSText.ParagraphSm>
    </WSElement>
  );
});
