import { QueryConfig } from "react-query";
import { QUERY_STATEMENTS } from "../keys";
import { getStatements } from "../../../services/api/banking/statements";
import { useWSQuery } from "../../helpers";
import { IStatementListResponse } from "../../../services/api/banking/statements/types";
import { WSServiceError } from "../../../utils/serviceHelper";

export const useStatementsQuery = (
  queryConfig?: QueryConfig<IStatementListResponse, WSServiceError>
) => {
  return useWSQuery(
    QUERY_STATEMENTS,
    async () => await getStatements(),
    queryConfig
  );
};
