import { IMember } from "@wingspanhq/users/dist/lib/interfaces";
import { selectorIsCompany } from "../../selectors/selectorIsCompany";

export const selectorIsPersonalAddressRequired = (
  member: IMember,
  isPersonalAddressTheSame?: boolean
) => {
  const isCompany = selectorIsCompany(member);
  return isCompany && !isPersonalAddressTheSame;
};
