import {
  toWSMoneyString,
  useIsMobile,
  WSButton,
  WSDivider,
  WSFlexBox,
  WSGrid,
  WSIcon,
  WSPage,
  WSPageToolbar,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";
import React from "react";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { useWithholdingBalance } from "../../../query/taxes/queries";
import { WSQueries } from "../../../query/WSQuery";
import {
  ADD_TO_CALENDAR_LINK,
  getCurrentIRSQuarter,
  getInternalProcessingDeadline
} from "../../../shared/utils/taxes";
import { openInNewTab } from "../../../utils/openInNewTab";
import { selectorWithholdingBalance } from "../selectors/selectorWithholdingBalance";
import { useModalTaxesQuarterInfo } from "../modals/TaxesModalQuarterInfo";
import { NextPaymentInfo } from "../components/NextPaymentInfo";
import { TaxesTransactions } from "../components/TaxesTransactions";
import { Sidebar } from "../components/Sidebar";

export const TaxesDashboard: React.FC = () => {
  const history = useHistory();
  const modalTaxInfo = useModalTaxesQuarterInfo();
  const quarter = getCurrentIRSQuarter();
  const isMobile = useIsMobile();

  const queryWithholdingBalance = useWithholdingBalance({
    forceFetchOnMount: true
  });

  useBrowserPageTitle("Taxes");

  const internalProcessingDeadline = getInternalProcessingDeadline(
    quarter.dueDate,
    "MMM DD, YYYY"
  );
  const internalDeadlineFromNow = internalProcessingDeadline.fromNow(true);

  return (
    <WSQueries
      queries={{
        queryWithholdingBalance
      }}
    >
      {({ queryWithholdingBalanceData }) => {
        const currentBalance = selectorWithholdingBalance(
          queryWithholdingBalanceData
        );
        const pendingAmounts =
          (queryWithholdingBalanceData as any)?.pending || 0;
        const availableBalance = queryWithholdingBalanceData?.available || 0;

        return (
          <WSPage>
            <WSPageToolbar
              title="Tax Withholdings"
              actions={[
                {
                  label: "Send tax payment",
                  buttonKind: "Secondary",
                  onClick: () => {
                    history.push("/member/taxes/sent-to-irs");
                  }
                },
                {
                  label: "Transfer",
                  hideButtonOn: false,
                  buttonKind: "Primary",
                  onClick: () => {
                    history.push("/member/transfer?balance=taxWithholdings");
                  }
                }
              ]}
            />

            <WSGrid data-testid="taxesDashboard">
              <WSGrid.Item span={{ s: "12", m: "8" }}>
                <WSPanel>
                  <WSText weight="medium" color="gray600" mb="XL">
                    Current balance set aside for taxes.
                  </WSText>
                  <WSText.Heading1 weight="book" color="gray500" mb="XS">
                    {toWSMoneyString(availableBalance)}
                  </WSText.Heading1>

                  {pendingAmounts || currentBalance ? (
                    <WSFlexBox
                      direction="column"
                      gap="M"
                      my="L"
                      alignItems="stretch"
                      alignContent="stretch"
                    >
                      {currentBalance ? (
                        <WSFlexBox.CenterY justify="space-between">
                          <WSText.ParagraphSm color="gray500">
                            Current balance
                          </WSText.ParagraphSm>
                          <WSText.ParagraphSm color="gray600">
                            {toWSMoneyString(currentBalance)}
                          </WSText.ParagraphSm>
                        </WSFlexBox.CenterY>
                      ) : null}
                      {pendingAmounts ? (
                        <WSFlexBox.CenterY justify="space-between">
                          <WSText.ParagraphSm color="gray500">
                            Pending amounts
                          </WSText.ParagraphSm>
                          <WSText.ParagraphSm color="gray500">
                            {toWSMoneyString(pendingAmounts)}
                          </WSText.ParagraphSm>
                        </WSFlexBox.CenterY>
                      ) : null}
                    </WSFlexBox>
                  ) : null}

                  <WSDivider mt="XL" />
                  <WSButton.Secondary
                    mt="L"
                    fullWidth
                    size="S"
                    onClick={() => {
                      history.push("/member/transfer?balance=taxWithholdings");
                    }}
                  >
                    Transfer
                  </WSButton.Secondary>

                  <WSFlexBox.CenterY mt="XL">
                    <WSText.ParagraphXs>
                      <NextPaymentInfo
                        internalDeadlineFromNow={internalDeadlineFromNow}
                        internalProcessingDeadline={internalProcessingDeadline}
                      />
                    </WSText.ParagraphXs>
                    <WSIcon
                      block
                      name="help-circle"
                      size="S"
                      ml="XS"
                      color="gray500"
                      onClick={() => modalTaxInfo.open()}
                    />
                    <WSButton.Link
                      ml="M"
                      size="S"
                      onClick={() => {
                        openInNewTab(ADD_TO_CALENDAR_LINK);
                      }}
                    >
                      Subscribe to Tax Calendar
                    </WSButton.Link>
                  </WSFlexBox.CenterY>
                </WSPanel>

                {isMobile ? <Sidebar mt="XL" /> : null}
                <TaxesTransactions />
              </WSGrid.Item>
              <WSGrid.Item span={{ s: "12", m: "4" }}>
                {isMobile ? null : <Sidebar />}
              </WSGrid.Item>
            </WSGrid>
          </WSPage>
        );
      }}
    </WSQueries>
  );
};
