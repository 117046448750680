import {
  useIsDesktop,
  WSButton,
  WSAvatar,
  WSElement,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./styles.module.scss";
import { SetupStartNewCorrectionFlowProps } from "./index";
import { EditLayout } from "../../../components/EditLayout";
import { WSQueries } from "../../../../../query/WSQuery";
import { FAQ1099 } from "../../../components/FAQ1099";
import { usePayerTaxForm } from "../../../../../query/taxForm/queries/usePayerTaxForm";
import { openIntercom } from "../../../../../shared/utils/intercom";
import {
  CorrectionType,
  TaxFormDocumentType
} from "@wingspanhq/payments/dist/interfaces/taxForm";
import { useTaxFormDownloadDocument } from "../../../../../query/taxForm/mutations/useTaxFormDownloadDocument";
import { downloadFileFromBlob } from "../../../../../utils/files";
import { TaxFormViewer } from "@wingspanhq/payments/dist/interfaces/api/taxForm";

export const RouteSubmitCorrectionSuccess: React.FC<SetupStartNewCorrectionFlowProps> = () => {
  const history = useHistory();
  const match = useRouteMatch<{ taxFormId: string }>();
  const { taxFormId } = match.params;
  const queryTaxForm = usePayerTaxForm(taxFormId);
  const [downloadDocument, downloadDocumentMeta] = useTaxFormDownloadDocument();

  const isDesktop = useIsDesktop();
  const faqsStyles = isDesktop
    ? {
        className: styles.separator
      }
    : {};
  return (
    <EditLayout
      noBack
      title="Start new correction"
      contentRight={<FAQ1099 mb="L" {...faqsStyles} />}
    >
      <WSQueries queries={{ queryTaxForm }}>
        {({ queryTaxFormData: taxForm }) => {
          const submission = taxForm.submissions?.find(
            submission =>
              submission.taxFormSubmissionId === taxForm.currentSubmissionId
          );

          return (
            <WSElement mr={isDesktop ? "XL" : "NONE"}>
              <WSFlexBox.Center direction="column" mb="M">
                <WSAvatar.Icon
                  icon="check"
                  size="M"
                  color="white"
                  colorBackground="green500"
                  mb="S"
                />
                <WSText.Heading2>
                  Corrections submitted to the IRS
                </WSText.Heading2>
              </WSFlexBox.Center>

              <WSText.ParagraphSm color="gray500" mb="M">
                We have submitted your correction to the IRS.
              </WSText.ParagraphSm>

              <WSButton.Primary
                fullWidth
                mb="L"
                onClick={() => {
                  history.push(`/member/1099-filing/details/${taxFormId}`);
                }}
              >
                Continue
              </WSButton.Primary>

              {submission?.correctionType === CorrectionType.Type1 ? (
                <WSElement mb="M">
                  <WSText.Heading4 mb="XS">Submitted forms</WSText.Heading4>
                  <WSText.ParagraphSm color="gray500">
                    Updated form 1099 filed for recipient
                    <WSButton.Link
                      ml="S"
                      onAsyncClick={async () => {
                        await downloadDocument(
                          {
                            viewer: TaxFormViewer.payer,
                            taxFormId,
                            submissionId: submission.taxFormSubmissionId
                          },
                          {
                            throwOnError: true,
                            onSuccess(pdf) {
                              downloadFileFromBlob(
                                pdf,
                                `Updated Form 1099-NEC.pdf`
                              );
                            }
                          }
                        );
                      }}
                    >
                      Download
                    </WSButton.Link>
                  </WSText.ParagraphSm>
                </WSElement>
              ) : submission?.correctionType === CorrectionType.Type2 ? (
                <WSElement mb="M">
                  <WSText.Heading4 mb="XS">Submitted forms</WSText.Heading4>
                  <WSText.ParagraphSm color="gray500">
                    Updated form 1099 filed for recipient
                    <WSButton.Link
                      ml="S"
                      onAsyncClick={async () => {
                        await downloadDocument(
                          {
                            viewer: TaxFormViewer.payer,
                            taxFormId,
                            submissionId: submission.taxFormSubmissionId,
                            documentType: TaxFormDocumentType.Primary
                          },
                          {
                            throwOnError: true,
                            onSuccess(pdf) {
                              downloadFileFromBlob(
                                pdf,
                                `Updated Form 1099-NEC.pdf`
                              );
                            }
                          }
                        );
                      }}
                    >
                      Download
                    </WSButton.Link>
                  </WSText.ParagraphSm>

                  <WSText.ParagraphSm color="gray500">
                    Previous form 1099 (zero’d out){" "}
                    <WSButton.Link
                      ml="S"
                      onAsyncClick={async () => {
                        await downloadDocument(
                          {
                            viewer: TaxFormViewer.payer,
                            taxFormId,
                            submissionId: submission.taxFormSubmissionId,
                            documentType: TaxFormDocumentType.Primary
                          },
                          {
                            throwOnError: true,
                            onSuccess(pdf) {
                              downloadFileFromBlob(
                                pdf,
                                `Previous form 1099 (zero’d out).pdf`
                              );
                            }
                          }
                        );
                      }}
                    >
                      Download
                    </WSButton.Link>
                  </WSText.ParagraphSm>
                </WSElement>
              ) : null}

              <WSElement mb="M">
                <WSText.Heading4 mb="XS">Need help?</WSText.Heading4>
                <WSButton.Link onClick={() => openIntercom()}>
                  Chat with a live representative
                </WSButton.Link>
              </WSElement>
            </WSElement>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
