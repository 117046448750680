import { TinType } from "@wingspanhq/payments/dist/interfaces";
import { IMember } from "@wingspanhq/users/dist/lib/interfaces";

export const selectorMemberDefaultTinType = (
  member: IMember
): TinType | undefined => {
  return member.profile.formW9Options?.tinPreference === TinType.Business
    ? TinType.Business
    : member.profile.formW9Options?.tinPreference === TinType.Individual
    ? TinType.Individual
    : member.profile.company?.taxId
    ? TinType.Business
    : member.profile.ssnProvided || member.profile.ssnLastFourProvided
    ? TinType.Individual
    : undefined;
};
