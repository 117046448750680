import { IActivity } from "@wingspanhq/users/dist/lib/interfaces";
import { PayablesSettings } from "../steps/PayrollSettings/PayablesSettings";
import { PayrollSchedule } from "../steps/PayrollSettings/PayrollSchedule";
import {
  OnboardingContext,
  OnboardingModulePayrollSettings,
  OnboardingStep
} from "../types";

const getStatus = (activity?: IActivity) => {
  if (
    activity?.flows.onboardingPayrollSettings?.complete &&
    activity?.flows.onboardingPayableSettings?.complete
  ) {
    return "Complete";
  }

  return "None";
};

export const getPayrollSettingsStepConfig = (
  _: OnboardingModulePayrollSettings,
  __: OnboardingContext,
  activity?: IActivity
): OnboardingStep => {
  const slug = "payroll-settings";

  const steps: OnboardingStep[] = [
    {
      title: "Payroll schedule",
      slug: "payroll-schedule",
      component: PayrollSchedule
    },
    {
      title: "Payables settings",
      slug: "payables-settings",
      component: PayablesSettings
    }
  ];

  const status = getStatus(activity);

  return {
    title: "Payroll settings",
    slug,
    steps,

    status,
    result: {
      Complete: {
        title: "Your payroll settings were saved",
        description:
          "The payroll settings your selected were saved. Click continue for the next step."
      }
    }
  };
};
