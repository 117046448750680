import {
  createIntegrationsQuickbooks,
  createIntegrationsQuickbooksAccountAssets,
  createIntegrationsQuickbooksAccountExpenses,
  createIntegrationsQuickbooksAccountLiabilities,
  createIntegrationsQuickbooksAccountRevenues,
  createIntegrationsQuickbooksCustomers,
  createIntegrationsQuickbooksItems,
  createIntegrationsQuickbooksVendors,
  deleteIntegrationsQuickbooks,
  updateIntegrationsQuickbooks
} from "../../services/integrations";
import { useWSMutation } from "../helpers";
import {
  QUERY_INTEGRATIONS_QUICKBOOKS,
  QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_ASSETS,
  QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_EXPENSES,
  QUERY_INTEGRATIONS_QUICKBOOKS_VENDORS,
  QUERY_INTEGRATIONS_QUICKBOOKS_ITEMS,
  QUERY_INTEGRATIONS_QUICKBOOKS_CUSTOMERS,
  QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_LIABILITIES,
  QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_REVENUES
} from "./keys";

export const useCreateIntegrationQuickbooks = () =>
  useWSMutation(
    (redirectUrl: string) => createIntegrationsQuickbooks({ redirectUrl }),
    {
      dependencies: [QUERY_INTEGRATIONS_QUICKBOOKS]
    }
  );

export const useUpdateIntegrationQuickbooks = () =>
  useWSMutation(updateIntegrationsQuickbooks, {
    dependencies: [QUERY_INTEGRATIONS_QUICKBOOKS]
  });

export const useDeleteIntegrationQuickbooks = () =>
  useWSMutation(deleteIntegrationsQuickbooks, {
    dependencies: [
      QUERY_INTEGRATIONS_QUICKBOOKS,
      QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_ASSETS,
      QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_EXPENSES,
      QUERY_INTEGRATIONS_QUICKBOOKS_VENDORS,
      QUERY_INTEGRATIONS_QUICKBOOKS_ITEMS,
      QUERY_INTEGRATIONS_QUICKBOOKS_CUSTOMERS
    ]
  });

export const useCreateIntegrationsQuickbooksAccountAssets = () =>
  useWSMutation(createIntegrationsQuickbooksAccountAssets, {
    dependencies: [QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_ASSETS]
  });

export const useCreateIntegrationsQuickbooksAccountExpenses = () =>
  useWSMutation(createIntegrationsQuickbooksAccountExpenses, {
    dependencies: [QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_EXPENSES]
  });

export const useCreateIntegrationsQuickbooksAccountLiabilities = () =>
  useWSMutation(createIntegrationsQuickbooksAccountLiabilities, {
    dependencies: [QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_LIABILITIES]
  });

export const useCreateIntegrationsQuickbooksAccountRevenues = () =>
  useWSMutation(createIntegrationsQuickbooksAccountRevenues, {
    dependencies: [QUERY_INTEGRATIONS_QUICKBOOKS_ACCOUNT_REVENUES]
  });

export const useCreateIntegrationsQuickbooksCustomers = () =>
  useWSMutation(createIntegrationsQuickbooksCustomers, {
    dependencies: [QUERY_INTEGRATIONS_QUICKBOOKS_CUSTOMERS]
  });

export const useCreateIntegrationsQuickbooksItems = () =>
  useWSMutation(createIntegrationsQuickbooksItems, {
    dependencies: [QUERY_INTEGRATIONS_QUICKBOOKS_ITEMS]
  });

export const useCreateIntegrationsQuickbooksVendors = () =>
  useWSMutation(createIntegrationsQuickbooksVendors, {
    dependencies: [QUERY_INTEGRATIONS_QUICKBOOKS_VENDORS]
  });
