export const deepTrim = (obj: any) => {
  // since typeof null is object
  if (obj === null) {
    return obj;
  }

  if ("File" in window && obj instanceof File) {
    return obj;
  }

  if ("Blob" in window && obj instanceof Blob) {
    return obj;
  }

  if (typeof obj === "string") {
    return obj.trim();
  }
  if (typeof obj === "object") {
    Object.keys(obj).forEach((key) => {
      obj[key] = deepTrim(obj[key]);
    });
  }
  return obj;
};
