import { WSService } from "../../../utils/WSService";
import { components, operations } from "./types";

const service = new WSService("/v2/onboarding/acknowledgements");

export const getAcknowledgement = async (
  acknowledgementName: operations["getAcknowledgement"]["parameters"]["path"]["acknowledgementName"]
): Promise<operations["getAcknowledgement"]["responses"]["200"]["content"]["application/json"]> => {
  const { data } = await service.get("/" + acknowledgementName);
  return data;
};

export const updateAcknowledgement = async (
  acknowledgementName: components["schemas"]["AcknowledgementName"],
  requestBody: operations["updateAcknowledgement"]["requestBody"]["content"]["application/json"]
): Promise<operations["updateAcknowledgement"]["responses"]["200"]["content"]["application/json"]> => {
  const { data } = await service.post("/" + acknowledgementName, requestBody);
  return data;
};
