import { IWebflowBenefit } from "@wingspanhq/cms/dist/lib/interfaces/webflow/benefits";
import {
  ISubscription,
  SubscriptionPackageRank,
  SubscriptionPackageTierRank
} from "@wingspanhq/users/dist/lib/interfaces/subscription";
import {
  IS_DEV_ENV,
  IS_PRODUCTION_ENV
} from "../../shared/constants/environment";

export const getBenefitSlug = (title: string) =>
  title
    .toLowerCase()
    .replace(/\W/g, " ")
    .replace(/\s+/g, "-");

export const getPathForBenefit = (title: string, parent?: string) =>
  `/member/benefits${parent ? `/${parent}` : ""}/${getBenefitSlug(title)}`;

export const getTabLinkPathForBenefit = (
  pageTitle: string,
  tabTitle: string,
  parent?: string
) =>
  `/member/benefits${parent ? `/${parent}` : ""}/${getBenefitSlug(
    pageTitle
  )}/${getBenefitSlug(tabTitle)}`;

export const createHealthSherpaLink = (zipCode: string) =>
  "https://www.healthsherpa.com/marketplace/applicants?_agent_id=wingspan";

export const sortCMSBenefits = (a: IWebflowBenefit, b: IWebflowBenefit) => {
  if (a.orderPriority && b.orderPriority) {
    if (a.orderPriority > b.orderPriority) {
      return 1;
    } else if (a.orderPriority < b.orderPriority) {
      return -1;
    }
  } else if (a.orderPriority) {
    return 1;
  } else if (b.orderPriority) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  }
  return 0;
};

export const isBenefitHidden = (benefit: IWebflowBenefit): boolean =>
  !isBenefitVisibleInThisEnvironment(benefit) || !!benefit.hidden;

export const isBenefitAlwaysAdditional = (benefit: IWebflowBenefit): boolean =>
  !!benefit.isAdditionalCoverage;

export const isBenefitIncludedInSubscriptionLevel = (
  benefit: IWebflowBenefit,
  subscription?: ISubscription
): boolean => {
  if (!subscription) {
    return true;
  }

  if (
    SubscriptionPackageRank[subscription.package] >=
    SubscriptionPackageRank[benefit.minSubscriptionPackage]
  ) {
    return (
      SubscriptionPackageTierRank[subscription.packageTier] >=
      SubscriptionPackageTierRank[benefit.minSubscriptionPackageTier]
    );
  }
  return false;
};

export const getCostOfBenefitWithSubscription = (
  benefit: IWebflowBenefit,
  subscription?: ISubscription
): string => {
  return `${
    benefit.helperCostIsNotIncluded
      ? ``
      : `Included${
          !isBenefitIncludedInSubscriptionLevel(benefit, subscription)
            ? " with Premium"
            : ""
        }`
  }${
    benefit.helperCostDescription
      ? `${benefit.helperCostIsNotIncluded ? `` : `, `}${
          benefit.helperCostDescription
        }`
      : ""
  }`;
};

export const insertMemberAndGroupIdsIntoText = (
  text: string,
  memberId?: string,
  groupId?: string
) => {
  return text
    .replaceAll("{{membershipId}}", memberId ? memberId : "---")
    .replaceAll("{{groupId}}", groupId ? groupId : "---");
};

export const isBenefitVisibleInThisEnvironment = (benefit: IWebflowBenefit) =>
  IS_DEV_ENV || (IS_PRODUCTION_ENV && !benefit.isDraft);

export const setPathAfterSuccess = (path: string) => {
  window.sessionStorage.setItem("setupBenefitsFrom", path);
};

export const getPathAfterSuccess = () => {
  return window.sessionStorage.getItem("setupBenefitsFrom");
};
