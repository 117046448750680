import {
  WSButton,
  WSFlexBox,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";

import { NotificationTemplate } from "../index";
import { useBackgroundChecksStartModal } from "../../../../Requirements/modules/BackgroundChecks/modals/useBackgroundChecksStartModal";
import { useBackgroundChecksRequestQuery } from "../../../../../query/backgroundChecks/queries/useBackgroundChecksRequestQuery";
import { WSQueries } from "../../../../../query/WSQuery";
import { BackgroundCheckRequestStatus } from "@wingspanhq/background-checks/dist/lib/interfaces";
import React from "react";

const Body: React.FC<BackgroundCheckRequestContractorContext> = props => {
  const backgroundChecksRequestQuery = useBackgroundChecksRequestQuery(
    props.backgroundCheckRequestId
  );

  return (
    <WSQueries queries={{ backgroundChecksRequestQuery }}>
      {({ backgroundChecksRequestQueryData }) => {
        return backgroundChecksRequestQueryData.status ===
          BackgroundCheckRequestStatus.New ? (
          <>
            Company Belay has requested a background check background check lvl
            2 to complete the onboarding process. <br />
            You will receive an email with instructions shortly.
          </>
        ) : (
          <>
            Company <b>{props.payer.payerName}</b> has requested a background
            check <b>{props.title}</b> to complete the onboarding process.
          </>
        );
      }}
    </WSQueries>
  );
};
const Header: React.FC<BackgroundCheckRequestContractorContext> = props => {
  const backgroundChecksRequestQuery = useBackgroundChecksRequestQuery(
    props.backgroundCheckRequestId
  );

  return (
    <WSQueries queries={{ backgroundChecksRequestQuery }}>
      {({ backgroundChecksRequestQueryData }) => {
        return (
          <WSFlexBox.CenterY wrap="nowrap">
            <WSText.Heading5 mr="M">Background check requested</WSText.Heading5>
            {backgroundChecksRequestQueryData.status ===
            BackgroundCheckRequestStatus.New ? (
              <WSPill px="M" text="Pending" />
            ) : backgroundChecksRequestQueryData.status ===
              BackgroundCheckRequestStatus.Pending ? (
              <WSPill px="M" text="Action required" theme="warning" />
            ) : null}
          </WSFlexBox.CenterY>
        );
      }}
    </WSQueries>
  );
};

export type BackgroundCheckRequestContractorContext = {
  payerPayeeId: string;
  payerPayeeEngagementId: string;
  backgroundCheckRequestId: string;
  backgroundCheckRequestSettingsId: string;
  title: string;
  payer: {
    payerId: string;
    payerName: string;
    logoFileId?: string;
  };
};

export const BackgroundCheckRequestContractor: NotificationTemplate<BackgroundCheckRequestContractorContext> = {
  renderTitle: props => "Background check requested",
  Header: props => {
    return <Header {...props.context} />;
  },
  renderBody: props => {
    // if (props.group.length > 1) {
    //   return "Your clients requested background checks to complete the onboarding process.";
    // }

    return <Body {...props.context} />;
  },
  Footer: props => {
    const backgroundChecksStartModal = useBackgroundChecksStartModal();
    const backgroundChecksRequestQuery = useBackgroundChecksRequestQuery(
      props.context.backgroundCheckRequestId
    );

    // const history = useHistory();
    //
    // if (props.group.length > 1) {
    //   return (
    //     <WSButton.Link
    //       size="S"
    //       rightIcon="chevron-right"
    //       onClick={() => {
    //         history.push("/member/dashboard/contractor-background-checks");
    //       }}
    //     >
    //       Open requests
    //     </WSButton.Link>
    //   );
    // } else
    //
    if (
      backgroundChecksRequestQuery.data?.status ===
      BackgroundCheckRequestStatus.Pending
    ) {
      return (
        <WSButton.Link
          size="S"
          rightIcon="chevron-right"
          onAsyncClick={async () => {
            await backgroundChecksStartModal.open({
              backgroundCheckRequestId: props.context.backgroundCheckRequestId
            });
          }}
        >
          Open <b>{props.context.title}</b>
        </WSButton.Link>
      );
    } else {
      return null;
    }
  }
};
