import {
  WSButton,
  WSFormOld,
  WSGrid,
  WSPage,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useRequestUser } from "../../query/users/mutations";
import { validatorEmail } from "../../shared/validators/validatorEmail";
import { validatorPassword } from "../../shared/validators/validatorPassword";
import { reset } from "../../utils/analytics";

export const AdminSignInAsMember: React.FC = () => {
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const defaultEmail = searchParams.get("email") || "";

  const [requestUser, requestUserMeta] = useRequestUser();

  return (
    <WSPage title="Request a member">
      <WSText mb="3XL">Sign in as a member</WSText>
      <WSGrid>
        <WSGrid.Item span={{ s: "6", l: "4" }}>
          <WSFormOld
            onSubmit={values => {
              requestUser(values, {
                onSuccess: () => {
                  reset();
                  history.push("/member/dashboard");
                  window.location.reload();
                },
                throwOnError: true
              });
            }}
            defaultValues={{
              email: defaultEmail,
              password: ""
            }}
            validationSchema={Yup.object().shape({
              email: validatorEmail.required("Email is required"),
              password: validatorPassword.required("Password is required")
            })}
          >
            <WSFormOld.Field
              name="email"
              label="Member's email"
              mb="XL"
              component={WSTextInput}
              componentProps={{
                type: "email"
              }}
            />
            <WSFormOld.Field
              name="password"
              label="Your password"
              mb="XL"
              component={WSTextInput}
              componentProps={{
                type: "password"
              }}
            />
            {/* Need hidden fields for captcha values */}
            <WSFormOld.Field
              hidden={true}
              name="captchaToken"
              component={WSTextInput}
            />
            <WSFormOld.Field
              hidden={true}
              name="captchaVersion"
              component={WSTextInput}
            />
            <WSButton.Primary fullWidth loading={requestUserMeta.isLoading}>
              Sign in
            </WSButton.Primary>
            {requestUserMeta.error && (
              <WSText mt="2XL" color="red400">
                {requestUserMeta.error?.response?.data.error}
              </WSText>
            )}
          </WSFormOld>
        </WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
