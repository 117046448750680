import { useWSSnackbar } from "@wingspanhq/fe-component-library";

export default function useSnackbar() {
  const { openSnackbar } = useWSSnackbar();

  return {
    success: (message: string) =>
      openSnackbar({
        duration: 5000,
        type: "success",
        message: message
      }),

    warning: (message: string) =>
      openSnackbar({
        duration: 5000,
        type: "warning",
        message: message
      })
  };
}

export type Snackbar = ReturnType<typeof useSnackbar>;
