import {
  IMemberClientRequirementResponse,
  IRequirement
} from "@wingspanhq/payments/dist/interfaces";
import { RequirementStatus } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { RequirementEmbeddedStatus } from "@wingspanhq/payments/dist/interfaces/requirement";
import { ISignedDocumentResponse } from "@wingspanhq/signed-documents/dist/lib/interfaces";

export const selectorIsMemberClientRequirementReadyToSignAsPayee = (
  requirement: IMemberClientRequirementResponse
): boolean => {
  return (
    requirement.status !== RequirementStatus.Complete &&
    !(
      requirement.status === RequirementStatus.Pending &&
      requirement.document?.events?.memberSignedAt
    )
  );
};

export const selectorIsRequirementReadyToSignAsPayee = (
  requirement: IRequirement,
  document?: ISignedDocumentResponse
): boolean => {
  const isSignedByPayee = document?.events?.payeeSignedAt;

  return (
    !!document &&
    requirement.dataSourceStatus !== RequirementEmbeddedStatus.Complete &&
    !isSignedByPayee
  );
};
