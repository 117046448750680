import React, { ReactNode } from "react";
import cn from "classnames";
import {
  WSSidebar,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";

import styles from "./BulkBatchDetailsLayout.module.scss";

export type BulkBatchDetailsLayoutProps = {
  onBack: () => void;
  title: string;
};

export type BulkBatchDetailsLayoutCardProps = {
  title?: ReactNode;
  titleRight?: ReactNode;
} & WSElementProps;

interface BulkBatchDetailsLayoutComponent
  extends React.FC<BulkBatchDetailsLayoutProps> {
  Card: React.FC<BulkBatchDetailsLayoutCardProps>;
}

export const BulkBatchDetailsLayout: BulkBatchDetailsLayoutComponent = ({
  children,
  onBack,
  title
}) => {
  return (
    <WSSidebar
      onClose={onBack}
      header={
        <WSText.Paragraph data-testid="bulkBatchDetailsHeader">
          {title}
        </WSText.Paragraph>
      }
    >
      {children}
    </WSSidebar>
  );
};

BulkBatchDetailsLayout.Card = ({
  children,
  title,
  titleRight,
  className,
  ...elementProps
}) => (
  <WSElement className={cn(styles.card, className)} {...elementProps}>
    {title && (
      <WSFlexBox
        className={styles.cardHeader}
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <WSText.Heading5>{title}</WSText.Heading5>
        {titleRight && <WSElement>{titleRight}</WSElement>}
      </WSFlexBox>
    )}
    <WSElement className={styles.main}>{children}</WSElement>
  </WSElement>
);
