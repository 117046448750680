import { IInternalAccount } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayoutSettingsResponse,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { selectorStandardAccounts } from "../../selectors/selectorStandardAccounts";
import { PayoutMethod } from "../PayoutMethod";

type Props = {
  payoutSettings: IPayoutSettingsResponse;
  internalAccount?: IInternalAccount;
  accounts: IAccount[];
  showInternationalTitle?: boolean;
} & WSElementProps;

export const StandardMethods: React.FC<Props> = ({
  payoutSettings,
  accounts,
  internalAccount,
  showInternationalTitle,
  ...elementProps
}) => {
  const standardDestination = payoutSettings.payoutDestinations.find(
    destination => destination.payoutMethod === PayoutPreferences.Standard
  );
  const accountsPayout = selectorStandardAccounts(accounts);
  const isVisible = accountsPayout.length > 0 || !!internalAccount;

  return isVisible ? (
    <WSElement {...elementProps}>
      <WSText weight="medium" mb="XL">
        {showInternationalTitle
          ? "US-based payout methods"
          : "Standard payout methods"}
      </WSText>
      {accountsPayout.map(account => (
        <PayoutMethod
          mb="M"
          account={account}
          type={PayoutPreferences.Standard}
          isDefault={
            payoutSettings.payoutPreferences === PayoutPreferences.Standard &&
            standardDestination &&
            standardDestination.destinationId === account.accountId
          }
        />
      ))}
      {internalAccount && (
        <PayoutMethod
          mb="M"
          internalAccount={internalAccount}
          type={PayoutPreferences.Standard}
          isDefault={
            payoutSettings.payoutPreferences === PayoutPreferences.Standard &&
            !standardDestination
          }
        />
      )}
    </WSElement>
  ) : null;
};
