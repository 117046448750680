import { Route, Switch } from "react-router-dom";
import { PAYERS_PATH, RouteClients } from "./routes/RouteClients";
import { useBrowserPageTitle } from "../../shared/hooks/useBrowserPageTitle";
import { RouteClientDetails } from "./routes/RouteClientDetails";
import { RouteSearchClients } from "./routes/RouteSearchClients";
import { RouteArchivedClients } from "./routes/RouteArchivedClients";

export const PayersIndex = () => {
  useBrowserPageTitle("Clients");

  return (
    <Switch>
      <Route
        path={`${PAYERS_PATH}/archived`}
        component={RouteArchivedClients}
        exact
      />
      <Route
        path={`${PAYERS_PATH}/search`}
        component={RouteSearchClients}
        exact
      />
      <Route path={`${PAYERS_PATH}/:clientId`} component={RouteClientDetails} />
      <Route path={PAYERS_PATH} component={RouteClients} />
    </Switch>
  );
};
