import React, { ErrorInfo } from "react";

type Props = {
  fallback: React.ReactNode | ((error: Error) => React.ReactNode);
  onCatch?: (error: Error, errorInfo: ErrorInfo) => void;
};

type State = {
  error?: Error;
};

export class WSErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, info: { componentStack: string }) {
    this.props.onCatch?.(error, info);
  }

  render() {
    if (this.state.error) {
      return typeof this.props.fallback === "function"
        ? this.props.fallback(this.state.error)
        : this.props.fallback;
    }

    return this.props.children;
  }
}
