import React from "react";
import {
  WSContainer,
  WSFormOld,
  WSCentered,
  WSPanel,
  WSRadioInputGroup,
  WSText
} from "@wingspanhq/fe-component-library";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import * as Yup from "yup";
import {
  ITransaction,
  TransactionStatus,
  TransactionType
} from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";
import { useHistory } from "react-router";
import { Currency } from "@wingspanhq/users/dist/lib/interfaces";
import { CurrencyUSD } from "../../../services/bookkeeping";

type Props = { next: string };

export const BookkeepingCSVIsBusiness: React.FC<Props> = ({ next }) => {
  const history = useHistory<{
    transactions: Array<ITransaction>;
    accountId?: string;
  }>();
  useBrowserPageTitle(
    "Bookkeeping - CSV: Are these dedicated business transactions?"
  );

  const transactions = Array.isArray(history.location.state?.transactions)
    ? history.location.state?.transactions
    : [];

  const accountId = history.location.state?.accountId;

  return (
    <ProjectOnboardingLayout progress={(100 / 5) * 3} noBack>
      <WSContainer verticalPadding>
        <WSFormOld
          validationSchema={Yup.object().shape({
            isBusiness: Yup.string()
          })}
          defaultValues={{ isBusiness: "YES" }}
          onSubmit={async formData => {
            const isBusiness = formData.isBusiness === "YES";

            history.replace(next, {
              accountId,
              transactions:
                transactions?.map(t => ({
                  ...t,
                  accountId,
                  currency: CurrencyUSD, // TODO(artemk): figure out why packages are picked up incurrectly
                  type: TransactionType.Transfer,
                  status: TransactionStatus.Active,
                  business: isBusiness
                })) || []
            });
          }}
        >
          <WSCentered span={{ m: "6" }}>
            <WSText.ParagraphSm color="gray500" mt="M" pt="M">
              3 of 5
            </WSText.ParagraphSm>
            <WSText.Heading4>
              Import historic business transactions
            </WSText.Heading4>

            <WSPanel mt="2XL">
              <WSText.Heading5 mt="XL">
                Are these dedicated business transactions?
              </WSText.Heading5>

              <WSText mt="XL">
                We’ll automatically mark all transactions as business so you can
                skip manual review.
              </WSText>

              <WSFormOld.Field
                mt="M"
                label=""
                name="isBusiness"
                component={WSRadioInputGroup}
                componentProps={{
                  options: [
                    {
                      value: "YES",
                      label: "Yes"
                    },
                    {
                      value: "NO",
                      label: "No"
                    }
                  ]
                }}
              />

              <WSFormOld.SubmitButton
                name="submitBusinessImport"
                mt="XL"
                fullWidth
              >
                Continue
              </WSFormOld.SubmitButton>
            </WSPanel>
          </WSCentered>
        </WSFormOld>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
