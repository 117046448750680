import Afghanistan from "./assets/images/flags/Afghanistan.png";
import Albania from "./assets/images/flags/Albania.png";
import Algeria from "./assets/images/flags/Algeria.png";
import Andorra from "./assets/images/flags/Andorra.png";
import Angola from "./assets/images/flags/Angola.png";
import Argentina from "./assets/images/flags/Argentina.png";
import Armenia from "./assets/images/flags/Armenia.png";
import Australia from "./assets/images/flags/Australia.png";
import Austria from "./assets/images/flags/Austria.png";
import Azerbaijan from "./assets/images/flags/Azerbaijan.png";
import Bahamas from "./assets/images/flags/Bahamas.png";
import Bahrain from "./assets/images/flags/Bahrain.png";
import Bangladesh from "./assets/images/flags/Bangladesh.png";
import Barbados from "./assets/images/flags/Barbados.png";
import Belarus from "./assets/images/flags/Belarus.png";
import Belgium from "./assets/images/flags/Belgium.png";
import Belize from "./assets/images/flags/Belize.png";
import Benin from "./assets/images/flags/Benin.png";
import Bhutan from "./assets/images/flags/Bhutan.png";
import Bolivia from "./assets/images/flags/Bolivia.png";
import Botswana from "./assets/images/flags/Botswana.png";
import Brazil from "./assets/images/flags/Brazil.png";
import Brunei from "./assets/images/flags/Brunei.png";
import Bulgaria from "./assets/images/flags/Bulgaria.png";
import BurkinaFaso from "./assets/images/flags/Burkina faso.png";
import Burundi from "./assets/images/flags/Burundi.png";
import Cambodia from "./assets/images/flags/Cambodia.png";
import Cameroon from "./assets/images/flags/Cameroon.png";
import Canada from "./assets/images/flags/Canada.png";
import CapeVerde from "./assets/images/flags/Cape verde.png";
import Chad from "./assets/images/flags/Chad.png";
import Chile from "./assets/images/flags/Chile.png";
import China from "./assets/images/flags/China.png";
import Colombia from "./assets/images/flags/Colombia.png";
import Comoros from "./assets/images/flags/Comoros.png";
import CostaRica from "./assets/images/flags/Costa rica.png";
import Croatia from "./assets/images/flags/Croatia.png";
import Cuba from "./assets/images/flags/Cuba.png";
import Cyprus from "./assets/images/flags/Cyprus.png";
import CzechRepublic from "./assets/images/flags/Czech republic.png";
import Denmark from "./assets/images/flags/Denmark.png";
import Djibouti from "./assets/images/flags/Djibouti.png";
import Dominica from "./assets/images/flags/Dominica.png";
import DominicanRepublic from "./assets/images/flags/Dominican republic.png";
import Ecuador from "./assets/images/flags/Ecuador.png";
import Egypt from "./assets/images/flags/Egypt.png";
import ElSalvador from "./assets/images/flags/El salvador.png";
import EquatorialGuinea from "./assets/images/flags/Equatorial guinea.png";
import Eritrea from "./assets/images/flags/Eritrea.png";
import Estonia from "./assets/images/flags/Estonia.png";
import Ethiopia from "./assets/images/flags/Ethiopia.png";
import Fiji from "./assets/images/flags/Fiji.png";
import Finland from "./assets/images/flags/Finland.png";
import France from "./assets/images/flags/France.png";
import Gabon from "./assets/images/flags/Gabon.png";
import Gambia from "./assets/images/flags/Gambia.png";
import Georgia from "./assets/images/flags/Georgia.png";
import Germany from "./assets/images/flags/Germany.png";
import Ghana from "./assets/images/flags/Ghana.png";
import Greece from "./assets/images/flags/Greece.png";
import Grenada from "./assets/images/flags/Grenada.png";
import Guatemala from "./assets/images/flags/Guatemala.png";
import GuineaBissau from "./assets/images/flags/Guinea-bissau.png";
import Guinea from "./assets/images/flags/Guinea.png";
import Guyana from "./assets/images/flags/Guyana.png";
import Haiti from "./assets/images/flags/Haiti.png";
import Honduras from "./assets/images/flags/Honduras.png";
import Hungary from "./assets/images/flags/Hungary.png";
import Iceland from "./assets/images/flags/Iceland.png";
import India from "./assets/images/flags/India.png";
import Indonesia from "./assets/images/flags/Indonesia.png";
import Iran from "./assets/images/flags/Iran.png";
import Iraq from "./assets/images/flags/Iraq.png";
import Ireland from "./assets/images/flags/Ireland.png";
import Israel from "./assets/images/flags/Israel.png";
import Italy from "./assets/images/flags/Italy.png";
import IvoryCoast from "./assets/images/flags/Ivory coast.png";
import Jamaica from "./assets/images/flags/Jamaica.png";
import Japan from "./assets/images/flags/Japan.png";
import Jordan from "./assets/images/flags/Jordan.png";
import Kazakhstan from "./assets/images/flags/Kazakhstan.png";
import Kenya from "./assets/images/flags/Kenya.png";
import Kiribati from "./assets/images/flags/Kiribati.png";
import Kuwait from "./assets/images/flags/Kuwait.png";
import Kyrgyzstan from "./assets/images/flags/Kyrgyzstan.png";
import Laos from "./assets/images/flags/Laos.png";
import Latvia from "./assets/images/flags/Latvia.png";
import Lebanon from "./assets/images/flags/Lebanon.png";
import Lesotho from "./assets/images/flags/Lesotho.png";
import Liberia from "./assets/images/flags/Liberia.png";
import Libya from "./assets/images/flags/Libya.png";
import Liechtenstein from "./assets/images/flags/Liechtenstein.png";
import Lithuania from "./assets/images/flags/Lithuania.png";
import Luxembourg from "./assets/images/flags/Luxembourg.png";
import Macedonia from "./assets/images/flags/Macedonia.png";
import Madagascar from "./assets/images/flags/Madagascar.png";
import Malawi from "./assets/images/flags/Malawi.png";
import Malaysia from "./assets/images/flags/Malaysia.png";
import Maldives from "./assets/images/flags/Maldives.png";
import Mali from "./assets/images/flags/Mali.png";
import Malta from "./assets/images/flags/Malta.png";
import MarshallIsland from "./assets/images/flags/Marshall island.png";
import Mauritania from "./assets/images/flags/Mauritania.png";
import Mauritius from "./assets/images/flags/Mauritius.png";
import Mexico from "./assets/images/flags/Mexico.png";
import Micronesia from "./assets/images/flags/Micronesia.png";
import Moldova from "./assets/images/flags/Moldova.png";
import Monaco from "./assets/images/flags/Monaco.png";
import Mongolia from "./assets/images/flags/Mongolia.png";
import Montenegro from "./assets/images/flags/Montenegro.png";
import Morocco from "./assets/images/flags/Morocco.png";
import Mozambique from "./assets/images/flags/Mozambique.png";
import Myanmar from "./assets/images/flags/Myanmar.png";
import Nambia from "./assets/images/flags/Nambia.png";
import Nauru from "./assets/images/flags/Nauru.png";
import Nepal from "./assets/images/flags/Nepal.png";
import Netherlands from "./assets/images/flags/Netherlands.png";
import NewZealand from "./assets/images/flags/New zealand.png";
import Nicaragua from "./assets/images/flags/Nicaragua.png";
import Niger from "./assets/images/flags/Niger.png";
import Nigeria from "./assets/images/flags/Nigeria.png";
import NorthKorea from "./assets/images/flags/North korea.png";
import Norway from "./assets/images/flags/Norway.png";
import Oman from "./assets/images/flags/Oman.png";
import Pakistan from "./assets/images/flags/Pakistan.png";
import Palau from "./assets/images/flags/Palau.png";
import Panama from "./assets/images/flags/Panama.png";
import PapuaNewGuinea from "./assets/images/flags/Papua new guinea.png";
import Paraguay from "./assets/images/flags/Paraguay.png";
import Peru from "./assets/images/flags/Peru.png";
import Philippines from "./assets/images/flags/Philippines.png";
import Poland from "./assets/images/flags/Poland.png";
import Portugal from "./assets/images/flags/Portugal.png";
import Qatar from "./assets/images/flags/Qatar.png";
import Romania from "./assets/images/flags/Romania.png";
import Russia from "./assets/images/flags/Russia.png";
import Rwanda from "./assets/images/flags/Rwanda.png";
import SaintKittsAndNevis from "./assets/images/flags/Saint kitts and nevis.png";
import SaintLucia from "./assets/images/flags/Saint lucia.png";
import SaintVincentAndTheGrenadines from "./assets/images/flags/Saint vincent and the grenadines.png";
import Samoa from "./assets/images/flags/Samoa.png";
import SanMarino from "./assets/images/flags/San marino.png";
import SaoTomeAndPrincipe from "./assets/images/flags/Sao tome and principe.png";
import SaudiArabia from "./assets/images/flags/Saudi arabia.png";
import Senegal from "./assets/images/flags/Senegal.png";
import Serbia from "./assets/images/flags/Serbia.png";
import Seychelles from "./assets/images/flags/Seychelles.png";
import SierraLeone from "./assets/images/flags/Sierra leone.png";
import Singapore from "./assets/images/flags/Singapore.png";
import Slovakia from "./assets/images/flags/Slovakia.png";
import SolomonIslands from "./assets/images/flags/Solomon islands.png";
import Somalia from "./assets/images/flags/Somalia.png";
import SouthAfrica from "./assets/images/flags/South africa.png";
import SouthKorea from "./assets/images/flags/South korea.png";
import SouthSudan from "./assets/images/flags/South sudan.png";
import Spain from "./assets/images/flags/Spain.png";
import SriLanka from "./assets/images/flags/Sri lanka.png";
import Sudan from "./assets/images/flags/Sudan.png";
import Suriname from "./assets/images/flags/Suriname.png";
import Swaziland from "./assets/images/flags/Swaziland.png";
import Sweden from "./assets/images/flags/Sweden.png";
import Switzerland from "./assets/images/flags/Switzerland.png";
import Syria from "./assets/images/flags/Syria.png";
import Taiwan from "./assets/images/flags/Taiwan.png";
import Tajikistan from "./assets/images/flags/Tajikistan.png";
import Tanzania from "./assets/images/flags/Tanzania.png";
import Thailand from "./assets/images/flags/Thailand.png";
import Togo from "./assets/images/flags/Togo.png";
import Tonga from "./assets/images/flags/Tonga.png";
import TrinidadAndTobago from "./assets/images/flags/Trinidad and tobago.png";
import Tunisia from "./assets/images/flags/Tunisia.png";
import Turkey from "./assets/images/flags/Turkey.png";
import Turkmenistan from "./assets/images/flags/Turkmenistan.png";
import Tuvalu from "./assets/images/flags/Tuvalu.png";
import Uganda from "./assets/images/flags/Uganda.png";
import Ukraine from "./assets/images/flags/Ukraine.png";
import UnitedArabEmirates from "./assets/images/flags/United arab emirates.png";
import UnitedKingdom from "./assets/images/flags/United kingdom.png";
import UnitedStates from "./assets/images/flags/United states.png";
import Uruguay from "./assets/images/flags/Uruguay.png";
import Uzbekistan from "./assets/images/flags/Uzbekistan.png";
import Vanuatu from "./assets/images/flags/Vanuatu.png";
import VaticanCity from "./assets/images/flags/Vatican city.png";
import Venezuela from "./assets/images/flags/Venezuela.png";
import Vietnam from "./assets/images/flags/Vietnam.png";
import Yemen from "./assets/images/flags/Yemen.png";
import Zambia from "./assets/images/flags/Zambia.png";
import Zimbabwe from "./assets/images/flags/Zimbabwe.png";
const files = {
  Afghanistan,
  Albania,
  Algeria,
  Andorra,
  Angola,
  Argentina,
  Armenia,
  Australia,
  Austria,
  Azerbaijan,
  Bahamas,
  Bahrain,
  Bangladesh,
  Barbados,
  Belarus,
  Belgium,
  Belize,
  Benin,
  Bhutan,
  Bolivia,
  Botswana,
  Brazil,
  Brunei,
  Bulgaria,
  BurkinaFaso,
  Burundi,
  Cambodia,
  Cameroon,
  Canada,
  CapeVerde,
  Chad,
  Chile,
  China,
  Colombia,
  Comoros,
  CostaRica,
  Croatia,
  Cuba,
  Cyprus,
  CzechRepublic,
  Denmark,
  Djibouti,
  Dominica,
  DominicanRepublic,
  Ecuador,
  Egypt,
  ElSalvador,
  EquatorialGuinea,
  Eritrea,
  Estonia,
  Ethiopia,
  Fiji,
  Finland,
  France,
  Gabon,
  Gambia,
  Georgia,
  Germany,
  Ghana,
  Greece,
  Grenada,
  Guatemala,
  GuineaBissau,
  Guinea,
  Guyana,
  Haiti,
  Honduras,
  Hungary,
  Iceland,
  India,
  Indonesia,
  Iran,
  Iraq,
  Ireland,
  Israel,
  Italy,
  IvoryCoast,
  Jamaica,
  Japan,
  Jordan,
  Kazakhstan,
  Kenya,
  Kiribati,
  Kuwait,
  Kyrgyzstan,
  Laos,
  Latvia,
  Lebanon,
  Lesotho,
  Liberia,
  Libya,
  Liechtenstein,
  Lithuania,
  Luxembourg,
  Macedonia,
  Madagascar,
  Malawi,
  Malaysia,
  Maldives,
  Mali,
  Malta,
  MarshallIsland,
  Mauritania,
  Mauritius,
  Mexico,
  Micronesia,
  Moldova,
  Monaco,
  Mongolia,
  Montenegro,
  Morocco,
  Mozambique,
  Myanmar,
  Nambia,
  Nauru,
  Nepal,
  Netherlands,
  NewZealand,
  Nicaragua,
  Niger,
  Nigeria,
  NorthKorea,
  Norway,
  Oman,
  Pakistan,
  Palau,
  Panama,
  PapuaNewGuinea,
  Paraguay,
  Peru,
  Philippines,
  Poland,
  Portugal,
  Qatar,
  Romania,
  Russia,
  Rwanda,
  SaintKittsAndNevis,
  SaintLucia,
  SaintVincentAndTheGrenadines,
  Samoa,
  SanMarino,
  SaoTomeAndPrincipe,
  SaudiArabia,
  Senegal,
  Serbia,
  Seychelles,
  SierraLeone,
  Singapore,
  Slovakia,
  SolomonIslands,
  Somalia,
  SouthAfrica,
  SouthKorea,
  SouthSudan,
  Spain,
  SriLanka,
  Sudan,
  Suriname,
  Swaziland,
  Sweden,
  Switzerland,
  Syria,
  Taiwan,
  Tajikistan,
  Tanzania,
  Thailand,
  Togo,
  Tonga,
  TrinidadAndTobago,
  Tunisia,
  Turkey,
  Turkmenistan,
  Tuvalu,
  Uganda,
  Ukraine,
  UnitedArabEmirates,
  UnitedKingdom,
  UnitedStates,
  Uruguay,
  Uzbekistan,
  Vanuatu,
  VaticanCity,
  Venezuela,
  Vietnam,
  Yemen,
  Zambia,
  Zimbabwe
} as const;

export type WSFlag = keyof typeof files;

export const FLAG_FILES = files as Record<WSFlag, string>;
