import {
  RequirementContextType,
  RequirementTableCallback,
  RequirementTableRowConfig
} from "../../types";
import { useMemo } from "react";
import { queryCache } from "react-query";
import {
  IRequirementResponse,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import { QUERY_BACKGROUND_CHECKS_REQUEST } from "../../../../query/backgroundChecks/queries/useBackgroundChecksRequestQuery";
import { selectorIsRequirement } from "../../selectors/selectorIsRequirement";
import { concurrentActions } from "../../../../utils/serviceHelper";
import { getBackgroundChecksRequest } from "../../../../services/backgroundChecks/getBackgroundChecksRequest";
import { IBackgroundCheckRequestResponse } from "@wingspanhq/background-checks/dist/lib/interfaces";
import { usePayerBackgroundChecksConfig } from "./cells/payer/usePayerBackgroundChecksConfig";
import { usePayeeBackgroundChecksConfig } from "./cells/payee/usePayeeBackgroundChecksConfig";

export const useBackgroundChecksRequirementTableConfig = (
  callback: RequirementTableCallback
): RequirementTableRowConfig[RequirementType.BackgroundCheck] => {
  const payerBackgroundChecksConfig = usePayerBackgroundChecksConfig(callback);
  const payeeBackgroundChecksConfig = usePayeeBackgroundChecksConfig(callback);

  const cells = useMemo(() => {
    const allCells: Array<keyof Omit<
      RequirementTableRowConfig[RequirementType.BackgroundCheck],
      "fetchContext"
    >> = ["nameCell", "typeCell", "statusCell", "rowActions", "rowMenuActions"];

    return allCells.reduce((acc, key) => {
      acc[key] = {
        [RequirementContextType.Payer]: (requirement, context) => {
          return payerBackgroundChecksConfig[key](
            requirement as IRequirementResponse,
            context
          ) as any;
        },
        [RequirementContextType.Payee]: (requirement, context) => {
          return payeeBackgroundChecksConfig[key](
            requirement as IRequirementResponse,
            context
          ) as any;
        }
      };
      return acc;
    }, {} as RequirementTableRowConfig[RequirementType.BackgroundCheck]);
  }, []);

  return {
    ...cells,
    async fetchContext(requirements) {
      const actions = requirements
        .filter(selectorIsRequirement)
        .filter(
          requirement =>
            requirement.requirementType === RequirementType.BackgroundCheck &&
            requirement.dataSourceId
        )
        .map(requirement => async () => {
          try {
            let request = queryCache.getQueryData<
              IBackgroundCheckRequestResponse
            >([QUERY_BACKGROUND_CHECKS_REQUEST, requirement.dataSourceId]);

            if (!request) {
              request = await getBackgroundChecksRequest(
                requirement.dataSourceId
              );
              queryCache.setQueryData(
                [QUERY_BACKGROUND_CHECKS_REQUEST, requirement.dataSourceId],
                request
              );
            }

            return request;
          } catch (e) {}
        });

      let requests = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return requests.filter(
        request => request
      ) as IBackgroundCheckRequestResponse[];
    }
  };
};
