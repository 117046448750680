import { WSTableCell } from "@wingspanhq/fe-component-library";
import { useBulkCollaboratorBatchSummary } from "../../query/bulkCollaborator/queries";

export function TotalCollaboratorsCount({ batchId }: { batchId: string }) {
  const qBulkPayableBatchItems = useBulkCollaboratorBatchSummary(batchId);
  return (
    <WSTableCell
      // shimmer={qBulkPayableBatchItems.isLoading}
      text={
        qBulkPayableBatchItems.data?.collaboratorsCount === undefined
          ? undefined
          : String(qBulkPayableBatchItems.data?.collaboratorsCount)
      }
    />
  );
}
