import cn from "classnames";
import React from "react";
import { WSInputBaseProps } from "../../types/forms";
import { WSElement } from "../WSElement/WSElement.component";
import {
  WSIcon,
  WSIconName,
  WSIconSize
} from "../core/WSIcon/WSIcon.component";
import styles from "./WSInputContainer.module.scss";
import { getStatusStyles } from "./getStatusStyles";

type WSInputContainerSize = "M" | "L";

export type WSInputContainerAction = {
  icon: WSIconName;
  onClick: () => void;
};

const sizeToIconSize: Record<WSInputContainerSize, WSIconSize> = {
  M: "S",
  L: "L"
};

export interface WSInputContainerProps {
  children?: React.ReactNode;
  status?: WSInputBaseProps["status"];
  disabled?: WSInputBaseProps["disabled"];
  size?: WSInputContainerSize;
  align?: "left" | "right";
  icon?: WSIconName;
  iconRight?: WSIconName;
  inputAction?: WSInputContainerAction;
  className?: string;
  onClick?: () => void;
  tabIndex?: number;
}

export const WSInputContainer = React.forwardRef<
  HTMLElement,
  WSInputContainerProps
>((props, ref) => {
  const {
    status,
    align = "left",
    size = "M",
    children,
    className,
    icon,
    iconRight,
    inputAction,
    disabled,
    ...otherProps
  } = props;

  const containerClassName = cn(
    styles.inputContainer,
    getStatusStyles(status),
    {
      [styles[`size-${size}`]]: size,
      [styles[`align-${align}`]]: align,
      [styles.disabled]: status === "loading" || disabled
    },
    className
  );

  return (
    <WSElement ref={ref} className={containerClassName} {...otherProps}>
      {icon ? (
        <WSIcon
          name={icon}
          className={styles.icon}
          size={sizeToIconSize[size]}
        />
      ) : null}

      <WSElement className={styles.input}>{children}</WSElement>

      {inputAction ? (
        <WSIcon
          size="S"
          name={inputAction.icon}
          className={styles.actionButton}
          onClick={inputAction.onClick}
        />
      ) : null}

      {iconRight ? (
        <WSIcon
          name={iconRight}
          className={styles.iconRight}
          size={sizeToIconSize[size]}
        />
      ) : null}
    </WSElement>
  );
});

WSInputContainer.displayName = "WSInputContainer";
