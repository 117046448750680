import {
  WSButton,
  WSCentered,
  WSFlexBox,
  WSFormOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import { TextField } from "../../Auth/components/TextField/TextField";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { WSQueries } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import { useCreateUser } from "../../query/users/mutations";
import { useUserProfile } from "../../query/users/queries";

type RouteProps = RouteComponentProps<{ code: string }>;

type Props = RouteProps;

export const OnboardingSetPassword: React.FC<Props> = ({ history }) => {
  const userId = useUserId();
  const qUserProfile = useUserProfile(userId);

  const [createUser, createUserMeta] = useCreateUser();

  return (
    <OnboardingLayout>
      <BrowserPageTitle title="Continue Sign Up flow" />
      <WSCentered span={{ m: "6", xl: "4" }}>
        <WSQueries queries={{ qUserProfile }}>
          {({ qUserProfile }) => (
            <>
              <WSText.Heading5 mb="XL">Continue sign up...</WSText.Heading5>

              <WSText mb="2XL">
                Welcome back! To complete sign up, please enter a password for
                your account.
              </WSText>

              <WSFormOld
                defaultValues={{ password: "", passwordConfirmation: "" }}
                validationSchema={Yup.object().shape({
                  password: Yup.string().required("Password is required"),
                  passwordConfirmation: Yup.string()
                    .required()
                    .oneOf([Yup.ref("password"), null], "Passwords must match")
                })}
                onSubmit={async values => {
                  await createUser(
                    {
                      email: qUserProfile.data.email,
                      password: values.password
                    },
                    {
                      onSuccess() {
                        window.location.reload();
                      },
                      onError() {
                        history.replace("/logout");
                      }
                    }
                  );
                }}
              >
                <WSFormOld.Field
                  key="password"
                  mb="XL"
                  name="password"
                  component={TextField}
                  componentProps={{
                    type: "password",
                    label: "Password"
                  }}
                />

                <WSFormOld.Field
                  key="passwordConfirmation"
                  mb="XL"
                  name="passwordConfirmation"
                  component={TextField}
                  componentProps={{
                    type: "password",
                    label: "Password Confirmation"
                  }}
                />

                <WSErrorMessage
                  my="XL"
                  forceShowApiErrors
                  contextKey="Auth"
                  error={createUserMeta.error}
                />

                <WSFlexBox.Center direction="column">
                  <WSButton
                    fullWidth
                    type="submit"
                    loading={createUserMeta.isLoading}
                  >
                    Continue
                  </WSButton>
                </WSFlexBox.Center>
              </WSFormOld>
            </>
          )}
        </WSQueries>
      </WSCentered>
    </OnboardingLayout>
  );
};
