import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import React from "react";
import { useUserId } from "../../../query/hooks/helpers";
import { usePayoutSettings } from "../../../query/payments/queries";
import { selectorInstantPayoutDefaultFee } from "../../selectors/selectorInstantPayoutDefaultFee";

type Props = {
  onClose: () => void;
};

export const ModalInstantPayoutInfo: React.FC<Props> = ({ onClose }) => {
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);

  return (
    <>
      <WSText.Heading5 mb="M">
        Connect your debit card for instant payouts
      </WSText.Heading5>
      <WSText mb="XL">
        Instantly receive funds to a supported debit card as soon as the funds
        are received. Income typically appear in the associated bank account
        within 30 minutes.
      </WSText>
      <WSText mb="XL">
        Instant payouts cost{" "}
        {selectorInstantPayoutDefaultFee(queryPayoutSettings.data)}% of the
        payout amount, with a minimum fee of $0.50.
      </WSText>
      <WSText>
        Most U.S. banks issue debit cards are compatible with Instant Payouts.
      </WSText>

      <WSButton.Link mt="XL" onClick={onClose} icon="arrow-left">
        Get back
      </WSButton.Link>
    </>
  );
};
