import { useCallback } from "react";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { LayoutSetup } from "../../shared/components/LayoutSetup";
import { FormTransfer } from "./components/FormTransfer";
import { FormType } from "./components/FormType";
import { useSearchParams } from "./useSearchParams";

export const Transfer: React.FC<RouteComponentProps> = ({
  location: { pathname, search }
}) => {
  const history = useHistory();

  const { balance, type } = useSearchParams();

  const onBack = useCallback(() => {
    if (balance === "wallet") {
      history.push("/member/wallet");
    } else {
      history.push("/member/taxes");
    }
  }, [balance, history]);

  if (!balance) {
    return <Redirect to="/member/dashboard" />;
  }

  return (
    <LayoutSetup wide onBack={onBack}>
      {type ? (
        <FormTransfer
          balance={balance}
          type={type}
          onCancel={onBack}
          onSuccess={onBack}
        />
      ) : (
        <FormType
          balance={balance}
          onDeposit={() => {
            history.push(pathname + search + "&type=deposit");
          }}
          onWithdrawal={() => {
            history.push(pathname + search + "&type=withdrawal");
          }}
        />
      )}
    </LayoutSetup>
  );
};
