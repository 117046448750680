import { ReactQueryMutationsConfig } from "react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation } from "../../helpers";
import { resendUserInvite } from "../../../services/user";
import { useCaptcha } from "../../../components/Captcha";
import { IResendInvitationsResponse } from "@wingspanhq/users/dist/lib/interfaces/api/invitation";
import { IResendInvitationsRequest } from "@wingspanhq/users/dist/lib/interfaces";

export const useResendUserInvite = (
  config?: ReactQueryMutationsConfig<IResendInvitationsResponse, WSServiceError>
) => {
  const callWithCaptcha = useCaptcha("resendUserInvite");

  return useWSMutation<
    IResendInvitationsResponse,
    WSServiceError,
    IResendInvitationsRequest
  >(
    payload =>
      callWithCaptcha((captchaToken, captchaVersion, forceCaptcha) =>
        resendUserInvite({
          captchaToken,
          captchaVersion,
          ...payload
        })
      ),
    {
      ...config
    }
  );
};
