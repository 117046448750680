import {
  WSButton,
  WSButtons,
  WSElement,
  WSMessageBox,
  WSText,
  useWSModal
} from "@wingspanhq/fe-component-library";
import { TextBulleted } from "../../../../shared/components/TextBulleted";

interface VoidOriginalTaxFormWarningModalProps {
  onContinue(): void;
  onClose(): void;
}

const PayerForceSubmitTaxFormWarningModal: React.FC<VoidOriginalTaxFormWarningModalProps> = ({
  onContinue,
  onClose
}) => {
  return (
    <WSElement>
      <WSMessageBox.Warning
        noBorder
        icon="alert"
        title="Submit correction without change to tax classification"
        mb="M"
      >
        <WSText.Paragraph color="gray700" mb="M">
          The recipient classification is not eligible for a 1099 according to
          IRS guidelines. Please verify information request is accurate before
          proceeding.
        </WSText.Paragraph>

        <WSText.Paragraph color="gray700" mb="M">
          You are choosing to submit with the following option:
          <TextBulleted ml="S" color="gray500">
            • Continue to submit a correction anyway with the change to an
            ineligible tax classification
          </TextBulleted>
        </WSText.Paragraph>
      </WSMessageBox.Warning>

      <WSButtons forceFullWidth>
        <WSButton.Primary onClick={onContinue}>
          Continue to review and submit
        </WSButton.Primary>
        <WSButton.Secondary onClick={onClose}>Back</WSButton.Secondary>
      </WSButtons>
    </WSElement>
  );
};

export const usePayerForceSubmitTaxFormWarningModal = () =>
  useWSModal(PayerForceSubmitTaxFormWarningModal, {
    title: "Are you sure?",
    size: "S"
  });
