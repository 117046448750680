import {
  WSButton,
  WSButtons,
  WSAvatar,
  WSContainer,
  WSFlexBox,
  WSCentered,
  WSIcon,
  WSPanel,
  WSText,
  WSTodoList
} from "@wingspanhq/fe-component-library";
import React from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import styles from "./styles.module.scss";
import { track } from "../../../utils/analytics";
import { useCreateBulkCollaboratorBatch } from "../../../modules/BulkImporter/query/bulkCollaborator/mutations";

export interface BulkUploadCollaboratorsStep1Props {}

export interface UploadCollaborator {
  email: string;
  contractorId?: string;
  firstName: string;
  lastName: string;

  name?: string;
  businessName?: string;
  companyStructure?: string;
  ssn?: string;
  ein?: string;

  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
}

export const BulkUploadCollaboratorsStep1: React.FC<BulkUploadCollaboratorsStep1Props> = () => {
  useBrowserPageTitle("Wingspan - Bulk import contractors");
  const history = useHistory();
  const qFeatureFlags = useFeatureFlags();

  const [
    createBulkCollaboratorBatch,
    createBulkCollaboratorBatchMeta
  ] = useCreateBulkCollaboratorBatch();

  const downloadExampleCollaborators = [
    {
      firstName: "Wade",
      lastName: "Warren",
      email: "wade@wingspan.app",
      phoneNumber: "",
      contractorId: ""
    }
  ];
  const csvProps = {
    filename: `Wingspan_Bulk_Upload_Contractors_Template.csv`,
    headers: [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Email", key: "email" },
      { label: "Phone number", key: "phoneNumber" },
      { label: "External Contractor ID", key: "contractorId" }
    ],
    data: downloadExampleCollaborators
  };

  const title = qFeatureFlags.data?.bulkImporterCollaborator
    ? "Import contractors with a spreadsheet"
    : "Bulk upload contractors with a spreadsheet";

  const initiateBulkUpload = async () => {
    if (qFeatureFlags.data?.bulkImporterCollaborator) {
      await createBulkCollaboratorBatch(
        {},
        {
          onSuccess: response => {
            track("Contractors Bulk Upload Started");
            history.push(
              `/member/invoices/contacts/collaborators/bulk-create/${response.bulkCollaboratorBatchId}/upload`
            );
          }
        }
      );
    } else {
      history.push(
        "/member/invoices/contacts/collaborators/bulk-create/upload"
      );
    }
  };

  return (
    <ProjectOnboardingLayout progress={(100 / 3) * 1}>
      <WSContainer verticalPadding>
        <WSCentered span={{ s: "6", m: "8" }}>
          {qFeatureFlags.data?.bulkImporterCollaborator ? null : (
            <WSText.ParagraphSm color="gray500" mt="M" mb="XL" pt="M">
              1 of 3
            </WSText.ParagraphSm>
          )}
          <WSText.Heading4 mb="M">{title}</WSText.Heading4>
          <WSPanel>
            <WSTodoList
              mb="XL"
              items={[
                {
                  mark: "1.",
                  content: (
                    <>
                      <WSText.ParagraphSm weight="medium">
                        Upload a spreadsheet of contractors
                      </WSText.ParagraphSm>
                      <WSText.ParagraphSm mt="XS">
                        Upload a CSV or Excel spreadsheet of your contractors.
                      </WSText.ParagraphSm>
                    </>
                  )
                },
                {
                  mark: "2.",
                  content: (
                    <>
                      <WSText.ParagraphSm weight="medium">
                        Confirm contractors information
                      </WSText.ParagraphSm>
                      <WSText.ParagraphSm mt="XS">
                        Review your imported contractors to ensure their
                        information is correct.
                      </WSText.ParagraphSm>
                    </>
                  )
                },
                {
                  mark: "3.",
                  content: (
                    <>
                      <WSText.ParagraphSm weight="medium">
                        Invite contractors
                      </WSText.ParagraphSm>
                      <WSText.ParagraphSm mt="XS">
                        New contractors will be automatically invited to
                        Wingspan.
                      </WSText.ParagraphSm>
                    </>
                  )
                }
              ]}
            />
            <WSButtons>
              <CSVLink
                {...csvProps}
                className={styles.csvLink}
                onClick={initiateBulkUpload}
              >
                <WSButton.Secondary
                  fullWidth
                  icon="download"
                  loading={createBulkCollaboratorBatchMeta.isLoading}
                >
                  Download Example
                </WSButton.Secondary>
              </CSVLink>
              <WSButton
                className={styles.nextBtn}
                name="bulkImportCollaboratorsStep1"
                fullWidth
                onClick={initiateBulkUpload}
                loading={createBulkCollaboratorBatchMeta.isLoading}
              >
                Upload contractors
              </WSButton>
            </WSButtons>

            {qFeatureFlags.data?.bulkImporterCollaborator ? (
              <WSPanel
                mt="XL"
                onClick={() => history.push("/member/imports/collaborators")}
              >
                <WSFlexBox.CenterY justify="space-between">
                  <WSFlexBox.CenterY>
                    <WSAvatar.Icon
                      icon="details"
                      color="blue500"
                      colorBackground="blue50"
                      size="M"
                      mr="M"
                    />
                    <WSText weight="medium">See imports</WSText>
                  </WSFlexBox.CenterY>

                  <WSIcon block name="chevron-right" color="gray500" size="M" />
                </WSFlexBox.CenterY>
              </WSPanel>
            ) : null}
          </WSPanel>
        </WSCentered>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
