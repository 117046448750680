import { useWSModal } from "@wingspanhq/fe-component-library";
import { W9Certify } from "./index";

export const useModalFormW9Certify = () =>
  useWSModal(W9Certify,
    {
      title: "Certify W-9 information",
      size: "S"
    }
  );
