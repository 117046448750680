import {
  useWSSnackbar,
  WSContainer,
  WSFlexBox,
  WSLayout,
  WSText
} from "@wingspanhq/fe-component-library";
import * as React from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { Header } from "../../Auth/components/Header/Header";
import { useCreateUser } from "../../query/users/mutations";

export const SignUpCheckEmail: React.FC<{}> = () => {
  const history = useHistory();
  const location = useLocation<{ email: string; growthSource?: string }>();
  const { openSnackbar } = useWSSnackbar();
  const [createUser, createUserMeta] = useCreateUser();
  const email = location.state?.email;

  const backLocation = {
    pathname: `/member/sign-up`,
    search: location.search,
    state: location.state
  };
  const onBack = () => {
    history.push(backLocation);
  };

  if (!email) {
    return <Redirect to={backLocation} />;
  }

  return (
    <WSLayout line onBack={onBack} headerCenter={<Header hideBorder />}>
      <BrowserPageTitle title="Verification email" />
      <WSContainer verticalPadding>
        <WSFlexBox.Center my="3XL" direction="column">
          <WSText>
            To continue the{" "}
            <WSText inline color="blue400" onClick={onBack}>
              Sign Up
            </WSText>{" "}
            process, please click on the link in the email that we’ve sent to{" "}
            {email}.
          </WSText>
          <WSText mt="2XL">
            Didn't receive the email?{" "}
            <WSText
              inline
              color="blue400"
              onClick={async () => {
                await createUser(
                  {
                    email
                  },
                  {
                    onError(error) {
                      if (
                        error.response?.status === 401 &&
                        error.response?.data?.errorSubType ===
                          "EmailVerificationRequired"
                      ) {
                        openSnackbar({
                          message: "Email successfully resent!",
                          duration: 3000,
                          type: "success"
                        });
                      } else {
                        history.replace("/member/sign-in");
                      }
                    }
                  }
                );
              }}
            >
              Click to resend
            </WSText>
          </WSText>
        </WSFlexBox.Center>
      </WSContainer>
    </WSLayout>
  );
};
