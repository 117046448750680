import { IInvoice, InvoiceStatus } from "@wingspanhq/payments/dist/interfaces";
import { IInvoiceRow } from "../../modules/Invoicing/service";

export const selectorIsInvoiceDeposited = (invoice: IInvoice) => {
  if (invoice.status === InvoiceStatus.Paid) {
    if (invoice.amountDetails) {
      return !!invoice.events.depositedAt || !!invoice.events.instantPayoutAt;
    }

    return true;
  }

  return false;
};
