import {
  WSButton,
  WSButtons,
  WSAvatar,
  WSDropFileInput,
  WSElement,
  WSFlexBox,
  WSRadioInputGroup,
  WSIcon,
  WSText,
  useWSModal,
  WSDivider,
  useWSSnackbar
} from "@wingspanhq/fe-component-library";
import { useRef, useState } from "react";

import styles from "./styles.module.scss";
import {
  DOCUMENT_VISIBILITY_OPTIONS,
  DocumentVisibility,
  UPLOAD_DOCUMENTS_ALLOWED_FORMATS
} from "./constants";
import { useMemberPrivateFileCreate } from "../../../../query/files/mutations";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";

interface UploadDocumentModalProps {
  onClose(): void;
  payerId: string;
  payeeId: string;
}

const UploadDocumentModal: React.FC<UploadDocumentModalProps> = ({
  onClose,
  payerId,
  payeeId
}) => {
  const [fileToUpload, setFileToUpload] = useState<File>();
  const { openSnackbar } = useWSSnackbar();
  const [documentVisibility, setDocumentVisibility] = useState<string>(
    DOCUMENT_VISIBILITY_OPTIONS[0].value
  );
  const uploadContainerElement = useRef<HTMLElement>(null);

  const [uploadFile, uploadFileMeta] = useMemberPrivateFileCreate({
    hidden: true,
    tags: [`payee:${payeeId}`, `payer:${payerId}`],
    ...(documentVisibility === DocumentVisibility.Viewer
      ? { viewerIds: [payeeId] }
      : {})
  });

  const onUpload = async () => {
    if (fileToUpload) {
      const data = new FormData();
      data.append("file", fileToUpload);
      await uploadFile(data, {
        onSuccess: () => {
          setFileToUpload(undefined);
          openSnackbar({
            message: "File uploaded successfully",
            type: "success"
          });
          onClose();
        }
      });
    }
  };

  return (
    <WSElement>
      <WSDivider mb="S" />
      <WSText.ParagraphSm color="gray500" mb="XS">
        Upload a new document
      </WSText.ParagraphSm>

      <WSElement mb="XL" ref={uploadContainerElement}>
        <WSDropFileInput
          name="test"
          buttonText="Browse files"
          onDropAccepted={(accepted: File[]) => {
            setFileToUpload(accepted[0]);
          }}
          accept={UPLOAD_DOCUMENTS_ALLOWED_FORMATS.value}
        />
        <WSText.ParagraphSm mb="M" color="gray600">
          Only {UPLOAD_DOCUMENTS_ALLOWED_FORMATS.label} supported. File limit of
          100MB per file.
        </WSText.ParagraphSm>

        {fileToUpload && (
          <>
            <WSElement mb="M" p="S" className={styles.selectedFile}>
              <WSFlexBox.CenterY wrap="nowrap">
                <WSAvatar.Icon
                  icon="paperclip"
                  mr="M"
                  color="green500"
                  colorBackground="green50"
                />
                <WSText className={styles.selectedFileName} singleLine>
                  {fileToUpload.name}
                </WSText>
                <WSIcon
                  block
                  color="gray500"
                  size="XS"
                  mx="M"
                  name="exit"
                  onClick={() => {
                    setFileToUpload(undefined);
                  }}
                />
              </WSFlexBox.CenterY>
            </WSElement>

            <WSText.ParagraphSm mb="XS" color="gray500">
              Document visibility *
            </WSText.ParagraphSm>
            <WSRadioInputGroup
              name="documentVisibility"
              options={DOCUMENT_VISIBILITY_OPTIONS}
              value={documentVisibility}
              onChange={(e: any) => setDocumentVisibility(e.target.value)}
            />
          </>
        )}
      </WSElement>

      <WSErrorMessage
        contextKey={ErrorContextKey.UploadDocument}
        error={uploadFileMeta.error}
        mb="M"
      />
      <WSButtons format="modal">
        <WSButton.Primary
          loading={uploadFileMeta.isLoading}
          disabled={!fileToUpload}
          onClick={onUpload}
        >
          Upload
        </WSButton.Primary>
        <WSButton.Tertiary onClick={onClose}>Cancel</WSButton.Tertiary>
      </WSButtons>
    </WSElement>
  );
};

export const useUploadDocumentModal = () =>
  useWSModal(UploadDocumentModal, {
    title: "Upload document",
    size: "S"
  });
