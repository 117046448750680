import React, { useMemo } from "react";
import { WingspanIsPCICertifiedSecure } from "../../../ClientPayments/components/ClientPaymentsWrapper/ClientPaymentsWrapper";
import { WSQueries } from "../../../query/WSQuery";
import { useCollaboratorQuery } from "../../../query/payments/queries";
import {
  getCollaboratorCompany,
  getCollaboratorName
} from "../../../query/payments/selectors";
import { useUserTag } from "../../../query/users/queries";
import {
  LayoutSetup,
  LayoutSetupProps
} from "../../../shared/components/LayoutSetup";
import { IBankTransferProcessingAccount } from "@wingspanhq/payments/dist/interfaces";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { usePayeeQuery } from "../../../query/payee/queries/usePayeeQuery";

type SetupAutopayLayoutProps = Omit<LayoutSetupProps, "title"> & {
  collaboratorId?: string;
  payeeId?: string;
  title?(companyName: string): string;
  children(props: {
    companyName: string;
    domesticBankAccount: IBankTransferProcessingAccount;
    internationalBankAccount: IBankTransferProcessingAccount;
  }): React.ReactNode;
};

export const SetupAutopayLayout: React.FC<SetupAutopayLayoutProps> = ({
  collaboratorId,
  payeeId,
  title,
  children,
  ...props
}) => {
  const collaboratorQuery = useCollaboratorQuery(collaboratorId ?? "");
  const payeeQuery = usePayeeQuery(payeeId as string, {
    enabled: !!payeeId
  });
  const userTagQuery = useUserTag(
    collaboratorQuery.data?.member?.user.tag ||
      // payeeQuery.data?.user?.tag ||
      "",
    {
      enabled: !!collaboratorQuery.data?.member?.user.tag
      // || !!payeeQuery.data?.user?.tag
    }
  );

  const logoUrl = userTagQuery.data?.member?.profile?.logoUrl;

  const companyName = useMemo(() => {
    if (collaboratorQuery.data) {
      return (
        getCollaboratorCompany(collaboratorQuery.data) ||
        getCollaboratorName(collaboratorQuery.data)
      );
    }
    if (payeeQuery.data) {
      const payeeInfo = wsName({
        user: {
          email: payeeQuery.data?.user?.email as string,
          profile: payeeQuery.data?.user?.profile
        },
        member: payeeQuery.data?.member
      });
      console.log("payeeInfo", payeeInfo);
      return (
        payeeInfo.companyName ||
        payeeInfo.legalBusinessName ||
        payeeInfo.getResolvedName()
      );
    }
    return "";
  }, [payeeQuery.data, collaboratorQuery.data]);

  const domesticBankAccount: IBankTransferProcessingAccount = {
    ...(((collaboratorQuery.data?.achCreditAccount ??
      payeeQuery.data
        ?.bankTransferProcessingAccount) as unknown) as IBankTransferProcessingAccount)
  };

  const internationalBankAccount = {
    ...(((collaboratorQuery.data?.internationalWireAccount ??
      payeeQuery.data
        ?.internationalBankTransferProcessingAccount) as unknown) as IBankTransferProcessingAccount)
  };

  console.log("companyName", companyName);

  return (
    <>
      <LayoutSetup
        customLogo={logoUrl}
        banner={
          <WSQueries
            queries={{
              ...(collaboratorId && { collaboratorQuery }),
              ...(payeeId && { payeeQuery })
            }}
          >
            {() => <WingspanIsPCICertifiedSecure name={companyName} />}
          </WSQueries>
        }
        title={title?.(companyName)}
        {...props}
      >
        <WSQueries
          queries={{
            ...(collaboratorId && { collaboratorQuery }),
            ...(payeeId && { payeeQuery })
          }}
        >
          {() =>
            children({
              companyName,
              domesticBankAccount,
              internationalBankAccount
            })
          }
        </WSQueries>
      </LayoutSetup>
    </>
  );
};
