import React, { useEffect } from "react";
import {
  WSAlert,
  WSControlGroup,
  WSForm,
  WSInputTextarea,
  WSList
} from "@wingspanhq/fe-component-library";
import { useFormContext } from "react-hook-form";
import { CreateRequirementDefinitionSharedFileMimeType } from "../types";
import { MIME_TYPES_LABELS } from "./utils";

export const CreateSharedFilePartialForm: React.FC = () => {
  const { register } = useFormContext();

  useEffect(() => {
    register("instructions");
    register("mimeTypes");
  }, []);

  return (
    <WSList gap="XL">
      <WSAlert theme="info" title="About file upload requirements">
        Contractor(s) assigned with this requirement will be asked to upload the
        file(s) defined here.
      </WSAlert>

      <WSForm.Field
        name="instructions"
        label="Instructions to contractor"
        placeholder="Include any instructions or guidelines for the contractor about uploading the required file(s) here (optional)."
        component={WSInputTextarea}
      />

      <WSForm.Field
        name="mimeTypes"
        label="Specify accepted file format(s)"
        component={WSControlGroup}
        componentProps={{
          required: true,
          helperText: "(Max 100MBs per file)",
          type: "switch",
          options: [
            // {
            //   label:
            //     MIME_TYPES_LABELS[
            //       CreateRequirementDefinitionSharedFileMimeType.All
            //     ],
            //   value: CreateRequirementDefinitionSharedFileMimeType.All
            // },
            {
              label:
                MIME_TYPES_LABELS[
                  CreateRequirementDefinitionSharedFileMimeType.Document
                ],
              value: CreateRequirementDefinitionSharedFileMimeType.Document
            },
            {
              label:
                MIME_TYPES_LABELS[
                  CreateRequirementDefinitionSharedFileMimeType.Image
                ],
              value: CreateRequirementDefinitionSharedFileMimeType.Image
            },
            {
              label:
                MIME_TYPES_LABELS[
                  CreateRequirementDefinitionSharedFileMimeType.Other
                ],
              value: CreateRequirementDefinitionSharedFileMimeType.Other
            }
          ]
        }}
      />
    </WSList>
  );
};
