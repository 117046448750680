import {
  useWSModal,
  WSActions,
  WSAlert,
  WSInfoBox,
  WSList
} from "@wingspanhq/fe-component-library";

export interface CardReplacementRequestedProps {
  onClose: () => void;
}

const CardReplacementRequested: React.FC<CardReplacementRequestedProps> = ({
  onClose
}) => {
  return (
    <WSList gap="L">
      <WSAlert theme="success">
        Your card is on it's way! Expected delivery: 7-10 business days.
      </WSAlert>
      <WSInfoBox>
        Once you’ve received the card in the mail, come back to your Wingspan
        Wallet page to activate the card.
      </WSInfoBox>
      <WSActions
        buttons={[
          {
            label: "Continue",
            onClick: async () => {
              onClose();
            },
            kind: "Primary"
          }
        ]}
      ></WSActions>
    </WSList>
  );
};

export function useCardReplacementRequestedModal() {
  return useWSModal(CardReplacementRequested, {
    title: "Card suspended",
    size: "S"
  });
}
