import AcceptIcon from "../../../assets/icons/functional/accept.svg";
import AlarmIcon from "../../../assets/icons/functional/alarm.svg";
import AlertCircleFillIcon from "../../../assets/icons/functional/alert-circle-fill.svg";
import AlertCircleIcon from "../../../assets/icons/functional/alert-circle.svg";
import AlertFillIcon from "../../../assets/icons/functional/alert-fill.svg";
import AlertIcon from "../../../assets/icons/functional/alert.svg";
import ArrowDownIcon from "../../../assets/icons/functional/arrow-down.svg";
import ArrowLeftIcon from "../../../assets/icons/functional/arrow-left.svg";
import ArrowRightIcon from "../../../assets/icons/functional/arrow-right.svg";
import ArrowUpIcon from "../../../assets/icons/functional/arrow-up.svg";
import BellIcon from "../../../assets/icons/functional/bell.svg";
import BlockIcon from "../../../assets/icons/functional/block.svg";
import BotIcon from "../../../assets/icons/functional/bot.svg";
import CalculatorIcon from "../../../assets/icons/functional/calculator.svg";
import CalendarCheckFillIcon from "../../../assets/icons/functional/calendar-check-fill.svg";
import CalendarCheckIcon from "../../../assets/icons/functional/calendar-check.svg";
import CalendarIcon from "../../../assets/icons/functional/calendar.svg";
import CaretDownIcon from "../../../assets/icons/functional/caret-down.svg";
import CaretLeftIcon from "../../../assets/icons/functional/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/functional/caret-right.svg";
import CaretUpIcon from "../../../assets/icons/functional/caret-up.svg";
import CashIcon from "../../../assets/icons/functional/cash.svg";
import CheckCircleFillIcon from "../../../assets/icons/functional/check-circle-fill.svg";
import CheckCircleIcon from "../../../assets/icons/functional/check-circle.svg";
import CheckDoubleIcon from "../../../assets/icons/functional/check-double.svg";
import CheckIcon from "../../../assets/icons/functional/check.svg";
import ChevronDownIcon from "../../../assets/icons/functional/chevron-down.svg";
import ChevronLeftIcon from "../../../assets/icons/functional/chevron-left.svg";
import ChevronRightIcon from "../../../assets/icons/functional/chevron-right.svg";
import ChevronUpIcon from "../../../assets/icons/functional/chevron-up.svg";
import ChevronsDownIcon from "../../../assets/icons/functional/chevrons-down.svg";
import ChevronsLeftIcon from "../../../assets/icons/functional/chevrons-left.svg";
import ChevronsRightIcon from "../../../assets/icons/functional/chevrons-right.svg";
import ChevronsUpIcon from "../../../assets/icons/functional/chevrons-up.svg";
import CommentIcon from "../../../assets/icons/functional/comment.svg";
import CopyFillIcon from "../../../assets/icons/functional/copy-fill.svg";
import CopyIcon from "../../../assets/icons/functional/copy.svg";
import DisputeIcon from "../../../assets/icons/functional/dispute.svg";
import DotsHIcon from "../../../assets/icons/functional/dots-h.svg";
import DotsVIcon from "../../../assets/icons/functional/dots-v.svg";
import DownloadIcon from "../../../assets/icons/functional/download.svg";
import DraftIcon from "../../../assets/icons/functional/draft.svg";
import EditBoxIcon from "../../../assets/icons/functional/edit-box.svg";
import EditLineIcon from "../../../assets/icons/functional/edit-line.svg";
import EditIcon from "../../../assets/icons/functional/edit.svg";
import EnvelopeOpenIcon from "../../../assets/icons/functional/envelope-open.svg";
import EnvelopeIcon from "../../../assets/icons/functional/envelope.svg";
import ExitCircleFillIcon from "../../../assets/icons/functional/exit-circle-fill.svg";
import ExitCircleIcon from "../../../assets/icons/functional/exit-circle.svg";
import ExitIcon from "../../../assets/icons/functional/exit.svg";
import FileSearchIcon from "../../../assets/icons/functional/file-search.svg";
import FileIcon from "../../../assets/icons/functional/file.svg";
import FilterIcon from "../../../assets/icons/functional/filter.svg";
import GroupFillIcon from "../../../assets/icons/functional/group-fill.svg";
import GroupIcon from "../../../assets/icons/functional/group.svg";
import HelpCircleFillIcon from "../../../assets/icons/functional/help-circle-fill.svg";
import HelpCircleIcon from "../../../assets/icons/functional/help-circle.svg";
import HideIcon from "../../../assets/icons/functional/hide.svg";
import InfoCircleFillIcon from "../../../assets/icons/functional/info-circle-fill.svg";
import InfoCircleIcon from "../../../assets/icons/functional/info-circle.svg";
import InstantCircleFillIcon from "../../../assets/icons/functional/instant-circle-fill.svg";
import InstantCircleIcon from "../../../assets/icons/functional/instant-circle.svg";
import InstantIcon from "../../../assets/icons/functional/instant.svg";
import LinkIcon from "../../../assets/icons/functional/link.svg";
import LoaderIcon from "../../../assets/icons/functional/loader.svg";
import LockIcon from "../../../assets/icons/functional/lock.svg";
import MenuIcon from "../../../assets/icons/functional/menu.svg";
import MessageIcon from "../../../assets/icons/functional/message.svg";
import MinusCircleIcon from "../../../assets/icons/functional/minus-circle.svg";
import MinusIcon from "../../../assets/icons/functional/minus.svg";
import OpenTabIcon from "../../../assets/icons/functional/open-tab.svg";
import PauseIcon from "../../../assets/icons/functional/pause.svg";
import PlusCircleIcon from "../../../assets/icons/functional/plus-circle.svg";
import PlusIcon from "../../../assets/icons/functional/plus.svg";
import RedoIcon from "../../../assets/icons/functional/redo.svg";
import RefreshHIcon from "../../../assets/icons/functional/refresh-h.svg";
import RefreshVIcon from "../../../assets/icons/functional/refresh-v.svg";
import RepeatCircleIcon from "../../../assets/icons/functional/repeat-circle.svg";
import RepeatIcon from "../../../assets/icons/functional/repeat.svg";
import ResetIcon from "../../../assets/icons/functional/reset.svg";
import SaveIcon from "../../../assets/icons/functional/save.svg";
import SearchIcon from "../../../assets/icons/functional/search.svg";
import SendIcon from "../../../assets/icons/functional/send.svg";
import SortAZIcon from "../../../assets/icons/functional/sort-a-z.svg";
import SortDownIcon from "../../../assets/icons/functional/sort-down.svg";
import SortUpIcon from "../../../assets/icons/functional/sort-up.svg";
import SortZAIcon from "../../../assets/icons/functional/sort-z-a.svg";
import SortIcon from "../../../assets/icons/functional/sort.svg";
import StarFillIcon from "../../../assets/icons/functional/star-fill.svg";
import StarIcon from "../../../assets/icons/functional/star.svg";
import TimeFiveIcon from "../../../assets/icons/functional/time-five.svg";
import TimeIcon from "../../../assets/icons/functional/time.svg";
import TransferIcon from "../../../assets/icons/functional/transfer.svg";
import TrashFillIcon from "../../../assets/icons/functional/trash-fill.svg";
import TrashIcon from "../../../assets/icons/functional/trash.svg";
import UndoIcon from "../../../assets/icons/functional/undo.svg";
import UnlinkIcon from "../../../assets/icons/functional/unlink.svg";
import UnlockIcon from "../../../assets/icons/functional/unlock.svg";
import UploadIcon from "../../../assets/icons/functional/upload.svg";
import UserCheckIcon from "../../../assets/icons/functional/user-check.svg";
import UserFillIcon from "../../../assets/icons/functional/user-fill.svg";
import UserIcon from "../../../assets/icons/functional/user.svg";
import ViewIcon from "../../../assets/icons/functional/view.svg";
import BankIcon from "../../../assets/icons/navigation/bank.svg";
import CardIcon from "../../../assets/icons/navigation/card.svg";
import CompassIcon from "../../../assets/icons/navigation/compass.svg";
import DashboardIcon from "../../../assets/icons/navigation/dashboard.svg";
import DataIcon from "../../../assets/icons/navigation/data.svg";
import HeartIcon from "../../../assets/icons/navigation/heart.svg";
import HomeIcon from "../../../assets/icons/navigation/home.svg";
import InIcon from "../../../assets/icons/navigation/in.svg";
import LaunchFillIcon from "../../../assets/icons/navigation/launch-fill.svg";
import LaunchIcon from "../../../assets/icons/navigation/launch.svg";
import LogoutIcon from "../../../assets/icons/navigation/logout.svg";
import OutIcon from "../../../assets/icons/navigation/out.svg";
import ReportIcon from "../../../assets/icons/navigation/report.svg";
import SettingsFillIcon from "../../../assets/icons/navigation/settings-fill.svg";
import SettingsIcon from "../../../assets/icons/navigation/settings.svg";
import StartIcon from "../../../assets/icons/navigation/start.svg";
import SupportIcon from "../../../assets/icons/navigation/support.svg";
import TaxesIcon from "../../../assets/icons/navigation/taxes.svg";
import UserAccountIcon from "../../../assets/icons/navigation/user-account.svg";
import WalletFillIcon from "../../../assets/icons/navigation/wallet-fill.svg";
import WalletIcon from "../../../assets/icons/navigation/wallet.svg";
import AfterIcon from "../../../assets/icons/secondary/after.svg";
import ArchiveIcon from "../../../assets/icons/secondary/archive.svg";
import BadgeOffIcon from "../../../assets/icons/secondary/badge-off.svg";
import BadgeIcon from "../../../assets/icons/secondary/badge.svg";
import BeforeIcon from "../../../assets/icons/secondary/before.svg";
import BlocksIcon from "../../../assets/icons/secondary/blocks.svg";
import BookmarkFillIcon from "../../../assets/icons/secondary/bookmark-fill.svg";
import BookmarkIcon from "../../../assets/icons/secondary/bookmark.svg";
import BriefcaseIcon from "../../../assets/icons/secondary/briefcase.svg";
import BuildingIcon from "../../../assets/icons/secondary/building.svg";
import BulbFillIcon from "../../../assets/icons/secondary/bulb-fill.svg";
import BulbIcon from "../../../assets/icons/secondary/bulb.svg";
import CarIcon from "../../../assets/icons/secondary/car.svg";
import ChartIcon from "../../../assets/icons/secondary/chart.svg";
import ChatIcon from "../../../assets/icons/secondary/chat.svg";
import CheckEnvelopeIcon from "../../../assets/icons/secondary/check-envelope.svg";
import CheckboxIcon from "../../../assets/icons/secondary/checkbox.svg";
import ClipboardCheckIcon from "../../../assets/icons/secondary/clipboard-check.svg";
import ClipboardIcon from "../../../assets/icons/secondary/clipboard.svg";
import CodeIcon from "../../../assets/icons/secondary/code.svg";
import CollectionIcon from "../../../assets/icons/secondary/collection.svg";
import CubeFillIcon from "../../../assets/icons/secondary/cube-fill.svg";
import CubeIcon from "../../../assets/icons/secondary/cube.svg";
import CustomizeIcon from "../../../assets/icons/secondary/customize.svg";
import DesktopIcon from "../../../assets/icons/secondary/desktop.svg";
import DetailsIcon from "../../../assets/icons/secondary/details.svg";
import DiningIcon from "../../../assets/icons/secondary/dining.svg";
import DollarCircleIcon from "../../../assets/icons/secondary/dollar-circle.svg";
import DollarIcon from "../../../assets/icons/secondary/dollar.svg";
import ExchangeIcon from "../../../assets/icons/secondary/exchange.svg";
import FolderOpenIcon from "../../../assets/icons/secondary/folder-open.svg";
import FolderIcon from "../../../assets/icons/secondary/folder.svg";
import GiftIcon from "../../../assets/icons/secondary/gift.svg";
import GlobeIcon from "../../../assets/icons/secondary/globe.svg";
import GraphIcon from "../../../assets/icons/secondary/graph.svg";
import GridLargeIcon from "../../../assets/icons/secondary/grid-large.svg";
import GridIcon from "../../../assets/icons/secondary/grid.svg";
import HealthIcon from "../../../assets/icons/secondary/health.svg";
import HomeHeartIcon from "../../../assets/icons/secondary/home-heart.svg";
import IdIcon from "../../../assets/icons/secondary/id.svg";
import ImageIcon from "../../../assets/icons/secondary/image.svg";
import InboxIcon from "../../../assets/icons/secondary/inbox.svg";
import KeyIcon from "../../../assets/icons/secondary/key.svg";
import LayersIcon from "../../../assets/icons/secondary/layers.svg";
import LibraryIcon from "../../../assets/icons/secondary/library.svg";
import LifeIcon from "../../../assets/icons/secondary/life.svg";
import ListFillIcon from "../../../assets/icons/secondary/list-fill.svg";
import ListIcon from "../../../assets/icons/secondary/list.svg";
import LocationIcon from "../../../assets/icons/secondary/location.svg";
import LogoIcon from "../../../assets/icons/secondary/logo.svg";
import MagicIcon from "../../../assets/icons/secondary/magic.svg";
import MapIcon from "../../../assets/icons/secondary/map.svg";
import ModulesIcon from "../../../assets/icons/secondary/modules.svg";
import NetworkIcon from "../../../assets/icons/secondary/network.svg";
import NewsIcon from "../../../assets/icons/secondary/news.svg";
import NoteIcon from "../../../assets/icons/secondary/note.svg";
import OrgIcon from "../../../assets/icons/secondary/org.svg";
import PaperclipIcon from "../../../assets/icons/secondary/paperclip.svg";
import PenFillIcon from "../../../assets/icons/secondary/pen-fill.svg";
import PenIcon from "../../../assets/icons/secondary/pen.svg";
import PercentFillIcon from "../../../assets/icons/secondary/percent-fill.svg";
import PercentIcon from "../../../assets/icons/secondary/percent.svg";
import PersonIcon from "../../../assets/icons/secondary/person.svg";
import PetIcon from "../../../assets/icons/secondary/pet.svg";
import PhoneIcon from "../../../assets/icons/secondary/phone.svg";
import PieChartIcon from "../../../assets/icons/secondary/pie-chart.svg";
import PinIcon from "../../../assets/icons/secondary/pin.svg";
import RewardIcon from "../../../assets/icons/secondary/reward.svg";
import SendMailIcon from "../../../assets/icons/secondary/send-mail.svg";
import ShareIcon from "../../../assets/icons/secondary/share.svg";
import ShieldCheckFillIcon from "../../../assets/icons/secondary/shield-check-fill.svg";
import ShieldCheckIcon from "../../../assets/icons/secondary/shield-check.svg";
import ShieldIcon from "../../../assets/icons/secondary/shield.svg";
import SkipCircleIcon from "../../../assets/icons/secondary/skip-circle.svg";
import SmileIcon from "../../../assets/icons/secondary/smile.svg";
import StatsIcon from "../../../assets/icons/secondary/stats.svg";
import TableIcon from "../../../assets/icons/secondary/table.svg";
import TagIcon from "../../../assets/icons/secondary/tag.svg";
import TrendIcon from "../../../assets/icons/secondary/trend.svg";
import WidgetsIcon from "../../../assets/icons/secondary/widgets.svg";
import WindowFillIcon from "../../../assets/icons/secondary/window-fill.svg";
import WindowIcon from "../../../assets/icons/secondary/window.svg";
import WorldIcon from "../../../assets/icons/secondary/world.svg";
import WrenchIcon from "../../../assets/icons/secondary/wrench.svg";

export const functionalIcons = {
  accept: AcceptIcon,
  alarm: AlarmIcon,
  "alert-circle-fill": AlertCircleFillIcon,
  "alert-circle": AlertCircleIcon,
  "alert-fill": AlertFillIcon,
  alert: AlertIcon,
  "arrow-down": ArrowDownIcon,
  "arrow-left": ArrowLeftIcon,
  "arrow-right": ArrowRightIcon,
  "arrow-up": ArrowUpIcon,
  bell: BellIcon,
  block: BlockIcon,
  bot: BotIcon,
  calculator: CalculatorIcon,
  "calendar-check-fill": CalendarCheckFillIcon,
  "calendar-check": CalendarCheckIcon,
  calendar: CalendarIcon,
  "caret-down": CaretDownIcon,
  "caret-left": CaretLeftIcon,
  "caret-right": CaretRightIcon,
  "caret-up": CaretUpIcon,
  cash: CashIcon,
  "check-circle-fill": CheckCircleFillIcon,
  "check-circle": CheckCircleIcon,
  "check-double": CheckDoubleIcon,
  check: CheckIcon,
  "chevron-down": ChevronDownIcon,
  "chevron-left": ChevronLeftIcon,
  "chevron-right": ChevronRightIcon,
  "chevron-up": ChevronUpIcon,
  "chevrons-down": ChevronsDownIcon,
  "chevrons-left": ChevronsLeftIcon,
  "chevrons-right": ChevronsRightIcon,
  "chevrons-up": ChevronsUpIcon,
  comment: CommentIcon,
  "copy-fill": CopyFillIcon,
  copy: CopyIcon,
  dispute: DisputeIcon,
  "dots-h": DotsHIcon,
  "dots-v": DotsVIcon,
  download: DownloadIcon,
  draft: DraftIcon,
  "edit-box": EditBoxIcon,
  "edit-line": EditLineIcon,
  edit: EditIcon,
  "envelope-open": EnvelopeOpenIcon,
  envelope: EnvelopeIcon,
  "exit-circle-fill": ExitCircleFillIcon,
  "exit-circle": ExitCircleIcon,
  exit: ExitIcon,
  "file-search": FileSearchIcon,
  file: FileIcon,
  filter: FilterIcon,
  "group-fill": GroupFillIcon,
  group: GroupIcon,
  "help-circle-fill": HelpCircleFillIcon,
  "help-circle": HelpCircleIcon,
  hide: HideIcon,
  "info-circle-fill": InfoCircleFillIcon,
  "info-circle": InfoCircleIcon,
  "instant-circle-fill": InstantCircleFillIcon,
  "instant-circle": InstantCircleIcon,
  instant: InstantIcon,
  link: LinkIcon,
  loader: LoaderIcon,
  lock: LockIcon,
  menu: MenuIcon,
  message: MessageIcon,
  "minus-circle": MinusCircleIcon,
  minus: MinusIcon,
  "open-tab": OpenTabIcon,
  pause: PauseIcon,
  "plus-circle": PlusCircleIcon,
  plus: PlusIcon,
  redo: RedoIcon,
  "refresh-h": RefreshHIcon,
  "refresh-v": RefreshVIcon,
  "repeat-circle": RepeatCircleIcon,
  repeat: RepeatIcon,
  reset: ResetIcon,
  save: SaveIcon,
  search: SearchIcon,
  send: SendIcon,
  "sort-a-z": SortAZIcon,
  "sort-down": SortDownIcon,
  "sort-up": SortUpIcon,
  "sort-z-a": SortZAIcon,
  sort: SortIcon,
  "star-fill": StarFillIcon,
  star: StarIcon,
  "time-five": TimeFiveIcon,
  time: TimeIcon,
  transfer: TransferIcon,
  "trash-fill": TrashFillIcon,
  trash: TrashIcon,
  undo: UndoIcon,
  unlink: UnlinkIcon,
  unlock: UnlockIcon,
  upload: UploadIcon,
  "user-check": UserCheckIcon,
  "user-fill": UserFillIcon,
  user: UserIcon,
  view: ViewIcon
};

export const navigationIcons = {
  bank: BankIcon,
  card: CardIcon,
  compass: CompassIcon,
  dashboard: DashboardIcon,
  data: DataIcon,
  heart: HeartIcon,
  home: HomeIcon,
  in: InIcon,
  "launch-fill": LaunchFillIcon,
  launch: LaunchIcon,
  logout: LogoutIcon,
  out: OutIcon,
  report: ReportIcon,
  "settings-fill": SettingsFillIcon,
  settings: SettingsIcon,
  start: StartIcon,
  support: SupportIcon,
  taxes: TaxesIcon,
  "user-account": UserAccountIcon,
  "wallet-fill": WalletFillIcon,
  wallet: WalletIcon
};

export const secondaryIcons = {
  after: AfterIcon,
  archive: ArchiveIcon,
  "badge-off": BadgeOffIcon,
  badge: BadgeIcon,
  before: BeforeIcon,
  blocks: BlocksIcon,
  "bookmark-fill": BookmarkFillIcon,
  bookmark: BookmarkIcon,
  briefcase: BriefcaseIcon,
  building: BuildingIcon,
  "bulb-fill": BulbFillIcon,
  bulb: BulbIcon,
  car: CarIcon,
  chart: ChartIcon,
  chat: ChatIcon,
  "check-envelope": CheckEnvelopeIcon,
  checkbox: CheckboxIcon,
  "clipboard-check": ClipboardCheckIcon,
  clipboard: ClipboardIcon,
  code: CodeIcon,
  collection: CollectionIcon,
  "cube-fill": CubeFillIcon,
  cube: CubeIcon,
  customize: CustomizeIcon,
  desktop: DesktopIcon,
  details: DetailsIcon,
  dining: DiningIcon,
  "dollar-circle": DollarCircleIcon,
  dollar: DollarIcon,
  exchange: ExchangeIcon,
  "folder-open": FolderOpenIcon,
  folder: FolderIcon,
  gift: GiftIcon,
  globe: GlobeIcon,
  graph: GraphIcon,
  "grid-large": GridLargeIcon,
  grid: GridIcon,
  health: HealthIcon,
  "home-heart": HomeHeartIcon,
  id: IdIcon,
  image: ImageIcon,
  inbox: InboxIcon,
  key: KeyIcon,
  layers: LayersIcon,
  library: LibraryIcon,
  life: LifeIcon,
  "list-fill": ListFillIcon,
  list: ListIcon,
  location: LocationIcon,
  logo: LogoIcon,
  magic: MagicIcon,
  map: MapIcon,
  modules: ModulesIcon,
  network: NetworkIcon,
  news: NewsIcon,
  note: NoteIcon,
  org: OrgIcon,
  paperclip: PaperclipIcon,
  "pen-fill": PenFillIcon,
  pen: PenIcon,
  "percent-fill": PercentFillIcon,
  percent: PercentIcon,
  person: PersonIcon,
  pet: PetIcon,
  phone: PhoneIcon,
  "pie-chart": PieChartIcon,
  pin: PinIcon,
  reward: RewardIcon,
  "send-mail": SendMailIcon,
  share: ShareIcon,
  "shield-check-fill": ShieldCheckFillIcon,
  "shield-check": ShieldCheckIcon,
  shield: ShieldIcon,
  "skip-circle": SkipCircleIcon,
  smile: SmileIcon,
  stats: StatsIcon,
  table: TableIcon,
  tag: TagIcon,
  trend: TrendIcon,
  widgets: WidgetsIcon,
  "window-fill": WindowFillIcon,
  window: WindowIcon,
  world: WorldIcon,
  wrench: WrenchIcon
};

export const icons = {
  ...functionalIcons,
  ...navigationIcons,
  ...secondaryIcons
};

export type WSIconName = keyof typeof icons;
