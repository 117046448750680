import { useDebounce } from "primereact/hooks";
import React, { useEffect } from "react";
import { WSElement } from "../WSElement/WSElement.component";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import styles from "./WSSelect.module.scss";

type Props = {
  text?: string;
  onChange: (value: string) => void;
};

export const Search: React.FC<Props> = ({ text, onChange }) => {
  const [value, debounceValue, setValue] = useDebounce(text || "", 400);

  const searchRef = React.useRef<string | null>("");

  useEffect(() => {
    if (searchRef.current === debounceValue) {
      return;
    }
    onChange?.(debounceValue);
    searchRef.current = debounceValue;
  }, [debounceValue, onChange]);

  return (
    <WSElement className={styles.search}>
      <WSIcon name="search" size="S" color="gray400" />

      <input
        type="text"
        className={styles.searchInput}
        placeholder="Search"
        autoFocus
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        autoComplete="off"
      />

      {value && (
        <WSIcon
          name="exit"
          size="S"
          color="gray400"
          onClick={() => {
            setValue("");
          }}
        />
      )}
    </WSElement>
  );
};
