import { IRequirement } from "@wingspanhq/payments/dist/interfaces";
import { RequirementEmbeddedStatus } from "@wingspanhq/payments/dist/interfaces/requirement";
import { ISharedFileRequestResponse } from "@wingspanhq/shared-files/dist/lib/interfaces";

export const selectorIsRequirementReadyToUploadFile = (
  requirement: IRequirement,
  sharedFile?: ISharedFileRequestResponse
): boolean => {
  return (
    !!sharedFile &&
    requirement.dataSourceStatus !== RequirementEmbeddedStatus.Complete
  );
};
