import {
  WSButton,
  WSElement,
  WSText,
  useWSModal
} from "@wingspanhq/fe-component-library";
import { useMemberPrivateDelete } from "../../../query/files/mutations";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../services/platform";

interface ConfirmDeleteDocumentModalProps {
  onClose(): void;
  documentId: string;
}
const ConfirmDeleteDocumentModal: React.FC<ConfirmDeleteDocumentModalProps> = ({
  documentId,
  onClose
}) => {
  const [deletePrivateFile, deletePrivateFileMeta] = useMemberPrivateDelete();

  return (
    <WSElement>
      <WSText mb="M">Once confirmed, this action can’t be undone.</WSText>

      <WSErrorMessage
        contextKey={ErrorContextKey.DeleteDocument}
        error={deletePrivateFileMeta.error}
        mb="M"
      />

      <WSButton
        destructive
        fullWidth
        loading={deletePrivateFileMeta.isLoading}
        onClick={async () => {
          await deletePrivateFile(documentId, {
            onSuccess: () => {
              onClose();
            }
          });
        }}
        name="deleteFile"
      >
        Delete
      </WSButton>
      <WSButton.Tertiary mt="M" fullWidth onClick={onClose}>
        Cancel
      </WSButton.Tertiary>
    </WSElement>
  );
};

export const useConfirmDeleteDocumentModal = () =>
  useWSModal(ConfirmDeleteDocumentModal, {
    title: "Are you sure you want to delete this file?",
    size: "S"
  });
