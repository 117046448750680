import {
  WSActions,
  WSControlGroup,
  WSControlGroupProps,
  WSElement,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { useEffect, useState } from "react";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";

export type StepOptionProps<Value> = {
  title?: string;
  description?: string;
  options: WSControlGroupProps["options"];
  onBack?: () => void;
  onContinue?: (value: Value) => void;
  error?: any;
  isLoading?: boolean;
};

export function StepOptions<Value extends string = string>({
  title,
  description,
  onBack,
  onContinue,
  options,
  error,
  isLoading
}: StepOptionProps<Value>) {
  const [value, setValue] = useState<Value>(options[0]?.value as Value);

  useEffect(() => {
    if (!value && options.length > 0) {
      setValue(options[0]?.value as Value);
    }
  }, [options, value]);

  return (
    <WSList gap="2XL">
      <WSPanel>
        <WSList gap="2XL">
          <WSElement>
            {title && <WSSectionToolbar title={title} />}
            {description && (
              <WSText.ParagraphSm color="gray500">
                {description}
              </WSText.ParagraphSm>
            )}
          </WSElement>

          <WSControlGroup
            name="type"
            type="radio"
            chip
            options={options}
            value={value}
            onChange={newValue => {
              setValue(newValue as Value);
            }}
          />

          <WSErrorMessage contextKey="Other" error={error} />
        </WSList>
      </WSPanel>

      <WSActions
        mb="2XL"
        alignment="fill"
        buttons={[
          {
            label: "Back",
            kind: "Secondary",
            onClick: onBack,
            visible: !!onBack
          },
          {
            label: "Continue",
            onClick: () => {
              onContinue?.(value);
            },
            loading: isLoading
          }
        ]}
      />
    </WSList>
  );
}
