import { WSFlexBox, WSText } from "@wingspanhq/fe-component-library";

import { FormDataAddress } from "../../../../shared/components/FormPartialAddress";
import { OriginalValuePreview } from "../OriginalValuePreview";
import { CorrectedValuePreview } from "../CorrectedValuePreview";
import { AddressText } from "../../../../shared/components/AddressText/AddressText";
import { TaxFormViewer } from "../../types";
import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse
} from "@wingspanhq/payments/dist/interfaces/api/taxForm";

export interface AddressCorrectionDiffPreviewProps {
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  taxFormViewer?: TaxFormViewer;
  onEdit?: () => void;
  originalAddress: FormDataAddress;
  correctedAddress: FormDataAddress;
}

export const AddressCorrectionDiffPreview: React.FC<AddressCorrectionDiffPreviewProps> = ({
  taxForm,
  taxFormViewer,
  originalAddress,
  correctedAddress,
  onEdit
}) => {
  return (
    <>
      <WSText.Heading5 mb="M">Address correction</WSText.Heading5>
      <WSText.ParagraphSm mb="XL" color="gray500">
        {taxFormViewer === TaxFormViewer.Payee
          ? "My address is not accurate."
          : "The recipient's address is not accurate."}
      </WSText.ParagraphSm>

      <OriginalValuePreview>
        <WSFlexBox direction="column">
          <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
            Address
          </WSText.ParagraphSm>
          <WSText.ParagraphSm color="gray600" strike>
            <AddressText
              {...originalAddress}
              postalCode={originalAddress.zipCode}
            />
          </WSText.ParagraphSm>
        </WSFlexBox>
      </OriginalValuePreview>

      <CorrectedValuePreview
        taxForm={taxForm}
        taxFormViewer={taxFormViewer}
        onEdit={onEdit}
      >
        <WSFlexBox direction="column">
          <WSText.ParagraphSm weight="medium" color="gray400" mb="XS">
            Address
          </WSText.ParagraphSm>
          <WSText.ParagraphSm weight="medium" color="gray600">
            <AddressText
              {...correctedAddress}
              postalCode={correctedAddress.zipCode}
            />
          </WSText.ParagraphSm>
        </WSFlexBox>
      </CorrectedValuePreview>
    </>
  );
};
