import { useWSModal } from "@wingspanhq/fe-component-library";

import { RequestCorrectionWarningModal } from "./index";

export function useRequestCorrectionWarningModal() {
  return useWSModal(RequestCorrectionWarningModal, {
    title: "Important, please read:",
    size: "S"
  });
}
