import { Route, Switch } from "react-router-dom";
import { RouteDetails } from "./routes/RouteDetails";
import { RouteIndex } from "./routes/RouteIndex";
import { RouteRequestCorrection } from "./routes/RouteDetails/RouteRequestCorrection";
import { RouteTaxPreferences } from "./routes/RouteTaxPreferences";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { usePayeeTaxForms } from "../../query/taxForm/queries/usePayeeTaxForms";
import { CURRENT_YEAR } from "../1099NECFiling/constants/currentYear";
import { useMemo } from "react";

export const TAX_DOCUMENTS_PATH = "/member/tax-documents";

export const TaxDocuments: React.FC<{}> = () => {
  const userId = useUserId();
  const featureFlagsQuery = useFeatureFlags();
  const queryPayerTaxForms = usePayeeTaxForms(CURRENT_YEAR);

  const isPayeeOnTaxForms = useMemo(() => {
    return (
      featureFlagsQuery.data?.nec1099RecipientsTaxDocuments &&
      queryPayerTaxForms.data &&
      queryPayerTaxForms.data.length > 0 &&
      queryPayerTaxForms.data.some(taxForm => taxForm.memberId === userId)
    );
  }, [queryPayerTaxForms.data, featureFlagsQuery.data, userId]);

  return isPayeeOnTaxForms ? (
    <Switch>
      <Route
        path={TAX_DOCUMENTS_PATH + "/tax-preferences"}
        component={RouteTaxPreferences}
      />
      <Route
        path={TAX_DOCUMENTS_PATH + "/:taxFormId/request-correction"}
        component={RouteRequestCorrection}
      />
      <Route
        path={TAX_DOCUMENTS_PATH + "/:taxFormId"}
        component={RouteDetails}
      />
      <Route path={TAX_DOCUMENTS_PATH} exact component={RouteIndex} />
    </Switch>
  ) : null;
};
