import { QueryConfig } from "react-query";
import { useWSQuery } from "../../helpers";

import { INVITATION_BY_TOKEN } from "../keys";
import { getInvitation } from "../../../services/invitations";
import { IInvitationResponse } from "@wingspanhq/users";
import { WSServiceError } from "../../../utils/serviceHelper";

export const useQueryInvitation = (
  token: string,
  queryConfig?: QueryConfig<IInvitationResponse, WSServiceError>
) =>
  useWSQuery([INVITATION_BY_TOKEN, token], () => getInvitation(token), {
    retry: false,
    ...queryConfig
  });
