import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { PaymentsSetup } from "../modules/PaymentsSetup";
import { WSQueries } from "../query/WSQuery";
import { useUserId } from "../query/hooks/helpers";
import { useFeatureFlags } from "../query/hooks/useFeatureFlags";
import { useActivities } from "../query/users/queries";
import { PersonalPayLinkCreate } from "./screens/PersonalPayLinkCreate";
import { PersonalPayLinkDashboard } from "./screens/PersonalPayLinkDashboard";
import { PersonalPayLinkOverview } from "./screens/PersonalPayLinkOverview";
import { PersonalPayLinkSuccess } from "./screens/PersonalPayLinkSuccess";

export const PersonalPayLinkRoutes: React.FC = () => {
  const memberId = useUserId();
  const qActivities = useActivities(memberId);
  const history = useHistory();
  const queryFeatureFlags = useFeatureFlags();

  return (
    <WSQueries queries={{ activities: qActivities }}>
      {({ activities }) => {
        const isSetupCompleted =
          activities.data.flows.personalPayLinkSetup?.complete;

        if (isSetupCompleted) {
          return (
            <Switch>
              <Redirect
                from="/member/personal-pay-link"
                exact
                to="/member/personal-pay-link/dashboard"
              />

              <Route
                path="/member/personal-pay-link/dashboard"
                exact
                component={PersonalPayLinkDashboard}
              />

              <Route
                path="/member/personal-pay-link/success"
                exact
                component={PersonalPayLinkSuccess}
              />

              <Redirect from="*" to="/member/personal-pay-link" />
            </Switch>
          );
        }

        return (
          <Switch>
            <Redirect
              from="/member/personal-pay-link"
              exact
              to="/member/personal-pay-link/overview"
            />
            <Route
              path="/member/personal-pay-link/overview"
              exact
              component={PersonalPayLinkOverview}
            />

            <Route
              path="/member/personal-pay-link/set-up/payments"
              render={() => (
                <PaymentsSetup
                  basePath="/member/personal-pay-link/set-up/payments"
                  onBack={() => {
                    history.push("/member/personal-pay-link/overview");
                  }}
                  onFinish={() => {
                    history.push("/member/personal-pay-link/set-up/create");
                  }}
                />
              )}
            />

            <Route
              path="/member/personal-pay-link/set-up/create"
              exact
              component={PersonalPayLinkCreate}
            />

            <Redirect from="*" to="/member/personal-pay-link" />
          </Switch>
        );
      }}
    </WSQueries>
  );
};
