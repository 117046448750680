import { FormPartialDataPerson } from ".";

export const emptyValues: FormPartialDataPerson = {
  firstName: "",
  middleName: "",
  lastName: "",
  ownershipStake: 100,
  representativeConfirmation: false,
  jobTitle: "",
  birthday: null,
  email: "",
  phone: {
    countryCode: "1",
    phoneNumber: ""
  },
  taxId: "",
  country: "US",
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: ""
  }
};
