import {
  WSButton,
  WSButtons,
  WSText,
  toWSDateString,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import { InvoiceStatus } from "@wingspanhq/payments/dist/interfaces";
import {
  useCancelInvoice,
  useDeleteInvoice
} from "../../../../query/payments/mutations";
import { IInvoiceRow } from "../../service";

type Props = {
  invoice: IInvoiceRow;
  onClose: () => void;
};

export const ModalDelete: React.FC<Props> = ({ invoice, onClose }) => {
  const [deleteInvoice, deleteInvoiceMeta] = useDeleteInvoice();
  const [cancelInvoice, cancelInvoiceMeta] = useCancelInvoice();

  const isCancel = invoice
    ? [InvoiceStatus.Open, InvoiceStatus.Overdue].includes(invoice.oldStatus)
    : false;

  const toText = "";

  const dueDate = invoice ? invoice.dueDate : new Date();
  const amount = invoice.amount;

  return (
    <>
      <WSText.Heading5 mb="M">
        {isCancel ? "Cancel" : "Delete"} invoice
        {toText}
      </WSText.Heading5>
      <WSText mb="M">
        Are you sure you want to {isCancel ? "cancel" : "delete"} your{" "}
        {toWSDateString(dueDate, "monthDayYear")} invoice {toText} for{" "}
        {toWSMoneyString(amount)}?
      </WSText>
      <WSText mb="XL">
        This action can't be undone, once you {isCancel ? "cancel" : "delete"}{" "}
        an invoice you'll have to recreate it.
      </WSText>

      <WSButtons format="modal">
        <WSButton
          destructive
          name="delete"
          onClick={() => {
            if (isCancel) {
              cancelInvoice(
                { invoiceId: invoice.invoiceId },
                { onSuccess: onClose }
              );
            } else {
              deleteInvoice(
                { invoiceId: invoice.invoiceId },
                { onSuccess: onClose }
              );
            }
          }}
          loading={cancelInvoiceMeta.isLoading || deleteInvoiceMeta.isLoading}
        >
          {isCancel ? "Cancel" : "Delete"} invoice
        </WSButton>
        <WSButton.Secondary onClick={onClose}>Keep invoice</WSButton.Secondary>
      </WSButtons>
    </>
  );
};
