import promiseMap from "p-map";
import axios from "axios";
import { RetryConfig, retry } from "ts-retry-promise";

export interface IPromiseMapOptions {
  concurrency?: number;
  retryConfig?: Partial<RetryConfig>;
}

export interface IPromiseMapOutput<T, R> {
  results: R[];
  errors: Array<{ item: T; error: any }>;
}

export async function pMap<T, R>(
  items: T[],
  mapper: Function,
  { concurrency = 100, retryConfig = { retries: 3 } }: IPromiseMapOptions = {}
) {
  const errors: IPromiseMapOutput<T, R>["errors"] = [];

  const processItem = async (item: T) => {
    const result = await retry(() => mapper(item), {
      retryIf: error => {
        const clientErrorCodes = [
          400, // Bad request
          401, // Unauthorized
          403, // Forbidden
          404 // Not found
        ];
        if (axios.isAxiosError(error)) {
          // Do not retry if the error is a client error
          return !clientErrorCodes.includes(error.response?.status ?? 0);
        }
        return true;
      },
      ...retryConfig
    }).catch((err: any) => {
      errors.push({
        item,
        error: err.lastError ?? err
      });
    });
    return result;
  };

  const results = await promiseMap(items, processItem, { concurrency });
  const successfulResults = results.filter(result => result !== undefined);
  return { results: successfulResults, errors };
}
