// TODO: move to FECL

import React, { useEffect } from "react";
import styles from "./Fullscreen.module.scss";

export const Fullscreen: React.FC = ({ children }) => {
  useEffect(() => {
    document.body.classList.add(styles.withFullscreen);
    return () => {
      document.body.classList.remove(styles.withFullscreen);
    };
  }, []);

  return <div className={styles.fullscreen}>{children}</div>;
};
