import { QueryConfig } from "react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { ICardLimitsResponse } from "../../../services/api/banking/cards/types";
import { getCardLimits } from "../../../services/api/banking/cards";

export const QUERY_CARD_LIMITS = "QUERY_CARD_LIMITS";

export const useCardLimits = (
  cardId: string,
  config?: QueryConfig<ICardLimitsResponse, WSServiceError>
) => {
  return useWSQuery<ICardLimitsResponse, WSServiceError>(
    [QUERY_CARD_LIMITS, cardId],
    () => getCardLimits(cardId),
    {
      retry: false,
      ...config
    }
  );
};
