import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSGrid,
  WSList,
  WSSectionToolbar
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import { useCardsQuery } from "../../../../query/cards/queries/useCardsQuery";
import { selectorWalletCards } from "../../selectors/selectorWalletCards";
import {
  useRedirectToCardDetails,
  WALLET_ACTIVATE_VIRTUAL_CARD_PATH,
  WALLET_ORDER_PHYSICAL_CARD_PATH
} from "../../index";
import { EmptyCardPlaceholder } from "../BankCard/EmptyCardPlaceholder";
import { CardView } from "../BankCard";

type Props = WSElementProps;

export const BankCardsList: React.FC<Props> = ({ ...elementProps }) => {
  const cardsQuery = useCardsQuery();
  const history = useHistory();
  const redirectToCardDetails = useRedirectToCardDetails();

  return (
    <WSElement {...elementProps}>
      <WSQueries queries={{ cardsQuery }}>
        {({ cardsQueryData }) => {
          const virtualCard = selectorWalletCards(cardsQueryData).filter(
            card => card.isVirtualCard
          )[0];
          const physicalCard = selectorWalletCards(cardsQueryData).filter(
            card => !card.isVirtualCard
          )[0];

          return (
            <WSGrid>
              <WSGrid.Item span={{ s: "12", m: "12", l: "6" }}>
                <WSList gap="XL">
                  <WSSectionToolbar
                    title="Virtual debit card"
                    button={
                      virtualCard
                        ? {
                            label: "Manage",
                            rightIcon: "chevron-right",
                            onClick: () =>
                              redirectToCardDetails(virtualCard.cardId)
                          }
                        : undefined
                    }
                  />
                  <WSFlexBox.Center>
                    {virtualCard ? (
                      <CardView card={virtualCard} />
                    ) : (
                      <EmptyCardPlaceholder
                        cardType="virtual"
                        actionIcon="card"
                        actionLabel="Create free card"
                        onAction={() => {
                          history.push(WALLET_ACTIVATE_VIRTUAL_CARD_PATH);
                        }}
                        data-testid="createCard"
                      />
                    )}
                  </WSFlexBox.Center>
                </WSList>
              </WSGrid.Item>
              <WSGrid.Item span={{ s: "12", m: "12", l: "6" }}>
                <WSList gap="XL">
                  <WSSectionToolbar
                    title="Physical debit card"
                    button={
                      physicalCard
                        ? {
                            label: "Manage",
                            rightIcon: "chevron-right",
                            onClick: () =>
                              redirectToCardDetails(physicalCard.cardId)
                          }
                        : undefined
                    }
                  />
                  <WSFlexBox.Center>
                    {physicalCard ? (
                      <CardView card={physicalCard} />
                    ) : (
                      <EmptyCardPlaceholder
                        cardType="physical"
                        actionIcon="card"
                        actionLabel="Order free card"
                        onAction={() => {
                          history.push(WALLET_ORDER_PHYSICAL_CARD_PATH);
                        }}
                        data-testid="createCard"
                      />
                    )}
                  </WSFlexBox.Center>
                </WSList>
              </WSGrid.Item>
            </WSGrid>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};
