import {
  WSActions,
  WSElement,
  WSForm,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import { wsName, WSNameParams } from "@wingspanhq/utils/dist/name/wsName";
import { useUserId } from "../../../../query/hooks/helpers";
import { useMutationUpdateAcknowledgement } from "../../../../query/onboarding/mutations/useMutationUpdateAcknowledgement";
import { useAllPayerListQuery } from "../../../../query/payers/queries/useAllPayerListQuery";
import { OnboardingStep } from "../../types";
import {
  IPayerResponse,
  PayeeTaxDocumentSharePermission,
  PayerPayeeStatus
} from "@wingspanhq/payments/dist/interfaces";
import { useQueryCustomerEntity } from "../../../../query/onboarding/queries/useQueryCustomerEntity";
import { WSQueries } from "../../../../query/WSQuery";
import {
  ConsentFormType,
  getConsentForms
} from "../../components/CertifyTaxPayerInfo/getConsentForms";
import {
  FormW9Consent,
  getValidationSchemaFormW9
} from "../../components/CertifyTaxPayerInfo/FormW9Consent";
import {
  FormW8BenConsent,
  getValidationSchemaFormW8Ben
} from "../../components/CertifyTaxPayerInfo/FormW8BenConsent";
import {
  FormCanadaTaxPayerCertificationConsent,
  getValidationSchemaFormCanadaTaxPayerCertificationConsent
} from "../../components/CertifyTaxPayerInfo/FormCanadaTaxPayerCertificationConsent";
import {
  FormLocalTaxInformationConsent,
  getValidationSchemaFormLocalTaxPayerCertificationConsent
} from "../../components/CertifyTaxPayerInfo/FormLocalTaxInformationConsent";
import React, { ReactElement } from "react";
import {
  QUERY_ACKNOWLEDGEMENT,
  useQueryAcknowledgement
} from "../../../../query/onboarding/queries/useQueryAcknowledgement";
import { useWSMutation } from "../../../../query/helpers";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { updatePayer } from "../../../../services/payers/updatePayer";
import { acknowledgementVersions } from "../../../../shared/constants/acknowledgements";
import { updateAcknowledgement } from "../../../../services/api/onboarding/acknowledgements";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { QUERY_ALL_PAYER_LIST } from "../../../../query/payers/keys";
import { useQueryPayerRowsAll } from "../../../../query/search/payer/queries/useQueryPayerRowsAll";
import { IPayerRow } from "../../../../services/search/payer";

function useConsentForms(
  payers: IPayerRow[],
  payeeCountry: string,
  onNext?: () => void
) {
  const userId = useUserId();
  // Fetching these acknowledgements to pre-fill the form
  const queryW9CertificationAcknowledgement = useQueryAcknowledgement(
    "W9Certification"
  );
  const queryW8BenCertificationAcknowledgement = useQueryAcknowledgement(
    "W8BenCertification"
  );
  const queryCanadianTaxPayerCertificationAcknowledgement = useQueryAcknowledgement(
    "CanadianTaxPayerCertification"
  );
  const queryLocalTaxPayerCertificationAcknowledgement = useQueryAcknowledgement(
    "LocalTaxPayerCertification"
  );
  const [_updateAcknowledgement] = useMutationUpdateAcknowledgement();

  const getPayerNames = (payers: IPayerRow[]) =>
    payers.map(payer =>
      wsName({
        user: payer.user,
        member: payer.member,
        payeeOwnedData: payer.payeeOwnedData
      } as WSNameParams).getResolvedName()
    );

  const { consentForms, taxInfoSharedPayers } = getConsentForms(
    payers,
    payeeCountry
  );

  const renderConsentForms = () => {
    const consentFormTypeToComponent: Record<ConsentFormType, ReactElement> = {
      [ConsentFormType.W9]: (
        <FormW9Consent
          payerNames={getPayerNames(taxInfoSharedPayers[ConsentFormType.W9])}
        />
      ),
      [ConsentFormType.W8Ben]: (
        <FormW8BenConsent
          payerNames={getPayerNames(taxInfoSharedPayers[ConsentFormType.W8Ben])}
        />
      ),
      [ConsentFormType.CanadaTaxPayerCertification]: (
        <FormCanadaTaxPayerCertificationConsent
          payerNames={getPayerNames(
            taxInfoSharedPayers[ConsentFormType.CanadaTaxPayerCertification]
          )}
        />
      ),
      [ConsentFormType.LocalTaxInformation]: (
        <FormLocalTaxInformationConsent
          payerNames={getPayerNames(
            taxInfoSharedPayers[ConsentFormType.LocalTaxInformation]
          )}
        />
      )
    };
    return (
      <WSList gap="XL">
        {consentForms.map(
          consentFormType => consentFormTypeToComponent[consentFormType]
        )}
      </WSList>
    );
  };

  const getValidationSchema = () => {
    const validationSchemas: Record<ConsentFormType, any> = {
      [ConsentFormType.W9]: getValidationSchemaFormW9(
        getPayerNames(taxInfoSharedPayers[ConsentFormType.W9])
      ),
      [ConsentFormType.W8Ben]: getValidationSchemaFormW8Ben(
        getPayerNames(taxInfoSharedPayers[ConsentFormType.W8Ben])
      ),
      [ConsentFormType.CanadaTaxPayerCertification]: getValidationSchemaFormCanadaTaxPayerCertificationConsent(
        getPayerNames(
          taxInfoSharedPayers[ConsentFormType.CanadaTaxPayerCertification]
        )
      ),
      [ConsentFormType.LocalTaxInformation]: getValidationSchemaFormLocalTaxPayerCertificationConsent(
        getPayerNames(taxInfoSharedPayers[ConsentFormType.LocalTaxInformation])
      )
    };
    return consentForms
      .map(consentFormType => validationSchemas[consentFormType])
      .reduce(
        (finalSchema, curSchema) => finalSchema.concat(curSchema),
        Yup.object().shape({})
      );
  };

  const getDefaultValues = () => {
    const defaultValues: Record<ConsentFormType, any> = {
      [ConsentFormType.W9]: {
        w9Consent:
          queryW9CertificationAcknowledgement.data?.acknowledgementStatus ===
          "Given",
        shareW9: false
      },
      [ConsentFormType.W8Ben]: {
        w8BenConsent:
          queryW8BenCertificationAcknowledgement.data?.acknowledgementStatus ===
          "Given",
        shareW8Ben: false
      },
      [ConsentFormType.CanadaTaxPayerCertification]: {
        canadaTaxInfoConsent:
          queryCanadianTaxPayerCertificationAcknowledgement.data
            ?.acknowledgementStatus === "Given",
        shareCanadaTaxInfo: false
      },
      [ConsentFormType.LocalTaxInformation]: {
        localTaxInfoConsent:
          queryLocalTaxPayerCertificationAcknowledgement.data
            ?.acknowledgementStatus === "Given",
        shareLocalTaxInfo: false
      }
    };

    return consentForms
      .map(ConsentFormType => defaultValues[ConsentFormType])
      .reduce((finalDefaultValues, curDefaultValues) => {
        return {
          ...finalDefaultValues,
          ...curDefaultValues
        };
      }, {});
  };

  const [submit, submitMeta] = useWSMutation<any, WSServiceError>(
    async () => {
      // Legacy way of updating user activity for consent forms
      // await usersService.activity.update(userId, {
      //   events: {
      //     w9FormAcknowledged: new Date()
      //   }
      // });

      const consentFormTypeToAcknowledgementNameMap: Record<
        ConsentFormType,
        Parameters<typeof updateAcknowledgement>[0]
      > = {
        [ConsentFormType.W9]: "W9Certification",
        [ConsentFormType.W8Ben]: "W8BenCertification",
        [ConsentFormType.CanadaTaxPayerCertification]:
          "CanadianTaxPayerCertification",
        [ConsentFormType.LocalTaxInformation]: "LocalTaxPayerCertification"
      };

      // Update acknowledgement for consent forms
      console.log("consentForms", consentForms);
      await Promise.all(
        consentForms.map(consentFormType =>
          _updateAcknowledgement({
            acknowledgementName:
              consentFormTypeToAcknowledgementNameMap[consentFormType],
            requestData: {
              acknowledgementName:
                consentFormTypeToAcknowledgementNameMap[consentFormType],
              acknowledgementStatus: "Given",
              version:
                acknowledgementVersions[
                  consentFormTypeToAcknowledgementNameMap[consentFormType]
                ]
            }
          })
        )
      );

      // share tax document with payers
      await Promise.all(
        payers.map(payer =>
          updatePayer(payer.payerId, {
            payeeOwnedData: {
              shareTaxDocument: PayeeTaxDocumentSharePermission.Allow
            }
          })
        )
      );
    },
    {
      dependencies: [QUERY_ACKNOWLEDGEMENT, QUERY_ALL_PAYER_LIST],
      onSuccess: () => {
        onNext?.();
      }
    }
  );

  return {
    consentFormsCount: consentForms.length,
    renderConsentForms,
    getValidationSchema,
    getDefaultValues,
    taxInfoSharedPayers,
    submit,
    submitMeta
  };
}

export const CertifyTaxPayerInfo: React.FC<OnboardingStep> = ({ onNext }) => {
  const queryAllPayerList = useQueryPayerRowsAll({
    filter: {
      "payeeOwnedData.status": PayerPayeeStatus.Active
    }
  });
  const queryCustomerEntity = useQueryCustomerEntity();

  const {
    consentFormsCount,
    renderConsentForms,
    getValidationSchema,
    getDefaultValues,
    submit,
    submitMeta
  } = useConsentForms(
    queryAllPayerList.data || [],
    queryCustomerEntity.data?.country ?? "US",
    onNext
  );

  const onSubmit = async (data: any) => {
    console.log("form submit data", data);
    await submit();
  };

  return (
    <>
      {consentFormsCount > 1 ? (
        <WSElement>
          <WSText.Paragraph weight="medium" color="gray700" mb="M">
            Certify taxpayer information
          </WSText.Paragraph>
          <WSText.ParagraphSm weight="book" color="gray500" mb="2XL">
            Please review and certify your taxpayer information is accurate.
            Separate certifications may be required if your clients are located
            in different countries.
          </WSText.ParagraphSm>
        </WSElement>
      ) : null}

      <WSQueries
        queries={{
          queryAllPayerList,
          queryCustomerEntity
        }}
      >
        {() => {
          const defaultValues = getDefaultValues();
          const validationSchema = getValidationSchema();
          return (
            <WSForm
              defaultValues={defaultValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <WSList gap="2XL">
                {renderConsentForms()}

                <WSErrorMessage
                  error={submitMeta.error}
                  contextKey={ErrorContextKey.PayeeOnboardingConsentForms}
                />

                <WSForm.Values names={Object.keys(defaultValues)}>
                  {(values: Record<string, boolean>) => {
                    const isValid = validationSchema.isValidSync(values);

                    return (
                      <WSActions
                        alignment="fill"
                        buttons={[
                          {
                            label: "Continue",
                            disabled: !isValid,
                            type: "submit",
                            loading: submitMeta.isLoading
                          }
                        ]}
                      />
                    );
                  }}
                </WSForm.Values>

                <WSText.ParagraphXs color="gray400">
                  The information on this page is required to setup your
                  Wingspan account, if you exit or leave without completing this
                  step, you will require to do this later.
                </WSText.ParagraphXs>
              </WSList>
            </WSForm>
          );
        }}
      </WSQueries>
    </>
  );
};
