import classNames from "classnames";
import React, { useMemo } from "react";
import { WSElement, WSMarginProps } from "../WSElement/WSElement.component";
import styles from "./WSBreadcrumbs.module.scss";
import { WSText } from "../core/WSText/WSText.component";
import { WSIcon } from "../core/WSIcon/WSIcon.component";
import { WSMenuTrigger } from "../common";
import { WSFlexBox } from "../layout";

export type WSBreadcrumbsItem = {
  label: string;
  onClick?: () => void;
  items?: WSBreadcrumbsItem[];
};

export interface WSBreadcrumbsProps extends WSMarginProps {
  className?: string;
  items?: WSBreadcrumbsItem[];
  onBack?: () => void;
  after?: React.ReactNode;
  limit?: number;
}

export const WSBreadcrumbs: React.FC<WSBreadcrumbsProps> = ({
  className,
  items = [],
  onBack,
  after,
  limit = 4,
  ...elementProps
}) => {
  const list = useMemo(() => {
    const result: React.ReactNode[] = [];
    const length = items?.length || 0;

    const addBreadcrumb = (
      item: WSBreadcrumbsItem,
      isLast: boolean = false
    ) => {
      const color = isLast ? "gray600" : "gray400";
      const className = classNames({
        [styles.currentBreadcrumb]: isLast,
        [styles.breadcrumb]: !isLast,
        [styles.clickable]: item.items?.length || item.onClick
      });

      if (item.items) {
        result.push(
          <WSMenuTrigger
            name={`Breadcrumb-${item.label}`}
            key={`item-${result.length}`}
            items={item.items.map((i) => ({
              label: i.label,
              onClick: i.onClick
            }))}
            renderTrigger={({ onToggle }) => (
              <WSFlexBox.CenterY onClick={onToggle}>
                <WSText.ParagraphXs
                  weight="medium"
                  color={color}
                  p="XS"
                  className={className}
                >
                  {item.label}
                </WSText.ParagraphXs>
                <WSIcon name="caret-down" color={color} size="S" />
              </WSFlexBox.CenterY>
            )}
          />
        );
      } else {
        result.push(
          <WSText.ParagraphXs
            key={`item-${result.length}`}
            weight="medium"
            color={color}
            p="XS"
            className={className}
            onClick={isLast ? undefined : item.onClick}
          >
            {item.label}
          </WSText.ParagraphXs>
        );
      }
    };

    const addMenu = (items: WSBreadcrumbsItem[]) => {
      result.push(
        <WSMenuTrigger
          name="Breadcrumbs"
          key={`item-${result.length}`}
          items={items.map((i) => ({
            label: i.label,
            onClick: i.onClick
          }))}
          renderTrigger={({ onToggle }) => (
            <WSFlexBox.Center
              onClick={onToggle}
              className={styles.hiddenBreadcrumbs}
            >
              <WSIcon
                name="dots-h"
                color="gray500"
                size="M"
                p="NONE"
                m="NONE"
              />
            </WSFlexBox.Center>
          )}
        />
      );
    };

    const addDivider = () => {
      result.push(
        <WSIcon
          key={`icon-${result.length}`}
          color="gray400"
          size="M"
          name="chevron-right"
          className={styles.breadcrumbIcon}
        />
      );
    };

    if (length > limit) {
      addBreadcrumb(items[0]);
      addDivider();
      addMenu(items.slice(1, length - 1));
      addDivider();
      addBreadcrumb(items[length - 1], true);
    } else {
      items?.forEach((item, index, items) => {
        const isLast = index === length - 1;

        addBreadcrumb(item, isLast);

        if (!isLast) {
          addDivider();
        }
      });
    }

    return result;
  }, [items, limit]);

  return (
    <WSElement
      className={classNames(styles.breadcrumbs, className)}
      {...elementProps}
    >
      {onBack ? (
        <WSFlexBox.CenterY onClick={onBack} className={styles.backButton}>
          <WSIcon
            color="gray400"
            size="M"
            name="arrow-left"
            className={styles.backIcon}
          />
          <WSText.ParagraphXs
            weight="medium"
            color={"gray400"}
            p="XS"
            className={styles.breadcrumb}
          >
            Back
          </WSText.ParagraphXs>
        </WSFlexBox.CenterY>
      ) : null}
      {list}
      {after}
    </WSElement>
  );
};
