import { QueryConfig } from "react-query";
import { useWSQuery } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import { getBackgroundChecksRequest } from "../../../services/backgroundChecks/getBackgroundChecksRequest";
import { IBackgroundCheckRequestResponse } from "@wingspanhq/background-checks/dist/lib/interfaces";

export const QUERY_BACKGROUND_CHECKS_REQUEST =
  "QUERY_BACKGROUND_CHECKS_REQUEST";

export const useBackgroundChecksRequestQuery = (
  requestId: string,
  queryConfig?: QueryConfig<IBackgroundCheckRequestResponse, WSServiceError>
) => {
  return useWSQuery(
    [QUERY_BACKGROUND_CHECKS_REQUEST, requestId],
    () => getBackgroundChecksRequest(requestId),
    queryConfig
  );
};
