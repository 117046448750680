import {
  IPayoutSettingsResponse,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";

export const selectorPayoutAccount = (
  payoutSettings?: IPayoutSettingsResponse,
  accounts?: IAccount[]
) => {
  const standardPayoutDestination = payoutSettings?.payoutDestinations.find(
    destination => destination.payoutMethod === PayoutPreferences.Standard
  );

  return (accounts || []).find(
    account => account.accountId === standardPayoutDestination?.destinationId
  );
};
