import {
  WSList,
  WSPanel,
  WSSectionToolbar
} from "@wingspanhq/fe-component-library";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../../../query/hooks/useFeatureFlags";
import { useQueryCustomerEntity } from "../../../../../query/onboarding/queries/useQueryCustomerEntity";
import { useMemberProfile } from "../../../../../query/users/queries";
import { ReviewAccountType } from "../../../../Onboarding/components/ReviewAccountType";
import { ReviewBusinessInformation } from "../../../../Onboarding/components/ReviewBusinessInformation";
import { InfoBoxChangeRequest } from "../components/InfoBoxChangeRequest";

type Props = {};

export const RouteBusinessInformation: React.FC<Props> = () => {
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);
  const queryMonitoredEntity = useQueryCustomerEntity();
  const queryFeatureFlags = useFeatureFlags();

  return (
    <WSList gap="2XL">
      {queryFeatureFlags.data?.monitoredEntityChange && (
        <InfoBoxChangeRequest />
      )}

      <ReviewAccountType
        monitoredEntity={queryMonitoredEntity.data}
        member={queryMember.data}
      />

      <ReviewBusinessInformation
        country={queryMonitoredEntity.data?.country || "US"}
        member={queryMember.data}
      />

      <WSPanel>
        <WSSectionToolbar title="Representative information (You)" hasBorder />
      </WSPanel>
    </WSList>
  );
};
