import { USStates } from "../../../constants/USstates";

export const TAX_ID_REQUIRED_STATES = [
  {
    label: "Alabama",
    value: "AL"
  },
  {
    label: "Delaware",
    value: "DE"
  },
  {
    label: "Kansas",
    value: "KS"
  },
  {
    label: "Mississippi",
    value: "MS"
  },
  {
    label: "North Dakota",
    value: "ND"
  },
  {
    label: "Oregon",
    value: "OR"
  },
  {
    label: "Pennsylvania",
    value: "PA"
  }
];

export interface IStateTaxId {
  state: string;
  taxId: string;
  stateLabel: string;
}

export const stateTaxIdMapToArray = (stateTaxIdMap: {
  [key: string]: string;
}) => {
  const _stateTaxIds: IStateTaxId[] = [];
  Object.keys(stateTaxIdMap).forEach(state => {
    _stateTaxIds.push({
      state,
      taxId: stateTaxIdMap[state],
      stateLabel:
        USStates.find(v => v.abbreviation === state)?.name ||
        TAX_ID_REQUIRED_STATES.find(v => v.value === state)?.label ||
        state
    });
  });
  return _stateTaxIds;
};
