import { WSFlexBox, WSText } from "@wingspanhq/fe-component-library";

interface TwoLineLabelProps {
  label: string;
  description: string;
}

export const TwoLineLabel: React.FC<TwoLineLabelProps> = ({
  label,
  description
}) => {
  return (
    <WSFlexBox direction="column" justify="flex-end">
      <WSText weight="medium" singleLine>
        {label}
      </WSText>
      <WSText.ParagraphSm color="gray500">{description}</WSText.ParagraphSm>
    </WSFlexBox>
  );
};
