import { WSCategory } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  CarExpensesSubcategories,
  HomeExpensesSubcategories,
  PersonalDeductionsSubcategories,
  TaxSubcategories,
  TransferSubcategories,
  TravelTransportSubcategories,
  VehiclesMachineryEquipmentSubcategories
} from "@wingspanhq/bookkeeping/dist/lib/interfaces/subcategories";

export type CategoryDescription = [string, string[], WSCategory, string | null];
export const CategoryDescriptionsMap: CategoryDescription[] = [
  [
    "For self-employed meal deductions, you can deduct 50% of all meals with clients that are specifically about business. For business meals while traveling, you can either choose to deduct the actual cost of your meals (again, 50% deduction), or use the standard meal allowance specified by the General Services Administration.",
    ["food", "restaraunt", "eating", "snack"],
    WSCategory.Meal,
    null
  ],
  [
    "Purchases for items you need in order to earn revenue.  If you're a painter, you'd put paint, canvas, brushes, etc. here.",
    [
      "small equipment",
      "business supplies",
      "materials",
      "tools",
      "job supplies"
    ],
    WSCategory.Supplies,
    null
  ],
  [
    "Business-related computer software like Wingspan, Zoom, Goggle Suite, Dropbox, & DocuSign.",
    ["computer software", "apps"],
    WSCategory.Software,
    null
  ],
  [
    "Insurance on your personal home or home rental.",
    ["renters insurances"],
    WSCategory.HomeExpenses,
    HomeExpensesSubcategories.HomeRentalInsurance
  ],
  [
    "Mortgage payments to a bank or rent paid to live in property you do not own.",
    ["mortgage", "rental"],
    WSCategory.HomeExpenses,
    HomeExpensesSubcategories.Rent
  ],
  [
    "Payments to your internet provider.",
    ["internet"],
    WSCategory.HomeExpenses,
    HomeExpensesSubcategories.WifiBill
  ],
  [
    "Painting and repairs for the area in your home or rental property that is used for business.",
    ["repairs & maintenance"],
    WSCategory.HomeExpenses,
    HomeExpensesSubcategories.HomeOfficeRepairsMaintenance
  ],
  [
    "If you own your own home, these are the quarterly payments you make to the township you live in.",
    ["real estate taxes"],
    WSCategory.HomeExpenses,
    HomeExpensesSubcategories.PropertyTax
  ],
  [
    "Electricity, gas, trash removal, and cleaning.  Do NOT include your home phone.  The basic local telephone service, including taxes, for the first land line into your home is not deductible.  However, you can deductc expenses for a business long-distance calls and or a 2nd phone line dedicated to business.  Record deductible telephone to Phone bill.",
    [
      "electic",
      "gas",
      "oil",
      "trash removal",
      "rubbish removal",
      "and cleaning services"
    ],
    WSCategory.HomeExpenses,
    HomeExpensesSubcategories.Utilities
  ],
  [
    "Expenses you incur to get to and from places you need to be for business.  Include subways, hired drivers (Uber, Lyft, taxi), and bus transportation.",
    ["transportation"],
    WSCategory.TravelTransport,
    TravelTransportSubcategories.PublicTransport
  ],
  [
    "Expenses for lodging connected with overnight travel for business while away from home.",
    ["hotel", "motel", "short-term rental"],
    WSCategory.TravelTransport,
    TravelTransportSubcategories.TravelLodging
  ],
  [
    "Expenses for commerical airline travel connected with business outside your home area.",
    ["air travel", "air transportation"],
    WSCategory.TravelTransport,
    TravelTransportSubcategories.Flight
  ],
  [
    "Gas you purchase for your car or truck.",
    ["gasoline"],
    WSCategory.CarExpenses,
    CarExpensesSubcategories.Gas
  ],
  [
    "Expenses for routine maintance and repairs on your car or truck.  This would include oil changes, new tires, replace broken belts, etc.",
    ["car repairs", "fuel"],
    WSCategory.CarExpenses,
    CarExpensesSubcategories.CarMaintenance
  ],
  [
    "Expenses for getting your car or truck washed or cleaned.",
    ["car cleaning"],
    WSCategory.CarExpenses,
    CarExpensesSubcategories.CarWash
  ],
  [
    "Expenses for tolls incurred while traveling for business.",
    [],
    WSCategory.CarExpenses,
    CarExpensesSubcategories.TollFee
  ],
  [
    "Expenses for parking incurred while traveling for business.",
    ["parking meters"],
    WSCategory.CarExpenses,
    CarExpensesSubcategories.Parking
  ],
  [
    "This represents payments for your car insurance.",
    ["vehicle insurance"],
    WSCategory.CarExpenses,
    CarExpensesSubcategories.CarInsurance
  ],
  [
    "Lease payments to use a vehicle that you do not own.  Do not include car rentals when you travel.",
    ["vehilcle lease"],
    WSCategory.CarExpenses,
    CarExpensesSubcategories.CarLease
  ],
  [
    "Payments on a car loan.",
    ["loan payment", "vehicle payment"],
    WSCategory.VehiclesMachineryEquipment,
    VehiclesMachineryEquipmentSubcategories.Gas
  ],
  [
    "All advertising costs are fully tax deductible. Some examples include the cost of printing flyers and business cards, running Google Adwords and Facebook ads, and trade show promotions. You can also deduct any “middleman” costs, such as fees paid to PR agencies or freelance copywriters.",
    ["digital marketing", "social media advertising"],
    WSCategory.AdvertisingMarketing,
    null
  ],
  [
    "Use this category for any classes or training you need related to your work.  Include continuing education costs required to maintain your professional license.",
    ["training", "cpe"],
    WSCategory.Education,
    null
  ],
  [
    "Use this category for business related payments you make to an individual or a company for services they perform.  Think of it as hiring someone, but you do not have them on payroll.  Do not include legal or accounting services. However, if you are an attorney and you pay another attorney to help you, it would be considered a contractor payment.  Be sure to collect a signed W9 form for each contractor before your first payment to them.",
    ["subcontractors", "contract labor"],
    WSCategory.ContractorPayment,
    null
  ],
  [
    "You can deduct any fees paid to licensed professionals such as attorneys, accountants and online bookkeeping services like Wignspan. This includes fees paid for tax advice, which means preparation of Schedule C is a deductible expense (how meta).",
    ["lawyer", "accountant"],
    WSCategory.LegalProfessionalService,
    null
  ],
  [
    "Licenses and regulatory fees for your trade or business paid each year to state or local governments.  ",
    [],
    WSCategory.BusinessLicense,
    null
  ],
  [
    "Payments for your cell phone service and or dedicated business land line.",
    ["telephone", "cell phone"],
    WSCategory.PhoneBill,
    null
  ],
  [
    "Payments for business insurance policies such as General Liability, E&O, cyber-security and the like.  Do not include health insurance, life insurance, or accident policies.",
    [],
    WSCategory.BusinessInsurance,
    null
  ],
  [
    "Use this category if you give business gifts in the course of your trade or business.  You can only deduct up to $25 per gift during the tax year.",
    ["present"],
    WSCategory.Gift,
    null
  ],
  [
    'This category is defined by the IRS as "any activity generally considered to provide entertainment, amusement, or recreation, and includes meals provided to a customer or client".  Entertainment is not a deductible business expense.',
    ["recreation", "amusement"],
    WSCategory.Entertainment,
    null
  ],
  [
    "Use this category for rent or lease of office and storage space.",
    ["lease", "rental"],
    WSCategory.OfficeRent,
    null
  ],
  [
    "Use this category if you pay an individual or business commission for services or work performed.  You should request a signed Form W9 from the individual or business, and issue a 1099 if you paid them $600 or more in a given year.",
    [],
    WSCategory.Commission,
    null
  ],
  [
    "This category is reserved for gross W2 wages paid, typically processed through a payroll service.",
    [],
    WSCategory.Payroll,
    null
  ],
  [
    "Use this category for a variety of bank charges including, monthly fees, wire transfers, over-draft, and the like.  You can also include merchant processing fees if you accept customer payments via credit card.",
    ["wire fee", "nsf", "service charge"],
    WSCategory.BankFee,
    null
  ],
  [
    "Can't find the right category?  If it's a business expense, categorize it here.",
    [],
    WSCategory.Other,
    null
  ],
  [
    "This category is used for interest paid on business credit cards or business loans.",
    [],
    WSCategory.Interest,
    null
  ],
  [
    "This category is to be used for monthly payments to your medical insurance provider.",
    [],
    WSCategory.OtherDeductions,
    PersonalDeductionsSubcategories.MedicalInsurance
  ],
  [
    "This category is for medical care expenses to diagnosis, cure, mitigation, treatment, or prevention of disease, or payment for treatments affecting any structure of function of the body.  Includes payments to doctors, dentists, surgeons, chiropractors, prescriptions, and the like.  ",
    ["doctor bills", "pharmacy", "medical procedures"],
    WSCategory.OtherDeductions,
    PersonalDeductionsSubcategories.MedicalExpenses
  ],
  [
    "This category is for payments made to a Health Savings Plan outside of a payroll service provider.  ",
    [],
    WSCategory.OtherDeductions,
    PersonalDeductionsSubcategories.HSAContributions
  ],
  [
    "This category is for monies deposited into a qualified retirement account such as an IRA or SEP.",
    ["ira contributions", "sep contributions"],
    WSCategory.OtherDeductions,
    PersonalDeductionsSubcategories.RetirementContribution
  ],
  [
    "Use this category for donations of money, goods, or services to a qualified non-profit organization.  Examples include churches, non-profit organizations such as the American Red Cross, and non-profit organizations such as the Boy Scouts of America, colleges, and museums.  ",
    ["donations"],
    WSCategory.OtherDeductions,
    PersonalDeductionsSubcategories.CharitableContribution
  ],
  [
    "Use this category for the quarterly estimated tax payments you make to the IRS and State.",
    ["estimated tax payments"],
    WSCategory.Taxes,
    TaxSubcategories.TaxPayment
  ],
  [
    "Use this category for the employer portion of payroll taxes paid to the IRS and State.",
    ["employer payroll taxes"],
    WSCategory.Taxes,
    TaxSubcategories.PayrollTax
  ],
  [
    "This category represents the funds you set aside for quarterly tax payments.",
    [],
    WSCategory.Taxes,
    TaxSubcategories.WithholdingDeposit
  ],
  [
    "This category represents to funds you withdraw out of your tax savings account.",
    [],
    WSCategory.Taxes,
    TaxSubcategories.WithholdingWithdraw
  ],
  [
    "This represents money that is moved from one bank account to another.",
    ["transfer to another account"],
    WSCategory.Transfer,
    TransferSubcategories.InternalTransfer
  ],
  [
    "This category represents money you use to pay a credit card bill.  If you are using this account for business expenses, make sure the credit card is connect to Wingspan!",
    [],
    WSCategory.Transfer,
    TransferSubcategories.CreditCardPayment
  ],
  [
    "This is money you put into the business personally.",
    ["contribution"],
    WSCategory.Transfer,
    TransferSubcategories.OwnersDeposit
  ],
  [
    "This is money you take out of the business personally.",
    ["distribution"],
    WSCategory.Transfer,
    TransferSubcategories.OwnersWithdraw
  ]
];
