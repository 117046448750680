import {
  Form1099Status,
  IMemberClientForm1099Balances
} from "@wingspanhq/payments/dist/interfaces";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";

export const SUGGESTION_PERIOD_DAYS = 360;
const SUGGESTION_PERIOD_MS = SUGGESTION_PERIOD_DAYS * 24 * 60 * 60 * 1000;

const isOpenSuggestionPeriod = (
  form1099Balance: IMemberClientForm1099Balances
) => {
  const irsAcceptedDate =
    form1099Balance.taxForm?.submissions?.[0].events?.acceptedByIrsAt;

  return irsAcceptedDate
    ? irsAcceptedDate.valueOf() + SUGGESTION_PERIOD_MS > Date.now()
    : true;
};

const getIsCorrectionPossible = (
  form1099Balance: IMemberClientForm1099Balances
) =>
  (form1099Balance.status === Form1099Status.Rejected ||
    (form1099Balance.status === Form1099Status.Accepted &&
      isOpenSuggestionPeriod(form1099Balance))) &&
  !form1099Balance.correction;

export const isFieldCorrected = (field?: number | string | CompanyStructure) =>
  typeof field !== "undefined";

export default getIsCorrectionPossible;
