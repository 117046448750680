import { IProfitAndLoss } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  WSDateRangeSelectOptions,
  WSGrid,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import {
  useCatchUpTransactionsExpense,
  useProfitAndLoss
} from "../../../query/bookkeeping/queries";
import { useUserId } from "../../../query/hooks/helpers";
import { useAccounts, useActivities } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { DashboardAccountsList } from "../../components/DashboardAccountsList/DashboardAccountsList";
import { HeroText } from "../../components/HeroText/HeroText";
import { Review } from "../../components/Review/Review";
import { Taxes101 } from "../../components/Taxes101/Taxes101";
import { openInNewTab } from "../../../utils/openInNewTab";
import { getHeroRangeSelectLabel } from "../../../shared/components/HeroRangeSelect/HeroRangeSelect";
import { TrackYourIncome } from "../../components/TrackYourIncome/TrackYourIncome";
import { Route, Switch } from "react-router-dom";
import { BookkeepingAddTransactionV2 } from "./BookkeepingAddTransactionV2";
import { isValidDateRange } from "../../utils";
import { useBookkeepingFilters } from "./BookkeepingIndex";

const helpText = (
  <WSText.ParagraphSm mt="XL" align="center">
    Need help? Visit our{" "}
    <WSText.ParagraphSm
      inline
      color="blue500"
      onClick={() =>
        openInNewTab(
          "https://help.wingspan.app/en/articles/4552381-how-does-wingspan-bookkeeping-work"
        )
      }
    >
      bookkeeping help center.
    </WSText.ParagraphSm>
  </WSText.ParagraphSm>
);

export const BookkeepingDashboardV2: React.FC = () => {
  const { filters } = useBookkeepingFilters();

  const userId = useUserId();
  const qCatchUpExpenseTransactions = useCatchUpTransactionsExpense(userId);
  const qAccounts = useAccounts();

  const dateRange = (isValidDateRange(filters.customDateRange)
    ? filters.customDateRange
    : WSDateRangeSelectOptions.LastYear.range) as any;

  const qProfitAndLoss = useProfitAndLoss({
    startDate: dateRange ? dateRange[0] : new Date(),
    endDate: dateRange ? dateRange[1] : new Date()
  });

  const qActivity = useActivities(userId);

  return (
    <>
      <Switch>
        <Route
          path={"/member/bookkeeping/dashboard/add-transaction"}
          component={BookkeepingAddTransactionV2}
          exact
        />
      </Switch>
      <WSQueries
        queries={{
          qCatchUpExpenseTransactions,
          qActivity,
          qAccounts
        }}
      >
        {({ qActivity, qCatchUpExpenseTransactions, qAccounts }) => {
          const profitAndLossData = (qProfitAndLoss.data ||
            {}) as IProfitAndLoss;
          const profitAndLossIsLoading = qProfitAndLoss.isLoading;

          const mainContent = (
            <>
              <HeroText
                dateRangeText={getHeroRangeSelectLabel(dateRange).full}
                isLoading={profitAndLossIsLoading}
                profitAndLoss={profitAndLossData}
              />
              <Review
                profitAndLoss={profitAndLossData}
                activity={qActivity.data}
                expenseCatchUp={qCatchUpExpenseTransactions.data}
              />
              {profitAndLossData.incomeAll === 0 ? <TrackYourIncome /> : null}
            </>
          );

          return (
            <>
              <BrowserPageTitle title="Income & Expenses: Dashboard" />

              <WSScreen.Mobile>
                <WSGrid>
                  <WSGrid.Item>
                    {mainContent}
                    <DashboardAccountsList mb="2XL" accounts={qAccounts.data} />
                    <Taxes101 mb="XL" />
                    {helpText}
                  </WSGrid.Item>
                </WSGrid>
              </WSScreen.Mobile>
              <WSScreen.TabletAndDesktop>
                <WSGrid>
                  <WSGrid.Item span={{ s: "7", m: "7", l: "8" }}>
                    {mainContent}
                  </WSGrid.Item>
                  <WSGrid.Item span={{ s: "5", m: "5", l: "4" }}>
                    <DashboardAccountsList mb="2XL" accounts={qAccounts.data} />
                    <Taxes101 mb="XL" />
                    {helpText}
                  </WSGrid.Item>
                </WSGrid>
              </WSScreen.TabletAndDesktop>
            </>
          );
        }}
      </WSQueries>
    </>
  );
};
