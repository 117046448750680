import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { TaxesEnablingServices } from "./TaxesEnablingServices";
import { TaxesFormExpectedIncome } from "./TaxesFormExpectedIncome";
import { TaxesFormFillingStatus } from "./TaxesFormFillingStatus";
import { TaxesFormState } from "./TaxesFormState";
import { TaxesFormTaxRate } from "./TaxesFormTaxRate";
import { TaxesMain } from "./TaxesMain";
import { TaxesNoPaymentOverview } from "./TaxesOnboardingNoPaymentOverview";
import { TaxesSendToIRS } from "./TaxesSendToIRS";

export type TaxesFormLayoutProps = {
  progress?: number;
  progressLabel?: string;
  submitButton?: string;
  next?: string;
  prev?: string;
  type: "edit" | "onboarding" | "edit-onboarding";
};

export const TaxesIndexRoute: React.FC = () => (
  <Switch>
    <Redirect from="/member/taxes" exact to="/member/taxes/dashboard" />
    <Route
      path="/member/taxes/onboarding/filing-status"
      exact
      render={() => (
        <TaxesFormFillingStatus
          progress={(1 / 3) * 100}
          progressLabel="1/3"
          submitButton="Continue"
          next="/member/taxes/onboarding/expected-income"
          prev="/member/taxes/dashboard"
          type="onboarding"
        />
      )}
    />
    <Route
      path="/member/taxes/onboarding/expected-income"
      exact
      render={() => (
        <TaxesFormExpectedIncome
          progressLabel="2/3"
          submitButton="Continue"
          progress={(2 / 3) * 100}
          prev="/member/taxes/onboarding/filing-status"
          next="/member/taxes/onboarding/state"
          type="onboarding"
        />
      )}
    />

    <Route
      path="/member/taxes/onboarding/state"
      exact
      render={() => (
        <TaxesFormState
          progress={90}
          progressLabel="3/3"
          prev="/member/taxes/onboarding/expected-income"
          next="/member/taxes/onboarding/enabling-services"
          type="onboarding"
        />
      )}
    />

    <Route
      path="/member/taxes/onboarding/enabling-services"
      exact
      component={TaxesEnablingServices}
    />

    <Route
      path="/member/taxes/edit/filing-status"
      exact
      render={() => (
        <TaxesFormFillingStatus
          submitButton="Update"
          prev="/member/taxes/edit/tax-rate"
          next="/member/taxes/edit/tax-rate"
          type="edit"
        />
      )}
    />
    <Route
      path="/member/taxes/edit/expected-income"
      exact
      render={() => (
        <TaxesFormExpectedIncome
          submitButton="Update"
          prev="/member/taxes/edit/tax-rate"
          next="/member/taxes/edit/tax-rate"
          type="edit"
        />
      )}
    />
    <Route
      path="/member/taxes/edit/state"
      exact
      render={() => (
        <TaxesFormState
          submitButton="Calculate recommendation"
          prev="/member/taxes/edit/tax-rate"
          next="/member/taxes/edit/tax-rate"
          type="edit"
        />
      )}
    />

    <Route
      path="/member/taxes/onboarding-edit/filing-status"
      exact
      render={() => (
        <TaxesFormFillingStatus
          submitButton="Update"
          next="/member/taxes/onboarding-edit/expected-income"
          prev="/member/taxes/onboarding/tax-rate"
          type="edit-onboarding"
        />
      )}
    />
    <Route
      path="/member/taxes/onboarding-edit/expected-income"
      exact
      render={() => (
        <TaxesFormExpectedIncome
          submitButton="Update"
          prev="/member/taxes/onboarding/tax-rate"
          next="/member/taxes/onboarding/tax-rate"
          type="edit-onboarding"
        />
      )}
    />
    <Route
      path="/member/taxes/onboarding-edit/state"
      exact
      render={() => (
        <TaxesFormState
          submitButton="Calculate recommendation"
          prev="/member/taxes/onboarding/tax-rate"
          next="/member/taxes/onboarding/tax-rate"
          type="edit-onboarding"
        />
      )}
    />

    <Route
      path="/member/taxes/edit/tax-rate"
      exact
      render={() => <TaxesFormTaxRate editPath="/member/taxes/edit" />}
    />

    <Route
      path="/member/taxes/onboarding/tax-rate"
      exact
      render={() => (
        <TaxesFormTaxRate
          editPath="/member/taxes/onboarding-edit"
          isOnboarding
        />
      )}
    />

    <Route
      path="/member/taxes/onboarding/no-payments"
      exact
      component={TaxesNoPaymentOverview}
    />

    <Route path="/member/taxes/dashboard" exact component={TaxesMain} />
    <Route path="/member/taxes/sent-to-irs" exact component={TaxesSendToIRS} />

    <Redirect from="*" exact to="/member/taxes" />
  </Switch>
);
