import {
  IPayeeTaxFormResponse,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces";

export const selectorTaxFormIsInPostFilingState = (
  taxForm: IPayeeTaxFormResponse
): boolean => {
  const postFilingStatuses = [
    TaxFormStatus.SubmittedToIrs,
    TaxFormStatus.AcceptedByIrs,
    TaxFormStatus.RejectedByIrs
  ];

  return postFilingStatuses.includes(taxForm.status);
};
