import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  useIsMobile,
  WSButton,
  WSButtons,
  WSGrid,
  WSPage,
  WSPreview,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useUpdateAccounts,
  useUpdateActivity
} from "../../../query/users/mutations";
import image1 from "../../../assets/images/bk-feature-1.png";
import image2 from "../../../assets/images/bk-feature-2.png";
import image3 from "../../../assets/images/bk-feature-3.png";
import image4 from "../../../assets/images/bk-feature-4.png";
import { useSetWSStore, useWSStore } from "../../../store";
import { useAccounts, useMemberProfile } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { useBookkeepingStatus } from "../../../query/bookkeeping/queries";
import { AccountStatus } from "@wingspanhq/users/dist/lib/interfaces";
import { useOnQueriesFetched } from "../../../shared/hooks/useOnQueriesFetched";

export const LandingPage: React.FC<RouteComponentProps<any, any, any>> = ({
  history
}) => {
  const isMobile = useIsMobile();
  const userId = useUserId();
  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);
  const { updateAccounts, updateAccountsLoading } = useUpdateAccounts();
  const qAccounts = useAccounts();
  const store = useWSStore();
  const setStore = useSetWSStore();
  const qMember = useMemberProfile(userId);
  const qBookkeepingService = useBookkeepingStatus();

  useOnQueriesFetched(
    () => {
      const memberData = qMember.data;
      const bookkeepingIsEnabled =
        memberData?.profile.taxInfo?.stateOfResidence &&
        memberData?.profile.taxInfo?.filingCode &&
        memberData?.profile.taxInfo?.incomeEstimateForYear &&
        memberData?.profile.taxInfo?.expensesEstimateForYear &&
        qBookkeepingService.data?.enabled;

      if (bookkeepingIsEnabled) {
        history.push("/member/bookkeeping");
      }
    },
    qMember,
    qBookkeepingService
  );

  return (
    <>
      <WSPage>
        <WSGrid>
          <WSGrid.Item span={{ l: "5" }}>
            <WSText.Heading4 mb="M">
              Bookkeeping you can bank on.
            </WSText.Heading4>
            <WSText mb={isMobile ? "XL" : "3XL"}>
              Wingspan’s bookkeeping tools help you minimize taxes and hang onto
              more of your money.
            </WSText>
          </WSGrid.Item>
        </WSGrid>

        <WSQueries queries={{ qAccounts }}>
          {({ qAccounts }) => (
            <>
              <WSButtons mb={isMobile ? "XL" : "3XL"}>
                <WSButton.Primary
                  loading={
                    updateActivityMeta.isLoading || updateAccountsLoading
                  }
                  onClick={async () => {
                    const bookkeepingAccounts = qAccounts.data.filter(
                      a =>
                        a.canBeUsedFor?.bookkeeping &&
                        a.institutionId &&
                        a.status !== AccountStatus.Inactive
                    );

                    if (bookkeepingAccounts?.length) {
                      await updateActivity({
                        flows: {
                          bookkeepingSetup: {
                            version: 4,
                            currentStep: 2,
                            totalSteps: 6,
                            complete: false
                          }
                        }
                      });

                      history.push(
                        `/member/bookkeeping/setup/import/plaid/${bookkeepingAccounts[0].institutionId}/sync`
                      );
                    } else {
                      await updateActivity({
                        flows: {
                          bookkeepingSetup: {
                            version: 4,
                            totalSteps: 6,
                            currentStep: 1,
                            complete: false
                          }
                        }
                      });
                      history.push("/member/bookkeeping/setup/import/start");
                    }
                  }}
                  data-testid="letsGetStarted"
                >
                  Set up Income & Expenses
                </WSButton.Primary>
                {store.productActivationAfterOnboarding && (
                  <WSButton.Secondary
                    onClick={() => {
                      setStore({ productActivationAfterOnboarding: false });
                      history.push("/member/dashboard");
                    }}
                  >
                    Explore Wingspan
                  </WSButton.Secondary>
                )}
              </WSButtons>
            </>
          )}
        </WSQueries>

        <WSPreview
          list={[
            {
              title: "Net paycheck",
              description:
                "Break out your income like a paystub — and get a clear picture of where each dollar goes.",
              image: image1
            },
            {
              title: "Expense tracking",
              description:
                "Track deductible expenses year-round, so you don’t have to go through a shoebox of receipts at tax time.",
              image: image2
            },
            {
              title: "Tax planning and compliance",
              description:
                "Wingspan tells you how much to set aside as you get paid, so you'll be covered come tax time.",
              image: image3
            },
            {
              title: "Automated quarterly filings ",
              description:
                "File quarterly tax documents and payments with a few clicks. Letters from the IRS are a thing of the past.",
              image: image4
            }
          ]}
        />
      </WSPage>
    </>
  );
};
