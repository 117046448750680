import {
  ITaxFormCorrectionRequest,
  ITaxFormCorrectionResponse
} from "@wingspanhq/payments/dist/interfaces";
import { WSServiceError } from "../../../utils/serviceHelper";
import { WSMutationConfig, useWSMutation } from "../../helpers";
import { paymentsService } from "../../../services/payments";
import {
  QUERY_PAYMENTS_TAXFORM_LIST_PAYEE,
  QUERY_PAYMENTS_TAXFORM_PAYEE
} from "../keys";

export const useTaxFormRequestCorrection = (
  config?: WSMutationConfig<
    ITaxFormCorrectionResponse,
    WSServiceError,
    { taxFormId: string; requestData: ITaxFormCorrectionRequest }
  >
) =>
  useWSMutation(
    ({ taxFormId, requestData }) =>
      paymentsService.taxForm.createCorrection(taxFormId, requestData),
    {
      dependencies: [
        QUERY_PAYMENTS_TAXFORM_PAYEE,
        QUERY_PAYMENTS_TAXFORM_LIST_PAYEE
      ],
      ...config
    }
  );
