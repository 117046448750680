import {
  WSButton,
  WSElement,
  WSFlexBox
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { EmptyState } from "../../../../../shared/components/EmptyState";
import { useAuthorizedScopeGroups } from "../../../../../shared/utils/teamUtils";

export const TableNoDataMessage: React.FC<{
  search?: string;
  isFiltered?: boolean;
}> = ({ search, isFiltered }) => {
  const history = useHistory();
  const { hasAdminScope } = useAuthorizedScopeGroups();

  if (search || isFiltered) {
    return (
      <WSElement>
        <EmptyState
          title={search ? `No results for ‘${search}’` : `No results`}
          description={`Clear search and filters to see all items`}
        />

        <WSFlexBox.Center mt="XL">
          <WSButton.Tertiary
            onClick={() => {
              history.push(history.location.pathname);
            }}
          >
            Clear search and filters
          </WSButton.Tertiary>
        </WSFlexBox.Center>
      </WSElement>
    );
  }

  return (
    <WSElement>
      <EmptyState
        title={`Nothing's here yet.`}
        description={`No 1099 forms found.`}
      />

      <WSFlexBox.Center mt="XL">
        {hasAdminScope ? (
          <WSButton.Tertiary
            onClick={() => {
              history.push("/member/1099-filing/batch-add-recipients");
            }}
          >
            Begin 1099 filing
          </WSButton.Tertiary>
        ) : null}
      </WSFlexBox.Center>
    </WSElement>
  );
};
