import {
  WSButton,
  WSElement,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import { IClientInvoice } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { CertifiedSecureDigitalPaymentsTooltip } from "../../../components/CertifiedSecureDigitalPaymentsTooltip/CertifiedSecureDigitalPaymentsTooltip";
import { openInNewTab } from "../../../shared/utils/openInNewTab";
import { Header } from "../Header/Header";
import { HelpButton } from "../HelpButton/HelpButton";
import styles from "./ClientPaymentsWrapper.module.scss";

export type ClientPaymentsWrapperProps = {
  clientInvoice?: IClientInvoice;
  companyName: string;
  companyLogoUrl?: string;
  memberName?: string;
  clientEmail?: string;
  onBack?: () => void;
};

export const WingspanIsPCICertifiedSecure: React.FC<{ name: string }> = ({
  name
}) => (
  <WSText color="gray600">
    {name} invited you to pay securely through{" "}
    <WSButton.Link
      onClick={() => {
        openInNewTab("https://www.wingspan.app/get-paid");
      }}
    >
      Wingspan
    </WSButton.Link>
    . <b>Wingspan is PCI Certified Secure.</b>
    <CertifiedSecureDigitalPaymentsTooltip ml="XS" />
  </WSText>
);

export const ClientPaymentsWrapper: React.FC<ClientPaymentsWrapperProps> = ({
  clientInvoice,
  companyName,
  companyLogoUrl,
  memberName,
  clientEmail,
  onBack,
  children
}) => {
  return (
    <WSElement className={styles.wrapper}>
      <WSScreen.TabletAndDesktop>
        <HelpButton />
      </WSScreen.TabletAndDesktop>

      <Header
        clientInvoice={clientInvoice}
        companyName={companyName}
        companyLogoUrl={companyLogoUrl}
        clientEmail={clientEmail}
      />

      <WSElement className={styles.headerBanner} py="M" px="XL">
        <WingspanIsPCICertifiedSecure name={memberName || companyName} />
      </WSElement>

      <WSElement as="main" className={styles.main}>
        <WSElement className={styles.mainContainer}>
          {onBack && (
            <WSButton.Link
              icon="chevron-left"
              onClick={onBack}
              className={styles.backButton}
            >
              Back
            </WSButton.Link>
          )}
          {children}
        </WSElement>
      </WSElement>
    </WSElement>
  );
};
