import {
  detachLayoutProps,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSImage,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import * as React from "react";
import styles from "./WSVirtualAssistant.module.scss";

interface WSVirtualAssistantProps extends WSElementProps {
  message: string;
}

export const WSVirtualAssistant: React.FC<WSVirtualAssistantProps> = ({
  message,
  ...otherProps
}) => {
  const { layoutProps } = detachLayoutProps(otherProps);
  return (
    <>
      <WSScreen.Mobile>
        <WSFlexBox wrap="nowrap" {...layoutProps} mb="3XL">
          <WSImage
            src={
              "https://storage.googleapis.com/wingspan-public-content/member_advocate_headshot_anthony_mironov.svg"
            }
            placeholder=""
            className={styles.photo}
            mr="M"
          />
          <WSElement className={styles.card}>
            <WSText>{message}</WSText>
          </WSElement>
        </WSFlexBox>
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        <WSFlexBox.CenterY
          wrap="nowrap"
          className={styles.card}
          {...layoutProps}
        >
          <WSImage
            src={
              "https://storage.googleapis.com/wingspan-public-content/member_advocate_headshot_anthony_mironov.svg"
            }
            placeholder=""
            className={styles.photo}
            mr="XL"
          />
          <WSElement>
            <WSText>{message}</WSText>
          </WSElement>
        </WSFlexBox.CenterY>
      </WSScreen.TabletAndDesktop>
    </>
  );
};
