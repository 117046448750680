import {
  useIsMobile,
  useModalContext,
  WSButton,
  WSGrid,
  WSPage,
  WSPreview,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import image1 from "../../assets/images/benefits-landing-preview-desktop-v1.png";
import image2 from "../../assets/images/benefits-landing-preview-desktop-v2.png";
import image3 from "../../assets/images/benefits-landing-preview-desktop-v3.png";
import image4 from "../../assets/images/benefits-landing-preview-desktop-v4.png";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { WSPersistentUpgradeButton } from "../../components/Membership/WSPersistentUpgradeButton";
import { useUserId } from "../../query/hooks/helpers";
import { useUpdateActivity } from "../../query/users/mutations";
import { WSFrontendFeature } from "../../Settings/utils/subscriptionUtils";
import styles from "./Benefits.module.scss";
import { useSubscriptionV3Query } from "../../query/subscriptions/queries";
import {
  BENEFITS_START_FREE_TRIAL,
  BenefitsStartFreeTrial
} from "../modals/BenefitsFreeTrialStarted";

export const BenefitsSetUpStep1: React.FC<RouteComponentProps> = ({
  history
}) => {
  const isMobile = useIsMobile();
  const userId = useUserId();
  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);
  const qSubscription = useSubscriptionV3Query(userId);
  const { openModal } = useModalContext();

  const setUp = async () => {
    await updateActivity({
      flows: {
        benefitsSetup: {
          version: 2,
          totalSteps: 4,
          currentStep: 1,
          complete: false
        }
      }
    });
    history.push("/member/benefits/set-up/2");
  };

  useBrowserPageTitle("Benefits - Benefits overview");

  return (
    <WSPage>
      <BenefitsStartFreeTrial />
      <WSGrid>
        <WSGrid.Item span={{ l: "6" }}>
          <WSText.Heading4 mb="M">Member benefits & perks</WSText.Heading4>
          <WSText mb={isMobile ? "XL" : "3XL"}>
            Get the coverage you need and the perks you want with custom
            packages for you and your family.
          </WSText>

          {qSubscription.data ? (
            <WSPersistentUpgradeButton
              loading={updateActivityMeta.isLoading}
              onClick={setUp}
              name="setUpBenefits"
              feature={WSFrontendFeature.EnrollBenefits}
            >
              Set up Benefits
            </WSPersistentUpgradeButton>
          ) : (
            <WSButton.Primary
              onClick={() => openModal(BENEFITS_START_FREE_TRIAL, { setUp })}
            >
              Set Up Benefits
            </WSButton.Primary>
          )}
        </WSGrid.Item>
      </WSGrid>

      <WSPreview
        className={styles.previewImage}
        mt={isMobile ? "2XL" : "5XL"}
        list={[
          {
            title: "Save money on medical bills",
            description:
              "Skip the doctor and get free 24/7 access to virtual care, 1:1 support with insurance bills, and Rx savings.",
            image: image1
          },
          {
            title: "Legal and financial support",
            description:
              "Get help with legal matters and work with a financial wellness coach to guide you through your goals.",
            image: image2
          },
          {
            title: "Mind and body support",
            description:
              "Get discounts on alternative medicine and chiropractic services. Therapy benefits coming soon!",
            image: image3
          },
          {
            title: "Medical, dental, and vision insurance",
            description:
              "By banding with other Wingspan members, you’re getting access to the best rates possible.",
            image: image4
          }
        ]}
      />
    </WSPage>
  );
};
