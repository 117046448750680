import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { FormAddDebitCard } from "../../../../shared/components/FormAddDebitCard";
import { LayoutFullscreen } from "../../../../shared/components/LayoutFullscreen";

export const RouteAddInstantDebitCard: React.FC<RouteComponentProps> = () => {
  const history = useHistory();

  return (
    <LayoutFullscreen
      headTitle="Add payout method"
      onBack={() => {
        history.push("/member/settings/payment-methods/add-instant");
      }}
      narrow
    >
      <FormAddDebitCard
        setInstantPayoutPreference
        onSuccess={() => {
          history.push("/member/settings/payment-methods");
        }}
      />
    </LayoutFullscreen>
  );
};
