import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSSidebar,
  WSText
} from "@wingspanhq/fe-component-library";
import cn from "classnames";
import React, { ReactNode } from "react";
import styles from "./DetailsLayout.module.scss";

export type DetailsLayoutProps = {
  onBack: () => void;
  title: string;
};

export type DetailsLayoutCardProps = {
  title?: ReactNode;
  titleRight?: ReactNode;
} & WSElementProps;

interface DetailsLayoutComponent extends React.FC<DetailsLayoutProps> {
  Card: React.FC<DetailsLayoutCardProps>;
}

export const DetailsLayout: DetailsLayoutComponent = ({
  children,
  onBack,
  title
}) => {
  return (
    <WSSidebar
      onClose={onBack}
      header={
        <WSText.Paragraph
          data-testid="invoiceDetailsHeader"
          singleLine
          mr="2XL"
        >
          {title}
        </WSText.Paragraph>
      }
    >
      {children}
    </WSSidebar>
  );
};

DetailsLayout.Card = ({
  children,
  title,
  titleRight,
  className,
  ...elementProps
}) => (
  <WSElement className={cn(styles.card, className)} {...elementProps}>
    {title && (
      <WSFlexBox
        className={styles.cardHeader}
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <WSText.Heading5>{title}</WSText.Heading5>
        {titleRight && <WSElement>{titleRight}</WSElement>}
      </WSFlexBox>
    )}
    <WSElement className={styles.main}>{children}</WSElement>
  </WSElement>
);
