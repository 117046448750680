import React from "react";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces/account";
import { useDeleteAccount } from "../../../query/users/mutations";
import {
  useModalContext,
  useWSSnackbar,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";

export const DELETE_INSTITUTION_MODAL = "DELETE_INSTITUTION_MODAL";

export const DeleteInstitution: React.FC = () => {
  const [deleteAccount, deleteAccountMeta] = useDeleteAccount();
  const { closeModal } = useModalContext();
  const { openSnackbar } = useWSSnackbar();

  return (
    <WSModal size="S" name={DELETE_INSTITUTION_MODAL}>
      {(accounts: IAccount[]) => {
        const institutionName = accounts[0]?.institution;
        const accountsCount = accounts.length;
        const postfix = accountsCount > 1 ? "s" : "";
        const entity = "account" + postfix;

        return (
          <>
            <WSText.Heading5 mb="M">Delete {institutionName}</WSText.Heading5>
            <WSText mb="M">
              We've imported transactions across {accountsCount}{" "}
              {institutionName} {entity}.
            </WSText>
            <WSText mb="M">Would you like to delete those transactions?</WSText>
            <WSText mb="2XL">
              Once confirmed, this action cannot be undone.
            </WSText>

            <WSButtons forceFullWidth format="modal">
              <WSButton
                destructive
                loading={deleteAccountMeta.isLoading}
                data-testid="removeInstitution"
                onClick={async () => {
                  await Promise.all(
                    accounts.map(account =>
                      deleteAccount({
                        accountId: account.accountId,
                        deleteTransactions: true
                      })
                    )
                  );

                  openSnackbar({
                    message: `${accountsCount} ${entity} deleted`,
                    type: "success"
                  });

                  closeModal(DELETE_INSTITUTION_MODAL);
                }}
              >
                Delete Transactions
              </WSButton>
              <WSButton
                destructive
                loading={deleteAccountMeta.isLoading}
                onClick={async () => {
                  await Promise.all(
                    accounts.map(account =>
                      deleteAccount({ accountId: account.accountId })
                    )
                  );

                  openSnackbar({
                    message: `${accountsCount} ${entity} deleted`,
                    type: "success"
                  });

                  closeModal(DELETE_INSTITUTION_MODAL);
                }}
              >
                Keep Transactions
              </WSButton>
              <WSButton.Secondary
                onClick={() => {
                  closeModal(DELETE_INSTITUTION_MODAL);
                }}
              >
                Cancel
              </WSButton.Secondary>
            </WSButtons>
          </>
        );
      }}
    </WSModal>
  );
};
