import cn from "classnames";
import { InputNumber, InputNumberProps } from "primereact/inputnumber";
import React from "react";
import { toCamelCase } from "../../../../utils";
import {
  WSElement,
  WSElementCustomProps,
  WSElementLayoutProps,
  detachLayoutProps
} from "../../WSElement/WSElement.component";
import { WSIcon, WSIconName } from "../WSIcon/WSIcon.component";
import styles from "./WSTextInput.module.scss";

export interface WSInputNumberPropsOld
  extends Omit<InputNumberProps, "pt" | "size" | "color">,
    WSElementLayoutProps,
    WSElementCustomProps {
  icon?: WSIconName;
  inputClassName?: string;
  error?: boolean;
}

export const WSInputNumberOld = React.forwardRef<
  InputNumber,
  WSInputNumberPropsOld
>((props, ref) => {
  const {
    icon,
    error,
    className,
    inputClassName: customInputClassName,
    systemProps,
    layoutProps,
    ...otherProps
  } = detachLayoutProps(props);

  const inputClassName = cn(styles.input, customInputClassName);
  const testId = otherProps.name
    ? toCamelCase(otherProps.name, "field")
    : undefined;

  const containerClassName = cn(styles.inputContainer, className, {
    [styles.error]: error,
    [styles.disabled]: otherProps.disabled
  });

  return (
    <WSElement {...systemProps} className={containerClassName} {...layoutProps}>
      {icon && <WSIcon block name={icon} className={styles.icon} size="XS" />}

      <InputNumber
        ref={ref}
        {...otherProps}
        data-testid={testId}
        pt={{ input: { root: { className: inputClassName } } }}
      />
    </WSElement>
  );
});

WSInputNumberOld.displayName = "WSInputNumber";
