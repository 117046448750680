import {
  WSActions,
  WSButton,
  WSForm,
  WSInfoBox,
  WSInputMask,
  WSList,
  WSLoader,
  WSModal,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { PhoneVerificationStatus } from "@wingspanhq/users/dist/lib/interfaces";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  useBeginPhoneVerification,
  useCompletePhoneVerification
} from "../../../../query/users/mutations";
import { useUserProfile } from "../../../../query/users/queries";
import { ErrorContextKey } from "../../../../services/platform";
import { validatorOTP } from "../../../../shared/validators/validatorOTP";
import {
  FormDataPhoneNumber,
  FormPartialPhoneNumber,
  validationSchemaPhoneNumber
} from "../../components/FormPartialPhoneNumber";
import { OnboardingStep } from "../../types";

export const SecureAccount: React.FC<OnboardingStep> = ({ onNext }) => {
  const userId = useUserId();
  const queryUserProfile = useUserProfile(userId);
  const phoneVerificationStatus = queryUserProfile.data?.phone.status;
  const history = useHistory();
  const createAcccountPath = history.location.pathname.replace("/secure", "");

  const [
    beginPhoneVerification,
    beginPhoneVerificationMeta
  ] = useBeginPhoneVerification(userId);

  const [
    completePhoneVerification,
    completePhoneVerificationMeta
  ] = useCompletePhoneVerification(userId);

  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);

  useEffect(() => {
    if (phoneVerificationStatus === PhoneVerificationStatus.Verified) {
      // Using replace to prevent history break. This step is being skipped, so it should not be in the history stack
      history.replace(createAcccountPath + "/account-type");
    }
  }, [phoneVerificationStatus, onNext, history, createAcccountPath]);

  if (queryUserProfile.isLoading) {
    return <WSLoader.Spinner />;
  }

  return (
    <>
      {phoneNumber && (
        <WSModal
          title="Verify your phone number"
          size="S"
          onClose={() => {
            setPhoneNumber(phoneNumber);
          }}
        >
          <WSForm<{
            otp: string;
          }>
            onSubmit={data => {
              completePhoneVerification(
                { code: data.otp },
                { onSuccess: onNext }
              );
            }}
            validationSchema={Yup.object().shape({
              otp: validatorOTP
            })}
          >
            <WSList gap="L">
              <WSInfoBox>
                Enter the 6-digit code sent to {phoneNumber}
              </WSInfoBox>

              <WSForm.Field
                name="otp"
                component={WSInputMask}
                componentProps={{
                  mask: "999999"
                }}
              />
              <WSErrorMessage
                error={completePhoneVerificationMeta.error}
                contextKey={ErrorContextKey.VerifyPhone}
              />

              <WSActions
                alignment="fill"
                orientation="vertical"
                buttons={[
                  {
                    kind: "Link",
                    label: "Resend code",
                    onClick: () => setPhoneNumber(null),
                    type: "button"
                  },
                  {
                    label: "Continue",
                    loading: completePhoneVerificationMeta.isLoading
                  }
                ]}
              />
            </WSList>
          </WSForm>
        </WSModal>
      )}

      <WSForm<{
        phone: FormDataPhoneNumber;
      }>
        onSubmit={data => {
          const phoneNumber = `+${data.phone.countryCode}${data.phone.phoneNumber}`;

          beginPhoneVerification(
            {
              phone: phoneNumber
            },
            {
              onSuccess: () => {
                setPhoneNumber(phoneNumber);
              },
              onError: err => {
                // Handle existing account
              }
            }
          );
        }}
        defaultValues={{
          phone: {
            countryCode: "1",
            phoneNumber: ""
          }
        }}
        validationSchema={Yup.object().shape({
          phone: validationSchemaPhoneNumber
        })}
      >
        <WSList gap="2XL">
          <WSPanel>
            <WSSectionToolbar title="Secure your account" />

            <WSList gap="2XL">
              <WSInfoBox>
                A 6 digit verification code will be sent via SMS to your phone
                number. Message and data rates may apply.
              </WSInfoBox>

              <FormPartialPhoneNumber name="phone" label="Phone number" />

              <WSErrorMessage
                error={beginPhoneVerificationMeta.error}
                contextKey={ErrorContextKey.VerifyPhone}
              />
            </WSList>
          </WSPanel>
          <WSButton
            size="M"
            fullWidth
            loading={beginPhoneVerificationMeta.isLoading}
          >
            Send code
          </WSButton>

          <WSText.ParagraphXs color="gray400">
            The information on this page is required to setup your Wingspan
            account, if you exit or leave without completing this step, you will
            return to this page the next time you sign-in
          </WSText.ParagraphXs>
        </WSList>
      </WSForm>
    </>
  );
};
