import { WSElement } from "@wingspanhq/fe-component-library";
import React from "react";
import { LogoApp } from "../../../shared/components/LogoApp";
import styles from "./BenefitsLogoHeader.module.scss";

export const BenefitsLogoHeader: React.FC<{}> = ({}) => {
  return (
    <WSElement className={styles.logo}>
      <LogoApp mt="XL" />
    </WSElement>
  );
};
