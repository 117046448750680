import React from "react";
import { WSAvatar, WSAvatarProps } from "../common/WSAvatar/WSAvatar.component";
import { WSButton, WSButtonProps } from "../core/WSButton/WSButton.component";
import { WSText } from "../core/WSText/WSText.component";
import { WSElement } from "../WSElement/WSElement.component";
import {
  WSTooltipIcon,
  WSTooltipIconProps
} from "../WSTooltipIcon/WSTooltipIcon";
import styles from "./Label.module.scss";

const DEFAULT_AVATAR_SIZE = "S";

export type WSDataItemLabelProps = {
  label?: string;
  labelAvatar?: WSAvatarProps;
  action?: WSButtonProps<"Link">;
  helperText?: string;
  tooltip?: WSTooltipIconProps["tooltip"];
};

export const Label: React.FC<WSDataItemLabelProps> = ({
  label,
  labelAvatar,
  action,
  tooltip,
  helperText
}) => {
  const labelElement = (
    <WSText kind="ParagraphSm" color="gray500">
      {label}
    </WSText>
  );

  const helperTextElement = helperText ? (
    <WSText kind="ParagraphXs" color="gray400">
      {helperText}
    </WSText>
  ) : undefined;

  return (
    <WSElement className={styles.labelWrapper}>
      <WSElement className={styles.label}>
        {labelAvatar && (
          <WSAvatar size={DEFAULT_AVATAR_SIZE} {...labelAvatar} />
        )}

        {helperTextElement ? (
          <WSElement>
            {labelElement}
            {helperTextElement}
          </WSElement>
        ) : (
          labelElement
        )}

        {tooltip && <WSTooltipIcon tooltip={tooltip} />}
      </WSElement>

      {action && <WSButton.Link {...action} />}
    </WSElement>
  );
};
