import { getInvoiceIdFromCPEPath } from "../ClientPayments/utils";
import {
  getAdminSessionToken,
  getRequestingUserSessionUserId
} from "../services/sessionStorage";
import { BUILD_ENV, IS_DEV_ENV } from "../shared/constants/environment";
import { localStorageUtil } from "./localStorage";

export const loadSegment = () => {
  try {
    const shouldSend = shouldSendAnalyticsCall();
    if (
      shouldSend &&
      process.env.REACT_APP_SEGMENT_API_KEY &&
      window.analytics &&
      window.analytics.load
    ) {
      window.analytics.load(process.env.REACT_APP_SEGMENT_API_KEY);

      // Fire first page event
      page();
    }
  } catch (error) {
    if (IS_DEV_ENV || window.debugSegment || window.TEST_ENV) {
      console.error("Failed loading Segment.io");
      console.error(error);
    }
  }
};

export const SEGMENT_NOT_LOADED = "SEGMENT_NOT_LOADED";

export const getSegmentAnonymousIdFromLocalStorage = (): string => {
  const id =
    localStorageUtil.getItem("ajs_anonymous_id") || `"${SEGMENT_NOT_LOADED}"`;
  return id.split('"')[1];
};

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

function shouldSendAnalyticsCall() {
  if (window.forceSendSegment) {
    return true;
  }

  if (IS_DEV_ENV || window.TEST_ENV) {
    return false;
  }

  const isAdminSession = !!getAdminSessionToken();
  const isUserEmailIsWingspanRelated =
    window.userEmail && window.userEmail.includes("wingspan.app");

  return !isUserEmailIsWingspanRelated && !isAdminSession;
}

const buildEnv = BUILD_ENV;
const buildDate = new Date(
  `${process.env.REACT_APP_BUILD_TIMESTAMP}`
).toLocaleString(undefined, { hour12: false });
const buildCommit = process.env.REACT_APP_GIT_LATEST_COMMIT;

function getCommonFields() {
  const deviceType = getDeviceType();
  return {
    context: {
      wingspan: {
        // enabledFeatureFlags: getEnabledFeatureFlags(state),
        deviceType,
        buildEnv,
        buildDate,
        buildCommit
      }
    }
  };
}

export function page(
  name: string | undefined = undefined,
  properties: any = {}
) {
  const customProperties = {
    invoiceId: getInvoiceIdFromCPEPath(window.location.pathname)
  };
  const requestingUserId = getRequestingUserSessionUserId();
  const commonPageProps = {
    ...(requestingUserId ? { requestingUserId } : {})
  };
  const props = [
    name,
    { ...commonPageProps, ...properties, ...customProperties },
    getCommonFields()
  ];
  const shouldSend = shouldSendAnalyticsCall();
  if (IS_DEV_ENV || window.debugSegment || window.TEST_ENV) {
    console.info(
      `Analytics Page call for ${getSegmentAnonymousIdFromLocalStorage()} ${
        shouldSend ? "sent" : "not sent"
      }: ${JSON.stringify([...props])}`
    );
  }

  if (shouldSend) {
    window.analytics?.page(...props);
  }
}

export function identifyWithCommonFields(
  userId: string | undefined = undefined,
  traits: any = {}
) {
  try {
    const shouldSend = shouldSendAnalyticsCall();
    const props = [
      userId,
      {
        ...traits
        // ...getAdditionalIdentifyTraits()
      },
      {
        ...getCommonFields(),
        integrations: {
          intercom: {
            user_hash: window.intercomUserHash
          }
        }
      },
      () => {}
    ];
    if (IS_DEV_ENV || window.debugSegment || window.TEST_ENV) {
      console.info(
        `Analytics Identify call for ${getSegmentAnonymousIdFromLocalStorage()} ${
          shouldSend ? "sent" : "not sent"
        }: ${JSON.stringify([...props])}`
      );
    }
    if (shouldSend) {
      window.analytics?.identify(...props);
    }
  } catch (error) {
    console.error("IDENTIFY ERROR:", error);
  }
}

export function identify(userId?: string, traits: any = {}) {
  const shouldSend = shouldSendAnalyticsCall();
  const props = [userId, traits];
  if (IS_DEV_ENV || window.debugSegment || window.TEST_ENV) {
    console.info(
      `Analytics Identify call for ${getSegmentAnonymousIdFromLocalStorage()} ${
        shouldSend ? "sent" : "not sent"
      }: ${JSON.stringify([...props])}`
    );
  }
  if (shouldSend) {
    window.analytics?.identify(...props);
  }
}

export function track(
  event: string | undefined = undefined,
  properties: any = {}
) {
  const shouldSend = shouldSendAnalyticsCall();
  const commonFields = getCommonFields();
  const requestingUserId = getRequestingUserSessionUserId();
  const commonTrackProps = {
    ...(requestingUserId ? { requestingUserId } : {})
  };
  const props = [event, { ...commonTrackProps, ...properties }, commonFields];
  if (IS_DEV_ENV || window.debugSegment || window.TEST_ENV) {
    console.info(
      `Analytics Track call for ${getSegmentAnonymousIdFromLocalStorage()} ${
        shouldSend ? "sent" : "not sent"
      }: ${JSON.stringify([...props])}`
    );
  }

  if (shouldSend) {
    window.analytics?.track(...props);
  }
}

export function reset() {
  // just wrap the function in our own fun(ction)
  if (IS_DEV_ENV || window.debugSegment || window.TEST_ENV) {
    console.info(`Analytics reset`);
    console.info("Analytics anon id", getSegmentAnonymousIdFromLocalStorage());
  }
  window.analytics?.reset();
}

export function trackEarlyAccessInterest() {
  track("Early Access Interest");
}

export function trackTemplateRequest(message: string) {
  track("Template Request", { message });
}

export function trackInternationalUsers(
  email: string,
  isoCode: string,
  countryName: string
) {
  track("International Request", { email, isoCode, countryName });
}

export function group(groupId: string, traits?: any) {
  const shouldSend = shouldSendAnalyticsCall();

  if (shouldSend) {
    window.analytics?.group(groupId, traits);
  }
}
