import {
  ITransaction,
  TransactionStatus,
  TransactionType
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  toWSDate,
  toWSDateString,
  WSFilters,
  WSInfiniteScroll,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSTableItem,
  WSText
} from "@wingspanhq/fe-component-library";
import { flatten } from "lodash";
import { Route, Switch } from "react-router-dom";
import {
  getTransactionAvatarProps,
  getTransactionName
} from "../../../Bookkeeping/utils";
import { WalletTransaction } from "./WalletTransaction";
import {
  TransactionParams,
  usePendingBankingTransactions,
  useTransactions,
  useTransactionsSummary
} from "../../../query/bookkeeping/queries";
import { useUrlQueryFilters } from "../../../utils/router";
import { TablePendingTransactions } from "../components/TablePendingTransactions";
import React from "react";
import { WSFilterDateRangeOption } from "@wingspanhq/fe-component-library/dist/lib/components/WSFilters/types";
import { PendingTransactionDetails } from "../components/PendingTransactionDetails";
import { AmountColumn } from "../components/AmountColumn";
import { WSCategory } from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";
import { useCards } from "../hooks/useCards";

const dateRangeMap = {
  all: "ALL",
  custom: "CUSTOM",
  pastWeek: "PAST_WEEK",
  pastMonth: "PAST_MONTH",
  yearToDate: "PAST_YEAR"
};

type FiltersType = {
  dateRange?: WSFilterDateRangeOption;
  customDueDateRange?: string[];
};

type TableItem = {
  source: ITransaction;
  merchant: string;
  date: Date;
  type: TransactionType;
  amount: number;
  receipt?: string;
  business: boolean;
  category?: WSCategory;
  subcategory?: string | boolean | null;
  account?: string;
};

export const defaultWalletFilters: FiltersType & { search?: string } = {
  // dateRange: "all",
  // customDueDateRange: [],
  // search: ""
};

const columns: Array<WSTableColumn> = [
  {
    config: {
      header: { text: "Merchant" },
      gridTemplateSizeMax: "0.5fr"
    },
    renderFunction: rowData => (
      <WSTableCell
        avatar={getTransactionAvatarProps(rowData.data.source)}
        text={rowData.data.merchant}
        pill={rowData.data.business ? undefined : { text: "Ignored" }}
        secondaryTextColor={
          rowData.data.business &&
          rowData.data.type !== TransactionType.Transfer
            ? "gray600"
            : "gray400"
        }
        secondaryText={toWSDateString(rowData.data.date, "monthDayYear")}
      />
    )
  },
  {
    config: {
      hideOnScreens: ["XS", "S", "M"],
      header: { text: "Description" },
      gridTemplateSizeMax: "0.5fr"
      // justify: "end"
    },
    renderFunction: rowData => (
      <WSTableCell
        text={
          rowData.data.category
            ? `${rowData.data.category}${
                rowData.data.subcategory ? ` - ${rowData.data.subcategory}` : ""
              }`
            : rowData.data.type
        }
      />
    )
  },
  {
    config: {
      hideOnScreens: ["XS"],
      header: { text: "Account" },
      gridTemplateSizeMax: "0.2fr"
      // justify: "end"
    },
    renderFunction: rowData => (
      <WSTableCell
        text={rowData.data.account ? `•••${rowData.data.account}` : "--"}
      />
    )
  },
  {
    config: {
      // justify: "end",
      header: { text: "Amount" },
      gridTemplateSizeMax: "0.1fr"
    },
    renderFunction: rowData => (
      <AmountColumn transaction={rowData.data.source} />
    )
  }
];

export const RouteTransactions: React.FC = () => {
  const {
    defaultFilters,
    filters,
    changedFilters,
    setFilters,
    redirectWithFilters
  } = useUrlQueryFilters(defaultWalletFilters);

  const { search, ...params } = filters;
  const queryPendingTransactions = usePendingBankingTransactions();

  const requestParams: TransactionParams = {
    sort: {
      date: "desc"
    },
    dateRange: dateRangeMap[
      params.dateRange as keyof typeof dateRangeMap
    ] as any,
    category: "ALL",
    customDateRange: params.customDueDateRange as any,
    pageSize: 10,
    status: [TransactionStatus.Active, TransactionStatus.Inactive],
    type: [
      TransactionType.Expense,
      TransactionType.Income,
      TransactionType.Transfer,
      TransactionType.ATMTransaction,
      TransactionType.Unknown
    ],
    nonBusiness: true,
    businessBanking: true
  };

  const queryTransactionsSummary = useTransactionsSummary(requestParams);
  const queryTransactions = useTransactions(requestParams);
  const cards = useCards();
  const transactionsList = flatten(queryTransactions.data || []);
  const pendingTransactions = queryPendingTransactions.data || [];
  const transactionsListMapped: WSTableItem<TableItem>[] = transactionsList.map(
    transaction => {
      const card = cards.find(
        card => card.cardId === transaction.wingspanCardId
      );

      return {
        data: {
          source: transaction,
          merchant: getTransactionName(transaction),
          date: transaction.date,
          type: transaction.type,
          amount: transaction.amount,
          receipt: transaction.receiptFileId,
          business: true,
          category: transaction.wsCategory,
          subcategory: transaction.labels.subcategory,
          account: card?.last4Digits
        },
        id: transaction.transactionId
      };
    }
  );

  return (
    <>
      <Switch>
        <Route
          path="/member/wallet/transactions/pending/:transactionId"
          component={PendingTransactionDetails}
        />
        <Route
          path="/member/wallet/transactions/:transactionId"
          component={WalletTransaction}
        />
      </Switch>

      <WSFilters<FiltersType>
        my="L"
        values={params}
        onFilter={(newValues: any) => {
          setFilters(
            typeof newValues.dateRange === "string"
              ? {
                  search,
                  dateRange: newValues.dateRange,
                  customDueDateRange: []
                }
              : newValues.dateRange
              ? {
                  search,
                  dateRange: "custom",
                  customDueDateRange: [
                    newValues.dateRange.start,
                    newValues.dateRange.end
                  ]
                }
              : {}
          );
        }}
        info={{
          count:
            pendingTransactions.length +
            (queryTransactionsSummary.data?.listSize || 0)
        }}
        primaryFilters={[
          {
            name: "dateRange",
            nonExpandable: true,
            type: "daterange",
            options: ["all", "pastWeek", "pastMonth", "yearToDate", "custom"]
          }
        ]}
      />

      <WSTable showHeader={true} my="XL" columns={columns} tableData={[]} />

      {Object.keys(changedFilters).length ? null : (
        <TablePendingTransactions pendingTransactions={pendingTransactions} />
      )}

      <WSInfiniteScroll
        onLoad={() => {
          queryTransactions.fetchMore();
        }}
        loadMore={transactionsList.length > 0}
        endOfList={!queryTransactions.canFetchMore}
        loading={!!queryTransactions.isFetchingMore}
      >
        <WSTable<TableItem>
          showHeader={Object.keys(changedFilters).length > 0}
          forceShowGroups
          my="XL"
          loading={
            queryTransactions.isLoading || queryPendingTransactions.isLoading
          }
          columns={columns}
          onRowClick={item => {
            redirectWithFilters(
              `/member/wallet/transactions/${item.data.source.transactionId}`
            );
          }}
          getGroupName={item => {
            const year = new Date(item.data.date).getFullYear();
            const month = toWSDate(item.data.date, "month").toString();

            return `${month} ${year}`;
          }}
          tableData={transactionsListMapped}
          bottomMessage={
            queryTransactions.isLoading === false &&
            transactionsList.length === 0 &&
            (search ? (
              <>
                No transactions found.{" "}
                <WSText.ParagraphXs
                  color="blue500"
                  inline
                  onClick={() => setFilters(defaultFilters)}
                >
                  Show full transaction history.
                </WSText.ParagraphXs>
              </>
            ) : queryPendingTransactions.data?.length ? null : (
              "No transactions found."
            ))
          }
        />
      </WSInfiniteScroll>

      <Switch>
        <Route
          path="/member/wallet/dashboard/transactions/:transactionId"
          exact
          component={WalletTransaction}
        />
      </Switch>
    </>
  );
};
