import { WSElement, WSElementProps } from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import React from "react";
import { useCMSWebflowEnterprise } from "../../../query/cms/queries";
import { useUserId } from "../../../query/hooks/helpers";
import { getCurrentEntepriseId } from "../../../query/platform/selectors";
import { useActivities, useUserProfile } from "../../../query/users/queries";
import { useWSStore } from "../../../store";
import styles from "./index.module.scss";
import { selectorIsWhilteLabeled } from "./selectorIsWhilteLabeled";
import { selectorLogoData } from "./selectorLogoData";
import { useCustomization } from "../../../modules/customization";

type Props = { small?: boolean } & WSElementProps;

export const LogoApp: React.FC<Props> = ({
  className,
  small,
  ...elementProps
}) => {
  const userId = useUserId();
  const store = useWSStore();
  const { branding } = useCustomization();
  const activityQuery = useActivities(userId);
  const userQuery = useUserProfile(userId);
  const enterpriseId = getCurrentEntepriseId(
    store,
    activityQuery.data,
    userQuery.data
  );
  const enterpriseQuery = useCMSWebflowEnterprise(enterpriseId || "", {
    enabled: !!enterpriseId
  });

  const customizingSmallLogo =
    branding(false)?.secondaryLogoUrl || branding(false)?.primaryLogoUrl;

  const logoData = customizingSmallLogo
    ? {
        src: customizingSmallLogo,
        alt: branding(false)?.name
      }
    : selectorLogoData(activityQuery.data, enterpriseQuery.data);

  return (
    <WSElement
      as={"img"}
      {...logoData}
      className={classNames(
        styles.logo,
        {
          [styles.whiteLabeled]: selectorIsWhilteLabeled(
            activityQuery.data,
            enterpriseQuery.data
          ),
          [styles.small]: small
        },
        className
      )}
      {...elementProps}
    />
  );
};
