import {
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useBankInstitution } from "../../../query/payments/queries";

type Props = {
  routingNumberFieldName: string;
  accountNameFieldName: string;
  rtpRequired?: boolean;
} & WSElementProps;

export const InstitutionPreview: React.FC<Props> = ({
  routingNumberFieldName,
  accountNameFieldName,
  rtpRequired,
  ...props
}) => {
  const { watch, setError, clearErrors, setValue, errors } = useFormContext();

  const routingNumberFieldValue = watch(routingNumberFieldName);

  useEffect(() => {
    if (routingNumberFieldValue?.length !== 9) {
      setValue(accountNameFieldName, "");
    }
  }, [routingNumberFieldValue]);

  const queryBankInstitution = useBankInstitution(routingNumberFieldValue, {
    enabled: routingNumberFieldValue?.length === 9,
    onError() {
      setError(routingNumberFieldName, {
        type: "setValueAs",
        message: "We couldn't find a bank with this routing number."
      });
      setValue(accountNameFieldName, "");
    },
    onSuccess(data) {
      if (rtpRequired && !data.isRTPSupported) {
        setError(routingNumberFieldName, {
          type: "setValueAs",
          message: "This bank does not support instant payments"
        });
        setValue(accountNameFieldName, "");
      } else {
        setValue(accountNameFieldName, data.name);
        clearErrors(routingNumberFieldName);
      }
    }
  });

  if (routingNumberFieldValue?.length !== 9) {
    return <WSElement {...props} />;
  }

  if (queryBankInstitution.data) {
    return (
      <WSFlexBox.CenterY {...props}>
        <WSIcon block name="check" size="S" color="green500" mr="XS" />
        <WSText.ParagraphSm weight="medium" color="gray500">
          {queryBankInstitution.data.name}
        </WSText.ParagraphSm>
      </WSFlexBox.CenterY>
    );
  } else {
    return <WSElement {...props} />;
  }
};
