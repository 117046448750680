import {
  toWSMoneyString,
  WSElementColorsType,
  WSElementProps
} from "@wingspanhq/fe-component-library";
import React from "react";
import {
  PayablesFilters,
  usePayablesQuery
} from "../NewPayablesTable/PayablesTable";
import { Summary } from "../Summary/Summary";

type Props = WSElementProps & {
  filter: PayablesFilters;
  label: string;
  displayAmount?: boolean;
  color?: WSElementColorsType;
};

export const PayablesSummary: React.FC<Props> = ({
  filter,
  label,
  displayAmount,
  color = "gray200",
  ...elementProps
}) => {
  const payablesQuery = usePayablesQuery(filter, {
    retry: false
  });

  return (
    <Summary
      {...elementProps}
      value={
        payablesQuery.data
          ? displayAmount
            ? toWSMoneyString(payablesQuery.data?.summary.totalValue)
            : String(payablesQuery.data?.summary.listSize)
          : "–"
      }
      label={label}
      color={color}
    />
  );
};
