import React from "react";
import { EditLayout } from "../../components/EditLayout";
import { useGoBackOrToParent } from "../../../../utils/goToParentRoute";
import { FormStateFiling } from "../../components/FormStateFiling";

export const RouteEditStateFiling: React.FC = () => {
  const goBack = useGoBackOrToParent();

  return (
    <EditLayout title="State filing" onBack={goBack}>
      <FormStateFiling onNext={goBack} onBack={goBack} isEdit={true} />
    </EditLayout>
  );
};
