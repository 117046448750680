import { updateAcknowledgement } from "../../services/api/onboarding/acknowledgements";
import { components } from "../../services/api/onboarding/types";
import { acknowledgementVersions } from "../constants/acknowledgements";

export async function updateAcknowledgements(
  acknowledgements: components["schemas"]["AcknowledgementName"][]
) {
  const acknowledgementPromises = acknowledgements.map(acknowledgementName =>
    updateAcknowledgement(acknowledgementName, {
      acknowledgementName: acknowledgementName,
      acknowledgementStatus: "Given",
      version: acknowledgementVersions[acknowledgementName]
    })
  );

  return await Promise.all(acknowledgementPromises);
}
