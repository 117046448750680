import { useWSModal, WSElement } from "@wingspanhq/fe-component-library";
import { DefaultUserProfileSettingForm } from "../../../../Settings/components/SettingsUpdateForms/DefaultUserProfileSettingForm";
import { LegalFirstNameForm } from "../../../../Settings/components/SettingsUpdateForms/LegalFirstNameForm";
import { LegalLastNameForm } from "../../../../Settings/components/SettingsUpdateForms/LegalLastNameForm";
import { UpdatePasswordForm } from "../../../../Settings/components/SettingsUpdateForms/UpdatePasswordForm";

export const useModalPreferredNameEdit = () =>
  useWSModal(
    (props: { value?: any; onClose: (e: any) => any }) => (
      <>
        <WSElement mb="M">
          A public-facing name that will appear to clients and contractors.
        </WSElement>
        <DefaultUserProfileSettingForm
          field={"preferredName"}
          defaultValues={{ preferredName: props.value }}
          onSubmit={props.onClose}
          onCancel={props.onClose}
        />
      </>
    ),
    {
      title: "Edit preferred name",
      size: "S"
    }
  );

export const useModalFirstNameEdit = () =>
  useWSModal(
    (props: { value?: any; onClose: (e: any) => any }) => (
      <>
        <WSElement mb="M">
          Enter your first name exactly as it is recorded with government tax
          authorities.
        </WSElement>
        <LegalFirstNameForm
          defaultValues={{ firstName: props.value }}
          onSubmit={props.onClose}
          onCancel={props.onClose}
        />
      </>
    ),
    {
      title: "Edit Legal first name",
      size: "S"
    }
  );

export const useModalLastNameEdit = () =>
  useWSModal(
    (props: { value?: any; onClose: (e: any) => any }) => (
      <>
        <WSElement mb="M">
          Enter your last name exactly as it is recorded with government tax
          authorities.
        </WSElement>
        <LegalLastNameForm
          defaultValues={{ lastName: props.value }}
          onSubmit={props.onClose}
          onCancel={props.onClose}
        />
      </>
    ),
    {
      title: "Edit Legal last name",
      size: "S"
    }
  );

export const useModalUserPasswordEdit = () =>
  useWSModal(
    (props: { onClose: (e: any) => any }) => (
      <UpdatePasswordForm onSubmit={props.onClose} onCancel={props.onClose} />
    ),
    {
      title: "Update Password",
      size: "S"
    }
  );
