import {
  WSButton,
  WSCentered,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSLayout,
  WSPill,
  WSScreen,
  WSSwitchInput,
  WSText,
  useIsMobile,
  useModalContext
} from "@wingspanhq/fe-component-library";
import { NextgenSubscriptionPlanType } from "@wingspanhq/users/dist/lib/interfaces";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { useUserId } from "../../query/hooks/helpers";
import { useNextGenSubscriptionPlans } from "../../query/subscriptions/queries";
import { LogoApp } from "../../shared/components/LogoApp";
import {
  MONEY_GUARANTEE_MODAL,
  MoneyGuaranteeModal
} from "../components/MembershipPackages/MoneyGuaranteeModal";
import { NextGenSubscriptionPlanCards } from "../components/NextGenSubscriptionPlans/NextGenSubscriptionPlanCards";
import { NEXT_GEN_SUBSCRIPTION_PLANS_USA } from "../components/NextGenSubscriptionPlans/nextGenSubscriptionPlans";
import styles from "./NextGenSubscriptionPlans.module.scss";

export const NextGenSubscriptionPlans: React.FC = () => {
  const history = useHistory();
  const { partner } = useParams<{ partner: string }>();
  const isMobile = useIsMobile();
  const { openModal } = useModalContext();
  const [isAnnualBilling, setIsAnnualBilling] = useState(true);
  const userId = useUserId();
  const nextGenSubscriptionPlansQuery = useNextGenSubscriptionPlans(userId);
  const onTogglePaymentFrequency = (value: any) => {
    setIsAnnualBilling(value);
  };

  const baseSubscriptionPlans = (
    nextGenSubscriptionPlansQuery.data || []
  )?.filter(plan => plan.planType === NextgenSubscriptionPlanType.Base);
  const benefitsSubscriptionPlans = (
    nextGenSubscriptionPlansQuery.data || []
  )?.filter(plan => plan.planType === NextgenSubscriptionPlanType.Benefits);
  const servicesSubscriptionPlans = (
    nextGenSubscriptionPlansQuery.data || []
  )?.filter(plan => plan.planType === NextgenSubscriptionPlanType.Service);

  return (
    <WSLayout line headerCenter={<LogoApp />} onBack={() => history.goBack()}>
      <MoneyGuaranteeModal />
      <WSElement>
        <WSElement py="2XL">
          <WSCentered>
            <WSFlexBox.Center direction="column">
              <WSText.Heading1 mx="XL" mb="XL">
                {isMobile
                  ? "Choose your membership"
                  : `Which ${
                      partner ? partner : "Wingspan"
                    } membership is right for you?`}
              </WSText.Heading1>
              <WSFlexBox.Center mb="XL">
                <WSText mr="M" color={isAnnualBilling ? "gray500" : "gray700"}>
                  Monthly billing
                </WSText>
                <WSSwitchInput
                  name="paymentFrequency"
                  value={isAnnualBilling}
                  onChange={onTogglePaymentFrequency}
                  className={styles.toggle}
                />
                <WSText ml="M" color={isAnnualBilling ? "gray700" : "gray500"}>
                  Annual billing
                </WSText>
              </WSFlexBox.Center>
              <WSFlexBox.Center mb="3XL">
                <WSText.ParagraphSm mr="XS" inline color="gray500">
                  Get
                </WSText.ParagraphSm>
                <WSPill theme="blue" text="2 months free" />
                <WSText.ParagraphSm ml="XS" inline color="gray500">
                  every year with annual billing.
                </WSText.ParagraphSm>
              </WSFlexBox.Center>

              {baseSubscriptionPlans.length > 0 ? (
                <>
                  <WSText.Heading1 mb="XL">Base Plans</WSText.Heading1>
                  <NextGenSubscriptionPlanCards
                    mb="3XL"
                    isAnnualBilling={isAnnualBilling}
                    planType={NextgenSubscriptionPlanType.Base}
                    packages={NEXT_GEN_SUBSCRIPTION_PLANS_USA}
                  />
                </>
              ) : null}

              {benefitsSubscriptionPlans.length > 0 ? (
                <>
                  <WSText.Heading1 mb="XS">Benefits Plans</WSText.Heading1>
                  <WSText mb="XL" color="gray500" mx="M" align="center">
                    Customize your membership with additional benefits
                  </WSText>
                  <NextGenSubscriptionPlanCards
                    mb="3XL"
                    isAnnualBilling={isAnnualBilling}
                    planType={NextgenSubscriptionPlanType.Benefits}
                    packages={NEXT_GEN_SUBSCRIPTION_PLANS_USA}
                  />
                </>
              ) : null}

              {servicesSubscriptionPlans.length > 0 ? (
                <>
                  <WSText.Heading1 mb="XS">Services Plans</WSText.Heading1>
                  <WSText mb="XL" color="gray500" mx="M" align="center">
                    Customize your membership with additional services
                  </WSText>
                  <NextGenSubscriptionPlanCards
                    mb="3XL"
                    isAnnualBilling={isAnnualBilling}
                    planType={NextgenSubscriptionPlanType.Service}
                    packages={NEXT_GEN_SUBSCRIPTION_PLANS_USA}
                  />
                </>
              ) : null}

              <WSScreen.TabletAndDesktop>
                <WSFlexBox.Center p="M" className={styles.satisfactionMsg}>
                  <WSFlexBox.Center
                    p="XS"
                    mr="M"
                    className={styles.badgeIconContainer}
                  >
                    <WSIcon block name="reward" size="M" color="blue400" />
                  </WSFlexBox.Center>
                  <WSText color="gray700" mr="XS">
                    <b>100% satisfaction guaranteed.</b>
                  </WSText>
                  <WSText inline color="gray600" mr="XS">
                    Wingspan backs our product with a{" "}
                    <WSButton.Link
                      onClick={() => openModal(MONEY_GUARANTEE_MODAL)}
                    >
                      risk-free, money back guarantee.
                    </WSButton.Link>
                  </WSText>
                </WSFlexBox.Center>
              </WSScreen.TabletAndDesktop>
              <WSScreen.Mobile>
                <WSFlexBox.Center
                  alignItems="flex-start"
                  direction="column"
                  p="M"
                  className={styles.satisfactionMsg}
                >
                  <WSFlexBox.CenterY mb="M">
                    <WSFlexBox.Center
                      p="S"
                      mr="M"
                      className={styles.badgeIconContainer}
                    >
                      <WSIcon block name="reward" size="M" color="blue400" />
                    </WSFlexBox.Center>
                    <WSText color="gray700" mb="XS">
                      <b>100% satisfaction guaranteed</b>
                    </WSText>
                  </WSFlexBox.CenterY>
                  <WSText inline color="gray600">
                    Wingspan backs our product with a{" "}
                    <WSButton.Link
                      onClick={() => openModal(MONEY_GUARANTEE_MODAL)}
                    >
                      risk-free, money back guarantee.
                    </WSButton.Link>
                  </WSText>
                </WSFlexBox.Center>
              </WSScreen.Mobile>
            </WSFlexBox.Center>
          </WSCentered>
        </WSElement>
      </WSElement>
    </WSLayout>
  );
};
