import { createTheme } from "baseui";

const primitives = {
  primary: "#282d37",
  negative: "#c70c00",
  positive: "#007f40",
  primaryFontFamily: "Centra No2, sans-serif"
};

export const wsThemePrimitives = {
  ...primitives
};

export const wsBaseWebTheme = createTheme(wsThemePrimitives);
