import React from "react";
import { WSScreen } from "../WSScreen/WSScreen.component";

export const WingspanLogo = () => (
  <>
    <WSScreen.TabletAndDesktop>
      <svg
        width="199"
        height="24"
        viewBox="0 0 199 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.3448 19.608L61.1396 4.23371H63.1608L67.0254 15.9616L71.0672 4.23371H72.2757L76.3159 15.9616L80.2039 4.23371H82.1799L76.9529 19.608H75.7018L71.6387 7.90141L67.5964 19.608H66.3448Z"
          fill="#282D37"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.737 19.609H89.6916V4.23476H87.737V19.609Z"
          fill="#282D37"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.904 4.23371V19.608H108.059L98.725 7.19843V19.608H96.8358V4.23371H98.8341L108.016 16.489V4.23371H109.904Z"
          fill="#282D37"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M128.512 8.73677C127.567 6.89176 125.81 5.83804 123.679 5.83804C120.428 5.83804 117.858 8.56115 117.858 11.9218C117.858 15.2382 120.385 17.9836 123.701 17.9836C126.534 17.9836 128.577 16.0508 128.906 13.3495H123.328V11.5045H130.971V12.2512C130.971 16.5345 128.049 19.85 123.679 19.85C119.33 19.85 115.86 16.3147 115.86 11.9218C115.86 7.50693 119.374 3.9707 123.701 3.9707C126.601 3.9707 129.039 5.53045 130.247 7.83634L128.512 8.73677Z"
          fill="#282D37"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M137.955 15.2605C138.527 16.8639 139.712 18.0055 141.469 18.0055C143.029 18.0055 144.346 17.0837 144.346 15.4798C144.346 14.25 143.6 13.4592 142.062 12.8887L140.262 12.208C137.977 11.3512 136.746 10.0772 136.746 8.07794C136.746 5.55278 138.747 3.9707 141.25 3.9707C143.423 3.9707 145.138 5.26653 145.906 7.41912L144.126 8.16625C143.644 6.8481 142.7 5.81571 141.228 5.81571C139.823 5.81571 138.723 6.67248 138.723 7.9683C138.723 9.06618 139.493 9.81331 141.052 10.4067L142.83 11.0873C145.181 11.9882 146.346 13.3277 146.346 15.3488C146.346 18.1374 144.149 19.8505 141.404 19.8505C138.812 19.8505 136.879 18.3795 136.133 15.9194L137.955 15.2605Z"
          fill="#282D37"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M154.651 11.2853H157.463C159.221 11.2853 160.45 10.3189 160.45 8.67179C160.45 6.98058 159.221 6.0355 157.463 6.0355H154.651V11.2853ZM162.428 8.67037C162.428 11.4818 160.386 13.0852 157.464 13.0852H154.653V19.608H152.698V4.23371H157.464C160.386 4.23371 162.428 5.85895 162.428 8.67037Z"
          fill="#282D37"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M170.128 13.3703H175.246L172.676 6.97899L170.128 13.3703ZM173.488 4.23371L179.815 19.608H177.75L175.949 15.15H169.426L167.624 19.608H165.604L171.952 4.23371H173.488Z"
          fill="#282D37"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M198.439 4.23371V19.608H196.594L187.259 7.19843V19.608H185.37V4.23371H187.37L196.55 16.489V4.23371H198.439Z"
          fill="#282D37"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.5235 21.3443L31.4692 11.9094L36.9993 1.58745H47.1074L36.5235 21.3443ZM24.6146 21.3444L19.5608 11.9095L24.6146 2.47458L29.6689 11.9095L24.6146 21.3444ZM12.7041 21.3443L2.12077 1.58745H12.2289L17.7584 11.9094L12.7041 21.3443ZM35.8239 0.418711L30.5691 10.2287L25.3139 0.418711C25.1755 0.160738 24.9071 0 24.6144 0C24.3217 0 24.0528 0.160738 23.9149 0.418711L18.6597 10.2287L13.4045 0.418711C13.2665 0.160738 12.9976 0 12.7049 0H0.794495C0.194706 0 -0.18779 0.639477 0.0949892 1.16832L12.0054 23.4012C12.1434 23.6587 12.4122 23.8194 12.7049 23.8194C12.9976 23.8194 13.2665 23.6587 13.4045 23.4012L18.6597 13.5908L23.9149 23.4012C24.0528 23.6587 24.3217 23.8194 24.6144 23.8194C24.9071 23.8194 25.1755 23.6587 25.3139 23.4012L30.5691 13.5908L35.8239 23.4012C35.9623 23.6587 36.2312 23.8194 36.5234 23.8194C36.8161 23.8194 37.085 23.6587 37.2229 23.4012L49.1328 1.16832C49.4161 0.639477 49.0331 0 48.4333 0H36.5234C36.2307 0 35.9618 0.160738 35.8239 0.418711Z"
          fill="#F5554B"
        />
      </svg>
    </WSScreen.TabletAndDesktop>
    <WSScreen.Mobile>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="72"
        viewBox="0 0 150 72.58"
      >
        <path
          fill="#f5554b"
          d="M109.16,1.28l-16,29.89-16-29.89A2.41,2.41,0,0,0,75,0h0a2.41,2.41,0,0,0-2.13,1.28l-16,29.89-16-29.89A2.41,2.41,0,0,0,38.71,0H2.42A2.42,2.42,0,0,0,.29,3.56L36.58,71.3a2.41,2.41,0,0,0,2.13,1.28h0a2.41,2.41,0,0,0,2.13-1.28l16-29.89,16,29.89A2.41,2.41,0,0,0,75,72.58h0a2.41,2.41,0,0,0,2.13-1.28l16-29.89,16,29.89a2.41,2.41,0,0,0,2.13,1.28h0a2.41,2.41,0,0,0,2.13-1.28L149.71,3.56A2.42,2.42,0,0,0,147.58,0H111.29A2.41,2.41,0,0,0,109.16,1.28ZM38.71,65,6.46,4.84h30.8L54.11,36.29ZM75,65,59.6,36.29,75,7.54,90.4,36.29Zm36.29,0L95.89,36.29,112.74,4.84h30.8Z"
        />
      </svg>
    </WSScreen.Mobile>
  </>
);
