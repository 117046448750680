import { IAuthorizedAccount, IMember } from "@wingspanhq/users";
import { redirectByScope } from "./redirectByScope";
import { useHistory } from "react-router-dom";
import { useResetWSStore } from "../../../store";
import { useRequestPrincipalUser } from "../../../query/users/mutations";
import { useModalPassword } from "../components/ModalPassword/useModalPassword";
import { useStoredSelectedAccountEmail } from "./useStoredSelectedAccountEmail";
import { useAuthorizedScopeGroups } from "../../../shared/utils/teamUtils";
import { useModalSelectAccounts } from "../components/ModalSelectAccounts/useModalSelectAccounts";
import { useStoredDefaultSelectedAccount } from "./useStoredDefaultSelectedAccount";
import { useWSSnackbar } from "@wingspanhq/fe-component-library";

type Props = {
  userId: string;
  member: IMember;
  accounts: IAuthorizedAccount[];
  switchToFirst?: boolean;
};

export const useSwitchingAccount = () => {
  const history = useHistory();
  const {
    isAdminSessionExists,
    isRequestingUserSessionExists,
    isRequestingUserTokenFromURL
  } = useAuthorizedScopeGroups();
  const [storedDefaultSelectedEmail] = useStoredDefaultSelectedAccount();
  const [
    storedSelectedEmail,
    setStoredSelectedEmail
  ] = useStoredSelectedAccountEmail();

  const resetWSStore = useResetWSStore();
  const [requestPrincipalUser] = useRequestPrincipalUser();
  const modalPassword = useModalPassword();
  const modalSelectAccounts = useModalSelectAccounts();
  const { openSnackbar } = useWSSnackbar();

  return async ({ accounts, member, switchToFirst, userId }: Props) => {
    const loginByAccount = async (
      selectedAuthorizedAccount: IAuthorizedAccount
    ) => {
      return new Promise<boolean>(async resolve => {
        await requestPrincipalUser(
          {
            email: selectedAuthorizedAccount.accessibleMember.user.email,
            captchaToken: await window.grecaptcha.execute(
              process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY || "",
              {
                action: "reauth"
              }
            ),
            captchaVersion: "3"
          },
          {
            onSuccess: () => {
              setStoredSelectedEmail(
                selectedAuthorizedAccount.accessibleMember.user.email
              );
              redirectByScope(selectedAuthorizedAccount, history, resetWSStore);
              resolve(true);
            },
            onError: async err => {
              if (err.response?.status === 401) {
                const result = await modalPassword.open(
                  {
                    account: selectedAuthorizedAccount
                  },
                  {
                    blockClose: false
                  }
                );

                if (result) {
                  setStoredSelectedEmail(storedDefaultSelectedEmail);
                  redirectByScope(
                    selectedAuthorizedAccount,
                    history,
                    resetWSStore
                  );
                  resolve(true);
                  return;
                } else {
                  setStoredSelectedEmail(member?.user?.email || "");
                }
              }

              openSnackbar({
                message: "Sorry! Error occurred while switching account.",
                type: "error"
              });

              resolve(false);
            }
          }
        );
      });
    };

    const getAccount = (email?: string) => {
      return email
        ? accounts.find(
            account => account.accessibleMember.user.email === email
          )
        : undefined;
    };

    if (!!window.TEST_ENV) {
      return false;
    }

    if (
      isRequestingUserSessionExists ||
      isAdminSessionExists ||
      isRequestingUserTokenFromURL
    ) {
      return false;
    }

    // If the default user is already logged in, don't redirect to select organization account
    if (storedSelectedEmail) {
      return false;
    }

    if (member?.user?.email === storedDefaultSelectedEmail) {
      return false;
    }

    const defaultAccount = getAccount(storedDefaultSelectedEmail);

    try {
      if (switchToFirst && accounts[0]) {
        return await loginByAccount(accounts[0]);
      } else if (defaultAccount) {
        return await loginByAccount(defaultAccount);
      } else if (accounts.length > 0) {
        return await modalSelectAccounts.open({});
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  };
};
