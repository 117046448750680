import * as Yup from "yup";

const regExp = new RegExp(/^[0-9]+$/);

export const validatorRoutingNumber = Yup.string().test(
  "optionalRoutingNumber",
  "Should be 9 digits",
  value =>
    value && value.length > 0 ? value.length === 9 && regExp.test(value) : true
);
