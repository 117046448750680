import {
  IRedeemCashbackBalanceRequest,
  IRedeemCashbackBalanceResponse
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  IPendingTransactionCreate,
  IPendingTransactionResponse,
  IPendingTransactionUpdate
} from "@wingspanhq/bookkeeping/dist/lib/interfaces/pendingTransaction";
import { ITransaction } from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";
import { IActivity } from "@wingspanhq/users";
import { queryCache, ReactQueryMutationsConfig } from "react-query";
import bookkeepingService, {
  CreateTransactionData,
  UpdateTransactionData
} from "../../services/bookkeeping";
import { WSServiceError } from "../../utils/serviceHelper";
import { useWSMutation } from "../helpers";
import {
  QUERY_BOOKKEEPING_BANKING_BALANCE,
  QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTIONS,
  QUERY_BOOKKEEPING_POSTED_CASHBACK_BALANCE,
  QUERY_BOOKKEEPING_PROFIT_AND_LOSS,
  QUERY_BOOKKEEPING_STATUS,
  QUERY_BOOKKEEPING_TRANSACTION,
  QUERY_BOOKKEEPING_TRANSACTIONS
} from "./keys";

export const useSetBookkeepingEnable = (
  config?: ReactQueryMutationsConfig<IActivity, WSServiceError>
) =>
  useWSMutation((userId: string) => bookkeepingService.enable(userId), {
    dependencies: [QUERY_BOOKKEEPING_STATUS],
    ...config
  });

export const useCreateTransaction = () =>
  useWSMutation(
    async (request: CreateTransactionData) =>
      await bookkeepingService.createTransaction(request),
    {
      dependencies: [
        QUERY_BOOKKEEPING_TRANSACTIONS,
        QUERY_BOOKKEEPING_PROFIT_AND_LOSS
      ]
    }
  );

export const useUpdateTransaction = (
  config?: ReactQueryMutationsConfig<ITransaction, WSServiceError>
) =>
  useWSMutation(
    async (request: UpdateTransactionData) =>
      await bookkeepingService.updateTransaction(request),
    {
      dependencies: [
        QUERY_BOOKKEEPING_TRANSACTIONS,
        QUERY_BOOKKEEPING_TRANSACTION,
        QUERY_BOOKKEEPING_PROFIT_AND_LOSS
      ],
      ...config
    }
  );

export const useDeleteTransaction = (
  config?: ReactQueryMutationsConfig<any, WSServiceError>
) =>
  useWSMutation(
    async (transactionId: string) =>
      await bookkeepingService.deleteTransaction(transactionId),
    {
      dependencies: [
        QUERY_BOOKKEEPING_TRANSACTIONS,
        QUERY_BOOKKEEPING_PROFIT_AND_LOSS
      ],
      onSuccess(data, transactionId) {
        queryCache.removeQueries([
          QUERY_BOOKKEEPING_TRANSACTION,
          transactionId
        ]);
      },
      ...config
    }
  );

export const useCreateBankingPendingTransaction = (
  config?: ReactQueryMutationsConfig<
    IPendingTransactionResponse,
    WSServiceError,
    IPendingTransactionCreate
  >
) =>
  useWSMutation(
    async (request: IPendingTransactionCreate) =>
      await bookkeepingService.createPendingBankingTransaction(request),
    {
      dependencies: [
        QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTIONS,
        QUERY_BOOKKEEPING_BANKING_BALANCE,
        QUERY_BOOKKEEPING_TRANSACTIONS
      ],
      ...config
    }
  );

export const useUpdateBankingPendingTransaction = (
  config?: ReactQueryMutationsConfig<
    IPendingTransactionResponse,
    WSServiceError,
    IPendingTransactionUpdate & { id: string }
  >
) =>
  useWSMutation(
    async (request: IPendingTransactionUpdate & { id: string }) =>
      await bookkeepingService.updatePendingBankingTransaction(
        request.id,
        request
      ),
    {
      dependencies: [
        QUERY_BOOKKEEPING_PENDING_BANKING_TRANSACTIONS,
        QUERY_BOOKKEEPING_BANKING_BALANCE
      ],
      ...config
    }
  );

export const useRedeemCashbackBalance = (
  config?: ReactQueryMutationsConfig<
    IRedeemCashbackBalanceResponse,
    WSServiceError,
    IRedeemCashbackBalanceRequest
  >
) =>
  useWSMutation(
    async (request: IRedeemCashbackBalanceRequest) =>
      await bookkeepingService.redeemCashbackBalance(request),
    {
      dependencies: [
        QUERY_BOOKKEEPING_POSTED_CASHBACK_BALANCE,
        QUERY_BOOKKEEPING_BANKING_BALANCE,
        QUERY_BOOKKEEPING_TRANSACTIONS
      ],
      ...config
    }
  );
