import {
  useIsMobile,
  WSFormOld,
  WSRadioInputGroup
} from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import React from "react";
import * as Yup from "yup";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import styles from "./SettingsUpdateForms.module.scss";

import { useQueryVerifications } from "../../../query/onboarding/queries/useQueryVerifications";
import { openIntercomMessage } from "../../../shared/utils/intercom";

export const BUSINESS_TYPE_OPTIONS = [
  {
    label: "Sole proprietorship",
    value: CompanyStructure.SoleProprietorship
  },
  {
    label: "S-corp",
    value: CompanyStructure.CorporationS
  },
  {
    label: "C-corp",
    value: CompanyStructure.CorporationC
  },
  {
    label: "Partnership",
    value: CompanyStructure.Partnership
  },
  {
    label: "LLC (single member)",
    value: CompanyStructure.LlcSingleMember
  },
  {
    label: "LLC (Taxed as S-corp)",
    value: CompanyStructure.LLCCorporationS
  },
  {
    label: "LLC (Taxed as C-corp)",
    value: CompanyStructure.LLCCorporationC
  },
  {
    label: "LLC (Taxed as Partnership)",
    value: CompanyStructure.LLCPartnership
  }
];

export const BUSINESS_TYPE_INDIVIDUAL_LABEL = "Individual";

export const getBusinessTypeLabel = (value?: CompanyStructure) =>
  value
    ? BUSINESS_TYPE_OPTIONS.find(item => value === item.value)?.label
    : BUSINESS_TYPE_INDIVIDUAL_LABEL;

interface BusinessTypeFormProps {
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (data: any) => void;
}

export const BusinessTypeForm: React.FC<BusinessTypeFormProps> = ({
  onSubmit,
  defaultValues,
  onCancel
}) => {
  const queryVerifications = useQueryVerifications();
  const isMobile = useIsMobile();
  const isVerified = queryVerifications.data?.tax === "Verified";

  const userId = useUserId();
  const [updateMemberProfile] = useUpdateMemberProfile();
  const onFormSubmit = async (data: any) => {
    if (defaultValues.structure && isVerified) {
      openIntercomMessage(
        `Hi there! I want to update my business structure to "${data.structure}".\n\n`
      );
      onSubmit(data);
      return;
    }

    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        company: {
          structure: data.structure
        }
      }
    };
    await updateMemberProfile(request, {
      onSuccess: () => {
        onSubmit(data);
      }
    });
  };
  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        structure: Yup.string().required("Select business type")
      })}
    >
      <WSFormOld.Field name="structure">
        <WSFormOld.Input
          render={props => (
            <WSRadioInputGroup {...props} options={BUSINESS_TYPE_OPTIONS} />
          )}
        />
        <WSFormOld.Error />
      </WSFormOld.Field>
      <WSFormOld.SubmitButton
        type="submit"
        name="update"
        mt="2XL"
        fullWidth={isMobile}
      >
        {defaultValues.structure && isVerified
          ? "Send request to customer support"
          : "Update"}
      </WSFormOld.SubmitButton>
    </WSFormOld>
  );
};
