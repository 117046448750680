import { useHistory } from "react-router-dom";
import { PATH_ONBOARDING_PAYER } from "..";
import { useQueryCustomerEntity } from "../../../query/onboarding/queries/useQueryCustomerEntity";
import { useQueryVerifications } from "../../../query/onboarding/queries/useQueryVerifications";
import { useWSAnalytics } from "../../../utils/WSAnalytics";
import { OnboardingFlow } from "../components/OnboardingFlow";
import { OnboardingModule } from "../types";

const payerOnboardingModules: OnboardingModule[] = [
  {
    type: "create_account",
    options: {
      accountTypes: ["Business"],
      verificationLevel: "Banking"
    }
  },
  {
    type: "payroll_funding_method"
  },
  {
    type: "payroll_settings"
  },
  {
    type: "business_profile"
  }
];

export const RoutePayerOnboarding: React.FC = () => {
  const history = useHistory();
  const { track } = useWSAnalytics();
  const queryCustomerEntity = useQueryCustomerEntity();
  const queryVerifications = useQueryVerifications();

  return (
    <OnboardingFlow
      basePath={PATH_ONBOARDING_PAYER}
      modules={payerOnboardingModules}
      onSuccess={() => {
        history.push("/member/dashboard");

        track.other("Onboarding Completed", {
          onboardingType: "payer",
          entityType: queryCustomerEntity.data?.type,
          entityCountry: queryCustomerEntity.data?.country,
          taxVerificationStatus: queryVerifications.data?.tax,
          bankingVerificationStatus: queryVerifications.data?.banking,
          enhancedVerificationStatus: queryVerifications.data?.enhanced
        });
      }}
    />
  );
};
