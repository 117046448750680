import {
  useIsDesktop,
  useIsMobile,
  WSActions,
  WSButton,
  WSControl,
  WSElement,
  WSFlexBox,
  WSForm,
  WSInputNumber,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  FrequencyAndScheduleStatus,
  PayrollWorkflowStrategy
} from "@wingspanhq/payments/dist/interfaces";
import {
  IFrequencyUpdate,
  Intervals
} from "@wingspanhq/payments/dist/interfaces/invoice";
import React from "react";
import { TextList } from "../../../../Benefits/components/TextList/TextList.module";
import { RadioGroupWithCustomInputs } from "../../../../components/RadioGroupWithCustomInputs/RadioGroupWithCustomInputs";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUpdatePayrollSettings } from "../../../../query/payments/mutations";
import { usePayrollSettings } from "../../../../query/payments/queries";
import { WeekDay } from "../../../../shared/types/time";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { weekDayToDayInIntervalMapping } from "../../../../shared/utils/time";
import { getChangedData } from "../../../../utils/getChangedData";
import styles from "./FormPayrollSettings.module.scss";
import { getDefaultValues } from "./defaultValues";
import { FormData, PayrollSettingsInterval } from "./types";

type Props = { onSuccess?: () => void };

export const FormPayrollSettings: React.FC<Props> = ({ onSuccess }) => {
  const userId = useUserId();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();

  const queryPayrollSettings = usePayrollSettings(userId, {
    onSuccess
  });
  const payrollSettings = queryPayrollSettings.data;

  const [
    updatePayrollSettings,
    updatePayrollSettingsMeta
  ] = useUpdatePayrollSettings(userId);

  const handleSubmit = (data: FormData) => {
    if (!data.runsPayroll) {
      updatePayrollSettings({
        runsPayroll: false
      });
      return;
    }

    let startDate = new Date();
    let frequency: IFrequencyUpdate;

    if (data.interval === PayrollSettingsInterval.Everyday) {
      frequency = {
        daily: true,
        startDate
      };
    } else if (data.interval === PayrollSettingsInterval.SemiMonthly) {
      frequency = {
        twicePerMonth: true,
        startDate
      };
    } else if (data.interval === PayrollSettingsInterval.Monthly) {
      frequency = {
        every: 1,
        dayInInterval: 31,
        interval: Intervals.Month,
        startDate
      };
    } else {
      frequency = {
        every: data.every,
        dayInInterval: weekDayToDayInIntervalMapping[data.dayInInterval],
        interval: Intervals.Week,
        startDate
      };
    }

    updatePayrollSettings(
      getChangedData(payrollSettings, {
        runsPayroll: true,
        frequency,
        status: FrequencyAndScheduleStatus.Active,
        scheduleDates: null,
        issue1099s: data.issue1099s,
        workflow: data.enableMultiStageApproval
          ? PayrollWorkflowStrategy.DualStage
          : PayrollWorkflowStrategy.SingleStage,
        enableProcessDaysBeforeDue: data.enableProcessDaysBeforeDue,
        processDaysBeforeDue: data.enableProcessDaysBeforeDue
          ? data.processDaysBeforeDue
          : undefined
      })
    );
  };

  return (
    <WSForm<FormData>
      defaultValues={getDefaultValues(payrollSettings)}
      onSubmit={handleSubmit}
    >
      {({ setValue }) => {
        return (
          <WSList gap="2XL">
            <WSPanel>
              <WSElement mb="2XL">
                <WSSectionToolbar title="Payroll schedule" />
                <WSText.ParagraphSm color="gray500">
                  Enable payroll to set how often your contractors receive
                  payments. If you prefer to not set a schedule, disable
                  payroll.
                </WSText.ParagraphSm>
              </WSElement>

              <WSList gap="2XL">
                <WSForm.Field
                  name="runsPayroll"
                  component={WSControl}
                  componentProps={{
                    type: "switch",
                    label: "Enable Payroll"
                  }}
                />
                <WSForm.Value name="runsPayroll">
                  {runsPayroll =>
                    runsPayroll ? (
                      <WSList gap="2XL">
                        <WSElement>
                          <WSText.Paragraph
                            weight="medium"
                            color="gray700"
                            mb="M"
                          >
                            Expected pay date
                          </WSText.Paragraph>
                          <RadioGroupWithCustomInputs
                            name="interval"
                            options={[
                              {
                                value: PayrollSettingsInterval.Monthly,
                                label: "Monthly"
                              },
                              {
                                value: PayrollSettingsInterval.SemiMonthly,
                                label: "Semi-monthly"
                              },
                              {
                                value: PayrollSettingsInterval.Everyday,
                                label: "Everyday"
                              },
                              {
                                value: PayrollSettingsInterval.Week,
                                label: (
                                  <WSFlexBox>
                                    <WSFlexBox
                                      wrap={isDesktop ? "nowrap" : "wrap"}
                                    >
                                      <WSText.ParagraphSm
                                        as="pre"
                                        inline
                                        mr="M"
                                        mt="M"
                                      >
                                        Every
                                      </WSText.ParagraphSm>
                                      <WSForm.Field
                                        name="every"
                                        component={WSInputNumber}
                                        componentProps={{
                                          className: styles.everyField,
                                          onFocus: () => {
                                            setValue(
                                              "interval",
                                              PayrollSettingsInterval.Week
                                            );
                                          }
                                        }}
                                      />
                                    </WSFlexBox>
                                    <WSFlexBox
                                      wrap={isDesktop ? "nowrap" : "wrap"}
                                      className={styles.weeksField}
                                      mx={isMobile ? "NONE" : "S"}
                                      mt={isMobile ? "M" : "NONE"}
                                    >
                                      <WSText.ParagraphSm
                                        as="pre"
                                        inline
                                        className={styles.weeksFieldLabel}
                                        mt={isMobile ? "NONE" : "M"}
                                      >
                                        week(s) on
                                      </WSText.ParagraphSm>
                                      <WSForm.Field
                                        name="dayInInterval"
                                        component={WSSelect}
                                        componentProps={{
                                          options: Object.keys(WeekDay).map(
                                            key => ({
                                              value: key,
                                              label: key
                                            })
                                          ),
                                          onFocus: () => {
                                            setValue(
                                              "interval",
                                              PayrollSettingsInterval.Week
                                            );
                                          }
                                        }}
                                      />
                                    </WSFlexBox>
                                  </WSFlexBox>
                                )
                              }
                            ]}
                          />
                        </WSElement>

                        <WSElement>
                          <WSText.Paragraph
                            weight="medium"
                            color="gray700"
                            mb="M"
                          >
                            Approvals
                          </WSText.Paragraph>

                          <WSList gap="2XL">
                            <WSElement>
                              <WSForm.Field
                                name="enableProcessDaysBeforeDue"
                                label="Pay approved payables only when due"
                                component={WSControl}
                                componentProps={{
                                  type: "checkbox",
                                  tooltip: (
                                    <>
                                      <WSText.ParagraphSm
                                        weight="medium"
                                        mt="XL"
                                        mb="S"
                                      >
                                        Only pay approved payables when due
                                      </WSText.ParagraphSm>
                                      <TextList
                                        list={[
                                          "If this checkbox is selected, approved payables will only be paid at the last possible payroll such that the payment arrives before the Due Date.",
                                          "If this checkbox is not selected, all approved payables will be paid out at the next payroll run.",
                                          "If you would like to change when a payable is paid, you can override the Due Date and set Pay Date by editing the payable.",
                                          "If you would like to change the number of days considered in payroll before an payable is due, contact your account manager."
                                        ]}
                                      />
                                    </>
                                  )
                                }}
                              />

                              {payrollSettings?.processDaysBeforeDue &&
                              payrollSettings.processDaysBeforeDue > 0 ? (
                                <WSForm.Value name="enableProcessDaysBeforeDue">
                                  {enableProcessDaysBeforeDue => (
                                    <WSElement
                                      mt="M"
                                      hidden={!enableProcessDaysBeforeDue}
                                      style={{ width: 150 }}
                                    >
                                      <WSForm.Field
                                        name="processDaysBeforeDue"
                                        component={WSInputNumber}
                                        label="Number of days to pay before due"
                                      />
                                    </WSElement>
                                  )}
                                </WSForm.Value>
                              ) : null}
                            </WSElement>

                            <WSForm.Field
                              name="enableMultiStageApproval"
                              component={WSControl}
                              label="Enable multi-stage approval for payables"
                              componentProps={{
                                type: "checkbox"
                              }}
                            />
                          </WSList>
                        </WSElement>
                      </WSList>
                    ) : null
                  }
                </WSForm.Value>

                <WSText.ParagraphSm weight="light">
                  Learn more about{" "}
                  <WSButton.Link
                    size="M"
                    type="button"
                    onClick={() => {
                      openInNewTab(
                        // TODO(onb): Update link to point to the correct location
                        "https://docs.wingspan.app/docs/payroll-settings"
                      );
                    }}
                  >
                    payroll schedule
                  </WSButton.Link>
                </WSText.ParagraphSm>
              </WSList>
            </WSPanel>
            <WSActions
              orientation="vertical"
              buttons={[
                {
                  label: "Save & continue",
                  loading: updatePayrollSettingsMeta.isLoading
                }
              ]}
            />
            <WSText.ParagraphXs weight="book" color="gray400">
              Payroll settings (payroll schedule and payables settings) can be
              viewed and updated at any time in your settings.
            </WSText.ParagraphXs>
          </WSList>
        );
      }}
    </WSForm>
  );
};
