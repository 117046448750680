import {
  WSElement,
  WSElementProps,
  WSFormOld,
  WSGrid,
  WSInputMaskOld,
  WSSelectOld,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import {
  SELECT_OPTIONS_CANADIAN_PROVINCES,
  SELECT_OPTIONS_COUNTRY,
  SELECT_OPTIONS_US_STATE
} from "../../constants/user";
import { formDataToRequest } from "./formDataToRequest";
import { selectorDefaultValues } from "./selectorDefaultValues";
import { FormData } from "./types";
import { validationSchema } from "./validationSchema";

export const validationSchemaAddress = validationSchema;
export const formDataToRequestAddress = formDataToRequest;
export const selectorAddressDefaultValues = selectorDefaultValues;

export type FormDataAddress = FormData;

export type FormPartialAddressProps = {
  name: string;
  disableCountry?: boolean;
} & WSElementProps;

export const FormPartialAddress: React.FC<FormPartialAddressProps> = ({
  name,
  disableCountry,
  ...elementProps
}) => {
  return (
    <WSElement {...elementProps}>
      <WSFormOld.Field
        name={name + ".country"}
        label="Country"
        component={WSSelectOld}
        componentProps={{
          options: disableCountry
            ? SELECT_OPTIONS_COUNTRY.filter(country => country.value === "US")
            : SELECT_OPTIONS_COUNTRY,
          searchable: !disableCountry,
          searchPlaceholder: "Search country..."
        }}
        mb="XL"
      />

      <WSFormOld.Field
        name={name + ".addressLine1"}
        label="Street address"
        component={WSTextInput}
        componentProps={{
          autoComplete: "address-line1",
          placeholder: "Address Line 1"
        }}
        mb="M"
      />

      <WSFormOld.Field
        name={name + ".addressLine2"}
        component={WSTextInput}
        componentProps={{
          autoComplete: "address-line2",
          placeholder: "Address Line 2"
        }}
        mb="XL"
      />

      <WSElement>
        <WSGrid gutter="M">
          <WSGrid.Item span={{ m: "5" }}>
            <WSFormOld.Field
              name={name + ".city"}
              component={WSTextInput}
              componentProps={{
                autoComplete: "address-level2"
              }}
              label="City"
            />
          </WSGrid.Item>
          <WSFormOld.Value name={name + ".country"}>
            {country =>
              country === "US" ? (
                <>
                  <WSGrid.Item span={{ s: "6", m: "4" }}>
                    <WSFormOld.Field
                      name={name + ".state"}
                      component={WSSelectOld}
                      componentProps={{
                        options: SELECT_OPTIONS_US_STATE,
                        searchable: true
                      }}
                      label="State"
                    />
                  </WSGrid.Item>
                  <WSGrid.Item span={{ s: "6", m: "3" }}>
                    <WSFormOld.Field
                      name={name + ".zipCode"}
                      component={WSInputMaskOld}
                      componentProps={{
                        mask: "99999",
                        autoComplete: "postal-code"
                      }}
                      label="Zip code"
                    />
                  </WSGrid.Item>
                </>
              ) : country === "CA" ? (
                <WSGrid.Item key="caProvince" span={{ s: "6", m: "7" }}>
                  <WSFormOld.Field
                    name={name + ".state"}
                    component={WSSelectOld}
                    componentProps={{
                      options: SELECT_OPTIONS_CANADIAN_PROVINCES,
                      searchable: true
                    }}
                    label="Province"
                  />
                </WSGrid.Item>
              ) : (
                <WSGrid.Item span={{ s: "6", m: "7" }}>
                  <WSFormOld.Field
                    name={name + ".state"}
                    component={WSTextInput}
                    componentProps={{
                      autoComplete: "address-level1"
                    }}
                    label="Region/state (optional)"
                  />
                </WSGrid.Item>
              )
            }
          </WSFormOld.Value>
        </WSGrid>

        <WSFormOld.Value name={name + ".country"}>
          {country =>
            country !== "US" && (
              <WSFormOld.Field
                mt="M"
                name={name + ".zipCode"}
                component={WSTextInput}
                componentProps={{
                  autoComplete: "postal-code"
                }}
                label="Postal code"
              />
            )
          }
        </WSFormOld.Value>
      </WSElement>
    </WSElement>
  );
};
