import { ISessionResponse } from "@wingspanhq/users";
import { QueryConfig } from "react-query";
import { usersService } from "../services/users";
import { WSServiceError } from "../utils/serviceHelper";
import { useWSQuery } from "./helpers";

// keys
export const QUERY_USERS_SESSION = "QUERY_USERS_SESSION";

// queries
export const useSession = (
  queryConfig?: QueryConfig<ISessionResponse, WSServiceError>
) => {
  return useWSQuery(QUERY_USERS_SESSION, usersService.session.get, {
    ...queryConfig
  });
};

// mutations
