import * as Yup from "yup";

const regExp = new RegExp(/^[0-9]+$/);

export const validatorTaxId = Yup.string().test(
  "optionalTaxId",
  "Should be 9 digits",
  value =>
    value && value.length > 0
      ? value.replace("-", "").length === 9 &&
        regExp.test(value.replace("-", ""))
      : true
);
