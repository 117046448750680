import {
  useWSModal,
  WSActions,
  WSInfoBox,
  WSList
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { PayeeEmailChangeResultType } from "@wingspanhq/payments/dist/interfaces/payerPayee";
import { useConfirmPayeeEmailUpdate } from "../../../../query/payee/mutations/useConfirmPayeeEmailUpdate";
import { FieldView } from "../../../../shared/components/FieldView/FieldView";
import { IPayeeEmailChangeResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEmailChange";

type EditEmailConfirmModalProps = {
  onClose: () => void;
  onSuccess?: () => void;
  response: IPayeeEmailChangeResponse;
};

const EditEmailConfirmModal: React.FC<EditEmailConfirmModalProps> = ({
  onClose,
  onSuccess,
  response
}) => {
  const [
    confirmEmailUpdate,
    confirmEmailUpdateMeta
  ] = useConfirmPayeeEmailUpdate();

  return (
    <WSList gap="L">
      {response.resultType === PayeeEmailChangeResultType.UpdatedEmail ? (
        <WSInfoBox>
          You are updating the contractor email and a new invite will be sent to
          the following email:
        </WSInfoBox>
      ) : (
        <WSInfoBox>
          You are updating the contractor email. The previous email (profile)
          will be archived and a new invite will be sent to the following email:
        </WSInfoBox>
      )}

      <FieldView label="Contractor email" value={response.targetEmail} />

      <WSErrorMessage
        contextKey={ErrorContextKey.Other}
        error={confirmEmailUpdateMeta.error}
      />

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            onAsyncClick: async () => {
              await confirmEmailUpdate(
                {
                  payeeId: response.sourcePayeeId,
                  targetEmail: response.targetEmail
                },
                {
                  onSuccess: () => {
                    onSuccess?.();
                    onClose();
                  }
                }
              );
            },
            kind: "Primary"
          },
          {
            label: "Back",
            onClick: onClose,
            kind: "Tertiary"
          }
        ]}
      />
    </WSList>
  );
};

export function useEditEmailConfirmModal() {
  return useWSModal(EditEmailConfirmModal, {
    title: "Send invite",
    size: "S"
  });
}
