import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import {
  SelectOptionOld,
  useModalContext,
  WSButtons,
  WSContainer,
  WSFlexBox,
  WSFormOld,
  WSCentered,
  WSPanel,
  WSSelectOld,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksAccountExpenses,
  useIntegrationsQuickbooksItems
} from "../../../../query/integrations/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { ProjectOnboardingLayout } from "../../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import * as Yup from "yup";
import { useUpdateIntegrationQuickbooks } from "../../../../query/integrations/mutations";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { isQuickbooksAuthenticated } from "../../../../query/integrations/selectors";
import {
  INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
  QUICKBOOKS_ENTITY,
  RefreshModal
} from "./RefreshButton";

export const QuickbooksSetupStep3: React.FC<RouteComponentProps<any>> = ({
  history
}) => {
  const { openModal } = useModalContext();
  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();

  const qIntegrationsQuickbooksAccountExpenses = useIntegrationsQuickbooksAccountExpenses();
  const qIntegrationsQuickbooksItems = useIntegrationsQuickbooksItems();
  const [
    updateIntegration,
    mUpdateIntegration
  ] = useUpdateIntegrationQuickbooks();

  return (
    <ProjectOnboardingLayout progress={(100 / 8) * 3}>
      <WSQueries
        queries={{
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksItems,
          qIntegrationsQuickbooksAccountExpenses
        }}
      >
        {({
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksItems,
          qIntegrationsQuickbooksAccountExpenses
        }) => {
          const integrationState = qIntegrationsQuickbooks.data;

          const quickbooksAccountsOptions: SelectOptionOld[] = qIntegrationsQuickbooksAccountExpenses.data
            .map(qboAccount => {
              return {
                label: qboAccount.fullyQualifiedName,
                value: qboAccount.accountId,
                searchText: [qboAccount.fullyQualifiedName, qboAccount.name]
                  .filter(Boolean)
                  .join(" ")
              };
            })
            .sort((a, b) => a.label.localeCompare(b.label));

          const quickbooksItemsOptions: SelectOptionOld[] = qIntegrationsQuickbooksItems.data
            .map(qboAccount => {
              return {
                label: qboAccount.fullyQualifiedName,
                value: qboAccount.itemId,
                searchText: [qboAccount.fullyQualifiedName, qboAccount.name]
                  .filter(Boolean)
                  .join(" ")
              };
            })
            .sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label));

          if (!isQuickbooksAuthenticated(integrationState)) {
            return (
              <Redirect to="/member/settings/integrations/quickbooks/setup" />
            );
          }

          return (
            <WSContainer verticalPadding>
              <WSCentered span={{ m: "8" }}>
                <WSText.ParagraphSm color="gray500" mt="M" pt="M">
                  3 of 8
                </WSText.ParagraphSm>

                <WSText.Heading4>
                  Select Default Invoice and Payable Line Items
                </WSText.Heading4>

                <WSPanel mt="2XL" pb="3XL" mb="5XL">
                  <WSText.Heading5 mt="XL">
                    We use defaults to map your Invoices and Payables to the
                    right places
                  </WSText.Heading5>

                  <WSText mt="XL">
                    When importing Invoices and Payables created in Wingspan to
                    Quickbooks, we need to provide default Items and Expense
                    Accounts
                  </WSText>

                  <WSFormOld
                    onSubmit={async data => {
                      await updateIntegration(
                        {
                          defaults: {
                            itemId: data.defaultItem,
                            expenseAccountId: data.defaultAccount
                          }
                        },
                        {
                          throwOnError: true
                        }
                      );
                      history.push(
                        "/member/settings/integrations/quickbooks/setup/step/4"
                      );
                    }}
                    validationSchema={Yup.object().shape({
                      defaultItem: Yup.string().required("Required"),
                      defaultAccount: Yup.string().required("Required")
                    })}
                    defaultValues={{
                      defaultItem: integrationState.defaults?.itemId,
                      defaultAccount:
                        integrationState.defaults?.expenseAccountId
                    }}
                  >
                    <WSFormOld.Field
                      mt="XL"
                      name="defaultItem"
                      component={WSSelectOld}
                      componentProps={{
                        options: quickbooksItemsOptions,
                        searchable: true,
                        placeholderActions: [
                          {
                            label: "Resync QBO Items",
                            icon: "refresh-v",
                            callback() {
                              openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                                entity: QUICKBOOKS_ENTITY.ITEMS
                              });
                            }
                          }
                        ]
                      }}
                      label="Default Product or Service for Invoices"
                    />

                    <WSFormOld.Field
                      mt="XL"
                      name="defaultAccount"
                      component={WSSelectOld}
                      componentProps={{
                        options: quickbooksAccountsOptions,
                        searchable: true,
                        placeholderActions: [
                          {
                            label: "Resync QBO Expenses Accounts",
                            icon: "refresh-v",
                            callback() {
                              openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                                entity: QUICKBOOKS_ENTITY.EXPENSES
                              });
                            }
                          }
                        ]
                      }}
                      label="Default Expense Account for Payables"
                    />

                    <WSErrorMessage
                      mt="2XL"
                      contextKey="IntegrationsQuickbooksMapping"
                      error={mUpdateIntegration.error}
                    />

                    <WSFlexBox.Center mt="2XL">
                      <WSButtons>
                        <WSFormOld.SubmitButton name="next">
                          Next
                        </WSFormOld.SubmitButton>
                      </WSButtons>
                    </WSFlexBox.Center>
                  </WSFormOld>
                </WSPanel>
              </WSCentered>
            </WSContainer>
          );
        }}
      </WSQueries>
      <RefreshModal />
    </ProjectOnboardingLayout>
  );
};
