import {
  ICollaboratorSchema,
  ICollaboratorV2,
  IMemberClient,
  IMemberClientForm1099Balances
} from "@wingspanhq/payments/dist/interfaces";
import getLatest1099NECIndex from "../../../utils/getLatest1099NECIndex";

import DocumentMeta from "./DocumentMeta";
import { selectorIsForm1099BalanceSubmitted } from "../../../shared/selectors/selectorIsForm1099BalanceSubmitted";

const getIsDraft = (data: IMemberClientForm1099Balances) =>
  !selectorIsForm1099BalanceSubmitted(data);

const getDocTitleSuffix = (data: IMemberClientForm1099Balances) =>
  getIsDraft(data) ? " draft" : "";

const getDocAmount = ({
  platformIncome,
  adjustments,
  paymentProcessingFees
}: IMemberClientForm1099Balances) =>
  platformIncome + adjustments + paymentProcessingFees;

// TODO: this needs to not be set universally but at this point we don't have the data to determine if it's shared
const getIsShared = (data: IMemberClientForm1099Balances) =>
  Boolean(data.events?.submittedForCollaboratorReviewAt) || true;

export default function getDocumentsMetaFromProfile(
  profile: IMemberClient | ICollaboratorSchema | ICollaboratorV2
): DocumentMeta[] | null {
  const documentEntries =
    profile.form1099Balances &&
    (Object.entries(profile.form1099Balances) as [
      string,
      IMemberClientForm1099Balances
    ][]);
  if (!documentEntries || !documentEntries.length) return null;

  return documentEntries
    .filter(([_, data]) => data.status)
    .filter(([_, data]) => !getIsDraft(data))
    .map(([year, data]) => ({
      year,
      title: `1099${getDocTitleSuffix(data)}`,
      amount: getDocAmount(data),
      status: data.status,
      memberId: profile?.memberId,
      isDraft: getIsDraft(data),
      isShared: getIsShared(data),
      latest1099NECIndex: getLatest1099NECIndex(data)
    }));
}
