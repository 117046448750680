import {
  useModalContext,
  WSButton,
  WSButtons,
  WSElementProps,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import { MemberClientStatus } from "@wingspanhq/payments/dist/interfaces/memberClient";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useUpdateMemberClient } from "../../../query/payments/mutations";

type Props = WSElementProps & {
  clientId: string;
  clientName: string;
  backPath: string;
};

export const DELETE_CLIENT_MODAL = "DELETE_CLIENT_MODAL";

export const DeleteClientModal: React.FC<Props> = ({
  clientName,
  clientId,
  backPath
}) => {
  const { closeModal } = useModalContext();
  const history = useHistory();

  const [updateMemberClient, updateMemberClientMeta] = useUpdateMemberClient(
    clientId
  );

  return (
    <WSModal name={DELETE_CLIENT_MODAL} size="S" title="Delete client">
      <WSText my="2XL">Are you sure you want to delete {clientName}?</WSText>

      <WSErrorMessage
        mt="XL"
        error={updateMemberClientMeta.error}
        contextKey="Other"
      />

      <WSButtons mt="3XL" forceFullWidth>
        <WSButton
          destructive
          name="confirmDeleteClient"
          onAsyncClick={async () => {
            await updateMemberClient(
              {
                status: MemberClientStatus.Inactive
              },
              {
                onSuccess: () => {
                  history.push(backPath);
                }
              }
            );

            closeModal(DELETE_CLIENT_MODAL);
          }}
        >
          Delete client
        </WSButton>
        <WSButton.Tertiary
          onClick={() => {
            closeModal(DELETE_CLIENT_MODAL);
          }}
        >
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </WSModal>
  );
};
