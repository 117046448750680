import {
  WSButton,
  WSElement,
  WSEmptyState,
  WSIcon,
  WSInputText,
  WSMarginProps,
  WSRecentSearches,
  WSText
} from "@wingspanhq/fe-component-library";
import { useDebounce } from "primereact/hooks";
import React, { useCallback, useEffect, useRef } from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import { useLocalStorage } from "../../../../shared/hooks/useLocalStorage";
import styles from "./index.module.scss";

export type WSSearchPanelProps = {
  context: string;
  onBack?: () => void;
  value: string;
  onSearch: (newValue: string) => void;
  onClear: () => void;
  showClearButton?: boolean;
  className?: string;
  placeholder?: string;
  hideRecentSearches?: boolean;
} & WSMarginProps;

export const SearchPanel: React.FC<WSSearchPanelProps> = ({
  onBack,
  value: externalValue,
  onSearch,
  onClear,
  placeholder,
  context,
  hideRecentSearches = false,
  showClearButton = false,
  ...elementProps
}) => {
  const userId = useUserId();
  const [recentItems, setRecentItems] = useLocalStorage<string[]>(
    `${userId}:${context}-recent-searches`,
    []
  );
  const [value, debouncedValue, setValue] = useDebounce(externalValue, 300);

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  useEffect(() => {
    setValue(externalValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalValue]);

  useEffect(() => {
    if (debouncedValue !== externalValue) {
      onSearch(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const handleAddToRecentItems = useCallback(
    (newValue: string) => {
      if (newValue) {
        setRecentItems(
          [newValue, ...recentItems.filter(item => item !== newValue)].slice(
            0,
            5
          )
        );
      }
    },
    [recentItems, setRecentItems]
  );

  const handleClearRecentItems = useCallback(() => {
    setRecentItems([]);
  }, [setRecentItems]);

  const handleInstantSearch = useCallback(
    (value: string) => {
      onSearch(value);
      handleAddToRecentItems(value);
    },
    [handleAddToRecentItems, onSearch]
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleInstantSearch(value);
    }
  };

  const handleBlur = () => {
    handleAddToRecentItems(value);
  };

  const handleDocumentKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (value) {
          handleInstantSearch("");
        } else {
          onBack?.();
        }
      }
    },
    [handleInstantSearch, onBack, value]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleDocumentKeyUp);
    return () => {
      document.removeEventListener("keyup", handleDocumentKeyUp);
    };
  }, [handleDocumentKeyUp]);

  return (
    <WSElement {...elementProps}>
      <WSElement className={styles.searchPanel}>
        {onBack && (
          <WSElement className={styles.backButton} onClick={onBack}>
            <WSIcon name="arrow-left" color="gray400" size="M" />
            <WSText.ParagraphXs color="gray400">Back</WSText.ParagraphXs>
          </WSElement>
        )}
        <WSElement className={styles.inputContainer}>
          <WSInputText
            ref={searchInputRef}
            icon="search"
            value={value}
            onChange={setValue}
            placeholder={placeholder}
            inputAction={
              value
                ? {
                    icon: "exit",
                    onClick: () => {
                      handleInstantSearch("");
                    }
                  }
                : undefined
            }
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
          {showClearButton ? (
            <WSButton.Secondary
              size="M"
              disabled={!externalValue}
              onClick={onClear}
            >
              Clear all
            </WSButton.Secondary>
          ) : null}
        </WSElement>
      </WSElement>

      {!hideRecentSearches &&
        !externalValue &&
        (recentItems.length > 0 ? (
          <WSRecentSearches
            mt="L"
            items={recentItems}
            onClear={handleClearRecentItems}
            onSelect={newValue => {
              handleInstantSearch(newValue as string);
            }}
          />
        ) : (
          <WSEmptyState
            mt="L"
            fullWidth
            hasBorder
            bgColor="gray"
            type="search"
            title="No recent searches"
            description="Your search history will appear here for your convenience."
          />
        ))}
    </WSElement>
  );
};
