import footprint, { FootprintComponentKind } from "@onefootprint/footprint-js";
import { IPaymentCard } from "@wingspanhq/payments/dist/interfaces";
import { useCallback, useMemo } from "react";
import { useMutationCompleteOneTimePaymentCardCreate } from "../../query/paymentCards/mutations/useMutationCompleteOneTimePaymentCardCreate";
import { useMutationCompletePaymentCardCreate } from "../../query/paymentCards/mutations/useMutationCompletePaymentCardCreate";
import { useMutationInitiateOneTimePaymentCardCreate } from "../../query/paymentCards/mutations/useMutationInitiateOneTimePaymentCardCreate";
import { useMutationInitiatePaymentCardCreate } from "../../query/paymentCards/mutations/useMutationInitiatePaymentCardCreate";
import { footprintAppearanceConfig } from "../constants/footprint";

type Config = {
  clientId?: string;
  oneTime?: boolean;
};

type OpenConfig = {
  onCancel?: () => void;
  onSuccess?: (paymentCard: IPaymentCard) => void;
  variant?: "modal" | "inline";
  containerId?: string;
};

export const usePaymentCardForm = (config?: Config) => {
  const [initiateCreate] = useMutationInitiatePaymentCardCreate();
  const [completeCreate] = useMutationCompletePaymentCardCreate();
  const [initiateOneTimeCreate] = useMutationInitiateOneTimePaymentCardCreate();
  const [completeOneTimeCreate] = useMutationCompleteOneTimePaymentCardCreate();

  const userId = useMemo(
    () => (config?.oneTime ? config.clientId : undefined),
    [config?.oneTime, config?.clientId]
  );

  const initiate = useCallback(async () => {
    if (userId) {
      return initiateOneTimeCreate({
        userId
      });
    }

    return initiateCreate();
  }, [initiateCreate, initiateOneTimeCreate, userId]);

  const complete = useCallback(
    async (
      paymentCardId: string,
      onSuccess?: (paymentCard: IPaymentCard) => void
    ) => {
      if (userId) {
        return completeOneTimeCreate(
          {
            paymentCardId,
            userId
          },
          { onSuccess }
        );
      }

      return completeCreate(
        {
          paymentCardId,
          saveForFutureUse: true
        },
        { onSuccess }
      );
    },
    [completeCreate, completeOneTimeCreate, userId]
  );

  const open = useCallback(async (config?: OpenConfig) => {
    const paymentCardCreateInitiate = await initiate();

    if (!paymentCardCreateInitiate) return;

    const component = footprint.init({
      appearance: footprintAppearanceConfig,
      authToken: paymentCardCreateInitiate.footprintToken,
      kind: FootprintComponentKind.Form,
      variant: config?.variant || "modal",
      containerId: config?.containerId || "root",
      onCancel: config?.onCancel,
      onClose: config?.onCancel,
      options: {
        hideFootprintLogo: true
      },
      onComplete: () => {
        complete(paymentCardCreateInitiate.paymentCardId, config?.onSuccess);

        component.destroy();
      }
    });

    component.render();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    open
  };
};
