class WSEvents {
  list = {
    OTP_REQUEST: new Event("opt_request"),
    OTP_COMPLETE: new Event("opt_complete"),
    OTP_FAIL: new Event("opt_fail"),
    FORCE_LOGOUT: new Event("force_logout")
  };

  dispatch(event: Event) {
    document.dispatchEvent(event);
  }

  addListener(
    event: Event,
    listener: (event: Event) => void,
    options?: boolean | AddEventListenerOptions
  ) {
    document.addEventListener(event.type, listener, options);
  }

  removeListener(event: Event, listener: (event: Event) => void) {
    document.removeEventListener(event.type, listener);
  }
}

export const wsEvents = new WSEvents();
