import {
  useModalContext,
  WSElementProps,
  WSModal
} from "@wingspanhq/fe-component-library";
import { ICollaboratorSchema } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { AddCollaboratorForm } from "../AddCollaboratorForm/AddCollaboratorForm";

type Props = WSElementProps;

export const ADD_COLLABORATOR_MODAL = "ADD_COLLABORATOR_MODAL";

export const AddCollaboratorModal: React.FC<Props> = () => {
  const history = useHistory();
  const { closeModal } = useModalContext();

  return (
    <WSModal name={ADD_COLLABORATOR_MODAL} size="S" title="Add contractor">
      {({
        onSuccess,
        onCancel
      }: {
        onSuccess?: (email: string, collaborator: ICollaboratorSchema) => void;
        onCancel?: () => void;
      } = {}) => (
        <AddCollaboratorForm
          onCancel={() => {
            onCancel?.();
            closeModal(ADD_COLLABORATOR_MODAL);
          }}
          onSuccess={(collaborator, email) => {
            closeModal(ADD_COLLABORATOR_MODAL);
            if (onSuccess) {
              onSuccess(email, collaborator);
              return;
            }

            history.push(
              `/member/invoices/contacts/collaborators/${collaborator.collaboratorId}`,
              {
                backPath: "/member/invoices/contacts/collaborators"
              }
            );
          }}
        />
      )}
    </WSModal>
  );
};
