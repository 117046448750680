import cn from "classnames";
import { InputTextarea, InputTextareaProps } from "primereact/inputtextarea";
import React from "react";
import { getFsExcludeClassName } from "../../../utils/getFsExcludeClassName";
import { getInputTestIdByName } from "../../../utils/getInputTestIdByName";
import { WSInputBaseProps } from "../../types/forms";
import { WSElement } from "../WSElement/WSElement.component";
import { detachFieldProps, WSField, WSFieldProps } from "../WSField/WSField";
import { getStatusStyles } from "../WSInputContainer/getStatusStyles";
import styles from "./WSInputTextarea.module.scss";

export type WSInputTextareaProps = WSInputBaseProps<
  string,
  React.ChangeEvent<HTMLTextAreaElement>,
  React.FocusEvent<HTMLTextAreaElement>
> &
  WSFieldProps & {
    autoResize?: InputTextareaProps["autoResize"];
    rows?: InputTextareaProps["rows"];
    minLength?: number;
    maxLength?: number;
  };

export const WSInputTextarea = React.forwardRef<
  HTMLTextAreaElement,
  WSInputTextareaProps
>((props, ref) => {
  const {
    name,
    status,
    autoResize = false,
    rows = 3,
    className,
    disabled,
    onChange,
    inputClassName,
    fsExclude,
    ...otherProps
  } = props;

  const containerClassName = cn(
    styles.textareaContainer,
    getStatusStyles(status),
    {
      [styles.disabled]: disabled
    },
    className
  );

  const textareaClassName = cn(
    styles.textarea,
    inputClassName,
    getFsExcludeClassName(fsExclude)
  );
  const { fieldProps, ...inputProps } = detachFieldProps(otherProps);

  return (
    <WSField {...fieldProps} status={status}>
      <WSElement className={containerClassName}>
        <InputTextarea
          ref={ref}
          disabled={disabled}
          autoResize={autoResize}
          rows={rows}
          onChange={(e) => onChange(e.target.value, e)}
          className={textareaClassName}
          data-testid={getInputTestIdByName(name)}
          {...inputProps}
        />
      </WSElement>
    </WSField>
  );
});

WSInputTextarea.displayName = "WSInputTextarea";
