import {
  WSButton,
  WSButtons,
  WSForm,
  WSText,
  WSTextArea
} from "@wingspanhq/fe-component-library";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useDisputeInvoice } from "../../../../query/payments/mutations";
import { IInvoiceRow } from "../../service";

type Props = {
  invoice: IInvoiceRow;
  onClose: () => void;
};

export const ModalDispute: React.FC<Props> = ({ invoice, onClose }) => {
  const [disputeInvoice, disputeInvoiceMeta] = useDisputeInvoice();

  return (
    <WSForm<{ disputeComment: string }>
      onSubmit={({ disputeComment }) => {
        disputeInvoice(
          {
            invoiceId: invoice.invoiceId,
            disputeComment
          },
          {
            onSuccess: onClose
          }
        );
      }}
    >
      <WSText mb="XL">
        Indicate the reason for the dispute in the field below
      </WSText>

      <WSForm.Field mb="XL" name="disputeComment" component={WSTextArea} />

      <WSErrorMessage
        mb="XL"
        error={disputeInvoiceMeta.error}
        contextKey="DisputeInvoice"
      />

      <WSButtons format="modal">
        <WSButton name="disputeInvoice" loading={disputeInvoiceMeta.isLoading}>
          Dispute invoice
        </WSButton>
        <WSButton.Secondary
          name="disputeInvoice"
          onClick={onClose}
          type="button"
        >
          Cancel
        </WSButton.Secondary>
      </WSButtons>
    </WSForm>
  );
};
