import { components } from "../../services/api/onboarding/types";
import { WS_LINKS } from "../../types/wsLinks";

export const bankingAcknowledgements: components["schemas"]["AcknowledgementName"][] = [
  "DepositAccountHolderAgreement",
  "LeadBankTerms",
  "ElectronicDisclosureAndConsent"
];

export const bankingAccountAcknowledgements: components["schemas"]["AcknowledgementName"][] = [
  ...bankingAcknowledgements,
  "WingspanPrivacyPolicyAcceptance",
  "WingspanTosAcceptance"
];

export const taxWithholdingAccountAcknowledgements: components["schemas"]["AcknowledgementName"][] = [
  ...bankingAcknowledgements,
  "WingspanPrivacyPolicyAcceptance",
  "WingspanTosAcceptance"
];

export const internationalClearingAccountAcknowledgements: components["schemas"]["AcknowledgementName"][] = [
  "CurrencyCloudTerms"
];

export const debitCardAcknowledgements: components["schemas"]["AcknowledgementName"][] = [
  "DebitCardHolderAgreement",
  "CashBackPromotionAgreement",
  ...bankingAccountAcknowledgements
];

// TODO: Add the rest of the acknowledgements
export const virtualCardAcknowledgements: components["schemas"]["AcknowledgementName"][] = [
  "DebitCardHolderAgreement",
  "CashBackPromotionAgreement",
  ...bankingAccountAcknowledgements
];

export const acknowledgementVersions: Record<
  components["schemas"]["AcknowledgementName"],
  string
> = {
  W9Certification: "2024-03-01",
  W8BenCertification: "2021-10-01",
  CanadianTaxPayerCertification: "2024-08-01",
  LocalTaxPayerCertification: "2024-08-01",
  WingspanTosAcceptance: "2024-07-22",
  WingspanPrivacyPolicyAcceptance: "2024-07-22",
  DepositAccountHolderAgreement: "2024-07-22",
  CashBackPromotionAgreement: "2022-10-04",
  DebitCardHolderAgreement: "2022-07-22",
  MoovTermsOfService: "2024-02-05",
  LeadBankTerms: "2022-08-09",
  ElectronicTaxFormConsent: "2024-08-01",
  ElectronicDisclosureAndConsent: "2024-08-01",
  CurrencyCloudTerms: "2024-09-25"
};

type AcknowledgementInfo = {
  link: string;
  title: string;
  description?: string;
};

export const acknowledgementInfo: Record<
  components["schemas"]["AcknowledgementName"],
  AcknowledgementInfo
> = {
  WingspanTosAcceptance: {
    title: "Wingspan Terms of Service",
    link: WS_LINKS.wingspanTosAcceptance,
    description:
      "By using Wingspan's services, you agree to abide by our Terms of Service."
  },
  WingspanPrivacyPolicyAcceptance: {
    title: "Privacy Notice",
    description:
      "We value your privacy. Our Privacy Notice details how we collect, use, and protect your personal information.",
    link: WS_LINKS.wingspanPrivacyPolicyAcceptance
  },
  DepositAccountHolderAgreement: {
    title: "Business Deposit Account Agreement",
    description:
      "By opening an account with us, you agree to the terms outlined in our Wingspan Business Deposit Account Agreement. Please review this document carefully.",
    link: WS_LINKS.depositAccountTermsConditions
  },
  CashBackPromotionAgreement: {
    title: "Wingspan Cash Back Terms & Conditions",
    description:
      "Our cash back program is subject to specific terms and conditions.",
    link: WS_LINKS.cashBackTermsAndConditions
  },
  DebitCardHolderAgreement: {
    title: "Debit Card Holder Agreement",
    description:
      "Use of our debit card is governed by the Business VISA Debit Cardholder Agreement. This agreement outlines the terms and conditions for using your Wingspan debit card.",
    link: WS_LINKS.debitCardHolderAgreement
  },
  MoovTermsOfService: {
    title: "Moov Platform Agreement",
    description:
      "As part of our service infrastructure, we use Moov's platform. By using our services, you also agree to Moov's Platform Agreement, which governs certain aspects of payment processing.",
    link: WS_LINKS.moovTermsOfService
  },
  LeadBankTerms: {
    title: "Lead Bank Terms of Use",
    description:
      "Banking services are provided by Lead Bank, member FDIC. By using our banking services, you also agree to Lead Bank's Terms of Use.",
    link: WS_LINKS.leadBankTerms
  },
  ElectronicDisclosureAndConsent: {
    title: "Electronic Disclosure and Consent",
    description:
      "By opening an account, you agree to receive all communications from us electronically. This includes important disclosures, notices, and other information related to your account.",
    link: WS_LINKS.electronicDisclosureAndConsent
  },
  CurrencyCloudTerms: {
    title: "Cross-Border Payment Services Agreement",
    description:
      "Our services power international payments. By using our cross-border payment capabilities, you agree to the Currency Cloud Terms of Use, which govern these international transactions.",
    link: WS_LINKS.crossBorderPaymentServicesAgreement
  },

  // Tax certification acknowledgements are implemented separatelly
  W9Certification: {
    title: "",
    description: "",
    link: ""
  },
  W8BenCertification: {
    title: "",
    description: "",
    link: ""
  },
  CanadianTaxPayerCertification: {
    title: "",
    description: "",
    link: ""
  },
  LocalTaxPayerCertification: {
    title: "",
    description: "",
    link: ""
  },
  ElectronicTaxFormConsent: {
    title: "",
    description: "",
    link: ""
  }
};
