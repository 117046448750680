import { WSList, WSText } from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  useMemberProfile,
  useUserProfile
} from "../../../../query/users/queries";
import { emptyValues as emptyAccountTypeValues } from "../../components/FormAccountType/emptyValues";
import { getProfileDataPerson } from "../../components/FormPartialPerson/getProfileDataPerson";
import { FormRepresentative } from "../../components/FormRepresentative";
import { useOnboardignModules, useOnboardingContext } from "../../Context";
import { OnboardingModuleCreateAccount, OnboardingStep } from "../../types";
import { CreateAccountContext } from "./types";

export const Representative: React.FC<OnboardingStep> = ({
  onNext,
  onBack
}) => {
  const [context, setContext] = useOnboardingContext<CreateAccountContext>();

  const modules = useOnboardignModules();
  const onboardingModule = modules.find(m => m.type === "create_account") as
    | OnboardingModuleCreateAccount
    | undefined;
  const verificationLevelIsBanking =
    onboardingModule?.options?.verificationLevel === "Banking";

  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const profileData = useMemo(
    () => ({
      representative: getProfileDataPerson(queryUser.data, queryMember.data)
    }),
    [queryMember.data, queryUser.data]
  );

  const country =
    context.accountType?.country || emptyAccountTypeValues.country;
  const singleOwnershipStake =
    country === "US" &&
    context.accountType?.type === "Business" &&
    context.businessInformation?.taxClassification === "LlcSingleMember";

  return (
    <WSList gap="2XL">
      <FormRepresentative
        singleOwnershipStake={singleOwnershipStake}
        defaultValues={context.representative || profileData}
        country={country}
        onBack={onBack}
        onSubmit={data => {
          setContext(prev => ({
            ...prev,
            representative: data
          }));
          onNext?.();
        }}
        includeBeneficialOwners={verificationLevelIsBanking}
        requireTaxId={verificationLevelIsBanking}
      />

      <WSText.ParagraphXs color="gray400">
        The information on this page is required to setup your Wingspan account,
        if you exit or leave without completing this step, you will return to
        this page the next time you sign-in
      </WSText.ParagraphXs>
    </WSList>
  );
};
