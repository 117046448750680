import React from "react";
import {
  WSContainer,
  WSCentered,
  WSText
} from "@wingspanhq/fe-component-library";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { useHistory } from "react-router-dom";
import { AccountsDashboard } from "../../components/AccountsDashboard/AccountsDashboard";
import { queryCache } from "react-query";
import {
  QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_EXPENSE,
  QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_INCOME
} from "../../../query/bookkeeping/keys";

export const BookkeepingEditAccounts: React.FC = () => {
  const history = useHistory();

  useBrowserPageTitle("Bookkeeping - Accounts");

  return (
    <ProjectOnboardingLayout>
      <WSContainer verticalPadding>
        <WSCentered span={{ m: "6" }}>
          <WSText.Heading4>Transaction Imports</WSText.Heading4>
          <WSText my="XL">
            Link all accounts containing business transactions to find the most
            tax savings.
          </WSText>

          <AccountsDashboard
            onSave={async () => {
              await queryCache.removeQueries(
                QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_EXPENSE
              );
              await queryCache.removeQueries(
                QUERY_BOOKKEEPING_CATCH_UP_TRANSACTIONS_INCOME
              );
              history.push("/member/bookkeeping");
            }}
            onContinueSetup={(institutionId, accounts) => {
              history.push(`/member/bookkeeping/imports/${institutionId}/sync`);
            }}
            onCSV={() => {
              history.push(`/member/bookkeeping/imports/csv`);
            }}
            onPlaidFinish={(institutionId, accounts) => {
              history.push(`/member/bookkeeping/imports/${institutionId}/sync`);
            }}
            onManageInstitution={({ id }) => {
              history.push(`/member/bookkeeping/imports/${id}/sync`);
            }}
          />
        </WSCentered>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
