import { QueryConfig } from "react-query";
import { IPayerResponse } from "@wingspanhq/payments/dist/interfaces";

import { getPayer } from "../../../services/payers";
import { useWSQuery } from "../../helpers";
import { QUERY_PAYER } from "../keys";

export const usePayerQuery = (
  id: string,
  queryConfig?: QueryConfig<IPayerResponse, unknown>
) =>
  useWSQuery([QUERY_PAYER, id], () => getPayer(id), {
    retry: false,
    ...queryConfig
  });
