import { useWSMutation } from "../../helpers";
import { WSServiceError } from "../../../utils/serviceHelper";
import {
  ICardResponse,
  ICreateCardRequest
} from "../../../services/api/banking/cards/types";
import { QUERY_CARD } from "../queries/useCardQuery";
import { QUERY_CARDS } from "../queries/useCardsQuery";
import { createCard } from "../../../services/api/banking/cards";

export const useCreateCard = () =>
  useWSMutation<ICardResponse, WSServiceError, ICreateCardRequest>(createCard, {
    dependencies(response, { replacementForPaymentCardId }) {
      return [[QUERY_CARD, replacementForPaymentCardId], QUERY_CARDS];
    }
  });
