import React from "react";
import { WSQueries } from "../../../../query/WSQuery";

import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { DefaultHeader } from "./DefaultHeader";
import { useRouteMatch } from "react-router-dom";
import { BulkResource } from "../../types";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { BulkPayableOpenBatchHeader } from "../../routes/BulkImportPayableBatchDetails/BulkPayableOpenBatchHeader";
import { BulkCollaboratorOpenBatchHeader } from "../../routes/BulkImportCollaboratorBatchDetails/BulkCollaboratorOpenBatchHeader";

interface BulkBatchDetailsHeaderProps {
  bulkResource: BulkResource;
}

export function BulkBatchDetailsHeader({
  bulkResource
}: BulkBatchDetailsHeaderProps) {
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;

  const { useBulkBatch } = getBulkQueriesByResource(bulkResource);
  const qBulkBatch = useBulkBatch(bulkBatchId);

  let OpenBatchHeader = BulkPayableOpenBatchHeader;
  if (bulkResource === BulkResource.Collaborator) {
    OpenBatchHeader = BulkCollaboratorOpenBatchHeader;
  }
  return (
    <WSQueries queries={{ qBulkBatch }}>
      {({ qBulkBatch: { data: bulkBatch } }) => {
        return bulkBatch.status === BulkStatus.Open ? (
          <OpenBatchHeader
            bulkBatchId={bulkBatchId}
            bulkResource={bulkResource}
          />
        ) : (
          <DefaultHeader
            bulkBatchId={bulkBatchId}
            bulkResource={bulkResource}
          />
        );
      }}
    </WSQueries>
  );
}
