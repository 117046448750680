import { IMember } from "@wingspanhq/users/dist/lib/interfaces";
import { selectorIsDomesticMember } from "../../selectors/selectorIsDomesticMember";

export const selectorIsSsnVisible = (
  member: IMember,
  skipTaxVerification?: boolean,
  isUSPerson?: boolean
) => {
  const isDomesticMember = selectorIsDomesticMember(member);
  const ssnNotProvided = !member.profile.ssnProvided;
  const tinEnabled = !skipTaxVerification;
  const domesticOrUSPerson = isDomesticMember || isUSPerson;

  return ssnNotProvided && tinEnabled && domesticOrUSPerson;
};
