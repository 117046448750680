import { useModalContext } from "@wingspanhq/fe-component-library";
import {
  AccountStatus,
  AccountType
} from "@wingspanhq/users/dist/lib/interfaces";
import { useCreateAccount } from "../../query/users/mutations";
import { useAccounts } from "../../query/users/queries";
import { useMemberPlaidLink } from "../../utils/usePlaidLink";
import { MODAL_PAYOUT_ACCOUNTS } from "../components/ModalPayoutAccounts";

export const useLinkPayoutAccount = (options?: {
  onSuccess?: () => void;
  forcePlaidOnLink?: boolean;
}) => {
  const { openModal } = useModalContext();
  const [createAccount, createAccountMeta] = useCreateAccount();
  const queryAccounts = useAccounts();

  const plaidHandler = useMemberPlaidLink({
    onSuccess: async (publicToken: string) => {
      const createResponse = await createAccount({
        publicToken,
        status: AccountStatus.Pending
      });

      let accounts = createResponse
        ? Array.isArray(createResponse)
          ? createResponse
          : [createResponse]
        : [];

      if (accounts.length > 0) {
        openModal(MODAL_PAYOUT_ACCOUNTS, {
          onSuccess: options?.onSuccess
        });

        return {
          success: true,
          data: accounts
        };
      } else {
        // If accounts is undefined or it's empty array
        console.log("No accounts created", accounts);
        return {
          success: false
        };
      }
    }
  });

  return {
    linkPayoutAccount: () => {
      const accounts = queryAccounts.data || [];

      const filteredAccounts = accounts.filter(
        account =>
          account.type === AccountType.Depository &&
          account.canBeUsedFor?.payouts
      );

      if (options?.forcePlaidOnLink || filteredAccounts.length === 0) {
        plaidHandler.open();
      } else {
        openModal(MODAL_PAYOUT_ACCOUNTS, {
          onSuccess: options?.onSuccess
        });
      }
    },
    plaidHandler,
    createAccountMeta
  };
};
