import {
  IIntegrationsQuickbooksResponse,
  IntegrationsQuickbooksAuthStatus,
  IntegrationsQuickbooksSyncStatus
} from "@wingspanhq/integrations/dist/lib/interfaces/quickbooks-api/integrationsQuickbooks";

export const isQuickbooksAuthenticated = (
  quickbooksServiceData: IIntegrationsQuickbooksResponse
) =>
  quickbooksServiceData.authStatus ===
  IntegrationsQuickbooksAuthStatus.Connected;

export const isQuickbooksSyncComplete = (
  quickbooksServiceData: IIntegrationsQuickbooksResponse
) =>
  quickbooksServiceData.syncStatus === IntegrationsQuickbooksSyncStatus.Synced;

export const isQuickbooksServiceActive = (
  quickbooksServiceData: IIntegrationsQuickbooksResponse
) =>
  quickbooksServiceData.authStatus ===
    IntegrationsQuickbooksAuthStatus.Connected &&
  quickbooksServiceData.syncStatus === IntegrationsQuickbooksSyncStatus.Synced;
