import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { useCallback, useState } from "react";

export const useAct = (actFn: Function) => {
  const { openSnackbar } = useWSSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const act = useCallback(async () => {
    try {
      setIsLoading(true);
      await actFn();
    } catch (error) {
      if (error instanceof Error) {
        setError(error);
        openSnackbar({
          type: "error",
          message: "Something went wrong. Please try again later."
        });
      } else {
        // WHY?
      }
    } finally {
      setIsLoading(false);
    }
  }, [actFn, openSnackbar]);

  return {
    act,
    isLoading,
    error
  };
};
