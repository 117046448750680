import {
  WSActions,
  WSControl,
  WSElement,
  WSForm,
  WSInfoBox,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import { updateAcknowledgements } from "../../../shared/utils/updateAcknowledgements";
import { useWSMutation } from "../../../query/helpers";
import { Acknowledgements } from "../../Onboarding/components/Acknowledgements";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import {
  debitCardAcknowledgements,
  virtualCardAcknowledgements
} from "../../../shared/constants/acknowledgements";
import { IAcknowledgements, WalletCardType } from "../types";

type Props = {
  cardType: WalletCardType;
  onBack?: () => void;
  onContinue: () => void;
};

const TITLES: Record<WalletCardType, string> = {
  virtual: "Create a Wingspan virtual debit card",
  physical: "Order a physical Wingspan business debit card"
};

const SUBTITLES: Record<WalletCardType, string> = {
  virtual:
    "You’ve opted to create a free Wingspan virtual debit card. Review and agree to all the agreements and terms below to continue.",
  physical:
    "You’ve opted to order a free physical Wingspan business debit card. Review and agree to all the agreements and terms below to continue."
};

const ACKNOWLEDGEMENTS: Record<WalletCardType, IAcknowledgements> = {
  virtual: virtualCardAcknowledgements,
  physical: debitCardAcknowledgements
};

export const FormCardAcknowledgements: React.FC<Props> = ({
  cardType,
  onBack,
  onContinue
}) => {
  const acknowledgements = ACKNOWLEDGEMENTS[cardType];
  const title = TITLES[cardType];
  const subtitle = SUBTITLES[cardType];

  const [submit, meta] = useWSMutation(async () => {
    await updateAcknowledgements(acknowledgements);
    onContinue();
  });

  return (
    <WSForm
      validationSchema={Yup.object().shape({
        agreement: Yup.boolean()
          .nullable()
          .required("Required")
      })}
      defaultValues={{ agreement: false }}
      onSubmit={submit}
    >
      <WSList gap="2XL">
        <WSPanel>
          <WSList gap="2XL">
            <WSElement>
              <WSSectionToolbar title={title} />
              <WSText.ParagraphSm color="gray500">
                {subtitle}
              </WSText.ParagraphSm>
            </WSElement>

            <WSInfoBox>
              <p>
                By clicking continue you agree that you have read, understood,
                and agree to the following:
              </p>

              <Acknowledgements aknowledgements={acknowledgements} />

              <p>
                Wingspan is not a financial services technology company and is
                not a bank. Banking services provided by Lead Bank, member FDIC.
              </p>
            </WSInfoBox>

            <WSForm.Field
              name="agreement"
              component={WSControl}
              componentProps={{
                type: "checkbox",
                label:
                  "I have read, and agree to all the above-mentioned documents and their contents.",
                size: "S"
              }}
            />

            <WSErrorMessage contextKey="Wallet" error={meta.error} />
          </WSList>
        </WSPanel>

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Back",
              kind: "Secondary",
              onClick: onBack,
              visible: !!onBack,
              type: "button"
            },
            {
              label: "Continue",
              loading: meta.isLoading
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};
