import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline
} from "react-google-login";
import { ISignInWithGoogleRequest } from "../../../../services/users";
import React from "react";
import { WSButton, WSMarginProps } from "@wingspanhq/fe-component-library";
import { useSignInWithGoogle } from "../../../../query/users/mutations";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import {
  IInvitation,
  IInvitationResponse,
  ISessionResponse
} from "@wingspanhq/users/dist/lib/interfaces";
import { useQueryAcknowledgement } from "../../../../query/onboarding/queries/useQueryAcknowledgement";
import { useMutationUpdateAcknowledgement } from "../../../../query/onboarding/mutations/useMutationUpdateAcknowledgement";
import { operations } from "../../../../services/api/onboarding/types";
import { acknowledgementVersions } from "../../../../shared/constants/acknowledgements";

type GoogleSignInProps = WSMarginProps & {
  invitationData?: IInvitationResponse;
  invitation?: IInvitation;
  title?: string;
  onBeforeClick?: (callback: () => void) => void;
  onSuccess: (request: ISessionResponse) => void;
  onFailure: (error: any) => void;
  email?: string;
};

export const GoogleSignIn: React.FC<GoogleSignInProps> = ({
  title,
  invitationData,
  invitation,
  onSuccess,
  onBeforeClick,
  onFailure,
  email,
  ...otherProps
}) => {
  const [signInWithGoogle, signInWithGoogleMeta] = useSignInWithGoogle();
  const [error, setError] = React.useState<any>(null);

  const queryWingspanTosAcceptanceAcknowledgement = useQueryAcknowledgement(
    "WingspanTosAcceptance"
  );
  const queryWingspanPrivacyPolicyAcceptanceAcknowledgement = useQueryAcknowledgement(
    "WingspanPrivacyPolicyAcceptance"
  );
  const queryElectronicDisclosureAndConsentAcknowledgement = useQueryAcknowledgement(
    "ElectronicDisclosureAndConsent"
  );

  const [updateAcknowledgement] = useMutationUpdateAcknowledgement();

  const onLoginSuccess = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    const request: ISignInWithGoogleRequest = {
      idToken: (response as GoogleLoginResponse).tokenId
    };

    signInWithGoogle(
      {
        ...request,
        ...(invitation?.invitationToken
          ? { invitationToken: invitation.invitationToken }
          : {})
      },
      {
        onSuccess: async response => {
          onSuccess(response);

          // Check if the user has not accepted the below acknowledgements
          if (
            queryWingspanTosAcceptanceAcknowledgement.data
              ?.acknowledgementStatus !== "Given" ||
            queryWingspanPrivacyPolicyAcceptanceAcknowledgement.data
              ?.acknowledgementStatus !== "Given" ||
            queryElectronicDisclosureAndConsentAcknowledgement.data
              ?.acknowledgementStatus !== "Given"
          ) {
            // set the acknowledgement for the below
            // 1. WingspanTosAcceptance
            // 2. WingspanPrivacyPolicyAcceptance
            // 3. ElectronicDisclosureAndConsent
            const consents: operations["getAcknowledgement"]["parameters"]["path"]["acknowledgementName"][] = [
              "WingspanTosAcceptance",
              "WingspanPrivacyPolicyAcceptance",
              "ElectronicDisclosureAndConsent"
            ];
            await Promise.all(
              consents.map(async consent => {
                updateAcknowledgement({
                  acknowledgementName: consent,
                  requestData: {
                    acknowledgementName: consent,
                    acknowledgementStatus: "Given",
                    version: acknowledgementVersions[consent]
                  }
                });
              })
            );
          }
        }
      }
    );
  };

  const onLoginFailure = (error: any) => {
    const { error: errorMessage } = error;
    // handle individual error messages
    let passOnError = false;
    // if message is not about idpi or closing the popup, show it
    if (
      !(
        errorMessage === "idpiframe_initialization_failed" ||
        errorMessage === "popup_closed_by_user"
      )
    ) {
      passOnError = true;
    }
    if (passOnError) {
      setError(error);
      onFailure(error);
    }
  };

  return (
    <>
      <GoogleLogin
        clientId={process.env.REACT_APP_SIGN_IN_WITH_GOOGLE_CLIENT_ID || ""}
        onSuccess={onLoginSuccess}
        onFailure={onLoginFailure}
        loginHint={email}
        render={renderProps => (
          <WSButton.Tertiary
            fullWidth
            type="button"
            size="M"
            onClick={() => {
              if (typeof onBeforeClick === "function") {
                onBeforeClick(renderProps.onClick);
              } else {
                renderProps.onClick();
              }
            }}
            disabled={renderProps.disabled}
            {...otherProps}
          >
            {title}
          </WSButton.Tertiary>
        )}
      />
      <WSErrorMessage
        my="XL"
        contextKey="Auth"
        forceShowApiErrors
        error={signInWithGoogleMeta.error || error}
      />
    </>
  );
};
