type BulkActionContext = any;

export function bulkActionFactory<T = any>({
  action,
  onStart,
  onFinish,
  concurrentLimit = 16
}: {
  action: (item: T, context?: BulkActionContext) => Promise<any>;
  onStart?: () => void;
  onFinish?: (result: { successItems: T[]; failedItems: T[] }) => void;
  concurrentLimit?: number;
}) {
  const run = async (
    items: T[],
    context?: BulkActionContext
  ): Promise<{
    successItems: T[];
    failedItems: T[];
  }> => {
    await onStart?.();

    const successItems: T[] = [];
    const failedItems: T[] = [];

    const requests = [...items];

    async function next(...batchItems: T[]) {
      await Promise.all(
        batchItems.map(async item => {
          try {
            await action(item, context);
            successItems.push(item);
          } catch {
            failedItems.push(item);
          }

          const nextItem = requests.pop();

          if (nextItem) {
            await next(nextItem);
          }
        })
      );
    }

    await next(...requests.splice(0, concurrentLimit));

    await onFinish?.({
      successItems,
      failedItems
    });

    return {
      successItems,
      failedItems
    };
  };

  return {
    run
  };
}
