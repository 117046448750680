import {
  useWSModal,
  WSButton,
  WSCheckboxToggle,
  WSElement,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { BankCard } from "../../../../shared/components/BankCard";
import { WS_LINKS } from "../../../../types/wsLinks";
import { openInNewTab } from "../../../../utils/openInNewTab";
import {
  IPayeeResponse,
  PaymentRequirementStrategy
} from "@wingspanhq/payments/dist/interfaces";
import { getPayeeNames } from "../../selectors/getPayeeNames";
import { IClientProfile } from "@wingspanhq/users";
import { BankAccount } from "../../../../shared/components/BankAccount";
import { useUpdatePayee } from "../../../../query/payee/mutations/useUpdatePayee";

type Props = {
  clientProfile: IClientProfile;
  payee: IPayeeResponse;
  onClose: () => void;
};

const ConfirmAutopayModal: React.FC<Props> = ({
  onClose,
  payee,
  clientProfile
}) => {
  const names = getPayeeNames(payee);
  const companyName = names.getResolvedName();
  const [updatePayee, updatePayeeMeta] = useUpdatePayee();

  const [agreement, setAgreement] = useState(false);

  return (
    <WSElement>
      <WSText mt="XL">
        {`Easily automate your ${companyName} invoice payments with our Autopay feature.`}
      </WSText>
      <WSText mt="XL">
        By enrolling, you authorize us to securely charge your payment method
        for each invoice, ensuring timely and hassle-free transactions. Review
        the terms, provide your consent, and let Autopay manage the rest.
      </WSText>

      <WSText weight="medium" my="XL">
        Payment Method
      </WSText>
      {clientProfile.defaultPaymentMethod?.accountId && (
        <WSPanel mb="L" p="L">
          <BankAccount
            accountId={clientProfile.defaultPaymentMethod.accountId}
          />
        </WSPanel>
      )}

      {clientProfile.defaultPaymentMethod?.paymentMethodId && (
        <WSPanel mb="L" p="L">
          <BankCard
            type="credit"
            cardId={clientProfile.defaultPaymentMethod.paymentMethodId}
          />
        </WSPanel>
      )}

      <WSCheckboxToggle
        my="XL"
        name="agreenment"
        label={`I agree to enroll in Autopay for ${companyName}`}
        value={agreement}
        onChange={value => {
          setAgreement(value);
        }}
      />

      <WSButton
        disabled={!agreement}
        fullWidth
        onAsyncClick={async () => {
          await updatePayee({
            payeeId: payee.payeeId,
            payerOwnedData: {
              autoPayStrategy: PaymentRequirementStrategy.All
            }
          });

          onClose();
        }}
      >
        Continue
      </WSButton>

      <WSText.ParagraphXs color="gray500" mt="XL">
        You can always turn off autopay:{" "}
        <WSButton.Link
          size="S"
          onClick={() => openInNewTab(WS_LINKS.autopayHelpGuide)}
        >
          Autopay Help Guide
        </WSButton.Link>
      </WSText.ParagraphXs>
    </WSElement>
  );
};

export const useConfirmAutopayModal = () =>
  useWSModal(ConfirmAutopayModal, {
    title: "Consent to paying automatically",
    size: "S"
  });
