import React from "react";
import { getDocumentNameFromContext, NotificationTemplate } from "../index";
import { useHistory } from "react-router-dom";
import { useCountersignDocument } from "../../../../../query/files/mutations";
import { WSButton } from "@wingspanhq/fe-component-library";

export type SignatureClientContext = {
  document: {
    documentId: string;
    title: string;
  };
  member: {
    memberId: string;
    fullName: string;
  };
  memberClient: {
    memberClientId: string;
  };
};
export const SignatureClient: NotificationTemplate<SignatureClientContext> = {
  renderTitle: props => "E-signatures required",
  renderBody: props => {
    const memberName = props.context.member.fullName;
    const documentName = getDocumentNameFromContext(props.context);

    if (props.group.length > 1) {
      return "Countersign documents that have been signed by your contractors.";
    }

    return (
      <>
        Countersign <b>{documentName}</b> that have been signed by{" "}
        <b>{memberName}</b>
      </>
    );
  },
  Footer: props => {
    const history = useHistory();
    const [countersign, countersignMeta] = useCountersignDocument();
    const documentName = getDocumentNameFromContext(props.context);

    if (props.group.length > 1) {
      return (
        <WSButton.Link
          size="S"
          rightIcon="chevron-right"
          onClick={() => {
            history.push("/member/dashboard/clients-e-signatures");
          }}
        >
          Sign documents
        </WSButton.Link>
      );
    } else {
      return (
        <WSButton.Link
          size="S"
          rightIcon="chevron-right"
          onAsyncClick={async () => {
            await countersign({
              documentId: props.context?.document?.documentId,
              collaboratorId: props.context?.memberClient?.memberClientId
            });
          }}
        >
          Sign <b>{documentName}</b>
        </WSButton.Link>
      );
    }
  }
};
