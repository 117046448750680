import { FormPartialDataPerson } from "../FormPartialPerson";

export const calculateTotalStake = (
  representativeOwnershipStake?: number | null,
  beneficialOwners?: FormPartialDataPerson[]
) =>
  (representativeOwnershipStake || 0) +
  (beneficialOwners || []).reduce(
    (acc, owner) => acc + (owner.ownershipStake || 0),
    0
  );
