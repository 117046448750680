import {
  WSElement,
  WSElementProps,
  WSIcon,
  WSImage
} from "@wingspanhq/fe-component-library";
import React from "react";
import { IS_PRODUCTION_ENV } from "../../../shared/constants/environment";

type Props = {
  institutionId?: string;
} & WSElementProps;

export const InstitutionLogo: React.FC<Props> = ({
  institutionId,
  ...elementProps
}) => {
  const bucketName = IS_PRODUCTION_ENV
    ? "wingspan-shared-content"
    : "wingspan-staging-shared-content";

  return (
    <WSElement {...elementProps}>
      <WSImage
        src={`https://storage.cloud.google.com/${bucketName}/plaid/logos/${institutionId}.png`}
        placeholder={<WSIcon block name="bank" />}
      />
    </WSElement>
  );
};
