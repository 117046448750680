import { serviceNotifications } from "./serviceNotifications";
import { IChannelNotificationResponse } from "@wingspanhq/notifications/dist/lib/interfaces";

export const patchDismissNotification = async (
  id: string
): Promise<IChannelNotificationResponse> => {
  const { data } = await serviceNotifications.patch(`/in-app/${id}/dismiss`);

  return data;
};
