import React from "react";
import { FieldValues, UseFormMethods, useFormContext } from "react-hook-form";

interface WSFormContextProps<TFieldValues extends FieldValues = FieldValues> {
  children(context: UseFormMethods<TFieldValues>): React.ReactNode;
}

// eslint-disable-next-line
export function WSFormContext<TFieldValues extends FieldValues = FieldValues>({
  children
}: WSFormContextProps<TFieldValues>) {
  const context = useFormContext<TFieldValues>();

  return <>{children(context)}</>;
}
