import {
  WSButton,
  WSCentered,
  WSContainer,
  WSElement,
  WSFileInput,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSLayout,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { IMemberPublicFileResponse } from "@wingspanhq/files/dist/lib/interfaces";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { WSQueries } from "../../query/WSQuery";
import { useMemberPublicCreate } from "../../query/files/mutations";
import { useGetMemberPublicFile } from "../../query/files/queries";
import { useUserId } from "../../query/hooks/helpers";
import {
  useUpdateMemberProfile,
  useUpdatePayLinkSetupActivity,
  useUpdateUserProfile
} from "../../query/users/mutations";
import { useMemberProfile, useUserProfile } from "../../query/users/queries";
import { PPLPrefix } from "../utils";
import styles from "./PersonalPayLinkCreate.module.scss";

const Logo: React.FC<{
  id: string;
  onLoad(d: IMemberPublicFileResponse): void;
}> = ({ id, onLoad }) => {
  const qFile = useGetMemberPublicFile(id, {
    refetchOnMount: true
  });

  useEffect(() => {
    if (qFile.data?.url) {
      onLoad(qFile.data);
    }
  }, [qFile.data?.url]);

  return null;
};

export const PersonalPayLinkCreate: React.FC = () => {
  const history = useHistory();
  const memberId = useUserId();
  const qMemberProfile = useMemberProfile(memberId);
  const qUserProfile = useUserProfile(memberId, {
    refetchOnMount: true
  });

  useBrowserPageTitle("Personal Pay Link - Create", "");

  const [file, setFile] = useState<IMemberPublicFileResponse | null>(null);

  const [
    uploadFile,
    { error: uploadLogoError, isLoading: isLogoUploading }
  ] = useMemberPublicCreate();
  const [
    updateMemberProfile,
    { error: updateMemberProfileError, isLoading: isProfileUpdating }
  ] = useUpdateMemberProfile();

  const [
    updatePayLinkSetupActivity,
    { error: updateActivityError, isLoading: isActivityUpdating }
  ] = useUpdatePayLinkSetupActivity();

  const [
    updateUserProfile,
    { error: updateUserProfileError, isLoading: isUserProfileUpdating }
  ] = useUpdateUserProfile();
  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.target && event.target.files && event.target.files) {
      const logoFile = event.target.files[0];
      const data = new FormData();
      data.append("file", logoFile);
      await uploadFile(data, {
        onSuccess: async response => {
          if (response.length > 0) {
            setFile(response[0]);
          }
        }
      });
    }
  };

  return (
    <WSQueries
      queries={{ memberData: qMemberProfile, userData: qUserProfile }}
      renderLoader={() => <WSLayout line />}
    >
      {({ memberData, userData }) => {
        return (
          <WSFormOld
            onSubmit={async formData => {
              if (userData.data.tag !== formData.tag) {
                await updateUserProfile(
                  {
                    tag: formData.tag
                  },
                  {
                    throwOnError: true
                  }
                );
              }

              if (
                file?.fileId &&
                memberData.data.profile.logoFileId !== file?.fileId
              ) {
                await updateMemberProfile(
                  {
                    memberId,
                    profile: {
                      logoFileId: file.fileId
                    }
                  },
                  {
                    throwOnError: true,
                    onError: () => {
                      setFile(null);
                    }
                  }
                );
              }

              await updatePayLinkSetupActivity(memberId, {
                throwOnError: true
              });

              history.push("/member/personal-pay-link/success");
            }}
            defaultValues={{
              tag: userData.data.tag || ""
            }}
            validationSchema={Yup.object().shape({
              tag: Yup.string().required("Link is required")
            })}
          >
            <WSLayout
              headerLeft={
                <WSIcon
                  block
                  name="chevron-left"
                  size="M"
                  color="gray500"
                  onClick={() => history.goBack()}
                />
              }
              headerCenter={<WSText weight="medium">Create my pay link</WSText>}
              line
            >
              <WSContainer verticalPadding>
                <WSFlexBox.Center direction="column" wrap="nowrap">
                  {memberData.data.profile.logoFileId ? (
                    <Logo
                      id={memberData.data.profile.logoFileId}
                      onLoad={setFile}
                    />
                  ) : null}
                  {file ? (
                    <>
                      <img
                        src={file?.url}
                        style={{
                          maxHeight: 50,
                          maxWidth: 80
                        }}
                      />
                      <WSText.ParagraphSm
                        mt="M"
                        color="garnet"
                        className={styles.action}
                        onClick={() => setFile(null)}
                      >
                        Remove logo
                      </WSText.ParagraphSm>
                    </>
                  ) : (
                    <WSFileInput
                      name="file"
                      onChange={onFileChange}
                      accept={"image/*"}
                    >
                      <WSButton.Link
                        type="button"
                        className={styles.uploadFileButton}
                        loading={isLogoUploading || isProfileUpdating}
                      >
                        Add logo
                      </WSButton.Link>
                    </WSFileInput>
                  )}

                  <WSErrorMessage
                    mt="M"
                    contextKey="UploadLogo"
                    error={uploadLogoError}
                  />
                </WSFlexBox.Center>
                <WSFlexBox.Center mt="2XL" mb="M">
                  <WSText.Heading4>
                    {memberData.data.profile.company?.name}
                  </WSText.Heading4>
                </WSFlexBox.Center>

                <WSCentered span={{ s: "8", m: "6", xl: "4" }}>
                  <WSFormOld.Field
                    name="tag"
                    mb="XL"
                    component={WSTextInput}
                    componentProps={{
                      prefix: <WSElement color="gray500">{PPLPrefix}</WSElement>
                    }}
                  />
                  <WSErrorMessage
                    mt="M"
                    contextKey="CreatePPL"
                    error={
                      updateUserProfileError ||
                      updateMemberProfileError ||
                      updateActivityError
                    }
                  />
                </WSCentered>
                <WSCentered span={{ s: "8", m: "6", xl: "4" }}>
                  <WSButton
                    name="createPPL"
                    fullWidth
                    mt="2XL"
                    mb="5XL"
                    type="submit"
                    loading={
                      isProfileUpdating ||
                      isUserProfileUpdating ||
                      isActivityUpdating
                    }
                  >
                    Create my wingspan.me link
                  </WSButton>
                </WSCentered>
              </WSContainer>
            </WSLayout>
          </WSFormOld>
        );
      }}
    </WSQueries>
  );
};
