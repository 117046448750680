import { cardsService } from "./cardsService";
import { ICardResponse, ISetPinRequest } from "./types";

export const updatePIN = async (
  cardId: string,
  request: ISetPinRequest
): Promise<ICardResponse> => {
  const { data } = await cardsService.post(`/${cardId}/set_pin`, request);

  return data;
};
