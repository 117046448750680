import {
  toCamelCase,
  useIsMobile,
  useIsTablet,
  useModalContext,
  WSButton,
  WSElement,
  WSElementProps,
  WSModal,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import cn from "classnames";
import React, { ReactNode, useState } from "react";
import styles from "./DefaultSettingItem.module.scss";
import { EditableSettingValue } from "./EditableSettingValue";
import {
  ReadOnlySettingValue,
  ReadOnlySettingValueProps,
  ValueTypeMap
} from "./ReadOnlySettingValue";

export interface DefaultSettingItemProps
  extends ReadOnlySettingValueProps,
    Omit<WSElementProps, "onClick"> {
  /**
   * `field` value must be matched with backend object key
   */
  field: string;
  label: string;
  description?: React.ReactNode;
  action?: string | ReactNode;
  onClick?: Function;
  onUpdate?: (data: any, field: string) => void;
  helpInfoText?: ReactNode;
  formContainerClassName?: string;
  readonly?: boolean;
  defaultEditable?: boolean;
}

export const DefaultSettingItem: React.FC<DefaultSettingItemProps> = ({
  field,
  label,
  description,
  value,
  valueType = ValueTypeMap.text,
  valueRenderer,
  action,
  onClick = () => {},
  onUpdate = (data: any, field: string) => {},
  helpInfoText,
  className = "",
  formContainerClassName = "",
  readonly = false,
  defaultEditable = false
}) => {
  const [editable, setEditable] = useState<boolean>(defaultEditable);
  const { openModal, closeModal } = useModalContext();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const onClickAction = () => {
    if (!readonly) {
      setEditable(!editable);
      if (isMobile || isTablet) {
        openModal(label);
      }
    }
    onClick();
  };

  const onFormSubmit = (data: any) => {
    setEditable(false);
    if (isMobile || isTablet) {
      closeModal(label);
    }
    onUpdate(data, field as string);
  };

  const onCancel = () => {
    setEditable(false);
    closeModal(label);
  };

  return (
    <WSElement
      mt="2XL"
      className={cn(styles.settingItem, className)}
      id={field}
    >
      <WSElement className={cn(styles.infoContainer, formContainerClassName)}>
        <WSText.ParagraphSm
          mb={description ? "XS" : "XL"}
          className={styles.label}
        >
          {label}
        </WSText.ParagraphSm>
        {description ? (
          <WSText.ParagraphSm mb="M" color="gray400">
            {description}
          </WSText.ParagraphSm>
        ) : null}
        <WSScreen.MobileAndTablet>
          <MobileUpdateForm
            label={label}
            onCancel={onCancel}
            field={field}
            value={value}
            onSubmit={onFormSubmit}
          />
        </WSScreen.MobileAndTablet>
        {editable ? (
          <WSScreen.Desktop>
            <EditableSettingValue
              field={field}
              value={value}
              onSubmit={onFormSubmit}
            />
          </WSScreen.Desktop>
        ) : (
          <ReadOnlySettingValue
            value={value}
            valueType={valueType}
            valueRenderer={valueRenderer}
          />
        )}
        {typeof helpInfoText === "string" ? (
          <WSText.ParagraphSm mt="2XL" color="gray600">
            {helpInfoText}
          </WSText.ParagraphSm>
        ) : (
          helpInfoText
        )}
      </WSElement>
      {action && (
        <WSElement className={styles.actionContainer}>
          {typeof action === "string" ? (
            <WSButton.Link
              key={action + editable}
              onClick={onClickAction}
              name={toCamelCase(field, editable ? "cancelEdit" : "edit")}
            >
              {editable ? "Cancel" : action}
            </WSButton.Link>
          ) : (
            action
          )}
        </WSElement>
      )}
    </WSElement>
  );
};

interface MobileUpdateFormProps extends DefaultSettingItemProps {
  onSubmit: (data: any) => void;
  onCancel: () => void;
}

const MobileUpdateForm: React.FC<MobileUpdateFormProps> = ({
  label,
  field,
  value,
  onSubmit,
  onCancel
}) => {
  return (
    <WSModal name={label} onClose={onCancel}>
      <WSElement>
        <WSText.Heading5 mb="M">Edit {label}</WSText.Heading5>

        <EditableSettingValue
          field={field}
          label={label}
          value={value}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </WSElement>
    </WSModal>
  );
};
