import {
  WSTableCell,
  WSTableColumn,
  toWSDateString
} from "@wingspanhq/fe-component-library";

export const columns: Array<WSTableColumn> = [
  {
    config: {
      header: {
        text: "Name"
      },
      gridTemplateSizeMax: "1fr"
    },
    renderFunction: row => <WSTableCell text={row.data.name} />
  },
  {
    config: {
      header: {
        text: "Created"
      },
      justify: "end"
    },
    renderFunction: row => (
      <WSTableCell text={toWSDateString(row.data.createdAt)} />
    )
  }
];
