import { openInNewTab } from "../../../../../shared/utils/openInNewTab";
import { NotificationTemplate } from "../index";

export const UnitVerification: NotificationTemplate<{
  unit: {
    applicationURL: string;
  };
}> = {
  renderTitle: props => "Re-submit documents to unlock all banking features",
  renderBody: props => {
    return "Our banking partner is requesting additional verification documents. To unlock all banking features like Wingspan Wallet and Tax Withholding, please provide the necessary verification documents.";
  },
  buttonAction: (props, history) => () => {
    return openInNewTab(props.context?.unit?.applicationURL!);
  },
  buttonText: () => "Re-upload verification documents"
};
