import {
  IPayeeTaxFormResponse,
  TinType
} from "@wingspanhq/payments/dist/interfaces";

export const selectorPayeeTaxFormPayerDefaultTinType = (
  data: IPayeeTaxFormResponse
) => {
  return data.payer?.profile?.company?.taxId && !false
    ? TinType.Business
    : !data.payer?.profile?.company?.taxId && false
    ? TinType.Individual
    : data.payer?.profile?.company?.taxId
    ? TinType.Business
    : false
    ? TinType.Individual
    : undefined;
};
