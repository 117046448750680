import { SIGNATURE_REQUIREMENT_EVENT } from "./modules/Signature/cells/utils";
import { SHARED_FILE_REQUIREMENT_EVENT } from "./modules/SharedFile/cells/utils";

export const ALL_REQUIREMENT_EVENTS = {
  ...SIGNATURE_REQUIREMENT_EVENT,
  ...SHARED_FILE_REQUIREMENT_EVENT
} as const;

// export const REQUIREMENT_EVENTS: {
//   [key in RequirementType]: Record<
//     keyof typeof ALL_REQUIREMENT_EVENTS,
//     RequirementEvent
//   >;
// } = {
//   [RequirementType.Signature]: SIGNATURE_REQUIREMENT_EVENT,
//   [RequirementType.SharedFile]: SHARED_FILE_REQUIREMENT_EVENT
// };

export type RequirementEvent = typeof ALL_REQUIREMENT_EVENTS[keyof typeof ALL_REQUIREMENT_EVENTS];
