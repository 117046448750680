import { WSText } from "@wingspanhq/fe-component-library";
import React from "react";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { PayablesTable } from "../../components/NewPayablesTable/PayablesTable";

export const BulkUploadedPayablesById: React.FC = () => {
  useBrowserPageTitle("Bulk uploaded Payables");

  return (
    <>
      <WSText.Heading1>Payables</WSText.Heading1>
      <WSText color="gray600" mb="2XL">
        Your recently bulk uploaded payables
      </WSText>

      <PayablesTable
        basePath="/member/invoices/payables/bulk-uploaded"
        visibleFilters={{
          status: true,
          collaborator: true,
          openedAtRange: true,
          paidAtRange: true
        }}
        showHeaders
        showInvoiceNumber
        showDateRequested
        showDueDate
        showDatePaid
        emptyState={{
          firstTime:
            "Every requested, scheduled, and paid payable will display here.",
          standart: "No payables."
        }}
      />
    </>
  );
};
