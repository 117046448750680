import {
  toWSDateString,
  WSButton,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSPill,
  WSTable,
  WSText
} from "@wingspanhq/fe-component-library";
import { ICustomField } from "@wingspanhq/payments/dist/interfaces";
import {
  ICollaboratorsPayoutsSummaryReportRequest,
  ICollaboratorsPayoutsSummaryReportResponse
} from "@wingspanhq/payments/dist/interfaces/api/reports";
import React, { useState } from "react";
import { useUserId } from "../../../../query/hooks/helpers";
import { useCollaboratorPayablesReportsQuery } from "../../../../query/payments/queries";
import { HeroRangeSelect } from "../../../../shared/components/HeroRangeSelect/HeroRangeSelect";
import { VerticalDivider } from "../../../../shared/components/VerticalDivider";
import { track } from "../../../../utils/analytics";
import { DownloadReportButton } from "../../components/DownloadReportButton/DownloadReportButton";
import { useSelectColumnsModal } from "../../components/ModalSelectColumns";
import { useReportColumns } from "../../hooks/useReportColumns";
import { ReportFormat, ReportType } from "../../types";
import { formatReportsValue } from "../../utils/formatReportsValue";
import { selectorColumnsWithAdditionalData } from "../../utils/selectorColumnsWithAdditionalData";
import { COLUMNS } from "./columns";
import { selectorTableColumns } from "../../utils/selectorTableColumns";

const COLUMNS_STORAGE_KEY =
  "REPORTS_COLLABORATOR_PAYABLES_SUMMARY_COLUMNS_STORAGE_KEY";

export const CollaboratorPayablesSummaryDashboard: React.FC<{
  collaboratorsAdditionalData: ICustomField[];
}> = ({ collaboratorsAdditionalData }) => {
  const userId = useUserId();
  const [selectedDateRange, setSelectedDateRange] = useState<[Date, Date]>([
    new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0),
    new Date(new Date().getFullYear(), 11, 31, 23, 59, 59, 999)
  ]);

  const columns = selectorColumnsWithAdditionalData<
    ICollaboratorsPayoutsSummaryReportResponse
  >(COLUMNS, collaboratorsAdditionalData, { prefixGroupName: false });

  const getColumnByKey = (key: string) =>
    columns.find(_column => key === _column.value)!;

  const [selectedColumns, setSelectedColumns] = useReportColumns(
    COLUMNS_STORAGE_KEY,
    userId,
    columns
  );

  const modalSelectColumns = useSelectColumnsModal();

  const [reportsData, setReportsData] = useState<
    {
      [key in string]?: ICollaboratorsPayoutsSummaryReportResponse[];
    }
  >({});

  const selectedRangeKey = selectedDateRange
    .map(d => toWSDateString(d, "monthDayYear"))
    .join(" - ");

  const isRunButtonDisabled = !selectedDateRange || !selectedColumns.length;

  const isDownloadButtonDisabled = !reportsData[selectedRangeKey]?.length;

  const filters: ICollaboratorsPayoutsSummaryReportRequest = {
    paidFromDate: selectedDateRange[0],
    paidToDate: selectedDateRange[1]
  };

  const queryReport = useCollaboratorPayablesReportsQuery(filters, {
    enabled: false
  });

  const currentReportsData = reportsData[selectedRangeKey];
  const tableData = reportsData[selectedRangeKey] || [];

  const getReportJSON = () => {
    if (!tableData.length) {
      return [];
    }
    return [
      selectedColumns.map(getColumnByKey).map(c => c?.label),
      ...tableData
        .map(data => {
          return selectedColumns.map(column => {
            let result;
            const currentColumn = getColumnByKey(column);

            try {
              result = formatReportsValue(currentColumn?.getTableCell?.(data));
            } catch (e) {
              console.error(`REPORT ERROR! "${currentColumn?.label}": `, e);
              result = undefined;
            }

            return result;
          });
        })
        .filter(arr => arr.some(Boolean))
    ];
  };

  return (
    <>
      <WSDivider mb="M" />
      <WSGrid>
        <WSGrid.Item span={{ s: "12" }}>
          <WSFlexBox.CenterY>
            <WSElement>
              <HeroRangeSelect
                color="blue500"
                position={"right"}
                customLabel={selectedRangeKey}
                onChange={data => {
                  setSelectedDateRange(data.value);
                }}
                value={selectedDateRange}
              />
            </WSElement>
            <VerticalDivider mr="XL" />
            <WSFlexBox.CenterY
              onClick={() =>
                modalSelectColumns.open({
                  selectedColumns,
                  columns,
                  onSubmit: ({ columns }) => {
                    setSelectedColumns(columns);
                  }
                })
              }
              data-testid="selectColumns"
            >
              <WSButton.Link icon="menu">Selected columns</WSButton.Link>
              <WSPill ml="M" theme="blue" text={`${selectedColumns.length}`} />
            </WSFlexBox.CenterY>

            <VerticalDivider mx="XL" />
            <WSButton.Primary
              name="runReport"
              disabled={isRunButtonDisabled}
              onAsyncClick={async () => {
                track("Report Ran", {
                  reportName: ReportType.CollaboratorPayablesSummary,
                  selectedColumnsCount: selectedColumns.length,
                  selectedColumns,
                  filters
                });
                const result = await queryReport.refetch({
                  throwOnError: true
                });

                setReportsData(data => ({
                  ...data,
                  ...{ [selectedRangeKey]: result }
                }));
              }}
            >
              Run report
            </WSButton.Primary>
          </WSFlexBox.CenterY>
        </WSGrid.Item>
      </WSGrid>
      <WSDivider mt="M" />
      <WSFlexBox.CenterY mt="2XL" mb="XL" justify="flex-end">
        <DownloadReportButton
          disabled={isDownloadButtonDisabled}
          getData={getReportJSON}
          onClick={(format: ReportFormat) => {
            track("Report Downloaded", {
              reportName: ReportType.CollaboratorPayablesSummary,
              selectedColumnsCount: selectedColumns.length,
              selectedColumns,
              filters,
              format
            });
          }}
          fileName={`Wingspan Contractor Report`}
          sheetName={selectedRangeKey}
        />
      </WSFlexBox.CenterY>

      {tableData.length ? (
        <WSElement
          style={{
            overflowX: selectedColumns.length > 6 ? "scroll" : undefined,
            transform: "rotateX(180deg)"
          }}
        >
          <WSTable<ICollaboratorsPayoutsSummaryReportResponse>
            mb="M"
            mt="XL"
            style={{
              minWidth:
                selectedColumns.length > 6
                  ? selectedColumns.length * 175
                  : undefined,
              transform: "rotateX(180deg)"
            }}
            columns={selectorTableColumns(
              selectedColumns.map(getColumnByKey).filter(Boolean)
            )}
            tableData={tableData.map((data, i) => ({
              data,
              id: `id_${i}_${data.memberId}`
            }))}
          />
        </WSElement>
      ) : (
        <WSFlexBox.Center mt="2XL">
          <WSText color="gray500">
            {currentReportsData?.length === 0
              ? `No contractors with payments found in this date range`
              : `Adjust period range then click 'Run Report'`}
          </WSText>
        </WSFlexBox.Center>
      )}
    </>
  );
};
