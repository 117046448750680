export const localStorageUtil = {
  setItemAsync: async function(key: string, value: string) {
    await null;
    return localStorage.setItem(key, value);
  },
  getItemAsync: async function(key: string) {
    await null;
    return localStorage.getItem(key);
  },
  getItem: function(key: string) {
    return localStorage.getItem(key);
  },
  setItem: function(key: string, value: string) {
    return localStorage.setItem(key, value);
  }
};
