import { IInternalAccount } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  ICheckbookCard,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { useMemo } from "react";
import { useBankingAccount } from "../../query/bookkeeping/queries";
import { useUserId } from "../../query/hooks/helpers";
import {
  usePayoutSettings,
  usePayoutSettingsDebitCards
} from "../../query/payments/queries";
import { useAccounts } from "../../query/users/queries";
import { getPossiblePayoutAccounts } from "../../query/users/selectors";
import { selectorAccountFullName } from "../../shared/selectors/selectorAccountFullName";
import { selectorDebitCardFullName } from "../../shared/selectors/selectorDebitCardFullName";

export type PayoutMethod =
  | {
      type: "account";
      data: IAccount;
      percentage?: number;
    }
  | { type: "debit"; data: ICheckbookCard; percentage?: number }
  | { type: "wallet"; data?: IInternalAccount; percentage?: number }
  | { type: "giftcard"; percentage?: number };

export const usePayoutMethods = () => {
  const userId = useUserId();
  const payoutSettingsQuery = usePayoutSettings(userId);
  const bankingAccountQuery = useBankingAccount();
  const accountsQuery = useAccounts();
  const payoutSettingsDebitCardsQuery = usePayoutSettingsDebitCards(userId);

  const payoutDestinations = payoutSettingsQuery.data?.payoutDestinations || [];
  const bankingAccount = bankingAccountQuery.data;
  const accounts = getPossiblePayoutAccounts(accountsQuery.data || []);
  const debitCards = payoutSettingsDebitCardsQuery.data || [];

  const standard = useMemo(() => {
    const standardPayoutMethods: PayoutMethod[] = [];
    const otherStandardPayoutMethods: PayoutMethod[] = [];

    let standardDestinationPercentage: number = 100;
    let standardWalletPercentage: number = 0;

    const standardDestination = payoutDestinations.find(
      destination => destination.payoutMethod === PayoutPreferences.Standard
    );

    if (standardDestination?.destinationId === "WeGift") {
      standardPayoutMethods.push({
        type: "giftcard",
        percentage: 100
      });
    } else {
      const payoutAccount = accounts.find(
        account => account.accountId === standardDestination?.destinationId
      );
      standardDestinationPercentage = standardDestination?.percentage || 0;
      standardWalletPercentage = 100 - standardDestinationPercentage;

      if (payoutAccount) {
        standardPayoutMethods.push({
          type: "account",
          data: payoutAccount,
          percentage: standardDestinationPercentage
        });
      }

      if (standardWalletPercentage > 0 && bankingAccount) {
        standardPayoutMethods.push({
          type: "wallet",
          data: bankingAccount,
          percentage: standardWalletPercentage
        });
      }
    }

    accounts
      .filter(
        account => account.accountId !== standardDestination?.destinationId
      )
      .forEach(account => {
        otherStandardPayoutMethods.push({
          type: "account",
          data: account
        });
      });

    if (standardWalletPercentage === 0 && bankingAccount) {
      otherStandardPayoutMethods.push({
        type: "wallet",
        data: bankingAccount
      });
    }

    return {
      active: standardPayoutMethods,
      other: otherStandardPayoutMethods
    };
  }, [
    payoutSettingsQuery.updatedAt,
    bankingAccountQuery.updatedAt,
    accountsQuery.updatedAt
  ]);

  const instant = useMemo(() => {
    const instantPayoutAccounts: PayoutMethod[] = [];
    const otherInstantPayoutAccounts: PayoutMethod[] = [];

    const instantDestination = payoutDestinations.find(
      destination => destination.payoutMethod === PayoutPreferences.Instant
    );
    const instantDestinationPercentage = instantDestination?.percentage || 0;
    const instantWalletPercentage = 100 - instantDestinationPercentage;

    accounts
      .filter(
        account =>
          account.accountId !== instantDestination?.destinationId &&
          account.canBeUsedFor?.rtp
      )
      .forEach(account => {
        otherInstantPayoutAccounts.push({
          type: "account",
          data: account
        });
      });

    debitCards
      .filter(card => card.cardId !== instantDestination?.destinationId)
      .forEach(card => {
        otherInstantPayoutAccounts.push({
          type: "debit",
          data: card
        });
      });

    if (instantWalletPercentage === 100 && bankingAccount) {
      // only the wallet is active
      instantPayoutAccounts.push({
        type: "wallet",
        data: bankingAccount,
        percentage: instantWalletPercentage
      });
    } else {
      // add the non-wallet account to the list
      const instantPayoutAccount = accounts.find(
        account => account.accountId === instantDestination?.destinationId
      );
      if (instantPayoutAccount) {
        instantPayoutAccounts.push({
          type: "account",
          data: instantPayoutAccount,
          percentage: instantDestinationPercentage
        });
      } else {
        const instantPayoutDebitCard = debitCards.find(
          card => card.cardId === instantDestination?.destinationId
        );
        if (instantPayoutDebitCard) {
          instantPayoutAccounts.push({
            type: "debit",
            data: instantPayoutDebitCard,
            percentage: instantDestinationPercentage
          });
        }
      }

      if (bankingAccount) {
        if (instantWalletPercentage > 0) {
          instantPayoutAccounts.push({
            type: "wallet",
            data: bankingAccount,
            percentage: instantWalletPercentage
          });
        } else {
          otherInstantPayoutAccounts.push({
            type: "wallet",
            data: bankingAccount
          });
        }
      }
    }

    return {
      active: instantPayoutAccounts,
      other: otherInstantPayoutAccounts
    };
  }, [
    payoutSettingsQuery.updatedAt,
    bankingAccountQuery.updatedAt,
    accountsQuery.updatedAt,
    payoutSettingsDebitCardsQuery.updatedAt
  ]);

  return {
    payoutPreferences: payoutSettingsQuery.data?.payoutPreferences,

    payoutMethods: {
      standard,
      instant
    },

    queries: {
      payoutSettingsQuery,
      bankingAccountQuery,
      accountsQuery,
      payoutSettingsDebitCardsQuery
    }
  };
};

export const getPayoutMethodName = (payoutMethod: PayoutMethod) =>
  payoutMethod.type === "account"
    ? selectorAccountFullName(payoutMethod.data)
    : payoutMethod.type === "debit"
    ? selectorDebitCardFullName(payoutMethod.data)
    : payoutMethod.type === "giftcard"
    ? "Gift Card"
    : `Wingspan Wallet${
        payoutMethod.data
          ? ` (${payoutMethod.data.numbers?.account?.slice(-4)})`
          : ""
      }`;
