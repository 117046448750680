import { IMemberClient } from "@wingspanhq/payments/dist/interfaces";

export const getClientName = (memberClient: IMemberClient) =>
  memberClient.name || (memberClient.client.user.profile as any)?.preferredName;

export const getClientCompanyName = (memberClient: IMemberClient) =>
  memberClient.company || memberClient.client.profile?.company?.name;

export const getClientEmail = (memberClient: IMemberClient) =>
  memberClient.client.user.email;
