import {
  ISignedDocumentTemplate,
  ISignedDocumentTemplateData
} from "@wingspanhq/signed-documents/dist/lib/interfaces";
import { serviceSignedDocuments } from "../serviceSignedDocuments";

export const createSignedDocumentTemplate = async (
  payload: ISignedDocumentTemplateData
): Promise<ISignedDocumentTemplate> => {
  const response = await serviceSignedDocuments.post("/template", payload);
  return response.data;
};
