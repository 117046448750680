import {
  IPayeeCreateRequest,
  IPayeeResponse,
  IPayeeUpdateRequest
} from "@wingspanhq/payments/dist/interfaces";

import { servicePayments } from "../servicePayments";

export const updatePayee = async (id: string, request: IPayeeUpdateRequest) => {
  const { data } = await servicePayments.patch(`/payee/${id}`, request);

  return data as IPayeeResponse;
};
