import {
  WSControl,
  WSElementProps,
  WSFlexBox,
  WSForm,
  WSIcon,
  WSPill,
  WSText,
  WSTooltip,
  detachLayoutProps
} from "@wingspanhq/fe-component-library";
import { InvoiceSettingKeys } from "@wingspanhq/payments/dist/interfaces";
import { ReactNode, useRef } from "react";

export interface InvoicingConfigFieldProps extends WSElementProps {
  editable?: boolean;
  name?: InvoiceSettingKeys;
  enabled?: boolean;
  label: string;
  helpText?: ReactNode;
  badgeText?: string;
  valuePreviewText?: string;
}

export const InvoicingConfigField: React.FC<InvoicingConfigFieldProps> = ({
  editable,
  name,
  enabled,
  label,
  badgeText,
  helpText,
  valuePreviewText,
  children,
  ...otherProps
}) => {
  const { layoutProps } = detachLayoutProps(otherProps);
  const tooltipIconRef = useRef<HTMLElement>(null);
  return (
    <>
      <WSFlexBox
        direction="row"
        alignItems={editable ? "center" : "flex-start"}
        wrap="nowrap"
        {...layoutProps}
      >
        {editable ? (
          <WSForm.Field
            mr="S"
            name={name as string}
            component={WSControl}
            componentProps={{
              type: "switch",
              size: "S"
            }}
            label={label}
          />
        ) : (
          <>
            <WSPill
              mr="S"
              badge
              theme={enabled ? "blue" : "neutral"}
              text={badgeText ?? (enabled ? "On" : "Off")}
            />
            {editable ? (
              <WSText.ParagraphXs weight="book">{label}</WSText.ParagraphXs>
            ) : (
              <WSFlexBox direction="column" mt="XS">
                <WSText.ParagraphXs weight="book">{label}</WSText.ParagraphXs>
                {enabled && valuePreviewText ? (
                  <WSText.ParagraphXs mt="XS" color="gray400">
                    {valuePreviewText}
                  </WSText.ParagraphXs>
                ) : null}
              </WSFlexBox>
            )}
          </>
        )}
        {helpText ? (
          <WSFlexBox ml={editable ? "NONE" : "S"} mt={editable ? "NONE" : "XS"}>
            <WSTooltip dark target={tooltipIconRef}>
              {helpText}
            </WSTooltip>
            <WSIcon
              ref={tooltipIconRef}
              name="info-circle-fill"
              color="gray400"
              size="S"
            />
          </WSFlexBox>
        ) : null}
      </WSFlexBox>
      {editable ? children : null}
    </>
  );
};
