import React from "react";
import {
  toWSDate,
  useIsMobile,
  WSButton,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSPanel,
  WSPanelProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { AccountStatus, IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { useHistory } from "react-router";
import { WSAccountsList } from "../../../components/WSAccountsList/WSAccountsList.component";

export type DashboardAccountsListProps = WSPanelProps & {
  accounts: IAccount[];
};

export const DashboardAccountsList: React.FC<DashboardAccountsListProps> = ({
  accounts,
  ...props
}) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const goToEdit = () => history.push("/member/bookkeeping/imports");

  const filteredAccounts = accounts.filter(
    a =>
      a.usedFor?.bookkeeping &&
      a.events?.initialSetupAt &&
      a.status !== AccountStatus.Inactive
  );

  return (
    <WSPanel {...props}>
      <WSFlexBox.CenterY justify="space-between" mb="XL">
        <WSText.Heading4>Your Accounts</WSText.Heading4>
        {filteredAccounts.length ? (
          <WSIcon block name="edit" color="blue500" onClick={goToEdit} />
        ) : null}
      </WSFlexBox.CenterY>

      {filteredAccounts.length ? (
        <WSAccountsList
          accounts={filteredAccounts}
          getInstitutionSubtitle={institution => {
            if (institution.isDisconnected) {
              return "Disconnected";
            } else if (institution.lastSynced) {
              return `Last synced ${toWSDate(
                new Date(institution.lastSynced),
                "timeframe"
              )}`;
            } else {
              return "Syncing...";
            }
          }}
        />
      ) : null}

      <WSButton.Secondary mt="XL" onClick={goToEdit} fullWidth={isMobile}>
        {filteredAccounts.length ? "Manage" : "Sync transactions"}
      </WSButton.Secondary>
    </WSPanel>
  );
};
