import {
  WSButton,
  WSButtons,
  WSFormOld,
  WSGrid,
  WSInputDateOld,
  WSText,
  toWSDateString
} from "@wingspanhq/fe-component-library";
import {
  FrequencyAndScheduleStatus,
  ICollaboratorSchema,
  IInvoiceCollaboratorCreateRequest,
  IInvoiceTemplate,
  ScheduleStatus
} from "@wingspanhq/payments/dist/interfaces";
import { addDays } from "date-fns";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router";
import * as Yup from "yup";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { WSQueries } from "../../query/WSQuery";
import { useWSMutation } from "../../query/helpers";
import { useUserId } from "../../query/hooks/helpers";
import {
  QUERY_COLLABORATORS,
  QUERY_INVOICE_TEMPLATE,
  QUERY_INVOICE_TEMPLATES
} from "../../query/payments/keys";
import { createCollaboratorsRequest } from "../../query/payments/mutations";
import {
  useCollaboratorsQuery,
  useInvoiceTemplatesQuery,
  usePaymentsStatusQuery
} from "../../query/payments/queries";
import { getInvoiceTemplate } from "../../query/payments/selectors";
import { paymentsService } from "../../services/payments";
import { getChangedData } from "../../utils/getChangedData";
import { AddCollaboratorModal } from "../components/AddCollaboratorModal/AddCollaboratorModal";
import { DrawerLayout } from "../components/DrawerLayout/DrawerLayout";
import {
  CollaboratorsSection,
  CollaboratorsSectionValues,
  collaboratorValidationSchema
} from "../components/InvoicesForm/CollaboratorsSection";
import {
  LineItemsSection,
  LineItemsSectionValues
} from "../components/InvoicesForm/LineItemsSection";
import { getValidationSchemaLineItem } from "../components/InvoicesForm/LineItemsSection/validationSchema";
import {
  OtherSection,
  OtherSectionValues
} from "../components/InvoicesForm/OtherSection";
import { getInvoiceTemplateDueInDays } from "../utils";
import {
  attachmentsFormDataToCreateRequest,
  attachmentsFormDataToUpdateRequest,
  convertToLineItemFormData,
  convertToLineItemsCreateRequest,
  convertToLineItemsUpdateRequest,
  generateLateFeeConfig,
  lateFeeToFormData
} from "../utils/invoicesFormUtils";

export type SeriesInvoiceFormValues = LineItemsSectionValues &
  CollaboratorsSectionValues &
  OtherSectionValues & {
    dueDate: Date;
    sendDate: Date;
  };

const getDefaultValues = (
  baseInvoiceTemplate: IInvoiceTemplate,
  sendDate: Date,
  collaborators: ICollaboratorSchema[]
) => ({
  lineItems: baseInvoiceTemplate.invoiceData.lineItems.map(lineItem =>
    convertToLineItemFormData(lineItem)
  ),
  collaborators: (baseInvoiceTemplate.invoiceData.collaborators || []).map(
    invoiceCollaborator => {
      const collaborator = collaborators.find(
        c => c.collaboratorId === invoiceCollaborator.memberClientId
      );

      return {
        email: collaborator?.member.user.email || "",
        description: invoiceCollaborator.description,
        amount: invoiceCollaborator.amount,
        remove: ""
      };
    }
  ),
  other: {
    projectName: String(
      baseInvoiceTemplate.invoiceData.labels?.projectName || ""
    ),
    notes: baseInvoiceTemplate.invoiceData.invoiceNotes || "",
    attachments: (
      baseInvoiceTemplate.invoiceData.attachments?.customAttachmentIds || []
    ).map(id => ({ fileId: id }))
  },
  purchaseOrderNumber:
    baseInvoiceTemplate.invoiceData.metadata?.purchaseOrderNumber || "",
  dueDate: addDays(sendDate, getInvoiceTemplateDueInDays(baseInvoiceTemplate)),
  sendDate: sendDate
});

export const InvoicesSeriesInvoiceEdit: React.FC<RouteComponentProps<
  {
    invoiceTemplateId: string;
    scheduleDateIndex: string;
  },
  {},
  { backPath?: string }
>> = ({ match, location }) => {
  const history = useHistory();
  const invoiceTemplatesQuery = useInvoiceTemplatesQuery();
  const parentInvoiceTemplateId = match.params.invoiceTemplateId;
  const scheduleDateIndex = Number(match.params.scheduleDateIndex);
  const collaboratorsQuery = useCollaboratorsQuery();
  const collaborators = collaboratorsQuery.data || [];
  const userId = useUserId();
  const paymentsStatusQuery = usePaymentsStatusQuery();

  const onBack = () => {
    if (location.state?.backPath) {
      history.replace({
        pathname: location.state.backPath,
        search: location.search
      });
    } else {
      history.goBack();
    }
  };

  const parentInvoiceTemplate = getInvoiceTemplate(
    invoiceTemplatesQuery.data || [],
    parentInvoiceTemplateId
  ) as IInvoiceTemplate;

  const initialScheduleDates = parentInvoiceTemplate.scheduleDates || [];
  const scheduleDate = initialScheduleDates[scheduleDateIndex];

  const [submit, submitMeta] = useWSMutation(
    async (values: SeriesInvoiceFormValues) => {
      const collaboratorsRequest = await createCollaboratorsRequest(
        values.collaborators,
        collaborators,
        userId,
        paymentsStatusQuery.data?.defaultAccountCurrency
      );

      if (scheduleDate.invoiceTemplateId) {
        const invoiceTemplate = getInvoiceTemplate(
          invoiceTemplatesQuery.data || [],
          scheduleDate.invoiceTemplateId
        );

        await paymentsService.invoiceTemplate.update(
          scheduleDate.invoiceTemplateId,
          getChangedData(invoiceTemplate, {
            scheduleDates: [
              {
                date: values.sendDate
              }
            ],
            invoiceData: {
              collaborators: collaboratorsRequest,
              lineItems: convertToLineItemsUpdateRequest(values.lineItems),
              invoiceNotes: values.other.notes,
              dueDate: values.dueDate,
              attachments: {
                customAttachmentIds: attachmentsFormDataToUpdateRequest(
                  values.other.attachments,
                  invoiceTemplate?.invoiceData?.attachments
                    ?.customAttachmentIds || []
                )
              },
              labels: values.other.projectName
                ? {
                    projectName: values.other.projectName
                  }
                : undefined
            }
          })
        );

        await paymentsService.invoiceTemplate.update(parentInvoiceTemplateId, {
          scheduleDates: initialScheduleDates.map((_, index) =>
            index === scheduleDateIndex
              ? {
                  date: values.sendDate
                }
              : {}
          )
        });

        onBack();
      } else {
        // Create new invoice template
        const createdInvoiceTemplate = await paymentsService.invoiceTemplate.create(
          {
            status: FrequencyAndScheduleStatus.Draft,
            scheduleDates: [
              {
                status: ScheduleStatus.Pending,
                date: values.sendDate
              }
            ],
            invoiceData: {
              ...(parentInvoiceTemplate.invoiceData as any),
              lateFeeHandling: parentInvoiceTemplate.invoiceData.lateFeeHandling
                ? generateLateFeeConfig({
                    formValues: lateFeeToFormData(
                      parentInvoiceTemplate.invoiceData.lateFeeHandling
                    ),
                    dueDate: values.dueDate
                  })
                : undefined,
              collaborators: collaboratorsRequest.filter(
                collaborator => !!collaborator
              ) as IInvoiceCollaboratorCreateRequest[],
              memberClientId: parentInvoiceTemplate.invoiceData
                .memberClientId as string,
              lineItems: convertToLineItemsCreateRequest(values.lineItems),
              invoiceNotes: values.other.notes,
              dueDate: values.dueDate,
              attachments: {
                customAttachmentIds: attachmentsFormDataToCreateRequest(
                  values.other.attachments
                )
              },
              labels: values.other.projectName
                ? {
                    projectName: values.other.projectName
                  }
                : undefined
            },
            isSchedulingOnly: true,
            labels: {
              parentInvoiceTemplateId
            }
          }
        );

        // Update new invoice template to be Active
        await paymentsService.invoiceTemplate.update(
          createdInvoiceTemplate.invoiceTemplateId,
          {
            status: FrequencyAndScheduleStatus.Active
          }
        );

        // Update schedule date in parent invoice template to use newly created invoice template
        await paymentsService.invoiceTemplate.update(parentInvoiceTemplateId, {
          scheduleDates: initialScheduleDates.map((_, index) =>
            index === scheduleDateIndex
              ? {
                  status: ScheduleStatus.Modified,
                  invoiceTemplateId: createdInvoiceTemplate.invoiceTemplateId,
                  date: values.sendDate
                }
              : {}
          )
        });

        onBack();
      }
    },
    {
      dependencies: [
        QUERY_INVOICE_TEMPLATES,
        [QUERY_INVOICE_TEMPLATE, parentInvoiceTemplateId],
        QUERY_COLLABORATORS
      ]
    }
  );

  return (
    <DrawerLayout
      title={`Edit invoice – ${toWSDateString(scheduleDate.date, "monthDate")}`}
      onBack={onBack}
    >
      <AddCollaboratorModal />
      <WSQueries queries={{ invoiceTemplatesQuery, collaboratorsQuery }}>
        {({
          invoiceTemplatesQuery: { data: invoiceTemplates },
          collaboratorsQuery: { data: collaborators }
        }) => {
          const parentInvoiceTemplate = getInvoiceTemplate(
            invoiceTemplates,
            parentInvoiceTemplateId
          ) as IInvoiceTemplate;

          const invoiceTemplateId =
            parentInvoiceTemplate.scheduleDates?.[scheduleDateIndex]
              .invoiceTemplateId;

          const invoiceTemplate = invoiceTemplateId
            ? (getInvoiceTemplate(
                invoiceTemplates,
                invoiceTemplateId
              ) as IInvoiceTemplate)
            : undefined;

          return (
            <WSFormOld<SeriesInvoiceFormValues>
              defaultValues={getDefaultValues(
                invoiceTemplate ? invoiceTemplate : parentInvoiceTemplate,
                parentInvoiceTemplate.scheduleDates?.[scheduleDateIndex]
                  .date as Date,
                collaborators
              )}
              validationSchema={Yup.object().shape({
                lineItems: Yup.array(getValidationSchemaLineItem())
                  .min(1, "At least one work item is required")
                  .required("At least one work item is required"),

                collaborators: Yup.array(collaboratorValidationSchema),
                sendDate: Yup.date().isTodayAndInFuture(),
                dueDate: Yup.date().isAfter("sendDate")
              })}
              onSubmit={values => {
                submit(values);
              }}
            >
              <WSText.Heading5 mb="M">Send</WSText.Heading5>
              <WSGrid gutter="M">
                <WSGrid.Item span={{ s: "6" }}>
                  <WSFormOld.Field
                    name="sendDate"
                    component={WSInputDateOld}
                    mb="3XL"
                  />
                </WSGrid.Item>
              </WSGrid>
              <WSText.Heading5 mb="M">Due</WSText.Heading5>
              <WSGrid gutter="M">
                <WSGrid.Item span={{ s: "6" }}>
                  <WSFormOld.Field
                    name="dueDate"
                    component={WSInputDateOld}
                    mb="3XL"
                  />
                </WSGrid.Item>
              </WSGrid>
              <WSText.Heading5 mb="M">Work summary</WSText.Heading5>
              <LineItemsSection mb="3XL" />
              <CollaboratorsSection mb="3XL" />
              <OtherSection mb="3XL" fullWidth />

              <WSErrorMessage
                mb="XL"
                contextKey="UpdateSeriesInvoice"
                error={submitMeta.error}
              />

              <WSButtons format="modal">
                <WSButton type="submit" loading={submitMeta.isLoading}>
                  Update invoice
                </WSButton>
              </WSButtons>
            </WSFormOld>
          );
        }}
      </WSQueries>
    </DrawerLayout>
  );
};
