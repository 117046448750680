import { IMemberClient } from "@wingspanhq/payments/dist/interfaces";
import { RequirementStatus } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";

export const selectorHasPendingEligibilityRequirements = (
  memberClients: IMemberClient[]
) =>
  memberClients.some(memberClient =>
    memberClient.eligibilityRequirements?.some(
      eligibilityRequirement =>
        eligibilityRequirement.status !== RequirementStatus.Complete
    )
  );
