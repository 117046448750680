import { WSElement, WSText } from "@wingspanhq/fe-component-library";
import { WSNavHistoryListItem } from "@wingspanhq/fe-component-library/dist/lib/components/common/WSNavHistoryList";
import React, { ReactNode } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useBrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import { Main1099LayoutModule } from "../Main1099Layout/Main1099Layout.module";

interface PayerSetupLayoutProps {
  title: string;
  noTitle?: boolean;
  description?: string;
  children: ReactNode;
  onBack?: () => void;
}

const sections: WSNavHistoryListItem[] = [
  {
    key: "IdentityVerification",
    title: "Identity verification"
  },
  {
    key: "TaxInformation",
    title: "Tax information"
  },
  {
    key: "Summary",
    title: "Summary"
  }
];

const getActiveSection = (pathname: string) => {
  if (
    pathname.startsWith("/member/1099-filing/tax-information/verify-identity")
  ) {
    return "IdentityVerification";
  }

  switch (pathname) {
    case "/member/1099-filing/tax-information/verify-identity":
      return "IdentityVerification";
    case "/member/1099-filing/tax-information/edit-taxpayer-information":
    case "/member/1099-filing/tax-information/personal-info":
      return "TaxInformation";
    case "/member/1099-filing/tax-information/confirm-preferences":
      return "Summary";
    default: {
      return "TaxInformation";
    }
  }
};

export const LayoutW9: React.FC<PayerSetupLayoutProps> = ({
  onBack,
  title,
  noTitle,
  description,
  children
}) => {
  useBrowserPageTitle(title);
  const history = useHistory();
  const location = useLocation();

  return (
    <Main1099LayoutModule
      title={title}
      sections={sections}
      activeSection={getActiveSection(location.pathname)}
      onSaveAndExit={onBack}
      headerRight={
        <WSText.ParagraphSm
          onClick={() => history.replace("/logout")}
          color="gray500"
        >
          Log out
        </WSText.ParagraphSm>
      }
    >
      <WSElement mb="XL">
        {noTitle ? null : <WSText.Heading4>{title}</WSText.Heading4>}
        {description && (
          <WSText mt="XS" color="gray500">
            {description}
          </WSText>
        )}
      </WSElement>
      {children}
    </Main1099LayoutModule>
  );
};
