import {
  toCamelCase,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSPanel,
  WSRadioInput,
  WSText
} from "@wingspanhq/fe-component-library";
import { ReactNode } from "react";

export interface FormInputOnboardingOptionProps<V extends string = string>
  extends Omit<WSElementProps, "title" | "onChange"> {
  name: string;
  value: V;
  title?: ReactNode;
  checked: boolean;
  onChange: (newValue: V) => void;
}

export const FormInputOnboardingOption = <V extends string = string>({
  name,
  value,
  children,
  title,
  checked,
  onChange,
  ...elementProps
}: FormInputOnboardingOptionProps<V>) => (
  <WSPanel
    as="label"
    data-testid={toCamelCase(name, value, "option")}
    {...{ ...elementProps, for: `${name}_${value}` }}
    style={{ cursor: "pointer" }}
  >
    <WSFlexBox.CenterY wrap="nowrap">
      <WSRadioInput
        id={`${name}_${value}`}
        value={value}
        name={name}
        mr="M"
        checked={checked}
        onChange={() => onChange(value)}
      />
      <WSElement>
        {title && (
          <WSText weight="medium" mb="XS">
            {title}
          </WSText>
        )}
        {children}
      </WSElement>
    </WSFlexBox.CenterY>
  </WSPanel>
);
