import React from "react";
import {
  useWSSnackbar,
  WSActions,
  WSDivider,
  WSElement,
  WSElementProps,
  WSForm,
  WSInputText,
  WSSelect,
  WSSelectOption,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayerCreateRequest,
  IPayerResponse
} from "@wingspanhq/payments/dist/interfaces";
import { queryCache } from "react-query";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { AdditionalEmailsField } from "../../../../Invoices/components/AdditionalEmailsField/AdditionalEmailsField";
import { QUERY_PAYER_LIST } from "../../../../query/payers/keys";
import { useCreatePayer } from "../../../../query/payers/mutations/useCreatePayer";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksCustomers,
  useIntegrationsQuickbooksItems
} from "../../../../query/integrations/queries";
import { isQuickbooksServiceActive } from "../../../../query/integrations/selectors";
import {
  QUICKBOOKS_ENTITY,
  useQboRefreshModal
} from "../../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import { validatorEmail } from "../../../../shared/validators/validatorEmail";
import {
  contactCompanyValidator,
  contactNameValidator
} from "../../../../utils/validators";
import { selectQuickbooksUserOptions } from "../../../Integrations/selectors/selectQuickbooksUserOptions";

export interface AddClientModalProps extends WSElementProps {
  onClose: (client?: IPayerResponse) => void;
  onSuccess?: (client?: IPayerResponse) => Promise<void>;
}

export const AddClientModal: React.FC<AddClientModalProps> = ({
  onClose,
  onSuccess,
  ...elementProps
}) => {
  const { openSnackbar } = useWSSnackbar();
  const qboRefreshModal = useQboRefreshModal();
  const qIntegrationsQuickbooks = useIntegrationsQuickbooks({
    refetchOnMount: true
  });
  const qIntegrationsQuickbooksCustomers = useIntegrationsQuickbooksCustomers();
  const qIntegrationsQuickbooksItems = useIntegrationsQuickbooksItems();

  const { data: integrationState } = qIntegrationsQuickbooks;
  const { data: qboCustomers } = qIntegrationsQuickbooksCustomers;
  const { data: qboItems } = qIntegrationsQuickbooksItems;

  const qFeatureFlags = useFeatureFlags();

  const [createClient, createClientMeta] = useCreatePayer();

  const isQuickbooksActive =
    integrationState &&
    (qFeatureFlags.data?.forceShowQBO ||
      isQuickbooksServiceActive(integrationState));

  const quickbooksCustomerOptions: WSSelectOption[] = selectQuickbooksUserOptions(
    qboCustomers
  );

  const quickbooksItemsOptions: WSSelectOption[] = (qboItems || [])
    .map(qboItem => {
      return {
        label: qboItem.fullyQualifiedName,
        value: qboItem.itemId,
        searchText: [qboItem.fullyQualifiedName, qboItem.name]
          .filter(Boolean)
          .join(" ")
      };
    })
    .sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label));

  const defaultItem = (qIntegrationsQuickbooksItems.data || []).find(
    item => item.itemId === integrationState?.defaults?.itemId
  );

  return (
    <WSElement {...elementProps}>
      <WSDivider mb="XL" />
      <WSForm<{
        company: string;
        contact: string;
        email: string;
        qboCustomerId: string;
        qboItemId: string;
        additionalEmails?: { email: string }[];
      }>
        defaultValues={{
          company: "",
          contact: "",
          email: "",
          qboCustomerId: "",
          qboItemId: "",
          additionalEmails: []
        }}
        onSubmit={async values => {
          const request: IPayerCreateRequest = {
            email: values.email
          };

          const payeeOwnedData: IPayerCreateRequest["payeeOwnedData"] = {
            payerName: values.contact,
            payerCompanyName: values.company,
            payerEmailCC: values.additionalEmails?.map(e => e.email)
          };

          if (
            isQuickbooksActive &&
            (values.qboCustomerId !== "" || values.qboItemId !== "")
          ) {
            payeeOwnedData.integration = {
              quickbooks: {
                ...(values.qboCustomerId
                  ? { customerId: values.qboCustomerId }
                  : {}),
                ...(values.qboItemId ? { itemId: values.qboItemId } : {})
              }
            };
          }

          request.payeeOwnedData = payeeOwnedData;

          const clientResponse = await createClient(request);

          if (clientResponse) {
            // Pre-populate the query cache with the new client
            const previous: undefined | IPayerResponse[] =
              queryCache.getQueryData(QUERY_PAYER_LIST) || [];
            const newData = [clientResponse, ...previous];
            queryCache.setQueryData(QUERY_PAYER_LIST, newData);

            await onSuccess?.(clientResponse);
            openSnackbar({
              duration: 5000,
              type: "success",
              message: "Client created successfully"
            });
            onClose(clientResponse);
          }
        }}
        validationSchema={Yup.object()
          .shape(
            {
              company: Yup.string().when("contact", {
                is: (contact: string) => contact?.length > 0,
                then: contactCompanyValidator,
                otherwise: contactCompanyValidator.required(
                  "At least one of these fields muse be provided"
                )
              }),
              contact: Yup.string().when("company", {
                is: (company: string) => company?.length > 0,
                then: contactNameValidator,
                otherwise: contactNameValidator.required(
                  "At least one of these fields muse be provided"
                )
              })
            },
            [["company", "contact"]]
          )
          .shape({
            email: validatorEmail.required("Required"),
            additionalEmails: Yup.array(
              Yup.object().shape({
                email: validatorEmail.required()
              })
            )
              .notEqualToField(
                "email",
                "Additional email can't be the same as the client email",
                "email"
              )
              .uniqueByProperty("Additional email must be unique", "email")
          })}
      >
        <WSText.ParagraphSm color="gray500">
          Client company or name *
        </WSText.ParagraphSm>
        <WSText.ParagraphXs color="gray400">
          At least one of these fields must be provided
        </WSText.ParagraphXs>

        <WSForm.Field
          mt="XL"
          name="company"
          label="Client company"
          component={WSInputText}
          mb="XL"
        />
        <WSForm.Field
          name="contact"
          label="Client name"
          component={WSInputText}
        />

        <WSDivider my="XL" />

        <WSForm.Field
          name="email"
          label="Client email *"
          component={WSInputText}
          mb="XL"
        />
        <AdditionalEmailsField
          name="additionalEmails"
          inputLabel="Contact email CC"
          buttonLabel="Add email recipient"
          buttonDescription="Additional email addresses will be cc’d"
          mb="2XL"
        />
        {isQuickbooksActive && quickbooksItemsOptions.length > 0 && (
          <>
            <WSDivider mb="XL" label="Quickbooks Mapping" />
            <WSText.ParagraphSm mb="XL" color="gray500">
              Because you have an active integration with QBO, please map this
              new Client to an existing Customer, or leave blank and we will
              create a new one.
            </WSText.ParagraphSm>
            <WSForm.Field
              mb="XL"
              name="qboCustomerId"
              component={WSSelect}
              componentProps={{
                placeholder: "Not mapped, create new Customer",
                options: quickbooksCustomerOptions,
                menuFooterAction: {
                  label: "Resync QBO Customers",
                  icon: "refresh-v",
                  onClick: () => {
                    qboRefreshModal.open({
                      entity: QUICKBOOKS_ENTITY.CUSTOMERS
                    });
                  }
                }
              }}
            />
            <WSForm.Field
              mb="2XL"
              name="qboItemId"
              component={WSSelect}
              componentProps={{
                options: quickbooksItemsOptions,
                placeholder: `Use default "${defaultItem?.fullyQualifiedName}"`,
                menuFooterAction: {
                  label: "Resync QBO Items",
                  icon: "refresh-v",
                  onClick: () => {
                    qboRefreshModal.open({
                      entity: QUICKBOOKS_ENTITY.ITEMS
                    });
                  }
                }
              }}
              label="Default QBO Item"
            />
          </>
        )}
        <WSErrorMessage
          error={createClientMeta.error}
          contextKey="CreateClient"
        />

        <WSDivider mt="2XL" mb="XL" />

        <WSActions
          buttons={[
            {
              label: "Add client",
              kind: "Primary",
              name: "addClient",
              type: "submit",
              loading: createClientMeta.isLoading
            },
            {
              label: "Cancel",
              kind: "Secondary",
              type: "button",
              onClick: () => onClose()
            }
          ]}
          alignment="fill"
        />
      </WSForm>
    </WSElement>
  );
};
