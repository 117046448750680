import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation, WSMutationConfig } from "../../helpers";
import {
  QUERY_PAYMENTS_TAXFORM_LIST_PAYEE,
  QUERY_PAYMENTS_TAXFORM_PAYEE
} from "../keys";
import { paymentsService } from "../../../services/payments";
import { VerificationStatus } from "@wingspanhq/payments/dist/interfaces";

export type TaxFormVerifyTinStatus = {
  status: VerificationStatus;
};

export const useTaxFormSubmitW9 = (
  config?: WSMutationConfig<TaxFormVerifyTinStatus, WSServiceError, never>
) =>
  useWSMutation(() => paymentsService.taxForm.submitW9(), {
    dependencies: [
      QUERY_PAYMENTS_TAXFORM_PAYEE,
      QUERY_PAYMENTS_TAXFORM_LIST_PAYEE
    ],
    ...config
  });
