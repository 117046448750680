import {
  toWSDateString,
  toWSMoneyString,
  useIsMobile,
  useModalContext,
  WSButton,
  WSCheckboxGroup,
  WSFiltersOld,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSInfiniteScroll,
  WSPill,
  WSSearch,
  WSSearchProps,
  WSSelectOld,
  WSTable,
  WSTableCell,
  WSTableItem,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  BulkStatus,
  ICollaboratorSchema,
  MemberClientStatus
} from "@wingspanhq/payments/dist/interfaces";
import { IDeductionResponse } from "@wingspanhq/payments/dist/interfaces/api/deductions";
import { DeductionStatus } from "@wingspanhq/payments/dist/interfaces/deductions";
import flatten from "lodash/flatten";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import reduce from "lodash/reduce";
import sortBy from "lodash/sortBy";
import startCase from "lodash/startCase";
import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import {
  useAllCollaboratorDeductions,
  useCollaboratorsQuery
} from "../../../query/payments/queries";
import { getCollaboratorCompany } from "../../../query/payments/selectors";
import { WSQueries } from "../../../query/WSQuery";
import { getCollaboratorName } from "../../../utils/collaborator";
import { useUrlQueryFilters } from "../../../utils/router";
import { getAllEntries } from "../../../utils/serviceHelper";
import {
  mapAndRevertParamsSortToTable,
  mapAndRevertTableSortToParams,
  SortDirection
} from "../../../utils/tableSortingHelpers";
import { CompletedDeductionDetails } from "../deductions/CompletedDuductionDetails";
import { DEDUCTION_INFO_MODAL } from "../deductions/modals/DeductionsInfo";
import { ScheduledDeductionDetails } from "../deductions/ScheduledDuductionDetails";
import styles from "./AllDeductions.module.scss";
import {
  ADD_PAYABLE_DEDUCTION_MODAL,
  AddPayableDeduction
} from "./modals/AddDeduction";

import { useBulkPayablesBatchesQuery } from "../../../modules/BulkImporter/query/bulkPayable/queries";
import { getUploadedFilename } from "../../../modules/BulkImporter/utils/getUploadedFilename";
import { paymentsService } from "../../../services/payments";
import { ButtonDownloadCsv } from "../../../shared/components/ButtonDownloadCsv";
import { mapDeductionsTableToCSV } from "./csvUtils";
import { useAddPayeeDeduction } from "./modals/AddPayeeDeduction";
import { BulkResource } from "../../../modules/BulkImporter/types";

type AllDeductionsDefaultFilters = {
  bulkBatchId?: string;
  status?: [DeductionStatus];
  collaboratorEmail?: string;
  sort?: {
    name?: SortDirection;
    status?: SortDirection;
    amount?: SortDirection;
  };
};

const allDeductionsDefaultFilters = {
  bulkBatchId: undefined,
  status: undefined,
  collaboratorEmail: undefined,
  sort: undefined
};

export const AllDeductions: React.FC = () => {
  useBrowserPageTitle("All Deductions");
  const { openModal } = useModalContext();
  const isMobile = useIsMobile();
  const history = useHistory();
  const addPayeeDeductionModal = useAddPayeeDeduction();

  const { filters, setFilters, defaultFilters } = useUrlQueryFilters<
    AllDeductionsDefaultFilters
  >(allDeductionsDefaultFilters);

  const qCollaborators = useCollaboratorsQuery();
  const qDeductions = useAllCollaboratorDeductions(
    {
      memberId: qCollaborators.data?.find(
        c => c.member.user.email === filters.collaboratorEmail
      )?.memberId,
      status: filters.status ? { in: filters.status } : undefined,
      "labels.bulkBatchId": filters.bulkBatchId,
      sort: filters.sort
    },
    {
      enabled: !!qCollaborators.data
    }
  );
  const qBulkPayableBatches = useBulkPayablesBatchesQuery();

  const onNameSort = () => {
    setFilters({
      sort: { name: mapAndRevertTableSortToParams(filters.sort?.name) }
    });
  };

  const onStatusSort = () => {
    setFilters({
      sort: {
        status: mapAndRevertTableSortToParams(filters.sort?.status)
      }
    });
  };

  const onAmountSort = () => {
    setFilters({
      sort: {
        amount: mapAndRevertTableSortToParams(filters.sort?.amount)
      }
    });
  };

  return (
    <>
      <Switch>
        <Route
          path={`/member/invoices/payables/deductions/scheduled/:deductionId`}
          component={ScheduledDeductionDetails}
          exact
        />
        <Route
          path={`/member/invoices/payables/deductions/completed/:deductionId`}
          component={CompletedDeductionDetails}
          exact
        />
      </Switch>
      <AddPayableDeduction />
      <WSFlexBox
        justify={"space-between"}
        direction={isMobile ? "column" : "row"}
      >
        <WSText.Heading1 mb="XL">
          All Deductions
          <WSIcon
            ml="M"
            size="XS"
            name="info-circle"
            color="gray400"
            onClick={() => openModal(DEDUCTION_INFO_MODAL)}
          />
        </WSText.Heading1>

        <WSButton.Primary
          mb="XL"
          fullWidth={isMobile}
          onClick={() => addPayeeDeductionModal.open()}
        >
          Add deduction
        </WSButton.Primary>
      </WSFlexBox>

      <WSQueries queries={{ qDeductions, qCollaborators, qBulkPayableBatches }}>
        {({
          qDeductions,
          qCollaborators,
          qBulkPayableBatches: { data: bulkPayableBatches }
        }) => {
          const deductions = flatten(qDeductions.data || []);

          const filteredCollaborators = qCollaborators.data.filter(
            c => c.status !== MemberClientStatus.Inactive
          );

          const recentCollaborators = sortBy(filteredCollaborators, "updatedAt")
            .reverse()
            .slice(0, 25);

          const tableData: WSTableItem<IDeductionResponse>[] = deductions.map(
            deduction => {
              const { deductionId } = deduction;

              return {
                id: deductionId,
                data: deduction
              };
            }
          );

          const filteredBulkBatches = bulkPayableBatches.filter(bulkBatch =>
            [BulkStatus.Complete, BulkStatus.Failed].includes(bulkBatch.status)
          );

          return (
            <>
              <WSFlexBox alignItems="center" justify="space-between" mb="XL">
                <WSFiltersOld
                  filtersGridItemProps={{
                    span: { s: "6" }
                  }}
                  filters={filters}
                  defaultFilters={defaultFilters}
                  getCount={(defaultValues, currentValues) => {
                    const _defaultValues = omit(defaultValues, ["sort"]);
                    const _currentValues = omit(currentValues, ["sort"]);

                    return reduce<any, any>(
                      _currentValues,
                      (result, value, key) =>
                        isEqual(value, _defaultValues[key])
                          ? result
                          : result.concat(key),
                      []
                    ).length;
                  }}
                  onFilter={newFilters => {
                    setFilters(newFilters);
                  }}
                >
                  <WSFormOld.Field
                    name="status"
                    label="Status"
                    mb="2XL"
                    component={WSCheckboxGroup}
                    componentProps={{
                      options: [
                        {
                          value: DeductionStatus.Complete,
                          label: "Completed"
                        },
                        {
                          value: DeductionStatus.PartiallyApplied,
                          label: "Partially completed"
                        },
                        {
                          value: DeductionStatus.Pending,
                          label: "Scheduled"
                        }
                      ]
                    }}
                  />

                  <WSFormOld.Field<WSSearchProps<ICollaboratorSchema>>
                    mb="XL"
                    name="collaboratorEmail"
                    label="Contractor"
                    component={WSSearch}
                    componentProps={{
                      placeholder: "Search by email, name, id..",
                      threshold: 0.8,
                      itemToString: item =>
                        [
                          item?.data.member.user.profile?.firstName,
                          item?.data.member.user.profile?.lastName
                        ]
                          .filter(Boolean)
                          .join(" ")
                          .trim() ||
                        item?.data.member.user.email ||
                        "",
                      defaultItems: recentCollaborators.map(collaborator => ({
                        value: collaborator.member.user.email || "",
                        searchText: collaborator.collaboratorId,
                        data: collaborator
                      })),
                      emptyResults: () => (
                        <WSFlexBox.Center p="L" direction="column">
                          <WSText.ParagraphSm color="gray400">
                            No Results
                          </WSText.ParagraphSm>
                        </WSFlexBox.Center>
                      ),
                      items: filteredCollaborators.map(collaborator => ({
                        value: collaborator.member.user.email || "",
                        searchText: [
                          collaborator.member.user.profile?.firstName,
                          collaborator.member.user.profile?.lastName,
                          collaborator.member.user.email,
                          collaborator.member.profile?.company?.name,
                          collaborator.collaboratorId,
                          collaborator.labels.contractorId
                        ]
                          .filter(Boolean)
                          .join(" "),
                        data: collaborator
                      })),
                      getTitle: collaborator =>
                        [
                          collaborator.member.user.profile?.firstName,
                          collaborator.member.user.profile?.lastName
                        ]
                          .filter(Boolean)
                          .join(" "),
                      getDetails: collaborator =>
                        `${collaborator.member.user.email}`,
                      getSideDetails: collaborator =>
                        collaborator.member.profile?.company?.name ? (
                          <WSPill
                            theme="blue"
                            text={collaborator.member.profile?.company?.name}
                          />
                        ) : null
                    }}
                  />

                  <WSFormOld.Field
                    name="bulkBatchId"
                    label="Bulk Batch"
                    mb="2XL"
                    component={WSSelectOld}
                    componentProps={{
                      searchable: true,
                      placeholder: "All bulk imports",
                      options: filteredBulkBatches.map(bulkBatch => ({
                        label:
                          getUploadedFilename(bulkBatch, BulkResource.Payable) +
                          ` (created ${toWSDateString(bulkBatch.createdAt)})`,
                        value: bulkBatch.bulkPayableBatchId,
                        searchText: [bulkBatch.labels.filename]
                          .filter(Boolean)
                          .join(" ")
                      }))
                    }}
                  />
                </WSFiltersOld>

                {tableData.length > 0 && (
                  <ButtonDownloadCsv
                    mt="M"
                    className={styles.csvLink}
                    getData={async () => {
                      const filter = {
                        memberId: qCollaborators.data?.find(
                          c => c.member.user.email === filters.collaboratorEmail
                        )?.memberId,
                        status: filters.status
                          ? { in: filters.status }
                          : undefined,
                        sort: filters.sort
                      };

                      const deductions = await getAllEntries(
                        paymentsService.collaboratorDeductions.list,
                        filter
                      );

                      return mapDeductionsTableToCSV(
                        deductions.map(deduction => {
                          const collaborator = qCollaborators.data.find(
                            collaborator => {
                              return (
                                collaborator.memberId === deduction.memberId
                              );
                            }
                          );

                          return {
                            deduction,
                            collaborator
                          };
                        })
                      );
                    }}
                    fileName="Deductions"
                  />
                )}
              </WSFlexBox>

              <WSInfiniteScroll
                onLoad={() => {
                  qDeductions.fetchMore();
                }}
                loadMore={tableData.length > 0}
                endOfList={!qDeductions.canFetchMore}
                loading={!!qDeductions.isFetchingMore}
              >
                <WSTable<IDeductionResponse>
                  mt="2XL"
                  tableData={tableData}
                  bottomMessage={
                    !tableData.length && (
                      <WSFlexBox.Center mt="2XL" direction="column">
                        <WSText weight="medium">No deductions found.</WSText>
                        <WSText.ParagraphSm mt="M" color="gray500">
                          To subtract an amount from a contractor's pay,
                          schedule a
                          <WSButton.Link
                            ml="XS"
                            onClick={() =>
                              openModal(ADD_PAYABLE_DEDUCTION_MODAL)
                            }
                          >
                            new deduction.
                          </WSButton.Link>
                        </WSText.ParagraphSm>
                      </WSFlexBox.Center>
                    )
                  }
                  onRowClick={item => {
                    if (item.data.status === DeductionStatus.Complete) {
                      history.push({
                        pathname: `/member/invoices/payables/deductions/completed/${item.data.deductionId}`,
                        search: history.location.search
                      });
                    } else {
                      history.push({
                        pathname: `/member/invoices/payables/deductions/scheduled/${item.data.deductionId}`,
                        search: history.location.search
                      });
                    }
                  }}
                  columns={[
                    {
                      config: {
                        gridTemplateSizeMax: "1fr",
                        hideOnScreens: ["XS", "S"],
                        header: {
                          text: "Contractor"
                        }
                      },
                      renderFunction: ({ data }) => {
                        const collaborator = qCollaborators.data.find(
                          collaborator => {
                            return collaborator.memberId === data.memberId;
                          }
                        );

                        return collaborator ? (
                          <WSTableCell
                            text={getCollaboratorName(collaborator)}
                            secondaryText={getCollaboratorCompany(collaborator)}
                          />
                        ) : (
                          ""
                        );
                      }
                    },
                    {
                      config: {
                        gridTemplateSizeMax: "1fr",
                        sortDirection: mapAndRevertParamsSortToTable(
                          filters.sort?.name
                        ),
                        onColumnSort: onNameSort,
                        header: {
                          text: "Name"
                        }
                      },
                      renderFunction: ({ data }) => {
                        return <WSTableCell text={data.name} />;
                      }
                    },
                    {
                      config: {
                        gridTemplateSizeMax: "1fr",
                        header: {
                          text: "Status"
                        }
                      },
                      renderFunction: ({ data }) => {
                        const isComplete =
                          data.status === DeductionStatus.Complete;
                        const isPartiallyApplied =
                          data.status === DeductionStatus.PartiallyApplied;

                        return (
                          <WSTableCell
                            pill={{
                              icon: isComplete
                                ? "check-double"
                                : isPartiallyApplied
                                ? "check"
                                : "time",
                              theme: isComplete
                                ? "success"
                                : isPartiallyApplied
                                ? "blue"
                                : "neutral",
                              text: startCase(data.status)
                            }}
                          />
                        );
                      }
                    },
                    {
                      config: {
                        hideOnScreens: ["XS", "S"],
                        gridTemplateSizeMax: "1fr",
                        header: {
                          text: "Date created"
                        }
                      },
                      renderFunction: ({ data }) => (
                        <WSTableCell
                          text={toWSDateString(data.createdAt, "mobileInput")}
                        />
                      )
                    },
                    {
                      config: {
                        hideOnScreens: ["XS", "S"],
                        gridTemplateSizeMax: "1fr",
                        header: {
                          text: "Remaining balance"
                        }
                      },
                      renderFunction: ({ data }) => {
                        const deductedAmount = data.application
                          .map(a => a.amountDeducted)
                          .reduce((a, b) => a + b, 0);

                        const remainingAmount = deductedAmount
                          ? data.amount - deductedAmount
                          : data.amount;

                        return (
                          <WSTableCell
                            text={toWSMoneyString(remainingAmount)}
                          />
                        );
                      }
                    },
                    {
                      config: {
                        gridTemplateSizeMax: "1fr",
                        justify: "end",
                        sortDirection: mapAndRevertParamsSortToTable(
                          filters.sort?.amount
                        ),
                        onColumnSort: onAmountSort,
                        header: {
                          text: "Total"
                        }
                      },
                      renderFunction: ({ data }) => {
                        return (
                          <WSTableCell text={toWSMoneyString(data.amount)} />
                        );
                      }
                    }
                  ]}
                />
              </WSInfiniteScroll>
            </>
          );
        }}
      </WSQueries>
    </>
  );
};
