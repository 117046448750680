import React from "react";
import cn from "classnames";

import { WSElementProps, WSElement } from "../../WSElement/WSElement.component";

import styles from "./WSNav.module.scss";

export interface WSNavProps extends WSElementProps {}

export interface NavFooterProps extends WSElementProps {}

export const WSNav: React.FC<WSNavProps> & {
  Footer: React.FC<NavFooterProps>;
} = ({ className, children, ...props }) => {
  const classNames = cn(styles.nav, className);
  return (
    <WSElement className={classNames} {...props}>
      {children}
    </WSElement>
  );
};

WSNav.Footer = ({ children, className, ...otherProps }) => (
  <WSElement className={cn(styles.footer, className)} {...otherProps}>
    {children}
  </WSElement>
);
