import {
  WSElement,
  WSElementProps,
  WSGrid,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { ReactNode } from "react";
import styles from "./InfoRow.module.scss";

type Props = {
  label: string;
  value: ReactNode;
  longName?: boolean;
} & WSElementProps;

export const InfoRow: React.FC<Props> = ({
  label,
  value,
  longName,
  ...elementProps
}) => (
  <WSElement {...elementProps}>
    <WSGrid>
      <WSGrid.Item span={{ xs: longName ? "4" : "2" }}>
        <WSText.ParagraphSm color="gray500">{label}</WSText.ParagraphSm>
      </WSGrid.Item>
      <WSGrid.Item span={{ xs: longName ? "8" : "10" }}>
        <WSText className={styles.text}>{value}</WSText>
      </WSGrid.Item>
    </WSGrid>
  </WSElement>
);
