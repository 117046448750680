import {
  IPayeeTaxFormResponse,
  TaxFormCorrectionStatus
} from "@wingspanhq/payments/dist/interfaces";

export const selectorPayeeTaxFormPayeeCorrectionIsRejected = (
  taxForm: IPayeeTaxFormResponse
) => {
  if (taxForm.corrections?.length) {
    const latestCorrection =
      taxForm.corrections[taxForm.corrections.length - 1];

    // is rejected and created by member
    return latestCorrection.status === TaxFormCorrectionStatus.Rejected;
  }

  return false;
};
