import { TeamTab } from "./RouteTeams/TeamTab";
import { Route, Switch } from "react-router-dom";
import * as React from "react";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { WSQueries } from "../../../query/WSQuery";
import { TeamIndex } from "../../TeamV2/routes";

export const TeamsIndex = () => {
  const queryFeatureFlags = useFeatureFlags();

  return (
    <WSQueries queries={{ queryFeatureFlags }}>
      {({ queryFeatureFlagsData }) => {
        return (
          <Switch>
            <Route
              path="/member/settings/team"
              component={
                queryFeatureFlagsData.organizationAccounts ? TeamIndex : TeamTab
              }
            />
          </Switch>
        );
      }}
    </WSQueries>
  );
};
