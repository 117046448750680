import React from "react";
import { WSPage } from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router";
import { OpenReceivableAgingReportDashboard } from "./OpenReceivableAgingReportDashboard";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { WSQueries } from "../../../../query/WSQuery";

export const RouteOpenReceivableAgingReport: React.FC = () => {
  const history = useHistory();

  const queryCustomFieldsAll = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });

  return (
    <WSPage
      title="Open Receivable Aging"
      breadcrumb={{
        label: "Back",
        onClick() {
          history.push("/member/reports");
        },
        icon: "arrow-left"
      }}
    >
      <WSQueries
        queries={{
          customFields: queryCustomFieldsAll
        }}
        component={OpenReceivableAgingReportDashboard}
      />
    </WSPage>
  );
};
