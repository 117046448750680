import {
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayoutSettingsResponse,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { selectorCountryName } from "../../../../shared/selectors/selectorCountryName";
import { selectorIsAccountInternational } from "../../../../shared/selectors/selectorIsAccountInternational";
import { PayoutMethod } from "../PayoutMethod";

type Props = {
  payoutSettings: IPayoutSettingsResponse;
  accounts: IAccount[];
} & WSElementProps;

export const InternationalMethods: React.FC<Props> = ({
  payoutSettings,
  accounts,
  ...elementProps
}) => {
  const destination = payoutSettings.payoutDestinations.find(
    destination => destination.payoutMethod === PayoutPreferences.Standard
  );
  const accountsInternational = accounts.filter(selectorIsAccountInternational);
  const isVisible = accountsInternational.length > 0;

  const countries: { code: string; accounts: IAccount[] }[] = [];

  accountsInternational.forEach(account => {
    const matchingCountry = countries.find(
      country => country.code === account.country
    );

    if (matchingCountry) {
      matchingCountry.accounts.push(account);
    } else {
      countries.push({ code: account.country || "", accounts: [account] });
    }
  });

  return isVisible ? (
    <WSElement {...elementProps}>
      {countries.map(country => (
        <WSElement key={country.code} mt="2XL">
          <WSText weight="medium" mb="XL">
            {selectorCountryName(country.code)} based payout methods
          </WSText>

          {country.accounts.map(account => (
            <PayoutMethod
              mb="M"
              account={account}
              type={PayoutPreferences.Standard}
              isDefault={
                destination && destination.destinationId === account.accountId
              }
            />
          ))}
        </WSElement>
      ))}
    </WSElement>
  ) : null;
};
