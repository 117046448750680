import {
  WSCard,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { openInNewTab } from "../../../utils/openInNewTab";

type Taxes101Props = WSElementProps;

export const Taxes101: React.FC<Taxes101Props> = props => {
  return (
    <WSCard
      header={{
        label: "Freelance Taxes 101 with Little Fish Accounting",
        value: {
          pill: {
            theme: "neutral",
            icon: "caret-right"
          }
        }
      }}
      onClick={() => {
        openInNewTab(
          "https://my.wingspan.app/member/knowledge-center/freelance-taxes-101"
        );
      }}
      {...props}
    >
      <WSText.ParagraphSm color="gray600">
        Learn the common deductible expenses for taxes. Little Fish Accounting's
        checklist to help you navigate freelance tax deductions
      </WSText.ParagraphSm>
    </WSCard>
  );
};
