import { servicePayments } from "../servicePayments";
import {
  IPayeeEngagementResponse,
  IPayeeEngagementUpdateRequest
} from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";

export const updatePayeeEngagement = async (
  payeeId: string,
  engagementId: string,
  request: IPayeeEngagementUpdateRequest
): Promise<IPayeeEngagementResponse> => {
  const { data } = await servicePayments.patch(
    `/payee/${payeeId}/engagement/${engagementId}`,
    request
  );

  return data;
};
