import { ICardResponse } from "../../../../services/api/banking/cards/types";
import { useChangePINModal } from "../../modals/ChangePIN/useChangePINModal";
import { usePINChangedModal } from "../../modals/ChangePIN/usePINChangedModal";
import { useReplaceCardModal } from "../../modals/ReplaceCard/useReplaceCardModal";
import { useCardReplacementRequestedModal } from "../../modals/ReplaceCard/useCardReplacementRequestedModal";
import { useSuspendCardModal } from "../../modals/SuspendCard/useSuspendCardModal";
import { useCardSuspendedModal } from "../../modals/SuspendCard/useCardSuspendedModal";
import { useCloseCardModal } from "../../modals/CloseCard/useCloseCardModal";
import {
  useWSSnackbar,
  WSActionsButton
} from "@wingspanhq/fe-component-library";
import { selectorCardName } from "../../selectors/selectorCardName";
import { useCardReactivatedModal } from "../../modals/ReactivateCard/useCardReactivatedModal";
import { useReactivateCardModal } from "../../modals/ReactivateCard/useReactivateCardModal";
import { useHistory } from "react-router-dom";
import { WALLET_ROOT_PATH } from "../../index";
import upperFirst from "lodash/upperFirst";

export const useActionButtons = (card: ICardResponse) => {
  const history = useHistory();

  const cardName = selectorCardName(card);
  const toastCardName = upperFirst(cardName);
  const cardId = card.cardId;

  const { openSnackbar } = useWSSnackbar();

  const changePINModal = useChangePINModal();
  const pinChangedModal = usePINChangedModal();

  const replaceCardModal = useReplaceCardModal();
  const cardReplacementRequestedModal = useCardReplacementRequestedModal();

  const suspendCardModal = useSuspendCardModal();
  const cardSuspendedModal = useCardSuspendedModal();

  const reactivateCardModal = useReactivateCardModal();
  const cardReactivatedModal = useCardReactivatedModal();

  const closeCardModal = useCloseCardModal();

  const getActions = (): WSActionsButton[] => {
    let buttons: WSActionsButton[] = [];
    if (card.status === "Active") {
      buttons.push({
        label: "Change PIN",
        onClick: () => {
          changePINModal.open({
            cardId,
            onSuccess() {
              openSnackbar({
                message: "PIN updated successfully",
                type: "success"
              });
              pinChangedModal.open();
            }
          });
        },
        kind: "Secondary"
      });

      buttons.push({
        label: "Suspend card",
        onClick: () => {
          suspendCardModal.open({
            cardId,
            cardName,
            onSuccess() {
              openSnackbar({
                message: `Card suspended`,
                type: "success"
              });
              cardSuspendedModal.open({
                cardId,
                cardName
              });
            }
          });
        },
        kind: "Tertiary"
      });

      if (!card.isVirtualCard) {
        buttons.push({
          label: "Replace lost or damaged card",
          onClick: () => {
            replaceCardModal.open({
              cardId,
              onSuccess() {
                openSnackbar({
                  message: "Card replacement requested",
                  type: "success"
                });

                cardReplacementRequestedModal.open();
              }
            });
          },
          kind: "Tertiary"
        });
      }
    }

    if (card.status === "Inactive") {
      buttons.push({
        label: "Reactivate card",
        onClick: () => {
          reactivateCardModal.open({
            cardId,
            cardName,
            onSuccess() {
              openSnackbar({
                message: "Card reactivated",
                type: "success"
              });
              cardReactivatedModal.open({
                cardId,
                cardName
              });
            }
          });
        },
        kind: "Tertiary"
      });
    }

    if (card.status !== "Closed") {
      buttons.push({
        label: "Close card",
        destructive: true,
        onClick: () => {
          closeCardModal.open({
            cardId,
            cardName,
            onSuccess() {
              history.push(WALLET_ROOT_PATH);
              openSnackbar({
                message: `${toastCardName} closed`,
                type: "success"
              });
            }
          });
        },
        kind: "Secondary"
      });
    }

    return buttons;
  };

  return getActions();
};
