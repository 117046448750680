import {
  useWSSnackbar,
  WSActions,
  WSForm,
  WSInputPassword,
  WSList
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { useCaptcha } from "../../../../../components/Captcha";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { useWSMutation } from "../../../../../query/helpers";
import { useUserId } from "../../../../../query/hooks/helpers";
import { QUERY_USERS_SESSION } from "../../../../../query/session";
import { QUERY_USERS_USER_PROFILE } from "../../../../../query/users/keys";
import { useUserProfile } from "../../../../../query/users/queries";
import { usersService } from "../../../../../services/users";
import { validatorPassword } from "../../../../../shared/validators/validatorPassword";
import { WSServiceError } from "../../../../../utils/serviceHelper";

type FormData = {
  newPassword: string;
  confirmNewPassword: string;
};

type Props = {
  onSuccess?: (data: FormData) => void;

  onClose: () => void;
};

export const FormAddPassword: React.FC<Props> = ({ onSuccess, onClose }) => {
  const userId = useUserId();
  const queryUser = useUserProfile(userId);

  const { openSnackbar } = useWSSnackbar();

  const callWithCaptcha = useCaptcha("createSession");

  const [mutate, meta] = useWSMutation<void, WSServiceError, FormData>(
    async data => {
      // Update password
      const user = await usersService.user.update(userId, {
        password: data.newPassword
      });

      // Create new session
      await callWithCaptcha((captchaToken, captchaVersion, forceCaptcha) =>
        usersService.session.create({
          captchaToken,
          captchaVersion,
          forceCaptcha,
          email: user.email,
          password: data.newPassword
        })
      );
    },
    {
      dependencies: [QUERY_USERS_USER_PROFILE, QUERY_USERS_SESSION],
      onSuccess: (_, data) => {
        onSuccess?.(data);
        onClose();
        openSnackbar({
          message: "Your password has been successfully updated.",
          type: "success"
        });
      }
    }
  );

  return (
    <WSForm<FormData>
      onSubmit={mutate}
      defaultValues={{
        newPassword: "",
        confirmNewPassword: ""
      }}
      validationSchema={Yup.object({
        newPassword: validatorPassword.required("Required"),
        confirmNewPassword: validatorPassword
          .required("Required")
          .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      })}
    >
      <WSList gap="L">
        <WSForm.Field
          name="newPassword"
          label="New Password"
          component={WSInputPassword}
        />

        <WSForm.Field
          name="confirmNewPassword"
          label="Confirm New Password"
          component={WSInputPassword}
        />

        <WSErrorMessage contextKey="PasswordReset" error={meta.error} />

        <WSActions
          buttons={[
            {
              label: "Update"
            },
            {
              label: "Cancel",
              kind: "Secondary"
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};
