import { IMember, INewUser } from "@wingspanhq/users/dist/lib/interfaces";
import { selectorUserLegalName } from "../../selectors/selectorUserLegalName";
import { FormData } from "./types";

export const selectorDefaultValues = (
  user: INewUser,
  member: IMember
): FormData => ({
  card: {
    name: selectorUserLegalName(user),
    cardNumber: "",
    expYYYY: "",
    expMM: "",
    cvv: ""
  },

  country: member.profile.address?.country || "US",
  address: {
    addressLine1: member.profile.address?.addressLine1 || "",
    addressLine2: member.profile.address?.addressLine2 || "",
    city: member.profile.address?.city || "",
    state: member.profile.address?.state || "",
    postalCode: member.profile.address?.postalCode || ""
  }
});
