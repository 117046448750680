import {
  IPaymentCardCreateInitiateResponse,
  IPaymentCardOneTimeCreateRequest
} from "@wingspanhq/payments/dist/interfaces";
import { paymentsService } from "../../../services/payments";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSMutation } from "../../helpers";

export const useMutationInitiateOneTimePaymentCardCreate = () =>
  useWSMutation<
    IPaymentCardCreateInitiateResponse,
    WSServiceError,
    IPaymentCardOneTimeCreateRequest
  >(paymentsService.paymentCard.oneTime.initiateCreate);
