import React from "react";
import { useHistory } from "react-router-dom";
import {
  WSButton,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";

export const Field: React.FC<{
  label: string;
  value: React.ReactNode;
  link?: string;
  errorMessage?: React.ReactNode;
} & WSElementProps> = ({ label, link, value, errorMessage, ...props }) => {
  const history = useHistory();
  return (
    <WSElement mb="XL" {...props}>
      <WSFlexBox.CenterY justify="space-between">
        <WSText weight="medium">{label}</WSText>
        {link && (
          <WSButton.Link onClick={() => history.push(link)}>Edit</WSButton.Link>
        )}
      </WSFlexBox.CenterY>
      <WSDivider my="M" />
      <WSElement>
        {errorMessage ? (
          errorMessage
        ) : typeof value === "string" ? (
          <WSText.ParagraphSm color="gray500">{value}</WSText.ParagraphSm>
        ) : (
          value
        )}
      </WSElement>
    </WSElement>
  );
};
