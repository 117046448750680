import { useUserProfileData } from "./utils/useUserProfileData";
import { isUserIdsIncluded } from "./utils/isUserIdsIncluded";
import { HIDE_NEW_COLLABORATOR_PAYMENT_NAV_IDS } from "./helpers";

export const useShowNewCollaboratorPaymentNav = () => {
  const userData = useUserProfileData();
  // if not collaborator node, show new collaborator payment nav
  const showNewCollaboratorPaymentNav = !isUserIdsIncluded(
    userData,
    HIDE_NEW_COLLABORATOR_PAYMENT_NAV_IDS
  );

  return showNewCollaboratorPaymentNav;
};
