import {
  useWSModal,
  WSActions,
  WSAlert,
  WSDivider,
  WSInfoBox,
  WSList
} from "@wingspanhq/fe-component-library";

export interface CardSuspendedProps {
  cardId: string;
  cardName: string;
  onClose: () => void;
}

const CardSuspended: React.FC<CardSuspendedProps> = ({
  cardId,
  cardName,
  onClose
}) => {
  return (
    <WSList gap="L">
      <WSAlert theme="success">Your {cardName} was suspended</WSAlert>
      <WSInfoBox>
        The card will remain suspended until you reactivate or close the card.
        The card cannot be used while it is suspended.
      </WSInfoBox>
      <WSDivider />
      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            onClick: async () => {
              onClose();
            },
            kind: "Primary"
          }
        ]}
      ></WSActions>
    </WSList>
  );
};

export function useCardSuspendedModal() {
  return useWSModal(CardSuspended, {
    title: "Card suspended",
    size: "S"
  });
}
