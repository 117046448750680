import {
  WSMarginProps,
  WSPaddingProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { WS_LINKS } from "../../../../types/wsLinks";
import styles from "./styles.module.scss";
import React from "react";

type Props = WSMarginProps & WSPaddingProps;

export const InvitationAuthPrivacyPolicyInfoBlock: React.FC<Props> = props => {
  return (
    <WSText.ParagraphXs color="gray500" {...props}>
      By proceeding you agree to Wingspan’s{" "}
      <a
        href={WS_LINKS.wingspanTosAcceptance}
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Use
      </a>
      {", "}
      <a
        href={WS_LINKS.wingspanPrivacyPolicyAcceptance}
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      {", & "}
      <a
        href={WS_LINKS.electronicDisclosureAndConsent}
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        Electronic Disclosure & Consent
      </a>
      . This site is protected by reCAPTCHA and the Google{" "}
      <a
        href={WS_LINKS.googlePrivacyPolicy}
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>{" "}
      and{" "}
      <a
        href={WS_LINKS.googleTermOfService}
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Service
      </a>{" "}
      apply.
    </WSText.ParagraphXs>
  );
};
