import React from "react";
import {
  ICardResponse,
  IPaymentCardStatus
} from "../../../../services/api/banking/cards/types";
import { ActiveCard } from "./ActiveCard";
import { ProcessCardPlaceholder } from "./ProcessCardPlaceholder";
import { selectorCardName } from "../../selectors/selectorCardName";
import { useReactivateCardModal } from "../../modals/ReactivateCard/useReactivateCardModal";
import { useCardReactivatedModal } from "../../modals/ReactivateCard/useCardReactivatedModal";
import { EmptyCardPlaceholder } from "./EmptyCardPlaceholder";
import {
  WALLET_ACTIVATE_VIRTUAL_CARD_PATH,
  WALLET_ORDER_PHYSICAL_CARD_PATH
} from "../../index";
import { useHistory } from "react-router-dom";
import { useCardActivatedModal } from "../../modals/ActivateCard/useCardActivatedModal";
import { useActivateCardModal } from "../../modals/ActivateCard/useActivateCardModal";
import { useWSSnackbar } from "@wingspanhq/fe-component-library";

type Props = {
  card: ICardResponse;
};

export const CardView: React.FC<Props> = ({ card }) => {
  const history = useHistory();
  const { openSnackbar } = useWSSnackbar();

  const cardType = card.isVirtualCard ? "virtual" : "physical";
  const cardName = selectorCardName(card);
  const cardId = card.cardId;

  const reactivateCardModal = useReactivateCardModal();
  const cardReactivatedModal = useCardReactivatedModal();

  const cardActivatedModal = useCardActivatedModal();
  const activateCardModal = useActivateCardModal();

  const cardComponent: Record<IPaymentCardStatus, React.ReactNode> = {
    Active: <ActiveCard card={card} />,
    Inactive: (
      <ProcessCardPlaceholder
        title="Card suspended"
        cardType={cardType}
        label="Reactivate card"
        onAction={() => {
          reactivateCardModal.open({
            cardId,
            cardName,
            onSuccess() {
              openSnackbar({
                message: "Card unsuspended",
                type: "success"
              });
              cardReactivatedModal.open({ cardId, cardName });
            }
          });
        }}
      />
    ),
    Closed: (
      <ProcessCardPlaceholder cardType={cardType} title="Card is closed" />
    ),
    OneTime: (
      <ProcessCardPlaceholder cardType={cardType} title="One time card" />
    ),
    PendingActivation: (
      <EmptyCardPlaceholder
        actionIcon="card"
        actionLabel="Activate card"
        cardType={cardType}
        onAction={() => {
          if (cardType === "physical") {
            activateCardModal.open({
              cardId,
              onSuccess() {
                openSnackbar({
                  message: "Card activated",
                  type: "success"
                });
                cardActivatedModal.open();
              }
            });
          } else {
            history.push(WALLET_ACTIVATE_VIRTUAL_CARD_PATH);
          }
        }}
      />
    ),
    PendingFulfillment: (
      <EmptyCardPlaceholder
        actionIcon="shield-check-fill"
        actionLabel="Activate card"
        cardType={cardType}
        onAction={() => {
          if (cardType === "physical") {
            activateCardModal.open({
              cardId,
              onSuccess() {
                openSnackbar({
                  message: "Card activated",
                  type: "success"
                });
                cardActivatedModal.open();
              }
            });
          } else {
            history.push(WALLET_ACTIVATE_VIRTUAL_CARD_PATH);
          }
        }}
      />
    )
  };

  const placeholderComponent = (
    <EmptyCardPlaceholder
      actionIcon="card"
      actionLabel="Order card"
      cardType={cardType}
      onAction={() => {
        if (cardType === "virtual") {
          history.push(WALLET_ACTIVATE_VIRTUAL_CARD_PATH);
        } else {
          history.push(WALLET_ORDER_PHYSICAL_CARD_PATH);
        }
      }}
    />
  );

  return <>{cardComponent[card.status] || placeholderComponent}</>;
};
