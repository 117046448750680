import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useLocation
} from "react-router-dom";
import { MemberResetPassword } from "../../Auth/screens/MemberResetPassword";
import { useCreateSession } from "../../query/users/mutations";
import { SignUpRoutes } from "../../SignUp";
import { OverlaySpinner } from "../OverlaySpinner";
import { MemberSignIn } from "../../Auth/screens/MemberSignIn";
import { AuthSSO } from "../../Auth/screens/AuthSSO";
import { SignUpCheckEmail } from "../../SignUp/screens/SignUpCheckEmail";
import { RouteAuth1099 } from "../../modules/1099NECFiling/routes/SignUp/RouteAuth1099";
import {
  INVITATION_AUTH_PATH,
  InvitationAuthIndex
} from "../../modules/Invitations";
import {
  REQUEST_INVITATION_USER_LINK_PATH,
  RouteRequestLinkIndex
} from "../../modules/Invitations/routes/RouteRequestLink/RouteRequestLinkIndex";

type RouteProps = RouteComponentProps<{ invoiceId: string }>;

type Props = RouteProps;

const RequestGuestSession: React.FC<Props> = () => {
  const [signInAsGuest] = useCreateSession();

  useEffect(() => {
    signInAsGuest();
  }, []);

  return <OverlaySpinner />;
};

export const AuthApp: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return (
    <Switch>
      <Route
        path="/member/verification-sent-to-email"
        component={SignUpCheckEmail}
      />
      <Route path={["/x/1099", "/member/1099"]} component={RouteAuth1099} />

      {/* All sign up routes */}
      <Route
        path={REQUEST_INVITATION_USER_LINK_PATH}
        component={RouteRequestLinkIndex}
      />
      <Route path={INVITATION_AUTH_PATH} component={InvitationAuthIndex} />
      <Route
        path={[
          "/autopay/:tag",
          "/member/sign-up",
          "/*/sign-up",
          "/member/pre-sign-up",
          "/*/pre-sign-up",
          "/member/employer-sign-up",
          "/*/employer-sign-up",
          "/member/1099",
          "/*/1099"
        ]}
        component={SignUpRoutes}
      />
      <Redirect from="/register" to="/member/pre-sign-up" />
      <Redirect from="/sign-up" to="/member/pre-sign-up" />

      <Route path="/member/reset-password" component={MemberResetPassword} />
      <Route path="/payment" component={RequestGuestSession} />

      <Redirect path="/invoice-payment/:invoiceId" to="/payment/:invoiceId" />

      <Redirect path="/i/:invoiceId" to="/payment/:invoiceId" />
      {!!params.get("growth_name") ? null : (
        <Redirect path="/p/:invoiceId" to="/payment/:invoiceId" />
      )}

      <Redirect path="/pay/:tag" to="/payment/ppl/:tag" />
      <Redirect path="/ppl/:tag" to="/payment/ppl/:tag" />

      {/* All sign in routes */}
      <Route path="/member/sign-in" component={MemberSignIn} />
      <Route path="/auth/sso" component={AuthSSO} />
      <Redirect from="/sign-in" to="/member/sign-in" />
      <Route
        path="*"
        render={({ location }) => (
          <Redirect
            to={`/member/sign-in#${location.pathname}${location.search}`}
          />
        )}
      />
    </Switch>
  );
};
