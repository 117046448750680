import {
  WSCheckboxToggle,
  WSElement,
  WSElementProps,
  WSFormOld,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

export type PaymentMethodsValues = {
  acceptAllPMs: boolean;
  acceptCreditCard: boolean;
  acceptACH: boolean;
  acceptManual: boolean;
};

export type PaymentMethodsSectionValues = {
  paymentMethods: PaymentMethodsValues;
};

type Props = {} & WSElementProps;

export const PaymentMethodsSection: React.FC<Props> = ({ ...elementProps }) => {
  return (
    <WSElement {...elementProps}>
      <WSFormOld.Field
        name="paymentMethods.acceptAllPMs"
        component={WSCheckboxToggle}
        componentProps={{
          label: <WSText.Heading5>Accept all payment methods</WSText.Heading5>
        }}
      />

      <WSFormOld.Value name="paymentMethods.acceptAllPMs">
        {acceptAllPMs => (
          <>
            <WSFormOld.Field
              mt="XL"
              hidden={acceptAllPMs}
              name="paymentMethods.acceptCreditCard"
              component={WSCheckboxToggle}
              componentProps={{
                label: "Credit cards",
                description:
                  "Clients can pay with any major credit card, fees are passed onto them by default."
              }}
            />
            <WSFormOld.Field
              mt="XL"
              hidden={acceptAllPMs}
              name="paymentMethods.acceptACH"
              component={WSCheckboxToggle}
              componentProps={{
                label: "ACH Debit",
                description:
                  "Clients link their bank account to Wingspan, and we'll draw the funds from their account."
              }}
            />
            <WSFormOld.Field
              mt="XL"
              hidden={acceptAllPMs}
              name="paymentMethods.acceptManual"
              component={WSCheckboxToggle}
              componentProps={{
                label: "Manual Wire / ACH Credit",
                description:
                  "Clients instruct their bank to make a transfer to a virtual bank account where we collect the payment."
              }}
            />
          </>
        )}
      </WSFormOld.Value>
    </WSElement>
  );
};
