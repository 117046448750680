import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { useTaxFormDownloadDocument } from "../../../../../query/taxForm/mutations/useTaxFormDownloadDocument";
import {
  ITaxFormSubmissionResponse,
  TaxFormViewer
} from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { downloadFileFromBlob } from "../../../../../utils/files";

type Props = {
  taxForm: IPayerTaxFormResponse;
} & WSElementProps;

export const Documents: React.FC<Props> = ({ taxForm, ...elementProps }) => {
  // reverse performed in place
  const documents = taxForm.submissions
    ? [...taxForm.submissions].reverse()
    : [];
  const [
    downloadDocument,
    _downloadDocumentMeta
  ] = useTaxFormDownloadDocument();

  return (
    <WSElement {...elementProps}>
      {documents.map((document, index) => {
        let postfix = "";

        if (documents.length > 1) {
          if (index === documents.length - 1) {
            postfix = "(original)";
          } else if (index === 0) {
            postfix = "(current)";
          } else {
            postfix = "(updated)";
          }
        }

        const date = document.events?.submittedAt;
        const name = `Form 1099-NEC ${postfix}`;

        return (
          <WSPanel key={`${document.taxFormSubmissionId}-${index}`} mb="M">
            <WSFlexBox
              wrap="nowrap"
              alignItems="stretch"
              justify="space-between"
            >
              <WSFlexBox.CenterX direction="column">
                <WSText.Heading4>{name}</WSText.Heading4>
                {date && (
                  <WSText.ParagraphSm
                    mt="S"
                    color="gray500"
                    formatDate="monthDayYear"
                  >
                    {date}
                  </WSText.ParagraphSm>
                )}
              </WSFlexBox.CenterX>
              <WSFlexBox.CenterY direction="column">
                <WSButton.Secondary
                  fullWidth
                  mb="S"
                  size="S"
                  onAsyncClick={async () => {
                    await downloadDocument(
                      {
                        viewer: TaxFormViewer.payer,
                        taxFormId: taxForm.taxFormId,
                        submissionId: document.taxFormSubmissionId
                      },
                      {
                        throwOnError: true,
                        onSuccess(pdf) {
                          downloadFileFromBlob(pdf, `${name} payer copy.pdf`);
                        }
                      }
                    );
                  }}
                >
                  Download payer copy
                </WSButton.Secondary>
                <WSButton.Secondary
                  fullWidth
                  size="S"
                  onAsyncClick={async () => {
                    await downloadDocument(
                      {
                        viewer: TaxFormViewer.payee,
                        taxFormId: taxForm.taxFormId,
                        submissionId: document.taxFormSubmissionId
                      },
                      {
                        throwOnError: true,
                        onSuccess(pdf) {
                          downloadFileFromBlob(pdf, `${name} payee copy.pdf`);
                        }
                      }
                    );
                  }}
                >
                  Download payee copy
                </WSButton.Secondary>
              </WSFlexBox.CenterY>
            </WSFlexBox>
          </WSPanel>
        );
      })}
    </WSElement>
  );
};
