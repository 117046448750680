import { IProfitAndLoss } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { IProfitAndLossAmountByWsCategory } from "@wingspanhq/bookkeeping/dist/lib/interfaces/profitAndLoss";
import { WSCategory } from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";
import {
  WSAvatar,
  WSDateRangeSelectOptions,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSPanel,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import { sortBy } from "lodash";
import React from "react";
import { useHistory } from "react-router";
import { Route, Switch } from "react-router-dom";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { WSQueries } from "../../../query/WSQuery";
import {
  useCatchUpTransactionsExpense,
  useProfitAndLoss
} from "../../../query/bookkeeping/queries";
import { useUserId } from "../../../query/hooks/helpers";
import { useActivities } from "../../../query/users/queries";
import { Review } from "../../components/Review/Review";
import { getIconNameByWSCategory, isValidDateRange } from "../../utils";
import { BookkeepingAddTransactionV2 } from "./BookkeepingAddTransactionV2";
import { useBookkeepingFilters } from "./BookkeepingIndex";
import styles from "./BookkeepingTransactions.module.scss";
import { TaxesDocuments } from "./TaxesDocuments";

const TopExpenseCategories: React.FC<{
  query: ReturnType<typeof useProfitAndLoss>;
}> = ({ query }) => {
  const history = useHistory();

  let items: IProfitAndLossAmountByWsCategory[] = query.data?.amountByWsCategory
    ? sortBy(
        query.data?.amountByWsCategory.filter(c => c.wsCategory),
        "amount"
      )
        .reverse()
        .slice(0, 5)
    : [];

  if (query.isLoading) {
    items = Array(5)
      .fill(0)
      .map(() => ({
        wsCategory: WSCategory.Supplies,
        amount: 999
      })) as IProfitAndLossAmountByWsCategory[];
  }

  return (
    <WSPanel>
      <WSText.Heading5 mb="XL">Top expense categories</WSText.Heading5>
      {items.length ? (
        items.map(({ wsCategory, amount }, index) => (
          <WSElement key={"1" + wsCategory + amount + index}>
            {index ? <WSDivider /> : null}
            <WSFlexBox.CenterY py="M" wrap="nowrap" justify="space-between">
              <WSFlexBox.CenterY wrap="nowrap">
                <WSAvatar.Icon
                  shimmer={query.isLoading}
                  mr="M"
                  icon={getIconNameByWSCategory(wsCategory) || "info-circle"}
                />
                <WSText
                  className={styles.reportItemName}
                  shimmer={query.isLoading}
                >
                  {wsCategory}
                </WSText>
              </WSFlexBox.CenterY>
              <WSText
                formatMoney
                shimmer={query.isLoading}
                data-testid="topExpenseCategoriesValue"
              >
                {Math.abs(amount)}
              </WSText>
            </WSFlexBox.CenterY>
          </WSElement>
        ))
      ) : (
        <WSText>
          No deductible expenses yet.{" "}
          <WSText
            inline
            color="blue500"
            onClick={() =>
              history.push("/member/bookkeeping/reports/add-transaction")
            }
          >
            Add transactions.
          </WSText>
        </WSText>
      )}
    </WSPanel>
  );
};

const TopMerchants: React.FC<{
  query: ReturnType<typeof useProfitAndLoss>;
}> = ({ query }) => {
  let items = query.data?.amountByMerchant
    ? sortBy(
        query.data?.amountByMerchant.filter(i => i.merchant),
        "amount"
      )
        .reverse()
        .slice(0, 5)
    : [];

  if (query.isLoading) {
    items = Array(5)
      .fill(0)
      .map(() => ({
        wsCategory: WSCategory.Supplies,
        merchant: "Merchant",
        amount: 999
      })) as any;
  }

  const history = useHistory();

  return (
    <WSPanel>
      <WSText.Heading5 mb="XL">Top expense merchants</WSText.Heading5>
      {items.length ? (
        items.map(({ amount, merchant, wsCategory }, index) => (
          <WSElement key={"2" + merchant + wsCategory + amount + index}>
            {index ? <WSDivider /> : null}
            <WSFlexBox.CenterY py="M" wrap="nowrap" justify="space-between">
              <WSFlexBox.CenterY wrap="nowrap">
                <WSAvatar.Icon
                  shimmer={query.isLoading}
                  mr="M"
                  icon={getIconNameByWSCategory(wsCategory) || "info-circle"}
                />
                <WSText
                  className={styles.reportItemName}
                  shimmer={query.isLoading}
                >
                  {merchant}
                </WSText>
              </WSFlexBox.CenterY>
              <WSText
                formatMoney
                shimmer={query.isLoading}
                data-testid="topExpenseMerchantsValue"
              >
                {Math.abs(amount)}
              </WSText>
            </WSFlexBox.CenterY>
          </WSElement>
        ))
      ) : (
        <WSText>
          No deductible expenses yet.{" "}
          <WSText
            inline
            color="blue500"
            onClick={() =>
              history.push("/member/bookkeeping/reports/add-transaction")
            }
          >
            Add transactions.
          </WSText>
        </WSText>
      )}
    </WSPanel>
  );
};

const TopIncomeByClient: React.FC<{
  query: ReturnType<typeof useProfitAndLoss>;
}> = ({ query }) => {
  const history = useHistory();

  let items = query.data?.amountByMerchant
    ? sortBy(query.data?.amountByMerchant, "amount")
        .reverse()
        .slice(0, 5)
    : [];
  if (query.isLoading) {
    items = Array(5)
      .fill(0)
      .map(() => ({
        wsCategory: WSCategory.Supplies,
        merchant: "Merchant",
        amount: 999
      })) as any;
  }

  return (
    <WSPanel>
      <WSText.Heading5 mb="XL">Income by client</WSText.Heading5>
      {items.length ? (
        items.map(({ merchant, wsCategory, amount }, index) => (
          <WSElement key={"3" + merchant + wsCategory + amount + index}>
            {index ? <WSDivider /> : null}
            <WSFlexBox.CenterY py="M" wrap="nowrap" justify="space-between">
              <WSFlexBox.CenterY wrap="nowrap">
                <WSAvatar.Text
                  shimmer={query.isLoading}
                  mr="M"
                  text={merchant.charAt(0)}
                />
                <WSText
                  className={styles.reportItemName}
                  shimmer={query.isLoading}
                >
                  {merchant}
                </WSText>
              </WSFlexBox.CenterY>
              <WSText formatMoney shimmer={query.isLoading}>
                {Math.abs(amount)}
              </WSText>
            </WSFlexBox.CenterY>
          </WSElement>
        ))
      ) : (
        <WSText>
          No income yet.{" "}
          <WSText
            inline
            color="blue500"
            onClick={() => history.push("/member/invoices")}
          >
            Go to invoicing.
          </WSText>
        </WSText>
      )}
    </WSPanel>
  );
};

export const BookkeepingReports: React.FC = () => {
  const { filters } = useBookkeepingFilters();

  const userId = useUserId();
  const qCatchUpExpenseTransactions = useCatchUpTransactionsExpense(userId);

  const dateRange = (isValidDateRange(filters.customDateRange)
    ? filters.customDateRange
    : WSDateRangeSelectOptions.LastYear.range) as any;

  const qProfitAndLoss = useProfitAndLoss({
    startDate: dateRange ? dateRange[0] : new Date(),
    endDate: dateRange ? dateRange[1] : new Date()
  });

  const profitAndLossData = (qProfitAndLoss.data || {}) as IProfitAndLoss;
  const qActivity = useActivities(userId);

  return (
    <>
      <Switch>
        <Route
          path={"/member/bookkeeping/reports/add-transaction"}
          component={BookkeepingAddTransactionV2}
          exact
        />
      </Switch>
      <WSQueries
        queries={{
          qCatchUpExpenseTransactions,
          qActivity
        }}
      >
        {({ qActivity, qCatchUpExpenseTransactions }) => {
          const mainContent = (
            <>
              <WSText.Heading1 mb="2XL">Reports</WSText.Heading1>
              <WSGrid gutter="M">
                <WSGrid.Item span={{ m: "6" }}>
                  <TopExpenseCategories query={qProfitAndLoss} />
                </WSGrid.Item>
                <WSGrid.Item span={{ m: "6" }}>
                  <TopMerchants query={qProfitAndLoss}></TopMerchants>
                </WSGrid.Item>
                {/*<WSGrid.Item  span={{m: "12"}}>*/}
                {/*  <TopIncomeByClient query={qProfitAndLoss}></TopIncomeByClient>*/}
                {/*</WSGrid.Item>*/}
              </WSGrid>
              <WSElement pb="XL" />
            </>
          );

          const sideContent = (
            <>
              <Review
                mb="XL"
                isShort
                profitAndLoss={profitAndLossData}
                activity={qActivity.data}
                expenseCatchUp={qCatchUpExpenseTransactions.data}
              />
              <WSPanel mb="XL">
                <TaxesDocuments />
              </WSPanel>
            </>
          );

          return (
            <>
              <BrowserPageTitle title="Income & Expenses: Profit&Loss" />

              <WSScreen.Mobile>
                <WSGrid>
                  <WSGrid.Item>
                    {mainContent}
                    {sideContent}
                  </WSGrid.Item>
                </WSGrid>
              </WSScreen.Mobile>
              <WSScreen.TabletAndDesktop>
                <WSGrid>
                  <WSGrid.Item span={{ s: "7", m: "7", l: "8" }}>
                    {mainContent}
                  </WSGrid.Item>
                  <WSGrid.Item span={{ s: "5", m: "5", l: "4" }}>
                    {sideContent}
                  </WSGrid.Item>
                </WSGrid>
              </WSScreen.TabletAndDesktop>
            </>
          );
        }}
      </WSQueries>
    </>
  );
};
