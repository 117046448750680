import wingspanLogo from "../../assets/images/ws-logo.svg";
import {
  CustomizableTerms,
  IBrandingCustomization,
  IOrganizationAccountConfig,
  ISupportCustomization,
  ITerminologyCustomization
} from "@wingspanhq/users/dist/lib/interfaces";

export interface DefaultCustomization
  extends Required<
    Pick<IOrganizationAccountConfig, "terminology" | "branding" | "support">
  > {
  terminology: Required<ITerminologyCustomization>;
  branding: Required<IBrandingCustomization>;
  support: Required<ISupportCustomization>;
}

export const DEFAULT_CUSTOMIZATION: DefaultCustomization = {
  terminology: {
    [CustomizableTerms.sendPaymentsPayable]: "Payable",
    [CustomizableTerms.sendPaymentsContractor]: "Contractor",
    [CustomizableTerms.sendPaymentsPaidAt]: "Processed (paid)",
    [CustomizableTerms.sendPaymentsOverdueAt]: "Past Due",
    [CustomizableTerms.getPaidClient]: "Client",
    [CustomizableTerms.getPaidInvoice]: "Invoice"
  },
  branding: {
    name: "Wingspan",
    url: "https://my.wingspan.app",
    primaryLogoUrl: wingspanLogo,
    secondaryLogoUrl: wingspanLogo
  },
  support: {
    generalSupportEmail: "",
    payerSupportEmail: "",
    payeeSupportEmail: "",
    documentation: {
      generalUrl: "",
      payoutInformationUrl: ""
    },
    portal: {
      generalUrl: ""
    }
  }
};
