import {
  toCamelCase,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import styles from "./index.module.scss";
import {
  SUBSCRIPTION_SELECT_OPTIONS,
  SubscriptionGrantOptionTitle
} from "../../constants/subscriptionOptions";
import classNames from "classnames";

export interface MembershipSelectorProps {
  onChange: (option: SubscriptionGrantOptionTitle) => void;
  error?: string;
  value: SubscriptionGrantOptionTitle;
}

export const MembershipSelector: React.FC<MembershipSelectorProps> = ({
  onChange,
  error,
  value
}) => {
  const selectedSubscription = SUBSCRIPTION_SELECT_OPTIONS.find(
    s => s.title === value
  )!;

  return (
    <WSElement
      className={classNames(styles.membership, { [styles.error]: error })}
    >
      <WSFlexBox.CenterY
        className={styles.trigger}
        p="S"
        tabIndex={1}
        justify="space-between"
        wrap="nowrap"
        data-testid="subscriptionGrantSelectTrigger"
      >
        <WSFlexBox.CenterY
          direction="column"
          alignItems="flex-start"
          wrap="wrap"
        >
          <WSText mb="XS">{selectedSubscription.title}</WSText>
          <WSText.ParagraphSm>
            {selectedSubscription.description}
          </WSText.ParagraphSm>
        </WSFlexBox.CenterY>
        <WSIcon block name="caret-down" color="gray500" size="M" />
      </WSFlexBox.CenterY>
      <WSElement className={styles.options}>
        <WSPanel.BS1 p="NONE" mb="3XL" noBorder>
          {SUBSCRIPTION_SELECT_OPTIONS.map(option => {
            const isSelected =
              option.value.package === selectedSubscription?.value.package &&
              option.value.packageTier ===
                selectedSubscription.value.packageTier;
            return (
              <WSFlexBox.CenterY
                tabIndex={0}
                justify="space-between"
                wrap="nowrap"
                onMouseDown={() => {
                  onChange(option.title);
                }}
                className={styles.option}
                data-testid={toCamelCase("grant", option.title)}
                p="S"
              >
                <WSFlexBox.CenterY direction="column" alignItems="flex-start">
                  <WSText color="gray700" mb="XS">
                    {option.title}
                  </WSText>
                  <WSText.ParagraphSm color="gray600">
                    {option.description}
                  </WSText.ParagraphSm>
                </WSFlexBox.CenterY>

                {isSelected ? (
                  <WSIcon block name="check" color="blue400" size="M" />
                ) : null}
              </WSFlexBox.CenterY>
            );
          })}
        </WSPanel.BS1>
        <WSElement className={styles.margin} pb="6XL" />
      </WSElement>
    </WSElement>
  );
};
