import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from "react";

declare global {
  interface WindowEventMap {
    "local-storage": CustomEvent;
  }
}

type SetValue<T> = Dispatch<SetStateAction<T>>;

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, SetValue<T>] {
  // Add a Wingspan prefix for to all keys
  const getKey = useCallback(() => {
    return `wingspan:${key}`;
  }, [key]);

  const readValue = useCallback((): T => {
    try {
      const item = window.localStorage.getItem(getKey());
      return item ? (parseJSON(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${getKey()}”:`, error);
      return initialValue;
    }
  }, [initialValue, getKey]);

  // Handles storing and setting value for current instance of hook
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Custom value setter which will write value to localstorage, local state and will trigger event so other instantces will pick it up
  const setValue: SetValue<T> = useCallback(
    value => {
      try {
        // Allow value to be a function so we have the same API as useState
        const newValue = value instanceof Function ? value(storedValue) : value;

        // Save to local storage
        window.localStorage.setItem(getKey(), JSON.stringify(newValue));

        // Save state
        setStoredValue(newValue);

        // We dispatch a custom event so every useLocalStorage hook are notified
        window.dispatchEvent(new Event("local-storage"));
      } catch (error) {
        console.warn(`Error setting localStorage key “${getKey()}”:`, error);
      }
    },
    [getKey, storedValue]
  );

  // Make sure outside changes of value will reflect in current instantce
  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent) => {
      const key = getKey();
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return;
      }
      setStoredValue(readValue());
    },
    [getKey, readValue]
  );
  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("local-storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("local-storage", handleStorageChange);
    };
  });

  return [storedValue, setValue];
}

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === "undefined" ? undefined : JSON.parse(value ?? "");
  } catch {
    console.log("parsing error on", { value });
    return undefined;
  }
}
