import { components } from "../../../services/api/onboarding/types";
import { acknowledgementInfo } from "../../../shared/constants/acknowledgements";

type Props = {
  aknowledgements: components["schemas"]["AcknowledgementName"][];
};

export const Acknowledgements: React.FC<Props> = ({ aknowledgements }) => (
  <ol>
    {aknowledgements.map(aknowledgement => (
      <li key={aknowledgement}>
        <a
          href={acknowledgementInfo[aknowledgement].link}
          target="_blank"
          rel="noreferrer"
        >
          {acknowledgementInfo[aknowledgement].title}
        </a>
        : {acknowledgementInfo[aknowledgement].description}
      </li>
    ))}
  </ol>
);
