import {
  toWSMoney,
  WSButton,
  WSDivider,
  WSFlexBox,
  WSList,
  WSMarginProps,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import styles from "./styles.module.scss";
import { getGrossPayment } from "../../utils/getGrossPayment";
import { useMemo } from "react";
import { WSQueries } from "../../../../query/WSQuery";
import {
  getPayoutTotalAmount,
  selectorIsPayoutItemVisible
} from "../../utils/getPayoutTotalAmount";
import { useHistory } from "react-router-dom";
import { IPayoutResponse } from "../../../../services/api/payments/payouts/types";
import { usePayoutInvoicesDetails } from "../../queries/usePayoutInvoicesDetails";
import { makeNegative } from "../../utils/makeNegative";
import { getPayoutSummaryFees } from "../../utils/getPayoutSummaryFees";
import { getPayoutSummaryCollaboratorPayments } from "../../utils/getPayoutSummaryCollaboratorPayments";
import { getPayoutSummaryDeductions } from "../../utils/getPayoutSummaryDeductions";

export interface PayoutSummaryPanelProps extends WSMarginProps {
  payout: IPayoutResponse;
}

export const PayoutSummaryPanel: React.FC<PayoutSummaryPanelProps> = ({
  payout,
  ...elementProps
}) => {
  const history = useHistory();

  const invoicesIds =
    payout.payoutItems
      ?.filter(selectorIsPayoutItemVisible)
      .map(invoice => invoice.sourceId) || [];

  const queryPayoutInvoicesDetails = usePayoutInvoicesDetails(invoicesIds);

  const payoutTotalAmount = useMemo(() => getPayoutTotalAmount(payout), [
    payout
  ]);

  return (
    <WSQueries queries={{ queryPayoutInvoicesDetails }}>
      {({ queryPayoutInvoicesDetails: { data: detailedInvoices } }) => {
        const feesAmount = getPayoutSummaryFees(detailedInvoices);
        const deductionsAmount = getPayoutSummaryDeductions(detailedInvoices);
        const paymentsToOthersAmount = getPayoutSummaryCollaboratorPayments(
          detailedInvoices
        );
        const feesAndDeductionsAmount = feesAmount + deductionsAmount;
        const grossPayments = getGrossPayment(detailedInvoices);

        return (
          <WSPanel className={styles.panel} {...elementProps}>
            <WSList gap="L">
              <WSFlexBox direction="column">
                <WSText.Caption>Gross payments</WSText.Caption>
              </WSFlexBox>
              <WSFlexBox justify="space-between">
                <WSText.ParagraphSm color={"gray500"} mr="2XL">
                  Payment for invoice(s):
                </WSText.ParagraphSm>
                <WSText.ParagraphSm color={"gray700"}>
                  {toWSMoney(grossPayments).formattedValue}
                </WSText.ParagraphSm>
              </WSFlexBox>

              <WSFlexBox direction="column">
                {detailedInvoices.map(invoice => (
                  <WSButton.Link
                    onClick={() => {
                      history.push(`/member/invoices/${invoice?.invoiceId}`);
                    }}
                  >
                    Invoice #{invoice?.invoiceNumber}
                  </WSButton.Link>
                ))}
              </WSFlexBox>

              {Boolean(feesAndDeductionsAmount) && (
                <>
                  <WSFlexBox direction="column">
                    <WSText.Caption>Fees & DEductions</WSText.Caption>
                  </WSFlexBox>
                  <WSFlexBox justify="space-between">
                    <WSText.ParagraphSm color={"gray500"} mr="2XL">
                      Fees & deductions for invoice(s)
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm color={"gray700"}>
                      {
                        toWSMoney(makeNegative(feesAndDeductionsAmount))
                          .formattedValue
                      }
                    </WSText.ParagraphSm>
                  </WSFlexBox>
                </>
              )}

              {Boolean(paymentsToOthersAmount) && (
                <>
                  <WSFlexBox direction="column">
                    <WSText.Caption>Payments to others</WSText.Caption>
                  </WSFlexBox>
                  <WSFlexBox justify="space-between">
                    <WSText.ParagraphSm color={"gray500"} mr="2XL">
                      Payments to other recipients for invoice(s)
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm color={"gray700"}>
                      {
                        toWSMoney(makeNegative(paymentsToOthersAmount))
                          .formattedValue
                      }
                    </WSText.ParagraphSm>
                  </WSFlexBox>
                </>
              )}

              {Boolean(payout.taxWithholdingTransfer?.amount) && (
                <>
                  <WSFlexBox direction="column">
                    <WSText.Caption>Tax withholdings</WSText.Caption>
                  </WSFlexBox>
                  <WSFlexBox justify="space-between">
                    <WSText.ParagraphSm color={"gray500"} mr="2XL">
                      {`Tax withholdings for invoice(s)`}
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm color={"gray700"}>
                      {
                        toWSMoney(
                          makeNegative(
                            payout.taxWithholdingTransfer?.amount || 0
                          )
                        ).formattedValue
                      }
                    </WSText.ParagraphSm>
                  </WSFlexBox>
                </>
              )}

              <WSDivider />

              <WSFlexBox justify="space-between">
                <WSText.ParagraphSm color={"gray500"} mr="2XL">
                  Net payout total
                </WSText.ParagraphSm>
                <WSText.ParagraphSm weight={"bold"} color={"gray700"}>
                  {toWSMoney(payoutTotalAmount).formattedValue}
                </WSText.ParagraphSm>
              </WSFlexBox>
            </WSList>
          </WSPanel>
        );
      }}
    </WSQueries>
  );
};
