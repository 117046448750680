import {
  useWSModal,
  WSActions,
  WSDivider,
  WSElement,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import { useCreateRequirementDefinition } from "../../../../query/requirementDefinitions/mutation/useCreateRequirementDefinition";
import {
  DefinitionDataSourceType,
  IRequirementDefinitionResponse,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import { useUserId } from "../../../../query/hooks/helpers";

import styles from "./styles.module.scss";
import { useAddRequirementToEngagement } from "../../../../query/engagements/mutations/useAddRequirementToEngagement";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";

interface ConfirmCreateRequirementModalProps {
  onClose: () => void;
  onSuccess: (requirement: IRequirementDefinitionResponse) => void;
  engagementId?: string;

  dataSourceType: DefinitionDataSourceType;
  dataSourceId: string;
  name: string;
  description?: string;
  requirementType: RequirementType;
}

const ConfirmCreateRequirementModal: React.FC<ConfirmCreateRequirementModalProps> = ({
  onClose,
  dataSourceType,
  dataSourceId,
  name,
  description,
  requirementType,
  engagementId,
  onSuccess
}) => {
  const userId = useUserId();
  const [
    createRequirement,
    createRequirementMeta
  ] = useCreateRequirementDefinition();
  const [
    addRequirementToEngagement,
    addRequirementToEngagementMeta
  ] = useAddRequirementToEngagement(engagementId as string);
  return (
    <WSList gap="XL">
      <WSDivider />

      <WSElement className={styles.confirmMessage} p="M">
        <WSText.ParagraphXs weight="medium" mb="XS">
          Confirm requirement creation
        </WSText.ParagraphXs>

        <WSText.ParagraphXs weight="book">
          Once a requirement is created, it cannot be edited.
          <br />
          If the requirement needs to be changed, a new version must be created
          and configured.
          <br />
          <br />
          Existing requirements can be previewed, attached, or removed from
          engagement at any time.
        </WSText.ParagraphXs>
      </WSElement>

      <WSDivider />

      <WSErrorMessage
        error={
          createRequirementMeta.error || addRequirementToEngagementMeta.error
        }
        contextKey="Other"
      />

      <WSActions
        buttons={[
          {
            label: "Create requirement",
            kind: "Primary",
            name: "createRequirement",
            loading:
              createRequirementMeta.isLoading ||
              addRequirementToEngagementMeta.isLoading,
            onClick: async () => {
              await createRequirement(
                {
                  clientId: userId,
                  dataSourceType,
                  dataSourceId,
                  name,
                  description,
                  requirementType
                },
                {
                  onSuccess: async (
                    requirement: IRequirementDefinitionResponse
                  ) => {
                    if (engagementId) {
                      // update engagement with new requirement
                      await addRequirementToEngagement(
                        {
                          requirementId: requirement.requirementDefinitionId
                        },
                        {
                          onSuccess: () => {
                            onSuccess(requirement);

                            onClose();
                          }
                        }
                      );
                    } else {
                      onSuccess(requirement);

                      onClose();
                    }
                  }
                }
              );
            }
          },
          {
            label: "Cancel",
            kind: "Secondary",
            onClick: onClose
          }
        ]}
        alignment="fill"
      />
    </WSList>
  );
};

export function useConfirmCreateRequirementModal() {
  return useWSModal(ConfirmCreateRequirementModal, {
    title: "Create requirement",
    size: "S"
  });
}
