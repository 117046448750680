import { IResendInvitationsRequest } from "@wingspanhq/users/dist/lib/interfaces";
import { serviceUser } from "./serviceUser";
import { IResendInvitationsResponse } from "@wingspanhq/users/dist/lib/interfaces/api/invitation";

export interface IResendInvitationsRequestWithCaptcha
  extends IResendInvitationsRequest {
  captchaToken: string;
  captchaVersion: string;
  forceCaptcha?: boolean;
}

export const resendUserInvite = async (
  request: IResendInvitationsRequestWithCaptcha
) => {
  const { data } = await serviceUser.post("/invitation/resend", request);

  return data as IResendInvitationsResponse;
};
