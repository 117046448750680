export enum PayoutMethodType {
  Instant = "Instant",
  Wallet = "Wallet",
  ACH = "ACH",
  GiftCard = "GiftCard"
}

export enum ACHConnectType {
  PlaidLink = "PlaidLink",
  Manual = "Manual"
}
