import {
  WSButton,
  WSCheckboxToggle,
  WSLoader,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { useQueryCustomerEntity } from "../../../../query/onboarding/queries/useQueryCustomerEntity";
import { useEnterpriseQuery } from "../../../../query/platform/queries";
import { LayoutPaymentsSetup } from "../../../../shared/components/LayoutPaymentsSetup";
import { TextBulleted } from "../../../../shared/components/TextBulleted";
import { selectorEnterpriseName } from "../../../../shared/selectors/selectorEnterpriseName";
import { WS_LINKS } from "../../../../types/wsLinks";
import { useMutationUpdateCurrentStep } from "../../mutations/useMutationUpdateCurrentStep";
import { RouteComponentProps } from "../../types";
import { useMutationSubmit } from "./useMutationSubmit";

export const RouteConsentInternational: React.FC<RouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const [agreement, setAgreement] = useState(false);
  const queryEnterprise = useEnterpriseQuery();
  const [updateCurrentStep] = useMutationUpdateCurrentStep();
  const [submit, submitMeta] = useMutationSubmit({
    onSuccess: () => {
      updateCurrentStep({ newStep: 5 });
      onNext();
    }
  });

  const customerEntity = useQueryCustomerEntity();

  if (customerEntity.isLoading || queryEnterprise.isLoading) {
    return (
      <LayoutPaymentsSetup
        onBack={onBack}
        title="Certify Your Tax Information Is Accurate"
      >
        <WSLoader.Spinner />
      </LayoutPaymentsSetup>
    );
  }

  return (
    <LayoutPaymentsSetup
      onBack={onBack}
      title="Certify Your Tax Information Is Accurate"
    >
      {customerEntity.data?.country === "CA" ? <W8Canada /> : <W8Other />}

      <WSCheckboxToggle
        my="3XL"
        name="agreement"
        label="By providing the information on this form, I hereby certify, to the
        best of my knowledge, that the information provided is complete and
        correct."
        value={agreement}
        onChange={value => {
          setAgreement(value);
        }}
      />

      <WSButton
        disabled={!agreement}
        mb="M"
        onClick={submit}
        fullWidth
        name="next"
        loading={submitMeta.isLoading}
      >
        Accept payments
        {queryEnterprise.data
          ? ` from ${selectorEnterpriseName(queryEnterprise.data)}`
          : ""}
      </WSButton>

      <WSText.ParagraphXs color="gray600" mb="M">
        We work with Stripe to power credit payments. By continuing, you agree
        to{" "}
        <a
          href={WS_LINKS.stripeConnectedTerms}
          target="_blank"
          rel="noreferrer"
        >
          Stripe Connected Account Agreement
        </a>
        .
      </WSText.ParagraphXs>

      <WSText.ParagraphXs color="gray600">
        Wingspan is a financial services technology company and is not a bank.
        Banking services provided by Lead Bank, Member FDIC.
      </WSText.ParagraphXs>
    </LayoutPaymentsSetup>
  );
};

const W8Canada = () => (
  <>
    <WSText mb="M">By submitting this form, I certify that:</WSText>
    <TextBulleted mb="M">
      <li>
        The information provided on this form is true, correct, and complete to
        the best of my knowledge and belief;
      </li>
      <li>
        The number provided on this form is my correct Canadian Social Insurance
        Number (SIN) as an individual, or Canadian Business Number (BN) as a
        business entity;
      </li>
      <li>I am a Canadian resident for tax purposes;</li>
      <li>
        I am the beneficial owner (or am authorized to sign for the beneficial
        owner) of all the income to which this form relates;
      </li>
      <li>
        The income to which this form relates is business or professional income
        earned as an independent contractor, not as an employee;
      </li>
      <li>
        I understand that this information may be shared with the Canada Revenue
        Agency (CRA) and/or the Internal Revenue Service (IRS) for tax reporting
        and verification purposes;
      </li>
      <li>
        I agree to notify the payer immediately of any changes that would render
        the information on this form incorrect;
      </li>
      <li>
        I understand that providing false or misleading information may result
        in penalties under Canadian and/or U.S. tax laws;
      </li>
      <li>
        I confirm that I have the authority to sign this form either as the
        individual contractor or on behalf of the business entity.
      </li>
    </TextBulleted>
  </>
);

const W8Other = () => (
  <>
    <WSText mb="M">
      By using Wingspan’s services you agree that the information provided for
      Form W-8 BEN is accurate and that you certify the provided information as
      follows:
    </WSText>
    <WSText mb="M">“I certify, under penalty of perjury that:</WSText>
    <TextBulleted mb="M">
      <li>
        I am the individual that is the beneficial owner of all the income or
        proceeds to which this form relates or am using this form to document
        myself for chapter 4 purposes;
      </li>
      <li>The person named on this form is not a U.S. person;</li>
      <li>
        This form relates to:
        <ul>
          <li>
            (a) income not effectively connected with the conduct of a trade or
            business in the United States;
          </li>
          <li>
            (b) income effectively connected with the conduct of a trade or
            business in the United States but is not subject to tax under an
            applicable income tax treaty;
          </li>
          <li>
            (c) the partner's share of a partnership's effectively onnected
            taxable income;
          </li>
          <li>
            or (d) the partner's amount realized from the transfer of a
            partnership interest subject to withholding under section 1446(f);
          </li>
        </ul>
      </li>
      <li>
        The person named on this form is a resident of the treaty country listed
        on the form (if any) within the meaning of the income tax treaty between
        the United States and that country.”
      </li>
    </TextBulleted>
  </>
);
