import { IWebflowBenefitCategory } from "@wingspanhq/cms/dist/lib/interfaces/webflow/benefits";
import {
  useIsMobile,
  WSElement,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import { ISubscription } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { Tabs } from "../../components/Tabs";
import { useCMSWebflowBenefits } from "../../query/cms/queries";
import { useUserId } from "../../query/hooks/helpers";
import { useSubscriptionV3Query } from "../../query/subscriptions/queries";
import { WSQueries } from "../../query/WSQuery";
import { isPremiumSubscription } from "../../Settings/utils/subscriptionUtils";
import {
  isBenefitHidden,
  isBenefitIncludedInSubscriptionLevel,
  sortCMSBenefits
} from "../utils";
import styles from "./Benefits.module.scss";
import { BenefitsCMSBlock } from "./BenefitsCMSBlock";

export const AllMyBenefits: React.FC<RouteComponentProps> = ({ history }) => {
  useBrowserPageTitle("Benefits - All Premium Perks");
  const isMobile = useIsMobile();
  const userId = useUserId();
  const qSubscription = useSubscriptionV3Query(userId);
  const qCMSWebflowBenefits = useCMSWebflowBenefits();

  return (
    <WSPage
      title="All Premium Perks"
      breadcrumb={{
        label: "Back",
        onClick() {
          history.push("/member/benefits/dashboard");
        },
        icon: "arrow-left"
      }}
    >
      <WSQueries
        queries={{
          qCMSWebflowBenefits
        }}
        renderLoader={() => <WSPage />}
      >
        {({ qCMSWebflowBenefits }) => {
          const cmsBenefits = qCMSWebflowBenefits.data.filter(
            benefit =>
              !isBenefitHidden(benefit) &&
              isBenefitIncludedInSubscriptionLevel(benefit, qSubscription.data)
          );
          const isPremium = isPremiumSubscription(
            qSubscription.data as ISubscription
          );
          return (
            <>
              {history.location.pathname.includes(
                "legal-and-financial-support"
              ) ? (
                <WSText my="2XL" className={styles.text}>
                  Through Wingspan, you can get help with basic legal matters
                  and work with a financial wellness coach to guide you through
                  life’s toughest logistics.
                </WSText>
              ) : history.location.pathname.includes(
                  "mind-and-body-support"
                ) ? (
                <WSText my="2XL" className={styles.text}>
                  Get discounts on alternative medicine and chiropractic through
                  Wingspan.
                  <b>
                    Keep an eye out for Therapy Benefits as well, coming soon!
                  </b>
                </WSText>
              ) : history.location.pathname.includes(
                  "save-money-on-medical-bills"
                ) ? (
                <WSText my="2XL" className={styles.text}>
                  Skip the doctor and get free 24/7 access to virtual care, 1:1
                  support negotiating insurance bills, savings on prescriptions,
                  and more included in your Wingspan Membership.
                </WSText>
              ) : null}

              <Tabs
                tabs={[
                  {
                    path:
                      "/member/benefits/my-benefits/all/legal-and-financial-support",
                    label: isMobile
                      ? "Legal & Financial"
                      : "Legal and financial support"
                  },
                  {
                    path:
                      "/member/benefits/my-benefits/all/mind-and-body-support",
                    label: isMobile ? "Mind & Body" : "Mind and body support"
                  },
                  {
                    path:
                      "/member/benefits/my-benefits/all/save-money-on-medical-bills",
                    label: isMobile
                      ? "Medical Bill Savings"
                      : "Save money on medical bills"
                  }
                ]}
              />
              <Switch>
                <Route
                  path="/member/benefits/my-benefits/all/legal-and-financial-support"
                  exact
                  render={() => (
                    <WSElement>
                      {cmsBenefits
                        ?.filter(benefit =>
                          [
                            IWebflowBenefitCategory.Finances,
                            IWebflowBenefitCategory.Legal
                          ].includes(benefit.category)
                        )
                        .filter(benefit =>
                          isBenefitIncludedInSubscriptionLevel(
                            benefit,
                            qSubscription.data
                          )
                        )
                        .sort((a, b) => sortCMSBenefits(a, b))
                        .map(benefit => (
                          <BenefitsCMSBlock
                            benefit={benefit}
                            subscription={qSubscription.data}
                            parentPath="my-benefits/all"
                          />
                        ))}
                    </WSElement>
                  )}
                />
                <Route
                  path="/member/benefits/my-benefits/all/mind-and-body-support"
                  exact
                  render={() => (
                    <WSElement>
                      {cmsBenefits
                        ?.filter(benefit =>
                          [
                            IWebflowBenefitCategory.Wellness,
                            IWebflowBenefitCategory.Other
                          ].includes(benefit.category)
                        )
                        .filter(benefit =>
                          isBenefitIncludedInSubscriptionLevel(
                            benefit,
                            qSubscription.data
                          )
                        )
                        .sort((a, b) => sortCMSBenefits(a, b))
                        .map(benefit => (
                          <BenefitsCMSBlock
                            benefit={benefit}
                            subscription={qSubscription.data}
                            parentPath="my-benefits/all"
                          />
                        ))}
                    </WSElement>
                  )}
                />
                <Route
                  path="/member/benefits/my-benefits/all/save-money-on-medical-bills"
                  exact
                  render={() => (
                    <WSElement>
                      {cmsBenefits
                        ?.filter(benefit =>
                          [IWebflowBenefitCategory.Health].includes(
                            benefit.category
                          )
                        )
                        .filter(benefit =>
                          isBenefitIncludedInSubscriptionLevel(
                            benefit,
                            qSubscription.data
                          )
                        )
                        .sort((a, b) => sortCMSBenefits(a, b))
                        .map(benefit => (
                          <BenefitsCMSBlock
                            benefit={benefit}
                            subscription={qSubscription.data}
                            parentPath="my-benefits/all"
                          />
                        ))}
                    </WSElement>
                  )}
                />

                <Redirect
                  from="*"
                  to="/member/benefits/my-benefits/all/legal-and-financial-support"
                />
              </Switch>
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
