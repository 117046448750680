export enum CreateRequirementDefinitionSharedFileMimeType {
  All = "all",
  Image = "image",
  Document = "document",
  Other = "other"
}

export type CreateRequirementDefinitionSharedFilePartialForm = {
  instructions: string;
  mimeTypes: Partial<
    Record<CreateRequirementDefinitionSharedFileMimeType, boolean>
  >;
};
