import { IMemberClientForm1099Balances } from "@wingspanhq/payments/dist/interfaces";

import { selectorIsForm1099BalanceSubmitted } from "../shared/selectors/selectorIsForm1099BalanceSubmitted";

const getLatest1099NECIndex = (
  form1099Balance: IMemberClientForm1099Balances
) => {
  const isFormSubmitted = selectorIsForm1099BalanceSubmitted(form1099Balance);
  const submissions = form1099Balance?.taxForm?.submissions;
  if (!isFormSubmitted || !submissions) return undefined;

  return submissions.length - 1;
};

export default getLatest1099NECIndex;
