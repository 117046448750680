import {
  useModalContext,
  WSButton,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import { IAccount } from "@wingspanhq/users";
import React, { useState } from "react";
import { BankAccountSelect } from "../BankAccountSelect/BankAccountSelect";
import styles from "./BankAcocuntSelectModal.module.scss";

export const BANK_ACCOUNT_SELECT_MODAL_KEY = "bankAccountSelect";

export type BankAccountSelectoModalProps = {
  accounts: IAccount[];
  title: string;
  onSelect: (value: string) => Promise<void> | void;
};

const Inner: React.FC<BankAccountSelectoModalProps> = ({
  accounts,
  title,
  onSelect
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [accountId, setAccountId] = useState(
    (accounts.length > 0 && accounts[0].accountId) || ""
  );
  const { closeModal } = useModalContext();

  return (
    <>
      <WSText.Heading5 mb="M">{title}</WSText.Heading5>

      <BankAccountSelect
        value={accountId}
        onChange={setAccountId}
        accounts={accounts}
        mb="3XL"
      />

      <WSButton
        fullWidth
        loading={isLoading}
        onClick={async () => {
          setIsLoading(true);
          try {
            await onSelect(accountId);
            closeModal(BANK_ACCOUNT_SELECT_MODAL_KEY);
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        }}
        name="selectAccount"
      >
        Select account
      </WSButton>
    </>
  );
};

export const BankAcocuntSelectModal: React.FC = () => {
  return (
    <WSModal
      size="S"
      name={BANK_ACCOUNT_SELECT_MODAL_KEY}
      className={styles.modal}
    >
      {(props: BankAccountSelectoModalProps) => <Inner {...props} />}
    </WSModal>
  );
};
