import { PayeeCorrectionReason } from "@wingspanhq/payments/dist/interfaces/taxForm";

export const CORRECTION_AMOUNT = "CORRECTION_AMOUNT";
export const CORRECTION_TIN_OR_NAME = "CORRECTION_TIN_OR_NAME";
export const CORRECTION_ADDRESS = "CORRECTION_ADDRESS";

export const CORRECTION_REASON_OPTIONS = [
  {
    label: "Mistake",
    value: PayeeCorrectionReason.Mistake
  },
  {
    label: "Legal Name Changes",
    value: PayeeCorrectionReason.LegalNameChange
  },
  {
    label: "Business Restructuring",
    value: PayeeCorrectionReason.BusinessRestructuring
  },
  {
    label: "Other",
    value: PayeeCorrectionReason.Other
  }
];

export const correctionReasonMap = {
  [PayeeCorrectionReason.Mistake]:
    "A mistake was made when providing the TIN, name or federal tax classification",
  [PayeeCorrectionReason.LegalNameChange]:
    "Personal events such as marriage or divorce that require updates for legal consistency",
  [PayeeCorrectionReason.BusinessRestructuring]:
    "Changes to the business form, such as a sole proprietorship becoming an LLC or corporation, necessitating a new TIN",
  [PayeeCorrectionReason.Other]: "• Please describe (free text)"
};
