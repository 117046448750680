import {
  useWSModal,
  WSButton,
  WSButtons,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { TaxFormDeliveryMethod } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { IAddress } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { useTaxFormResendEmail } from "../../../../../query/taxForm/mutations/useTaxFormResendEmail";
import { AddressText } from "../../../../../shared/components/AddressText/AddressText";
import {
  FormDataAddress,
  formDataToRequestAddress,
  FormPartialAddress,
  selectorAddressDefaultValues,
  validationSchemaAddress
} from "../../../../../shared/components/FormPartialAddress";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";

const ResendMailCopy: React.FC<{
  taxForm: IPayeeTaxFormResponse;
  onClose(result: boolean): void;
}> = ({ onClose, taxForm }) => {
  const [resendCopy, resendCopyMeta] = useTaxFormResendEmail();
  const [isEdit, setIsEdit] = React.useState(false);
  const [address, setAddress] = React.useState<FormDataAddress>(
    selectorAddressDefaultValues(taxForm.data?.w9Info as IAddress)
  );

  if (isEdit) {
    return (
      <WSElement>
        <WSText.Heading3 mb="M">Edit address</WSText.Heading3>

        <WSText color="gray600">
          Edit the address to re-mail the recipient’s 1099-NEC tax forms:
        </WSText>

        <WSText.Heading4 mt="M">Recipient’s business address</WSText.Heading4>

        <WSFormOld<{ address: FormDataAddress }>
          defaultValues={{ address }}
          validationSchema={Yup.object().shape({
            address: validationSchemaAddress
          })}
          onSubmit={values => {
            setAddress(values.address);
            setIsEdit(false);
          }}
        >
          <FormPartialAddress name="address" />
          <WSText.ParagraphXs color="gray500" mb="M" mt="XS">
            The recipient’s form 1099 will be mailed to this address.
          </WSText.ParagraphXs>
          <WSButtons forceFullWidth>
            <WSFormOld.SubmitButton mt="M" fullWidth>
              Save changes
            </WSFormOld.SubmitButton>
            <WSButton.Secondary
              onClick={() => {
                setIsEdit(false);
              }}
            >
              Cancel
            </WSButton.Secondary>
          </WSButtons>
        </WSFormOld>
      </WSElement>
    );
  } else {
    return (
      <WSElement>
        <WSText.Heading3 mb="M">Re-mail tax forms</WSText.Heading3>

        <WSText color="gray600">
          Please confirm that you want to re-mail the recipient’s 1099-NEC tax
          forms to the following address:
        </WSText>

        <WSText.Heading4 mt="M">Business name</WSText.Heading4>
        <WSDivider my="M" />
        <WSText.ParagraphSm color="gray500">
          {selectorTaxFormRecipientName(taxForm)}
        </WSText.ParagraphSm>

        <WSFlexBox.CenterY justify="space-between" mt="S">
          <WSText.Heading4 mt="M">Address</WSText.Heading4>
          <WSButton.Link
            onClick={() => {
              setIsEdit(true);
            }}
          >
            Edit
          </WSButton.Link>
        </WSFlexBox.CenterY>

        <WSDivider my="S" />
        <WSText.ParagraphSm color="gray500">
          <AddressText placeholder="-" {...formDataToRequestAddress(address)} />
        </WSText.ParagraphSm>

        <WSErrorMessage
          mt="S"
          error={resendCopyMeta.error}
          contextKey="Other"
        />

        <WSButton.Primary
          mt="M"
          fullWidth
          loading={resendCopyMeta.isLoading}
          onClick={() => {
            resendCopy(
              {
                taxFormId: taxForm.taxFormId,
                deliveryMethod: TaxFormDeliveryMethod.Mail,
                // TODO: fix interface
                address: formDataToRequestAddress(address) as any
              },
              {
                onSuccess() {
                  onClose?.(true);
                }
              }
            );
          }}
        >
          Confirm and send
        </WSButton.Primary>
      </WSElement>
    );
  }
};

export const useModalResendMailCopy = () =>
  useWSModal(ResendMailCopy, {
    size: "S"
  });
