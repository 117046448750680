import classNames from "classnames";
import React from "react";
import { WSAlert, WSAlertProps } from "../WSAlert/WSAlert";
import { WSDivider } from "../WSDivider/WSDivider";
import { WSElement } from "../WSElement/WSElement.component";
import { Label, WSDataItemLabelProps } from "./Label";
import styles from "./styles.module.scss";
import { Value, WSDataItemValueProps } from "./Value";

const DEFAULT_ALERT_SIZE = "S";

export type WSDataItemProps = {
  className?: string;
  inline?: boolean;
  divider?: boolean;
  alert?: WSAlertProps;
} & WSDataItemLabelProps &
  WSDataItemValueProps;

export const WSDataItem: React.FC<WSDataItemProps> = (props) => {
  return (
    <WSElement className={props.className}>
      <WSElement
        className={classNames(
          styles.dataItem,
          props.inline && styles.inline,
          props.divider && styles.divider
        )}
      >
        <Label
          label={props.label}
          labelAvatar={props.labelAvatar}
          action={props.action}
          helperText={props.helperText}
          tooltip={props.tooltip}
        />

        {props.divider && !props.inline && <WSDivider />}

        <Value
          value={props.value}
          formatterOptions={props.formatterOptions}
          pill={props.pill}
          avatar={props.avatar}
          icon={props.icon}
          copy={props.copy}
        />
      </WSElement>

      {props.alert && (
        <WSAlert
          size={DEFAULT_ALERT_SIZE}
          className={styles.alert}
          {...props.alert}
        />
      )}
    </WSElement>
  );
};
