import {
  useIsMobile,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { IBenefitFaq } from "../../screens/BenefitsV3";

export interface BenefitDetailsFaqsProps {
  faqs: Array<IBenefitFaq>;
}

export const BenefitDetailsFaqs: React.FC<BenefitDetailsFaqsProps> = ({
  faqs
}) => {
  const isMobile = useIsMobile();
  const [isExpanded, setIsExpanded] = useState(isMobile);
  return faqs.length > 0 ? (
    <WSFlexBox direction="column">
      <WSFlexBox.Center onClick={() => setIsExpanded(!isExpanded)} mb="M">
        <WSText.Heading4>FAQs</WSText.Heading4>
        <WSIcon
          block
          color="blue400"
          size="XS"
          ml="M"
          name={isExpanded ? "chevron-up" : "chevron-down"}
        />
      </WSFlexBox.Center>
      {isExpanded && (
        <WSFlexBox direction="column">
          {faqs.map((faq: IBenefitFaq) => (
            <WSFlexBox direction="column" mb="2XL">
              <WSText.ParagraphSm weight="medium" mb="XS">
                {faq.question}
              </WSText.ParagraphSm>
              <WSText>{faq.answer}</WSText>
            </WSFlexBox>
          ))}
        </WSFlexBox>
      )}
    </WSFlexBox>
  ) : null;
};
