import { toWSDateString } from "../../../../utils";
import {
  WSDateRange,
  WSFilter,
  WSFilterDateRangeOption,
  WSFilterDateRangeValue,
  WSFilterRadio,
  WSFilterValues
} from "../types";

export const dateRangeLabelMap: Record<WSFilterDateRangeOption, string> = {
  all: "All",
  pastWeek: "Past week",
  pastMonth: "Past month",
  yearToDate: "Year to date",
  custom: "Custom"
};

const getPastWeekRange = (): WSDateRange => {
  const start = new Date();
  start.setDate(start.getDate() - 7);
  start.setHours(0, 0, 0, 0);

  return { start };
};

const getPastMonthRange = (): WSDateRange => {
  const start = new Date();
  start.setMonth(start.getMonth() - 1);
  start.setHours(0, 0, 0, 0);

  return { start };
};

const getYearToDateRange = (): WSDateRange => {
  const start = new Date();
  start.setMonth(0);
  start.setDate(1);
  start.setHours(0, 0, 0, 0);

  return { start };
};

export const convertDateRangeToDates = (
  dateRange?: WSFilterDateRangeValue
): WSDateRange | undefined => {
  if (dateRange === undefined) {
    return undefined;
  }

  if (typeof dateRange === "object") {
    const start = dateRange.start ? new Date(dateRange.start) : undefined;
    const end = dateRange.end ? new Date(dateRange.end) : undefined;

    if (end) {
      end.setHours(23, 59, 59, 999);
    }

    return {
      start: start,
      end: end
    };
  }

  switch (dateRange) {
    case "pastWeek":
      return getPastWeekRange();
    case "pastMonth":
      return getPastMonthRange();
    case "yearToDate":
      return getYearToDateRange();
    default:
      return undefined;
  }
};

export const createDateFiltersButtonText = (
  dateTypeName: string,
  dateRangeName: string
) => (values: WSFilterValues, filters: WSFilter[]) => {
  // Date type
  const dateTypeValue = values[dateTypeName];

  const dateTypeFilter = filters.find(
    (filter) => filter.name === dateTypeName
  ) as WSFilterRadio | undefined;

  const dateTypeOption = dateTypeValue
    ? dateTypeFilter?.options.find((option) => option.name === dateTypeValue)
    : dateTypeFilter?.options[0];

  const dateTypeLabel = dateTypeOption ? dateTypeOption.label : "Date";

  // Date range
  const dateRange: WSFilterDateRangeValue = values[
    dateRangeName
  ] as WSFilterDateRangeValue;

  const dateRangeLabel = getDateRangeLabel(dateRange);

  return `${dateTypeLabel}: ${dateRangeLabel}`;
};

export const getDateRangeLabel = (dateRange?: WSFilterDateRangeValue) => {
  if (dateRange === undefined) {
    return "All";
  }

  if (typeof dateRange === "object") {
    if (dateRange.start && !dateRange.end) {
      return `from ${toWSDateString(dateRange.start, "monthDate")}`;
    }

    if (!dateRange.start && dateRange.end) {
      return `to ${toWSDateString(dateRange.end, "monthDate")}`;
    }

    return `${toWSDateString(dateRange.start, "monthDate")} - ${toWSDateString(
      dateRange.end,
      "monthDate"
    )}`;
  }

  return dateRangeLabelMap[dateRange];
};
