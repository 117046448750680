import {
  WSPill,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import { ICreditCard } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { WSAccountsListV2Props } from "./WSAccountsListV2.component";
import styles from "./WSAccountsListV2.module.scss";
import { PaymentMethodType } from "./types";

export interface CreditCardTemplateProps
  extends Partial<WSAccountsListV2Props> {
  creditCard: ICreditCard;
  customEdit?: (card: ICreditCard) => React.ReactNode;
}

export const CreditCardTemplate: React.FC<CreditCardTemplateProps> = ({
  defaultPaymentMethod,
  creditCard,
  onEditAccount,
  customEdit
}) => {
  return (
    <WSElement data-testid={`account-block-${creditCard.last4}`}>
      <WSFlexBox.CenterY
        ml="3XL"
        justify="space-between"
        wrap="nowrap"
        className={styles.account}
      >
        <WSText.ParagraphSm>• • • • {creditCard.last4}</WSText.ParagraphSm>
        <WSFlexBox.CenterY wrap="nowrap">
          {defaultPaymentMethod?.paymentMethodId ===
            creditCard.paymentMethodId && <WSPill mx="M" text="Default" />}
          {customEdit?.(creditCard)}
          {onEditAccount && (
            <WSIcon
              block
              mx="M"
              size="S"
              color="blue400"
              name="edit"
              onClick={() =>
                onEditAccount(creditCard, PaymentMethodType.CREDIT_CARD)
              }
            />
          )}
        </WSFlexBox.CenterY>
      </WSFlexBox.CenterY>
      <WSDivider />
    </WSElement>
  );
};
