import { useUserId } from "../../query/hooks/helpers";
import { useUserProfile } from "../../query/users/queries";
import { selectorIsEngagementsActive } from "../selectors/selectorIsEngagementsActive";

export const useIsEngagementsActive = () => {
  const userId = useUserId();
  const userProfile = useUserProfile(userId, {
    refetchOnMount: false
  });

  return userProfile.data
    ? selectorIsEngagementsActive(userProfile.data)
    : false;
};
