import { WSPill, WSTableCell } from "@wingspanhq/fe-component-library";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { BulkResource } from "../../types";

export function BulkBatchImportStatus({
  batchId,
  bulkResource
}: {
  batchId: string;
  bulkResource: BulkResource;
}) {
  const { useBulkBatch, useBulkBatchSummary } = getBulkQueriesByResource(
    bulkResource
  );
  const qBulkBatch = useBulkBatch(batchId);
  const status = qBulkBatch.data?.status;

  const qBulkBatchItems = useBulkBatchSummary(batchId, undefined, {
    enabled: status === BulkStatus.Open
  });
  const isLoading =
    qBulkBatch.isFetching ||
    qBulkBatch.isLoading ||
    qBulkBatchItems.isLoading ||
    qBulkBatchItems.isFetching;

  switch (status) {
    case BulkStatus.Open:
      return (
        <WSTableCell
          // shimmer={isLoading}
          pill={{
            text:
              qBulkBatchItems.data && qBulkBatchItems.data?.listSize > 0
                ? "Incomplete"
                : "Empty"
          }}
        />
      );

    case BulkStatus.Pending:
    case BulkStatus.Processing:
      return (
        <WSTableCell
          // shimmer={isLoading}
          pill={{
            theme: "blue",
            text: "Importing"
          }}
        />
      );

    case BulkStatus.Failed:
      return (
        <WSTableCell
          // shimmer={isLoading}
          pill={{
            theme: "warning",
            icon: true,
            text: "Needs Action"
          }}
        />
      );

    case BulkStatus.Complete:
      return (
        <WSTableCell
          // shimmer={isLoading}
          pill={{
            theme: "success",
            text: "Complete"
          }}
        />
      );

    default:
      return null;
  }
}
