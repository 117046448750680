(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("react-hook-form"), require("react-responsive"));
	else if(typeof define === 'function' && define.amd)
		define("fe-component-library", ["react", "react-dom", "react-hook-form", "react-responsive"], factory);
	else if(typeof exports === 'object')
		exports["fe-component-library"] = factory(require("react"), require("react-dom"), require("react-hook-form"), require("react-responsive"));
	else
		root["fe-component-library"] = factory(root["react"], root["react-dom"], root["react-hook-form"], root["react-responsive"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__7111__, __WEBPACK_EXTERNAL_MODULE__5369__, __WEBPACK_EXTERNAL_MODULE__4467__) {
return 