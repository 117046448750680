import {
  BackgroundChecksPackageStatus,
  IBackgroundChecksPackageResponse
} from "@wingspanhq/background-checks/dist/lib/interfaces";

export const selectorBackgroundChecksActivePackages = (
  packages: IBackgroundChecksPackageResponse[]
): IBackgroundChecksPackageResponse[] => {
  return packages.filter(
    p => p.status === BackgroundChecksPackageStatus.Active
  );
};
