import { IErrorResponse } from "@wingspanhq/routing/dist/lib/interfaces/error";
import { SessionType } from "@wingspanhq/users/dist/lib/interfaces";
import Axios, { AxiosError } from "axios";
import { addMinutes } from "date-fns";
import isString from "lodash/isString";
import times from "lodash/times";
import qs from "qs";
import { pullSessionToken, pushSessionToken } from "../services/sessionStorage";
import { wsEvents } from "./WSEvents";
import { getChangedData } from "./getChangedData";
import { parseJwt } from "./jwt";

const requestOtp = async () => {
  return new Promise<void>((resolve, reject) => {
    wsEvents.dispatch(wsEvents.list.OTP_REQUEST);

    const resolveHandler = () => {
      resolve();
      wsEvents.removeListener(wsEvents.list.OTP_COMPLETE, resolveHandler);
      wsEvents.removeListener(wsEvents.list.OTP_FAIL, rejectHandler);
    };

    const rejectHandler = () => {
      reject();
      wsEvents.removeListener(wsEvents.list.OTP_COMPLETE, resolveHandler);
      wsEvents.removeListener(wsEvents.list.OTP_FAIL, rejectHandler);
    };

    wsEvents.addListener(wsEvents.list.OTP_COMPLETE, resolveHandler);
    wsEvents.addListener(wsEvents.list.OTP_FAIL, rejectHandler);
  });
};

const forceLogout = async () => {
  wsEvents.dispatch(wsEvents.list.FORCE_LOGOUT);
};

const GUEST_TOKEN_EXPIRAITON_TIME_MS = 3 * 60 * 60 * 1000;

// Check out JSON.parse docs – https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse
function dateReviver(_: string, value: any) {
  if (isSerializedDate(value)) {
    return new Date(value);
  }
  return value;
}

export function isSerializedDate(value: any) {
  var datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  return isString(value) && datePattern.test(value);
}

export type ErrorTransform = {
  regex: RegExp;
  message?: string | ((regexMatches: RegExpMatchArray) => string);
};

export const deepTrim = (obj: any) => {
  // since typeof null is object
  if (obj === null) {
    return obj;
  }

  if (typeof obj === "string") {
    return obj.trim();
  }

  if (typeof obj === "object") {
    for (var key in obj) {
      obj[key] = deepTrim(obj[key]);
    }
  }
  return obj;
};

// TODO: think about separate service for sessions OR all requests which not require Authorization token
const createGuestSession = async (data: {
  captchaToken: string;
  captchaVersion: string;
}) => {
  const response = await Axios.post(
    `${process.env.REACT_APP_API_URL}/users/session`,
    data,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  return response.data;
};

export const requestFactory = (
  basePath: string,
  errorTransforms?: ErrorTransform[]
) => {
  const axios = Axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}${basePath}`,
    responseType: "text",
    transformRequest: (body: object | string, headers) => {
      if (body && body.constructor.name === "FormData") {
        headers["Content-Type"] = "multipart/form-data";
        return body;
      } else if (body && typeof body === "string") {
        return body;
      } else {
        body = deepTrim(body);
        return JSON.stringify(body);
      }
    },
    headers: {
      "Content-Type": "application/json"
    },
    timeout: 120000,
    withCredentials: true,
    paramsSerializer: {
      serialize: params => qs.stringify(params)
    },
    transformResponse: function(data) {
      let response;
      try {
        response = JSON.parse(data, dateReviver);
      } catch {
        response = data;
      }
      if (response && response.error && errorTransforms) {
        let serverError: string | undefined;
        const defaultError = "Sorry! Something went wrong... Please try again!";
        try {
          serverError = response.error;
          for (const { regex, message } of errorTransforms) {
            let matches = serverError?.match(regex);
            if (matches) {
              if (typeof message === "string") {
                serverError = message;
              } else if (typeof message === "function") {
                serverError = message(matches);
              }
              break;
            }
          }
        } finally {
          response.error = serverError || defaultError;
        }
      }
      return response;
    }
  });

  axios.interceptors.request.use(async config => {
    let authToken;

    const tokenFromStorage = pullSessionToken();

    if (tokenFromStorage && tokenFromStorage !== "undefined") {
      if (window.sessionType === SessionType.guest) {
        // If it's a guest session we need to check expiration time of token, b/c these sessions short-live (3 hrs)

        const parsedJWT = parseJwt(tokenFromStorage);

        if (parsedJWT.iat) {
          const tokenExpirationTime = new Date(
            parsedJWT.iat * 1000 + GUEST_TOKEN_EXPIRAITON_TIME_MS
          );

          if (addMinutes(tokenExpirationTime, -1) > new Date()) {
            // If token is not expired OR it's not about to expire, we can use it
            authToken = tokenFromStorage;
          }
        }

        // If token is expired, it's not in authToken variable, so it's going to be recreated below
      } else {
        authToken = tokenFromStorage;
      }
    }

    if (!authToken && window.sessionType === SessionType.guest) {
      // If there was no token in storage AND it's a guest, we can try to recreate session and token
      // To to it we need to get captcha token first

      let captchaToken: string | undefined;
      // If this fails, we should make sure that recaptcha is loaded
      try {
        captchaToken = await window.grecaptcha.execute(
          process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY || "",
          {
            action: "reauth"
          }
        );
      } catch (e) {
        console.error(
          "serviceHelper reauth failed because grecaptcha was not ready",
          e
        );
      }
      if (captchaToken) {
        // As we have captcha token, just create a session with it and set auth token in place
        const session = await createGuestSession({
          captchaToken: captchaToken,
          captchaVersion: "3"
        });
        pushSessionToken(session.token);
        window.sessionType = session.sessionType;
        authToken = session.token;
      }
    }

    // Finally, if we have auth token – we cat set it in Authorization header
    if (authToken) {
      config.headers.setAuthorization(`Bearer ${authToken}`);
    }
    // If there is no token in the end – request will fail (except requests which don't require Authorization header)
    return config;
  });

  axios.interceptors.response.use(
    response => response,
    async (error: WSServiceError) => {
      if (error.config && error.response?.data.errorSubType === "OtpRequired") {
        try {
          await requestOtp();
        } catch {
          throw error;
        }
        return await axios.request(error.config);
      } else if (
        error &&
        error.response &&
        [401, 403].includes(error.response.status) &&
        !error.config?.url?.includes("session")
      ) {
        console.error("Session expired or not provided to backend");
        // If session is expired OR authToken is not provided to backend, then force logout the user.
        try {
          // HOTFIX: forceLogout is not working properly, so we temporarily disabling it.
          // forceLogout();
        } catch {
          throw error;
        }
      }
      throw error;
    }
  );

  return axios;
};

export const addElementToArray = <T = any>(
  arrayLength: number,
  objectToAdd: T
): Array<T | {}> => [...times(arrayLength, () => ({})), objectToAdd];

export const removeElementFromArray = (
  arrayLength: number,
  indexToRemove: number
): Array<{}> =>
  times(arrayLength, (index: number) => {
    if (index === indexToRemove) return null as any;
    else return {};
  });

export interface ListRequestQuery<Filter = undefined, Sort = undefined> {
  filter?: Filter;
  sort?: Sort;
  page?: {
    size?: number;
    number: number;
  };
}

export const getAllEntries = async <R = any, F = undefined, S = undefined>(
  request: (query: ListRequestQuery<F, S>) => Promise<R[]>,
  filter?: F,
  sort?: S
) => {
  const entries: R[] = [];

  const PAGE_SIZE = 100;

  let nextPageNumber: number | null = 1;

  while (nextPageNumber) {
    const chunk = await request({
      filter,
      sort,
      page: {
        size: PAGE_SIZE,
        number: nextPageNumber
      }
    });

    chunk.forEach(entry => entries.push(entry));

    if (chunk.length === PAGE_SIZE) nextPageNumber++;
    else nextPageNumber = null;
  }

  return entries;
};

export type UpdateArray<I = any> = Array<I | null | {}>;

export function updateArray<I = any>(
  oldArray: Array<I>,
  newArray: Array<I>
): UpdateArray<I> {
  const updateArray: UpdateArray<I> = [];

  for (let index = 0; index < oldArray.length; index++) {
    if (newArray[index]) {
      const changedData = getChangedData(oldArray[index], newArray[index], {
        dismissTimeInDates: true
      });
      updateArray.push(changedData);
    } else {
      updateArray.push(null);
    }
  }

  if (newArray.length > oldArray.length) {
    for (let index = oldArray.length; index < newArray.length; index++) {
      updateArray.push(newArray[index]);
    }
  }

  return updateArray;
}

export function updateArrayString<E = string>(
  oldArray: Array<E>,
  newArray: Array<E>
): Array<E | null> {
  const updateArray: Array<E | null> = [];

  for (let index = 0; index < oldArray.length; index++) {
    if (newArray[index]) {
      updateArray.push(newArray[index]);
    } else {
      updateArray.push(null);
    }
  }

  if (newArray.length > oldArray.length) {
    for (let index = oldArray.length; index < newArray.length; index++) {
      updateArray.push(newArray[index]);
    }
  }

  return updateArray;
}

export type UnwrapService<T> = T extends () => Promise<infer U> ? U : T;

interface Action<R> {
  (): Promise<R>;
}

export const concurrentActions = async <R = any>(
  actions: Array<Action<R>>,
  options?: {
    concurrentLimit?: number;
  }
): Promise<Array<R>> => {
  const result: Array<R> = [];

  async function next(...batchActions: Array<Action<R>>) {
    await Promise.all(
      batchActions.map(async action => {
        const actionResult = await action();
        result.push(actionResult);

        const nextAction = actions.pop();

        if (nextAction) {
          await next(nextAction);
        }
      })
    );
  }

  await next(...actions.splice(0, options?.concurrentLimit || 16));

  return result;
};

export type WSServiceError = AxiosError<IErrorResponse>;

export const isSameDate = (date1: Date, date2: Date) => {
  return date1.getTime() === date2.getTime();
};

export const sleep = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));
