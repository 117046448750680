import React, { useMemo, useState } from "react";

import { useFlatfile, Space, useListener } from "@flatfile/react";

import { WSButton, WSFlexBox, WSText } from "@wingspanhq/fe-component-library";
import { hybridEncrypt } from "@wingspanhq/utils/dist/crypto";

import { WSQueries } from "../../../../query/WSQuery";
import { BulkBatchImportStatus } from "../../components/BulkBatchImportStatus";

import styles from "./styles.module.scss";
import { useFlatfile as useWSFlatfile } from "../../../../hooks/useFlatfile";
import { PartialRejection } from "@flatfile/sdk";
import { pullSessionToken } from "../../../../services/sessionStorage";
import { IS_PRODUCTION_ENV } from "../../../../shared/constants/environment";
import { processFlatfileChunk } from "../../utils/flatfileBulkCollaborator";
import {
  BulkStatus,
  CustomFieldResourceType,
  IBulkCollaboratorBatch,
  ICollaboratorGroupResponse
} from "@wingspanhq/payments/dist/interfaces";
import { flatfileConfig, flatfileTheme } from "../../utils/flatfileThemeConfig";
import useSnackbar from "../../../../hooks/useSnackbar";
import { BulkResource } from "../../types";
import { getUploadedFilename } from "../../utils/getUploadedFilename";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { useCollaboratorGroupQuery } from "../../../../query/payments/queries";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { useFlatfileErrorModal } from "../../components/FlatfileError/useFlatfileErrorModal";
import { FlatfileError } from "@flatfile/sdk/dist/errors/FlatfileError";
import { useHistory } from "react-router-dom";
import { BulkImportError } from "../../components/BulkImportError";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { themeConfig } from "../../../../shared/flatfile/themeConfig";
import FlatfileListener, { FlatfileEvent } from "@flatfile/listener";
import { queryCache } from "react-query";
import {
  QUERY_BULK_COLLABORATOR_BATCH,
  QUERY_BULK_COLLABORATOR_BATCH_SUMMARY
} from "../../query/bulkCollaborator/keys";

export const BULK_IMPORT_CONTRACTORS_NAMESPACE = "contractors";
export const ACTION_SLUG = "finalizeUpload";

interface BulkCollaboratorOpenBatchHeaderProps {
  bulkBatchId: string;
  bulkResource: BulkResource;
}

export function BulkCollaboratorOpenBatchHeader({
  bulkBatchId,
  bulkResource
}: BulkCollaboratorOpenBatchHeaderProps) {
  const { warning } = useSnackbar();
  const history = useHistory();
  const {
    useBulkBatch,
    useBulkBatchSummary,
    useUpdateBulkBatch
  } = getBulkQueriesByResource(bulkResource);

  const qBulkBatch = useBulkBatch(bulkBatchId);
  const qBulkBatchItems = useBulkBatchSummary(bulkBatchId);
  const qFeatureFlags = useFeatureFlags();

  // We will deprecate the collaboratorGroupId in the future
  const collaboratorGroupId = qBulkBatch.data?.labels
    .collaboratorGroupId as string;
  const engagementId = qBulkBatch.data?.labels.engagementId as string;

  const qCollaboratorGroup = useCollaboratorGroupQuery(
    collaboratorGroupId as string,
    {
      enabled: !!collaboratorGroupId
    }
  );
  const qCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });
  const collaboratorCustomFields = qCollaboratorCustomFields.data || [];

  const collaboratorGroup = qCollaboratorGroup.data as ICollaboratorGroupResponse;

  const [
    updateBulkCollaboratorBatch,
    updateBulkCollaboratorBatchMeta
  ] = useUpdateBulkBatch();

  // Flatfile Platform
  const { openPortal, closePortal } = useFlatfile();

  // encrypt the API token for storage in the metadata
  const publicKey = process.env.REACT_APP_ENCRYPTION_PUBLIC_KEY as string;
  const originalPublicKey = publicKey.replace(/\\n/g, "\n");

  const apiTokenEncrypted = useMemo(
    () => hybridEncrypt(originalPublicKey, pullSessionToken() as string),
    []
  );

  useListener((listener: FlatfileListener) => {
    listener.on("space:created", (event: FlatfileEvent) => {
      const { spaceId } = event.context;
      console.log("spaceId", spaceId);
    });

    listener.on(
      "job:completed",
      { job: `workbook:${ACTION_SLUG}` },
      async () => {
        closePortal();
        queryCache.invalidateQueries([
          QUERY_BULK_COLLABORATOR_BATCH,
          bulkBatchId
        ]);
        queryCache.invalidateQueries(QUERY_BULK_COLLABORATOR_BATCH_SUMMARY);
      }
    );
  });

  // Flatfile V3 error handling
  const flatfileErrorModal = useFlatfileErrorModal();

  const onFlatfileError = (error: FlatfileError) => {
    flatfileErrorModal.open({
      error,

      batchId: bulkBatchId,
      bulkResource,
      onStartNewImport: bulkBatch => {
        history.push(
          `/member/imports/collaborators/${
            (bulkBatch as IBulkCollaboratorBatch).bulkCollaboratorBatchId
          }`
        );
      }
    });
  };

  // Flatfile V3 initialization

  const EMBED_ID = process.env
    .REACT_APP_FLATFILE_CONTACTS_COLLABORATORS_EMBED_ID as string;

  const flatfile = useWSFlatfile({
    embedId: EMBED_ID,
    onError: onFlatfileError
  });

  const importDataV3 = async () => {
    flatfile.requestDataFromUser({
      ...flatfileConfig,
      theme: {
        ...flatfileTheme
      },
      onInit: ({ session }) => {
        session.updateEnvironment({
          // TODO: Pass selectedEngagementName to show in validation errors
          selectedEngagementId: engagementId,
          selectedCollaboratorGroupId: collaboratorGroup?.collaboratorGroupId,
          selectedCollaboratorGroupName: collaboratorGroup?.name,
          isProdEnv: IS_PRODUCTION_ENV,
          authToken: pullSessionToken(),
          bulkBatchId
        });

        session.on("close", () => {
          // Just refetch the batch items query to update the batch details view
          // so that user won't upload the spreadsheet again in the same batch.
          qBulkBatchItems.refetch();
        });
      },
      customFields: collaboratorCustomFields.map(({ key, name }) => {
        return {
          field: key,
          label: name,
          // FIX: boolean fields are not supported in Flatfile
          type: "string"
        };
      }),
      /**
       * Flatfile streams the uploaded spreadsheet data as chunks of size 1000 (default) to onData
       * callback function where we can process the spreadsheet data (like making an external API call)
       * and reject any spreadsheet row(s) if there are any failures during processing.
       */
      onData: async (chunk, next) => {
        const rejections = await processFlatfileChunk(
          chunk,
          bulkBatchId,
          { engagementId, collaboratorGroupId },
          collaboratorCustomFields
        );
        next(new PartialRejection(rejections));
      },
      onComplete: async payload => {
        const csvRecords = await payload.data();
        const csvCollaborators = csvRecords.rows
          .filter(row => row.valid)
          .map(row => row.data);

        // ------------- Flatfile's Duplicate Row issue Debug Statements ----------------------
        console.group("Bulk Import Collaborators");
        console.log("CSV Line items", csvCollaborators);
        console.groupEnd();
        // ------------- Flatfile's Duplicate Row issue Debug Statements ----------------------

        if (csvCollaborators.length === 0) {
          warning("Sorry, Looks like the uploaded spreadsheet is empty!");
          return;
        }
        await updateBulkCollaboratorBatch({
          id: bulkBatchId,
          data: {
            labels: {
              flatfileBatchId: payload.batchId
            }
          }
        });
      }
    });
  };

  // status: Open & batch is empty

  const uploadSpreadsheet = () => {
    if (qFeatureFlags.data?.flatfilePlatform) {
      openPortal();
    } else {
      importDataV3();
    }
  };

  // status: Open & batch has some batch items

  const startImport = async () => {
    await updateBulkCollaboratorBatch({
      id: bulkBatchId,
      data: {
        status: BulkStatus.Pending
      }
    });
  };

  return (
    <WSQueries queries={{ qBulkBatch, qBulkBatchItems, qFeatureFlags }}>
      {({ qBulkBatchData: bulkBatch, qFeatureFlagsData: featureFlags }) => {
        const isEmptyBatch =
          qBulkBatchItems.data && qBulkBatchItems.data.listSize === 0;
        const filename = getUploadedFilename(
          bulkBatch,
          BulkResource.Collaborator,
          { length: 35 }
        );
        return (
          <>
            {featureFlags.flatfilePlatform && (
              <Space
                config={{
                  name: "Bulk Import Contractors",
                  // custom name/type for the space
                  namespace: BULK_IMPORT_CONTRACTORS_NAMESPACE,

                  // required to trigger job:configure
                  autoConfigure: true,
                  // set batch item Id
                  // set api token to use it on listener
                  metadata: {
                    // custom data
                    apiToken: apiTokenEncrypted,
                    bulkBatchId,
                    selectedCollaboratorGroupId:
                      collaboratorGroup?.collaboratorGroupId,
                    selectedCollaboratorGroupName: collaboratorGroup?.name,

                    // part of configuration
                    sidebarConfig: {
                      showSidebar: true
                    },
                    ...themeConfig
                  }
                }}
              />
            )}
            <WSFlexBox direction="column">
              <WSFlexBox.CenterY
                className={styles.header}
                justify="space-between"
                mb="XL"
              >
                <WSText
                  weight="medium"
                  data-testid="bulkBatchFilename"
                  shimmer={
                    qBulkBatchItems.isLoading ||
                    updateBulkCollaboratorBatchMeta.isLoading
                  }
                  title={getUploadedFilename(
                    bulkBatch,
                    BulkResource.Collaborator
                  )}
                >
                  {filename ||
                    (isEmptyBatch
                      ? "File not uploaded"
                      : "Unnamed Payable Batch")}
                </WSText>
                <BulkBatchImportStatus
                  batchId={bulkBatchId}
                  bulkResource={bulkResource}
                />
              </WSFlexBox.CenterY>

              <WSText.ParagraphSm color="gray500" mb="XL">
                {isEmptyBatch
                  ? "This import is empty. You can upload a spreadsheet of contractors to continue, or delete the import."
                  : "A spreadsheet is uploaded and ready to be imported as contractors."}
              </WSText.ParagraphSm>

              {isEmptyBatch ? (
                <WSButton
                  shimmer={qBulkBatchItems.isLoading}
                  name="uploadSpreadsheet"
                  icon="upload"
                  fullWidth
                  onClick={uploadSpreadsheet}
                >
                  Upload spreadsheet
                </WSButton>
              ) : (
                <WSButton
                  name="initImportCollaborators"
                  icon="details"
                  fullWidth
                  onClick={startImport}
                  loading={updateBulkCollaboratorBatchMeta.isLoading}
                >
                  Import contractors
                </WSButton>
              )}

              {isEmptyBatch ? null : (
                <BulkImportError
                  mt="XL"
                  bulkResource={bulkResource}
                  batchId={bulkBatchId}
                />
              )}
            </WSFlexBox>
          </>
        );
      }}
    </WSQueries>
  );
}
