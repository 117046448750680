import {
  useIsMobile,
  WSButton,
  WSButtons,
  WSContainer,
  WSFlexBox,
  WSIcon,
  WSLayout,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useEffect } from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { Header } from "../../Auth/components/Header/Header";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { useSetWSStore } from "../../store";
import { useAnonymousFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { useCMSWebflowPartner } from "../../query/cms/queries";
import styles from "./styles.module.scss";

export interface VerifyPhoneNumberExistingAccountProps {}

export const VerifyPhoneNumberExistingAccount: React.FC<VerifyPhoneNumberExistingAccountProps> = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const location = useLocation();
  const setStore = useSetWSStore();

  const queries = queryString.parse(location.search);
  const partnerId = queries.partner as string;
  const qAnonymousFeatureFlags = useAnonymousFeatureFlags();
  const qPartner = useCMSWebflowPartner(partnerId, {
    enabled: partnerId,
    onSuccess(partner) {
      setStore({ signUpType: partner.signUpType });
      setStore({ discountCode: partner.stripeDiscountCode });
    }
  });

  useEffect(() => {
    if (!(location.state as any)?.message) {
      history.replace("/member/verify/phone-number");
    }
  }, [location]);

  const partner = qPartner.data;

  return (
    <WSLayout
      line
      onBack={isMobile ? undefined : () => history.goBack()}
      headerCenter={<Header hideBorder partnerLogo={partner?.logo?.src} />}
    >
      <BrowserPageTitle title="Verify Phone number | Wingspan" />
      <WSMessageBox
        kind="Warning"
        noBorder
        centered
        className={styles.banner}
      ></WSMessageBox>
      <WSContainer verticalPadding>
        {isMobile ? (
          <WSFlexBox.CenterY mb="M" onClick={() => history.goBack()}>
            <WSIcon
              block
              name="chevron-left"
              size="XS"
              mr="XS"
              color="gray700"
            />
            <WSText.ParagraphSm color="gray500">
              <b>Back</b>
            </WSText.ParagraphSm>
          </WSFlexBox.CenterY>
        ) : null}
        <WSFlexBox
          alignContent="center"
          alignItems="stretch"
          direction="column"
        >
          <WSText.Heading5 mb="M">
            Number linked to existing account
          </WSText.Heading5>
          <WSText color="gray600" mb="3XL">
            {(location.state as any)?.message}
          </WSText>
          <WSButtons>
            <WSButton.Primary onClick={() => history.push("/sign-out")}>
              Sign in
            </WSButton.Primary>
            <WSButton.Secondary
              onClick={() => history.push("/member/verify/phone-number")}
            >
              Choose another number
            </WSButton.Secondary>
          </WSButtons>
        </WSFlexBox>
      </WSContainer>
    </WSLayout>
  );
};
