import {
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";

interface TextListProps extends WSElementProps {
  list: React.ReactNode[];
}

export const TextList: React.FC<TextListProps> = ({ list, ...other }) => {
  return (
    <WSElement as="ul" ml="XL" mt="M" {...other}>
      {list.map((item, index) => (
        <WSElement key={index} as="li">
          <WSText mt="XS">{item}</WSText>
        </WSElement>
      ))}
    </WSElement>
  );
};
