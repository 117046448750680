import {
  WSDivider,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { InstitutionLogoCircle } from "../InstitutionLogo/InstitutionLogoCircle";
import { AccountTemplate } from "./AccountTemplate";
import { WSAccountsListV2Props } from "./WSAccountsListV2.component";
import styles from "./WSAccountsListV2.module.scss";
import { Institution } from "./types";
import { getAccountSubName } from "./utils";
import { Actions } from "../Actions";

export interface InstitutionTemplateProps
  extends Partial<WSAccountsListV2Props> {
  institution: Institution;
}

export const InstitutionTemplate: React.FC<InstitutionTemplateProps> = ({
  institution,
  getInstitutionSubtitle,
  onDeleteInstitution,
  onManageInstitution,
  onEditAccount,
  onDeleteAccount,
  defaultPaymentMethod,
  isSingleAccountPreview
}) => {
  return (
    <WSElement
      className={styles.institution}
      data-testid={`institutionAccount${institution.id}`}
    >
      <WSFlexBox.CenterY
        mb={isSingleAccountPreview ? "NONE" : "M"}
        justify="space-between"
      >
        <WSElement>
          <WSFlexBox.CenterY>
            <InstitutionLogoCircle institutionId={institution.id} />
            <WSElement ml="M">
              <WSText weight="medium" singleLine>
                {institution.name || "Manually Added"}
              </WSText>
              {getInstitutionSubtitle && (
                <WSText.ParagraphSm singleLine>
                  {getInstitutionSubtitle(institution)}
                </WSText.ParagraphSm>
              )}
            </WSElement>
          </WSFlexBox.CenterY>
        </WSElement>
        <WSFlexBox.CenterY>
          {onDeleteInstitution && !onManageInstitution ? (
            <WSElement
              onClick={() => onDeleteInstitution(institution)}
              data-testid={`delete-institution-${institution.id}`}
            >
              <WSIcon block size="S" color="gray600" p="S" name="trash" />
            </WSElement>
          ) : null}
          {(onDeleteInstitution && onManageInstitution) ||
          (!onDeleteInstitution && onManageInstitution) ? (
            <Actions
              p="S"
              name="actions"
              items={[
                ...(onManageInstitution
                  ? [
                      {
                        label: "Manage synced accounts",
                        onClick: () => onManageInstitution(institution)
                      }
                    ]
                  : []),
                ...(onDeleteInstitution
                  ? [
                      {
                        label: "Delete",
                        onClick: () => onDeleteInstitution(institution)
                      }
                    ]
                  : [])
              ].filter(Boolean)}
            />
          ) : null}
        </WSFlexBox.CenterY>
      </WSFlexBox.CenterY>
      {isSingleAccountPreview ? null : <WSDivider />}
      {institution.accounts
        .sort((a, b) => (getAccountSubName(a) > getAccountSubName(b) ? 1 : -1))
        .map(account => (
          <WSElement key={account.accountId}>
            <AccountTemplate
              account={account}
              onEditAccount={onEditAccount}
              onDeleteAccount={onDeleteAccount}
              defaultPaymentMethod={defaultPaymentMethod}
              isSingleAccountPreview={isSingleAccountPreview}
            />
          </WSElement>
        ))}
    </WSElement>
  );
};
