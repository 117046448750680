import {
  WSAmountInput,
  WSButton,
  WSButtons,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSText,
  toWSMoneyString,
  useIsMobile,
  useWSSnackbar
} from "@wingspanhq/fe-component-library";
import { useState } from "react";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { MethodTaxWithholdings } from "../../../../shared/components/MethodTaxWithholdings";
import { MethodWallet } from "../../../../shared/components/MethodWallet";
import { selectorAccountFullName } from "../../../../shared/selectors/selectorAccountFullName";
import { ContainerAccount } from "../../../PaymentMethods/components/ContainerAccount";
import { TransferBalance, TransferType } from "../../types";
import { DropdownAccount } from "./DropdownAccount";
import styles from "./index.module.scss";
import { useMutationTransfer } from "./useMutationTransfer";

type Props = {
  balance: TransferBalance;
  type: TransferType;
  onCancel: () => void;
  onSuccess: () => void;
} & WSElementProps;

export const FormTransfer: React.FC<Props> = ({
  type,
  balance,
  onCancel,
  onSuccess,
  ...elementProps
}) => {
  const isMobile = useIsMobile();
  const { openSnackbar } = useWSSnackbar();

  const [amount, setAmount] = useState<number | null>(null);
  const [selectedMethodId, setSelectedMethodId] = useState<
    TransferBalance | string | undefined
  >();

  const [transfer, transferMeta] = useMutationTransfer({
    onSuccess: () => {
      openSnackbar({
        message: "Transfer successfully initiated"
      });
      onSuccess();
    }
  });

  const balancePreivew = (
    <WSElement className={styles.badge}>
      {balance === "wallet" ? <MethodWallet /> : <MethodTaxWithholdings />}
    </WSElement>
  );

  const dropdown = (
    <DropdownAccount
      balance={balance}
      type={type}
      value={selectedMethodId}
      onChange={setSelectedMethodId}
    />
  );

  return (
    <WSElement {...elementProps}>
      <WSText.Heading4 mb="2XL" align="center">
        {type === "deposit"
          ? `Transfer to ${
              balance === "wallet" ? "Wallet" : "Tax withholdings"
            }`
          : "Transfer to bank"}
      </WSText.Heading4>

      <WSFlexBox justify="center" mb="2XL">
        <WSElement style={{ width: 310 }}>
          <WSAmountInput
            name="amount"
            value={amount}
            onChange={({ value }) => {
              setAmount(value);
            }}
          />
        </WSElement>
      </WSFlexBox>

      <WSElement className={styles.accountContainer} mb="2XL">
        <WSElement className={styles.from}>
          <WSText weight="medium" mb="XL">
            From
          </WSText>
          {type === "deposit" ? dropdown : balancePreivew}
        </WSElement>

        <WSIcon
          block
          name={isMobile ? "arrow-down" : "arrow-right"}
          className={styles.icon}
        />

        <WSElement className={styles.to}>
          <WSText weight="medium" mb="XL">
            To
          </WSText>
          {type === "deposit" ? balancePreivew : dropdown}
        </WSElement>
      </WSElement>

      {selectedMethodId && (
        <WSText.ParagraphSm align="center" color="gray400">
          {selectedMethodId === "wallet" ? (
            "Transfer will be sent from your Wallet balance"
          ) : selectedMethodId === "taxWithholdings" ? (
            "Transfer will be sent from your Tax Withholdings balance"
          ) : (
            <ContainerAccount accountId={selectedMethodId}>
              {account => (
                <>
                  Transfer will be sent from {selectorAccountFullName(account)}
                </>
              )}
            </ContainerAccount>
          )}
        </WSText.ParagraphSm>
      )}

      <WSButtons mt="M">
        <WSButton.Secondary style={{ flex: 1 }} mr="M" onClick={onCancel}>
          Cancel
        </WSButton.Secondary>
        <WSButton
          onClick={() => {
            if (!amount || !selectedMethodId) {
              return;
            }

            transfer({
              balance,
              type,
              amount,
              methodId: selectedMethodId
            });
          }}
          style={{ flex: 1 }}
          disabled={!selectedMethodId || !amount}
          loading={transferMeta.isLoading}
        >
          Transfer {toWSMoneyString(amount)}
        </WSButton>
      </WSButtons>

      <WSErrorMessage
        mt="XL"
        contextKey="PendingWithholding"
        error={transferMeta.error}
      />
    </WSElement>
  );
};
