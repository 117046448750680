import cn from "classnames";
import React, { KeyboardEventHandler, useCallback, useEffect } from "react";
import { WSIcon } from "../../core/WSIcon/WSIcon.component";
import { WSText } from "../../core/WSText/WSText.component";
import { WSScreen } from "../../layout";
import {
  WSElement,
  WSElementProps,
  WSElementSpacingsType
} from "../../WSElement/WSElement.component";
import styles from "./WSModal.module.scss";
import { WSSidebar } from "../WSSidebar/WSSidebar.component";

export enum WSModalSizeMap {
  XS = "XS",
  S = "S",
  M = "M",
  L = "L",
  XL = "XL"
}

export type WSModalSize = keyof typeof WSModalSizeMap;

export interface WSModalProps extends WSElementProps {
  title?: string;
  onClose?: (result: any) => void;
  blockClose?: boolean;
  size?: WSModalSize;
  pullOver?: boolean;
  showCloseIcon?: boolean;
  centered?: boolean;
  fullScreen?: boolean /** For tablet & desktop */;
  fullScreenOnMobile?: boolean;
  allowClickOutsideToClose?: boolean;
  allowClickOutsideWithConfirmationToClose?: boolean;
}

const modalPaddingMap: { [key in WSModalSizeMap]: WSElementSpacingsType } = {
  XS: "L",
  S: "L",
  M: "XL",
  L: "XL",
  XL: "XL"
};

const CONFIRM_CLOSE_MESSAGE =
  "Are you sure you want to close? Your work won't be saved.";
export const WSModal: React.FC<WSModalProps> = (props) => {
  const { blockClose, onClose, title, children, ...otherProps } = props;

  // Lock body scroll
  useEffect(() => {
    document.body.classList.add(styles.withModal);
    return () => {
      document.body.classList.remove(styles.withModal);
    };
  }, []);

  useEffect(
    () => () => {
      onClose?.(null);
    },
    [onClose]
  );

  // Single close handler
  const handleClose = useCallback(() => {
    if (!blockClose) {
      onClose?.(null);
    }
  }, [onClose, blockClose]);

  const renderChildren = useCallback(
    () => (
      <>
        {title && <WSText.Heading5 mb="M">{title}</WSText.Heading5>}
        {children}
      </>
    ),
    [title, children]
  );

  // Handle Escape button press
  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === "Escape") {
      handleClose();
    }
  };

  const {
    className,
    pullOver,
    showCloseIcon = true,
    fullScreen,
    centered = false,
    fullScreenOnMobile,
    size = "M",
    allowClickOutsideToClose,
    allowClickOutsideWithConfirmationToClose,
    ...elementProps
  } = otherProps;

  return (
    <>
      <WSScreen.Mobile>
        {pullOver || fullScreenOnMobile ? (
          <>
            <WSElement className={styles.overlay} onClick={handleClose} />
            <WSElement
              className={cn(
                styles.base,
                {
                  [styles.fullScreen]: fullScreenOnMobile,
                  [styles.centered]: centered
                },
                className
              )}
              onKeyDown={handleKeyDown}
              tabIndex={0}
              ref={(element) => {
                if (element) {
                  element.focus();
                  element.scrollTop = 0;
                }
              }}
              p={modalPaddingMap[size]}
              {...elementProps}
            >
              {renderChildren()}
            </WSElement>
          </>
        ) : (
          <WSSidebar
            onClose={handleClose}
            className={cn(styles.drawerBase, className)}
            onKeyDown={handleKeyDown}
            position="bottom"
            contentElementProps={{
              colorBackground: "white"
            }}
            size="AUTO"
            {...elementProps}
          >
            {renderChildren()}
          </WSSidebar>
        )}
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        <WSElement
          data-active-modal
          className={cn(styles.wrapper, blockClose && styles.blockClose)}
          onClick={
            allowClickOutsideToClose || allowClickOutsideWithConfirmationToClose
              ? (event) => {
                  if (event.target === event.currentTarget) {
                    if (allowClickOutsideToClose) {
                      handleClose();
                    } else if (allowClickOutsideWithConfirmationToClose) {
                      if (window.confirm(CONFIRM_CLOSE_MESSAGE)) {
                        handleClose();
                      }
                    }
                  }
                }
              : undefined
          }
        >
          <WSElement
            className={cn(
              styles.base,
              {
                [styles.fullScreen]: fullScreen,
                [styles.centered]: centered
              },
              className,
              styles[`size-${size}`]
            )}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            ref={(element) => {
              if (element) {
                element.focus();
                element.scrollTop = 0;
              }
            }}
            p={modalPaddingMap[size]}
            {...elementProps}
          >
            {showCloseIcon && (
              <WSIcon
                block
                name="exit"
                size="L"
                onClick={handleClose}
                className={cn(
                  styles.closeIcon,
                  blockClose && styles.blockClose
                )}
              />
            )}
            {renderChildren()}
          </WSElement>
        </WSElement>
      </WSScreen.TabletAndDesktop>
    </>
  );
};

WSModal.displayName = "WSModal";
