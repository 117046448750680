import {
  toWSMoneyString,
  useModalContext,
  WSButton,
  WSButtons,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import { IDeductionResponse } from "@wingspanhq/payments/dist/interfaces/api/deductions";
import React from "react";
import { useHistory } from "react-router-dom";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useDeleteCollaboratorDeduction } from "../../../../query/payments/mutations";
import { goBackOrToParent } from "../../../../utils/goToParentRoute";

type Props = {
  deduction: IDeductionResponse;
};

export const DELETE_DEDUCTION_MODAL = "DELETE_DEDUCTION_MODAL";

export const Inner: React.FC<Props> = ({ deduction }) => {
  const { closeModal } = useModalContext();
  const history = useHistory();
  const onClose = () => goBackOrToParent(history);

  const [deleteDeduction, deleteDeductionMeta] = useDeleteCollaboratorDeduction(
    {
      onSuccess: () => {
        closeModal(DELETE_DEDUCTION_MODAL);
        onClose();
      }
    }
  );

  const deductedAmount = deduction.application
    .map(a => a.amountDeducted)
    .reduce((a, b) => a + b, 0);

  const remainingAmount = deductedAmount
    ? deduction.amount - deductedAmount
    : 0;

  return (
    <>
      {remainingAmount ? (
        <WSText mt="XL">
          {toWSMoneyString(deductedAmount)} has already been deducted from
          previous invoices. Only the remaining balance will be cancelled.
        </WSText>
      ) : null}

      <WSErrorMessage
        mt="XL"
        error={deleteDeductionMeta.error}
        contextKey="Deduction"
      />

      <WSButtons mt="3XL">
        <WSButton.Tertiary onClick={() => closeModal(DELETE_DEDUCTION_MODAL)}>
          Go Back
        </WSButton.Tertiary>
        <WSButton
          destructive
          loading={deleteDeductionMeta.isLoading}
          onClick={() => deleteDeduction(deduction.deductionId)}
        >
          Delete
        </WSButton>
      </WSButtons>
    </>
  );
};

export const DeleteDeduction: React.FC = () => {
  return (
    <WSModal
      name={DELETE_DEDUCTION_MODAL}
      size="XS"
      title="Are you sure you want to delete this deduction?"
    >
      {({ deduction }) => <Inner deduction={deduction} />}
    </WSModal>
  );
};
