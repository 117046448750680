import {
  FundingSourceType,
  IPayrollSettings
} from "@wingspanhq/payments/dist/interfaces";
import { IClient } from "@wingspanhq/users/dist/lib/interfaces";

export const selectorIsDefaultPaymentMethodSet = (
  client: IClient,
  payrollSettings: IPayrollSettings
) =>
  !!client.profile.defaultPaymentMethod?.accountId ||
  !!client.profile.defaultPaymentMethod?.paymentMethodId ||
  payrollSettings.fundingSource?.fundingSourceType ===
    FundingSourceType.InternalAccount;
