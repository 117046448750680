import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { RoutePayeesDashboard } from "./routes/RouteDashboard";
import { RoutePayeeDetails } from "./routes/RoutePayeeDetails";
import {
  PAYEES_ARCHIVED_PATH,
  PAYEES_DETAILS_ROUTE,
  PAYEES_ENGAGEMENTS_DETAILS_PATH,
  PAYEES_ENGAGEMENTS_LIST_PATH,
  PAYEES_GROUPS_LIST_PATH,
  PAYEES_ROOT_PATH,
  PAYEES_SEARCH_PATH
} from "./utils";
import { RouteArchivedPayees } from "./routes/RouteArchivedPayees";
import { RoutePayeesSearch } from "./routes/RoutePayeesSearch/RoutePayeesSearch";
import { RouteEngagementDetails } from "./routes/RouteEngagementDetails";
import { useIsEngagementsActive } from "../../shared/hooks/useIsEngagementsActive";
import { WSEmptyState } from "@wingspanhq/fe-component-library";

export const PayeesIndex: React.FC = () => {
  useBrowserPageTitle("Contractors");
  const isNewEngagements = useIsEngagementsActive();

  return (
    <Switch>
      <Route path={PAYEES_SEARCH_PATH} component={RoutePayeesSearch} />
      <Route
        path={PAYEES_ENGAGEMENTS_DETAILS_PATH}
        component={RouteEngagementDetails}
      />

      {isNewEngagements
        ? [
            <Route
              key={PAYEES_ENGAGEMENTS_LIST_PATH}
              path={PAYEES_ENGAGEMENTS_LIST_PATH}
              component={RoutePayeesDashboard}
            />,
            <Route
              key={PAYEES_GROUPS_LIST_PATH}
              path={PAYEES_GROUPS_LIST_PATH}
              component={() => (
                <WSEmptyState
                  fullWidth
                  type="people"
                  size="M"
                  theme="garnet"
                  title="You have no access to groups"
                  description="Contact your administrator to request access"
                />
              )}
            />
          ]
        : [
            <Route
              key={PAYEES_GROUPS_LIST_PATH}
              path={PAYEES_GROUPS_LIST_PATH}
              component={RoutePayeesDashboard}
            />,
            <Route
              key={PAYEES_ENGAGEMENTS_LIST_PATH}
              path={PAYEES_ENGAGEMENTS_LIST_PATH}
              component={() => (
                <WSEmptyState
                  fullWidth
                  type="people"
                  size="M"
                  theme="garnet"
                  title="You have no access to engagements"
                  description="Contact your administrator to request access"
                />
              )}
            />
          ]}

      <Route
        path={
          isNewEngagements
            ? PAYEES_ENGAGEMENTS_LIST_PATH
            : PAYEES_GROUPS_LIST_PATH
        }
        component={RoutePayeesDashboard}
      />

      <Route
        path={PAYEES_ARCHIVED_PATH}
        component={RouteArchivedPayees}
        exact
      />
      <Route path={PAYEES_DETAILS_ROUTE} component={RoutePayeeDetails} />
      <Route path={PAYEES_ROOT_PATH} component={RoutePayeesDashboard} />

      <Redirect from="*" to={PAYEES_ROOT_PATH} />
    </Switch>
  );
};
