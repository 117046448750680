import {
  useIsMobile,
  WSPage,
  WSPreview,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";
import image1 from "../../../../../assets/images/1099-filing/feature-1.svg";
import image2 from "../../../../../assets/images/1099-filing/feature-2.svg";
import image3 from "../../../../../assets/images/1099-filing/feature-3.svg";

import styles from "./RoutePreview.module.scss";
import { WSPersistentUpgradeButton } from "../../../../../components/Membership/WSPersistentUpgradeButton";
import { WSFrontendFeature } from "../../../../../Settings/utils/subscriptionUtils";
import { openInNewTab } from "../../../../../utils/openInNewTab";
import { CURRENT_YEAR } from "../../../constants/currentYear";

export const RoutePreview: React.FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const onClickBeginFiling = () => {
    history.push("/member/1099-filing/set-up");
  };

  return (
    <WSPage
      title={
        <>
          <WSText.Heading1 mr="M" inline>
            1099 Filing Dashboard
          </WSText.Heading1>
          <WSText.ParagraphSm
            className={styles.filingYear}
            inline
            color="gray500"
          >
            {CURRENT_YEAR}
          </WSText.ParagraphSm>
        </>
      }
      primaryButtonComponent={props => (
        <WSPersistentUpgradeButton
          name="beginFiling"
          feature={WSFrontendFeature.NEC1099Filing}
          onClick={onClickBeginFiling}
          {...props}
        >
          {`Begin ${CURRENT_YEAR} filing`}
        </WSPersistentUpgradeButton>
      )}
    >
      <WSText color="gray500" className={styles.description}>
        Use the Wingspan's 1099 tax reporting to create, revise, file, and
        deliver tax forms for your contractors accounts.
        <WSText
          className={styles.learnMoreLink}
          color="gray500"
          inline
          ml="XS"
          onClick={() => {
            openInNewTab(
              "https://wingspan.readme.io/docs/getting-started-1099-filing"
            );
          }}
        >
          Learn more.
        </WSText>
      </WSText>

      <WSPreview
        mt={isMobile ? "2XL" : "5XL"}
        list={[
          {
            title: "Provide a seamless contractor experience",
            description:
              "Your contractors can now access tax forms electronically and manage their information directly through Wingspan.",
            image: image1
          },
          {
            title: "Save time on 1099 filing",
            description:
              "Auto-generate 1099s and avoid hours of manual work. Wingspan handles e-filing with the IRS and states and delivers 1099s to your contractors",
            image: image2
          },
          {
            title: "Reduce costly corrections",
            description:
              "Wingspan can share a draft 1099 with your contractors, allowing them to dispute any issues before filing to minimize costly corrections.",
            image: image3
          }
        ]}
      />
    </WSPage>
  );
};
