import {
  useIsMobile,
  WSFormOld,
  WSInputMaskOld
} from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import React from "react";
import * as Yup from "yup";
import { useUserId } from "../../../query/hooks/helpers";
import { useQueryVerifications } from "../../../query/onboarding/queries/useQueryVerifications";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import { getSsnInputProps } from "../../../shared/utils/getSsnInputProps";
import { openIntercomMessage } from "../../../shared/utils/intercom";
import { validatorSSN } from "../../../utils/validators";
import styles from "./SettingsUpdateForms.module.scss";

interface UpdateSSNFormProps {
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: () => void;
}

export const UpdateSSNForm: React.FC<UpdateSSNFormProps> = ({
  onSubmit,
  defaultValues,
  onCancel
}) => {
  const isMobile = useIsMobile();
  const queryVerifications = useQueryVerifications();
  const isVerified = queryVerifications.data?.tax === "Verified";

  const userId = useUserId();
  const [updateMemberProfile] = useUpdateMemberProfile();
  const onFormSubmit = async (data: any) => {
    if (defaultValues.ssn && isVerified) {
      openIntercomMessage(`Hi there! I want to update my SSN.\n\n`);
      onSubmit(data);
      return;
    }

    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        ssn: data.ssn
      }
    };
    await updateMemberProfile(request, {
      onSettled: () => {
        onSubmit(data);
      }
    });
  };

  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={{
        ssn: ""
      }}
      validationSchema={Yup.object().shape({
        ssn: validatorSSN.required("Required")
      })}
    >
      <WSFormOld.Field
        mt="XL"
        name="ssn"
        component={WSInputMaskOld}
        componentProps={getSsnInputProps()}
      />

      <WSFormOld.SubmitButton
        name="update"
        type="submit"
        mt="2XL"
        fullWidth={isMobile}
      >
        {defaultValues.ssn && isVerified
          ? "Send request to customer support"
          : "Update"}
      </WSFormOld.SubmitButton>
    </WSFormOld>
  );
};
