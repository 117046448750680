import {
  WSButton,
  WSFlexBox,
  WSMarginProps,
  WSRadioInputGroup,
  useWSModal
} from "@wingspanhq/fe-component-library";
import { useEffect } from "react";
import { ModalDeletePaymentCard } from "../../../components/WSAccountsListV2/ModalDeletePaymentCard";
import { useQueryPaymentsCards } from "../../../query/paymentCards/queries/useQueryPaymentsCards";
import { usePaymentCardForm } from "../../../shared/hooks/usePaymentCardForm";
import { Card } from "../Card/Card";
import { PaymentCard } from "../PaymentCard";

type Props = {
  value: string;
  onChange: (newValue: string) => void;
} & WSMarginProps;

export const SelectPaymentCard: React.FC<Props> = ({
  value,
  onChange,
  ...elementProps
}) => {
  const queryPaymentCards = useQueryPaymentsCards();
  const paymentCardForm = usePaymentCardForm();

  const openPaymentCardForm = () => {
    paymentCardForm.open();
  };

  const hasPaymentCards =
    queryPaymentCards.data && queryPaymentCards.data.length > 0;

  useEffect(() => {
    if (queryPaymentCards.data && queryPaymentCards.data.length > 0 && !value) {
      onChange(queryPaymentCards.data[0].paymentCardId);
    }
  }, [onChange, queryPaymentCards.data, value]);

  const modalDelete = useWSModal(ModalDeletePaymentCard, {
    size: "S"
  });

  return (
    <Card
      title="Credit card information"
      action={
        hasPaymentCards
          ? {
              kind: "Link",
              icon: "plus-circle",
              children: "Add",
              onClick: openPaymentCardForm
            }
          : undefined
      }
      {...elementProps}
    >
      <WSRadioInputGroup
        name="paymentCardId"
        value={value}
        options={(queryPaymentCards.data || []).map((paymentCard, index) => ({
          value: paymentCard.paymentCardId,
          label: (
            <WSFlexBox
              wrap="nowrap"
              justify="space-between"
              alignItems="center"
            >
              <PaymentCard paymentCard={paymentCard} mr="XL" />

              <WSButton.Link
                type="button"
                onClick={() => {
                  modalDelete.open({
                    paymentCard
                  });
                }}
              >
                Remove
              </WSButton.Link>
            </WSFlexBox>
          )
        }))}
        onChange={(event: any) => {
          onChange(event.target.value);
        }}
      />

      {!hasPaymentCards && (
        <WSButton.Link
          icon="plus-circle"
          onClick={() => {
            paymentCardForm.open();
          }}
        >
          Add credit card
        </WSButton.Link>
      )}
    </Card>
  );
};
