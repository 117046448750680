// TODO: refactor code to get rid of standard html elements

import {
  WSButton,
  WSButtons,
  WSRadioInput,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayoutSettingsResponse } from "@wingspanhq/payments/dist/interfaces";
import { IAccount } from "@wingspanhq/users";
import React, { useEffect, useMemo, useState } from "react";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../services/platform";
import { selectorAccountFullName } from "../../selectors/selectorAccountFullName";
import { selectorPayoutAccount } from "../../selectors/selectorPayoutAccount";
import { IconInstitution } from "../IconInstitution";
import styles from "./index.module.scss";
import { selectorPossiblePayoutAccounts } from "./selectorPossiblePayoutAccounts";

type Props = {
  accounts: IAccount[];
  payoutSettings: IPayoutSettingsResponse;
  onSubmit: (accountId: string) => void;
  onLinkAnotherAccount: () => void;
  error?: any;
  isLoading?: boolean;
};

export const Form: React.FC<Props> = ({
  accounts,
  payoutSettings,
  onSubmit,
  error,
  isLoading,
  onLinkAnotherAccount
}) => {
  const [value, setValue] = useState("");

  const filteredAccounts = useMemo(
    () => selectorPossiblePayoutAccounts(accounts),
    [accounts]
  );

  useEffect(() => {
    const currentPayoutAccount = selectorPayoutAccount(
      payoutSettings,
      filteredAccounts
    );

    setValue(
      currentPayoutAccount
        ? currentPayoutAccount.accountId
        : filteredAccounts.length > 0
        ? filteredAccounts[0].accountId
        : ""
    );
  }, [filteredAccounts, payoutSettings]);

  const accountsByInstitution: { [key: string]: IAccount[] } = {};

  filteredAccounts.forEach(account => {
    const institution = account.institution || "";
    if (!accountsByInstitution[institution])
      accountsByInstitution[institution] = [];

    accountsByInstitution[institution].push(account);
  });

  return Object.keys(accountsByInstitution).length > 0 ? (
    <>
      {Object.keys(accountsByInstitution).map(institution => (
        <div key={institution} className={styles.section}>
          <h3 className={styles.sectionTitle}>{institution}</h3>
          <ul>
            {accountsByInstitution[institution].map(account => (
              <li
                key={account.accountId}
                onClick={() => {
                  setValue(account.accountId);
                }}
                data-testid={`account-item-${account.accountId}`}
              >
                <div className={styles.name}>
                  <IconInstitution institutionId={account.institutionId} />
                  {selectorAccountFullName(account)}
                </div>
                <WSRadioInput
                  name="user-account"
                  value={account.accountId}
                  checked={value === account.accountId}
                />
              </li>
            ))}
          </ul>
        </div>
      ))}

      <WSErrorMessage
        my="XL"
        contextKey={ErrorContextKey.CreateAccount}
        error={error}
      />

      <WSButtons forceFullWidth format="modal">
        <WSButton
          name="proceedSelectedAccount"
          onClick={() => {
            onSubmit(value);
          }}
          loading={isLoading}
        >
          Proceed with selected account
        </WSButton>
        <WSButton
          data-testid="linkAccount"
          kind="Secondary"
          onClick={onLinkAnotherAccount}
        >
          Link another account
        </WSButton>
      </WSButtons>
    </>
  ) : (
    <>
      <WSText mb="XL">
        We weren't able to find any accounts eligible for deposits. Please try
        connecting another bank account.{" "}
      </WSText>
      <WSButton
        data-testid="linkAccount"
        fullWidth
        onClick={onLinkAnotherAccount}
      >
        Link a different account
      </WSButton>
    </>
  );
};
