import { useHistory, useRouteMatch } from "react-router-dom";
import { EditLayout } from "../../../components/EditLayout";
import { WSQueries } from "../../../../../query/WSQuery";
import {
  WSButton,
  WSButtons,
  WSDivider,
  WSElement,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";
import { getCorrectionTypeFromCorrectionRequest } from "./getCorrectionTypeFromCorrectionRequest";
import { AddressCorrectionDiffPreview } from "../../../../TaxDocuments/components/AddressCorrectionDiffPreview";
import { AmountCorrectionDiffPreview } from "../../../../TaxDocuments/components/AmountCorrectionDiffPreview";
import { TINOrNameCorrectionDiffPreview } from "../../../../TaxDocuments/components/TINOrNameCorrectionDiffPreview";
import {
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME,
  CORRECTION_ADDRESS
} from "../../../../TaxDocuments/constants/corrections";
import {
  getPayeeTINorName,
  getPayeeFormW9Address
} from "../../../../TaxDocuments/routes/RequestCorrectionFlow/getDefaultValuesForCorrectionsForm";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import { TinType } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { UnchangedRecipientDetails } from "../StartNewCorrectionFlow/UnchangedRecipientDetails";
import { useModalPayerRejectReason } from "../../../../TaxDocuments/components/useModalPayerRejectReason";
import {
  IPayerTaxFormResponse,
  ITaxFormCorrectionResponse
} from "@wingspanhq/payments/dist/interfaces";
import { usePayerTaxFormWithCorrection } from "../../../../../query/taxForm/queries/usePayerTaxFormWithCorrection";
import { transformCorrectionRequestToFormData } from "./transformCorrectionRequestToFormData";
import { TaxFormViewer } from "../../../../TaxDocuments/types";
import { usePayerForceSubmitTaxFormWarningModal } from "../../../components/modals/usePayerForceSubmitTaxFormWarningModal";
import { usePayerVoidOriginalTaxFormWarningModal } from "../../../components/modals/usePayerVoidOriginalTaxFormWarningModal";

export const RouteReviewCorrectionRequest: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch<{ taxFormId: string }>();
  const { taxFormId } = match.params;
  const queryTaxForm = usePayerTaxFormWithCorrection(taxFormId);
  const rejectionModal = useModalPayerRejectReason();
  const payerVoidOriginalTaxFormWarningModal = usePayerVoidOriginalTaxFormWarningModal();
  const payerForceSubmitTaxFormWarningModal = usePayerForceSubmitTaxFormWarningModal();

  const onContinue = (options = { isForce: 0 }) => {
    history.push(
      `/member/1099-filing/details/${taxFormId}/submit-correction?force=${options.isForce}`
    );
  };

  const rejectCorrectionRequest = () => {
    rejectionModal.open({
      taxForm: queryTaxForm.data as IPayerTaxFormResponse
    });
  };

  const onCancel = () => {
    history.push(`/member/1099-filing/details/${taxFormId}`);
  };

  const onEditUnchangedDetails = () => {
    const taxForm = queryTaxForm.data as IPayerTaxFormResponse;
    const { corrections } = getCorrectionTypeFromCorrectionRequest(taxForm);

    onEdit({
      corrections: [
        ...(!corrections.includes(CORRECTION_AMOUNT)
          ? [CORRECTION_AMOUNT]
          : []),
        ...(!corrections.includes(CORRECTION_TIN_OR_NAME)
          ? [CORRECTION_TIN_OR_NAME]
          : []),
        ...(!corrections.includes(CORRECTION_ADDRESS)
          ? [CORRECTION_ADDRESS]
          : [])
      ],
      showRequestedValue: false
    });
  };

  const onAmountEdit = () => {
    onEdit({
      corrections: [CORRECTION_AMOUNT],
      showRequestedValue: true
    });
  };

  const onTINOrNameEdit = () => {
    onEdit({
      corrections: [CORRECTION_TIN_OR_NAME],
      showRequestedValue: true
    });
  };

  const onAddressEdit = () => {
    onEdit({
      corrections: [CORRECTION_ADDRESS],
      showRequestedValue: true
    });
  };

  const onEdit = ({
    corrections,
    showRequestedValue
  }: {
    corrections: string[];
    showRequestedValue: boolean;
  }) => {
    const taxForm = queryTaxForm.data as IPayerTaxFormResponse;
    const correctionRequest = taxForm?.pendingCorrection as ITaxFormCorrectionResponse;
    history.push({
      pathname: `/member/1099-filing/details/${taxFormId}/submit-correction/edit`,
      state: {
        backPath: history.location.pathname,
        corrections,
        showRequestedValue,
        correctionsFormData: transformCorrectionRequestToFormData(
          corrections,
          taxForm,
          correctionRequest
        )
      }
    });
  };

  return (
    <EditLayout title="Submit correction">
      <WSElement>
        <WSText.Heading2 mb="XS">
          Update 1099-NEC and submit correction
        </WSText.Heading2>
        <WSText.ParagraphSm color="gray500">
          Please review the requested corrections thoroughly before submitting a
          correction to the IRS.
        </WSText.ParagraphSm>

        <WSDivider my="XL" />

        <WSQueries queries={{ queryTaxForm }}>
          {({ queryTaxFormData: taxForm }) => {
            const recipientName = selectorTaxFormRecipientName(taxForm);
            const correctionRequest = taxForm?.pendingCorrection;
            if (!correctionRequest) {
              return (
                <WSMessageBox.Info
                  button={{
                    kind: "Link",
                    children: "Refresh",
                    onClick: () => {
                      window.location.reload();
                    }
                  }}
                >
                  Please refresh the web page if you can't see correction
                  details.
                </WSMessageBox.Info>
              );
            }
            const {
              corrections,
              isVoidType,
              isCompanyStructureChangeOnly
            } = getCorrectionTypeFromCorrectionRequest(taxForm);

            const hasUnchangedDetails =
              !corrections.includes(CORRECTION_AMOUNT) ||
              !corrections.includes(CORRECTION_TIN_OR_NAME) ||
              !corrections.includes(CORRECTION_ADDRESS);

            const correctionTypeToPreviewComponentMap: Record<
              string,
              JSX.Element
            > = {
              [CORRECTION_AMOUNT]: (
                <AmountCorrectionDiffPreview
                  taxFormViewer={TaxFormViewer.Payer}
                  taxForm={taxForm}
                  onEdit={onAmountEdit}
                  amount={taxForm?.data?.totalAmount ?? 0}
                  correctedAmount={correctionRequest.data?.totalAmount ?? 0}
                  comment={correctionRequest.payeeOwnedData?.comment ?? ""}
                />
              ),
              [CORRECTION_TIN_OR_NAME]: (
                <TINOrNameCorrectionDiffPreview
                  taxFormViewer={TaxFormViewer.Payer}
                  taxForm={taxForm}
                  onEdit={onTINOrNameEdit}
                  identificationNumberType={
                    correctionRequest.data?.w9Info?.ein ||
                    correctionRequest.data?.w9Info?.einLastFour
                      ? TinType.Business
                      : TinType.Individual
                  }
                  originalTinData={getPayeeTINorName(taxForm)}
                  correctedTinData={{
                    ssn: correctionRequest?.data?.w9Info?.ssn ?? "",
                    ein: correctionRequest?.data?.w9Info?.ein ?? "",
                    businessName:
                      correctionRequest?.data?.w9Info?.legalBusinessName ?? "",
                    firstName: correctionRequest?.data?.w9Info?.firstName,
                    lastName: correctionRequest?.data?.w9Info?.lastName,
                    disregardedEntityName:
                      correctionRequest?.data?.w9Info?.disregardedEntityName,
                    taxClassification: correctionRequest?.data?.w9Info
                      ?.companyStructure as CompanyStructure
                  }}
                  reasonForChange={
                    correctionRequest?.payeeOwnedData?.reason ?? ""
                  }
                  otherReason={correctionRequest?.payeeOwnedData?.reasonComment}
                  hasOtherEligibleCorrectionChanges={
                    !isCompanyStructureChangeOnly
                  }
                />
              ),
              [CORRECTION_ADDRESS]: (
                <AddressCorrectionDiffPreview
                  taxFormViewer={TaxFormViewer.Payer}
                  taxForm={taxForm}
                  onEdit={onAddressEdit}
                  originalAddress={getPayeeFormW9Address(taxForm)}
                  correctedAddress={{
                    addressLine1:
                      correctionRequest?.data?.w9Info?.addressLine1 ?? "",
                    addressLine2:
                      correctionRequest?.data?.w9Info?.addressLine2 ?? "",
                    city: correctionRequest?.data?.w9Info?.city ?? "",
                    state: correctionRequest?.data?.w9Info?.state ?? "",
                    zipCode: correctionRequest?.data?.w9Info?.postalCode ?? "",
                    country: correctionRequest?.data?.w9Info?.country ?? ""
                  }}
                />
              )
            };
            return (
              <>
                <WSText.Heading2 mb="L">{recipientName}</WSText.Heading2>

                {corrections.map(correctionType => {
                  const DiffPreviewComponent =
                    correctionTypeToPreviewComponentMap[correctionType];
                  return (
                    <WSElement mb="L" key={correctionType}>
                      {DiffPreviewComponent}
                      <WSDivider my="XL" />
                    </WSElement>
                  );
                })}

                {hasUnchangedDetails ? (
                  <>
                    <UnchangedRecipientDetails
                      onEdit={onEditUnchangedDetails}
                      taxForm={taxForm}
                      corrections={corrections}
                    />
                    <WSDivider my="XL" />
                  </>
                ) : null}

                {isVoidType ? (
                  <WSButtons forceFullWidth>
                    <WSButton.Primary
                      onClick={() =>
                        payerVoidOriginalTaxFormWarningModal.open({
                          onContinue: () => onContinue({ isForce: 0 })
                        })
                      }
                    >
                      Continue: void original filing
                    </WSButton.Primary>
                    {!isCompanyStructureChangeOnly ? (
                      <WSButton.Secondary
                        onClick={() =>
                          payerForceSubmitTaxFormWarningModal.open({
                            onContinue: () => onContinue({ isForce: 1 })
                          })
                        }
                      >
                        Continue: submit as a correction anyway
                      </WSButton.Secondary>
                    ) : null}
                    <WSButton destructive onClick={rejectCorrectionRequest}>
                      Reject request
                    </WSButton>
                    <WSButton.Tertiary onClick={onCancel}>
                      Cancel
                    </WSButton.Tertiary>
                  </WSButtons>
                ) : (
                  <WSButtons forceFullWidth>
                    <WSButton.Primary onClick={() => onContinue()}>
                      Continue to confirm
                    </WSButton.Primary>
                    <WSButton destructive onClick={rejectCorrectionRequest}>
                      Reject request
                    </WSButton>
                    <WSButton.Tertiary onClick={onCancel}>
                      Cancel
                    </WSButton.Tertiary>
                  </WSButtons>
                )}
              </>
            );
          }}
        </WSQueries>
      </WSElement>
    </EditLayout>
  );
};
