import { useUserProfileData } from "./utils/useUserProfileData";
import { isUserIdsIncluded } from "./utils/isUserIdsIncluded";
import { SHOW_MULTI_CURRENCY_INVOICES_BANNER_IDS } from "./helpers";

export const useShowMultiCurrencyInvoicesBanner = () => {
  const userData = useUserProfileData();

  const showMultiCurrencyInvoicesBanner = isUserIdsIncluded(
    userData,
    SHOW_MULTI_CURRENCY_INVOICES_BANNER_IDS
  );

  return showMultiCurrencyInvoicesBanner;
};
