import React, { useEffect } from "react";

export function useDidClickOutside(ref: any, callback: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
}

export const CatchClickOutside: React.FC<{
  onClickOutside(): void;
  children(ref: React.MutableRefObject<any>): React.ReactNode;
}> = ({ children, onClickOutside }) => {
  const ref = React.useRef<any>(null);

  useDidClickOutside(ref, () => {
    onClickOutside();
  });

  return <>{children(ref)}</>;
};
